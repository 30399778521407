import { useMemo, useState } from "react";

import {
  IProductsModulesTableProps,
  ProductsModulesTableColumnType,
} from "./interface";

import { createPaginationTableObject } from "~components/Pagination";
import { Result } from "~components/Result";
import { useFetchGetPaginatedModules } from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { parseCurrency } from "~utils/parse";
import { Table } from "antd";

export function ProductsModulesTable({
  size = "small",
}: IProductsModulesTableProps) {
  const [currentPage, setCurrentPage] = useState(0);

  const { screens } = useAppConfig();

  const {
    data,
    isLoading: isModulesLoading,
    error: modulesError,
  } = useFetchGetPaginatedModules({
    dependencyArray: [currentPage],
    payload: {
      page: currentPage,
    },
  });

  const DATA_SOURCE = useMemo(() => {
    return data?.content ?? [];
  }, [data?.content]);

  const columns: ProductsModulesTableColumnType = [
    {
      title: "Modelo",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Marca",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Distribuidor",
      dataIndex: "distributor",
      key: "distributor",
    },
    {
      title: "Potência",
      dataIndex: "powerWp",
      key: "powerWp",
      render: (i) => i + "Wp",
    },
    {
      title: "Preço",
      dataIndex: "precoBaseCents",
      key: "precoBaseCents",
      render: (item) => parseCurrency(item / 100),
    },
  ];

  return (
    <Table
      data-cy="products-modules-table"
      pagination={createPaginationTableObject({
        onChange: (page) => setCurrentPage(page - 1),
        size: data?.size,
        disabled: false,
        itemType: "módulos",
        number: data?.number ? data?.number + 1 : 1,
        totalElements: data?.totalElements,
        displayTotalInformation: screens.sm,
      })}
      rowKey="id"
      loading={isModulesLoading}
      style={{ width: "100%" }}
      scroll={{ x: 720 }}
      columns={columns}
      dataSource={DATA_SOURCE}
      size={size}
      locale={{
        emptyText: (
          <Result.TableErrorOrEmpty
            errorMessage={modulesError?.message}
            emptyMessage="Nenhum módulo encontrado."
          />
        ),
      }}
    />
  );
}
