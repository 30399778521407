import { AiOutlinePoweroff } from "react-icons/ai";

import type { ILogoutButtonProps } from "./interface";
import styles from "./styles";

import { useSession } from "~hooks/useSession";
import { Button, Flex } from "antd";

export function LogoutButton({
  size = "small",
  type = "link",
  className,
  ...props
}: ILogoutButtonProps) {
  const { signOut } = useSession();

  return (
    <Button
      data-cy="logout-avatar"
      type={type}
      onClick={signOut}
      className={`menuItem logoutItem ${className}`}
      size={size}
      {...props}
    >
      <style jsx>{styles}</style>
      <Flex align="center" gap={8}>
        <AiOutlinePoweroff />
        Logout
      </Flex>
    </Button>
  );
}
