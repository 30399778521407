import { useEffect, useRef } from "react";
import { BsSliders } from "react-icons/bs";

import type { ISolarzInputProps, SolarzInputInputType } from "./interface";
import { StyledInput } from "./styles";

import {
  formatCnpj,
  formatCpf,
  formatCpfCnpj,
  formatTelephoneWithDdd,
  formatCep,
} from "~utils/format";
import { Dropdown, InputRef } from "antd";

function formatValueByInputType(
  value: string,
  inputType: SolarzInputInputType,
) {
  switch (inputType) {
    case "cnpj": {
      return formatCnpj(value ?? "");
    }
    case "telephone": {
      return formatTelephoneWithDdd(value ?? "");
    }
    case "cpf": {
      return formatCpf(value ?? "");
    }
    case "cpf/cnpj": {
      return formatCpfCnpj(value ?? "");
    }
    case "cep": {
      return formatCep(value ?? "");
    }
    default: {
      return value ?? "";
    }
  }
}

export function SolarzInput({
  defaultValue,
  id,
  name,
  onChange,
  onPressEnter,
  placeholder,
  style,
  value,
  filterRender,
  icon,
  scale,
  readOnly = false,
  inputType = "default",
  isDisabled = false,
}: ISolarzInputProps) {
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (defaultValue && inputRef.current?.input) {
      inputRef.current.input.value = defaultValue;
    }
  }, [inputRef, defaultValue]);

  return (
    <StyledInput
      ref={inputRef}
      defaultValue={defaultValue ?? undefined}
      value={value ?? undefined}
      onChange={(event) => {
        event.target.value = formatValueByInputType(
          event.target?.value ?? "",
          inputType,
        );

        if (typeof onChange === "function") {
          onChange(event.target.value);
        }
      }}
      suffix={
        filterRender ? (
          <Dropdown
            trigger={["click"]}
            placement="bottomRight"
            dropdownRender={() => filterRender ?? <div></div>}
            overlayStyle={{ top: 162 }}
          >
            {icon ?? <BsSliders />}
          </Dropdown>
        ) : (
          icon ?? undefined
        )
      }
      onFocus={(event) => event.target.select()}
      onPressEnter={(_event) => {
        if (typeof onPressEnter === "function") {
          onPressEnter(value || inputRef.current?.input?.value || "");
        }
      }}
      disabled={isDisabled}
      id={id}
      name={name}
      placeholder={placeholder}
      style={style}
      scale={scale ?? "medium"}
      readOnly={readOnly}
      type={inputType === "email" ? "email" : undefined}
    />
  );
}
