import { IoChevronForwardOutline } from "react-icons/io5";

import { INewOrganizationModalInSelectBreadcrumbProps } from "./interface";
import { StyledBreadcrumb } from "./styles";

export function NewOrganizationModalInSelectBreadcrumb({
  parentTitleModal,
}: INewOrganizationModalInSelectBreadcrumbProps) {
  return (
    <StyledBreadcrumb
      separator={<IoChevronForwardOutline size={12} />}
      items={[
        { title: <span>{parentTitleModal}</span> },
        { title: <span className="active">Nova Organização</span> },
      ]}
    />
  );
}
