import type { ITransformerGroupFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function TransformerGroupFormModal({
  isOpen = false,
  onClose = () => null,
  width = 720,
  formProps = {
    initialValues: undefined,
  },
}: ITransformerGroupFormModalProps) {
  return (
    <Modal
      data-cy="transformer-group-form-modal"
      open={isOpen}
      onCancel={onClose}
      title="Regra do transformador"
      width={width}
      footer={null}
      destroyOnClose
    >
      <Form.TransformerGroup {...formProps} onCancel={onClose} />
    </Modal>
  );
}
