import type {
  IComplementaryItemsSpreadsheetUploadFormProps,
  IComplementaryItemsSpreadsheetUploadFormValues,
} from "./interface";

import { Upload } from "~components/Upload";
import { useFetchUploadComplementaryCostSpreadsheet } from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { Button, Form, Flex } from "antd";
import type {
  RcFile,
  UploadChangeParam,
  UploadFile,
} from "antd/lib/upload/interface";

export function ComplementaryItemsSpreadsheetUploadForm({
  initialValues = {
    file: "",
  },
  onCancel = () => undefined,
  onError = () => undefined,
  onSuccess = () => undefined,
}: IComplementaryItemsSpreadsheetUploadFormProps) {
  const { message } = useAppConfig();

  const [form] = Form.useForm();

  const {
    mutate: uploadComplementaryCostSpreadsheet,
    isLoading: isSendingComplementaryCostSpreadsheet,
  } = useFetchUploadComplementaryCostSpreadsheet({
    options: {
      onSuccess,
      onError,
    },
  });

  function handleSubmitForm() {
    const { file }: IComplementaryItemsSpreadsheetUploadFormValues =
      form.getFieldsValue(true);

    const [, base64] = file.split("base64,");

    uploadComplementaryCostSpreadsheet({
      data: base64,
    });
  }

  function validateFileField() {
    const { file }: IComplementaryItemsSpreadsheetUploadFormValues =
      form.getFieldsValue(true);

    return file ? Promise.resolve() : Promise.reject("Arquivo é obrigatório!");
  }

  function parseToBase64(file: RcFile): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Data = reader.result as string;
        resolve(base64Data);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }

  function handleBeforeUpload(file: RcFile): Promise<string | Blob> {
    return parseToBase64(file);
  }

  function handleRemoveFile() {
    form.setFieldValue("file", undefined);
  }

  async function handleUploadDone(file: RcFile) {
    try {
      const base64 = await parseToBase64(file);

      form.setFields([
        { name: "file", value: base64, errors: [] },
        {
          name: "spreadsheet",
          value: form.getFieldValue("spreadsheet"),
          errors: [],
        },
      ]);
    } catch (error: any) {
      message.error(`Client error: ${error?.message}`);
    }
  }

  function onChangeFileField(event: UploadChangeParam<UploadFile<string>>) {
    const { status, originFileObj } = event.file;

    switch (status) {
      case "done":
        return handleUploadDone(originFileObj as RcFile);
      case "removed":
        return handleRemoveFile();
      default:
        return;
    }
  }

  return (
    <Form
      data-cy="complementary-items-spreadsheet-upload-form"
      form={form}
      onFinish={handleSubmitForm}
      initialValues={initialValues}
      layout="vertical"
      style={{ width: "100%" }}
    >
      <Form.Item
        name="spreadsheet"
        rules={[
          {
            validator: validateFileField,
          },
        ]}
        initialValue={initialValues?.file}
      >
        <Upload.DefaultDragger
          acceptableFileType={["xlsx"]}
          beforeUpload={handleBeforeUpload}
          onChange={onChangeFileField}
        />
      </Form.Item>

      <Flex align="center" gap={8} justify="end">
        <Button onClick={onCancel}>Cancelar</Button>
        <Button
          type="primary"
          htmlType="submit"
          loading={isSendingComplementaryCostSpreadsheet}
        >
          Enviar
        </Button>
      </Flex>
    </Form>
  );
}
