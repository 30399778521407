import styled from "@emotion/styled";
import { Button } from "antd";

export const StyledButton = styled(Button)`
  width: 1.75rem !important;
  height: 1.75rem !important;
  line-height: 0 !important;
  padding: 0 !important;

  &:hover {
    background-color: var(--gray-50) !important;
  }

  &:focus {
    background-color: var(--gray-300) !important;
  }

  html.dark & {
    &:hover {
      background-color: var(--gray-700) !important;
    }

    &:focus {
      background-color: var(--gray-800) !important;
    }
  }
`;
