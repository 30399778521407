import { FaUserShield } from "react-icons/fa";
import { FaUserGroup } from "react-icons/fa6";
import { GoLog } from "react-icons/go";
import {
  MdOutlineDatasetLinked,
  MdOutlineIntegrationInstructions,
  MdOutlineProductionQuantityLimits,
} from "react-icons/md";

import NextLink from "next/link";
import { useRouter } from "next/router";

import type { IAdminMenuProps, AdminMenuOptionType } from "./interface";
import { StyledMenu } from "./styles";

import { useAppConfig } from "~hooks/useAppConfig";
import { useSession } from "~hooks/useSession";
import { Flex, Skeleton } from "antd";

export function AdminMenu(_props: IAdminMenuProps) {
  const { sessionStatus, userIsSuperSysAdmin } = useSession();
  const { screens } = useAppConfig();
  const router = useRouter();

  const routes = router?.asPath
    .split("?")[0]
    .split("/")
    .filter((route) => {
      return route;
    })
    .map((route) => `/${route}`);

  if (sessionStatus !== "authenticated") {
    return (
      <Flex
        vertical={screens.mobile ? false : true}
        gap={24}
        align="center"
        justify={screens.mobile ? "space-between" : "flex-start"}
        style={{ padding: "1rem" }}
      >
        <Skeleton.Avatar active shape="square" />
      </Flex>
    );
  }

  return (
    <StyledMenu
      mobile={screens.mobile}
      mode={screens.mobile ? "horizontal" : "vertical"}
      style={{ height: "100%", borderRight: 0 }}
      selectedKeys={routes.length > 0 ? routes : ["/"]}
      items={
        [
          {
            key: "/gestores",
            hide: !userIsSuperSysAdmin,
            icon: (
              <NextLink href="/admin/gestores">
                <FaUserShield size={24} />
              </NextLink>
            ),
            title: "Admins",
            label: screens.mobile ? null : "Admins",
          },
          {
            key: "/usuarios",
            hide: false,
            icon: (
              <NextLink href="/admin/usuarios">
                <FaUserGroup size={24} />
              </NextLink>
            ),
            title: "Usuários",
            label: screens.mobile ? null : "Usuários",
          },
          {
            key: "/datasheets",
            hide: false,
            icon: (
              <NextLink href="/admin/datasheets">
                <MdOutlineDatasetLinked size={24} />
              </NextLink>
            ),
            title: "Datasheets",
            label: screens.mobile ? null : "Datasheets",
          },
          {
            key: "/produtos",
            hide: false,
            icon: (
              <NextLink href="/admin/produtos">
                <MdOutlineProductionQuantityLimits size={24} />
              </NextLink>
            ),
            title: "Produtos",
            label: screens.mobile ? null : "Produtos",
          },
          {
            key: "/logs",
            hide: false,
            icon: (
              <NextLink href="/admin/logs">
                <GoLog size={24} />
              </NextLink>
            ),
            title: "Logs",
            label: screens.mobile ? null : "Logs",
          },
          {
            key: "/status-das-integracoes",
            hide: false,
            icon: (
              <NextLink href="/admin/status-das-integracoes">
                <MdOutlineIntegrationInstructions size={24} />
              </NextLink>
            ),
            title: "Status das integrações",
            label: screens.mobile ? null : "Status das integrações",
          },
        ].filter((option) => !option.hide) as AdminMenuOptionType[]
      }
    />
  );
}
