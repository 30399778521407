import { useQuery } from "react-query";

import type { IUseFetchGetPipedrivePipelineDeals } from "./interface";

import { fetchGetPipedrivePipelineDeals } from "~api/pipedrive/pipelines/{id}/deals/get";

export const useFetchGetPipedrivePipelineDealsKey =
  "api/pipedrive/pipelines/{pipelineId}/deals";

export function useFetchGetPipedrivePipelineDeals({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetPipedrivePipelineDeals) {
  return useQuery(
    [useFetchGetPipedrivePipelineDealsKey, dependencyArray],
    async () => await fetchGetPipedrivePipelineDeals(payload),
    options,
  );
}
