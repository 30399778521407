import { useEffect, useMemo, useState } from "react";

import type {
  IPersonFormProps,
  PersonFormMappedFieldSettings,
  PersonDefaultFieldsSettingsKeys,
  PersonFormValuesType,
} from "./interface";

import { Alert } from "~components/Alert";
import { Button } from "~components/Button";
import { GoogleMapsProvider, SelectLocation } from "~components/GoogleMaps";
import { Select } from "~components/Select";
import {
  useFetchCreatePerson,
  useFetchGetPersonById,
  useFetchUpdatePerson,
} from "~hooks/api";
import { useFetchGetPersonDefaultFieldsSettings } from "~hooks/api/config/useFetchGetPersonDefaultFieldsSettings";
import { useAppConfig } from "~hooks/useAppConfig";
import { useSession } from "~hooks/useSession";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzFormItem } from "~solarzui/SolarzFormItem";
import { SolarzInput } from "~solarzui/SolarzInput";
import { SolarzInputNumber } from "~solarzui/SolarzInputNumber";
import type { IPersonDTOSchema } from "~types/schemas";
import {
  formatAddressDTO,
  formatStringToBeNumeric,
  formatTelephoneWithDdd,
} from "~utils/format";
import { parseGoogleLocationToAddressForm } from "~utils/parseGoogleLocationToAddressForm";
import { Col, Flex, Form, Row, Spin } from "antd";

function parsePersonDTOToPersonFormValues(
  person: IPersonDTOSchema,
): Partial<PersonFormValuesType> {
  return {
    address: {
      ...person.address,
      city: {
        name: person.address?.city?.name,
      },
      province: {
        name: person.address?.province?.name,
        uf: person.address?.province?.uf,
      },
      formattedAddress: person.address ? formatAddressDTO(person.address) : "",
    },
    email: person.email,
    identifier: person.identifier,
    name: person.name,
    organizationId: person.organization?.id,
    responsibleSellerId: person.owner?.id,
    phone: person.phone ? formatTelephoneWithDdd(person.phone) : undefined,
  };
}

export function PersonForm({
  formInstance,
  initialValues,
  personId = 0,
  displayPipedriveIntegration = false,
  personInTheOrganization = false,
  onCancel,
  onError,
  onSuccess,
}: IPersonFormProps) {
  const { screens } = useAppConfig();
  const { user } = useSession();
  const [form] = Form.useForm<PersonFormValuesType>(formInstance);

  const [selectedPersonId, setSelectedPersonId] = useState<
    number | undefined
  >();

  const {
    data: fieldsSettings,
    isLoading: isLoadingFieldSettings,
    error: fieldSettingsError,
    refetch: reloadFieldSettings,
  } = useFetchGetPersonDefaultFieldsSettings({});

  const mappedFieldsSettings = useMemo(() => {
    return (
      fieldsSettings?.reduce(
        (accumulator, settings) => {
          const { personDefaultField, ...rest } = settings;
          return {
            ...accumulator,
            [personDefaultField?.name || ""]: {
              ...rest,
            },
          };
        },
        {} as Record<
          PersonDefaultFieldsSettingsKeys,
          PersonFormMappedFieldSettings
        >,
      ) ??
      ({} as Record<
        PersonDefaultFieldsSettingsKeys,
        PersonFormMappedFieldSettings
      >)
    );
  }, [fieldsSettings]);

  const { mutate: createPerson, isLoading: isCreatingPerson } =
    useFetchCreatePerson({
      options: {
        onSuccess,
        onError,
      },
    });

  const { mutate: updatePerson, isLoading: isUpdatingPerson } =
    useFetchUpdatePerson({
      options: {
        onSuccess,
        onError,
      },
    });

  const {
    data: initialPersonData,
    isFetching: isLoadingInitialPersonData,
    error: errorInitialPersonData,
    refetch: reloadInitialPersonData,
  } = useFetchGetPersonById({
    payload: {
      personId: personId,
    },
    dependencyArray: [personId],
    options: {
      enabled: personId > 0,
      retry: 1,
    },
  });

  function handleSubmit(formValues: PersonFormValuesType) {
    const parsedPhone = formValues.phone
      ? formatStringToBeNumeric(formValues.phone)
      : undefined;

    const parsedIdentifier =
      formValues.identifier?.trim() === "" ? undefined : formValues.identifier;

    const parsedAddress =
      formValues.address?.city?.name ||
      formValues.address?.province?.name ||
      formValues.address?.street ||
      formValues.address?.zipCode ||
      formValues.address?.number
        ? formValues.address
        : undefined;

    personId
      ? updatePerson({
          ...formValues,
          phone: parsedPhone,
          identifier: parsedIdentifier,
          address: parsedAddress,
          personId,
        })
      : createPerson({
          ...formValues,
          phone: parsedPhone,
          identifier: parsedIdentifier,
          address: parsedAddress,
        });
  }

  function handleCancel() {
    const formInitialValues = initialPersonData
      ? parsePersonDTOToPersonFormValues(initialPersonData)
      : initialValues ?? {};

    form.setFieldsValue(formInitialValues);

    if (typeof onCancel === "function") {
      return onCancel();
    }
  }

  const isLoadingFormData =
    isLoadingInitialPersonData || isLoadingFieldSettings;

  const isSubmittingForm = isCreatingPerson || isUpdatingPerson;

  useEffect(() => {
    if (initialPersonData) {
      const initialPersonFormData =
        parsePersonDTOToPersonFormValues(initialPersonData);

      form.setFieldsValue(initialPersonFormData);
    }
  }, [form, initialPersonData]);

  return (
    <Form
      data-cy="person-form"
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmit}
      preserve
      layout="vertical"
    >
      {errorInitialPersonData && (
        <Alert.CardError
          errorMessage={errorInitialPersonData?.message}
          title="Falha ao carregar dados iniciais"
          reloadFn={reloadInitialPersonData}
          style={{ marginBottom: 16 }}
        />
      )}

      {fieldSettingsError && (
        <Alert.CardError
          errorMessage={fieldSettingsError?.message}
          title="Falha ao carregar configurações dos campos"
          reloadFn={reloadFieldSettings}
          style={{ marginBottom: 16 }}
        />
      )}

      <Spin spinning={isLoadingFormData}>
        {user?.pipedriveIntegration && displayPipedriveIntegration && (
          <div
            className={`flex gap-1 -mx-${screens.mobile ? "2" : "3"} gap-${screens.mobile ? "4" : "6"}`}
          >
            <div className={screens.mobile ? "w-full" : "w-7/12"}>
              <Form.Item
                label="Buscar cliente"
                extra="Digite ao menos 3 caracteres para pesquisar"
                tooltip="Busque um cliente existente para vinculá-lo ao cliente do Pipedrive."
              >
                <Select.PersonToLinkPipedrive
                  placeholder="Digite ao menos 3 caracteres para pesquisar..."
                  onChange={(_, option) => {
                    if (!option) {
                      setSelectedPersonId(undefined);
                    }

                    if (Array.isArray(option)) return;

                    setSelectedPersonId(option?.record.id);
                  }}
                />
              </Form.Item>
            </div>
            <div className={screens.mobile ? "w-full" : "w-5/12"}>
              <Form.Item label=" ">
                <Button.BindPipedriveIdToPerson
                  personId={selectedPersonId}
                  pipedriveId={initialValues?.pipedriveId}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </div>
          </div>
        )}
        <Row gutter={screens.mobile ? 16 : 24}>
          <Col span={screens.mobile ? 24 : 12}>
            <SolarzFormItem<PersonFormValuesType>
              name="name"
              label="Nome"
              hidden={mappedFieldsSettings["name"]?.hidden}
              rules={[
                {
                  required: mappedFieldsSettings["name"]?.required,
                  message: "Nome da pessoa é obrigatório!",
                },
              ]}
            >
              <SolarzInput
                isDisabled={!mappedFieldsSettings["name"]?.editable}
                scale="large"
                placeholder="Ex.: João da Silva"
              />
            </SolarzFormItem>
          </Col>

          <Col span={screens.mobile ? 24 : 12}>
            <SolarzFormItem<PersonFormValuesType>
              name="identifier"
              label="CPF"
              hidden={mappedFieldsSettings["identifier"]?.hidden}
              rules={[
                {
                  required: mappedFieldsSettings["identifier"]?.required,
                  message: "CPF é obrigatório!",
                },
                {
                  min: 14,
                  message: "CPF precisa conter pelo menos 11 caracteres",
                },
              ]}
            >
              <SolarzInput
                isDisabled={!mappedFieldsSettings["identifier"]?.editable}
                inputType="cpf"
                scale="large"
                placeholder="Ex.: 000.000.000-00"
              />
            </SolarzFormItem>
          </Col>
        </Row>

        <SolarzFormItem<PersonFormValuesType>
          name="responsibleSellerId"
          label="Responsável"
          hidden={mappedFieldsSettings["responsibleSeller"]?.hidden}
          rules={[
            {
              required: mappedFieldsSettings["responsibleSeller"]?.required,
              message: "Responsável é obrigatório",
            },
          ]}
        >
          <Select.User
            isDisabled={!mappedFieldsSettings["responsibleSeller"]?.editable}
            scale="large"
            placeholder="Selecione o responsável..."
          />
        </SolarzFormItem>

        <SolarzFormItem<PersonFormValuesType>
          name="organizationId"
          label="Organização"
          hidden={mappedFieldsSettings["organization"]?.hidden}
          rules={[
            {
              required: mappedFieldsSettings["organization"]?.required,
              message: "Organização é obrigatório",
            },
          ]}
        >
          <Select.Organization
            isDisabled={
              !mappedFieldsSettings["organization"]?.editable ||
              personInTheOrganization
            }
            scale="large"
            placeholder="Selecione a organização..."
          />
        </SolarzFormItem>

        <SolarzFormItem label="Buscar endereço">
          <GoogleMapsProvider
            onChangeLocationCallback={(location) => {
              const parsedValue = parseGoogleLocationToAddressForm(location);
              form.setFieldValue("address", parsedValue);
            }}
          >
            <SelectLocation
              style={{
                width: "100%",
                height: 48,
              }}
              placeholder="Procurar endereço com o Google"
            />
          </GoogleMapsProvider>
        </SolarzFormItem>

        <Row gutter={screens.mobile ? 16 : 24}>
          <Col span={screens.mobile ? 16 : 18}>
            <SolarzFormItem<PersonFormValuesType>
              name={["address", "street"]}
              label="Rua"
              hidden={mappedFieldsSettings["street"]?.hidden}
              rules={[
                {
                  required: mappedFieldsSettings["street"]?.required,
                  message: "Rua é obrigatório",
                },
                {
                  max: 255,
                  message: "Rua não pode ter mais de 255 caracteres",
                },
              ]}
            >
              <SolarzInput
                isDisabled={!mappedFieldsSettings["street"]?.editable}
                scale="large"
                placeholder="Ex.: Rua José da Silva"
              />
            </SolarzFormItem>
          </Col>

          <Col span={screens.mobile ? 8 : 6}>
            <SolarzFormItem<PersonFormValuesType>
              name={["address", "number"]}
              label="Número"
              hidden={mappedFieldsSettings["number"]?.hidden}
              rules={[
                {
                  required: mappedFieldsSettings["number"]?.required,
                  message: "É Obrigatório",
                },
              ]}
            >
              <SolarzInputNumber
                scale="large"
                isDisabled={!mappedFieldsSettings["number"]?.editable}
                placeholder="Ex.: 777"
                precision={0}
                min={0}
                onChange={(value) => {
                  const parsedValueString = value?.toString() || null;
                  form.setFieldValue(["address", "number"], parsedValueString);
                }}
              />
            </SolarzFormItem>
          </Col>
        </Row>

        <Row gutter={screens.mobile ? 16 : 24}>
          <Col span={screens.mobile ? 16 : 12}>
            <SolarzFormItem<PersonFormValuesType>
              name={["address", "city", "name"]}
              label="Cidade"
              hidden={mappedFieldsSettings["city"]?.hidden}
              rules={[
                {
                  required: mappedFieldsSettings["city"]?.required,
                  message: "Cidade é obrigatório",
                },
              ]}
            >
              <SolarzInput
                isDisabled={!mappedFieldsSettings["city"]?.editable}
                scale="large"
                placeholder="Ex.: Mossoró"
              />
            </SolarzFormItem>
          </Col>
          <Col span={screens.mobile ? 8 : 6}>
            <SolarzFormItem<PersonFormValuesType>
              name={["address", "province", "name"]}
              label="Estado"
              hidden={mappedFieldsSettings["province"]?.hidden}
              rules={[
                {
                  required: mappedFieldsSettings["province"]?.required,
                  message: "Estado é obrigatório",
                },
              ]}
            >
              <Select.BrazilianState
                style={{ height: 48 }}
                disabled={!mappedFieldsSettings["province"]?.editable}
                placeholder="Ex.: RN"
                onChange={(value) => {
                  form.setFieldValue(["address", "province", "uf"], value);
                }}
              />
            </SolarzFormItem>
          </Col>
          <Col span={screens.mobile ? 24 : 6}>
            <SolarzFormItem<PersonFormValuesType>
              name={["address", "zipCode"]}
              label="CEP"
              hidden={mappedFieldsSettings["zipCode"]?.hidden}
              rules={[
                {
                  required: mappedFieldsSettings["zipCode"]?.required,
                  message: "CEP é obrigatório",
                },
              ]}
            >
              <SolarzInput
                isDisabled={!mappedFieldsSettings["zipCode"]?.editable}
                scale="large"
                placeholder="Ex.: 00000-000"
                inputType="cep"
              />
            </SolarzFormItem>
          </Col>
        </Row>

        <Row gutter={screens.mobile ? 16 : 24}>
          <Col span={screens.mobile ? 24 : 12}>
            <SolarzFormItem<PersonFormValuesType>
              name="phone"
              label="Telefone"
              hidden={mappedFieldsSettings["phone"]?.hidden}
              rules={[
                {
                  required: mappedFieldsSettings["phone"]?.required,
                  message: "Telefone é obrigatório",
                },
              ]}
              style={{ width: "100%" }}
            >
              <SolarzInput
                inputType="telephone"
                isDisabled={!mappedFieldsSettings["phone"]?.editable}
                scale="large"
                placeholder="Ex.: (00) 00000-0000"
              />
            </SolarzFormItem>
          </Col>
          <Col span={screens.mobile ? 24 : 12}>
            <SolarzFormItem<PersonFormValuesType>
              name="email"
              label="Email"
              hidden={mappedFieldsSettings["email"]?.hidden}
              rules={[
                {
                  required: mappedFieldsSettings["email"]?.required,
                  message: "Email é obrigatório",
                },
              ]}
              style={{ width: "100%" }}
            >
              <SolarzInput
                scale="large"
                isDisabled={!mappedFieldsSettings["email"]?.editable}
                placeholder="Ex.: joaodasilva77@mail.com"
              />
            </SolarzFormItem>
          </Col>
        </Row>

        <Flex
          style={{ marginTop: 12 }}
          align="center"
          gap={16}
          justify="flex-end"
        >
          <SolarzButton variant="secondary" scale="tiny" onClick={handleCancel}>
            Cancelar
          </SolarzButton>
          <SolarzButton
            scale="tiny"
            htmlType="submit"
            isDisabled={isLoadingInitialPersonData}
            isLoading={isSubmittingForm}
          >
            {personId ? "Atualizar pessoa" : "Criar pessoa"}
          </SolarzButton>
        </Flex>
      </Spin>
    </Form>
  );
}
