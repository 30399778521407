import { IAddUserFormValues, IAddUserFormProps } from "./interface";

import { GoogleMapsProvider, SelectLocation } from "~components/GoogleMaps";
import { Select } from "~components/Select";
import { useFetchAdminCreateUser } from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { SolarzFormItem } from "~solarzui/SolarzFormItem";
import { SolarzInput } from "~solarzui/SolarzInput";
import { SolarzInputNumber } from "~solarzui/SolarzInputNumber";
import { SolarzInputPassword } from "~solarzui/SolarzInputPassword";
import { Col, Form, Row, Button, Flex } from "antd";

export function AddUserForm({
  onError = () => undefined,
  onSuccess = () => undefined,
  onCancel = () => undefined,
}: IAddUserFormProps) {
  const { screens, message } = useAppConfig();

  const [form] = Form.useForm<IAddUserFormValues>();

  const { mutate: fetchAdminCreateUser, isLoading: isCreateUserLoading } =
    useFetchAdminCreateUser({
      options: {
        onSuccess,
        onError,
      },
    });

  const onSubmit = async (formValues: IAddUserFormValues) => {
    if (!formValues?.address.street) {
      return message.info("Logradouro não encontrado");
    }

    fetchAdminCreateUser({
      ...formValues,
      address: {
        city: {
          name: formValues.address.city,
        },
        province: {
          name: formValues.address.state,
          uf: formValues.address.state,
        },
      },
    });
  };

  return (
    <Form
      data-cy="add-user-form"
      form={form}
      onFinish={onSubmit}
      layout="vertical"
    >
      <Row gutter={[16, 0]}>
        <Col span={!screens.sm ? 24 : 12}>
          <SolarzFormItem<IAddUserFormValues>
            name="email"
            label="Email"
            rules={[{ required: true }, { type: "email", warningOnly: true }]}
          >
            <SolarzInput
              inputType="email"
              placeholder="Ex.: Exemplo@solarz.com.br"
            />
          </SolarzFormItem>
        </Col>
        <Col span={!screens.sm ? 24 : 12}>
          <SolarzFormItem<IAddUserFormValues>
            name="password"
            label="Senha"
            rules={[{ required: true }]}
          >
            <SolarzInputPassword
              style={{
                height: "2.5rem",
              }}
              placeholder="Ex.: Usuário@123"
            />
          </SolarzFormItem>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={!screens.sm ? 24 : 12}>
          <SolarzFormItem<IAddUserFormValues>
            name="companyName"
            label="Nome da empresa"
            rules={[{ required: true }]}
          >
            <SolarzInput placeholder="Ex.: Solarz" />
          </SolarzFormItem>
        </Col>
        <Col span={!screens.sm ? 24 : 12}>
          <SolarzFormItem<IAddUserFormValues>
            name="identifier"
            label="CNPJ"
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject("O campo CNPJ é obrigatório");
                  }

                  if (value.length < 18) {
                    return Promise.reject("Por favor, insira um CNPJ válido!");
                  }

                  return Promise.resolve();
                },
                validateTrigger: "submit",
              },
            ]}
          >
            <SolarzInput
              inputType="cnpj"
              placeholder="Ex.: 00.000.000/0000-00"
            />
          </SolarzFormItem>
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
        <Col span={!screens.md ? 24 : 12}>
          <SolarzFormItem<IAddUserFormValues>
            label="Endereço"
            name="address"
            rules={[{ required: true }]}
          >
            <GoogleMapsProvider
              onChangeLocationCallback={(location) => {
                form.setFieldValue("address", location);
                form.setFieldValue(["address", "city"], location.city);
              }}
            >
              <SelectLocation
                style={{
                  width: "100%",
                }}
                placeholder="Procurar endereço com o Google..."
              />
            </GoogleMapsProvider>
          </SolarzFormItem>
        </Col>
        <Col span={!screens.md ? 24 : 12}>
          <SolarzFormItem<IAddUserFormValues>
            name={["address", "city"]}
            label="Cidade"
            rules={[{ required: true }]}
          >
            <Select.City
              allowClear
              placeholder="Digite 3 letras para buscar..."
            />
          </SolarzFormItem>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={!screens.md ? 24 : 12}>
          <SolarzFormItem<IAddUserFormValues>
            name="maxUsersLicensed"
            label="Quantidade de usuários licenciados"
            tooltip="Indicará o número máximo de colaboradores que o integrador pode ter na conta"
          >
            <SolarzInputNumber min={0} precision={0} placeholder="Ex.: 5" />
          </SolarzFormItem>
        </Col>
        <Col span={!screens.md ? 24 : 12}>
          <SolarzFormItem<IAddUserFormValues>
            name="companyIdForImportSettings"
            label="Criar a partir de um modelo"
            tooltip="Criar a partir do modelo de outro integrador existente"
          >
            <Select.AdminUser placeholder="Digite 3 letras para buscar..." />
          </SolarzFormItem>
        </Col>
      </Row>

      <Flex align="center" gap={8} justify="end">
        <Button onClick={onCancel} disabled={isCreateUserLoading}>
          Cancelar
        </Button>
        <Button loading={isCreateUserLoading} type="primary" htmlType="submit">
          Criar usuário
        </Button>
      </Flex>
    </Form>
  );
}
