import type { IConcessionairesProfileFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function ConcessionairesProfileFormModal({
  isOpen = false,
  onClose = () => null,
  width = 800,
  formProps = {},
}: IConcessionairesProfileFormModalProps) {
  return (
    <Modal
      data-cy="concessionaire-profile-form-modal"
      open={isOpen}
      onCancel={onClose}
      title="Perfil da Concessionária"
      width={width}
      footer={null}
      destroyOnClose
    >
      <Form.ConcessionaireProfile {...formProps} onCancel={onClose} />
    </Modal>
  );
}
