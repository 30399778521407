export function indentTextAreaCode(code: string) {
  const formattedCode = code.replace(/\$\{([^}]+)\}/g, "__$1__");

  const indentedJs = formattedCode
    .replace(/(\{)\n?/g, "$1\n")
    .replace(/\n?(\})/g, "\n$1");

  const newCode = indentedJs.replace(/__(.*?)__/g, "${$1}");

  return newCode;
}

// OLD CODE
// const beautify = require("js-beautify");

// export function indentTextAreaCode(code: string) {
//   const formattedCode = code
//     .replace(/\$\{([^}]+)\}/g, "__$1__")
//     .replace(/return/g, "return ");
//   const indentedJs = beautify(formattedCode, {
//     indent_size: 2,
//     space_in_empty_paren: false,
//   });

//   const newCode = indentedJs.replace(/__(.*?)__/g, "${$1}");

//   return newCode;
// }
