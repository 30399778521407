import type { IDeleteDealCustomFieldGroupFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function DeleteDealCustomFieldGroupFormModal({
  dealCustomFieldGroupId = 0,
  isOpen = false,
  onClose = () => undefined,
  maxWidth = 1024,
  title = "Apagar sessão de variáveis customizadas",
  formProps,
}: IDeleteDealCustomFieldGroupFormModalProps) {
  return (
    <Modal
      data-cy="delete-deal-custom-field-group-form"
      title={title}
      width="90vw"
      style={{
        maxWidth,
      }}
      open={isOpen}
      footer={null}
      onCancel={onClose}
      destroyOnClose
    >
      <Form.DeleteDealCustomFieldGroup
        {...formProps}
        onCancel={onClose}
        dealCustomFieldGroupId={dealCustomFieldGroupId}
      />
    </Modal>
  );
}
