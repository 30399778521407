import { useState } from "react";
import {
  AiOutlineMinusCircle,
  AiOutlinePlus,
  AiOutlineQuestionCircle,
} from "react-icons/ai";

import type { ILaborFormValues, LaborFormProps } from "./interface";

import { CustomInput } from "~components/CustomInput";
import { Mentions } from "~components/Mentions";
import { Select } from "~components/Select";
import { useFetchCreateLabor, useFetchUpdateLabor } from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import type { IRangeDTOSchema } from "~types/schemas";
import {
  Button,
  Col,
  Form,
  Row,
  Select as SelectAntd,
  Divider,
  Radio,
  Space,
  message,
  Typography,
  Tooltip,
  Alert,
  Flex,
} from "antd";

export function LaborForm({
  initialValues,
  onSuccess = () => undefined,
  onError = () => undefined,
  onCancel = () => undefined,
}: LaborFormProps) {
  const { screens } = useAppConfig();

  const [form] = Form.useForm();

  const applyTax = Form.useWatch("notApplyDefaultAdditionalCostTax", form);
  const ranges = Form.useWatch("ranges", form);
  const currentValuePricingRule = Form.useWatch("pricingRule", form);

  const [valueAdditionalCostTaxId, setValueAdditionalCostTaxId] =
    useState<number>();

  const { mutate: fetchCreateLabor, isLoading: isCreatingLabor } =
    useFetchCreateLabor({
      options: {
        onSuccess,
        onError: (error) => onError(error.message),
      },
    });

  const { mutate: fetchEditLabor, isLoading: isUpdatingLabor } =
    useFetchUpdateLabor({
      options: {
        onSuccess,
        onError: (error) => onError(error.message),
      },
    });

  const onFinish = async (formValues: ILaborFormValues) => {
    if (
      formValues.pricingRule !== "EXPRESSAO_PERSONALIZADA" &&
      !formValues.ranges?.length
    ) {
      return message.warning("É necessário ao menos um intervalo!");
    }

    if (!initialValues?.id) {
      fetchCreateLabor({
        ...formValues,
        structureTypes: formValues.structureTypes?.map((item) => ({
          active: !!item.active,
          available: !!item.available,
          belongsToLabor: !!item.belongsToLabor,
          id: item.id,
          subCategory: item.subCategory,
          type: item.type,
        })),
      });
    } else {
      fetchEditLabor({
        ...formValues,
        structureTypes: formValues.structureTypes?.map((item) => ({
          active: !!item.active,
          available: !!item.available,
          belongsToLabor: !!item.belongsToLabor,
          id: item.id,
          subCategory: item.subCategory,
          type: item.type,
        })),
        laborId: initialValues.id,
      });
    }
  };

  function getLastAmount(index: number) {
    if (index > 0) {
      const maxPreviousValue = form.getFieldValue([
        "ranges",
        index - 1,
        "maxValue",
      ]) as number;

      return maxPreviousValue + 1;
    } else {
      return 1;
    }
  }

  function getValidAmount(index: number): number {
    const amount = getLastAmount(index);

    if (!isNaN(amount)) {
      return amount;
    } else {
      return getValidAmount(index - 1);
    }
  }

  const isLoading = isCreatingLabor || isUpdatingLabor;

  return (
    <Form
      data-cy="labor-form"
      form={form}
      initialValues={
        {
          ...initialValues,
          pricingRule: initialValues?.pricingRule || "NUMBER_OF_MODULES",
          notApplyDefaultAdditionalCostTax:
            !initialValues?.notApplyDefaultAdditionalCostTax &&
            initialValues?.additionalCostTaxId
              ? false
              : true,
          additionalCostTaxId: initialValues?.notApplyDefaultAdditionalCostTax
            ? null
            : initialValues?.additionalCostTaxId,
          costAggregatorIds:
            initialValues?.costAggregators?.map(
              (costAggregator) => costAggregator?.id,
            ) ?? [],
          ranges:
            initialValues?.ranges ??
            ([
              {
                maxValue: 1,
                costPerValue: 0,
                minimumCost: 0,
                pricePerKm: 0,
                minimumDistance: 0,
              },
            ] as IRangeDTOSchema[]),
        } as ILaborFormValues
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      layout="horizontal"
      onFinish={onFinish}
      labelAlign="left"
    >
      <Row>
        <Col xs={24} xl={24}>
          <Row gutter={5}>
            <Col xs={24} xl={12}>
              <Form.Item
                label="Nome da tabela"
                name="name"
                rules={[{ required: true }]}
              >
                <CustomInput.Text placeholder="Nome" />
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item
                label="Regra de precificação"
                name="pricingRule"
                rules={[{ required: true }]}
              >
                <SelectAntd
                  options={[
                    {
                      value: "NUMBER_OF_MODULES",
                      label: "Número de módulos",
                    },
                    { value: "POWER", label: "Potência do kit (kWp)" },
                    {
                      value: "EXPRESSAO_PERSONALIZADA",
                      label: "Expressão personalizada",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label="Escolher grupo de agregador de custo"
            name="costAggregatorIds"
            tooltip={
              'Agregadores de custo são utilizados para referenciar grupos de custo dentro de expressões personalizadas. Exemplo: se você tiver 5 custos que somados compõe a mão de obra, você pode indicar que eles pertencem ao grupo de custo "mão de obra" e usar o identificador do grupo para referenciar o somatório desse grupo dentro de uma expressão customizada.'
            }
          >
            <Select.CostAggregator mode="multiple" />
          </Form.Item>

          <Row gutter={4}>
            <Col xs={24} xl={10}>
              <Form.Item
                label="Aplicar Imposto pós precificado"
                tooltip="Alíquota aplicada por fora ao final dos cálculos de precificação, útil quando há alíquotas de imposto diferente nos itens complementares ou quando há custos precificado em % sobre o global e custos em % precificados sobre os custos complementares simultaneamente e ainda seja necessário cálculo de imposto"
                name="notApplyDefaultAdditionalCostTax"
              >
                <Radio.Group
                  onChange={(e) => {
                    if (e.target.value === true) {
                      form.resetFields(["additionalCostTaxId"]);
                      setValueAdditionalCostTaxId(undefined);
                    }
                  }}
                >
                  <Space>
                    <Radio value={false}>Sim</Radio>
                    <Radio value={true}>Não aplicar</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} xl={14}>
              <Form.Item
                label="Escolher imposto pós precificado"
                rules={[{ required: !applyTax }]}
                name="additionalCostTaxId"
              >
                <Select.TaxesAfterPricing
                  value={valueAdditionalCostTaxId}
                  onChange={(e) => setValueAdditionalCostTaxId(e)}
                  isEditForm
                  disabled={applyTax}
                  applyTax={applyTax}
                />
              </Form.Item>
            </Col>
          </Row>
          {currentValuePricingRule !== "EXPRESSAO_PERSONALIZADA" && (
            <>
              <Divider orientation="left">
                <Typography.Title level={5}>Intervalos</Typography.Title>
              </Divider>

              <Col xs={24}>
                <Form.List name="ranges">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        {fields.map(({ key, name, ...restField }, index) => (
                          <Row
                            key={key}
                            gutter={12}
                            style={{ textAlign: "left" }}
                          >
                            <Col xs={24} md={12} lg={5}>
                              <Form.Item
                                {...restField}
                                label={
                                  currentValuePricingRule === "POWER"
                                    ? "Potência do kit (kWp)"
                                    : "Número de módulos"
                                }
                                name={[name, "maxValue"]}
                                rules={[{ required: true }]}
                              >
                                <CustomInput.Number
                                  prefix={`De ${getValidAmount(index)} até `}
                                  min={getValidAmount(index)}
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={12} md={12} lg={5}>
                              <Form.Item
                                {...restField}
                                name={[name, "costPerValue"]}
                                label={
                                  currentValuePricingRule === "POWER"
                                    ? "Valor por kWp"
                                    : "Valor por módulo"
                                }
                                rules={[{ required: true }]}
                              >
                                <CustomInput.Money min={0} />
                              </Form.Item>
                            </Col>

                            <Col xs={12} md={8} lg={5}>
                              <Form.Item
                                {...restField}
                                name={[name, "minimumCost"]}
                                label="Valor mínimo"
                                rules={[{ required: true }]}
                              >
                                <CustomInput.Money min={0} />
                              </Form.Item>
                            </Col>

                            <Col xs={11} md={7} lg={4}>
                              <Form.Item
                                {...restField}
                                name={[name, "pricePerKm"]}
                                label="Preço por Km"
                                rules={[{ required: true }]}
                              >
                                <CustomInput.Money min={0} />
                              </Form.Item>
                            </Col>

                            <Col xs={12} md={8} lg={4}>
                              <Form.Item
                                {...restField}
                                name={[name, "minimumDistance"]}
                                label="Distância min."
                                rules={[{ required: true }]}
                                tooltip="Distância mínima para iniciar a cobrança de preço por Km"
                              >
                                <CustomInput.UnitMeasurement
                                  precision="1"
                                  unitMeasurement="Km"
                                  min={0}
                                />
                              </Form.Item>
                            </Col>

                            <Col
                              xs={1}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Button
                                disabled={
                                  fields.length === 1 ||
                                  index < fields.length - 1
                                }
                                type="text"
                                onClick={() => remove(name)}
                                icon={<AiOutlineMinusCircle size={16} />}
                                style={{ marginTop: "1.2rem" }}
                              />
                            </Col>
                            {!screens.lg && <Divider />}
                          </Row>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() =>
                              add({
                                minimumCost: 0,
                                minimumDistance: 0,
                                pricePerKm: 0,
                                costPerValue: 0,
                              } as IRangeDTOSchema)
                            }
                            block
                            disabled={
                              (initialValues?.ranges &&
                                initialValues.ranges[
                                  initialValues.ranges.length - 1
                                ]?.maxValue == undefined) ||
                              !!form.getFieldError([
                                "ranges",
                                fields.length - 2,
                                "maxValue",
                              ])[0] ||
                              !(
                                Number(ranges?.[ranges?.length - 1]?.maxValue) >
                                0
                              )
                            }
                          >
                            <Flex
                              justify="center"
                              align="center"
                              wrap="nowrap"
                              gap={4}
                            >
                              <AiOutlinePlus style={{ marginTop: -2 }} />
                              Novo intervalo
                            </Flex>
                          </Button>
                        </Form.Item>
                      </>
                    );
                  }}
                </Form.List>
              </Col>
            </>
          )}
          {currentValuePricingRule === "EXPRESSAO_PERSONALIZADA" && (
            <Col xs={24} xl={24}>
              <Form.Item
                name="customExpression"
                label={
                  <Flex gap={0} align="center">
                    <span>Expressão personalizada</span>

                    <Tooltip title="Trecho de código javascript.">
                      <AiOutlineQuestionCircle
                        size={18}
                        style={{ marginLeft: 4 }}
                      />
                    </Tooltip>
                  </Flex>
                }
                help="Para expressões javascript com condicionais o 'return' deve ser informado,e não utilizar loopings."
              >
                <Alert
                  message='Para a utilização de números decimais utilize "." ao invés de "," (ponto ao invés de virgula)'
                  type="info"
                  style={{ marginBottom: 8 }}
                />
                <Mentions.Variables
                  initialValueField={initialValues?.customExpression}
                  rows={7}
                  onChange={(value) =>
                    form.setFieldValue("customExpression", value)
                  }
                />
              </Form.Item>
            </Col>
          )}

          <Flex align="center" gap={8} justify="end">
            <Button onClick={onCancel}>Voltar</Button>
            <Button loading={isLoading} type="primary" htmlType="submit">
              Salvar
            </Button>
          </Flex>
        </Col>
      </Row>
    </Form>
  );
}
