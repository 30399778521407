import type { ISelectPersonFormModalProps } from "./interface";

import { Breadcrumb } from "~components/Breadcrumb";
import { Form } from "~components/Form";
import { Modal } from "antd";

export function SelectPersonFormModal({
  isOpen = false,
  onClose = () => undefined,
  maxWidth = 820,
  parentTitleModal = "",
  formProps,
}: ISelectPersonFormModalProps) {
  return (
    <Modal
      data-cy="select-person-form-modal"
      width="90vw"
      style={{
        maxWidth,
      }}
      title={
        <Breadcrumb.NewPersonModalInSelect
          parentTitleModal={parentTitleModal}
        />
      }
      open={isOpen}
      footer={null}
      onCancel={onClose}
      destroyOnClose
    >
      <Form.Person {...formProps} onCancel={onClose} />
    </Modal>
  );
}
