import styled from "@emotion/styled";
import { Card, Timeline } from "antd";

export const StyledTimeline = styled(Timeline)`
  .ant-timeline-item-head {
    background-color: transparent !important;
  }
`;

export const StyledCard = styled(Card)`
  background-color: var(--gray-100);

  html.dark & {
    background-color: var(--gray-900);
  }
`;
