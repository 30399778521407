import { useState } from "react";
import { AiFillEdit, AiOutlineDelete } from "react-icons/ai";

import { IProposalExpirationRuleListProps } from "./interface";

import { Menu } from "~components/Menu";
import { Modal } from "~components/Modal";
import { Result } from "~components/Result";
import {
  useFetchDeleteProposalExpirationRule,
  useFetchGetProposalExpirationRule,
} from "~hooks/api";
import { IProposalExpirationRuleDTO } from "~types/schemas";
import { List } from "antd";

function formatPeriodsString(periods: Record<string, number>): string {
  const formattedPeriods = Object.entries(periods).map(
    ([potency, value]) => `Potência ${potency}: ${value} dias`,
  );

  return formattedPeriods.join(", ");
}

export function ProposalExpirationRuleList(
  props: IProposalExpirationRuleListProps,
) {
  const [expirationRuleToUpdate, setExpirationRuleToUpdate] =
    useState<IProposalExpirationRuleDTO>();
  const [expirationRuleToDelete, setExpirationRuleToDelete] =
    useState<IProposalExpirationRuleDTO>();

  const {
    data: proposalExpirationRule,
    isFetching,
    error,
  } = useFetchGetProposalExpirationRule({});

  const {
    mutate: handleDeleteExpirationRule,
    isLoading: isDeletingExpirationRule,
  } = useFetchDeleteProposalExpirationRule({
    onSuccess: () => setExpirationRuleToDelete(undefined),
  });

  return (
    <>
      <List<IProposalExpirationRuleDTO>
        data-cy="proposal-expiration-rule-list"
        {...props}
        itemLayout="horizontal"
        dataSource={proposalExpirationRule}
        loading={isFetching}
        rowKey="id"
        locale={{
          emptyText: (
            <Result.TableErrorOrEmpty
              emptyMessage="Nenhum regra de expiração da proposta encontrada"
              errorMessage={error?.message}
            />
          ),
        }}
        renderItem={(rule) => (
          <List.Item style={{ width: "100%" }}>
            <List.Item.Meta
              title={`Regra de expiração da proposta`}
              description={formatPeriodsString(rule?.periods ?? {})}
            />

            <Menu.MoreDropdown
              items={[
                {
                  icon: <AiFillEdit size={16} color="var(--gold-500)" />,
                  text: "Alterar regra de expiração da proposta",
                  color: "var(--gold-500)",
                  onClick: () => setExpirationRuleToUpdate(rule),
                },
                {
                  icon: <AiOutlineDelete size={16} color="var(--red-500)" />,
                  text: "Remover regra de expiração da proposta",
                  color: "var(--red-500)",
                  onClick: () => setExpirationRuleToDelete(rule),
                },
              ]}
              position="bottomLeft"
            />
          </List.Item>
        )}
      />

      <Modal.DefaultRemove
        isLoading={isDeletingExpirationRule}
        isOpen={!!expirationRuleToDelete}
        onClose={() => setExpirationRuleToDelete(undefined)}
        suffix="regra de expiração da proposta"
        onSubmit={() =>
          handleDeleteExpirationRule({
            ProposalExpirationRuleId: expirationRuleToDelete?.id ?? 0,
          })
        }
        title="Remover regra"
      />

      <Modal.ProposalExpirationRuleForm
        isOpen={!!expirationRuleToUpdate}
        onClose={() => setExpirationRuleToUpdate(undefined)}
        formProps={{
          initialValues: expirationRuleToUpdate,
          onSubmitSuccess: () => setExpirationRuleToUpdate(undefined),
        }}
      />
    </>
  );
}
