import styled from "@emotion/styled";
import { Card } from "antd";

export const StyledCard = styled(Card)`
  width: 493px;
  height: 100%;
  min-height: 180px;
  padding: 24px 24px;
  gap: 24px;
  border-radius: 8px;
  border: 1px solid var(--gray-300) !important;
  opacity: 0px;

  html.dark & {
    border: 1px solid var(--gray-600) !important;
  }
`;
