export * from "./ACMaterialList/interface";
import { ACMaterialList } from "./ACMaterialList";
export * from "./BaseAddressList/interface";
import { BaseAddressList } from "./BaseAddressList";
export * from "./ComplementaryItemsOfSettingsList/interface";
import { ComplementaryItemsOfSettingsList } from "./ComplementaryItemsOfSettingsList";
export * from "./ConcessionaireProfilesList/interface";
import { ConcessionaireProfilesList } from "./ConcessionaireProfilesList";
export * from "./CostAggregatorList/interface";
import { CostAggregatorList } from "./CostAggregatorList";
export * from "./DealCustomFieldsList/interface";
import { DealCustomFieldsList } from "./DealCustomFieldsList";
export * from "./FinancialList/interface";
import { FinancialList } from "./FinancialList";
export * from "./IntegrationList/interface";
import { IntegrationList } from "./IntegrationList";
export * from "./OrganizationDealsList/interface";
import { OrganizationDealsList } from "./OrganizationDealsList";
export * from "./PaymentSimulationsList/interface";
import { PaymentSimulationsList } from "./PaymentSimulationsList";
export * from "./PerformanceProfilesList/interface";
import { PerformanceProfilesList } from "./PerformanceProfilesList";
export * from "./PersonDealsList/interface";
import { PersonDealsList } from "./PersonDealsList";
export * from "./PipedriveUsersList/interface";
import { PipedriveUsersList } from "./PipedriveUsersList";
export * from "./PricingList/interface";
import { PricingList } from "./PricingList";
export * from "./ProductsImportHistoryList/interface";
import { ProductsImportHistoryList } from "./ProductsImportHistoryList";
export * from "./ProfilesPermissionsList/interface";
import { ProfilesPermissionsList } from "./ProfilesPermissionsList";
export * from "./ProfileUsersList/interface";
import { ProfileUsersList } from "./ProfileUsersList";
export * from "./ProposalDealList/interface";
import { ProposalDealList } from "./ProposalDealList";
export * from "./ProposalExpirationRuleList/interface";
import { ProposalExpirationRuleList } from "./ProposalExpirationRuleList";
export * from "./ProposalHistoryList/interface";
import { ProposalHistoryList } from "./ProposalHistoryList";
export * from "./ProposalViewsList/interface";
import { ProposalViewsList } from "./ProposalViewsList";
export * from "./SimultaneityProfilesList/interface";
import { SimultaneityProfilesList } from "./SimultaneityProfilesList";
export * from "./TransformGroupRuleList/interface";
import { TransformGroupRuleList } from "./TransformGroupRuleList";
export * from "./UserGroupList/interface";
import { UserGroupList } from "./UserGroupList";
export * from "./UserProfilesList/interface";
import { UserProfilesList } from "./UserProfilesList";
export * from "./ValidationRulesList/interface";
import { ValidationRulesList } from "./ValidationRulesList";

export const List = {
  TransformGroupRule: TransformGroupRuleList,
  BaseAddress: BaseAddressList,
  ConcessionaireProfiles: ConcessionaireProfilesList,
  CostAggregator: CostAggregatorList,
  DealCustomFields: DealCustomFieldsList,
  Financial: FinancialList,
  Integration: IntegrationList,
  OrganizationDeals: OrganizationDealsList,
  PipedriveUsers: PipedriveUsersList,
  Pricing: PricingList,
  ProductsImportHistory: ProductsImportHistoryList,
  ProfilesPermissions: ProfilesPermissionsList,
  ProfileUsers: ProfileUsersList,
  ProposalExpirationRule: ProposalExpirationRuleList,
  SimultaneityProfiles: SimultaneityProfilesList,
  ACMaterial: ACMaterialList,
  ComplementaryItemsOfSettings: ComplementaryItemsOfSettingsList,
  UserProfiles: UserProfilesList,
  ValidationRules: ValidationRulesList,
  PaymentSimulations: PaymentSimulationsList,
  ProposalDeal: ProposalDealList,
  ProposalViews: ProposalViewsList,
  ProposalHistory: ProposalHistoryList,
  PerformanceProfiles: PerformanceProfilesList,
  PersonDeals: PersonDealsList,
  UserGroup: UserGroupList,
};
