import { useMemo } from "react";
import { GoPencil } from "react-icons/go";

import { useRouter } from "next/router";

import {
  OrganizationTableQueryParamKeysEnum,
  type IOrganizationTableProps,
  type OrganizationTableFiltersType,
  type OrganizationTableQueryParamsKeysType,
  type OrganizationTableDataType,
} from "./interface";
import {
  DeleteButton,
  EditButton,
  OrganizationIcon,
  TableWrapper,
} from "./styles";

import { Modal } from "~components/Modal";
import { Delete, Company } from "~estrela-ui/icons";
import { useFetchGetPaginatedOrganization } from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { useQueryParams, parseFunctions } from "~hooks/useQueryParams";
import { useSession } from "~hooks/useSession";
import { SolarzAnchor } from "~solarzui/SolarzAnchor";
import { SolarzTable } from "~solarzui/SolarzTable";
import { formatCnpj, formatTelephoneWithDdd } from "~utils/format";
import { Flex } from "antd";

const MODAL_QUERY_PARAM_KEY = "update-organization";
const DELETE_MODAL_QUERY_KEY = "delete-organization";

export function OrganizationTable(props: IOrganizationTableProps) {
  const router = useRouter();
  const { screens } = useAppConfig();
  const { user } = useSession();
  const { getParsedQueryParams, handleSaveInQueryParams } = useQueryParams();

  const selectedOrganizationIdToUpdate =
    getParsedQueryParams(MODAL_QUERY_PARAM_KEY, parseFunctions.NUMBER) || 0;

  const selectedOrganizationIdToDelete = getParsedQueryParams(
    DELETE_MODAL_QUERY_KEY,
    parseFunctions.NUMBER,
  );

  const QUERY_PARAMS_KEYS: OrganizationTableQueryParamsKeysType = useMemo(
    () => ({
      ...OrganizationTableQueryParamKeysEnum,
      ...props.queryParamKeys,
    }),
    [props.queryParamKeys],
  );

  const filters: OrganizationTableFiltersType = useMemo(
    () => ({
      currentPage:
        getParsedQueryParams(
          QUERY_PARAMS_KEYS.CURRENT_PAGE,
          parseFunctions.NUMBER,
        ) ?? 1,
      query:
        decodeURIComponent(
          getParsedQueryParams(
            QUERY_PARAMS_KEYS.QUERY,
            parseFunctions.STRING,
          ) ?? "",
        ) || undefined,
      userId:
        getParsedQueryParams(
          QUERY_PARAMS_KEYS.USER_ID,
          parseFunctions.NUMBER,
        ) || undefined,
    }),
    [getParsedQueryParams, QUERY_PARAMS_KEYS],
  );

  function handleOpenUpdateOrganizationModal(organizationId: number) {
    handleSaveInQueryParams({ [MODAL_QUERY_PARAM_KEY]: organizationId });
  }

  function handleCloseUpdateOrganizationModal() {
    handleSaveInQueryParams({ [MODAL_QUERY_PARAM_KEY]: "" });
  }

  function handleOpenDeleteOrganizationModal(organizationId: number) {
    handleSaveInQueryParams({ [DELETE_MODAL_QUERY_KEY]: organizationId });
  }

  function handleCloseDeleteOrganizationModal() {
    handleSaveInQueryParams({ [DELETE_MODAL_QUERY_KEY]: "" });
  }

  const {
    data: companies,
    isFetching: isLoadingCompanies,
    error: companiesError,
    refetch: reloadCompanies,
  } = useFetchGetPaginatedOrganization({
    dependencyArray: [filters],
    options: {
      retry: 1,
      enabled: filters.currentPage > 0,
    },
    payload: {
      page: filters.currentPage - 1,
      size: 10, // STATIC,
      query: filters.query,
      userId: filters.userId,
    },
  });

  return (
    <TableWrapper>
      <SolarzTable<OrganizationTableDataType>
        data-cy="organization-table"
        columns={[
          {
            title: "NOME",
            dataIndex: "name",
            key: "name",
            width: screens.desktop ? 288 : 182,
            render: (_, record) => {
              if (
                (screens.desktop || screens.tablet) &&
                !user?.pipedriveIntegration
              ) {
                return (
                  <SolarzAnchor
                    isNextLink
                    href={`/clientes/empresa/${record.id}`}
                  >
                    {record.name}
                  </SolarzAnchor>
                );
              }

              return (
                <Flex align="center" gap={8} flex={1}>
                  <OrganizationIcon>
                    <Company />
                  </OrganizationIcon>
                  {record.name}
                </Flex>
              );
            },
            onCell:
              screens.mobile && !user?.pipedriveIntegration
                ? (record) => {
                    return {
                      onClick: () =>
                        router.push(`/clientes/empresa/${record.id}`),
                    };
                  }
                : undefined,
          },
          {
            title: "CNPJ",
            dataIndex: "cnpj",
            key: "cnpj",
            width: 180,
            render: (_, record) => formatCnpj(record.cnpj),
            hidden: !screens.desktop,
          },
          {
            title: "ENDEREÇO",
            dataIndex: "address",
            key: "address",
            width: 580,
            render: (_, record) => record.address?.formattedAddress ?? "-",
            hidden: screens.mobile,
          },
          {
            title: "TELEFONE",
            dataIndex: "phone",
            key: "phone",
            width: 180,
            hidden: !screens.desktop,
            render: (_, record) => formatTelephoneWithDdd(record.phone) ?? "-",
          },
          {
            title: "PESSOAS",
            dataIndex: "clientCount",
            key: "clientCount",
            align: "center",
            width: 92,
            render: (_, record) => record.clientCount ?? "-",
            hidden: !screens.desktop,
          },
          {
            width: 80,
            render: (_, record) => {
              return (
                <Flex align="center" gap={12}>
                  <EditButton
                    icon={<GoPencil />}
                    onClick={() => {
                      handleOpenUpdateOrganizationModal(record.id);
                    }}
                    scale="tiny"
                    variant="link"
                  />
                  <DeleteButton
                    icon={<Delete />}
                    onClick={() => {
                      handleOpenDeleteOrganizationModal(record.id);
                    }}
                    scale="tiny"
                    variant="link"
                  />
                </Flex>
              );
            },
            hidden: !screens.desktop,
          },
        ]}
        rows={companies?.content ?? []}
        pagination={{
          currentItemCount: companies?.size ?? 0,
          currentPage: filters.currentPage || 1,
          itemLabel: "organizações",
          totalItemCount: companies?.totalElements ?? 0,
          onChange: (currentPage) => {
            handleSaveInQueryParams({
              [QUERY_PARAMS_KEYS.CURRENT_PAGE]: currentPage,
            });
          },
        }}
        scroll={
          screens.mobile
            ? {
                x: undefined,
                y: "calc(100vh - 326px)",
              }
            : {
                x: screens.desktop ? 1200 : 616,
              }
        }
        emptyMessage="Não foi encontrado nenhuma organização"
        rowKey="id"
        errorMessage={companiesError?.message}
        reloadFn={reloadCompanies}
        isLoading={isLoadingCompanies}
        showHeader={!screens.mobile}
        rowClassName={screens.desktop ? undefined : "cursor-pointer"}
      />

      <Modal.OrganizationForm
        isOpen={selectedOrganizationIdToUpdate > 0}
        title="Atualizar organização"
        onClose={handleCloseUpdateOrganizationModal}
        formProps={{
          onSuccess: handleCloseUpdateOrganizationModal,
          organizationId: selectedOrganizationIdToUpdate,
        }}
      />

      <Modal.DeleteOrganization
        organizationId={selectedOrganizationIdToDelete ?? 0}
        isOpen={Number(selectedOrganizationIdToDelete) > 0}
        onClose={handleCloseDeleteOrganizationModal}
      />
    </TableWrapper>
  );
}
