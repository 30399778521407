import { useMemo } from "react";

import type {
  IStructureGroupSelectProps,
  IStructureGroupOption,
} from "./interface";

import { Select } from "antd";

export function StructureGroupSelect({
  value,
  onChange,
  defaultValue,
  disabledValues,
  hideValues,
  ...props
}: IStructureGroupSelectProps) {
  const OPTIONS: IStructureGroupOption[] = useMemo(
    () =>
      [
        {
          label: "Kit" as const,
          value: "KIT" as const,
          disabled: disabledValues?.KIT,
          isHidden: hideValues?.KIT,
        },
        {
          label: "Custos complementares" as const,
          value: "CUSTOS_COMPLEMENTARES" as const,
          disabled: disabledValues?.CUSTOS_COMPLEMENTARES,
          isHidden: hideValues?.CUSTOS_COMPLEMENTARES,
        },
      ].filter((option) => !option.isHidden),
    [disabledValues, hideValues],
  );

  return (
    <Select
      {...props}
      options={OPTIONS}
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
}
