import { useCallback, useEffect, useMemo, useState } from "react";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineQuestionCircle,
} from "react-icons/ai";
import { FiAlertTriangle } from "react-icons/fi";

import type {
  IAneelModalState,
  IProposalSizingStepFormInitialValues,
  ISizingStepFieldSettings,
  ISizingStepFormProps,
  ISizingStepFormValues,
  ProposalSizingStepCustomField,
} from "./interface";

import { Button } from "~components/Button";
import { CustomInput } from "~components/CustomInput";
import { InputTextArea } from "~components/CustomInput/TextArea";
import { Modal } from "~components/Modal";
import { MonthFormModal } from "~components/Modal/MonthFormModal";
import { ProposalCustomFieldsForm } from "~components/Others";
import { Select } from "~components/Select";
import { ENV } from "~configs/Env";
import {
  PROPOSAL_DATA_HAS_CHANGED,
  SIZING_STEP_STORAGE_KEY,
} from "~configs/ProposalConfig";
import {
  useFetchUpdateSizingStep,
  useFetchGetConcessionaireProfile,
  useFetchGetStepTwoAutoComplete,
  useFetchPostToGetEstimatedPowerPlant,
  useFetchGetStructureTypes,
  useFetchGetPaginatedDistributors,
  useFetchGetProposalExpirationRules,
  useFetchUpdateMonthlyPerformanceRateByCustomId,
  useFetchGetAllProposalDefaultFieldSettings,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { useInterval } from "~hooks/useInterval";
import { useProposal } from "~hooks/useProposal";
import { useSession } from "~hooks/useSession";
import { ElectricUtilityProfileDTO } from "~types/api/ElectricUtilityProfileDTO";
import { StepTwoForm } from "~types/api/StepTwoForm";
import { IrradiationTypeEnum } from "~types/enum";
import { IMonthlyPerformanceRate } from "~types/schemas";
import { getBase64 } from "~utils/getBase64";
import { getFieldReadonlyStyle } from "~utils/getFieldReadonlyStyle";
import { parsePotency } from "~utils/parse";
import {
  Button as AntdButton,
  Col,
  Divider,
  Form,
  Row,
  Select as AntdSelect,
  Typography,
  Layout,
  Spin,
  message,
  Space,
  notification,
  Tooltip,
  Radio,
  Flex,
  DatePicker,
  Alert,
} from "antd";
import { RcFile } from "antd/lib/upload/interface";
import dayjs, { Dayjs } from "dayjs";

export function ProposalSizingStepForm({
  initialValues,
  proposalId,
  customFieldsInitialValues,
  cancelText,
  installationProvinceId,
  voltageType,
  averageConsumption: initialAverageConsumption,
  averageConsumptionP: initialAverageConsumptionP,
  averageConsumptionFP: initialAverageConsumptionFP,
  onSuccess = () => undefined,
  onError = () => undefined,
  onCancel = () => undefined,
  lastStep,
  onHandleStepChange = () => undefined,
  cannotEditPerformanceRate = false,
  cannotEditSimultaneityFactor = false,
  notifyCityWasChanged = false,
  onCloseSolarimetryModal,
  onCloseCityWasChangedNotification,
}: ISizingStepFormProps) {
  const { screens, currentTheme } = useAppConfig();
  const {
    userHavePermission,
    userIsAdmin,
    userHaveIntegrationWithDistributor,
  } = useSession();
  const { proposalState, steps } = useProposal();

  const [api, contextHolder] = notification.useNotification();

  const cannotEditProposalExpiration =
    !userIsAdmin && userHavePermission("PROPOSAL_EXPIRATION_READONLY");

  const [solarimetryModalOpen, setSolarimetryModalOpen] = useState(false);

  const [hasDirectlyInserted, setHasDirectlyInserted] =
    useState<boolean>(false);

  const handleOpenNotificationToRedefineSolarimetry = useCallback(() => {
    api.open({
      message: "Redefinir solarimetria?",
      description:
        "Você alterou a cidade no passo de dados, deseja redefinir a solarimetria?",
      key: "SOLARIMETRY_CHANGE_NOTIFICATION",
      duration: 0,
      onClose: () => {
        if (typeof onCloseCityWasChangedNotification === "function") {
          onCloseCityWasChangedNotification();
        }

        api.destroy();
      },
      onClick: () => null,
      type: "warning",
      btn: (
        <Space>
          <AntdButton
            type="link"
            size="small"
            onClick={() => {
              if (typeof onCloseCityWasChangedNotification === "function") {
                onCloseCityWasChangedNotification();
              }

              api.destroy();
            }}
          >
            Cancelar
          </AntdButton>
          <AntdButton
            type="primary"
            size="small"
            onClick={() => {
              setSolarimetryModalOpen(true);
              api.destroy();
            }}
          >
            Redefinir
          </AntdButton>
        </Space>
      ),
    });
  }, [api, onCloseCityWasChangedNotification]);

  useEffect(() => {
    if (notifyCityWasChanged) {
      handleOpenNotificationToRedefineSolarimetry();
    }
  }, [notifyCityWasChanged, handleOpenNotificationToRedefineSolarimetry]);

  // OLD
  const [
    isConcessionaireProfileModalOpen,
    setIsConcessionaireProfileModalOpen,
  ] = useState(false);
  // NEW ANEEL LIST
  const [aneelModalState, setAneelModalState] = useState<IAneelModalState>({
    isOpen: false,
    voltageType: "LOW",
  });

  const [form] = Form.useForm<ISizingStepFormValues>();

  const [settingsFields, setSettingsFields] = useState<
    ISizingStepFieldSettings[]
  >([]);

  const idStructureType = Form.useWatch("idStructureType", form);

  const idLabor = Form.useWatch("idLabor", form);

  const expirationDate = Form.useWatch("expirationDate", {
    preserve: true,
    form: form,
  }) as Dayjs | undefined;

  const isMonthToMonthActive = Form.useWatch("isMonthToMonthActive", {
    form: form,
    preserve: true,
  });

  const isMonthToMonthPActive = Form.useWatch("isMonthToMonthPActive", {
    form: form,
    preserve: true,
  });

  const isMonthToMonthFPActive = Form.useWatch("isMonthToMonthFPActive", {
    form: form,
    preserve: true,
  });

  const [irradiationTypeState, setIrradiationTypeState] =
    useState<IrradiationTypeEnum>(initialValues?.irradiationType || "DAILY");

  const [
    typeOfIrradiationReturnedAfterUpdate,
    setTypeOfIrradiationReturnedAfterUpdate,
  ] = useState<IrradiationTypeEnum | undefined>(initialValues?.irradiationType);

  const handleSetIrradiationTypeState = (newValue: IrradiationTypeEnum) => {
    setIrradiationTypeState(newValue);
  };

  const [currentPerformance, setCurrentPerformance] = useState<number>(
    initialValues?.performanceRate ?? 0,
  );

  const handleSetCurrentPerformance = (newValue = 0) => {
    setCurrentPerformance(newValue);
    form.setFieldsValue({ performanceRate: newValue });
  };

  const [concessionaireProfile, setConcessionaireProfile] =
    useState<ElectricUtilityProfileDTO>();

  // MODAL STATE MONTH BY MONTH
  const [isMonthConsumptionModalOpen, setIsMonthConsumptionModalOpen] =
    useState<boolean>(false);
  const [isMonthConsumptionPModalOpen, setIsMonthConsumptionPModalOpen] =
    useState<boolean>(false);
  const [isMonthConsumptionFPModalOpen, setIsMonthConsumptionFPModalOpen] =
    useState<boolean>(false);

  // WATCHERS
  const tensionType = Form.useWatch("tensionType", form) as
    | "LOW"
    | "MEDIUM"
    | undefined;

  const electricUtilityId: number | undefined = Form.useWatch(
    "electricUtilityId",
    form,
  );

  const electricUtilityName: string | undefined = Form.useWatch(
    "electricUtilityName",
    {
      form,
      preserve: true,
    },
  );

  const taxType = Form.useWatch("taxType", form) as
    | "GREEN"
    | "BLUE"
    | undefined;

  const averageConsumption = Form.useWatch("averageConsumption", form);
  const averageConsumptionP = Form.useWatch("averageConsumptionP", form);
  const averageConsumptionFP = Form.useWatch("averageConsumptionFP", form);

  const consumptionUnits = Form.useWatch("consumptionUnits", {
    form,
    preserve: true,
  });

  const compensationType = Form.useWatch("compensationType", {
    form,
    preserve: true,
  });

  const allocationPercentage = Form.useWatch("allocationPercentage", {
    form,
    preserve: true,
  });

  const generationFactor = Form.useWatch("generationFactor", {
    form,
    preserve: true,
  });

  const powerPhase = Form.useWatch("powerPhase", {
    form,
    preserve: true,
  });

  const teFP = Form.useWatch("teFP", {
    form,
    preserve: true,
  });

  const teP = Form.useWatch("teP", {
    form,
    preserve: true,
  });

  const expectedSurplusEnergy = Form.useWatch("expectedSurplusEnergy", {
    form,
    preserve: true,
  });

  const considerConsumptionUnits = Form.useWatch("considerConsumptionUnits", {
    form,
    preserve: true,
  });

  const userCanNotChangeStructureGroup =
    !userIsAdmin && userHavePermission("NOT_EDIT_STRUCTURE_GROUP");

  const [needGetNewExpirationDate, setNeedGetNewExpirationDate] =
    useState(false);

  const {
    data: estimatedPowerPlant,
    isFetching: isLoadingEstimatedPowerPlant,
    refetch: refecthEstimatedPowerPlant,
    error: estimatedPowerPlantError,
  } = useFetchPostToGetEstimatedPowerPlant({
    dependencyArray: [
      generationFactor,
      currentPerformance,
      tensionType,
      averageConsumption,
      averageConsumptionP,
      averageConsumptionFP,
      powerPhase,
      teFP,
      teP,
      expectedSurplusEnergy,
      considerConsumptionUnits,
      consumptionUnits,
    ],
    payload: {
      generationFactor: generationFactor || 0,
      performanceRate: currentPerformance || 0,
      lowVoltageForm: tensionType === "LOW",
      mediumVoltageForm: tensionType === "MEDIUM",
      averageConsumption: averageConsumption,
      averageConsumptionP: averageConsumptionP ?? 0,
      averageConsumptionFP: averageConsumptionFP ?? 0,
      powerPhase,
      teFP,
      teP,
      considerConsumptionUnits:
        !!concessionaireProfile || considerConsumptionUnits,
      consumptionUnits,
    },
    options: {
      retry: 0,
      enabled:
        !!averageConsumption ||
        (!!averageConsumptionP &&
          !!averageConsumptionFP &&
          teFP > 0 &&
          teP > 0),
      onSuccess: () => {
        setNeedGetNewExpirationDate(true);
      },
    },
  });

  const { data: distributorsConfig } = useFetchGetPaginatedDistributors({
    dependencyArray: [],
    options: {},
    payload: {
      page: 0,
      size: 100,
    },
  });

  const wegIsEnabled = distributorsConfig?.content?.find(
    (distributor) => distributor.name === "WEG",
  )?.enabled;

  useFetchGetAllProposalDefaultFieldSettings({
    payload: {
      pricingTypeId: proposalState?.pricingTypeId,
    },
    options: {
      onSuccess: (data) => {
        try {
          const proposalFieldsData = data ?? [];

          const settingsFields: ISizingStepFieldSettings[] =
            proposalFieldsData.map((item) => ({
              fieldName: item.formField?.nameField ?? "",
              displayName: item.formField?.displayName ?? "",
              isRequired: !!item.required,
              isEditable: !!item.editable,
              isHidden: !!item.hidden,
            }));

          setSettingsFields(settingsFields);
        } catch (error) {
          console.error("Erro ao processar dados da proposta:", error);
        }
      },
      enabled: !!proposalState?.pricingTypeId,
    },
    dependencyArray: [proposalState?.pricingTypeId],
  });

  const checkIfFieldSettings = useCallback(
    (field: string, displayName?: string) => {
      const currentField = settingsFields.find(
        (item) =>
          (!displayName || displayName === item.displayName) &&
          item.fieldName === field,
      );

      return {
        isRequired: currentField?.isRequired ?? false,
        isHidden: currentField?.isHidden ?? false,
        isEditable: currentField?.isEditable ?? true,
      };
    },
    [settingsFields],
  );

  const fieldSettings = {
    inverterTransformerPowerLimitKwp: checkIfFieldSettings(
      "inverterTransformerPowerLimitKwp",
      "Limite de potência do inversor",
    ),
    fdi: checkIfFieldSettings("fdi", "Sobredimensionamento - FDI"),
    minCelsiusTemperature: checkIfFieldSettings(
      "minCelsiusTemperature",
      "Temperatura mínima",
    ),
    note: checkIfFieldSettings("note", "Observação"),
    cosipTaxBefore: checkIfFieldSettings(
      "cosipTaxBefore",
      "COSIP - Antes solar",
    ),
    cosipTaxAfter: checkIfFieldSettings(
      "cosipTaxAfter",
      "COSIP - Depois solar",
    ),
    accumulatedCreditsUsedInPayback: checkIfFieldSettings(
      "accumulatedCreditsUsedInPayback",
      "Considerar crédito acumulado no payback",
    ),
    idLabor: checkIfFieldSettings("idLabor", "Mão de obra"),

    considerConsumptionUnits: checkIfFieldSettings(
      "considerConsumptionUnits",
      "Considerar consumo das unidades no dimensionamento da usina",
    ),
  };

  // GENERATION FACTOR
  const [generationPerMonth, setGenerationPerMonth] = useState<number>(0);

  const calculateGenerationPerMonth = useCallback(
    (performanceRate: number, generationFactorForCalculation: number) => {
      let calculation = 0;

      if (typeOfIrradiationReturnedAfterUpdate === "DAILY") {
        calculation =
          (generationFactorForCalculation / 1000) *
          performanceRate *
          (365 / 12.0);
      } else if (typeOfIrradiationReturnedAfterUpdate === "MONTHLY") {
        calculation = generationFactorForCalculation * performanceRate;
      }
      return Number((calculation / 100).toFixed(2));
    },
    [typeOfIrradiationReturnedAfterUpdate],
  );

  const handleGenerationPerMonth = useCallback(
    async (performanceRate: number, generationFactorForCalculation: number) => {
      const calculation = calculateGenerationPerMonth(
        performanceRate,
        generationFactorForCalculation,
      );

      setGenerationPerMonth(calculation);

      // if (tensionType === "LOW") {
      //   handleEstimatedPowerPlant(averageConsumption ?? 0, calculation);
      // } else if (tensionType === "MEDIUM") {
      //   handleEstimatedPowerPlantTip(averageConsumptionP ?? 0, calculation);
      //   handleEstimatedPowerPlantOffTip(averageConsumptionFP ?? 0, calculation);
      // }
    },
    [
      // averageConsumption,
      // averageConsumptionFP,
      // averageConsumptionP,
      calculateGenerationPerMonth,
      // handleEstimatedPowerPlant,
      // handleEstimatedPowerPlantOffTip,
      // handleEstimatedPowerPlantTip,
      // tensionType,
    ],
  );

  const isTaxesValuesValid = useCallback(
    (type: "te/tusd" | "teP/tusdP" | "teFP/tusdFP") => {
      const [identifierA, identifierB] = type.split("/");
      const valueA = Number(form.getFieldValue(identifierA) ?? 0);
      const valueB = Number(form.getFieldValue(identifierB) ?? 0);

      const sumValues = valueA + valueB;

      return sumValues !== 0;
    },
    [form],
  );

  const { data: structureTypes = [] } = useFetchGetStructureTypes({
    dependencyArray: [],
    options: {},
  });

  const canDisplayRegionField =
    userHaveIntegrationWithDistributor("WEG") &&
    !!structureTypes.find((structure) => {
      if (structure.type === "SOLO") {
        return idStructureType === structure.id;
      }

      if (structure.type === "TELHADO") {
        const subCategory = structure.subCategory;

        switch (subCategory) {
          case "CERAMICO":
            return idStructureType === structure.id;
          case "FIBROMADEIRA":
            return idStructureType === structure.id;
          case "FIBROMETALICA":
            return idStructureType === structure.id;
          default:
            return false;
        }
      }
    })?.id;

  useFetchGetStepTwoAutoComplete({
    options: {
      onSuccess(data) {
        if (initialValues?.regionId) return;
        form.setFieldValue("regionId", data.regionId);
        form.setFieldValue(
          "expirationDate",
          dayjs(data.proposalExpirationDate),
        );
      },
      enabled:
        canDisplayRegionField || !!estimatedPowerPlant?.expectedPowerFull,
    },
    payload: estimatedPowerPlant?.expectedPowerFull
      ? {
          potency: estimatedPowerPlant?.expectedPowerFull,
        }
      : undefined,
    dependencyArray: [structureTypes, estimatedPowerPlant?.expectedPowerFull],
  });

  const { refetch: reloadExpirationDate, isFetching: isLoadingExpirationDate } =
    useFetchGetProposalExpirationRules({
      options: {
        onSuccess(data) {
          form.setFieldValue(
            "expirationDate",
            dayjs(data.proposalExpirationDate),
          );
          setNeedGetNewExpirationDate(false);
        },
        enabled: !initialValues?.expirationDate,
      },
      payload: {
        potency: estimatedPowerPlant?.expectedPowerFull
          ? Number(estimatedPowerPlant?.expectedPowerFull)
          : undefined,
      },
      dependencyArray: [estimatedPowerPlant?.expectedPowerFull],
    });

  const { isFetching: isLoadingDataProfile } = useFetchGetConcessionaireProfile(
    {
      payload: {
        concessionaireProfileId: concessionaireProfile?.id || 0,
        electricUtilityId: concessionaireProfile?.electricUtilityId,
        profileName: concessionaireProfile?.name,
        voltageType: tensionType,
        modalidade: taxType,
      },
      options: {
        enabled: !isNaN(Number(concessionaireProfile?.id)), // zero is accepted!
        onSuccess(data) {
          if (data.lowVoltage) {
            const lowVoltageTe = data.lowVoltage.te ?? 0;
            const lowVoltageTusd = data.lowVoltage.tusd ?? 0;
            const tax = lowVoltageTe + lowVoltageTusd;

            return form.setFieldsValue({
              te: data.lowVoltage.te,
              tusd: data.lowVoltage.tusd,
              wireBTax: data.lowVoltage.wireBTax,
              tax,
              monthlyValues:
                data?.lowVoltage?.monthlyValues ??
                form.getFieldValue("monthlyValues"),
            });
          }

          if (data.mediumVoltage) {
            const taxTeP = data.mediumVoltage.teP ?? 0;
            const taxTeFP = data.mediumVoltage.teFP ?? 0;
            const taxTusdP = data.mediumVoltage.tusdP ?? 0;
            const taxTusdFP = data.mediumVoltage.tusdFP ?? 0;

            const taxP = taxTeP + taxTusdP;
            const taxFP = taxTeFP + taxTusdFP;

            form.setFieldsValue({
              taxP,
              taxFP,
              teP: data.mediumVoltage.teP,
              teFP: data.mediumVoltage.teFP,
              tusdP: data.mediumVoltage.tusdP,
              tusdFP: data.mediumVoltage.tusdFP,
              wireBTaxP: data.mediumVoltage.wireBTaxP,
              wireBTaxFP: data.mediumVoltage.wireBTaxFP,
              monthlyValuesFP:
                data?.mediumVoltage?.monthlyValuesFP ??
                form.getFieldValue("monthlyValuesFP"),
              monthlyValuesP:
                data?.mediumVoltage?.monthlyValuesP ??
                form.getFieldValue("monthlyValuesP"),
              contractedDemandTax: data.mediumVoltage?.contractedDemandTax,
              contractedDemandTaxP: data.mediumVoltage?.contractedDemandTaxP,
              contractedDemandTaxFP: data.mediumVoltage?.contractedDemandTaxFP,
              contractedDemandTaxTusdG:
                data.mediumVoltage?.contractedDemandTaxTusdG,
            });
          }
        },
        retry: 1,
        onError: (error) => {
          message.info(`Falha ao carregar dados das tarifas: ${error.message}`);
        },
      },
      dependencyArray: [concessionaireProfile],
    },
  );

  //CHANGE OF VALUE OF THE RATES FIELDS
  function onChangeTaxValues(value = 0, formKey: "te" | "tusd") {
    const invertValue: number = form.getFieldValue(formKey) || 0;
    form.setFieldValue("tax", value + invertValue);
  }

  function onChangeTaxPValues(value = 0, formKey: "teP" | "tusdP") {
    const invertValue: number = form.getFieldValue(formKey) || 0;
    form.setFieldValue("taxP", value + invertValue);
  }

  function onChangeTaxFPValues(value = 0, formKey: "teFP" | "tusdFP") {
    const invertValue: number = form.getFieldValue(formKey) || 0;
    form.setFieldValue("taxFP", value + invertValue);
  }

  // HANDLE SUBMIT FORM
  const {
    mutate: fetchUpdateSizingStep,
    isLoading: isLoadingUpdateSizingStep,
  } = useFetchUpdateSizingStep({
    options: {
      onSuccess,
      onError,
    },
  });

  function createLowTensionBody(
    formData: ISizingStepFormValues,
    isUpdate = false,
  ) {
    const idLabor = formData.idLabor > 0 ? formData.idLabor : undefined;

    const body = {
      generationFactor: formData.generationFactor || 0,
      performanceRate: formData.performanceRate || 0,
      idStructureType: formData.idStructureType,
      idLabor,
      outputVoltage: formData.outputVoltage,
      fdi: formData.fdi,
      powerPhase: formData.powerPhase,
      lowVoltage: {
        averageConsumption: formData.averageConsumption,
        tusd: formData.tusd,
        te: formData.te,
        monthlyValues: formData.monthlyValues,
        wireBTax: formData.wireBTax,
        cosipTaxAfter: formData.lowVoltageCosipTaxAfter,
        cosipTaxBefore: formData.lowVoltageCosipTaxBefore,
        inverterTransformerPowerLimitKwp:
          formData.inverterTransformerPowerLimitKwp,
      },
      note: formData.note,
      simultaneityFactor: formData.simultaneityFactor,
      plantDataCustomFieldValues: formData?.plantDataCustomFieldValues,
      idElectricUtilityProfile: concessionaireProfile?.electricUtilityId,
      electricUtilityId: formData.electricUtilityId,
      structureGroup: formData.structureGroup,
      averageExcessEnergyTariff: formData.averageExcessEnergyTariff,
      expectedSurplusEnergy: formData.expectedSurplusEnergy,
      averageExcessEnergyTariffWireB: formData.averageExcessEnergyTariffWireB,
      minCelsiusTemperature: formData.minCelsiusTemperature,
      monthlySolarRadiation: formData.monthlySolarRadiation,
      considerConsumptionUnits: formData.considerConsumptionUnits,
      accumulatedCreditsUsedInPayback: formData.accumulatedCreditsUsedInPayback,
      consumptionUnits: formData.consumptionUnits,
      allocationPercentage: formData.allocationPercentage,
      compensationType: formData.compensationType,
      plantUnitCode: formData.plantUnitCode,
      regionId: canDisplayRegionField ? formData.regionId : undefined,
      expirationDate: dayjs(formData.expirationDate).toISOString(),
    } as Omit<StepTwoForm, "mediumVoltage">;

    if (isUpdate) {
      return { ...body, step: 4 };
    }

    return body;
  }

  function createMediumTensionBody(
    formData: ISizingStepFormValues,
    isUpdate = false,
  ) {
    const idLabor = formData.idLabor > 0 ? formData.idLabor : undefined;

    const body = {
      generationFactor: formData.generationFactor || 0,
      performanceRate: formData.performanceRate,
      idStructureType: formData.idStructureType,
      idLabor,
      outputVoltage: formData.outputVoltage,
      fdi: formData.fdi,
      powerPhase: formData.powerPhase,
      mediumVoltage: {
        averageConsumptionFP: formData.averageConsumptionFP,
        averageConsumptionP: formData.averageConsumptionP,
        tusdFP: formData.tusdFP,
        teFP: formData.teFP,
        tusdP: formData.tusdP,
        teP: formData.teP,
        wireBTaxP: formData.wireBTaxP,
        wireBTaxFP: formData.wireBTaxFP,
        monthlyValuesP: formData.monthlyValuesP,
        monthlyValuesFP: formData.monthlyValuesFP,
        postSolarDemandKw: formData.postSolarDemandKw,
        contractedDemand: formData.contractedDemand,
        contractedDemandTax: formData.contractedDemandTax,
        contractedDemandTaxTusdG: formData.contractedDemandTaxTusdG,
        taxType: formData.taxType,
        contractedDemandP: formData.contractedDemandP,
        contractedDemandTaxP: formData.contractedDemandTaxP,
        contractedDemandFP: formData.contractedDemandFP,
        contractedDemandTaxFP: formData.contractedDemandTaxFP,
        cosipTaxAfter: formData.mediumVoltageCosipTaxAfter,
        cosipTaxBefore: formData.mediumVoltageCosipTaxBefore,
        reactivePowerAverage: formData.reactivePowerAverage,
      },
      note: formData.note,
      simultaneityFactor: formData.simultaneityFactor,
      plantDataCustomFieldValues: formData.plantDataCustomFieldValues,
      idElectricUtilityProfile: concessionaireProfile?.electricUtilityId,
      electricUtilityId: formData.electricUtilityId,
      structureGroup: formData.structureGroup,
      averageExcessEnergyTariff: formData.averageExcessEnergyTariff,
      averageExcessEnergyTariffWireB: formData.averageExcessEnergyTariffWireB,
      expectedSurplusEnergy: formData.expectedSurplusEnergy,
      minCelsiusTemperature: formData.minCelsiusTemperature,
      monthlySolarRadiation: formData.monthlySolarRadiation,
      considerConsumptionUnits: formData.considerConsumptionUnits,
      accumulatedCreditsUsedInPayback: formData.accumulatedCreditsUsedInPayback,
      allocationPercentage: formData.allocationPercentage,
      compensationType: formData.compensationType,
      plantUnitCode: formData.plantUnitCode,
      regionId: canDisplayRegionField ? formData.regionId : undefined,
      expirationDate: dayjs(formData.expirationDate).toISOString(),
    } as Omit<StepTwoForm, "lowVoltage">;

    if (isUpdate) {
      return { ...body, step: 4 };
    }

    return body;
  }

  const openNotification = (callback: { (): void; (): void }) => {
    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <AntdButton type="link" size="small" onClick={() => api.destroy()}>
          Cancelar
        </AntdButton>
        <AntdButton
          type="primary"
          size="small"
          onClick={() => {
            onHandleStepChange(4);
            callback();
          }}
        >
          Confirmar
        </AntdButton>
      </Space>
    );
    api.open({
      message: "Atenção",
      description:
        'Ao alterar a proposta o PDF gerado anteriormente será perdido. Para gerar outro,deve-se finalizar a proposta no passo "Proposta"',
      btn,
      key,
      duration: 0,
      onClose: () => api.destroy(),
    });
  };

  function handleCustomFields(
    formData: ISizingStepFormValues,
    section: keyof ISizingStepFormValues,
  ) {
    const sectionData: ProposalSizingStepCustomField[] = formData[
      section
    ] as ProposalSizingStepCustomField[];

    if (Array.isArray(sectionData)) {
      return Promise.all(
        sectionData.map(async (item) => {
          let parsedValue = item.value;

          if (item.type === "IMAGE" && item.file === null) {
            parsedValue = null;
          } else if (item.type === "IMAGE" && item.file) {
            parsedValue = (
              (await getBase64(item.file as RcFile)) as string
            ).replace(/^data:image\/\w+;base64,/, "");
          } else if (
            item.type === "DATE" &&
            item.value !== null &&
            item.value !== undefined
          ) {
            parsedValue = dayjs(item.value.toString()).format("DD/MM/YYYY");
          }

          return {
            ...item,
            value: parsedValue,
          };
        }),
      );
    } else {
      return Promise.resolve([]);
    }
  }

  const { mutate: updateMonthlyPerformanceRate } =
    useFetchUpdateMonthlyPerformanceRateByCustomId({});

  async function handleSubmit(
    formData: ISizingStepFormValues,
    isUpdate = false,
  ) {
    if (!proposalId) {
      throw new Error("Faltando o ID da negociação");
    }

    const monthlyPerformanceRateValues: IMonthlyPerformanceRate = {
      jan: formData.performanceRate,
      feb: formData.performanceRate,
      mar: formData.performanceRate,
      apr: formData.performanceRate,
      may: formData.performanceRate,
      jun: formData.performanceRate,
      jul: formData.performanceRate,
      aug: formData.performanceRate,
      sep: formData.performanceRate,
      oct: formData.performanceRate,
      nov: formData.performanceRate,
      dec: formData.performanceRate,
    };

    if (hasDirectlyInserted && proposalId) {
      updateMonthlyPerformanceRate({
        ...monthlyPerformanceRateValues,
        customId: proposalId,
      });
    }

    const parsedCustomFieldsInvoice = await handleCustomFields(
      formData,
      "customFieldsInvoiceData",
    );

    const parsedCustomFieldsPlant = await handleCustomFields(
      formData,
      "customFieldsPlantData",
    );

    const parsedCustomFieldsPowerPlant = await handleCustomFields(
      formData,
      "customFieldsPowerPlantData",
    );

    localStorage.removeItem(PROPOSAL_DATA_HAS_CHANGED);
    dispatchEvent(new Event("storage"));

    const body =
      tensionType === "LOW"
        ? createLowTensionBody(formData, isUpdate)
        : createMediumTensionBody(formData, isUpdate);

    fetchUpdateSizingStep({
      ...body,
      customId: proposalId,
      accumulatedCreditsUsedInPayback:
        !!formData.accumulatedCreditsUsedInPayback,
      considerConsumptionUnits: !!formData.considerConsumptionUnits,
      regionId: canDisplayRegionField ? formData.regionId : undefined,
      plantDataCustomFieldValues:
        [
          ...parsedCustomFieldsInvoice,
          ...parsedCustomFieldsPlant,
          ...parsedCustomFieldsPowerPlant,
        ].map((item) => ({
          customFieldId: item.customFieldId,
          value: item.value?.toString(),
        })) ?? [],
    });
  }

  // PERSISTS DATA IN STORAGE
  const handlePersistsDataInStorage = useCallback(
    (data: IProposalSizingStepFormInitialValues, dealId: number) => {
      try {
        const stringifiedData = JSON.stringify({ ...data, id: dealId });
        localStorage.setItem(SIZING_STEP_STORAGE_KEY, stringifiedData);
      } catch (error: any) {
        console.warn(error?.message);
      }
    },
    [],
  );

  useInterval(() => {
    if (!proposalId) return;

    const formFields = form.getFieldsValue(true);
    const data = {
      ...formFields,
      tensionType,
      plantDataCustomFieldValues: formFields?.plantDataCustomFieldValues?.map(
        (item: any, index: number) => {
          return item[index] ?? item;
        },
      ),
    };
    handlePersistsDataInStorage(data, proposalId);
  }, 3000); // 3 seconds

  useEffect(() => {
    if (!initialValues) return;

    const initialData = {
      ...initialValues,
      generationFactor:
        initialValues.generationFactor ??
        form.getFieldValue("generationFactor"),
      tensionType: initialValues.tensionType,
      electricUtilityProfileName: initialValues.electricUtilityProfileName,
      expirationDate: dayjs(initialValues.expirationDate),
    };
    // THE EXCESS RATE FIELDS HAVE BEEN REMOVED, THIS MAY BE TEMPORARY.
    // const showTaxExtraFields =
    //   initialValues.expectedSurplusEnergy ||
    //   initialValues.expectedSurplusEnergy;

    // setShowTaxExtraFields(!!showTaxExtraFields);

    form.setFieldsValue(initialData);
  }, [form, initialValues]);

  useEffect(() => {
    form.resetFields(["idElectricUtilityProfile"]);
  }, [electricUtilityId, tensionType, form]);

  // MEMOIZED COMPONENT
  // THE EXCESS RATE FIELDS HAVE BEEN REMOVED, THIS MAY BE TEMPORARY.
  // const MemoizedExtraTaxFields = useMemo(() => {
  //   return (
  //     <Flex
  //       direction={screens.lg ? "row" : "column"}
  //       wrap="nowrap"
  //       gap={8}
  //       style={{ width: "100%" }}
  //     >
  //       <Form.Item
  //         label="Tarifa média de energia excedente"
  //         name="averageExcessEnergyTariff"
  //         labelCol={{ xs: 24, lg: 24, xl: 20, xxl: 24 }}
  //         wrapperCol={{ xs: 24, lg: 24 }}
  //         style={{
  //           width: "100%",
  //         }}
  //       >
  //         <CustomInput.UnitMeasurement
  //           precision="2"
  //           unitMeasurement="R$/kWh"
  //           placeholder="Ex.: 500"
  //           min={0}
  //         />
  //       </Form.Item>
  //       <Form.Item
  //         label="Tarifa fio B da energia excedente"
  //         name="averageExcessEnergyTariffWireB"
  //         labelCol={{ xs: 24, lg: 22, xl: 20, xxl: 23 }}
  //         wrapperCol={{ xs: 24, lg: 24 }}
  //         style={{
  //           width: "100%",
  //         }}
  //       >
  //         <CustomInput.UnitMeasurement
  //           precision="2"
  //           unitMeasurement="R$/kWh"
  //           placeholder="Ex.: 600"
  //           min={0}
  //         />
  //       </Form.Item>
  //       <Form.Item
  //         label="Energia excedente esperada"
  //         name="expectedSurplusEnergy"
  //         labelCol={{ xs: 24, lg: 16, xl: 20, xxl: 20 }}
  //         wrapperCol={{ xs: 24, lg: 24 }}
  //         style={{
  //           width: "100%",
  //         }}
  //       >
  //         <CustomInput.UnitMeasurement
  //           precision="2"
  //           unitMeasurement="KWh"
  //           placeholder="Ex.: 300"
  //           min={0}
  //         />
  //       </Form.Item>
  //     </Flex>
  //   );
  // }, [screens.lg]);

  // OLD NEED SUBSTITUTE THIS FOR BUTTON SELECT ANEEL
  const MemoizedButtonSelectAneel = useMemo(() => {
    return (
      <Tooltip
        title={!electricUtilityId && "Necessário selecionar a concessionária"}
      >
        <AntdButton
          type="link"
          size="small"
          style={{ margin: 0, padding: 0 }}
          onClick={() => setIsConcessionaireProfileModalOpen(true)}
          disabled={!electricUtilityId}
        >
          Buscar tarifas?
        </AntdButton>
      </Tooltip>
    );
  }, [electricUtilityId]);

  const MemoizedButtonConsumerUnits = useMemo(() => {
    const consumerUnits = consumptionUnits ?? [];

    return (
      <Tooltip
        title={!electricUtilityId && "Necessário selecionar uma concessionária"}
      >
        <Button.ConsumerUnits
          modalProps={{
            formProps: {
              initialValues: {
                consumerUnits:
                  consumerUnits?.map((unit) => {
                    const voltageType = unit.mediumVoltage ? "MEDIUM" : "LOW";

                    const taxType = unit.mediumVoltage?.contractedDemandTaxTusdG
                      ? "BLUE"
                      : "GREEN";

                    return {
                      ...unit,
                      voltageType,
                      taxType,
                    };
                  }) ?? [],
                compensationType,
                allocationPercentage,
                considerConsumptionUnits:
                  form.getFieldValue("considerConsumptionUnits") ??
                  initialValues?.considerConsumptionUnits,
              },
              customId: proposalId,
              concessionaireId: electricUtilityId,
              onSuccess(data) {
                form.setFieldValue("consumptionUnits", data.consumerUnits);
                form.setFieldValue("compensationType", data.compensationType);
                form.setFieldValue(
                  "considerConsumptionUnits",
                  data.considerConsumptionUnits,
                );
                form.setFieldValue(
                  "allocationPercentage",
                  data.allocationPercentage,
                );
                refecthEstimatedPowerPlant();
              },
            },
          }}
          closeAfterSubmit
          block
          disabled={!electricUtilityId}
        >
          {consumerUnits.length > 0
            ? "Editar unidades consumidoras"
            : "Adicionar dados de outras UCs"}
        </Button.ConsumerUnits>
      </Tooltip>
    );
  }, [
    consumptionUnits,
    electricUtilityId,
    compensationType,
    allocationPercentage,
    form,
    initialValues?.considerConsumptionUnits,
    proposalId,
    refecthEstimatedPowerPlant,
  ]);

  useEffect(() => {
    if (voltageType) {
      const formData: ISizingStepFormValues = form.getFieldsValue(true);

      if (voltageType === "LOW") {
        formData.averageConsumption = initialAverageConsumption;
      }
      if (voltageType === "MEDIUM") {
        formData.averageConsumptionP = initialAverageConsumptionP ?? 0;
        formData.averageConsumptionFP = initialAverageConsumptionFP ?? 0;
      }
      formData.tensionType = voltageType;
      form.setFieldsValue(formData);
    }
  }, [voltageType]);
  return (
    <>
      <Form
        data-cy="proposal-sizing-step-form"
        form={form}
        initialValues={initialValues}
        onFinish={() =>
          lastStep === steps.length
            ? openNotification(() =>
                handleSubmit(form.getFieldsValue(true), true),
              )
            : handleSubmit(form.getFieldsValue(true))
        }
        onValuesChange={() => {
          localStorage.setItem(PROPOSAL_DATA_HAS_CHANGED, "true");
          dispatchEvent(new Event("storage"));
        }}
        layout="vertical"
        style={{ height: "100%" }}
        scrollToFirstError
      >
        <Layout
          style={{
            height: "100%",
            background: "transparent",
            padding: screens.xl ? 0 : "1rem 0rem",
            width: "100%",
            overflowY: "hidden",
          }}
        >
          {contextHolder}
          <Layout.Content
            style={{
              width: "100%",
              height: "100%",
              overflowY: "auto",
              overflowX: "hidden",
              padding: screens.xl ? "2rem" : "0 1rem 1rem 1rem",
            }}
          >
            <Spin spinning={isLoadingDataProfile} tip="Carregando dados...">
              <Row>
                <Col span={screens.md ? 11 : 24}>
                  <Flex
                    style={{ width: "100%", marginBottom: "1rem" }}
                    justify="space-between"
                    align="center"
                    gap={32}
                  >
                    <Typography.Title level={4} style={{ margin: 0 }}>
                      Dados da fatura
                    </Typography.Title>

                    <Form.Item
                      label={!screens.md ? "Unidade" : null}
                      style={{ width: "100%", margin: 0, flex: "1 1 0" }}
                      name="tensionType"
                      data-cy="select-tension-type"
                    >
                      <AntdSelect<"LOW" | "MEDIUM">
                        options={[
                          {
                            label: "Baixa tensão",
                            value: "LOW",
                          },
                          {
                            label: "Média tensão",
                            value: "MEDIUM",
                          },
                        ]}
                        onChange={(value) => {
                          form.setFieldValue(
                            "electricUtilityProfileName",
                            undefined,
                          );

                          value === "MEDIUM"
                            ? form.setFieldValue("powerPhase", "Trifásico")
                            : form.setFieldValue("powerPhase", "Monofásico");
                        }}
                      />
                    </Form.Item>
                  </Flex>
                  <Flex align="center" vertical>
                    <Form.Item
                      label="Concessionárias"
                      name="electricUtilityId"
                      tooltip="Ao selecionar uma concessionária os perfis dela serão exibidos no próximo campo de seleção"
                      style={{ width: "100%" }}
                    >
                      <Select.ConcessionairesWithAssociatedProfileByProvince
                        provinceId={installationProvinceId}
                        data-cy="select-concessionaires"
                        placeholder="Selecione uma concessionária..."
                        onLoad={(concessionaires) => {
                          const recordExists = concessionaires?.find(
                            (concessionaire) =>
                              concessionaire.id === electricUtilityId,
                          );

                          form.setFieldValue(
                            "electricUtilityId",
                            recordExists?.id,
                          );

                          form.setFieldValue(
                            "electricUtilityName",
                            recordExists?.name,
                          );
                        }}
                        onChange={(_, option) => {
                          form.setFieldValue(
                            "electricUtilityName",
                            option?.record.name,
                          );
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="plantUnitCode"
                      label="Código da unidade"
                      rules={[
                        {
                          pattern: /^[a-zA-Z0-9_]+$/, // Regex para permitir apenas letras, números e sublinhados
                          message: "Formato é inválido!",
                        },
                        {
                          required: false,
                        },
                      ]}
                      style={{ width: "100%" }}
                    >
                      <CustomInput.Text
                        data-cy="input-code-step-two"
                        placeholder="Ex.: A1B2C345"
                      />
                    </Form.Item>
                    <Form.Item
                      label="Fase da rede do cliente"
                      name="powerPhase"
                      rules={[{ required: true }]}
                      style={{ width: "100%" }}
                    >
                      <Select.Phase
                        data-cy="select-client-phase"
                        placeholder="Selecionar fase..."
                      />
                    </Form.Item>
                    <Form.Item
                      label="Tensão da rede do cliente"
                      name="outputVoltage"
                      style={{ width: "100%" }}
                      data-cy="select-client-tension"
                    >
                      <Select.OutputVoltage
                        hideValues={{
                          V460: true,
                          V480: true,
                          V500: true,
                          V540: true,
                          V600: true,
                          V800: true,
                        }}
                      />
                    </Form.Item>
                    {tensionType === "MEDIUM" && (
                      <Form.Item
                        label="Tipo de tarifa"
                        name="taxType"
                        style={{ width: "100%" }}
                      >
                        <Select.TaxType />
                      </Form.Item>
                    )}
                  </Flex>
                  {tensionType === "LOW" && (
                    <>
                      <Form.Item
                        name="averageConsumption"
                        label={
                          <Flex gap={0} align="center">
                            <span style={{ marginRight: "0.2rem" }}>
                              Consumo médio mensal
                            </span>
                            <Tooltip
                              title="Ao alterar esse campo diretamente os valores
                                  mês-a-mês serão limpos"
                            >
                              <AiOutlineQuestionCircle
                                size={16}
                                style={{ color: "var(--gray-500)" }}
                              />
                            </Tooltip>
                            <AntdButton
                              type="link"
                              onClick={() =>
                                setIsMonthConsumptionModalOpen(true)
                              }
                            >
                              Mês a mês
                            </AntdButton>
                          </Flex>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Por favor, insira Consumo médio",
                          },
                          {
                            validator: (_rule, value) => {
                              return value > 0
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error(
                                      "Valor tem que ser maior que zero!",
                                    ),
                                  );
                            },
                          },
                        ]}
                        extra={
                          <Flex vertical>
                            {estimatedPowerPlantError ? (
                              <span style={{ color: "var(--red-500)" }}>
                                Falha ao carregar potência estimada:{" "}
                                {estimatedPowerPlantError?.message}
                              </span>
                            ) : (
                              <Flex gap={4}>
                                Potência estimada da usina:
                                <Spin
                                  spinning={isLoadingEstimatedPowerPlant}
                                  size="small"
                                >
                                  <span>
                                    {parsePotency(
                                      estimatedPowerPlant?.expectedPowerFull ??
                                        0,
                                    )}
                                  </span>
                                </Spin>
                              </Flex>
                            )}
                            {isMonthToMonthActive && (
                              <Flex align="center">
                                <Tooltip title="Os valores foram inseridos mês-a-mês. ATENÇÃO: ao alterar esse campo os valores mês-a-mês serão perdidos.">
                                  <Flex
                                    align="center"
                                    style={{
                                      cursor: "help",
                                    }}
                                  >
                                    <FiAlertTriangle
                                      size={14}
                                      style={{ color: "var(--red-500)" }}
                                    />
                                    <span
                                      style={{
                                        color: "var(--red-500)",
                                        marginLeft: "0.25rem",
                                      }}
                                    >
                                      Inserido mês-a-mês
                                    </span>
                                  </Flex>
                                </Tooltip>
                              </Flex>
                            )}
                          </Flex>
                        }
                      >
                        <CustomInput.UnitMeasurement
                          min={0}
                          precision="2"
                          unitMeasurement="KWh"
                          placeholder="Ex.: 52,00"
                          style={{ width: "100%" }}
                          onChange={() => {
                            form.setFieldsValue({
                              isMonthToMonthActive: false,
                              monthlyValues: undefined,
                            });
                          }}
                        />
                      </Form.Item>

                      {/* The excess rate fields have been removed, this may be temporary. */}
                      {/* {showTaxExtraFields && MemoizedExtraTaxFields} */}

                      <Form.Item
                        label="Tarifa TE"
                        name="te"
                        tooltip="Tarifa com imposto incluso"
                        rules={[
                          {
                            required: true,
                            message: "Campo é obrigatório",
                          },
                          {
                            validator(_rule, _value) {
                              return isTaxesValuesValid("te/tusd")
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error(
                                      "A soma das tarifa TE + TUSD não podem ser zero",
                                    ),
                                  );
                            },
                          },
                        ]}
                        extra={
                          ENV.AMBIENT === "DEVELOPMENT" ? (
                            <AntdButton
                              type="link"
                              size="small"
                              style={{ margin: 0, padding: 0 }}
                              disabled={!electricUtilityName}
                              onClick={() => {
                                setAneelModalState({
                                  isOpen: true,
                                  voltageType: "LOW",
                                });
                              }}
                            >
                              Buscar tarifas?
                            </AntdButton>
                          ) : (
                            MemoizedButtonSelectAneel
                          )
                        }
                      >
                        <CustomInput.Money
                          unit="R$/kWh"
                          min={0}
                          onChange={(value) =>
                            onChangeTaxValues(value as number, "tusd")
                          }
                          precision={6}
                          placeholder="1,000000"
                          style={{
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Tarifa TUSD"
                        tooltip="Tarifa com imposto incluso"
                        name="tusd"
                        rules={[
                          {
                            required: true,
                            message: "Campo é obrigatório",
                          },
                          {
                            validator(_rule, _value) {
                              return isTaxesValuesValid("te/tusd")
                                ? Promise.resolve()
                                : Promise.reject(
                                    "A soma das tarifa TE + TUSD não podem ser zero",
                                  );
                            },
                          },
                        ]}
                      >
                        <CustomInput.Money
                          unit="R$/kWh"
                          min={0}
                          onChange={(value) =>
                            onChangeTaxValues(value as number, "te")
                          }
                          style={{
                            width: "100%",
                          }}
                          precision={6}
                          placeholder="1,000000"
                        />
                      </Form.Item>
                      <Form.Item
                        label="Tarifa de Fio B"
                        name="wireBTax"
                        tooltip="Tarifa com imposto incluso"
                        rules={[
                          {
                            required: true,
                            message: "Campo é obrigatório!",
                          },
                        ]}
                      >
                        <CustomInput.Money
                          unit="R$/kWh"
                          min={0}
                          style={{
                            width: "100%",
                          }}
                          precision={6}
                          placeholder="1,000000"
                        />
                      </Form.Item>

                      <Divider>
                        <Typography.Text
                          style={{
                            color: "var(--primary-500)",
                            fontWeight: 700,
                          }}
                        >
                          Outras informações
                        </Typography.Text>
                      </Divider>

                      <Form.Item
                        label="Taxa de iluminação pública - antes da solar"
                        name="lowVoltageCosipTaxBefore"
                        rules={[
                          {
                            required: fieldSettings?.cosipTaxBefore?.isRequired,
                          },
                        ]}
                        tooltip="Valor utilizado para estimar a fatura antes da instalação do sistema de energia solar"
                        hidden={fieldSettings?.cosipTaxBefore?.isHidden}
                      >
                        <CustomInput.Money
                          precision={2}
                          placeholder="Ex.: 1,00"
                          min={0}
                          readOnly={!fieldSettings?.cosipTaxBefore?.isEditable}
                          style={getFieldReadonlyStyle(
                            !!fieldSettings?.cosipTaxBefore?.isEditable,
                            !!(currentTheme === "dark"),
                          )}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Taxa de iluminação pública - depois da solar"
                        name="lowVoltageCosipTaxAfter"
                        rules={[
                          {
                            required: fieldSettings?.cosipTaxAfter?.isRequired,
                          },
                        ]}
                        tooltip="Valor utilizado para estimar a fatura após a instalação do sistema de energia solar"
                        hidden={fieldSettings?.cosipTaxAfter?.isHidden}
                      >
                        <CustomInput.Money
                          precision={2}
                          placeholder="Ex.: 1,00"
                          min={0}
                          readOnly={!fieldSettings?.cosipTaxAfter?.isEditable}
                          style={getFieldReadonlyStyle(
                            !!fieldSettings?.cosipTaxAfter?.isEditable,
                            !!(currentTheme === "dark"),
                          )}
                        />
                      </Form.Item>
                      {/* <Form.Item
                        label="Considerar consumo das unidades no dimensionamento da usina"
                        name="considerConsumptionUnits"
                      >
                        <Radio.Group
                          defaultValue={true}
                          options={[
                            {
                              label: "Sim",
                              value: true,
                            },
                            {
                              label: "Não",
                              value: false,
                            },
                          ]}
                        />
                      </Form.Item> */}

                      <Form.Item
                        label="Considerar crédito acumulado no payback"
                        name="accumulatedCreditsUsedInPayback"
                        initialValue={false}
                        rules={[
                          {
                            required:
                              fieldSettings?.accumulatedCreditsUsedInPayback
                                ?.isRequired,
                          },
                        ]}
                        hidden={
                          fieldSettings?.accumulatedCreditsUsedInPayback
                            ?.isHidden
                        }
                      >
                        <Radio.Group
                          defaultValue={false}
                          disabled={
                            !fieldSettings?.accumulatedCreditsUsedInPayback
                              ?.isEditable
                          }
                          style={getFieldReadonlyStyle(
                            !!fieldSettings?.accumulatedCreditsUsedInPayback
                              ?.isEditable,
                            !!(currentTheme === "dark"),
                          )}
                          options={[
                            {
                              label: "Sim",
                              value: true,
                            },
                            {
                              label: "Não",
                              value: false,
                            },
                          ]}
                        />
                      </Form.Item>

                      {MemoizedButtonConsumerUnits}
                    </>
                  )}
                  {tensionType === "MEDIUM" && (
                    <Flex gap={0} vertical style={{ width: "100%" }}>
                      <Divider>
                        <Typography.Text
                          style={{
                            color: "var(--primary-500)",
                            fontWeight: 700,
                          }}
                        >
                          Ponta
                        </Typography.Text>
                      </Divider>

                      <Form.Item
                        style={{ width: "100%" }}
                        label={
                          <Flex gap={0} align="center">
                            <span style={{ marginRight: "0.2rem" }}>
                              Consumo médio mensal
                            </span>
                            <Tooltip
                              title="Ao alterar esse campo diretamente os valores
                                  mês-a-mês serão limpos"
                            >
                              <AiOutlineQuestionCircle
                                size={16}
                                style={{ color: "var(--gray-500)" }}
                              />
                            </Tooltip>
                            <AntdButton
                              type="link"
                              onClick={() =>
                                setIsMonthConsumptionPModalOpen(true)
                              }
                              data-cy="average-monthly-consumption-ponta"
                            >
                              Mês a mês
                            </AntdButton>
                          </Flex>
                        }
                        extra={
                          <Flex vertical>
                            {estimatedPowerPlantError ? (
                              <span style={{ color: "var(--red-500)" }}>
                                Falha ao carregar potência estimada:{" "}
                                {estimatedPowerPlantError?.message}
                              </span>
                            ) : (
                              <Flex gap={4}>
                                Potência estimada da usina:
                                <Spin
                                  spinning={isLoadingEstimatedPowerPlant}
                                  size="small"
                                >
                                  <span>
                                    {parsePotency(
                                      estimatedPowerPlant?.expectedPowerFull ??
                                        0,
                                    )}
                                  </span>
                                </Spin>
                              </Flex>
                            )}
                            {isMonthToMonthPActive && (
                              <Flex align="center">
                                <Tooltip title="Os valores foram inseridos mês-a-mês. ATENÇÃO: ao alterar esse campo os valores mês-a-mês serão perdidos.">
                                  <Flex
                                    align="center"
                                    style={{
                                      cursor: "help",
                                    }}
                                  >
                                    <FiAlertTriangle
                                      size={14}
                                      style={{ color: "var(--red-500)" }}
                                    />
                                    <span
                                      style={{
                                        color: "var(--red-500)",
                                        marginLeft: "0.25rem",
                                      }}
                                    >
                                      Inserido mês-a-mês
                                    </span>
                                  </Flex>
                                </Tooltip>
                              </Flex>
                            )}
                          </Flex>
                        }
                        name="averageConsumptionP"
                        rules={[
                          {
                            required: true,
                            message: "Por favor, insira Consumo médio",
                          },
                          {
                            validator(_rule, value) {
                              return value > 0
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error("Valor não pode ser zero!"),
                                  );
                            },
                          },
                        ]}
                      >
                        <CustomInput.UnitMeasurement
                          min={0}
                          precision="2"
                          unitMeasurement="KWh"
                          placeholder="Ex.: 33,00"
                          style={{ width: "100%" }}
                          onChange={() => {
                            form.setFieldsValue({
                              isMonthToMonthPActive: false,
                              monthlyValuesP: undefined,
                            });
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Tarifa TE"
                        name="teP"
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Campo é obrigatório",
                          },
                          {
                            validator(_rule, _value) {
                              return isTaxesValuesValid("teP/tusdP")
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error(
                                      "A soma das tarifa TE + TUSD não podem ser zero",
                                    ),
                                  );
                            },
                          },
                        ]}
                        tooltip="Tarifa TE com imposto"
                        extra={
                          ENV.AMBIENT === "DEVELOPMENT" ? (
                            <AntdButton
                              type="link"
                              size="small"
                              style={{ margin: 0, padding: 0 }}
                              disabled={!electricUtilityName}
                              onClick={() => {
                                setAneelModalState({
                                  isOpen: true,
                                  voltageType: "MEDIUM",
                                });
                              }}
                            >
                              Buscar tarifas?
                            </AntdButton>
                          ) : (
                            MemoizedButtonSelectAneel
                          )
                        }
                      >
                        <CustomInput.Money
                          unit="R$/kWh"
                          min={0}
                          onChange={(value) =>
                            onChangeTaxPValues(value as number, "tusdP")
                          }
                          style={{
                            width: "100%",
                          }}
                          precision={6}
                          placeholder="Ex.: 1,000000"
                        />
                      </Form.Item>

                      <Form.Item
                        label="Tarifa TUSD"
                        name="tusdP"
                        tooltip="Tarifa TUSD com imposto"
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Campo é obrigatório",
                          },
                          {
                            validator(_rule, _value) {
                              return isTaxesValuesValid("teP/tusdP")
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error(
                                      "A soma das tarifa TE + TUSD não podem ser zero",
                                    ),
                                  );
                            },
                          },
                        ]}
                      >
                        <CustomInput.Money
                          unit="R$/kWh"
                          min={0}
                          onChange={(value) =>
                            onChangeTaxPValues(value as number, "teP")
                          }
                          style={{
                            width: "100%",
                          }}
                          precision={6}
                          placeholder="Ex.: 1,000000"
                        />
                      </Form.Item>
                      <Form.Item
                        label="Tarifa de Fio B"
                        tooltip="Tarifa de fio B com imposto - Fornecido pela concessionária de energia e utilizado no cálculo de retorno financeiro junto com a taxa de simultaneidade e a progressão de cobrança prevista na legislação."
                        name="wireBTaxP"
                        style={{ width: "100%" }}
                        rules={[{ required: true }]}
                      >
                        <CustomInput.Money
                          unit="R$/kWh"
                          min={0}
                          style={{
                            width: "100%",
                          }}
                          precision={6}
                          placeholder="Ex.: 1,000000"
                        />
                      </Form.Item>
                      {taxType === "BLUE" && (
                        <>
                          <Form.Item
                            label="Demanda contratada"
                            style={{ width: "100%" }}
                            name="contractedDemandP"
                            rules={[
                              {
                                validator: (_rule, value) => {
                                  const parsedValue = Number(value);

                                  if (isNaN(parsedValue)) {
                                    return Promise.resolve();
                                  }

                                  return parsedValue > 0
                                    ? Promise.resolve()
                                    : Promise.reject(
                                        new Error(
                                          "Valor tem que ser maior que zero!",
                                        ),
                                      );
                                },
                              },
                            ]}
                          >
                            <CustomInput.UnitMeasurement
                              precision="2"
                              unitMeasurement="KW"
                              min={0}
                              style={{ width: "100%" }}
                              placeholder="1,33"
                            />
                          </Form.Item>
                          <Form.Item
                            label="Tarifa de Demanda "
                            style={{ width: "100%" }}
                            name="contractedDemandTaxP"
                            rules={[
                              {
                                validator: (_rule, value) => {
                                  const parsedValue = Number(value);

                                  if (isNaN(parsedValue)) {
                                    return Promise.resolve();
                                  }

                                  return parsedValue > 0
                                    ? Promise.resolve()
                                    : Promise.reject(
                                        new Error(
                                          "Valor tem que ser maior que zero!",
                                        ),
                                      );
                                },
                              },
                            ]}
                          >
                            <CustomInput.Money
                              precision={6}
                              placeholder="Ex.: 123,000000"
                              min={0}
                              unit="R$/kW"
                            />
                          </Form.Item>
                        </>
                      )}

                      <Divider>
                        <Typography.Text
                          style={{
                            color: "var(--primary-500)",
                            fontWeight: 700,
                          }}
                        >
                          Fora ponta
                        </Typography.Text>
                      </Divider>

                      <Form.Item
                        style={{ width: "100%" }}
                        label={
                          <Flex gap={0} align="center">
                            <span style={{ marginRight: "0.2rem" }}>
                              Consumo médio mensal
                            </span>
                            <Tooltip
                              title="Ao alterar esse campo diretamente os valores
                                  mês-a-mês serão limpos"
                            >
                              <AiOutlineQuestionCircle
                                size={16}
                                style={{ color: "var(--gray-500)" }}
                              />
                            </Tooltip>
                            <AntdButton
                              type="link"
                              onClick={() =>
                                setIsMonthConsumptionFPModalOpen(true)
                              }
                              data-cy="average-monthly-consumption-fora-ponta"
                            >
                              Mês a mês
                            </AntdButton>
                          </Flex>
                        }
                        name="averageConsumptionFP"
                        extra={
                          <Flex vertical>
                            {estimatedPowerPlantError ? (
                              <span style={{ color: "var(--red-500)" }}>
                                Falha ao carregar potência estimada:{" "}
                                {estimatedPowerPlantError?.message}
                              </span>
                            ) : (
                              <Flex gap={4}>
                                Potência estimada da usina:
                                <Spin
                                  spinning={isLoadingEstimatedPowerPlant}
                                  size="small"
                                >
                                  <span>
                                    {parsePotency(
                                      estimatedPowerPlant?.expectedPowerFull ??
                                        0,
                                    )}
                                  </span>
                                </Spin>
                              </Flex>
                            )}
                            {isMonthToMonthFPActive && (
                              <Flex align="center">
                                <Tooltip title="Os valores foram inseridos mês-a-mês. ATENÇÃO: ao alterar esse campo os valores mês-a-mês serão perdidos.">
                                  <Flex
                                    align="center"
                                    style={{
                                      cursor: "help",
                                    }}
                                  >
                                    <FiAlertTriangle
                                      size={14}
                                      style={{ color: "var(--red-500)" }}
                                    />
                                    <span
                                      style={{
                                        color: "var(--red-500)",
                                        marginLeft: "0.25rem",
                                      }}
                                    >
                                      Inserido mês-a-mês
                                    </span>
                                  </Flex>
                                </Tooltip>
                              </Flex>
                            )}
                          </Flex>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Por favor, insira Consumo médio",
                          },
                          {
                            validator(_rule, value) {
                              return value !== 0
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error("Valor não pode ser zero!"),
                                  );
                            },
                          },
                        ]}
                      >
                        <CustomInput.UnitMeasurement
                          min={0}
                          precision="2"
                          unitMeasurement="KWh"
                          placeholder="Ex.: 33,00"
                          style={{ width: "100%" }}
                          onChange={() => {
                            form.setFieldsValue({
                              isMonthToMonthFPActive: false,
                              monthlyValuesFP: undefined,
                            });
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Tarifa TE"
                        name="teFP"
                        tooltip="Tarifa TE com imposto"
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Campo é obrigatório",
                          },
                          {
                            validator(_rule, _value) {
                              return isTaxesValuesValid("teFP/tusdFP")
                                ? Promise.resolve()
                                : Promise.resolve(
                                    new Error(
                                      "A soma das tarifa TE + TUSD não podem ser zero",
                                    ),
                                  );
                            },
                          },
                        ]}
                        extra={
                          ENV.AMBIENT === "DEVELOPMENT" ? (
                            <AntdButton
                              type="link"
                              size="small"
                              style={{ margin: 0, padding: 0 }}
                              disabled={!electricUtilityName}
                              onClick={() => {
                                setAneelModalState({
                                  isOpen: true,
                                  voltageType: "MEDIUM",
                                });
                              }}
                            >
                              Buscar tarifas?
                            </AntdButton>
                          ) : (
                            MemoizedButtonSelectAneel
                          )
                        }
                      >
                        <CustomInput.Money
                          unit="R$/kWh"
                          min={0}
                          onChange={(value) =>
                            onChangeTaxFPValues(value as number, "tusdFP")
                          }
                          style={{
                            width: "100%",
                          }}
                          precision={6}
                          placeholder="Ex.: 1,000000"
                        />
                      </Form.Item>

                      <Form.Item
                        label="Tarifa TUSD"
                        tooltip="Tarifa TUSD com imposto"
                        name="tusdFP"
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Campo é obrigatório",
                          },
                          {
                            validator(_rule, _value) {
                              return isTaxesValuesValid("teFP/tusdFP")
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error(
                                      "A soma das tarifa TE + TUSD não podem ser zero",
                                    ),
                                  );
                            },
                          },
                        ]}
                      >
                        <CustomInput.Money
                          unit="R$/kWh"
                          min={0}
                          onChange={(value) =>
                            onChangeTaxFPValues(value as number, "teFP")
                          }
                          style={{
                            width: "100%",
                          }}
                          precision={6}
                          placeholder="Ex.: 1,000000"
                        />
                      </Form.Item>
                      <Form.Item
                        label="Tarifa de Fio B"
                        name="wireBTaxFP"
                        tooltip="Tarifa de fio B com imposto - Fornecido pela concessionária de energia e utilizado no cálculo de retorno financeiro junto com a taxa de simultaneidade e a progressão de cobrança prevista na legislação."
                        style={{ width: "100%" }}
                        rules={[{ required: true }]}
                      >
                        <CustomInput.Money
                          unit="R$/kWh"
                          min={0}
                          style={{
                            width: "100%",
                          }}
                          precision={6}
                          placeholder="Ex.: 1,000000"
                        />
                      </Form.Item>
                      {taxType === "BLUE" && (
                        <>
                          <Form.Item
                            label="Demanda contratada"
                            style={{ width: "100%" }}
                            name="contractedDemandFP"
                            rules={[
                              {
                                validator: (_rule, value) => {
                                  return value !== 0
                                    ? Promise.resolve()
                                    : Promise.reject(
                                        new Error(
                                          "Valor tem que ser maior que zero!",
                                        ),
                                      );
                                },
                              },
                            ]}
                          >
                            <CustomInput.UnitMeasurement
                              precision="2"
                              unitMeasurement="KW"
                              min={0}
                              style={{ width: "100%" }}
                              placeholder="1,33"
                            />
                          </Form.Item>
                          <Form.Item
                            label="Tarifa de Demanda "
                            style={{ width: "100%" }}
                            name="contractedDemandTaxFP"
                            rules={[
                              {
                                validator: (_rule, value) => {
                                  return value !== 0
                                    ? Promise.resolve()
                                    : Promise.reject(
                                        new Error(
                                          "Valor tem que ser maior que zero!",
                                        ),
                                      );
                                },
                              },
                            ]}
                          >
                            <CustomInput.Money
                              precision={6}
                              placeholder="Ex.: 123,000000"
                              min={0}
                              unit="R$/kW"
                            />
                          </Form.Item>
                        </>
                      )}

                      <Divider>
                        <Typography.Text
                          style={{
                            color: "var(--primary-500)",
                            fontWeight: 700,
                          }}
                        >
                          Outras informações
                        </Typography.Text>
                      </Divider>

                      {taxType === "GREEN" && (
                        <Form.Item
                          label="Demanda contratada"
                          style={{ width: "100%" }}
                          name="contractedDemand"
                          rules={[
                            {
                              validator: (_rule, value) => {
                                if (!value) {
                                  return Promise.resolve();
                                }

                                return value > 0
                                  ? Promise.resolve()
                                  : Promise.reject(
                                      new Error(
                                        "Valor tem que ser maior que zero!",
                                      ),
                                    );
                              },
                            },
                          ]}
                        >
                          <CustomInput.UnitMeasurement
                            precision="2"
                            unitMeasurement="KW"
                            min={0}
                            style={{ width: "100%" }}
                            placeholder="1,33"
                          />
                        </Form.Item>
                      )}

                      {taxType === "GREEN" && (
                        <>
                          <Form.Item
                            label="Demanda pós solar"
                            name="postSolarDemandKw"
                            style={{ width: "100%" }}
                            tooltip="Apenas caso seja feito ajuste de demanda junto com o projeto de energia solar."
                          >
                            <CustomInput.UnitMeasurement
                              precision="2"
                              unitMeasurement="KW"
                              min={0}
                              style={{ width: "100%" }}
                              placeholder="1,55"
                            />
                          </Form.Item>

                          <Form.Item
                            label="Tarifa de demanda contratada"
                            style={{ width: "100%" }}
                            name="contractedDemandTax"
                            rules={[
                              {
                                validator: (_rule, value) => {
                                  return value !== 0
                                    ? Promise.resolve()
                                    : Promise.reject(
                                        new Error(
                                          "Valor tem que ser maior que zero!",
                                        ),
                                      );
                                },
                              },
                            ]}
                          >
                            <CustomInput.Money
                              precision={6}
                              placeholder="Ex.: 123,000000"
                              min={0}
                              unit="R$/kW"
                            />
                          </Form.Item>
                        </>
                      )}

                      <Form.Item
                        label="Tusd G"
                        style={{ width: "100%" }}
                        name="contractedDemandTaxTusdG"
                        rules={[
                          {
                            validator: (_rule, value) => {
                              return value !== 0
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error(
                                      "Valor tem que ser maior que zero!",
                                    ),
                                  );
                            },
                          },
                        ]}
                      >
                        <CustomInput.Money
                          precision={6}
                          placeholder="Ex.: 123,000000"
                          min={0}
                          unit="R$/kW"
                        />
                      </Form.Item>

                      <Form.Item
                        label="Média energia reativa"
                        name="reactivePowerAverage"
                        rules={[{ required: false }]}
                        style={{ width: "100%" }}
                      >
                        <CustomInput.Money
                          precision={6}
                          placeholder="Ex.: 1,000000"
                          min={0}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Taxa de iluminação pública - antes da solar"
                        name="mediumVoltageCosipTaxBefore"
                        rules={[
                          {
                            required: fieldSettings?.cosipTaxBefore?.isRequired,
                          },
                        ]}
                        tooltip="Valor utilizado para estimar a fatura antes da instalação do sistema de energia solar"
                        style={{ width: "100%" }}
                        hidden={fieldSettings?.cosipTaxBefore?.isHidden}
                      >
                        <CustomInput.Money
                          precision={2}
                          placeholder="Ex.: 1,00"
                          min={0}
                          readOnly={!fieldSettings?.cosipTaxBefore?.isEditable}
                          style={getFieldReadonlyStyle(
                            !!fieldSettings?.cosipTaxBefore?.isEditable,
                            !!(currentTheme === "dark"),
                          )}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Taxa de iluminação pública - depois da solar"
                        name="mediumVoltageCosipTaxAfter"
                        rules={[
                          {
                            required: fieldSettings?.cosipTaxAfter?.isRequired,
                          },
                        ]}
                        tooltip="Valor utilizado para estimar a fatura após a instalação do sistema de energia solar"
                        style={{ width: "100%" }}
                        hidden={fieldSettings?.cosipTaxAfter?.isHidden}
                      >
                        <CustomInput.Money
                          precision={2}
                          placeholder="Ex.: 1,00"
                          min={0}
                          readOnly={!fieldSettings?.cosipTaxAfter?.isEditable}
                          style={getFieldReadonlyStyle(
                            !!fieldSettings?.cosipTaxAfter?.isEditable,
                            !!(currentTheme === "dark"),
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Considerar consumo das unidades no dimensionamento da usina"
                        name="considerConsumptionUnits"
                        rules={[
                          {
                            required:
                              fieldSettings?.considerConsumptionUnits
                                ?.isRequired,
                          },
                        ]}
                        hidden={
                          fieldSettings?.considerConsumptionUnits?.isHidden
                        }
                      >
                        <Radio.Group
                          defaultValue={true}
                          disabled={
                            !fieldSettings?.considerConsumptionUnits?.isEditable
                          }
                          style={getFieldReadonlyStyle(
                            !!fieldSettings?.considerConsumptionUnits
                              ?.isEditable,
                            !!(currentTheme === "dark"),
                          )}
                          options={[
                            {
                              label: "Sim",
                              value: true,
                            },
                            {
                              label: "Não",
                              value: false,
                            },
                          ]}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Considerar crédito acumulado no payback"
                        name="accumulatedCreditsUsedInPayback"
                        initialValue={false}
                        rules={[
                          {
                            required:
                              fieldSettings?.accumulatedCreditsUsedInPayback
                                ?.isRequired,
                          },
                        ]}
                        hidden={
                          fieldSettings?.accumulatedCreditsUsedInPayback
                            ?.isHidden
                        }
                      >
                        <Radio.Group
                          defaultValue={false}
                          disabled={
                            !fieldSettings?.accumulatedCreditsUsedInPayback
                              ?.isEditable
                          }
                          style={getFieldReadonlyStyle(
                            !!fieldSettings?.accumulatedCreditsUsedInPayback
                              ?.isEditable,
                            !!(currentTheme === "dark"),
                          )}
                          options={[
                            {
                              label: "Sim",
                              value: true,
                            },
                            {
                              label: "Não",
                              value: false,
                            },
                          ]}
                        />
                      </Form.Item>

                      {MemoizedButtonConsumerUnits}
                    </Flex>
                  )}
                  <Flex vertical style={{ marginTop: 24, width: "100%" }}>
                    <ProposalCustomFieldsForm
                      initialValues={customFieldsInitialValues}
                      proposalSection="INVOICE_DATA"
                      pricingTypeId={proposalState?.pricingTypeId}
                      form={form}
                      instanceKey="InvoiceData"
                    />

                    <ProposalCustomFieldsForm
                      initialValues={customFieldsInitialValues}
                      proposalSection="PLANT_DATA"
                      pricingTypeId={proposalState?.pricingTypeId}
                      form={form}
                      instanceKey="PlantData"
                    />
                  </Flex>
                </Col>

                <Col
                  span={screens.md ? 2 : 24}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Divider
                    type={screens.md ? "vertical" : "horizontal"}
                    style={{ height: "100%" }}
                  />
                </Col>
                <Col span={screens.md ? 11 : 24}>
                  <Typography.Title level={4} style={{ marginBottom: "1rem" }}>
                    Dados da Usina
                  </Typography.Title>
                  <Form.Item
                    label="Perfis de Performance"
                    name="performanceProfiles"
                    tooltip="Este campo não será salvo na proposta, ele é usado apenas para buscar perfis de performance onde o valor desse perfil será atribuída ao campo abaixo (Taxa de Desempenho)."
                  >
                    <Select.PerformanceProfiles
                      onLoad={(value) => {
                        form.setFieldValue("performanceProfiles", value);
                      }}
                      onChange={(_, option) => {
                        form.setFieldValue(
                          "performanceRate",
                          option?.record.value,
                        );
                        setCurrentPerformance(option?.record.value ?? 0);
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Taxa de Desempenho"
                    name="performanceRate"
                    rules={[{ required: true }]}
                    style={{ marginBottom: 0 }}
                  >
                    <CustomInput.PerformanceRate
                      customId={proposalId ?? 0}
                      min={0}
                      max={100}
                      defaultPerformanceRateValue={
                        initialValues?.performanceRate
                      }
                      style={{ width: "100%" }}
                      onChange={(value) => {
                        const parsedValue = value as number;
                        handleGenerationPerMonth(
                          parsedValue,
                          form.getFieldValue("generationFactor"),
                        );
                      }}
                      completeWithDefaultValueInFirstLoad={
                        !initialValues?.performanceRate
                      }
                      currentPerformance={currentPerformance}
                      setCurrentPerformance={handleSetCurrentPerformance}
                      readOnly={cannotEditPerformanceRate}
                      disabled={cannotEditPerformanceRate}
                      cannotSelectMonthToMonth={cannotEditPerformanceRate}
                      hasDirectlyInserted={hasDirectlyInserted}
                      setHasDirectlyInserted={setHasDirectlyInserted}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Fator de geração"
                    name="generationFactor"
                    rules={[{ required: true }]}
                    style={{ marginBottom: 0 }}
                    tooltip="Dado coletado do Atlas Brasileiro de Energia Solar"
                  >
                    <CustomInput.GlobalIrradiation
                      customId={proposalId ?? 0}
                      extra={`${generationPerMonth} kWh / kWp ao mês`}
                      defaultIrradiationValue={{
                        ...initialValues?.monthlySolarRadiation,
                        average: initialValues?.generationFactor ?? 0,
                      }}
                      irradiationType={irradiationTypeState}
                      onChange={(solarimetry) => {
                        const monthlySolar: ISizingStepFormValues["monthlySolarRadiation"] =
                          {
                            irradiationType:
                              solarimetry?.irradiationType ?? "DAILY",
                            monthlySolarRadiation: {
                              ...solarimetry,
                            },
                          };

                        form.setFieldValue(
                          "generationFactor",
                          solarimetry?.average,
                        );

                        form.setFieldValue(
                          "monthlySolarRadiation",
                          monthlySolar,
                        );

                        handleGenerationPerMonth(
                          form.getFieldValue("performanceRate"),
                          solarimetry?.average ?? 0,
                        );
                      }}
                      completeWithDefaultValueInFirstLoad={
                        !initialValues?.generationFactor
                      }
                      setIrradiationType={handleSetIrradiationTypeState}
                      setTypeOfIrradiationReturnedAfterUpdate={(value) =>
                        setTypeOfIrradiationReturnedAfterUpdate(value)
                      }
                      solarimetryModalOpen={solarimetryModalOpen}
                      onCloseSolarimetryModal={onCloseSolarimetryModal}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Temperatura mínima"
                    name="minCelsiusTemperature"
                    rules={[
                      {
                        required:
                          fieldSettings?.minCelsiusTemperature?.isRequired,
                      },
                    ]}
                    hidden={fieldSettings?.minCelsiusTemperature?.isHidden}
                  >
                    <CustomInput.UnitMeasurement
                      precision="0"
                      unitMeasurement="Celsius"
                      placeholder="Ex.: 24"
                      readOnly={
                        !fieldSettings?.minCelsiusTemperature?.isEditable
                      }
                      style={getFieldReadonlyStyle(
                        !!fieldSettings?.minCelsiusTemperature?.isEditable,
                        !!(currentTheme === "dark"),
                      )}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Tipo de estrutura"
                    name="idStructureType"
                    rules={[{ required: true }]}
                  >
                    <Select.CompanyStructureType
                      placeholder="Selecionar estrutura..."
                      onChange={(id) => {
                        if (id !== initialValues?.idStructureType) {
                          form.setFieldValue("idLabor", undefined);
                        }
                      }}
                      filters={{
                        active: true,
                      }}
                    />
                  </Form.Item>
                  {canDisplayRegionField && (
                    <Form.Item
                      label="Região"
                      name="regionId"
                      rules={[{ required: wegIsEnabled }]}
                    >
                      <Select.EquipmentRegion allowClear />
                    </Form.Item>
                  )}
                  <Form.Item
                    label="Incluir estrutura em"
                    name="structureGroup"
                    rules={[{ required: true }]}
                  >
                    <Select.StructureGroup
                      disabled={userCanNotChangeStructureGroup}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Mão de obra"
                    name="idLabor"
                    tooltip="Precificação de mão de obra sugerida por estrutura, porém você poderá livremente mudar essa opção."
                    help="Será pré selecionada uma opção com base no que for selecionado em estrutura"
                    rules={[{ required: fieldSettings?.idLabor?.isRequired }]}
                    hidden={fieldSettings?.idLabor?.isHidden}
                  >
                    <Select.LaborSuggested
                      placeholder="Selecionar mão de obra..."
                      structureId={idStructureType}
                      disabled={!fieldSettings?.idLabor?.isEditable}
                      style={getFieldReadonlyStyle(
                        !!fieldSettings?.idLabor?.isEditable,
                        !!(currentTheme === "dark"),
                      )}
                      onLoad={(value) => {
                        if (value) {
                          form.setFieldValue("idLabor", value);
                        }
                      }}
                      initialValue={idLabor}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Perfis de Simultaneidade"
                    name="simultaneityProfiles"
                    tooltip="Este campo não será salvo na proposta, ele é usado apenas para buscar perfis de simultaneidade onde a taxa desse perfil será atribuída ao campo abaixo (Taxa de simultaneidade)."
                  >
                    <Select.SimultaneityProfiles form={form} />
                  </Form.Item>
                  <Form.Item
                    label="Taxa de simultaneidade"
                    tooltip="Estimativa de quanto haverá de consumo instantâneo na unidade consumidora"
                    name="simultaneityFactor"
                    rules={[
                      {
                        pattern: new RegExp(
                          /^(?:100(?:\.0{1,2})?|\d{1,2}(?:\.\d{1,2})?|0(?:\.[1-9]\d{0,1})?)$/,
                        ),
                        message: "Somente porcentagem de 0 e 100",
                      },
                      { required: true },
                    ]}
                  >
                    <CustomInput.UnitMeasurement
                      precision="0"
                      unitMeasurement="%"
                      placeholder="Ex.: 10"
                      disabled={cannotEditSimultaneityFactor}
                      readOnly={cannotEditSimultaneityFactor}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Sobredimensionamento (FDI)"
                    name="fdi"
                    tooltip="É o mínimo que a potência do inversor pode ser menor que a potência dos módulos. O FDI só é utilizado quando o sistema faz o dimensionamento para o usuário a partir de módulos e inversores avulsos. Kits fechados não são afetados por esse valor."
                    rules={[{ required: fieldSettings?.fdi?.isRequired }]}
                    hidden={fieldSettings?.fdi?.isHidden}
                  >
                    <CustomInput.UnitMeasurement
                      precision="0"
                      unitMeasurement="%"
                      placeholder="Ex.: 15"
                      readOnly={!fieldSettings?.fdi?.isEditable}
                      style={getFieldReadonlyStyle(
                        !!fieldSettings?.fdi?.isEditable,
                        !!(currentTheme === "dark"),
                      )}
                    />
                  </Form.Item>

                  {tensionType === "LOW" && (
                    <Form.Item
                      label="Limite de potência de inversor (Trafo)"
                      name="inverterTransformerPowerLimitKwp"
                      tooltip="Se preenchido, será o limite máximo de potência (CA) de inversor permitido no kit."
                      rules={[
                        {
                          required:
                            fieldSettings?.inverterTransformerPowerLimitKwp
                              ?.isRequired,
                        },
                      ]}
                      hidden={
                        fieldSettings?.inverterTransformerPowerLimitKwp
                          ?.isHidden
                      }
                    >
                      <CustomInput.UnitMeasurement
                        unitMeasurement="kVa"
                        precision="2"
                        placeholder="Ex.: 1,00"
                        min={0}
                        readOnly={
                          !fieldSettings?.inverterTransformerPowerLimitKwp
                            ?.isEditable
                        }
                        style={getFieldReadonlyStyle(
                          !!fieldSettings?.inverterTransformerPowerLimitKwp
                            ?.isEditable,
                          !!(currentTheme === "dark"),
                        )}
                      />
                    </Form.Item>
                  )}
                  <Form.Item
                    label={<span>Validade da proposta </span>}
                    name="expirationDate"
                    extra={
                      <Flex vertical>
                        {expirationDate?.isBefore(Date.now(), "day") && (
                          <Alert
                            message="A data atual é uma data passada"
                            type="info"
                            showIcon
                            style={{
                              height: 32,
                              marginTop: 4,
                            }}
                          />
                        )}
                        {needGetNewExpirationDate === true && (
                          <Alert
                            message="A potência mudou, deseja revalidar a data de expiração?"
                            type="info"
                            showIcon
                            style={{
                              height: 32,
                              marginTop: 4,
                            }}
                            action={
                              <AntdButton
                                type="link"
                                onClick={() => reloadExpirationDate()}
                                loading={isLoadingExpirationDate}
                                size="small"
                              >
                                Revalidar
                              </AntdButton>
                            }
                          />
                        )}
                      </Flex>
                    }
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      format="DD/MM/YYYY"
                      allowClear={false}
                      disabled={cannotEditProposalExpiration}
                      disabledDate={(currentDate) =>
                        currentDate.isBefore(Date.now(), "day")
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    label="Observação"
                    name="note"
                    rules={[{ required: fieldSettings?.note?.isRequired }]}
                    hidden={fieldSettings?.note?.isHidden}
                  >
                    <InputTextArea
                      placeholder="Adicione uma observação para sua proposta"
                      readOnly={!fieldSettings?.note?.isEditable}
                      style={getFieldReadonlyStyle(
                        !!fieldSettings?.note?.isEditable,
                        !!(currentTheme === "dark"),
                      )}
                    />
                  </Form.Item>

                  <ProposalCustomFieldsForm
                    initialValues={customFieldsInitialValues}
                    proposalSection="POWER_PLANT_DATA"
                    pricingTypeId={proposalState?.pricingTypeId}
                    form={form}
                    instanceKey="PowerPlantData"
                  />
                </Col>
              </Row>
            </Spin>
          </Layout.Content>
          <Layout.Footer
            style={{
              background: "var(--background-color)",
              padding: screens.desktop ? "0.5rem 2rem" : "0.5rem 1.5rem",
              width: "100%",
              height: screens.desktop ? "2.5rem" : "1.5rem",
              marginBottom: "0rem",
              boxShadow: !screens.desktop
                ? currentTheme === "default"
                  ? "0px -2px 4px 0px rgba(0,0,0,0.12)"
                  : "0px -2px 4px 0px rgba(255,255,255,0.12)"
                : undefined,
              zIndex: 1000,
            }}
          >
            <Flex justify="end" gap={16}>
              <Flex gap={12} justify="end">
                <Form.Item style={{ margin: 0 }}>
                  <AntdButton type="link" onClick={onCancel} size="small">
                    <Flex align="center" gap={8}>
                      {!isLoadingUpdateSizingStep && (
                        <AiOutlineArrowLeft size={18} />
                      )}
                      {cancelText}
                    </Flex>
                  </AntdButton>
                </Form.Item>
                <Form.Item style={{ margin: 0 }}>
                  <AntdButton
                    type="primary"
                    htmlType="submit"
                    data-cy="button-continue-proposal-generation"
                    loading={isLoadingUpdateSizingStep}
                    icon={
                      !isLoadingUpdateSizingStep && (
                        <AiOutlineArrowRight size={18} />
                      )
                    }
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.25rem",
                    }}
                  >
                    Prosseguir
                  </AntdButton>
                </Form.Item>
              </Flex>
            </Flex>
          </Layout.Footer>
        </Layout>
      </Form>

      <MonthFormModal
        isOpen={isMonthConsumptionModalOpen}
        onClose={() => setIsMonthConsumptionModalOpen(false)}
        onSuccess={() => {
          form.setFieldValue("isMonthToMonthActive", true);
        }}
        form={form}
        averageFormKey="averageConsumption"
        monthlyFormKey="monthlyValues"
        destroyOnClose
      />

      <MonthFormModal
        isOpen={isMonthConsumptionPModalOpen}
        onClose={() => setIsMonthConsumptionPModalOpen(false)}
        onSuccess={() => {
          form.setFieldValue("isMonthToMonthPActive", true);
        }}
        form={form}
        averageFormKey="averageConsumptionP"
        monthlyFormKey="monthlyValuesP"
        title="Consumo ponta mês-a-mês"
      />

      <MonthFormModal
        isOpen={isMonthConsumptionFPModalOpen}
        onClose={() => setIsMonthConsumptionFPModalOpen(false)}
        onSuccess={() => {
          form.setFieldValue("isMonthToMonthFPActive", true);
        }}
        form={form}
        averageFormKey="averageConsumptionFP"
        monthlyFormKey="monthlyValuesFP"
        title="Consumo fora-ponta mês-a-mês"
      />

      {/* OLD */}
      <Modal.SelectConcessionaireProfileForm
        isOpen={isConcessionaireProfileModalOpen}
        onClose={() => setIsConcessionaireProfileModalOpen(false)}
        formProps={{
          concessionaireId: electricUtilityId,
          voltageType: tensionType ?? "LOW",
          modalityTaxType: taxType,
          onSubmit: (concessionaireProfile) => {
            setConcessionaireProfile({
              ...concessionaireProfile,
              id: concessionaireProfile.id ?? 0,
            });
            setIsConcessionaireProfileModalOpen(false);
          },
        }}
      />
      {/* ANEEL LIST */}
      <Modal.AneelTable
        isOpen={aneelModalState.isOpen}
        onClose={() => {
          setAneelModalState((current) => ({ ...current, isOpen: false }));
        }}
        title="Selecione o item"
        isSelectable
        tableProps={{
          displayExtraFilters: true,
          voltageType: aneelModalState.voltageType,
          electricUtilityName: electricUtilityName ?? "",
        }}
        onHandleClickOk={(record) => {
          form.setFieldValue("te", record.lowVoltageTaxes?.te);
          form.setFieldValue("tusd", record.lowVoltageTaxes?.tusd);
          form.setFieldValue("wireB", record.lowVoltageTaxes?.wireB);
          form.setFieldValue("teP", record.mediumVoltageTaxes?.teP);
          form.setFieldValue("tusdP", record.mediumVoltageTaxes?.tusdP);
          form.setFieldValue("wireB", record.mediumVoltageTaxes?.wireBP);
          form.setFieldValue("teFP", record.mediumVoltageTaxes?.teFP);
          form.setFieldValue("tusdFP", record.mediumVoltageTaxes?.tusdFP);
          form.setFieldValue("wireB", record.mediumVoltageTaxes?.wireBFP);
        }}
      />
    </>
  );
}
