import styled from "@emotion/styled";
import { Button, Flex } from "antd";

export const Container = styled(Flex)`
  width: auto !important;
  max-width: 34rem !important;

  padding: 1rem !important;
  margin: 0 !important;

  border: 1px solid var(--gray-300) !important;
  border-radius: 0.5rem !important;
  background-color: var(--background-container);

  display: flex;
  flex-direction: column;

  .ant-dropdown .ant-dropdown-menu,
  .ant-dropdown-menu-submenu .ant-dropdown-menu,
  .teste {
    padding: 0 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
  }
`;

export const StyledNotificationsButton = styled(Button)`
  padding: 0 !important;
  margin: 0 !important;

  padding-inline-start: 0 !important;
  padding-inline-end: 0 !important;

  border: none !important;
  border-radius: 100% !important;
  color: var(--gray-400) !important;

  display: flex;
  justify-content: center;
  align-items: center;
`;
