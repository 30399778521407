import { useEffect } from "react";

import type {
  IChangeProfileImageFormProps,
  IChangeProfileImageFormValues,
} from "./interface";

import { Avatar } from "~components/Avatar";
import { X, Image as ImageIcon, Delete } from "~estrela-ui/icons";
import { useFetchDeleteProfileImage } from "~hooks/api";
import { useFetchUploadProfileImage } from "~hooks/api/users/useFetchUploadProfileImage";
import { useAppConfig } from "~hooks/useAppConfig";
import { useSession } from "~hooks/useSession";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import {
  Button,
  Flex,
  Form,
  Skeleton,
  Spin,
  Tooltip,
  Typography,
  Upload,
} from "antd";
import SkeletonAvatar from "antd/es/skeleton/Avatar";
import { RcFile } from "antd/lib/upload";

const TYPE_BY_FILE_EXTENSION = ["image/jpeg", "image/jpg", "image/png"];

const getMimeType = (fileType: string): boolean => {
  return TYPE_BY_FILE_EXTENSION.includes(fileType);
};

export function ChangeProfileImageForm({
  formInstance,
  onError,
  onSuccess,
}: IChangeProfileImageFormProps) {
  const { message } = useAppConfig();
  const { user, sessionStatus } = useSession();
  const [form] = Form.useForm(formInstance);

  // WATCHERS
  const fileType: IChangeProfileImageFormValues["fileType"] = Form.useWatch(
    "fileType",
    form,
  );

  const file: RcFile | undefined = Form.useWatch("file", {
    preserve: true,
    form: form,
  });

  const base64: string | undefined = Form.useWatch("base64", {
    preserve: true,
    form: form,
  });

  const { mutate: uploadProfileImage, isLoading: isUpdatingProfileImage } =
    useFetchUploadProfileImage({
      options: {
        onSuccess,
        onError: (error) => {
          form.setFieldsValue({
            file: undefined,
            base64: undefined,
            fileType: undefined,
          });

          if (typeof onError === "function") {
            onError(error);
          }
        },
      },
    });

  const { mutate: fetchDeleteProfileImage, isLoading: isDeletingProfileImage } =
    useFetchDeleteProfileImage({
      options: {
        onSuccess: () => {
          form.setFieldsValue({
            file: undefined,
            base64: undefined,
            fileType: undefined,
          });
        },
      },
    });

  useEffect(() => {
    if (user?.avatarUrl) {
      form.setFieldsValue({ base64: user.avatarUrl });
    }
  }, [form, user?.avatarUrl]);

  useEffect(() => {
    if (file) {
      form.submit();
    }
  }, [file, form]);

  function processImageRcFile(file: RcFile) {
    const type = file.type;
    const mimeType = getMimeType(type);
    if (!mimeType) {
      return message.error(
        `${file.name} não é um arquivo do tipo ${Object.keys(fileType).join(", ")}!`,
      );
    }

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = async (e) => {
      try {
        message.open({
          key: "LOADING_INDICATOR_KEY",
          type: "loading",
          content: "Processando arquivo...",
          duration: 9999,
        });

        const loadedString = e.target?.result as string;

        if (!loadedString) {
          throw new Error(
            "Client error: falha ao converter arquivo para base64",
          );
        }

        if (file.size > 10485760) {
          throw new Error("Client error: arquivo muito grande, máximo de 10MB");
        }

        form.setFieldsValue({
          base64: loadedString,
          file: file,
          fileType: type
            .split("/")[1]
            .toUpperCase() as IChangeProfileImageFormValues["fileType"],
        });

        message.open({
          key: "LOADING_INDICATOR_KEY",
          type: "success",
          content: "Arquivo processado",
          duration: 2,
        });
      } catch (error: any) {
        message.open({
          key: "LOADING_INDICATOR_KEY",
          type: "error",
          content: error?.message,
          duration: 2,
        });
      }
    };

    reader.onerror = () => {
      form.setFieldsValue({
        file: undefined,
        base64: undefined,
        fileType: undefined,
      });

      message.error("Client error: falha ao ler arquivo");
    };
  }

  async function beforeUpload(file: RcFile) {
    return processImageRcFile(file);
  }

  function handleSubmit() {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      uploadProfileImage(formData);
    }
  }

  if (isUpdatingProfileImage) {
    return (
      <Flex
        vertical
        align="center"
        justify="center"
        style={{ marginBottom: "1rem" }}
        gap={8}
      >
        <SkeletonAvatar active style={{ width: 128, height: 128 }} />
        <Skeleton.Input style={{ width: 64, height: 16 }} />
      </Flex>
    );
  }

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      style={{ width: "100%" }}
    >
      <Spin spinning={isDeletingProfileImage}>
        {base64 ? (
          <Flex
            vertical
            align="center"
            justify="center"
            style={{ marginBottom: "1rem" }}
          >
            <Avatar.CustomUser
              avatarUrl={base64}
              sessionStatus={sessionStatus}
              userId={user?.id}
              width={128}
              height={128}
              quality={100}
            />
            <Tooltip title="Remover imagem">
              <Button
                type="link"
                onClick={() => {
                  fetchDeleteProfileImage();
                }}
              >
                <div className="flex justify-center items-center gap-2">
                  <SolarzTypography
                    fontWeight="regular"
                    hierarchy="paragraph2"
                    variant="link"
                  >
                    imagem.png
                  </SolarzTypography>
                  <SolarzTypography
                    fontWeight="regular"
                    hierarchy="paragraph2"
                    variant="link"
                    style={{ marginTop: "2px" }}
                  >
                    <X />
                  </SolarzTypography>
                </div>
              </Button>
            </Tooltip>
          </Flex>
        ) : (
          <Upload.Dragger
            beforeUpload={beforeUpload}
            multiple={false}
            showUploadList={false}
            style={{ marginBottom: "1rem" }}
            accept=".jpg,.jpeg,.png,.webp"
          >
            <Flex vertical align="center" style={{ width: "100%", padding: 5 }}>
              <ImageIcon
                style={{ height: 64, width: 64, color: "var(--primary-500)" }}
              />

              <Typography.Text style={{ color: "var(--text)" }}>
                Clique ou arraste a imagem
              </Typography.Text>

              <Typography.Text
                style={{ color: "var(--gray-500)", marginTop: "0.5rem" }}
              >
                {fileType?.toLowerCase()}
                .JPG, .PNG, até 10MB
              </Typography.Text>
            </Flex>
          </Upload.Dragger>
        )}
      </Spin>
    </Form>
  );
}
