import type { IPipelineSelectProps } from "./interface";
import { StyledSolarzAnchor } from "./styles";

import { Add } from "~estrela-ui/icons";
import { useFetchGetAllPipelines } from "~hooks/api";
import { useSession } from "~hooks/useSession";
import { SolarzSelect } from "~solarzui/SolarzSelect";
import { Divider, Flex } from "antd";

export function PipelineSelect(props: IPipelineSelectProps) {
  const { userHavePermission } = useSession();

  const {
    data: pipelines = [],
    isFetching: isLoadingPipelines,
    error: pipelinesError,
    refetch: refetchPipelines,
  } = useFetchGetAllPipelines({
    options: {
      onSuccess: props.onLoad,
    },
  });

  const userCanCreatePipeline = userHavePermission("CREATE_PIPELINE");

  return (
    <SolarzSelect
      {...props}
      isLoading={isLoadingPipelines}
      options={pipelines.map((pipeline) => {
        return {
          label: pipeline.name,
          record: pipeline,
          value: pipeline.id,
          hidden: !!props.hideOptions?.find((option) => option === pipeline.id),
        };
      })}
      errorMessage={pipelinesError?.message}
      emptyMessage="Nenhum Pipeline encontrado"
      refetchFn={refetchPipelines}
      dropdownRender={
        props.withFooter
          ? (menu) => {
              return (
                <Flex vertical>
                  {menu}
                  <Flex vertical style={{ margin: "8px 16px 0 16px" }}>
                    <Divider style={{ margin: 0 }} />
                    <StyledSolarzAnchor
                      isDisabled={!userCanCreatePipeline}
                      isNextLink
                      href="/funil/criar"
                      style={{ margin: "2px 0 2px 0" }}
                    >
                      <Add />
                      Novo funil
                    </StyledSolarzAnchor>
                  </Flex>
                </Flex>
              );
            }
          : undefined
      }
    />
  );
}
