import { BiErrorCircle } from "react-icons/bi";

import { ITaxesAfterPricingSelectProps, SelectOptionType } from "./interface";

import { useFetchGetAdditionalCostTaxes } from "~hooks/api";
import { Select } from "antd";

export function TaxesAfterPricingSelect({
  value,
  onChange = () => null,
  isEditForm,
  applyTax,
  ...props
}: ITaxesAfterPricingSelectProps) {
  const {
    data: taxes,
    isFetching: isLoadingTaxes,
    error: taxesError,
  } = useFetchGetAdditionalCostTaxes({
    dependencyArray: [applyTax],
    options: {
      onSuccess: (data) => {
        const defaultOption = data?.find((item) => item.defaultTax);

        if (!isEditForm && applyTax === false) {
          onChange(defaultOption?.id);
        }
      },
    },
  });

  const dataSource: SelectOptionType[] = taxes
    ? taxes
      .map((tax) => ({
        label: tax.name,
        value: tax.id,
        record: tax,
      }))
      .filter((tax) => tax.record.active)
    : [];

  const taxValueId = dataSource.find((item) => item.value === value)
    ?.value as number;

  return (
    <Select
      {...props}
      loading={isLoadingTaxes}
      options={dataSource}
      status={taxesError ? "error" : ""}
      value={taxValueId}
      onChange={onChange}
      allowClear
      data-cy="taxes-after-pricing-select"
      suffixIcon={
        taxesError ? (
          <BiErrorCircle size={18} color="var(--red-200)" />
        ) : undefined
      }
    />
  );
}
