import type { ISelectOrganizationFormModalProps } from "./interface";

import { Breadcrumb } from "~components/Breadcrumb";
import { Form } from "~components/Form";
import { Modal } from "antd";

export function SelectOrganizationFormModal({
  isOpen = false,
  onClose = () => undefined,
  maxWidth = 820,
  parentTitleModal = "",
  formProps,
}: ISelectOrganizationFormModalProps) {
  return (
    <Modal
      data-cy="organization-form-select-modal"
      width="90vw"
      style={{
        maxWidth,
      }}
      title={
        <Breadcrumb.NewOrganizationModalInSelect
          parentTitleModal={parentTitleModal}
        />
      }
      open={isOpen}
      footer={null}
      onCancel={onClose}
      destroyOnClose
      closable={false}
    >
      <Form.Organization {...formProps} onCancel={onClose} />
    </Modal>
  );
}
