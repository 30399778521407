import * as React from "react";

import type { IIconProps } from "../@types/globals";
import { cn } from "../lib/utils";

const X: React.FC<IIconProps> = ({ className, ...props }) => (
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    className={cn("pointer-events-none w-6 h-6 stroke-2", className)}
    {...props}
  >
    <title>Fechar</title>
    <path
      d="M20.0001 4.00013L4.00013 20.0001M4.00007 4.00006L20 20"
      strokeLinecap="round"
    />
  </svg>
);

X.displayName = "X";

export { X };
