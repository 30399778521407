import { useState } from "react";

import {
  IUpdateProposalDealForm,
  IUpdateProposalDealFormProps,
} from "./interface";

import { Select } from "~components/Select";
import { useFetchUpdateProposalDeal } from "~hooks/api/proposal/useFetchUpdateProposalDeal";
import { Button, Flex, Form, Radio } from "antd";

export function UpdateProposalDealForm({
  customId,
  onSubmitForm = () => null,
  onCancel = () => null,
}: IUpdateProposalDealFormProps) {
  const [showPipelineDeals, setShowPipelineDeals] = useState(false);

  const [form] = Form.useForm<IUpdateProposalDealForm>();

  const pipelineId = Form.useWatch("pipelineId", form);

  const { mutate: updateProposalDeal, isLoading: isUpdatingProposalDeal } =
    useFetchUpdateProposalDeal({
      options: {
        onSuccess: onSubmitForm,
      },
    });

  async function handleSubmit(proposalDeal: IUpdateProposalDealForm) {
    updateProposalDeal({
      ...proposalDeal,
      customId,
    });
  }

  return (
    <Form
      form={form}
      style={{ width: "100%" }}
      onFinish={handleSubmit}
      layout="vertical"
    >
      <Flex vertical gap={12}>
        <Form.Item
          label="Pipeline"
          name="pipelineId"
          rules={[{ required: true }]}
          style={{
            marginBottom: 0,
          }}
        >
          <Select.PipedrivePipeline />
        </Form.Item>

        <Form.Item
          style={{
            marginBottom: 0,
          }}
          rules={[{ required: true }]}
          label="Deseja usar um negócio existente?"
          name="useAvailableDeal"
        >
          <Radio.Group
            options={[
              {
                label: "Sim",
                value: true,
              },
              {
                label: "Não, criar novo",
                value: false,
              },
            ]}
            onChange={(event) => {
              setShowPipelineDeals(event.target.value);
            }}
          />
        </Form.Item>

        {showPipelineDeals && (
          <Form.Item
            label="Négocio"
            name="pipedriveDealId"
            rules={[{ required: true }]}
          >
            <Select.PipedrivePipelineDeals
              disabled={!pipelineId}
              pipelineId={pipelineId}
            />
          </Form.Item>
        )}
      </Flex>

      <Flex gap={12} justify="end">
        <Form.Item style={{ margin: 0 }}>
          <Button onClick={onCancel}>Cancelar</Button>
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={isUpdatingProposalDeal}
          >
            Confirmar
          </Button>
        </Form.Item>
      </Flex>
    </Form>
  );
}
