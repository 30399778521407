import { useMemo, useState } from "react";
import { AiOutlineDelete, AiFillCrown } from "react-icons/ai";
import { BsBuilding } from "react-icons/bs";
import { FaUserEdit, FaPen } from "react-icons/fa";

import type { IUserProfilesListProps } from "./interface";

import { Avatar } from "~components/Avatar";
import { Menu } from "~components/Menu";
import { Modal } from "~components/Modal";
import { createPaginationTableObject } from "~components/Pagination";
import { Result } from "~components/Result";
import {
  useFetchGetPaginatedUsersByCompany,
  useFetchRemoveUserFromCompanyByUserId,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import type { IUserManagementDTOSchema } from "~types/schemas";
import { Flex, List, Skeleton, Tooltip } from "antd";

export function UserProfilesList(props: IUserProfilesListProps) {
  const { screens } = useAppConfig();

  const [currentPage, setCurrentPage] = useState<number>(1);

  const [userToDelete, setUserToDelete] = useState<IUserManagementDTOSchema>();

  const [userToChangeProfile, setUserToChangeProfile] =
    useState<IUserManagementDTOSchema>();

  const [userToChangeBusinessUnit, setUserToChangeBusinessUnit] =
    useState<IUserManagementDTOSchema>();

  const [userToUpdate, setUserToUpdate] = useState<IUserManagementDTOSchema>();

  const { data: usersByCompany, isFetching } =
    useFetchGetPaginatedUsersByCompany({
      payload: {
        page: currentPage - 1,
        size: 5,
      },
      dependencyArray: [currentPage],
      options: {
        enabled: currentPage > 0,
      },
    });

  const { mutate: handleDeleteUser, isLoading: isDeletingUser } =
    useFetchRemoveUserFromCompanyByUserId({
      onSuccess: () => {
        setUserToDelete(undefined);
      },
    });

  function onChangePagination(number: number, _totalElements?: number) {
    setCurrentPage(number);
  }

  const DATA_SOURCE = useMemo(() => {
    return usersByCompany?.content ?? [];
  }, [usersByCompany?.content]);

  return (
    <>
      <List<IUserManagementDTOSchema>
        data-cy="user-profiles-list"
        {...props}
        itemLayout="horizontal"
        dataSource={DATA_SOURCE}
        loading={isFetching}
        rowKey="id"
        pagination={createPaginationTableObject({
          number: currentPage,
          size: usersByCompany?.size,
          totalElements: usersByCompany?.totalElements,
          onChange: onChangePagination,
          disabled: false,
          itemType: "usuários",
          displayTotalInformation: screens.sm,
        })}
        locale={{
          emptyText: <Result.TableErrorOrEmpty />,
        }}
        renderItem={(user) => {
          return (
            <List.Item key={user.id} style={{ width: "100%" }}>
              <Skeleton loading={isFetching} active avatar>
                <List.Item.Meta
                  title={
                    <Flex gap={4}>
                      {user.name}
                      {user.accountOwner && (
                        <Tooltip title="Este usuário é o dono da conta">
                          <AiFillCrown
                            size={18}
                            style={{ cursor: "help", color: "var(--gold-500)" }}
                          />
                        </Tooltip>
                      )}
                    </Flex>
                  }
                  description={
                    <Flex vertical>
                      {`${user.email || "Sem email informado"}`}
                      <br />
                      {`${user.profileName || "Sem perfil associado"}`}
                    </Flex>
                  }
                  avatar={
                    <Avatar.CustomUser
                      name={user.name}
                      userId={user.id}
                      avatarUrl={user.avatarUrl}
                      width={32}
                      height={32}
                    />
                  }
                />
              </Skeleton>

              <Menu.MoreDropdown
                items={[
                  {
                    icon: (
                      <FaPen size={16} style={{ color: "var(--gold-500)" }} />
                    ),
                    text: "Alterar usuário",
                    color: "var(--gold-500)",
                    onClick: () => setUserToUpdate(user),
                  },
                  {
                    icon: (
                      <AiOutlineDelete
                        size={16}
                        style={{ color: "var(--red-500)" }}
                      />
                    ),
                    text: "Remover usuário",
                    color: "var(--red-500)",
                    onClick: () => setUserToDelete(user),
                    isDisabled: user.accountOwner,
                    tooltip: user.accountOwner
                      ? "Este usuário é um administrador, por tanto não pode ser removido."
                      : "",
                  },
                  {
                    icon: (
                      <FaUserEdit
                        size={16}
                        style={{ color: "var(--cyan-500)" }}
                      />
                    ),
                    text: "Alterar perfil de usuário",
                    color: "var(--cyan-500)",
                    onClick: () => setUserToChangeProfile(user),
                  },
                  {
                    icon: (
                      <BsBuilding
                        size={16}
                        style={{ color: "var(--blue-500)" }}
                      />
                    ),
                    text: "Alterar unidades de negócio",
                    color: "var(--blue-500)",
                    onClick: () => setUserToChangeBusinessUnit(user),
                  },
                ]}
                position="bottomLeft"
              />
            </List.Item>
          );
        }}
      />

      <Modal.DefaultRemove
        isLoading={isDeletingUser}
        isOpen={!!userToDelete}
        itemName={userToDelete?.email}
        onClose={() => setUserToDelete(undefined)}
        suffix="usuário"
        onSubmit={() => handleDeleteUser({ userId: userToDelete?.id || 0 })}
        title="Remover usuário"
      />

      <Modal.ChangeUserProfileForm
        isOpen={!!userToChangeProfile}
        onClose={() => setUserToChangeProfile(undefined)}
        formProps={{
          userId: userToChangeProfile?.id,
          initialValues: {
            profileId: userToChangeProfile?.profileId,
          },
          onSubmitSuccess: () => setUserToChangeProfile(undefined),
        }}
      />

      <Modal.ChangeUserBusinessUnitForm
        isOpen={!!userToChangeBusinessUnit}
        onClose={() => setUserToChangeBusinessUnit(undefined)}
        userId={userToChangeBusinessUnit?.id}
      />

      <Modal.UserForm
        isOpen={!!userToUpdate}
        formProps={{
          initialValues: {
            ...userToUpdate,
            userId: userToUpdate?.id,
          },
          onSuccess: () => setUserToUpdate(undefined),
          hideProfileField: true,
        }}
        onClose={() => setUserToUpdate(undefined)}
      />
    </>
  );
}
