import { useState } from "react";
import {
  AiFillCheckCircle,
  AiOutlineVerticalAlignBottom,
} from "react-icons/ai";
import { FiTrash } from "react-icons/fi";

import type { IPipedriveUsersListProps } from "./interface";

import { Modal } from "~components/Modal";
import { Result } from "~components/Result";
import {
  useFetchGetPipedriveUsers,
  useFetchRemoveUserFromCompanyByUserId,
} from "~hooks/api";
import type { IPipedriveSolarzUserInfoDTOSchema } from "~types/schemas";
import { Button, Flex, List, Tooltip } from "antd";

export function PipedriveUsersList(props: IPipedriveUsersListProps) {
  const [userToImport, setUserToImport] =
    useState<IPipedriveSolarzUserInfoDTOSchema>();

  const [userToDelete, setUserToDelete] =
    useState<IPipedriveSolarzUserInfoDTOSchema>();

  const {
    isFetching: isLoadingPipedriveUsers,
    data: pipedriveUsers,
    error,
  } = useFetchGetPipedriveUsers({});

  const { mutate: handleDeleteUser, isLoading: isDeletingUser } =
    useFetchRemoveUserFromCompanyByUserId({
      onSuccess: () => {
        setUserToDelete(undefined);
      },
    });

  return (
    <>
      <List<IPipedriveSolarzUserInfoDTOSchema>
        {...props}
        data-cy="pipedrive-users-list"
        itemLayout="horizontal"
        dataSource={pipedriveUsers}
        loading={isLoadingPipedriveUsers}
        rowKey="id"
        locale={{
          emptyText: (
            <Result.TableErrorOrEmpty
              errorTitle="Falha ao carregar usuários"
              errorMessage={error?.message}
            />
          ),
        }}
        style={{ width: "100%" }}
        renderItem={(user) => (
          <List.Item style={{ width: "100%" }}>
            <List.Item.Meta
              title={
                <Flex align="center" gap={2} wrap="nowrap">
                  {user.email}
                  {user.imported && (
                    <Tooltip title="Usuário já importado">
                      <AiFillCheckCircle
                        size={15}
                        style={{ marginTop: -4, cursor: "help" }}
                        color="var(--green-500)"
                      />
                    </Tooltip>
                  )}
                </Flex>
              }
              description={!user.name ? "Sem perfil associado" : user.name}
            />

            <Button
              size="small"
              type="link"
              style={{
                padding: 0,
                color: user.imported ? "var(--red-500)" : "var(--blue-500)",
              }}
              onClick={() => {
                user.imported ? setUserToDelete(user) : setUserToImport(user);
              }}
            >
              <Flex align="center" gap={4}>
                {user.imported && (
                  <>
                    <FiTrash size={16} color="var(--red-500)" />
                    Remover
                  </>
                )}

                {!user.imported && (
                  <>
                    <AiOutlineVerticalAlignBottom size={16} />
                    Importar
                  </>
                )}
              </Flex>
            </Button>
          </List.Item>
        )}
      />

      <Modal.ImportUserFromPipedriveForm
        isOpen={!!userToImport}
        onClose={() => setUserToImport(undefined)}
        formProps={{
          initialValues: {
            email: userToImport?.email,
            id: userToImport?.id,
            profileId: userToImport?.imported?.profileId,
          },
          onSuccess: () => setUserToImport(undefined),
        }}
      />

      <Modal.DefaultRemove
        isLoading={isDeletingUser}
        isOpen={!!userToDelete}
        itemName={userToDelete?.email}
        onClose={() => setUserToDelete(undefined)}
        suffix="usuário"
        onSubmit={() =>
          handleDeleteUser({ userId: userToDelete?.imported?.userId || 0 })
        }
        title="Remover usuário"
      />
    </>
  );
}
