import { CustomInput } from "~components/CustomInput";
import { Divider, Flex, Form, Typography } from "antd";

export function MediumVoltageConcessionaireProfileForm() {
  return (
    <>
      <Divider>
        <Typography.Text
          style={{ color: "var(--primary-500)", fontWeight: 700 }}
        >
          Ponta
        </Typography.Text>
      </Divider>
      <Flex
        align="center"
        justify="space-between"
        wrap="nowrap"
        gap={16}
        data-cy="medium-voltage-concessionaire-profile-form"
      >
        <Form.Item
          label="Tarifa TE"
          name="teP"
          tooltip="Tarifa com imposto incluso"
          rules={[
            { required: true },
            {
              validator: (_rule, value) => {
                return value > 0
                  ? Promise.resolve()
                  : Promise.reject(
                    new Error("Valor tem que ser maior que zero!"),
                  );
              },
            },
          ]}
          style={{ width: "100%" }}
        >
          <CustomInput.Money
            unit="R$/kWh"
            min={0}
            style={{ width: "100%" }}
            precision={6}
            placeholder="1,000000"
          />
        </Form.Item>
      </Flex>
      <Flex align="center" justify="space-between" wrap="nowrap" gap={16}>
        <Form.Item
          label="Tarifa TUSD"
          tooltip="Tarifa com imposto incluso"
          name="tusdP"
          rules={[
            { required: true },
            {
              validator: (_rule, value) => {
                return value > 0
                  ? Promise.resolve()
                  : Promise.reject(
                    new Error("Valor tem que ser maior que zero!"),
                  );
              },
            },
          ]}
          style={{ width: "100%" }}
        >
          <CustomInput.Money
            unit="R$/kWh"
            min={0}
            style={{ width: "100%" }}
            precision={6}
            placeholder="1,000000"
          />
        </Form.Item>

        <Form.Item
          label="Tarifa de Fio B"
          name="wireBTaxP"
          tooltip="Tarifa com imposto incluso"
          rules={[
            { required: true },
            {
              validator: (_rule, value) => {
                return value > 0
                  ? Promise.resolve()
                  : Promise.reject(
                    new Error("Valor tem que ser maior que zero!"),
                  );
              },
            },
          ]}
          style={{ width: "100%" }}
        >
          <CustomInput.Money
            unit="R$"
            min={0}
            style={{ width: "100%" }}
            precision={6}
            placeholder="1,000000"
          />
        </Form.Item>
      </Flex>

      <Divider>
        <Typography.Text
          style={{ color: "var(--primary-500)", fontWeight: 700 }}
        >
          Fora ponta
        </Typography.Text>
      </Divider>
      <Flex align="center" justify="space-between" wrap="nowrap" gap={16}>
        <Form.Item
          label="Tarifa TE"
          name="teFP"
          tooltip="Tarifa com imposto incluso"
          rules={[
            { required: true },
            {
              validator: (_rule, value) => {
                return value > 0
                  ? Promise.resolve()
                  : Promise.reject(
                    new Error("Valor tem que ser maior que zero!"),
                  );
              },
            },
          ]}
          style={{ width: "100%" }}
        >
          <CustomInput.Money
            unit="R$/kWh"
            min={0}
            style={{ width: "100%" }}
            precision={6}
            placeholder="1,000000"
          />
        </Form.Item>
      </Flex>
      <Flex align="center" justify="space-between" wrap="nowrap" gap={16}>
        <Form.Item
          label="Tarifa TUSD"
          tooltip="Tarifa com imposto incluso"
          name="tusdFP"
          rules={[
            { required: true },
            {
              validator: (_rule, value) => {
                return value > 0
                  ? Promise.resolve()
                  : Promise.reject(
                    new Error("Valor tem que ser maior que zero!"),
                  );
              },
            },
          ]}
          style={{ width: "100%" }}
        >
          <CustomInput.Money
            unit="R$/kWh"
            min={0}
            style={{ width: "100%" }}
            precision={6}
            placeholder="1,000000"
          />
        </Form.Item>

        <Form.Item
          label="Tarifa de Fio B"
          name="wireBTaxFP"
          tooltip="Tarifa com imposto incluso"
          rules={[
            { required: true },
            {
              validator: (_rule, value) => {
                return value > 0
                  ? Promise.resolve()
                  : Promise.reject(
                    new Error("Valor tem que ser maior que zero!"),
                  );
              },
            },
          ]}
          style={{ width: "100%" }}
        >
          <CustomInput.Money
            unit="R$"
            min={0}
            style={{ width: "100%" }}
            precision={6}
            placeholder="1,000000"
          />
        </Form.Item>
      </Flex>
    </>
  );
}
