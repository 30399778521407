import type { IPersonActivityStatisticsCardProps } from "./interface";

import { Alert } from "~components/Alert";
import { useFetchGetActivitiesCountByPersonId } from "~hooks/api";
import { SolarzPercentageRowGraph } from "~solarzui/SolarzPercentageRowGraph";
import { SolarzPercentageRowGraphContentType } from "~solarzui/SolarzPercentageRowGraph/interface";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import type { IActivityBoardDTOSchema } from "~types/schemas";
import { BadgeProps, Card, Flex, Skeleton } from "antd";

function parseDataToGraphContent(
  data: IActivityBoardDTOSchema,
): SolarzPercentageRowGraphContentType<IActivityBoardDTOSchema>[] {
  return [
    {
      label: "Ligações",
      absoluteValue: data.call,
      color: "cyan" as BadgeProps["color"],
      record: data,
    },
    {
      label: "Tarefas",
      absoluteValue: data.task,
      color: "blue" as BadgeProps["color"],
      record: data,
    },
    {
      label: "Emails",
      absoluteValue: data.email,
      color: "magenta" as BadgeProps["color"],
      record: data,
    },
    {
      label: "Propostas",
      absoluteValue: data.proposal,
      color: "gold" as BadgeProps["color"],
      record: data,
    },
  ];
}

export function PersonActivityStatisticsCard({
  personId,
  style,
  ...props
}: IPersonActivityStatisticsCardProps) {
  const {
    data: dealsCount,
    isFetching: isLoadingDealsCount,
    error: errorDealsCount,
    refetch: reloadDealsCount,
  } = useFetchGetActivitiesCountByPersonId({
    payload: {
      personId,
    },
    dependencyArray: [personId],
    options: {
      enabled: personId > 0,
    },
  });

  const graphContent = dealsCount
    ? parseDataToGraphContent(dealsCount)
    : undefined;

  return (
    <Card
      {...props}
      style={{
        width: "100%",
        maxWidth: "400px",
        ...style,
      }}
      styles={{
        body: {
          padding: 16,
        },
      }}
      title={
        <SolarzTypography
          fontWeight="semiBold"
          hierarchy="paragraph1"
          variant="title"
        >
          Visão Geral
        </SolarzTypography>
      }
    >
      <Flex vertical gap={8} style={{ margin: "0.25rem 0" }}>
        {errorDealsCount && (
          <Alert.CardError
            title="Falha ao carregar dados"
            errorMessage={errorDealsCount?.message}
            reloadFn={reloadDealsCount}
          />
        )}

        <SolarzTypography
          hierarchy="paragraph1"
          variant="title"
          fontWeight="medium"
          style={{ marginBottom: 8 }}
        >
          Principais atividades
        </SolarzTypography>

        {(isLoadingDealsCount || !dealsCount) && (
          <Skeleton.Node
            style={{ width: "100%", height: 24 }}
            active={isLoadingDealsCount}
          >
            {" "}
          </Skeleton.Node>
        )}

        {(isLoadingDealsCount || !dealsCount) &&
          Array(4)
            .fill(null)
            .map((_, index) => (
              <Flex key={index + 1} align="center" justify="space-between">
                <Skeleton.Node
                  style={{ width: 12, height: 12 }}
                  active={isLoadingDealsCount}
                >
                  {" "}
                </Skeleton.Node>

                <Skeleton.Node
                  style={{ width: 72, height: 16 }}
                  active={isLoadingDealsCount}
                >
                  {" "}
                </Skeleton.Node>

                <Skeleton.Node
                  style={{ width: 16, height: 16 }}
                  active={isLoadingDealsCount}
                >
                  {" "}
                </Skeleton.Node>

                <Skeleton.Node
                  style={{ width: 32, height: 16 }}
                  active={isLoadingDealsCount}
                >
                  {" "}
                </Skeleton.Node>
              </Flex>
            ))}

        <SolarzPercentageRowGraph content={graphContent} />
      </Flex>
    </Card>
  );
}
