import { BiMessageAltDetail } from "react-icons/bi";
import { BsTelephone } from "react-icons/bs";
import { FaRegDotCircle } from "react-icons/fa";
import { IoDocumentOutline } from "react-icons/io5";

import { IBusinessTimelineProps } from "./interface";
import { StyledCard, StyledTimeline } from "./styles";

import { SolarzAnchor } from "~solarzui/SolarzAnchor";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { Flex } from "antd";
import dayjs from "dayjs";

const MOCKED_DATA = [
  {
    id: 1,
    date: "2024-02-08T16:28:00.000Z",
    title: "Planta do cliente recebida",
    url: "#",
    type: "DOCUMENT",
  },
  {
    id: 2,
    date: "2024-02-07T08:12:00.000Z",
    title: "Gerar proposta e enviar para o Lead até o dia 12/02",
    url: null,
    type: "MESSAGE",
  },
  {
    id: 3,
    date: "2024-02-07T08:06:00.000Z",
    title: "Ligação com o lead",
    url: null,
    type: "CALL",
  },
  {
    id: 4,
    date: "2024-02-07T08:06:00.000Z",
    title: "Lead Qualificado",
    url: null,
    type: "GENERAL",
  },
  {
    id: 5,
    date: "2024-02-06T12:06:00.000Z",
    title: "Organização criada",
    url: null,
    type: "GENERAL",
  },
];

function getIconByTimelineDataType(type: string) {
  switch (type) {
    case "DOCUMENT":
      return <IoDocumentOutline size={18} />;
    case "MESSAGE":
      return <BiMessageAltDetail size={18} />;
    case "CALL":
      return <BsTelephone size={16} />;
    default:
      return <FaRegDotCircle size={12} />;
  }
}

function translateTimelineDataType(type: string) {
  switch (type) {
    case "DOCUMENT":
      return "Documento";
    case "MESSAGE":
      return "Mensagem";
    case "CALL":
      return "Ligação";
    default:
      return "";
  }
}

export function BusinessTimeline({
  companyId,
  ...props
}: IBusinessTimelineProps) {
  const dataTimeline = MOCKED_DATA.map((data) => ({
    ...data,
    date: dayjs(data.date),
  }));

  return (
    <StyledTimeline
      {...props}
      items={dataTimeline.map((data) => {
        return {
          children: (
            <Flex vertical style={{ minHeight: 94 }}>
              {data.type === "MESSAGE" ? (
                <StyledCard
                  size="small"
                  styles={{
                    body: {
                      padding: 8,
                    },
                  }}
                >
                  <SolarzTypography
                    hierarchy="small"
                    fontWeight="regular"
                    variant="disabled"
                  >
                    {data.date
                      .add(3, "hours")
                      .format("DD [de] MMMM [de] YYYY • HH:mm")}
                  </SolarzTypography>

                  <Flex gap={12}>
                    <SolarzTypography
                      hierarchy="paragraph2"
                      fontWeight="regular"
                      variant="subTitle"
                    >
                      {data.title}
                    </SolarzTypography>

                    {data.url && (
                      <SolarzAnchor isNextLink href={data.url}>
                        <SolarzTypography
                          hierarchy="paragraph2"
                          fontWeight="medium"
                          variant="link"
                        >
                          {`Abrir ${translateTimelineDataType(data.type)}`}
                        </SolarzTypography>
                      </SolarzAnchor>
                    )}
                  </Flex>
                </StyledCard>
              ) : (
                <div>
                  <Flex gap={12}>
                    <SolarzTypography
                      hierarchy="paragraph2"
                      fontWeight="regular"
                      variant="subTitle"
                    >
                      {data.title}
                    </SolarzTypography>

                    {data.url && (
                      <SolarzAnchor isNextLink href={data.url}>
                        <SolarzTypography
                          hierarchy="small"
                          fontWeight="medium"
                          variant="link"
                        >
                          {`Abrir ${translateTimelineDataType(data.type)}`}
                        </SolarzTypography>
                      </SolarzAnchor>
                    )}
                  </Flex>

                  <SolarzTypography
                    hierarchy="small"
                    fontWeight="regular"
                    variant="disabled"
                  >
                    {data.date
                      .add(3, "hours")
                      .format("DD [de] MMMM [de] YYYY • HH:mm")}
                  </SolarzTypography>
                </div>
              )}
            </Flex>
          ),
          dot: getIconByTimelineDataType(data.type),
          key: data.id,
        };
      })}
    />
  );
}
