import type { IReasonForLossSelectProps } from "./interface";

import { useFetchGetAllReasonForLoss } from "~hooks/api";
import { SolarzSelect } from "~solarzui/SolarzSelect";

export function ReasonForLossSelect({
  disabledOptions,
  hiddenOptions,
  scale = "large",
  placeholder = "Selecione o motivo...",
  ...props
}: IReasonForLossSelectProps) {
  const {
    data: reasonsForLoss = [],
    error: errorReasonsForLoss,
    isFetching: isLoadingReasonsForLoss,
    refetch: reloadReasonsForLoss,
  } = useFetchGetAllReasonForLoss({});

  return (
    <SolarzSelect
      {...props}
      scale={scale}
      placeholder={placeholder}
      emptyMessage="Nenhum motivo de perda cadastrado"
      errorMessage={errorReasonsForLoss?.message}
      isLoading={isLoadingReasonsForLoss}
      refetchFn={reloadReasonsForLoss}
      options={reasonsForLoss.map((reasonForLoss) => {
        return {
          label: reasonForLoss.reason,
          value: reasonForLoss.id,
          record: reasonForLoss,
          disabled: !!disabledOptions?.[reasonForLoss.id],
          hidden: !!hiddenOptions?.[reasonForLoss.id],
        };
      })}
    />
  );
}
