import type { IUserFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function UserFormModal({
  isOpen = false,
  onClose = () => null,
  maxWidth = 560,
  formProps,
}: IUserFormModalProps) {
  return (
    <Modal
      data-cy="user-form-modal"
      open={isOpen}
      onCancel={onClose}
      title="Formulário de usuário"
      width="90vw"
      style={{ maxWidth }}
      footer={null}
      destroyOnClose
    >
      <Form.User {...formProps} onCancel={onClose} />
    </Modal>
  );
}
