import styled from "@emotion/styled";
import { Modal } from "antd";

export interface IStyledModalProps {
  isPictureInPictureMode: boolean;
  inPictureModeWidth: string;
  inFullModeWidth: string;
  inPictureModeHeight: string;
  inFullModeHeight: string;
}

export const StyledModal = styled(Modal, {
  shouldForwardProp: (props) => props !== "isPictureInPictureMode",
})<IStyledModalProps>(({ isPictureInPictureMode }) => ({
  ".ant-modal-content": {
    background: "transparent",
    boxShadow: "none",
    padding: 0,
    display: "flex",
    justifyContent: "center",
  },

  ".ant-modal-wrap": {
    bottom: "1rem !important",
    right: "1rem !important",
    top: "auto !important",
    left: "auto !important",
    position: "fixed",
    "z-index": "1 !important",
    height: "204px !important",
    width: "360px !important",
  },

  ".ant-modal-body": {
    padding: 0,
    height: isPictureInPictureMode ? "204px" : "464px",
    width: isPictureInPictureMode ? "360px" : "820px",
    backgroundColor: "rgba(0, 0, 0, 0.44)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    bottom: isPictureInPictureMode ? "1rem" : "auto",
    right: isPictureInPictureMode ? "1rem" : "auto",
    transition: isPictureInPictureMode ? "all 0.2s ease-out" : "none",
  },
}));
