export function getTwoFirstLatterOfFullName(
  fullName: string,
): [string, string] {
  const [firstName = "", lastName = ""] = fullName.trim().split(" ");

  const firstLetter = firstName.charAt(0) || "?";
  const secondLetter = lastName.charAt(0) || "";

  return [firstLetter, secondLetter];
}
