import styled from "@emotion/styled";
import { Collapse } from "antd";

export const StyledCollapse = styled(Collapse)`
  border: none;

  .ant-collapse-content {
    background: var(--gray-50);
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }

  .ant-checkbox-group {
    padding-bottom: 12px;
  }

  .ant-checkbox-group > .ant-checkbox-group-item {
    padding: 12px 0;
    border-bottom: 1px solid var(--gray-200);
  }

  .ant-checkbox-group > .ant-checkbox-group-item > .ant-checkbox {
    margin-left: 40px;
  }

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }

  html.dark & {
    .ant-collapse-content {
      background: var(--gray-900);
    }

    .ant-checkbox-group > .ant-checkbox-group-item {
      border-bottom: 1px solid var(--gray-600);
    }
  }
`;
