import { BsCheckCircle } from "react-icons/bs";

import NextImage from "next/image";

import type { IBindPipedriveIdToOrganizationButtonProps } from "./interface";

import {
  useFetchBindsPipedriveIdToOrganization,
  useFetchGetOrganizationById,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { SolarzButton } from "~solarzui/SolarzButton";
import { Skeleton, Spin } from "antd";

export function BindPipedriveIdToOrganizationButton({
  children,
  clientId = 0,
  pipedriveId = 0,
  ...props
}: IBindPipedriveIdToOrganizationButtonProps) {
  const { message } = useAppConfig();

  const { data: organizationDetail, isLoading: isLoadingOrganizationDetail } =
    useFetchGetOrganizationById({
      payload: {
        organizationId: clientId,
      },
      dependencyArray: [clientId],
      options: {
        enabled: clientId > 0,
        retry: 1,
      },
    });

  const { mutate: handleBindsPipedriveIdToOrganization, isLoading } =
    useFetchBindsPipedriveIdToOrganization({});

  const handleClick = async () => {
    if (clientId > 0 && pipedriveId > 0) {
      handleBindsPipedriveIdToOrganization({
        organizationId: clientId,
        pipedriveId: pipedriveId,
      });
    } else {
      message.info(
        "Por favor, verifique se a organização está corretamente selecionada.",
      );
    }
  };
  return (
    <>
      <SolarzButton
        {...props}
        data-cy="bind-pipedrive-id-to-organization-button"
        onClick={handleClick}
        variant="secondary"
        isLoading={isLoading || isLoadingOrganizationDetail}
        htmlType="button"
        isDisabled={
          !(clientId > 0 && pipedriveId > 0 && !organizationDetail?.pipedriveId)
        }
        icon={
          isLoadingOrganizationDetail ? (
            <Spin size="small" />
          ) : organizationDetail?.pipedriveId ? (
            <BsCheckCircle size={18} />
          ) : (
            <NextImage
              src="/images/pipedrive-icon-black.svg"
              height={14}
              width={14}
              alt="Integrado com Pipedrive"
            />
          )
        }
      >
        {children ?? isLoadingOrganizationDetail ? (
          <Skeleton.Input
            active={isLoadingOrganizationDetail}
            size="small"
            style={{ width: "100%" }}
          />
        ) : organizationDetail?.pipedriveId ? (
          "Organização vinculado ao pipedrive"
        ) : (
          "Vincular organização ao pipedrive"
        )}
      </SolarzButton>
    </>
  );
}
