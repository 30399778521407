import { api, apiException } from "~services/api";
import { IFetchGetPipelineDealsPayloadSchema } from "~types/schemas/FetchGetPipelineDealsPayloadSchema";
import { PipelineDealListSchema } from "~types/schemas/PipelineDealListSchema";

export const fetchGetPipedrivePipelineDeals = async (
  payload: IFetchGetPipelineDealsPayloadSchema,
) => {
  try {
    const response = await api.get(
      `/pipedrive/pipelines/${payload?.pipelineId}/deals`,
    );
    const parsedData = PipelineDealListSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
