import { useMemo } from "react";
import { BiErrorCircle } from "react-icons/bi";

import { IConcessionairesWithAssociatedProfileByProvinceSelectProps } from "./interface";

import { useFetchGetConcessionairesWithAssociatedProfileByProvince } from "~hooks/api";
import { Select } from "antd";

export function ConcessionairesWithAssociatedProfileByProvinceSelect({
  provinceId = 0,
  allowClear = true,
  onChange,
  onLoad = () => undefined,
  ...props
}: IConcessionairesWithAssociatedProfileByProvinceSelectProps) {
  const filterOption = (
    input: string,
    option?: { label: string; value: number },
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const {
    data: concessionaires,
    isFetching: isLoadingConcessionaires,
    error: concessionairesError,
    refetch: refetchConcessionaires,
  } = useFetchGetConcessionairesWithAssociatedProfileByProvince({
    dependencyArray: [provinceId],
    payload: {
      provinceId,
    },
    options: {
      onSuccess: onLoad,
      enabled: provinceId > 0,
    },
  });

  const OPTIONS = useMemo(() => {
    if (!concessionaires) return [];

    return concessionaires.map((concessionaire) => ({
      label: concessionaire.name,
      value: concessionaire.id,
      record: concessionaire,
    }));
  }, [concessionaires]);

  return (
    <Select
      {...props}
      showSearch
      optionFilterProp="children"
      loading={isLoadingConcessionaires}
      options={OPTIONS}
      allowClear={allowClear}
      status={concessionairesError ? "error" : ""}
      filterOption={filterOption}
      suffixIcon={
        concessionairesError ? (
          <BiErrorCircle
            size={18}
            color="var(--red-200)"
            onClick={() => refetchConcessionaires()}
          />
        ) : undefined
      }
      onChange={(value, option) => {
        if (option instanceof Array || !onChange) return;
        onChange(value, option);
      }}
    />
  );
}
