import { useState, useMemo, useEffect } from "react";

import { IProductModuleAutoCompleteProps } from "./interface";

import { AutoCompleteSuffixIcon } from "~components/Others";
import { Result } from "~components/Result";
import { useFetchGetPaginatedProductModules } from "~hooks/api";
import { AutoComplete } from "antd";

export function ProductModuleAutoComplete({
  allowClear = true,
  onChange,
  filters,
  value = "",
  onClear,
  autoCompleteInSingleOption = false,
  ...props
}: IProductModuleAutoCompleteProps) {
  const [inputValue, setInputValue] = useState("");

  const CAN_LOAD_DATA = useMemo(() => inputValue.length >= 3, [inputValue]);

  const [preventsAutoFill, setPreventsAutoFill] = useState(false);

  const { data, isFetching, error, refetch } =
    useFetchGetPaginatedProductModules({
      dependencyArray: [inputValue, filters],
      payload: {
        name: inputValue,
        page: 0,
        size: 100,
        manufacturer: filters?.manufacturer,
        power: filters?.power,
      },
      options: {
        enabled: CAN_LOAD_DATA || (!!filters?.manufacturer && !!filters?.power),
        retry: false,
        onSuccess(moduleModels) {
          if (
            autoCompleteInSingleOption &&
            moduleModels.content.length === 1 &&
            !preventsAutoFill
          ) {
            const uniqueModulePower = moduleModels.content[0];

            setInputValue(uniqueModulePower.model);

            if (onChange) {
              onChange(uniqueModulePower.model, {
                label: uniqueModulePower.model,
                value: uniqueModulePower.model,
                record: uniqueModulePower,
              });
            }
          }
        },
      },
    });

  const OPTIONS = useMemo(() => {
    return (
      data?.content.map((product) => ({
        label: product.model,
        value: product.model,
        record: product,
        key: product.id,
      })) ?? []
    );
  }, [data?.content]);

  function onSearch(value: string) {
    setInputValue(value);
  }

  useEffect(() => {
    setPreventsAutoFill(false);
  }, [filters?.manufacturer, filters?.power]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <AutoComplete
      {...props}
      data-cy="product-module-auto-complete"
      value={inputValue}
      showSearch
      filterOption={false}
      onSearch={onSearch}
      options={OPTIONS}
      status={error ? "error" : ""}
      allowClear={allowClear}
      notFoundContent={
        <Result.SelectErrorOrEmpty
          errorMessage={error?.message}
          emptyMessage="Nenhum módulo encontrado"
          loading={isFetching}
          reloadFn={refetch}
          disableReload={!CAN_LOAD_DATA}
        />
      }
      suffixIcon={
        <AutoCompleteSuffixIcon hasError={!!error} isLoading={isFetching} />
      }
      onClear={() => {
        setInputValue("");
        setPreventsAutoFill(true);
        if (typeof onClear === "function") {
          onClear();
        }
      }}
      onChange={(value, option) => {
        if (option instanceof Array || !onChange) return;
        onChange(value, option);

        if (value) {
          setPreventsAutoFill(false);
        }
      }}
      style={{ width: "100%" }}
    />
  );
}
