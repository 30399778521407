import styled from "@emotion/styled";
import type { IProfileDTOSchema } from "~types/schemas";
import { List } from "antd";

export const StyledList = styled(List<IProfileDTOSchema>)`
  & .ant-list-item {
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    padding: 1rem;

    &:hover {
      background-color: var(--gray-100);
    }
  }

  & .ant-list-item.active {
    background-color: var(--gray-200);
  }

  /* DARK THEME */
  html.dark & .ant-list-item:hover {
    background-color: var(--gray-800);
  }

  html.dark & .ant-list-item.active {
    background-color: var(--gray-700);
  }
`;
