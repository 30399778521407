import { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";

import type {
  ISolarzKanbanProps,
  PrivateKanbanColumnStateType,
  PrivateKanbanTaskStateType,
  SolarzKanbanColumnType,
} from "./interface";
import { KanbanColumn } from "./KanbanColumn";
import { KanbanTask } from "./KanbanTask";

import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { SolarzButton } from "~solarzui/SolarzButton";
import { Card, Flex, Result, Skeleton } from "antd";

export function SolarzKanban<
  ColumnRecordType extends Record<string, any>,
  TaskRecordType extends Record<string, any>,
>({
  dataSource: initialDataSource = [],
  renderHeader,
  renderFooter,
  renderTask,
  disableColumnDragAndDrop = false,
  disableTaskDragAndDrop = false,
  isLoading = false,
  emptyMessage = "Não existem dados a serem mostrados, pois nenhuma foi coluna foi definida.",
  errorMessage,
  reloadFn,
  onChange,
  onDragChange,
  onDeleteTask,
  className,
  style,
}: ISolarzKanbanProps<ColumnRecordType, TaskRecordType>) {
  const [kanbanColumns, setKanbanColumns] = useState<
    PrivateKanbanColumnStateType<ColumnRecordType>[]
  >([]);

  const [kanbanTasks, setKanbanTasks] = useState<
    PrivateKanbanTaskStateType<TaskRecordType>[]
  >([]);
  const [dataSource, setDataSource] = useState<
    SolarzKanbanColumnType<ColumnRecordType, TaskRecordType>[]
  >([...initialDataSource]);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    setDataSource([...initialDataSource]);
  }, [initialDataSource]);

  useEffect(() => {
    const { columns, tasks } = dataSource.reduce(
      ({ columns, tasks }, data, index) => {
        const { tasks: columnTasksData, ...columnData } = data;

        const parsedColumnData = {
          ...columnData,
          customId: `column_${index + 1}`,
        };

        const parsedTasks: PrivateKanbanTaskStateType<TaskRecordType>[] =
          columnTasksData.map((column, taskIndex) => ({
            ...column,
            customId: `task_${index + 1}_${taskIndex + 1}`,
            columnId: parsedColumnData.id,
            customColumnId: parsedColumnData.customId,
          }));

        return {
          columns: [...columns, parsedColumnData],
          tasks: [...tasks, ...parsedTasks],
        };
      },
      {
        columns: [],
        tasks: [],
      } as {
        columns: PrivateKanbanColumnStateType<ColumnRecordType>[];
        tasks: PrivateKanbanTaskStateType<TaskRecordType>[];
      },
    );
    setKanbanColumns(columns);
    setKanbanTasks(tasks);
  }, [dataSource]);

  const [activeColumn, setActiveColumn] =
    useState<PrivateKanbanColumnStateType<ColumnRecordType> | null>(null);

  const [activeTask, setActiveTask] =
    useState<PrivateKanbanTaskStateType<TaskRecordType> | null>(null);

  const kanbanColumnsId = useMemo(() => {
    return kanbanColumns.map((column) => column.customId);
  }, [kanbanColumns]);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 150,
        tolerance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  // FUNCTIONS
  function onDragStart(event: DragStartEvent) {
    if (onDragChange) {
      onDragChange(true);
    }
    setIsDragging(true);
    switch (event.active.data.current?.type) {
      case "Column":
        setActiveColumn(event.active.data.current.column);
        return;
      case "Task":
        setActiveTask(event.active.data.current.task);
        return;
      default:
        return;
    }
  }

  function onDragEnd(event: DragEndEvent) {
    if (onDragChange) {
      onDragChange(false);
    }
    setIsDragging(false);

    const currentActiveColumn = activeColumn;
    const currentActiveTask = activeTask;

    setActiveColumn(null);
    setActiveTask(null);

    const { active, over } = event;

    if (!over) return;

    const activeId = active.id as string;
    const overId = over.id as string;

    if (activeId === overId) {
      if (typeof onChange === "function") {
        onChange({
          columns: kanbanColumns,
          tasks: kanbanTasks,
          activeColumn: currentActiveColumn ?? undefined,
          activeTask: currentActiveTask ?? undefined,
        });
      }

      return;
    }

    const isActiveAColumn = active.data.current?.type === "Column";

    if (!isActiveAColumn) {
      if (overId === "delete-column" && active.data.current?.type === "Task") {
        //If the task is being dragged to the trash zone reset the task to its original column
        setDataSource([...initialDataSource]);
        onDeleteTask?.(active.data.current.task);
      } else {
        if (typeof onChange === "function") {
          onChange({
            columns: kanbanColumns,
            tasks: kanbanTasks,
            activeColumn: currentActiveColumn ?? undefined,
            activeTask: currentActiveTask ?? undefined,
          });
        }
      }

      return;
    }

    setKanbanColumns((columns) => {
      const activeColumnIndex = columns.findIndex(
        (column) => column.customId === activeId,
      );

      const overColumnIndex = columns.findIndex(
        (column) => column.customId === overId,
      );

      const newColumnData = arrayMove(
        columns,
        activeColumnIndex,
        overColumnIndex,
      );

      if (typeof onChange === "function") {
        onChange({
          columns: newColumnData,
          tasks: kanbanTasks,
          activeColumn: currentActiveColumn ?? undefined,
          activeTask: currentActiveTask ?? undefined,
        });
      }

      return newColumnData;
    });
  }

  function onDragOver(event: DragOverEvent) {
    const { active, over } = event;

    if (!over) return;

    const activeId = active.id as string;
    const overId = over.id as string;
    if (
      activeId === overId ||
      overId === "delete-column" ||
      activeId === "delete-column"
    )
      return;
    const isActiveATask = active.data.current?.type === "Task";
    const isOverATask = over.data.current?.type === "Task";

    if (!isActiveATask) return;

    // Drop task over another task
    if (isActiveATask && isOverATask) {
      setKanbanTasks((tasks) => {
        const activeIndex = tasks.findIndex(
          (task) => task.customId === activeId,
        );

        const overIndex = tasks.findIndex((task) => task.customId === overId);

        const taskActiveIndex = tasks[activeIndex].columnId.toString();
        const taskOverIndex = tasks[overIndex].columnId.toString();

        if (taskActiveIndex !== taskOverIndex) {
          tasks[activeIndex].columnId = tasks[overIndex].columnId;
          return arrayMove(tasks, activeIndex, overIndex - 1);
        }

        return arrayMove(tasks, activeIndex, overIndex);
      });
    }

    const isOverAColumn = over.data.current?.type === "Column";

    // Drop task over a column
    if (isActiveATask && isOverAColumn) {
      setKanbanTasks((tasks) => {
        const activeIndex = tasks.findIndex(
          (task) => task.customId === activeId,
        );

        if (activeIndex === -1) return tasks;

        tasks[activeIndex].customColumnId = overId;
        tasks[activeIndex].columnId =
          kanbanColumns.find((col) => col.customId === overId)?.id ?? 0;

        return arrayMove(tasks, activeIndex, activeIndex);
      });
    }
  }

  if (isLoading || errorMessage || (!isLoading && dataSource.length === 0)) {
    return (
      <Flex
        style={{
          ...style,
          width: "auto",
          minHeight: 512,
          overflowX: "auto",
          overflowY: "hidden",
          position: "relative",
        }}
        className={className}
        gap={12}
      >
        <Skeleton.Node
          active={isLoading}
          style={{ height: "100%", width: 256 }}
        >
          {" "}
        </Skeleton.Node>
        <Skeleton.Node
          active={isLoading}
          style={{ height: "100%", width: 256 }}
        >
          {" "}
        </Skeleton.Node>
        <Skeleton.Node
          active={isLoading}
          style={{ height: "100%", width: 256 }}
        >
          {" "}
        </Skeleton.Node>
        <Skeleton.Node
          active={isLoading}
          style={{ height: "100%", width: 256 }}
        >
          {" "}
        </Skeleton.Node>

        {!isLoading && errorMessage && (
          <Card
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            size="small"
          >
            <Result
              status="error"
              title="Error"
              subTitle={errorMessage}
              style={{ width: 562 }}
              extra={
                reloadFn && (
                  <Flex justify="center">
                    <SolarzButton onClick={reloadFn} isLoading={isLoading}>
                      Tentar novamente
                    </SolarzButton>
                  </Flex>
                )
              }
            />
          </Card>
        )}

        {!isLoading && !errorMessage && emptyMessage && (
          <Card
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            size="small"
          >
            <Result
              status="warning"
              title="Aviso"
              subTitle={emptyMessage}
              extra={
                reloadFn && (
                  <Flex justify="center">
                    <SolarzButton onClick={reloadFn} isLoading={isLoading}>
                      Tentar novamente
                    </SolarzButton>
                  </Flex>
                )
              }
              style={{ width: 562 }}
            />
          </Card>
        )}
      </Flex>
    );
  }
  return (
    <Flex
      style={{
        ...style,
        width: "auto",
        minHeight: 512,
        overflowX: "auto",
        overflowY: "hidden",
      }}
      className={className}
      gap={12}
    >
      <DndContext
        sensors={sensors}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragOver={onDragOver}
      >
        <SortableContext items={kanbanColumnsId}>
          {kanbanColumns.map((column) => {
            const columnTasks = kanbanTasks.filter(
              (task) => task.customColumnId === column.customId,
            );

            return (
              <KanbanColumn
                key={column.customId}
                column={{
                  ...column,
                  tasks: columnTasks,
                }}
                tasks={kanbanTasks.filter(
                  (task) => task.customColumnId === column.customId,
                )}
                renderTask={renderTask}
                renderHeader={renderHeader}
                renderFooter={renderFooter}
                disableColumnDragAndDrop={disableColumnDragAndDrop}
                disableTaskDragAndDrop={disableTaskDragAndDrop}
              />
            );
          })}
        </SortableContext>

        {typeof document !== "undefined" &&
          createPortal(
            <DragOverlay>
              {activeColumn && (
                <KanbanColumn
                  column={{
                    ...activeColumn,
                    tasks: kanbanTasks.filter(
                      (task) => task.customColumnId === activeColumn.customId,
                    ),
                  }}
                  tasks={kanbanTasks.filter(
                    (task) => task.customColumnId === activeColumn.customId,
                  )}
                  renderTask={renderTask}
                  renderHeader={renderHeader}
                  renderFooter={renderFooter}
                  disableColumnDragAndDrop={disableColumnDragAndDrop}
                  disableTaskDragAndDrop={disableTaskDragAndDrop}
                />
              )}
              {activeTask && (
                <KanbanTask
                  task={activeTask}
                  renderTask={renderTask}
                  disableTaskDragAndDrop={disableTaskDragAndDrop}
                />
              )}
            </DragOverlay>,
            document.body,
          )}
      </DndContext>
    </Flex>
  );
}
