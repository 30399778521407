import type {
  ICostAggregatorFormValues,
  ICostAggregatorFormProps,
} from "./interface";

import { CustomInput } from "~components/CustomInput";
import {
  useFetchCreateCostAggregator,
  useFetchUpdateCostAggregator,
} from "~hooks/api";
import { Button, Form, Flex } from "antd";

export function CostAggregatorForm({
  initialValues,
  onSuccess = () => undefined,
  onError = () => undefined,
  onCancel = () => undefined,
}: ICostAggregatorFormProps) {
  const [form] = Form.useForm<ICostAggregatorFormValues>();

  const { mutate: createCostAggregator, isLoading: isCreatingCostAggregator } =
    useFetchCreateCostAggregator({
      options: {
        onSuccess: (data) => {
          onSuccess(data);
        },
        onError: (error) => {
          onError(error.message);
        },
      },
    });

  const { mutate: updateCostAggregator, isLoading: isUpdatingCostAggregator } =
    useFetchUpdateCostAggregator({
      options: {
        onSuccess: (data) => {
          onSuccess(data);
        },
        onError: (error) => {
          onError(error.message);
        },
      },
    });

  function handleSubmitForm(data: ICostAggregatorFormValues) {
    if (initialValues?.id) {
      updateCostAggregator({
        ...data,
        costAggregatorId: initialValues.id,
      });
    } else {
      createCostAggregator(data);
    }
  }

  const isLoading = isCreatingCostAggregator || isUpdatingCostAggregator;

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmitForm}
      layout="vertical"
    >
      <Form.Item
        name="name"
        label="Nome"
        rules={[{ required: true }]}
        style={{ width: "100%" }}
        tooltip={
          'Agregadores de custo são utilizados para referenciar grupos de custo dentro de expressões personalizadas. Exemplo: se você tiver 5 custos que somados compõe a mão de obra, você pode indicar que eles pertencem ao grupo de custo "mão de obra" e usar o identificador do grupo para referenciar o somatório desse grupo dentro de uma expressão customizada.'
        }
      >
        <CustomInput.Text
          data-cy="name"
          placeholder="Ex.: Grupo agregador de custo"
        />
      </Form.Item>

      <Form.Item
        label="Identificador"
        name="identifier"
        data-cy="identifier"
        rules={[
          {
            pattern: new RegExp(/^[A-Za-z_-]*$/),
            message: "Apenas letras, underlines ou traços",
          },
        ]}
        tooltip="Não pode conter espaços e/ou caracteres especiais"
        style={{ width: "100%" }}
      >
        <CustomInput.Text placeholder="Ex.: id_extra" />
      </Form.Item>

      <Flex gap={12} justify="end">
        <Form.Item style={{ margin: 0 }}>
          <Button onClick={onCancel}>Cancelar</Button>
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button
            data-cy="button-save"
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            Salvar
          </Button>
        </Form.Item>
      </Flex>
    </Form>
  );
}
