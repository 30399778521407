import { IDealCardProps } from "./interface";

import { Alert } from "~components/Alert";
import { Modal } from "~components/Modal";
import { Edit } from "~estrela-ui/icons";
import { useFetchGetDealByCustomId } from "~hooks/api";
import { parseFunctions, useQueryParams } from "~hooks/useQueryParams";
import { SolarzAnchor } from "~solarzui/SolarzAnchor";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { parseCurrency } from "~utils/parse";
import { Card, Col, Flex, Row, Skeleton } from "antd";
import dayjs, { Dayjs } from "dayjs";

const CREATE_DEAL_MODAL_PARAM_KEY = "editar-negocio";

function formatDateDifference(start: Dayjs, end: Dayjs) {
  const differenceInHours = end.add(3, "hours").diff(start, "hours");

  if (differenceInHours <= 0) {
    const differenceInMinutes = end.add(3, "hours").diff(start, "minutes");
    return `${differenceInMinutes} minutos`;
  }

  const differenceInDays = end.diff(start.startOf("day"), "days");

  if (differenceInDays < 1) {
    return `${differenceInHours} horas`;
  }

  return differenceInDays === 1
    ? `${differenceInDays} dia`
    : `${differenceInDays} dias`;
}

export function DealCard({ dealId, style }: IDealCardProps) {
  const { handleSaveInQueryParams, getParsedQueryParams } = useQueryParams();

  const {
    data: deal,
    isFetching: isLoadingDeal,
    error: dealError,
    refetch: refetchDeal,
  } = useFetchGetDealByCustomId({
    payload: {
      customId: dealId,
    },
    dependencyArray: [dealId],
    options: {
      enabled: dealId > 0,
    },
  });

  const selectedDealId = getParsedQueryParams(
    CREATE_DEAL_MODAL_PARAM_KEY,
    parseFunctions.NUMBER,
  );

  function handleOpenUpdateDealModal() {
    handleSaveInQueryParams({ [CREATE_DEAL_MODAL_PARAM_KEY]: dealId });
  }

  function handleCloseUpdateDealModal() {
    handleSaveInQueryParams({ [CREATE_DEAL_MODAL_PARAM_KEY]: "" });
  }

  const todayDate = dayjs();
  const parsedDealCreatedAt = deal?.createdAt ? dayjs(deal?.createdAt) : null;

  return (
    <>
      <Card
        style={{
          width: "100%",
          maxWidth: "400px",
          ...style,
        }}
        data-cy="deal-card"
        styles={{
          body: {
            padding: 16,
          },
        }}
        title={
          <Flex justify="space-between" align="center">
            <SolarzTypography
              fontWeight="semiBold"
              hierarchy="paragraph1"
              variant="title"
            >
              Negócio
            </SolarzTypography>

            <SolarzButton
              scale="tiny"
              variant="ghost"
              isDisabled={dealId === 0 || !deal?.active}
              icon={
                <Edit
                  style={{
                    color:
                      dealId === 0 || !deal?.active
                        ? "var(--gray-300)"
                        : "var(--primary-500)",
                  }}
                />
              }
              onClick={handleOpenUpdateDealModal}
              style={{
                border: "1px solid var(--gray-300)",
                width: 28,
                height: 28,
                padding: 0,
              }}
            />
          </Flex>
        }
      >
        <Flex vertical gap={16}>
          {dealError && (
            <Alert.CardError
              title="Falha ao carregar dados"
              errorMessage={dealError?.message}
              reloadFn={refetchDeal}
            />
          )}

          <Row align="top" gutter={24} wrap={false}>
            <Col span={14}>
              <SolarzTypography
                fontWeight="medium"
                variant="title"
                hierarchy="paragraph2"
                style={{ textAlign: "start" }}
              >
                Valor
              </SolarzTypography>
            </Col>
            <Col span={10}>
              {isLoadingDeal || dealError ? (
                <Skeleton.Input active={isLoadingDeal} size="small" />
              ) : (
                <SolarzTypography
                  fontWeight="regular"
                  variant="disabled"
                  hierarchy="small"
                >
                  {parseCurrency(deal?.value)}
                </SolarzTypography>
              )}
            </Col>
          </Row>

          <Row align="top" gutter={24} wrap={false}>
            <Col span={14}>
              <SolarzTypography
                fontWeight="medium"
                variant="title"
                hierarchy="paragraph2"
                style={{ textAlign: "start" }}
              >
                Funil
              </SolarzTypography>
            </Col>
            <Col span={10}>
              {isLoadingDeal || dealError ? (
                <Skeleton.Input active={isLoadingDeal} size="small" />
              ) : (
                <SolarzTypography
                  fontWeight="regular"
                  variant="disabled"
                  hierarchy="small"
                >
                  <SolarzAnchor
                    isNextLink
                    aria-label={deal?.pipeline?.name}
                    href={`/funil?pipeline=${deal?.pipeline?.id}`}
                    defaultUnderlined={false}
                    isDisabled={!deal?.pipeline?.id}
                    style={{ textAlign: "start" }}
                  >
                    {deal?.pipeline?.name ?? "-"}
                  </SolarzAnchor>
                </SolarzTypography>
              )}
            </Col>
          </Row>

          <Row align="top" gutter={24} wrap={false}>
            <Col span={14}>
              <SolarzTypography
                fontWeight="medium"
                variant="title"
                hierarchy="paragraph2"
                style={{ textAlign: "start" }}
              >
                Fase do funil
              </SolarzTypography>
            </Col>
            <Col span={10}>
              {isLoadingDeal || dealError ? (
                <Skeleton.Input active={isLoadingDeal} size="small" />
              ) : (
                <SolarzTypography
                  fontWeight="regular"
                  variant="disabled"
                  hierarchy="small"
                  style={{ textAlign: "start" }}
                >
                  {deal?.pipelineStage?.name ?? "-"}
                </SolarzTypography>
              )}
            </Col>
          </Row>

          <Row align="top" gutter={24} wrap={false}>
            <Col span={14}>
              <SolarzTypography
                fontWeight="medium"
                variant="title"
                hierarchy="paragraph2"
                style={{ textAlign: "start" }}
              >
                Organização
              </SolarzTypography>
            </Col>
            <Col span={10}>
              {isLoadingDeal || dealError ? (
                <Skeleton.Input active={isLoadingDeal} size="small" />
              ) : (
                <SolarzTypography
                  fontWeight="regular"
                  variant="disabled"
                  hierarchy="small"
                >
                  <SolarzAnchor
                    isNextLink
                    aria-label={
                      deal?.organization?.name ||
                      deal?.person?.organization?.name
                    }
                    href={`/clientes/empresa/${
                      deal?.organization?.id || deal?.person?.organization?.id
                    }?tab=anotacoes`}
                    defaultUnderlined={false}
                    isDisabled={
                      !(
                        deal?.organization?.id || deal?.person?.organization?.id
                      )
                    }
                    style={{ textAlign: "start" }}
                  >
                    {deal?.organization?.name ||
                      deal?.person?.organization?.name ||
                      "-"}
                  </SolarzAnchor>
                </SolarzTypography>
              )}
            </Col>
          </Row>

          <Row align="top" gutter={24} wrap={false}>
            <Col span={14}>
              <SolarzTypography
                fontWeight="medium"
                variant="title"
                hierarchy="paragraph2"
                style={{ textAlign: "start" }}
              >
                Pessoa de contato
              </SolarzTypography>
            </Col>
            <Col span={10}>
              {isLoadingDeal || dealError ? (
                <Skeleton.Input active={isLoadingDeal} size="small" />
              ) : (
                <SolarzTypography
                  fontWeight="regular"
                  variant="disabled"
                  hierarchy="small"
                >
                  <SolarzAnchor
                    isNextLink
                    aria-label={deal?.owner?.name}
                    href={`/clientes/pessoa/${deal?.person?.id}?tab=anotacoes`}
                    defaultUnderlined={false}
                    isDisabled={!deal?.person?.id}
                    style={{ textAlign: "start" }}
                  >
                    {deal?.person?.name ?? "-"}
                  </SolarzAnchor>
                </SolarzTypography>
              )}
            </Col>
          </Row>

          <Row align="top" gutter={24} wrap={false}>
            <Col span={14}>
              <SolarzTypography
                fontWeight="medium"
                variant="title"
                hierarchy="paragraph2"
                style={{ textAlign: "start" }}
              >
                Responsável
              </SolarzTypography>
            </Col>
            <Col span={10}>
              {isLoadingDeal || dealError ? (
                <Skeleton.Input active={isLoadingDeal} size="small" />
              ) : (
                <SolarzTypography
                  fontWeight="regular"
                  variant="disabled"
                  hierarchy="small"
                >
                  <SolarzAnchor
                    isNextLink
                    aria-label={
                      deal?.owner?.name ||
                      deal?.owner?.email ||
                      "Sem nome/email"
                    }
                    href={`/configuracoes-gerais/gerenciamento-de-usuarios?tab=usuarios&id=${deal?.owner?.id}`}
                    defaultUnderlined={false}
                    style={{ textAlign: "start" }}
                  >
                    {deal?.owner?.name ||
                      deal?.owner?.email ||
                      "Sem nome/email"}
                  </SolarzAnchor>
                </SolarzTypography>
              )}
            </Col>
          </Row>

          <Row align="top" gutter={24} wrap={false}>
            <Col span={14}>
              <SolarzTypography
                fontWeight="medium"
                variant="title"
                hierarchy="paragraph2"
                style={{ textAlign: "start" }}
              >
                Idade do negócio
              </SolarzTypography>
            </Col>
            <Col span={10}>
              {isLoadingDeal || dealError ? (
                <Skeleton.Input active={isLoadingDeal} size="small" />
              ) : (
                <SolarzTypography
                  fontWeight="regular"
                  variant="disabled"
                  hierarchy="small"
                  style={{ textAlign: "start" }}
                >
                  {parsedDealCreatedAt
                    ? formatDateDifference(parsedDealCreatedAt, todayDate)
                    : "-"}
                </SolarzTypography>
              )}
            </Col>
          </Row>

          <Row align="top" gutter={24} wrap={false}>
            <Col span={14}>
              <SolarzTypography
                fontWeight="medium"
                variant="title"
                hierarchy="paragraph2"
                style={{ textAlign: "start" }}
              >
                Data de inicio
              </SolarzTypography>
            </Col>
            <Col span={10}>
              {isLoadingDeal || dealError ? (
                <Skeleton.Input active={isLoadingDeal} size="small" />
              ) : (
                <SolarzTypography
                  fontWeight="regular"
                  variant="disabled"
                  hierarchy="small"
                >
                  {parsedDealCreatedAt?.format("DD/MM/YYYY")}
                </SolarzTypography>
              )}
            </Col>
          </Row>

          <Row align="top" gutter={24} wrap={false}>
            <Col span={14}>
              <SolarzTypography
                fontWeight="medium"
                variant="title"
                hierarchy="paragraph2"
                style={{ textAlign: "start" }}
              >
                Data de fechamento estimada
              </SolarzTypography>
            </Col>
            <Col span={10}>
              {isLoadingDeal || dealError ? (
                <Skeleton.Input active={isLoadingDeal} size="small" />
              ) : (
                <SolarzTypography
                  fontWeight="regular"
                  variant="disabled"
                  hierarchy="small"
                  style={{ textAlign: "start" }}
                >
                  -{/* {dayjs().format("DD/MM/YYYY")} */}
                </SolarzTypography>
              )}
            </Col>
          </Row>

          <Row align="top" gutter={24} wrap={false}>
            <Col span={14}>
              <SolarzTypography
                fontWeight="medium"
                variant="title"
                hierarchy="paragraph2"
                style={{ textAlign: "start" }}
              >
                Inativo em dias
              </SolarzTypography>
            </Col>
            <Col span={10}>
              {isLoadingDeal || dealError ? (
                <Skeleton.Input active={isLoadingDeal} size="small" />
              ) : (
                <SolarzTypography
                  fontWeight="regular"
                  variant="disabled"
                  hierarchy="small"
                  style={{ textAlign: "start" }}
                >
                  -{/* {`${dayjs().diff(dayjs(), "days")} dias`} */}
                </SolarzTypography>
              )}
            </Col>
          </Row>
        </Flex>
      </Card>

      <Modal.DealForm
        isOpen={!!selectedDealId}
        onClose={handleCloseUpdateDealModal}
        title="Editar negócio"
        formProps={{
          onSuccess: handleCloseUpdateDealModal,
          dealId: selectedDealId ?? 0,
        }}
      />
    </>
  );
}
