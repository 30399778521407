import { useEffect, useRef } from "react";

import { ISolarzInputPasswordProps } from "./interface";
import { StyledInputPassword } from "./styles";

import { InputRef } from "antd";

export function SolarzInputPassword({
  defaultValue,
  onChange,
  placeholder,
  style,
  value,
  name,
  id,
}: ISolarzInputPasswordProps) {
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (inputRef.current?.input && defaultValue) {
      inputRef.current.input.value = defaultValue || "";
    }
  }, [inputRef, onChange, defaultValue]);

  return (
    <StyledInputPassword
      ref={inputRef}
      defaultValue={defaultValue ?? undefined}
      value={value ?? undefined}
      onChange={(event) => {
        if (typeof onChange === "function") {
          onChange(event.target.value ?? null);
        }
      }}
      onFocus={(event) => event.target.select()}
      style={style}
      placeholder={placeholder}
      name={name}
      id={id}
    />
  );
}
