import { useState } from "react";

import type { ICreateConcessionaireProfileButtonProps } from "./interface";

import { Modal } from "~components/Modal";
import { Button } from "antd";

export function CreateConcessionaireProfileButton({
  children,
  ...props
}: ICreateConcessionaireProfileButtonProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button
        {...props}
        data-cy="create-concessionaire-profile-button"
        onClick={() => setIsModalOpen(true)}
        type="primary"
        htmlType="button"
      >
        {children ?? "Criar tarifa de energia"}
      </Button>

      <Modal.ConcessionairesProfileForm
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        formProps={{
          onSuccess: () => setIsModalOpen(false),
        }}
      />
    </>
  );
}
