import { useState } from "react";

import type {
  IComplementaryItemFormProps,
  IComplementaryItemFormValues,
} from "./interface";

import { CustomInput } from "~components/CustomInput";
import { ComplementaryItemCategoryType, Select } from "~components/Select";
import { useAppConfig } from "~hooks/useAppConfig";
import { Button, Form, Radio, Flex, Collapse } from "antd";

export function ComplementaryItemForm({
  initialValues = {
    category: "Valor fixo",
    name: undefined,
    quantity: 1,
    unityAmount: undefined,
    key: undefined,
    additionalCostTaxId: undefined,
    applyTax: true,
    costAggregatorIds: undefined,
  },
  onlyUpdatePriceAndQuantity = false,
  canUpdateExtraFields = false,
  isLoading = false,
  onSubmit = () => undefined,
  onCancel = () => null,
  isEditForm = false,
}: IComplementaryItemFormProps) {
  const { screens } = useAppConfig();

  const [form] = Form.useForm<IComplementaryItemFormValues>();

  const [category, setCategory] = useState<
    ComplementaryItemCategoryType | undefined
  >(initialValues?.category);

  const applyTax = Form.useWatch("applyTax", form);

  function onSelectCategory(value: ComplementaryItemCategoryType) {
    if (value !== "Valor fixo") {
      form.setFieldValue("unityAmount", undefined);
      form.setFieldValue("quantity", 1);
    } else {
      form.setFieldValue("quantity", undefined);
      form.setFieldValue("unityAmount", undefined);
    }

    setCategory(value);
  }

  function handleSubmit() {
    const values = form.getFieldsValue(true) as IComplementaryItemFormValues;
    onSubmit({
      ...values,
      notApplyDefaultAdditionalCostTax: !!values.applyTax,
    });
  }

  const showPercentageInput =
    category === "Percentual I.C" ||
    category === "Percentual total" ||
    category === "Agregador custo";

  const showMoneyInput =
    category === "Valor fixo" ||
    category === "Multiplicador" ||
    category === "Personalizado" ||
    category === "Material";

  const showQuantityInput = category !== "Material";

  return (
    <Form
      data-cy="complementary-item-form"
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmit}
      layout="vertical"
    >
      <Form.Item
        name="name"
        label="Nome do item"
        rules={[{ required: true }]}
        extra={
          onlyUpdatePriceAndQuantity && "Sem permissão para alterar esse campo"
        }
      >
        <CustomInput.Text
          placeholder="Ex.: Inversor"
          disabled={onlyUpdatePriceAndQuantity}
        />
      </Form.Item>

      <Form.Item
        name="category"
        label="Categoria"
        rules={[{ required: true }]}
        extra={
          onlyUpdatePriceAndQuantity && "Sem permissão para alterar esse campo"
        }
      >
        <Select.ComplementaryItemCategory
          placeholder="Ex.: Módulo"
          onSelect={onSelectCategory}
          options={{
            hideMultiplicador: true,
            hidePersonalizado: true,
            hideMaterial: true,
          }}
          disabled={onlyUpdatePriceAndQuantity}
        />
      </Form.Item>

      {/* I know there is a more performative way, but this one is more readable */}
      {showPercentageInput && (
        <Form.Item
          name="unityAmount"
          label="Valor percentual"
          rules={[{ required: true }]}
          style={{ width: "100%" }}
        >
          <CustomInput.UnitMeasurement
            precision="2"
            unitMeasurement="%"
            max={100}
            min={0}
            data-cy="input-percentual-value"
          />
        </Form.Item>
      )}

      {showMoneyInput && (
        <>
          <Flex
            align="center"
            justify="space-between"
            wrap="nowrap"
            gap={screens.xs ? 0 : 16}
            style={{ flexDirection: screens.xs ? "column" : "row" }}
          >
            <Form.Item
              name="unityAmount"
              label="Valor unitário"
              rules={[{ required: true }]}
              style={{ width: "100%" }}
            >
              <CustomInput.Money min={0} style={{ width: "100%" }} />
            </Form.Item>

            {showQuantityInput && (
              <Form.Item
                name="quantity"
                label="Quantidade"
                rules={[{ required: true }]}
                style={{ width: "100%" }}
              >
                <CustomInput.Number
                  min={0}
                  precision={0}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            )}
          </Flex>
        </>
      )}

      {canUpdateExtraFields && (
        <Collapse
          items={[
            {
              key: 1,
              label: "Opcionais",
              children: (
                <>
                  <Form.Item
                    label="Aplicar Imposto pós precificado"
                    tooltip="Alíquota aplicada por fora ao final dos cálculos de precificação, útil quando há alíquotas de imposto diferente nos itens complementares ou quando há custos precificado em % sobre o global e custos em % precificados sobre os custos complementares simultaneamente e ainda seja necessário cálculo de imposto"
                    name="applyTax"
                  >
                    <Radio.Group
                      options={[
                        {
                          label: "Aplicar",
                          value: false,
                        },
                        {
                          label: "Não aplicar",
                          value: true,
                        },
                      ]}
                      onChange={() => {
                        form.setFieldValue("additionalCostTaxId", undefined);
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Escolher imposto pós precificado"
                    rules={[{ required: !applyTax }]}
                    name="additionalCostTaxId"
                  >
                    <Select.TaxesAfterPricing
                      isEditForm={isEditForm}
                      disabled={applyTax}
                      applyTax={applyTax}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Escolher grupo de agregador de custo"
                    name="costAggregatorIds"
                    tooltip='Agregadores de custo são utilizados para referenciar grupos de custo dentro de expressões personalizadas. Exemplo: se você tiver 5 custos que somados compõe a mão de obra, você pode indicar que eles pertencem ao grupo de custo "mão de obra" e usar o identificador do grupo para referenciar o somatório desse grupo dentro de uma expressão customizada.'
                  >
                    <Select.CostAggregator mode="multiple" />
                  </Form.Item>
                </>
              ),
            },
          ]}
          size="small"
          style={{ marginBottom: "1rem" }}
        />
      )}

      <Flex gap={12} justify="end">
        <Form.Item style={{ margin: 0 }}>
          <Button onClick={onCancel}>Cancelar</Button>
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button
            data-cy="save-button"
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            Salvar
          </Button>
        </Form.Item>
      </Flex>
    </Form>
  );
}
