import type { IProposalDealListProps } from "./interface";

import { Card } from "~components/Card";
import { Result } from "~components/Result";
import { useFetchGetPaginatedProposalByDealId } from "~hooks/api";
import { IProposalByDealIdResponseSchema } from "~types/schemas";
import { List } from "antd";

export function ProposalDealList({ dealId, style }: IProposalDealListProps) {
  const {
    data: proposal,
    isFetching: isLoadingProposal,
    error: proposalError,
    refetch: refetchProposal,
  } = useFetchGetPaginatedProposalByDealId({
    payload: {
      dealId,
    },
    dependencyArray: [dealId],
    options: {
      enabled: dealId > 0,
    },
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        ...style,
      }}
    >
      <List<IProposalByDealIdResponseSchema>
        data-cy="proposal-deal-list"
        itemLayout="horizontal"
        dataSource={proposal?.content ?? []}
        loading={isLoadingProposal}
        rowKey="id"
        locale={{
          emptyText: (
            <Result.TableErrorOrEmpty
              emptyMessage="Nenhuma proposta encontrada"
              errorMessage={proposalError?.message}
              reloadFn={refetchProposal}
              loading={isLoadingProposal}
            />
          ),
        }}
        renderItem={(proposalDeal) => (
          <List.Item
            style={{
              width: "100%",
              height: "100%",
              minHeight: "100px",
            }}
          >
            <Card.ProposalItem
              proposal={proposalDeal}
              style={{ width: "100%", height: "100%", padding: "12px" }}
            />
          </List.Item>
        )}
      />
    </div>
  );
}
