import { useMemo } from "react";

import type {
  IAldoIntegrationFormProps,
  IAldoIntegrationFormValues,
} from "./interface";

import { CustomInput } from "~components/CustomInput";
import { useFetchUpdateConfigAldoDistributorIntegration } from "~hooks/api";
import { Button, Flex, Form } from "antd";

const DEFAULT_VALUES: IAldoIntegrationFormValues = {
  authCode: "",
  token: "",
};

export function AldoIntegrationForm({
  initialValues,
  formInstance,
  onCancel = () => undefined,
  onError = () => undefined,
  onSuccess = () => undefined,
}: IAldoIntegrationFormProps) {
  const [form] = Form.useForm<IAldoIntegrationFormValues>(formInstance);

  const { mutate: updateConfigAldo, isLoading: isUpdatingConfigAldo } =
    useFetchUpdateConfigAldoDistributorIntegration({
      onError,
      onSuccess,
    });

  function handleSubmitForm(formValues: IAldoIntegrationFormValues) {
    updateConfigAldo(formValues);
  }

  const INITIAL_VALUES: IAldoIntegrationFormValues = useMemo(
    () => ({
      ...DEFAULT_VALUES,
      ...initialValues,
    }),
    [initialValues],
  );

  return (
    <Form
      data-cy="aldo-integration-form"
      form={form}
      onFinish={handleSubmitForm}
      initialValues={INITIAL_VALUES}
      layout="vertical"
      style={{ width: "100%" }}
    >
      <Form.Item<IAldoIntegrationFormValues>
        name="authCode"
        label="Código de autenticação"
        rules={[{ required: true, message: "Campo é obrigatório" }]}
      >
        <CustomInput.Text placeholder="Código de autenticação..." />
      </Form.Item>

      <Form.Item<IAldoIntegrationFormValues>
        name="token"
        label="Token"
        rules={[{ required: true, message: "Campo é obrigatório" }]}
      >
        <CustomInput.Text placeholder="Token..." />
      </Form.Item>

      <Flex align="center" gap={8} justify="end">
        <Button onClick={onCancel}>Cancelar</Button>
        <Button type="primary" htmlType="submit" loading={isUpdatingConfigAldo}>
          Integrar Aldo
        </Button>
      </Flex>
    </Form>
  );
}
