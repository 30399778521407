import styled from "@emotion/styled";
import { Table } from "antd";

export const StyledTable = styled(Table<any>)`
  thead .ant-table-cell {
    text-transform: uppercase;
    color: var(--gray-600) !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    line-height: 15px !important;
    padding: 1rem !important;
    background-color: var(--gray-100) !important;
  }

  ul.ant-pagination {
    margin: 0 !important;
    padding: 1.5rem 1rem;
    background-color: var(--gray-50) !important;

    .ant-pagination-total-text {
      margin: 0 auto 0 0;
      color: var(--gray-600);
    }

    .ant-pagination-prev {
      margin-inline-end: 8px;
    }
  }

  li.ant-pagination-simple-pager > input {
    width: 2rem;
  }

  .ant-table-row-expand-icon-cell {
    line-height: 0;
    width: 52px !important;
  }

  li.ant-pagination-next {
    cursor: default;
  }

  // DARK THEME
  html.dark & thead .ant-table-cell {
    color: var(--gray-400) !important;
    background-color: var(--gray-900) !important;
  }

  html.dark & ul.ant-pagination {
    background-color: var(--gray-900) !important;

    .ant-pagination-total-text {
      color: var(--gray-400);
    }

    .ant-pagination-prev a,
    .ant-pagination-next a {
      color: var(--primary-500);
    }

    .ant-pagination-prev a,
    .ant-pagination-next a {
      color: var(--primary-500);
    }

    .ant-pagination-disabled a {
      color: var(--gray-300) !important;
    }
  }

  // MOBILE
  @media (max-width: 768px) {
    ul.ant-pagination {
      justify-content: space-between !important;
      padding: 0.75rem;
      flex-wrap: nowrap;
    }

    ul .ant-pagination-total-text {
      text-align: left;
    }

    .ant-pagination-prev {
      text-align: left;
      margin-inline-end: 0 !important;
      display: flex;
      justify-content: start;
      align-items: start;
    }

    .ant-pagination-simple-pager {
      text-align: center;
      margin-inline-end: 0 !important;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1 1 100%;
    }

    .ant-pagination-next {
      text-align: right;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }
`;

export const TableExpandButton = styled.button`
  height: 20px;
  width: 20px;

  padding: 0;
  margin: 0;

  position: relative;

  background: none;
  border: none;
  border-radius: 4px;

  cursor: pointer;

  transition: background-color 0.3s;

  color: var(--gray-500);

  &:not(:disabled):hover {
    background-color: var(--gray-200);
  }

  &:disabled {
    cursor: not-allowed;
  }

  & > svg {
    height: 16px;
    width: 16px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s;
    transform-origin: center;
  }

  &.expanded > svg {
    transform: translate(-50%, -50%) rotate(90deg);
  }

  &:focus-within {
    outline: 2px solid var(--primary-300);
  }
`;
