import { useAppConfig } from "~hooks/useAppConfig";

interface IHandleCopyOptions {
  triggerMessageInCopy?: boolean;
  onSuccess?: (value?: string) => void;
  onError?: (error?: string) => void;
}

const HANDLE_COPY_DEFAULT_OPTIONS = {
  onError: undefined,
  onSuccess: undefined,
  triggerMessageInCopy: true,
} as IHandleCopyOptions;

export function useCopyToClipboard() {
  const { message } = useAppConfig();

  const handleCopy = async (text: string, options?: IHandleCopyOptions) => {
    const handleCopyOptions = {
      ...HANDLE_COPY_DEFAULT_OPTIONS,
      ...options,
    };

    try {
      if (!navigator?.clipboard) {
        throw new Error("Não suportado/permitido pelo browser!");
      }

      await navigator.clipboard.writeText(text);

      if (handleCopyOptions?.triggerMessageInCopy) {
        message.success("Copiado para área de transferência!");
      }

      if (typeof options?.onSuccess === "function") {
        options.onSuccess(text);
      }

      return true;
    } catch (error: any) {
      message.error("Falha ao copiar: " + error?.message);

      if (typeof options?.onError === "function") {
        options.onError(error?.message);
      }

      return false;
    }
  };

  return { handleCopy };
}
