import { IMonthlyPerformanceRate } from "~types/schemas";

export function getAverageMonthlyPerformanceRate(
  performanceRate: Partial<IMonthlyPerformanceRate>,
) {
  const values = Object.values<number>(performanceRate);

  const total = values.reduce((total, value) => total + value, 0);

  const average = Number((total / 12).toFixed(2));

  return average;
}
