import styled from "@emotion/styled";
import { ReleaseNotesIOButton } from "~components/Others/ReleaseNotesIOButton";

export const StyledReleaseNotesIOButton = styled(ReleaseNotesIOButton)`
  padding: 0 !important;
  margin: 0 !important;

  padding-inline-start: 0 !important;
  padding-inline-end: 0 !important;

  border: none !important;
  color: var(--gray-400) !important;
`;
