import styled from "@emotion/styled";
import { SolarzAnchor } from "~solarzui/SolarzAnchor";

export const StyledSolarzAnchor = styled(SolarzAnchor)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
  cursor: pointer;
  gap: 4px;

  text-decoration: none !important;
`;
