import { CardItem } from "./CardItem";
import { IDemandWarningCardProps } from "./interface";

import { Card, Col, Typography } from "antd";

export function DemandWarningCard({
  simplified = false,
  width,
  maxWidth = 280,
  height,
  maxHeight,
  isProposalStepLoading,
  demandAfterSolar,
  demandBeforeSolar,
}: IDemandWarningCardProps) {
  return (
    <Card
      title={!simplified && "ATENÇÃO"}
      size="small"
      style={{
        width: width ?? "100%",
        height,
        maxHeight,
        maxWidth,
      }}
    >
      <Col>
        {simplified && (
          <Typography.Text style={{ fontWeight: "bold", paddingLeft: 4 }}>
            Atenção
          </Typography.Text>
        )}
        <CardItem
          title={`A demanda anterior era ${demandBeforeSolar} kW e nova demanda será ${demandAfterSolar} kW`}
          value="Haverá um aumento de demanda neste projeto."
          isLoading={isProposalStepLoading}
        />
      </Col>
    </Card>
  );
}
