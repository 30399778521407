import styled from "@emotion/styled";
import { Card, Flex, Timeline } from "antd";

export const StyledTimeline = styled(Timeline)`
  .ant-timeline-item-head {
    background-color: transparent !important;
  }

  .rdw-mention-link {
    color: var(--primary-500);
    background-color: transparent;
  }
`;

export const StyledCard = styled(Card)`
  background-color: var(--gray-100);

  html.dark & {
    background-color: var(--gray-900);
  }
`;
//  min-height: 94px;
export const StyledFlex = styled(Flex)`
  flex-direction: column;
  padding: 0 0 20px 20px;
`;
export const StyledFlexNote = styled(Flex)`
  flex-direction: column;
  padding: 12px;
  background-color: var(--gray-100);
  border-radius: 4px;
  border: 1px solid var(--gray-200);

  .rdw-link-decorator-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
    gap: 0.2rem;
  }

  .rdw-link-decorator-wrapper a {
    color: var(--primary-500);
  }

  .rdw-link-decorator-icon {
    position: unset;
    margin-bottom: 0.1rem;
    background-color: transparent;
  }

  html.dark & {
    background-color: var(--gray-900);
    border: 1px solid var(--gray-800);
  }
`;
