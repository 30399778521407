import NextImage from "next/image";

import { ICompanyLogoProps } from "./interface";

import { useFetchGetCompanyLogo } from "~hooks/api";
import { useSession } from "~hooks/useSession";
import { Flex, Skeleton, Tooltip } from "antd";

export function CompanyLogo({
  height = 42,
  width = 164,
  displayTooltip = true,
}: ICompanyLogoProps) {
  const { user, sessionStatus } = useSession();

  const {
    data: logoBase64,
    isFetching: isLoadingGetCompanyLogo,
    error,
  } = useFetchGetCompanyLogo({
    dependencyArray: [user?.id],
    options: {
      enabled: !!user?.id,
      retry: 1,
    },
  });

  const logoSrc =
    logoBase64 && !error
      ? `data:image/png;base64,${logoBase64}`
      : "/images/logo-solar-z.png";

  const alt = `Logo da ${(user?.company && logoBase64 && !error) ?? "SolarZ"}`;

  const isLoading = isLoadingGetCompanyLogo || sessionStatus === "loading";

  return (
    <Tooltip title={displayTooltip && user?.company} placement="right">
      <Flex
        justify="center"
        align="center"
        data-cy="company-logo"
        style={{ width, height }}
      >
        {isLoading ? (
          <Skeleton.Image active style={{ width, height }} />
        ) : (
          <div style={{ width: "100%", height: "100%", position: "relative" }}>
            <NextImage
              src={logoSrc}
              alt={alt}
              fill
              style={{
                objectFit: "contain",
              }}
              priority
            />
          </div>
        )}
      </Flex>
    </Tooltip>
  );
}
