import { useEffect, useState } from "react";

import type {
  ISolarzDateRangePicker,
  SolarzDateRangePickerValueType,
} from "./interface";
import { StyledDateRangePicker } from "./styles";

export function SolarzDateRangePicker({
  format = "DD, MMM. YYYY",
  defaultValue = [null, null],
  onChange,
  ...props
}: ISolarzDateRangePicker) {
  const [currentDate, setCurrentDate] =
    useState<SolarzDateRangePickerValueType>(defaultValue);

  useEffect(() => {
    if (props.value !== undefined) {
      setCurrentDate(props.value);
    }
  }, [props.value]);

  function handleChangeDate(dates: SolarzDateRangePickerValueType) {
    setCurrentDate(dates);

    if (typeof onChange === "function") {
      onChange(dates);
    }
  }

  return (
    <StyledDateRangePicker
      {...props}
      value={currentDate}
      format={format}
      allowClear={false}
      suffixIcon={null}
      onChange={(dates) => {
        handleChangeDate([dates?.[0] ?? null, dates?.[1] ?? null]);
      }}
      disabled={props.disabled}
      scale={props.scale ?? "medium"}
      ref={(ref) => {
        const inputElement = ref?.nativeElement.querySelector("input");

        if (inputElement) {
          inputElement.readOnly = true;
        }
      }}
    />
  );
}
