import { useMemo, useState } from "react";

import {
  IProductsStructuresTableProps,
  ProductsStructuresTableColumnType,
} from "./interface";

import { createPaginationTableObject } from "~components/Pagination";
import { Result } from "~components/Result";
import { useFetchGetPaginatedStructures } from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import type { ICustomPvModuleStructureDTOSchema } from "~types/schemas";
import { parseCurrency } from "~utils/parse";
import { Table } from "antd";

export function ProductsStructuresTable({
  size = "small",
}: IProductsStructuresTableProps) {
  const { screens } = useAppConfig();
  const [currentPage, setCurrentPage] = useState(0);

  const {
    data: structures,
    isLoading: isStructuresLoading,
    error: structuresError,
  } = useFetchGetPaginatedStructures({
    dependencyArray: [currentPage],
    payload: {
      page: currentPage,
    },
  });

  const DATA_SOURCE: ICustomPvModuleStructureDTOSchema[] = useMemo(() => {
    return (
      structures?.content.map((item) => ({
        id: item.id,
        appliesTo: item.appliesTo,
        brand: item.brand,
        distributor: item.distributor,
        name: item.name,
        numSupportedModules: item.numSupportedModules,
        precoBaseCents: item.precoBaseCents,
        subtype: item.subtype,
        type: item.type,
      })) ?? []
    );
  }, [structures?.content]);

  const columns: ProductsStructuresTableColumnType = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Marca",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Distribuidor",
      dataIndex: "distributor",
      key: "distributor",
    },
    {
      title: "Tipo",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Módulos Suportados",
      dataIndex: "numSupportedModules",
      key: "numSupportedModules",
    },
    {
      title: "Preço",
      dataIndex: "precoBaseCents",
      key: "precoBaseCents",
      render: (item) => parseCurrency(item / 100),
    },
  ];

  return (
    <Table
      data-cy="products-structures-table"
      pagination={createPaginationTableObject({
        onChange: (page) => setCurrentPage(page - 1),
        size: structures?.size,
        disabled: false,
        itemType: "estruturas",
        number: structures?.number ? structures?.number + 1 : 1,
        totalElements: structures?.totalElements,
        displayTotalInformation: screens.sm,
      })}
      loading={isStructuresLoading}
      style={{ width: "100%" }}
      tableLayout="auto"
      rowKey="id"
      scroll={{ x: 720 }}
      columns={columns}
      dataSource={DATA_SOURCE}
      size={size}
      locale={{
        emptyText: (
          <Result.TableErrorOrEmpty
            errorMessage={structuresError?.message}
            emptyMessage="Nenhum módulo encontrado."
          />
        ),
      }}
    />
  );
}
