import * as React from "react";

import type { IIconProps } from "../@types/globals";
import { cn } from "../lib/utils";

const Express: React.FC<IIconProps> = ({ className, ...props }) => (
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    stroke="currentColor"
    className={cn("pointer-events-none w-6 h-6", className)}
    {...props}
  >
    <title>Expressa</title>
    <path d="M21.8128 10.7494L13.545 5.4844C13.32 5.34056 13.0605 5.25975 12.7937 5.25042C12.5268 5.24109 12.2623 5.30358 12.0278 5.43135C11.7934 5.55913 11.5975 5.74751 11.4607 5.97683C11.3238 6.20615 11.2511 6.46799 11.25 6.73503V10.2319L3.795 5.4844C3.57002 5.34056 3.31054 5.25975 3.04367 5.25042C2.7768 5.24109 2.51232 5.30358 2.27784 5.43135C2.04336 5.55913 1.84748 5.74751 1.71066 5.97683C1.57383 6.20615 1.50108 6.46799 1.5 6.73503V17.265C1.50139 17.5319 1.57433 17.7936 1.71122 18.0227C1.84811 18.2519 2.04394 18.4401 2.27832 18.5678C2.51269 18.6955 2.77703 18.7581 3.04378 18.7489C3.31054 18.7397 3.56996 18.6592 3.795 18.5157L11.25 13.7682V17.265C11.2514 17.5319 11.3243 17.7936 11.4612 18.0227C11.5981 18.2519 11.7939 18.4401 12.0283 18.5678C12.2627 18.6955 12.527 18.7581 12.7938 18.7489C13.0605 18.7397 13.32 18.6592 13.545 18.5157L21.8128 13.2507C22.0235 13.117 22.1969 12.9322 22.3171 12.7136C22.4373 12.495 22.5003 12.2495 22.5003 12C22.5003 11.7505 22.4373 11.5051 22.3171 11.2865C22.1969 11.0678 22.0235 10.8831 21.8128 10.7494ZM3 17.2444V6.75659L11.2331 12L3 17.2444ZM12.75 17.2444V6.75659L20.9831 12L12.75 17.2444Z" />
  </svg>
);

Express.displayName = "Express";

export { Express };
