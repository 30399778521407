import { useState } from "react";

import type {
  IServicesDealCardProps,
  IServicesDealCardQueryKeys,
} from "./interface";
import { StyledCard, StyledCollapse } from "./styles";

import { Alert } from "~components/Alert";
import { List } from "~components/List";
import { Modal } from "~components/Modal";
import { ArrowRight, Edit } from "~estrela-ui/icons";
import { useFetchGetDealByCustomId } from "~hooks/api";
import { parseFunctions, useQueryParams } from "~hooks/useQueryParams";
import { SolarzAnchor } from "~solarzui/SolarzAnchor";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { parseCurrency, parsePotency } from "~utils/parse";
import { Col, Row, Skeleton, Tooltip } from "antd";

const DEFAULT_QUERY_KEYS: IServicesDealCardQueryKeys = {
  createServiceModalKey: "adicionar-servico",
};

export function ServicesDealCard({
  dealId,
  style,
  queryKeys,
  ...props
}: IServicesDealCardProps) {
  const { getParsedQueryParams, handleSaveInQueryParams } = useQueryParams();

  // QUERY KEYS
  const createServiceQueryKey =
    queryKeys?.createServiceModalKey ??
    DEFAULT_QUERY_KEYS.createServiceModalKey;

  const isCreateServiceModalOpen = !!getParsedQueryParams(
    createServiceQueryKey,
    parseFunctions.BOOLEAN,
  );

  const [isCollapseExpanded, setIsCollapseExpanded] = useState(true);

  const {
    data: deal,
    isFetching: isLoadingDeal,
    error: errorDeal,
    refetch: reloadDeal,
  } = useFetchGetDealByCustomId({
    payload: {
      customId: dealId,
    },
    dependencyArray: [dealId],
    options: {
      enabled: dealId > 0,
      retry: 1,
    },
  });

  function handleOpenModal() {
    handleSaveInQueryParams({ [createServiceQueryKey]: "true" });
  }

  function handleCloseModal() {
    handleSaveInQueryParams({ [createServiceQueryKey]: null });
  }

  function handleExpandCollapse() {
    setIsCollapseExpanded(true);
  }

  function handleCompressCollapse() {
    setIsCollapseExpanded(false);
  }

  const solarService = deal?.solarService;
  const diverseService = deal?.diverseService;

  const isEditButtonDisabled =
    (!solarService && !diverseService) || !deal.active;

  return (
    <>
      {errorDeal && (
        <Alert.CardError
          title="Falha ao carregar dados"
          reloadFn={reloadDeal}
          errorMessage={errorDeal?.message}
        />
      )}

      <StyledCard
        data-cy="services-deal-card"
        {...props}
        style={{
          maxWidth: "unset",
          ...style,
        }}
        styles={{
          header: {
            padding: "0.5rem 1rem",
          },
        }}
        title={
          <div className="flex items-center justify-between">
            <SolarzTypography
              fontWeight="semiBold"
              hierarchy="paragraph1"
              variant="title"
            >
              Serviços
            </SolarzTypography>

            <Tooltip
              title={
                !solarService && !diverseService
                  ? "Necessário ao menos um serviço para alterar"
                  : undefined
              }
            >
              <span>
                <SolarzButton
                  scale="tiny"
                  variant="ghost"
                  isDisabled={isEditButtonDisabled}
                  onClick={handleOpenModal}
                  icon={
                    <Edit
                      style={{
                        color: isEditButtonDisabled
                          ? "var(--gray-300)"
                          : "var(--primary-500)",
                      }}
                    />
                  }
                  style={{
                    border: "1px solid var(--gray-300)",
                    width: 28,
                    height: 28,
                    padding: 0,
                  }}
                />
              </span>
            </Tooltip>
          </div>
        }
      >
        {(solarService || diverseService) && (
          <StyledCollapse
            collapsible="icon"
            activeKey={isCollapseExpanded ? ["services-deal"] : []}
            expandIcon={() => undefined} // remove default icon
            items={[
              {
                key: "services-deal",
                label: (
                  <div className="flex flex-col justify-center items-start gap-4">
                    {solarService && (
                      <div className="w-full">
                        <header className="flex w-full items-center justify-between">
                          <SolarzTypography
                            fontWeight="semiBold"
                            hierarchy="paragraph1"
                            variant="title"
                          >
                            Propostas solares
                          </SolarzTypography>
                          <SolarzButton
                            scale="tiny"
                            isDisabled={isLoadingDeal}
                            icon={
                              <ArrowRight
                                className={`h-4 w-4 text-primary ${isCollapseExpanded ? "rotate-90" : "rotate-0"}`}
                              />
                            }
                            onClick={
                              isCollapseExpanded
                                ? handleCompressCollapse
                                : handleExpandCollapse
                            }
                            variant="secondary"
                            style={{
                              borderRadius: 9999,
                              height: 24,
                              width: 24,
                              padding: 0,
                              borderColor: "var(--gray-200)",
                            }}
                          />
                        </header>
                        <Row gutter={6} className="mt-4">
                          <Col span={12}>
                            <SolarzTypography
                              fontWeight="semiBold"
                              hierarchy="paragraph2"
                              variant="subTitle"
                            >
                              Necessidade energética
                            </SolarzTypography>
                            {isLoadingDeal || errorDeal?.message ? (
                              <Skeleton.Input
                                active={isLoadingDeal}
                                size="small"
                              />
                            ) : (
                              <SolarzTypography
                                fontWeight="regular"
                                hierarchy="paragraph2"
                                variant="default"
                              >
                                {parsePotency(solarService.desiredPower)}
                              </SolarzTypography>
                            )}
                          </Col>
                          <Col span={12}>
                            <SolarzTypography
                              fontWeight="semiBold"
                              hierarchy="paragraph2"
                              variant="subTitle"
                            >
                              Potência desejada
                            </SolarzTypography>
                            {isLoadingDeal || errorDeal?.message ? (
                              <Skeleton.Input
                                active={isLoadingDeal}
                                size="small"
                              />
                            ) : (
                              <SolarzTypography
                                fontWeight="regular"
                                hierarchy="paragraph2"
                                variant="default"
                              >
                                {parsePotency(solarService.energyRequirement)}
                              </SolarzTypography>
                            )}
                          </Col>
                        </Row>
                        <Row gutter={6} className="mt-4">
                          <Col span={12}>
                            <SolarzTypography
                              fontWeight="semiBold"
                              hierarchy="paragraph2"
                              variant="subTitle"
                            >
                              Tipo de tensão
                            </SolarzTypography>
                            {isLoadingDeal || errorDeal?.message ? (
                              <Skeleton.Input
                                active={isLoadingDeal}
                                size="small"
                              />
                            ) : (
                              <SolarzTypography
                                fontWeight="regular"
                                hierarchy="paragraph2"
                                variant="default"
                              >
                                {solarService.voltageType === "LOW"
                                  ? "Baixa"
                                  : "Média"}
                              </SolarzTypography>
                            )}
                          </Col>
                          <Col span={12}>
                            <SolarzTypography
                              fontWeight="semiBold"
                              hierarchy="paragraph2"
                              variant="subTitle"
                            >
                              Cidade/UF
                            </SolarzTypography>
                            {isLoadingDeal || errorDeal?.message ? (
                              <Skeleton.Input
                                active={isLoadingDeal}
                                size="small"
                              />
                            ) : (
                              <SolarzTypography
                                fontWeight="regular"
                                hierarchy="paragraph2"
                                variant="default"
                              >
                                {solarService?.city?.name ?? "Não informado"}
                              </SolarzTypography>
                            )}
                          </Col>
                        </Row>
                      </div>
                    )}

                    {diverseService && (
                      <div className="w-full">
                        <header className="flex w-full items-center justify-between">
                          <SolarzTypography
                            fontWeight="semiBold"
                            hierarchy="paragraph1"
                            variant="title"
                          >
                            Propostas Diversas
                          </SolarzTypography>
                          <SolarzButton
                            scale="tiny"
                            isDisabled={isLoadingDeal}
                            icon={
                              <ArrowRight
                                className={`h-4 w-4 text-primary ${isCollapseExpanded ? "rotate-90" : "rotate-0"}`}
                              />
                            }
                            onClick={
                              isCollapseExpanded
                                ? handleCompressCollapse
                                : handleExpandCollapse
                            }
                            variant="secondary"
                            style={{
                              borderRadius: 9999,
                              height: 24,
                              width: 24,
                              padding: 0,
                              borderColor: "var(--gray-200)",
                            }}
                          />
                        </header>
                        <Row gutter={6} className="mt-4">
                          <Col span={24}>
                            <SolarzTypography
                              fontWeight="semiBold"
                              hierarchy="paragraph2"
                              variant="subTitle"
                            >
                              Valor total
                            </SolarzTypography>
                            {isLoadingDeal || errorDeal?.message ? (
                              <Skeleton.Input
                                active={isLoadingDeal}
                                size="small"
                              />
                            ) : (
                              <SolarzTypography
                                fontWeight="regular"
                                hierarchy="paragraph2"
                                variant="default"
                              >
                                {parseCurrency(diverseService.totalValue)}
                              </SolarzTypography>
                            )}
                          </Col>
                        </Row>
                      </div>
                    )}
                  </div>
                ),
                children: (
                  <div className="flex flex-col">
                    <List.ProposalDeal
                      dealId={dealId}
                      style={{ width: "100%" }}
                    />

                    <div className="mx-auto">
                      <SolarzAnchor
                        isNextLink
                        href={`/proposta?negocio=${dealId}`}
                        style={{
                          marginTop: 12,
                          marginBottom: 24,
                          fontWeight: "600",
                          fontSize: 14,
                          textDecoration: "none",
                        }}
                        isDisabled={!deal.active}
                      >
                        Nova proposta
                      </SolarzAnchor>
                    </div>
                  </div>
                ),
              },
            ]}
            expandIconPosition="right"
            defaultActiveKey={
              solarService || diverseService ? ["services-deal"] : []
            }
            style={style}
          />
        )}

        {!solarService && !diverseService && (
          <SolarzButton
            variant="ghost"
            onClick={handleOpenModal}
            className="mx-auto my-2"
          >
            Adicionar serviço
          </SolarzButton>
        )}
      </StyledCard>

      <Modal.ServicesDealForm
        dealId={dealId}
        isOpen={isCreateServiceModalOpen}
        onClose={handleCloseModal}
        formPropsSolarService={{
          onSuccess: handleCloseModal,
        }}
        formPropsDiverseService={{
          onSuccess: handleCloseModal,
        }}
        title="Adicionar serviço"
      />
    </>
  );
}
