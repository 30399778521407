import type { IKitItemFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function KitItemFormModal({
  isOpen = false,
  onClose = () => null,
  title = "Formulário do item do kit",
  width = 512,
  formProps = {
    initialValues: undefined,
    onSubmit: () => undefined,
  },
}: IKitItemFormModalProps) {
  return (
    <Modal
      data-cy="kit-item-form-modal"
      open={isOpen}
      onCancel={onClose}
      title={title}
      width={width}
      footer={null}
      destroyOnClose
    >
      <Form.KitItem {...formProps} onCancel={onClose} />
    </Modal>
  );
}
