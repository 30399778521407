import { useEffect, useMemo, useRef } from "react";

import type {
  RecordType,
  IFullCalendarProps,
  OnItemClickEventType,
  OnDropEventType,
  OnResizeEventType,
  OnSelectEventType,
} from "./interface";
import { CalendarWrapper } from "./styles";
import { TimeIndicator } from "./TimeIndicator";

import PluginInteraction from "@fullcalendar/interaction";
import type FullCalendarRefType from "@fullcalendar/react";
import Calendar from "@fullcalendar/react";
import PluginTimeGrid from "@fullcalendar/timegrid";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { Alert, Flex, Spin } from "antd";
import dayjs from "dayjs";

/*
OBS: use ref to count timer not work, because Calendar render
two indicators elements using same ref in distinct locations,
so React loses one of the refs.
*/

export function FullCalendar<Record = RecordType>({
  displayHeader = true,
  itemRender,
  viewType,
  activities,
  errorMessage,
  extraComponent,
  height,
  isLoading,
  onDrop,
  onItemClick,
  onResize,
  onSelect,
  refetchFn,
  date,
}: IFullCalendarProps<Record>) {
  const calendarRef = useRef<FullCalendarRefType | null>(null);

  useEffect(() => {
    if (calendarRef.current && viewType) {
      calendarRef.current
        .getApi()
        .changeView(viewType === "day" ? "timeGridDay" : "timeGridWeek");
    }
  }, [calendarRef, viewType]);

  useEffect(() => {
    if (calendarRef.current && date) {
      calendarRef.current.getApi().gotoDate(date?.toISOString());
    }
  }, [calendarRef, date]);

  // PREVENTS FLICK
  const MEMOIZED_CALENDAR = useMemo(() => {
    return (
      <Calendar
        ref={calendarRef}
        plugins={[PluginInteraction, PluginTimeGrid]}
        locale="pt-br"
        slotDuration="00:30:00"
        slotLabelInterval="01:00:00"
        timeZone="America/Sao_Paulo"
        allDayContent=""
        nowIndicatorContent={<TimeIndicator />}
        editable
        selectable
        selectMirror
        dayMaxEvents
        nowIndicator
        headerToolbar={false}
        events={activities?.map((activity) => {
          return {
            ...activity,
            start: activity.start ? activity.start?.toJSON() : "",
            end: activity.end ? activity.end?.toJSON() : "",
            id: activity.id.toString(),
            record: activity,
          };
        })}
        height={height}
        dayHeaders={!!displayHeader}
        dayHeaderFormat={{ weekday: "long", day: "numeric" }}
        scrollTime={dayjs().subtract(1, "hour").format("HH:mm:ss")}
        eventDrop={(event) => {
          if (typeof onDrop !== "function") return;

          const data: OnDropEventType = {
            id: Number(event.event.id),
            allDay: event.event.allDay,
            end: event.event.end
              ? dayjs(event.event.end).add(3, "hours")
              : null,
            start: event.event.start
              ? dayjs(event.event.start).add(3, "hours")
              : null,
            title: event.event.title,
            record: event.event.extendedProps,
          };

          onDrop(data);
        }}
        eventResize={(event) => {
          if (typeof onResize !== "function") return;

          const data: OnResizeEventType = {
            id: Number(event.event.id),
            allDay: event.event.allDay,
            end: event.event.end
              ? dayjs(event.event.end).add(3, "hours")
              : null,
            start: event.event.start
              ? dayjs(event.event.start).add(3, "hours")
              : null,
            title: event.event.title,
            record: event.event.extendedProps,
          };

          onResize(data);
        }}
        eventClick={(event) => {
          if (typeof onItemClick !== "function") return;

          // YES IT IS NECESSARY, THE F*** EVENT.EVENT.END NOT RETURN A VALUE
          const currentElement = activities?.find(
            (activity) => activity.id === Number(event.event.id),
          );

          const data: OnItemClickEventType = {
            id: Number(event.event.id),
            allDay: event.event.allDay,
            end: currentElement?.end ?? null,
            start: currentElement?.start ?? null,
            title: event.event.title,
            record: event.event.extendedProps,
          };

          onItemClick(data);
        }}
        select={(event) => {
          if (typeof onSelect !== "function") return;

          const cellData: OnSelectEventType = {
            title: event.view.title,
            allDay: event.allDay,
            end: event.end ? dayjs(event.end).add(3, "hours") : null,
            start: event.start ? dayjs(event.start).add(3, "hours") : null,
          };

          onSelect(cellData);
        }}
        slotLabelFormat={{
          hour: "numeric",
          minute: "2-digit",
          omitZeroMinute: false,
          meridiem: "short",
        }}
        eventContent={(eventContent) => {
          if (typeof itemRender !== "function") return;

          const item = {
            id: Number(eventContent.event.id),
            title: eventContent.event.title,
            start: eventContent.event.start
              ? dayjs(eventContent.event.start).add(3, "hours")
              : null,
            end: eventContent.event.end
              ? dayjs(eventContent.event.end).add(3, "hours")
              : null,
            allDay: eventContent.event.allDay,
            record: eventContent.event.extendedProps as Record,
          };

          return itemRender(item);
        }}
      />
    );
  }, [
    displayHeader,
    activities,
    height,
    onDrop,
    onResize,
    onItemClick,
    onSelect,
    itemRender,
  ]);

  return (
    <CalendarWrapper data-cy="full-calendar" displayHeader={!!displayHeader}>
      {errorMessage && (
        <Alert
          type="error"
          message={
            <Flex vertical gap={8}>
              <SolarzTypography
                hierarchy="paragraph2"
                fontWeight="medium"
                variant="danger"
              >
                Falha ao carregar dados:
              </SolarzTypography>
              <SolarzTypography hierarchy="small" variant="danger">
                {errorMessage}
              </SolarzTypography>

              {typeof refetchFn === "function" && (
                <Flex>
                  <SolarzButton
                    onClick={refetchFn}
                    scale="tiny"
                    variant="primary"
                    style={{ display: "flex" }}
                  >
                    Recarregar
                  </SolarzButton>
                </Flex>
              )}
            </Flex>
          }
          style={{ marginBottom: "1rem" }}
        />
      )}

      {extraComponent}

      <Spin spinning={!!isLoading}>{MEMOIZED_CALENDAR}</Spin>
    </CalendarWrapper>
  );
}
