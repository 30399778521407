import { useMemo, useRef, useState } from "react";
import {
  AiOutlineCheckCircle,
  AiOutlineDownload,
  AiOutlineQuestionCircle,
} from "react-icons/ai";
import { BiErrorCircle } from "react-icons/bi";
import { MdOutlineCancel } from "react-icons/md";

import { IProductsImportHistoryListProps } from "./interface";

import { createPaginationTableObject } from "~components/Pagination";
import { Result } from "~components/Result";
import {
  useFetchCancelImportProductQueue,
  useFetchGetPaginatedProductImportHistory,
} from "~hooks/api";
import { useFetchGetDownloadProductQueue } from "~hooks/api/product-queue/useFetchGetDownloadProductQueue";
import { useAppConfig } from "~hooks/useAppConfig";
import { ImportStatusEnum, ImportTypeEnum } from "~types/enum";
import { Button, List, Popconfirm, Spin, Tooltip, Flex } from "antd";
import dayjs from "dayjs";

export function ProductsImportHistoryList(
  props: IProductsImportHistoryListProps,
) {
  const { screens } = useAppConfig();

  const downloadLinkRef = useRef<HTMLAnchorElement>(null);

  const [currentPage, setCurrentPage] = useState(1);

  const [selectedIdToDownload, setSelectedIdToDownload] = useState<number>(0);

  useFetchGetDownloadProductQueue({
    payload: {
      id: selectedIdToDownload,
    },
    options: {
      enabled: selectedIdToDownload > 0,
      onSuccess(data) {
        const url = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${data.data}`;

        if (downloadLinkRef.current) {
          downloadLinkRef.current.href = url;
        }

        downloadLinkRef?.current?.click();
      },
    },
    dependencyArray: [selectedIdToDownload],
  });

  const { mutate: cancelImportation, isLoading } =
    useFetchCancelImportProductQueue({});

  const { data, isFetching, error } = useFetchGetPaginatedProductImportHistory({
    dependencyArray: [currentPage],
    payload: {
      size: 5,
      page: currentPage - 1,
    },
    options: {
      refetchInterval: 10000,
    },
  });

  function parseImportTypeToLabel(importType: ImportTypeEnum) {
    switch (importType) {
      case "CLEAN_ALL_BEFORE_INSERT":
        return "Remover produtos atuais";
      case "UPDATE_AND_INSERT":
        return "Manter produtos atuais";
      default:
        return "";
    }
  }

  function parseImportStatusToIcon(
    importStatus: ImportStatusEnum,
    error?: string,
  ) {
    const ICON_SIZE = 18;

    switch (importStatus) {
      case "CANCELLED":
        return (
          <Tooltip title="Importação foi cancelada">
            <MdOutlineCancel
              size={ICON_SIZE}
              style={{ color: "var(--gold-500)" }}
            />
          </Tooltip>
        );
      case "DONE":
        return (
          <Tooltip title="Importado com sucesso!">
            <AiOutlineCheckCircle
              size={ICON_SIZE}
              style={{ color: "var(--green-500)" }}
            />
          </Tooltip>
        );
      case "ERROR":
        return (
          <Tooltip title={`Falha na importação: ${error ?? "Indefinido"}`}>
            <BiErrorCircle
              size={ICON_SIZE}
              style={{ color: "var(--red-500)" }}
            />
          </Tooltip>
        );
      case "PENDING":
        return (
          <Tooltip title="Dados em fila de processamento">
            <Spin spinning size="small" />
          </Tooltip>
        );
      case "PROCESSING":
        return (
          <Tooltip title="Dados estão sendo processados">
            <Spin spinning size="small" />
          </Tooltip>
        );
      default:
        return (
          <Tooltip title="Informação desconhecida">
            <AiOutlineQuestionCircle
              size={ICON_SIZE}
              style={{ color: "var(--gray-500)" }}
            />
          </Tooltip>
        );
    }
  }

  const DATA_SOURCE = useMemo(() => data?.content ?? [], [data?.content]);

  return (
    <>
      <a
        ref={downloadLinkRef}
        style={{ display: "none", opacity: 0, width: 0, height: 0 }}
        type="link"
        download="download.xlsx"
      >
        Download
      </a>
      <List
        data-cy="products-import-history-list"
        {...props}
        itemLayout="horizontal"
        dataSource={DATA_SOURCE}
        loading={isFetching}
        rowKey="id"
        locale={{
          emptyText: (
            <Result.TableErrorOrEmpty
              errorMessage={error?.message}
              emptyMessage="Nenhuma importação encontrado."
            />
          ),
        }}
        pagination={createPaginationTableObject({
          number: currentPage,
          size: data?.size,
          totalElements: data?.totalElements,
          onChange: (page) => setCurrentPage(page),
          itemType: "registros",
          displayTotalInformation: screens.sm,
        })}
        renderItem={(history) => {
          const title = parseImportTypeToLabel(history.importType);
          const icon = parseImportStatusToIcon(
            history.importStatus,
            history.response,
          );
          const description = dayjs(history.createdAt).format(
            "DD/MM/YYYY HH:mm:ss",
          );
          const displayButtonToCancel = history.importStatus === "PENDING";

          return (
            <List.Item style={{ width: "100%" }}>
              <List.Item.Meta
                title={
                  <Flex align="center" gap={4}>
                    <span>{title}</span>
                    {icon}
                  </Flex>
                }
                description={description}
              />

              <Flex align="center" gap={4} wrap="nowrap">
                {displayButtonToCancel && (
                  <Tooltip title="Cancelar importação" placement="left">
                    <Popconfirm
                      title="Cancelar importação"
                      description="Você tem certeza que deseja cancelar importação?"
                      onConfirm={() => cancelImportation({ id: history.id })}
                      okText="Sim"
                      cancelText="Não"
                    >
                      <Button size="small" disabled={isLoading}>
                        {isLoading ? (
                          <Spin size="small" spinning />
                        ) : (
                          <MdOutlineCancel
                            size={18}
                            style={{ color: "var(--volcano-500)" }}
                          />
                        )}
                      </Button>
                    </Popconfirm>
                  </Tooltip>
                )}

                <Tooltip title="Baixar planilha enviada">
                  <Button size="small" type="link">
                    <AiOutlineDownload
                      onClick={() => setSelectedIdToDownload(history.id)}
                      size={18}
                      style={{ color: "var(--blue-500)" }}
                    />
                  </Button>
                </Tooltip>
              </Flex>
            </List.Item>
          );
        }}
      />
    </>
  );
}
