import type { IConcessionairesProfile } from "~components/List";
import type { ElectricUtilityProfileDTO } from "~types/api/ElectricUtilityProfileDTO";
import { createUuid } from "~utils/createUuid";

type dataType = ElectricUtilityProfileDTO & { key?: string };

export function mapperElectricUtilityProfileListItemDtoToProfile(
  data: dataType,
) {
  const key = data.key ?? createUuid();

  const parsedData: IConcessionairesProfile = {
    key,
    id: data.id,
    name: data.name,
    electricUtilityId: data.electricUtilityId,
    voltageType: data.voltageType,
    averageConsumption: data.lowVoltage?.averageConsumption,
    powerPhase: data.lowVoltage?.powerPhase as
      | "Monofásico"
      | "Trifásico"
      | "Bifásico"
      | undefined,
    // tax: data.lowVoltage?.tax,
    te: data.lowVoltage?.te,
    tusd: data.lowVoltage?.tusd,
    wireBTax: data.lowVoltage?.wireBTax,
    averageConsumptionP: data.mediumVoltage?.averageConsumptionP,
    averageConsumptionFP: data.mediumVoltage?.averageConsumptionFP,
    // taxP: data.mediumVoltage?.taxP,
    // taxFP: data.mediumVoltage?.taxFP,
    teP: data.mediumVoltage?.teP,
    teFP: data.mediumVoltage?.teFP,
    tusdP: data.mediumVoltage?.tusdP,
    tusdFP: data.mediumVoltage?.tusdFP,
    wireBTaxP: data.mediumVoltage?.wireBTaxP,
    wireBTaxFP: data.mediumVoltage?.wireBTaxFP,
  };

  return parsedData;
}
