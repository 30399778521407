import { useMemo, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";

import type { IBaseAddressListProps } from "./interface";

import { Menu } from "~components/Menu";
import { Modal } from "~components/Modal";
import { Result } from "~components/Result";
import {
  useFetchDeleteBaseAddress,
  useFetchGetCompanyAddresses,
} from "~hooks/api";
import type { IAddressDTOSchema } from "~types/schemas";
import { List, Col, Flex } from "antd";

export function BaseAddressList(props: IBaseAddressListProps) {
  const [selectedAddressToUpdate, setSelectedAddressToUpdate] =
    useState<IAddressDTOSchema>();
  const [selectedAddressToDelete, setSelectedAddressToDelete] =
    useState<IAddressDTOSchema>();

  const { mutate: deleteBaseAddress, isLoading: isDeletingBaseAddress } =
    useFetchDeleteBaseAddress({
      options: {
        onSuccess: () => setSelectedAddressToDelete(undefined),
      },
    });

  const {
    data: addressList,
    isFetching: isAddressListLoading,
    error: addressListError,
  } = useFetchGetCompanyAddresses({});

  const DATA_SOURCE = useMemo(() => {
    return addressList ?? [];
  }, [addressList]);

  return (
    <>
      <List<IAddressDTOSchema>
        {...props}
        data-cy="base-address-list"
        itemLayout="horizontal"
        dataSource={DATA_SOURCE}
        loading={isAddressListLoading}
        style={{ width: "100%" }}
        rowKey="id"
        renderItem={(item) => {
          const city = item.city?.name ?? "Cidade não informada";
          const street = item.street ?? "Logradouro não informado";
          const residenceNumber = item.street ? `Nº${item.number}` : "S/N";
          const address = `${street} - ${residenceNumber}`;

          return (
            <List.Item>
              <Flex
                align="center"
                justify="space-between"
                style={{ width: "100%", paddingRight: "0.5rem" }}
              >
                <Col flex={4}>
                  <List.Item.Meta title={city} description={address} />
                </Col>
                <Menu.MoreDropdown
                  items={[
                    {
                      icon: <FaEdit size={16} color="var(--gold-500)" />,
                      text: "Alterar",
                      color: "var(--gold-500)",
                      onClick: () => setSelectedAddressToUpdate(item),
                    },

                    {
                      icon: (
                        <AiOutlineDelete size={16} color="var(--red-500)" />
                      ),
                      text: "Remover",
                      color: "var(--red-500)",
                      onClick: () => setSelectedAddressToDelete(item),
                    },
                  ]}
                  position="bottomLeft"
                  icon="cog"
                />
              </Flex>
            </List.Item>
          );
        }}
        locale={{
          emptyText: (
            <Result.TableErrorOrEmpty
              errorMessage={addressListError?.message}
              emptyMessage="Nenhum endereço encontrado."
            />
          ),
        }}
      />

      <Modal.BaseAddressForm
        isOpen={!!selectedAddressToUpdate}
        onClose={() => setSelectedAddressToUpdate(undefined)}
        formProps={{
          initialValues: {
            ...selectedAddressToUpdate,
            city: selectedAddressToUpdate?.city?.name,
            province: selectedAddressToUpdate?.province?.name,
            uf: selectedAddressToUpdate?.province?.uf,
          },
          onSuccess: () => setSelectedAddressToUpdate(undefined),
        }}
      />

      <Modal.DefaultRemove
        isOpen={!!selectedAddressToDelete}
        isLoading={isDeletingBaseAddress}
        itemName={
          selectedAddressToDelete?.city?.name ?? selectedAddressToDelete?.street
        }
        onClose={() => setSelectedAddressToDelete(undefined)}
        suffix="Endereço"
        onSubmit={() =>
          deleteBaseAddress({ baseAddressId: selectedAddressToDelete?.id ?? 0 })
        }
        title="Remover endereço base"
      />
    </>
  );
}
