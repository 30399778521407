import { useMemo, useEffect } from "react";

import {
  type ILogsTableHeaderProps,
  type LogsTableFiltersType,
  LogsTableQueryParamKeysEnum,
  type LogsTableQueryParamsKeysType,
  LogsFormFiltersType,
} from "./interface";

import { Select } from "~components/Select";
import { useAppConfig } from "~hooks/useAppConfig";
import { parseFunctions, useQueryParams } from "~hooks/useQueryParams";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzDateRangePicker } from "~solarzui/SolarzDateRangePicker";
import { SolarzFormItem } from "~solarzui/SolarzFormItem";
import { SolarzInput } from "~solarzui/SolarzInput";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { Card, Divider, Flex, Form } from "antd";
import dayjs from "dayjs";

export function LogsTableHeader(props: ILogsTableHeaderProps) {
  const { screens } = useAppConfig();

  const [form] = Form.useForm<LogsFormFiltersType>();
  const { getParsedQueryParams, handleSaveInQueryParams } = useQueryParams();

  const QUERY_PARAMS_KEYS: LogsTableQueryParamsKeysType = useMemo(
    () => ({
      USER_NAME:
        props.queryParamKeys?.USER_NAME ||
        LogsTableQueryParamKeysEnum.USER_NAME,
      START_DATE:
        props.queryParamKeys?.START_DATE ||
        LogsTableQueryParamKeysEnum.START_DATE,
      END_DATE:
        props.queryParamKeys?.END_DATE || LogsTableQueryParamKeysEnum.END_DATE,
      CURRENT_PAGE:
        props.queryParamKeys?.CURRENT_PAGE ||
        LogsTableQueryParamKeysEnum.CURRENT_PAGE,
      ACTION:
        props.queryParamKeys?.ACTION || LogsTableQueryParamKeysEnum.ACTION,
      ENTITY:
        props.queryParamKeys?.ENTITY || LogsTableQueryParamKeysEnum.ENTITY,
    }),
    [props.queryParamKeys],
  );

  const filters: LogsTableFiltersType = useMemo(
    () => ({
      currentPage:
        getParsedQueryParams(
          QUERY_PARAMS_KEYS.CURRENT_PAGE,
          parseFunctions.NUMBER,
        ) ?? 1,
      startDate:
        getParsedQueryParams(
          QUERY_PARAMS_KEYS.START_DATE,
          parseFunctions.DAYJS,
        ) ?? dayjs().subtract(1, "week"),
      endDate:
        getParsedQueryParams(
          QUERY_PARAMS_KEYS.END_DATE,
          parseFunctions.DAYJS,
        ) ?? dayjs(),
      userName: getParsedQueryParams(
        QUERY_PARAMS_KEYS.USER_NAME,
        parseFunctions.STRING,
      ),
      action: getParsedQueryParams(
        QUERY_PARAMS_KEYS.ACTION,
        parseFunctions.STRING,
      ) as LogsTableFiltersType["action"],
      entity: getParsedQueryParams(
        QUERY_PARAMS_KEYS.ENTITY,
        parseFunctions.STRING,
      ) as LogsTableFiltersType["entity"],
    }),
    [getParsedQueryParams, QUERY_PARAMS_KEYS],
  );

  useEffect(() => {
    if (form && filters) {
      form.setFieldValue(QUERY_PARAMS_KEYS.ENTITY, filters.entity);
      form.setFieldValue(QUERY_PARAMS_KEYS.ACTION, filters.action);
    }
  }, [filters, form, QUERY_PARAMS_KEYS]);

  return (
    <Flex
      justify="flex-end"
      gap={screens.mobile ? 8 : 16}
      style={{
        margin: screens.mobile ? "0" : "0 0 1.5rem 0",
        padding: screens.mobile ? "1rem" : "0",
        ...props.style,
      }}
      vertical={screens.mobile}
    >
      <SolarzInput
        placeholder="Pesquisar por usuário"
        defaultValue={filters.userName}
        onPressEnter={(userName) => {
          handleSaveInQueryParams({
            [QUERY_PARAMS_KEYS.USER_NAME]: userName,
            [QUERY_PARAMS_KEYS.CURRENT_PAGE]: 1,
          });
        }}
        style={{ maxWidth: screens.mobile ? "none" : 292 }}
        scale={screens.mobile ? "medium" : "large"}
        filterRender={
          <Card style={{ width: 245 }} styles={{ body: { padding: "1rem 0" } }}>
            <SolarzTypography
              hierarchy="paragraph2"
              variant="title"
              style={{ padding: "0 1rem 0.75rem 1rem" }}
            >
              Filtros
            </SolarzTypography>

            <Divider style={{ margin: "0 0 12px 0" }} />

            <Form<LogsFormFiltersType>
              form={form}
              onFinish={(values) => {
                handleSaveInQueryParams({
                  [QUERY_PARAMS_KEYS.ENTITY]: values.entity ?? null,
                  [QUERY_PARAMS_KEYS.ACTION]: values.action ?? null,
                  [QUERY_PARAMS_KEYS.CURRENT_PAGE]: 1,
                });
              }}
            >
              <Flex vertical style={{ padding: "0 1rem" }}>
                <SolarzFormItem
                  name={QUERY_PARAMS_KEYS.ENTITY}
                  label="Entidade"
                >
                  <Select.LogEntityType
                    placeholder="Selecione..."
                    scale="medium"
                  />
                </SolarzFormItem>
                <SolarzFormItem
                  name={QUERY_PARAMS_KEYS.ACTION}
                  label="Ação"
                  style={{ marginBottom: 8 }}
                >
                  <Select.LogActionType
                    placeholder="Selecione..."
                    scale="medium"
                  />
                </SolarzFormItem>
              </Flex>

              <Divider style={{ margin: "4px 0 12px 0" }} />

              <Flex
                align="center"
                justify="flex-end"
                gap={8}
                style={{ padding: "0 1rem" }}
              >
                <SolarzButton variant="secondary" scale="tiny" htmlType="reset">
                  Limpar filtros
                </SolarzButton>
                <SolarzButton htmlType="submit" variant="primary" scale="tiny">
                  Aplicar
                </SolarzButton>
              </Flex>
            </Form>
          </Card>
        }
      />
      <SolarzDateRangePicker
        value={[filters.startDate, filters.endDate]}
        onChange={(dates) => {
          handleSaveInQueryParams({
            [QUERY_PARAMS_KEYS.START_DATE]: dates[0],
            [QUERY_PARAMS_KEYS.END_DATE]: dates[1],
            [QUERY_PARAMS_KEYS.CURRENT_PAGE]: 1,
          });
        }}
        disabledDate={(current) => {
          return current && current > dayjs().endOf("day");
        }}
        scale={screens.mobile ? "medium" : "large"}
      />
    </Flex>
  );
}
