import type { IGTSolarIntegrationFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function GTSolarIntegrationFormModal({
  isOpen = false,
  onClose = () => null,
  title = "GTSolar integração",
  maxWidth = 512,
  formProps,
}: IGTSolarIntegrationFormModalProps) {
  return (
    <Modal
      data-cy="gt-solar-integration-form-modal"
      open={isOpen}
      onCancel={onClose}
      title={title}
      width="90vw"
      footer={null}
      style={{ maxWidth }}
      destroyOnClose
    >
      <Form.GTSolarIntegration {...formProps} onCancel={onClose} />
    </Modal>
  );
}
