import { FC } from "react";

import type { KitItemMultipleFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export const KitItemMultipleFormModal: FC<KitItemMultipleFormModalProps> = ({
  isOpen = false,
  onClose = () => null,
  title = "Adicionar itens do kit",
  width = 960,
  formProps,
}) => {
  return (
    <Modal
      data-cy="kit-item-multiple-form-modal"
      open={isOpen}
      onCancel={onClose}
      title={title}
      width={width}
      footer={null}
      destroyOnClose
      style={{ maxWidth: "90vw" }}
    >
      <Form.KitItemMultiple {...formProps} onCancel={onClose} />
    </Modal>
  );
};
