import { type IGeneralInformationCardProps } from "./interface";
import { StyledCard } from "./styles";

import { Form } from "~components/Form";
import { SolarzTypography } from "~solarzui/SolarzTypography";

export function GeneralInformationCard({
  style,
}: IGeneralInformationCardProps) {
  return (
    <StyledCard
      style={{
        ...style,
        border: "none",
      }}
      styles={{
        header: {
          border: "none",
          padding: 0,
        },
        body: {
          padding: 0,
        },
      }}
      title={
        <div className="flex flex-col justify-center items-center gap-4 mb-4">
          <div className="flex justify-start items-start w-full">
            <SolarzTypography
              fontWeight="semiBold"
              hierarchy="title2"
              variant="title"
            >
              Informações gerais
            </SolarzTypography>
          </div>
        </div>
      }
    >
      <div className="flex flex-col justify-center items-center gap-2 h-48">
        <Form.ChangeProfileImage />
      </div>
      <div className="border-t pt-4">
        <Form.GeneralInformation />
      </div>
    </StyledCard>
  );
}
