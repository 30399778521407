import { useMemo, useState } from "react";

import type {
  IPricingTypeSelectProps,
  PricingSelectRecordType,
} from "./interface";

import {
  useFetchGetAllPricingTypes,
  useFetchUpdatePricingTypeInUse,
} from "~hooks/api";
import { useSession } from "~hooks/useSession";
import { SolarzSelect } from "~solarzui/SolarzSelect";

export function PricingTypeSelect({
  onChange,
  onLoad = () => undefined,
  activeTab = undefined,
  onSelectItem,
  ...props
}: IPricingTypeSelectProps) {
  const { user } = useSession();
  const [selectedPricingId, setSelectedPricingId] = useState<
    number | undefined
  >();

  const { data: pricing, isFetching: isLoadingPricing } =
    useFetchGetAllPricingTypes({
      options: {
        onSuccess(data) {
          if (user?.pricingTypeId) {
            const initialPricingType = data.find(
              (item) => item.id === user?.pricingTypeId,
            );

            if (initialPricingType) {
              setSelectedPricingId(initialPricingType.id);
              if (onSelectItem) {
                onSelectItem(initialPricingType);
              }
              return onLoad(initialPricingType.id);
            }
          }

          const defaultPricing = data.find((item) => item.default === true);

          if (defaultPricing) {
            setSelectedPricingId(defaultPricing.id);
            if (onSelectItem) {
              onSelectItem(defaultPricing);
            }
            return onLoad(defaultPricing.id);
          }
        },
      },
    });

  const { mutate: updatePricingType, isLoading: isUpdatingPricingType } =
    useFetchUpdatePricingTypeInUse({ activeTab });

  function handleSelectPricingType(type: number) {
    setSelectedPricingId(type);
    if (type) {
      updatePricingType({ pricingTypeId: type });
    }
  }

  const options = useMemo(() => {
    return pricing?.map((item) => ({
      label: item.name,
      value: item.id,
      record: item,
    }));
  }, [pricing]);

  return (
    <SolarzSelect<PricingSelectRecordType, "id">
      {...props}
      options={options}
      allowClear={false}
      isLoading={isLoadingPricing || isUpdatingPricingType}
      value={selectedPricingId}
      onChange={(value, option) => {
        const parsedValue = value as number;

        handleSelectPricingType(parsedValue);

        if (!onChange) return;

        onChange(parsedValue, option);

        if (onSelectItem && option && !Array.isArray(option)) {
          onSelectItem(option.record);
        }
      }}
    />
  );
}
