import type { IPersonFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function PersonFormModal({
  isOpen = false,
  onClose = () => undefined,
  maxWidth = 820,
  title = "Formulário de pessoa",
  formProps,
}: IPersonFormModalProps) {
  return (
    <Modal
      data-cy="person-form-modal"
      title={title}
      width="90vw"
      style={{
        maxWidth,
      }}
      open={isOpen}
      footer={null}
      onCancel={onClose}
      destroyOnClose
    >
      <Form.Person {...formProps} onCancel={onClose} />
    </Modal>
  );
}
