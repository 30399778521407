import type {
  DeletePipelineFormValuesType,
  IDeletePipelineFormProps,
} from "./interface";
import { LoadingSkeleton, StyledAlert } from "./styles";

import { Alert } from "~components/Alert";
import { Select } from "~components/Select";
import { useFetchDeletePipeline, useFetchGetPipelineById } from "~hooks/api";
import { useSession } from "~hooks/useSession";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzFormItem } from "~solarzui/SolarzFormItem";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { Flex, Form, Radio } from "antd";

export function DeletePipelineForm({
  formInstance,
  initialValues,
  pipelineId,
  onCancel,
  onError,
  onSuccess,
}: IDeletePipelineFormProps) {
  const [form] = Form.useForm<DeletePipelineFormValuesType>(formInstance);

  const needDestinationWatcher = Form.useWatch("needDestination", form);

  const { userHavePermission } = useSession();

  const { mutate: deletePipeline, isLoading: isDeletingPipeline } =
    useFetchDeletePipeline({
      options: {
        onSuccess,
        onError,
      },
    });

  const {
    data: pipeline,
    isFetching: isLoadingPipeline,
    error: pipelineError,
    refetch: refetchPipeline,
  } = useFetchGetPipelineById({
    dependencyArray: [pipelineId],
    options: {
      retry: 1,
      enabled: pipelineId > 0,
    },
    payload: {
      pipelineId,
    },
  });

  function handleSubmit(formValues: DeletePipelineFormValuesType) {
    deletePipeline({
      pipelineId,
      destinationPipelineId: formValues.needDestination
        ? formValues.destinationPipelineId
        : undefined,
    });
  }

  function handleCancel() {
    typeof onCancel === "function" ? onCancel() : form.resetFields();
  }

  const disableSubmitButton =
    isLoadingPipeline || !!pipelineError || !pipelineId;

  const userCanRemoverDeal = userHavePermission("REMOVE_DEAL");

  const countAllDeals = pipeline?.stages?.reduce(
    (total, stage) => stage.deals.length + total,
    0,
  );

  return (
    <Form
      data-cy="delete-pipeline-form"
      form={form}
      initialValues={{
        needDestination: false,
        ...initialValues,
      }}
      onFinish={handleSubmit}
      preserve
      layout="vertical"
    >
      {pipelineError?.message && (
        <Alert.CardError
          errorMessage={pipelineError?.message}
          reloadFn={refetchPipeline}
          title="Falha ao carregar dados do funil"
          style={{ marginBottom: 16 }}
        />
      )}

      {isLoadingPipeline && (
        <LoadingSkeleton
          active
          style={{
            height: 160,
            marginBottom: 12,
            width: "calc(100% - 60px)",
          }}
        >
          {" "}
        </LoadingSkeleton>
      )}

      {!isLoadingPipeline && (
        <StyledAlert
          type="error"
          description={
            <Flex vertical>
              <SolarzTypography variant="danger" fontWeight="medium">
                Você tem certeza que deseja apagar o funil de vendas &ldquo;
                {pipeline?.name}&rdquo;?
              </SolarzTypography>

              <ul>
                <li>
                  <SolarzTypography variant="danger" hierarchy="paragraph2">
                    Todos os negócios {countAllDeals} negócios serão{" "}
                    {needDestinationWatcher ? "movidos" : "apagados"}
                  </SolarzTypography>
                </li>
              </ul>
            </Flex>
          }
        />
      )}

      <SolarzFormItem<DeletePipelineFormValuesType>
        name="needDestination"
        rules={[{ required: true }]}
        style={{ margin: "1rem 0" }}
      >
        <Radio.Group
          options={[
            {
              label: (
                <SolarzTypography
                  style={{
                    pointerEvents: userCanRemoverDeal ? "auto" : "none",
                    opacity: userCanRemoverDeal ? 1 : 0.5,
                  }}
                >
                  Apagar permanentemente todos os negócios do funil
                </SolarzTypography>
              ),
              value: false,
              style: {
                marginBottom: "0.5rem",
              },
              disabled: !userCanRemoverDeal,
            },
            {
              label: (
                <SolarzTypography>
                  Apagar pipeline e mover negócios para outro funil
                </SolarzTypography>
              ),
              value: true,
            },
          ]}
        />
      </SolarzFormItem>

      {needDestinationWatcher === true && (
        <SolarzFormItem<DeletePipelineFormValuesType>
          name="destinationPipelineId"
          rules={[{ required: true, message: "Campo é obrigatório" }]}
          preserve={false}
        >
          <Select.Pipeline
            placeholder="Selecione o funil..."
            allowClear={false}
            hideOptions={[pipelineId]}
          />
        </SolarzFormItem>
      )}

      <Flex
        style={{ marginTop: 12 }}
        align="center"
        gap={16}
        justify="flex-end"
      >
        <SolarzButton variant="secondary" scale="tiny" onClick={handleCancel}>
          Cancelar
        </SolarzButton>
        <SolarzButton
          scale="tiny"
          htmlType="submit"
          isDisabled={disableSubmitButton}
          isLoading={isDeletingPipeline}
          variant="danger"
        >
          {needDestinationWatcher ? "Mover e apagar" : "Apenas apagar"}
        </SolarzButton>
      </Flex>
    </Form>
  );
}
