import { useMemo } from "react";

import type { IStatisticInfoCardProps } from "./interface";

import { StatisticInfo } from "~components/Others";
import { createUuid } from "~utils/createUuid";
import { Card, Flex } from "antd";

export function StatisticInfoCard({
  data = [],
  maxWidth = 320,
  width = "100%",
  height = "auto",
  minHeight = 112,
  gap = 16,
  isMobile = false,
  ...props
}: IStatisticInfoCardProps) {
  const statistics = useMemo(() => {
    const statisticsProps = data.map((statistic) => {
      const uuid = createUuid();
      const { contentItemProps, ...statisticProps } = statistic;

      return {
        uuid,
        contentItemProps: {
          grow: 1,
          shrink: 0,
          align: "center" as const,
          basis: "auto" as const,
          ...contentItemProps,
          style: {
            width: isMobile ? "100%" : `calc(50% - ${gap}px)`,
            ...contentItemProps?.style,
          },
        },
        ...statisticProps,
      };
    });

    return statisticsProps;
  }, [data, gap]);

  return (
    <Card
      data-cy="statistic-info-card"
      {...props}
      style={{
        maxWidth,
        width,
        height,
        minHeight: isMobile ? 212.16 : minHeight,
        ...props.style,
      }}
    >
      <Flex gap={gap} wrap="wrap">
        {statistics.map(({ uuid, contentItemProps, ...statisticInfo }) => (
          <div key={uuid} {...contentItemProps} style={contentItemProps?.style}>
            <StatisticInfo {...statisticInfo} />
          </div>
        ))}
      </Flex>
    </Card>
  );
}
