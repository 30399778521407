import styled from "@emotion/styled";
import { Button } from "antd";

export const StyledButton = styled(Button)`
  line-height: 0;
  color: var(--green-500) !important;
  background-color: var(--green-50) !important;
  border-color: var(--green-400) !important;
  height: 2.125rem;
  min-height: 2.125rem;

  &&:hover {
    color: var(--green-500) !important;
    background-color: var(--green-100) !important;
    border-color: var(--green-400) !important;
  }

  &&:focus {
    color: var(--green-500) !important;
    background-color: var(--green-100) !important;
    border-color: var(--green-400) !important;
  }
`;
