import type { IProfileFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function ProfileFormModal({
  isOpen = false,
  onClose = () => null,
  title = "Gerenciamento de perfil",
  maxWidth = 768,
  formProps = {},
}: IProfileFormModalProps) {
  return (
    <Modal
      data-cy="profile-form-modal"
      open={isOpen}
      onCancel={onClose}
      title={title}
      width="90vw"
      footer={null}
      destroyOnClose
      style={{ maxWidth }}
      styles={{
        body: {
          maxHeight: "calc(100vh - 240px)",
          overflowY: "auto",
        },
      }}
    >
      <Form.Profile onCancel={onClose} {...formProps} />
    </Modal>
  );
}
