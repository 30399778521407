export * from "./ConsumerUnitsButton/interface";
import { AldoIntegrationButton } from "./AldoIntegrationButton";
export * from "./BindPipedriveIdToOrganizationButton/interface";
import { BindPipedriveIdToOrganizationButton } from "./BindPipedriveIdToOrganizationButton";
export * from "./BindPipedriveIdToPersonButton";
import { BindPipedriveIdToPersonButton } from "./BindPipedriveIdToPersonButton";
import { ConsumerUnitsButton } from "./ConsumerUnitsButton";
export * from "./ControlThemeButton/interface";
import { ControlThemeButton } from "./ControlThemeButton";
export * from "./CopyToClipboardButton/interface";
import { CopyToClipboardButton } from "./CopyToClipboardButton";
export * from "./CreateAuxiliaryVariablesButton/interface";
import { CreateAuxiliaryVariablesButton } from "./CreateAuxiliaryVariablesButton";
export * from "./CreateBaseAddressButton/interface";
import { CreateBaseAddressButton } from "./CreateBaseAddressButton";
export * from "./CreateClientButton/interface";
import { CreateClientButton } from "./CreateClientButton";
export * from "./CreateComplementaryItemButton/interface";
import { CreateComplementaryItemButton } from "./CreateComplementaryItemButton";
export * from "./CreateConcessionaireProfileButton/interface";
import { CreateConcessionaireProfileButton } from "./CreateConcessionaireProfileButton";
export * from "./CreateCustomFieldButton/interface";
import { CreateCustomFieldButton } from "./CreateCustomFieldButton";
export * from "./CreateCustomVariableButton/interface";
import { CreateCustomVariableButton } from "./CreateCustomVariableButton";
export * from "./CreatePerformanceProfileButton/interface";
import { CreatePerformanceProfileButton } from "./CreatePerformanceProfileButton";
export * from "./CreatePersonCustomFieldButton/interface";
import { CreatePersonCustomFieldButton } from "./CreatePersonCustomFieldButton";
export * from "./CreatePricingButton/interface";
import { CreatePricingButton } from "./CreatePricingButton";
export * from "./CreateProductTypeOtherButton/interface";
import { CreateProductTypeOtherButton } from "./CreateProductTypeOtherButton";
export * from "./CreateProfileButton/interface";
import { CreateProfileButton } from "./CreateProfileButton";
export * from "./CreateProposalButton/interface";
import { CreateProposalButton } from "./CreateProposalButton";
export * from "./CreateProposalExpirationRuleButton/interface";
import { CreateProposalExpirationRuleButton } from "./CreateProposalExpirationRuleButton";
export * from "./CreateSimulationButton/interface";
import { CreateSimulationButton } from "./CreateSimulationButton";
export * from "./CreateSimultaneityProfileButton/interface";
import { CreateSimultaneityProfileButton } from "./CreateSimultaneityProfileButton";
export * from "./CreateSizingKitButton/interface";
import { CreateSizingKitButton } from "./CreateSizingKitButton";
export * from "./CreateSysAdminUserButton/interface";
import { CreateSysAdminUserButton } from "./CreateSysAdminUserButton";
export * from "./CreateTransformerGroupButton/interface";
import { CreateTransformerGroupButton } from "./CreateTransformerGroupButton";
export * from "./CreateUserButton/interface";
import { CreateUserButton } from "./CreateUserButton";
export * from "./CreateUserGroupButton/interface";
import { CreateUserGroupButton } from "./CreateUserGroupButton";
export * from "./CreateValidationRuleButton/interface";
import { CreateValidationRuleButton } from "./CreateValidationRuleButton";
export * from "./DeleteIntegrationApiButton/interface";
import { DeleteIntegrationApiButton } from "./DeleteIntegrationApiButton";
export * from "./DistributorIntegrationButton/interface";
import { DistributorIntegrationButton } from "./DistributorIntegrationButton";
export * from "./DownloadPdfButton/interface";
import { DownloadPdfButton } from "./DownloadPdfButton";
export * from "./EdeltecIntegrationButton/interface";
import { EdeltecIntegrationButton } from "./EdeltecIntegrationButton";
export * from "./GoogleMapSatelliteButton/interface";
import { GoogleMapSatelliteButton } from "./GoogleMapSatelliteButton";
export * from "./GTSolarIntegrationButton/interface";
import { GTSolarIntegrationButton } from "./GTSolarIntegrationButton";
export * from "./ImportUsersListModalButton/interface";
import { ImportUsersListModalButton } from "./ImportUsersListModalButton";
export * from "./KomecoIntegrationButton/interface";
import { KomecoIntegrationButton } from "./KomecoIntegrationButton";
export * from "./LoggedUserDrawerButton/interface";
import { LoggedUserDrawerButton } from "./LoggedUserDrawerButton";
export * from "./LogoutButton/interface";
import { LogoutButton } from "./LogoutButton";
export * from "./NotificationCenterDrawerButton/interface";
import { NotificationCenterDrawerButton } from "./NotificationCenterDrawerButton";
export * from "./PipedriveLoginButton/interface";
import { PipedriveLoginButton } from "./PipedriveLoginButton";
export * from "./ProposalDownloadPdfButton/interface";
import { ProposalDownloadPdfButton } from "./ProposalDownloadPdfButton";
export * from "./ReleaseNoteInfoButton/interface";
import { ReleaseNoteInfoButton } from "./ReleaseNoteInfoButton";
export * from "./SearchGoogleAddressModalButton/interface";
import { SearchGoogleAddressModalButton } from "./SearchGoogleAddressModalButton";
export * from "./SetProposalStatusButton/interface";
import { SetProposalStatusButton } from "./SetProposalStatusButton";
export * from "./UpdatePipedriveTokenApiButton/interface";
import { UpdatePipedriveTokenApiButton } from "./UpdatePipedriveTokenApiButton";
export * from "./VariableActionMenuButton/interface";
import { VariableActionMenuButton } from "./VariableActionMenuButton";
export * from "./WegIntegrationButton/interface";
import { WegIntegrationButton } from "./WegIntegrationButton";

export const Button = {
  AldoIntegration: AldoIntegrationButton,
  BindPipedriveIdToOrganization: BindPipedriveIdToOrganizationButton,
  BindPipedriveIdToPerson: BindPipedriveIdToPersonButton,
  ConsumerUnits: ConsumerUnitsButton,
  ControlTheme: ControlThemeButton,
  CopyToClipboard: CopyToClipboardButton,
  CreateBaseAddress: CreateBaseAddressButton,
  CreateClient: CreateClientButton,
  CreateComplementaryItem: CreateComplementaryItemButton,
  CreateConcessionaireProfile: CreateConcessionaireProfileButton,
  CreateCustomField: CreateCustomFieldButton,
  CreateCustomVariable: CreateCustomVariableButton,
  CreatePerformanceProfile: CreatePerformanceProfileButton,
  CreatePersonCustomField: CreatePersonCustomFieldButton,
  CreatePricing: CreatePricingButton,
  CreateSysAdminUser: CreateSysAdminUserButton,
  // CreateActivity: CreateActivityButton,
  CreateAuxiliaryVariables: CreateAuxiliaryVariablesButton,
  CreateProductTypeOther: CreateProductTypeOtherButton,
  CreateProfile: CreateProfileButton,
  CreateProposal: CreateProposalButton,
  CreateProposalExpirationRule: CreateProposalExpirationRuleButton,
  CreateSimulation: CreateSimulationButton,
  CreateSimultaneityProfile: CreateSimultaneityProfileButton,
  CreateSizingKit: CreateSizingKitButton,
  CreateTransformerGroup: CreateTransformerGroupButton,
  CreateUser: CreateUserButton,
  CreateUserGroup: CreateUserGroupButton,
  CreateValidationRule: CreateValidationRuleButton,
  DeleteIntegrationApi: DeleteIntegrationApiButton,
  DistributorIntegration: DistributorIntegrationButton,
  DownloadPdf: DownloadPdfButton,
  EdeltecIntegration: EdeltecIntegrationButton,
  GoogleMapSatellite: GoogleMapSatelliteButton,
  GTSolarIntegration: GTSolarIntegrationButton,
  ImportUsersListModal: ImportUsersListModalButton,
  KomecoIntegration: KomecoIntegrationButton,
  LoggedUserDrawer: LoggedUserDrawerButton,
  Logout: LogoutButton,
  NotificationCenterDrawer: NotificationCenterDrawerButton,
  PipedriveLogin: PipedriveLoginButton,
  ProposalDownloadPdf: ProposalDownloadPdfButton,
  ReleaseNoteInfo: ReleaseNoteInfoButton,
  SearchGoogleAddressModal: SearchGoogleAddressModalButton,
  SetProposalStatus: SetProposalStatusButton,
  UpdatePipedriveTokenApi: UpdatePipedriveTokenApiButton,
  VariableActionMenu: VariableActionMenuButton,
  WegIntegration: WegIntegrationButton,
};
