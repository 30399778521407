import { useMutation } from "react-query";

import { useFetchGetPersonDefaultFieldsSettingsKey } from "../useFetchGetPersonDefaultFieldsSettings";
import type { IUseFetchPatchPersonDefaultFieldsSettings } from "./interface";

import { fetchPatchPersonDefaultFieldsSettings } from "~api/config/persons/default-fields/settings/{id}/patch";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchPatchPersonDefaultFieldsSettingsKey =
  "api/config/persons/default-fields/settings/{id}/patch";

export function useFetchPatchPersonDefaultFieldsSettings({
  options,
}: IUseFetchPatchPersonDefaultFieldsSettings) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      return await fetchPatchPersonDefaultFieldsSettings(payload);
    },
    {
      ...options,
      mutationKey: useFetchPatchPersonDefaultFieldsSettingsKey,
      onSuccess: (data, variables, context) => {
        message.success("Configurações do campo foram alteradas");
        queryClient.invalidateQueries(
          useFetchGetPersonDefaultFieldsSettingsKey,
        );
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        queryClient.invalidateQueries(
          useFetchGetPersonDefaultFieldsSettingsKey,
        );
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
