import { useMemo } from "react";

import type { IKomecoEquipmentModuleSelectProps } from "./interface";

import { AutoCompleteSuffixIcon } from "~components/Others";
import { Result } from "~components/Result";
import { useFetchGetAllKomecoEquipmentModules } from "~hooks/api";
import { Select } from "antd";

export function KomecoEquipmentModuleSelect({
  allowClear = true,
  onChange,
  ...props
}: IKomecoEquipmentModuleSelectProps) {
  const {
    data: modules = [],
    isFetching,
    error,
    refetch,
  } = useFetchGetAllKomecoEquipmentModules({
    options: {
      retry: 1,
    },
  });

  const OPTIONS = useMemo(
    () =>
      modules.map((item) => ({
        label: item.description ?? "Desconhecida",
        value: item.id,
        record: item,
      })),
    [modules],
  );

  return (
    <Select
      {...props}
      data-cy="komeco-equipment-module-select"
      options={OPTIONS}
      allowClear={allowClear}
      status={error ? "error" : ""}
      loading={isFetching}
      notFoundContent={
        <Result.SelectErrorOrEmpty
          errorMessage={error?.message}
          emptyMessage="Nenhum módulo encontrado"
          loading={isFetching}
          reloadFn={refetch}
        />
      }
      onChange={(value, option) => {
        if (option instanceof Array || typeof onChange === "undefined") return;
        onChange(value, option);
      }}
      suffixIcon={
        <AutoCompleteSuffixIcon hasError={!!error} isLoading={isFetching} />
      }
      style={{ width: "100%" }}
    />
  );
}
