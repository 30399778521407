import type { INotificationCenterTypeRadioGroupProps } from "./interface";
import { RadioButton, RadioGroup } from "./styles";

export function NotificationCenterTypeRadioGroup({
  ...props
}: INotificationCenterTypeRadioGroupProps) {
  return (
    <RadioGroup {...props} defaultValue={"ALL"}>
      <RadioButton key="ALL" value="ALL">
        Todas
      </RadioButton>
      <RadioButton key="READ" value="READ">
        Lidas
      </RadioButton>
      <RadioButton key="UNREAD" value="UNREAD">
        Não lidas
      </RadioButton>
    </RadioGroup>
  );
}
