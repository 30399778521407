import { useState } from "react";
import { CiUser } from "react-icons/ci";

import type {
  PersonToLinkPipedriveSelectRecordType,
  IPersonToLinkPipedriveSelectProps,
} from "./interface";

import { useFetchGetPipedriveUnlinkedAutoCompletePerson } from "~hooks/api";
import { SolarzSelect } from "~solarzui/SolarzSelect";

export function PersonToLinkPipedriveSelect({
  searchValue: _searchValue,
  ...props
}: IPersonToLinkPipedriveSelectProps) {
  const [searchValue, setSearchValue] = useState("");
  const [selectedOption, setSelectedOption] = useState<string | null>();

  const canMakeRequest = searchValue.length >= 3;

  const {
    data = [],
    isFetching,
    error,
    refetch,
  } = useFetchGetPipedriveUnlinkedAutoCompletePerson({
    dependencyArray: [searchValue],
    payload: {
      term: searchValue,
    },
    options: {
      enabled: canMakeRequest,
    },
  });

  return (
    <>
      <SolarzSelect<PersonToLinkPipedriveSelectRecordType, "uniqueKey">
        {...props}
        searchValue={_searchValue ?? searchValue}
        value={selectedOption}
        onChangeSearch={(value) => {
          setSearchValue(value);
          if (typeof props.onChangeSearch === "function") {
            props.onChangeSearch(value);
          }
        }}
        onChange={(value, option) => {
          if (value === null) {
            setSearchValue("");
            setSelectedOption(null);
          }
          if (typeof value === "string") {
            setSelectedOption(value);
          }

          if (typeof props.onChange === "function") {
            props.onChange(value, option);
          }
        }}
        options={data.map((option) => {
          const icon = <CiUser size={18} strokeWidth={0.75} />;
          const uniqueKey = `${option.id}`;

          return {
            label: (
              <div className="flex items-center gap-2">
                {icon}
                <span>
                  {option.id} - {option.name}
                </span>
              </div>
            ),
            record: {
              ...option,
              uniqueKey,
            },
            value: uniqueKey,
          };
        })}
        isLoading={isFetching}
        refetchFn={canMakeRequest ? refetch : undefined}
        errorMessage={error?.message}
        emptyMessage={
          canMakeRequest
            ? "Nenhum cliente encontrado"
            : "Digite ao menos 3 letras para buscar"
        }
        showSearch
        autoClearSearchValue={false}
      />
    </>
  );
}
