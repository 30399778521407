import * as React from "react";

import type { IIconProps } from "../@types/globals";
import { cn } from "../lib/utils";

const Import: React.FC<IIconProps> = ({ className, ...props }) => (
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    stroke="currentColor"
    className={cn("pointer-events-none w-6 h-6", className)}
    {...props}
  >
    <title>Importar</title>
    <path d="M12.75 3C12.75 2.58579 12.4142 2.25 12 2.25C11.5858 2.25 11.25 2.58579 11.25 3H12.75ZM12 16L11.4465 16.5061C11.5886 16.6615 11.7894 16.75 12 16.75C12.2106 16.75 12.4114 16.6615 12.5535 16.5061L12 16ZM16.5535 12.1311C16.833 11.8254 16.8118 11.351 16.5061 11.0715C16.2004 10.792 15.726 10.8132 15.4465 11.1189L16.5535 12.1311ZM8.55352 11.1189C8.27402 10.8132 7.79962 10.792 7.49392 11.0715C7.18822 11.351 7.16698 11.8254 7.44648 12.1311L8.55352 11.1189ZM15 20.25H9V21.75H15V20.25ZM9 20.25C7.56458 20.25 6.56347 20.2484 5.80812 20.1469C5.07434 20.0482 4.68577 19.8678 4.40901 19.591L3.34835 20.6517C3.95027 21.2536 4.70814 21.5125 5.60825 21.6335C6.48678 21.7516 7.60699 21.75 9 21.75V20.25ZM2.25 15C2.25 16.393 2.24841 17.5132 2.36652 18.3918C2.48754 19.2919 2.74643 20.0497 3.34835 20.6517L4.40901 19.591C4.13225 19.3142 3.9518 18.9257 3.85315 18.1919C3.75159 17.4365 3.75 16.4354 3.75 15H2.25ZM20.25 15C20.25 16.4354 20.2484 17.4365 20.1469 18.1919C20.0482 18.9257 19.8678 19.3142 19.591 19.591L20.6517 20.6517C21.2536 20.0497 21.5125 19.2919 21.6335 18.3918C21.7516 17.5132 21.75 16.393 21.75 15H20.25ZM15 21.75C16.393 21.75 17.5132 21.7516 18.3918 21.6335C19.2919 21.5125 20.0497 21.2536 20.6517 20.6517L19.591 19.591C19.3142 19.8678 18.9257 20.0482 18.1919 20.1469C17.4365 20.2484 16.4354 20.25 15 20.25V21.75ZM11.25 3V16H12.75V3H11.25ZM12.5535 16.5061L16.5535 12.1311L15.4465 11.1189L11.4465 15.4939L12.5535 16.5061ZM12.5535 15.4939L8.55352 11.1189L7.44648 12.1311L11.4465 16.5061L12.5535 15.4939Z" />
  </svg>
);

Import.displayName = "Import";

export { Import };
