import { useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";

import type { IPipelineDetailedSelectProps } from "./interface";
import { InputWrapper, StyledSolarzAnchor } from "./styles";

import { Add } from "~estrela-ui/icons";
import { useFetchGetAllPipelinesDetailed } from "~hooks/api";
import { useSession } from "~hooks/useSession";
import { SolarzAnchor } from "~solarzui/SolarzAnchor";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzSelect } from "~solarzui/SolarzSelect";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { IDetailedPipelineDTOSchema } from "~types/schemas";
import { parseCurrency } from "~utils/parse";
import { Divider, Flex } from "antd";

export function PipelineDetailedSelect({
  onLoad,
  value,
  onChange,
  ...props
}: IPipelineDetailedSelectProps) {
  const [pipelineId, setPipelineId] = useState<number>();

  const { userHavePermission } = useSession();

  const {
    data: pipelinesDetailed = [],
    isFetching: isLoadingPipelinesDetailed,
    error: pipelinesDetailedError,
    refetch: refetchPipelinesDetailed,
  } = useFetchGetAllPipelinesDetailed({
    options: {
      onSuccess: onLoad,
    },
  });

  const userCanUpdatePipeline = userHavePermission("CHANGE_PIPELINE");
  const userCanCreatePipeline = userHavePermission("CREATE_PIPELINE");

  return (
    <InputWrapper style={{ position: "relative" }}>
      <SolarzSelect<IDetailedPipelineDTOSchema, "id">
        {...props}
        value={value ?? pipelineId}
        onChange={(value, record) => {
          if (Array.isArray(value) || Array.isArray(record)) return;

          const pipelineId = value ?? undefined;

          setPipelineId(pipelineId);

          if (typeof onChange === "function") {
            onChange(pipelineId ?? null, record);
          }
        }}
        isLoading={isLoadingPipelinesDetailed}
        options={pipelinesDetailed.map((pipeline) => {
          const formattedValue = parseCurrency(pipeline.dealsTotalValue);

          return {
            label: (
              <Flex vertical gap={4}>
                <SolarzTypography
                  variant="subTitle"
                  fontWeight="bold"
                  hierarchy="paragraph1"
                >
                  {pipeline.name}
                </SolarzTypography>
                <SolarzTypography variant="default" hierarchy="small">
                  {formattedValue} • {pipeline.dealsQuantity} negócios
                </SolarzTypography>
              </Flex>
            ),
            record: pipeline,
            value: pipeline.id,
          };
        })}
        errorMessage={pipelinesDetailedError?.message}
        emptyMessage="Nenhum funil encontrado"
        refetchFn={refetchPipelinesDetailed}
        style={{
          height: 74,
          width: "100%",
          ...props.style,
        }}
        dropdownRender={(menu) => {
          return (
            <Flex vertical>
              {menu}
              <Flex vertical style={{ margin: "8px 16px 0 16px" }}>
                <Divider style={{ margin: 0 }} />
                <StyledSolarzAnchor
                  isNextLink
                  href="/funil/criar"
                  style={{ margin: "8px 0 4px 0" }}
                  isDisabled={!userCanCreatePipeline}
                >
                  <Add />
                  Novo funil
                </StyledSolarzAnchor>
              </Flex>
            </Flex>
          );
        }}
        labelRender={(props) => {
          const { label } = props;

          if (label) {
            return (
              <Flex
                align="start"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                gap={12}
              >
                <div style={{ marginTop: "-2px", marginLeft: 40 }}>{label}</div>
              </Flex>
            );
          }
        }}
      />

      <SolarzAnchor
        href={
          value || pipelineId ? `/funil/editar/${value || pipelineId}` : "#"
        }
        isDisabled={!value && !pipelineId && userCanUpdatePipeline}
        isNextLink
        style={{
          position: "absolute",
          top: 8,
          left: 8,
          height: 0,
          width: 0,
          outline: "none !important",
        }}
        aria-label="-1"
      >
        <SolarzButton
          scale="tiny"
          variant="secondary"
          style={{ height: 28, width: 28, padding: 0 }}
          icon={<AiOutlineEdit size={16} />}
          isDisabled={!userCanUpdatePipeline}
        />
      </SolarzAnchor>
    </InputWrapper>
  );
}
