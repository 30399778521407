import type {
  IChangePasswordFormProps,
  IChangePasswordFormValues,
} from "./interface";

import { CustomInput } from "~components/CustomInput";
import { useFetchChangePassword } from "~hooks/api";
import { checkIfPasswordIsValid } from "~utils/checkIfPasswordIsValid";
import { Button, Form, Flex } from "antd";

function passwordValidate(value: string) {
  return checkIfPasswordIsValid(value)
    ? Promise.resolve()
    : Promise.reject("Senha é inválida!");
}

export function ChangePasswordForm({
  initialValues,
  onCancel = () => undefined,
  onError = () => undefined,
  onSuccess = () => undefined,
}: IChangePasswordFormProps) {
  const { mutate: changePassword, isLoading: isChangingPassword } =
    useFetchChangePassword({
      options: {
        onSuccess,
        onError,
      },
    });

  function handleSubmit(formValues: IChangePasswordFormValues) {
    changePassword(formValues);
  }

  return (
    <Form
      data-cy="change-password-form"
      onFinish={handleSubmit}
      initialValues={initialValues}
      layout="vertical"
      style={{ width: "100%" }}
    >
      <Form.Item
        name="currentPassword"
        label="Senha atual"
        rules={[{ required: true, message: "Senha atual é obrigatória" }]}
      >
        <CustomInput.Text type="text" />
      </Form.Item>

      <Form.Item
        name="password"
        label="Nova senha"
        rules={[{ validator: (_, value) => passwordValidate(value) }]}
        tooltip="Regra: Senha deve ter ao menos seis caracteres, uma letra maiúscula e um caractere especial"
      >
        <CustomInput.Text type="text" />
      </Form.Item>

      <Flex align="center" gap={8} justify="end">
        <Button onClick={onCancel}>Cancelar</Button>
        <Button type="primary" htmlType="submit" loading={isChangingPassword}>
          Salvar
        </Button>
      </Flex>
    </Form>
  );
}
