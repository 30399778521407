export * from "./BusinessTimelineCard/interface";
import { BusinessTimelineCard } from "./BusinessTimelineCard";
export * from "./ClientDataCard/interface";
import { ClientDataCard } from "./ClientDataCard";
export * from "./DealCard/interface";
import { DealCard } from "./DealCard";
export * from "./DealCustomFieldsCard/interface";
import { DealCustomFieldsCard } from "./DealCustomFieldsCard";
export * from "./DealOwnerDetailsCard/interface";
import { DealOwnerDetailsCard } from "./DealOwnerDetailsCard";
export * from "./DealTimelineCard/interface";
import { DealTimelineCard } from "./DealTimelineCard";
export * from "./DemandWarningCard/interface";
export * from "./DeleteOrganizationCard/interface";
import { DeleteOrganizationCard } from "./DeleteOrganizationCard";
export * from "./DeletePersonCard/interface";
import { DeletePersonCard } from "./DeletePersonCard";
import { DemandWarningCard } from "./DemandWarningCard";
export * from "./DistributorIntegrationCard/interface";
import { DistributorIntegrationCard } from "./DistributorIntegrationCard";
export * from "./FinancialCard/interface";
import { FinancialCard } from "./FinancialCard";
export * from "./GeneralInformationCard/interface";
import { GeneralInformationCard } from "./GeneralInformationCard";
export * from "./GenerateTokenCard/interface";
import { GenerateTokenCard } from "./GenerateTokenCard";
export * from "./InfoTagCard/interface";
import { InfoTagCard } from "./InfoTagCard";
export * from "./Integration/interface";
import { IntegrationCard } from "./Integration";
export * from "./NotesTimelineCard/interface";
import { NotesTimelineCard } from "./NotesTimelineCard";
export * from "./NotificationCard/interface";
import { NotificationCard } from "./NotificationCard";
export * from "./OrganizationCard/interface";
import { OrganizationCard } from "./OrganizationCard";
export * from "./OrganizationDealsResumeInfoCard/interface";
import { OrganizationDealsResumeInfoCard } from "./OrganizationDealsResumeInfoCard";
export * from "./OrganizationPersonsCard/interface";
import { OrganizationPersonsCard } from "./OrganizationPersonsCard";
export * from "./OrganizationSummaryCard/interface";
import { OrganizationSummaryCard } from "./OrganizationSummaryCard";
export * from "./OrganizationTimelineCard/interface";
import { OrganizationTimelineCard } from "./OrganizationTimelineCard";
export * from "./PersonActivityStatisticsCard/interface";
import { PersonActivityStatisticsCard } from "./PersonActivityStatisticsCard";
export * from "./PersonCard/interface";
import { PersonCard } from "./PersonCard";
export * from "./PersonDealsResumeInfoCard/interface";
import { PersonDealsResumeInfoCard } from "./PersonDealsResumeInfoCard";
export * from "./PersonSummaryCard/interface";
import { PersonSummaryCard } from "./PersonSummaryCard";
export * from "./PersonTimelineCard/interface";
import { PersonTimelineCard } from "./PersonTimelineCard";
export * from "./ProposalCard/interface";
import { ProposalCard } from "./ProposalCard";
export * from "./ProposalDealCard/interface";
import { ProposalDealCard } from "./ProposalDealCard";
export * from "./ProposalItemCard/interface";
import { ProposalItemCard } from "./ProposalItemCard";
export * from "./ServicesDealCard/interface";
import { ServicesDealCard } from "./ServicesDealCard";
export * from "./SolarzMonitoringIframeCard/interface";
import { SolarzMonitoringIframeCard } from "./SolarzMonitoringIframeCard";
export * from "./StatisticInfoCard/interface";
import { StatisticInfoCard } from "./StatisticInfoCard";
export * from "./StructureMaterialCostRuleCard";
import { StructureMaterialCostRuleCard } from "./StructureMaterialCostRuleCard";
export * from "./UserInfoCard/interface";
import { UserInfoCard } from "./UserInfoCard";

export const Card = {
  PersonActivityStatistics: PersonActivityStatisticsCard,
  BusinessTimeline: BusinessTimelineCard,
  ClientData: ClientDataCard,
  Deal: DealCard,
  DealCustomFields: DealCustomFieldsCard,
  DealTimeline: DealTimelineCard,
  DealOwnerDetails: DealOwnerDetailsCard,
  DeleteOrganization: DeleteOrganizationCard,
  DeletePerson: DeletePersonCard,
  DistributorIntegration: DistributorIntegrationCard,
  Financial: FinancialCard,
  GeneralInformation: GeneralInformationCard,
  GenerateToken: GenerateTokenCard,
  InfoTag: InfoTagCard,
  Integration: IntegrationCard,
  NotesTimeline: NotesTimelineCard,
  Notification: NotificationCard,
  Organization: OrganizationCard,
  OrganizationTimeline: OrganizationTimelineCard,
  OrganizationDealsResumeInfo: OrganizationDealsResumeInfoCard,
  OrganizationSummary: OrganizationSummaryCard,
  Person: PersonCard,
  PersonTimeline: PersonTimelineCard,
  PersonDealsResumeInfo: PersonDealsResumeInfoCard,
  PersonSummary: PersonSummaryCard,
  OrganizationPersons: OrganizationPersonsCard,
  Proposal: ProposalCard,
  ProposalDealCard: ProposalDealCard,
  ProposalItem: ProposalItemCard,
  ServicesDeal: ServicesDealCard,
  SolarzMonitoringIframe: SolarzMonitoringIframeCard,
  StatisticInfo: StatisticInfoCard,
  StructureMaterialCostRule: StructureMaterialCostRuleCard,
  UserInfo: UserInfoCard,
  DemandWarning: DemandWarningCard,
};
