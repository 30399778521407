import { useState } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";

import {
  IConcessionairesProfilesListProps,
  IConcessionairesProfile,
} from "./interface";

import { Modal } from "~components/Modal";
import { createPaginationTableObject } from "~components/Pagination";
import { useFetchDeleteConcessionaireProfile } from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { Button, Empty, Flex, List, Result, Tooltip } from "antd";
export function ConcessionaireProfilesList({
  profiles = [],
  error,
  isLoading,
  page,
  setCurrentPage,
}: IConcessionairesProfilesListProps) {
  const { screens, message } = useAppConfig();

  const [selectedProfileToUpdate, setSelectedProfileToUpdate] =
    useState<IConcessionairesProfile>();

  const [_selectedProfileToDelete, _setSelectedProfileToDelete] =
    useState<IConcessionairesProfile>();

  function onSelectToDelete(profile?: IConcessionairesProfile) {
    _setSelectedProfileToDelete(profile);
  }

  const {
    mutate: deleteConcessionaireProfile,
    isLoading: isDeletingConcessionaireProfile,
  } = useFetchDeleteConcessionaireProfile({
    options: {
      onSuccess: () => _setSelectedProfileToDelete(undefined),
    },
  });

  function handleDeleteSubmit() {
    const profileId = _selectedProfileToDelete?.id;

    if (!profileId) {
      message.error("Client error: Faltando o ID do perfil");
      return;
    }

    deleteConcessionaireProfile({ concessionaireProfileId: profileId });
  }

  return (
    <>
      <List
        data-cy="concessionaire-profiles-list"
        itemLayout="horizontal"
        dataSource={profiles}
        pagination={createPaginationTableObject({
          onChange: (page, _) => {
            setCurrentPage(page - 1);
          },
          size: page?.size ?? 10,
          totalElements: page?.totalElements,
          number: page?.number ? page.number + 1 : 1,
          itemType: "perfis",
          displayTotalInformation: screens.sm,
        })}
        renderItem={(profile) => (
          <List.Item key={profile.id}>
            <Flex
              align="center"
              justify="space-between"
              style={{
                width: "100%",
                paddingRight: "0.5rem",
                display: screens.xs ? "block" : "flex",
                flexDirection: screens.xs ? "column" : "row",
              }}
            >
              <List.Item.Meta
                title="Nome do perfil"
                description={profile.name}
              />
              <List.Item.Meta
                title="Tipo de voltagem"
                description={profile.voltageType === "LOW" ? "Baixa" : "Média"}
              />

              <Flex
                align="center"
                gap={16}
                style={{
                  marginRight: "1rem",
                  marginBottom: screens.xs ? "16px" : "0",
                }}
              >
                <Tooltip title="Alterar perfil">
                  <Button
                    type="link"
                    size="small"
                    shape="circle"
                    onClick={() => setSelectedProfileToUpdate(profile)}
                  >
                    <Flex align="center" gap={4}>
                      <AiOutlineEdit
                        fontSize={20}
                        style={{ color: "var(--gold-500)" }}
                      />
                    </Flex>
                  </Button>
                </Tooltip>
                <Tooltip title="Remover perfil">
                  <Button
                    type="link"
                    size="small"
                    shape="circle"
                    onClick={() => onSelectToDelete(profile)}
                  >
                    <Flex align="center" gap={4}>
                      <AiOutlineDelete
                        fontSize={20}
                        style={{ color: "var(--volcano-500)" }}
                      />
                    </Flex>
                  </Button>
                </Tooltip>
              </Flex>
            </Flex>
          </List.Item>
        )}
        locale={{
          emptyText: error ? (
            <Result
              title="Falha ao carregar perfis da concessionárias"
              subTitle={error}
              status="error"
            />
          ) : (
            <Empty description="Nenhum perfil cadastrado ainda!" />
          ),
        }}
        loading={isLoading}
      />
      <Modal.DefaultRemove
        isOpen={!!_selectedProfileToDelete}
        isLoading={isDeletingConcessionaireProfile}
        itemName={_selectedProfileToDelete?.name}
        onClose={() => onSelectToDelete(undefined)}
        suffix="perfil da concessionária"
        title="Remover perfil"
        onSubmit={handleDeleteSubmit}
      />
      <Modal.ConcessionairesProfileForm
        isOpen={!!selectedProfileToUpdate}
        onClose={() => setSelectedProfileToUpdate(undefined)}
        formProps={{
          initialValues: selectedProfileToUpdate,
          onSuccess: () => setSelectedProfileToUpdate(undefined),
        }}
      />
    </>
  );
}
