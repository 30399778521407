import { useMemo, useState } from "react";

import type {
  UserSelectOptionType,
  UserSelectRecordType,
  IUserSelectProps,
} from "./interface";

import { Avatar } from "~components/Avatar";
import { useFetchGetAllUsersList } from "~hooks/api";
import { SolarzSelect } from "~solarzui/SolarzSelect";
import { parseRemoveAccent } from "~utils/parse";

export function UserSelect({
  showSearch,
  extraOptions,
  renderWithAvatar = false,
  ...props
}: IUserSelectProps) {
  const [searchValue, setSearchValue] = useState("");

  const {
    data: users = [],
    isFetching: isLoadingUsers,
    error: usersError,
    refetch: refetchUsers,
  } = useFetchGetAllUsersList({});

  const OPTIONS = useMemo(() => {
    const parsedOptions = users.map((owner) => ({
      key: owner.id,
      value: owner.id,
      label: renderWithAvatar ? (
        <div className="flex flex-row gap-2 flex-nowrap items-center">
          <Avatar.CustomUser
            avatarUrl={owner.avatarUrl}
            name={owner.name || owner.email}
            height={32}
            width={32}
          />
          <p>{owner.name || owner.email || "Sem nome/email"}</p>
        </div>
      ) : (
        `${owner.name || owner.email || "Sem nome/email"}`
      ),
      record: owner,
      hidden: !!props.hideOptions?.includes(owner.id),
    })) as NonNullable<UserSelectOptionType>;

    return extraOptions ? [...extraOptions, ...parsedOptions] : parsedOptions;
  }, [users, extraOptions, renderWithAvatar, props.hideOptions]);

  const FILTERED_OPTIONS = searchValue
    ? OPTIONS.filter((option) => {
        const formattedRecordName = parseRemoveAccent(
          option.record.name.toLowerCase(),
        );

        const formattedSearchValue = parseRemoveAccent(
          searchValue.toLowerCase(),
        );

        return formattedRecordName.includes(formattedSearchValue);
      })
    : OPTIONS;

  return (
    <SolarzSelect<UserSelectRecordType, "id">
      {...props}
      options={FILTERED_OPTIONS}
      data-cy="user-select"
      isLoading={isLoadingUsers}
      refetchFn={refetchUsers}
      errorMessage={usersError?.message}
      emptyMessage="Nenhum usuário encontrado"
      searchValue={showSearch ? searchValue : undefined}
      onChangeSearch={
        showSearch
          ? (value) => {
              setSearchValue(value);
            }
          : undefined
      }
      showSearch={showSearch}
    />
  );
}
