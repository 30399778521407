import { FiAlertOctagon } from "react-icons/fi";

import { IDefaultRemoveModal } from "./interface";

import { Alert, Flex, Modal, Typography } from "antd";

export function DefaultRemoveModal({
  isOpen = false,
  isLoading = false,
  onClose = () => null,
  onSubmit = () => undefined,
  itemName = "",
  suffix = "item",
  title = "Remover item",
  alertMessage = "",
  maxWidth = 420,
}: IDefaultRemoveModal) {
  return (
    <Modal
      data-cy="default-remove-modal"
      open={isOpen}
      onCancel={onClose}
      onOk={onSubmit}
      closable={false}
      confirmLoading={isLoading}
      width="90vw"
      destroyOnClose
      style={{ maxWidth }}
    >
      <Flex vertical gap={16} style={{ padding: "1rem" }}>
        {alertMessage && (
          <Alert
            style={{ width: "100%" }}
            message={alertMessage}
            type="info"
            showIcon
          />
        )}

        <Flex align="start" gap={12} wrap="nowrap">
          <FiAlertOctagon color="var(--red-400)" size={28} />

          <Flex vertical>
            <Typography.Title level={4} style={{ color: "var(--red-500)" }}>
              {title}
            </Typography.Title>
            <Typography.Text>
              Você está certo que deseja remover o(a){" "}
              <b>
                {suffix} {itemName}
              </b>
              ?
            </Typography.Text>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
}
