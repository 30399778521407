import { useEffect } from "react";

import { IMonthFormModalPropsProps } from "./interface";

import { CustomInput } from "~components/CustomInput";
import { FooterModal } from "~components/FooterModal";
import { useAppConfig } from "~hooks/useAppConfig";
import { Form, Modal, Row, Col, Button } from "antd";

const MONTHS = [
  { ptBrKey: "Janeiro", key: "january" },
  { ptBrKey: "Fevereiro", key: "february" },
  { ptBrKey: "Março", key: "march" },
  { ptBrKey: "Abril", key: "april" },
  { ptBrKey: "Maio", key: "may" },
  { ptBrKey: "junho", key: "june" },
  { ptBrKey: "Julho", key: "july" },
  { ptBrKey: "Agosto", key: "august" },
  { ptBrKey: "Setembro", key: "september" },
  { ptBrKey: "Outubro", key: "october" },
  { ptBrKey: "Novembro", key: "november" },
  { ptBrKey: "Dezembro", key: "december" },
];

export function MonthFormModal({
  isOpen = false,
  onClose = () => null,
  onSuccess = () => null,
  form: formInstance,
  averageFormKey,
  monthlyFormKey,
  title = "Consumo mês a mês",
  destroyOnClose = false,
}: IMonthFormModalPropsProps) {
  const { screens } = useAppConfig();
  const [form] = Form.useForm();

  const onFinish = (
    fieldsValue: Record<
      IMonthFormModalPropsProps["monthlyFormKey"],
      Record<string, number>
    >,
  ) => {
    const monthlyObject = MONTHS.reduce(
      (object, month) => {
        const actualValue: number =
          fieldsValue?.[monthlyFormKey]?.[month.key] ?? 0;

        return {
          ...object,
          [month.key]: actualValue,
        };
      },
      {} as Record<string, number>,
    );

    const sumValues = Object.values(monthlyObject).reduce(
      (sum, value) => sum + value,
      0,
    );

    const averageValue = sumValues / 12;

    formInstance.setFieldValue(monthlyFormKey, monthlyObject);
    formInstance.setFieldValue(averageFormKey, averageValue);

    onSuccess();
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue({
        [monthlyFormKey]: { ...formInstance.getFieldValue(monthlyFormKey) },
      });
    }
  }, [form, formInstance, isOpen, monthlyFormKey]);

  return (
    <Modal
      data-cy="month-form-modal"
      title={title}
      open={isOpen}
      onCancel={onClose}
      footer={null}
      destroyOnClose={destroyOnClose}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        scrollToFirstError
      >
        <Row gutter={16}>
          {MONTHS.map((month, index) => (
            <Col key={month.key} span={screens.xs ? 12 : 8}>
              <Form.Item
                name={[monthlyFormKey, month.key]}
                initialValue={form.getFieldValue(monthlyFormKey)?.[index]}
                rules={[
                  {
                    required: true,
                    message: `Por favor, insira o mês de ${month.ptBrKey}`,
                  },
                ]}
              >
                <CustomInput.UnitMeasurement
                  placeholder={month.ptBrKey}
                  min={0}
                  precision="2"
                  unitMeasurement="KWh"
                  style={{ width: "100%" }}
                  data-cy="month-input"
                />
              </Form.Item>
            </Col>
          ))}
        </Row>

        <FooterModal>
          <Button onClick={onClose}>Cancelar</Button>
          <Button
            data-cy="button-define-consumption"
            type="primary"
            htmlType="submit"
          >
            Definir consumo
          </Button>
        </FooterModal>
      </Form>
    </Modal>
  );
}
