import { AiOutlineGoogle } from "react-icons/ai";

import type { ISearchGoogleAddressModalButtonProps } from "./interface";

import { SelectLocationInputModal } from "~components/GoogleMaps";
import { parseFunctions, useQueryParams } from "~hooks/useQueryParams";
import { SolarzButton } from "~solarzui/SolarzButton";

const DEFAULT_MODAL_QUERY_KEY = "google-search";

export function SearchGoogleAddressModalButton({
  onSubmit,
  children = "Buscar endereço com Google",
  icon = <AiOutlineGoogle />,
  ...props
}: ISearchGoogleAddressModalButtonProps) {
  const { handleSaveInQueryParams, getParsedQueryParams } = useQueryParams();

  const isModalOpen = !!getParsedQueryParams(
    DEFAULT_MODAL_QUERY_KEY,
    parseFunctions.BOOLEAN,
  );

  function handleOpenModal() {
    handleSaveInQueryParams({ [DEFAULT_MODAL_QUERY_KEY]: "true" });
  }

  function handleCloseModal() {
    handleSaveInQueryParams({ [DEFAULT_MODAL_QUERY_KEY]: "" });
  }

  return (
    <>
      <SolarzButton
        {...props}
        data-cy="search-google-address-modal-button"
        icon={icon}
        onClick={handleOpenModal}
      >
        {children}
      </SolarzButton>

      <SelectLocationInputModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSubmit={(location) => {
          if (typeof onSubmit === "function") {
            onSubmit(location);
          }

          handleCloseModal();
        }}
      />
    </>
  );
}
