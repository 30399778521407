import styled from "@emotion/styled";
import { Avatar, Skeleton } from "antd";

interface IStyledAvatarProps {
  variant?: "medium" | "large";
}

export const StyledAvatar = styled(Avatar)<IStyledAvatarProps>`
  color: var(--white);
  background-color: var(--primary-500);
  height: ${(props) => (props.variant === "large" ? "4rem" : "2rem")};
  width: ${(props) => (props.variant === "large" ? "4rem" : "2rem")};
  min-height: ${(props) => (props.variant === "large" ? "4rem" : "2rem")};
  min-width: ${(props) => (props.variant === "large" ? "4rem" : "2rem")};

  > span {
    font-size: ${(props) => (props.variant === "large" ? "24px" : "14px")};
    line-height: 28px;
  }
`;

export const LoadingAvatar = styled(Skeleton.Avatar)<IStyledAvatarProps>`
  height: ${(props) => (props.variant === "large" ? "4rem" : "2rem")};
  width: ${(props) => (props.variant === "large" ? "4rem" : "2rem")};
`;
