import { useMemo } from "react";

import type {
  IProposalStatusSelectProps,
  IProposalStatusSelectOption,
} from "./interface";

import { Select } from "antd";

export function ProposalStatusSelect({
  value,
  onChange,
  defaultValue,
  disableOptions = [],
  hideOptions = [],
  ...props
}: IProposalStatusSelectProps) {
  const OPTIONS: IProposalStatusSelectOption[] = useMemo(
    () =>
      [
        {
          label: "Ganha",
          value: "WON" as const,
          disabled: disableOptions.includes("WON"),
          hide: hideOptions.includes("WON"),
        },
        {
          label: "Perdida",
          value: "LOST" as const,
          disabled: disableOptions.includes("LOST"),
          hide: hideOptions.includes("LOST"),
        },
        {
          label: "Aberta",
          value: "OPEN" as const,
          disabled: disableOptions.includes("OPEN"),
          hide: hideOptions.includes("OPEN"),
        },
        {
          label: "Apagada",
          value: "DELETED" as const,
          disabled: disableOptions.includes("DELETED"),
          hide: hideOptions.includes("DELETED"),
        },
        {
          label: "Rejeitada",
          value: "REJECTED" as const,
          disabled: disableOptions.includes("REJECTED"),
          hide: hideOptions.includes("REJECTED"),
        },
      ].filter((option) => !option.hide),
    [disableOptions, hideOptions],
  );

  return (
    <Select
      {...props}
      options={OPTIONS}
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
}
