import NextImage from "next/image";

import { IPipedriveLoginButtonProps } from "./interface";
import { StyledSolarzButton } from "./styles";

import { useSession } from "~hooks/useSession";

export function PipedriveLoginButton({
  isDisabled = false,
  isBlock = false,
}: IPipedriveLoginButtonProps) {
  const { signInWithPipedrive, sessionStatus } = useSession();

  const isLoading = sessionStatus === "loading";

  return (
    <StyledSolarzButton
      htmlType="button"
      isBlock={isBlock}
      icon={
        <NextImage
          src="/images/pipedrive-icon-blue.svg"
          alt="Ícone da letra P do Pipedrive demonstrando vinculação do botão ao login do Pipedrive"
          width={20}
          height={20}
        />
      }
      variant="secondary"
      isLoading={isLoading}
      onClick={signInWithPipedrive}
      isDisabled={isDisabled}
      data-cy="pipedrive-login-button"
      className="pipedrive-button"
    >
      Entrar com pipedrive
    </StyledSolarzButton>
  );
}
