import { useMemo } from "react";

import type {
  ProjectTypeEosFinancingPersonRecordType,
  IProjectTypeEosFinancingPersonSelectProps,
  ProjectTypeEosFinancingPersonOptionType,
} from "./interface";

import { SolarzSelect } from "~solarzui/SolarzSelect";
import { typeEosProjectEnum } from "~types/enum";
import { translateTypeProjectEosFinancingEnum } from "~utils/translate";

export function ProjectTypeEosFinancingPersonSelect({
  ...props
}: IProjectTypeEosFinancingPersonSelectProps) {
  const OPTIONS = useMemo(() => {
    return typeEosProjectEnum.map((value) => ({
      value,
      record: {
        value,
      },
      label: translateTypeProjectEosFinancingEnum(value),
    })) as ProjectTypeEosFinancingPersonOptionType;
  }, []);

  return (
    <SolarzSelect<ProjectTypeEosFinancingPersonRecordType, "value">
      {...props}
      options={OPTIONS}
    />
  );
}
