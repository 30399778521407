import { IInputUUIDProps } from "./interface";

import { formatUuid } from "~utils/format";
import { Input } from "antd";

// CAN USE THIS RULE TO CHECK IN FORM.ITEM IF IS VALID
// rules={[
//   { required: true, message: "Token é obrigatório" },
//   {
//     validator(_rule, value) {
//       return validateUuid(value)
//         ? Promise.resolve()
//         : Promise.reject(new Error("O valor não é um UUID válido!"));
//     },
//   },
// ]}

export function InputUUID({
  onChange,
  onFocus = (event) => event.target.select(),
  separateWithDash = true,
  ...rest
}: IInputUUIDProps) {
  return (
    <Input
      {...rest}
      onFocus={onFocus}
      onChange={(event) => {
        const trimmedValue = event.target.value.trim() ?? "";
        const formattedValue = formatUuid(trimmedValue, separateWithDash);

        event.target.value = formattedValue;

        if (!onChange) return;
        onChange(event);
      }}
    />
  );
}
