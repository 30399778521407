import {
  IDiverseServiceFormProps,
  DiverseServicesFormValuesType,
} from "./interface";

import { Alert } from "~components/Alert";
import {
  useFetchGetDealByCustomId,
  useFetchUpdateDiverseServiceByDealId,
} from "~hooks/api";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzFormItem } from "~solarzui/SolarzFormItem";
import { SolarzInputNumber } from "~solarzui/SolarzInputNumber";
import { Form } from "antd";

export function DiverseServiceDealForm({
  formInstance,
  initialValues,
  dealId,
  onError,
  onSuccess,
  onCancel,
  style,
}: IDiverseServiceFormProps) {
  const [form] = Form.useForm<DiverseServicesFormValuesType>(formInstance);

  const { mutate: updateSolarService, isLoading: isUpdatingDiverseService } =
    useFetchUpdateDiverseServiceByDealId({
      options: {
        onSuccess,
        onError,
      },
    });

  function handleSubmit(formValues: DiverseServicesFormValuesType) {
    updateSolarService({
      ...formValues,
      dealId,
    });
  }

  const {
    data: deal,
    isFetching: isLoadingDeal,
    error: dealError,
    refetch: refetchDeal,
  } = useFetchGetDealByCustomId({
    dependencyArray: [dealId],
    payload: {
      customId: dealId,
    },
    options: {
      enabled: dealId > 0,
      retry: 1,
      onSuccess(deal) {
        form.setFieldsValue({
          ...deal,
          totalValue: deal.diverseService?.totalValue,
        });
      },
    },
  });

  function handleCancel() {
    if (typeof onCancel === "function") {
      return onCancel();
    }

    if (deal) {
      return form.setFieldsValue({
        ...deal,
        totalValue: deal.diverseService?.totalValue,
      });
    }

    form.resetFields();
  }

  const isDisable = !!dealError || isUpdatingDiverseService || isLoadingDeal;

  return (
    <Form
      data-cy="diverse-service-deal-form"
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFinish={() => {
        handleSubmit(form.getFieldsValue(true));
      }}
      preserve
      style={{ ...style }}
    >
      <div className="w-full flex flex-col gap-3">
        {dealError && (
          <Alert.CardError
            errorMessage={dealError?.message}
            title="Falha ao carregar dados iniciais"
            reloadFn={refetchDeal}
            style={{ marginBottom: 16 }}
          />
        )}
        <SolarzFormItem<DiverseServicesFormValuesType>
          name="totalValue"
          label="Valor total"
          rules={[{ required: true, message: "Campo obrigatório" }]}
        >
          <SolarzInputNumber icon={"R$"} placeholder="00.000,00" />
        </SolarzFormItem>

        <div className="w-full flex flex-row align-middle justify-end gap-6">
          <SolarzButton variant="secondary" onClick={handleCancel}>
            Cancelar
          </SolarzButton>
          <SolarzButton
            variant="primary"
            htmlType="submit"
            isLoading={isUpdatingDiverseService || isLoadingDeal}
            isDisabled={isDisable}
          >
            Salvar
          </SolarzButton>
        </div>
      </div>
    </Form>
  );
}
