import { useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";

import type {
  IDealDefaultFieldsTableData,
  IDealDefaultFieldsTableColumn,
} from "./interface";
import { StyledCollapse } from "./styles";

import { Menu } from "~components/Menu";
import { Modal } from "~components/Modal";
import { Result } from "~components/Result";
import { useFetchGetDealDefaultFieldsSettings } from "~hooks/api/config/useFetchGetDealDefaultFieldsSettings";
import { Badge, Flex, Table, Typography } from "antd";

export function DealDefaultFieldsTable() {
  const [selectedDefaultFieldToUpdate, setSelectedDefaultFieldToUpdate] =
    useState<IDealDefaultFieldsTableData | undefined>(undefined);

  const columns: IDealDefaultFieldsTableColumn = [
    {
      key: "sort",
      width: 42,
      align: "center" as const,
    },
    {
      title: "Nome do campo",
      dataIndex: "dealDefaultField",
      width: "auto",
      render: (_, record) => {
        return (
          <span>
            {record?.dealDefaultField?.label || "-"}
            {record.required && (
              <b style={{ color: "var(--primary-500)" }}>*</b>
            )}
          </span>
        );
      },
      key: "id",
    },
    {
      title: "",
      dataIndex: "action",
      width: 42,
      align: "center" as const,
      render: (_, defaultField) => (
        <Menu.MoreDropdown
          items={[
            {
              icon: (
                <AiOutlineEdit
                  fontSize={18}
                  style={{ color: "var(--gold-500)" }}
                />
              ),
              text: "Alterar",
              color: "var(--gold-500)",
              onClick: () => setSelectedDefaultFieldToUpdate(defaultField),
            },
          ]}
          position="bottomLeft"
        />
      ),
    },
  ];

  const {
    isFetching: isLoadingDefaultFields,
    error: defaultFieldsError,
    data: defaultFields,
  } = useFetchGetDealDefaultFieldsSettings({});

  return (
    <>
      <StyledCollapse
        size="large"
        style={{ padding: 0, border: 0 }}
        defaultActiveKey={["1"]}
        items={[
          {
            key: "1",
            label: (
              <Flex align="center" gap={6}>
                <Typography.Text
                  style={{
                    margin: 0,
                    fontWeight: "bold",
                  }}
                >
                  Campos padrões
                </Typography.Text>

                <Flex align="center">
                  <Badge
                    count={defaultFields ? defaultFields.length : 0}
                    style={{
                      backgroundColor: "#e4e4e5",
                      color: "#21232c",
                      fontWeight: "bold",
                    }}
                  />
                </Flex>
              </Flex>
            ),
            children: (
              <Table
                rowKey="key"
                columns={columns}
                dataSource={defaultFields}
                scroll={{ x: 900 }}
                size="small"
                pagination={false}
                loading={isLoadingDefaultFields}
                locale={{
                  emptyText: (
                    <Result.TableErrorOrEmpty
                      errorMessage={defaultFieldsError?.message}
                      emptyMessage="Nenhum campo Dealalizado encontrado"
                    />
                  ),
                }}
                style={{ width: "100%" }}
              />
            ),
          },
        ]}
      />

      <Modal.DealDefaultFieldForm
        isOpen={!!selectedDefaultFieldToUpdate}
        onClose={() => setSelectedDefaultFieldToUpdate(undefined)}
        formProps={{
          initialValues: selectedDefaultFieldToUpdate,
          onSubmitSuccess: () => setSelectedDefaultFieldToUpdate(undefined),
        }}
      />
    </>
  );
}
