import { useMemo, useState } from "react";
import { BsNodePlusFill } from "react-icons/bs";

import type {
  DiverseKitsTableColumnType,
  DiverseKitsTableDataType,
  IDiverseKitsTablePagination,
  IDiverseKitsTableSort,
  IDiverseKitsTableProps,
} from "./interface";

import { Modal } from "~components/Modal";
import { createPaginationTableObject } from "~components/Pagination";
import { Result } from "~components/Result";
import { useAppConfig } from "~hooks/useAppConfig";
import { parseCurrency } from "~utils/parse";
import { Button, Table } from "antd";

export function DiverseKitsTable({
  diverseKits = [],
  isLoading = false,
  defaultPagination = {
    number: 1,
    totalElements: 0,
  },
  size = "small",
  disableSelect = false,
  onSelectKit = () => null,
  onSubmit = () => undefined,
  defaultSort = {
    by: "priceCents",
    order: "ascend",
  },
}: IDiverseKitsTableProps) {
  const { screens } = useAppConfig();

  const [selectedKitDetails, setSelectedKitDetails] = useState<
    DiverseKitsTableDataType | undefined
  >();

  const [pagination, setPagination] =
    useState<IDiverseKitsTablePagination>(defaultPagination);

  const [sort, setSort] = useState<IDiverseKitsTableSort>(defaultSort);

  const DATA_SOURCE: DiverseKitsTableDataType[] = useMemo(
    () => diverseKits,
    [diverseKits],
  );

  function onPageChange(page: number) {
    setPagination((current): { number: number; totalElements: number } => ({
      ...current,
      number: page,
    }));
    onSubmit();
  }

  function renderTotalValue(data: DiverseKitsTableDataType) {
    return data.totalValue ? parseCurrency(data.totalValue) : "-";
  }

  const COLUMNS: DiverseKitsTableColumnType[] = useMemo(
    () =>
      (
        [
          {
            title: "Nome",
            dataIndex: "name",
            key: "name",
            render: (value: string) => value,
          },
          {
            title: "Preço",
            dataIndex: "priceCents",
            align: "center" as const,
            width: "10rem",
            render: (_, record) => renderTotalValue(record),
            sortOrder: sort.by === "priceCents" ? sort.order : undefined,
            sorter: true,
            onHeaderCell: () => ({
              onClick: () => {
                setSort((current) => {
                  const sortBy = "priceCents";

                  const sortOrder =
                    sort.by === "priceCents"
                      ? sort.order === "ascend"
                        ? "descend"
                        : "ascend"
                      : "ascend";

                  return {
                    ...current,
                    by: sortBy,
                    order: sortOrder,
                  };
                });
              },
            }),
          },
          {
            title: "",
            dataIndex: "action",
            align: "center" as const,
            width: "4rem",
            render: (_, kit) => (
              <Button type="link" onClick={() => setSelectedKitDetails(kit)}>
                <BsNodePlusFill size={20} />
              </Button>
            ),
          },
        ] as DiverseKitsTableColumnType[]
      ).filter((column) => !column.isHidden),
    [sort.by, sort.order],
  );

  return (
    <>
      <Table
        data-cy="diverse-kits-table"
        columns={COLUMNS}
        dataSource={DATA_SOURCE}
        rowKey="key"
        pagination={{
          ...createPaginationTableObject({
            number: pagination.number,
            size: 30,
            totalElements: pagination.totalElements,
            onChange: onPageChange,
            itemType: "kits diversos",
            displayTotalInformation: screens.sm,
          }),
          position: ["bottomRight"],
        }}
        scroll={{ x: 1280, y: undefined }}
        size={size}
        loading={isLoading}
        locale={{
          emptyText: (
            <Result.TableErrorOrEmpty
              errorMessage=""
              emptyMessage="Nenhum kit encontrado"
            />
          ),
        }}
        rowSelection={
          disableSelect
            ? undefined
            : {
                onSelect: (record) => {
                  onSelectKit(record);
                },
                type: "radio",
              }
        }
      />
      <Modal.KitItemsDetails
        isOpen={!!selectedKitDetails?.items}
        itemKits={selectedKitDetails?.items}
        title={selectedKitDetails?.name}
        onClose={() => {
          setSelectedKitDetails(undefined);
        }}
        tableProps={{
          displayActionColumn: false,
          displayItemInfoIcon: false,
        }}
      />
    </>
  );
}
