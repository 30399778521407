import {
  ISimultaneityProfileFormProps,
  ISimultaneityProfileFormValues,
} from "./interface";

import { CustomInput } from "~components/CustomInput";
import {
  useFetchCreateSimultaneityProfile,
  useFetchUpdateSimultaneityProfile,
} from "~hooks/api";
import { Button, Flex, Form } from "antd";

export function SimultaneityProfileForm({
  initialValues,
  onCancel = () => undefined,
  onSuccess = () => undefined,
  onError = () => undefined,
}: ISimultaneityProfileFormProps) {
  const [form] = Form.useForm<ISimultaneityProfileFormValues>();

  const {
    mutate: createSimultaneityProfile,
    isLoading: isCreatingSimultaneityProfile,
  } = useFetchCreateSimultaneityProfile({
    options: {
      onSuccess: () => {
        onSuccess();
      },
      onError: (error) => {
        onError(error.message);
      },
    },
  });

  const {
    mutate: updateSimultaneityProfile,
    isLoading: isUpdateSimultaneityProfile,
  } = useFetchUpdateSimultaneityProfile({
    options: {
      onSuccess: () => {
        onSuccess();
      },
      onError: (error) => {
        onError(error.message);
      },
    },
  });

  function onFinish(data: ISimultaneityProfileFormValues) {
    if (initialValues?.id) {
      updateSimultaneityProfile({
        ...data,
        simultaneityProfileId: initialValues.id,
      });
    } else {
      createSimultaneityProfile(data);
    }
  }

  const isLoading =
    isCreatingSimultaneityProfile || isUpdateSimultaneityProfile;

  return (
    <Form
      data-cy="simultaneity-profile-form"
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Form.Item
        label="Descrição"
        name="description"
        rules={[{ required: true }]}
        style={{ width: "100%" }}
      >
        <CustomInput.Text placeholder="Descrição do perfil" />
      </Form.Item>

      <Form.Item
        label="Taxa de simultaneidade %"
        name="value"
        rules={[{ required: true }]}
        style={{ width: "100%" }}
      >
        <CustomInput.UnitMeasurement
          precision="2"
          unitMeasurement="%"
          placeholder="Exemplo: 99,99%"
          min={0}
          max={100}
        />
      </Form.Item>

      <Flex align="center" gap={8} justify="end">
        <Button onClick={onCancel}>Cancelar</Button>

        <Button type="primary" htmlType="submit" loading={isLoading}>
          Salvar
        </Button>
      </Flex>
    </Form>
  );
}
