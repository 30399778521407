import { IComplementaryItemsOfSettingsFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function ComplementaryItemsOfSettingsFormModal({
  isOpen = false,
  onClose = () => null,
  title = "Item complementar",
  width = "90vw",
  formProps = {},
}: IComplementaryItemsOfSettingsFormModalProps) {
  return (
    <Modal
      data-cy="complementary-items-of-settings-form-modal"
      open={isOpen}
      onCancel={onClose}
      title={title}
      width={width}
      footer={null}
      destroyOnClose
      style={{ maxWidth: 1000 }}
    >
      <Form.ComplementaryItemsOfSettings onCancel={onClose} {...formProps} />
    </Modal>
  );
}
