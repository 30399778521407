import { useEffect } from "react";

import type {
  IDealCustomFieldGroupFormProps,
  DealCustomFieldGroupFormValuesType,
} from "./interface";

import { Alert } from "~components/Alert";
import {
  useFetchCreateDealCustomFieldGroup,
  useFetchGetDealCustomFieldGroupById,
  useFetchUpdateDealCustomFieldGroup,
} from "~hooks/api";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzFormItem } from "~solarzui/SolarzFormItem";
import { SolarzInput } from "~solarzui/SolarzInput";
import { Flex, Form, Spin } from "antd";

export function DealCustomFieldGroupForm({
  formInstance,
  initialValues,
  dealCustomFieldGroupId = 0,
  onCancel,
  onError,
  onSuccess,
}: IDealCustomFieldGroupFormProps) {
  const [form] = Form.useForm<DealCustomFieldGroupFormValuesType>(formInstance);

  const {
    mutate: createDealCustomFieldGroup,
    isLoading: isCreatingDealCustomFieldGroup,
  } = useFetchCreateDealCustomFieldGroup({
    options: {
      onSuccess,
      onError,
    },
  });

  const {
    mutate: updateDealCustomFieldGroup,
    isLoading: isUpdatingDealCustomFieldGroup,
  } = useFetchUpdateDealCustomFieldGroup({
    options: {
      onSuccess,
      onError,
    },
  });

  const {
    data: dealCustomFieldGroup,
    isFetching: isLoadingDealCustomFieldGroup,
    error: errorDealCustomFieldGroup,
    refetch: refetchDealCustomFieldGroup,
  } = useFetchGetDealCustomFieldGroupById({
    dependencyArray: [dealCustomFieldGroupId],
    options: {
      retry: 1,
      enabled: dealCustomFieldGroupId > 0,
    },
    payload: {
      id: dealCustomFieldGroupId,
    },
  });

  function handleSubmit(formValues: DealCustomFieldGroupFormValuesType) {
    dealCustomFieldGroupId
      ? updateDealCustomFieldGroup({
          ...formValues,
          id: dealCustomFieldGroupId,
        })
      : createDealCustomFieldGroup({
          ...formValues,
        });
  }

  function handleCancel() {
    const formInitialValues = dealCustomFieldGroup
      ? { name: dealCustomFieldGroup?.name }
      : initialValues ?? {};

    form.setFieldsValue(formInitialValues);

    if (typeof onCancel === "function") {
      return onCancel();
    }
  }

  const isSubmittingForm =
    isCreatingDealCustomFieldGroup || isUpdatingDealCustomFieldGroup;

  useEffect(() => {
    if (dealCustomFieldGroup) {
      form.setFieldsValue({
        name: dealCustomFieldGroup.name,
      });
    }
  }, [form, dealCustomFieldGroup]);

  return (
    <Form
      data-cy="deal-custom-field-group-form"
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmit}
      preserve
      layout="vertical"
    >
      {errorDealCustomFieldGroup && (
        <Alert.CardError
          errorMessage="Aguardando get by id para pegar dados iniciais"
          title="Em desenvolvimento..."
          reloadFn={refetchDealCustomFieldGroup}
          style={{ marginBottom: 16 }}
        />
      )}

      <Spin spinning={isLoadingDealCustomFieldGroup}>
        <SolarzFormItem<DealCustomFieldGroupFormValuesType>
          name="name"
          label="Nome da sessão"
          rules={[{ required: true }]}
        >
          <SolarzInput placeholder="Nome da sessão..." />
        </SolarzFormItem>
      </Spin>

      <Flex
        style={{ marginTop: 12 }}
        align="center"
        gap={16}
        justify="flex-end"
      >
        <SolarzButton variant="secondary" scale="tiny" onClick={handleCancel}>
          Cancelar
        </SolarzButton>
        <SolarzButton
          scale="tiny"
          htmlType="submit"
          isDisabled={isLoadingDealCustomFieldGroup}
          isLoading={isSubmittingForm}
        >
          {dealCustomFieldGroupId ? "Atualizar grupo" : "Criar grupo"}
        </SolarzButton>
      </Flex>
    </Form>
  );
}
