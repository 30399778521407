import type { IDealCustomFieldsFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function DealCustomFieldsFormModal({
  isOpen = false,
  onClose = () => undefined,
  maxWidth = 560,
  title = "Formulário campos customizados de negócio",
  formProps,
}: IDealCustomFieldsFormModalProps) {
  return (
    <Modal
      data-cy="deal-custom-fields-form-modal"
      title={title}
      width="90vw"
      style={{
        maxWidth,
      }}
      open={isOpen}
      footer={null}
      onCancel={onClose}
      destroyOnClose
    >
      <Form.DealCustomFields {...formProps} onCancel={onClose} />
    </Modal>
  );
}
