import { useEffect, useState } from "react";
import { BiErrorCircle } from "react-icons/bi";
import { FiAlertTriangle } from "react-icons/fi";

import { IInputPerformanceRateProps } from "./interface";
import styles from "./styles";

import { Modal } from "~components/Modal";
import { useFetchGetMonthlyPerformanceRateByCustomId } from "~hooks/api";
import { getAverageMonthlyPerformanceRate } from "~utils/calculateAverageMonthlyPerformance";
import { Button, Divider, Form, InputNumber, Spin, Tooltip, Flex } from "antd";

export function InputPerformanceRate({
  customId = 0,
  className,
  onFocus = (event) => event.target.select(),
  extra,
  currentPerformance,
  setCurrentPerformance = () => undefined,
  onChange = () => undefined,
  completeWithDefaultValueInFirstLoad = false,
  cannotSelectMonthToMonth = false,
  hasDirectlyInserted = false,
  setHasDirectlyInserted = () => undefined,
  ...props
}: IInputPerformanceRateProps) {
  const [defaultPerformance, setDefaultPerformance] = useState<number>();
  const [PerformanceError, setPerformanceError] = useState<string | undefined>(
    undefined,
  );

  const [isPerformanceModalOpen, setIsPerformanceModalOpen] = useState(false);

  const {
    isFetching: isLoadingProjectPerformanceRate,
    refetch: reloadProjectPerformanceRate,
  } = useFetchGetMonthlyPerformanceRateByCustomId({
    dependencyArray: [customId],
    payload: {
      customId,
    },
    options: {
      enabled: customId > 0,
      onSuccess(data) {
        if (!data) {
          return;
        }

        const average = getAverageMonthlyPerformanceRate(data);

        setDefaultPerformance(average);
      },
      onError(error) {
        setPerformanceError(error.message);
      },
    },
  });

  useEffect(() => {
    onChange(currentPerformance);
  }, [onChange, currentPerformance]);

  useEffect(() => {
    if (completeWithDefaultValueInFirstLoad && !currentPerformance) {
      setCurrentPerformance(defaultPerformance);
    }
  }, [
    completeWithDefaultValueInFirstLoad,
    currentPerformance,
    defaultPerformance,
    setCurrentPerformance,
  ]);

  useEffect(() => {
    if (defaultPerformance !== currentPerformance) {
      setHasDirectlyInserted(true);
    } else {
      setHasDirectlyInserted(false);
    }
  }, [defaultPerformance, currentPerformance, setHasDirectlyInserted]);

  const isLoading = isLoadingProjectPerformanceRate;
  // const hasDirectlyInserted = defaultPerformance !== currentPerformance;

  return (
    <>
      <style jsx>{styles}</style>
      <Form.Item
        extra={
          !cannotSelectMonthToMonth && (
            <Flex justify="space-between" align="center">
              <Flex align="center" gap={4}>
                <Button
                  type="link"
                  size="small"
                  onClick={() => setIsPerformanceModalOpen(true)}
                  style={{ margin: 0, padding: 0 }}
                >
                  <Flex justify="center" align="center">
                    Definir performance mês a mês
                  </Flex>
                </Button>
                {hasDirectlyInserted && (
                  <>
                    <Divider type="vertical" />
                    <Tooltip title="O valor foi inserido manualmente ignorando o valor mês-a-mês">
                      <Flex align="center" style={{ cursor: "help" }}>
                        <FiAlertTriangle size={14} color="var(--red-500)" />
                        <span
                          style={{
                            color: "var(--red-500)",
                            marginLeft: "0.25rem",
                          }}
                        >
                          Inserido manualmente!
                        </span>
                      </Flex>
                    </Tooltip>
                  </>
                )}
              </Flex>

              {extra}
            </Flex>
          )
        }
      >
        <InputNumber
          {...props}
          decimalSeparator=","
          precision={2}
          className={className ?? "input"}
          addonAfter={
            isLoading ? (
              <Tooltip title="Carregando dados da performance">
                <Spin size="small" />
              </Tooltip>
            ) : PerformanceError ? (
              <Tooltip title="Clique para recarregar">
                <BiErrorCircle
                  size={18}
                  color="var(--red-200)"
                  onClick={() => reloadProjectPerformanceRate()}
                />
              </Tooltip>
            ) : (
              <span style={{ fontSize: "0.875rem" }}>%</span>
            )
          }
          onChange={(value) => {
            const performance = Number(value);
            const parsedValue = isNaN(performance) ? undefined : performance;
            setCurrentPerformance(parsedValue);
          }}
          value={currentPerformance}
          placeholder="Ex.: 33,77"
          onFocus={onFocus}
        />
      </Form.Item>

      <Modal.PerformanceRateMonthForm
        isOpen={isPerformanceModalOpen}
        formProps={{
          customId,
          onSuccess: (data) => {
            setCurrentPerformance(data.average);
            setIsPerformanceModalOpen(false);
          },
        }}
        onClose={() => setIsPerformanceModalOpen(false)}
      />
    </>
  );
}
