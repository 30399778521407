import { useState } from "react";

import type { IGoogleMapSatelliteButton } from "./interface";

import { Modal } from "~components/Modal";
import { Button } from "antd";

export function GoogleMapSatelliteButton({
  children,
  ...props
}: IGoogleMapSatelliteButton) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button
        {...props}
        data-cy="google-map-satellite-button"
        onClick={() => setIsModalOpen(true)}
        type="primary"
        htmlType="button"
      >
        {children ?? "Ver residência com o Google"}
      </Button>

      <Modal.GoogleMapSatellite
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
}
