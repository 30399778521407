import { RcFile } from "antd/lib/upload/interface";

export const getBase64 = async (file: RcFile): Promise<string> => {
  try {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    return await new Promise((resolve) => {
      reader.onload = () => resolve(reader.result as string);
    });
  } catch (error) {
    console.error("Erro ao ler arquivo como base64:", error);
    throw error;
  }
};
