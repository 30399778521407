import { FC, ReactNode, CSSProperties } from "react";

import { Divider, Space } from "antd";

type Props = {
  children?: ReactNode;
  dividerStyle?: CSSProperties;
  spaceStyle?: CSSProperties;
};

export const FooterModal: FC<Props> = ({
  children,
  dividerStyle = {},
  spaceStyle = {},
}) => {
  return (
    <>
      <Divider
        style={{
          margin: 0,
          ...dividerStyle,
        }}
      />

      <Space
        size="small"
        align="center"
        wrap={false}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "1rem",
          ...spaceStyle,
        }}
      >
        {children}
      </Space>
    </>
  );
};
