import { useState } from "react";
import { FaRegQuestionCircle } from "react-icons/fa";

import type { IClientAutoCompleteProps } from "./interface";

import { AutoCompleteSuffixIcon } from "~components/Others";
import { Result } from "~components/Result";
import { Company, People } from "~estrela-ui/icons";
import { useFetchGetSearchClient } from "~hooks/api";
import { IClientSearchDTOSchema } from "~types/schemas";
import { AutoComplete, Flex } from "antd";

export function ClientAutoComplete({
  allowClear = true,
  onChange,
  onClear,
  ...props
}: IClientAutoCompleteProps) {
  const [inputValue, setInputValue] = useState<string>("");

  const {
    data: clients = [],
    isFetching,
    error,
    refetch,
  } = useFetchGetSearchClient({
    dependencyArray: [inputValue],
    payload: {
      term: inputValue,
    },
    options: {
      enabled: inputValue.length >= 3,
    },
  });

  const handleSearch = (value: string) => {
    setInputValue(value);
  };

  return (
    <AutoComplete
      {...props}
      data-cy="client-auto-complete"
      showSearch
      filterOption={false}
      onSearch={handleSearch}
      options={clients.map((client, index) => ({
        value: client.name,
        title: client.name,
        label: client.name,
        record: client,
        key: index + 1,
      }))}
      optionRender={(option) => {
        const optionData = option.data.record as IClientSearchDTOSchema;
        const clientType = optionData.clientType;

        return (
          <Flex align="center" gap={8}>
            {clientType === "ORGANIZATION" && <Company />}
            {clientType === "PERSON" && <People />}
            {!clientType && (
              <FaRegQuestionCircle size={18} strokeWidth={0.75} />
            )}
            <span>{option.label}</span>
          </Flex>
        );
      }}
      allowClear={allowClear}
      status={error ? "error" : ""}
      notFoundContent={
        <Result.SelectErrorOrEmpty
          errorMessage={error?.message}
          emptyMessage="Nenhuma empresa encontrada"
          loading={isFetching}
          reloadFn={refetch}
          disableReload={inputValue.length < 3}
        />
      }
      suffixIcon={
        <AutoCompleteSuffixIcon hasError={!!error} isLoading={isFetching} />
      }
      onClear={() => {
        setInputValue("");
        if (typeof onClear === "function") {
          onClear();
        }
      }}
      onChange={(value, option) => {
        if (option instanceof Array || !onChange) return;
        onChange(value, option);
      }}
      style={{ width: "100%" }}
    />
  );
}
