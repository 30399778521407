import { GoogleMapsProvider } from "../contexts/GoogleMapsContext";
import { SelectLocation } from "./SelectLocation";

import { Button, Flex, Form, Modal } from "antd";

interface ISelectLocationInputModalSubmitValue {
  street?: string;
  streetNumber?: number;
  city?: string;
  state?: string;
  district?: string;
  zipCode?: string;
}

interface ISelectLocationInputModal {
  isOpen?: boolean;
  onClose?: () => void;
  onSubmit?: (location: ISelectLocationInputModalSubmitValue) => void;
}

export function SelectLocationInputModal({
  isOpen = false,
  onClose = () => undefined,
  onSubmit = () => undefined,
}: ISelectLocationInputModal) {
  const [form] = Form.useForm();

  function handleSubmit() {
    const formValues: ISelectLocationInputModalSubmitValue =
      form.getFieldsValue(true);

    onSubmit(formValues);
  }

  return (
    <Modal
      data-cy="google-location-modal"
      open={isOpen}
      onCancel={onClose}
      footer={null}
      title={undefined}
      destroyOnClose
    >
      <Form
        layout="vertical"
        onFinish={handleSubmit}
        autoComplete="no"
        data-cy="google-location-form"
      >
        <Form.Item
          label="Buscar endereço"
          tooltip="Vamos buscar seu endereço com o Google"
          data-cy="autocomplete-address"
        >
          <GoogleMapsProvider
            onChangeLocationCallback={(location) => {
              form.setFieldValue("street", location?.street);
              form.setFieldValue("streetNumber", location?.streetNumber);
              form.setFieldValue("city", location?.city);
              form.setFieldValue("state", location?.state);
              form.setFieldValue("district", location?.district);
              form.setFieldValue("zipCode", location?.zipCode);
            }}
          >
            <SelectLocation
              style={{
                width: "100%",
              }}
              placeholder="Procurar endereço com o Google"
            />
          </GoogleMapsProvider>
        </Form.Item>

        <Flex gap={12} justify="end">
          <Form.Item style={{ margin: 0 }}>
            <Button onClick={onClose}>Cancelar</Button>
          </Form.Item>
          <Form.Item style={{ margin: 0 }}>
            <Button data-cy="button-confirm" type="primary" htmlType="submit">
              Confirmar
            </Button>
          </Form.Item>
        </Flex>
      </Form>
    </Modal>
  );
}
