import type { IDealDefaultDataFieldFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function DealDefaultFieldFormModal({
  isOpen = false,
  onClose = () => null,
  width = "90vw",
  formProps = {
    initialValues: undefined,
  },
}: IDealDefaultDataFieldFormModalProps) {
  return (
    <Modal
      data-cy="deal-default-field-form-modal"
      open={isOpen}
      onCancel={onClose}
      title="Editar campo"
      width={width}
      style={{ maxWidth: 890 }}
      footer={null}
      destroyOnClose
    >
      <Form.DealDefaultField {...formProps} onCancel={onClose} />
    </Modal>
  );
}
