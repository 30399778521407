import { IImportUserListModalProps } from "./interface";

import { List } from "~components/List";
import { Modal } from "antd";

export function ImportUserListModal({
  isOpen = false,
  onClose = () => null,
  title = "Importar usuários",
  width = 960,
}: IImportUserListModalProps) {
  return (
    <Modal
      data-cy="import-user-list-modal"
      open={isOpen}
      onCancel={onClose}
      title={title}
      width={width}
      footer={null}
      destroyOnClose
      style={{ maxWidth: "90vw" }}
    >
      <List.PipedriveUsers />
    </Modal>
  );
}
