import { SolarzTableColumnType } from "~solarzui/SolarzTable/interface";
import { IAdditionalCostDTOSchema } from "~types/schemas";

export type ComplementaryItemsOfSettingsTableFiltersType = {
  currentPage: number;
};

export enum ComplementaryItemsOfSettingsTableQueryParamKeysEnum {
  CURRENT_PAGE = "page",
}

export type ComplementaryItemsOfSettingsTableQueryParamsKeysType = Record<
  keyof typeof ComplementaryItemsOfSettingsTableQueryParamKeysEnum,
  string
>;

export interface IComplementaryItemsOfSettingsTableDataType
  extends IAdditionalCostDTOSchema {}

export interface IComplementaryItemsOfSettingsTableColumType
  extends SolarzTableColumnType<IComplementaryItemsOfSettingsTableDataType> {}

export interface IComplementaryItemsOfSettingsTableProps {
  defaultFilters?: Partial<ComplementaryItemsOfSettingsTableFiltersType>;
  displayFiltersComponent?: boolean;
  queryParamKeys?: ComplementaryItemsOfSettingsTableQueryParamsKeysType;
  isAuxVariable?: boolean;
}
