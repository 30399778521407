import { useEffect, useState } from "react";

import type { INotificationCenterDrawerButtonProps } from "./interface";
import { StyledNotificationsButton } from "./styles";

import { Drawer, INotificationStatus } from "~components/Drawer";
import { IRxStompSubscription } from "~contexts/RxStompClientContext/interface";
import { At, Bell } from "~estrela-ui/icons";
import { useFetchGetNotificationStatus } from "~hooks/api";
import { useRxStompClient } from "~hooks/useRxStompClient";
import { useSession } from "~hooks/useSession";
import { type INotificationDataDTOSchema } from "~types/schemas";
import { Badge, BadgeProps } from "antd";

export function NotificationCenterDrawerButton(
  _props: INotificationCenterDrawerButtonProps,
) {
  const { user } = useSession();
  const { subscribe, isConnected } = useRxStompClient();

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [notificationStatus, setNotificationStatus] =
    useState<INotificationStatus>({
      totalUnread: 0,
      eventType: "",
    });

  useFetchGetNotificationStatus({
    options: {
      onSuccess: (data) => {
        setNotificationStatus(data);
      },
      enabled: !!user,
    },
  });

  const badgePropsWhenHasNotifications: BadgeProps = {
    count:
      notificationStatus?.eventType === "MENTION" ? (
        <At
          style={{
            backgroundColor: "var(--red-500)",
            width: 23,
            height: 16,
            padding: 2,
            borderRadius: 26,
            color: "var(--white)",
          }}
        />
      ) : notificationStatus?.totalUnread > 0 ? (
        notificationStatus?.totalUnread
      ) : undefined,
  };

  const onReceiveNotification = (data: string) => {
    const notification = JSON.parse(data) as INotificationDataDTOSchema;
    if (!notification) return;
    setNotificationStatus(notification);
  };

  const handleListenNotifications = async () => {
    return await subscribe(`/receive/notification/${user?.id}`, (data) =>
      onReceiveNotification(data.body),
    );
  };

  useEffect(() => {
    let listeningNotifications: IRxStompSubscription | undefined;
    if (user?.id && isConnected) {
      handleListenNotifications().then(
        (listener) => (listeningNotifications = listener),
      );
    }
    return () => {
      if (listeningNotifications) listeningNotifications.unsubscribe();
    };
  }, [isConnected, user?.id]);

  return (
    <>
      <StyledNotificationsButton
        type="default"
        onClick={() => {
          setIsDrawerOpen(true);
          setNotificationStatus({
            totalUnread: 0,
            eventType: "",
          });
        }}
      >
        <Badge {...badgePropsWhenHasNotifications}>
          <Bell className="text-gray-400" />
        </Badge>
      </StyledNotificationsButton>

      <Drawer.NotificationCenter
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      />
    </>
  );
}
