import type {
  AddPersonToOrganizationFormValuesType,
  IAddPersonToOrganizationFormProps,
} from "./interface";

import { Alert } from "~components/Alert";
import { Select } from "~components/Select";
import {
  useFetchAddPersonToOrganization,
  useFetchGetPersonsFromOrganization,
} from "~hooks/api";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzFormItem } from "~solarzui/SolarzFormItem";
import { Flex, Form, Spin } from "antd";

export function AddPersonToOrganizationForm({
  formInstance,
  disableOrganizationId = false,
  onCancel,
  onError,
  onSuccess,
  onSuccessClear,
  initialValues,
}: IAddPersonToOrganizationFormProps) {
  const [form] =
    Form.useForm<AddPersonToOrganizationFormValuesType>(formInstance);

  const organizationIdWatcher: number | undefined = Form.useWatch(
    "organizationId",
    form,
  );

  const {
    mutate: assignUserToOrganization,
    isLoading: isAssigningUserToOrganization,
  } = useFetchAddPersonToOrganization({
    options: {
      onSuccess: () => {
        if (onSuccessClear) {
          form.resetFields();
        }

        if (typeof onSuccess === "function") {
          onSuccess();
        }
      },
      onError,
    },
  });

  const {
    data: alreadyAssignPerson,
    isFetching: isLoadingAlreadyAssignPerson,
    error: alreadyAssignPersonError,
    refetch: refetchAlreadyAssignPerson,
  } = useFetchGetPersonsFromOrganization({
    payload: {
      organizationId: organizationIdWatcher ?? 0,
    },
    dependencyArray: [organizationIdWatcher],
    options: {
      enabled: Number(organizationIdWatcher) > 0,
      retry: 1,
    },
  });

  function handleSubmit(formValues: AddPersonToOrganizationFormValuesType) {
    assignUserToOrganization(formValues);
  }

  function handleCancel() {
    typeof onCancel === "function" ? onCancel() : form.resetFields();
  }

  return (
    <Form
      data-cy="add-person-to-organization-form"
      form={form}
      onFinish={handleSubmit}
      preserve
      layout="vertical"
      initialValues={initialValues}
    >
      {!isLoadingAlreadyAssignPerson && alreadyAssignPersonError && (
        <Alert.CardError
          errorMessage={alreadyAssignPersonError?.message}
          title="Não foi possível ler as pessoas já associadas"
          reloadFn={refetchAlreadyAssignPerson}
          style={{ marginBottom: 16 }}
        />
      )}

      <Spin spinning={isLoadingAlreadyAssignPerson}>
        <SolarzFormItem<AddPersonToOrganizationFormValuesType>
          name="organizationId"
          label="Organização"
          style={{ marginBottom: 24 }}
          rules={[{ required: true, message: "Organização é obrigatório" }]}
        >
          <Select.Organization
            scale="large"
            placeholder="Selecione a organização..."
            isDisabled={disableOrganizationId}
          />
        </SolarzFormItem>

        <SolarzFormItem<AddPersonToOrganizationFormValuesType>
          name="peopleId"
          label="Pessoa"
          rules={[{ required: true, message: "Pessoa é obrigatório!" }]}
        >
          <Select.Person
            mode="multiple"
            scale="large"
            placeholder="Selecione pessoas..."
            isDisabled={!organizationIdWatcher}
            disableOptions={alreadyAssignPerson?.map((person) => person.id)}
            hasOrganization={false}
          />
        </SolarzFormItem>
      </Spin>

      <Flex align="center" gap={16} justify="flex-end">
        <SolarzButton variant="secondary" scale="tiny" onClick={handleCancel}>
          Cancelar
        </SolarzButton>
        <SolarzButton
          scale="tiny"
          htmlType="submit"
          isLoading={isAssigningUserToOrganization}
        >
          Salvar
        </SolarzButton>
      </Flex>
    </Form>
  );
}
