import { IDealTimelineCardProps } from "./interface";
import { StyledCard } from "./styles";

import { Timeline } from "~components/Timeline";
import { SolarzTypography } from "~solarzui/SolarzTypography";

export function DealTimelineCard({
  dealId,
  className,
  style,
  ...props
}: IDealTimelineCardProps) {
  return (
    <StyledCard
      {...props}
      size="small"
      styles={{
        body: {
          padding: 24,
        },
      }}
      style={style}
      className={className}
    >
      <SolarzTypography
        fontWeight="medium"
        hierarchy="paragraph2"
        variant="title"
        style={{
          marginBottom: "1rem",
        }}
      >
        Linha do tempo
      </SolarzTypography>

      <Timeline.Deal dealId={dealId} />
    </StyledCard>
  );
}
