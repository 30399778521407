import { useMemo } from "react";
import { BiErrorCircle } from "react-icons/bi";

import type { IConcessionairesWithAssociatedProfileSelectProps } from "./interface";

import { useFetchGetConcessionairesWithAssociatedProfile } from "~hooks/api";
import { Select } from "antd";

export function ConcessionairesWithAssociatedProfileSelect({
  ...props
}: IConcessionairesWithAssociatedProfileSelectProps) {
  const {
    data: concessionaires,
    isFetching: isLoadingConcessionaires,
    error: concessionairesError,
  } = useFetchGetConcessionairesWithAssociatedProfile({});

  const OPTIONS = useMemo(() => {
    if (!concessionaires) return [];

    return concessionaires.map((concessionaire) => ({
      label: concessionaire.name,
      value: concessionaire.id,
      record: concessionaire,
    }));
  }, [concessionaires]);

  return (
    <Select
      {...props}
      loading={isLoadingConcessionaires}
      options={OPTIONS}
      status={concessionairesError ? "error" : ""}
      allowClear
      suffixIcon={
        concessionairesError ? (
          <BiErrorCircle size={18} color="var(--red-200)" />
        ) : undefined
      }
    />
  );
}
