import { useMemo } from "react";

import type {
  ICurrencyTypeSelectOption,
  ICurrencyTypeSelectProps,
} from "./interface";

import { Select } from "antd";

export function CurrencyTypeSelect({
  value,
  defaultValue,
  onChange = () => null,
  otherOptions = {
    disableReais: false,
    hideReais: false,
    disablePercentual: false,
    hidePercentual: false,
  },
  ...props
}: ICurrencyTypeSelectProps) {
  const OPTIONS: ICurrencyTypeSelectOption[] = useMemo(
    () => [
      {
        label: "Reais",
        value: "Reais",
        disabled: otherOptions.disableReais,
        hidden: otherOptions.hideReais,
      },
      {
        label: "Percentual",
        value: "Percentual",
        disabled: otherOptions.disablePercentual,
        hidden: otherOptions.hidePercentual,
      },
    ],
    [otherOptions],
  );

  return (
    <Select
      {...props}
      options={OPTIONS}
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
}
