import { useState, useEffect } from "react";

import type { IPipedriveClientAutoCompleteProps } from "./interface";

import { AutoCompleteSuffixIcon } from "~components/Others";
import { Result } from "~components/Result";
import { useFetchGetSearchPipedriveClient } from "~hooks/api";
import { AutoComplete } from "antd";

export function PipedriveClientAutoComplete({
  allowClear = true,
  onChange,
  value = "",
  onClear,
  ...props
}: IPipedriveClientAutoCompleteProps) {
  const [inputValue, setInputValue] = useState("");

  const { data, isFetching, error, refetch } = useFetchGetSearchPipedriveClient(
    {
      dependencyArray: [inputValue],
      payload: {
        term: inputValue,
      },
      options: {
        enabled: inputValue.length >= 3,
        retry: false,
      },
    },
  );

  function onSearch(value: string) {
    setInputValue(value);
  }

  // For controlled components
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <AutoComplete
      {...props}
      data-cy="pipedrive-client-auto-complete"
      value={inputValue}
      showSearch
      filterOption={false}
      onSearch={onSearch}
      options={
        data?.map((client) => ({
          record: client,
          label: client.name,
          title: client.name,
          value: client.pipedriveId,
        })) ?? []
      }
      status={error ? "error" : ""}
      allowClear={allowClear}
      notFoundContent={
        <Result.SelectErrorOrEmpty
          errorMessage={error?.message}
          emptyMessage="Nenhum negócio encontrado"
          loading={isFetching}
          reloadFn={refetch}
          disableReload={inputValue.length < 3}
        />
      }
      suffixIcon={
        <AutoCompleteSuffixIcon hasError={!!error} isLoading={isFetching} />
      }
      onClear={() => {
        setInputValue("");
        if (typeof onClear === "function") {
          onClear();
        }
      }}
      onChange={(value, option) => {
        if (option instanceof Array || !onChange) return;
        onChange(value, option);
      }}
      style={{ width: "100%" }}
    />
  );
}
