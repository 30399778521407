import { SolarzTableColumnType } from "~solarzui/SolarzTable/interface";
import { ITransformerAdditionalCostListItemDTOSchema } from "~types/schemas";

export type TransformerGroupRulesTableFiltersType = {
  currentPage: number;
};

export enum TransformerGroupRulesTableQueryParamKeysEnum {
  CURRENT_PAGE = "page",
}

export type TransformerGroupRulesTableQueryParamsKeysType = Record<
  keyof typeof TransformerGroupRulesTableQueryParamKeysEnum,
  string
>;

export interface ITransformerGroupRulesTableDataType
  extends ITransformerAdditionalCostListItemDTOSchema {}

export interface ITransformerGroupRulesTableColumType
  extends SolarzTableColumnType<ITransformerGroupRulesTableDataType> {}

export interface ITransformerGroupRulesTableProps {
  defaultFilters?: Partial<TransformerGroupRulesTableFiltersType>;
  displayFiltersComponent?: boolean;
  queryParamKeys?: TransformerGroupRulesTableQueryParamsKeysType;
}
