import type { IUpdateDealPipedriveSwitchProps } from "./interface";

// import { useFetchToggleUpdateDealPipedrive } from "~hooks/api";
import { Switch } from "antd";

export function UpdateDealPipedriveSwitch({
  isChecked = false,
  isLoading = false,
}: IUpdateDealPipedriveSwitchProps) {
  // const { mutate: updatePremisesPipedrive } =
  //   useFetchToggleUpdateDealPipedrive();

  // function handleClickOnSwitch() {
  //   updateDealPipedrive();
  // }

  return (
    <Switch
      checked={isChecked}
      loading={isLoading}
      // onClick={handleClickOnSwitch}
      checkedChildren="ativo"
      unCheckedChildren="inativo"
    />
  );
}
