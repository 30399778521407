import type { IWegIntegrationFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function WegIntegrationFormModal({
  isOpen = false,
  onClose = () => null,
  title = "WEG integração",
  maxWidth = 580,
  formProps,
}: IWegIntegrationFormModalProps) {
  return (
    <Modal
      data-cy="weg-integration-form-modal"
      open={isOpen}
      onCancel={onClose}
      title={title}
      width="90vw"
      footer={null}
      style={{ maxWidth }}
      destroyOnClose
    >
      <Form.WegIntegration {...formProps} onCancel={onClose} />
    </Modal>
  );
}
