import { useState, useMemo } from "react";

import { IProductAutoCompleteProps } from "./interface";

import { AutoCompleteSuffixIcon } from "~components/Others";
import { Result } from "~components/Result";
import { useFetchGetAutoCompleteProducts } from "~hooks/api";
import { AutoComplete } from "antd";

export function ProductAutoComplete({
  allowClear = true,
  onChange,
  onClear,
  ...props
}: IProductAutoCompleteProps) {
  const [inputValue, setInputValue] = useState<string>("");

  const CAN_LOAD_DATA = useMemo(() => inputValue.length >= 3, [inputValue]);

  const { data, isFetching, error, refetch } = useFetchGetAutoCompleteProducts({
    dependencyArray: [inputValue],
    payload: {
      name: inputValue,
    },
    options: {
      enabled: CAN_LOAD_DATA,
      retry: false,
    },
  });

  const OPTIONS = useMemo(() => {
    return (
      data?.content.map((product) => ({
        label: product.distributor
          ? `${product.name} - ${product.distributor}`
          : product.name,
        value: product.name,
        record: product,
        key: product.id,
      })) ?? []
    );
  }, [data?.content]);

  function onSearch(value: string) {
    setInputValue(value);
  }

  return (
    <AutoComplete
      {...props}
      data-cy="auto-complete-product"
      showSearch
      filterOption={false}
      onSearch={onSearch}
      options={OPTIONS}
      status={error ? "error" : ""}
      allowClear={allowClear}
      notFoundContent={
        <Result.SelectErrorOrEmpty
          errorMessage={error?.message}
          emptyMessage="Nenhum produto encontrado"
          loading={isFetching}
          reloadFn={refetch}
          disableReload={!CAN_LOAD_DATA}
        />
      }
      suffixIcon={
        <AutoCompleteSuffixIcon hasError={!!error} isLoading={isFetching} />
      }
      onClear={() => {
        setInputValue("");
        if (typeof onClear === "function") {
          onClear();
        }
      }}
      onChange={(value, option) => {
        if (option instanceof Array || !onChange) return;
        onChange(value, option);
      }}
      style={{ width: "100%" }}
    />
  );
}
