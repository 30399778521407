import type { IAdminFormProps, IAdminFormValues } from "./interface";

import { useFetchPostSysAdminUser, useFetchPutSysAdminUser } from "~hooks/api";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzInput } from "~solarzui/SolarzInput";
import type {
  IFetchPostSysAdminUserPayloadSchema,
  IFetchPutSysAdminUserPayloadSchema,
} from "~types/schemas";
import { Flex, Form } from "antd";

export function AdminForm({
  initialValues,
  onCancel = () => undefined,
  onError = () => undefined,
  onSuccess = () => undefined,
}: IAdminFormProps) {
  const [form] = Form.useForm<IAdminFormValues>();

  const { mutate: createAdminUser, isLoading: isCreatingAdminUser } =
    useFetchPostSysAdminUser({
      options: {
        onSuccess,
        onError,
      },
    });

  const { mutate: updateAdminUser, isLoading: isUpdatingAdminUser } =
    useFetchPutSysAdminUser({
      options: {
        onSuccess,
        onError,
      },
    });

  function handleSubmit(formValues: IAdminFormValues) {
    if (initialValues?.id) {
      updateAdminUser({
        id: initialValues.id,
        ...formValues,
      } as IFetchPutSysAdminUserPayloadSchema);
    } else {
      createAdminUser({
        ...formValues,
      } as IFetchPostSysAdminUserPayloadSchema);
    }
  }

  const isLoading = isCreatingAdminUser || isUpdatingAdminUser;

  return (
    <Form
      data-cy="admin-form"
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmit}
      layout="vertical"
      style={{ width: "100%" }}
    >
      <Form.Item
        name="name"
        label="Nome"
        required
        rules={[
          {
            required: true,
            message: "Nome é obrigatório",
          },
        ]}
      >
        <SolarzInput
          placeholder="Ex.: José da Silva"
          defaultValue={initialValues?.name}
        />
      </Form.Item>
      <Form.Item
        name="email"
        label="E-mail"
        required
        rules={[
          {
            message: "Informe um e-mail válido",
            type: "email",
            warningOnly: true,
          },
          {
            required: true,
            message: "E-mail é obrigatório",
          },
        ]}
      >
        <SolarzInput
          isDisabled={!!initialValues?.email}
          placeholder="Ex.: exemplo@solarz.com"
          defaultValue={initialValues?.email}
        />
      </Form.Item>

      <Flex gap={12} justify="end">
        <Form.Item style={{ margin: 0 }}>
          <SolarzButton variant="secondary" scale="tiny" onClick={onCancel}>
            Cancelar
          </SolarzButton>
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <SolarzButton
            variant="primary"
            scale="tiny"
            htmlType="submit"
            isLoading={isLoading}
          >
            Salvar
          </SolarzButton>
        </Form.Item>
      </Flex>
    </Form>
  );
}
