import { useMemo, useState } from "react";

import type {
  AdminUserSelectOptionType,
  AdminUserSelectRecordType,
  IAdminUserSelectProps,
} from "./interface";

import { useFetchGetAdminUsers } from "~hooks/api";
import { useSession } from "~hooks/useSession";
import { SolarzSelect } from "~solarzui/SolarzSelect";

export function AdminUserSelect({
  showSearch = true,
  ...props
}: IAdminUserSelectProps) {
  const { user } = useSession();
  const [searchTerm, setSearchTerm] = useState("");

  const {
    data: adminUsersData,
    isFetching: isLoadingAdminUsersData,
    error: errorAdminUsersData,
    refetch: refetchAdminUsersData,
  } = useFetchGetAdminUsers({
    dependencyArray: [user, searchTerm],
    payload: {
      page: 0,
      size: 100,
      company: searchTerm,
    },
    options: {
      retry: 1,
      enabled: !!user && searchTerm.length >= 3,
    },
  });

  const OPTIONS: AdminUserSelectOptionType = useMemo(() => {
    if (!adminUsersData?.content || adminUsersData.content.length === 0)
      return [];

    const parsedOptions: AdminUserSelectOptionType = adminUsersData.content.map(
      (adminUser) => {
        return {
          label: adminUser.company || "Sem nome",
          record: adminUser,
          value: adminUser.companyId || 0,
        };
      },
    );

    return parsedOptions;
  }, [adminUsersData]);

  return (
    <SolarzSelect<AdminUserSelectRecordType, "id">
      {...props}
      data-cy="admin-user-select"
      options={OPTIONS}
      isLoading={isLoadingAdminUsersData}
      refetchFn={refetchAdminUsersData}
      errorMessage={errorAdminUsersData?.message}
      emptyMessage={
        searchTerm.length > 3
          ? "Nenhuma usuário encontrado"
          : "Digite ao menos 3 letras para buscar"
      }
      searchValue={searchTerm}
      onChangeSearch={(value) => {
        setSearchTerm(value);
      }}
      showSearch={showSearch}
    />
  );
}
