import { CSSProperties, FC, useMemo } from "react";

import { useGoogleMaps } from "../hooks/useGoogleMaps";
import { parseGoogleAddress } from "../utils/parseGoogleAddress";

import { useAppConfig } from "~hooks/useAppConfig";
import { Select } from "antd";
import type { SelectProps } from "antd/lib/select";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

type OptionType = {
  label: string;
  key: string;
  value: string;
  disabled?: boolean;
};

type Props = Omit<
  SelectProps,
  | "style"
  | "showSearch"
  | "value"
  | "onSearch"
  | "onChange"
  | "notFoundContent"
  | "filterOption"
  | "labelInValue"
  | "options"
> & {
  style?: CSSProperties;
};

export const SelectLocation: FC<Props> = ({ style = {}, ...rest }) => {
  const { message } = useAppConfig();
  const { onChangeLocation } = useGoogleMaps();

  const {
    value,
    setValue,
    suggestions: { data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleSelect = async ({ value }: OptionType) => {
    setValue(value, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address: value });
      const address = parseGoogleAddress(
        results[0].address_components,
        results[0].formatted_address,
      );
      const { lat, lng } = getLatLng(results[0]);
      onChangeLocation({
        latitude: lat,
        longitude: lng,
        ...address,
      });
    } catch (error: any) {
      message.error(error?.message);
    }
  };

  const options: OptionType[] = useMemo(() => {
    return data.map(({ description, place_id }) => ({
      label: description,
      value: description,
      key: place_id,
      title: description,
    }));
  }, [data]);

  /*
    Em algumas situações parece que os navegadores podem ignorar o autocomplete="off" e como solução encontrada foi necessário mudar a string do autocomplete, porém
    o antd não possui a propriedade autocomplete apesar dela existir por isso foi necessário alterar a partir do event do componente.
  */
  return (
    <Select<any>
      data-cy="google-select-location"
      {...rest}
      onFocus={(event: any) => {
        if (event.target) {
          event.target.autocomplete = "nope";
        }
      }}
      showSearch
      value={
        value
          ? {
              value: value?.trim().length !== 0 ? value : undefined,
              label: <>{value}</>,
            }
          : undefined
      }
      onSearch={(value) => setValue(value)}
      onChange={handleSelect}
      notFoundContent={null}
      filterOption={false}
      labelInValue
      style={{ width: "100%", height: "2.5rem", ...style }}
      options={options}
    />
  );
};
