import type { IOrganizationFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function OrganizationFormModal({
  isOpen = false,
  onClose = () => undefined,
  maxWidth = 820,
  title = "Formulário da organização",
  formProps,
}: IOrganizationFormModalProps) {
  return (
    <Modal
      data-cy="organization-form-modal"
      title={title}
      width="90vw"
      style={{
        maxWidth,
      }}
      open={isOpen}
      footer={null}
      onCancel={onClose}
      destroyOnClose
    >
      <Form.Organization {...formProps} onCancel={onClose} />
    </Modal>
  );
}
