export * from "./AdminMenu/interface";
import { AdminMenu } from "./AdminMenu";
export * from "./AdminPagesMenu/interface";
import { AdminPagesMenu } from "./AdminPagesMenu";
export * from "./AppMenu/interface";
import { AppMenu } from "./AppMenu";
export * from "./AppPagesMenu/interface";
import { AppPagesMenu } from "./AppPagesMenu";
export * from "./MoreDropdownMenu/interface";
import { MoreDropdownMenu } from "./MoreDropdownMenu";
export * from "./UserActionDropdownMenu/interface";
import { UserActionDropdownMenu } from "./UserActionDropdownMenu";

export const Menu = {
  Admin: AdminMenu,
  AdminPages: AdminPagesMenu,
  App: AppMenu,
  AppPages: AppPagesMenu,
  MoreDropdown: MoreDropdownMenu,
  UserActionDropdown: UserActionDropdownMenu,
};
