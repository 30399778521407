import { useEffect, useState } from "react";

import type {
  FieldValue,
  IProposalDataFieldProfileSettingsFormProps,
  IProposalDataFieldProfileSettingsFormValues,
} from "./interface";
import { StyledCard } from "./styles";

import { Modal } from "~components/Modal";
import { Delete } from "~estrela-ui/icons";
import {
  useFetchDeleteDataFields,
  useFetchGetProposalCustomFieldSettingsByProfile,
  useFetchUpdateProposalCustomFieldsProfileSettings,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import {
  CustomFieldSettingsFormSchema,
  ICustomFieldSettingsFormSchema,
  IFieldSettingsDTOSchema,
} from "~types/schemas";
import {
  Button,
  Form,
  Flex,
  Divider,
  Col,
  Row,
  Checkbox,
  Typography,
  List,
  Tag,
} from "antd";

export function ProposalDataFieldProfileSettingsForm({
  initialValues,
  onCancel,
  onSubmitFail = () => undefined,
  onSubmitSuccess = () => undefined,
}: IProposalDataFieldProfileSettingsFormProps) {
  const { screens, currentTheme, message } = useAppConfig();

  const [form] = Form.useForm();

  const customFieldId = initialValues?.id ?? 0;

  const [profileSettingsToDelete, setProfileSettingsToDelete] =
    useState<IFieldSettingsDTOSchema>();

  const [editedProfilesSettings, setEditedProfilesSettings] = useState<
    ICustomFieldSettingsFormSchema[]
  >([]);

  const [profileTags, setProfileTags] = useState<React.ReactNode[]>([]);

  const [currentProfileId, setCurrentProfileId] = useState<number | null>(null);

  const {
    mutate: updateProfileSettings,
    isLoading: isUpdatingProfileSettings,
  } = useFetchUpdateProposalCustomFieldsProfileSettings({
    options: {
      onSuccess: onSubmitSuccess,
      onError: onSubmitFail,
    },
  });

  const { data: profiles, isFetching: isLoadingProfiles } =
    useFetchGetProposalCustomFieldSettingsByProfile({
      payload: {
        customFieldId,
      },
      options: {
        enabled: customFieldId > 0,
        onSuccess(data) {
          const profilesWithConfig = data.filter(
            (profile) => profile.id !== null,
          );

          const transformedDataProfileSettings = profilesWithConfig.map(
            (profile) => CustomFieldSettingsFormSchema.parse(profile),
          );

          setEditedProfilesSettings(transformedDataProfileSettings);

          const firstEditableProfile = profilesWithConfig.find(
            (profile) => profile.editable === true,
          );

          if (firstEditableProfile?.profileId) {
            setCurrentProfileId(firstEditableProfile?.profileId);

            form.setFieldsValue({
              required: firstEditableProfile?.required || false,
              editable: firstEditableProfile?.editable || false,
              hidden: firstEditableProfile?.hidden || false,
            });
          }
        },
      },
      dependencyArray: [customFieldId],
    });

  const updateProfileFieldsSettings = (field: string, value: FieldValue) => {
    setEditedProfilesSettings((prevProfiles) =>
      prevProfiles.map((profile) =>
        profile.profileId === currentProfileId
          ? { ...profile, [field]: value }
          : profile,
      ),
    );
  };

  const handleCardClick = (profileId: number) => {
    setCurrentProfileId(profileId);

    const selectedProfileSettings = editedProfilesSettings.find(
      (profile) => profile.profileId === profileId,
    );

    form.setFieldsValue({
      required: selectedProfileSettings?.required || false,
      editable: selectedProfileSettings?.editable || false,
      hidden: selectedProfileSettings?.hidden || false,
    });
  };

  const handleCheckboxChange = (profileId: number, isChecked: boolean) => {
    if (isChecked) {
      setEditedProfilesSettings((prevProfiles) => {
        const existingProfile = prevProfiles.find(
          (profile) => profile.profileId === profileId,
        );

        if (!existingProfile) {
          // const newProfile = profileSettings.find(
          //   (profile) => profile.profileId === profileId,
          // );
          // console.log(newProfile, "new");
          // return newProfile ? [...prevProfiles, newProfile] : prevProfiles;
          return [
            ...prevProfiles,
            {
              profileId,
              required: false,
              editable: false,
              hidden: false,
            },
          ];
        }

        return prevProfiles;
      });
    } else {
      setEditedProfilesSettings((prevProfiles) =>
        prevProfiles.filter((profile) => profile.profileId !== profileId),
      );
      form.setFieldsValue({
        required: false,
        editable: false,
        hidden: false,
      });
      setCurrentProfileId(null);
    }
  };

  const { mutate: deleteProfileSettings, isLoading: isDeletingSettings } =
    useFetchDeleteDataFields({
      onSuccess: () => setProfileSettingsToDelete(undefined),
    });

  const handleSubmitForm = () => {
    if (customFieldId && editedProfilesSettings.length > 0) {
      const parsedData = {
        customFieldId: customFieldId,
        settings: editedProfilesSettings.map((profile) => ({
          profileId: profile.profileId || undefined,
          required: profile.required || undefined,
          editable: profile.editable || undefined,
          hidden: profile.hidden || undefined,
        })),
      };
      updateProfileSettings(parsedData);
    } else {
      message.warning(
        "A lista de perfis está vazia. Preencha pelo menos um perfil.",
      );
      return;
    }
  };

  const handleTagClose = (profileIdToRemove?: IFieldSettingsDTOSchema) => {
    if (!profileIdToRemove) return;
    setEditedProfilesSettings((prevProfiles) =>
      prevProfiles.filter(
        (profile) => profile.profileId !== profileIdToRemove.profileId,
      ),
    );

    if (currentProfileId === profileIdToRemove.profileId) {
      setCurrentProfileId(null);
      form.resetFields();
    }

    if (profileIdToRemove?.id) {
      deleteProfileSettings({
        defaultFieldProfileSettingsId: profileIdToRemove.id,
      });
    }
  };
  useEffect(() => {
    const tags = profiles
      ?.filter((profile) => profile.id !== null)
      .map((profile) => {
        return (
          <Tag key={profile.profileId} color="volcano">
            <span
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {profile.profileName}
              <Button
                type="link"
                size="small"
                onClick={() => setProfileSettingsToDelete(profile)}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Delete className="h-3.5 w-3.5" />
              </Button>
            </span>
          </Tag>
        );
      });

    setProfileTags(tags ?? []);
  }, [form, profiles, currentProfileId]);

  return (
    <>
      {initialValues?.label && (
        <Typography.Text type="secondary" style={{ margin: 16 }}>
          {initialValues?.label}
        </Typography.Text>
      )}
      <Form
        form={form}
        data-cy="proposal-custom-field-form"
        onFinish={handleSubmitForm}
        layout="vertical"
      >
        <Flex
          align="center"
          gap={screens.md ? 8 : 0}
          wrap="nowrap"
          style={{
            display: "block",
          }}
        >
          <Row>
            <Col span={screens.md ? 14 : 24}>
              <div style={{ marginLeft: 16, marginTop: 12 }}>Perfil</div>
              <List
                style={{
                  border: 0,
                  margin: 0,
                  padding: 0,
                  overflowY: "auto",
                  maxHeight: 260,
                }}
                loading={isLoadingProfiles || isDeletingSettings}
                size="large"
                bordered
                dataSource={profiles}
                renderItem={(item) => (
                  <List.Item
                    style={{
                      border: 0,
                    }}
                  >
                    <StyledCard
                      currentProfileId={currentProfileId === item.profileId}
                      theme={currentTheme}
                      hoverable
                      onClick={() => handleCardClick(item.profileId as number)}
                    >
                      <Checkbox
                        name={`${item.profileId}`}
                        style={{ marginRight: 10 }}
                        onChange={(e) =>
                          handleCheckboxChange(
                            item.profileId as number,
                            e.target.checked,
                          )
                        }
                        checked={editedProfilesSettings.some(
                          (profile) => profile.profileId === item.profileId,
                        )}
                      />
                      <span>{item.profileName}</span>
                    </StyledCard>
                  </List.Item>
                )}
              />
            </Col>

            <Col
              span={screens.md ? 2 : 24}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Divider
                type={screens.md ? "vertical" : "horizontal"}
                style={{ height: "100%" }}
              />
            </Col>

            <Col span={screens.md ? 8 : 24}>
              <Flex vertical gap={8}>
                <Divider orientation="left" orientationMargin="0">
                  {profiles &&
                    profiles.find(
                      (profile) => profile.profileId === currentProfileId,
                    )?.profileName}
                </Divider>

                <Typography.Text>Características do campo</Typography.Text>

                <Form.Item<IProposalDataFieldProfileSettingsFormValues>
                  name="required"
                  style={{ marginBottom: 0, paddingBottom: 0 }}
                  valuePropName="checked"
                >
                  <Checkbox
                    disabled={
                      !editedProfilesSettings.some(
                        (profile) => profile.profileId === currentProfileId,
                      )
                    }
                    onChange={(e) =>
                      updateProfileFieldsSettings("required", e.target.checked)
                    }
                  >
                    Obrigatório
                  </Checkbox>
                </Form.Item>
                <Form.Item<IProposalDataFieldProfileSettingsFormValues>
                  name="editable"
                  style={{ marginBottom: 0, paddingBottom: 0 }}
                  valuePropName="checked"
                >
                  <Checkbox
                    disabled={
                      !editedProfilesSettings.some(
                        (profile) => profile.profileId === currentProfileId,
                      )
                    }
                    onChange={(e) =>
                      updateProfileFieldsSettings("editable", e.target.checked)
                    }
                  >
                    Editável
                  </Checkbox>
                </Form.Item>
                <Form.Item<IProposalDataFieldProfileSettingsFormValues>
                  name="hidden"
                  style={{ marginBottom: 0, paddingBottom: 0 }}
                  valuePropName="checked"
                >
                  <Checkbox
                    disabled={
                      !editedProfilesSettings.some(
                        (profile) => profile.profileId === currentProfileId,
                      )
                    }
                    onChange={(e) =>
                      updateProfileFieldsSettings("hidden", e.target.checked)
                    }
                  >
                    Oculto
                  </Checkbox>
                </Form.Item>
              </Flex>
            </Col>
          </Row>
        </Flex>
        <Divider type={"horizontal"} />
        <Flex
          gap={8}
          style={{
            flexDirection: "column",
          }}
        >
          <Typography.Text type="secondary" style={{ marginLeft: 16 }}>
            Perfis salvos:
          </Typography.Text>
          <Flex
            gap={8}
            style={{
              marginLeft: 16,
              marginTop: 8,
              flexWrap: "wrap",
            }}
          >
            {profileTags}
          </Flex>
        </Flex>

        <Flex gap={12} justify="end">
          <Form.Item style={{ margin: 0 }}>
            <Button onClick={onCancel}>Cancelar</Button>
          </Form.Item>
          <Form.Item style={{ margin: 0 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={isUpdatingProfileSettings}
            >
              Salvar
            </Button>
          </Form.Item>
        </Flex>
      </Form>
      <Modal.DefaultRemove
        isLoading={isDeletingSettings}
        isOpen={!!profileSettingsToDelete}
        itemName={profileSettingsToDelete?.profileName}
        onClose={() => setProfileSettingsToDelete(undefined)}
        suffix={`configurações do campo ${initialValues?.label} para o perfil`}
        onSubmit={() => handleTagClose(profileSettingsToDelete)}
        title={`Remover configurações para o perfil`}
      />
    </>
  );
}
