import type { ILostDealFormValuesType, ILostDealFormProps } from "./interface";

import { Alert } from "~components/Alert";
import { Select } from "~components/Select";
import {
  useFetchGetDealByCustomId,
  useFetchUpdateDealByCustomId,
} from "~hooks/api";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzFormItem } from "~solarzui/SolarzFormItem";
import { Flex, Form, Input, Spin } from "antd";

export function LostDealForm({
  formInstance,
  onCancel,
  onError,
  onSuccess,
  dealId,
  initialValues,
}: ILostDealFormProps) {
  const [form] = Form.useForm<ILostDealFormValuesType>(formInstance);

  const {
    data: deal,
    isLoading: isLoadingDeal,
    error: dealError,
    refetch: refetchDeal,
  } = useFetchGetDealByCustomId({
    dependencyArray: [dealId],
    options: {
      retry: 1,
      enabled: !!dealId,
      onSuccess(deal) {
        form.setFieldsValue({
          reasonForLossId: deal.dealReasonForLoss?.reasonForLossId,
          comment: deal.dealReasonForLoss?.comment,
        });
      },
    },
    payload: {
      customId: dealId ?? 0,
    },
  });

  const { mutate: updateDeal, isLoading: isUpdatingDeal } =
    useFetchUpdateDealByCustomId({
      options: {
        onError,
        onSuccess,
      },
    });

  function handleSubmit(formValues: ILostDealFormValuesType) {
    return updateDeal({
      customId: dealId,
      status: "LOST",
      dealReasonForLoss: {
        comment: formValues.comment,
        reasonForLossId: formValues.reasonForLossId,
      },
    });
  }

  function handleCancel() {
    if (typeof onCancel === "function") {
      return onCancel();
    }

    form.setFieldsValue({
      comment: deal?.dealReasonForLoss?.comment ?? initialValues?.comment,
      reasonForLossId:
        deal?.dealReasonForLoss?.reasonForLossId ??
        initialValues?.reasonForLossId,
    });
  }

  return (
    <Form
      data-cy="lost-deal-form"
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmit}
      preserve
      layout="vertical"
    >
      {dealError && (
        <Alert.CardError
          errorMessage={dealError?.message}
          title="Falha ao carregar dados iniciais"
          reloadFn={refetchDeal}
          style={{ marginBottom: 16 }}
        />
      )}

      <Spin spinning={isLoadingDeal}>
        <SolarzFormItem<ILostDealFormValuesType>
          name="reasonForLossId"
          label="Motivo da perda"
          style={{ marginBottom: 24 }}
          rules={[{ required: true, message: "Motivo é obrigatório!" }]}
        >
          <Select.ReasonForLoss />
        </SolarzFormItem>

        <SolarzFormItem<ILostDealFormValuesType>
          name="comment"
          label="Observações"
          style={{ marginBottom: 24 }}
        >
          <Input.TextArea
            style={{ minHeight: 144, maxHeight: 320 }}
            placeholder="Observações..."
            size="large"
          />
        </SolarzFormItem>
      </Spin>

      <Flex align="center" gap={16} justify="flex-end">
        <SolarzButton variant="secondary" scale="tiny" onClick={handleCancel}>
          Cancelar
        </SolarzButton>
        <SolarzButton scale="tiny" htmlType="submit" isLoading={isUpdatingDeal}>
          Salvar
        </SolarzButton>
      </Flex>
    </Form>
  );
}
