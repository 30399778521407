import { IoMdLink } from "react-icons/io";

import { IProposalItemCardProps } from "./interface";
import { InnerRow, StyledProposalItemCard, StyledRow } from "./styles";

import { useFetchUpdateProposalStatusByCustomId } from "~hooks/api";
import { SolarzAnchor } from "~solarzui/SolarzAnchor";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { parseCurrency } from "~utils/parse";
import { Checkbox } from "antd";

export function ProposalItemCard({ style, proposal }: IProposalItemCardProps) {
  const { mutate: updateProposalState } =
    useFetchUpdateProposalStatusByCustomId({});

  function handleSetState() {
    if (proposal) {
      const newStatus = proposal.status === "WON" ? "OPEN" : "WON";
      updateProposalState({
        customId: proposal.id,
        changeDealStatus: false,
        status: newStatus,
      });
    }
  }

  return (
    <StyledProposalItemCard style={style} key={proposal.id}>
      <StyledRow>
        <SolarzTypography
          fontWeight="medium"
          variant="title"
          hierarchy="paragraph2"
          style={{ textAlign: "left", maxWidth: "80%" }}
        >
          {proposal.title}
        </SolarzTypography>

        <IoMdLink size={20} />
      </StyledRow>
      <StyledRow>
        <SolarzTypography fontWeight="semiBold" hierarchy="paragraph2">
          Valor
        </SolarzTypography>
        <SolarzTypography fontWeight="semiBold" hierarchy="paragraph2">
          {parseCurrency(proposal.valueForCustomer)}
        </SolarzTypography>
      </StyledRow>
      <StyledRow>
        <InnerRow>
          <Checkbox
            id={`checkbox_proposal_${proposal.id}`}
            name={`checkbox_proposal_${proposal.id}`}
            checked={proposal.status === "WON"}
            onClick={handleSetState}
            style={{ width: "16px", height: "16px" }}
          />
          <SolarzTypography fontWeight="regular" hierarchy="small">
            <label
              htmlFor={`checkbox_proposal_${proposal.id}`}
              style={{ cursor: "pointer" }}
            >
              Escolhida pelo cliente
            </label>
          </SolarzTypography>
        </InnerRow>
        <SolarzAnchor
          isNextLink
          href={`/proposta/${proposal.id}?passo=${proposal.lastStep || 1}`}
        >
          <SolarzTypography
            fontWeight="regular"
            hierarchy="small"
            variant="link"
          >
            Ver proposta
          </SolarzTypography>
        </SolarzAnchor>
      </StyledRow>
    </StyledProposalItemCard>
  );
}
