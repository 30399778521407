import type {
  IProjectTypeSelectProps,
  ProjectTypeSelectRecordType,
} from "./interface";

import { useFetchUpdateProjectTypeInUse } from "~hooks/api";
import { useSession } from "~hooks/useSession";
import { SolarzSelect } from "~solarzui/SolarzSelect";
import { ProjectTypeEnum, projectTypeEnumValues } from "~types/enum";

function translateProjectType(action: ProjectTypeEnum) {
  const translations = {
    ALL: "Todos",
    DEFAULT: "Proposta solar",
    DIVERSE: "Proposta diversa",
    EXPRESS: "Proposta express",
  };

  return translations?.[action] ?? "";
}

function getDefaultProposalType(
  projectType: ProjectTypeEnum,
  canCreateDefaultProposal: boolean,
  canCreateDiverseProposal: boolean,
  canCreateExpressProposal: boolean,
  hideDefault: boolean,
  hideDiverse: boolean,
  hideExpress: boolean,
) {
  if (projectType === "DEFAULT") {
    if (canCreateDefaultProposal && !hideDefault) {
      return projectType;
    }
    if (canCreateDiverseProposal && !hideDiverse) {
      return "DIVERSE";
    }
    if (canCreateExpressProposal && !hideExpress) {
      return "EXPRESS";
    }
  }

  if (projectType === "DIVERSE") {
    if (canCreateDiverseProposal && !hideDiverse) {
      return projectType;
    }
    if (canCreateDefaultProposal && !hideDefault) {
      return "DEFAULT";
    }
    if (canCreateExpressProposal && !hideExpress) {
      return "EXPRESS";
    }
  }

  if (projectType === "EXPRESS") {
    if (canCreateExpressProposal && !hideExpress) {
      return projectType;
    }
    if (canCreateDefaultProposal && !hideDefault) {
      return "DEFAULT";
    }
    if (canCreateDiverseProposal && !hideDiverse) {
      return "DIVERSE";
    }
  }

  return projectType;
}

export function ProposalTypeSelect({
  value,
  onChange,
  hideAll = false,
  hideDefault = false,
  hideDiverse = false,
  hideExpress = false,
  activeTab = undefined,
  ...props
}: IProjectTypeSelectProps) {
  const { user, userHavePermission, userIsAdmin } = useSession();

  const canCreateDefaultProposal =
    userIsAdmin || userHavePermission("CREATE_DEFAULT_PROPOSAL");

  const canCreateDiverseProposal =
    userIsAdmin || userHavePermission("CREATE_DIVERSE_PROPOSAL");

  const canCreateExpressProposal =
    userIsAdmin || userHavePermission("CREATE_EXPRESS_PROPOSAL");

  const { mutate: updateProjectType, isLoading: isUpdatingProjectType } =
    useFetchUpdateProjectTypeInUse({ activeTab });

  const defaultValue = getDefaultProposalType(
    user?.projectType ?? "DEFAULT",
    canCreateDefaultProposal,
    canCreateDiverseProposal,
    canCreateExpressProposal,
    hideDefault,
    hideDiverse,
    hideExpress,
  );

  function handleSelectProjectType(type: ProjectTypeEnum) {
    const payload = {
      projectType: type,
    };

    updateProjectType(payload);
  }

  const OPTIONS = projectTypeEnumValues.map((value) => ({
    label: translateProjectType(value),
    value,
    record: {
      value,
    },
    disabled:
      (value === "DEFAULT" && !canCreateDefaultProposal) ||
      (value === "DIVERSE" && !canCreateDiverseProposal) ||
      (value === "EXPRESS" && !canCreateExpressProposal),
    hidden:
      (value === "ALL" && hideAll) ||
      (value === "DEFAULT" && hideDefault) ||
      (value === "EXPRESS" && hideExpress) ||
      (value === "DIVERSE" && hideDiverse),
  }));

  return (
    <SolarzSelect<ProjectTypeSelectRecordType, "value">
      {...props}
      options={OPTIONS}
      isLoading={isUpdatingProjectType}
      value={value}
      onChange={(value, record) => {
        const parsedValue = value as ProjectTypeEnum;

        handleSelectProjectType(parsedValue);

        if (!onChange) return;

        onChange(parsedValue, record);
      }}
      defaultValue={userIsAdmin ? user?.projectType : defaultValue}
    />
  );
}
