import { useMemo, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";

import {
  IProductsOthersTableProps,
  IProductsOthersTableColumnType,
} from "./interface";

import { Menu } from "~components/Menu";
import { Modal } from "~components/Modal";
import { createPaginationTableObject } from "~components/Pagination";
import { Result } from "~components/Result";
import {
  useFetchDeleteProductTypeOther,
  useFetchGetPaginatedOtherProducts,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import type { ICustomProductDTOSchema } from "~types/schemas";
import { parseCurrency } from "~utils/parse";
import { Table } from "antd";

export function ProductsOthersTable({
  size = "small",
  defaultCurrentPage = 1,
}: IProductsOthersTableProps) {
  const { screens } = useAppConfig();

  const [currentPage, setCurrentPage] = useState(defaultCurrentPage);

  const [selectedProductToDelete, setSelectedProductToDelete] =
    useState<ICustomProductDTOSchema>();

  const [selectedProductToUpdate, setSelectedProductToUpdate] =
    useState<ICustomProductDTOSchema>();

  const {
    data: productData,
    isFetching: isLoadingProducts,
    error: productError,
  } = useFetchGetPaginatedOtherProducts({
    payload: {
      page: currentPage - 1,
    },
    dependencyArray: [currentPage],
    options: {
      enabled: currentPage > 0,
    },
  });

  const { mutate: deleteProduct, isLoading: isDeletingProduct } =
    useFetchDeleteProductTypeOther({
      options: {
        onSuccess: () => setSelectedProductToDelete(undefined),
      },
    });

  function onChangePagination(number: number, _totalElements?: number) {
    setCurrentPage(number);
  }

  function renderUnityPriceColumn(priceBaseCents?: string | number) {
    const numberValue = Number(priceBaseCents);

    if (isNaN(numberValue)) return "";

    const price = numberValue / 100;

    const formattedPrice = parseCurrency(price);

    return formattedPrice;
  }

  function renderActionColumn(record: ICustomProductDTOSchema) {
    return (
      <Menu.MoreDropdown
        items={[
          {
            icon: (
              <AiOutlineEdit
                fontSize={16}
                style={{ color: "var(--gold-500)" }}
              />
            ),
            text: "Editar item",
            onClick: () => setSelectedProductToUpdate(record),
            color: "var(--gold-500)",
          },
          {
            icon: (
              <AiOutlineDelete
                fontSize={16}
                style={{ color: "var(--red-500)" }}
              />
            ),
            text: "Apagar item",
            color: "var(--red-500)",
            onClick: () => setSelectedProductToDelete(record),
          },
        ]}
        position="bottomLeft"
      />
    );
  }

  const DATA_SOURCE = useMemo(
    () => productData?.content ?? [],
    [productData?.content],
  );

  const columns: IProductsOthersTableColumnType = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Valor unitário",
      dataIndex: "priceBaseCents",
      key: "priceBaseCents",
      align: "center" as const,
      render: renderUnityPriceColumn,
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 64,
      align: "center" as const,
      render: (_, record) => renderActionColumn(record),
    },
  ];

  const isLoading = isDeletingProduct || isLoadingProducts;

  return (
    <>
      <Table
        data-cy="products-others-table"
        rowKey="id"
        columns={columns}
        dataSource={DATA_SOURCE}
        pagination={createPaginationTableObject({
          number: currentPage,
          size: productData?.size,
          totalElements: productData?.totalElements,
          onChange: onChangePagination,
          disabled: false,
          itemType: "produtos",
          displayTotalInformation: screens.sm,
        })}
        loading={isLoading}
        style={{ width: "100%" }}
        scroll={{ x: 720 }}
        size={size}
        locale={{
          emptyText: (
            <Result.TableErrorOrEmpty
              errorMessage={productError?.message}
              emptyMessage="Nenhum produto encontrado."
            />
          ),
        }}
      />

      <Modal.ProductTypeOtherForm
        formProps={{
          initialValues: selectedProductToUpdate,
          onSuccess: () => setSelectedProductToUpdate(undefined),
        }}
        onClose={() => setSelectedProductToUpdate(undefined)}
        isOpen={!!selectedProductToUpdate}
      />

      <Modal.DefaultRemove
        title="Remover produto"
        isLoading={isDeletingProduct}
        itemName={selectedProductToDelete?.name}
        isOpen={!!selectedProductToDelete}
        onSubmit={() =>
          deleteProduct({
            id: selectedProductToDelete?.id ?? 0,
          })
        }
        suffix="Produto"
        onClose={() => setSelectedProductToDelete(undefined)}
      />
    </>
  );
}
