import { PiDotOutlineFill } from "react-icons/pi";

import { IDeleteCustomFieldDealModalProps } from "./interface";
import { StyledAlert } from "./styles";

import { Alert } from "~components/Alert";
import {
  useFetchDeleteDealCustomField,
  useFetchGetDealCustomFieldsById,
} from "~hooks/api";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { Modal, Skeleton } from "antd";

export function DeleteCustomFieldDealModal({
  customFieldId = 0,
  isOpen = false,
  onClose = () => null,
  title = "Apagar campo personalizado da proposta",
  maxWidth = 520,
  onSuccess,
  onError,
}: IDeleteCustomFieldDealModalProps) {
  const {
    data: customField,
    isLoading: isLoadingCustomField,
    error: errorCustomField,
    refetch: reloadCustomField,
  } = useFetchGetDealCustomFieldsById({
    dependencyArray: [customFieldId, isOpen],
    payload: {
      id: customFieldId,
    },
    options: {
      enabled: customFieldId > 0 && isOpen,
    },
  });

  const { mutate: deleteCustomField, isLoading: isDeletingCustomField } =
    useFetchDeleteDealCustomField({
      onSuccess: () => {
        if (typeof onSuccess === "function") {
          onSuccess();
        }

        onClose();
      },
      onError,
    });

  function handleDeleteCustomField() {
    deleteCustomField({
      id: customFieldId,
    });
  }

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title={title}
      style={{ maxWidth }}
      footer={null}
    >
      {errorCustomField && (
        <Alert.CardError
          title="Falha ao carregar dados"
          errorMessage={errorCustomField?.message}
          reloadFn={reloadCustomField}
          style={{ marginBottom: 12 }}
        />
      )}
      <div className="w-full flex flex-col align-middle justify-center gap-6 mt-6">
        {isLoadingCustomField || errorCustomField ? (
          <Skeleton.Input
            active={isLoadingCustomField || errorCustomField !== null}
            style={{ width: "100%", height: 100 }}
          />
        ) : (
          <StyledAlert
            type="error"
            description={
              <div className="flex flex-col gap-2">
                <SolarzTypography
                  variant="danger"
                  fontWeight="medium"
                  hierarchy="paragraph2"
                >
                  Tem certeza que deseja apagar esse campo personalizado?
                </SolarzTypography>

                <div className="w-full flex flex-row gap-1 align-middle text-red-600">
                  <PiDotOutlineFill size={24} />
                  <SolarzTypography
                    variant="danger"
                    hierarchy="small"
                    fontWeight="regular"
                  >
                    Se você apagar <strong>{customField?.label}</strong>
                    será removido permanentemente do negócio.
                  </SolarzTypography>
                </div>
              </div>
            }
          />
        )}
        <div className="w-full flex flex-row align-middle justify-end gap-2">
          <SolarzButton variant="secondary" onClick={onClose}>
            Cancelar
          </SolarzButton>
          <SolarzButton
            variant="danger"
            onClick={handleDeleteCustomField}
            isDisabled={isLoadingCustomField || errorCustomField !== null}
            isLoading={isDeletingCustomField}
          >
            Apagar
          </SolarzButton>
        </div>
      </div>
    </Modal>
  );
}
