import { useState, useMemo, useEffect } from "react";

import { IModulePowerAutoCompleteProps } from "./interface";

import { AutoCompleteSuffixIcon } from "~components/Others";
import { Result } from "~components/Result";
import { useFetchGetPaginatedProductModulePowers } from "~hooks/api";
import { createUuid } from "~utils/createUuid";
import { parsePotency } from "~utils/parse";
import { AutoComplete } from "antd";

// inputValue must be null not undefined, because if value is undefined, the antd maintain the form.item value by ref, if null antd clean the value
export function ModulePowerAutoComplete({
  allowClear = true,
  onChange,
  filters,
  value,
  autoCompleteInSingleOption = false,
  onClear,
  ...props
}: IModulePowerAutoCompleteProps) {
  const [inputValue, setInputValue] = useState<number | null>(null);

  const { data, isFetching, error, refetch } =
    useFetchGetPaginatedProductModulePowers({
      dependencyArray: [filters],
      payload: {
        page: 0,
        size: 100,
        manufacturer: filters?.manufacturer,
      },
      options: {
        onSuccess(modulePowers) {
          if (autoCompleteInSingleOption && modulePowers.length === 1) {
            const uniqueModulePower = modulePowers[0];

            setInputValue(uniqueModulePower);

            if (onChange) {
              onChange(modulePowers[0], {
                label: parsePotency(uniqueModulePower, 2) ?? "Indefinido",
                value: uniqueModulePower,
                record: uniqueModulePower,
              });
            }
          }
        },
      },
    });

  const OPTIONS = useMemo(() => {
    return (
      data?.map((power) => ({
        label: parsePotency(power, 2)?.replace("k", "") ?? "Indefinido",
        value: power.toString(), // when use auto-complete of antd, the value must be a string
        record: power,
        key: createUuid(),
      })) ?? []
    );
  }, [data]);

  const filteredOptions = OPTIONS.filter((opt) => {
    if (!inputValue) return OPTIONS;

    const parsedInput = inputValue.toString();
    const parsedLabel = opt.label.toLowerCase();

    return parsedLabel.includes(parsedInput);
  });

  function onSearch(value: string) {
    const parsedValue = Number(value);

    if (isNaN(parsedValue)) return;

    setInputValue(parsedValue);
  }

  useEffect(() => {
    if (value === "") {
      return setInputValue(null);
    }

    const parsedValue = Number(value);

    setInputValue(isNaN(parsedValue) ? null : parsedValue);
  }, [value]);

  return (
    <AutoComplete
      {...props}
      data-cy="module-power-auto-complete"
      value={inputValue}
      showSearch
      filterOption={false}
      onSearch={onSearch}
      options={filteredOptions}
      allowClear={allowClear}
      status={error ? "error" : ""}
      notFoundContent={
        <Result.SelectErrorOrEmpty
          errorMessage={error?.message}
          emptyMessage="Nenhum módulo encontrado"
          loading={isFetching}
          reloadFn={refetch}
        />
      }
      suffixIcon={
        <AutoCompleteSuffixIcon hasError={!!error} isLoading={isFetching} />
      }
      onClear={() => {
        setInputValue(null);
        if (typeof onClear === "function") {
          onClear();
        }
      }}
      onChange={(value: number | string | undefined | null, option) => {
        const parsedValue = value === "" ? undefined : Number(value);

        setInputValue(parsedValue ?? null);

        if (option instanceof Array || !onChange) return;
        onChange(parsedValue, option);
      }}
      style={{ width: "100%" }}
    />
  );
}
