import { useMemo } from "react";

import { ITaxTypeSelectProps, ITaxTypeOption } from "./interface";

import { Select } from "antd";

export function TaxTypeSelect({
  value,
  onChange,
  defaultValue,
  ...props
}: ITaxTypeSelectProps) {
  const OPTIONS: ITaxTypeOption[] = useMemo(
    () => [
      {
        label: "Horário verde" as const,
        value: "GREEN" as const,
      },
      {
        label: "Horário azul" as const,
        value: "BLUE" as const,
      },
    ],
    [],
  );

  return (
    <Select
      {...props}
      options={OPTIONS}
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
      data-cy="select-tax-type"
    />
  );
}
