import { useState } from "react";

import type { IDiverseKitsTableModalProps } from "./interface";
import styles from "./styles";

import { DiverseKitsTableDataType, Table } from "~components/Table";
import { useAppConfig } from "~hooks/useAppConfig";
import {
  Button as AntdButton,
  message,
  Modal as AntdModal,
  Tooltip,
  Flex,
} from "antd";

export function DiverseKitsTableModal({
  isOpen = false,
  onClose = () => null,
  title = "Detalhes",
  width = "90vw",
  onSubmit = () => undefined,
  isLoading = false,
  canSelect = true,
  isClosable = true,
  tableProps = {
    diverseKits: [],
    defaultSort: {
      by: "priceCents",
      order: "ascend",
    },
    disableSelect: false,
    onSubmit: () => null,
  },
  extraFooterOptions,
  styleTop = 48 /* 3rem */,
}: IDiverseKitsTableModalProps) {
  const { screens } = useAppConfig();
  const [selectedKit, setSelectedKit] = useState<DiverseKitsTableDataType>();

  function handleCloseModal() {
    onClose();
  }

  return (
    <AntdModal
      data-cy="diverse-kit-table-modal"
      open={isOpen}
      onCancel={handleCloseModal}
      title={title}
      width={width}
      closable={isClosable}
      footer={
        canSelect && (
          <Flex
            justify={screens.md ? "space-between" : "center"}
            align="center"
            style={{
              width: "100%",
              flexDirection: screens.md ? "row" : "column",
            }}
            gap={8}
          >
            <Flex align="center" gap={8} justify="start">
              {extraFooterOptions}
            </Flex>
            <Flex
              align="center"
              gap={8}
              justify={screens.md ? "end" : "center"}
              style={{
                marginRight: screens.lg ? "1rem" : 0,
                flexDirection: screens.md ? "row" : "column",
              }}
            >
              <Flex align="center" gap={8} justify="center">
                <AntdButton onClick={onClose} disabled={!isClosable}>
                  Cancelar
                </AntdButton>
                <Tooltip
                  title={selectedKit ? "" : "Necessário selecionar o kit"}
                >
                  <AntdButton
                    type="primary"
                    disabled={!selectedKit}
                    data-cy="select-kit-button"
                    onClick={() => {
                      if (selectedKit) {
                        onSubmit(selectedKit, true);
                      } else {
                        message.error(
                          "Client error: o kit não foi selecionado!",
                        );
                      }
                    }}
                    loading={isLoading}
                  >
                    Selecionar kit
                  </AntdButton>
                </Tooltip>
              </Flex>
            </Flex>
          </Flex>
        )
      }
      destroyOnClose
      styles={{
        body: {
          height: "calc(100vh - 280px)",
          overflowX: "auto",
        },
      }}
      style={{ top: styleTop, maxWidth: "1980px" }}
    >
      <style jsx>{styles}</style>
      <Table.DiverseKits
        {...tableProps}
        onSelectKit={setSelectedKit}
        disableSelect={!canSelect}
      />
    </AntdModal>
  );
}
