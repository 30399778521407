import type { IApiErrorResponse } from "~services/api/interface";

export enum ProposalStatusModalActionEnum {
  SET_AS_WON = "WON",
  SET_AS_LOST = "LOST",
  SET_AS_DELETED = "DELETED",
  SET_AS_OPEN = "OPEN",
}

export interface ISetProposalStatusModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  maxWidth?: number;
  customId?: number;
  pipedriveDealId?: number;
  dealId?: number;
  onSuccess?: (data: void) => void;
  onError?: (error: IApiErrorResponse) => void;
  action?: keyof typeof ProposalStatusModalActionEnum;
}
