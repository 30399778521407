import { useMemo } from "react";

import type { IDealCustomFieldsCardProps } from "./interface";

import { List } from "~components/List";
import { Modal } from "~components/Modal";
import { Edit } from "~estrela-ui/icons";
import { useFetchGetDealCustomFieldValueById } from "~hooks/api";
import { parseFunctions, useQueryParams } from "~hooks/useQueryParams";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import type { IDealCustomFieldValueResponseSchema } from "~types/schemas";
import { Card, Flex, Tooltip } from "antd";

const UPDATE_DEAL_CUSTOM_FIELDS_MODAL_PARAM_KEY = "editar-campos-customizados";

export function DealCustomFieldsCard({
  dealId = 0,
  isDealActive = true,
  style,
}: IDealCustomFieldsCardProps) {
  const { handleSaveInQueryParams, getParsedQueryParams } = useQueryParams();

  const {
    data: dealCustomFieldGroup = [],
    isFetching: isLoadingDealCustomFieldGroup,
    error: errorDealCustomFieldGroup,
    // refetch: reloadDealCustomFieldGroup, // Componente LIST already have reload fn
  } = useFetchGetDealCustomFieldValueById({
    payload: {
      id: dealId,
    },
    dependencyArray: [dealId],
    options: {
      enabled: dealId > 0,
    },
  });

  const dealCustomFields = useMemo(() => {
    const allDealCustomFields = dealCustomFieldGroup.reduce((fields, group) => {
      return [...fields, ...group.dealCustomFields];
    }, [] as IDealCustomFieldValueResponseSchema[]);

    return allDealCustomFields;
  }, [dealCustomFieldGroup]);

  const selectedDealId = getParsedQueryParams(
    UPDATE_DEAL_CUSTOM_FIELDS_MODAL_PARAM_KEY,
    parseFunctions.NUMBER,
  );

  function handleOpenUpdateDealCustomFieldsModal() {
    handleSaveInQueryParams({
      [UPDATE_DEAL_CUSTOM_FIELDS_MODAL_PARAM_KEY]: dealId,
    });
  }

  function handleCloseUpdateDealCustomFieldsModal() {
    handleSaveInQueryParams({
      [UPDATE_DEAL_CUSTOM_FIELDS_MODAL_PARAM_KEY]: "",
    });
  }

  const isEditButtonDisabled =
    isLoadingDealCustomFieldGroup ||
    dealCustomFields.length === 0 ||
    !isDealActive;

  return (
    <>
      <Card
        style={{
          width: "100%",
          maxWidth: "400px",
          ...style,
        }}
        data-cy="deal-custom-fields-card"
        styles={{
          body: {
            padding: 16,
          },
        }}
        title={
          <Flex justify="space-between" align="center">
            <SolarzTypography
              fontWeight="semiBold"
              hierarchy="paragraph1"
              variant="title"
            >
              Personalizados
            </SolarzTypography>

            <Tooltip
              title={
                dealCustomFields.length === 0
                  ? "A lista de itens está vazia"
                  : errorDealCustomFieldGroup
                    ? `Falha ao carregar dados: ${errorDealCustomFieldGroup.message}`
                    : ""
              }
            >
              <div>
                <SolarzButton
                  scale="tiny"
                  variant="ghost"
                  icon={
                    <Edit
                      style={{
                        color: isEditButtonDisabled
                          ? "var(--gray-300)"
                          : "var(--primary-500)",
                      }}
                    />
                  }
                  onClick={handleOpenUpdateDealCustomFieldsModal}
                  isDisabled={isEditButtonDisabled}
                  style={{
                    border: "1px solid var(--gray-300)",
                    width: 28,
                    height: 28,
                    padding: 0,
                  }}
                />
              </div>
            </Tooltip>
          </Flex>
        }
      >
        <List.DealCustomFields dealId={dealId} />
      </Card>

      <Modal.DealCustomFieldsForm
        isOpen={!!selectedDealId}
        onClose={handleCloseUpdateDealCustomFieldsModal}
        title="Editar campos customizados de negócio"
        formProps={{
          onSuccess: handleCloseUpdateDealCustomFieldsModal,
          dealId: selectedDealId ?? 0,
        }}
      />
    </>
  );
}
