import { useEffect, useState } from "react";

import type { IActivityEventTypeRadioGroupProps } from "./interface";
import { RadioButton, RadioGroup } from "./styles";

import { useFetchGetActivityTypes } from "~hooks/api";
import { parseFunctions, useQueryParams } from "~hooks/useQueryParams";
import { activityEventTypeEnum } from "~types/enum";
import { parseRemoveAccent } from "~utils/parse";
import {
  parseStringToActivityEventTypeEnum,
  translateActivityEventTypeEnum,
} from "~utils/translate";
import { Flex, Skeleton } from "antd";

export const TYPE_FILTER_QUERY_PARAM_KEY = "tipo";

export function ActivityEventTypeRadioGroup({
  filters,
  className,
  style,
}: IActivityEventTypeRadioGroupProps) {
  const { handleSaveInQueryParams, getParsedQueryParams } = useQueryParams();

  const defaultValue = parseStringToActivityEventTypeEnum(
    getParsedQueryParams(TYPE_FILTER_QUERY_PARAM_KEY, parseFunctions.STRING) ??
      "",
  );

  const [selectedValue, setSelectedValue] = useState(defaultValue ?? "ALL");

  const { data: activityTypes, isFetching: isLoadingActivityTypes } =
    useFetchGetActivityTypes({
      dependencyArray: [filters],
      payload: {
        begin: filters?.begin?.toISOString(),
        end: filters?.end.toISOString(),
        eventType: filters?.eventType,
        status: filters?.statusType,
        state: filters?.stateType,
        priority: filters?.priority,
        name: filters?.name,
        ownerId: filters?.ownerId,
      },
      options: {
        enabled: filters?.begin.isValid() && filters?.end.isValid(),
      },
    });

  const needDisplayQuantity = !!(
    filters?.begin.isValid() && filters?.end.isValid()
  );

  const totalQuantity = activityTypes?.reduce(
    (total, type) => type.quantity + total,
    0,
  );
  useEffect(() => {
    const updatedValue = parseStringToActivityEventTypeEnum(
      getParsedQueryParams(
        TYPE_FILTER_QUERY_PARAM_KEY,
        parseFunctions.STRING,
      ) ?? "",
    );
    setSelectedValue(updatedValue ?? "ALL");
  }, [getParsedQueryParams]);

  return (
    <RadioGroup
      value={selectedValue}
      onChange={(event) => {
        const translatedValue = translateActivityEventTypeEnum(
          event.target.value,
        );

        const parsedValue =
          parseRemoveAccent(translatedValue).toLocaleLowerCase();

        handleSaveInQueryParams({
          [TYPE_FILTER_QUERY_PARAM_KEY]: parsedValue,
        });
      }}
      style={style}
      className={className}
    >
      <RadioButton key="ALL" value="ALL">
        <Flex gap={4} align="center">
          Todas
          {needDisplayQuantity && isLoadingActivityTypes && (
            <Skeleton.Node
              active={isLoadingActivityTypes}
              style={{ height: 18, width: 20, borderRadius: 9999 }}
            >
              {" "}
            </Skeleton.Node>
          )}
          {needDisplayQuantity && !isLoadingActivityTypes && (
            <span>({totalQuantity})</span>
          )}
        </Flex>
      </RadioButton>

      {activityEventTypeEnum.map((eventType) => {
        const quantity =
          activityTypes?.find((type) => type.eventType === eventType)
            ?.quantity ?? 0;

        return (
          <RadioButton key={eventType} value={eventType}>
            <Flex gap={4} align="center">
              {translateActivityEventTypeEnum(eventType)}{" "}
              {needDisplayQuantity && isLoadingActivityTypes && (
                <Skeleton.Node
                  active={isLoadingActivityTypes}
                  style={{ height: 18, width: 20, borderRadius: 9999 }}
                >
                  {" "}
                </Skeleton.Node>
              )}
              {needDisplayQuantity && !isLoadingActivityTypes && (
                <span>({quantity})</span>
              )}
            </Flex>
          </RadioButton>
        );
      })}
    </RadioGroup>
  );
}
