import type { IDeleteDatasheetModalProps } from "./interface";

import { Alert } from "~components/Alert";
import { useFetchDeleteDatasheet, useFetchGetDatasheetById } from "~hooks/api";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { Col, Flex, Modal, Row, Skeleton, Tag } from "antd";

export function DeleteDatasheetModal({
  isOpen = false,
  onClose = () => null,
  title = "Apagar Datasheet",
  maxWidth = 512,
  datasheetId,
  onError,
  onSuccess,
}: IDeleteDatasheetModalProps) {
  const {
    data: datasheetData,
    isFetching: isLoadingDatasheet,
    error: datasheetError,
    refetch: reloadDatasheetData,
  } = useFetchGetDatasheetById({
    payload: {
      id: datasheetId,
    },
    dependencyArray: [datasheetId],
    options: {
      enabled: datasheetId > 0,
      retry: 1,
    },
  });

  const { mutate: deleteDatasheet, isLoading: isDeletingDatasheet } =
    useFetchDeleteDatasheet({
      options: {
        onSuccess: () => {
          if (typeof onSuccess === "function") {
            onSuccess();
          }

          onClose();
        },
        onError,
      },
    });

  function handleDeleteDatasheet() {
    deleteDatasheet({ id: datasheetId });
  }

  return (
    <Modal
      data-cy="datasheet-form-modal"
      open={isOpen}
      onCancel={onClose}
      title={title}
      width="90vw"
      style={{
        maxWidth,
      }}
      okText="Apagar"
      onOk={handleDeleteDatasheet}
      okButtonProps={{
        loading: isDeletingDatasheet,
      }}
    >
      <Flex vertical gap={16}>
        {datasheetError && (
          <Alert.CardError
            title="Falha ao carregar dados"
            errorMessage={datasheetError?.message}
            reloadFn={reloadDatasheetData}
            style={{ marginBottom: 12 }}
          />
        )}

        <Tag color="red" style={{ padding: "8px 16px" }}>
          Você tem certeza que deseje apagar o Datasheet?
        </Tag>

        <Row align="top" gutter={24} wrap={false}>
          <Col span={10}>
            <SolarzTypography
              fontWeight="medium"
              variant="title"
              hierarchy="paragraph2"
              style={{ textAlign: "right" }}
            >
              Nome
            </SolarzTypography>
          </Col>
          <Col span={14}>
            {isLoadingDatasheet || datasheetError ? (
              <Skeleton.Input active={isLoadingDatasheet} size="small" />
            ) : (
              <SolarzTypography
                fontWeight="regular"
                variant="disabled"
                hierarchy="small"
              >
                {datasheetData?.name}
              </SolarzTypography>
            )}
          </Col>
        </Row>

        <Row align="top" gutter={24} wrap={false}>
          <Col span={10}>
            <SolarzTypography
              fontWeight="medium"
              variant="title"
              hierarchy="paragraph2"
              style={{ textAlign: "right" }}
            >
              Fabricante
            </SolarzTypography>
          </Col>
          <Col span={14}>
            {isLoadingDatasheet || datasheetError ? (
              <Skeleton.Input active={isLoadingDatasheet} size="small" />
            ) : (
              <SolarzTypography
                fontWeight="regular"
                variant="disabled"
                hierarchy="small"
              >
                {datasheetData?.brand}
              </SolarzTypography>
            )}
          </Col>
        </Row>
      </Flex>
    </Modal>
  );
}
