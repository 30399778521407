import * as React from "react";

import type { IIconProps } from "../@types/globals";
import { cn } from "../lib/utils";

const Edit: React.FC<IIconProps> = ({ className, ...props }) => (
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    className={cn("pointer-events-none w-6 h-6 stroke-2", className)}
    {...props}
  >
    <title>Editar</title>
    <path d="M14.1241 4.87079L14.9582 4.0366C16.3404 2.65447 18.5813 2.65447 19.9634 4.0366C21.3455 5.41873 21.3455 7.65962 19.9634 9.04175L19.1292 9.87594M14.1241 4.87079C14.1241 4.87079 14.2283 6.64345 15.7924 8.20756C17.3565 9.77167 19.1292 9.87594 19.1292 9.87594M14.1241 4.87079L6.45494 12.5399C5.9355 13.0594 5.67577 13.3191 5.45241 13.6055C5.18892 13.9433 4.96302 14.3088 4.77871 14.6955C4.62246 15.0234 4.50631 15.3718 4.274 16.0687L3.28963 19.0219M19.1292 9.87594L11.4601 17.5451C10.9406 18.0645 10.6809 18.3242 10.3945 18.5476C10.0567 18.8111 9.69122 19.037 9.30448 19.2213C8.97663 19.3775 8.62817 19.4937 7.93126 19.726L4.97813 20.7104M4.97813 20.7104L4.25626 20.951C3.9133 21.0653 3.53519 20.9761 3.27957 20.7204C3.02394 20.4648 2.93469 20.0867 3.049 19.7437L3.28963 19.0219M4.97813 20.7104L3.28963 19.0219" />
  </svg>
);

Edit.displayName = "Edit";

export { Edit };
