import { useMemo } from "react";

import { IProposalStepOption, IProposalStepSelectProps } from "./interface";

import { Select } from "antd";

export function ProposalStepSelect({
  value,
  onChange,
  defaultValue,
  options,
  ...props
}: IProposalStepSelectProps) {
  const OPTIONS: IProposalStepOption[] = useMemo(
    () =>
      [
        {
          label: "Passo de dados",
          value: "DATA" as const,
          disabled: options?.disableInitial,
          hide: options?.hideInitial,
        },
        {
          label: "Passo do dimensionamento",
          value: "SIZING" as const,
          disabled: options?.disablePlant,
          hide: options?.hidePlant,
        },
        {
          label: "Passo de detalhamento",
          value: "DETAILS" as const,
          disabled: options?.disableKits,
          hide: options?.hideKits,
        },
        {
          label: "Passo da proposta",
          value: "PROPOSAL" as const,
          disabled: options?.disableProposal,
          hide: options?.hideProposal,
        },
        {
          label: "Passo pós-dimensionamento",
          value: "AFTER_SIZING" as const,
          disabled: options?.disableIntermediate,
          hide: options?.hideIntermediate,
        },
      ].filter((option) => !option.hide),
    [options],
  );

  return (
    <Select
      {...props}
      options={OPTIONS}
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
}
