import { useMemo } from "react";
import { BiErrorCircle } from "react-icons/bi";

import type { IBaseAddressSelectProps } from "./interface";

import { Result } from "~components/Result";
import { useFetchGetAddressesCompany } from "~hooks/api";
import { Button, Select } from "antd";

function parseAddressToString({
  street = "",
  number = "",
  district = "",
  zipCode = "",
  city = "",
  province = "",
}) {
  return `${street} ${number ? `, ${number}` : ""} ${
    district ? `- ${district}` : ""
  } ${zipCode ? `- ${zipCode}` : ""} ${city ? `- ${city}` : ""} ${
    province ? `- ${province}` : ""
  }`;
}

export function BaseAddressSelect({
  allowClear = true,
  onChange,
  ...props
}: IBaseAddressSelectProps) {
  const {
    data: addresses,
    isFetching,
    error,
    refetch,
  } = useFetchGetAddressesCompany({});

  const OPTIONS = useMemo(() => {
    return addresses
      ? addresses.map((address) => ({
          label: parseAddressToString({
            city: address.city?.name,
            district: address.district,
            number: address.number,
            province: address.province?.name,
            street: address.street,
            zipCode: address.zipCode,
          }),
          value: address.id,
          record: address,
        }))
      : [];
  }, [addresses]);

  return (
    <Select
      {...props}
      loading={isFetching}
      options={OPTIONS}
      allowClear={allowClear}
      status={error ? "error" : ""}
      suffixIcon={
        error ? (
          <Button htmlType="button" type="link">
            {/* using button to set primary color in links */}
            <BiErrorCircle size={18} />
          </Button>
        ) : undefined
      }
      notFoundContent={
        <Result.SelectErrorOrEmpty
          errorMessage={error?.message}
          emptyMessage="Nenhuma atividade encontrada"
          loading={isFetching}
          reloadFn={refetch}
        />
      }
      onChange={(value, option) => {
        if (option instanceof Array || !onChange) return;
        onChange(value, option);
      }}
    />
  );
}
