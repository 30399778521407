import { Spin } from "antd";
import type { SpinProps } from "antd/lib/spin";

interface LoadingProps extends SpinProps {}

export function Loading({ style, ...props }: LoadingProps) {
  return (
    <Spin
      data-cy="loading"
      size="large"
      delay={300}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
        ...style,
      }}
      {...props}
    />
  );
}
