import { useMemo } from "react";

import { KanbanHeader } from "../KanbanHeader";
import { KanbanTask } from "../KanbanTask";
import type { ISolarzKanbanColumnProps } from "./interface";

import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useAppConfig } from "~hooks/useAppConfig";
import { Flex } from "antd";

export function KanbanColumn<
  ColumnRecordType extends Record<string, any>,
  TaskRecordType extends Record<string, any>,
>({
  column,
  tasks,
  renderTask,
  renderHeader,
  renderFooter,
  disableColumnDragAndDrop = true,
  disableTaskDragAndDrop = false,
}: ISolarzKanbanColumnProps<ColumnRecordType, TaskRecordType>) {
  const { currentTheme } = useAppConfig();

  const tasksIds = useMemo(() => {
    return tasks.map((task) => task.customId);
  }, [tasks]);

  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: column.customId,
    data: {
      type: "Column",
      column,
    },
    disabled: disableColumnDragAndDrop,
  });

  const sharedStyle = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  return (
    <Flex
      ref={setNodeRef}
      vertical
      style={{
        ...sharedStyle,
        width: "100%",
        minWidth: 160,
        background:
          currentTheme === "dark" ? "var(--gray-900)" : "var(--gray-50)",
        border:
          currentTheme === "dark"
            ? "1px solid var(--gray-700)"
            : "1px solid var(--gray-300)",
        borderRadius: 8,
        opacity: isDragging ? 0.32 : 1,
      }}
      flex={0}
    >
      <Flex
        {...attributes}
        {...listeners}
        style={{
          width: "100%",
          padding: 12,
          background:
            currentTheme === "dark" ? "var(--gray-999)" : "var(--white)",
          borderRadius: "8px 8px 0 0",
          cursor: disableColumnDragAndDrop ? undefined : "move",
        }}
        vertical
        gap={4}
      >
        <KanbanHeader
          column={column}
          tasks={tasks}
          renderHeader={renderHeader}
        />
      </Flex>

      <Flex
        flex="1 1 0%"
        vertical
        style={{
          overflowY: "auto",
          overflowX: "hidden",
          padding: 8,
        }}
        gap={8}
      >
        <SortableContext items={tasksIds}>
          {tasks.map((task) => (
            <KanbanTask<TaskRecordType>
              key={task.customId}
              task={task}
              renderTask={renderTask}
              disableTaskDragAndDrop={disableTaskDragAndDrop}
            />
          ))}
        </SortableContext>
      </Flex>

      {typeof renderFooter === "function" && (
        <Flex style={{ padding: "0px 8px" }}>
          {renderFooter({ column, tasks })}
        </Flex>
      )}
    </Flex>
  );
}
