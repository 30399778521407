import { useState } from "react";
import {
  AiOutlineCopy,
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineLink,
  AiOutlineCheckCircle,
} from "react-icons/ai";

import type { IVariablePopoverProps } from "./interface";

import { useCopyToClipboard } from "~hooks/useCopyToClipboard";
import { useInterval } from "~hooks/useInterval";
import { Button, Popover, Tag, Typography, Flex } from "antd";

export function VariableActionMenuButton({
  onAssign = () => undefined,
  canAssign = true,
  onUpdate = () => undefined,
  canUpdate = true,
  onDelete = () => undefined,
  canDelete = true,
  title = "",
  value = "",
}: IVariablePopoverProps) {
  const { handleCopy } = useCopyToClipboard();

  const [isCopied, setIsCopied] = useState<boolean>(false);

  useInterval(
    () => {
      if (isCopied) {
        setIsCopied(false);
      }
    },
    isCopied ? 1500 : null,
  );

  const options = [
    {
      icon: isCopied ? (
        <AiOutlineCheckCircle size={18} color="var(--green-500)" />
      ) : (
        <AiOutlineCopy size={18} color="var(--geekBlue-500)" />
      ),
      title: isCopied ? "Copiado" : "Copiar",
      onClick: () =>
        handleCopy(value, {
          onSuccess: () => setIsCopied(true),
        }),
      color: isCopied ? "var(green-500)" : "var(--geekBlue-500)",
    },
    {
      icon: <AiOutlineEdit size={18} color="var(--gold-500)" />,
      title: "Editar",
      onClick: onUpdate,
      hide: !canUpdate,
      color: "var(--gold-500)",
    },
    {
      icon: <AiOutlineDelete size={18} color="var(--gold-500)" />,
      title: "Remover",
      onClick: onDelete,
      hide: !canDelete,
      color: "var(--red-500)",
    },
    {
      icon: <AiOutlineLink size={18} color="var(--magenta-500)" />,
      title: "Associar ao Pipedrive",
      onClick: onAssign,
      hide: !canAssign,
      color: "var(--magenta-500)",
    },
  ].filter((option) => !option.hide);

  return (
    <Popover
      data-cy="variable-action-menu-button"
      placement="topLeft"
      zIndex={100}
      trigger="click"
      content={
        <Flex vertical gap={8}>
          {options.map((option, index) => (
            <Button
              size="small"
              type="link"
              style={{ padding: 4, margin: 0 }}
              key={index}
              block
              onClick={option.onClick}
            >
              <Flex align="center" gap={8}>
                {option.icon}
                <Typography.Text style={{ color: option.color }}>
                  {option.title}
                </Typography.Text>
              </Flex>
            </Button>
          ))}
        </Flex>
      }
    >
      <Tag
        color="volcano"
        style={{
          padding: "0 0.5rem",
          margin: 0,
          width: "auto",
          fontSize: "0.75rem",
          cursor: "pointer",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {title}
      </Tag>
    </Popover>
  );
}
