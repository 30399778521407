import { GoDotFill } from "react-icons/go";

import { NotificationCardProps } from "./interface";

import { Avatar } from "~components/Avatar";
import { Tag } from "~components/Tag";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { MentionOriginEnum } from "~types/enum";
import { Flex } from "antd";

export function NotificationCard({ notification }: NotificationCardProps) {
  const translateNotificationMentionOrigin = (
    mentionOrigin: MentionOriginEnum,
  ) => {
    switch (mentionOrigin) {
      case "DEAL":
        return "no negócio";
      case "ACTIVITY":
        return "na atividade";
      case "ORGANIZATION":
        return "na organização";
      case "PERSON":
        return "na pessoa";
      default:
        return "na entidade";
    }
  };

  const notificationContent = `${notification?.userName} mencionou você ${translateNotificationMentionOrigin(notification.origin)} ${notification.originDescription}`;

  const notificationRead = notification?.status === "READ";

  const handleMountNotificationTimePast = () => {
    const currentDate = new Date();
    const notificationDate = new Date(notification?.createdAt);
    const diffTime = currentDate.getTime() - notificationDate.getTime();

    const pastMinutes = Math.floor(diffTime / (1000 * 60));
    const pastHours = pastMinutes / 60;
    const pastDays = pastHours / 24;

    if (pastDays >= 1) return `Há ${Math.round(pastDays)} dia(s) atrás`;
    if (pastHours >= 1) return `Há ${Math.round(pastHours)} hora(s) atrás`;
    if (pastMinutes >= 1)
      return `Há ${Math.round(pastMinutes)} minuto(s) atrás`;

    return "Agora mesmo";
  };

  const renderTitle = () => {
    const userName = notification?.userName ?? "";
    const mentionedOrigin = notification?.originDescription ?? "";

    const userKey = userName.split(" ").join("+");
    const mentionedUserKey = mentionedOrigin.split(" ").join("+");
    const titleWithMentions = notificationContent
      .replace(userName, userKey)
      .replace(mentionedOrigin, mentionedUserKey);

    return (
      <SolarzTypography
        style={{
          fontSize: 14,
        }}
      >
        {titleWithMentions.split(" ").map((word: string, index) => {
          const space = index == 0 ? "" : " ";

          if (userKey === word) {
            return <strong key={index}>{space + userName}</strong>;
          } else if (mentionedUserKey === word) {
            return (
              <Tag.UniqueValue
                key={index}
                style={{ border: 0, fontWeight: "600" }}
                color="default"
                title={mentionedOrigin}
              />
            );
          }

          return space + word;
        })}
      </SolarzTypography>
    );
  };

  return (
    <Flex align="start" gap={16} vertical={false}>
      <Avatar.CustomUser
        badgeProps={{
          icon: "MENTION",
          offset: ["-5%", "80%"],
        }}
        height={55}
        width={55}
        avatarUrl={notification?.userAvatarUrl}
        name={notification?.userName}
      />
      <Flex flex={1} justify="space-between">
        <Flex vertical style={{ maxWidth: 360 }}>
          {renderTitle()}
          <SolarzTypography style={{ fontSize: 12 }}>
            &quot;{notification?.text}&quot;
          </SolarzTypography>
          <SolarzTypography
            style={{ fontSize: 12, color: "var(--primary-500)" }}
          >
            {handleMountNotificationTimePast()}
          </SolarzTypography>
        </Flex>

        <GoDotFill
          style={{ alignSelf: "center" }}
          size={24}
          color={notificationRead ? "var(--gray-200)" : "var(--primary-500)"}
        />
      </Flex>
    </Flex>
  );
}
