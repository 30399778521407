import { useMemo, useState } from "react";

import {
  IProposalViewsListDataType,
  IProposalViewsListProps,
} from "./interface";

import { createPaginationTableObject } from "~components/Pagination";
import { Result } from "~components/Result";
import { useFetchGetPaginatedTracking } from "~hooks/api";
import { useSession } from "~hooks/useSession";
import { List } from "antd";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

export function ProposalViewsList({
  proposalId,
  ...props
}: IProposalViewsListProps) {
  const { token } = useSession();

  const [currentPage, setCurrentPage] = useState(1);

  const { data, isLoading, error } = useFetchGetPaginatedTracking({
    dependencyArray: [proposalId, currentPage, token],
    payload: {
      trackingId: proposalId ?? 0,
      size: 5,
      page: currentPage - 1,
    },
    options: {
      enabled: Number(proposalId) > 0,
    },
  });

  const dataSource: IProposalViewsListDataType[] = useMemo(() => {
    return (
      data?.content.map((item) => ({
        id: item.id as number,
        viewedAt: item.viewedAt,
        lastHeartbeatAt: item.lastHeartbeatAt,
      })) ?? []
    );
  }, [data]);

  function getTimeEllaped(track: IProposalViewsListDataType) {
    const diference = dayjs.duration(
      dayjs(track.lastHeartbeatAt).diff(track.viewedAt),
    );

    if (diference.asSeconds() <= 60) {
      return `${Math.floor(diference.asSeconds())} segundos`;
    }

    if (diference.asMinutes() <= 60) {
      return `${Math.floor(diference.asMinutes())} minutos`;
    }

    if (!isNaN(diference.asHours())) {
      return `${Math.floor(diference.asHours())} horas`;
    }

    return "";
  }

  return (
    <List
      data-cy="proposal-views-list"
      {...props}
      itemLayout="horizontal"
      dataSource={dataSource}
      loading={isLoading}
      rowKey="id"
      locale={{
        emptyText: (
          <Result.TableErrorOrEmpty
            errorMessage={error?.message}
            emptyMessage="Nenhum acesso encontrado."
          />
        ),
      }}
      pagination={createPaginationTableObject({
        number: currentPage,
        size: data?.size,
        totalElements: data?.totalElements,
        onChange: (page) => setCurrentPage(page),
        itemType: "registros",
      })}
      renderItem={(track) => (
        <List.Item style={{ width: "100%" }}>
          <List.Item.Meta
            title={dayjs(track.viewedAt).format("DD/MM/YYYY HH:mm:ss")}
            description={getTimeEllaped(track)}
          />
        </List.Item>
      )}
    />
  );
}
