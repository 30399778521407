import { useMemo } from "react";
import { GoPencil } from "react-icons/go";

import { useRouter } from "next/router";

import {
  PersonsTableDataType,
  PersonsTableQueryParamKeysEnum,
  type IPersonsTableProps,
  type PersonsTableFiltersType,
  type PersonsTableQueryParamsKeysType,
} from "./interface";
import {
  DeleteButton,
  EditButton,
  OrganizationIcon,
  TableWrapper,
} from "./styles";

import { Modal } from "~components/Modal";
import { Delete, People } from "~estrela-ui/icons";
import { useFetchGetPaginatedPerson } from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { useQueryParams, parseFunctions } from "~hooks/useQueryParams";
import { useSession } from "~hooks/useSession";
import { SolarzAnchor } from "~solarzui/SolarzAnchor";
import { SolarzTable } from "~solarzui/SolarzTable";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { formatTelephoneWithDdd } from "~utils/format";
import { Flex } from "antd";

const UPDATE_MODAL_QUERY_KEY = "update-person";
const DELETE_MODAL_QUERY_KEY = "delete-person";

export function PersonsTable(props: IPersonsTableProps) {
  const router = useRouter();
  const { screens } = useAppConfig();
  const { user } = useSession();
  const { getParsedQueryParams, handleSaveInQueryParams } = useQueryParams();

  const QUERY_PARAMS_KEYS: PersonsTableQueryParamsKeysType = useMemo(
    () => ({
      ...PersonsTableQueryParamKeysEnum,
      ...props.queryParamKeys,
    }),
    [props.queryParamKeys],
  );

  const filters: PersonsTableFiltersType = useMemo(
    () => ({
      currentPage:
        getParsedQueryParams(
          QUERY_PARAMS_KEYS.CURRENT_PAGE,
          parseFunctions.NUMBER,
        ) ?? 1,
      query:
        decodeURIComponent(
          getParsedQueryParams(
            QUERY_PARAMS_KEYS.QUERY,
            parseFunctions.STRING,
          ) ?? "",
        ) ?? undefined,
      userId:
        getParsedQueryParams(
          QUERY_PARAMS_KEYS.USER_ID,
          parseFunctions.NUMBER,
        ) ?? undefined,
    }),
    [getParsedQueryParams, QUERY_PARAMS_KEYS],
  );

  const selectedPersonIdToUpdate = getParsedQueryParams(
    UPDATE_MODAL_QUERY_KEY,
    parseFunctions.NUMBER,
  );

  const selectedPersonIdToDelete = getParsedQueryParams(
    DELETE_MODAL_QUERY_KEY,
    parseFunctions.NUMBER,
  );

  function handleOpenUpdatePersonModal(personId: number) {
    handleSaveInQueryParams({ [UPDATE_MODAL_QUERY_KEY]: personId });
  }

  function handleCloseUpdatePersonModal() {
    handleSaveInQueryParams({ [UPDATE_MODAL_QUERY_KEY]: "" });
  }

  function handleOpenDeletePersonModal(personId: number) {
    handleSaveInQueryParams({ [DELETE_MODAL_QUERY_KEY]: personId });
  }

  function handleCloseDeletePersonModal() {
    handleSaveInQueryParams({ [DELETE_MODAL_QUERY_KEY]: "" });
  }

  const {
    data: personsData,
    isFetching: isLoadingPersons,
    error: personsError,
    refetch: reloadPersons,
  } = useFetchGetPaginatedPerson({
    dependencyArray: [filters],
    options: {
      retry: 1,
      enabled: filters.currentPage > 0,
    },
    payload: {
      page: filters.currentPage - 1,
      size: 10, // STATIC,
      query: filters.query ?? undefined,
      userId: filters.userId ?? undefined,
    },
  });

  return (
    <TableWrapper>
      <Flex vertical>
        <SolarzTable<PersonsTableDataType>
          data-cy="persons-table"
          columns={[
            {
              title: "Nome",
              dataIndex: "name",
              key: "name",
              render: (_, record) => {
                if (
                  (screens.desktop || screens.tablet) &&
                  !user?.pipedriveIntegration
                ) {
                  return (
                    <SolarzAnchor
                      isNextLink
                      href={`/clientes/pessoa/${record.id}`}
                    >
                      {record.name}
                    </SolarzAnchor>
                  );
                }

                return (
                  <Flex align="center" gap={8} flex={1}>
                    <OrganizationIcon>
                      <People />
                    </OrganizationIcon>

                    <Flex vertical>
                      <SolarzTypography variant="title" hierarchy="paragraph2">
                        {record.name}
                      </SolarzTypography>
                      <SolarzTypography
                        variant="subTitle"
                        hierarchy="paragraph2"
                        style={{
                          opacity: 0.6,
                        }}
                      >
                        {!screens.desktop
                          ? record.organization
                            ? record.organization.name
                            : "-"
                          : null}
                      </SolarzTypography>
                    </Flex>
                  </Flex>
                );
              },
              width: screens.desktop ? undefined : 246,
              onCell:
                screens.mobile && !user?.pipedriveIntegration
                  ? (record) => {
                      return {
                        onClick: () =>
                          router.push(`/clientes/pessoa/${record.id}`),
                      };
                    }
                  : undefined,
            },
            {
              title: "Organização",
              dataIndex: "organization",
              key: "organization",
              render: (_, { organization }) => (
                <div>
                  {organization ? (
                    <SolarzAnchor
                      isNextLink
                      href={`/clientes/empresa/${organization.id}`}
                    >
                      {organization.name}
                    </SolarzAnchor>
                  ) : (
                    "-"
                  )}
                </div>
              ),
              width: screens.desktop ? undefined : 246,
              hidden: screens.mobile,
            },
            {
              title: "Email",
              dataIndex: "email",
              key: "email",
              render: (_, record) => record.email,
              hidden: !screens.desktop,
              width: 246,
            },
            // {
            //   title: "Endereço",
            //   dataIndex: "address",
            //   key: "address",
            //   render: (_, { address }) =>
            //     address && address !== null
            //       ? address?.city?.name + ", " + address?.province?.name
            //       : "Atribua um endereço a está unidade",
            //   hidden: !screens.desktop,
            // },
            {
              title: "Telefone",
              dataIndex: "phone",
              key: "phone",
              render: (_, { phone }) => phone && formatTelephoneWithDdd(phone),
              hidden: !screens.desktop,
              width: 246,
            },
            {
              width: 80,
              render: (_, record) => {
                return (
                  <Flex align="center" gap={12}>
                    <EditButton
                      icon={<GoPencil />}
                      onClick={() => {
                        handleOpenUpdatePersonModal(record.id);
                      }}
                      scale="tiny"
                      variant="link"
                    />
                    <DeleteButton
                      icon={<Delete />}
                      onClick={() => {
                        handleOpenDeletePersonModal(record.id);
                      }}
                      scale="tiny"
                      variant="link"
                    />
                  </Flex>
                );
              },
              hidden: !screens.desktop,
            },
          ]}
          rows={personsData?.content ?? []}
          isLoading={isLoadingPersons}
          pagination={{
            currentItemCount: personsData?.size ?? 0,
            currentPage: filters.currentPage || 1,
            itemLabel: "pessoas",
            totalItemCount: personsData?.totalElements ?? 0,
            onChange: (currentPage) => {
              handleSaveInQueryParams({
                [QUERY_PARAMS_KEYS.CURRENT_PAGE]: currentPage,
              });
            },
          }}
          scroll={
            screens.mobile
              ? {
                  x: undefined,
                  y: "calc(100vh - 326px)",
                }
              : {
                  x: undefined,
                }
          }
          emptyMessage="Sem pessoas encontradas"
          errorMessage={personsError?.message}
          reloadFn={reloadPersons}
          rowKey="id"
          showHeader={!screens.mobile}
          rowClassName={screens.desktop ? undefined : "cursor-pointer"}
        />
      </Flex>

      <Modal.PersonForm
        isOpen={!!selectedPersonIdToUpdate}
        title="Atualizar pessoa"
        onClose={handleCloseUpdatePersonModal}
        formProps={{
          onSuccess: handleCloseUpdatePersonModal,
          personId: selectedPersonIdToUpdate ?? undefined,
        }}
      />

      <Modal.DeletePerson
        personId={selectedPersonIdToDelete ?? 0}
        isOpen={Number(selectedPersonIdToDelete) > 0}
        onClose={handleCloseDeletePersonModal}
      />
    </TableWrapper>
  );
}
