import { z } from "zod";

export const FetchUpdateProposalDealPayloadSchema = z.object({
  customId: z.number().int().gt(0),
  pipelineId: z.number().int().gt(0),
  pipedriveDealId: z.number().nullish(),
});

export interface IFetchUpdateProposalDealPayloadSchema
  extends z.infer<typeof FetchUpdateProposalDealPayloadSchema> {}
