import { useState } from "react";

import type { ICreatePerformanceProfileButtonProps } from "./interface";

import { Modal } from "~components/Modal";
import { Button } from "antd";

export function CreatePerformanceProfileButton({
  children,
  ...props
}: ICreatePerformanceProfileButtonProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button
        {...props}
        data-cy="create-performance-profile-button"
        onClick={() => setIsModalOpen(true)}
        type="primary"
        htmlType="button"
      >
        {children ?? "Criar perfil de performance"}
      </Button>

      <Modal.PerformanceProfileForm
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        formProps={{
          onSuccess: () => setIsModalOpen(false),
        }}
      />
    </>
  );
}
