import { CgAddR } from "react-icons/cg";
import { useMutation } from "react-query";

import { useRouter } from "next/router";

import type { ICreateProposalButtonProps } from "./interface";

import { fetchCreateProject } from "~api/projects/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { SolarzButton } from "~solarzui/SolarzButton";
import { message } from "antd";

export function CreateProposalButton({
  createWithCompetition,
  ...props
}: ICreateProposalButtonProps) {
  const router = useRouter();
  const { screens } = useAppConfig();

  const useCreateProposal = useMutation(
    "/projects/post",
    async () => {
      const response = await fetchCreateProject({});

      const id = response.data.id;

      if (!id) throw new Error("Missing ID");

      return response.data.id;
    },
    {
      onSuccess: (id) => {
        router.push(`/proposta/${id}?new=true`);
      },
      onError: (error: any) => {
        message.error(error?.message);
      },
    },
  );

  function handleCreateProposal() {
    createWithCompetition
      ? useCreateProposal.mutate()
      : router.push("/proposta", undefined, { shallow: true });
  }

  const isCreatingProposal = useCreateProposal.isLoading;

  return (
    <SolarzButton
      data-cy="create-proposal-button"
      scale={screens.mobile ? "medium" : "large"}
      icon={screens.desktop ? <CgAddR /> : undefined}
      isLoading={isCreatingProposal}
      onClick={handleCreateProposal}
      htmlType="button"
      {...props}
    >
      {screens.mobile ? "Criar" : "Criar proposta"}
    </SolarzButton>
  );
}
