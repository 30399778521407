import styled from "@emotion/styled";
import { Image, Space } from "antd";

export const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  border-radius: 4px;
`;

// deve adicionar um background pra destacar os icones dentro
export const StyledSpace = styled(Space)`
  background-color: var(--gray-100);
  font-size: 20px;
  color: var(--gray-600);
  border-radius: 4px;
  padding: 5px;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;

  html.dark & {
    background-color: var(--gray-900);
    color: var(--gray-300);
    border: 1px solid #333;
  }
`;
