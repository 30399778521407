import styled from "@emotion/styled";
import { SolarzAnchor } from "~solarzui/SolarzAnchor";
import { Flex } from "antd";

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;

  table td.ant-table-cell {
    padding: 4px;
  }
`;

export const PipelineTabControl = styled(Flex)`
  position: absolute;
  width: 100%;
  bottom: 0px;
  padding: 1rem 1rem 0 1rem;
  height: 120px;
  border-top: 1px solid var(--gray-300);
  background-color: var(--background-layout);

  html.dark & {
    background-color: var(--gray-900);
  }
`;

export const TableContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding-bottom: 120px;
`;

export const CalendarIcon = styled.div`
  width: 24px;
  height: 24px;
  border: 1px solid var(--gray-500);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: var(--gray-500);
    height: 16px !important;
    width: 16px !important;
    line-height: 0;
    font-size: 16px;
    stroke-width: 2px;
  }

  &.is-danger {
    border-color: var(--red-500) !important;
    color: var(--red-500) !important;
  }
`;

export const Deal = styled(SolarzAnchor)`
  width: 100%;
  min-height: 98px;
  text-decoration: none !important;
`;
