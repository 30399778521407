import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { MdDragHandle } from "react-icons/md";

import type {
  IReasonForLossTableDataType,
  IReasonForLossTableProps,
} from "./interface";

import { Menu } from "~components/Menu";
import { Modal } from "~components/Modal";
import {
  useFetchGetAllReasonForLoss,
  useFetchUpdateReasonForLossPosition,
} from "~hooks/api";
import { parseFunctions, useQueryParams } from "~hooks/useQueryParams";
import { SolarzTable } from "~solarzui/SolarzTable";
import { SolarzTypography } from "~solarzui/SolarzTypography";

const DEFAULT_UPDATE_MODAL_KEY = "alterar-motivo-de-perda";
const DEFAULT_DELETE_MODAL_KEY = "deletar-motivo-de-perda";

export function ReasonForLossTable({
  customQueryKeys,
  ...props
}: IReasonForLossTableProps) {
  // START - QUERY KEYS
  const UPDATE_MODAL_KEY =
    customQueryKeys?.updateModal ?? DEFAULT_UPDATE_MODAL_KEY;

  const DELETE_MODAL_KEY =
    customQueryKeys?.deleteModal ?? DEFAULT_DELETE_MODAL_KEY;
  // END - QUERY KEYS

  const { handleSaveInQueryParams, getParsedQueryParams } = useQueryParams();

  const reasonForLossIdToUpdate =
    getParsedQueryParams(UPDATE_MODAL_KEY, parseFunctions.NUMBER) ?? 0;
  const reasonForLossIdToDelete =
    getParsedQueryParams(DELETE_MODAL_KEY, parseFunctions.NUMBER) ?? 0;

  const {
    data: reasonsForLoss = [],
    error: errorReasonsForLoss,
    isFetching: isLoadingReasonsForLoss,
    refetch: reloadReasonsForLoss,
  } = useFetchGetAllReasonForLoss({});

  const {
    mutate: handleUpdateReasonForLossPosition,
    isLoading: isUpdatingReasonForLossPosition,
  } = useFetchUpdateReasonForLossPosition({});

  function handleOpenUpdateModal(reasonForLossId: number) {
    handleSaveInQueryParams({
      [UPDATE_MODAL_KEY]: reasonForLossId,
    });
  }

  function handleCloseUpdateModal() {
    handleSaveInQueryParams({
      [UPDATE_MODAL_KEY]: null,
    });
  }

  function handleOpenDeleteModal(reasonForLossId: number) {
    handleSaveInQueryParams({
      [DELETE_MODAL_KEY]: reasonForLossId,
    });
  }

  function handleCloseDeleteModal() {
    handleSaveInQueryParams({
      [DELETE_MODAL_KEY]: null,
    });
  }

  return (
    <>
      <SolarzTable<IReasonForLossTableDataType>
        {...props}
        data-cy="proposal-custom-fields-table"
        draggable
        rowKey="id"
        rows={reasonsForLoss}
        columns={[
          {
            render: () => {
              return <MdDragHandle size={24} />;
            },
            width: 48,
            align: "center",
          },
          {
            title: "Motivo de perda",
            dataIndex: "reason",
            render: (_, { reason }) => {
              return <SolarzTypography>{reason}</SolarzTypography>;
            },
          },
          {
            width: 64,
            align: "center",
            render: (_, { id }) => {
              return (
                <Menu.MoreDropdown
                  items={[
                    {
                      icon: (
                        <AiOutlineEdit
                          fontSize={18}
                          style={{ color: "var(--gold-500)" }}
                        />
                      ),
                      text: "Alterar",
                      color: "var(--gold-500)",
                      onClick: () => handleOpenUpdateModal(id),
                    },
                    {
                      icon: (
                        <AiOutlineDelete
                          fontSize={16}
                          style={{ color: "var(--red-500)" }}
                        />
                      ),
                      text: "Remover",
                      color: "var(--red-500)",
                      onClick: () => handleOpenDeleteModal(id),
                    },
                  ]}
                  position="bottomLeft"
                />
              );
            },
          },
        ]}
        scroll={{ x: 512 }}
        isLoading={isLoadingReasonsForLoss || isUpdatingReasonForLossPosition}
        emptyMessage="Nenhum motivo de perda encontrado."
        errorMessage={errorReasonsForLoss?.message}
        reloadFn={reloadReasonsForLoss}
        onDragEnd={(rowChanged, updatedRows) => {
          const newPosition = updatedRows.findIndex(
            (row) => row.id === rowChanged.id,
          );

          handleUpdateReasonForLossPosition({
            id: rowChanged.id,
            position: newPosition + 1,
          });
        }}
      />

      <Modal.ReasonForLossForm
        isOpen={reasonForLossIdToUpdate > 0}
        onClose={handleCloseUpdateModal}
        title="Alterar motivo de perda"
        reasonForLossId={reasonForLossIdToUpdate}
        formProps={{
          onSuccess: () => {
            handleCloseUpdateModal();
          },
        }}
      />

      <Modal.DeleteReasonForLoss
        reasonForLossId={reasonForLossIdToDelete}
        isOpen={reasonForLossIdToDelete > 0}
        onClose={handleCloseDeleteModal}
        onSuccess={() => {
          handleCloseDeleteModal();
        }}
      />
    </>
  );
}
