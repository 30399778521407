import { useEffect, useState } from "react";

import type {
  IUserGroupFormProps,
  IUserGroupFormValues,
  CheckboxOption,
  CheckboxValueType,
} from "./interface";

import { Loading } from "~components/Loading";
import {
  useFetchCreateUserGroup,
  useFetchGetAllUsers,
  useFetchUpdateUserGroup,
} from "~hooks/api";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzFormItem } from "~solarzui/SolarzFormItem";
import { SolarzInput } from "~solarzui/SolarzInput";
import { IUserAccountDTOSchema } from "~types/schemas";
import { Checkbox, Empty, Form } from "antd";

export function UserGroupForm({
  initialValues,
  onCancel = () => undefined,
  onError = () => undefined,
  onSuccess = () => undefined,
}: IUserGroupFormProps) {
  const [form] = Form.useForm<IUserGroupFormValues>();

  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);

  const [options, setOptions] = useState<CheckboxOption[]>([]);

  const onChangeCheckedList = (list: CheckboxValueType[]) => {
    setCheckedList(list);
  };

  const { isLoading: isLoadingOptions } = useFetchGetAllUsers({
    options: {
      onSuccess(data) {
        if (data.length === 0 || !data) return setOptions([]);

        const parsedData = data.map((user: IUserAccountDTOSchema) => ({
          label: user.email || user.name,
          value: user.id as number,
          record: user,
        }));

        setOptions(parsedData);
      },
      onError: (error) => {
        onError(error?.message);
      },
    },
  });

  const { mutate: createUserGroup, isLoading: isCreatingUserGroup } =
    useFetchCreateUserGroup({
      options: {
        onSuccess,
      },
    });

  const { mutate: updateUserGroup, isLoading: isUpdatingUserGroup } =
    useFetchUpdateUserGroup({
      options: {
        onSuccess,
      },
    });

  function handleSubmit(formValues: IUserGroupFormValues) {
    if (initialValues?.id) {
      const payload = {
        userGroupId: initialValues.id,
        ...formValues,
        users: checkedList.map((item) => Number(item)),
      };

      updateUserGroup(payload);
    } else {
      const payload = {
        ...formValues,
        users: checkedList.map((item) => Number(item)),
      };

      createUserGroup(payload);
    }
  }

  useEffect(() => {
    if (initialValues) {
      setCheckedList(initialValues.users || []);
    }
  }, [initialValues, initialValues?.users]);

  const isLoading = isCreatingUserGroup || isUpdatingUserGroup;

  return (
    <Form
      data-cy="user-group-form"
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmit}
      layout="vertical"
      style={{ width: "100%" }}
    >
      <SolarzFormItem
        name="name"
        label="Nome"
        required
        rules={[
          {
            required: true,
            message: "Nome é obrigatório",
          },
        ]}
      >
        <SolarzInput placeholder="Ex.: José da Silva" style={{ height: 48 }} />
      </SolarzFormItem>

      <SolarzFormItem label="Selecione os usuários:">
        {isLoadingOptions ? (
          <Loading style={{ height: "100%" }} />
        ) : (
          <>
            <Checkbox.Group
              style={{
                flexDirection: "column",
                flexWrap: "wrap",
              }}
              className="usersGroupCheckboxes"
              options={options}
              value={checkedList}
              onChange={onChangeCheckedList}
            />
            {options.length === 0 && (
              <div className="flex justify-center items-center">
                <Empty description="Não há usuários disponíveis" />
              </div>
            )}
          </>
        )}
      </SolarzFormItem>

      <div className="flex justify-end gap-3">
        <SolarzButton variant="secondary" onClick={onCancel}>
          Cancelar
        </SolarzButton>
        <SolarzButton variant="primary" htmlType="submit" isLoading={isLoading}>
          Salvar
        </SolarzButton>
      </div>
    </Form>
  );
}
