import NextImage from "next/image";
import router from "next/router";

import type { IFinancialCardProps } from "./interface";

import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { Card, Typography, Flex, Divider } from "antd";

function getPropsByFinancial({ financial, description }: IFinancialCardProps) {
  const IMAGE_HEIGHT = 80;

  switch (financial) {
    case "EOS":
      return {
        title: "EOS Financiamento Solar",
        subtitle: description,
        src: "/images/financeiras/logo-eos-solar.svg",
        alt: "Logotipo da Eos",
        width: 306,
        height: IMAGE_HEIGHT,
        content: (
          <Flex align="center" gap={12}>
            <SolarzButton
              onClick={() =>
                router.push(`/configuracoes-de-propostas/financiamento/EOS`)
              }
            >
              Gerenciar
            </SolarzButton>
          </Flex>
        ),
      };

    default:
      return {
        title: "Desconhecido",
        subtitle: "O sistema não reconhece esse integrador",
        src: "/images/financeiras/unknown-financiamento.svg",
        alt: "Integrador desconhecido",
        width: 100,
        height: 80,
        content: <></>,
      };
  }
}

export function FinancialCard(props: IFinancialCardProps) {
  const { content, title, subtitle, ...logoProps } = getPropsByFinancial(props);

  return (
    <Card
      data-cy="financial-card"
      style={{
        maxWidth: 306,
        minWidth: 280,
        minHeight: 249,
      }}
    >
      <Flex align="center" justify="center" vertical gap={16}>
        <NextImage {...logoProps} priority />
        <SolarzTypography
          style={{ margin: 0 }}
          variant="subTitle"
          fontWeight="semiBold"
        >
          {title}
        </SolarzTypography>

        <Typography.Text style={{ textAlign: "center" }}>
          {subtitle}
        </Typography.Text>
        <Divider />
        {content}
      </Flex>
    </Card>
  );
}
