import { useMemo } from "react";

import {
  IKitItemCategorySelectProps,
  IKitItemCategorySelectOption,
} from "./interface";

import { Select } from "antd";

export function KitItemCategorySelect({
  value,
  onChange = () => null,
  defaultValue,
  options = {
    disable: [],
  },
  ...props
}: IKitItemCategorySelectProps) {
  const OPTIONS: IKitItemCategorySelectOption[] = useMemo(
    () => [
      {
        label: "Cabo",
        value: "WIRING",
        disabled: !!options.disable.find((option) => option === "WIRING"),
      },
      {
        label: "Disjuntor",
        value: "CA_PROTECTION",
        disabled: !!options.disable.find(
          (option) => option === "CA_PROTECTION",
        ),
      },
      {
        label: "Estrutura",
        value: "STRUCTURE",
        disabled: !!options.disable.find((option) => option === "STRUCTURE"),
      },
      {
        label: "Garantia Estendida",
        value: "EXTENDED_WARRANTY",
        disabled: !!options.disable.find(
          (option) => option === "EXTENDED_WARRANTY",
        ),
      },
      {
        label: "Inversor",
        value: "INVERTER",
        disabled: !!options.disable.find((option) => option === "INVERTER"),
      },
      {
        label: "Módulo",
        value: "MODULE",
        disabled: !!options.disable.find((option) => option === "MODULE"),
      },
      {
        label: "Outro",
        value: "OTHER",
        disabled: !!options.disable.find((option) => option === "OTHER"),
      },
      {
        label: "Transformador",
        value: "TRANSFORMER",
        disabled: !!options.disable.find((option) => option === "TRANSFORMER"),
      },
    ],
    [options],
  );

  return (
    <Select
      {...props}
      options={OPTIONS}
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
      placeholder="Selecione..."
    />
  );
}
