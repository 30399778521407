import { ActivityTableDataType } from "./interface";

import { useFetchToggleStatusActivity } from "~hooks/api";
import { Checkbox, Spin } from "antd";

export interface ICheckActivityStatusProps {
  record: ActivityTableDataType;
}

export function CheckActivityStatus({ record }: ICheckActivityStatusProps) {
  const {
    mutate: handleToggleStatusActivity,
    isLoading: isTogglingStatusActivity,
  } = useFetchToggleStatusActivity({
    customKey: `api/activities/${record.id}/toggleStatus/patch`,
  });

  function handleClickActivityStatus() {
    handleToggleStatusActivity({ id: record.id });
  }

  return (
    <Spin spinning={isTogglingStatusActivity}>
      <Checkbox
        checked={record.status === "DONE"}
        onClick={handleClickActivityStatus}
      />
    </Spin>
  );
}
