import { IPersonDTOSchema } from "~types/schemas";

export type PersonsTableDataType = IPersonDTOSchema;

export type PersonsTableFiltersType = {
  currentPage: number;
  userId?: number;
  query?: string;
};

export enum PersonsTableQueryParamKeysEnum {
  CURRENT_PAGE = "page",
  QUERY = "query",
  USER_ID = "user-id",
}

export type PersonsTableQueryParamsKeysType = Record<
  keyof typeof PersonsTableQueryParamKeysEnum,
  string
>;

export interface IPersonsTableProps {
  queryParamKeys?: Partial<PersonsTableQueryParamsKeysType>;
}
