import { useState } from "react";

import type {
  IOrganizationDealsListFilterType,
  IOrganizationDealsListItemType,
  IOrganizationDealsListProps,
} from "./interface";
import { OrganizationDealListSkeletonLoading } from "./OrganizationDealListSkeletonLoading";
import { DealNextStepButton, StyledCard } from "./styles";

import { Result } from "~components/Result";
import {
  useFetchGetPaginatedOrganizationDealsByOrganizationId,
  useFetchSetDealToNextStep,
} from "~hooks/api";
import { SolarzAnchor } from "~solarzui/SolarzAnchor";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzList } from "~solarzui/SolarzList";
import { SolarzPipelineTab } from "~solarzui/SolarzPipelineTab";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { parseCurrency } from "~utils/parse";
import { Card, Flex } from "antd";

export function OrganizationDealsList({
  organizationId,
  nextStepButton = false,
  ...props
}: IOrganizationDealsListProps) {
  const [filters, setFilters] = useState<IOrganizationDealsListFilterType>({
    totalPages: 0,
    currentPage: 1,
  });

  const [organizationDeals, setOrganizationDeals] = useState<
    IOrganizationDealsListItemType[]
  >([]);

  const {
    isFetching: isLoadingOrganizationDeals,
    error: organizationDealsError,
    refetch: reloadOrganizationDeals,
  } = useFetchGetPaginatedOrganizationDealsByOrganizationId({
    payload: {
      organizationId,
      page: filters.currentPage - 1,
      size: 3,
    },
    dependencyArray: [organizationId, filters.currentPage],
    options: {
      onSuccess: (data) => {
        const newDealsIds = data.content.map((deal) => deal.id);

        setFilters((current) => ({
          ...current,
          totalPages: data.totalPages,
        }));

        setOrganizationDeals((currentDeals) => {
          const filteredCurrent = currentDeals.filter(
            (deal) => !newDealsIds.find((newDeal) => newDeal === deal.id),
          );
          return [...filteredCurrent, ...data.content];
        });
      },
      enabled: filters.currentPage > 0 && organizationId > 0,
    },
  });

  const { mutate: setDealToNextStep, isLoading: isSettingDealToNextStep } =
    useFetchSetDealToNextStep({});

  const haveNextPage = filters.totalPages > filters.currentPage;

  const isInitialLoading =
    (organizationDeals.length === 0 && isLoadingOrganizationDeals) ||
    organizationId === 0;

  if (isInitialLoading) {
    return (
      <OrganizationDealListSkeletonLoading
        count={3}
        isLoading={isLoadingOrganizationDeals}
      />
    );
  }

  if (organizationDealsError || organizationDeals.length === 0) {
    return (
      <OrganizationDealListSkeletonLoading
        count={3}
        isLoading={false}
        style={{ position: "relative" }}
      >
        <Card
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
          }}
        >
          <Result.TableErrorOrEmpty
            disableReload={organizationId === 0}
            errorMessage={organizationDealsError?.message}
            reloadFn={reloadOrganizationDeals}
            errorTitle={undefined}
            emptyMessage="Nenhum negócio encontrado."
          />
        </Card>
      </OrganizationDealListSkeletonLoading>
    );
  }

  return (
    <SolarzList<IOrganizationDealsListItemType>
      {...props}
      data-cy="organization-deals-infinity-list"
      items={organizationDeals}
      renderItem={(deal) => {
        return (
          <StyledCard size="small">
            <Flex justify="space-between" style={{ marginBottom: 12 }}>
              <SolarzAnchor
                isNextLink
                href={`/funil/negocio/${deal.id}`}
                defaultUnderlined={false}
              >
                <SolarzTypography
                  hierarchy="paragraph2"
                  variant="subTitle"
                  fontWeight="semiBold"
                  style={{ color: "var(--primary-500)" }}
                >
                  {deal.name}
                </SolarzTypography>
              </SolarzAnchor>
            </Flex>

            <Flex
              justify="space-between"
              align="flex-end"
              style={{ marginBottom: 12 }}
            >
              <div style={{ width: 180 }}>
                <SolarzPipelineTab
                  scale="tiny"
                  separator="arrow"
                  tabs={deal.pipelineStages.map((stage) => {
                    return {
                      id: stage.id,
                      label: stage.name,
                      record: stage,
                      disabled: true,
                    };
                  })}
                  actualStep={deal.pipelinePosition ?? 0}
                />
              </div>

              {nextStepButton && (
                <DealNextStepButton
                  variant="link"
                  onClick={() => setDealToNextStep({ customId: deal.id })}
                  isLoading={isSettingDealToNextStep}
                  scale="tiny"
                  isDisabled={
                    deal.pipelineStages.length === (deal.pipelinePosition ?? 0)
                  }
                >
                  Próxima fase
                </DealNextStepButton>
              )}
            </Flex>

            <Flex justify="space-between">
              <SolarzTypography
                hierarchy="small"
                variant="title"
                fontWeight="medium"
              >
                Valor
              </SolarzTypography>

              <SolarzTypography hierarchy="small" fontWeight="regular">
                {parseCurrency(deal.value)}
              </SolarzTypography>
            </Flex>
          </StyledCard>
        );
      }}
      listItemKey="id"
      loadMoreRender={
        !organizationDealsError &&
        !isInitialLoading &&
        haveNextPage && (
          <SolarzButton
            isLoading={isLoadingOrganizationDeals}
            scale="tiny"
            variant="ghost"
            isBlock
            onClick={() => {
              setFilters((currentFilters) => ({
                ...currentFilters,
                currentPage: currentFilters.currentPage + 1,
              }));
            }}
          >
            Carregar mais
          </SolarzButton>
        )
      }
    />
  );
}
