import { useState } from "react";
import { FaRegQuestionCircle } from "react-icons/fa";

import type {
  PipedriveClientSearchSelectRecordType,
  IPipedriveClientSearchSelectProps,
} from "./interface";

import { Modal } from "~components/Modal";
import { Company, People } from "~estrela-ui/icons";
import { useFetchGetSearchPipedriveClient } from "~hooks/api";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzSelect } from "~solarzui/SolarzSelect";
import { Col, Divider, Flex, Row } from "antd";

const ICONS = {
  ORGANIZATION: <Company />,
  PERSON: <People />,
} as Record<string, React.ReactElement>;

function getIconByClientType(clientType = "") {
  return (
    ICONS[clientType] ?? <FaRegQuestionCircle size={18} strokeWidth={0.75} />
  );
}

export function PipedriveClientSearchSelect({
  searchValue: _searchValue,
  onCreatePersonSuccess,
  onCreatePersonError,
  onCreateOrganizationError,
  onCreateOrganizationSuccess,
  createPersonInitialValue,
  createOrganizationInitialValue,
  ...props
}: IPipedriveClientSearchSelectProps) {
  const [searchValue, setSearchValue] = useState("");
  const [selectedOption, setSelectedOption] = useState<string | null>();

  const [isPersonModalOpen, setIsPersonModalOpen] = useState(false);
  const [isOrganizationModalOpen, setIsOrganizationModalOpen] = useState(false);

  const canMakeRequest = searchValue.length >= 3;

  const {
    data = [],
    isFetching,
    error,
    refetch,
  } = useFetchGetSearchPipedriveClient({
    dependencyArray: [searchValue],
    payload: {
      term: searchValue,
    },
    options: {
      enabled: canMakeRequest,
      retry: false,
    },
  });

  return (
    <>
      <SolarzSelect<PipedriveClientSearchSelectRecordType, "uniqueKey">
        {...props}
        searchValue={_searchValue ?? searchValue}
        value={selectedOption}
        onChangeSearch={(value) => {
          setSearchValue(value);
          if (typeof props.onChangeSearch === "function") {
            props.onChangeSearch(value);
          }
        }}
        onChange={(value, option) => {
          if (value === null) {
            setSearchValue("");
            setSelectedOption(null);
          }
          if (typeof value === "string") {
            setSelectedOption(value);
          }

          if (typeof props.onChange === "function") {
            props.onChange(value, option);
          }
        }}
        options={data.map((option) => {
          const icon = getIconByClientType(option.clientType);
          const uniqueKey = `${option.clientType ?? "UNKNOWN"}_${option.pipedriveId}`;

          return {
            label: (
              <Flex align="center" gap={8}>
                {icon}
                <span>
                  {option.pipedriveId} - {option.name}
                </span>
              </Flex>
            ),
            record: {
              ...option,
              uniqueKey,
            },
            value: uniqueKey,
          };
        })}
        isLoading={isFetching}
        refetchFn={canMakeRequest ? refetch : undefined}
        errorMessage={error?.message}
        emptyMessage={
          canMakeRequest
            ? "Nenhum cliente encontrado"
            : "Digite ao menos 3 letras para buscar"
        }
        showSearch
        autoClearSearchValue={false}
        dropdownRender={(menu) => {
          return (
            <Flex vertical>
              {menu}
              <Flex vertical style={{ margin: "8px 16px 0 16px" }}>
                <Divider style={{ margin: 0 }} />

                <Row gutter={12}>
                  <Col span={10}>
                    <Flex justify="end">
                      <SolarzButton
                        style={{ margin: "8px 0 4px 0" }}
                        variant="ghost"
                        scale="tiny"
                        onClick={() => setIsPersonModalOpen(true)}
                      >
                        Nova pessoa
                      </SolarzButton>
                    </Flex>
                  </Col>
                  <Col span={4}>
                    <Flex justify="center">
                      <Divider
                        type="vertical"
                        style={{ marginTop: 8, height: 32 }}
                      />
                    </Flex>
                  </Col>
                  <Col span={10}>
                    <Flex justify="start">
                      <SolarzButton
                        style={{ margin: "8px 0 4px 0" }}
                        variant="ghost"
                        scale="tiny"
                        onClick={() => setIsOrganizationModalOpen(true)}
                      >
                        Nova organização
                      </SolarzButton>
                    </Flex>
                  </Col>
                </Row>
              </Flex>
            </Flex>
          );
        }}
      />

      <Modal.PersonForm
        title="Cadastrar pessoa"
        formProps={{
          onSuccess: (person) => {
            setIsPersonModalOpen(false);

            if (typeof onCreatePersonSuccess === "function") {
              onCreatePersonSuccess(person);
            }
          },
          onError: onCreatePersonError,
          initialValues: createPersonInitialValue,
        }}
        isOpen={isPersonModalOpen}
        onClose={() => setIsPersonModalOpen(false)}
      />

      <Modal.OrganizationForm
        title="Cadastrar organização"
        formProps={{
          onSuccess: (organization) => {
            setIsOrganizationModalOpen(false);

            if (typeof onCreateOrganizationSuccess === "function") {
              onCreateOrganizationSuccess(organization);
            }
          },
          onError: onCreateOrganizationError,
          initialValues: createOrganizationInitialValue,
        }}
        isOpen={isOrganizationModalOpen}
        onClose={() => setIsOrganizationModalOpen(false)}
      />
    </>
  );
}
