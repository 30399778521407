import type { IConsumerUnitsFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function ConsumerUnitsFormModal({
  isOpen = false,
  onClose = () => null,
  width = 1280,
  formProps,
}: IConsumerUnitsFormModalProps) {
  return (
    <Modal
      data-cy="consumer-units-form-modal"
      open={isOpen}
      onCancel={onClose}
      title="Unidades consumidoras"
      width={width}
      footer={null}
      destroyOnClose
      style={{ maxWidth: "90vw" }}
    >
      <Form.ConsumerUnits {...formProps} onCancel={onClose} />
    </Modal>
  );
}
