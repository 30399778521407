import { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";

import {
  SelectSimultaneityProfilesOptionType,
  ISimultaneityProfilesSelectProps,
} from "./interface";

import { useFetchGetAllSimultaneityProfiles } from "~hooks/api";
import { createUuid } from "~utils/createUuid";
import { Select } from "antd";

export function SimultaneityProfilesSelect({
  form,
}: ISimultaneityProfilesSelectProps) {
  const [optionsSimultaneityProfiles, setOptionsSimultaneityProfiles] =
    useState<SelectSimultaneityProfilesOptionType[]>([]);

  const { isFetching: isLoadingOptionSimultaneityProfiles } =
    useFetchGetAllSimultaneityProfiles({
      options: {
        onSuccess: (data) => {
          const parsedData = data.map((item) => ({
            key: createUuid(),
            label: item.description,
            value: item.id,
            record: item,
          }));

          setOptionsSimultaneityProfiles(parsedData);
        },
      },
    });
  return (
    <Select
      showSearch
      allowClear
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      popupMatchSelectWidth
      dropdownStyle={{ maxHeight: 300 }}
      options={optionsSimultaneityProfiles}
      loading={isLoadingOptionSimultaneityProfiles}
      suffixIcon={<AiOutlineSearch />}
      placeholder="Procurar perfil"
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option?.label?.toString() ?? "").includes(input)
      }
      filterSort={(optionA, optionB) =>
        (optionA?.label?.toString() ?? "")
          .toLowerCase()
          .localeCompare((optionB?.label?.toString() ?? "").toLowerCase())
      }
      onChange={(value) => {
        const perfil = optionsSimultaneityProfiles.find(
          (item) => item.record.id === value,
        );
        form.setFieldValue("simultaneityFactor", perfil?.record.value);
      }}
    />
  );
}
