export * from "./AdminPagesMenuDrawer/interface";
import { AdminPagesMenuDrawer } from "./AdminPagesMenuDrawer";
export * from "./AppPagesMenuDrawer/interface";
import { AppPagesMenuDrawer } from "./AppPagesMenuDrawer";
export * from "./LoggedUserDrawer/interface";
import { LoggedUserDrawer } from "./LoggedUserDrawer";
export * from "./NotificationCenterDrawer/interface";
import { NotificationCenterDrawer } from "./NotificationCenterDrawer";
export * from "./PermissionDrawer/interface";
import { PermissionDrawer } from "./PermissionDrawer";

export const Drawer = {
  AdminPagesMenu: AdminPagesMenuDrawer,
  AppPagesMenu: AppPagesMenuDrawer,
  LoggedUser: LoggedUserDrawer,
  NotificationCenter: NotificationCenterDrawer,
  Permission: PermissionDrawer,
};
