import { useEffect, useRef, useState } from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";

import { IStatisticInfoProps } from "./interface";
import styles from "./styles";

import { useAppConfig } from "~hooks/useAppConfig";
import { useOnClickOutside } from "~hooks/useOnClickOutside";
import { Flex, Spin, Tooltip, Typography } from "antd";

export function StatisticInfo({
  title = "-",
  tooltip = undefined,
  value = "-",
  style,
  titleStyle,
  valueStyle,
  fieldValue,
  onClickEdit = () => undefined,
  isEditable = false,
  onEditSubmit = () => undefined,
  isSubmittingEdit = false,
  isEditing = false,
  extra = undefined,
}: IStatisticInfoProps) {
  const { currentTheme } = useAppConfig();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditFieldOpen, setIsEditFieldOpen] = useState(false);

  const TypographyRef = useRef<HTMLSpanElement | null>(null);
  useOnClickOutside(TypographyRef, () => setIsEditFieldOpen(false));

  function handleSubmit(value: string) {
    onEditSubmit(value);
  }

  function handleClickEdit() {
    setIsEditFieldOpen(true);
    onClickEdit();
  }

  useEffect(() => {
    if (!isEditing) {
      setIsEditFieldOpen(false);
    }
  }, [isEditing]);

  useEffect(() => {
    setIsSubmitting(isSubmittingEdit);
  }, [isSubmittingEdit]);

  return (
    <Flex
      data-cy="statistic-info"
      vertical
      align="start"
      gap={2}
      style={{ ...style }}
    >
      <style jsx>{styles}</style>
      <Typography.Title
        style={{
          fontSize: "0.9375rem",
          margin: 0,
          color:
            currentTheme === "default" ? "var(--gray-900)" : "var(--gray-300)",
          ...titleStyle,
        }}
        className="edit"
        editable={
          isEditable && {
            tooltip: "Editar",
            text: fieldValue?.toString() ?? "",
            autoSize: false,
            onChange: handleSubmit,
            editing: isEditFieldOpen,
            onStart: handleClickEdit,
            enterIcon: isSubmitting ? (
              <Spin size="small" style={{ fontSize: 0 }} />
            ) : undefined,
            icon: isSubmitting ? (
              <Spin size="small" style={{ fontSize: 0 }} />
            ) : undefined,
            onCancel: () => setIsEditFieldOpen(false),
            onEnd: () => setIsEditFieldOpen(false),
          }
        }
      >
        {value}
      </Typography.Title>
      {extra}
      <Typography.Text
        style={{
          color:
            currentTheme === "default" ? "var(--gray-700)" : "var(--gray-500)",
          ...valueStyle,
        }}
      >
        <Flex
          align="center"
          wrap="nowrap"
          gap={2}
          style={{ fontSize: "0.6875rem" }}
        >
          {title}{" "}
          {tooltip && (
            <Tooltip title={tooltip}>
              <AiOutlineQuestionCircle
                fontSize={13}
                style={{ color: valueStyle?.color || "var(--cyan-600)" }}
              />
            </Tooltip>
          )}
        </Flex>
      </Typography.Text>
    </Flex>
  );
}
