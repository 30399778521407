import { Fragment, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";

import NextImage from "next/image";

import {
  ProposalCustomField,
  ProposalCustomFieldValueType,
  IProposalCustomFieldsFormProps,
} from "./interface";

import { CustomInput } from "~components/CustomInput";
import { useFetchGetProposalCustomFields } from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import type { FieldTypeEnum } from "~types/enum";
import type { ICustomFieldProposalDTOSchema } from "~types/schemas";
import { getBase64 } from "~utils/getBase64";
import { getFieldReadonlyStyle } from "~utils/getFieldReadonlyStyle";
import {
  DatePicker,
  Divider,
  Form,
  Modal,
  Segmented,
  Select,
  Spin,
  Typography,
  Upload,
  message,
} from "antd";
import type { RcFile, UploadFile } from "antd/lib/upload/interface";
import dayjs, { Dayjs } from "dayjs";

function parseCustomFieldValue(value = "") {
  switch (value) {
    case "true":
      return true;
    case "false":
      return false;
    default:
      return undefined;
  }
}

const getFormFieldName = (instanceKey: string) => `customFields${instanceKey}`;

const getUploadFileFromBase64 = (base64String: string): UploadFile => ({
  uid: "-1",
  name: "Imagem Salva",
  status: "done",
  url: base64String,
  thumbUrl: base64String,
});

export function ProposalCustomFieldsForm({
  proposalSection,
  pricingTypeId,
  initialValues,
  form,
  instanceKey,
}: IProposalCustomFieldsFormProps) {
  const { currentTheme } = useAppConfig();
  const formFieldName = getFormFieldName(instanceKey);

  const [previewOpens, setPreviewOpens] = useState<Record<string, boolean>>({});
  const [previewImages, setPreviewImages] = useState<Record<string, string>>(
    {},
  );
  const [previewTitles, setPreviewTitles] = useState<Record<string, string>>(
    {},
  );

  const [fileLists, setFileLists] = useState<Record<string, UploadFile[]>>({});

  const [initialCustomFields, setInitialCustomFields] = useState<
    ProposalCustomField[]
  >([]);

  function parseCustomFieldValueByType(
    customField: ProposalCustomField,
  ): ProposalCustomField {
    if (customField.type === "BOOLEAN") {
      const parsedValue = customField.value?.toString();
      const value = parseCustomFieldValue(parsedValue);

      const initialValue = parseCustomFieldValue(parsedValue);

      return { ...customField, value, initialValue };
    }

    if (customField.type === "NUMBER") {
      const parsedValue = Number(customField.value);
      const value = isNaN(parsedValue) ? undefined : parsedValue;

      const parsedInitialValue = Number(customField.initialValue);
      const initialValue = isNaN(parsedInitialValue)
        ? undefined
        : parsedInitialValue;
      return { ...customField, value, initialValue };
    }
    if (customField.type === "DATE") {
      const parseDate = (
        dateString: ProposalCustomFieldValueType,
      ): Dayjs | undefined =>
        dateString ? dayjs(dateString as string, "DD/MM/YYYY") : undefined;

      const value = parseDate(customField.value);
      const initialValue = parseDate(customField.initialValue)?.toString();

      return { ...customField, value, initialValue };
    }

    return customField;
  }

  function parseCustomFieldDtoToCustomField(
    data: ICustomFieldProposalDTOSchema[] = [],
  ): ProposalCustomField[] {
    const onlyEnabledFields = data.filter((field) => !field.hidden);

    const parsedData = onlyEnabledFields.map((field) => ({
      customFieldId: field.id,
      required: field.required,
      editable: field.editable,
      type: field.type as FieldTypeEnum,
      label: field.label,
      proposalSection: field.proposalSection,
      initialValue: field.defaultValue === "" ? null : field.defaultValue,
      order: field.displayOrder,
      value: field.defaultValue === "" ? null : field.defaultValue,
      options: field.options ?? [],
      projectType: field.projectTypes ?? [],
      width: field.width,
      height: field.height,
    }));

    const dataWithParsedValue = parsedData.map((data) =>
      parseCustomFieldValueByType({
        ...data,
        order: data.order ?? 999999,
        options: data.options ?? [],
      }),
    );

    return dataWithParsedValue;
  }

  const { isFetching: isLoadingCustomFields } = useFetchGetProposalCustomFields(
    {
      payload: {
        pricingTypeId: pricingTypeId,
      },
      options: {
        onSuccess: (data) => {
          const initialCustomFields = parseCustomFieldDtoToCustomField(
            data,
          ).filter((field) => field.proposalSection === proposalSection);

          const updatedDataCustomFieldValues = initialCustomFields.map(
            (field) => {
              const fieldAlreadyHaveInitialData = initialValues?.find(
                (initial) => initial.customFieldId === field.customFieldId,
              );

              if (fieldAlreadyHaveInitialData) {
                const updatedFieldValue: ProposalCustomField = {
                  ...field,
                  value: fieldAlreadyHaveInitialData.value,
                };

                const parsedCustomField =
                  parseCustomFieldValueByType(updatedFieldValue);

                return parsedCustomField;
              } else {
                const parsedCustomField = parseCustomFieldValueByType(field);
                return parsedCustomField;
              }
            },
          );

          const sortedUpdatedInitialCustomFields = [
            ...updatedDataCustomFieldValues,
          ].sort((a, b) => a.order - b.order);

          sortedUpdatedInitialCustomFields.forEach((field, index) => {
            const { value } = field;

            if (
              typeof value === "string" &&
              /^[a-zA-Z0-9+/]+={0,2}$/.test(value) &&
              value.length % 4 === 0
            ) {
              const parsedValue = "data:image/png;base64," + value;
              setFileLists((prev) => ({
                ...prev,
                [index]: [getUploadFileFromBase64(parsedValue)],
              }));
            }
          });

          form.setFieldValue(formFieldName, sortedUpdatedInitialCustomFields);

          setInitialCustomFields(sortedUpdatedInitialCustomFields);
        },
        enabled: !!pricingTypeId,
      },
      dependencyArray: [initialValues, pricingTypeId],
    },
  );

  const handlePreview = async (fieldKey: string, file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImages((prev) => ({
      ...prev,
      [fieldKey]: file.url || (file.preview as string),
    }));
    setPreviewOpens((prev) => ({ ...prev, [fieldKey]: true }));
    setPreviewTitles((prev) => ({
      ...prev,
      [fieldKey]:
        file.name ||
        (file.url ? file.url.substring(file.url.lastIndexOf("/") + 1) : ""),
    }));
  };

  const handleRemoveImage = (fieldKey: string) => {
    setFileLists((prev) => ({ ...prev, [fieldKey]: [] }));

    form.setFieldValue([formFieldName, fieldKey], {
      ...form.getFieldValue([formFieldName, fieldKey]),
      file: null,
      value: null,
    });
  };

  const handleCancel = (fieldKey: string) =>
    setPreviewOpens((prev) => ({ ...prev, [fieldKey]: false }));

  const handleChange =
    (fieldKey: string) =>
    ({ fileList }: { fileList: UploadFile[] }) => {
      setFileLists((prev) => {
        const updatedFileLists = { ...prev, [fieldKey]: fileList };

        if (fileList.length === 0) {
          form.setFieldValue([formFieldName, fieldKey], {
            ...form.getFieldValue([formFieldName, fieldKey]),
            file: null,
          });

          setPreviewImages((prev) => ({ ...prev, [fieldKey]: "" }));
        }

        return updatedFileLists;
      });

      const removedFiles = (fileLists[fieldKey] || []).filter(
        (prevFile) =>
          !fileList.some((currentFile) => currentFile.uid === prevFile.uid),
      );

      if (removedFiles.length > 0) {
        handleRemoveImage(fieldKey);
      }
    };

  const renderUploadButton = (fieldKey: string) => {
    const hasImage = previewImages[fieldKey];

    if (hasImage) {
      return null;
    }

    return (
      <button style={{ border: 0, background: "none" }} type="button">
        <AiOutlinePlus />
        <div style={{ marginTop: 8 }}>Upload</div>
      </button>
    );
  };

  return (
    <>
      {isLoadingCustomFields ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        initialCustomFields.length > 0 &&
        !isLoadingCustomFields && (
          <>
            {(instanceKey === "InitialData" || instanceKey === "PlantData") && (
              <>
                <Divider />
                <Typography.Title level={4} style={{ marginBottom: "1rem" }}>
                  Outros
                </Typography.Title>
              </>
            )}
            {instanceKey === "IntermediateData" && (
              <>
                <Divider />
                <Typography.Title level={4} style={{ marginBottom: "1rem" }}>
                  Campos customizados
                </Typography.Title>
              </>
            )}

            <Form.List name={formFieldName} initialValue={initialCustomFields}>
              {(fields) => (
                <>
                  {fields.map((field) => {
                    const record: ProposalCustomField = form.getFieldValue([
                      formFieldName,
                      field.key,
                    ]);

                    const fieldIsRequired = !!record.required;
                    const fieldIsReadonly = !record.editable;

                    return (
                      <Fragment key={field.key}>
                        <Form.Item
                          name={["id", field.key]}
                          required={false}
                          hidden
                        >
                          <CustomInput.Number readOnly disabled />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          key={formFieldName}
                          label={record?.label}
                          name={[formFieldName, field.key]}
                          required={fieldIsRequired}
                          rules={[
                            ({ getFieldValue }) => ({
                              validator: () => {
                                const fieldValue =
                                  getFieldValue(formFieldName)?.[field.key];
                                const value: ProposalCustomFieldValueType =
                                  fieldValue.value;

                                if (fieldIsRequired) {
                                  const uploadedFiles = fileLists[field.key];
                                  if (
                                    (value !== undefined &&
                                      value?.toString()?.trim() !== "" &&
                                      value !== null) ||
                                    (uploadedFiles && uploadedFiles.length > 0)
                                  ) {
                                    return Promise.resolve();
                                  } else {
                                    Promise.reject("Campo é obrigatório");
                                  }

                                  return value !== undefined &&
                                    value?.toString()?.trim() !== "" &&
                                    value !== null
                                    ? Promise.resolve()
                                    : Promise.reject("Campo é obrigatório");
                                } else {
                                  return Promise.resolve();
                                }
                              },
                            }),
                          ]}
                        >
                          <>
                            {record?.type === "TEXT" && (
                              <CustomInput.Text
                                placeholder="Campo textual"
                                defaultValue={record.value as string}
                                readOnly={fieldIsReadonly}
                                style={getFieldReadonlyStyle(
                                  !fieldIsReadonly,
                                  !!(currentTheme === "dark"),
                                )}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  form.setFieldValue(
                                    [formFieldName, field.key],
                                    {
                                      ...record,
                                      value,
                                    },
                                  );
                                }}
                              />
                            )}

                            {record?.type === "TEXT_AREA" && (
                              <CustomInput.TextArea
                                placeholder="Caixa de texto"
                                defaultValue={record.value as string}
                                readOnly={fieldIsReadonly}
                                style={getFieldReadonlyStyle(
                                  !fieldIsReadonly,
                                  !!(currentTheme === "dark"),
                                )}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  form.setFieldValue(
                                    [formFieldName, field.key],
                                    {
                                      ...record,
                                      value,
                                    },
                                  );
                                }}
                              />
                            )}

                            {record?.type === "NUMBER" && (
                              <CustomInput.Number
                                placeholder="Campo numérico"
                                defaultValue={record.value as number}
                                readOnly={fieldIsReadonly}
                                style={getFieldReadonlyStyle(
                                  !fieldIsReadonly,
                                  !!(currentTheme === "dark"),
                                )}
                                onChange={(changeValue) => {
                                  const valueNumber = Number(changeValue);

                                  const trueValue = isNaN(valueNumber)
                                    ? 0
                                    : valueNumber;

                                  form.setFieldValue(
                                    [formFieldName, field.key],
                                    {
                                      ...record,
                                      value: trueValue,
                                    },
                                  );
                                }}
                              />
                            )}

                            {/* SEGMENTED AQUI É GAMBIARRA, O RADIO.GROUP POR ALGUM MOTIVO NÃO PEGA A REFERENCIA CERTA DO VALOR DO FORMULÁRIO. */}
                            {record?.type === "BOOLEAN" && (
                              <Segmented
                                defaultValue={
                                  (record?.value?.toString() as string) ?? ""
                                }
                                readOnly={fieldIsReadonly}
                                style={getFieldReadonlyStyle(
                                  !fieldIsReadonly,
                                  !!(currentTheme === "dark"),
                                )}
                                options={[
                                  {
                                    value: "true",
                                    label: "Sim",
                                  },
                                  {
                                    value: "false",
                                    label: "Não",
                                  },
                                  {
                                    value: "",
                                    label: "",
                                    disabled: true,
                                  },
                                ].filter((item) => item.value !== "")}
                                onChange={(value) => {
                                  form.setFieldValue(
                                    [formFieldName, field.key],
                                    {
                                      ...record,
                                      value: value === "true",
                                    },
                                  );
                                }}
                              />
                            )}

                            {record?.type === "SELECT" && (
                              <Select
                                defaultValue={record.value as number}
                                disabled={fieldIsReadonly}
                                options={
                                  record?.options.map((option) => ({
                                    label: option,
                                    value: option,
                                  })) ?? []
                                }
                                onChange={(value) => {
                                  form.setFieldValue(
                                    [formFieldName, field.key],
                                    {
                                      ...record,
                                      value,
                                    },
                                  );
                                }}
                                allowClear
                              />
                            )}

                            {record?.type === "IMAGE" && (
                              <>
                                <Upload
                                  action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                                  listType="picture-card"
                                  disabled={fieldIsReadonly}
                                  style={getFieldReadonlyStyle(
                                    !fieldIsReadonly,
                                    !!(currentTheme === "dark"),
                                  )}
                                  fileList={fileLists[field.key] || []}
                                  beforeUpload={async (file) => {
                                    const isJpgOrPng =
                                      file.type === "image/jpeg" ||
                                      file.type === "image/png";
                                    if (!isJpgOrPng) {
                                      message.error(
                                        "Você pode fazer upload apenas de arquivos PNG ou JPG!",
                                      );
                                      return Upload.LIST_IGNORE;
                                    }
                                    const isLt2M = file.size / 1024 / 1024 < 2;
                                    if (!isLt2M) {
                                      message.error(
                                        "A imagem deve ser menor que 2MB!",
                                      );
                                      return Upload.LIST_IGNORE;
                                    }

                                    form.setFieldValue(
                                      [formFieldName, field.key],
                                      {
                                        ...record,
                                        file,
                                      },
                                    );

                                    return isJpgOrPng && isLt2M;
                                  }}
                                  onPreview={(file) =>
                                    handlePreview(String(field.key), file)
                                  }
                                  onChange={handleChange(String(field.key))}
                                >
                                  {fileLists[field.key]?.length >= 1
                                    ? null
                                    : renderUploadButton(String(field.key))}
                                </Upload>
                                <Modal
                                  open={previewOpens[field.key] || false}
                                  title={previewTitles[field.key] || ""}
                                  footer={null}
                                  onCancel={() =>
                                    handleCancel(String(field.key))
                                  }
                                >
                                  <NextImage
                                    alt="example"
                                    style={{ width: "100%" }}
                                    src={previewImages[field.key] || ""}
                                  />
                                </Modal>
                              </>
                            )}
                            {record?.type === "DATE" && (
                              <DatePicker
                                defaultValue={
                                  record.value !== undefined
                                    ? (dayjs(
                                        record.value as string | number,
                                      ) as Dayjs)
                                    : undefined
                                }
                                readOnly={fieldIsReadonly}
                                style={{ width: "100%" }}
                                format="DD/MM/YYYY"
                                allowClear={false}
                                // disabledDate={(currentDate) =>
                                //   currentDate.isBefore(Date.now(), "day")
                                // }
                                onChange={(value) => {
                                  form.setFieldValue(
                                    [formFieldName, field.key],
                                    {
                                      ...record,
                                      value: value?.toISOString(),
                                    },
                                  );
                                }}
                              />
                            )}
                          </>
                        </Form.Item>
                      </Fragment>
                    );
                  })}
                </>
              )}
            </Form.List>
          </>
        )
      )}
    </>
  );
}
