import { useEffect } from "react";

import { IUsableAreaRoofFormValues } from "./interface";

import { Alert } from "~components/Alert";
import { CustomInput } from "~components/CustomInput";
import { Result } from "~components/Result";
import {
  useFetchCreatePremises,
  useFetchGetPremises,
  useFetchPatchPremisesUsefulArea,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { useSession } from "~hooks/useSession";
import { Button, Col, Flex, Form, Row } from "antd";

export function UsableAreaRoofTypeForm() {
  const [form] = Form.useForm<IUsableAreaRoofFormValues>();
  const { user } = useSession();

  const { screens } = useAppConfig();

  const {
    data: premisesData,
    isLoading: isPremisesDataLoading,
    error: premisesDataError,
    refetch: reloadPremisesData,
  } = useFetchGetPremises({
    dependencyArray: [user?.businessUnitId],
  });

  const { mutate: fetchCreatePremises, isLoading: isCreatingPremises } =
    useFetchCreatePremises({});

  const {
    mutate: fetchUpdatePremisesUsefulArea,
    isLoading: isUpdatingPremisesUsefulArea,
  } = useFetchPatchPremisesUsefulArea({});

  const onFinish = async (values: IUsableAreaRoofFormValues) => {
    premisesData
      ? fetchUpdatePremisesUsefulArea(
          values.usefulAreas.map((item) => {
            return {
              usefulAreaId: item.id,
              spacingFactor: item.spacingFactor,
            };
          }),
        )
      : fetchCreatePremises({
          usefulAreas: values.usefulAreas,
        });
  };

  useEffect(() => {
    if (premisesData) {
      form.setFieldsValue({
        usefulAreas: premisesData.usefulAreaList,
      });
    }
  }, [form, premisesData]);

  const usefulAreaList = premisesData?.usefulAreaList ?? [];

  const isSubmitting = isCreatingPremises || isUpdatingPremisesUsefulArea;

  return (
    <Flex
      style={{
        margin: !screens.md ? "0" : "3rem 0",
        display: "block",
      }}
    >
      {premisesDataError?.message && (
        <Alert.CardError
          title="Falha ao carregar dados"
          errorMessage={premisesDataError?.message}
          reloadFn={reloadPremisesData}
        />
      )}

      {!premisesDataError?.message && usefulAreaList.length === 0 && (
        <Result.TableErrorOrEmpty
          emptyMessage="Nenhuma área útil encontrada"
          reloadFn={reloadPremisesData}
          loading={isPremisesDataLoading}
        />
      )}

      {!premisesDataError?.message && usefulAreaList.length > 0 && (
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          style={{ width: "100%" }}
        >
          <Row gutter={16}>
            {usefulAreaList.map((usefulArea, index) => (
              <Col xs={24} xl={12} key={usefulArea.id}>
                <Form.Item
                  label={usefulArea.structureDescription}
                  name={["usefulAreas", index, "id"]}
                  rules={[{ required: true }]}
                  hidden
                >
                  <CustomInput.Number />
                </Form.Item>

                <Form.Item
                  label={usefulArea.structureDescription}
                  name={["usefulAreas", index, "spacingFactor"]}
                  rules={[{ required: true }]}
                >
                  <CustomInput.UnitMeasurement
                    precision="2"
                    unitMeasurement="m²/m² de módulos"
                    placeholder="Exemplo: 1,20"
                  />
                </Form.Item>
              </Col>
            ))}
          </Row>

          <Row justify="end">
            <Col offset={24}>
              <Button
                type="primary"
                size="middle"
                htmlType="submit"
                loading={isSubmitting}
                disabled={isPremisesDataLoading}
              >
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Flex>
  );
}
