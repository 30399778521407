import styled from "@emotion/styled";
import { SolarzButton } from "~solarzui/SolarzButton";

export const EditButton = styled(SolarzButton)`
  width: 28px;
  height: 28px;
  padding: 0;

  border: 1px solid var(--gray-300);

  svg {
    color: var(--primary-500);
  }

  html.dark & {
    border: 1px solid var(--gray-600) !important;
  }
`;
