import type {
  DeletePipelineStageFormValuesType,
  IDeletePipelineStageFormProps,
} from "./interface";
import { LoadingSkeleton, StyledAlert } from "./styles";

import { Alert } from "~components/Alert";
import {
  useFetchDeletePipelineStage,
  useFetchGetPipelineById,
} from "~hooks/api";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzFormItem } from "~solarzui/SolarzFormItem";
import { SolarzSelect } from "~solarzui/SolarzSelect";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { Flex, Form, Radio } from "antd";

export function DeletePipelineStageForm({
  formInstance,
  initialValues,
  pipelineId,
  pipelineStageId,
  onCancel,
  onError,
  onSuccess,
}: IDeletePipelineStageFormProps) {
  const [form] = Form.useForm<DeletePipelineStageFormValuesType>(formInstance);

  const needDestinationWatcher = Form.useWatch("needDestination", form);

  const { mutate: deletePipelineStage, isLoading: isDeletingPipelineStage } =
    useFetchDeletePipelineStage({
      options: {
        onSuccess,
        onError,
      },
    });

  const {
    data: pipeline,
    isFetching: isLoadingPipeline,
    error: pipelineError,
    refetch: refetchPipeline,
  } = useFetchGetPipelineById({
    dependencyArray: [pipelineId],
    options: {
      retry: 1,
      enabled: pipelineId > 0,
    },
    payload: {
      pipelineId,
    },
  });

  function handleSubmit(formValues: DeletePipelineStageFormValuesType) {
    deletePipelineStage({
      pipelineStageId,
      destinationPipelineStageId: formValues.needDestination
        ? formValues.destinationPipelineStageId
        : undefined,
    });
  }

  function handleCancel() {
    typeof onCancel === "function" ? onCancel() : form.resetFields();
  }

  const disableSubmitButton =
    isLoadingPipeline || !!pipelineError || !pipelineId;

  const dealsQuantity = pipeline?.stages.reduce(
    (total, stage) => total + stage.deals.length,
    0,
  );

  const stageOptions = pipeline?.stages.filter(
    (stage) => stage.id !== pipelineStageId,
  );

  return (
    <Form
      data-cy="delete-pipeline-form"
      form={form}
      initialValues={{
        needDestination: false,
        ...initialValues,
      }}
      onFinish={handleSubmit}
      preserve
      layout="vertical"
    >
      {pipelineError?.message && (
        <Alert.CardError
          errorMessage={pipelineError?.message}
          reloadFn={refetchPipeline}
          title="Falha ao carregar dados do funil"
          style={{ marginBottom: 16 }}
        />
      )}

      {isLoadingPipeline && (
        <LoadingSkeleton
          active
          style={{
            height: 160,
            marginBottom: 12,
            width: "calc(100% - 60px)",
          }}
        >
          {" "}
        </LoadingSkeleton>
      )}

      {!isLoadingPipeline && (
        <StyledAlert
          type="error"
          description={
            <Flex vertical>
              <SolarzTypography variant="danger" fontWeight="medium">
                Você tem certeza que deseja{" "}
                {needDestinationWatcher ? "mover" : "apagar"} a fase &ldquo;
                {pipeline?.name}&rdquo; do funil de vendas?
              </SolarzTypography>

              <ul>
                <li>
                  <SolarzTypography variant="danger" hierarchy="paragraph2">
                    Todos os {dealsQuantity} negócios cadastrados serão{" "}
                    {needDestinationWatcher ? "movidos" : "apagados"}.
                  </SolarzTypography>
                </li>
              </ul>
            </Flex>
          }
        />
      )}

      <SolarzFormItem<DeletePipelineStageFormValuesType>
        name="needDestination"
        rules={[{ required: true }]}
        style={{ margin: "1rem 0" }}
      >
        <Radio.Group
          options={[
            {
              label: (
                <SolarzTypography>
                  Apagar permanentemente todos os negócios da fase{" "}
                  {pipeline?.name && (
                    <span>&ldquo;{pipeline?.name}&rdquo;</span>
                  )}
                </SolarzTypography>
              ),
              value: false,
              style: {
                marginBottom: "0.5rem",
              },
            },
            {
              label: (
                <SolarzTypography>
                  Apagar fase do funil e mover negócios para outro funil
                </SolarzTypography>
              ),
              value: true,
            },
          ]}
        />
      </SolarzFormItem>

      {needDestinationWatcher && (
        <SolarzFormItem<DeletePipelineStageFormValuesType>
          name="destinationPipelineStageId"
          rules={[{ required: true, message: "Campo é obrigatório" }]}
          preserve={false}
        >
          <SolarzSelect
            placeholder="Selecione a fase..."
            options={stageOptions?.map((option) => {
              return {
                label: option.name,
                record: option,
                value: option.id,
              };
            })}
            allowClear={false}
          />
        </SolarzFormItem>
      )}

      <Flex
        style={{ marginTop: 12 }}
        align="center"
        gap={16}
        justify="flex-end"
      >
        <SolarzButton variant="secondary" scale="tiny" onClick={handleCancel}>
          Cancelar
        </SolarzButton>
        <SolarzButton
          scale="tiny"
          htmlType="submit"
          isDisabled={disableSubmitButton}
          isLoading={isDeletingPipelineStage}
          variant="danger"
        >
          {needDestinationWatcher ? "Mover e apagar" : "Apenas apagar"}
        </SolarzButton>
      </Flex>
    </Form>
  );
}
