import type { IAdminPagesMenuDrawer } from "./interface";
import styles from "./styles";

import { Button } from "~components/Button";
import { Card } from "~components/Card";
import { Menu } from "~components/Menu";
import { Drawer, Flex } from "antd";

export function AdminPagesMenuDrawer({
  isOpen = false,
  onClose = () => undefined,
  menuProps = undefined,
  ...props
}: IAdminPagesMenuDrawer) {
  return (
    <Drawer
      data-cy="admin-pages-menu-drawer"
      title={
        <Flex vertical gap={4} style={{ paddingTop: "32px" }}>
          <Card.UserInfo />

          <Button.ControlTheme
            size="middle"
            type="link"
            style={{ padding: 0, margin: 0 }}
          />

          <Button.Logout
            size="middle"
            type="link"
            style={{ padding: 0, margin: 0 }}
          />
        </Flex>
      }
      placement="right"
      onClose={onClose}
      open={isOpen}
      styles={{
        body: { padding: 0, ...props.styles?.body },
        header: {
          padding: "1rem",
          ...props.styles?.header,
        },
      }}
      contentWrapperStyle={{
        maxWidth: "90vw",
        width: "320px",
      }}
      className="header"
      {...props}
    >
      <style jsx>{styles}</style>
      <Menu.AdminPages mode="inline" {...menuProps} />
    </Drawer>
  );
}
