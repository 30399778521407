import type { IDeletePipelineFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function DeletePipelineFormModal({
  pipelineId,
  isOpen = false,
  onClose = () => null,
  title = "Apagar funil de vendas",
  maxWidth = 520,
  formProps,
}: IDeletePipelineFormModalProps) {
  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title={title}
      width="90vw"
      footer={null}
      style={{ maxWidth }}
      destroyOnClose
    >
      <Form.DeletePipeline
        {...formProps}
        onCancel={onClose}
        pipelineId={pipelineId}
      />
    </Modal>
  );
}
