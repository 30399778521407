import type { CSSProperties, ReactNode } from "react";
import { IoWarningOutline, IoAtOutline } from "react-icons/io5";

import type { IUserAvatarProps } from "./interface";

import { useSession } from "~hooks/useSession";
import { getTwoFirstLatterOfFullName } from "~utils/getTwoFirstLatterOfFullName";
import { Avatar, Badge, Skeleton } from "antd";

export function UserAvatar({
  size = "large",
  badgeProps,
  name,
  ...props
}: IUserAvatarProps) {
  const { user, sessionStatus } = useSession();

  const avatarName = name ?? user?.name;

  const avatarLetters = getTwoFirstLatterOfFullName(avatarName ?? "")
    .join("")
    .toUpperCase();

  if (sessionStatus === "loading") {
    return <Skeleton.Avatar active />;
  }

  const badgeIcon = (): ReactNode => {
    if (!badgeProps?.icon || badgeProps?.showBadge) return <></>;
    const iconStyle: CSSProperties = {
      backgroundColor: "var(--white)",
      padding: 1,
      borderRadius: "100%",
      fontSize: 18,
      color: "var(--primary-400)",
    };
    switch (badgeProps?.icon) {
      case "WARNING":
        return <IoWarningOutline style={iconStyle} />;
      case "MENTION":
        return <IoAtOutline style={iconStyle} />;
      default:
        return <></>;
    }
  };

  return (
    <Badge count={badgeIcon()} {...badgeProps}>
      <Avatar
        data-cy="user-avatar"
        style={{
          color: "var(--white)",
          backgroundColor: "var(--primary-400)",
          ...props.style,
        }}
        size={size}
        {...props}
      >
        {avatarLetters}
      </Avatar>
    </Badge>
  );
}
