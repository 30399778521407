import Script from "next/script";

import type { IReleaseNotesIOButtonProps } from "./interface";

import { Button } from "antd";

export function ReleaseNotesIOButton({
  children,
  onSuccessLoadFn,
  ...props
}: IReleaseNotesIOButtonProps) {
  const handleShowWidget = () => {
    if (typeof rnw === "function") {
      rnw("show");

      if (typeof onSuccessLoadFn === "function") {
        onSuccessLoadFn();
      }
    } else {
      console.error(
        "rnw function is not available. Make sure the script is loaded.",
      );
    }
  };

  return (
    <>
      <Button {...props} onClick={handleShowWidget}>
        {children}
      </Button>

      <Script id="releases-note-io">
        {`
          (function (w,d,s,o,f,js,fjs) {
            w['ReleaseNotesWidget']=o;
            w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
            js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
            js.id = o; js.src = f; js.async = 1;
            fjs.parentNode.insertBefore(js, fjs);
          })(window, document, 'script', 'rnw', 'https://s3.amazonaws.com/cdn.releasenotes.io/v1/bootstrap.js');

          rnw('init', {
            account: 'solarzcrm.releasenotes.io',
            selector: '.rn-badge',
          });
        `}
      </Script>
    </>
  );
}
