import {
  projectTypeValues,
  type IProposalTypeFilterSelectProps,
} from "./interface";

import { ProjectTypeEnum } from "~types/enum";
import { Select } from "antd";

function translateProjectType(action: ProjectTypeEnum) {
  const translations = {
    ALL: "Todos",
    DEFAULT: "Solar",
    DIVERSE: "Diversa",
    EXPRESS: "Express",
  };

  return translations?.[action] ?? "";
}

export function ProposalTypeFilterSelect({
  value,
  onChange,
  defaultValue,
  disableOptions = [],
  hideOptions = [],
  ...props
}: IProposalTypeFilterSelectProps) {
  const OPTIONS = projectTypeValues.map((value) => ({
    label: translateProjectType(value),
    value,
    record: {
      value,
    },
    disabled: disableOptions.includes(value),
    hide: hideOptions.includes(value),
  }));

  return (
    <Select
      {...props}
      options={OPTIONS}
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
}
