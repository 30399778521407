import type { IChangeUserProfileFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function ChangeUserProfileFormModal({
  isOpen = false,
  onClose = () => null,
  title = "Alterar perfil do usuário",
  maxWidth = 512,
  formProps = {},
}: IChangeUserProfileFormModalProps) {
  return (
    <Modal
      data-cy="change-user-profile-form-modal"
      open={isOpen}
      onCancel={onClose}
      title={title}
      width="90vw"
      footer={null}
      destroyOnClose
      style={{ maxWidth }}
    >
      <Form.ChangeUserProfile onCancel={onClose} {...formProps} />
    </Modal>
  );
}
