import type { IDeletePipelineStageFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function DeletePipelineStageFormModal({
  pipelineId,
  pipelineStageId,
  isOpen = false,
  onClose = () => null,
  title = "Apagar fase do funil",
  maxWidth = 520,
  formProps,
}: IDeletePipelineStageFormModalProps) {
  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title={title}
      width="90vw"
      footer={null}
      style={{ maxWidth }}
      destroyOnClose
    >
      <Form.DeletePipelineStage
        {...formProps}
        onCancel={onClose}
        pipelineId={pipelineId}
        pipelineStageId={pipelineStageId}
      />
    </Modal>
  );
}
