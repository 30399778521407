import { useState } from "react";

import { IAldoIntegrationButton } from "./interface";

import { Modal } from "~components/Modal";
import { Button } from "antd";

export function AldoIntegrationButton({
  children,
  type = "primary",
  ...props
}: IAldoIntegrationButton) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button
        {...props}
        data-cy="aldo-integration-button"
        onClick={() => setIsModalOpen(true)}
        type={type}
        htmlType="button"
      >
        {children ?? "Configurar Aldo"}
      </Button>

      <Modal.WegIntegrationForm
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Configurar Aldo"
        formProps={{
          onSuccess: () => setIsModalOpen(false),
        }}
      />
    </>
  );
}
