import type { IProductTypeOtherFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function ProductTypeOtherFormModal({
  isOpen = false,
  onClose = () => null,
  maxWidth = 512,
  formProps,
}: IProductTypeOtherFormModalProps) {
  return (
    <Modal
      data-cy="product-type-other-form-modal"
      open={isOpen}
      onCancel={onClose}
      title="Formulário de outros produtos"
      width="90vw"
      style={{ maxWidth }}
      footer={null}
      destroyOnClose
    >
      <Form.ProductTypeOther {...formProps} onCancel={onClose} />
    </Modal>
  );
}
