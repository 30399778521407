import { useMemo } from "react";

import type { IProposalSectionsSelectProps } from "./interface";

import { SelectSuffixIcon } from "~components/Others";
import { Result } from "~components/Result";
import { useFetchGetCustomFieldsSectionsByProposalStep } from "~hooks/api";
import { Select } from "antd";

export function ProposalSectionsSelect({
  allowClear = true,
  proposalStep = undefined,
  onChange,
  ...props
}: IProposalSectionsSelectProps) {
  const {
    data: proposalSections = [],
    error: proposalSectionsError,
    isFetching: isLoadingSectionsTypes,
    refetch: reloadSectionsTypes,
  } = useFetchGetCustomFieldsSectionsByProposalStep({
    payload: { proposalStep },
    dependencyArray: [proposalStep],
  });

  const OPTIONS = useMemo(() => {
    return proposalSections.map((section) => ({
      label: section.title,
      value: section.proposalSection,
      record: section,
    }));
  }, [proposalSections]);

  return (
    <Select
      {...props}
      loading={isLoadingSectionsTypes}
      options={OPTIONS}
      allowClear={allowClear}
      status={proposalSectionsError ? "error" : ""}
      suffixIcon={
        <SelectSuffixIcon
          hasError={!!proposalSectionsError}
          isLoading={isLoadingSectionsTypes}
        />
      }
      notFoundContent={
        <Result.SelectErrorOrEmpty
          errorMessage={proposalSectionsError?.message}
          emptyMessage="Nenhuma seção foi encontrado"
          loading={isLoadingSectionsTypes}
          reloadFn={reloadSectionsTypes}
        />
      }
      onChange={(value, option) => {
        if (!onChange) return;
        onChange(value, option);
      }}
    />
  );
}
