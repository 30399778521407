import { useState } from "react";

import type {
  IBaseAddressFormValues,
  IBaseAddressFormProps,
} from "./interface";

import { fetchGetCities } from "~api/cities/search/get";
import { CustomInput } from "~components/CustomInput";
import { GoogleMapsProvider, SelectLocation } from "~components/GoogleMaps";
import {
  useFetchCreateBaseAddress,
  useFetchUpdateBaseAddress,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzFormItem } from "~solarzui/SolarzFormItem";
import { SolarzInput } from "~solarzui/SolarzInput";
import { SolarzInputNumber } from "~solarzui/SolarzInputNumber";
import type {
  ICitySchema,
  IFetchUpdateBaseAddressPayloadSchema,
} from "~types/schemas";
import { AutoComplete, Form, message } from "antd";

export interface IBaseAddressCity {
  id?: number;
  name: string;
  ibge: number;
  province?: {
    id?: number;
    name: string;
    uf: string;
    ibge: number;
    country?: {
      id?: number;
      name: string;
      abbreviation: string;
    };
  };
}

export function BaseAddressForm({
  initialValues,
  onSuccess = () => undefined,
  onError = () => undefined,
  onCancel = () => undefined,
}: IBaseAddressFormProps) {
  const [form] = Form.useForm<IBaseAddressFormValues>();
  const { screens } = useAppConfig();
  const [cities, setCities] = useState<ICitySchema[]>([]);

  const {
    mutate: fetchCreateBaseAddress,
    isLoading: isCreateBaseAddressLoading,
  } = useFetchCreateBaseAddress({
    options: {
      onSuccess,
      onError,
    },
  });

  const {
    mutate: fetchUpdateBaseAddress,
    isLoading: isUpdateBaseAddressLoading,
  } = useFetchUpdateBaseAddress({
    options: {
      onSuccess,
      onError,
    },
  });

  const onSearch = (searchText: string) => {
    form.setFieldsValue({
      province: "",
    });
    if (searchText.length >= 3) {
      fetchGetCities({ term: searchText })
        .then((data) => {
          const filteredCities = data.map((item) => {
            return {
              ...item,
              value: item.name,
            };
          });
          setCities(filteredCities);
        })
        .catch((error: any) => message.error(error.message));
    }
  };

  const onFinish = async (formValues: IBaseAddressFormValues) => {
    const parsedFormValues: Omit<
      IFetchUpdateBaseAddressPayloadSchema,
      "baseAddressId"
    > = {
      ...formValues,
      city: {
        name: formValues.city,
      },
      province: {
        name: formValues.uf,
        uf: formValues.uf,
      },
    };

    if (initialValues?.id) {
      fetchUpdateBaseAddress({
        ...parsedFormValues,
        baseAddressId: initialValues?.id,
      });
    } else {
      fetchCreateBaseAddress(parsedFormValues);
    }
  };

  const onSelect = (_: string, obj: IBaseAddressCity) => {
    form.setFieldsValue({
      province: obj.province?.name,
    });
  };

  return (
    <Form
      data-cy="base-address-form"
      layout="vertical"
      form={form}
      onFinish={onFinish}
      initialValues={initialValues}
    >
      <div className="flex flex-col w-full">
        <div
          className={`flex ${screens.mobile && "flex-col"} justify-between  gap-3`}
        >
          <div className={`${screens.mobile ? "w-full" : "w-1/3"}`}>
            <SolarzFormItem
              name="identifier"
              label="Identificador"
              rules={[
                { required: true, message: "Identificador é obrigatório" },
              ]}
            >
              <SolarzInput
                placeholder="Ex.: Endereço 01"
                style={{ height: 48 }}
              />
            </SolarzFormItem>
          </div>

          <div className={`${screens.mobile ? "w-full" : "w-2/3"}`}>
            <SolarzFormItem label="Buscar endereço">
              <GoogleMapsProvider
                onChangeLocationCallback={(location) => {
                  form.setFieldValue(
                    "street",
                    location?.state
                      ? `${location?.street}${
                          location?.streetNumber
                            ? " , " + location.streetNumber
                            : ""
                        }`
                      : "",
                  );

                  form.setFieldValue("city", location?.city);
                  form.setFieldValue("uf", location?.state);
                  form.setFieldValue("zipCode", location?.zipCode);
                  form.setFieldValue(
                    "number",
                    location?.streetNumber?.toString(),
                  );
                  form.setFieldValue("district", location?.district);
                }}
              >
                <SelectLocation style={{ height: 48 }} />
              </GoogleMapsProvider>
            </SolarzFormItem>
          </div>
        </div>
        <div
          className={`flex ${screens.mobile && "flex-col"} justify-between gap-3`}
        >
          <div className={`${screens.mobile ? "w-full" : "w-1/3"}`}>
            <SolarzFormItem
              label="Rua"
              name="street"
              rules={[{ required: true, message: "Rua é obrigatório" }]}
            >
              <SolarzInput
                placeholder="Rua das Vassouras"
                style={{ height: 48 }}
              />
            </SolarzFormItem>
          </div>

          <div className={`${screens.mobile ? "w-full" : "w-1/3"}`}>
            <SolarzFormItem
              label="Número"
              name="number"
              rules={[{ required: true, message: "Número é obrigatório" }]}
            >
              <SolarzInputNumber
                min={0}
                precision={0}
                placeholder="123"
                style={{
                  display: "flex",
                  justifyItems: "start",
                  alignItems: "center",
                  height: 48,
                }}
                onChange={(value) => {
                  const parsedValue = Number(value);

                  form.setFieldValue(
                    "number",
                    isNaN(parsedValue) ? undefined : parsedValue.toString(),
                  );
                }}
              />
            </SolarzFormItem>
          </div>

          <div className={`${screens.mobile ? "w-full" : "w-1/3"}`}>
            <SolarzFormItem
              label="CEP"
              name="zipCode"
              rules={[{ required: true, message: "CEP é obrigatório" }]}
            >
              <CustomInput.Cep
                placeholder="Ex.: 59000-000"
                maxLength={9}
                style={{ width: "100%", height: 48 }}
              />
            </SolarzFormItem>
          </div>
        </div>
        <div
          className={`flex ${screens.mobile && "flex-col"} justify-between gap-3`}
        >
          <div className={`${screens.mobile ? "w-full" : "w-1/2"}`}>
            <SolarzFormItem
              label="Cidade"
              name="city"
              rules={[{ required: true, message: "Cidade é obrigatório" }]}
            >
              <AutoComplete
                onFocus={(event: any) => event.target?.select()}
                style={{ width: "100%", height: 48 }}
                options={cities}
                onSearch={onSearch}
                placeholder="Cidade"
                onSelect={(city: string, obj: ICitySchema) =>
                  onSelect(city, obj)
                }
              />
            </SolarzFormItem>
          </div>

          <div className={`${screens.mobile ? "w-full" : "w-1/2"}`}>
            <SolarzFormItem
              label="Estado"
              name="uf"
              rules={[{ required: true, message: "Estado é obrigatório" }]}
            >
              <SolarzInput
                placeholder="Ex.: Rio Grande do Sul"
                style={{ height: 48 }}
              />
            </SolarzFormItem>
          </div>
        </div>
      </div>

      <div className="flex flex-row align-middle justify-end gap-3">
        <SolarzButton variant="secondary" onClick={onCancel}>
          Cancelar
        </SolarzButton>
        <SolarzButton
          variant="primary"
          htmlType="submit"
          isLoading={isCreateBaseAddressLoading || isUpdateBaseAddressLoading}
        >
          {initialValues?.id ? "Alterar" : "Salvar"}
        </SolarzButton>
      </div>
    </Form>
  );
}
