import type { IAdminInverterFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function AdminInverterFormModal({
  id,
  isOpen = false,
  onClose = () => undefined,
  title = "Formulário do inversor",
  maxWidth = 860,
  formProps,
}: IAdminInverterFormModalProps) {
  return (
    <Modal
      data-cy="admin-inverter-form-modal"
      width="90vw"
      title={title}
      footer={null}
      destroyOnClose
      open={isOpen}
      onCancel={onClose}
      style={{ maxWidth }}
    >
      <Form.AdminInverter {...formProps} onCancel={onClose} id={id} />
    </Modal>
  );
}
