import { EosFinancingStepKeyType } from "~configs/EosFinancingConfig";
import { ProposalStepKeyType } from "~configs/ProposalConfig";
import {
  ActivityEventTypeEnum,
  ActivityPeriodsEnum,
  ActivityPriorityEnum,
  ActivityStateQueryEnum,
  ActivityStatusQueryEnum,
  FieldTypeEnum,
  GuarantorNatureOccupationEnum,
  MonthEnum,
  TypeEosProjectEnum,
  locationServiceEnumValues,
  type LogActionTypeEnum,
  type LogEntityTypeEnum,
} from "~types/enum";

interface ITranslationMap {
  [key: string]: string;
}

export function translateLogActionType(action: LogActionTypeEnum) {
  const translations = {
    CREATE: "Criou",
    DELETE: "Apagou",
    UPDATE: "Alterou",
  };

  return translations?.[action] ?? "";
}

export function translateActivityPeriods(period: ActivityPeriodsEnum) {
  const translations = {
    TODAY: "Hoje",
    TOMORROW: "Amanhã",
    THIS_WEEK: "Esta semana",
    NEXT_WEEK: "Próxima semana",
    THIS_MONTH: "Este mês",
  };

  return translations?.[period] ?? "";
}

export function translateLogEntityType(entity: LogEntityTypeEnum) {
  const translations = {
    AdditionalCostDescriptor: "Item complementar",
    AlternatingCurrentMaterial: "Material de C.A",
    Labor: "Mão de obra",
    Logistics: "Logística",
    Project: "Proposta",
    StructureMaterialCostRule: "Cálculo de estrutura",
    TemplateReport: "Template de documento",
    UserAccount: "Usuário",
    Company: "Empresa",
    Inverter: "Datasheet inversor",
    PvModule: "Datasheet módulo fotovoltaico",
  };

  return translations?.[entity] ?? entity;
}

export function translateLogPropertyName(value: string) {
  const translations: ITranslationMap = {
    unity: "Unidade",
    projectTitle: "Nome do Projeto",
    owner: "Nome do consultor",
    emailResponsible: "Email do consultor",
    phoneResponsible: "Telefone do consultor",
    idBaseAddress: "Endereço base",
    expirationDate: "Validade da proposta",
    pipedrivePipelineId: "Funil do pipedrive",
    name: "Nome",
    email: "E-mail",
    cpfCnpj: "CPF/CNPJ",
    phone: "Número de contato do cliente",
    street: "Rua",
    number: "Número do endereço",
    district: "Bairro",
    city: "Cidade",
    state: "Estado",
    zipCode: "Cep",
    tensionType: "Dados da fatura",
    electricUtilityId: "Concessionárias",
    plantUnitCode: "Código da unidade",
    powerPhase: "Fase da rede do cliente",
    outputVoltage: "Tensão da rede do cliente",
    taxType: "Tipo de tarifa",
    averageConsumption: "Consumo médio mensal",
    te: "Tarifa TE",
    tusd: "Tarifa TUSD",
    wireBTax: "Tarifa de Fio B",
    lowVoltageCosipTaxBefore: "Taxa de iluminação pública - antes da solar",
    lowVoltageCosipTaxAfter: "Taxa de iluminação pública - depois da solar",
    accumulatedCreditsUsedInPayback: "Considerar crédito acumulado no payback",
    averageConsumptionP: "Consumo médio mensal",
    teP: "Tarifa TE",
    tusdP: "Tarifa TUSD",
    wireBTaxP: "Tarifa de Fio B",
    contractedDemandP: "Demanda contratada",
    contractedDemandTaxP: "Tarifa de Demanda",
    averageConsumptionFP: "Consumo médio mensal",
    teFP: "Tarifa TE",
    tusdFP: "Tarifa TUSD",
    wireBTaxFP: "Tarifa de Fio B",
    tusdG: "Tusd G",
    reactivePowerAverage: "Média energia reativa",
    mediumVoltageCosipTaxBefore: "Taxa de iluminação pública - antes da solar",
    mediumVoltageCosipTaxAfter: "Taxa de iluminação pública - depois da solar",
    considerConsumptionUnits:
      "Considerar consumo das unidades no dimensionamento da usina",
    performanceProfiles: "Perfis de Performance",
    PerformanceRate: "Taxa de Desempenho",
    GenerationFactor: "Fator de geração",
    MinCelsiusTemperature: "Temperatura mínima",
    IdStructureType: "Tipo de estrutura",
    RegionId: "Região",
    StructureGroup: "Incluir estrutura em",
    IdLabor: "Mão de obra",
    SimultaneityProfiles: "Perfis de Simultaneidade",
    SimultaneityFactor: "Taxa de simultaneidade",
    FDI: "Sobredimensionamento (FDI)",
    InverterTransformerPowerLimitKwp: "Limite de potência de inversor (Trafo)",
    ExpirationDate: "Validade da proposta",
    Note: "Observação",
    pipedriveDealId: "Identificação da proposta no Pipedrive",
    lastStepEnum: "Ultimo passo acessado",
    lastUpdate: "Data da ultima atualização",
    value: "Valor do campo customizado",
    executionOrder: "Ordem de execução",
    notApplyDefaultAdditionalCostTax: "NÃO aplicar Imposto pós precificado",
    lastStep: "Ultimo passo",
    status: "Status da proposta",
    customFieldId: "Identificador do campo customizado",
  };

  return translations?.[value] ?? "";
}

export function translateProposalStep(proposalStep: ProposalStepKeyType) {
  switch (proposalStep) {
    case "AFTER_SIZING":
      return "Pós-dimensionamento";
    case "DATA":
      return "Dados";
    case "DETAILS":
      return "Detalhes";
    case "FINISH":
      return "Finalizar";
    case "PAYMENT":
      return "Pagamento";
    case "PROPOSAL":
      return "Proposta";
    case "SIZING":
      return "Dimensionamento";
  }
}

export function translateEosFinancingStep(
  eosFinancingStep: EosFinancingStepKeyType,
) {
  switch (eosFinancingStep) {
    case "DATA_PROJECT":
      return "Dados do projeto";
    case "DATA_FINANCING":
      return "Dados do financiamento";
    case "DATA_GUARANTOR":
      return "Dados do avalista";
  }
}

export function translateActivityPriorityEnum(
  activityPriority: ActivityPriorityEnum,
) {
  switch (activityPriority) {
    case "HIGH":
      return "Alta";
    // case "HIGHEST":
    //   return "Altíssima";
    case "LOW":
      return "Baixa";
    // case "LOWEST":
    //   return "Baixíssima";
    case "MEDIUM":
      return "Média";
  }
}

export function translateActivityEventTypeEnum(
  activityEventType: ActivityEventTypeEnum,
) {
  switch (activityEventType) {
    case "CALL":
      return "Ligação";
    case "EMAIL":
      return "Email";
    case "MEETING":
      return "Reunião";
    case "PROPOSAL":
      return "Proposta";
    case "TASK":
      return "Tarefa";
    default:
      return undefined;
  }
}

export function parseStringToActivityEventTypeEnum(
  activityEventType: string,
): ActivityEventTypeEnum | undefined {
  const lowerString = activityEventType.toLowerCase();

  switch (lowerString) {
    case "ligação":
      return "CALL";
    case "ligacao":
      return "CALL";
    case "email":
      return "EMAIL";
    case "reunião":
      return "MEETING";
    case "reuniao":
      return "MEETING";
    case "proposta":
      return "PROPOSAL";
    case "tarefa":
      return "TASK";
    default:
      return undefined;
  }
}

export function parseStringToActivityStatusTypeEnum(
  activityStatusType: string,
): ActivityStatusQueryEnum | undefined {
  const lowerString = activityStatusType.toLowerCase();

  switch (lowerString) {
    case "pendente":
      return "PENDING";
    case "concluída":
      return "DONE";
    case "concluida":
      return "DONE";
    case "expirada":
      return "EXPIRED";
    default:
      return undefined;
  }
}

export function parseStringToActivityStateTypeEnum(
  activityStateType: string,
): ActivityStateQueryEnum | undefined {
  const lowerString = activityStateType.toLowerCase();

  switch (lowerString) {
    case "ativa":
      return "ACTIVE";
    case "inativa":
      return "INACTIVE";
    default:
      return undefined;
  }
}

export function parseStringToActivityPriorityTypeEnum(
  activityPriorityType: string,
): ActivityPriorityEnum | undefined {
  const lowerString = activityPriorityType.toLowerCase();

  switch (lowerString) {
    case "baixa":
      return "LOW";
    case "média":
      return "MEDIUM";
    case "media":
      return "MEDIUM";
    case "alta":
      return "HIGH";
    default:
      return undefined;
  }
}

export function translateTypeProjectEosFinancingEnum(
  typeProject: TypeEosProjectEnum,
) {
  switch (typeProject) {
    case "SOLAR":
      return "Solar";
    case "HOMEIMPROVE":
      return "Melhorar a casa";
    case "WORKING_CAPITAL":
      return "Capital de giro";
  }
}

export function translateOccupationEosFinancingEnum(
  occupation: GuarantorNatureOccupationEnum | string,
) {
  switch (occupation) {
    case "APOSENTADO_PENSIONISTA":
      return "Aposentado e pensionista";
    case "ASSALARIADO":
      return "Assalariado";
    case "AUTONOMO":
      return "Autônomo";
    case "EMPRESARIO":
      return "Empresario";
    case "PROFISSIONAL_LIBERAL":
      return "Profissional liberal";
    case "FUNCIONARIO_PUBLICO":
      return "Funcionário público";
  }
}

export function translateLocationServiceEnum(value: string) {
  const locationService = {
    [locationServiceEnumValues[0]]: "Here",
    [locationServiceEnumValues[1]]: "Google",
  } as Record<string, string>;

  return locationService?.[value] || "";
}

export function translateStructureType(structure = "") {
  const translations = {
    TELHADO: "Telhado",
    SOLO: "Solo",
    ESTACIONAMENTO: "Estacionamento",
    SEM_ESTRUTURA: "Sem estrutura",
  } as Record<string, string>;

  return translations?.[structure] ?? "";
}

export function translateFieldType(fieldType = "") {
  const translations = {
    BOOLEAN: "Sim/Não",
    DATE: "Data",
    IMAGE: "Imagem",
    NUMBER: "Númerico",
    SELECT: "Selecionável",
    TEXT: "Texto",
    TEXT_AREA: "Caixa de texto",
  } as Record<FieldTypeEnum, string>;

  return (translations as Record<string, string>)?.[fieldType] ?? "";
}

export function translateMonthEnum(month = "") {
  const translations = {
    JANUARY: "Janeiro",
    FEBRUARY: "Fevereiro",
    MARCH: "Marco",
    APRIL: "Abril",
    AUGUST: "Agosto",
    DECEMBER: "Dezembro",
    JULY: "Julho",
    JUNE: "Junho",
    MAY: "Maio",
    NOVEMBER: "Novembro",
    OCTOBER: "Outubro",
    SEPTEMBER: "Setembro",
  } as Record<MonthEnum, string>;

  return (translations as Record<string, string>)?.[month] ?? "";
}
