import { useState } from "react";

import type { ICreateSimultaneityProfileButtonProps } from "./interface";

import { Modal } from "~components/Modal";
import { Button } from "antd";

export function CreateSimultaneityProfileButton({
  children,
  ...props
}: ICreateSimultaneityProfileButtonProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button
        {...props}
        data-cy="create-simultaneity-profile-button"
        onClick={() => setIsModalOpen(true)}
        type="primary"
        htmlType="button"
      >
        {children ?? "Criar perfil de simultaneidade"}
      </Button>

      <Modal.SimultaneityProfileForm
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        formProps={{
          onSuccess: () => setIsModalOpen(false),
        }}
      />
    </>
  );
}
