import styled from "@emotion/styled";
import { Card, Row } from "antd";

export const StyledProposalItemCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-height: 100px;
  border-radius: 4px;
  border: 1px solid var(--gray-200);
  background-color: var(--gray-50);

  .ant-card-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
  }

  // DARK THEME
  html.dark & {
    background-color: var(--gray-999);
    border: 1px solid var(--gray-800);
    a {
      color: var(--primary-500);
    }
  }
`;

export const StyledRow = styled(Row)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: start;

  svg {
    color: var(--primary-500);
  }
`;

export const InnerRow = styled(Row)`
  display: flex;
  align-items: center;
  gap: 8px;
`;
