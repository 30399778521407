import { useMemo, useState } from "react";

import { IEosBaseProposalAutoCompleteProps } from "./interface";

import { AutoCompleteSuffixIcon } from "~components/Others";
import { Result } from "~components/Result";
import { useFetchGetAutoCompleteProposal } from "~hooks/api";
import { IProposalAutoCompleteResponseSchema } from "~types/schemas";
import { AutoComplete } from "antd";

export function EosBaseProposalAutoComplete({
  allowClear = true,
  onChange,
  onClear,
  ...props
}: IEosBaseProposalAutoCompleteProps) {
  const [inputValue, setInputValue] = useState<string>("");

  const CAN_LOAD_DATA = useMemo(() => inputValue.length >= 3, [inputValue]);

  const {
    data: proposals,
    isFetching,
    error,
    refetch,
  } = useFetchGetAutoCompleteProposal({
    dependencyArray: [inputValue],
    payload: {
      term: inputValue,
    },
    options: {
      enabled: inputValue.length >= 3,
    },
  });

  function formatProposalLabel(proposal: IProposalAutoCompleteResponseSchema) {
    const title = proposal.title ?? "Título Não Especificado";
    const clientName = proposal.clientName ?? "Cliente Não Especificado";
    const fullPower = proposal.fullPower ?? "Potência Não Especificada";
    return `${title} - ${clientName} - ${fullPower}`;
  }

  const OPTIONS = useMemo(() => {
    return proposals
      ? proposals.map((proposal) => ({
          label: formatProposalLabel(proposal),
          value: formatProposalLabel(proposal),
          record: proposal,
          key: proposal.id,
        }))
      : [];
  }, [proposals]);

  const handleSearch = (value: string) => {
    setInputValue(value);
  };

  return (
    <AutoComplete
      {...props}
      data-cy="proposal-auto-complete"
      showSearch
      filterOption={false}
      onSearch={handleSearch}
      options={OPTIONS}
      allowClear={allowClear}
      status={error ? "error" : ""}
      notFoundContent={
        <Result.SelectErrorOrEmpty
          errorMessage={error?.message}
          emptyMessage="Nenhum proposta encontrada"
          loading={isFetching}
          reloadFn={refetch}
          disableReload={!CAN_LOAD_DATA}
        />
      }
      suffixIcon={
        <AutoCompleteSuffixIcon hasError={!!error} isLoading={isFetching} />
      }
      onClear={() => {
        setInputValue("");
        if (typeof onClear === "function") {
          onClear();
        }
      }}
      onChange={(value, option) => {
        if (option instanceof Array || !onChange) return;
        onChange(value, option);
      }}
      style={{ width: "100%", height: "2.5rem" }}
    />
  );
}
