import { useState } from "react";

import type { IUpdatePipedriveTokenApiButtonProps } from "./interface";

import { Modal } from "~components/Modal";
import { Button } from "antd";

export function UpdatePipedriveTokenApiButton({
  children,
  modalProps,
  closeOnSuccess,
  ...props
}: IUpdatePipedriveTokenApiButtonProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button
        {...props}
        data-cy="update-pipedrive-token-api-button"
        onClick={() => setIsModalOpen(true)}
      >
        {children ?? "Alterar pipedrive api token"}
      </Button>

      <Modal.UpdatePipedriveTokenApiForm
        {...modalProps}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        formProps={{
          ...modalProps?.formProps,
          onSuccess() {
            if (typeof modalProps?.formProps?.onSuccess === "function") {
              modalProps?.formProps?.onSuccess();
            }

            if (closeOnSuccess) {
              setIsModalOpen(false);
            }
          },
        }}
      />
    </>
  );
}
