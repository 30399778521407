import type {
  IValidationRuleForm,
  IValidationRuleFormProps,
} from "./interface";

import { CustomInput } from "~components/CustomInput";
import { Mentions } from "~components/Mentions";
import {
  useFetchCreateValidationRule,
  useFetchUpdateValidationRule,
} from "~hooks/api";
import { Button, Flex, Form } from "antd";

export function ValidationRuleForm({
  initialValues,
  onCancel,
  onError,
  onSuccess,
}: IValidationRuleFormProps) {
  const [form] = Form.useForm<IValidationRuleForm>();

  const { mutate: fetchCreateValidationRule } = useFetchCreateValidationRule({
    options: {
      onSuccess,
      onError,
    },
  });

  const { mutate: fetchUpdateValidationRule } = useFetchUpdateValidationRule({
    options: {
      onSuccess,
      onError,
    },
  });

  async function handleSubmit(rule: IValidationRuleForm) {
    if (initialValues && initialValues.id) {
      fetchUpdateValidationRule({
        validationRuleId: initialValues.id,
        ...rule,
      });
    } else {
      fetchCreateValidationRule(rule);
    }
  }

  return (
    <Form
      data-cy="validation-rule-form"
      form={form}
      style={{ width: "100%" }}
      initialValues={initialValues}
      onFinish={handleSubmit}
      layout="vertical"
    >
      <Form.Item label="Nome" name="name" rules={[{ required: true }]}>
        <CustomInput.Text />
      </Form.Item>

      <Form.Item
        name="expression"
        label="Regra de validação"
        rules={[{ required: true }]}
        help="Use o caractere $ seguido de pelos menos 3 caracteres para buscar uma variável. Ex: $var"
      >
        <Mentions.Variables
          initialValueField={initialValues?.expression}
          rows={3}
        />
      </Form.Item>

      <Flex gap={12} justify="end">
        <Form.Item style={{ margin: 0 }}>
          <Button onClick={onCancel}>Cancelar</Button>
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button type="primary" htmlType="submit" loading={false}>
            Salvar
          </Button>
        </Form.Item>
      </Flex>
    </Form>
  );
}
