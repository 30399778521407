import styled from "@emotion/styled";
import { Drawer, Menu } from "antd";

interface IStyledMenuProps {
  mobile?: boolean;
}

interface IStyledDrawerProps {
  mobile?: boolean;
}

export const StyledMenu = styled(Menu)<IStyledMenuProps>`
  width: 100%;

  display: flex;
  justify-content: ${(props) => (props.mobile ? "space-between" : "start")};
  align-items: center;
  flex-direction: ${(props) => (props.mobile ? "row" : "column")};
  gap: 1rem;
  background-color: transparent;

  .ant-menu-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;

    height: 3rem;
    width: 3rem;

    padding: 0;
    margin: 0;
    line-height: 0;

    position: relative;
    color: var(--gray-500);

    border-radius: 0.25rem !important;

    opacity: 1 !important;
    order: 0 !important;

    svg {
      width: 1.5rem !important;
      height: 1.5rem !important;

      position: absolute;
      inset: 25%;
    }
  }

  .ant-menu-item.is-selected {
    background: var(--gray-200);
    color: var(--primary-500) !important;

    // DARK THEME
    html.dark-theme & {
      background: var(--gray-900) !important;
    }
  }

  li:focus {
    background: var(--gray-200);

    // DARK THEME
    html.dark-theme & {
      background: var(--gray-900) !important;
    }
  }

  .ant-menu-item-selected {
    color: var(--primary-500);
  }
`;

export const StyledDrawer = styled(Drawer)<IStyledDrawerProps>`
  width: ${(props) => (props.mobile ? "100vw" : "246px")} !important;

  // DARK THEME
  html.dark-theme & {
    background-color: black;
    border-color: var(--gray-700) !important;
  }

  strong {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    color: var(--gray-500);

    // DARK THEME
    html.dark-theme & {
      color: var(--gray-400);
    }
  }

  button {
    color: var(--gray-500);

    // DARK THEME
    html.dark-theme & {
      color: var(--gray-300);
    }
  }

  ul:focus-visible {
    outline: none !important;
  }

  .ant-drawer-header {
    padding: ${(props) => (props.mobile ? "1.5rem" : "1rem")} !important;
    border-bottom: ${(props) =>
      props.mobile ? "1px solid var(--gray-300)" : null} !important;

    // DARK THEME
    html.dark-theme & {
      border-color: var(--gray-700) !important;
    }
  }

  .ant-drawer-title {
    strong {
      color: ${(props) =>
        props.mobile ? "var(--primary-500)" : null} !important;
    }
  }

  .ant-drawer-body {
    padding: ${(props) => (props.mobile ? "0" : "0 1rem")} !important;
  }

  .ant-menu {
    display: flex;
    flex-direction: column;
    background: transparent;
    width: 100%;
    gap: ${(props) => (props.mobile ? "0" : "0.5rem")} !important;
    background-color: ${(props) =>
      props.mobile ? "var(--gray-50)" : "var(--white)"} !important;

    // DARK THEME
    html.dark-theme & {
      background-color: var(--black) !important;
    }
  }

  .ant-menu-title-content {
    margin: 0 !important;
    opacity: 1 !important;
    width: 100%;

    svg {
      color: var(--primary-500) !important;
      height: 16px;
      width: 16px;
    }
  }

  .ant-menu-item {
    width: 100%;

    height: ${(props) => (props.mobile ? "3.5rem" : "2.5rem")} !important;
    font-size: ${(props) => (props.mobile ? "14px" : "12px")} !important;
    line-height: ${(props) => (props.mobile ? "18.48px" : "16px")} !important;
    border-radius: ${(props) => (props.mobile ? "0" : null)} !important;
    border: ${(props) =>
      props.mobile
        ? "1px solid var(--gray-200)"
        : "1px solid var(--gray-300)"} !important;
    border-width: ${(props) => (props.mobile ? "0 0 1px 0" : null)} !important;
    padding: ${(props) => (props.mobile ? "1rem" : "0 0.5rem")} !important;
    background-color: var(--white);

    margin: 0;

    display: flex;
    align-items: center;
    gap: ${(props) => (props.mobile ? "0.75rem" : "0.5rem")} !important;
    font-weight: 500;

    border-radius: 0.25rem;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);

    a {
      color: var(--gray-500) !important;
    }

    & > svg {
      color: var(--gray-600);
      height: ${(props) => (props.mobile ? "16px" : "12px")} !important;
      width: ${(props) => (props.mobile ? "16px" : "12px")} !important;
    }

    &:hover,
    &:focus {
      border-color: ${(props) =>
        props.mobile ? null : "var(--primary-500)"} !important;
      background: unset !important;

      a {
        color: var(--primary-500) !important;
      }

      svg {
        color: var(--primary-500) !important;
      }

      // DARK THEME
      html.dark-theme & {
        border-color: var(--primary-500) !important;
      }
    }

    // DARK THEME
    html.dark-theme & {
      border-color: var(--gray-700) !important;
      background-color: var(--gray-900);

      a {
        color: var(--gray-400);
      }

      svg {
        color: var(--gray-500);
      }
    }
  }

  .ant-menu-item-active {
    border-color: var(--primary-500);
    background: unset !important;

    z-index: 1;

    a {
      color: var(--primary-500) !important;
    }

    svg {
      color: var(--primary-500) !important;
    }

    // DARK THEME
    html.dark-theme & {
      border-color: var(--primary-500);
    }
  }

  .ant-menu-item-selected {
    background-color: var(--primary-50) !important;
    border-color: var(--primary-200) !important;
    border-top: ${(props) =>
      props.mobile ? "1px solid var(--primary-200)" : null} !important;

    a {
      color: var(--primary-500) !important;
    }

    svg {
      color: var(--primary-500) !important;
    }

    // DARK THEME
    html.dark-theme & {
      background-color: var(--primary-900) !important;
      border-color: var(--primary-700) !important;

      a {
        color: var(--primary-300) !important;
      }

      svg {
        color: var(--primary-200) !important;
      }
    }
  }
`;
