export const getFieldReadonlyStyle = (
  isEditable: boolean,
  isDarkTheme?: boolean,
) => ({
  backgroundColor: isEditable
    ? "transparent"
    : isDarkTheme
      ? "#313131"
      : "#f5f5f5",
  pointerEvents: isEditable ? ("auto" as const) : ("none" as const),
});
