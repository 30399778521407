import type { IComplementaryItemFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function ComplementaryItemFormModal({
  isOpen = false,
  onClose = () => null,
  title = "Formulário do item complementar",
  width = 512,
  formProps = {},
}: IComplementaryItemFormModalProps) {
  return (
    <Modal
      data-cy="complementary-item-form-modal"
      open={isOpen}
      onCancel={onClose}
      title={title}
      width={width}
      footer={null}
      destroyOnClose
    >
      <Form.ComplementaryItem onCancel={onClose} {...formProps} />
    </Modal>
  );
}
