import type { IUpdateProposalDealFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function UpdateProposalDealFormModal({
  isOpen = false,
  onClose = () => null,
  title = "Associar proposta a um negócio",
  maxWidth = 512,
  proposalCustomId = 0,
  onSubmit = () => null,
}: IUpdateProposalDealFormModalProps) {
  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title={title}
      width="90vw"
      footer={null}
      style={{ maxWidth }}
      destroyOnClose
    >
      <Form.UpdateProposalDeal
        onSubmitForm={onSubmit}
        onCancel={onClose}
        customId={proposalCustomId}
      />
    </Modal>
  );
}
