import type {
  IKomecoIntegrationFormProps,
  IKomecoIntegrationFormValues,
} from "./interface";

import { CustomInput } from "~components/CustomInput";
import { useAppConfig } from "~hooks/useAppConfig";
import { Button, Flex, Form } from "antd";

const DEFAULT_VALUES: IKomecoIntegrationFormValues = {
  password: undefined,
};

export function KomecoIntegrationForm({
  formInstance,
  onCancel = () => undefined,
}: IKomecoIntegrationFormProps) {
  const { message } = useAppConfig();

  const [form] = Form.useForm<IKomecoIntegrationFormValues>(formInstance);

  function handleSubmitForm(formValues: IKomecoIntegrationFormValues) {
    message.info(JSON.stringify(formValues));
  }

  return (
    <Form
      data-cy="komeco-integration-form"
      form={form}
      onFinish={handleSubmitForm}
      initialValues={DEFAULT_VALUES}
      layout="vertical"
      style={{ width: "100%" }}
    >
      <Form.Item<IKomecoIntegrationFormValues> name="password">
        <CustomInput.Password placeholder="Sua senha Komeco..." />
      </Form.Item>

      <Flex align="center" gap={8} justify="end">
        <Button onClick={onCancel}>Cancelar</Button>
        <Button type="primary" htmlType="submit">
          Salvar
        </Button>
      </Flex>
    </Form>
  );
}
