import type { ISolarzButtonProps } from "./interface";

import styled from "@emotion/styled";
import { Button } from "antd";

function getStylesByScaleProps(
  scale: ISolarzButtonProps["scale"],
  iconOnly = false,
) {
  switch (scale) {
    case "large":
      return {
        padding: iconOnly ? "0 0.5rem" : "0 1rem",
        height: "3rem",
      };
    case "medium":
      return {
        padding: iconOnly ? "0 0.5rem" : "0 1rem",
        height: "2.5rem",
      };
    case "tiny":
      return {
        padding: iconOnly ? "0 0.5rem" : "0 1rem",
        height: "2rem",
      };
    default:
      return {
        padding: "0 1rem",
        height: "2.5rem",
      };
  }
}

function getStylesByVariantProps(
  variant: ISolarzButtonProps["variant"],
  theme: "default" | "dark",
) {
  switch (variant) {
    case "primary":
      return {
        backgroundColor: "var(--primary-500)",
        backgroundColorHover: "var(--primary-400) !important",
        color: "var(--white)",
        colorHover: "var(--gray-50) !important",
        border: "none",
      };
    case "secondary":
      return {
        backgroundColor:
          theme === "dark" ? "var(--gray-999) !important" : "var(--white)",
        backgroundColorHover:
          theme === "dark" ? "var(--gray-900)" : "var(--gray-50)",
        color: theme === "dark" ? "var(--gray-500)" : "var(--gray-500)",
        colorHover:
          theme === "dark"
            ? "var(--gray-700) !important"
            : "var(--gray-500) !important",
        border: "1px solid var(--gray-500)",
      };
    case "ghost":
      return {
        backgroundColor: "unset",
        backgroundColorHover: "unset !important",
        color: "var(--primary-500)",
        colorHover: "var(--primary-400) !important",
        border: "none",
      };
    case "link":
      return {
        backgroundColor: "unset",
        backgroundColorHover: "unset !important",
        color: "var(--primary-500)",
        colorHover: "var(--primary-400) !important",
        border: "none",
      };
    case "danger":
      return {
        backgroundColor: "var(--red-400)",
        backgroundColorHover: "var(--red-600) !important",
        color: "var(--white)",
        colorHover: "var(--white) !important",
        border: "none",
      };
    default:
      return {
        backgroundColor: "var(--primary-500)",
        backgroundColorHover: "var(--primary-400) !important",
        color: "var(--white)",
        colorHover: "var(--gray-50) !important",
        border: "none",
      };
  }
}

interface IStyledButtonProps {
  scale: ISolarzButtonProps["scale"];
  variant: ISolarzButtonProps["variant"];
  iconOnly: boolean;
  iconPlace: ISolarzButtonProps["iconPlace"];
}

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== "iconPlace" &&
    prop !== "iconOnly" &&
    prop !== "variant" &&
    prop !== "scale",
})<IStyledButtonProps>`
  font-weight: 600;
  font-size: 14px;
  line-height: 19.6px;
  box-shadow: none;
  text-decoration: none;

  width: auto;
  height: ${(props) =>
    props.variant === "link"
      ? "auto"
      : getStylesByScaleProps(props.scale).height};
  padding: ${(props) =>
    props.variant === "link"
      ? "0"
      : getStylesByScaleProps(props.scale, props.iconOnly).padding};
  background-color: ${(props) =>
    getStylesByVariantProps(props.variant, "default").backgroundColor};
  color: ${(props) => getStylesByVariantProps(props.variant, "default").color};
  border: ${(props) =>
    getStylesByVariantProps(props.variant, "default").border};

  html.dark & {
    background-color: ${(props) =>
      getStylesByVariantProps(props.variant, "dark").backgroundColor};
    color: ${(props) => getStylesByVariantProps(props.variant, "dark").color};
    border: ${(props) => getStylesByVariantProps(props.variant, "dark").border};
  }

  display: flex;
  align-items: center;
  flex-direction: ${(props) =>
    props.iconPlace === "right" ? "row-reverse" : "row"};
  justify-content: center;
  gap: 0.5rem;

  > svg {
    height: 1.25rem !important;
    width: 1.25rem !important;
  }

  &:disabled {
    background-color: ${(props) =>
      props.variant === "primary" || props.variant === "danger"
        ? "var(--gray-200) !important"
        : props.variant === "secondary"
          ? "var(--gray-100) !important"
          : "unset !important"};
    color: var(--gray-400) !important;
    cursor: not-allowed !important;

    html.dark & {
      color: var(--gray-600) !important;
      background-color: ${(props) =>
        getStylesByVariantProps(props.variant, "dark").backgroundColor};
    }
  }

  &:not(:disabled):hover {
    background-color: ${(props) =>
      getStylesByVariantProps(props.variant, "default").backgroundColorHover};
    color: ${(props) =>
      getStylesByVariantProps(props.variant, "default").colorHover};
    text-decoration: ${(props) =>
      props.variant === "link" ? "underline" : "none"};
    border-color: ${(props) =>
      props.variant === "secondary" ? "var(--gray-500) !important" : null};
  }

  &:focus {
    outline-color: ${(props) =>
      props.variant === "secondary" ? "var(--gray-300) !important" : null};
    outline-offset: 0px !important;
  }
`;
