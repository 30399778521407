import { useState } from "react";

import { type IGenerateTokenCardProps } from "./interface";
import { StyledCard } from "./styles";

import { Alert } from "~components/Alert";
import { Button } from "~components/Button";
import {
  useFetchCreatePersonalToken,
  useFetchGetPersonalToken,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzInput } from "~solarzui/SolarzInput";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { Spin } from "antd";

const MESSAGE_KEY = "copying_token_message_key";

export function GenerateTokenCard({ style }: IGenerateTokenCardProps) {
  const { message } = useAppConfig();

  const [personalTokenValue, setPersonalTokenValue] = useState<string>("-");
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] =
    useState<boolean>(true);

  const {
    data: personalToken,
    isFetching: isLoadingPersonalToken,
    error: personalTokenError,
    refetch: refetchPersonalToken,
  } = useFetchGetPersonalToken({
    dependencyArray: [],
    options: {
      retry: 1,
      onSuccess(data) {
        setPersonalTokenValue(data.token);
        setIsDeleteButtonDisabled(false);
      },
      onError(error) {
        if (error?.code === 404) {
          setPersonalTokenValue("-");
          setIsDeleteButtonDisabled(true);
        }
      },
    },
  });

  const { mutate: createPersonalToken, isLoading: isCreatePersonalToken } =
    useFetchCreatePersonalToken({
      options: {
        onSuccess: () => {
          refetchPersonalToken();
        },
      },
    });

  async function copyPersonalTokenToClipboard() {
    if (typeof navigator !== "undefined") {
      try {
        message.open({
          content: "Copiando token...",
          type: "loading",
          key: MESSAGE_KEY,
          duration: 0,
        });

        await navigator.clipboard.writeText(personalToken?.token ?? "");

        message.destroy(MESSAGE_KEY);
        message.success("Token copiado para a Área de Transferência");
      } catch (e) {
        message.success("Falha ao copiar o token para a Área de Transferência");
      }
    }
  }

  async function handleSubmit() {
    createPersonalToken();
  }

  return (
    <StyledCard
      style={{
        ...style,
      }}
      styles={{
        header: {
          padding: 0,
          border: "none",
        },
        body: {
          padding: 0,
        },
      }}
      title={
        <div className="flex justify-between items-center gap-4 mb-4">
          <SolarzTypography
            fontWeight="semiBold"
            hierarchy="paragraph1"
            variant="title"
          >
            API
          </SolarzTypography>
          <div className="flex gap-4">
            <SolarzButton
              variant="primary"
              htmlType="button"
              onClick={handleSubmit}
              isLoading={isCreatePersonalToken}
            >
              Gerar novo token
            </SolarzButton>
            <Button.DeleteIntegrationApi
              isDisabled={isDeleteButtonDisabled}
              isLoading={isLoadingPersonalToken}
            />
          </div>
        </div>
      }
    >
      <div className="flex flex-col gap-2">
        <SolarzTypography
          fontWeight="bold"
          hierarchy="paragraph2"
          variant="title"
        >
          Seu token de API pessoal
        </SolarzTypography>
        <div className="flex w-full gap-4">
          <SolarzInput
            inputType="default"
            value={personalTokenValue}
            isDisabled
            icon={<Spin spinning={isLoadingPersonalToken} />}
          />
          <SolarzButton
            htmlType="button"
            variant="secondary"
            onClick={copyPersonalTokenToClipboard}
          >
            Copiar
          </SolarzButton>
        </div>

        {personalTokenError && personalTokenError?.code !== 404 && (
          <Alert.CardError
            errorMessage={personalTokenError.message}
            reloadFn={refetchPersonalToken}
            title="Falha ao carregar token"
            style={{ marginTop: "1rem" }}
          />
        )}
      </div>
    </StyledCard>
  );
}
