import type {
  IProposalCustomFieldFormProps,
  IProposalCustomFieldFormValues,
  IProposalCustomFieldInitialValues,
} from "./interface";
import styles from "./styles";

import { CustomInput } from "~components/CustomInput";
import { Select } from "~components/Select";
import {
  useFetchCreateCustomField,
  useFetchUpdateCustomField,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { useSession } from "~hooks/useSession";
import {
  Button,
  Form,
  Input as AntdInput,
  Radio,
  Select as AntdSelect,
  Flex,
} from "antd";

export function ProposalCustomFieldForm({
  initialValues = {
    id: undefined,
    proposalSection: "INITIAL_DATA",
    type: "TEXT",
    required: false,
    hidden: true,
    defaultValue: "",
    identifier: undefined,
    label: "",
    options: [],
  },
  onCancel,
  onSubmitFail = () => undefined,
  onSubmitSuccess = () => undefined,
}: IProposalCustomFieldFormProps) {
  const { screens } = useAppConfig();

  const { user } = useSession();

  const [form] = Form.useForm<IProposalCustomFieldFormValues>();

  const { mutate: createCustomField, isLoading: isCreatingCustomField } =
    useFetchCreateCustomField({
      options: {
        onSuccess: onSubmitSuccess,
        onError: onSubmitFail,
      },
    });

  const { mutate: updateCustomField, isLoading: isUpdatingCustomField } =
    useFetchUpdateCustomField({
      options: {
        onSuccess: onSubmitSuccess,
        onError: onSubmitFail,
      },
    });

  function handleSubmitForm(data: IProposalCustomFieldFormValues) {
    const parsedDefaultValue = data.defaultValue?.toString() ?? "";

    const customFieldIdToUpdate = initialValues.id;

    if (customFieldIdToUpdate) {
      updateCustomField({
        ...data,
        defaultValue: parsedDefaultValue,
        id: customFieldIdToUpdate,
      });
    } else {
      createCustomField({
        ...data,
        defaultValue: parsedDefaultValue,
      });
    }
  }

  function parseDefaultValue(initialValues: IProposalCustomFieldInitialValues) {
    if (initialValues.type !== "BOOLEAN") return initialValues.defaultValue;

    if (!initialValues.defaultValue) return "";

    return initialValues.defaultValue === "true";
  }

  const type = Form.useWatch("type", form);
  const options = Form.useWatch(
    "options",
    form,
  ) as IProposalCustomFieldFormValues["options"];

  return (
    <Form<IProposalCustomFieldFormValues>
      data-cy="proposal-custom-field-form"
      form={form}
      initialValues={{
        ...initialValues,
        defaultValue: parseDefaultValue(initialValues),
      }}
      onFinish={handleSubmitForm}
      layout="vertical"
    >
      <style jsx>{styles}</style>
      {initialValues?.id && (
        <Flex
          align="center"
          justify="space-between"
          gap={screens.md ? 8 : 0}
          wrap="nowrap"
          style={{ flexDirection: screens.md ? "row" : "column" }}
        >
          <Form.Item
            label="Tipo de proposta"
            name="projectType"
            rules={[{ required: true }]}
          >
            <Select.ProjectType />
          </Form.Item>
        </Flex>
      )}
      <Flex
        align="center"
        justify="space-between"
        gap={screens.md ? 8 : 0}
        wrap="nowrap"
        style={{ flexDirection: screens.md ? "row" : "column" }}
      >
        <Form.Item
          label="Nome a ser mostrado"
          name="label"
          rules={[
            {
              required: true,
              message: "Campo é obrigatório",
            },
          ]}
          style={{ width: "100%" }}
        >
          <CustomInput.Text placeholder="Ex.: Revisão" maxLength={255} />
        </Form.Item>

        <Form.Item
          label="Identificador"
          name="identifier"
          rules={[
            {
              pattern: new RegExp(/^[a-zA-Z0-9_]*$/),
              message: "Apenas letras, números ou underlines",
            },
          ]}
          tooltip="Não pode conter espaços e/ou caracteres especiais"
          style={{ width: "100%" }}
        >
          <CustomInput.Text placeholder="Ex.: kit_solar" />
        </Form.Item>
      </Flex>

      <Flex
        align="center"
        justify="space-between"
        gap={screens.md ? 8 : 0}
        wrap="nowrap"
        style={{ flexDirection: screens.md ? "row" : "column" }}
      >
        <Form.Item
          label="Seção da proposta"
          name="proposalSection"
          required
          style={{ width: "100%" }}
        >
          <Select.ProposalStep
            options={{
              hideKits: true,
              hideProposal: true,
              hidePlant:
                user?.projectType === "EXPRESS" ||
                user?.projectType === "DIVERSE"
                  ? true
                  : false,
            }}
          />
        </Form.Item>

        <Form.Item
          label="Tipo da variável"
          name="type"
          style={{ width: "100%" }}
        >
          <Select.ProposalCustomFieldType
            onChange={() => {
              form.setFieldValue("defaultValue", "");
            }}
          />
        </Form.Item>
      </Flex>

      <Form.Item
        className="inline"
        label="Campo é obrigatório?"
        name="required"
        style={{
          width: screens.sm ? "auto" : "min-content",
        }}
      >
        <Radio.Group
          options={[
            {
              label: "Sim",
              value: true,
            },
            {
              label: "Não",
              value: false,
            },
          ]}
        />
      </Form.Item>

      <Form.Item
        className="inline"
        label="Campo habilitado?"
        name="hidden"
        style={{
          width: screens.sm ? "auto" : "min-content",
        }}
      >
        <Radio.Group
          options={[
            {
              label: "Sim",
              value: true,
            },
            {
              label: "Não",
              value: false,
            },
          ]}
        />
      </Form.Item>

      <Form.Item label="Opções:" name="options" hidden={type !== "SELECT"}>
        <AntdSelect
          mode="tags"
          placeholder="Digite as opções"
          disabled={type !== "SELECT"}
        />
      </Form.Item>

      {type === "BOOLEAN" && (
        <Form.Item label="Valor padrão:" name="defaultValue">
          <Radio.Group
            options={[
              {
                label: "Sim",
                value: true,
              },
              {
                label: "Não",
                value: false,
              },
              {
                label: "Vazio",
                value: "",
              },
            ]}
          />
        </Form.Item>
      )}

      {type === "NUMBER" && (
        <Form.Item label="Valor padrão:" name="defaultValue">
          <CustomInput.Number
            type="number"
            placeholder="Ex.: 32"
            style={{ width: "100%" }}
          />
        </Form.Item>
      )}

      {type === "TEXT" && (
        <Form.Item label="Valor padrão:" name="defaultValue">
          <AntdInput.TextArea
            placeholder="Ex.: Revisão de proposta número 1"
            style={{ width: "100%", maxHeight: 120 }}
            maxLength={255}
          />
        </Form.Item>
      )}

      {type === "SELECT" && (
        <Form.Item label="Valor padrão:" name="defaultValue">
          <AntdSelect
            placeholder="Selecione..."
            disabled={type !== "SELECT"}
            options={options?.map((option) => ({
              label: option,
              value: option,
            }))}
            allowClear
          />
        </Form.Item>
      )}
      {type === "IMAGE" && (
        <>
          <Form.Item
            label="Altura da imagem"
            name="height"
            rules={[
              {
                required: true,
                message: "Por favor, insira a altura da imagem!",
              },
              {
                validator: (_rule, value) => {
                  return value > 0
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error("Valor tem que ser maior que zero!"),
                      );
                },
              },
            ]}
          >
            <CustomInput.Number
              type="number"
              placeholder="Ex.: 32"
              style={{ width: "100%" }}
              precision={0}
            />
          </Form.Item>
          <Form.Item
            label="Largura da imagem"
            name="width"
            rules={[
              {
                required: true,
                message: "Por favor, insira a largura da imagem!",
              },
              {
                validator: (_rule, value) => {
                  return value > 0
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error("Valor tem que ser maior que zero!"),
                      );
                },
              },
            ]}
          >
            <CustomInput.Number
              type="number"
              placeholder="Ex.: 32"
              style={{ width: "100%" }}
              precision={0}
            />
          </Form.Item>
        </>
      )}
      <Form.Item
        name="businessUnits"
        label="Unidades de Negócio (opcional, selecione conforme necessário)"
        tooltip="Ao selecionar unidades de negócio, o campo associado entrará em vigor apenas quando a unidade escolhida estiver ativa."
      >
        <Select.CustomFieldBusinessUnit mode="multiple" />
      </Form.Item>

      <Flex gap={12} justify="end">
        <Form.Item style={{ margin: 0 }}>
          <Button onClick={onCancel}>Cancelar</Button>
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={isCreatingCustomField || isUpdatingCustomField}
          >
            Salvar
          </Button>
        </Form.Item>
      </Flex>
    </Form>
  );
}
