import { useEffect, useState } from "react";

import type { IKitsTableModalProps } from "./interface";
import styles from "./styles";

import { Button } from "~components/Button";
import { Modal } from "~components/Modal";
import { IKitsTableData, IOnSubmitParams, Table } from "~components/Table";
import { useAppConfig } from "~hooks/useAppConfig";
import { useWindowSize } from "~hooks/useWindowSize";
import {
  Button as AntdButton,
  message,
  Modal as AntdModal,
  Tooltip,
  Flex,
} from "antd";

export function KitsTableModal({
  isOpen = false,
  onClose = () => null,
  title = "Detalhes",
  width = "90vw",
  onSubmit = () => undefined,
  isLoading = false,
  canSelect = true,
  isClosable = true,
  tableProps = {
    kits: [],
    defaultFilters: {
      // minFullPower: 0, // IT WILL BE ADDED
      // maxFullPower: 1, // IT WILL BE ADDED
      fullPower: 1,
      phase: ["Monofásico"],
    },
    defaultPagination: {
      number: 1,
      totalElements: 0,
      size: 10,
    },
    defaultSort: {
      by: "fullPower",
      order: "ascend",
    },
    disableSelect: false,
    onSubmit: () => null,
  },
  emptyKitProps,
  sizingKitProps,
  canStartWithEmptyKit = false,
  canCreateSizingKit = false,
  extraFooterOptions,
  styleTop = 48 /* 3rem */,
}: IKitsTableModalProps) {
  const { screens } = useAppConfig();
  const { height: screenHeight } = useWindowSize();

  const [selectedKit, setSelectedKit] = useState<IKitsTableData>();

  const [isEmptyKitModalOpen, setIsEmptyKitModalOpen] = useState(false);

  function handleCloseModal() {
    setIsEmptyKitModalOpen(false);
    onClose();
  }

  useEffect(() => {
    if (isOpen === false) {
      setIsEmptyKitModalOpen(false);
    }
  }, [isOpen]);

  return (
    <AntdModal
      data-cy="kit-table-modal"
      open={isOpen}
      onCancel={handleCloseModal}
      title={title}
      width={width}
      closable={isClosable}
      footer={
        canSelect && (
          <Flex
            justify={screens.md ? "space-between" : "center"}
            align="center"
            style={{
              width: "100%",
              flexDirection: screens.md ? "row" : "column",
            }}
            gap={8}
          >
            <Flex align="center" gap={8} justify="start">
              {extraFooterOptions}
            </Flex>
            <Flex
              align="center"
              gap={8}
              justify={screens.md ? "end" : "center"}
              style={{
                marginRight: screens.lg ? "1rem" : 0,
                flexDirection: screens.md ? "row" : "column",
              }}
            >
              {canCreateSizingKit && (
                <Button.CreateSizingKit
                  type="dashed"
                  className="solarZGhostButton"
                  {...sizingKitProps}
                />
              )}
              {canStartWithEmptyKit && (
                <AntdButton
                  type="dashed"
                  className="solarZGhostButton"
                  onClick={() => setIsEmptyKitModalOpen(true)}
                >
                  Kit vazio
                </AntdButton>
              )}
              <Flex align="center" gap={8} justify="center">
                <AntdButton onClick={onClose} disabled={!isClosable}>
                  Cancelar
                </AntdButton>
                <Tooltip
                  title={selectedKit ? "" : "Necessário selecionar o kit"}
                >
                  <AntdButton
                    type="primary"
                    disabled={!selectedKit}
                    onClick={() => {
                      if (selectedKit) {
                        onSubmit(selectedKit);
                      } else {
                        message.error(
                          "Client error: o kit não foi selecionado!",
                        );
                      }
                    }}
                    loading={isLoading}
                  >
                    Selecionar kit
                  </AntdButton>
                </Tooltip>
              </Flex>
            </Flex>
          </Flex>
        )
      }
      destroyOnClose
      styles={{
        body: {
          height: "calc(100vh - 280px)",
          overflowX: "auto",
        },
      }}
      style={{ top: styleTop, maxWidth: "1980px" }}
    >
      <style jsx>{styles}</style>
      <Table.Kits
        {...tableProps}
        onSelectKit={setSelectedKit}
        disableSelect={!canSelect}
        onSubmit={(params: IOnSubmitParams) => {
          if (typeof tableProps.onSubmit === "function") {
            setSelectedKit(undefined);
            tableProps.onSubmit(params);
          }
        }}
        scroll={{
          x: 1280,
          y: screenHeight - 360,
        }}
      />

      <Modal.EmptyKitForm
        {...emptyKitProps}
        isOpen={isEmptyKitModalOpen}
        onClose={() => setIsEmptyKitModalOpen(false)}
      />
    </AntdModal>
  );
}
