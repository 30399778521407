import type { IAldoIntegrationSwitchProps } from "./interface";
import styles from "./styles";

import { useFetchToggleDistributors } from "~hooks/api";
import { Flex, Switch } from "antd";

export function AldoIntegrationSwitch({
  isChecked = false,
  distributorId = 0,
}: IAldoIntegrationSwitchProps) {
  const { mutate: fetchToggleDistributor, isLoading } =
    useFetchToggleDistributors();

  function handleIntegrationActivationAndDeactivation() {
    fetchToggleDistributor({
      distributorId,
    });
  }

  return (
    <>
      <style jsx>{styles}</style>
      <Flex data-cy="aldo-integration-switch" align="center" gap={8}>
        <Switch
          checked={isChecked}
          checkedChildren="ativo"
          unCheckedChildren="inativo"
          className="aldo-integration-switch"
          onClick={handleIntegrationActivationAndDeactivation}
          loading={isLoading}
        />
      </Flex>
    </>
  );
}
