import type {
  IPerformanceProfileFormProps,
  IPerformanceProfileFormValues,
} from "./interface";

import { CustomInput } from "~components/CustomInput";
import {
  useFetchCreatePerformanceProfile,
  useFetchUpdatePerformanceProfile,
} from "~hooks/api";
import { Button, Flex, Form } from "antd";

export function PerformanceProfileForm({
  initialValues,
  onSuccess = () => undefined,
  onError = () => undefined,
  onCancel = () => undefined,
}: IPerformanceProfileFormProps) {
  const [form] = Form.useForm<IPerformanceProfileFormValues>();

  const {
    mutate: createPerformanceProfile,
    isLoading: isCreatingPerformanceProfile,
  } = useFetchCreatePerformanceProfile({
    options: {
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: (error) => {
        onError(error.message);
      },
    },
  });

  const {
    mutate: updatePerformanceProfile,
    isLoading: isUpdatingPerformanceProfile,
  } = useFetchUpdatePerformanceProfile({
    options: {
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: (error) => {
        onError(error.message);
      },
    },
  });

  function handleSubmitForm(data: IPerformanceProfileFormValues) {
    if (initialValues?.id) {
      updatePerformanceProfile({
        ...data,
        performanceProfileId: initialValues.id,
      });
    } else {
      createPerformanceProfile(data);
    }
  }

  const isLoading =
    isCreatingPerformanceProfile || isUpdatingPerformanceProfile;

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmitForm}
      layout="vertical"
    >
      <Form.Item
        name="description"
        label="Descrição do perfil de performance"
        rules={[{ required: true }]}
        style={{ width: "100%" }}
      >
        <CustomInput.Text />
      </Form.Item>
      <Form.Item
        name="value"
        label="Valor"
        rules={[{ required: true }]}
        style={{ width: "100%" }}
      >
        <CustomInput.UnitMeasurement
          precision="2"
          unitMeasurement="%"
          placeholder="Exemplo: 99,99%"
          min={0}
          max={100}
        />
      </Form.Item>
      <Flex gap={12} justify="end">
        <Form.Item style={{ margin: 0 }}>
          <Button onClick={onCancel}>Cancelar</Button>
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Salvar
          </Button>
        </Form.Item>
      </Flex>
    </Form>
  );
}
