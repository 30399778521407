import { useCallback, useEffect } from "react";

import type {
  IEosFinancingOrganizationFinancingDataFormProps,
  EosFinancingOrganizationFinancingDataFormValuesType,
} from "./interface";

import { GoogleMapsProvider, SelectLocation } from "~components/GoogleMaps";
import { Select } from "~components/Select";
import { useAppConfig } from "~hooks/useAppConfig";
import { useInterval } from "~hooks/useInterval";
import { EOS_FORM_STEP_VALUES_ORGANIZATION_STORAGE_KEY } from "~pages/configuracoes-de-propostas/financiamento/EOS/cadastro/_module/page.config";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzFormItem } from "~solarzui/SolarzFormItem";
import { SolarzInput } from "~solarzui/SolarzInput";
import { SolarzInputNumber } from "~solarzui/SolarzInputNumber";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import {
  getLocalStorageData,
  setLocalStorageData,
} from "~utils/localstorageUtilitFunctions";
import { parseGoogleLocationToAddressEosForm } from "~utils/parseGoogleLocationToAddressForm";
import { Col, Flex, Form, Radio, Row } from "antd";
import dayjs from "dayjs";

export function EosFinancingOrganizationFinancingDataForm({
  formInstance,
  initialValues,
  proposalDetails,
  onNextStep,
  onPrevStep,
}: IEosFinancingOrganizationFinancingDataFormProps) {
  const { screens } = useAppConfig();
  const [form] =
    Form.useForm<EosFinancingOrganizationFinancingDataFormValuesType>(
      formInstance,
    );

  function handleSubmit() {
    if (typeof onNextStep === "function") {
      const formFields = form.getFieldsValue(true);
      handlePersistsDataInStorage(formFields);
      onNextStep();
    }
  }

  const handlePersistsDataInStorage = useCallback(
    (data: EosFinancingOrganizationFinancingDataFormValuesType) => {
      try {
        const objectExisting = getLocalStorageData(
          EOS_FORM_STEP_VALUES_ORGANIZATION_STORAGE_KEY,
        );
        const objectUpdated = {
          ...objectExisting,
          ...data,
        };

        setLocalStorageData(
          EOS_FORM_STEP_VALUES_ORGANIZATION_STORAGE_KEY,
          objectUpdated,
        );
      } catch (error: any) {
        console.warn(error?.message);
      }
    },
    [],
  );

  useInterval(() => {
    const formFields = form.getFieldsValue(true);
    handlePersistsDataInStorage({ ...formFields });
  }, 3000);

  function handleCancel() {
    if (typeof onPrevStep === "function") {
      return onPrevStep();
    }
  }

  useEffect(() => {
    const objectExisting = getLocalStorageData(
      EOS_FORM_STEP_VALUES_ORGANIZATION_STORAGE_KEY,
    );
    const dateString = objectExisting.clienteDataNascimento;

    const clientDateBirth =
      dateString && dayjs(dateString).isValid() ? dayjs(dateString) : undefined;

    if (objectExisting) {
      form.setFieldsValue({
        ...objectExisting,
        clienteDataNascimento: clientDateBirth,
        comSeguro: objectExisting.comSeguro ?? true,
      });
    }
  }, [form, proposalDetails]);

  return (
    <Form
      data-cy="eos-financing-step-two-form"
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmit}
      preserve
      layout="vertical"
    >
      <SolarzTypography
        fontWeight="semiBold"
        hierarchy="paragraph1"
        variant="title"
        style={{ marginTop: "38px", marginBottom: "12px" }}
      >
        Dados da instalação
      </SolarzTypography>

      <Row gutter={24}>
        <Col span={24}>
          <SolarzFormItem label="Buscar endereço no Google">
            <GoogleMapsProvider
              onChangeLocationCallback={(location) => {
                const parsedValue =
                  parseGoogleLocationToAddressEosForm(location);

                form.setFieldsValue({
                  endereco: {
                    bairro: parsedValue?.bairro,
                    cep: parsedValue?.cep,
                    logradouro: parsedValue?.logradouro,
                    cidade: parsedValue?.cidade,
                    estado: parsedValue?.estado,
                    numero: parsedValue?.numero,
                  },
                });
              }}
            >
              <SelectLocation
                style={{
                  width: "100%",
                  height: 48,
                }}
                placeholder="Procurar endereço com o Google"
              />
            </GoogleMapsProvider>
          </SolarzFormItem>
        </Col>
      </Row>
      <Row gutter={screens.mobile ? 16 : 24}>
        <Col span={screens.mobile ? 24 : 8}>
          <SolarzFormItem<EosFinancingOrganizationFinancingDataFormValuesType>
            name={["endereco", "logradouro"]}
            label="Logradouro"
            rules={[{ required: true, message: "Logradouro é obrigatório" }]}
          >
            <SolarzInput
              scale={screens.mobile ? "medium" : "large"}
              placeholder="Ex.: R. Presidente Venceslau"
            />
          </SolarzFormItem>
        </Col>
        <Col span={screens.mobile ? 12 : 8}>
          <SolarzFormItem<EosFinancingOrganizationFinancingDataFormValuesType>
            name={["endereco", "bairro"]}
            label="Bairro"
            rules={[{ required: true, message: "É Obrigatório" }]}
          >
            <SolarzInput
              scale={screens.mobile ? "medium" : "large"}
              placeholder="Ex.: Bairro Senhor divino"
            />
          </SolarzFormItem>
        </Col>

        <Col span={screens.mobile ? 12 : 8}>
          <SolarzFormItem<EosFinancingOrganizationFinancingDataFormValuesType>
            name={["endereco", "numero"]}
            label="Número"
            rules={[{ required: true, message: "É Obrigatório" }]}
          >
            <SolarzInputNumber
              scale={screens.mobile ? "medium" : "large"}
              placeholder="Ex.: 777"
              precision={0}
              min={0}
              onChange={(value) => {
                const parsedValueString = value?.toString() || null;
                form.setFieldValue(["endereco", "numero"], parsedValueString);
              }}
            />
          </SolarzFormItem>
        </Col>
      </Row>
      <Row gutter={screens.mobile ? 16 : 24}>
        <Col span={screens.mobile ? 16 : 12}>
          <SolarzFormItem<EosFinancingOrganizationFinancingDataFormValuesType>
            name={["endereco", "cidade"]}
            label="Cidade"
            rules={[{ required: true, message: "Cidade é obrigatório" }]}
          >
            <SolarzInput
              scale={screens.mobile ? "medium" : "large"}
              placeholder="Ex.: Mossoró"
            />
          </SolarzFormItem>
        </Col>
        <Col span={screens.mobile ? 8 : 6}>
          <SolarzFormItem<EosFinancingOrganizationFinancingDataFormValuesType>
            name={["endereco", "estado"]}
            label="Estado"
            rules={[{ required: true, message: "Estado é obrigatório" }]}
          >
            <Select.BrazilianStateByAcronym
              style={{ height: screens.mobile ? 40 : 48 }}
              placeholder="Ex.: RN"
            />
          </SolarzFormItem>
        </Col>
        <Col span={screens.mobile ? 24 : 6}>
          <SolarzFormItem<EosFinancingOrganizationFinancingDataFormValuesType>
            name={["endereco", "cep"]}
            label="CEP"
            rules={[{ required: true, message: "CEP é obrigatório" }]}
          >
            <SolarzInput
              scale={screens.mobile ? "medium" : "large"}
              placeholder="Ex.: 00000-000"
            />
          </SolarzFormItem>
        </Col>
      </Row>
      <Row gutter={screens.mobile ? 16 : 24}>
        <Col span={screens.mobile ? 24 : 12}>
          <SolarzFormItem<EosFinancingOrganizationFinancingDataFormValuesType>
            name={["endereco", "metragemQuadradaM2"]}
            label="Dimensão do terreno"
            rules={[{ required: true }]}
          >
            <SolarzInputNumber
              inputType="m²"
              scale={screens.mobile ? "medium" : "large"}
              placeholder="Ex.: 30"
            />
          </SolarzFormItem>
        </Col>
        <Col span={screens.mobile ? 24 : 12}>
          <SolarzFormItem<EosFinancingOrganizationFinancingDataFormValuesType>
            name={["endereco", "situacaoImovel"]}
            label="Situação do imóvel"
            rules={[{ required: true }]}
          >
            <Select.PropertySituationEosFinancingPerson
              scale={screens.mobile ? "medium" : "large"}
            />
          </SolarzFormItem>
        </Col>
      </Row>
      <SolarzTypography
        fontWeight="semiBold"
        hierarchy="paragraph1"
        variant="title"
        style={{ marginBottom: "24px" }}
      >
        Dados da organização
      </SolarzTypography>
      <Row gutter={screens.mobile ? 16 : 24}>
        <Col span={screens.mobile ? 24 : 12}>
          <SolarzFormItem<EosFinancingOrganizationFinancingDataFormValuesType>
            name={["cliente", "empresa", "nomeFantasia"]}
            label="Nome fantasia da organização"
            rules={[{ required: true }]}
          >
            <SolarzInput
              scale={screens.mobile ? "medium" : "large"}
              placeholder="Ex.: João da Silva Ltda"
            />
          </SolarzFormItem>
        </Col>

        <Col span={screens.mobile ? 24 : 12}>
          <SolarzFormItem<EosFinancingOrganizationFinancingDataFormValuesType>
            name={["cliente", "empresa", "cnpj"]}
            label="CNPJ"
            rules={[{ required: true }]}
          >
            <SolarzInput
              inputType="cnpj"
              scale={screens.mobile ? "medium" : "large"}
              placeholder="Ex.: 00.000.000/0000-00"
            />
          </SolarzFormItem>
        </Col>
      </Row>
      <Row gutter={screens.mobile ? 16 : 24}>
        <Col span={screens.mobile ? 24 : 12}>
          <SolarzFormItem<EosFinancingOrganizationFinancingDataFormValuesType>
            name={["cliente", "empresa", "contato", "telefone"]}
            label="Telefone"
            rules={[{ required: true }]}
          >
            <SolarzInput
              scale={screens.mobile ? "medium" : "large"}
              inputType="telephone"
              placeholder="Ex.: (00) 00000-0000"
            />
          </SolarzFormItem>
        </Col>
        <Col span={screens.mobile ? 24 : 12}>
          <SolarzFormItem<EosFinancingOrganizationFinancingDataFormValuesType>
            name={["cliente", "empresa", "contato", "email"]}
            label="Email"
            rules={[{ required: true }]}
          >
            <SolarzInput
              scale={screens.mobile ? "medium" : "large"}
              placeholder="Ex.: joaodasilva77@mail.com"
            />
          </SolarzFormItem>
        </Col>
      </Row>
      <Row gutter={screens.mobile ? 16 : 24}>
        <Col span={screens.mobile ? 12 : 12}>
          <SolarzFormItem<EosFinancingOrganizationFinancingDataFormValuesType>
            name={["cliente", "rendaMensal"]}
            label="Renda mensal"
            rules={[{ required: true, message: "Renda mensal é obrigatório" }]}
          >
            <SolarzInputNumber
              scale={screens.mobile ? "medium" : "large"}
              placeholder="Ex.: 3.700,00"
              inputType="R$"
            />
          </SolarzFormItem>
        </Col>
        <Col span={screens.mobile ? 12 : 12}>
          <SolarzFormItem<EosFinancingOrganizationFinancingDataFormValuesType>
            name={["cliente", "outrasRendas"]}
            label="Outras rendas"
            rules={[{ required: true, message: "É Obrigatório" }]}
          >
            <SolarzInputNumber
              scale={screens.mobile ? "medium" : "large"}
              placeholder="Ex.: 0"
              inputType="R$"
            />
          </SolarzFormItem>
        </Col>

        {/* <Col span={screens.mobile ? 6 : 8}>
          <SolarzFormItem<EosFinancingOrganizationFinancingDataFormValuesType>
            name={["cliente", " "]}
            label="Patrimônio"
            rules={[{ required: true, message: "É Obrigatório" }]}
          >
            <SolarzInputNumber
              scale={screens.mobile ? "medium" : "large"}
              placeholder="Ex.: 35.000"
              inputType="R$"
            />
          </SolarzFormItem>
        </Col> */}
      </Row>
      <Row>
        <Col span={24}>
          <SolarzFormItem<EosFinancingOrganizationFinancingDataFormValuesType>
            name="comSeguro"
            label="Com seguro"
            rules={[{ required: true, message: "É Obrigatório" }]}
          >
            <Radio.Group
              defaultValue={true}
              options={[
                {
                  label: <SolarzTypography>Sim</SolarzTypography>,
                  value: true,
                },
                {
                  label: <SolarzTypography>Não</SolarzTypography>,
                  value: false,
                },
              ]}
            />
          </SolarzFormItem>
        </Col>
      </Row>
      <Flex
        style={{ marginTop: 12 }}
        align="center"
        gap={16}
        justify="space-between"
      >
        <SolarzButton variant="secondary" scale="large" onClick={handleCancel}>
          Voltar
        </SolarzButton>
        <SolarzButton scale="large" htmlType="submit">
          Próximo
        </SolarzButton>
      </Flex>
    </Form>
  );
}
