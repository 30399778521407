import { useState } from "react";
import { PiDotOutlineFill } from "react-icons/pi";

import { IDeleteOrganizationModalProps } from "./interface";
import { StyledAlert } from "./style";

import { Card } from "~components/Card";
import {
  useFetchDeleteOrganization,
  useFetchDeleteOrganizationPipedrive,
  useFetchGetOrganizationById,
} from "~hooks/api";
import { useSession } from "~hooks/useSession";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { Checkbox, Modal } from "antd";

export function DeleteOrganizationModal({
  organizationId,
  isOpen = false,
  onClose = () => null,
  title = "Apagar organização",
  maxWidth = 520,
  onError,
  onSuccess,
}: IDeleteOrganizationModalProps) {
  const { user } = useSession();
  const [deleteFromPipedriveToo, setDeleteFromPipedriveToo] = useState(false);

  const {
    data: organizationData,
    isFetching: isLoadingOrganization,
    error: organizationError,
  } = useFetchGetOrganizationById({
    payload: {
      organizationId,
    },
    dependencyArray: [organizationId],
    options: {
      enabled: organizationId > 0,
      retry: 1,
    },
  });

  const { mutate: deleteOrganization, isLoading: isDeletingOrganization } =
    useFetchDeleteOrganization({
      options: {
        onSuccess: () => {
          if (typeof onSuccess === "function") {
            onSuccess();
          }

          onClose();
        },
        onError,
      },
    });

  const {
    mutate: deleteOrganizationPipedrive,
    isLoading: isDeletingOrganizationPipedrive,
  } = useFetchDeleteOrganizationPipedrive({
    onSuccess: () => {
      if (typeof onSuccess === "function") {
        onSuccess();
      }

      onClose();
    },
    onError,
  });

  function handleDeleteOrganization() {
    deleteOrganization({ organizationId });

    if (
      user?.pipedriveIntegration &&
      deleteFromPipedriveToo &&
      organizationData?.pipedriveId
    ) {
      deleteOrganizationPipedrive({
        pipedriveId: organizationData.pipedriveId,
      });
    }
  }

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title={title}
      data-cy="delete-organization-modal"
      style={{
        width: "100%",
        maxWidth,
      }}
      okText="Apagar"
      onOk={handleDeleteOrganization}
      okButtonProps={{
        loading: isDeletingOrganization || isDeletingOrganizationPipedrive,
        disabled: isLoadingOrganization || !!organizationError,
      }}
    >
      <div className="flex flex-col gap-6">
        <StyledAlert
          type="error"
          description={
            <div className="flex flex-col gap-2">
              <SolarzTypography
                variant="danger"
                fontWeight="medium"
                hierarchy="paragraph2"
              >
                Tem certeza que deseja apagar essa organização?
              </SolarzTypography>

              <div className="w-full flex flex-row gap-1 align-middle text-red-600">
                <PiDotOutlineFill size={24} />
                <SolarzTypography
                  variant="danger"
                  hierarchy="small"
                  fontWeight="regular"
                >
                  Todas as <strong>notas</strong>, <strong>arquivos</strong> e{" "}
                  <strong>documentos</strong> associados a essa organização
                  serão removidos.
                </SolarzTypography>
              </div>
            </div>
          }
        />

        <Card.DeleteOrganization organizationId={organizationId} />

        {!!user?.pipedriveIntegration && organizationData?.pipedriveId && (
          <div className="w-full">
            <Checkbox
              checked={deleteFromPipedriveToo}
              onChange={(e) => {
                setDeleteFromPipedriveToo(e.target.checked);
              }}
            >
              Excluir também do Pipedrive
            </Checkbox>
          </div>
        )}
      </div>
    </Modal>
  );
}
