import { MdOutlineEmail } from "react-icons/md";

import type { ILoginFormProps, ILoginFormValues } from "./interface";

import { useSession } from "~hooks/useSession";
import { SolarzAnchor } from "~solarzui/SolarzAnchor";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzFormItem } from "~solarzui/SolarzFormItem";
import { SolarzInput } from "~solarzui/SolarzInput";
import { SolarzInputPassword } from "~solarzui/SolarzInputPassword";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { Flex, Form } from "antd";

export function LoginForm({
  initialValues,
  displayLinkToRecoveryPassword = true,
}: ILoginFormProps) {
  const { signInWithCredentials, sessionStatus } = useSession();

  const isLoading = sessionStatus === "loading";

  function handleLogin(formData: ILoginFormValues) {
    signInWithCredentials({
      user: formData.email,
      password: formData.password,
    });
  }

  return (
    <Form
      data-cy="login-form"
      onFinish={handleLogin}
      initialValues={initialValues}
    >
      <SolarzFormItem
        label="Email"
        rules={[
          {
            required: true,
            message: "Email é obrigatório",
          },
          {
            pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            message: "Formato de email é inválido!",
          },
        ]}
        name="email"
      >
        <SolarzInput
          scale="large"
          placeholder="Email"
          icon={<MdOutlineEmail />}
        />
      </SolarzFormItem>

      <SolarzFormItem
        label="Senha"
        rules={[
          {
            required: true,
            message: "Senha é obrigatória",
          },
          {
            validator: (_rule, value: string) => {
              return value.trim().length > 0
                ? Promise.resolve()
                : Promise.reject("Senha não pode ser vazia!");
            },
          },
        ]}
        name="password"
      >
        <SolarzInputPassword placeholder="Senha" />
      </SolarzFormItem>

      {displayLinkToRecoveryPassword && (
        <Flex
          justify="flex-end"
          style={{ padding: "0.375rem 0", marginBottom: "1rem" }}
        >
          <SolarzAnchor isNextLink href="/recuperar-senha">
            <SolarzTypography
              hierarchy="paragraph2"
              fontWeight="medium"
              variant="link"
            >
              Recuperar minha senha
            </SolarzTypography>
          </SolarzAnchor>
        </Flex>
      )}

      <SolarzButton htmlType="submit" isBlock isLoading={isLoading}>
        Entrar
      </SolarzButton>
    </Form>
  );
}
