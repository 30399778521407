import { useEffect, useState } from "react";
import { CiStopwatch } from "react-icons/ci";
import { GoPeople } from "react-icons/go";
import { IoNewspaperOutline } from "react-icons/io5";
import { LuPhone } from "react-icons/lu";
import { MdOutlineEmail } from "react-icons/md";

import DynamicImport from "next/dynamic";

import type { ActivityFormValuesType, IActivityFormProps } from "./interface";
import { DeleteButton, RadioGroup } from "./styles";

const UserMentionRichTextEditor =
  DynamicImport<IUserMentionRichTextEditorProps>(
    () =>
      import("~components/RichTextEditor/UserMentionRichTextEditor").then(
        (mod) => mod.UserMentionRichTextEditor,
      ),
    { ssr: false, loading: () => <Spin spinning /> },
  );

import type { IUserMentionRichTextEditorProps } from "~components/RichTextEditor/UserMentionRichTextEditor/interface";
import { Select } from "~components/Select";
import { Delete } from "~estrela-ui/icons";
import {
  useFetchCreateActivity,
  useFetchDeleteActivity,
  useFetchGetActivityById,
  useFetchUpdateActivity,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzFormItem } from "~solarzui/SolarzFormItem";
import { SolarzInput } from "~solarzui/SolarzInput";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import type { IActivityDTOSchema, IActivityFormSchema } from "~types/schemas";
import {
  Alert,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Flex,
  Form,
  Radio,
  Row,
  Spin,
  TimePicker,
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import { EditorState, ContentState, convertFromHTML } from "draft-js";

function parseActivityDtoToActivityFormValues(
  data: IActivityDTOSchema,
): ActivityFormValuesType {
  return {
    addressId: data.address?.id,
    allDay: data.allDay,
    busyFree: data.busyFree,
    clientId: data.client?.id,
    organizationId: data.organization?.id,
    date: {
      end: dayjs(data.endDate),
      start: dayjs(data.beginDate),
    },
    notes: {
      text: data?.notes,
      mentions: [],
    },
    eventType: data.eventType,
    id: data.id,
    hour: {
      end: dayjs(data.endDate),
      start: dayjs(data.beginDate),
    },
    isDone: data.status === "DONE",
    name: data.name,
    owner: data.owner?.id,
    priority: data.priority,
  };
}

// need be a function because of dayjs must be exact now.
function getDefaultInitialValues() {
  const now = dayjs();

  return {
    eventType: "CALL",
    priority: "MEDIUM",
    allDayDate: now,
    date: {
      end: now.add(30, "minutes"),
      start: now,
    },
    hour: {
      end: now.add(30, "minutes"),
      start: now,
    },
    notes: undefined,
    isDone: undefined,
    organizationId: undefined,
    clientId: undefined,
    name: undefined,
    owner: undefined,
  } as Partial<ActivityFormValuesType>;
}

export function ActivityForm({
  activityId,
  formInstance,
  hideDeleteButton = false,
  initialValues,
  onCancel,
  onError,
  onSuccess,
  onDeleteError,
  onDeleteSuccess,
}: IActivityFormProps) {
  const { screens } = useAppConfig();
  const [form] = Form.useForm<ActivityFormValuesType>(formInstance);

  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty(),
  );

  const IS_TO_UPDATE_FORM = typeof activityId === "number" && activityId > 0;
  const dealId = initialValues?.dealId;

  const formWatcherEventType = Form.useWatch("eventType", form);
  const formWatcherAllDay = Form.useWatch("allDay", form);
  const formWatcherDealId = Form.useWatch("dealId", form);
  const formWatcherOrganizationId = Form.useWatch("organizationId", form);

  const {
    data: activityInitialData,
    isLoading: isLoadingActivityInitialData,
    error: activityInitialDataError,
  } = useFetchGetActivityById({
    dependencyArray: [activityId],
    options: {
      enabled: IS_TO_UPDATE_FORM,
      retry: 1,
    },
    payload: {
      activityId: activityId ?? 0,
    },
  });

  const { mutate: createActivity, isLoading: isCreatingActivity } =
    useFetchCreateActivity({
      options: {
        onSuccess,
        onError,
      },
    });

  const { mutate: updateActivity, isLoading: isUpdatingActivity } =
    useFetchUpdateActivity({
      options: {
        onSuccess,
        onError,
      },
    });

  const { mutate: deleteActivity, isLoading: isDeletingActivity } =
    useFetchDeleteActivity({
      options: {
        onSuccess: onDeleteSuccess,
        onError: onDeleteError,
      },
    });

  function handleSubmit(formValues: ActivityFormValuesType) {
    const dateAndHourAreEqual = checkIfDateAndHourAreEqual();

    if (dateAndHourAreEqual) {
      return form.setFields([
        {
          name: ["date", "start"],
          errors: ["A data e hora estão iguais!"],
        },
        {
          name: ["date", "end"],
          errors: ["A data e hora estão iguais!"],
        },
        {
          name: ["hour", "start"],
          errors: ["A data e hora estão iguais!"],
        },
        {
          name: ["hour", "end"],
          errors: ["A data e hora estão iguais!"],
        },
      ]);
    }

    const getBeginDate = () => {
      if (formValues.allDay) {
        const allDayDate = formValues.allDayDate ?? dayjs();
        return allDayDate.startOf("day");
      }

      if (formValues.date.start && formValues.hour.start) {
        const hour = formValues.hour.start.hour();
        const minute = formValues.hour.start.minute();
        return formValues.date.start
          .set("hour", hour)
          .set("minute", minute)
          .add(-3, "hours");
      }

      return undefined;
    };

    const getEndDate = () => {
      if (formValues.allDay) {
        const allDayDate = formValues.allDayDate ?? dayjs();
        return allDayDate.endOf("day");
      }

      if (formValues.date.end && formValues.hour.end) {
        const hour = formValues.hour.end.hour();
        const minute = formValues.hour.end.minute();
        return formValues.date.end
          .set("hour", hour)
          .set("minute", minute)
          .add(-3, "hours");
      }

      return undefined;
    };

    const beginDate = getBeginDate();
    const endDate = getEndDate();

    const payload: IActivityFormSchema = {
      addressId: formValues.addressId,
      allDay: formValues.allDay,
      busyFree: formValues.busyFree,
      clientId: formValues.clientId,
      notes: formValues.notes?.text,
      mentionedIds: formValues.notes?.mentions,
      eventType: formValues.eventType,
      name: formValues.name,
      organizationId: formValues.organizationId,
      dealId: formValues.dealId,
      owner: formValues.owner,
      priority: formValues.priority,
      endDate: endDate?.toISOString(),
      beginDate: beginDate?.toISOString(),
      status: formValues.isDone ? "DONE" : "PENDING",
    };

    return IS_TO_UPDATE_FORM
      ? updateActivity({ ...payload, id: activityId })
      : createActivity(payload);
  }

  function handleCancel() {
    if (typeof onCancel === "function") {
      return onCancel();
    }

    if (!activityInitialData) {
      setEditorState(EditorState.createEmpty());

      return form.setFieldsValue({
        ...getDefaultInitialValues(),
        ...initialValues,
      });
    }

    const formValues =
      parseActivityDtoToActivityFormValues(activityInitialData);

    const editorStateString = formValues.notes?.text;

    if (editorStateString) {
      const content = ContentState.createFromBlockArray(
        convertFromHTML(
          `<p>${editorStateString.replaceAll("\n", "<br />")}</p>`,
        ).contentBlocks,
      );

      setEditorState(EditorState.createWithContent(content));
    } else {
      setEditorState(EditorState.createEmpty());
    }

    form.setFieldsValue(formValues);
  }

  function handleDelete(activityId: number) {
    deleteActivity({ activityId });

    form.setFieldsValue({
      ...getDefaultInitialValues(),
      ...initialValues,
    });
  }

  function checkIfDateAndHourAreEqual() {
    const startDate = form.getFieldValue(["date", "start"]) as
      | Dayjs
      | undefined;

    if (!startDate) return false;

    const endDate = form.getFieldValue(["date", "start"]) as Dayjs | undefined;

    if (!endDate) return false;

    const isDayEqual = startDate.isSame(endDate, "day");

    if (!isDayEqual) return false;

    const startHour = form.getFieldValue(["hour", "start"]) as
      | Dayjs
      | undefined;

    if (!startHour) return false;

    const endHour = form.getFieldValue(["hour", "end"]) as Dayjs | undefined;

    if (!endHour) return false;

    const isHourEqual = startHour.isSame(endHour, "hours");
    const isMinutesEqual = startHour.isSame(endHour, "minutes");

    return isHourEqual && isMinutesEqual;
  }

  useEffect(() => {
    if (activityInitialData) {
      const formValues =
        parseActivityDtoToActivityFormValues(activityInitialData);
      form.setFieldsValue(formValues);
    }
  }, [form, activityInitialData]);

  // set rich text editor text
  useEffect(() => {
    if (typeof window === "undefined") return;

    if (activityInitialData?.notes) {
      const content = ContentState.createFromBlockArray(
        convertFromHTML(
          `<p>${activityInitialData.notes.replaceAll("\n", "<br />")}</p>`,
        ).contentBlocks,
      );
      setEditorState(EditorState.createWithContent(content));
    }
  }, [activityInitialData?.notes]);

  const isSubmittingForm = isCreatingActivity || isUpdatingActivity;

  return (
    <Form
      form={form}
      initialValues={{
        ...getDefaultInitialValues(),
        ...initialValues,
      }}
      onFinish={handleSubmit}
      preserve
      layout="vertical"
    >
      {activityInitialDataError && (
        <Alert
          message={
            <Flex vertical>
              <SolarzTypography
                variant="danger"
                fontWeight="medium"
                hierarchy="small"
              >
                Falha ao carregar:
              </SolarzTypography>
              <SolarzTypography
                variant="danger"
                fontWeight="regular"
                hierarchy="small"
              >
                {activityInitialDataError.message}
              </SolarzTypography>
            </Flex>
          }
          style={{ marginBottom: "0.5rem" }}
          type="error"
        />
      )}

      <Spin spinning={isLoadingActivityInitialData} tip="Carregando...">
        <SolarzFormItem<ActivityFormValuesType>
          name="id"
          required={false}
          hidden
        >
          <SolarzInput readOnly />
        </SolarzFormItem>

        <Flex align="center" justify="space-between" gap={8} wrap="wrap">
          <SolarzFormItem<ActivityFormValuesType>
            name="eventType"
            style={{ minWidth: screens.desktop ? 250 : "unset" }}
          >
            <RadioGroup
              style={{
                marginBottom: 0,
                display: "flex",
                alignItems: "center",
                height: 32,
              }}
              defaultValue="CALL"
            >
              <Radio.Button value="CALL">
                <Flex align="center" gap={8}>
                  <LuPhone size={18} />
                  <span
                    className={
                      formWatcherEventType === "CALL" && screens.desktop
                        ? undefined
                        : "is-hidden"
                    }
                  >
                    Chamada
                  </span>
                </Flex>
              </Radio.Button>
              <Radio.Button value="MEETING">
                <Flex align="center" gap={8}>
                  <GoPeople size={18} />
                  <span
                    className={
                      formWatcherEventType === "MEETING" && screens.desktop
                        ? undefined
                        : "is-hidden"
                    }
                  >
                    Reunião
                  </span>
                </Flex>
              </Radio.Button>
              <Radio.Button value="TASK">
                <Flex align="center" gap={8}>
                  <CiStopwatch size={18} strokeWidth={0.75} />
                  <span
                    className={
                      formWatcherEventType === "TASK" && screens.desktop
                        ? undefined
                        : "is-hidden"
                    }
                  >
                    Tarefa
                  </span>
                </Flex>
              </Radio.Button>
              <Radio.Button value="EMAIL">
                <Flex align="center" gap={8}>
                  <MdOutlineEmail size={18} />
                  <span
                    className={
                      formWatcherEventType === "EMAIL" && screens.desktop
                        ? undefined
                        : "is-hidden"
                    }
                  >
                    Email
                  </span>
                </Flex>
              </Radio.Button>
              <Radio.Button value="PROPOSAL">
                <Flex align="center" gap={8}>
                  <IoNewspaperOutline size={18} />
                  <span
                    className={
                      formWatcherEventType === "PROPOSAL" && screens.desktop
                        ? undefined
                        : "is-hidden"
                    }
                  >
                    Proposta
                  </span>
                </Flex>
              </Radio.Button>
            </RadioGroup>
          </SolarzFormItem>

          <SolarzFormItem<ActivityFormValuesType>
            name="priority"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flexGrow: "1",
              width: "auto",
            }}
            rules={[{ required: true, message: "Obrigatório" }]}
          >
            <Select.ActivityPriority
              style={{ width: "100%", minWidth: 144 }}
              scale="tiny"
              allowClear={false}
            />
          </SolarzFormItem>
        </Flex>

        <SolarzFormItem<ActivityFormValuesType>
          name="name"
          rules={[{ required: true, message: "Campo obrigatório" }]}
        >
          <SolarzInput scale="large" placeholder="Nome da atividade" />
        </SolarzFormItem>

        {formWatcherAllDay && (
          <SolarzFormItem<ActivityFormValuesType>
            name="allDayDate"
            label="Selecione a data"
            style={{ marginTop: "1rem" }}
            rules={[{ required: true, message: "Data é obrigatória" }]}
          >
            <DatePicker
              style={{ width: "100%", height: "2.5rem" }}
              format="ddd, DD MMM. YYYY"
              disabled={!formWatcherAllDay}
            />
          </SolarzFormItem>
        )}

        {!formWatcherAllDay && (
          <Row gutter={16} style={{ marginBottom: -8 }}>
            <Col span={12}>
              <Flex vertical gap={8}>
                <SolarzTypography hierarchy="paragraph2" variant="title">
                  <span className="text-red-500 pointer-events-none select-none">
                    *{" "}
                  </span>
                  Início
                </SolarzTypography>

                <SolarzFormItem<ActivityFormValuesType>
                  name={["date", "start"]}
                  rules={[
                    {
                      validator: () => {
                        const startDate = form.getFieldValue([
                          "date",
                          "start",
                        ]) as Dayjs | undefined;

                        return startDate
                          ? Promise.resolve()
                          : Promise.reject("Data inicial é obrigatória");
                      },
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%", height: "2.5rem" }}
                    format="ddd, DD MMM. YYYY"
                    disabled={formWatcherAllDay}
                    onChange={(initialDate) => {
                      if (!initialDate) return;

                      const endDate = form.getFieldValue(["date", "end"]) as
                        | Dayjs
                        | undefined;

                      if (!endDate) return;

                      return initialDate.isAfter(endDate, "date")
                        ? form.setFieldsValue({
                            date: {
                              end: initialDate,
                              start: endDate,
                            },
                          })
                        : form.setFieldValue(["date", "start"], initialDate);
                    }}
                  />
                </SolarzFormItem>

                <SolarzFormItem<ActivityFormValuesType>
                  name={["hour", "start"]}
                  rules={[
                    {
                      validator: () => {
                        const startHour = form.getFieldValue([
                          "hour",
                          "start",
                        ]) as Dayjs | undefined;

                        return startHour
                          ? Promise.resolve()
                          : Promise.reject("Hora inicial é obrigatória");
                      },
                    },
                  ]}
                >
                  <TimePicker
                    format={{ format: "HH:mm" }}
                    style={{ width: "100%", height: "2.5rem" }}
                    placeholder="Selecione hora"
                    disabled={formWatcherAllDay}
                    needConfirm={false}
                    minuteStep={5}
                    onChange={(startHour) => {
                      if (!startHour) return;

                      const endHour = form.getFieldValue(["hour", "end"]) as
                        | Dayjs
                        | undefined;

                      if (!endHour) {
                        return form.setFieldValue(["hour", "start"], startHour);
                      }

                      const startDate = form.getFieldValue([
                        "date",
                        "start",
                      ]) as Dayjs | undefined;

                      const endDate = form.getFieldValue(["date", "end"]) as
                        | Dayjs
                        | undefined;

                      if (!startDate || !endDate) {
                        return form.setFieldValue(["hour", "start"], startHour);
                      }

                      const datesAreEqualInDay = startDate.isSame(endDate, "D");

                      if (!datesAreEqualInDay) {
                        return form.setFieldValue(["hour", "start"], startHour);
                      }

                      startHour.isAfter(endHour, "hour")
                        ? form.setFieldsValue({
                            hour: {
                              end: startHour,
                              start: endHour,
                            },
                          })
                        : form.setFieldValue(["hour", "start"], startHour);
                    }}
                  />
                </SolarzFormItem>
              </Flex>
            </Col>
            <Col span={12}>
              <Flex vertical gap={8}>
                <SolarzTypography hierarchy="paragraph2" variant="title">
                  <span className="text-red-500 pointer-events-none select-none">
                    *{" "}
                  </span>
                  Fim
                </SolarzTypography>

                <SolarzFormItem<ActivityFormValuesType>
                  name={["date", "end"]}
                  rules={[
                    {
                      validator: () => {
                        const endDate = form.getFieldValue(["date", "end"]) as
                          | Dayjs
                          | undefined;

                        return endDate
                          ? Promise.resolve()
                          : Promise.reject("Data inicial é obrigatória");
                      },
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%", height: "2.5rem" }}
                    format="ddd, DD MMM. YYYY"
                    disabled={formWatcherAllDay}
                    onChange={(endDate) => {
                      if (!endDate) return;

                      const startDate = form.getFieldValue([
                        "date",
                        "start",
                      ]) as Dayjs | undefined;

                      if (!startDate) return;

                      return endDate.isBefore(startDate, "date")
                        ? form.setFieldsValue({
                            date: {
                              end: startDate,
                              start: endDate,
                            },
                          })
                        : form.setFieldValue(["date", "end"], endDate);
                    }}
                  />
                </SolarzFormItem>

                <SolarzFormItem<ActivityFormValuesType>
                  name={["hour", "end"]}
                  rules={[
                    {
                      validator: () => {
                        const endHour = form.getFieldValue(["hour", "end"]) as
                          | Dayjs
                          | undefined;

                        return endHour
                          ? Promise.resolve()
                          : Promise.reject("Hora final é obrigatória");
                      },
                    },
                  ]}
                >
                  <TimePicker
                    format={{ format: "HH:mm" }}
                    style={{ width: "100%", height: "2.5rem" }}
                    placeholder="Selecione hora"
                    disabled={formWatcherAllDay}
                    needConfirm={false}
                    minuteStep={5}
                    onChange={(endHour) => {
                      if (!endHour) return;

                      const startHour = form.getFieldValue([
                        "hour",
                        "start",
                      ]) as Dayjs | undefined;

                      if (!startHour) {
                        return form.setFieldValue(["hour", "end"], endHour);
                      }

                      const startDate = form.getFieldValue([
                        "date",
                        "start",
                      ]) as Dayjs | undefined;

                      const endDate = form.getFieldValue(["date", "end"]) as
                        | Dayjs
                        | undefined;

                      if (!startDate || !endDate) {
                        return form.setFieldValue(["hour", "end"], endHour);
                      }

                      const datesAreEqualInDay = startDate.isSame(endDate, "D");

                      if (!datesAreEqualInDay) {
                        return form.setFieldValue(["hour", "end"], endHour);
                      }

                      endHour.isBefore(startHour, "hour")
                        ? form.setFieldsValue({
                            hour: {
                              end: startHour,
                              start: endHour,
                            },
                          })
                        : form.setFieldValue(["hour", "end"], endHour);
                    }}
                  />
                </SolarzFormItem>
              </Flex>
            </Col>
          </Row>
        )}

        <SolarzFormItem<ActivityFormValuesType>
          valuePropName="checked"
          name="allDay"
          style={{ marginBottom: 16 }}
        >
          <Checkbox>Atividade irá durar o dia todo</Checkbox>
        </SolarzFormItem>

        <SolarzFormItem<ActivityFormValuesType>
          name="owner"
          label="Proprietário"
          style={{ marginBottom: 16 }}
          rules={[{ required: true, message: "Proprietário é obrigatório" }]}
        >
          <Select.User
            placeholder="Selecione o proprietário..."
            allowClear={false}
            useDefaultValue
          />
        </SolarzFormItem>

        <SolarzFormItem<ActivityFormValuesType>
          name="dealId"
          label="Negócio"
          style={{ marginBottom: 16 }}
        >
          <Select.DealAutoComplete
            dealId={dealId}
            onChange={(value, option) => {
              if (!value) {
                return form.setFieldsValue({
                  dealId: undefined,
                  organizationId: undefined,
                  clientId: undefined,
                });
              }

              if (Array.isArray(value) || Array.isArray(option)) return;

              form.setFieldsValue({
                dealId: value,
                organizationId: option?.record.organization?.id,
                clientId: option?.record.person?.id,
              });
            }}
            placeholder="Buscar negócio..."
          />
        </SolarzFormItem>

        <SolarzFormItem<ActivityFormValuesType>
          name="organizationId"
          label="Organização"
          style={{ marginBottom: 16 }}
          rules={[
            {
              validator: () => {
                const { organizationId, clientId } = form.getFieldsValue();

                return organizationId || clientId
                  ? Promise.resolve()
                  : Promise.reject(
                      "Organização ou Pessoa devem estar preenchidos",
                    );
              },
            },
          ]}
        >
          <Select.Organization placeholder="Selecione a organização..." />
        </SolarzFormItem>

        <SolarzFormItem<ActivityFormValuesType>
          name="clientId"
          label="Pessoa"
          style={{ marginBottom: 16 }}
          rules={[
            {
              validator: () => {
                const { organizationId, clientId } = form.getFieldsValue();

                return organizationId || clientId
                  ? Promise.resolve()
                  : Promise.reject(
                      "Organização ou Pessoa devem estar preenchidos",
                    );
              },
            },
          ]}
        >
          <Select.Person
            placeholder="Selecione a pessoa..."
            filters={
              formWatcherDealId
                ? undefined
                : {
                    organizationId: formWatcherOrganizationId,
                  }
            }
          />
        </SolarzFormItem>

        <SolarzFormItem<ActivityFormValuesType> name="notes" label="Anotações">
          <UserMentionRichTextEditor
            style={{ minHeight: 90, maxHeight: 320 }}
            placeholder="Anotações..."
            defaultValue={activityInitialData?.notes}
            onEditorTextChange={(content) =>
              form.setFieldValue("notes", content)
            }
            editorState={editorState}
          />
        </SolarzFormItem>

        <Divider style={{ marginBottom: 16 }} />

        <Flex
          vertical={screens.mobile}
          align={screens.mobile ? undefined : "center"}
          justify="space-between"
        >
          <SolarzFormItem<ActivityFormValuesType>
            valuePropName="checked"
            name="isDone"
            style={{ marginBottom: screens.mobile ? 24 : undefined }}
          >
            <Checkbox>Marcar como feita</Checkbox>
          </SolarzFormItem>

          <Flex
            gap={16}
            justify={screens.mobile ? "space-between" : undefined}
            align="center"
            style={{ width: screens.mobile ? "100%" : undefined }}
          >
            {!hideDeleteButton && IS_TO_UPDATE_FORM && (
              <DeleteButton
                icon={<Delete />}
                variant="link"
                scale="tiny"
                onClick={() => handleDelete(activityId)}
                isLoading={isDeletingActivity}
              >
                Apagar
              </DeleteButton>
            )}

            <Flex gap={16}>
              <SolarzButton
                variant="secondary"
                scale="tiny"
                onClick={handleCancel}
              >
                Cancelar
              </SolarzButton>
              <SolarzButton
                scale="tiny"
                htmlType="submit"
                isLoading={isSubmittingForm}
              >
                Salvar
              </SolarzButton>
            </Flex>
          </Flex>
        </Flex>
      </Spin>
    </Form>
  );
}
