import { useMemo } from "react";

import type {
  IEdeltecIntegrationFormProps,
  IEdeltecIntegrationFormValues,
} from "./interface";

import { CustomInput } from "~components/CustomInput";
import { useFetchUpdateConfigEdeltecDistributorIntegration } from "~hooks/api";
import { checkIfIsValidUuid } from "~utils/checkIfIsValidUuid";
import { Button, Flex, Form } from "antd";

const DEFAULT_VALUES: IEdeltecIntegrationFormValues = {
  accessKey: "",
  password: "",
};

export function EdeltecIntegrationForm({
  initialValues,
  formInstance,
  onCancel = () => undefined,
  onError = () => undefined,
  onSuccess = () => undefined,
}: IEdeltecIntegrationFormProps) {
  const [form] = Form.useForm<IEdeltecIntegrationFormValues>(formInstance);

  const { mutate: updateConfigEdeltec, isLoading: isUpdatingConfigEdeltec } =
    useFetchUpdateConfigEdeltecDistributorIntegration({
      onError,
      onSuccess,
    });

  function handleSubmitForm(formValues: IEdeltecIntegrationFormValues) {
    updateConfigEdeltec(formValues);
  }

  const INITIAL_VALUES: IEdeltecIntegrationFormValues = useMemo(
    () => ({
      ...DEFAULT_VALUES,
      ...initialValues,
    }),
    [initialValues],
  );

  return (
    <Form
      data-cy="edeltec-integration-form"
      form={form}
      onFinish={handleSubmitForm}
      initialValues={INITIAL_VALUES}
      layout="vertical"
      style={{ width: "100%" }}
    >
      <Form.Item<IEdeltecIntegrationFormValues>
        name="accessKey"
        label="Chave de acesso"
        rules={[
          { required: true, message: "Campo é obrigatório" },
          {
            validator: (_, value) => {
              return checkIfIsValidUuid(value)
                ? Promise.resolve()
                : Promise.reject("Formato inválido");
            },
            validateTrigger: "accessKey",
          },
        ]}
        required
      >
        <CustomInput.UUID placeholder="Chave de acesso..." />
      </Form.Item>

      <Form.Item<IEdeltecIntegrationFormValues>
        name="password"
        label="Senha"
        rules={[
          { required: true, message: "Campo é obrigatório" },
          {
            validator: (_, value) => {
              return value && value.trim() !== ""
                ? Promise.resolve()
                : Promise.reject("Campo não pode ser vazio");
            },
            validateTrigger: "password",
          },
        ]}
        required
      >
        <CustomInput.Password placeholder="Senha" />
      </Form.Item>

      <Flex align="center" gap={8} justify="end">
        <Button onClick={onCancel}>Cancelar</Button>
        <Button
          type="primary"
          htmlType="submit"
          loading={isUpdatingConfigEdeltec}
        >
          Integrar Edeltec
        </Button>
      </Flex>
    </Form>
  );
}
