import { useMemo } from "react";

import type {
  IPropertySituationEosFinancingPersonSelectProps,
  PropertySituationEosFinancingPersonOptionType,
  PropertySituationEosFinancingPersonRecordType,
} from "./interface";

import { SolarzSelect } from "~solarzui/SolarzSelect";
import { SITUACAO_IMOVEL } from "~types/enum";

export function PropertySituationEosFinancingPersonSelect({
  ...props
}: IPropertySituationEosFinancingPersonSelectProps) {
  const OPTIONS = useMemo(() => {
    return SITUACAO_IMOVEL.map((value) => ({
      value,
      record: {
        value,
      },
      label: value,
    })) as PropertySituationEosFinancingPersonOptionType;
  }, []);

  return (
    <SolarzSelect<PropertySituationEosFinancingPersonRecordType, "value">
      {...props}
      options={OPTIONS}
    />
  );
}
