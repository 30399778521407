import type { IDealCustomFieldTypeSelectProps } from "./interface";

import { SolarzSelect } from "~solarzui/SolarzSelect";
import { fieldTypeEnumArray } from "~types/enum";
import { translateFieldType } from "~utils/translate";

export function DealCustomFieldTypeSelect({
  disabledOptions,
  hiddenOptions,
  ...props
}: IDealCustomFieldTypeSelectProps) {
  return (
    <SolarzSelect
      {...props}
      options={fieldTypeEnumArray.map((option) => {
        return {
          label: translateFieldType(option),
          value: option,
          record: {
            value: option,
          },
          disabled: !!disabledOptions?.[option],
          hidden: !!hiddenOptions?.[option],
        };
      })}
    />
  );
}
