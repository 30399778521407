import { useMemo, useState } from "react";
import { AiOutlineSetting } from "react-icons/ai";

import type {
  ConcessionairesTableDataType,
  ConcessionairesTableColumnType,
  IConcessionairesTableProps,
} from "./interface";

import { Menu } from "~components/Menu";
import { Modal } from "~components/Modal";
import { Result } from "~components/Result";
import { useFetchGetConcessionairesWithAssociatedProfile } from "~hooks/api";
import { Table } from "antd";

export function ConcessionaireTable({
  size = "small",
}: IConcessionairesTableProps) {
  const [
    selectedConcessionaireToManageProfiles,
    setSelectedConcessionaireToManageProfiles,
  ] = useState<ConcessionairesTableDataType>();

  const {
    data: concessionaires,
    isFetching: isConcessionairesLoading,
    error: concessionaireError,
  } = useFetchGetConcessionairesWithAssociatedProfile({});

  const columns: ConcessionairesTableColumnType = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      render: (value: string) => value,
    },
    {
      title: "Sigla",
      dataIndex: "initialism",
      key: "initialism",
      width: "30%",
      render: (value: string) => value,
    },
    {
      title: "",
      align: "center" as const,
      width: 64,
      render: (_, item) => (
        <Menu.MoreDropdown
          position="bottomRight"
          items={[
            {
              icon: (
                <AiOutlineSetting
                  fontSize={18}
                  style={{ color: "var(--blue-500)" }}
                />
              ),
              text: "Gerenciar perfis",
              color: "var(--blue-500)",
              onClick: () => setSelectedConcessionaireToManageProfiles(item),
            },
          ]}
        />
      ),
    },
  ];
  // WILL BE ADDED IN THE FUTURE
  // function onPageChange(page: number) {
  //   setPage(page);
  // }

  const dataSource: ConcessionairesTableDataType[] = useMemo(() => {
    return (
      concessionaires?.map((item) => ({
        id: item.id,
        initialism: item.initialism,
        name: item.name,
        province: item.province,
      })) ?? []
    );
  }, [concessionaires]);

  return (
    <>
      <Table
        data-cy="concessionaires-table"
        rowKey="id"
        loading={isConcessionairesLoading}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        size={size}
        scroll={{ x: 720 }}
        // WILL BE ADDED IN THE FUTURE
        // pagination={createPaginationTableObject({
        //   size: concessionaires?.size,
        //   number: concessionaires?.number ? concessionaires?.number + 1 : 1,
        //   itemType: "concessionárias",
        //   totalElements: concessionaires?.totalElements,
        //   onChange: (page) => onPageChange(page - 1),
        // })}
        locale={{
          emptyText: (
            <Result.TableErrorOrEmpty
              errorMessage={concessionaireError?.message}
              emptyMessage="Nenhuma concessionária encontrada."
            />
          ),
        }}
      />

      <Modal.ConcessionairesProfilesList
        isOpen={!!selectedConcessionaireToManageProfiles}
        concessionaire={selectedConcessionaireToManageProfiles}
        onClose={() => {
          setSelectedConcessionaireToManageProfiles(undefined);
        }}
      />
    </>
  );
}
