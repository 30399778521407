import type { ISolarzSelectProps } from "~solarzui/SolarzSelect/interface";
import type { ProjectTypeEnum } from "~types/enum";

export const projectTypeValues = ["DEFAULT", "EXPRESS", "DIVERSE"] as const;

export type ProposalTypeFilterSelectRecordType = {
  value: ProjectTypeEnum;
};

export interface IProposalTypeFilterSelectProps
  extends Omit<
    ISolarzSelectProps<ProposalTypeFilterSelectRecordType, "value">,
    "options" | "value" | "defaultValue"
  > {
  value?: ProjectTypeEnum | ProjectTypeEnum[];
  defaultValue?: ProjectTypeEnum | ProjectTypeEnum[];
  disableOptions?: ProjectTypeEnum[];
  hideOptions?: ProjectTypeEnum[];
}
