import { useState } from "react";
import { PiDotOutlineFill } from "react-icons/pi";

import type { IDeletePersonModalProps } from "./interface";
import { StyledAlert } from "./style";

import { Card } from "~components/Card";
import {
  useFetchDeletePerson,
  useFetchDeletePersonPipedrive,
  useFetchGetPersonById,
} from "~hooks/api";
import { useSession } from "~hooks/useSession";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { Checkbox, Modal } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";

export function DeletePersonModal({
  personId,
  isOpen = false,
  onClose = () => null,
  title = "Apagar pessoa",
  maxWidth = 520,
  onError,
  onSuccess,
}: IDeletePersonModalProps) {
  const { user } = useSession();
  const [isChecked, setIsChecked] = useState(false);

  const { data: personData } = useFetchGetPersonById({
    payload: {
      personId,
    },
    dependencyArray: [personId],
    options: {
      enabled: personId > 0,
      retry: 1,
    },
  });

  const { mutate: deletePerson, isLoading: isDeletingPerson } =
    useFetchDeletePerson({
      onSuccess: () => {
        if (typeof onSuccess === "function") {
          onSuccess();
        }

        onClose();
      },
      onError,
    });

  const {
    mutate: deletePersonPipedrive,
    isLoading: isDeletingPersonPipedrive,
  } = useFetchDeletePersonPipedrive({
    onSuccess: () => {
      if (typeof onSuccess === "function") {
        onSuccess();
      }

      onClose();
    },
    onError,
  });

  function handleDeletePerson() {
    deletePerson({ personId });

    if (isChecked && user?.pipedriveIntegration) {
      handleDeletePersonPipedrive();
    }
  }

  function handleDeletePersonPipedrive() {
    if (!personData?.pipedriveId) {
      return;
    }

    deletePersonPipedrive({ pipedriveId: personData.pipedriveId });
  }

  const handleCheckboxChange = (event: CheckboxChangeEvent) => {
    setIsChecked(event.target.checked);
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title={title}
      data-cy="delete-person-modal"
      style={{
        width: "100%",
        maxWidth,
      }}
      okText="Apagar"
      onOk={handleDeletePerson}
      okButtonProps={{
        loading: isDeletingPerson || isDeletingPersonPipedrive,
      }}
    >
      <div className="flex flex-col gap-6">
        <StyledAlert
          type="error"
          description={
            <div className="flex flex-col gap-2">
              <SolarzTypography
                variant="danger"
                fontWeight="medium"
                hierarchy="paragraph2"
              >
                Tem certeza que deseja apagar essa pessoa?
              </SolarzTypography>

              <div className="w-full flex flex-row gap-1 align-middle text-red-600">
                <PiDotOutlineFill size={24} />
                <SolarzTypography
                  variant="danger"
                  hierarchy="small"
                  fontWeight="regular"
                >
                  Todas as <strong>notas</strong>, <strong>arquivos</strong> e{" "}
                  <strong>documentos</strong> associados a essa pessoa também
                  serão removidos.
                </SolarzTypography>
              </div>
            </div>
          }
        />

        <Card.DeletePerson personId={personId} />

        {!!user?.pipedriveIntegration && (
          <div className="w-full">
            <Checkbox onChange={handleCheckboxChange}>
              Excluir também do Pipedrive
            </Checkbox>
          </div>
        )}
      </div>
    </Modal>
  );
}
