import Link from "next/link";
import { useRouter } from "next/router";

import { StyledBreadcrumb } from "./styles";

import { ArrowRight } from "~estrela-ui/icons";
import { useFetchGetOrganizationById } from "~hooks/api";
import { useSession } from "~hooks/useSession";
import { Skeleton } from "antd";

function getOrganizationIdFromUrlPath(path: string) {
  try {
    const organizationIdString = path
      .split("/clientes/empresa/")[1]
      .split("?")[0];

    const organizationId = Number(organizationIdString);

    return isNaN(organizationId) ? 0 : organizationId;
  } catch {
    return 0;
  }
}

// Ex.: expected string /clientes/empresa/1?...
export function OrganizationBreadcrumb() {
  const router = useRouter();
  const { sessionStatus } = useSession();

  const organizationId = getOrganizationIdFromUrlPath(router.asPath);

  const {
    data: organization,
    isFetching: isLoadingOrganization,
    error: organizationError,
  } = useFetchGetOrganizationById({
    payload: {
      organizationId,
    },
    dependencyArray: [organizationId, sessionStatus],
    options: {
      enabled: organizationId > 0 && sessionStatus === "authenticated",
      retry: 1,
    },
  });

  return (
    <StyledBreadcrumb
      separator={<ArrowRight className="h-3 w-3" />}
      items={[
        { title: <Link href="/clientes">Clientes</Link> },
        { title: <span>Empresa</span> },
        {
          title:
            isLoadingOrganization || organizationError ? (
              <Skeleton.Node
                active={isLoadingOrganization}
                style={{ height: 16, width: 80 }}
              >
                {" "}
              </Skeleton.Node>
            ) : (
              <Link href={`/clientes/empresa/${organizationId}`}>
                {organization?.name ?? organizationId}
              </Link>
            ),
          className: "active",
        },
      ]}
    />
  );
}
