import { useMemo, useState } from "react";

import type {
  IProductsInvertersTableProps,
  IProductsInvertersTableColumnType,
} from "./interface";

import { createPaginationTableObject } from "~components/Pagination";
import { Result } from "~components/Result";
import { useFetchGetPaginatedInverters } from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import type { IInverterCustomDTOSchema } from "~types/schemas";
import { parseCurrency } from "~utils/parse";
import { Table } from "antd";

export function ProductsInvertersTable({
  size = "small",
}: IProductsInvertersTableProps) {
  const { screens } = useAppConfig();
  const [currentPage, setCurrentPage] = useState(0);

  const {
    data: inverters,
    isLoading: isInvertersLoading,
    error: invertersError,
  } = useFetchGetPaginatedInverters({
    dependencyArray: [currentPage],
    payload: {
      page: currentPage,
    },
  });

  const columns: IProductsInvertersTableColumnType = [
    {
      title: "Modelo",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Marca",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Distribuidor",
      dataIndex: "distributor",
      key: "distributor",
    },
    {
      title: "Potência nominal",
      key: "nominalPower",
      dataIndex: "nominalPower",
      render: (i) => i + "Wp",
    },
    {
      title: "Fase",
      dataIndex: "phase",
      key: "phase",
    },
    {
      title: "Tensão de saída",
      key: "outputVoltage",
      dataIndex: "outputVoltage",
      // render: (i) => i + "V",
    },
    {
      title: "Mppt",
      dataIndex: "mpptNum",
      key: "mpptNum",
    },
    {
      title: "Preço",
      key: "precoBaseCents",
      dataIndex: "precoBaseCents",
      render: (item) => parseCurrency(item / 100),
    },
    {
      title: "Nº Itens Extra",
      key: "extraItemsCount",
      dataIndex: "extraItemsCount",
    },
  ];

  const DATA_SOURCE: IInverterCustomDTOSchema[] = useMemo(
    () => inverters?.content ?? [],
    [inverters?.content],
  );

  return (
    <Table
      data-cy="products-inverters-table"
      pagination={createPaginationTableObject({
        onChange: (page) => setCurrentPage(page - 1),
        size: inverters?.size,
        disabled: false,
        itemType: "inversores",
        number: inverters?.number ? inverters?.number + 1 : 1,
        totalElements: inverters?.totalElements,
        displayTotalInformation: screens.sm,
      })}
      loading={isInvertersLoading}
      style={{ width: "100%" }}
      scroll={{ x: 920 }}
      columns={columns}
      rowKey="id"
      dataSource={DATA_SOURCE}
      size={size}
      locale={{
        emptyText: (
          <Result.TableErrorOrEmpty
            errorMessage={invertersError?.message}
            emptyMessage="Nenhum inversor encontrado."
          />
        ),
      }}
    />
  );
}
