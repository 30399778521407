import { useMemo } from "react";
import { AiOutlineUser } from "react-icons/ai";

import Link from "next/link";
import { useRouter } from "next/router";

import type { IAdminPagesMenuProps, AdminMenuItemType } from "./interface";
import styles from "./styles";

import { Menu } from "antd";

function getActiveKeysByNextRouterPath(path: string) {
  if (!path || path === "/") {
    return ["/"];
  }

  const formattedStringPaths = path
    .split("/")
    .filter((path) => path)
    .map((path) => "/" + path);

  const pathParsedToKey = formattedStringPaths.reduce((keys, path) => {
    if (keys.length === 0) {
      return [path];
    }

    const newKey = keys.concat() + path;

    return [...keys, newKey];
  }, [] as string[]);

  return pathParsedToKey;
}

export function AdminPagesMenu({
  inlineIndent = 12,
  mode = "inline",
  ...props
}: IAdminPagesMenuProps) {
  const router = useRouter();

  const MENU_ITEMS = useMemo(
    () =>
      [
        {
          key: "/admin/usuarios",
          icon: <AiOutlineUser />,
          label: <Link href="/admin/usuarios">Usuários</Link>,
        },
      ] as AdminMenuItemType,
    [],
  );

  const activeKeys = getActiveKeysByNextRouterPath(router.asPath).map(
    (route) => route.split("?")[0],
  );

  return (
    <>
      <style jsx>{styles}</style>
      <Menu
        data-cy="admin-pages-menu"
        selectedKeys={activeKeys}
        defaultOpenKeys={activeKeys}
        items={MENU_ITEMS}
        style={{ height: "100%" }}
        inlineIndent={inlineIndent}
        mode={mode}
        className="menu"
        {...props}
      />
    </>
  );
}
