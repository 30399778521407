import { useEffect, useMemo, useState } from "react";

import type { ISolarzMonitoringIframeCardProps } from "./interface";

import { Alert } from "~components/Alert";
import { useFetchCreateEncryptedPersonalToken } from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { useSession } from "~hooks/useSession";
import { Card, Flex, Skeleton } from "antd";

export function SolarzMonitoringIframeCard({
  style,
  dealId = 0,
}: ISolarzMonitoringIframeCardProps) {
  const { user } = useSession();
  const { currentTheme } = useAppConfig();

  const [isLoadingIframe, setIsLoadingIframe] = useState(true);

  const {
    data: encryptedPersonalToken,
    isFetching: isLoadingEncryptedPersonalToken,
    error: errorEncryptedPersonalToken,
    refetch: reloadEncryptedPersonalToken,
  } = useFetchCreateEncryptedPersonalToken({
    dependencyArray: [user?.id, user?.companyId],
    options: {
      retry: 1,
      enabled: !!user?.id && !!user?.companyId,
      onSuccess: () => {
        setIsLoadingIframe(true); // ensure loading animation in iframe
      },
    },
    payload: {
      companyId: user?.companyId || 0,
      userId: user?.id || 0,
    },
  });

  async function onLoadIFrame() {
    await new Promise((response) => setTimeout(response, 2500));
    setIsLoadingIframe(false);
  }

  const iframeUrl = useMemo(() => {
    if (!encryptedPersonalToken?.token || !dealId) return "";

    const urlParamsArray = [
      "https://pages.solarz.com.br/crm/dashboard",
      `?selectedIds=${dealId}`,
      `&theme=${currentTheme === "dark" ? "dark" : "light"}`,
      `&token=${encryptedPersonalToken.token}`,
    ];

    return urlParamsArray.join("");
  }, [currentTheme, dealId, encryptedPersonalToken?.token]);

  useEffect(() => {
    setIsLoadingIframe(true);
  }, [currentTheme]);

  return (
    <>
      <Card
        style={{
          width: "100%",
          ...style,
        }}
        styles={{
          body: {
            padding: 0,
          },
        }}
        title="Solarz Monitoramento - Usinas"
      >
        <Flex vertical gap={16}>
          {errorEncryptedPersonalToken && (
            <Alert.CardError
              title="Falha ao carregar dados"
              errorMessage={errorEncryptedPersonalToken?.message}
              reloadFn={reloadEncryptedPersonalToken}
            />
          )}

          {!dealId && (
            <Alert.CardError
              title="Faltando ID do negócio"
              errorMessage="O componente não recebeu um dealId válido"
            />
          )}

          {(isLoadingIframe ||
            isLoadingEncryptedPersonalToken ||
            !iframeUrl) && (
            <Skeleton.Node
              active={
                dealId > 0 &&
                (isLoadingEncryptedPersonalToken || isLoadingIframe)
              }
              style={{ height: 375, width: "100%" }}
            >
              {" "}
            </Skeleton.Node>
          )}

          {!isLoadingEncryptedPersonalToken && iframeUrl && (
            <iframe
              src={iframeUrl}
              height={375}
              style={{ borderRadius: "0 0 4px 4px" }}
              onLoad={onLoadIFrame}
              className={isLoadingIframe ? "sr-only" : undefined}
            />
          )}
        </Flex>
      </Card>
    </>
  );
}
