import { IActivateDistributorKitFilterFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function ActivateDistributorKitFilterFormModal({
  isOpen = false,
  onClose,
  distributorId = 0,
  activeKitFilter,
}: IActivateDistributorKitFilterFormModalProps) {
  return (
    <Modal
      data-cy="activate-distributor-kit-filter-form-modal"
      width={500}
      open={isOpen}
      footer={null}
      onCancel={onClose}
      destroyOnClose
    >
      <Form.ActivateDistributorKitFilter
        activeKitFilter={activeKitFilter}
        distributorId={distributorId}
        onCancel={onClose}
      />
    </Modal>
  );
}
