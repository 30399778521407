import { BiErrorCircle } from "react-icons/bi";

import { IIrradiationSourceSelectProps } from "./interface";

import { useFetchGetIrradiationSources } from "~hooks/api";
import { Button, Select, Tooltip } from "antd";

export function IrradiationSourceSelect({
  allowClear = true,
  onChange,
  ...props
}: IIrradiationSourceSelectProps) {
  const {
    data: irradiationSources,
    isFetching: isLoadingIrradiationSources,
    error: IrradiationSourcesError,
    refetch,
  } = useFetchGetIrradiationSources({});

  const dataSource = irradiationSources
    ? irradiationSources.map((irradiation) => ({
        label: irradiation.name,
        value: irradiation.id,
        record: irradiation,
      }))
    : [];

  return (
    <Select
      {...props}
      data-cy="irradiation-source-select"
      loading={isLoadingIrradiationSources}
      options={dataSource}
      allowClear={allowClear}
      status={IrradiationSourcesError ? "error" : ""}
      suffixIcon={
        IrradiationSourcesError ? (
          <Tooltip title="Recarregar dados">
            <Button type="link" loading={isLoadingIrradiationSources}>
              <BiErrorCircle size={18} onClick={() => refetch()} />
            </Button>
          </Tooltip>
        ) : undefined
      }
      onChange={(value, option) => {
        if (option instanceof Array || !onChange) return;
        onChange(value, option);
      }}
      style={{ width: "100%" }}
    />
  );
}
