import { IDeleteIntegrationLinksModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function DeleteIntegrationLinksModal({
  isOpen = false,
  onClose = () => undefined,
  onSuccess = () => undefined,
  width = 420,
  distributorId,
  fetchToggleDistributor,
  fetchDeleteIntegrationLinks,
}: IDeleteIntegrationLinksModalProps) {
  return (
    <Modal
      data-cy="delete-integration-links-form-modal"
      open={isOpen}
      onCancel={onClose}
      title="Excluir integrações"
      width={width}
      footer={null}
      destroyOnClose
      style={{ maxWidth: "90vw" }}
    >
      <Form.DeleteIntegrationLinks
        distributorId={distributorId}
        fetchToggleDistributor={fetchToggleDistributor}
        fetchDeleteIntegrationLinks={fetchDeleteIntegrationLinks}
        initialValues={{ isChecked: false }}
        onSuccess={onSuccess}
      />
    </Modal>
  );
}
