export const getLocalStorageData = (key: string) => {
  try {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  } catch (error) {
    console.error(`Error getting data from localStorage key "${key}":`, error);
    return null;
  }
};

export const setLocalStorageData = (key: string, value: any) => {
  try {
    const stringifiedData = JSON.stringify(value);
    localStorage.setItem(key, stringifiedData);
  } catch (error) {
    console.error(`Error setting data to localStorage key "${key}":`, error);
  }
};

export const removeLocalStorageData = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error(`Error removing data from localStorage key "${key}":`, error);
  }
};
