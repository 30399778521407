import type { ISolarzKanbanTaskProps } from "./interface";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Tag } from "antd";

export function KanbanTask<TaskRecordType extends Record<string, any>>({
  task,
  renderTask,
  disableTaskDragAndDrop = false,
}: ISolarzKanbanTaskProps<TaskRecordType>) {
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: task.customId,
    data: {
      type: "Task",
      task,
    },
    disabled: disableTaskDragAndDrop,
  });

  const sharedStyle = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const customRender = typeof renderTask === "function";

  if (isDragging) {
    return (
      <div
        ref={setNodeRef}
        tabIndex={customRender ? -1 : undefined}
        style={{ ...sharedStyle, opacity: 0.4 }}
      >
        {customRender ? (
          renderTask(task)
        ) : (
          <Tag
            color="geekblue"
            style={{ display: "block", cursor: "pointer", minHeight: 40 }}
          >
            {task.title}
          </Tag>
        )}
      </div>
    );
  }

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      tabIndex={customRender ? -1 : undefined}
    >
      {customRender ? (
        renderTask(task)
      ) : (
        <Tag
          color="geekblue"
          style={{ display: "block", cursor: "pointer", minHeight: 40 }}
        >
          {task.title}
        </Tag>
      )}
    </div>
  );
}
