import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useRouter } from "next/router";

import {
  IProposalContext,
  IProposalContextProviderProps,
  IProposalState,
} from "./interface";

import {
  SIZING_STEP_STORAGE_KEY,
  CITY_WAS_CHANGED_KEY,
  PROPOSAL_STEPS,
  ProposalStepKeyType,
} from "~configs/ProposalConfig";
import { useFetchGetProjectRevisionById } from "~hooks/api";
import { useSession } from "~hooks/useSession";

export const ProposalContext = createContext({} as IProposalContext);

export function ProposalContextProvider({
  children,
}: IProposalContextProviderProps) {
  const router = useRouter();

  const { sessionStatus } = useSession();

  const [isOpenDealUpdateTotalValueModal, setIsOpenDealUpdateTotalValueModal] =
    useState(false);
  const [proposalState, setProposalState] = useState<IProposalState>();
  const [possibleId, setPossibleId] = useState<number>(0);
  const [cityWasChanged, setCityWasChanged] = useState(false);

  const CURRENT_STEP = useMemo(() => {
    const CURRENT_STEP = Number(router.query["passo"]) || 1;
    return CURRENT_STEP;
  }, [router.query]);

  const STEPS = useMemo(() => {
    const filteredSteps = PROPOSAL_STEPS.reduce((steps, step) => {
      if (step === "AFTER_SIZING" && !proposalState?.hasIntermediateStep) {
        return steps;
      }

      if (step === "PAYMENT" && !proposalState?.hasPaymentStep) {
        return steps;
      }

      if (proposalState?.projectType === "DIVERSE" && step === "SIZING") {
        return steps;
      }

      if (
        proposalState?.projectType === "EXPRESS" &&
        (step === "SIZING" || step === "AFTER_SIZING" || step === "DETAILS")
      ) {
        return steps;
      }

      return [...steps, step];
    }, [] as ProposalStepKeyType[]);

    return filteredSteps;
  }, [
    proposalState?.hasIntermediateStep,
    proposalState?.hasPaymentStep,
    proposalState?.projectType,
  ]);

  const { isLoading: isProposalCheckLoading } = useFetchGetProjectRevisionById({
    dependencyArray: [!!possibleId, sessionStatus],
    payload: {
      projectId: possibleId,
    },
    options: {
      enabled: possibleId > 0 && sessionStatus === "authenticated",
      onSuccess: (data) => {
        const countExtraSteps = [
          data.showIntermediateStep,
          data.showPaymentStep,
        ].filter((item) => item === true).length;

        setProposalState({
          title: data.title,
          businessUnitId: data.businessUnitId,
          pipedriveId: data.pipedriveDealId,
          revision: data.revision,
          proposalId: possibleId,
          isExpress: data.express,
          projectType: data.projectType,
          lastStep: data.lastStep ?? 0,
          alreadyFinished: 5 + countExtraSteps === data.lastStep,
          projectId: data.projectId,
          projectCustomId: data.projectCustomId,
          pipedriveDealStatus: data.pipedriveDealStatus,
          status: data.status,
          hasIntermediateStep: !!data.showIntermediateStep,
          hasPaymentStep: !!data.showPaymentStep,
          pricingTypeId: data.pricingTypeId,
          deal: data.revisionDealResponse
            ? {
                id: data.revisionDealResponse.dealId,
                name: data.revisionDealResponse.dealName,
              }
            : undefined,
        });
      },
      onError: () => router.replace("/proposta", undefined, { shallow: true }),
    },
  });

  useEffect(() => {
    if (router.isReady && router.pathname !== "/proposta") {
      const id = Number(router.query["id"]);
      id > 0
        ? setPossibleId(id)
        : router.replace("/proposta", undefined, { shallow: true });
    }
  }, [router]);

  // LOAD PERSISTED CITY WAS CHANGED VALUE
  useEffect(() => {
    if (!proposalState?.proposalId) {
      return;
    }

    const persistedCityWasChangedInfo =
      localStorage.getItem(CITY_WAS_CHANGED_KEY);

    if (persistedCityWasChangedInfo) {
      try {
        const persistedObject = JSON.parse(persistedCityWasChangedInfo) as {
          cityWasChanged: boolean;
          proposalId: number;
        };

        if (
          typeof persistedObject.proposalId === "number" &&
          proposalState?.proposalId === persistedObject.proposalId
        ) {
          setCityWasChanged(!!persistedObject.cityWasChanged);
        } else {
          localStorage.removeItem(CITY_WAS_CHANGED_KEY);
        }
      } catch (error) {
        localStorage.removeItem(CITY_WAS_CHANGED_KEY);
      }
    }
  }, [proposalState?.proposalId]);

  const handleNextStep = useCallback(() => {
    setProposalState((prevState) => ({
      ...prevState,
      lastStep: CURRENT_STEP + 1,
    }));
    router.push(
      {
        query: { ...router.query, passo: CURRENT_STEP + 1 },
      },
      undefined,
      { shallow: true },
    );
  }, [CURRENT_STEP, router]);

  const handlePreviousStep = useCallback(() => {
    setProposalState((prevState) => ({
      ...prevState,
      lastStep: CURRENT_STEP - 1,
    }));
    router.push(
      {
        query: { ...router.query, passo: CURRENT_STEP - 1 },
      },
      undefined,
      { shallow: true },
    );
  }, [CURRENT_STEP, router]);

  const handleChangeStep = useCallback((step: number) => {
    setProposalState((prevState) => ({
      ...prevState,
      lastStep: step,
    }));
  }, []);

  const handleChangeCity = useCallback(
    (value: boolean) => {
      setCityWasChanged(value);

      const proposalId = Number(proposalState?.proposalId);

      if (isNaN(proposalId) || proposalId <= 0) return;

      const persistObject = {
        cityWasChanged: value,
        proposalId,
      };

      localStorage.setItem(CITY_WAS_CHANGED_KEY, JSON.stringify(persistObject));
    },
    [proposalState?.proposalId],
  );

  const handleClear = useCallback(() => {
    setPossibleId(0);
    setProposalState(undefined);
    localStorage.removeItem(SIZING_STEP_STORAGE_KEY);
    localStorage.removeItem(CITY_WAS_CHANGED_KEY);
  }, []);

  const memoizedContextValues: IProposalContext = useMemo(
    () => ({
      proposalState,
      isProposalCheckLoading,
      cityWasChanged,
      handleNextStep,
      handlePreviousStep,
      handleChangeStep,
      handleClear,
      handleChangeCity,
      steps: STEPS,
      isOpenDealUpdateTotalValueModal,
      setIsOpenDealUpdateTotalValueModal,
    }),
    [
      proposalState,
      isProposalCheckLoading,
      cityWasChanged,
      handleNextStep,
      handlePreviousStep,
      handleChangeStep,
      handleClear,
      handleChangeCity,
      STEPS,
      isOpenDealUpdateTotalValueModal,
    ],
  );

  return (
    <ProposalContext.Provider value={memoizedContextValues}>
      {children}
    </ProposalContext.Provider>
  );
}
