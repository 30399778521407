import { IBusinessTimelineCardProps } from "./interface";
import { StyledCard } from "./styles";

import { Timeline } from "~components/Timeline";
import { SolarzTypography } from "~solarzui/SolarzTypography";

export function BusinessTimelineCard({
  companyId,
  className,
  style,
  ...props
}: IBusinessTimelineCardProps) {
  return (
    <StyledCard
      {...props}
      size="small"
      styles={{
        body: {
          padding: 24,
        },
      }}
      style={style}
      className={className}
    >
      <SolarzTypography
        fontWeight="medium"
        hierarchy="paragraph2"
        variant="title"
        style={{
          margin: "0rem 0 2rem 0",
        }}
      >
        Linha do tempo
      </SolarzTypography>

      <Timeline.Business companyId={companyId} />
    </StyledCard>
  );
}
