import { useMemo } from "react";

import { IPipedriveLeadSelectProps, SelectLeadOptionType } from "./interface";

import {
  useFetchGetPipedriveLeadType,
  useFetchUpdatePipedriveLead,
} from "~hooks/api";
import { useSession } from "~hooks/useSession";
import { PipedriveLeadTypeEnum } from "~types/enum";
import { Select } from "antd";

export function PipedriveLeadTypeSelect({
  onChange,
  onLoad = () => undefined,
  ...props
}: IPipedriveLeadSelectProps) {
  const { user, token } = useSession();

  const OPTIONS: SelectLeadOptionType[] = useMemo(
    () => [
      {
        label: "Pessoa" as const,
        value: "PERSON" as const,
      },
      {
        label: "Organização" as const,
        value: "ORG" as const,
      },
    ],
    [],
  );

  const { data: currentLeadType, isFetching: isLoadingLeadType } =
    useFetchGetPipedriveLeadType({
      dependencyArray: [token],
      options: {
        onSuccess: onLoad,
        enabled: !!user?.pipedriveIntegration,
      },
    });

  const { mutate: updateLeadCurrent, isLoading: isUpdatingLeadCurrent } =
    useFetchUpdatePipedriveLead({});

  function handleSelectLeadTypeurrent(leadType: PipedriveLeadTypeEnum) {
    const payload = {
      pipedriveLeadType: leadType,
    };

    updateLeadCurrent(payload);
  }

  const isLoading = isLoadingLeadType || isUpdatingLeadCurrent;

  return (
    <Select
      {...props}
      loading={isLoading}
      options={OPTIONS}
      value={currentLeadType?.pipedriveLeadType}
      onChange={(value, option) => {
        const parsedValue = value as PipedriveLeadTypeEnum;

        handleSelectLeadTypeurrent(parsedValue);

        if (option instanceof Array || !onChange) return;
        onChange(value, option);
      }}
      style={{ maxWidth: "100%" }}
    />
  );
}
