import { useMemo } from "react";

import {
  IComplementaryItemCategorySelectProps,
  IComplementaryItemCategorySelectOption,
} from "./interface";

import { Select } from "antd";

export function ComplementaryItemCategorySelect({
  value,
  onChange = () => null,
  defaultValue,
  options = {
    disablePercentualIC: false,
    disablePercentualTotal: false,
    disableValorFixo: false,
    disableMultiplicador: false,
    disablePersonalizado: false,
    disableMaterial: false,
    hidePercentualIC: false,
    hidePercentualTotal: false,
    hideValorFixo: false,
    hideMultiplicador: false,
    hidePersonalizado: false,
    hideMaterial: false,
  },
  ...props
}: IComplementaryItemCategorySelectProps) {
  const OPTIONS = useMemo(() => {
    const parsedOptions = [
      {
        label: "Percentual I.C",
        value: "Percentual I.C",
        disabled: options.disablePercentualIC,
        hidden: options.hidePercentualIC,
      },
      {
        label: "Percentual total",
        value: "Percentual total",
        disabled: options.disablePercentualTotal,
        hidden: options.hidePercentualTotal,
      },
      {
        label: "Valor fixo",
        value: "Valor fixo",
        disabled: options.disableValorFixo,
        hidden: options.hideValorFixo,
      },
      {
        label: "Multiplicador",
        value: "Multiplicador",
        disabled: options.disableMultiplicador,
        hidden: options.hideMultiplicador,
      },
      {
        label: "Personalizado",
        value: "Personalizado",
        disabled: options.disablePersonalizado,
        hidden: options.hidePersonalizado,
      },
      {
        label: "Percentual do material",
        value: "Material",
        disabled: options.disableMaterial,
        hidden: options.hideMaterial,
      },
      {
        label: "Agregador de custo",
        value: "Agregador custo",
        disabled: options.disableAgregadorCusto,
        hidden: options.hideAgregadorCusto,
      },
    ] as const;

    const filteredOptions = parsedOptions.filter((item) => !item.hidden);

    return filteredOptions as IComplementaryItemCategorySelectOption[];
  }, [options]);

  return (
    <Select
      {...props}
      options={OPTIONS}
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
}
