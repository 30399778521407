import { Tag } from "~components/Tag";
import { useFetchGetRemainingPercentage } from "~hooks/api";
import { useSession } from "~hooks/useSession";
import { parsePercentage } from "~utils/parse";
import { Flex, Spin } from "antd";

export function PercentageInfo() {
  const { user } = useSession();

  const {
    data: additionalCostsInfo,
    isFetching: isLoadingAdditionalCostsInfo,
  } = useFetchGetRemainingPercentage({
    dependencyArray: [user?.businessUnitId],
  });

  const totalPercentage = parsePercentage(additionalCostsInfo?.totalPercentage);
  const remainingPercentage = parsePercentage(
    additionalCostsInfo?.remainingPercentage,
  );

  return (
    <Spin
      spinning={isLoadingAdditionalCostsInfo}
      size="default"
      style={{ width: "350px" }}
    >
      <Flex align="center" gap={6} style={{ marginBottom: "1rem" }}>
        <Tag.Info
          fieldName="Porcentagem utilizada"
          fieldValue={totalPercentage}
          tooltip="Soma das porcentagens que já foram atribuídas"
          color="cyan"
        />
        <Tag.Info
          fieldName="Porcentagem restante"
          fieldValue={remainingPercentage}
          tooltip="Porcentagem Possível para ser atribuída"
          color="blue"
        />
      </Flex>
    </Spin>
  );
}
