import { useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";

import type {
  IOrganizationDefaultFieldsTableData,
  OrganizationDefaultFieldsTableColumn,
} from "./interface";
import { StyledCollapse } from "./styles";

import { Menu } from "~components/Menu";
import { Modal } from "~components/Modal";
import { Result } from "~components/Result";
import { useFetchGetOrganizationDefaultFieldSettings } from "~hooks/api";
import { Badge, Flex, Table, Typography } from "antd";

export function OrganizationDefaultFieldsTable() {
  const [
    selectedDefaultDataFieldToUpdate,
    setSelectedDefaultDataFieldToUpdate,
  ] = useState<IOrganizationDefaultFieldsTableData | undefined>(undefined);

  const columns: OrganizationDefaultFieldsTableColumn = [
    {
      key: "sort",
      width: 42,
      align: "center" as const,
    },
    {
      title: "Nome do campo",
      dataIndex: "organizationDefaultField",
      width: "auto",
      render: (_, record) => {
        return (
          <span>
            {record?.organizationDefaultField?.label || "-"}
            {record.required && (
              <b style={{ color: "var(--primary-500)" }}>*</b>
            )}
          </span>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      width: 42,
      align: "center" as const,
      render: (_, defaultField) => (
        <Menu.MoreDropdown
          items={[
            {
              icon: (
                <AiOutlineEdit
                  fontSize={18}
                  style={{ color: "var(--gold-500)" }}
                />
              ),
              text: "Alterar",
              color: "var(--gold-500)",
              onClick: () => setSelectedDefaultDataFieldToUpdate(defaultField),
            },
          ]}
          position="bottomLeft"
        />
      ),
    },
  ];

  const {
    data: organizationDefaultFields,
    isFetching: isLoadingDefaultFields,
    error: defaultFieldsError,
  } = useFetchGetOrganizationDefaultFieldSettings({});

  return (
    <>
      <StyledCollapse
        size="large"
        style={{ padding: 0, border: 0 }}
        defaultActiveKey={["1"]}
        items={[
          {
            key: "1",
            label: (
              <Flex align="center" gap={6}>
                <Typography.Text
                  style={{
                    margin: 0,
                    fontWeight: "bold",
                  }}
                >
                  Campos padrão
                </Typography.Text>

                <Flex align="center">
                  <Badge
                    count={
                      organizationDefaultFields
                        ? organizationDefaultFields.length
                        : 0
                    }
                    style={{
                      backgroundColor: "#e4e4e5",
                      color: "#21232c",
                      fontWeight: "bold",
                    }}
                  />
                </Flex>
              </Flex>
            ),
            children: (
              <Table
                rowKey="key"
                columns={columns}
                dataSource={organizationDefaultFields}
                scroll={{ x: 900 }}
                size="small"
                pagination={false}
                loading={isLoadingDefaultFields}
                locale={{
                  emptyText: (
                    <Result.TableErrorOrEmpty
                      errorMessage={defaultFieldsError?.message}
                      emptyMessage="Nenhum campo padrão encontrado"
                    />
                  ),
                }}
                style={{ width: "100%" }}
              />
            ),
          },
        ]}
      />
      <Modal.OrganizationDefaultFieldForm
        isOpen={!!selectedDefaultDataFieldToUpdate}
        onClose={() => setSelectedDefaultDataFieldToUpdate(undefined)}
        formProps={{
          initialValues: selectedDefaultDataFieldToUpdate,
          onSubmitSuccess: () => setSelectedDefaultDataFieldToUpdate(undefined),
        }}
      />
    </>
  );
}
