import { IntlLocale } from "~configs/AppConfig";

type LocaleType = "pt-BR" | "en-US" | "es-ES";
type CurrencyType = "BRL" | "USD" | "EUR";

// 333.33 => 333,33 KWp or 333,33KW
export const parsePotency = (
  value: number | undefined,
  digits = 2,
  isPeak = true,
  locale: IntlLocale = "pt-BR",
) => {
  const parsedValue = Number(value);

  if (isNaN(parsedValue)) return;

  if (parsedValue >= 1000000) {
    const newValue = parsedValue / 1000000;

    return new Intl.NumberFormat(locale, {
      style: "percent",
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    })
      .format(newValue / 100)
      .replace("%", isPeak ? " GWp" : " GW");
  }

  if (parsedValue >= 1000) {
    const newValue = parsedValue / 1000;

    return new Intl.NumberFormat(locale, {
      style: "percent",
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    })
      .format(newValue / 100)
      .replace("%", isPeak ? " MWp" : " MW");
  }

  return new Intl.NumberFormat(locale, {
    style: "percent",
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  })
    .format(parsedValue / 100)
    .replace("%", isPeak ? " kWp" : " kW");
};

// 333.33 => R$ 333,33
export const parseCurrency = (
  value: number | undefined,
  locale: LocaleType = "pt-BR",
  currency: CurrencyType = "BRL",
) => {
  const parsedValue = Number(value);

  if (isNaN(parsedValue)) return;

  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(parsedValue);
};

// 333.33 => 333,33%
export const parsePercentage = (
  value: number | undefined,
  locale: LocaleType = "pt-BR",
  digits = 2,
) => {
  const parsedValue = Number(value);

  if (isNaN(parsedValue)) return;

  return new Intl.NumberFormat(locale, {
    style: "percent",
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(parsedValue / 100);
};

// 333.33 => 333,33 KWh
export const parseEnergy = (
  value: number | undefined,
  digits = 2,
  locale: IntlLocale = "pt-BR",
) => {
  if (!value) return "";

  if (value >= 1000000) {
    const parsedValue = value / 1000000;

    return new Intl.NumberFormat(locale, {
      style: "percent",
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    })
      .format(parsedValue / 100)
      .replace("%", " GWh");
  }

  if (value >= 1000) {
    const parsedValue = value / 1000;

    return new Intl.NumberFormat(locale, {
      style: "percent",
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    })
      .format(parsedValue / 100)
      .replace("%", " MWh");
  }

  return new Intl.NumberFormat(locale, {
    style: "percent",
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  })
    .format(value / 100)
    .replace("%", " kWh");
};

export const parseCapitalized = (value: string | undefined) => {
  return value
    ? value.charAt(0).toUpperCase() + value.toLowerCase().slice(1)
    : "";
};

// Módulo => Modulo
export const parseRemoveAccent = (
  value: string | undefined,
  toLowerCase = false,
) => {
  if (!value) return "";

  const normalizedString = value
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");

  return toLowerCase ? normalizedString.toLowerCase() : normalizedString;
};

// !test@ => test
export const parseWithoutEspecialCharacters = (value: string | undefined) => {
  if (!value) return "";
  return value.replace(/[^0-9a-zA-Z]+/g, "");
};

// valueType is the inputNumber antd onChange event value type. If NaN returns undefined
export const parseInputValueTypeToNumber = (
  value: string | number | null | undefined,
) => {
  const parsedNumberValue = Number(value);
  const parsedValue = isNaN(parsedNumberValue) ? undefined : parsedNumberValue;
  return parsedValue;
};

// "Hello \n World\n " -> "Hello World"
export const removeSpacesAndBreakLines = (value: string | undefined) => {
  if (!value) return "";

  return value.replace(/[\n\s]/g, "");
};

// 22 -> 22.00 m | 22,3 -> 22.3 m
export const parseMeters = (
  value: number | undefined,
  locale: LocaleType = "pt-BR",
) => {
  if (!value) return "";

  return new Intl.NumberFormat(locale, {
    style: "unit",
    unit: "meter",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

// 22 -> 22.00 m² | 22,3 -> 22.3 m²
export const parseSquareMeters = (
  value: number | undefined,
  locale: LocaleType = "pt-BR",
) => {
  if (value === undefined) return "";

  return new Intl.NumberFormat(locale, {
    style: "unit",
    unit: "meter",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
    .format(value)
    .replace("m", "m²");
};

export const parseKilometers = (
  value: number | undefined,
  locale: LocaleType = "pt-BR",
) => {
  if (value === undefined) return "";

  return new Intl.NumberFormat(locale, {
    style: "unit",
    unit: "kilometer",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

export const parseBytesToKB = (bytes: number) => {
  return Math.ceil(bytes / 1024);
};
