import { useMemo, useState } from "react";
import { AiOutlineCopy, AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";

import type { ILaborTableProps, LaborTableColumnType } from "./interface";

import { Menu } from "~components/Menu";
import { Modal } from "~components/Modal";
import { createPaginationTableObject } from "~components/Pagination";
import { Result } from "~components/Result";
import {
  useFetchCreateLaborCopy,
  useFetchDeleteLabor,
  useFetchGetPaginatedLabors,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import type { ILaborDTOSchema } from "~types/schemas";
import { parseCurrency, parseKilometers } from "~utils/parse";
import { Button, Col, Row, Table, Tooltip } from "antd";

export function LaborTable({
  size = "small",
  defaultCurrentPage = 1,
}: ILaborTableProps) {
  const { screens } = useAppConfig();

  const [selectedLaborToManageStructures, setSelectedLaborToManageStructures] =
    useState<ILaborDTOSchema>();

  const [selectedLaborToCopy, setSelectedLaborToCopy] =
    useState<ILaborDTOSchema>();

  const [selectedLaborToDelete, setSelectedLaborToDelete] =
    useState<ILaborDTOSchema>();

  const [selectedLaborToUpdate, setSelectedLaborToUpdate] =
    useState<ILaborDTOSchema>();

  const [currentPage, setCurrentPage] = useState<number>(defaultCurrentPage);

  function onPageChange(page: number) {
    setCurrentPage(page);
  }

  const { mutate: createCopyLabor, isLoading: isCopingLabor } =
    useFetchCreateLaborCopy({
      options: {
        onSuccess: () => setSelectedLaborToCopy(undefined),
      },
    });

  const { mutate: fetchDeleteLabor, isLoading: isDeletingLabor } =
    useFetchDeleteLabor({
      options: {
        onSuccess: () => setSelectedLaborToDelete(undefined),
      },
    });

  function handleCopyLabor() {
    createCopyLabor({
      laborId: selectedLaborToCopy?.id ?? 0,
      name: selectedLaborToCopy?.name
        ? `${selectedLaborToCopy?.name} (Cópia)`
        : "",
    });
  }

  function handleDeleteLabor() {
    fetchDeleteLabor({
      laborId: selectedLaborToDelete?.id ?? 0,
    });
  }

  const { data, isFetching, error } = useFetchGetPaginatedLabors({
    payload: {
      page: currentPage - 1,
      size: 5,
    },
    dependencyArray: [currentPage],
    options: {
      enabled: currentPage > 0,
    },
  });

  const columns: LaborTableColumnType = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Intervalos",
      dataIndex: "ranges",
      key: "ranges",
      render: (items) => items.length,
    },
    {
      title: "Gerenciamento de estruturas",
      dataIndex: "structureTypes",
      key: "structureTypes",
      render: (_, record) => (
        <Tooltip title="Visualizar estruturas" placement="bottom">
          <Button
            onClick={() => setSelectedLaborToManageStructures(record)}
            type="link"
            style={{ padding: "0" }}
            data-cy="button-structures"
          >
            Estruturas
          </Button>
        </Tooltip>
      ),
    },
    {
      title: "",
      align: "center" as const,
      width: 64,
      render: (_, item: ILaborDTOSchema) => (
        <Menu.MoreDropdown
          position="bottomRight"
          items={[
            {
              icon: (
                <AiOutlineEdit
                  fontSize={16}
                  style={{ color: "var(--gold-500)" }}
                />
              ),
              text: "Editar",
              color: "var(--gold-500)",
              onClick: () => setSelectedLaborToUpdate(item),
            },
            {
              text: "Criar cópia",
              icon: <AiOutlineCopy color={"var(--blue-500)"} />,
              color: "var(--blue-500)",
              onClick: () => setSelectedLaborToCopy(item),
            },
            {
              icon: (
                <AiOutlineDelete
                  fontSize={16}
                  style={{ color: "var(--volcano-500)" }}
                />
              ),
              text: "Remover",
              color: "var(--volcano-500)",
              onClick: () => setSelectedLaborToDelete(item),
            },
          ]}
        />
      ),
    },
  ];

  const DATA_SOURCE = useMemo(() => data?.content ?? [], [data?.content]);

  return (
    <>
      <Table
        data-cy="labor-table"
        rowKey="id"
        loading={isFetching}
        columns={columns}
        dataSource={DATA_SOURCE}
        pagination={createPaginationTableObject({
          number: data?.number ? data?.number + 1 : 1,
          size: data?.size,
          totalElements: data?.totalElements,
          onChange: (page) => onPageChange(page),
          itemType: "Itens",
          disabled: false,
          displayTotalInformation: screens.sm,
        })}
        size={size}
        scroll={{ x: 660 }}
        locale={{
          emptyText: (
            <Result.TableErrorOrEmpty
              errorMessage={error?.message}
              emptyMessage="Nenhuma mão de obra encontrada."
            />
          ),
        }}
        expandable={{
          expandedRowRender: (record: ILaborDTOSchema) => {
            const getLabelText = () => {
              switch (record.pricingRule) {
                case "POWER":
                  return "kWp";
                case "NUMBER_OF_MODULES":
                  return "módulos";
              }
            };
            return (
              <>
                {record.ranges.map((data, index, record) => (
                  <Row key={data.id}>
                    <Col span={4}>
                      De{" "}
                      {index === 0 ? 1 : (record[index - 1].maxValue ?? 0) + 1}{" "}
                      a {data.maxValue ?? 0} {getLabelText()}
                    </Col>
                    <Col span={4}>
                      Valor por {getLabelText()}:{" "}
                      {parseCurrency(data.costPerValue)}
                    </Col>
                    <Col span={4}>
                      Valor mínimo: {parseCurrency(data.minimumCost)}
                    </Col>
                    <Col span={4}>
                      Valor por Km: {parseCurrency(data.pricePerKm)}
                    </Col>
                    <Col span={4}>
                      Distância mínima: {parseKilometers(data.minimumDistance)}
                    </Col>
                  </Row>
                ))}
              </>
            );
          },
        }}
      />

      <Modal.DefaultRemove
        isOpen={!!selectedLaborToDelete}
        isLoading={isDeletingLabor}
        itemName={selectedLaborToDelete?.name}
        onClose={() => setSelectedLaborToDelete(undefined)}
        suffix="mão de obra"
        title="Remover mão de obra"
        onSubmit={handleDeleteLabor}
      />

      <Modal.LaborForm
        isOpen={!!selectedLaborToUpdate}
        onClose={() => {
          setSelectedLaborToUpdate(undefined);
        }}
        formProps={{
          initialValues: selectedLaborToUpdate,
          onSuccess: () => {
            setSelectedLaborToUpdate(undefined);
          },
        }}
      />

      <Modal.LaborStructuresForm
        isOpen={!!selectedLaborToManageStructures}
        onClose={() => {
          setSelectedLaborToManageStructures(undefined);
        }}
        laborId={selectedLaborToManageStructures?.id ?? 0}
        formProps={{
          onSuccess: () => {
            setSelectedLaborToManageStructures(undefined);
          },
        }}
      />

      <Modal.DefaultAlert
        isOpen={!!selectedLaborToCopy}
        isLoading={isCopingLabor}
        onClose={() => setSelectedLaborToCopy(undefined)}
        subtitle="Realmente deseja criar um cópia desta mão de obra?"
        title="Copiar mão de obra"
        onSubmit={handleCopyLabor}
      />
    </>
  );
}
