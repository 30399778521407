import { BiErrorCircle } from "react-icons/bi";

import type {
  IPipedrivePipelineDealsSelectProps,
  PipedrivePipelineDealsSelectOptionType,
} from "./interface";

import { useFetchGetPipedrivePipelineDeals } from "~hooks/api/pipedrive/useFetchGetPipedrivePipelineDeals";
import { useSession } from "~hooks/useSession";
import { Button, Select, Spin, Tooltip } from "antd";

export function PipedrivePipelineDealsSelect({
  allowClear = true,
  onChange,
  pipelineId,
  ...props
}: IPipedrivePipelineDealsSelectProps) {
  const { user } = useSession();

  const {
    data: pipelineDeals,
    isFetching,
    error,
    refetch,
  } = useFetchGetPipedrivePipelineDeals({
    dependencyArray: [pipelineId],
    payload: { pipelineId },
    options: {
      enabled: !!user?.pipedriveIntegration && pipelineId >= 0,
    },
  });

  const options: PipedrivePipelineDealsSelectOptionType[] =
    pipelineDeals?.data?.map((deal) => ({
      label: deal.title,
      value: deal.id,
      record: deal,
    })) ?? [];

  return (
    <Select
      {...props}
      loading={isFetching}
      options={options}
      allowClear={allowClear}
      status={error ? "error" : ""}
      suffixIcon={
        <>
          {error && (
            <Tooltip title={error.message + " | Clique para recarregar."}>
              <Button type="link" loading={isFetching}>
                <BiErrorCircle size={18} onClick={() => refetch()} />
              </Button>
            </Tooltip>
          )}
          {isFetching && <Spin size="small" spinning />}
        </>
      }
      onChange={(value, option) => {
        if (option instanceof Array || !onChange) return;
        onChange(value, option);
      }}
    />
  );
}
