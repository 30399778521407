import { Key, useMemo } from "react";

import type { ISolarzListProps } from "./interface";
import { StyledList } from "./styles";

import { Result } from "~components/Result";
import { SolarzAnchor } from "~solarzui/SolarzAnchor";
import { List } from "antd";

export function SolarzList<RecordType extends Record<string, any>>({
  items,
  listItemKey,
  renderItem,
  disableReload,
  emptyMessage,
  errorMessage,
  isLoading,
  reloadFn,
  loadMoreRender,
  pagination,
  ...props
}: ISolarzListProps<RecordType>) {
  const CURRENT_PAGE = useMemo(
    () => pagination?.currentPage ?? 0,
    [pagination?.currentPage],
  );

  const HAVE_NEXT_PAGE = useMemo(() => {
    const totalItemCount = pagination?.totalItemCount ?? 0;
    const currentPage = pagination?.currentPage ?? 0;
    const currentItemCount = pagination?.currentItemCount ?? 0;

    return currentItemCount * currentPage < totalItemCount;
  }, [
    pagination?.totalItemCount,
    pagination?.currentPage,
    pagination?.currentItemCount,
  ]);

  return (
    <StyledList
      {...props}
      itemLayout="horizontal"
      dataSource={items}
      loading={isLoading}
      renderItem={(record, index) => {
        return (
          <List.Item key={listItemKey as Key}>
            {renderItem(record, index)}
          </List.Item>
        );
      }}
      loadMore={loadMoreRender}
      rowKey={listItemKey}
      pagination={
        pagination
          ? {
              showSizeChanger: false,
              simple: true,
              showTotal(total, [start, end]) {
                return (
                  <span>{`${start}-${end} de ${total} ${pagination?.itemLabel}.`}</span>
                );
              },
              itemRender(_page, type, originalElement) {
                if (type === "prev") {
                  return (
                    <SolarzAnchor
                      isDisabled={CURRENT_PAGE <= 1}
                      aria-label="Ir para página anterior"
                    >
                      Anterior
                    </SolarzAnchor>
                  );
                }

                if (type === "next") {
                  return (
                    <SolarzAnchor
                      isDisabled={!HAVE_NEXT_PAGE}
                      aria-label="Ir para a próxima página"
                    >
                      Próximo
                    </SolarzAnchor>
                  );
                }

                return originalElement;
              },
              pageSize: pagination.currentItemCount,
              current: CURRENT_PAGE,
              total: pagination.totalItemCount,
              onChange: pagination.onChange,
            }
          : undefined
      }
      locale={{
        emptyText: (
          <Result.TableErrorOrEmpty
            disableReload={disableReload}
            errorMessage={errorMessage}
            emptyMessage={emptyMessage}
            loading={isLoading}
            reloadFn={reloadFn}
            errorTitle={undefined}
          />
        ),
      }}
    />
  );
}
