import { useEffect, useMemo } from "react";

import type {
  IBusinessUnitSelectProps,
  BusinessUnitSelectOptionType,
  BusinessUnitSelectType,
} from "./interface";

import {
  useFetchGetUserBusinessUnits,
  useFetchUpdateUserBusinessUnit,
} from "~hooks/api";
import { useSession } from "~hooks/useSession";
import { SolarzSelect } from "~solarzui/SolarzSelect";

export function BusinessUnitSelect({
  placeholder = "Unidade de negócio...",
  updateCurrentUserBusinessUnitOnSelect = false,
  ...props
}: IBusinessUnitSelectProps) {
  const { user } = useSession();

  const {
    mutate: updateUserBusinessUnit,
    isLoading: isUpdatingUserBusinessUnit,
  } = useFetchUpdateUserBusinessUnit({});

  const {
    data: businessUnits = [],
    isFetching: isLoadingBusinessUnits,
    error: errorBusinessUnits,
    refetch: reloadBusinessUnits,
  } = useFetchGetUserBusinessUnits({
    options: {
      enabled: !!user,
    },
  });

  function handleSelectBusinessUnit(businessUnitId: number) {
    updateUserBusinessUnit({
      businessUnitId,
    });
  }

  const options: BusinessUnitSelectOptionType = useMemo(() => {
    return businessUnits.map((item) => ({
      label: item.name,
      value: item.id,
      record: item,
    }));
  }, [businessUnits]);

  useEffect(() => {
    const firstOptionBusinessUnitId = businessUnits[0]?.id ?? 0;

    if (firstOptionBusinessUnitId <= 0) return;

    const alreadyHaveValidBusinessUnit = businessUnits.find(
      (businessUnit) => businessUnit.id === user?.businessUnitId,
    );

    if (alreadyHaveValidBusinessUnit) return;

    updateUserBusinessUnit({ businessUnitId: firstOptionBusinessUnitId });
  }, [user?.businessUnitId, businessUnits, updateUserBusinessUnit]);

  const isLoading = isUpdatingUserBusinessUnit || isLoadingBusinessUnits;

  return (
    <SolarzSelect<BusinessUnitSelectType, "id">
      {...props}
      options={options}
      isLoading={isLoading}
      onChange={(value, option) => {
        if (typeof props.onChange === "function") {
          props.onChange(value, option);
        }

        if (updateCurrentUserBusinessUnitOnSelect && value) {
          handleSelectBusinessUnit(value instanceof Array ? value[0] : value);
        }
      }}
      placeholder={placeholder}
      emptyMessage="Nenhuma unidade de negócio encontrado"
      errorMessage={errorBusinessUnits?.message}
      refetchFn={reloadBusinessUnits}
      style={{ width: "100%", ...props.style }}
    />
  );
}
