import type { IAddPersonToOrganizationFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function AddPersonToOrganizationFormModal({
  isOpen = false,
  onClose = () => undefined,
  maxWidth = 560,
  title = "Associar pessoa",
  formProps,
  destroyOnClose = true,
}: IAddPersonToOrganizationFormModalProps) {
  return (
    <Modal
      data-cy="add-person-to-organization-form-modal"
      title={title}
      width="100%"
      style={{
        maxWidth,
      }}
      open={isOpen}
      footer={null}
      onCancel={onClose}
      destroyOnClose={destroyOnClose}
      forceRender
    >
      <Form.AddPersonToOrganization {...formProps} onCancel={onClose} />
    </Modal>
  );
}
