import type { IFortlevIntegrationSwitchProps } from "./interface";
import styles from "./styles";

import {
  useFetchToggleDistributors,
  useFetchUpdateConfigFortlevDistributorIntegration,
} from "~hooks/api";
import { Flex, Switch } from "antd";

export function FortlevIntegrationSwitch({
  isChecked = false,
  distributorId = 0,
}: IFortlevIntegrationSwitchProps) {
  const {
    mutate: deactivateIntegration,
    isLoading: isDeactivatingIntegration,
  } = useFetchToggleDistributors();

  const { mutate: activateIntegration, isLoading: isActivatingIntegration } =
    useFetchUpdateConfigFortlevDistributorIntegration();

  function handleActivateIntegration() {
    activateIntegration();
  }

  function handleDeactivateIntegration() {
    deactivateIntegration({ distributorId });
  }

  const isLoading = isActivatingIntegration || isDeactivatingIntegration;

  return (
    <>
      <style jsx>{styles}</style>
      <Flex data-cy="fortlev-integration-switch" align="center" gap={8}>
        <Switch
          checked={isChecked}
          checkedChildren="ativo"
          unCheckedChildren="inativo"
          className="fortlev-integration-switch"
          onClick={
            isChecked ? handleDeactivateIntegration : handleActivateIntegration
          }
          loading={isLoading}
        />
      </Flex>
    </>
  );
}
