import { ISolarzInputProps } from "./interface";

import styled from "@emotion/styled";
import { Input, type InputProps } from "antd";

function getStylesByScaleProps(scale?: ISolarzInputProps["scale"]) {
  switch (scale) {
    case "large":
      return {
        fontSize: "16px",
        height: "3rem",
        lineHeight: "19.2px",
      };
    case "medium":
      return {
        fontSize: "14px",
        height: "2.5rem",
        lineHeight: "17.52px",
      };
    case "tiny":
      return {
        fontSize: "12px",
        height: "2rem",
        lineHeight: "15.84px",
      };
    default:
      return {
        fontSize: "14px",
        height: "2.5rem",
      };
  }
}

interface IStyledInput extends InputProps {
  scale?: ISolarzInputProps["scale"];
}

export const StyledInput = styled(Input)<IStyledInput>`
  width: 100%;
  height: ${(props) => getStylesByScaleProps(props.scale).height};
  font-size: ${(props) => getStylesByScaleProps(props.scale).fontSize};
  line-height: ${(props) => getStylesByScaleProps(props.scale).lineHeight};

  padding: 0rem 1rem;

  svg {
    color: var(--gray-400);
    font-size: 1.25rem;
  }

  .ant-dropdown-trigger {
    cursor: pointer;
  }

  .ant-dropdown-open {
    color: var(--primary-500);
  }
`;
