import { BiErrorCircle } from "react-icons/bi";

import {
  IPipedriveActivitiesSelectProps,
  SelectActivitiesOptionType,
} from "./interface";

import { Result } from "~components/Result";
import {
  useFetchGetActivityCurrent,
  useFetchGetPipedriveActivities,
  useFetchUpdateActivityCurrent,
} from "~hooks/api";
import { useSession } from "~hooks/useSession";
import { Button, Select } from "antd";

export function SelectActivities({
  allowClear = true,
  onChange,
  onLoad = () => undefined,
  ...props
}: IPipedriveActivitiesSelectProps) {
  const { user, token } = useSession();

  const { data: currentActivity, isFetching: isLoadingCurrentActivity } =
    useFetchGetActivityCurrent({
      dependencyArray: [token],
      options: {
        enabled: !!user?.pipedriveIntegration,
      },
    });

  const {
    mutate: updateActivityCurrent,
    isLoading: isUpdatingActivityCurrent,
  } = useFetchUpdateActivityCurrent({});

  function handleSelectActivityCurrent(activitiesId: number) {
    updateActivityCurrent({
      activitiesId,
    });
  }

  const {
    data: activities,
    isFetching: isLoadingActivities,
    error: activitiesError,
    refetch: reloadActivities,
  } = useFetchGetPipedriveActivities({
    dependencyArray: [token],
    options: {
      onSuccess: onLoad,
      enabled: !!user?.pipedriveIntegration,
    },
  });

  const options: SelectActivitiesOptionType[] =
    activities?.data?.map((activity) => ({
      label: activity.name,
      value: activity.id,
      record: activity,
    })) ?? [];

  const isLoading =
    isLoadingActivities ||
    isLoadingCurrentActivity ||
    isUpdatingActivityCurrent;

  return (
    <Select
      {...props}
      loading={isLoading}
      options={options}
      allowClear={allowClear}
      status={activitiesError ? "error" : ""}
      suffixIcon={
        activitiesError ? (
          <Button htmlType="button" type="link">
            {/* using button to set primary color in links */}
            <BiErrorCircle size={18} />
          </Button>
        ) : undefined
      }
      notFoundContent={
        <Result.SelectErrorOrEmpty
          errorMessage={activitiesError?.message}
          emptyMessage="Nenhuma atividade encontrada"
          loading={isLoading}
          reloadFn={reloadActivities}
        />
      }
      value={options.length !== 0 ? currentActivity?.idPipedrive : undefined}
      defaultValue={
        options.length !== 0 ? currentActivity?.idPipedrive : undefined
      }
      onChange={(value, option) => {
        const parsedValue = value as number;

        handleSelectActivityCurrent(parsedValue);

        if (option instanceof Array || !onChange) return;
        onChange(value, option);
      }}
      style={{ maxWidth: "100%" }}
    />
  );
}
