import styled from "@emotion/styled";
import { Card, CardProps } from "antd";

interface StyledCardProps extends CardProps {
  currentProfileId?: boolean;
  theme: string;
}

export const StyledCard = styled(Card)<StyledCardProps>`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;

  ${({ currentProfileId, theme }) => `
    color:${currentProfileId && theme === "dark" ? `var(--gray-800)` : ""};
    border-color: ${
      currentProfileId
        ? theme === "dark"
          ? `var(--primary-500)`
          : `var(--primary-200)`
        : ""
    };
    background-color: ${
      currentProfileId
        ? theme === "dark"
          ? `var(--primary-100)`
          : `var(--primary-50)`
        : ""
    };
  `}
`;
