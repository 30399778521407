import { useMemo } from "react";

import { IDownloadPdfButtonProps } from "./interface";

import { Button } from "antd";

export function DownloadPdfButton({
  children,
  base64,
  filename,
  disabled,
  ...props
}: IDownloadPdfButtonProps) {
  const HREF = useMemo(() => {
    return base64 ? `data:application/pdf;base64,${base64}` : "#";
  }, [base64]);

  const DOWNLOAD_FILENAME = useMemo(() => {
    return filename ? `${filename}.pdf` : `${new Date().toString()}.pdf`;
  }, [filename]);

  return (
    <Button
      {...props}
      href={HREF}
      download={DOWNLOAD_FILENAME}
      disabled={disabled || HREF === "#"}
    >
      {children ?? "Download PDF"}
    </Button>
  );
}
