import type {
  OrganizationOrPersonTabsType,
  IOrganizationOrPersonTabsFormModalProps,
} from "./interface";

import { Alert } from "~components/Alert";
import { Form } from "~components/Form";
import { SolarzTabs } from "~solarzui/SolarzTabs";
import { Modal } from "antd";

export function OrganizationOrPersonTabsFormModal({
  isOpen = false,
  onClose = () => undefined,
  maxWidth = 820,
  title = "Novo cliente",
  organizationFormProps,
  personFormProps,
  disableOptions,
  hideOptions,
  alertMessage,
}: IOrganizationOrPersonTabsFormModalProps) {
  return (
    <Modal
      data-cy="organization-or-person-tabs-form-modal"
      title={title}
      width="90vw"
      style={{
        maxWidth,
      }}
      open={isOpen}
      footer={null}
      onCancel={onClose}
      destroyOnClose
    >
      {!!alertMessage && (
        <Alert.CardError errorMessage={alertMessage} title="AVISO:" />
      )}

      <SolarzTabs<OrganizationOrPersonTabsType>
        tabs={[
          {
            key: "organization",
            label: "Organização",
            disabled: disableOptions?.organization,
            isHidden: hideOptions?.organization,
            children: (
              <Form.Organization
                {...organizationFormProps}
                onSuccess={(organization) => {
                  onClose();
                  if (typeof organizationFormProps?.onSuccess === "function") {
                    organizationFormProps.onSuccess(organization);
                  }
                }}
                onCancel={onClose}
              />
            ),
          },
          {
            key: "person",
            label: "Pessoa",
            disabled: disableOptions?.person,
            isHidden: hideOptions?.person,
            children: (
              <Form.Person
                {...personFormProps}
                onSuccess={(person) => {
                  onClose();
                  if (typeof personFormProps?.onSuccess === "function") {
                    personFormProps.onSuccess(person);
                  }
                }}
                onCancel={onClose}
              />
            ),
          },
        ]}
      />
    </Modal>
  );
}
