import { useState } from "react";

import type {
  FormTypeOptionType,
  FormTypeSelectType,
  IServicesDealFormModalProps,
} from "./interface";

import { Alert } from "~components/Alert";
import { Form } from "~components/Form";
import { cn } from "~estrela-ui/lib/utils";
import { SolarzFormItem } from "~solarzui/SolarzFormItem";
import { SolarzSelect } from "~solarzui/SolarzSelect";
import { Modal } from "antd";

export function ServicesDealFormModal({
  dealId = 0,
  isOpen,
  onClose,
  title = "Formulário de serviços",
  maxWidth = 720,
  formPropsDiverseService,
  formPropsSolarService,
  extraErrorAlertProps,
}: IServicesDealFormModalProps) {
  const [selectFormType, setSelectFormType] =
    useState<FormTypeOptionType>("SOLAR_SERVICE");

  function onCloseModal() {
    setSelectFormType("SOLAR_SERVICE");

    if (typeof onClose === "function") {
      onClose();
    }
  }

  return (
    <Modal
      data-cy="services-deal-form-modal"
      open={isOpen}
      onCancel={onCloseModal}
      title={title}
      width="90vw"
      footer={null}
      style={{ maxWidth }}
      destroyOnClose
    >
      {extraErrorAlertProps && (
        <Alert.CardError
          {...extraErrorAlertProps}
          className={cn("mb-4", extraErrorAlertProps.className)}
        />
      )}

      <div className="w-full flex flex-col gap-3 align-middle justify-center">
        <SolarzFormItem label="Selecione o tipo de serviço">
          <SolarzSelect<FormTypeSelectType, "value">
            style={{ width: "100%" }}
            options={[
              {
                label: "Serviço Solar",
                value: "SOLAR_SERVICE",
                record: {
                  value: "SOLAR_SERVICE",
                  label: "Serviço Solar",
                },
              },
              {
                label: "Serviço Diverso",
                value: "DIVERSE_SERVICE",
                record: {
                  value: "DIVERSE_SERVICE",
                  label: "Serviço Diverso",
                },
              },
            ]}
            value={selectFormType}
            onChange={(value) => {
              if (!value || Array.isArray(value)) return;
              setSelectFormType(value);
            }}
          />
        </SolarzFormItem>

        {selectFormType === "SOLAR_SERVICE" ? (
          <Form.SolarServiceDeal
            {...formPropsSolarService}
            dealId={dealId}
            onCancel={onCloseModal}
          />
        ) : selectFormType === "DIVERSE_SERVICE" ? (
          <Form.DiverseServiceDeal
            {...formPropsDiverseService}
            dealId={dealId}
            onCancel={onCloseModal}
          />
        ) : null}
      </div>
    </Modal>
  );
}
