import { BsTelephone } from "react-icons/bs";
import { CiMail } from "react-icons/ci";
import { FaLinkSlash, FaUserLargeSlash } from "react-icons/fa6";

import { CardLoading } from "./CardLoading";
import type { IOrganizationPersonsCardProps } from "./interface";
import { AssignButton, StyledCard } from "./styles";

import { Alert } from "~components/Alert";
import { Modal } from "~components/Modal";
import { Add } from "~estrela-ui/icons";
import { useFetchGetPersonsFromOrganization } from "~hooks/api";
import { useQueryParams, parseFunctions } from "~hooks/useQueryParams";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { formatTelephoneWithDdd } from "~utils/format";
import { Card, Empty, Flex } from "antd";

const ADD_PERSON_MODAL_PARAM_KEY = "add-person";
const DELETE_PERSON_MODAL_PARAM_KEY = "remove-person";

export function OrganizationPersonsCard({
  organizationId,
  style,
}: IOrganizationPersonsCardProps) {
  const { getParsedQueryParams, handleSaveInQueryParams } = useQueryParams();

  // ADD PERSON MODAL
  const isAddPersonToOrganizationModalOpen = !!getParsedQueryParams(
    ADD_PERSON_MODAL_PARAM_KEY,
    parseFunctions.BOOLEAN,
  );

  function handleOpenAddPersonToOrganizationModal() {
    handleSaveInQueryParams({ [ADD_PERSON_MODAL_PARAM_KEY]: "true" });
  }

  function handleCloseAddPersonToOrganizationModal() {
    handleSaveInQueryParams({ [ADD_PERSON_MODAL_PARAM_KEY]: "" });
  }

  // REMOVE PERSON MODAL
  const removePersonFromOrganizationSelectedId = getParsedQueryParams(
    DELETE_PERSON_MODAL_PARAM_KEY,
    parseFunctions.NUMBER,
  );

  function handleOpenRemovePersonFromOrganizationModal(personId: number) {
    handleSaveInQueryParams({ [DELETE_PERSON_MODAL_PARAM_KEY]: personId });
  }

  function handleCloseRemovePersonFromOrganizationModal() {
    handleSaveInQueryParams({ [DELETE_PERSON_MODAL_PARAM_KEY]: "" });
  }

  const {
    data: persons = [],
    isFetching: isLoadingPersons,
    error: personsError,
    refetch: reloadPersons,
  } = useFetchGetPersonsFromOrganization({
    payload: {
      organizationId,
    },
    dependencyArray: [organizationId],
    options: {
      enabled: organizationId > 0,
    },
  });

  return (
    <>
      <Card
        style={{
          width: "100%",
          maxWidth: "400px",
          ...style,
        }}
        styles={{
          body: {
            padding: 16,
          },
        }}
        title={
          <Flex justify="space-between" align="center">
            <SolarzTypography
              fontWeight="semiBold"
              hierarchy="paragraph1"
              variant="title"
            >
              Pessoas ({persons.length})
            </SolarzTypography>

            <AssignButton
              onClick={handleOpenAddPersonToOrganizationModal}
              scale="tiny"
              variant="ghost"
              icon={<Add className="h-4 w-4" />}
            />
          </Flex>
        }
      >
        <Flex vertical gap={8} style={{ margin: "0.25rem 0" }}>
          {personsError && (
            <Alert.CardError
              title="Falha ao carregar pessoas"
              errorMessage={personsError?.message}
              reloadFn={reloadPersons}
              style={{
                marginBottom: 12,
              }}
            />
          )}

          {isLoadingPersons && <CardLoading />}

          {!isLoadingPersons && persons.length === 0 && !personsError && (
            <Empty
              image={
                <FaUserLargeSlash
                  size={64}
                  style={{ color: "var(--gray-600)" }}
                />
              }
              description="Nenhuma pessoa associada"
            />
          )}

          {!isLoadingPersons &&
            persons.map((person) => {
              return (
                <StyledCard key={person.email} size="small">
                  <Flex align="flex-start" justify="space-between">
                    <SolarzTypography
                      hierarchy="paragraph2"
                      fontWeight="semiBold"
                      style={{ marginBottom: "0.75rem" }}
                    >
                      {person.name}
                    </SolarzTypography>

                    <SolarzButton
                      onClick={() => {
                        handleOpenRemovePersonFromOrganizationModal(person.id);
                      }}
                      scale="tiny"
                      variant="link"
                      icon={
                        <FaLinkSlash
                          size={16}
                          style={{
                            color: "var(--primary-500)",
                            strokeWidth: "1px",
                          }}
                        />
                      }
                      style={{
                        width: 24,
                        height: 24,
                        padding: 0,
                      }}
                    />
                  </Flex>

                  <SolarzTypography
                    hierarchy="small"
                    style={{ marginBottom: "0.5rem" }}
                  >
                    <Flex align="center" gap={8}>
                      <CiMail size={20} />
                      {person.email}
                    </Flex>
                  </SolarzTypography>

                  <SolarzTypography hierarchy="small">
                    <Flex align="center" gap={8}>
                      <BsTelephone style={{ width: 20, height: 16 }} />
                      {formatTelephoneWithDdd(person.phone ?? "")}
                    </Flex>
                  </SolarzTypography>
                </StyledCard>
              );
            })}
        </Flex>
      </Card>

      <Modal.AddPersonToOrganizationForm
        isOpen={isAddPersonToOrganizationModalOpen}
        onClose={handleCloseAddPersonToOrganizationModal}
        title="Adicionar pessoas na organização"
        formProps={{
          onSuccess: handleCloseAddPersonToOrganizationModal,
          disableOrganizationId: true,
          initialValues: {
            organizationId,
          },
          onSuccessClear: true,
        }}
        destroyOnClose={false}
      />

      <Modal.RemovePersonFromOrganization
        isOpen={!!removePersonFromOrganizationSelectedId}
        onClose={handleCloseRemovePersonFromOrganizationModal}
        organizationId={organizationId}
        personId={removePersonFromOrganizationSelectedId ?? 0}
      />
    </>
  );
}
