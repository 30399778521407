import { useEffect, useState } from "react";

import { IReleaseNoteInfoButtonProps } from "./interface";
import { StyledReleaseNotesIOButton } from "./styles";

import { RELEASE_NOTE_READ_STORAGE_KEY } from "~configs/AppConfig";
import { ENV } from "~configs/Env";
import { Newspaper } from "~estrela-ui/icons";
import { Badge } from "antd";

export function ReleaseNoteInfoButton(_props: IReleaseNoteInfoButtonProps) {
  const [isNew, setIsNew] = useState<boolean>(false);

  function onSuccessExecuteReleaseNoteModalFn() {
    if (ENV.APP_VERSION) {
      localStorage.setItem(RELEASE_NOTE_READ_STORAGE_KEY, ENV.APP_VERSION);
      setIsNew(false);
    }
  }

  useEffect(() => {
    const versionAlreadyRead = localStorage.getItem(
      RELEASE_NOTE_READ_STORAGE_KEY,
    );

    if (
      !!ENV.APP_VERSION &&
      (!versionAlreadyRead || versionAlreadyRead !== ENV.APP_VERSION)
    ) {
      setIsNew(true);
    }
  }, []);

  return (
    <Badge
      dot={isNew}
      styles={{
        indicator: {
          width: "0.625rem", // 10px,
          height: "0.625rem", // 10px,
          top: "16%",
          right: "14%",
        },
      }}
    >
      <StyledReleaseNotesIOButton
        type="link"
        shape="round"
        icon={<Newspaper color="var(--gray-400)" className="text-gray-400" />}
        onSuccessLoadFn={onSuccessExecuteReleaseNoteModalFn}
      // animate={isNew}
      />
    </Badge>
  );
}
