import { useState } from "react";

import type {
  IBusinessUnitFormProps,
  IBusinessUnitFormInitialValues,
  IBusinessUnitFormType,
} from "./interface";

import { Modal } from "~components/Modal";
import { Select } from "~components/Select";
import {
  useFetchCreateBusinessUnit,
  useFetchGetAddressesCompany,
  useFetchUpdateBusinessUnit,
} from "~hooks/api";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzInput } from "~solarzui/SolarzInput";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { Form } from "antd";
import type { DefaultOptionType } from "antd/lib/select";

export function BusinessUnitForm({
  initialValues = {
    addressBaseId: undefined,
    id: undefined,
    name: undefined,
  },
  onCancel = () => null,
  onError = () => undefined,
  onSuccess = () => undefined,
}: IBusinessUnitFormProps) {
  const [form] = Form.useForm<IBusinessUnitFormInitialValues>();
  const businessUnitId = initialValues.id;

  const [options, setOptions] = useState<DefaultOptionType[]>([]);

  const [isCreateBaseAddressModalOpen, setIsCreateBaseAddressModalOpen] =
    useState(false);

  const { mutate: updateBusinessUnit, isLoading: isUpdating } =
    useFetchUpdateBusinessUnit({
      options: {
        onSuccess,
        onError,
      },
    });

  const { mutate: createBusinessUnit, isLoading: isCreating } =
    useFetchCreateBusinessUnit({
      options: {
        onSuccess,
        onError,
      },
    });

  const { isLoading: isLoadingOptions } = useFetchGetAddressesCompany({
    options: {
      onSuccess: (data) => {
        const parsedData = data.map((item: any) => {
          return {
            label: `${item.city.name} - ${item.street}, ${item.number} - ${item.city.name} / ${item.province.name}`,
            value: item.id,
          };
        });

        setOptions(parsedData);
      },
    },
  });

  async function handleCreateBusinessUnit(body: IBusinessUnitFormType) {
    createBusinessUnit(body);
  }

  async function editBusinessUnit(
    businessUnitId: number,
    body: IBusinessUnitFormType,
  ) {
    updateBusinessUnit({ businessUnitId, ...body });
  }

  async function onHandleSubmit(values: IBusinessUnitFormInitialValues) {
    if (!values.name || !values.addressBaseId) return;

    const body = {
      name: values.name,
      address: {
        id: values.addressBaseId,
      },
    };

    businessUnitId
      ? editBusinessUnit(businessUnitId, body)
      : handleCreateBusinessUnit(body);
  }

  const isUpdatingData = isUpdating || isCreating;

  return (
    <>
      <Form
        data-cy="business-unit-form"
        layout="vertical"
        style={{ width: "100%" }}
        form={form}
        onFinish={onHandleSubmit}
        initialValues={initialValues}
      >
        <Form.Item
          label="Nome da unidade"
          name="name"
          rules={[{ required: true }]}
        >
          <SolarzInput placeholder="Nome" style={{ height: 48 }} />
        </Form.Item>

        <Form.Item
          label="Endereço base"
          name="addressBaseId"
          rules={[{ required: true }]}
          extra={
            !isLoadingOptions &&
            options.length === 0 && (
              <SolarzTypography
                style={{
                  color: "var(--gray-500)",
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                  gap: 4,
                  marginTop: 8,
                }}
                fontWeight="semiBold"
                hierarchy="tiny"
              >
                É preciso{" "}
                <SolarzButton
                  variant="link"
                  scale="tiny"
                  style={{ padding: 0, color: "var(--primary-500)" }}
                  onClick={() => setIsCreateBaseAddressModalOpen(true)}
                >
                  cadastrar um endereço base
                </SolarzButton>{" "}
                para continuar
              </SolarzTypography>
            )
          }
        >
          <Select.BaseAddress style={{ height: 48 }} />
        </Form.Item>

        <div className="flex flex-row align-middle justify-end gap-3">
          <SolarzButton variant="secondary" onClick={onCancel}>
            Cancelar
          </SolarzButton>
          <SolarzButton
            isLoading={isUpdatingData}
            variant="primary"
            htmlType="submit"
          >
            Salvar
          </SolarzButton>
        </div>
      </Form>

      <Modal.BaseAddressForm
        isOpen={isCreateBaseAddressModalOpen}
        onClose={() => setIsCreateBaseAddressModalOpen(false)}
      />
    </>
  );
}
