import type { IDealCustomFieldGroupFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function DealCustomFieldGroupFormModal({
  isOpen = false,
  onClose = () => undefined,
  maxWidth = 960,
  title = "Formulário de grupo",
  formProps,
}: IDealCustomFieldGroupFormModalProps) {
  return (
    <Modal
      data-cy="deal-custom-field-group-form-modal"
      title={title}
      width="90vw"
      style={{
        maxWidth,
      }}
      open={isOpen}
      footer={null}
      onCancel={onClose}
      destroyOnClose
    >
      <Form.DealCustomFieldGroup {...formProps} onCancel={onClose} />
    </Modal>
  );
}
