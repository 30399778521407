import { useState } from "react";

import type {
  AdminDatasheetRevisionsTableFiltersType,
  IAdminDatasheetRevisionsTableProps,
} from "./interface";

import { useFetchGetPaginatedDatasheetRevisions } from "~hooks/api";
import { SolarzTable } from "~solarzui/SolarzTable";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import dayjs from "dayjs";

const DEFAULT_FILTERS: AdminDatasheetRevisionsTableFiltersType = {
  page: 1,
  size: 10,
};

export function AdminDatasheetRevisionsTable({
  datasheetId,
  initialFilters,
}: IAdminDatasheetRevisionsTableProps) {
  const [filters, setFilters] =
    useState<AdminDatasheetRevisionsTableFiltersType>({
      ...DEFAULT_FILTERS,
      ...initialFilters,
    });

  function onChangeFilters(
    filters: Partial<AdminDatasheetRevisionsTableFiltersType>,
  ) {
    setFilters((currentFilters) => {
      return {
        ...currentFilters,
        ...filters,
      };
    });
  }

  const {
    data: adminsData,
    isFetching: isLoadingAdminDatasheetRevisions,
    error: adminsError,
    refetch: reloadAdminDatasheetRevisions,
  } = useFetchGetPaginatedDatasheetRevisions({
    dependencyArray: [filters.page, datasheetId],
    payload: {
      ...filters,
      page: filters.page - 1,
      id: datasheetId,
    },
    options: {
      enabled: datasheetId > 0 && filters.page > 0,
    },
  });

  return (
    <SolarzTable
      data-cy="admin-datasheet-revisions-table"
      isLoading={isLoadingAdminDatasheetRevisions}
      columns={[
        {
          title: "Id",
          dataIndex: "id",
          key: "id",
          width: 80,
          align: "center",
          render: (_, record) => (
            <SolarzTypography fontWeight="regular" style={{ fontSize: 14 }}>
              #{record.id}
            </SolarzTypography>
          ),
        },
        {
          title: "Revisão",
          dataIndex: "revision",
          key: "revision",
          width: 80,
          align: "center",
          render: (_, record) => (
            <SolarzTypography fontWeight="regular" style={{ fontSize: 14 }}>
              #{record.revision}
            </SolarzTypography>
          ),
        },
        {
          title: "Hash",
          dataIndex: "hash",
          key: "hash",
          render: (_, record) => (
            <SolarzTypography fontWeight="semiBold" style={{ fontSize: 14 }}>
              {record.hash}
            </SolarzTypography>
          ),
        },
        {
          title: "Criado em:",
          dataIndex: "createdAt",
          key: "createdAt",
          width: 320,
          render: (_, record) => {
            const formattedDate = record.createdAt
              ? dayjs(record.createdAt).format("DD/MM/YYYY")
              : "-";

            return (
              <SolarzTypography fontWeight="regular" style={{ fontSize: 14 }}>
                {formattedDate}
              </SolarzTypography>
            );
          },
        },
      ]}
      rows={adminsData?.content ?? []}
      pagination={{
        currentItemCount: adminsData?.size ?? 0,
        currentPage: adminsData?.number ? adminsData?.number + 1 : 1,
        itemLabel: "Revisões",
        totalItemCount: adminsData?.totalElements ?? 0,
        onChange: (page) =>
          onChangeFilters({
            page: page - 1,
          }),
      }}
      rowKey="id"
      scroll={{ x: 688 }}
      emptyMessage="Nenhuma revisão encontrada"
      errorMessage={adminsError?.message}
      reloadFn={reloadAdminDatasheetRevisions}
    />
  );
}
