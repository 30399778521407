import type { IChangeIsTemplateModalProps } from "./interface";

import { useFetchChangeIsTemplate } from "~hooks/api";
import { Flex, Modal, Alert } from "antd";

export function ChangeIsTemplateModal({
  companyId,
  isOpen = false,
  isTemplate = false,
  onClose = () => null,
  maxWidth = 520,
  onError,
  onSuccess,
}: IChangeIsTemplateModalProps) {
  const { mutate: changeIsTemplate, isLoading: isChanging } =
    useFetchChangeIsTemplate({
      onSuccess: () => {
        if (typeof onSuccess === "function") {
          onSuccess();
        }

        onClose();
      },
      onError,
    });

  function handleAccountChangeModel() {
    changeIsTemplate({ companyId });
  }

  const title = !isTemplate
    ? "Transformar conta em Template"
    : "Desfazer conta como Template";

  const actionText = !isTemplate
    ? "Transformar em Template"
    : "Desfazer Template";

  const infoMessage = !isTemplate
    ? "Você tem certeza que deseja transformar esta conta em um template?"
    : "Você tem certeza que deseja desfazer o template desta conta?";

  const descriptionMessage = !isTemplate
    ? "Uma conta template serve como modelo para configurar outras contas. Ela pode ser de um integrador ou criada apenas para configuração. Transformá-la em template não afeta o uso diário; é uma mudança para facilitar a replicação das configurações por usuários administrativos, agilizando a criação de novas contas."
    : "Desfazer o template desta conta permitirá que ela volte a ser uma conta comum. Isso não afetará as configurações atuais, mas impedirá que a conta seja usada como modelo para novas contas.";

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title={title}
      style={{
        width: "100%",
        maxWidth,
      }}
      okText={actionText}
      onOk={handleAccountChangeModel}
      okButtonProps={{
        loading: isChanging,
      }}
    >
      <Flex vertical gap={16}>
        <Alert
          type="info"
          message={infoMessage}
          description={descriptionMessage}
          style={{ padding: "8px 16px" }}
        />
      </Flex>
    </Modal>
  );
}
