import type { Location as GoogleLocation } from "~components/GoogleMaps/contexts/GoogleMapsContext";
import type { IAddressDTOSchema } from "~types/schemas";

// 12345678901234 => 12.345.678/9012-34 or 12345678901 => 123.456.789-01
export const formatCpfCnpj = (value: string) => {
  try {
    if (!value) throw new Error("missing value");

    const replacedValue = value.replace(/\D/g, "");

    if (replacedValue.length <= 11) {
      return replacedValue
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    }

    return replacedValue
      .replace(/^(\d{2})(\d)/, "$1.$2")
      .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
      .replace(/\.(\d{3})(\d)/, ".$1/$2")
      .replace(/(\d{4})(\d)/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  } catch (error: any) {
    console.warn("formatCpfCnpj:", error?.message);
    return "";
  }
};

// 12345678901 => 123.456.789-01
export const formatCpf = (value: string) => {
  try {
    if (!value) throw new Error("missing value");

    return value
      .replace(/\D+/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  } catch (error: any) {
    console.warn("formatCpf:", error?.message);
    return "";
  }
};

// 12345678901234 => 12.345.678/9012-34
export const formatCnpj = (value: string) => {
  if (!value) return "";

  return value
    .replace(/\D+/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
};

// 12345123 => 12345-123
export const formatCep = (value: string) => {
  try {
    if (!value) throw new Error("missing value");

    return value
      .replace(/\D+/g, "")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{3})\d+?$/, "$1");
  } catch (error: any) {
    console.warn("formatCep:", error?.message);
    return "";
  }
};

// 123456789 => 12345-6789
export const formatTelephone = (value: string) => {
  try {
    if (!value) throw new Error("missing value");

    const replacedValue = value.replace(/\D/g, "");

    if (replacedValue.length >= 9) {
      return replacedValue
        .replace(/(\d{5})(\d)/, "$1-$2")
        .replace(/(-\d{4})\d+?$/, "$1");
    }

    return replacedValue
      .replace(/(\d{4})(\d)/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1");
  } catch (error: any) {
    console.warn("formatTelephone:", error?.message);
    return "";
  }
};

// 83123456789 => (83) 12345-6789
export const formatTelephoneWithDdd = (value: string) => {
  try {
    const numericOnly = value.replace(/\D/g, "");

    if (!numericOnly) {
      return "";
    }

    const areaCode = numericOnly.substring(0, 2);

    const isAFiveDigitsNumber = numericOnly.length >= 11;

    const localPart = numericOnly.substring(2, isAFiveDigitsNumber ? 7 : 6);

    if (!localPart) {
      return numericOnly;
    }

    let remainingPart = numericOnly.substring(isAFiveDigitsNumber ? 7 : 6);

    if (numericOnly.length >= 6) {
      remainingPart = remainingPart.substring(0, 4);
    }

    if (!remainingPart) {
      const formattedNumber = `(${areaCode}) ${localPart}`;
      return formattedNumber;
    }

    const formattedNumber = `(${areaCode}) ${localPart}-${remainingPart}`;

    return formattedNumber;
  } catch (error: any) {
    console.warn("formatTelephone:", error?.message);
    return "";
  }
};

export const formatUuid = (value: string, withDash = true) => {
  try {
    if (!value) throw new Error("missing value");

    const sanitizedValue = value
      .replaceAll("-", "")
      .substring(0, 32)
      .replace(/[^a-fA-F0-9]/g, "");

    if (!withDash) {
      return sanitizedValue;
    }

    const part1 = sanitizedValue.substring(0, 8);

    const part2 = sanitizedValue.substring(8, 12);

    const part3 = sanitizedValue.substring(12, 16);

    const part4 = sanitizedValue.substring(16, 20);

    const part5 = sanitizedValue.substring(20, 32);

    const formattedValue = `${part1}${part2 ? "-" + part2 : ""}${
      part3 ? "-" + part3 : ""
    }${part4 ? "-" + part4 : ""}${part5 ? "-" + part5 : ""}`;

    return formattedValue;
  } catch (error: any) {
    console.warn("formatUuid:", error?.message);
    return "";
  }
};

export const formatAddressDTO = (address: IAddressDTOSchema) => {
  const street = address.street ? `${address.street}` : undefined;
  const streetNumber = address.number ? `nº ${address.number}` : undefined;
  const city = address.city ? `${address.city.name}` : undefined;
  const uf = address.province ? `${address.province?.uf}` : undefined;
  const zipCode = address.zipCode ? `${address.zipCode}` : undefined;

  return [street, streetNumber, city, uf, zipCode].join(", ");
};

export const formatGoogleLocationAddress = (location: GoogleLocation) => {
  const street = location.street ? `${location.street}` : undefined;
  const streetNumber = location.street
    ? `nº ${location.streetNumber}`
    : undefined;
  const city = location.city ? `${location.city}` : undefined;
  const uf = location.state ? `${location.state}` : undefined;
  const zipCode = location.zipCode ? `${location.zipCode}` : undefined;

  return [street, streetNumber, city, uf, zipCode].join(", ");
};

// (83) 98765-4321 -> 83987654321
export const formatStringToBeNumeric = (value: string) => {
  const onlyNumbers = value.replace(/\D/g, "");
  return onlyNumbers;
};
