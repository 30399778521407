import type {
  ChangeDealTotalValueTypeFormValuesType,
  IChangeDealTotalValueTypeFormProps,
} from "./interface";

import { useFetchGetDealByCustomId, useFetchGetProposalStep } from "~hooks/api";
import { useFetchPatchDealTotalValueType } from "~hooks/api/projects/useFetchPatchDealTotalValueType";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzFormItem } from "~solarzui/SolarzFormItem";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { parseCurrency } from "~utils/parse";
import { Flex, Form, Radio, Skeleton } from "antd";

export function ChangeDealTotalValueTypeForm({
  formInstance,
  initialValues,
  dealId,
  projectCustomId,
  onCancel,
  onError,
  onSuccess,
}: IChangeDealTotalValueTypeFormProps) {
  const [form] =
    Form.useForm<ChangeDealTotalValueTypeFormValuesType>(formInstance);

  const {
    data: dealDetails,
    isLoading: isDealDetailsLoading,
    error: errorDealDetails,
    refetch: reloadDealDetails,
  } = useFetchGetDealByCustomId({
    payload: {
      customId: dealId ?? 0,
    },
    options: {
      enabled: dealId !== null,
    },
    dependencyArray: [dealId],
  });

  const {
    data: proposalStepData,
    isLoading: isProposalStepLoading,
    error: errorProposalStepData,
    refetch: reloadProposalStepData,
  } = useFetchGetProposalStep({
    payload: {
      customId: projectCustomId,
    },
    options: {
      enabled: projectCustomId > 0,
    },
  });

  const { mutate: changeDealTotalValueType, isLoading: isUpdating } =
    useFetchPatchDealTotalValueType({
      options: {
        onSuccess,
        onError,
      },
    });

  function handleSubmit(formValues: ChangeDealTotalValueTypeFormValuesType) {
    if (formValues.dealUpdateTotalValueType)
      changeDealTotalValueType({
        customId: projectCustomId,
        dealUpdateTotalValueType: formValues.dealUpdateTotalValueType,
      });
  }

  function handleCancel() {
    typeof onCancel === "function" ? onCancel() : form.resetFields();
  }

  const proposalValue = proposalStepData?.value ?? 0;
  const dealValue = dealDetails?.value ?? 0;
  const finalValue = proposalValue + dealValue;

  const isLoadingData = isProposalStepLoading || isDealDetailsLoading;

  return (
    <Form
      form={form}
      initialValues={{
        dealUpdateTotalValueType: "ADD",
        ...initialValues,
      }}
      onFinish={handleSubmit}
      preserve
      layout="vertical"
    >
      <SolarzTypography
        variant="subTitle"
        fontWeight="semiBold"
        style={{ marginTop: "1rem" }}
      >
        O valor atual do negócio é de{" "}
        {isLoadingData ? (
          <Skeleton.Input size="small" />
        ) : (
          <span>{parseCurrency(dealValue)}</span>
        )}
      </SolarzTypography>
      <SolarzFormItem<ChangeDealTotalValueTypeFormValuesType>
        name="dealUpdateTotalValueType"
        rules={[{ required: true }]}
        style={{ marginTop: "0.5rem", marginBottom: "1.5rem" }}
      >
        <SolarzTypography variant="subTitle" style={{ marginBottom: "0.5rem" }}>
          O que deseja fazer?
        </SolarzTypography>
        <Radio.Group
          className="totalValueTypesRadioGroup"
          defaultValue={"ADD"}
          onChange={(value) =>
            form.setFieldValue("dealUpdateTotalValueType", value.target.value)
          }
          options={[
            {
              label: (
                <Flex vertical>
                  <SolarzTypography>
                    Somar valor da proposta ao valor do negócio
                  </SolarzTypography>
                  <SolarzTypography fontWeight="semiBold" hierarchy="small">
                    Valor final:{" "}
                    {isLoadingData ? (
                      <Skeleton.Input size="small" />
                    ) : (
                      <span style={{ color: "var(--primary-500)" }}>
                        {parseCurrency(finalValue)}
                      </span>
                    )}
                  </SolarzTypography>
                </Flex>
              ),
              value: "ADD",
              style: {
                marginBottom: "0.5rem",
                gap: 4,
              },
            },
            {
              label: (
                <Flex vertical>
                  <SolarzTypography>
                    Substituir valor do negócio pelo valor da proposta
                  </SolarzTypography>
                  <SolarzTypography fontWeight="semiBold" hierarchy="small">
                    Valor final:{" "}
                    {isLoadingData ? (
                      <Skeleton.Input size="small" />
                    ) : (
                      <span style={{ color: "var(--primary-500)" }}>
                        {parseCurrency(proposalValue)}
                      </span>
                    )}
                  </SolarzTypography>
                </Flex>
              ),
              value: "OVERWRITE",
              style: {
                gap: 4,
              },
            },
          ]}
        />
      </SolarzFormItem>

      <Flex
        style={{ marginTop: 16 }}
        align="center"
        gap={16}
        justify="flex-end"
      >
        <SolarzButton
          isDisabled={isUpdating}
          variant="secondary"
          scale="tiny"
          onClick={handleCancel}
        >
          Cancelar
        </SolarzButton>
        <SolarzButton
          isLoading={isUpdating}
          isDisabled={isUpdating}
          scale="tiny"
          htmlType="submit"
          variant="primary"
        >
          Salvar
        </SolarzButton>
      </Flex>
    </Form>
  );
}
