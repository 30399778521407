import type { IPerformanceProfileFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function PerformanceProfileFormModal({
  isOpen = false,
  onClose = () => null,
  title = "Perfil de performance",
  maxWidth = 512,
  formProps = {},
}: IPerformanceProfileFormModalProps) {
  return (
    <Modal
      data-cy="performance-profile-form-modal"
      open={isOpen}
      onCancel={onClose}
      title={title}
      width="90vw"
      footer={null}
      style={{ maxWidth }}
      destroyOnClose
    >
      <Form.PerformanceProfile onCancel={onClose} {...formProps} />
    </Modal>
  );
}
