import { useEffect, useMemo, useState } from "react";

import type {
  DealAutoCompleteSelectOptionType,
  DealAutoCompleteSelectRecordType,
  IDealAutoCompleteSelectSelectProps,
} from "./interface";

import { useFetchSearchDeal } from "~hooks/api";
import { SolarzSelect } from "~solarzui/SolarzSelect";

export function DealAutoCompleteSelect({
  dealId,
  onChange,
  ...props
}: IDealAutoCompleteSelectSelectProps) {
  const [search, setSearch] = useState("");

  const {
    data: deals = [],
    isFetching: isLoadingDeal,
    error: errorDeal,
    refetch: reloadDeals,
  } = useFetchSearchDeal({
    dependencyArray: [search],
    payload: {
      term: search,
    },
    options: {
      enabled: search.length >= 0,
      retry: false,
    },
  });

  const OPTIONS = useMemo(() => {
    return deals.map((deal) => ({
      value: deal.id,
      label: deal.name,
      record: deal,
    })) as DealAutoCompleteSelectOptionType;
  }, [deals]);

  const SELECTED_OPTION = useMemo(() => {
    if (dealId && !!OPTIONS) {
      const initialOption = OPTIONS.find((option) => option.value === dealId);
      if (initialOption) {
        return initialOption;
      }
    }
    return null;
  }, [dealId, OPTIONS]);

  useEffect(() => {
    if (SELECTED_OPTION && typeof onChange === "function") {
      onChange(SELECTED_OPTION.value, SELECTED_OPTION);
    }
  }, [SELECTED_OPTION, onChange]);

  return (
    <SolarzSelect<DealAutoCompleteSelectRecordType, "id">
      {...props}
      data-cy="deal-select"
      showSearch
      onChangeSearch={(value) => setSearch(value)}
      value={SELECTED_OPTION?.value}
      onChange={(value, option) => {
        if (typeof onChange === "function") {
          if (!value || !option) return onChange(null, null);
          if (Array.isArray(option)) return onChange(null, null);
          return onChange(option.value || null, option);
        }
      }}
      options={OPTIONS}
      isLoading={isLoadingDeal}
      refetchFn={reloadDeals}
      errorMessage={errorDeal?.message}
      emptyMessage="Nenhum negócio encontrado"
    />
  );
}
