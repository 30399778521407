import { FC } from "react";

import type { IKitItemsDetailsTableModalProps } from "./interface";

import { Table } from "~components/Table";
import { Modal } from "antd";

export const KitItemsDetailsTableModal: FC<IKitItemsDetailsTableModalProps> = ({
  isOpen = false,
  onClose = () => null,
  itemKits = [],
  title = "Detalhes",
  width = "90vw",
  maxWidth = 1280,
  tableProps,
}) => {
  return (
    <Modal
      data-cy="kit-items-table-modal"
      open={isOpen}
      onCancel={onClose}
      title={title}
      width={width}
      footer={null}
      destroyOnClose
      style={{
        maxWidth,
      }}
    >
      <Table.KitItemsDetails {...tableProps} kitItems={itemKits} />
    </Modal>
  );
};
