const COLORS = [
  "#fa541c",
  "#52c41a",
  "#1677ff",
  "#F333FF",
  "#13c2c2",
  "#BDB700",
  "#FF9333",
  "#8C33FF",
  "#f5222d",
  "#eb2f96",
];

// Função simples de hashing para converter o ID do usuário e iniciais em um número
function simpleHash(input: string) {
  let hash: number = 0;

  for (let i = 0; i < input.length; i++) {
    const char = input.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash; // Convert to 32bit integer
  }

  return Math.abs(hash);
}

export function getAvatarColorByIdAndName(userId: number, name: string) {
  const uniqueInput = `${userId}-${name}`;

  const hash = simpleHash(uniqueInput);
  const colorIndex = hash % COLORS.length;

  return COLORS[colorIndex];
}
