import { useState } from "react";

import type { ICreateUserGroupButtonProps } from "./interface";

import { Modal } from "~components/Modal";
import { SolarzButton } from "~solarzui/SolarzButton";

export function CreateUserGroupButton({
  children,
  ...props
}: ICreateUserGroupButtonProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <SolarzButton
        {...props}
        data-cy="create-user-group-button"
        onClick={() => setIsModalOpen(true)}
        variant="primary"
        htmlType="button"
      >
        {children ?? "Criar grupo de usuário"}
      </SolarzButton>

      <Modal.UserGroupForm
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        formProps={{
          onSuccess: () => setIsModalOpen(false),
        }}
      />
    </>
  );
}
