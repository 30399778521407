import { useEffect, useMemo } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";

import {
  ILaborStructuresFormProps,
  ILaborStructuresFormType,
} from "./interface";
import styles from "./styles";

import { CustomInput } from "~components/CustomInput";
import { Result } from "~components/Result";
import {
  useFetchAddStructuresInLabor,
  useFetchGetAllStructuresToAssociateLabor,
} from "~hooks/api";
import type { IStructureTypeDTOSchema } from "~types/schemas";
import { parseStructureToLabel } from "~utils/parseInformationToStructureLabel";
import { Button, Checkbox, Form, Typography, Spin, Flex } from "antd";

export function LaborStructuresForm({
  laborId,
  onSuccess = () => undefined,
  onError = () => undefined,
  onCancel = () => undefined,
}: ILaborStructuresFormProps) {
  const [form] = Form.useForm<ILaborStructuresFormType>();

  const {
    data: structures = [],
    isFetching: isLoadingStructures,
    error: structureError,
  } = useFetchGetAllStructuresToAssociateLabor({
    payload: {
      id: laborId,
    },
    options: {
      enabled: laborId > 0,
    },
  });

  const { mutate: addStructuresInLabor, isLoading: isAddingStructuresInLabor } =
    useFetchAddStructuresInLabor({
      options: {
        onSuccess: () => {
          onSuccess();
        },
        onError: (error) => {
          onError(error.message);
        },
      },
    });

  const OPTIONS = useMemo(() => {
    const options = structures.map((item: IStructureTypeDTOSchema) => ({
      label: parseStructureToLabel(item),
      value: item.id,
      record: item,
    }));

    const filteredOptions = options.filter(
      (item) =>
        (item.record.belongsToLabor || item.record.available) &&
        item.record.active,
    );

    return filteredOptions;
  }, [structures]);

  useEffect(() => {
    const structureIds = structures
      .filter((item) => item.belongsToLabor)
      .map((item) => item.id);

    form.setFieldsValue({
      laborId,
      structureIds,
    });
  }, [form, laborId, structures]);

  return (
    <Form
      form={form}
      data-cy="labor-structures-form"
      onFinish={addStructuresInLabor}
    >
      <style jsx>{styles}</style>

      <Form.Item<ILaborStructuresFormType> name="laborId" hidden>
        <CustomInput.Number readOnly disabled />
      </Form.Item>

      <Typography.Text
        type="secondary"
        style={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          marginBottom: 10,
        }}
      >
        <AiOutlineInfoCircle size={20} />
        Selecione as estruturas e clique no botão para salvar
      </Typography.Text>

      {structures.length === 0 && !isLoadingStructures && (
        <Result.TableErrorOrEmpty
          emptyMessage="Nenhuma estrutura possível"
          errorMessage={structureError?.message}
          errorTitle="Ops!"
        />
      )}

      <Spin spinning={isLoadingStructures}>
        <Form.Item<ILaborStructuresFormType> name="structureIds">
          <Checkbox.Group
            style={{
              flexDirection: "column",
              flexWrap: "wrap",
            }}
            className="laborStructuresCheckboxes"
            options={OPTIONS}
          />
        </Form.Item>
      </Spin>

      <Flex align="center" justify="flex-end" gap={8}>
        <Button onClick={onCancel}>Cancelar</Button>
        <Button
          type="primary"
          htmlType="submit"
          loading={isAddingStructuresInLabor}
        >
          Salvar
        </Button>
      </Flex>
    </Form>
  );
}
