import {
  IGlobalSolarRadiation,
  IMonthlySolarRadiationDTOSchema,
  MonthlySolarRadiationDTOSchema,
} from "~types/schemas";

export function getAverageMonthlyIrradiation(
  globalIrradiation: IMonthlySolarRadiationDTOSchema,
) {
  const values = globalIrradiation?.monthlySolarRadiation
    ? Object.values<number>(globalIrradiation?.monthlySolarRadiation)
    : [];
  const total = values.reduce((total, value) => total + value, 0);

  const average = Number((total / 12).toFixed(2));

  return average;
}

export function getAverageGlobalIrradiation(
  globalIrradiation: Partial<IGlobalSolarRadiation>,
) {
  const values = Object.values<number>(globalIrradiation);

  const total = values.reduce((total, value) => total + value, 0);

  const average = Number((total / 12).toFixed(2));

  return average;
}

export function transformGlobalRadiationToMonthlySolarRadiation(
  solarRadiation: IGlobalSolarRadiation,
): IGlobalSolarRadiation {
  return {
    jan: (solarRadiation.jan * 31) / 1000,
    feb: (solarRadiation.feb * 28) / 1000,
    mar: (solarRadiation.mar * 31) / 1000,
    apr: (solarRadiation.apr * 30) / 1000,
    may: (solarRadiation.may * 31) / 1000,
    jun: (solarRadiation.jun * 30) / 1000,
    jul: (solarRadiation.jul * 31) / 1000,
    aug: (solarRadiation.aug * 31) / 1000,
    sep: (solarRadiation.sep * 30) / 1000,
    oct: (solarRadiation.oct * 31) / 1000,
    nov: (solarRadiation.nov * 30) / 1000,
    dec: (solarRadiation.dec * 31) / 1000,
  };
}

export function transformMonthlySolarRadiationToDailyRadiation(
  adjustedSolarRadiation: IMonthlySolarRadiationDTOSchema,
): IMonthlySolarRadiationDTOSchema {
  if (!adjustedSolarRadiation.monthlySolarRadiation) {
    return adjustedSolarRadiation;
  }
  const originalSolarRadiation = adjustedSolarRadiation.monthlySolarRadiation;

  const originalValues = {
    jan: (originalSolarRadiation.jan * 1000) / 31,
    feb: (originalSolarRadiation.feb * 1000) / 28,
    mar: (originalSolarRadiation.mar * 1000) / 31,
    apr: (originalSolarRadiation.apr * 1000) / 30,
    may: (originalSolarRadiation.may * 1000) / 31,
    jun: (originalSolarRadiation.jun * 1000) / 30,
    jul: (originalSolarRadiation.jul * 1000) / 31,
    aug: (originalSolarRadiation.aug * 1000) / 31,
    sep: (originalSolarRadiation.sep * 1000) / 30,
    oct: (originalSolarRadiation.oct * 1000) / 31,
    nov: (originalSolarRadiation.nov * 1000) / 30,
    dec: (originalSolarRadiation.dec * 1000) / 31,
  };

  const reversedMonthlySolarRadiation = {
    monthlySolarRadiation: originalValues,
    irradiationType: adjustedSolarRadiation.irradiationType,
  };

  return reversedMonthlySolarRadiation;
}

export function transformDailySolarRadiationToMonthlyRadiation(
  adjustedSolarRadiation: IMonthlySolarRadiationDTOSchema,
): IMonthlySolarRadiationDTOSchema {
  if (!adjustedSolarRadiation.monthlySolarRadiation) {
    return adjustedSolarRadiation;
  }

  const originalSolarRadiation = adjustedSolarRadiation.monthlySolarRadiation;

  const originalValues = {
    jan: (originalSolarRadiation.jan * 31) / 1000,
    feb: (originalSolarRadiation.feb * 28) / 1000,
    mar: (originalSolarRadiation.mar * 31) / 1000,
    apr: (originalSolarRadiation.apr * 30) / 1000,
    may: (originalSolarRadiation.may * 31) / 1000,
    jun: (originalSolarRadiation.jun * 30) / 1000,
    jul: (originalSolarRadiation.jul * 31) / 1000,
    aug: (originalSolarRadiation.aug * 31) / 1000,
    sep: (originalSolarRadiation.sep * 30) / 1000,
    oct: (originalSolarRadiation.oct * 31) / 1000,
    nov: (originalSolarRadiation.nov * 30) / 1000,
    dec: (originalSolarRadiation.dec * 31) / 1000,
  };

  const reversedMonthlySolarRadiation = {
    monthlySolarRadiation: originalValues,
    irradiationType: adjustedSolarRadiation.irradiationType,
  };

  // Ensure the reversed values adhere to the MonthlySolarRadiation schema
  return MonthlySolarRadiationDTOSchema.parse(reversedMonthlySolarRadiation);
}
