import type { ISolarzTypographyProps } from "./interface";

import styled from "@emotion/styled";
import { Typography } from "antd";

function getFontProps(hierarchy: ISolarzTypographyProps["hierarchy"]) {
  switch (hierarchy) {
    case "h1": {
      return {
        fontSize: 64,
        lineHeight: 16 * (108 / 100),
      };
    }
    case "h2": {
      return {
        fontSize: 48,
        lineHeight: 16 * (108 / 100),
      };
    }
    case "h3": {
      return {
        fontSize: 40,
        lineHeight: 16 * (108 / 100),
      };
    }
    case "h4": {
      return {
        fontSize: 32,
        lineHeight: 16 * (116 / 100),
      };
    }
    case "title1": {
      return {
        fontSize: 24,
        lineHeight: 16 * (124 / 100),
      };
    }
    case "title2": {
      return {
        fontSize: 20,
        lineHeight: 16 * (124 / 100),
      };
    }
    case "paragraph1": {
      return {
        fontSize: 16,
        lineHeight: 16 * (132 / 100),
      };
    }
    case "paragraph2": {
      return {
        fontSize: 14,
        lineHeight: 16 * (132 / 100),
      };
    }
    case "small": {
      return {
        fontSize: 12,
        lineHeight: 16 * (132 / 100),
      };
    }
    case "tiny": {
      return {
        fontSize: 10,
        lineHeight: 16 * (102 / 100),
      };
    }
    default: {
      return {
        fontSize: 16,
        lineHeight: 16 * (132 / 100),
      };
    }
  }
}

function getFontWeight(fontWeight: ISolarzTypographyProps["fontWeight"]) {
  switch (fontWeight) {
    case "bold": {
      return 700;
    }
    case "semiBold": {
      return 600;
    }
    case "medium": {
      return 500;
    }
    case "regular": {
      return 400;
    }
    default: {
      return 400;
    }
  }
}

function getColor(
  variant: ISolarzTypographyProps["variant"],
  theme: "default" | "dark",
) {
  switch (variant) {
    case "danger": {
      return "var(--red-600)";
    }
    case "default": {
      return theme === "dark" ? "var(--gray-300)" : "var(--gray-500)";
    }
    case "disabled": {
      return theme === "dark" ? "var(--gray-500)" : "var(--gray-400)";
    }
    case "success": {
      return "var(--green-500)";
    }
    case "warning": {
      return "var(--gold-600)";
    }
    case "subTitle": {
      return theme === "dark" ? "var(--gray-400)" : "var(--gray-600)";
    }
    case "title": {
      return theme === "dark" ? "var(--gray-300)" : "var(--gray-700)";
    }
    case "link": {
      return "var(--primary-500)";
    }
    default: {
      return theme === "dark" ? "var(--gray-500)" : "var(--gray-500)";
    }
  }
}

export const StyledTypography = styled(Typography)<ISolarzTypographyProps>`
  font-size: ${(props) => `${getFontProps(props.hierarchy).fontSize}px`};
  line-height: ${(props) => `${getFontProps(props.hierarchy).lineHeight}px`};
  font-weight: ${(props) => `${getFontWeight(props.fontWeight)}`};
  color: ${(props) => `${getColor(props.variant, "default")}`};
  margin: 0;
  padding: 0;

  &.ellipse {
    text-overflow: ellipsis;
    white-space: nowrap !important;
    overflow: hidden !important;
  }

  html.dark & {
    color: ${(props) => `${getColor(props.variant, "dark")}`} !important;
  }
`;
