import type { IProposalCustomFieldFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function ProposalCustomFieldFormModal({
  isOpen = false,
  onClose = () => null,
  width = 700,
  formProps = {
    initialValues: undefined,
  },
}: IProposalCustomFieldFormModalProps) {
  return (
    <Modal
      data-cy="proposal-custom-field-form-modal"
      open={isOpen}
      onCancel={onClose}
      title="Campo customizado da proposta"
      width={width}
      style={{ maxWidth: "90vw" }}
      footer={null}
      destroyOnClose
    >
      <Form.ProposalCustomField {...formProps} onCancel={onClose} />
    </Modal>
  );
}
