import { BiErrorCircle } from "react-icons/bi";
import { CiSearch } from "react-icons/ci";

import type { IAutoCompleteSuffixIcon } from "./interface";

import { Spin } from "antd";

export function AutoCompleteSuffixIcon({
  hasError,
  isLoading,
}: IAutoCompleteSuffixIcon) {
  if (isLoading) {
    return <Spin size="small" spinning />;
  }

  if (hasError) {
    return <BiErrorCircle size={18} />;
  }

  return <CiSearch size={18} />;
}
