import { useMemo, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import {
  BsCashCoin,
  BsChatSquareText,
  BsCoin,
  BsColumnsGap,
  BsFileEarmarkFont,
  BsFillPeopleFill,
  BsGeoAlt,
  BsHddStack,
  BsListCheck,
  BsToggles2,
} from "react-icons/bs";
import { FaRegBuilding, FaRegNewspaper } from "react-icons/fa";
import { FiBox } from "react-icons/fi";
import { GoLog } from "react-icons/go";
import { GrValidate } from "react-icons/gr";
import { HiOutlineCog } from "react-icons/hi";
import { HiOutlineSquaresPlus } from "react-icons/hi2";
import { IoChevronForwardSharp, IoSadOutline } from "react-icons/io5";
import { LuCalculator, LuCalendarSearch } from "react-icons/lu";
import { PiUsersThree } from "react-icons/pi";
import { RiCalendarCheckLine, RiMoneyDollarCircleLine } from "react-icons/ri";

import NextLink from "next/link";
import { useRouter } from "next/router";

import type {
  ActiveDrawerStateType,
  AppMenuItemType,
  IAppMenuProps,
} from "./interface";
import { StyledDrawer, StyledMenu } from "./styles";

import { ENV } from "~configs/Env";
import { Insights, Pipeline } from "~estrela-ui/icons";
import { useAppConfig } from "~hooks/useAppConfig";
import { useSession } from "~hooks/useSession";
import { Button, Flex, Menu, Skeleton } from "antd";
import type { MenuProps } from "antd/lib";

export function AppMenu(_props: IAppMenuProps) {
  const { sessionStatus, user, userHavePermission } = useSession();
  const { screens } = useAppConfig();
  const router = useRouter();

  const [isDrawerOpen, setIsDrawerOpen] = useState<ActiveDrawerStateType>(null);

  function handleOpenDrawer(drawer: Required<ActiveDrawerStateType>) {
    setIsDrawerOpen(drawer);
  }

  function handleCloseDrawer() {
    setIsDrawerOpen(null);
  }

  const routes = router?.asPath
    .split("?")[0]
    .split("/")
    .filter((route) => {
      return route;
    })
    .map((route) => `/${route}`);

  const SALES_OPTIONS = useMemo(() => {
    return [
      {
        url: "/vendas/propostas",
        icon: <BsHddStack />,
        name: "Propostas",
      },
      {
        url: "/vendas/funil",
        icon: <Pipeline className="stroke-0" />,
        name: "Funil",
        hide: !!user?.pipedriveIntegration,
      },
    ].filter((menu) => !menu.hide);
  }, [user?.pipedriveIntegration]);

  const PROPOSAL_CONFIG_OPTIONS = useMemo(() => {
    return [
      {
        url: "/configuracoes-de-propostas/campos-da-proposta",
        icon: <BsToggles2 />,
        name: "Campos da proposta",
        hide: ENV.AMBIENT !== "DEVELOPMENT",
      },
      {
        url: "/configuracoes-de-propostas/customizar-documento",
        icon: <BsFileEarmarkFont />,
        name: "Customizar documento",
      },
      {
        url: "/configuracoes-de-propostas/custos-complementares",
        icon: <RiMoneyDollarCircleLine />,
        name: "Custos complementares",
        hide: ENV.AMBIENT !== "DEVELOPMENT",
      },
      {
        url: "/configuracoes-de-propostas/integracao",
        icon: <HiOutlineSquaresPlus />,
        name: "Integração",
      },
      {
        url: "/configuracoes-de-propostas/mensagem-compartilhada",
        icon: <BsChatSquareText />,
        name: "Mensagem compartilhada",
      },
      {
        url: "/configuracoes-de-propostas/meus-produtos",
        icon: <FiBox />,
        name: "Meus produtos",
      },
      {
        url: "/configuracoes-de-propostas/precificacoes",
        icon: <BsCashCoin />,
        name: "Precificações",
      },

      {
        url: "/configuracoes-de-propostas/predefinicoes",
        icon: <BsListCheck />,
        name: "Predefinições",
      },
      {
        url: "/configuracoes-de-propostas/regras-de-expiracao",
        icon: <RiCalendarCheckLine />,
        name: "Regras de expiração",
      },
      {
        url: "/configuracoes-de-propostas/regras-de-validacao",
        icon: <GrValidate />,
        name: "Regras de validação",
      },
      {
        url: "/configuracoes-de-propostas/simulacao-de-pagamentos",
        icon: <LuCalculator />,
        name: "Simulação de pagamentos",
      },
      {
        url: "/configuracoes-de-propostas/financiamento",
        icon: <BsCoin />,
        name: "Financiamento",
        hide: true,
      },
    ].filter((menu) => !menu.hide);
  }, []);

  const GENERAL_CONFIG_OPTIONS = useMemo(() => {
    return [
      {
        url: "/configuracoes-gerais/campos-de-dados",
        icon: <BsHddStack />,
        name: "Campos de dados",
      },
      {
        url: "/configuracoes-gerais/empresa",
        icon: <FaRegBuilding />,
        name: "Empresa",
      },
      {
        url: "/configuracoes-gerais/endereco-base",
        icon: <BsGeoAlt />,
        name: "Endereço base",
      },
      {
        url: "/configuracoes-gerais/gerenciamento-de-usuarios",
        icon: <BsFillPeopleFill />,
        name: "Gerenciamento de usuarios",
      },
      {
        url: "/configuracoes-gerais/logs",
        icon: <GoLog />,
        name: "Logs",
      },
      {
        url: "/configuracoes-gerais/motivos-de-perda",
        icon: <IoSadOutline strokeWidth={2} />,
        name: "Motivos de perda",
        hide: !!user?.pipedriveIntegration,
      },
      {
        url: "/configuracoes-gerais/unidades-de-negocio",
        icon: <BsColumnsGap />,
        name: "Unidades de negócio",
      },
    ].filter((menu) => !menu.hide);
  }, [user?.pipedriveIntegration]);

  if (sessionStatus !== "authenticated") {
    return (
      <Flex
        vertical={screens.mobile ? false : true}
        gap={24}
        align="center"
        justify={screens.mobile ? "space-between" : "flex-start"}
        style={{ padding: "1rem" }}
      >
        <Skeleton.Avatar active shape="square" />
        <Skeleton.Avatar active shape="square" />
        <Skeleton.Avatar active shape="square" />
        <Skeleton.Avatar active shape="square" />
        <Skeleton.Avatar active shape="square" />
      </Flex>
    );
  }

  return (
    <>
      <StyledMenu
        mobile={screens.mobile}
        mode={screens.mobile ? "horizontal" : "vertical"}
        style={{ height: "100%", borderRight: 0 }}
        selectedKeys={routes.length > 0 ? routes : ["/"]}
        items={
          (
            [
              {
                key: "/propostas",
                icon: (
                  <NextLink
                    href="/propostas"
                    onClick={() => {
                      if (screens.mobile) {
                        handleCloseDrawer();
                      }
                    }}
                  >
                    <BsHddStack size={24} />
                  </NextLink>
                ),
                label: screens.mobile ? null : "Propostas",
                onClick: handleCloseDrawer,
              },
              {
                key: "/funil",
                icon: (
                  <NextLink
                    href="/funil"
                    onClick={() => {
                      if (screens.mobile) {
                        handleCloseDrawer();
                      }
                    }}
                  >
                    <Pipeline className="h-6 w-6 stroke-0" />
                  </NextLink>
                ),
                label: screens.mobile ? null : "Funil",
                onClick: handleCloseDrawer,
                hide: !!user?.pipedriveIntegration,
              },
              {
                key: "/clientes",
                icon: (
                  <NextLink
                    href="/clientes"
                    onClick={() => {
                      if (screens.mobile) {
                        handleCloseDrawer();
                      }
                    }}
                  >
                    <PiUsersThree size={24} />
                  </NextLink>
                ),
                label: screens.mobile ? null : "Clientes",
                onClick: handleCloseDrawer,
              },
              {
                key: "/atividades",
                icon: (
                  <NextLink
                    href="/atividades"
                    onClick={() => {
                      if (screens.mobile) {
                        handleCloseDrawer();
                      }
                    }}
                  >
                    <LuCalendarSearch size={24} />
                  </NextLink>
                ),
                label: screens.mobile ? null : "Atividades",
                onClick: handleCloseDrawer,
                hide: !!user?.pipedriveIntegration,
              },
              {
                key: "/insights",
                icon: (
                  <NextLink
                    href="/insights"
                    onClick={() => {
                      if (screens.mobile) {
                        handleCloseDrawer();
                      }
                    }}
                  >
                    <Insights className="h-6 w-6" />
                  </NextLink>
                ),
                label: screens.mobile ? null : "Insights",
                onClick: handleCloseDrawer,
                hide:
                  !!user?.pipedriveIntegration ||
                  !user?.flags?.includes("demoAccount"),
              },
              {
                key: "/configuracoes-de-propostas",
                icon: (
                  <span tabIndex={0}>
                    <FaRegNewspaper size={24} />
                  </span>
                ),
                label: screens.mobile ? null : "Configurações de propostas",
                onClick: () => handleOpenDrawer("PROPOSAL"),
                className: isDrawerOpen === "PROPOSAL" ? "is-selected" : null,
                hide: !userHavePermission("ACCESS_ADMINISTRATIVE_AREA"),
              },
              {
                key: "/configuracoes-gerais",
                icon: (
                  <span tabIndex={0}>
                    <HiOutlineCog size={24} />
                  </span>
                ),

                label: screens.mobile ? null : "Configurações gerais",
                onClick: () => handleOpenDrawer("GENERAL"),
                hide: !userHavePermission("ACCESS_ADMINISTRATIVE_AREA"),
                className: isDrawerOpen === "GENERAL" ? "is-selected" : null,
              },
              {
                key: "/mensagem-compartilhada",
                icon: (
                  <NextLink href="/configuracoes-de-propostas/mensagem-compartilhada">
                    <BsChatSquareText size={24} />
                  </NextLink>
                ),

                label: screens.mobile ? null : "Mensagem compartilhada",
                hide: userHavePermission("ACCESS_ADMINISTRATIVE_AREA"),
              },
            ] as AppMenuItemType[]
          ).filter((option) => !option.hide) as MenuProps["items"]
        }
      />

      {/* SALES */}
      <StyledDrawer
        mobile={screens.mobile}
        open={isDrawerOpen === "SALES"}
        onClose={handleCloseDrawer}
        placement={screens.mobile ? "right" : "left"}
        zIndex={999}
        style={{
          margin: screens.mobile ? "4rem 0 5rem 0" : "5rem 0 0 5rem", // 5rem = 80px
          borderRight: "1px solid var(--gray-300)",
        }}
        width="auto"
        styles={{
          mask: {
            background: "none",
          },
          wrapper: {
            height: screens.mobile
              ? "calc(100vh - 144px)"
              : "calc(100vh - 80px)",
          },
        }}
        title={
          <Flex align="center" justify="space-between">
            <strong>Vendas</strong>
            <Button
              type="text"
              icon={<AiOutlineClose size={16} />}
              onClick={handleCloseDrawer}
              style={{ lineHeight: 0 }}
              size="small"
            />
          </Flex>
        }
        closeIcon={false}
      >
        <Menu
          selectedKeys={routes.length > 0 ? [routes.join("")] : ["/"]}
          style={{ height: "100%", borderRight: 0 }}
          items={
            SALES_OPTIONS.map((option) => ({
              key: option.url,
              icon: option.icon,
              label: (
                <NextLink
                  key={option.url}
                  href={option.url}
                  onClick={() => {
                    if (!screens.mobile) return;
                    handleCloseDrawer();
                  }}
                >
                  <Flex align="center" justify="space-between">
                    {option.name}
                    {screens.mobile && <IoChevronForwardSharp size={1} />}
                  </Flex>
                </NextLink>
              ),
              className: isDrawerOpen === "PROPOSAL" ? "is-selected" : null,
            })) as AppMenuItemType[]
          }
        />
      </StyledDrawer>

      {/* CONFIG PROPOSAL */}
      <StyledDrawer
        mobile={screens.mobile}
        open={isDrawerOpen === "PROPOSAL"}
        onClose={handleCloseDrawer}
        placement={screens.mobile ? "right" : "left"}
        zIndex={999}
        style={{
          margin: screens.mobile ? "4rem 0 5rem 0" : "5rem 0 0 5rem", // 5rem = 80px
          borderRight: "1px solid var(--gray-300)",
        }}
        width="auto"
        styles={{
          mask: {
            background: "none",
          },
          wrapper: {
            height: screens.mobile
              ? "calc(100vh - 144px)"
              : "calc(100vh - 80px)",
          },
        }}
        title={
          <Flex align="center" justify="space-between">
            <strong>Configurações da proposta</strong>
            <Button
              type="text"
              icon={<AiOutlineClose size={16} />}
              onClick={handleCloseDrawer}
              style={{ lineHeight: 0 }}
              size="small"
            />
          </Flex>
        }
        closeIcon={false}
      >
        <Menu
          selectedKeys={routes.length > 0 ? [routes.join("")] : ["/"]}
          style={{ height: "100%", borderRight: 0 }}
          items={
            PROPOSAL_CONFIG_OPTIONS.map((option) => ({
              key: option.url,
              icon: option.icon,
              label: (
                <NextLink
                  key={option.url}
                  href={option.url}
                  onClick={() => {
                    if (!screens.mobile) return;
                    handleCloseDrawer();
                  }}
                >
                  <Flex align="center" justify="space-between">
                    {option.name}
                    {screens.mobile && <IoChevronForwardSharp size={1} />}
                  </Flex>
                </NextLink>
              ),
              className: isDrawerOpen === "PROPOSAL" ? "is-selected" : null,
            })) as AppMenuItemType[]
          }
        />
      </StyledDrawer>

      {/* CONFIG GENERAL */}
      <StyledDrawer
        mobile={screens.mobile}
        open={isDrawerOpen === "GENERAL"}
        onClose={handleCloseDrawer}
        placement={screens.mobile ? "right" : "left"}
        zIndex={999}
        style={{
          margin: screens.mobile ? "4rem 0 5rem 0" : "5rem 0 0 5rem", // 5rem = 80px
          borderRight: "1px solid var(--gray-300)",
        }}
        width="auto"
        styles={{
          mask: {
            background: "none",
          },
          header: {
            border: 0,
            paddingBottom: 0,
          },
          wrapper: {
            height: screens.mobile
              ? "calc(100vh - 144px)"
              : "calc(100vh - 80px)",
          },
        }}
        title={
          <Flex align="center" justify="space-between">
            <strong>Configurações gerais</strong>
            <Button
              type="text"
              icon={<AiOutlineClose size={16} />}
              onClick={handleCloseDrawer}
              style={{ lineHeight: 0 }}
              size="small"
            />
          </Flex>
        }
        closeIcon={false}
      >
        <Menu
          selectedKeys={routes.length > 0 ? [routes.join("")] : ["/"]}
          style={{ height: "100%", borderRight: 0 }}
          items={
            GENERAL_CONFIG_OPTIONS.map((option) => ({
              key: option.url,
              icon: option.icon,
              label: (
                <NextLink
                  key={option.url}
                  href={option.url}
                  onClick={() => {
                    if (screens.mobile) {
                      handleCloseDrawer();
                    }
                  }}
                >
                  <Flex align="center" justify="space-between">
                    {option.name}
                    {screens.mobile && <IoChevronForwardSharp size={1} />}
                  </Flex>
                </NextLink>
              ),
            })) as AppMenuItemType[]
          }
        />
      </StyledDrawer>
    </>
  );
}
