import { useCallback, useEffect, useRef, useState } from "react";
import { EditorProps, RawDraftContentState } from "react-draft-wysiwyg";
import { BiMessageAltDetail } from "react-icons/bi";
import {
  BsBuildings,
  BsClipboard2Check,
  BsPerson,
  BsTelephone,
} from "react-icons/bs";
import {
  FaRegDotCircle,
  FaRegHandshake,
  FaUser,
  FaBuilding,
  FaStickyNote,
} from "react-icons/fa";
import { IoDocumentOutline } from "react-icons/io5";

import DynamicImport from "next/dynamic";

import type { IDealTimelineProps } from "./interface";
import { StyledTimeline } from "./styles";

import { DownloadFile, DownloadImage } from "~components/Others";
import { IDealNoteRichTextEditor } from "~components/RichTextEditor";
import {
  useFetchGetHistoriesTypeFilterByDealId,
  useFetchGetPaginatedHistoriesFilterByDealId,
} from "~hooks/api";
import { SolarzAnchor } from "~solarzui/SolarzAnchor";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { HistoryFilterEnum, HistoryTypeEnum } from "~types/enum";
import { IHistoryDTOSchema } from "~types/schemas";
import { Button, Flex, Skeleton, Spin, Tabs } from "antd";
import dayjs from "dayjs";
import { ArrowRight, Edit } from "estrela-ui/icons";
const Editor = DynamicImport<EditorProps>(
  () => import("react-draft-wysiwyg").then((mod) => mod.Editor),
  { ssr: false },
);
const DealNoteRichTextEditor = DynamicImport<IDealNoteRichTextEditor>(
  () =>
    import("~components/RichTextEditor/DealNoteRichTextEditor").then(
      (mod) => mod.DealNoteRichTextEditor,
    ),
  { ssr: false, loading: () => <Spin spinning /> },
);

const iconMap: Record<HistoryTypeEnum | "DEFAULT", JSX.Element> = {
  GENERAL: <FaRegDotCircle size={12} />,
  CALL: <BsTelephone size={16} />,
  MEETING: <IoDocumentOutline size={18} />,
  TASK: <BiMessageAltDetail size={18} />,
  EMAIL: <BiMessageAltDetail size={18} />,
  PROPOSAL: <FaRegHandshake size={18} />,
  DEAL: <FaRegHandshake size={18} />,
  PERSON: <FaUser size={18} />,
  ORGANIZATION: <FaBuilding size={18} />,
  NOTE: <FaStickyNote size={18} />,
  DEFAULT: <FaRegDotCircle size={12} />,
};

function getIconByTimelineDataType(
  type: HistoryTypeEnum | string,
): JSX.Element {
  return iconMap[type as HistoryTypeEnum] || iconMap.DEFAULT;
}

const entityActionIconMap: Record<HistoryFilterEnum | "ALL", string> = {
  ALL: "Todos",
  NOTE: "Notas",
  ACTIVITY: "Atividades",
  CHANGE_LOG: "Registros",
};

function translateTimelineDataType(type: HistoryFilterEnum | string): string {
  return (
    entityActionIconMap[type as HistoryFilterEnum] || entityActionIconMap.ALL
  );
}

const textEntityMap: Record<HistoryTypeEnum | "DEFAULT", string> = {
  GENERAL: "o item",
  CALL: "a ligação",
  MEETING: "a reunião",
  TASK: "a tarefa",
  EMAIL: "o email",
  PROPOSAL: "a proposta",
  DEAL: "o negócio",
  PERSON: "a pessoa",
  ORGANIZATION: "a organização",
  NOTE: "a nota",
  DEFAULT: "o item",
};

function getEntityByTimelineDataType(type: HistoryTypeEnum | string): string {
  return textEntityMap[type as HistoryTypeEnum] || textEntityMap.DEFAULT;
}

const textPropertyMap: Record<string | "DEFAULT", string> = {
  name: "Nome",
  phone: "Telefone",
  email: "E-mail",
  address: "Endereço",
  city: "Cidade",
  state: "Estado",
  zipCode: "CEP",
  cpf: "CPF",
  cnpj: "CNPJ",
  description: "Descrição",
  owner: "Responsável",
  status: "Status",
  pipelineStage: "Estágio",
  dealReasomForLoss: "Motivo da perda",
  value: "Valor",
};

function getPropertyByTimelineDataType(type: string): string | undefined {
  return textPropertyMap[type] || textPropertyMap.value;
}

function extractMentions(contentObject: RawDraftContentState): string[] {
  const allMentions: string[] = [];
  for (const entity of Object.values(contentObject.entityMap)) {
    const mention = entity as any;

    if (mention?.type !== "MENTION") continue;

    allMentions.push(mention?.data?.url);
  }
  return allMentions;
}

function updateContentObjectWithMentions(
  contentObject: RawDraftContentState,
  mentions: string[],
): RawDraftContentState {
  const updatedContent = { ...contentObject };

  let mentionIndex = 0;
  for (const key in updatedContent.entityMap) {
    const entity = updatedContent.entityMap[key] as any;

    if (entity.type === "MENTION" && entity.data?.url) {
      entity.data.url = mentions[mentionIndex];
      mentionIndex++;
    }
  }

  return updatedContent;
}

const contentStateFormatter = (content: string) => {
  const contentObject = JSON.parse(content) as RawDraftContentState;
  const extractedMentions = extractMentions(contentObject);

  // Modificar as URLs das menções
  const modifiedMentions = extractedMentions.map(
    (url) =>
      `/configuracoes-gerais/gerenciamento-de-usuarios?tab=usuarios&id=${url}`,
  );

  // Atualizar o objeto contentObject com as menções modificadas
  const modifiedContentObject = updateContentObjectWithMentions(
    contentObject,
    modifiedMentions,
  );

  return modifiedContentObject;
};

export function DealTimeline({ dealId, ...props }: IDealTimelineProps) {
  const [activeTab, setActiveTab] = useState<HistoryFilterEnum>("ALL");
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [timelineItems, setTimelineItems] = useState<
    (IHistoryDTOSchema & {
      isEditable: boolean;
      updates: {
        userName?: string;
        createdAt: string;
      }[];
    })[]
  >([]);
  const [hasMore, setHasMore] = useState(true);
  const loader = useRef(null);

  const resetTimeline = useCallback(() => {
    setPage(0);
    setLastPage(0);
    setTimelineItems([]);
  }, []);

  const toggleEdit = useCallback((id: number) => {
    setTimelineItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, isEditable: !item.isEditable } : item,
      ),
    );
  }, []);

  const { isFetching: isLoadingTimeline, refetch: refetchTimeline } =
    useFetchGetPaginatedHistoriesFilterByDealId({
      payload: {
        dealId,
        searchQuery: activeTab,
        page,
        size: 10,
      },
      dependencyArray: [dealId, activeTab, page],
      options: {
        enabled: dealId > 0,
        onSuccess: (data) => {
          if (data) {
            setTimelineItems((prevItems) => {
              // Create a map to store the latest version of each entity, using entityId as the key if it is a note
              const itemsMap = new Map<
                number,
                IHistoryDTOSchema & {
                  isEditable: boolean;
                  updates: {
                    userName?: string;
                    createdAt: string;
                  }[];
                }
              >();

              // Iterate over the previous items and add them to the map
              // This preserves the original position in the timeline
              prevItems.forEach((item) => {
                if (
                  item.log.entityType === "NOTE" ||
                  item.log.entityType === "Note"
                ) {
                  itemsMap.set(item.log.entityId, item);
                } else {
                  itemsMap.set(item.id, item);
                }
              });
              // Process the new items received and update them in the map
              data.content.forEach((item) => {
                const id =
                  item.log.entityType === "NOTE" ||
                  item.log.entityType === "Note"
                    ? item.log.entityId
                    : item.id;
                const existingItem = itemsMap.get(id);

                if (!existingItem) {
                  // If the item does not exist in the map (new item), add it directly
                  itemsMap.set(id, {
                    ...item,
                    isEditable: false,
                    updates: [],
                  });
                } else {
                  // If the item already exists, update its content
                  itemsMap.set(id, {
                    ...existingItem,
                    // Keep the earliest creation date between the new and the old item
                    createdAt: dayjs(item.createdAt).isBefore(
                      dayjs(existingItem.createdAt),
                    )
                      ? item.createdAt
                      : existingItem.createdAt,
                    // Accumulate the updates
                    updates: [
                      ...existingItem.updates,
                      {
                        userName: item.userAccount?.name,
                        createdAt: item.createdAt,
                      },
                    ],
                  });
                }
              });
              // Convert the map into a list and sort it by the original creation date
              const updatedItems = Array.from(itemsMap.values()).sort((a, b) =>
                dayjs(b.createdAt).diff(dayjs(a.createdAt)),
              );
              // Return the updated items to be displayed in the timeline
              return updatedItems;
            });
            // Check if there are more pages to load
            setHasMore(page < data.totalPages - 1);
          }
        },
        onError: () => {
          resetTimeline();
        },
        onSettled: (data, _error) => {
          if (data) {
            if (lastPage === page && page !== 0) {
              resetTimeline();
            }
            setLastPage(page);
          }
        },
      },
    });

  const { data: filters } = useFetchGetHistoriesTypeFilterByDealId({
    payload: {
      dealId,
    },
    dependencyArray: [dealId],
    options: {
      enabled: dealId > 0,
    },
  });

  const handleTabChange = (key: HistoryFilterEnum) => {
    setHasMore(true);
    setTimelineItems([]);
    setPage(0);
    setActiveTab(key);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !isLoadingTimeline) {
          setPage((prevPage) => prevPage + 1);
        }
      },
      {
        threshold: 1.0,
      },
    );

    const loaderCurrent = loader.current;

    if (loaderCurrent) {
      observer.observe(loaderCurrent);
    }

    return () => {
      if (loaderCurrent) {
        observer.unobserve(loaderCurrent);
      }
    };
  }, [hasMore, isLoadingTimeline, timelineItems.length]);

  useEffect(() => {
    if (dealId) {
      resetTimeline();
      refetchTimeline();
    }
  }, [dealId, resetTimeline, refetchTimeline]);

  return (
    <>
      <Tabs
        activeKey={activeTab}
        onChange={(key) => handleTabChange(key as HistoryFilterEnum)}
        items={filters?.map((filter) => ({
          label: `${translateTimelineDataType(filter.type)} (${filter.quantity || 0})`,
          key: filter.type,
        }))}
      />
      <StyledTimeline
        {...props}
        items={timelineItems
          .sort((a, b) => dayjs(b.createdAt).diff(dayjs(a.createdAt)))
          .map((data, index) => {
            const icon = getIconByTimelineDataType(data.log.historyType);
            const textEntity = getEntityByTimelineDataType(
              data.log.historyType,
            );

            return {
              children:
                data.log.historyType === "NOTE" ? (
                  <div
                    key={`${data.id}-${data.log.entityId}`}
                    className={`flex flex-col p-3 bg-gray-100 dark:bg-background-custom rounded border border-border pb-${data.log.attachedFiles.length > 0 ? "0" : "4"}`}
                  >
                    <div className="flex justify-between">
                      <SolarzTypography
                        hierarchy="small"
                        fontWeight="regular"
                        variant="disabled"
                      >
                        <div className="flex flex-row justify-start items-center gap-4 max-tablet:flex-col max-tablet:items-start max-tablet:gap-0">
                          <div className="flex justify-center items-center gap-1">
                            {data.updates.length > 0
                              ? `${dayjs(data.createdAt)
                                  .add(3, "hours")
                                  .format(
                                    `DD [de] MMMM [de] YYYY HH:mm`,
                                  )} ${data.updates
                                  .sort((a, b) =>
                                    dayjs(b.createdAt).diff(dayjs(a.createdAt)),
                                  )
                                  .map((update) => `• ${update.userName}`)
                                  .join(" ")} • ${data.userAccount?.name}`
                              : `${dayjs(data.createdAt).add(3, "hours").format(`DD [de] MMMM [de] YYYY HH:mm`)} • ${data.userAccount?.name}`}
                          </div>
                          {data.person?.name && (
                            <div className="flex justify-center items-center gap-1">
                              <BsPerson />
                              {data.person?.name}
                            </div>
                          )}
                          {data.organization?.name && (
                            <div className="flex justify-center items-center gap-1">
                              <BsBuildings />
                              {data.organization?.name}
                            </div>
                          )}
                          {data.deal?.name && (
                            <div className="flex justify-center items-center gap-1">
                              <BsClipboard2Check />
                              {data.deal?.name}
                            </div>
                          )}
                        </div>
                      </SolarzTypography>
                      <div className="flex justify-center items-center w-5 h-5">
                        <Button
                          type="link"
                          htmlType="button"
                          onClick={() => toggleEdit(data.id)}
                          style={{
                            padding: 0,
                            height: "100%",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Edit className="w-3.75 h-3.75 text-primary" />
                        </Button>
                      </div>
                    </div>
                    {data.log.title.startsWith('{"blocks"') ? (
                      !data.isEditable ? (
                        <Editor
                          key={`${data.id}-${data.log.entityId}-${index}`}
                          editorStyle={{
                            width: "100%",
                            height: "100%",
                            padding: 0,
                            margin: 0,
                            fontSize: "14px",
                          }}
                          readOnly
                          toolbarHidden
                          contentState={contentStateFormatter(data.log.title)}
                          mention={{
                            separator: " ",
                            trigger: "@",
                            suggestions: [],
                          }}
                        />
                      ) : (
                        <DealNoteRichTextEditor
                          key={`${data.id}-${data.log.entityId}-${index}`}
                          noteId={data.log.entityId}
                          dealId={dealId}
                          content={data.log.title}
                          toolbarHidden={!data.isEditable}
                          readOnly={!data.isEditable}
                          minHeight={50}
                          style={{
                            width: "100%",
                            height: "100%",
                            padding: 0,
                            margin: 0,
                            fontSize: "14px",
                          }}
                          onSuccess={() => {
                            resetTimeline();
                            refetchTimeline();
                          }}
                          onCancel={() => toggleEdit(data.id)}
                        />
                      )
                    ) : (
                      <SolarzTypography
                        hierarchy="paragraph2"
                        fontWeight="regular"
                        variant="subTitle"
                        style={{ marginBottom: 12 }}
                      >
                        {data.log.title}
                      </SolarzTypography>
                    )}
                    {data.log.attachedFiles?.length > 0 && (
                      <div className="flex flex-wrap justify-start w-full gap-4">
                        {data.log.attachedFiles?.map((attachment, index) => {
                          if (attachment.fileType?.startsWith("image")) {
                            return (
                              <div
                                key={`${data.id}-${data.log.entityId}-${attachment.fileId}-${index}`}
                                className="w-full tablet:w-1/3 desktop:w-1/5"
                              >
                                <DownloadImage
                                  fileId={attachment.fileId}
                                  entityId={data.log.entityId}
                                  filename={attachment.fileName ?? ""}
                                  fileType={attachment.fileType}
                                />
                              </div>
                            );
                          }
                          return (
                            <div
                              key={`${data.id}-${data.log.entityId}-${attachment.fileId}-${index}`}
                              className="w-full tablet:w-1/3 desktop:w-1/5"
                            >
                              <DownloadFile
                                fileId={attachment.fileId}
                                entityId={data.log.entityId}
                                title={attachment.fileName?.split("_")[1]}
                                filename={attachment.fileName ?? ""}
                                fileType={attachment.fileType ?? ""}
                                fileSize={attachment.fileSize ?? 1024}
                                requestDownload={isDownloading}
                                setRequestDownload={setIsDownloading}
                              />
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    className="flex flex-col justify-start items-start gap-4"
                    key={`${data.id}-${data.log.entityId}`}
                  >
                    <div className="flex flex-col gap-2">
                      <SolarzTypography
                        hierarchy="paragraph2"
                        fontWeight="regular"
                        variant="subTitle"
                      >
                        {data.log.title}
                      </SolarzTypography>
                      {data.log.entityAction === "UPDATE" &&
                        data.log.propertyDiffs.length > 0 && (
                          <div className="flex flex-col gap-2">
                            <SolarzTypography
                              hierarchy="paragraph2"
                              fontWeight="regular"
                              variant="subTitle"
                            >
                              <SolarzAnchor
                                href={`/configuracoes-gerais/gerenciamento-de-usuarios?tab=usuarios&id=${data.userAccount?.id}`}
                                style={{ textDecoration: "none" }}
                                isNextLink
                              >
                                {data.userAccount?.name}
                              </SolarzAnchor>{" "}
                              alterou {textEntity}:
                            </SolarzTypography>
                            {data.log.propertyDiffs.map((diff, index) => (
                              <div
                                key={`${data.id}-${diff.propertyName}-${index}`}
                                className="flex gap-1"
                              >
                                <SolarzTypography
                                  hierarchy="paragraph2"
                                  fontWeight="regular"
                                  variant="subTitle"
                                >
                                  {getPropertyByTimelineDataType(
                                    diff.propertyName,
                                  ) || diff.propertyName}
                                  :
                                </SolarzTypography>
                                <SolarzTypography
                                  hierarchy="paragraph2"
                                  fontWeight="bold"
                                  variant="subTitle"
                                >
                                  {diff.oldValue}
                                </SolarzTypography>
                                <ArrowRight
                                  style={{
                                    width: 14,
                                    height: 14,
                                    marginTop: "4px",
                                  }}
                                />
                                <SolarzTypography
                                  hierarchy="paragraph2"
                                  fontWeight="bold"
                                  variant="subTitle"
                                >
                                  {diff.newValue}
                                </SolarzTypography>
                              </div>
                            ))}
                          </div>
                        )}
                    </div>
                    <div>
                      <SolarzTypography
                        hierarchy="small"
                        fontWeight="regular"
                        variant="disabled"
                      >
                        <div className="flex flex-row justify-start items-center gap-4 max-tablet:flex-col max-tablet:items-start max-tablet:gap-0">
                          <div className="flex justify-center items-center gap-1">
                            {dayjs(data.createdAt)
                              .add(3, "hours")
                              .format(`DD [de] MMMM [de] YYYY HH:mm`)}
                            {data.userAccount?.name &&
                              ` • ${data.userAccount.name}`}
                          </div>
                          {data.person?.name && (
                            <div className="flex justify-center items-center gap-1">
                              <BsPerson />
                              {data.person?.name}
                            </div>
                          )}
                          {data.organization?.name && (
                            <div className="flex justify-center items-center gap-1">
                              <BsBuildings />[{data.organization?.id}]{" "}
                              {data.organization?.name}
                            </div>
                          )}
                          {data.deal?.name && (
                            <div className="flex justify-center items-center gap-1">
                              <BsClipboard2Check />[{data.deal?.id}]{" "}
                              {data.deal?.name}
                            </div>
                          )}
                        </div>
                      </SolarzTypography>
                    </div>
                  </div>
                ),
              dot: icon,
              key: `${data.id}-${data.log.entityId}`,
            };
          })}
      />
      {isLoadingTimeline && (
        <Flex vertical gap={12} style={{ width: "100%", paddingLeft: 26 }}>
          {new Array(5).fill(null).map((_, index) => {
            return (
              <Skeleton.Input
                active
                key={`Skeleton_${index}`}
                style={{ height: 120, width: "100%" }}
              />
            );
          })}
        </Flex>
      )}
      <div ref={loader} />
    </>
  );
}
