import type { IAdminCompanyConfigurationFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function AdminCompanyConfigurationFormModal({
  companyId,
  isOpen = false,
  onClose = () => undefined,
  title = "Configurações do usuário",
  maxWidth = 512,
  formProps,
}: IAdminCompanyConfigurationFormModalProps) {
  return (
    <Modal
      data-cy="admin-company-configuration-form-modal"
      width="90vw"
      title={title}
      footer={false}
      destroyOnClose
      open={isOpen}
      onCancel={onClose}
      style={{ maxWidth }}
    >
      <Form.AdminCompanyConfiguration
        {...formProps}
        onCancel={onClose}
        companyId={companyId}
      />
    </Modal>
  );
}
