import { useMemo } from "react";

import {
  IProposalCustomFieldTypeOption,
  IProposalCustomFieldTypeSelectProps,
} from "./interface";

import { Select } from "antd";

export function ProposalCustomFieldTypeSelect({
  value,
  onChange,
  defaultValue,
  options,
  ...props
}: IProposalCustomFieldTypeSelectProps) {
  const OPTIONS: IProposalCustomFieldTypeOption[] = useMemo(
    () =>
      [
        {
          label: "Sim/Não",
          value: "BOOLEAN" as const,
          disabled: options?.disableBoolean,
          hide: options?.hideBoolean,
        },
        {
          label: "Numérico",
          value: "NUMBER" as const,
          disabled: options?.disableNumber,
          hide: options?.hideNumber,
        },
        {
          label: "Texto",
          value: "TEXT" as const,
          disabled: options?.disableText,
          hide: options?.hideText,
        },
        {
          label: "Caixa de texto",
          value: "TEXT_AREA" as const,
          disabled: options?.disableTextArea,
          hide: options?.hideTextArea,
        },
        {
          label: "Selecionável",
          value: "SELECT" as const,
          disabled: options?.disableText,
          hide: options?.hideText,
        },
        {
          label: "Imagem",
          value: "IMAGE" as const,
          disabled: options?.disableImage,
          hide: options?.hideImage,
        },
        {
          label: "Data",
          value: "DATE" as const,
          disabled: options?.disableDate,
          hide: options?.hideDate,
        },
      ].filter((option) => !option.hide),
    [options],
  );

  return (
    <Select
      {...props}
      options={OPTIONS}
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
}
