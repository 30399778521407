import Link from "next/link";
import { useRouter } from "next/router";

import { StyledBreadcrumb } from "./styles";

import { ArrowRight } from "~estrela-ui/icons";
import { useFetchGetPersonById } from "~hooks/api";
import { useSession } from "~hooks/useSession";
import { Skeleton } from "antd";

function getPersonIdFromUrlPath(path: string) {
  try {
    const personIdString = path.split("/clientes/pessoa/")[1].split("?")[0];

    const personId = Number(personIdString);

    return isNaN(personId) ? 0 : personId;
  } catch {
    return 0;
  }
}

// Ex.: expected string /clientes/pessoa/1?...
export function PersonBreadcrumb() {
  const router = useRouter();
  const { sessionStatus } = useSession();

  const personId = getPersonIdFromUrlPath(router.asPath);

  const {
    data: person,
    isFetching: isLoadingPerson,
    error: personError,
  } = useFetchGetPersonById({
    payload: {
      personId,
    },
    dependencyArray: [personId, sessionStatus],
    options: {
      enabled: personId > 0 && sessionStatus === "authenticated",
      retry: 1,
    },
  });

  return (
    <StyledBreadcrumb
      separator={<ArrowRight className="h-3 w-3" />}
      items={[
        { title: <Link href="/clientes">Clientes</Link> },
        { title: <span>Pessoa</span> },
        {
          title:
            isLoadingPerson || personError ? (
              <Skeleton.Node
                active={isLoadingPerson}
                style={{ height: 16, width: 80 }}
              >
                {" "}
              </Skeleton.Node>
            ) : (
              <Link href={`/clientes/pessoa/${personId}`}>
                {person?.name ?? personId}
              </Link>
            ),
          className: "active",
        },
      ]}
    />
  );
}
