import type { ICostAggregatorFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function CostAggregatorFormModalFormModal({
  isOpen = false,
  onClose = () => null,
  title = "Agregador de custos",
  maxWidth = 512,
  formProps = {},
}: ICostAggregatorFormModalProps) {
  return (
    <Modal
      data-cy="cost-aggregator-form-modal"
      open={isOpen}
      onCancel={onClose}
      title={title}
      width="90vw"
      footer={null}
      style={{ maxWidth }}
      destroyOnClose
    >
      <Form.CostAggregator onCancel={onClose} {...formProps} />
    </Modal>
  );
}
