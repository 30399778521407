import type { IBusinessUnitFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function BusinessUnitFormModal({
  isOpen = false,
  onClose = () => null,
  title = "Formulário de unidade de negócio",
  width = 512,
  formProps = {
    initialValues: {
      id: undefined,
      name: undefined,
      addressBaseId: undefined,
    },
  },
}: IBusinessUnitFormModalProps) {
  return (
    <Modal
      data-cy="business-unit-form-modal"
      open={isOpen}
      onCancel={onClose}
      title={title}
      width={width}
      footer={null}
      destroyOnClose
    >
      <Form.BusinessUnit
        {...formProps}
        onCancel={onClose}
        onSuccess={onClose}
        onError={onClose}
      />
    </Modal>
  );
}
