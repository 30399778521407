import { useMemo } from "react";

import type { IEdeltecInverterBrandsSelectProps } from "./interface";

import { SelectSuffixIcon } from "~components/Others";
import { Result } from "~components/Result";
import { useFetchGetEdeltecInverterBrands } from "~hooks/api";
import { Select } from "antd";

export function EdeltecInverterBrandsSelect({
  allowClear = true,
  onChange,
  ...props
}: IEdeltecInverterBrandsSelectProps) {
  const {
    data: InverterBrands = [],
    error: InverterBrandsError,
    isFetching: isLoadingInverterBrands,
    refetch: reloadInverterBrands,
  } = useFetchGetEdeltecInverterBrands({
    dependencyArray: [],
    options: {
      retry: 1,
    },
  });

  const OPTIONS = useMemo(() => {
    return InverterBrands.map((product) => ({
      label: product.description,
      value: product.inverterBrandType,
      record: product,
    }));
  }, [InverterBrands]);

  return (
    <Select
      {...props}
      loading={isLoadingInverterBrands}
      options={OPTIONS}
      allowClear={allowClear}
      status={InverterBrandsError ? "error" : ""}
      suffixIcon={
        <SelectSuffixIcon
          hasError={!!InverterBrandsError}
          isLoading={isLoadingInverterBrands}
        />
      }
      notFoundContent={
        <Result.SelectErrorOrEmpty
          errorMessage={InverterBrandsError?.message}
          emptyMessage="Nenhum módulo da Edeltec foi encontrado"
          loading={isLoadingInverterBrands}
          reloadFn={reloadInverterBrands}
        />
      }
      onChange={(value, option) => {
        if (!onChange) return;
        onChange(value, option);
      }}
    />
  );
}
