import { FC, useState } from "react";
import { AiFillPlusCircle } from "react-icons/ai";

import { FooterModal } from "~components/FooterModal";
import { BusinessUnitTableDataType } from "~components/Table/BusinessUnitTable/interface";
import {
  useFetchAssignBusinessUnitToUser,
  useFetchGetBusinessUnitUsersOut,
} from "~hooks/api";
import {
  Modal,
  Row,
  Col,
  message,
  Typography,
  Button,
  Empty,
  Flex,
} from "antd";

type Props = {
  open: boolean;
  onClose?: () => void;
  idUnit?: number;
  unit?: BusinessUnitTableDataType;
};

interface CompanyUser {
  accountOwner: boolean;
  email: string;
  id: number;
}

export const ModalAddBusinessUnitUsers: FC<Props> = ({
  onClose,
  idUnit,
  open,
}) => {
  const [dataSourceCompanyUsers, setDataSourceCompanyUsers] =
    useState<CompanyUser[]>();

  useFetchGetBusinessUnitUsersOut({
    payload: {
      businessUnitId: idUnit ?? 0,
    },
    options: {
      enabled: !!idUnit && idUnit > 0,
      onSuccess: (data) => {
        setDataSourceCompanyUsers(data);
      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  });

  const { mutate: addBusinessUnitUser } = useFetchAssignBusinessUnitToUser();

  const handleAddBusinessUnitUser = async (
    businessUnitId: number,
    userId: number,
  ) => {
    addBusinessUnitUser({
      businessUnitId,
      userId,
    });
  };

  return (
    <>
      <Modal
        open={open}
        onCancel={onClose}
        footer={null}
        title={`Usuários da companhia`}
        destroyOnClose
      >
        <Row>
          {dataSourceCompanyUsers && dataSourceCompanyUsers?.length !== 0 ? (
            dataSourceCompanyUsers.map((user) => {
              return (
                <Col
                  xs={24}
                  key={user.id}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Row justify="start" style={{ width: "35vh" }}>
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        type="link"
                        onClick={() => {
                          if (!idUnit) {
                            return message.error(
                              "Client error: Faltando o id da unidade",
                            );
                          }
                          handleAddBusinessUnitUser(idUnit, user.id);
                        }}
                      >
                        <AiFillPlusCircle size={20} />
                      </Button>
                    </Col>
                    <Col
                      xs={20}
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <Typography.Text>{user.email}</Typography.Text>
                    </Col>
                  </Row>
                </Col>
              );
            })
          ) : (
            <Flex justify="center" align="center" style={{ width: "100%" }}>
              <Empty />
            </Flex>
          )}
        </Row>
        <FooterModal spaceStyle={{ justifyContent: "center" }}>
          <Flex gap={16}>
            <Button
              type="primary"
              size="small"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                margin: "10px",
              }}
              onClick={onClose}
            >
              Fechar
            </Button>
          </Flex>
        </FooterModal>
      </Modal>
    </>
  );
};
