import { useMemo } from "react";

import { IPhaseSelectProps, IPhaseOption } from "./interface";

import { Select } from "antd";

export function PhaseSelect({
  value,
  onChange,
  defaultValue,
  disableMonofasico = false,
  disableBifasico = false,
  disableTrifasico = false,
  hideMonofasico = false,
  hideBifasico = false,
  hideTrifasico = false,
  ...props
}: IPhaseSelectProps) {
  const OPTIONS: IPhaseOption[] = useMemo(
    () =>
      [
        {
          label: "Monofásico" as const,
          value: "Monofásico" as const,
          disabled: disableMonofasico,
          isHidden: hideMonofasico,
        },
        {
          label: "Bifásico" as const,
          value: "Bifásico" as const,
          disabled: disableBifasico,
          isHidden: hideBifasico,
        },
        {
          label: "Trifásico" as const,
          value: "Trifásico" as const,
          disabled: disableTrifasico,
          isHidden: hideTrifasico,
        },
      ].filter((option) => !option.isHidden),
    [
      disableMonofasico,
      disableBifasico,
      disableTrifasico,
      hideMonofasico,
      hideBifasico,
      hideTrifasico,
    ],
  );

  return (
    <Select
      {...props}
      options={OPTIONS}
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
}
