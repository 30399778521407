import type { IComplementaryItemsSpreadsheetUploadFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function ComplementaryItemsSpreadsheetUploadFormModal({
  isOpen,
  onClose,
  title = "Enviar planilha",
  width,
  formProps,
}: IComplementaryItemsSpreadsheetUploadFormModalProps) {
  return (
    <Modal
      data-cy="complementary-items-spreadsheet-upload-form-modal"
      open={isOpen}
      onCancel={onClose}
      title={title}
      width={width}
      footer={null}
      destroyOnClose
      style={{ maxWidth: 768 }}
    >
      <Form.ComplementaryItemsSpreadsheetUpload
        {...formProps}
        onCancel={onClose}
      />
    </Modal>
  );
}
