import * as React from "react";

import type { IIconProps } from "../@types/globals";
import { cn } from "../lib/utils";

const OpenEye: React.FC<IIconProps> = ({ className, ...props }) => (
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    stroke="currentColor"
    className={cn("pointer-events-none w-6 h-6", className)}
    {...props}
  >
    <title>Olho aberto</title>
    <path d="M20.7251 8.70433L20.1308 9.1618L20.7251 8.70433ZM20.7251 15.2957L20.1308 14.8382L20.7251 15.2957ZM22 12H21.25H22ZM3.27489 8.70433L3.86922 9.1618L3.27489 8.70433ZM3.27489 15.2957L3.86922 14.8382H3.86922L3.27489 15.2957ZM3.86922 9.1618C5.49864 7.04492 8.15036 4.75 12 4.75V3.25C7.48587 3.25 4.44529 5.9542 2.68057 8.24686L3.86922 9.1618ZM12 4.75C15.8496 4.75 18.5014 7.04492 20.1308 9.1618L21.3194 8.24686C19.5547 5.9542 16.5141 3.25 12 3.25V4.75ZM20.1308 14.8382C18.5014 16.9551 15.8496 19.25 12 19.25V20.75C16.5141 20.75 19.5547 18.0458 21.3194 15.7531L20.1308 14.8382ZM12 19.25C8.15036 19.25 5.49864 16.9551 3.86922 14.8382L2.68057 15.7531C4.44529 18.0458 7.48587 20.75 12 20.75V19.25ZM20.1308 9.1618C20.5694 9.73159 20.8263 10.0721 20.9952 10.4545C21.1532 10.812 21.25 11.2489 21.25 12H22.75C22.75 11.1117 22.6344 10.4529 22.3673 9.8484C22.1113 9.26895 21.7308 8.78127 21.3194 8.24686L20.1308 9.1618ZM21.3194 15.7531C21.7308 15.2187 22.1113 14.7311 22.3673 14.1516C22.6344 13.5471 22.75 12.8883 22.75 12H21.25C21.25 12.7511 21.1532 13.188 20.9952 13.5455C20.8263 13.9279 20.5694 14.2684 20.1308 14.8382L21.3194 15.7531ZM2.68057 8.24686C2.26922 8.78127 1.88868 9.26895 1.63269 9.8484C1.36564 10.4529 1.25 11.1117 1.25 12H2.75C2.75 11.2489 2.84684 10.812 3.00476 10.4545C3.17374 10.0721 3.43064 9.73159 3.86922 9.1618L2.68057 8.24686ZM3.86922 14.8382C3.43064 14.2684 3.17374 13.9279 3.00476 13.5455C2.84684 13.188 2.75 12.7511 2.75 12H1.25C1.25 12.8883 1.36564 13.5471 1.63269 14.1516C1.88868 14.7311 2.26922 15.2187 2.68057 15.7531L3.86922 14.8382ZM14.25 12C14.25 13.2426 13.2426 14.25 12 14.25V15.75C14.0711 15.75 15.75 14.0711 15.75 12H14.25ZM12 14.25C10.7574 14.25 9.75 13.2426 9.75 12H8.25C8.25 14.0711 9.92893 15.75 12 15.75V14.25ZM9.75 12C9.75 10.7574 10.7574 9.75 12 9.75V8.25C9.92893 8.25 8.25 9.92893 8.25 12H9.75ZM12 9.75C13.2426 9.75 14.25 10.7574 14.25 12H15.75C15.75 9.92893 14.0711 8.25 12 8.25V9.75Z" />
  </svg>
);

OpenEye.displayName = "OpenEye";

export { OpenEye };
