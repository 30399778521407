import { useEffect, useMemo, useState } from "react";

import type { IPipelineStagesCheckboxGroupCollapseProps } from "./interface";
import { StyledCollapse, StyledInnerCollapse } from "./styles";

import { Alert } from "~components/Alert";
import { useFetchGetAllPipelinesDetailed } from "~hooks/api";
import { createMapperByNumberArray } from "~utils/createMapperByNumberArray";
import { Checkbox, Flex, Spin, Switch } from "antd";

function findMissingElements(array: number[], expectedElements: number[]) {
  const elementsSet = new Set(array);

  const missingElements = expectedElements.filter(
    (element) => !elementsSet.has(element),
  );

  return missingElements;
}

export function PipelineStagesCheckboxGroupCollapse({
  onChangeSelectedStagesIds,
  initialSelectedStagesIds = [],
  selectedStagesIds: controlledSelectedStagesIds,
  displayOnly = [],
  canSelectItems: controlledCanSelectItems,
  canSelectItemsIsDisabled = false,
  onChangeCanSelectItems,
  startExpanded = true,
}: IPipelineStagesCheckboxGroupCollapseProps) {
  const [selectedStagesIds, setSelectedStagesIds] = useState<number[]>(
    initialSelectedStagesIds,
  );

  const [canSelectItems, setCanSelectItems] = useState(false);

  const {
    data: pipelines = [],
    isFetching: pipelineIsLoading,
    error: pipelineError,
    refetch: pipelineRefetch,
  } = useFetchGetAllPipelinesDetailed({
    dependencyArray: [],
    options: {
      retry: 0,
    },
  });

  const displayOnlyMap = useMemo(
    () => createMapperByNumberArray(displayOnly),
    [displayOnly],
  );

  const filteredPipelines = pipelines.filter(
    (pipeline) => !!displayOnlyMap?.[pipeline.id],
  );

  function getAllPipelineStagesIds() {
    const pipelineStages = filteredPipelines.reduce(
      (stagesIds = [], pipeline) => {
        const newStageIds = pipeline.stages.map((stage) => stage.id);
        return [...stagesIds, ...newStageIds];
      },
      [] as number[],
    );

    return pipelineStages;
  }

  function getStagesByPipelineId(pipelineId: number) {
    const pipelineStages = filteredPipelines.find(
      (pipeline) => pipeline.id === pipelineId,
    );

    return pipelineStages?.stages ?? [];
  }

  function getSelectedStagesFromPipelineId(pipelineId: number) {
    const pipelineStage = getStagesByPipelineId(pipelineId);

    const filteredStages = pipelineStage.filter((stage) =>
      (controlledSelectedStagesIds ?? selectedStagesIds).includes(stage.id),
    );

    return filteredStages;
  }

  function handleSelectAll() {
    const allStagesIds = getAllPipelineStagesIds();
    setSelectedStagesIds(allStagesIds);

    if (typeof onChangeSelectedStagesIds === "function") {
      onChangeSelectedStagesIds(allStagesIds);
    }
  }

  function handleRemoveAll() {
    setSelectedStagesIds([]);

    if (typeof onChangeSelectedStagesIds === "function") {
      onChangeSelectedStagesIds([]);
    }
  }

  function handleSelectPipelineStages(pipelineId: number) {
    const pipelineStages = getStagesByPipelineId(pipelineId);

    const pipelineStagesIds = pipelineStages.map((stage) => stage.id);

    setSelectedStagesIds((current) => {
      const newData = [...current, ...pipelineStagesIds];

      if (typeof onChangeSelectedStagesIds === "function") {
        onChangeSelectedStagesIds(newData);
      }

      return newData;
    });
  }

  function handleRemovePipelineStages(pipelineId: number) {
    const pipelineStages = getStagesByPipelineId(pipelineId);

    const pipelineStagesIds = pipelineStages.map((stage) => stage.id);

    setSelectedStagesIds((current) => {
      const filteredStageIds = current.filter(
        (stageId) => !pipelineStagesIds.includes(stageId),
      );

      if (typeof onChangeSelectedStagesIds === "function") {
        onChangeSelectedStagesIds(filteredStageIds);
      }

      return filteredStageIds;
    });
  }

  function handleSelectSingleOption(
    selectedStagesIds: number[],
    pipelineId: number,
  ) {
    setSelectedStagesIds((current) => {
      const pipelineStages = getStagesByPipelineId(pipelineId);

      const pipelineStagesIds = getStagesByPipelineId(pipelineId).map(
        (stage) => stage.id,
      );

      const currentStagesByPipelineId = current.filter((element) =>
        pipelineStagesIds.includes(element),
      );

      const isAddingElements =
        selectedStagesIds.length > currentStagesByPipelineId.length;

      // IS REMOVING ELEMENT
      if (!isAddingElements) {
        const stageIdToBeRemoved = findMissingElements(
          selectedStagesIds,
          currentStagesByPipelineId,
        )[0] as number | undefined;

        const stageIdPositionInStage = pipelineStages.findIndex(
          (stage) => stage.id === stageIdToBeRemoved,
        );

        const slicedPipelineStages = pipelineStages.slice(
          stageIdPositionInStage + 1,
          pipelineStages.length,
        );

        const updatedStagesIds = slicedPipelineStages.map((stage) => stage.id);

        const filteredCurrentSelectedIds = current.filter(
          (stageId) => !pipelineStagesIds.includes(stageId),
        );

        const newData = [...filteredCurrentSelectedIds, ...updatedStagesIds];

        if (typeof onChangeSelectedStagesIds === "function") {
          onChangeSelectedStagesIds(newData);
        }

        return newData;
      }

      const newStageId = findMissingElements(current, selectedStagesIds)[0] as
        | number
        | undefined;

      // IS ADDING A ELEMENT
      const newStageIdPositionInStage = pipelineStages.findIndex(
        (stage) => stage.id === newStageId,
      );

      const slicedPipelineStages = pipelineStages.slice(
        newStageIdPositionInStage,
        pipelineStages.length,
      );

      const newStagesIds = slicedPipelineStages.map((stage) => stage.id);

      const filteredCurrentSelectedIds = current.filter(
        (stageId) => !pipelineStagesIds.includes(stageId),
      );

      const newData = [...filteredCurrentSelectedIds, ...newStagesIds];

      if (typeof onChangeSelectedStagesIds === "function") {
        onChangeSelectedStagesIds(newData);
      }

      return newData;
    });
  }

  const allPipelineStagesIds = getAllPipelineStagesIds();

  const isAllChecked =
    allPipelineStagesIds.length ===
    (controlledSelectedStagesIds ?? selectedStagesIds).length;

  const isIndeterminate =
    (controlledSelectedStagesIds ?? selectedStagesIds).length > 0 &&
    (controlledSelectedStagesIds ?? selectedStagesIds).length <
      allPipelineStagesIds.length;

  useEffect(() => {
    if (controlledSelectedStagesIds) {
      setSelectedStagesIds(controlledSelectedStagesIds);
    }
  }, [controlledSelectedStagesIds]);

  // when displayOnly change (needs remove some of already selected items)
  useEffect(() => {
    if (displayOnly.length > 0) {
      const notDisplayOnlyPipeline = pipelines.filter(
        (pipeline) => !displayOnlyMap?.[pipeline.id],
      );

      const pipelineStagesIdsMap = notDisplayOnlyPipeline.reduce(
        (map, pipeline) => {
          const mappedStages = pipeline.stages.reduce(
            (map, stage) => {
              return { ...map, [stage.id]: stage.id };
            },
            {} as Record<string, number>,
          );

          return { ...map, ...mappedStages };
        },
        {} as Record<string, number>,
      );

      setSelectedStagesIds((current) => {
        const filteredStagesIds = current.filter(
          (stageId) => !pipelineStagesIdsMap?.[stageId],
        );

        if (
          typeof onChangeSelectedStagesIds === "function" &&
          filteredStagesIds.toString() !== current.toString()
        ) {
          onChangeSelectedStagesIds(filteredStagesIds);
        }

        return filteredStagesIds;
      });
    }
  }, [displayOnly, pipelines, displayOnlyMap, onChangeSelectedStagesIds]);

  return (
    <Flex vertical gap={8}>
      {pipelineError && (
        <Alert.CardError
          errorMessage={pipelineError.message}
          reloadFn={pipelineRefetch}
          title="Falha ao carregar etapas das pipelines"
        />
      )}

      <StyledCollapse
        collapsible={pipelineError ? "disabled" : "header"}
        items={[
          {
            key: "CheckboxGroup.Pipeline",
            label: (
              <Flex gap={8}>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Switch
                    id="required-field-switch"
                    checked={controlledCanSelectItems ?? canSelectItems}
                    disabled={canSelectItemsIsDisabled}
                    onChange={(checked) => {
                      setCanSelectItems(checked);

                      if (typeof onChangeCanSelectItems === "function") {
                        onChangeCanSelectItems(checked);
                      }
                    }}
                  />
                </div>
                <label
                  htmlFor="required-field-switch"
                  style={{ fontWeight: 500 }}
                  onClick={(e) => e.stopPropagation()}
                >
                  Campo obrigatório
                </label>
              </Flex>
            ),
            children: (
              <Flex vertical>
                {filteredPipelines.length > 1 && (
                  <Flex
                    gap={8}
                    style={{
                      padding: "12px 0 12px 16px",
                      borderBottom: "1px solid var(--gray-200)",
                    }}
                  >
                    <Checkbox
                      id="select-all-stages-of-all-pipelines"
                      onClick={(e) => {
                        e.stopPropagation();
                        isAllChecked ? handleRemoveAll() : handleSelectAll();
                      }}
                      indeterminate={isIndeterminate}
                      checked={isAllChecked}
                      disabled={
                        !(controlledCanSelectItems ?? canSelectItems) ||
                        !!pipelineError ||
                        !!pipelineIsLoading
                      }
                    />
                    <label
                      htmlFor="select-all-stages-of-all-pipelines"
                      onClick={(e) => e.stopPropagation()}
                    >
                      Obrigatório em todas as etapas dos funis
                    </label>
                  </Flex>
                )}

                {filteredPipelines.map((pipeline) => {
                  const pipelineStagesIds = getStagesByPipelineId(
                    pipeline.id,
                  ).map((stage) => stage.id);

                  const selectedStagesIdsFromPipeline =
                    getSelectedStagesFromPipelineId(pipeline.id).map(
                      (stage) => stage.id,
                    );

                  const isAllChecked =
                    pipelineStagesIds.length ===
                    selectedStagesIdsFromPipeline.length;

                  const isIndeterminate =
                    selectedStagesIdsFromPipeline.length > 0 &&
                    selectedStagesIdsFromPipeline.length <
                      pipelineStagesIds.length;

                  return (
                    <StyledInnerCollapse
                      key={pipeline.id}
                      collapsible={
                        !(controlledCanSelectItems ?? canSelectItems) ||
                        pipelineError ||
                        pipelineStagesIds.length === 0
                          ? "disabled"
                          : "header"
                      }
                      items={[
                        {
                          key: "PipelineStagesCheckboxGroupInnerCollapse",
                          label: (
                            <Flex gap={8}>
                              <Checkbox
                                id={`all-stages-of-pipeline-${pipeline.id}`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  isAllChecked
                                    ? handleRemovePipelineStages(pipeline.id)
                                    : handleSelectPipelineStages(pipeline.id);
                                }}
                                indeterminate={isIndeterminate}
                                checked={isAllChecked}
                                disabled={
                                  !(
                                    controlledCanSelectItems ?? canSelectItems
                                  ) ||
                                  !!pipelineError ||
                                  !!pipelineIsLoading ||
                                  pipelineStagesIds.length === 0
                                }
                              />
                              <label
                                htmlFor={`all-stages-of-pipeline-${pipeline.id}`}
                                style={{ fontWeight: 500 }}
                                onClick={(e) => e.stopPropagation()}
                              >
                                {pipeline.name}
                              </label>
                            </Flex>
                          ),
                          children: (
                            <Checkbox.Group<number>
                              options={pipeline.stages.map((stage) => {
                                return {
                                  label: stage.name,
                                  value: stage.id,
                                };
                              })}
                              value={
                                controlledSelectedStagesIds ?? selectedStagesIds
                              }
                              onChange={(stagesIds) => {
                                handleSelectSingleOption(
                                  stagesIds,
                                  pipeline.id,
                                );
                              }}
                              disabled={
                                !(controlledCanSelectItems ?? canSelectItems)
                              }
                            />
                          ),
                        },
                      ]}
                      expandIconPosition="right"
                      expandIcon={
                        pipelineIsLoading
                          ? () => <Spin size="small" spinning />
                          : pipelineStagesIds.length === 0
                            ? () => null
                            : undefined
                      }
                      defaultActiveKey={
                        startExpanded
                          ? ["PipelineStagesCheckboxGroupInnerCollapse"]
                          : []
                      }
                    />
                  );
                })}
              </Flex>
            ),
          },
        ]}
        expandIconPosition="right"
        expandIcon={
          pipelineIsLoading ? () => <Spin size="small" spinning /> : undefined
        }
        defaultActiveKey={startExpanded ? ["CheckboxGroup.Pipeline"] : []}
      />
    </Flex>
  );
}
