import type {
  IImportUserFromPipedriveFormProps,
  IImportUserFromPipedriveFormValues,
} from "./interface";

import { CustomInput } from "~components/CustomInput";
import { Select } from "~components/Select";
import { useFetchImportPipedriveUser } from "~hooks/api";
import { Button, Flex, Form } from "antd";

export function ImportUserFromPipedriveForm({
  initialValues = {
    email: "",
    id: 0,
  },
  onCancel = () => undefined,
  onError = () => undefined,
  onSuccess = () => undefined,
}: IImportUserFromPipedriveFormProps) {
  const { mutate: importPipedriveUser, isLoading } =
    useFetchImportPipedriveUser({
      options: {
        onSuccess,
        onError,
      },
    });

  function handleSubmit(formValues: IImportUserFromPipedriveFormValues) {
    importPipedriveUser({
      userId: formValues.id,
      profileId: formValues.profileId,
    });
  }

  return (
    <Form
      data-cy="import-user-from-pipedrive-form"
      onFinish={handleSubmit}
      initialValues={initialValues}
      layout="vertical"
      style={{ width: "100%" }}
    >
      <Form.Item name="id" label="id" hidden>
        <CustomInput.Number disabled placeholder="Ex.: 1" />
      </Form.Item>

      <Form.Item name="email" label="Usuário" rules={[{ required: true }]}>
        <CustomInput.Text
          type="email"
          placeholder="Ex.: usuario@solarz.com.br"
          disabled
        />
      </Form.Item>

      <Form.Item name="profileId" label="Selecione o perfil...">
        <Select.Profile placeholder="Selecione o perfil..." />
      </Form.Item>

      <Flex align="center" gap={8} justify="end">
        <Button onClick={onCancel}>Cancelar</Button>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Importar
        </Button>
      </Flex>
    </Form>
  );
}
