import type {
  IAdditionalCostTaxFormValues,
  IAdditionalCostTaxFormProps,
} from "./interface";

import { CustomInput } from "~components/CustomInput";
import {
  useFetchCreateAdditionalCostTax,
  useFetchUpdateAdditionalCostTax,
} from "~hooks/api";
import { Button, Form, Radio, Flex } from "antd";

function validateIdentifier(value = "") {
  try {
    // replace all characters with spaces and/or especial characters except underline
    const parsedValue = value.replace(/[^a-zA-Z0-9_]/g, "");

    return value === parsedValue
      ? Promise.resolve()
      : Promise.reject("Formato é inválido!");
  } catch (error: any) {
    return Promise.reject(`Error ao validar: ${error?.message}`);
  }
}

export function AdditionalCostTaxForm({
  initialValues,
  onSuccess = () => undefined,
  onError = () => undefined,
  onCancel = () => undefined,
}: IAdditionalCostTaxFormProps) {
  const [form] = Form.useForm<IAdditionalCostTaxFormValues>();

  const {
    mutate: createAdditionalCostTax,
    isLoading: isCreatingAdditionalCostTax,
  } = useFetchCreateAdditionalCostTax({
    options: {
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: (error) => {
        onError(error.message);
      },
    },
  });

  const {
    mutate: updateAdditionalCostTax,
    isLoading: isUpdatingAdditionalCostTax,
  } = useFetchUpdateAdditionalCostTax({
    options: {
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: (error) => {
        onError(error.message);
      },
    },
  });

  function handleSubmitForm(data: IAdditionalCostTaxFormValues) {
    if (initialValues?.id) {
      updateAdditionalCostTax({
        ...data,
        identifier: data.identifier ?? undefined,
        id: initialValues.id,
      });
    } else {
      createAdditionalCostTax({
        ...data,
        identifier: data.identifier ?? undefined,
      });
    }
  }

  const isLoading = isCreatingAdditionalCostTax || isUpdatingAdditionalCostTax;

  return (
    <Form
      data-cy="additional-cost-tax-form"
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmitForm}
      layout="vertical"
    >
      <Form.Item
        name="name"
        label="Nome"
        rules={[{ required: true }]}
        style={{ width: "100%" }}
      >
        <CustomInput.Text placeholder="Ex.: Alíquota extra" />
      </Form.Item>

      <Form.Item
        label="Identificador"
        name="identifier"
        rules={[
          {
            validator: (_, value: string | null) =>
              validateIdentifier(value ?? ""),
          },
          {
            required: false,
          },
        ]}
        tooltip="Não pode conter espaços e/ou caracteres especiais"
        style={{ width: "100%" }}
      >
        <CustomInput.Text placeholder="Ex.: id_extra" />
      </Form.Item>

      <Form.Item
        name="rate"
        label="Taxa"
        rules={[{ required: true }]}
        style={{ width: "100%" }}
      >
        <CustomInput.UnitMeasurement
          precision="2"
          unitMeasurement="%"
          placeholder="Ex.: 25.00"
          min={0}
        />
      </Form.Item>

      <Form.Item
        label="Campo ativo?"
        name="active"
        rules={[{ required: true }]}
        style={{ width: "100%" }}
      >
        <Radio.Group>
          <Radio value={true}>Sim</Radio>
          <Radio value={false}>Não</Radio>
        </Radio.Group>
      </Form.Item>

      <Flex gap={12} justify="end">
        <Form.Item style={{ margin: 0 }}>
          <Button onClick={onCancel}>Cancelar</Button>
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Salvar
          </Button>
        </Form.Item>
      </Flex>
    </Form>
  );
}
