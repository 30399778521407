import { ISolarzButtonProps } from "./interface";
import { StyledButton } from "./styles";

export function SolarzButton({
  children,
  icon,
  iconPlace = "left",
  scale = "medium",
  variant = "primary",
  isDisabled = false,
  isLoading = false,
  isBlock = false,
  onClick,
  htmlType = "button",
  style,
  className,
  href,
}: ISolarzButtonProps) {
  return (
    <StyledButton
      iconPlace={iconPlace}
      scale={scale}
      variant={variant}
      disabled={isDisabled}
      loading={isLoading}
      onClick={onClick}
      block={isBlock}
      htmlType={htmlType}
      style={style}
      className={className}
      iconOnly={!children && !!icon}
      href={href}
    >
      {!isLoading ? icon : null}
      {children}
    </StyledButton>
  );
}
