import { useMemo, useState } from "react";
import {
  AiOutlineCopy,
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineQuestionCircle,
} from "react-icons/ai";
import { BsQuestionCircle } from "react-icons/bs";

import { IComplementaryItemsOfSettingsListProps } from "./interface";

import { Menu } from "~components/Menu";
import { Modal } from "~components/Modal";
import { createPaginationTableObject } from "~components/Pagination";
import { Result } from "~components/Result";
import { Select } from "~components/Select";
import { Tag, UniqueValueTagColorType } from "~components/Tag";
import {
  useFetchCreateCopyComplementaryItems,
  useFetchDeleteComplementaryItemsOfSettings,
  useFetchGetPaginatedAdditionalCostsAuxVariable,
  useFetchGetPaginatedComplementaryItemsOfSettings,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import type { CalculationTypeEnum, ProjectTypeEnum } from "~types/enum";
import type { IAdditionalCostDTOSchema } from "~types/schemas";
import { parseCurrency, parsePercentage } from "~utils/parse";
import {
  Col,
  Flex,
  List,
  Popover,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";

const tooltipDescriptions = [
  {
    name: "percentualDeLucro",
    content: (
      <div style={{ width: "500px" }}>
        <Typography.Text>
          O lucro é a diferença entre a receita total e o custo total da
          empresa. Este indicador representa aquilo que sobrou após todos os
          descontos obrigatórios, custos fixos e custos variáveis de produção.
          Ele poderá ser utilizado para ser distribuído aos sócios, compor o
          capital de giro ou reinvestido. É possível alterar a base de cálculo
          entre o valor total do projeto incluindo o kit, ou apenas considerando
          os itens complementares com exceção do kit.
          <br />
          <span style={{ color: "red" }}>
            Observação 1: Ele poderá ser negativo caso a receita não seja
            suficiente para pagar todos as despesas.
          </span>
        </Typography.Text>
      </div>
    ),
  },
  {
    name: "margemDeContribuição",
    content: (
      <div style={{ width: "500px" }}>
        <Typography.Text>
          O Índice de Margem de Contribuição é o percentual resultante entre os
          custos fixos não envolvido diretamente neste projeto dividido pela
          receita bruta. IMC = Projeção Custos Fixos/Projeção Receita Bruta IMC
          = Índice de Margem de Contribuição Projeção Custos Fixos = Somatório
          de todas as despesas que não estão diretamente relacionadas ao projeto
          atual, como por exemplo: Pró-Labore, Mão de Obra Administrativa,
          Aluguel, Energia, Internet, Contador, Advogado, IPTU, Juros de
          Empréstimo, Amortização de Investimentos, Entre Outros. Projeção
          Receita Bruta = Somatório de toda a receita a ser obtida pela empresa.
          É possível alterar a base de cálculo entre o valor total do projeto
          incluindo o kit, ou apenas considerando os itens complementares com
          exceção do kit.
          <br />
          <span style={{ color: "red" }}>
            Observação 1: O Índice de margem de contribuição não considera o
            lucro, ele reflete apenas o necessário para cobrir as despesas
            fixas.
          </span>
        </Typography.Text>
      </div>
    ),
  },
  {
    name: "impostos",
    content: (
      <div style={{ width: "500px" }}>
        <Typography.Text>
          Este campo representa o custo com impostos, por padrão poderá ser
          utilizado um único percentual para representar todos os impostos, ou
          poderá ser detalhados incluindo mais itens. É possível alterar a base
          de cálculo entre o valor total do projeto incluindo o kit, ou apenas
          considerando os itens complementares com exceção do kit.
        </Typography.Text>
      </div>
    ),
  },
];

function getTagColorByProjectType(
  value: ProjectTypeEnum,
): UniqueValueTagColorType {
  switch (value) {
    case "ALL":
      return "geekblue";
    case "DEFAULT":
      return "cyan";
    case "DIVERSE":
      return "volcano";
    case "EXPRESS":
      return "magenta";
    default:
      return "processing";
  }
}
function translateProjectType(value: ProjectTypeEnum) {
  switch (value) {
    case "ALL":
      return "Todos";
    case "DEFAULT":
      return "Solar";
    case "DIVERSE":
      return "Diversa";
    case "EXPRESS":
      return "Express";
    default:
      return "Desconhecido";
  }
}

function projectTypeColumnRender(value: ProjectTypeEnum | undefined) {
  if (!value) {
    return (
      <Tag.UniqueValue
        color="default"
        title="Sem tipo"
        tooltip="Sem tipo definido"
      />
    );
  }
  const tagColor = getTagColorByProjectType(value as ProjectTypeEnum);
  const tagValue = translateProjectType(value as ProjectTypeEnum);

  return (
    <Tag.UniqueValue
      color={tagColor}
      title={tagValue}
      tooltip={`Campo para propostas do tipo ${tagValue}`}
    />
  );
}

function formatValue(
  value: number | undefined | null,
  type?: CalculationTypeEnum,
) {
  const parsedValue = Number(value);
  if (isNaN(parsedValue)) return "-";

  switch (type) {
    case "VALOR_FIXO":
      return parseCurrency(parsedValue);
    case "PERCENTUAL":
      return parsePercentage(parsedValue);
    default:
      return parsedValue.toFixed(2);
  }
}

export function ComplementaryItemsOfSettingsList({
  isAuxVariable = false,
  header,
  ...props
}: IComplementaryItemsOfSettingsListProps) {
  const { screens } = useAppConfig();

  const [selectedAdvancedCostModalOpen, setSelectedAdvancedCostModalOpen] =
    useState<IAdditionalCostDTOSchema>();

  const [selectedComplementaryItemToCopy, setSelectedComplementaryItemToCopy] =
    useState<IAdditionalCostDTOSchema>();

  const [
    selectedComplementaryItemToDelete,
    setSelectedComplementaryItemToDelete,
  ] = useState<IAdditionalCostDTOSchema>();

  const [
    selectedComplementaryItemToUpdate,
    setSelectedComplementaryItemToUpdate,
  ] = useState<IAdditionalCostDTOSchema>();

  const [
    selectedAuxiliaryVariablesToUpdate,
    setSelectedAuxiliaryVariablesToUpdate,
  ] = useState<IAdditionalCostDTOSchema>();

  const [currentPage, setCurrentPage] = useState<number>(1);

  const useFetchData = isAuxVariable
    ? useFetchGetPaginatedAdditionalCostsAuxVariable
    : useFetchGetPaginatedComplementaryItemsOfSettings;

  const { data, isFetching, error } = useFetchData({
    payload: {
      page: currentPage - 1,
      size: 5,
    },
    dependencyArray: [currentPage],
    options: {
      enabled: currentPage > 0,
    },
  });

  const {
    mutate: deleteComplementaryItem,
    isLoading: isDeletingComplementaryItem,
  } = useFetchDeleteComplementaryItemsOfSettings({
    onSuccess: () => setSelectedComplementaryItemToDelete(undefined),
  });

  const {
    mutate: copyComplementaryItem,
    isLoading: isCopingComplementaryItem,
  } = useFetchCreateCopyComplementaryItems({
    options: {
      onSuccess: () => setSelectedComplementaryItemToCopy(undefined),
    },
  });

  function handleDeleteComplementaryItem() {
    deleteComplementaryItem({
      additionalCostsId: selectedComplementaryItemToDelete?.id ?? 0,
    });
  }

  function handleCopyComplementaryItem() {
    copyComplementaryItem({
      complementaryItemId: selectedComplementaryItemToCopy?.id ?? 0,
      name: selectedComplementaryItemToCopy?.name
        ? `${selectedComplementaryItemToCopy?.name} (Cópia)`
        : "",
    });
  }

  function onChangePagination(number: number, _totalElements?: number) {
    setCurrentPage(number);
  }

  const DATA_SOURCE = useMemo(() => data?.content ?? [], [data?.content]);

  return (
    <>
      {header?.display && (
        <Flex
          vertical
          style={{
            width: "100%",
          }}
        >
          <Space>
            <Typography.Text style={{ marginLeft: 2 }}>
              Tipo de proposta
            </Typography.Text>
            <Tooltip title="Possibilidade de definir se um custo complementar deve ser exibido em diferentes tipos de propostas: Diversa, Solar ou Express. Isso permite personalizar a visibilidade do custo de acordo com as necessidades específicas de cada tipo de proposta.">
              <AiOutlineQuestionCircle
                size={14}
                style={{ display: "flex", alignItems: "center" }}
              />
            </Tooltip>
          </Space>

          <Select.ProposalType
            hideExpress={!!header.hideExpressOption}
            hideAll={true}
            style={{ width: "100%", maxWidth: 200 }}
            onChange={() => {
              setCurrentPage(1);
            }}
          />
        </Flex>
      )}

      <List
        {...props}
        data-cy="complementary-items-of-settings-list"
        itemLayout="horizontal"
        size="small"
        dataSource={DATA_SOURCE}
        loading={isFetching}
        style={{ marginTop: "30px", width: "100%" }}
        renderItem={(item: IAdditionalCostDTOSchema) => (
          <List.Item key={item.id} style={{ padding: "8px 0" }}>
            <Flex
              align="center"
              justify="space-between"
              wrap="nowrap"
              style={{ width: "100%" }}
            >
              <Row gutter={16} style={{ width: "100%" }}>
                <Col xs={24} sm={12} xl={6}>
                  <List.Item.Meta
                    title={
                      item.fixed
                        ? [
                            <b
                              key={
                                item.id
                                  ? item.id + Math.random()
                                  : Math.random()
                              }
                            >
                              {item.name}
                            </b>,
                            <Popover
                              content={
                                item.identifier === "cc_lucro"
                                  ? tooltipDescriptions[0].content
                                  : item.identifier === "cc_margem_contribuicao"
                                    ? tooltipDescriptions[1].content
                                    : item.identifier === "cc_impostos"
                                      ? tooltipDescriptions[2].content
                                      : ""
                              }
                              trigger="hover"
                              key={item.id}
                            >
                              <span>
                                <BsQuestionCircle
                                  style={{ marginLeft: "10px" }}
                                />
                              </span>
                            </Popover>,
                          ]
                        : item.name
                    }
                    description={formatValue(item.value, item.calculationType)}
                  />
                </Col>
                <Col xs={24} sm={12} xl={6}>
                  <List.Item.Meta
                    title="Categoria"
                    description={`${item.calculationType ?? "-"} `}
                  />
                </Col>
                <Col xs={24} sm={12} xl={4}>
                  <List.Item.Meta
                    title="Base de cálculo"
                    description={`${
                      item.calculationType === "PERCENTUAL"
                        ? item.baseCalculationType
                        : "-"
                    }`}
                  />
                </Col>
                <Col xs={24} sm={12} xl={4}>
                  <List.Item.Meta
                    title="Grupo de custo"
                    description={item.costGroup}
                  />
                </Col>
                <Col xs={24} sm={12} xl={4}>
                  <List.Item.Meta
                    title="Tipo de proposta"
                    description={projectTypeColumnRender(
                      item.pricingType?.projectType,
                    )}
                  />
                </Col>
              </Row>
              <Menu.MoreDropdown
                items={[
                  {
                    text: "Alterar",
                    icon: (
                      <AiOutlineEdit style={{ color: "var(--gold-500)" }} />
                    ),
                    color: "var(--gold-500)",
                    onClick: () =>
                      isAuxVariable
                        ? setSelectedAuxiliaryVariablesToUpdate(item)
                        : setSelectedComplementaryItemToUpdate(item),
                  },
                  {
                    text: "Criar cópia",
                    icon: (
                      <AiOutlineCopy style={{ color: "var(--blue-500)" }} />
                    ),
                    color: "var(--blue-500)",
                    onClick: () => setSelectedComplementaryItemToCopy(item),
                  },
                  {
                    text: "Remover",
                    icon: (
                      <AiOutlineDelete style={{ color: "var(--red-500)" }} />
                    ),
                    color: "var(--red-500)",
                    onClick: () => setSelectedComplementaryItemToDelete(item),
                    isDisabled: item.fixed,
                    tooltip: item.fixed
                      ? "Este item é fixo, não pode ser removido."
                      : undefined,
                  },
                ]}
                position="bottomLeft"
              />
            </Flex>
          </List.Item>
        )}
        pagination={createPaginationTableObject({
          number: currentPage,
          size: data?.size,
          totalElements: data?.totalElements,
          onChange: onChangePagination,
          disabled: false,
          itemType: isAuxVariable
            ? "variáveis auxiliares"
            : "itens complementares",
          displayTotalInformation: screens.sm,
        })}
        locale={{
          emptyText: (
            <Result.TableErrorOrEmpty
              errorMessage={error?.message}
              emptyMessage={
                isAuxVariable
                  ? "Nenhuma variável auxiliar encontrada."
                  : "Nenhum item complementar encontrado."
              }
            />
          ),
        }}
      />

      <Modal.ComplementaryItemsOfSettingsForm
        isOpen={!!selectedAdvancedCostModalOpen}
        onClose={() => setSelectedAdvancedCostModalOpen(undefined)}
        formProps={{
          initialValues: selectedAdvancedCostModalOpen,
          onSuccess: () => setSelectedAdvancedCostModalOpen(undefined),
        }}
      />

      <Modal.AuxiliaryVariablesForm
        isOpen={!!selectedAuxiliaryVariablesToUpdate}
        onClose={() => setSelectedAuxiliaryVariablesToUpdate(undefined)}
        formProps={{
          initialValues: selectedAuxiliaryVariablesToUpdate,
          onSuccess: () => setSelectedAuxiliaryVariablesToUpdate(undefined),
        }}
      />

      <Modal.DefaultRemove
        isLoading={isDeletingComplementaryItem}
        isOpen={!!selectedComplementaryItemToDelete}
        itemName={selectedComplementaryItemToDelete?.name}
        onClose={() => setSelectedComplementaryItemToDelete(undefined)}
        suffix="Item complementar"
        title="Remover Item complementar"
        onSubmit={handleDeleteComplementaryItem}
      />

      <Modal.DefaultAlert
        isOpen={!!selectedComplementaryItemToCopy}
        isLoading={isCopingComplementaryItem}
        onClose={() => setSelectedComplementaryItemToCopy(undefined)}
        subtitle="Realmente deseja criar um cópia deste item complementar?"
        title="Copiar item complementar"
        onSubmit={handleCopyComplementaryItem}
      />

      <Modal.ComplementaryItemsOfSettingsForm
        isOpen={!!selectedComplementaryItemToUpdate}
        onClose={() => setSelectedComplementaryItemToUpdate(undefined)}
        formProps={{
          initialValues: selectedComplementaryItemToUpdate,
          onSuccess: () => setSelectedComplementaryItemToUpdate(undefined),
        }}
      />
    </>
  );
}
