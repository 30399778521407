import { useState } from "react";

import type { ICreateTransformerGroupButtonProps } from "./interface";

import { Modal } from "~components/Modal";
import { Add } from "~estrela-ui/icons";
import { useAppConfig } from "~hooks/useAppConfig";
import { SolarzButton } from "~solarzui/SolarzButton";

export function CreateTransformerGroupButton({
  children,
  icon = <Add />,
  ...props
}: ICreateTransformerGroupButtonProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { screens } = useAppConfig();

  return (
    <>
      <SolarzButton
        {...props}
        data-cy="create-transformer-group-button"
        onClick={() => setIsModalOpen(true)}
        variant="primary"
        htmlType="button"
        scale={screens.mobile ? "tiny" : "medium"}
      >
        {icon}
        {children ?? "Nova regra"}
      </SolarzButton>

      <Modal.TransformerRuleForm
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        formProps={{
          onSuccess: () => setIsModalOpen(false),
        }}
      />
    </>
  );
}
