import type {
  ICustomPipedriveActivityMessageFormProps,
  ICustomPipedriveActivityMessageFormValues,
} from "./interface";

import { CustomInput } from "~components/CustomInput";
import { useFetchUpdateActivityMessageFromPipedrive } from "~hooks/api";
import { Button, Form, Flex } from "antd";

export function CustomPipedriveActivityMessageForm({
  initialValues,
  onSuccess = () => undefined,
  onError = () => undefined,
  onCancel = () => undefined,
}: ICustomPipedriveActivityMessageFormProps) {
  const [form] = Form.useForm<ICustomPipedriveActivityMessageFormValues>();

  const { mutate: updateActivityMessage, isLoading: isUpdateActivityMessage } =
    useFetchUpdateActivityMessageFromPipedrive({
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: (error) => {
        onError(error.message);
      },
    });

  function handleSubmitForm(
    formValues: ICustomPipedriveActivityMessageFormValues,
  ) {
    updateActivityMessage(formValues);
  }

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmitForm}
      layout="vertical"
      style={{ width: "100%" }}
    >
      <Form.Item
        name="customPipedriveActivityMessage"
        label="Mensagem do pipedrive"
        rules={[{ required: true }]}
        style={{ width: "100%" }}
      >
        <CustomInput.TextArea
          autoSize
          placeholder="Digite sua mensagem aqui..."
        />
      </Form.Item>
      <Flex gap={12} justify="end">
        <Form.Item style={{ margin: 0 }}>
          <Button onClick={onCancel}>Cancelar</Button>
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={isUpdateActivityMessage}
          >
            Alterar
          </Button>
        </Form.Item>
      </Flex>
    </Form>
  );
}
