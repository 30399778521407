import { FC } from "react";

import { Input as InputAntTextArea } from "antd";
import type { TextAreaProps } from "antd/lib/input";

export const InputTextArea: FC<TextAreaProps> = ({
  onFocus = (event) => event.target.select(),
  ...rest
}) => {
  return <InputAntTextArea.TextArea {...rest} onFocus={onFocus} />;
};
