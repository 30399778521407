import { useMemo } from "react";
import { AiOutlineInbox } from "react-icons/ai";

import type {
  IDefaultDraggerUploadProps,
  IDefaultDraggerUploadFileType,
} from "./interface";

import { Flex, Typography, Upload } from "antd";

function getAcceptableTypeByFileType(type: IDefaultDraggerUploadFileType) {
  switch (type) {
    case "docx":
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    case "jpg":
      return "image/jpeg";
    case "json":
      return "application/json";
    case "pdf":
      return "application/pdf";
    case "png":
      return "image/png";
    case "txt":
      return "text/plain";
    case "xlsx":
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    default:
      return "";
  }
}

export function DefaultDraggerUpload({
  uploadHeight = 256,
  acceptableFileType = ["docx", "jpg", "json", "pdf", "png", "txt", "xlsx"],
  maxCount = 1,
  multiple = false,
  beforeUpload = () => undefined,
  onChange = () => undefined,
  title = "Clique ou arraste o arquivo aqui",
  subtitle = "Suporta apenas um único arquivo",
}: IDefaultDraggerUploadProps) {
  const acceptableTypes = useMemo(() => {
    const acceptableTypesArray = acceptableFileType.map((type) =>
      getAcceptableTypeByFileType(type),
    );

    return acceptableTypesArray.join(",");
  }, [acceptableFileType]);

  return (
    <Upload.Dragger
      data-cy="default-dragger-upload"
      type="drag"
      accept={acceptableTypes}
      beforeUpload={beforeUpload}
      onChange={onChange}
      maxCount={maxCount}
      multiple={multiple}
      customRequest={({ onSuccess }) =>
        setTimeout(() => {
          onSuccess ? onSuccess("ok", undefined) : undefined;
        }, 0)
      }
    >
      <Flex
        vertical
        justify="center"
        align="center"
        gap={8}
        style={{ height: uploadHeight }}
      >
        <AiOutlineInbox size={48} color="var(--primary-500)" />
        <Typography.Title
          level={4}
          style={{ color: "var(--primary-500)", margin: 0 }}
        >
          {title}
        </Typography.Title>
        <Typography.Text type="secondary">{subtitle}</Typography.Text>
      </Flex>
    </Upload.Dragger>
  );
}
