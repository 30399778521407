import { api, apiException } from "~services/api";
import {
  IFetchUpdateProposalDealPayloadSchema,
  FetchUpdateProposalDealPayloadSchema,
} from "~types/schemas/FetchUpdateProposalDealPayloadSchema";

export const fetchUpdateProposalDeal = async (
  payload: IFetchUpdateProposalDealPayloadSchema,
) => {
  try {
    const body = FetchUpdateProposalDealPayloadSchema.parse(payload);

    await api.put(`/proposal/pipedrive/pipelines/deals`, body);
  } catch (error) {
    throw apiException(error);
  }
};
