import type { IDealCustomVariableFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function DealCustomVariableFormModal({
  isOpen = false,
  onClose = () => undefined,
  maxWidth = 1024,
  title = "Formulário da variável customizada de negócio",
  formProps,
  customVariableId,
}: IDealCustomVariableFormModalProps) {
  return (
    <Modal
      data-cy="person-form-modal"
      title={title}
      width="90vw"
      style={{
        maxWidth,
      }}
      open={isOpen}
      footer={null}
      onCancel={onClose}
      destroyOnClose
      styles={{
        header: {
          marginBottom: "1rem",
        },
      }}
    >
      <Form.DealCustomVariable
        {...formProps}
        onCancel={onClose}
        customVariableId={customVariableId}
        maxHeight={512}
      />
    </Modal>
  );
}
