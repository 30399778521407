import { useState } from "react";

import type {
  IPersonSearchSelectProps,
  PersonSearchSelectRecordType,
} from "./interface";

import { People } from "~estrela-ui/icons";
import { useFetchGetAutoCompletePerson } from "~hooks/api";
import { SolarzSelect } from "~solarzui/SolarzSelect";
import { Flex } from "antd";

export function PersonSearchSelect(props: IPersonSearchSelectProps) {
  const [searchValue, setSearchValue] = useState("");

  const canMakeRequest = searchValue.length >= 3;

  const {
    data = [],
    isFetching,
    error,
    refetch,
  } = useFetchGetAutoCompletePerson({
    dependencyArray: [searchValue],
    payload: {
      term: searchValue,
    },
    options: {
      enabled: canMakeRequest,
    },
  });

  return (
    <SolarzSelect<PersonSearchSelectRecordType, "id">
      {...props}
      searchValue={props.searchValue ?? searchValue}
      onChangeSearch={(value) => {
        setSearchValue(value);

        if (typeof props.onChangeSearch === "function") {
          props.onChangeSearch(value);
        }
      }}
      options={data.map((option) => {
        return {
          label: (
            <Flex align="center" gap={8}>
              <People />
              <span>{option.name}</span>
            </Flex>
          ),
          record: option,
          value: option.id,
        };
      })}
      isLoading={isFetching}
      refetchFn={canMakeRequest ? refetch : undefined}
      errorMessage={error?.message}
      emptyMessage={
        canMakeRequest
          ? "Nenhuma pessoa encontrada"
          : "Digite ao menos 3 letras para buscar"
      }
      showSearch
    />
  );
}
