import type { IComplementaryItemsTableData } from "./interface";

import styled from "@emotion/styled";
import { Table } from "antd";

export interface IStyledLayoutProps {
  displayFooter?: boolean;
}

export const StyledTable = styled(Table<IComplementaryItemsTableData>, {
  shouldForwardProp: (prop) => prop !== "displayFooter",
})<IStyledLayoutProps>`
  && .ant-table-tbody tr:last-child {
    background-color: ${({ displayFooter }) =>
      displayFooter ? "var(--gray-100)" : ""};
  }

  html.dark && .ant-table-tbody tr:last-child {
    background-color: ${({ displayFooter }) =>
      displayFooter ? "var(--gray-800)" : ""};
  }
`;
