import type { IUserSettingsFormProps } from "./interface";

import { CustomInput } from "~components/CustomInput";
import { useFetchUpdateLocationService } from "~hooks/api/admin/useFetchUpdateLocationService";
import { useFetchUpdateMaxUsersLicensed } from "~hooks/api/admin/useFetchUpdateMaxUsersLicensed";
import { IUpdateUserSettingsSchema } from "~types/schemas";
import { Button, Flex, Form, Select, message } from "antd";

export function UserSettingsForm({
  initialValues,
  onCancel = () => undefined,
  onSubmitFail = () => undefined,
  onSubmitSuccess = () => undefined,
}: IUserSettingsFormProps) {
  const { mutate: updateMaxUsersLicensed, isLoading: isUpdatingLicense } =
    useFetchUpdateMaxUsersLicensed({
      options: {
        onSuccess: onSubmitSuccess,
        onError: onSubmitFail,
      },
    });

  const {
    mutate: updateLocationService,
    isLoading: isUpdatingLocationService,
  } = useFetchUpdateLocationService({
    options: {
      onSuccess: onSubmitSuccess,
      onError: onSubmitFail,
    },
  });

  function handleSubmitForm(formValues: IUpdateUserSettingsSchema) {
    try {
      updateMaxUsersLicensed({
        userId: initialValues?.id ?? 0,
        maxUsersLicensed: formValues.maxUsersLicensed,
      });

      updateLocationService({
        userId: initialValues?.id ?? 0,
        locationService: formValues.locationService,
      });
      message.success("Configurações salvas!");
    } catch (error: any) {
      message.error(error.message);
    }
  }

  const OPTIONS = [
    {
      label: "Here",
      value: "HERE",
    },
    {
      label: "Google Maps",
      value: "GOOGLE",
    },
  ];

  const loading = isUpdatingLicense || isUpdatingLocationService;

  return (
    <Form
      data-cy="users-settings-form"
      onFinish={handleSubmitForm}
      initialValues={initialValues}
      layout="vertical"
      style={{ width: "100%" }}
    >
      <Form.Item
        name="maxUsersLicensed"
        label="Máximo de usuários licenciados"
        rules={[{ required: true }]}
      >
        <CustomInput.Number precision={0} placeholder="Ex.: 10" />
      </Form.Item>
      <Form.Item
        label="Serviço de localização"
        style={{ marginBottom: "5rem" }}
        name="locationService"
        rules={[{ required: true }]}
      >
        <Select options={OPTIONS} />
      </Form.Item>
      <Flex align="center" gap={8} justify="end">
        <Button onClick={onCancel}>Cancelar</Button>
        <Button type="primary" htmlType="submit" loading={loading}>
          Salvar
        </Button>
      </Flex>
    </Form>
  );
}
