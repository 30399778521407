import { AiOutlineMinusCircle, AiOutlinePlus } from "react-icons/ai";

import type {
  IEmptyKitFormValues,
  IEmptyKitFormProps,
  IEmptyKitFormInversor,
} from "./interface";
import styles from "./styles";

import { AutoComplete } from "~components/AutoComplete";
import { CustomInput } from "~components/CustomInput";
import { Select } from "~components/Select";
import { useAppConfig } from "~hooks/useAppConfig";
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Flex,
  Form,
  Row,
} from "antd";

export function EmptyKitForm({
  initialValues,
  onSubmit = () => undefined,
  onCancel = () => undefined,
  isLoading = false,
}: IEmptyKitFormProps) {
  const { screens } = useAppConfig();

  const [form] = Form.useForm<IEmptyKitFormValues>();

  function handleSubmit(formValues: IEmptyKitFormValues) {
    const payload: IEmptyKitFormValues = {
      ...formValues,
      inversors: formValues.inversors,
    };

    return onSubmit(payload);
  }

  return (
    <Form
      data-cy="empty-kit-form"
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmit}
      layout="vertical"
    >
      <style jsx>{styles}</style>
      <Flex
        align="center"
        gap={!screens.md ? 0 : 12}
        justify="space-between"
        wrap="nowrap"
        style={{ flexDirection: !screens.md ? "column" : "row" }}
      >
        <Form.Item
          label="Preço"
          name="price"
          rules={[
            {
              required: true,
              message: "Campo é obrigatório",
            },
          ]}
          style={{ width: "100%" }}
        >
          <CustomInput.Money placeholder="Ex.: 1000" min={0} />
        </Form.Item>
      </Flex>

      <Divider
        orientation="left"
        style={{
          color: "var(--primary-500)",
          margin: "0px 0px 1rem 0px",
          fontSize: 14,
        }}
      >
        Módulo
      </Divider>

      <Flex
        vertical
        wrap="nowrap"
        style={{
          width: "100%",
        }}
      >
        <Row
          align="middle"
          wrap={true}
          justify="center"
          gutter={[16, 0]}
          style={{
            width: "100%",
          }}
        >
          <Col xs={0}>
            <Form.Item name="moduleId" label="id" hidden>
              <CustomInput.Number readOnly disabled />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="moduleModel"
              label="Modelo do módulo"
              rules={[{ required: true, whitespace: true }]}
            >
              <AutoComplete.ProductModule
                placeholder="Digite ao menos 3 letras para auto-completar"
                onChange={(value, option) => {
                  if (!option?.record) return;

                  form.setFieldsValue({
                    ...form.getFieldsValue(),
                    moduleId: option.record.id,
                    moduleBrand: option.record.brand,
                    moduleModel: option.record.model,
                    moduleDistributor: option.record.distributor,
                    moduleType: option.record.type,
                    modulePowerWp: option.record.powerWp,
                    moduleAreaM2: option.record.areaM2,
                    moduleLenghtMm: option.record.lengthMm,
                    moduleWidthMm: option.record.widthMm,
                    moduleUnityAmount:
                      (option.record?.precoBaseCents ?? 0) / 100,
                  } as IEmptyKitFormValues);
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={0}>
            <Form.Item name="moduleCategory" label="Categoria" hidden>
              <Select.KitItemCategory style={{ width: "100%" }} disabled />
            </Form.Item>
          </Col>

          <Col xs={12} md={6}>
            <Form.Item
              name="moduleUnitAmount"
              label="Valor unitário"
              rules={[{ required: true, message: "Obrigatório" }]}
            >
              <CustomInput.Money min={0} style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col xs={12} md={6}>
            <Form.Item
              name="moduleQuantity"
              label="Quantidade"
              rules={[{ required: true, message: "Obrigatório" }]}
            >
              <CustomInput.Number
                min={0}
                precision={0}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row
          align="middle"
          wrap={true}
          justify="center"
          gutter={[16, 0]}
          style={{
            width: "100%",
          }}
        >
          <Col xs={24} md={8}>
            <Form.Item name="moduleBrand" label="Marca do módulo">
              <CustomInput.Text placeholder="Marca do módulo" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item name="moduleDistributor" label="Distribuidor">
              <CustomInput.Text placeholder="Distribuidor" />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Form.Item name="moduleType" label="Tipo">
              <CustomInput.Text placeholder="Ex.: 50" />
            </Form.Item>
          </Col>
        </Row>
        <Row
          align="middle"
          wrap={true}
          justify="center"
          gutter={[16, 0]}
          style={{
            width: "100%",
          }}
        >
          <Col xs={24} md={6}>
            <Form.Item name="modulePowerWp" label="Potência">
              <CustomInput.UnitMeasurement
                precision="2"
                unitMeasurement="KWp"
                placeholder="Ex.: 180"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={6}>
            <Form.Item name="moduleAreaM2" label="Area em M²">
              <CustomInput.UnitMeasurement
                precision="2"
                unitMeasurement="m²"
                placeholder="Ex.: 50"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={6}>
            <Form.Item name="moduleWidthMm" label="Largura">
              <CustomInput.UnitMeasurement
                precision="2"
                unitMeasurement="mm"
                placeholder="Ex.: 250"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={6}>
            <Form.Item name="moduleLenghtMm" label="Comprimento">
              <CustomInput.UnitMeasurement
                precision="2"
                unitMeasurement="mm"
                placeholder="Ex.: 300"
              />
            </Form.Item>
          </Col>
        </Row>
      </Flex>

      <Collapse
        defaultActiveKey="INVERTERS"
        items={[
          {
            key: "INVERTERS",
            label: "Lista de inversores",
            children: (
              <Form.List
                name="inversors"
                initialValue={[
                  {
                    category: "INVERTER",
                    quantity: 1,
                    unityAmount: 0,
                  },
                ]}
              >
                {(fields, { add, remove }) => (
                  <>
                    <Flex
                      vertical
                      wrap="nowrap"
                      style={{
                        width: "100%",
                      }}
                    >
                      {fields.map(({ key, name, ...restField }, index) => (
                        <div key={key} style={{ width: "100%" }}>
                          <Divider
                            orientation="left"
                            style={{
                              fontSize: 12,
                              color: "var(--primary-500)",
                            }}
                          >
                            Inversor {index + 1}
                          </Divider>
                          <Row
                            align="middle"
                            wrap={true}
                            justify="center"
                            gutter={[16, 0]}
                            style={{
                              width: "100%",
                            }}
                          >
                            <Col xs={0}>
                              <Form.Item
                                {...restField}
                                name={[name, "id"]}
                                label="id"
                                hidden
                              >
                                <CustomInput.Number readOnly disabled />
                              </Form.Item>
                            </Col>

                            <Col xs={24} md={8}>
                              <Form.Item
                                {...restField}
                                name={[name, "model"]}
                                label="Modelo do Inversor"
                                rules={[{ required: true, whitespace: true }]}
                              >
                                <AutoComplete.ProductInverter
                                  placeholder="Digite ao menos 3 letras para auto-completar"
                                  onChange={(value, option) => {
                                    if (!option) return;

                                    const fields = form.getFieldsValue();
                                    const { inversors } = fields;

                                    if (!inversors?.[name] || !option.record)
                                      return;

                                    Object.assign(inversors[name], {
                                      unityAmount:
                                        option.record.precoBaseCents / 100,
                                      category: "INVERTER",
                                      brand: option.record.brand,
                                      maximumPotency:
                                        option.record.maxCurrentPerMppt,
                                      outputVoltage:
                                        option.record.outputVoltage,
                                      potency: option.record.maxPower,
                                      id: option.record?.id,
                                    } as IEmptyKitFormInversor);

                                    form.setFieldsValue({ inversors });
                                  }}
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={0}>
                              <Form.Item
                                {...restField}
                                name={[name, "category"]}
                                label="Categoria"
                                hidden
                              >
                                <Select.KitItemCategory
                                  style={{ width: "100%" }}
                                  disabled
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={12} md={8}>
                              <Form.Item
                                {...restField}
                                name={[name, "unityAmount"]}
                                label="Valor unitário"
                                rules={[
                                  { required: true, message: "Obrigatório" },
                                ]}
                              >
                                <CustomInput.Money
                                  min={0}
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={12} md={7}>
                              <Form.Item
                                {...restField}
                                name={[name, "quantity"]}
                                label="Quantidade"
                                rules={[
                                  { required: true, message: "Obrigatório" },
                                ]}
                              >
                                <CustomInput.Number
                                  min={0}
                                  precision={0}
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </Col>

                            <Col md={1}>
                              <Flex justify="center" align="center">
                                <Button
                                  type="text"
                                  size="small"
                                  shape="circle"
                                  style={{ lineHeight: 0 }}
                                  onClick={() => remove(name)}
                                  disabled={fields.length <= 1}
                                >
                                  <AiOutlineMinusCircle size={16} />
                                </Button>
                              </Flex>
                            </Col>
                          </Row>
                          <Row
                            align="middle"
                            wrap={true}
                            justify="center"
                            gutter={[16, 0]}
                            style={{
                              width: "100%",
                            }}
                          >
                            <Col xs={24} md={6}>
                              <Form.Item
                                {...restField}
                                name={[name, "brand"]}
                                label="Marca"
                              >
                                <CustomInput.Text placeholder="Marca do inversor" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                              <Form.Item
                                {...restField}
                                name={[name, "outputVoltage"]}
                                label="Tensão de saída"
                              >
                                <Select.OutputVoltage placeholder="Ex.: 220V" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                              <Form.Item
                                {...restField}
                                name={[name, "potency"]}
                                label="Potência"
                              >
                                <CustomInput.UnitMeasurement
                                  precision="2"
                                  unitMeasurement="KWp"
                                  placeholder="Ex.: 180"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                              <Form.Item
                                {...restField}
                                name={[name, "maximumPotency"]}
                                label="Potência máxima"
                              >
                                <CustomInput.UnitMeasurement
                                  precision="2"
                                  unitMeasurement="KWp"
                                  placeholder="Ex.: 200"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </Flex>

                    <Divider style={{ margin: "0 0 0.5rem 0" }} />

                    <Button
                      block
                      type="dashed"
                      className="addRowButton"
                      onClick={() =>
                        add({
                          category: undefined,
                          name: undefined,
                          quantity: 1,
                          unityAmount: 0,
                        })
                      }
                    >
                      <Flex align="center" justify="center" gap={8}>
                        <AiOutlinePlus size={16} />
                        Novo Inversor
                      </Flex>
                    </Button>
                  </>
                )}
              </Form.List>
            ),
          },
        ]}
        accordion
        size="small"
      />

      <Flex
        align="center"
        gap={!screens.md ? 0 : 12}
        justify="space-between"
        wrap="nowrap"
        style={{
          marginTop: "1rem",
          flexDirection: !screens.md ? "column" : "row",
        }}
      >
        <Form.Item name="structureCostInclude">
          <Checkbox
            defaultChecked={true}
            onChange={(e) =>
              form.setFieldValue("structureCostInclude", !!e.target.checked)
            }
          >
            Incluir o custo da estrutura?
          </Checkbox>
        </Form.Item>
      </Flex>

      <Flex gap={12} justify="end">
        <Form.Item style={{ margin: 0 }}>
          <Button onClick={onCancel}>Cancelar</Button>
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Salvar
          </Button>
        </Form.Item>
      </Flex>
    </Form>
  );
}
