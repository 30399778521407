import { useEffect } from "react";
import { AiOutlineMinusCircle, AiOutlinePlus } from "react-icons/ai";

import type {
  IProposalPaymentStepFormProps,
  IProposalPaymentStepFormValues,
  IProposalPaymentOptionsValues,
} from "./interface";
import { StyledButton } from "./styles";

import { CustomInput } from "~components/CustomInput";
import { useFetchUpdateManyProposalPayment } from "~hooks/api";
import { useFetchGetProposalPayments } from "~hooks/api/projects/useFetchGetProposalPayments";
import { useFetchGetProposalPaymentsAutocomplete } from "~hooks/api/projects/useFetchGetProposalPaymentsAutocomplete";
import { useProposal } from "~hooks/useProposal";
import { useSession } from "~hooks/useSession";
import { IProposalPaymentsDTO } from "~types/schemas";
import { Button, Col, Divider, Flex, Form, Row } from "antd";

export function ProposalPaymentStepForm({
  formInstance,
  onSuccess = () => undefined,
  onError = () => undefined,
  onCancel = () => undefined,
  maxContentHeight = "62vh",
  onLoad,
  displayFooter = true,
}: IProposalPaymentStepFormProps) {
  const { proposalState } = useProposal();
  const { sessionStatus } = useSession();

  const [form] = Form.useForm<IProposalPaymentStepFormValues>(formInstance);

  useFetchGetProposalPayments({
    payload: {
      customId: proposalState?.proposalId ?? 0,
    },
    options: {
      enabled: !!proposalState?.proposalId && sessionStatus === "authenticated",
      onSuccess: (data) => {
        if (data.length == 0) return;
        form.setFieldsValue({
          options: data,
        });
      },
    },
    dependencyArray: [proposalState?.proposalId],
  });

  useFetchGetProposalPaymentsAutocomplete({
    payload: {
      customId: proposalState?.proposalId ?? 0,
    },
    options: {
      enabled: !!proposalState?.proposalId && sessionStatus === "authenticated",
      onSuccess: (data) => {
        const formValues = form.getFieldValue(
          "options",
        ) as IProposalPaymentsDTO;

        const alreadyExistsAValue = formValues?.some((option) => option?.id);

        if (!alreadyExistsAValue) {
          form.setFieldsValue({
            options: data,
          });
        }
      },
    },
    dependencyArray: [proposalState?.proposalId],
  });

  const {
    mutate: fetchUpdateProposalPayments,
    isLoading: isUpdatingProposalPayments,
  } = useFetchUpdateManyProposalPayment({
    options: {
      onSuccess: () => {
        onSuccess();
      },
      onError: () => {
        onError();
      },
    },
  });

  function handleSubmit(formData: IProposalPaymentStepFormValues) {
    fetchUpdateProposalPayments({
      customId: proposalState?.proposalId ?? 0,
      payments: formData.options,
    });
  }

  function handleCancel() {
    typeof onCancel === "function" ? onCancel() : form.resetFields();
  }

  useEffect(() => {
    if (typeof onLoad === "function") {
      onLoad(isUpdatingProposalPayments);
    }
  }, [onLoad, isUpdatingProposalPayments]);

  return (
    <Form form={form} onFinish={handleSubmit} layout="vertical">
      <Form.List name="options">
        {(fields, { add, remove }) => {
          return (
            <>
              <Flex
                vertical
                style={{
                  maxHeight: maxContentHeight,
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                {fields.map(({ key, name, ...restField }, index) => {
                  return (
                    <div key={key}>
                      {index > 0 && (
                        <Divider style={{ marginTop: 0, marginBottom: 12 }} />
                      )}

                      <Row key={key} gutter={12} align="middle">
                        <Col xs={24} md={6}>
                          <Form.Item
                            label="Nome"
                            {...restField}
                            name={[name, "name"]}
                            rules={[
                              {
                                required: true,
                                message: "Por favor, insira o nome",
                              },
                            ]}
                          >
                            <CustomInput.Text placeholder="Ex.: Pagamento 01" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={4}>
                          <Form.Item
                            {...restField}
                            name={[name, "installments"]}
                            label="Número de parcelas"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                            tooltip="Deve ser maior que 0"
                          >
                            <CustomInput.UnitMeasurement
                              precision="0"
                              unitMeasurement="meses"
                              min={0}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} md={6}>
                          <Form.Item
                            {...restField}
                            name={[name, "monthlyInterestRate"]}
                            label="Taxa de juros mensal"
                            rules={[{ required: true }]}
                            tooltip="Não é permitido valor maior que 80% ao mês"
                          >
                            <CustomInput.UnitMeasurement
                              precision="2"
                              unitMeasurement="%"
                              min={0}
                              max={80}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={22} md={6}>
                          <Form.Item
                            {...restField}
                            name={[name, "downPayment"]}
                            label="Valor de entrada"
                            required
                          >
                            <CustomInput.Money min={0} />
                          </Form.Item>
                        </Col>

                        <Col
                          xs={1}
                          md={1}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            type="link"
                            onClick={() => remove(name)}
                            icon={<AiOutlineMinusCircle size={16} />}
                            style={{ marginTop: 8 }}
                          />
                        </Col>
                      </Row>
                    </div>
                  );
                })}
              </Flex>

              <Form.Item>
                <StyledButton
                  type="dashed"
                  onClick={() =>
                    add({
                      name: "",
                      installments: 0,
                      monthlyInterestRate: 0,
                      downPayment: 0,
                    } as IProposalPaymentOptionsValues)
                  }
                  block
                >
                  <Flex align="center" justify="center" gap={8}>
                    <AiOutlinePlus size={16} />
                    Novo intervalo
                  </Flex>
                </StyledButton>
              </Form.Item>
            </>
          );
        }}
      </Form.List>

      {displayFooter && (
        <Flex wrap="nowrap" justify="flex-end" align="center" gap={8}>
          <Button onClick={handleCancel}>Cancelar</Button>
          <Button
            htmlType="submit"
            type="primary"
            loading={isUpdatingProposalPayments}
          >
            Enviar
          </Button>
        </Flex>
      )}
    </Form>
  );
}
