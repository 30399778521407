import type { IDealOwnerDetailsCardProps } from "./interface";

import { Alert } from "~components/Alert";
import { Modal } from "~components/Modal";
import { Edit } from "~estrela-ui/icons";
import { useFetchGetDealByCustomId } from "~hooks/api";
import { parseFunctions, useQueryParams } from "~hooks/useQueryParams";
import { SolarzAnchor } from "~solarzui/SolarzAnchor";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { formatTelephoneWithDdd } from "~utils/format";
import { Card, Col, Flex, Row, Skeleton, Tooltip } from "antd";

const UPDATE_PERSON_MODAL_QUERY_KEY = "editar-pessoa";
const UPDATE_ORGANIZATION_MODAL_QUERY_KEY = "editar-organizacao";

export function DealOwnerDetailsCard({
  dealId,
  style,
  updateOrganizationQueryKey = UPDATE_ORGANIZATION_MODAL_QUERY_KEY,
  updatePersonQueryKey = UPDATE_PERSON_MODAL_QUERY_KEY,
}: IDealOwnerDetailsCardProps) {
  const { handleSaveInQueryParams, getParsedQueryParams } = useQueryParams();

  const selectedPersonId = getParsedQueryParams(
    updatePersonQueryKey,
    parseFunctions.NUMBER,
  );

  const selectedOrganizationId = getParsedQueryParams(
    updateOrganizationQueryKey,
    parseFunctions.NUMBER,
  );

  function handleOpenUpdatePersonModal() {
    handleSaveInQueryParams({ [updatePersonQueryKey]: deal?.person?.id ?? 0 });
  }

  function handleCloseUpdatePersonModal() {
    handleSaveInQueryParams({ [updatePersonQueryKey]: null });
  }

  function handleOpenUpdateOrganizationModal() {
    handleSaveInQueryParams({
      [updateOrganizationQueryKey]: deal?.organization?.id ?? 0,
    });
  }

  function handleCloseUpdateOrganizationModal() {
    handleSaveInQueryParams({ [updateOrganizationQueryKey]: null });
  }

  const {
    data: deal,
    isFetching: isLoadingDeal,
    error: dealError,
    refetch: refetchDeal,
  } = useFetchGetDealByCustomId({
    dependencyArray: [dealId],
    payload: {
      customId: dealId,
    },
    options: {
      enabled: dealId > 0,
    },
  });

  return (
    <>
      <Card
        style={{
          width: "100%",
          maxWidth: "400px",
          ...style,
        }}
        data-cy="deal-owner-details-card"
        styles={{
          body: {
            padding: 16,
          },
        }}
        title={
          <Flex justify="space-between" align="center">
            <SolarzTypography
              fontWeight="semiBold"
              hierarchy="paragraph1"
              variant="title"
            >
              Informações do cliente
            </SolarzTypography>

            {deal?.person && (
              <Tooltip
                title={
                  !deal?.id
                    ? "Impossível alterar: Faltando id da organização"
                    : undefined
                }
              >
                <span>
                  <SolarzButton
                    scale="tiny"
                    variant="ghost"
                    isDisabled={!deal?.id || !deal.active}
                    icon={
                      <Edit
                        style={{
                          color:
                            !deal?.id || !deal.active
                              ? "var(--gray-300)"
                              : "var(--primary-500)",
                        }}
                      />
                    }
                    onClick={handleOpenUpdatePersonModal}
                    style={{
                      border: "1px solid var(--gray-300)",
                      width: 28,
                      height: 28,
                      padding: 0,
                    }}
                  />
                </span>
              </Tooltip>
            )}

            {deal?.organization && !deal?.person && (
              <Tooltip
                title={
                  !deal?.id
                    ? "Impossível alterar: Faltando id da organização"
                    : undefined
                }
              >
                <span>
                  <SolarzButton
                    scale="tiny"
                    variant="ghost"
                    isDisabled={!deal?.id || !deal.active}
                    icon={
                      <Edit
                        style={{
                          color: !deal?.id
                            ? "var(--gray-300)"
                            : "var(--primary-500)",
                        }}
                      />
                    }
                    onClick={handleOpenUpdateOrganizationModal}
                    style={{
                      border: "1px solid var(--gray-300)",
                      width: 28,
                      height: 28,
                      padding: 0,
                    }}
                  />
                </span>
              </Tooltip>
            )}
          </Flex>
        }
      >
        <Flex vertical gap={16}>
          {dealError && (
            <Alert.CardError
              title="Falha ao carregar dados"
              errorMessage={dealError?.message}
              reloadFn={refetchDeal}
            />
          )}

          {deal?.person && (
            <>
              <Row align="top" gutter={24} wrap={false}>
                <Col span={10}>
                  <SolarzTypography
                    fontWeight="medium"
                    variant="title"
                    hierarchy="paragraph2"
                    style={{ textAlign: "left" }}
                  >
                    Pessoa
                  </SolarzTypography>
                </Col>
                <Col span={14}>
                  <SolarzTypography
                    fontWeight="regular"
                    variant="disabled"
                    hierarchy="small"
                  >
                    {isLoadingDeal || dealError ? (
                      <Skeleton.Input active={isLoadingDeal} size="small" />
                    ) : (
                      <SolarzAnchor
                        isNextLink
                        href={`/clientes/pessoa/${deal?.person?.id}?tab=atividades`}
                        aria-label={deal?.person?.name}
                        defaultUnderlined={false}
                      >
                        {deal?.person?.name}
                      </SolarzAnchor>
                    )}
                  </SolarzTypography>
                </Col>
              </Row>

              <Row align="top" gutter={24} wrap={false}>
                <Col span={10}>
                  <SolarzTypography
                    fontWeight="medium"
                    variant="title"
                    hierarchy="paragraph2"
                    style={{ textAlign: "left" }}
                  >
                    Telefone
                  </SolarzTypography>
                </Col>
                <Col span={14}>
                  {isLoadingDeal || dealError ? (
                    <Skeleton.Input active={isLoadingDeal} size="small" />
                  ) : (
                    <SolarzTypography
                      fontWeight="regular"
                      variant="disabled"
                      hierarchy="small"
                    >
                      {formatTelephoneWithDdd(deal?.person?.phone) || ""}
                    </SolarzTypography>
                  )}
                </Col>
              </Row>

              <Row align="top" gutter={24} wrap={false}>
                <Col span={10}>
                  <SolarzTypography
                    fontWeight="medium"
                    variant="title"
                    hierarchy="paragraph2"
                    style={{ textAlign: "left" }}
                  >
                    Org. da pessoa
                  </SolarzTypography>
                </Col>
                <Col span={14}>
                  {isLoadingDeal || dealError ? (
                    <Skeleton.Input active={isLoadingDeal} size="small" />
                  ) : (
                    <SolarzTypography
                      fontWeight="regular"
                      variant="disabled"
                      hierarchy="small"
                    >
                      <SolarzAnchor
                        isNextLink
                        href={`/clientes/pessoa/${deal?.person?.organization?.id}?tab=atividades`}
                        aria-label={deal?.person?.organization?.name}
                        defaultUnderlined={false}
                      >
                        {deal?.person?.organization?.name || "-"}
                      </SolarzAnchor>
                    </SolarzTypography>
                  )}
                </Col>
              </Row>

              <Row align="top" gutter={24} wrap={false}>
                <Col span={10}>
                  <SolarzTypography
                    fontWeight="medium"
                    variant="title"
                    hierarchy="paragraph2"
                    style={{ textAlign: "left" }}
                  >
                    Tel. da organização
                  </SolarzTypography>
                </Col>
                <Col span={14}>
                  {isLoadingDeal || dealError ? (
                    <Skeleton.Input active={isLoadingDeal} size="small" />
                  ) : (
                    <SolarzTypography
                      fontWeight="regular"
                      variant="disabled"
                      hierarchy="small"
                    >
                      {formatTelephoneWithDdd(
                        deal?.person?.organization?.phone || "",
                      ) || "-"}
                    </SolarzTypography>
                  )}
                </Col>
              </Row>
            </>
          )}

          {deal?.organization && !deal?.person && (
            <>
              <Row align="top" gutter={24} wrap={false}>
                <Col span={10}>
                  <SolarzTypography
                    fontWeight="medium"
                    variant="title"
                    hierarchy="paragraph2"
                    style={{ textAlign: "left" }}
                  >
                    Organização
                  </SolarzTypography>
                </Col>
                <Col span={14}>
                  <SolarzTypography
                    fontWeight="regular"
                    variant="disabled"
                    hierarchy="small"
                  >
                    {isLoadingDeal || dealError ? (
                      <Skeleton.Input active={isLoadingDeal} size="small" />
                    ) : (
                      <SolarzAnchor
                        isNextLink
                        href={`/clientes/empresa/${deal?.organization?.id}?tab=atividades`}
                        aria-label={deal?.organization?.name}
                        defaultUnderlined={false}
                      >
                        {deal?.organization?.name}
                      </SolarzAnchor>
                    )}
                  </SolarzTypography>
                </Col>
              </Row>

              <Row align="top" gutter={24} wrap={false}>
                <Col span={10}>
                  <SolarzTypography
                    fontWeight="medium"
                    variant="title"
                    hierarchy="paragraph2"
                    style={{ textAlign: "left" }}
                  >
                    Tel. da organização
                  </SolarzTypography>
                </Col>
                <Col span={14}>
                  {isLoadingDeal || dealError ? (
                    <Skeleton.Input active={isLoadingDeal} size="small" />
                  ) : (
                    <SolarzTypography
                      fontWeight="regular"
                      variant="disabled"
                      hierarchy="small"
                    >
                      {formatTelephoneWithDdd(deal?.organization?.phone) || ""}
                    </SolarzTypography>
                  )}
                </Col>
              </Row>
            </>
          )}
        </Flex>
      </Card>

      <Modal.PersonForm
        isOpen={!!selectedPersonId}
        onClose={handleCloseUpdatePersonModal}
        title="Alterar dados da pessoa"
        formProps={{
          onSuccess: handleCloseUpdatePersonModal,
          personId: selectedPersonId ?? 0,
        }}
      />

      <Modal.OrganizationForm
        isOpen={!!selectedOrganizationId}
        onClose={handleCloseUpdateOrganizationModal}
        title="Alterar dados da organização"
        formProps={{
          onSuccess: handleCloseUpdateOrganizationModal,
          organizationId: selectedOrganizationId ?? 0,
        }}
      />
    </>
  );
}
