import {
  IDealDefaultFieldFormProps,
  IDealDefaultFieldFormValues,
} from "./interface";
import styles from "./styles";

import { CustomInput } from "~components/CustomInput";
import { useFetchPatchDealDefaultFieldsSettings } from "~hooks/api/config/useFetchPatchDealDefaultFieldsSettings";
import { useAppConfig } from "~hooks/useAppConfig";
import {
  Button,
  Form,
  Flex,
  Divider,
  Col,
  Row,
  Checkbox,
  Typography,
} from "antd";

const DEFAULT_VALUES = {
  id: 0,
  required: false,
  editable: true,
  hidden: false,
} as IDealDefaultFieldFormValues;

export function DealDefaultFieldForm({
  initialValues,
  onCancel,
  onSubmitFail = () => undefined,
  onSubmitSuccess = () => undefined,
}: IDealDefaultFieldFormProps) {
  const { screens, message } = useAppConfig();

  const [form] = Form.useForm();
  const fieldIsConfigurable = !initialValues?.dealDefaultField?.configurable;

  const { mutate: updateDefaultField, isLoading: isUpdatingDefaultField } =
    useFetchPatchDealDefaultFieldsSettings({
      options: {
        onSuccess: onSubmitSuccess,
        onError: onSubmitFail,
      },
    });

  function handleSubmitForm(data: IDealDefaultFieldFormValues) {
    const defaultFieldIdToUpdate = initialValues?.id;

    if (defaultFieldIdToUpdate) {
      updateDefaultField({
        ...data,
        id: defaultFieldIdToUpdate,
      });
    } else {
      message.error(
        "Client error: Não existe ID para atualizar este campo padrão",
      );
    }
  }

  return (
    <Form
      form={form}
      data-cy="deal-custom-field-form"
      onFinish={handleSubmitForm}
      initialValues={{
        ...DEFAULT_VALUES,
        ...initialValues,
        label: initialValues?.dealDefaultField?.label,
      }}
      layout="vertical"
    >
      <style jsx>{styles}</style>

      <Flex
        align="center"
        gap={screens.md ? 8 : 0}
        wrap="nowrap"
        style={{
          display: "block",
        }}
      >
        <Row>
          <Col span={screens.md ? 14 : 24}>
            <Form.Item
              label="Nome do campo"
              name="label"
              style={{ width: "100%", fontWeight: "600" }}
            >
              <CustomInput.Text
                disabled
                placeholder="Ex.: Revisão"
                maxLength={255}
              />
            </Form.Item>
          </Col>

          <Col
            span={screens.md ? 2 : 24}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Divider
              type={screens.md ? "vertical" : "horizontal"}
              style={{ height: "100%" }}
            />
          </Col>

          <Col span={screens.md ? 8 : 24}>
            <Flex vertical gap={8}>
              <Typography.Text strong style={{ color: "var(--gray-600)" }}>
                Características do campo
              </Typography.Text>

              <Form.Item<IDealDefaultFieldFormValues>
                name="required"
                style={{ marginBottom: 0, paddingBottom: 0 }}
                valuePropName="checked"
              >
                <Checkbox disabled={fieldIsConfigurable}>Obrigatório</Checkbox>
              </Form.Item>
              <Form.Item<IDealDefaultFieldFormValues>
                name="editable"
                style={{ marginBottom: 0, paddingBottom: 0 }}
                valuePropName="checked"
              >
                <Checkbox disabled={fieldIsConfigurable}>Editável</Checkbox>
              </Form.Item>
              <Form.Item<IDealDefaultFieldFormValues>
                name="hidden"
                style={{ marginBottom: 0, paddingBottom: 0 }}
                valuePropName="checked"
              >
                <Checkbox disabled={fieldIsConfigurable}>Oculto</Checkbox>
              </Form.Item>
            </Flex>
          </Col>
        </Row>
      </Flex>
      <Flex gap={12} justify="end">
        <Form.Item style={{ margin: 0 }}>
          <Button onClick={onCancel}>Cancelar</Button>
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={isUpdatingDefaultField}
            disabled={fieldIsConfigurable}
          >
            Salvar
          </Button>
        </Form.Item>
      </Flex>
    </Form>
  );
}
