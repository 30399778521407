import { useState } from "react";
import { AiOutlineEdit, AiOutlineSetting } from "react-icons/ai";

import type {
  IAdminInvertersTableData,
  IAdminInvertersTableFilters,
  IAdminInvertersTableProps,
} from "./interface";

import { ColumnFilter } from "~components/ColumnFilter";
import { CustomInput } from "~components/CustomInput";
import { Menu } from "~components/Menu";
import { Modal } from "~components/Modal";
import { useFetchGetPaginatedAdminInverters } from "~hooks/api";
import { SolarzAnchor } from "~solarzui/SolarzAnchor";
import { SolarzTable } from "~solarzui/SolarzTable";
import { parsePotency } from "~utils/parse";
import { Form, Select } from "antd";

const DEFAULT_FILTERS: IAdminInvertersTableFilters = {
  page: 1,
  size: 10,
};

export function AdminInvertersTable({
  defaultFilters,
}: IAdminInvertersTableProps) {
  const [filters, setFilters] = useState<IAdminInvertersTableFilters>({
    ...DEFAULT_FILTERS,
    ...defaultFilters,
  });

  const [inverterIdToUpdate, setInverterIdToUpdate] = useState<number>();

  const [inverterIdToDisplayDetails, setInverterIdToDisplayDetails] =
    useState<number>();

  const {
    data: paginatedAdminInverters,
    isFetching: isLoadingPaginatedAdminInverters,
    refetch: reloadPaginatedAdminInverters,
    error: errorPaginatedAdminInverters,
  } = useFetchGetPaginatedAdminInverters({
    payload: filters,
    dependencyArray: [filters.page],
    options: {
      retry: 1,
    },
  });

  function handleChangeFilter(filters: Partial<IAdminInvertersTableFilters>) {
    setFilters((currentData) => {
      return { ...currentData, ...filters };
    });
  }

  function handleFetchTableData() {
    filters.page > 1
      ? setFilters((current) => ({ ...current, pageNumber: 1 }))
      : reloadPaginatedAdminInverters();
  }

  return (
    <>
      <SolarzTable<IAdminInvertersTableData>
        data-cy="admin-inverters-table"
        rowKey="id"
        columns={[
          {
            title: "Modelo",
            dataIndex: "model",
            render: (_, record) => record.model || "-",
            filterDropdown: (
              <ColumnFilter
                fields={[
                  <Form.Item key="models" htmlFor="models" label="Modelo">
                    <Select
                      mode="tags"
                      id="models"
                      value={filters.models}
                      onChange={(models) => {
                        handleChangeFilter({
                          models: models,
                        });
                      }}
                      placeholder="Modelos..."
                    />
                  </Form.Item>,
                ]}
                onReset={() => handleChangeFilter({ models: undefined })}
                isLoading={isLoadingPaginatedAdminInverters}
                onSubmit={handleFetchTableData}
              />
            ),
            filteredValue: filters.models,
          },
          {
            title: "Fabricante",
            dataIndex: "manufacturer",
            width: 320,
            render: (_, record) => record.manufacturer || "-",
            filterDropdown: (
              <ColumnFilter
                fields={[
                  <Form.Item key="brands" htmlFor="brands" label="Fabricante">
                    <Select
                      mode="tags"
                      id="brands"
                      value={filters.manufacturers}
                      onChange={(brands) => {
                        handleChangeFilter({
                          manufacturers: brands,
                        });
                      }}
                      placeholder="Fabricantes..."
                    />
                  </Form.Item>,
                ]}
                onReset={() => handleChangeFilter({ manufacturers: undefined })}
                isLoading={isLoadingPaginatedAdminInverters}
                onSubmit={handleFetchTableData}
              />
            ),
            filteredValue: filters.manufacturers,
          },
          {
            title: "Potência nominal",
            dataIndex: "nominalPower",
            width: 180,
            align: "center" as const,
            render: (_, record) =>
              record.nominalPower ? parsePotency(record.nominalPower) : "-",
            filterDropdown: (
              <ColumnFilter
                fields={[
                  <Form.Item
                    key="nominalPower"
                    htmlFor="nominalPower"
                    label="Potência nominal"
                  >
                    <CustomInput.Number
                      id="nominalPower"
                      value={filters.nominalPower}
                      onChange={(value) => {
                        const parsedValue = Number(value);

                        handleChangeFilter({
                          nominalPower: isNaN(parsedValue)
                            ? undefined
                            : parsedValue,
                        });
                      }}
                      min={0}
                      placeholder="Potência..."
                    />
                  </Form.Item>,
                ]}
                onReset={() => handleChangeFilter({ nominalPower: undefined })}
                isLoading={isLoadingPaginatedAdminInverters}
                onSubmit={handleFetchTableData}
              />
            ),
            filteredValue: filters.nominalPower ? [true] : [],
          },
          {
            title: "Tipo",
            dataIndex: "type",
            width: 140,
            align: "center" as const,
            render: (_, record) => record.type || "-",
          },
          {
            title: "Fase",
            dataIndex: "phase",
            width: 140,
            align: "center" as const,
            render: (_, record) => record.phase || "-",
          },
          {
            title: "Link",
            dataIndex: "datasheetId",
            width: 80,
            align: "center" as const,
            render: (_, record) => {
              const isValidId = record.datasheetId > 0;

              return (
                <SolarzAnchor
                  isNextLink
                  href={`/admin/datasheets?id=${record.datasheetId}`}
                  isDisabled={!isValidId}
                >
                  {isValidId ? "LINK" : "-"}
                </SolarzAnchor>
              );
            },
          },
          {
            key: "action",
            width: 64,
            render: (_, record) => {
              return (
                <Menu.MoreDropdown
                  items={[
                    {
                      icon: (
                        <AiOutlineEdit
                          fontSize={18}
                          style={{ color: "var(--gold-500)" }}
                        />
                      ),
                      text: "Alterar",
                      color: "var(--gold-500)",
                      onClick: () => setInverterIdToUpdate(record.id),
                    },
                    {
                      icon: (
                        <AiOutlineSetting
                          fontSize={18}
                          style={{ color: "var(--blue-500)" }}
                        />
                      ),
                      text: "Detalhes",
                      color: "var(--blue-500)",
                      onClick: () => setInverterIdToDisplayDetails(record.id),
                    },
                  ]}
                  position="bottomLeft"
                />
              );
            },
          },
        ]}
        rows={paginatedAdminInverters?.content ?? []}
        pagination={{
          currentItemCount: filters.size,
          currentPage: filters.page,
          itemLabel: "inversores",
          onChange: (page) => handleChangeFilter({ page }),
          totalItemCount: paginatedAdminInverters?.totalElements ?? 0,
          isDisabled: false,
        }}
        scroll={{ x: 1360 }}
        isLoading={isLoadingPaginatedAdminInverters}
        reloadFn={reloadPaginatedAdminInverters}
        errorMessage={errorPaginatedAdminInverters?.message}
        emptyMessage="Nenhum inversor encontrado"
      />

      <Modal.AdminInverterForm
        isOpen={!!inverterIdToUpdate}
        onClose={() => setInverterIdToUpdate(undefined)}
        id={inverterIdToUpdate ?? 0}
        formProps={{
          onSuccess: () => {
            setInverterIdToUpdate(undefined);
          },
        }}
      />

      <Modal.AdminInverterDetails
        isOpen={!!inverterIdToDisplayDetails}
        onClose={() => setInverterIdToDisplayDetails(undefined)}
        inverterId={inverterIdToDisplayDetails ?? 0}
      />
    </>
  );
}
