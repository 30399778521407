import { useCallback } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

import type {
  IProposalAfterSizingStepFormInitialValues,
  IProposalAfterSizingStepFormProps,
  ProposalAfterSizingStepCustomField,
} from "./interface";

import { ProposalCustomFieldsForm } from "~components/Others";
import { PROPOSAL_DATA_HAS_CHANGED } from "~configs/ProposalConfig";
import { AFTER_SIZING_STEP_STORAGE_KEY } from "~configs/SessionConfig";
import { useFetchCreateCustomFieldValue } from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { useInterval } from "~hooks/useInterval";
import { useProposal } from "~hooks/useProposal";
import { getBase64 } from "~utils/getBase64";
import {
  Col,
  Form,
  Row,
  Layout,
  Space,
  notification,
  Button,
  Typography,
  Flex,
} from "antd";
import { RcFile } from "antd/lib/upload/interface";
import dayjs from "dayjs";

export function ProposalAfterSizingStepForm({
  proposalId,
  customFieldsInitialValues,
  cancelText,
  onSuccess = () => undefined,
  onError = () => undefined,
  onCancel = () => undefined,
  lastStep,
  onHandleStepChange = () => undefined,
}: IProposalAfterSizingStepFormProps) {
  const { screens } = useAppConfig();
  const { proposalState } = useProposal();

  const [api, contextHolder] = notification.useNotification();

  const [form] = Form.useForm();

  const openNotification = (callback: { (): void; (): void }) => {
    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <Button type="link" size="small" onClick={() => api.destroy()}>
          Cancelar
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={() => {
            onHandleStepChange(5);
            callback();
          }}
        >
          Confirmar
        </Button>
      </Space>
    );
    api.open({
      message: "Atenção",
      description:
        'Ao alterar a proposta o PDF gerado anteriormente será perdido. Para gerar outro,deve-se finalizar a proposta no passo "Proposta"',
      btn,
      key,
      duration: 0,
      onClose: () => api.destroy(),
    });
  };
  const {
    mutate: fetchCreateStepIntermediate,
    isLoading: isLoadingCreateStepIntermediate,
  } = useFetchCreateCustomFieldValue({
    options: {
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: (data) => {
        onError(data);
      },
    },
  });

  async function handleSubmit(
    formData: IProposalAfterSizingStepFormInitialValues,
  ) {
    if (!proposalId) {
      throw new Error("Faltando o ID da negociação");
    }

    const parsedCustomFields: ProposalAfterSizingStepCustomField[] =
      await Promise.all(
        (formData.customFieldsIntermediateData || []).map(async (item) => {
          let parsedValue = item.value;

          if (item.type === "IMAGE" && item.file === null) {
            parsedValue = null;
          } else if (item.type === "IMAGE" && item.file) {
            parsedValue = (
              (await getBase64(item.file as RcFile)) as string
            ).replace(/^data:image\/\w+;base64,/, "");
          } else if (
            item.type === "DATE" &&
            item.value !== null &&
            item.value !== undefined
          ) {
            parsedValue = dayjs(item.value.toString()).format("DD/MM/YYYY");
          }
          return {
            ...item,
            value: parsedValue,
          };
        }),
      );

    const parseData = {
      intermediateDataCustomFieldValues:
        parsedCustomFields.map((item) => ({
          customFieldId: item.customFieldId ?? 0,
          value: item?.value?.toString(),
        })) ?? [],
      customId: proposalId,
    };

    fetchCreateStepIntermediate(parseData);
  }

  // PERSISTS DATA IN STORAGE
  const handlePersistsDataInStorage = useCallback(
    (data: IProposalAfterSizingStepFormInitialValues, dealId: number) => {
      try {
        const stringifiedData = JSON.stringify({ ...data, id: dealId });
        localStorage.setItem(AFTER_SIZING_STEP_STORAGE_KEY, stringifiedData);
      } catch (error: any) {
        console.warn(error?.message);
      }
    },
    [],
  );

  useInterval(() => {
    if (!proposalId) return;

    const formFields = form.getFieldsValue(true);
    const data = {
      intermediateDataCustomFieldValues:
        formFields?.intermediateDataCustomFieldValues?.map(
          (item: any, index: number) => {
            return item[index] ?? item;
          },
        ),
    };
    handlePersistsDataInStorage(data, proposalId);
  }, 3000); // 3 seconds

  return (
    <>
      <Form
        data-cy="proposal-intermediate-step-form"
        form={form}
        onFinish={() =>
          lastStep === 6
            ? openNotification(() => handleSubmit(form.getFieldsValue(true)))
            : handleSubmit(form.getFieldsValue(true))
        }
        onValuesChange={() => {
          localStorage.setItem(PROPOSAL_DATA_HAS_CHANGED, "true");
          dispatchEvent(new Event("storage"));
        }}
        layout="vertical"
        style={{ height: "100%" }}
        scrollToFirstError
      >
        <Layout
          style={{
            height: "100%",
            background: "transparent",
            padding: screens.xl ? 0 : "1rem 0rem",
            width: "100%",
            overflowY: "hidden",
          }}
        >
          {contextHolder}
          <Layout.Content
            style={{
              width: "100%",
              height: "100%",
              overflowY: "auto",
              overflowX: "hidden",
              padding: screens.xl ? "2rem" : "0 1rem 1rem 1rem",
            }}
          >
            <Row>
              <Col span={screens.md ? 11 : 24}>
                <Typography.Title level={4} style={{ margin: 0 }}>
                  Dados Pós-dimensionamento
                </Typography.Title>
                <ProposalCustomFieldsForm
                  initialValues={customFieldsInitialValues}
                  proposalSection="INTERMEDIATE_DATA"
                  pricingTypeId={proposalState?.pricingTypeId}
                  form={form}
                  instanceKey="IntermediateData"
                />
              </Col>
            </Row>
          </Layout.Content>
          <Layout.Footer
            style={{
              background: "var(--background-color)",
              padding: "0.5rem 2rem",
              width: "100%",
              height: "2.5rem",
              marginBottom: screens.xl ? "0rem" : "2rem",
            }}
          >
            <Flex justify="end" gap={16}>
              <Flex gap={12} justify="end">
                <Form.Item style={{ margin: 0 }}>
                  <Button type="link" onClick={onCancel} size="small">
                    <Flex align="center" gap={8}>
                      {!isLoadingCreateStepIntermediate && (
                        <AiOutlineArrowLeft size={18} />
                      )}
                      {cancelText}
                    </Flex>
                  </Button>
                </Form.Item>
                <Form.Item style={{ margin: 0 }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    data-cy="button-continue-proposal-generation"
                    loading={isLoadingCreateStepIntermediate}
                    icon={
                      !isLoadingCreateStepIntermediate && (
                        <AiOutlineArrowRight size={18} />
                      )
                    }
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.25rem",
                    }}
                  >
                    Prosseguir
                  </Button>
                </Form.Item>
              </Flex>
            </Flex>
          </Layout.Footer>
        </Layout>
      </Form>
    </>
  );
}
