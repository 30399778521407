import { IProfileUsersListProps } from "./interface";

import { Avatar } from "~components/Avatar";
import { Result } from "~components/Result";
import { useFetchGetUsersAssignedWithProfile } from "~hooks/api";
import { List, Skeleton } from "antd";

export function ProfileUsersList({
  profileId = 0,
  ...props
}: IProfileUsersListProps) {
  const {
    data: users = [],
    isFetching: isLoadingUsers,
    error: usersError,
    refetch: reloadUsers,
  } = useFetchGetUsersAssignedWithProfile({
    dependencyArray: [profileId],
    options: {
      retry: 1,
      enabled: Number(profileId) > 0,
    },
    payload: {
      profileId,
    },
  });

  return (
    <List
      {...props}
      data-cy="profile-users-list"
      rowKey="id"
      dataSource={users}
      locale={{
        emptyText: (
          <Result.TableErrorOrEmpty
            emptyMessage="Nenhum usuário encontrado"
            errorMessage={usersError?.message}
            loading={isLoadingUsers}
            reloadFn={reloadUsers}
          />
        ),
      }}
      renderItem={(user) => {
        return (
          <List.Item key={user.id} style={{ width: "100%" }}>
            <Skeleton loading={isLoadingUsers} active avatar>
              <List.Item.Meta
                title={user.name}
                avatar={
                  <Avatar.CustomUser
                    name={user.name}
                    avatarUrl={user.avatarUrl}
                    userId={user.id}
                    width={32}
                    height={32}
                  />
                }
                description={user.email}
              />
            </Skeleton>
          </List.Item>
        );
      }}
    />
  );
}
