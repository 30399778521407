import { CustomInput } from "~components/CustomInput";
import { useAppConfig } from "~hooks/useAppConfig";
import { Flex, Form } from "antd";

export function LowVoltageConcessionaireProfileForm() {
  const { screens } = useAppConfig();

  return (
    <>
      <Flex
        data-cy="low-voltage-concessionaire-profile-form"
        align="center"
        justify="space-between"
        wrap="nowrap"
        gap={16}
      >
        <Form.Item
          label="Tarifa TE"
          name="te"
          tooltip="Tarifa com imposto incluso"
          rules={[
            { required: true },
            {
              validator: (_rule, value) => {
                return value > 0
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error("Valor tem que ser maior que zero!"),
                    );
              },
            },
          ]}
          style={{ width: "100%" }}
        >
          <CustomInput.Money
            unit="R$/kWh"
            min={0}
            style={{ width: "100%" }}
            precision={6}
            placeholder="1,000000"
          />
        </Form.Item>
      </Flex>
      <Flex
        align="center"
        justify="space-between"
        wrap={screens.xl ? "nowrap" : "wrap"}
        gap={16}
      >
        <Form.Item
          label="Tarifa TUSD"
          tooltip="Tarifa com imposto incluso"
          name="tusd"
          rules={[
            { required: true },
            {
              validator: (_rule, value) => {
                return value > 0
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error("Valor tem que ser maior que zero!"),
                    );
              },
            },
          ]}
          style={{ width: "100%" }}
        >
          <CustomInput.Money
            unit="R$/kWh"
            min={0}
            style={{ width: "100%" }}
            precision={6}
            placeholder="1,000000"
          />
        </Form.Item>

        <Form.Item
          label="Tarifa de Fio B"
          name="wireBTax"
          tooltip="Tarifa com imposto incluso"
          rules={[
            { required: true },
            {
              validator: (_rule, value) => {
                return value > 0
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error("Valor tem que ser maior que zero!"),
                    );
              },
            },
          ]}
          style={{ width: "100%" }}
        >
          <CustomInput.Money
            unit="R$"
            min={0}
            style={{ width: "100%" }}
            precision={6}
            placeholder="1,000000"
          />
        </Form.Item>
      </Flex>
    </>
  );
}
