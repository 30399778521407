export * from "./ACMaterialForm/interface";
import { ACMaterialForm } from "./ACMaterialForm";
export * from "./ActivateDistributorKitFilter/interface";
import { ActivateDistributorKitFilterForm } from "./ActivateDistributorKitFilter";
export * from "./ActivityForm/interface";
import { ActivityForm } from "./ActivityForm";
export * from "./AdditionalCostMarginTypeForm/interface";
import { AdditionalCostMarginTypeForm } from "./AdditionalCostMarginTypeForm";
export * from "./AdditionalCostTaxForm/interface";
import { AdditionalCostTaxForm } from "./AdditionalCostTaxForm";
export * from "./AddPersonToOrganizationForm/interface";
import { AddPersonToOrganizationForm } from "./AddPersonToOrganizationForm";
export * from "./AddUserForm/interface";
import { AddUserForm } from "./AddUserForm";
export * from "./AdminCompanyConfigurationForm/interface";
import { AdminCompanyConfigurationForm } from "./AdminCompanyConfigurationForm";
export * from "./AdminForm/interface";
import { AdminForm } from "./AdminForm";
export * from "./AdminInverterForm/interface";
import { AdminInverterForm } from "./AdminInverterForm";
export * from "./AdminModuleForm/interface";
import { AdminModuleForm } from "./AdminModuleForm";
export * from "./AldoIntegrationForm/interface";
import { AldoIntegrationForm } from "./AldoIntegrationForm";
export * from "./BaseAddressForm/interface";
import { AssignVariableWithPipedriveForm } from "./AssignVariableWithPipedriveForm";
export * from "./AssignVariableWithPipedriveForm/interface";
import { AuxiliaryVariablesForm } from "./AuxiliaryVariablesForm";
export * from "./AuxiliaryVariablesForm/interface";
export * from "./BaseAddressForm/interface";
import { BaseAddressForm } from "./BaseAddressForm";
export * from "./BusinessUnitForm/interface";
import { BusinessUnitForm } from "./BusinessUnitForm";
export * from "./ChangeDealTotalValueTypeForm/interface";
import { ChangeDealTotalValueTypeForm } from "./ChangeDealTotalValueTypeForm";
export * from "./ChangePasswordForm/interface";
import { ChangePasswordForm } from "./ChangePasswordForm";
export * from "./ChangeProfileImageForm/interface";
import { ChangeProfileImageForm } from "./ChangeProfileImageForm";
export * from "./ChangeUserProfileForm/interface";
import { ChangeUserProfileForm } from "./ChangeUserProfileForm";
export * from "./ComplementaryItemForm/interface";
import { ComplementaryItemForm } from "./ComplementaryItemForm";
export * from "./ComplementaryItemsOfSettingsForm/interface";
import { ComplementaryItemsOfSettingsForm } from "./ComplementaryItemsOfSettingsForm";
export * from "./ComplementaryItemsSpreadsheetUploadForm/interface";
import { ComplementaryItemsSpreadsheetUploadForm } from "./ComplementaryItemsSpreadsheetUploadForm";
export * from "./ConcessionaireProfileForm/interface";
import { ConcessionaireProfileForm } from "./ConcessionaireProfileForm";
export * from "./ConsumerUnitsForm/interface";
import { ConsumerUnitsForm } from "./ConsumerUnitsForm";
export * from "./CopyBusinessUnitForm/interface";
import { CopyBusinessUnitForm } from "./CopyBusinessUnitForm";
export * from "./CostAggregatorForm/interface";
import { CostAggregatorForm } from "./CostAggregatorForm";
export * from "./UpdateProposalDealForm/interface";
export * from "./CustomPipedriveActivityMessageForm/interface";
import { CustomPipedriveActivityMessageForm } from "./CustomPipedriveActivityMessageForm";
export * from "./CustomVariableForm/interface";
import { CustomVariableForm } from "./CustomVariableForm";
export * from "./DatasheetForm/interface";
import { DatasheetForm } from "./DatasheetForm";
export * from "./DealCustomFieldGroupForm/interface";
import { DealCustomFieldGroupForm } from "./DealCustomFieldGroupForm";
export * from "./DealCustomFieldsForm/interface";
import { DealCustomFieldsForm } from "./DealCustomFieldsForm";
export * from "./DealCustomVariableForm/interface";
import { DealCustomVariableForm } from "./DealCustomVariableForm";
export * from "./DealDefaultDataFieldForm/interface";
import { DealDefaultFieldForm } from "./DealDefaultDataFieldForm";
export * from "./DealForm/interface";
import { DealForm } from "./DealForm";
export * from "./DeleteDealCustomFieldGroupForm/interface";
import { DeleteDealCustomFieldGroupForm } from "./DeleteDealCustomFieldGroupForm";
export * from "./DeleteIntegrationLinksForm/interface";
import { DeleteIntegrationLinksForm } from "./DeleteIntegrationLinksForm";
export * from "./DeletePipelineForm/interface";
import { DeletePipelineForm } from "./DeletePipelineForm";
export * from "./DeletePipelineStageForm/interface";
import { DeletePipelineStageForm } from "./DeletePipelineStageForm";
export * from "./DiscountKitItemsForm/interface";
import { DiscountKitItemsForm } from "./DiscountKitItemsForm";
export * from "./DiverseServiceDealForm/interface";
import { DiverseServiceDealForm } from "./DiverseServiceDealForm";
export * from "./EdeltecIntegrationForm/interface";
import { EdeltecIntegrationForm } from "./EdeltecIntegrationForm";
export * from "./EmptyKitForm/interface";
import { EmptyKitForm } from "./EmptyKitForm";
export * from "./EosFinancingOrganizationFinancingDataForm/interface";
import { EosFinancingOrganizationFinancingDataForm } from "./EosFinancingOrganizationFinancingDataForm";
export * from "./EosFinancingOrganizationGuarantorDataForm/interface";
import { EosFinancingOrganizationGuarantorDataForm } from "./EosFinancingOrganizationGuarantorDataForm";
export * from "./EosFinancingOrganizationProjectDataForm/interface";
import { EosFinancingOrganizationProjectDataForm } from "./EosFinancingOrganizationProjectDataForm";
export * from "./EosFinancingPersonFinancingDataForm/interface";
import { EosFinancingPersonFinancingDataForm } from "./EosFinancingPersonFinancingDataForm";
export * from "./EosFinancingPersonProjectDataForm/interface";
import { EosFinancingPersonProjectDataForm } from "./EosFinancingPersonProjectDataForm";
export * from "./GTSolarIntegrationForm/interface";
import { GTSolarIntegrationForm } from "./GTSolarIntegrationForm";
export * from "./ImportUserFromPipedriveForm/interface";
import { ImportUserFromPipedriveForm } from "./ImportUserFromPipedriveForm";
export * from "./KitItemForm/interface";
import { KitItemForm } from "./KitItemForm";
export * from "./KitItemMultipleForm/interface";
import { KitItemMultipleForm } from "./KitItemMultipleForm";
export * from "./KomecoIntegrationForm/interface";
import { KomecoIntegrationForm } from "./KomecoIntegrationForm";
export * from "./LaborForm/interface";
import { LaborForm } from "./LaborForm";
export * from "./LaborStructuresForm/interface";
import { LaborStructuresForm } from "./LaborStructuresForm";
export * from "./LoginForm/interface";
import { LoginForm } from "./LoginForm";
export * from "./LostDealForm/interface";
import { LostDealForm } from "./LostDealForm";
import { LowVoltageConcessionaireProfileForm } from "./LowVoltageConcessionaireProfileForm";
import { MediumVoltageConcessionaireProfileForm } from "./MediumVoltageConcessionaireProfileForm";
export * from "./MonthlyForm/interface";
import { MonthlyForm } from "./MonthlyForm";
export * from "./OrganizationDefaultFieldForm/interface";
import { OrganizationDefaultFieldForm } from "./OrganizationDefaultFieldForm";
export * from "./OrganizationForm/interface";
import { OrganizationForm } from "./OrganizationForm";
export * from "./PaymentSimulationOptionsForm/interface";
import { PaymentSimulationOptionsForm } from "./PaymentSimulationOptionsForm";
export * from "./PerformanceProfileForm/interface";
import { PerformanceProfileForm } from "./PerformanceProfileForm";
export * from "./PerformanceRateMonthForm/interface";
import { PerformanceRateMonthForm } from "./PerformanceRateMonthForm";
export * from "./PermissionsProfileForm/interface";
import { PermissionsProfileForm } from "./PermissionsProfileForm";
export * from "./PersonCustomDataFieldForm/interface";
import { PersonCustomDataFieldForm } from "./PersonCustomDataFieldForm";
export * from "./PersonDefaultDataFieldForm/interface";
import { PersonDefaultDataFieldForm } from "./PersonDefaultDataFieldForm";
export * from "./PersonForm/interface";
import { PersonForm } from "./PersonForm";
import { PipedriveActivitiesForm } from "./PipedriveActivitiesForm";
export * from "./PricingForm/interface";
import { PricingForm } from "./PricingForm";
export * from "./ProductTypeOtherForm/interface";
import { ProductTypeOtherForm } from "./ProductTypeOtherForm";
export * from "./ProfileForm/interface";
import { ProfileForm } from "./ProfileForm";
export * from "./ProposalAfterSizingStepForm/interface";
import { ProposalAfterSizingStepForm } from "./ProposalAfterSizingStepForm";
export * from "./ProposalCustomDataFieldForm/interface";
import { ProposalCustomDataFieldForm } from "./ProposalCustomDataFieldForm";
export * from "./ProposalCustomFieldForm/interface";
import { ProposalCustomFieldForm } from "./ProposalCustomFieldForm";
export * from "./ProposalDataFieldProfileSettingsForm/interface";
import { ProposalDataFieldProfileSettingsForm } from "./ProposalDataFieldProfileSettingsForm";
export * from "./ProposalDataStepForm/interface";
import { ProposalDataStepForm } from "./ProposalDataStepForm";
export * from "./ProposalDefaultDataFieldForm/interface";
import { ProposalDefaultDataFieldForm } from "./ProposalDefaultDataFieldForm";
export * from "./ProposalDefaultDataFieldProfileSettingsForm/interface";
import { ProposalDefaultDataFieldProfileSettingsForm } from "./ProposalDefaultDataFieldProfileSettingsForm";
export * from "./ProposalExpirationRuleForm/interface";
import { ProposalExpirationRuleForm } from "./ProposalExpirationRuleForm";
export * from "./ProposalPaymentStepForm/interface";
import { ProposalPaymentStepForm } from "./ProposalPaymentStepForm";
export * from "./ProposalSizingStepForm/interface";
import { ProposalSizingStepForm } from "./ProposalSizingStepForm";
export * from "./ReasonForLossForm/interface";
import { ReasonForLossForm } from "./ReasonForLossForm";
export * from "./ResetPasswordForm/interface";
import { ResetPasswordForm } from "./ResetPasswordForm";
export * from "./SelectConcessionaireProfileForm/interface";
import { SelectConcessionaireProfileForm } from "./SelectConcessionaireProfileForm";
export * from "./SendProposalWhatsAppMessageForm/interface";
import { SendProposalWhatsAppMessageForm } from "./SendProposalWhatsAppMessageForm";
export * from "./SendRecoverPasswordForm/interface";
import { SendRecoverPasswordForm } from "./SendRecoverPasswordForm";
export * from "./SendTemplateForm/interface";
import { SendTemplateForm } from "./SendTemplateForm";
export * from "./SolarServiceDealForm/interface";
export * from "./SimultaneityProfileForm/interface";
import { SimultaneityProfileForm } from "./SimultaneityProfileForm";
export * from "./SizingKitForm/interface";
import { SizingKitForm } from "./SizingKitForm";
export * from "./SolarimetryForm/interface";
import { SolarimetryForm } from "./SolarimetryForm";
import { SolarServiceDealForm } from "./SolarServiceDealForm";
export * from "./StructureDistributorsForm/interface";
import { StructureDistributorsForm } from "./StructureDistributorsForm";
export * from "./TransformerGroupForm/interface";
import { TransformerGroupForm } from "./TransformerGroupForm";
export * from "./TransformerGroupRuleForm/interface";
import { TransformerGroupRuleForm } from "./TransformerGroupRuleForm";
export * from "./UserForm/interface";
import { UpdatePipedriveTokenApiForm } from "./UpdatePipedriveTokenApiForm";
import { UpdateProposalDealForm } from "./UpdateProposalDealForm";
export * from "./UpdatePipedriveTokenApiForm/interface";
export * from "./UsableAreaRoofTypeForm/interface";
import { UsableAreaRoofTypeForm } from "./UsableAreaRoofTypeForm";
import { UserForm } from "./UserForm";
export * from "./UserGroupForm/interface";
import { UserGroupForm } from "./UserGroupForm";
export * from "./UserSettingsForm/interface";
import { UserSettingsForm } from "./UserSettingsForm";
export * from "./ValidationRuleForm/interface";
import { ValidationRuleForm } from "./ValidationRuleForm";
export * from "./WegIntegrationForm/interface";
import { WegIntegrationForm } from "./WegIntegrationForm";
export * from "./GeneralInformationForm/interface";
import { GeneralInformationForm } from "./GeneralInformationForm";

export const Form = {
  Admin: AdminForm,
  ACMaterial: ACMaterialForm,
  Activity: ActivityForm,
  ActivateDistributorKitFilter: ActivateDistributorKitFilterForm,
  AdditionalCostMarginType: AdditionalCostMarginTypeForm,
  AdditionalCostTax: AdditionalCostTaxForm,
  AddPersonToOrganization: AddPersonToOrganizationForm,
  AddUser: AddUserForm,
  AdminCompanyConfiguration: AdminCompanyConfigurationForm,
  AdminInverter: AdminInverterForm,
  AdminModule: AdminModuleForm,
  AldoIntegration: AldoIntegrationForm,
  AssignVariableWithPipedrive: AssignVariableWithPipedriveForm,
  AuxiliaryVariables: AuxiliaryVariablesForm,
  BaseAddress: BaseAddressForm,
  BusinessUnit: BusinessUnitForm,
  ChangePassword: ChangePasswordForm,
  ChangeProfileImage: ChangeProfileImageForm,
  ChangeUserProfile: ChangeUserProfileForm,
  DealDefaultField: DealDefaultFieldForm,
  PersonCustomDataField: PersonCustomDataFieldForm,
  PersonDefaultDataField: PersonDefaultDataFieldForm,
  Person: PersonForm,
  ComplementaryItem: ComplementaryItemForm,
  ComplementaryItemsOfSettings: ComplementaryItemsOfSettingsForm,
  ComplementaryItemsSpreadsheetUpload: ComplementaryItemsSpreadsheetUploadForm,
  ConcessionaireProfile: ConcessionaireProfileForm,
  ConsumerUnits: ConsumerUnitsForm,
  CopyBusinessUnit: CopyBusinessUnitForm,
  CostAggregator: CostAggregatorForm,
  CustomPipedriveActivityMessage: CustomPipedriveActivityMessageForm,
  CustomVariable: CustomVariableForm,
  ChangeDealTotalValueType: ChangeDealTotalValueTypeForm,
  DealCustomFields: DealCustomFieldsForm,
  Deal: DealForm,
  DeleteDealCustomFieldGroup: DeleteDealCustomFieldGroupForm,
  DeletePipeline: DeletePipelineForm,
  DeletePipelineStage: DeletePipelineStageForm,
  Datasheet: DatasheetForm,
  DealCustomFieldGroup: DealCustomFieldGroupForm,
  DealCustomVariable: DealCustomVariableForm,
  DeleteIntegrationLinks: DeleteIntegrationLinksForm,
  DiscountKitItems: DiscountKitItemsForm,
  DiverseServiceDeal: DiverseServiceDealForm,
  EdeltecIntegration: EdeltecIntegrationForm,
  EmptyKit: EmptyKitForm,
  EosFinancingOrganizationGuarantorData:
    EosFinancingOrganizationGuarantorDataForm,
  EosFinancingOrganizationProjectData: EosFinancingOrganizationProjectDataForm,
  EosFinancingOrganizationFinancingData:
    EosFinancingOrganizationFinancingDataForm,
  EosFinancingPersonFinancingData: EosFinancingPersonFinancingDataForm,
  EosFinancingPersonProjectData: EosFinancingPersonProjectDataForm,
  GTSolarIntegration: GTSolarIntegrationForm,
  GeneralInformation: GeneralInformationForm,
  ImportUserFromPipedrive: ImportUserFromPipedriveForm,
  KitItemMultiple: KitItemMultipleForm,
  KomecoIntegration: KomecoIntegrationForm,
  Labor: LaborForm,
  LaborStructures: LaborStructuresForm,
  Login: LoginForm,
  LostDeal: LostDealForm,
  LowVoltageConcessionaireProfile: LowVoltageConcessionaireProfileForm,
  UserSettings: UserSettingsForm,
  MediumVoltageConcessionaireProfile: MediumVoltageConcessionaireProfileForm,
  Monthly: MonthlyForm,
  Organization: OrganizationForm,
  OrganizationDefaultField: OrganizationDefaultFieldForm,
  Profile: ProfileForm,
  ProposalAfterSizingStep: ProposalAfterSizingStepForm,
  KitItem: KitItemForm,
  PaymentSimulationOptions: PaymentSimulationOptionsForm,
  PerformanceProfile: PerformanceProfileForm,
  PerformanceRateMonth: PerformanceRateMonthForm,
  PermissionsProfile: PermissionsProfileForm,
  PipedriveActivities: PipedriveActivitiesForm,
  Pricing: PricingForm,
  ProductTypeOther: ProductTypeOtherForm,
  ProposalCustomDataField: ProposalCustomDataFieldForm,
  ProposalCustomField: ProposalCustomFieldForm,
  ProposalDataFieldProfileSettings: ProposalDataFieldProfileSettingsForm,
  ProposalDataStep: ProposalDataStepForm,
  ProposalDefaultDataField: ProposalDefaultDataFieldForm,
  ProposalDefaultDataFieldProfileSettings:
    ProposalDefaultDataFieldProfileSettingsForm,
  ProposalExpirationRule: ProposalExpirationRuleForm,
  ProposalPaymentStep: ProposalPaymentStepForm,
  ProposalSizingStep: ProposalSizingStepForm,
  ReasonForLoss: ReasonForLossForm,
  ResetPassword: ResetPasswordForm,
  SelectConcessionaireProfile: SelectConcessionaireProfileForm,
  SendProposalWhatsAppMessage: SendProposalWhatsAppMessageForm,
  SendRecoverPassword: SendRecoverPasswordForm,
  SendTemplate: SendTemplateForm,
  SolarServiceDeal: SolarServiceDealForm,
  SimultaneityProfile: SimultaneityProfileForm,
  SizingKit: SizingKitForm,
  Solarimetry: SolarimetryForm,
  StructureDistributors: StructureDistributorsForm,
  TransformerGroup: TransformerGroupForm,
  TransformerGroupRule: TransformerGroupRuleForm,
  UpdatePipedriveTokenApi: UpdatePipedriveTokenApiForm,
  UsableAreaRoofType: UsableAreaRoofTypeForm,
  User: UserForm,
  UserGroup: UserGroupForm,
  ValidationRule: ValidationRuleForm,
  WegIntegration: WegIntegrationForm,
  UpdateProposalDeal: UpdateProposalDealForm,
};
