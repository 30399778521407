import { useState } from "react";

import type {
  IConcessionaireProfileFormValues,
  IConcessionaireProfileFormProps,
  CurrentValueProfileType,
} from "./interface";

import { CustomInput } from "~components/CustomInput";
import { Form as FormComponents } from "~components/Form";
import { Select } from "~components/Select";
import {
  useFetchCreateConcessionaireProfile,
  useFetchUpdateConcessionaireProfile,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import type {
  IDataLowVoltageDTOSchema,
  IDataMediumVoltageDTOSchema,
} from "~types/schemas";
import { Button, Divider, Form, Select as SelectAntd, Spin, Flex } from "antd";

export function ConcessionaireProfileForm({
  initialValues,
  onSuccess = () => undefined,
  onError = () => undefined,
  onCancel = () => undefined,
}: IConcessionaireProfileFormProps) {
  const { screens } = useAppConfig();
  const [form] = Form.useForm<IConcessionaireProfileFormValues>();

  const [currentValueProfileType, setCurrentValueProfileType] = useState<
    CurrentValueProfileType | undefined
  >(initialValues?.voltageType);

  function parsedData(values: IConcessionaireProfileFormValues) {
    if (values.voltageType === "LOW") {
      const body = {
        name: values.name,
        lowVoltage: {
          tusd: values.tusd ?? 0,
          te: values.te ?? 0,
          wireBTax: values.wireBTax ?? 0,
          active: true,
        } as IDataLowVoltageDTOSchema,
        voltageType: values.voltageType,
        electricUtilityId: values.electricUtilityId,
      };
      return body;
    } else {
      const body = {
        name: values.name,
        mediumVoltage: {
          tusdFP: values.tusdFP ?? 0,
          teFP: values.teFP ?? 0,
          tusdP: values.tusdP ?? 0,
          teP: values.teP ?? 0,
          wireBTaxP: values.wireBTaxP ?? 0,
          wireBTaxFP: values.wireBTaxFP ?? 0,
          active: true,
          averageConsumptionFP: 0,
          averageConsumptionP: 0,
        } as IDataMediumVoltageDTOSchema,
        voltageType: values.voltageType,
        electricUtilityId: values.electricUtilityId,
      };
      return body;
    }
  }

  const {
    mutate: createConcessionaireProfile,
    isLoading: isCreatingConcessionaireProfile,
  } = useFetchCreateConcessionaireProfile({
    options: {
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: (error) => {
        onError(error.message);
      },
    },
  });

  const {
    mutate: updateConcessionaireProfile,
    isLoading: isUpdatingConcessionaireProfile,
  } = useFetchUpdateConcessionaireProfile({
    options: {
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: (error) => {
        onError(error.message);
      },
    },
  });

  function handleSubmit(data: IConcessionaireProfileFormValues) {
    const body = parsedData(data);

    const concessionaireProfileId = initialValues?.id;

    if (concessionaireProfileId) {
      updateConcessionaireProfile({
        ...body,
        concessionaireProfileId:
          body.electricUtilityId || concessionaireProfileId,
        id: concessionaireProfileId,
      });
    } else {
      createConcessionaireProfile(body);
    }
  }
  const isLoading =
    isCreatingConcessionaireProfile || isUpdatingConcessionaireProfile;

  return (
    <Spin
      spinning={isLoading}
      tip="Carregando..."
      style={{ marginTop: "-2rem" }}
      data-cy="concessionaire-profile-form"
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={initialValues}
      >
        <Flex
          align="center"
          justify="space-between"
          wrap={screens.xl ? "nowrap" : "wrap"}
          gap={screens.xl ? 16 : 0}
        >
          <Form.Item
            name="name"
            label="Nome do perfil"
            rules={[{ required: true }]}
            style={{ width: "100%" }}
          >
            <CustomInput.Text
              placeholder="Ex.: Perfil de teste"
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            name="electricUtilityId"
            label="Concessionária"
            rules={[{ required: true }]}
            style={{ width: "100%" }}
          >
            <Select.Concessionaire
              style={{ width: screens.xl ? 250 : "100%" }}
            />
          </Form.Item>
          <Form.Item
            label="Tipo de voltagem"
            rules={[{ required: true }]}
            name="voltageType"
            tooltip="Define o tipo de formulário para o perfil"
            style={{ width: "100%" }}
          >
            <SelectAntd
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              placeholder="Selecione uma opção ..."
              style={{ width: "100%" }}
              options={[
                { value: "LOW", label: "Baixa tensão" },
                { value: "MEDIUM", label: "Média tensão" },
              ]}
              onSelect={(value: any) => {
                const parsedValue = value as CurrentValueProfileType;
                setCurrentValueProfileType(parsedValue);
              }}
            />
          </Form.Item>
        </Flex>
        {currentValueProfileType === "LOW" && (
          <FormComponents.LowVoltageConcessionaireProfile />
        )}
        {currentValueProfileType === "MEDIUM" && (
          <FormComponents.MediumVoltageConcessionaireProfile />
        )}

        <Divider style={{ margin: "0 0 0.5rem 0" }} />

        <Flex gap={12} justify="end" style={{ marginTop: "1.5rem" }}>
          <Form.Item style={{ margin: 0 }}>
            <Button onClick={onCancel}>Cancelar</Button>
          </Form.Item>
          <Form.Item style={{ margin: 0 }}>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Salvar
            </Button>
          </Form.Item>
        </Flex>
      </Form>
    </Spin>
  );
}
