import type { IProposalDataFieldProfileSettingsFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function ProposalDataFieldProfileSettingsFormModal({
  isOpen = false,
  onClose = () => null,
  width = 890,
  formProps = {
    initialValues: undefined,
  },
}: IProposalDataFieldProfileSettingsFormModalProps) {
  return (
    <Modal
      data-cy="proposal-data-field-profile-settings-form-modal"
      open={isOpen}
      onCancel={onClose}
      title={<span style={{ marginLeft: 16 }}>Edição de campo por perfil</span>}
      width={width}
      style={{ maxWidth: "90vw" }}
      footer={null}
      destroyOnClose
    >
      <Form.ProposalDataFieldProfileSettings
        {...formProps}
        onCancel={onClose}
      />
    </Modal>
  );
}
