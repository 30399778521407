import { useMemo } from "react";
import { BiErrorCircle } from "react-icons/bi";
import { FaChevronDown } from "react-icons/fa";

import type {
  ISolarzSelectProps,
  SolarzSelectOnChangeRecordType,
  SolarzSelectOptionType,
} from "./interface";
import { StyledSelect } from "./styles";

import { Button, Empty, Flex, Spin, Tag, Typography } from "antd";

export function SolarzSelect<
  RecordType extends Record<string, any>,
  RecordKey extends keyof RecordType,
>(props: ISolarzSelectProps<RecordType, RecordKey>) {
  const OPTIONS = useMemo(() => {
    const options = (props.options ?? []) as SolarzSelectOptionType<
      RecordType,
      RecordType[RecordKey] extends Array<any>
        ? RecordType[RecordKey][number]
        : RecordType[RecordKey] | any
    >[];

    const filteredOptions = options.filter((option) => !option.hidden);

    return filteredOptions.map((option) => ({
      label: option.label,
      value: option.value,
      children: undefined,
      record: option.record,
      disabled: option.disabled,
    }));
  }, [props.options]);

  const SUFFIX_ICON = useMemo(() => {
    if (props.isLoading) return <Spin size="small" spinning />;

    if (props.errorMessage) return <BiErrorCircle size={18} />;

    return <FaChevronDown />;
  }, [props.isLoading, props.errorMessage]);

  function onClear() {
    if (props.onChange) {
      props.onChange(null, null);
    }
  }

  return (
    <StyledSelect
      {...props}
      id={props.id}
      onFocus={(event: any) => {
        if (event.target && !props.disableBrowserAutoComplete) {
          event.target.autocomplete = "nope";
        }
      }}
      autoClearSearchValue={props.autoClearSearchValue ?? true}
      filterOption={false}
      options={OPTIONS}
      allowClear={props.allowClear ?? true}
      onClear={onClear}
      loading={props.isLoading}
      defaultValue={props.defaultValue}
      value={props.value}
      disabled={props.isDisabled}
      scale={props.scale ?? "medium"}
      style={props.style}
      placeholder={props.placeholder}
      status={props.errorMessage ? "error" : ""}
      suffixIcon={SUFFIX_ICON}
      showSearch={props.showSearch}
      searchValue={props.searchValue}
      mode={props.mode}
      tagRender={(props) => {
        return (
          <Tag className="solarz-select-tag" onClose={props.onClose} closable>
            {props.label}
          </Tag>
        );
      }}
      onChange={(value, record) => {
        if (!props.onChange) return;

        if (!value) {
          return props.onChange(null, null);
        }

        // WHEN IS ARRAY OF RECORDS | WHEN IS MODE MULTI OR TAGS
        if (record instanceof Array) {
          const parsedRecord = record.map((item) => ({
            value: item.value,
            label: item.label,
            record: item.record!,
          })) as SolarzSelectOnChangeRecordType<RecordType, RecordKey>[];

          return props.onChange(value, parsedRecord);
        }

        const parsedRecord = {
          value: record.value,
          label: record.label,
          record: record.record!,
        } as SolarzSelectOnChangeRecordType<RecordType, RecordKey>;

        props.onChange(value, parsedRecord);
      }}
      onSearch={(value: string) => {
        if (typeof props.onChangeSearch === "function") {
          props.onChangeSearch(value);
        }
      }}
      notFoundContent={
        <Flex justify="center" align="center">
          <Empty
            description={
              <Flex vertical>
                {!props.isLoading && (
                  <Typography.Text
                    type={props.errorMessage ? "danger" : "secondary"}
                    style={{ margin: 0 }}
                  >
                    {props.errorMessage ?? props.emptyMessage}
                  </Typography.Text>
                )}
                {props.refetchFn && (
                  <Button
                    type="link"
                    style={{ margin: 0, padding: 0, marginBottom: "0.5rem" }}
                    onClick={props.refetchFn}
                    disabled={props.isLoading || props.isRefetchDisabled}
                  >
                    {props.isLoading ? (
                      <Flex align="center" gap={8}>
                        <Spin size="small" />{" "}
                        <span style={{ color: "var(--primary-500)" }}>
                          Carregando...
                        </span>
                      </Flex>
                    ) : (
                      "Carregar novamente?"
                    )}
                  </Button>
                )}
              </Flex>
            }
            imageStyle={{
              width: 98,
              margin: "0 auto",
            }}
          />
        </Flex>
      }
      dropdownRender={props.dropdownRender}
      labelRender={props.labelRender}
    />
  );
}
