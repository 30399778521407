import styled from "@emotion/styled";
import { Input } from "antd";

export const StyledInputPassword = styled(Input.Password)`
  width: 100%; // 292px
  height: 3rem;
  font-size: 1rem;
  padding: 0.75rem 1rem;
  line-height: 18px;

  svg {
    color: var(--gray-400);
  }
`;
