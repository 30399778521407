import { ReactElement } from "react";

import Link from "next/link";
import { useRouter } from "next/router";

import { DealBreadcrumb } from "./DealBreadcrumb";
import { OrganizationBreadcrumb } from "./OrganizationBreadcrumb";
import { PersonBreadcrumb } from "./PersonBreadcrumb";
import { StyledBreadcrumb } from "./styles";

import { ArrowRight } from "~estrela-ui/icons";
import { parseCapitalized } from "~utils/parse";
import { parseUrlPathToAccentedWord } from "~utils/parseUrlPathToAccentedWord";
import { ItemType } from "antd/lib/breadcrumb/Breadcrumb";

function clearString(text: string) {
  const formattedText = text.replaceAll("/", "").replaceAll("-", " ");
  const capitalizedText = parseCapitalized(formattedText);

  return parseUrlPathToAccentedWord(capitalizedText);
}

function pathNameCanBeUrl(url = "") {
  switch (url) {
    case "/configuracoes-gerais":
      return false;
    case "/configuracoes-de-propostas":
      return false;
    case "/clientes/empresa":
      return false;
    case "/clientes/pessoa":
      return false;
    case "/funil/editar":
      return false;
    case "/funil/criar":
      return false;
    case "/funil/negocio":
      return false;
    default:
      return true;
  }
}

const OrganizationPagePattern = new RegExp("^/clientes/empresa/\\d+(\\?.*)?$");
const PersonPagePattern = new RegExp("^/clientes/pessoa/\\d+(\\?.*)?$");
const DealPagePattern = new RegExp("^/funil/negocio/\\d+(\\?.*)?$");

function checkIsCustomBreadCrumb(route?: string) {
  if (!route || route === "/") return undefined;

  if (OrganizationPagePattern.test(route)) {
    return "ORGANIZATION";
  }

  if (PersonPagePattern.test(route)) {
    return "PERSON";
  }

  if (DealPagePattern.test(route)) {
    return "DEAL";
  }

  return undefined;
}

const CustomBreadCrumbs = {
  ORGANIZATION: <OrganizationBreadcrumb />,
  PERSON: <PersonBreadcrumb />,
  DEAL: <DealBreadcrumb />,
} as Record<string, ReactElement>;

export function LoggedUserBreadcrumb() {
  const router = useRouter();

  const isCustomBreadCrumb = checkIsCustomBreadCrumb(router?.asPath);

  if (isCustomBreadCrumb) {
    return CustomBreadCrumbs[isCustomBreadCrumb];
  }

  const routes = router?.asPath
    .split("?")[0]
    .split("/")
    .filter((route) => {
      return route;
    })
    .map((route) => `/${route}`);

  const items =
    routes?.length > 0
      ? (routes.map((route, index, allRoutes) => {
          const href = routes.slice(0, index + 1).join("");

          return {
            title:
              allRoutes.length > 1 && pathNameCanBeUrl(href) ? (
                <Link href={href}>{clearString(route)}</Link>
              ) : (
                <span>{clearString(route)}</span>
              ),
            className: routes.length - 1 === index ? "active" : null,
          };
        }) as ItemType[])
      : ([{ title: <span>Propostas</span> }] as ItemType[]);

  return (
    <StyledBreadcrumb
      data-cy="logged-user-breadcrumb"
      separator={<ArrowRight className="h-3 w-3" />}
      items={items}
    />
  );
}
