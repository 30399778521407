import { useMutation } from "react-query";

import type { IUseFetchUpdateProposalDeal } from "./interface";

import { fetchUpdateProposalDeal } from "~api/proposal/pipedrive/pipelines/deals/put";
import { useAppConfig } from "~hooks/useAppConfig";

export const useFetchUpdateProposalDealKey =
  "api/proposal/pipedrive/pipelines/deals/put";

export function useFetchUpdateProposalDeal({
  options,
}: IUseFetchUpdateProposalDeal) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchUpdateProposalDeal(payload),
    {
      ...options,
      mutationKey: useFetchUpdateProposalDealKey,
      onSuccess: (data, variables, context) => {
        message.success("O negócio da proposta foi alterado!");
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao definir negócio da proposta: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
