import { useEffect } from "react";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { IoReload } from "react-icons/io5";

import type {
  IAdminInverterFormData,
  IAdminInverterFormProps,
} from "./interface";
import { StyledButton } from "./styles";

import { CustomInput } from "~components/CustomInput";
import { Select } from "~components/Select";
import {
  useFetchCreateAdminInverters,
  useFetchGetAdminInvertersById,
  useFetchUpdateAdminInverters,
} from "~hooks/api";
import { SolarzButton } from "~solarzui/SolarzButton";
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Flex,
  Form,
  Row,
  Spin,
  Tooltip,
} from "antd";

export function AdminInverterForm({
  id = 0,
  formInstance,
  initialValues,
  onCancel,
  onError,
  onSuccess,
}: IAdminInverterFormProps) {
  const [form] = Form.useForm<IAdminInverterFormData>(formInstance);

  const {
    data: adminInverter,
    isFetching: isLoadingAdminInverter,
    error: adminInverterError,
    refetch: reloadAdminInverter,
  } = useFetchGetAdminInvertersById({
    payload: {
      id: id,
    },
    options: {
      enabled: id > 0,
      retry: 1,
    },
  });

  const { mutate: updateAdminInverters, isLoading: isUpdatingAdminInverters } =
    useFetchUpdateAdminInverters({
      options: {
        onSuccess,
        onError,
      },
    });

  const { mutate: createAdminInverters, isLoading: isCreatingAdminInverters } =
    useFetchCreateAdminInverters({
      options: {
        onSuccess,
        onError,
      },
    });

  function handleSubmit(formData: IAdminInverterFormData) {
    return id > 0
      ? updateAdminInverters({
          ...formData,
          id,
        })
      : createAdminInverters(formData);
  }

  function handleCancel() {
    form.setFieldsValue({
      ...initialValues,
      ...adminInverter,
    });

    if (typeof onCancel === "function") {
      onCancel();
    }
  }

  useEffect(() => {
    if (adminInverter) {
      form.setFieldsValue({
        ...adminInverter,
        datasheetId: adminInverter?.datasheet?.id || undefined,
      });
    }
  }, [form, adminInverter]);

  return (
    <Form
      data-cy="admin-inverter-form"
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={initialValues}
    >
      {adminInverterError && (
        <Alert
          type="error"
          message="Falha ao carregar dados iniciais"
          description={adminInverterError.message}
          style={{ marginBottom: "1rem", marginTop: "1rem" }}
          action={
            <Tooltip title="Tentar recarregar dados">
              <Button
                onClick={() => reloadAdminInverter()}
                icon={<IoReload strokeWidth={4} size={18} />}
              />
            </Tooltip>
          }
        />
      )}

      <Spin spinning={isLoadingAdminInverter}>
        <Form.Item<IAdminInverterFormData>
          name="identifier"
          label="Identificador"
          rules={[{ required: true, message: "Este campo é obrigatório" }]}
        >
          <CustomInput.Text size="large" />
        </Form.Item>

        <Row gutter={16}>
          <Col span={24} md={12}>
            <Form.Item<IAdminInverterFormData>
              name="model"
              label="Modelo"
              rules={[{ required: true, message: "Este campo é obrigatório" }]}
            >
              <CustomInput.Text size="large" />
            </Form.Item>
          </Col>

          <Col span={24} md={12}>
            <Form.Item<IAdminInverterFormData>
              name="manufacturer"
              label="Fabricante"
              rules={[{ required: true, message: "Este campo é obrigatório" }]}
            >
              <CustomInput.Text size="large" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24} md={12}>
            <Form.Item<IAdminInverterFormData>
              name="nominalPower"
              label="Potência nominal"
              rules={[{ required: true, message: "Este campo é obrigatório" }]}
            >
              <CustomInput.UnitMeasurement
                precision="0"
                unitMeasurement="KWp"
                min={0}
                size="large"
              />
            </Form.Item>
          </Col>

          <Col span={24} md={12}>
            <Form.Item<IAdminInverterFormData>
              name="gridType"
              label="Tipo do grid"
              rules={[{ required: true, message: "Este campo é obrigatório" }]}
            >
              <Select.GridType size="large" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24} md={12}>
            <Form.Item<IAdminInverterFormData>
              name="type"
              label="Tipo do inversor"
              rules={[{ required: true, message: "Este campo é obrigatório" }]}
            >
              <Select.InverterTypeSelect size="large" />
            </Form.Item>
          </Col>

          <Col span={24} md={12}>
            <Form.Item<IAdminInverterFormData>
              name="phase"
              label="Fase"
              rules={[{ required: true, message: "Este campo é obrigatório" }]}
            >
              <Select.Phase size="large" />
            </Form.Item>
          </Col>
        </Row>

        <Divider style={{ marginTop: 0, marginBottom: "1rem" }} />

        <Row gutter={16}>
          <Col span={24} md={12}>
            <Form.Item<IAdminInverterFormData>
              name="maxPower"
              label="Potência máxima"
            >
              <CustomInput.UnitMeasurement
                precision="2"
                unitMeasurement="KWp"
                min={0}
                size="large"
              />
            </Form.Item>
          </Col>

          <Col span={24} md={12}>
            <Form.Item<IAdminInverterFormData>
              name="overload"
              label="Sobrecarga"
            >
              <CustomInput.UnitMeasurement
                precision="2"
                unitMeasurement="KWp"
                min={0}
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24} md={8}>
            <Form.Item<IAdminInverterFormData>
              name="outputVoltage"
              label="Tensão de saída"
            >
              <Select.OutputVoltage size="large" />
            </Form.Item>
          </Col>

          <Col span={24} md={8}>
            <Form.Item<IAdminInverterFormData>
              name="startupVoltage"
              label="Tensão mínima"
            >
              <CustomInput.UnitMeasurement
                precision="2"
                unitMeasurement="V"
                min={0}
                size="large"
              />
            </Form.Item>
          </Col>

          <Col span={24} md={8}>
            <Form.Item<IAdminInverterFormData>
              name="maxInputVoltagePerMppt"
              label="Tensão máxima"
            >
              <CustomInput.UnitMeasurement
                precision="2"
                unitMeasurement="V"
                min={0}
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24} md={12}>
            <Form.Item<IAdminInverterFormData>
              name="warrantyInYears"
              label="Garantia em dias"
            >
              <CustomInput.Number precision={0} min={0} size="large" />
            </Form.Item>
          </Col>

          <Col span={24} md={12}>
            <Form.Item<IAdminInverterFormData>
              name="datasheetId"
              label="Linkar ao Datasheet"
            >
              <Select.DatasheetSearch
                placeholder="Selecione o Datasheet"
                initialRecord={adminInverter?.datasheet}
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider
          style={{ marginTop: 0, marginBottom: 16, fontSize: 14 }}
          orientation="left"
        >
          Strings por mppt
        </Divider>

        <Form.List name="details">
          {(fields, { add, remove }) => (
            <Flex
              vertical
              wrap="nowrap"
              style={{
                overflowX: "hidden",
                maxHeight: 320,
                overflowY: "auto",
              }}
              gap={12}
            >
              {fields.map(({ key, name, ...restField }) => {
                return (
                  <Card
                    key={key}
                    title={`MPPT ${name + 1}`}
                    extra={
                      <SolarzButton
                        variant="link"
                        scale="tiny"
                        onClick={() => remove(name)}
                      >
                        <AiOutlineClose />
                      </SolarzButton>
                    }
                    size="small"
                    styles={{
                      body: {
                        paddingBottom: 0,
                      },
                    }}
                  >
                    <Row gutter={8} align="middle">
                      <Col span={24} md={8}>
                        <Form.Item<IAdminInverterFormData["details"]>
                          {...restField}
                          name={[name, "inputPerMpptNum"]}
                          label="Nº de entradas"
                          rules={[{ required: true }]}
                          style={{ width: "100%" }}
                        >
                          <CustomInput.Number
                            size="large"
                            min={0}
                            precision={0}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24} md={8}>
                        <Form.Item<IAdminInverterFormData["details"]>
                          {...restField}
                          name={[name, "maxCurrentPerMpptInput"]}
                          label="Máx. Corrente de entrada"
                          rules={[{ required: true }]}
                          style={{ width: "100%" }}
                        >
                          <CustomInput.Number size="large" min={0} />
                        </Form.Item>
                      </Col>
                      <Col span={24} md={8}>
                        <Form.Item<IAdminInverterFormData["details"]>
                          {...restField}
                          name={[name, "maxShortCircuitCurrentPerMpptInput"]}
                          label="Máx. Corrente de curto da entrada"
                          rules={[{ required: true }]}
                          style={{ width: "100%" }}
                        >
                          <CustomInput.Number size="large" min={0} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                );
              })}

              <StyledButton
                block
                type="dashed"
                className="addInverterButton"
                onClick={() => add()} // need be this, or the value passed to antd will be the onClick element
              >
                <Flex align="center" justify="center" gap={8}>
                  <AiOutlinePlus size={16} />
                  Adicionar
                </Flex>
              </StyledButton>
            </Flex>
          )}
        </Form.List>
      </Spin>

      <Flex align="center" gap={8} justify="end" style={{ marginTop: 16 }}>
        <Button
          onClick={handleCancel}
          disabled={isUpdatingAdminInverters || isCreatingAdminInverters}
        >
          Cancelar
        </Button>
        <Button
          loading={isUpdatingAdminInverters || isCreatingAdminInverters}
          type="primary"
          htmlType="submit"
          disabled={isLoadingAdminInverter}
        >
          {id > 0 ? "Atualizar" : "Criar"}
        </Button>
      </Flex>
    </Form>
  );
}
