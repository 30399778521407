import { useEffect, useState } from "react";
import {
  AiOutlineCloudDownload,
  AiOutlineCopy,
  AiOutlineEye,
  AiOutlineFundView,
  AiOutlineHistory,
  AiOutlineLink,
  AiOutlineQuestionCircle,
  AiOutlineWhatsApp,
} from "react-icons/ai";
import { BiUserX } from "react-icons/bi";
import {
  BsArrowUpRightSquare,
  BsFillPersonCheckFill,
  BsFillTrashFill,
  BsGraphDownArrow,
  BsGraphUpArrow,
  BsPencilSquare,
} from "react-icons/bs";
import { FaRegFileAlt } from "react-icons/fa";
import { MdAttachMoney, MdRestore } from "react-icons/md";

import Link from "next/link";
import { useRouter } from "next/router";

import type {
  IProposalsTableProps,
  ISelectedProposalToAction,
  ProposalsTableDataType,
  ProposalsTableFiltersType,
} from "./interface";

import { AutoComplete } from "~components/AutoComplete";
import { Avatar } from "~components/Avatar";
import { Button } from "~components/Button";
import { ColumnFilter } from "~components/ColumnFilter";
import { CustomInput } from "~components/CustomInput";
import { Menu } from "~components/Menu";
import { Modal } from "~components/Modal";
import { Select } from "~components/Select";
import { Company, People } from "~estrela-ui/icons";
import {
  useFetchDeleteProjectByCustomId,
  useFetchGetProposalPdf,
  useFetchPostToGetPaginatedProposals,
  useFetchProposalCopy,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { useCopyToClipboard } from "~hooks/useCopyToClipboard";
import { useSession } from "~hooks/useSession";
import { SolarzAnchor } from "~solarzui/SolarzAnchor";
import { SolarzTable } from "~solarzui/SolarzTable";
import { SolarzTableColumnType } from "~solarzui/SolarzTable/interface";
import {
  PipedriveDealStatusEnum,
  ProjectTypeEnum,
  ProposalStatusEnum,
} from "~types/enum";
import { IProposalResponseSchema } from "~types/schemas";
import { getProposalEnumEquivalentStepNumber } from "~utils/checkIfIsAValidProposalStep";
import {
  parseCurrency,
  parseInputValueTypeToNumber,
  parsePotency,
  parseRemoveAccent,
} from "~utils/parse";
import { Flex, Form, Tooltip } from "antd";

const DEFAULT_FILTERS: ProposalsTableFiltersType = {
  id: undefined,
  cityName: undefined,
  clientName: undefined,
  sellerName: undefined,
  fullPower: undefined,
  valueForCustomer: undefined,
  pipedriveDealStatus: ["LOST", "OPEN", "WON"],
  status: ["LOST", "OPEN", "WON"],
  page: 1,
  size: 10, // static
  projectType: ["DEFAULT", "EXPRESS", "DIVERSE"],
};

export function ProposalsTable({
  defaultFilters,
  hideActionColumn = false,
}: IProposalsTableProps) {
  const router = useRouter();
  const { currentTheme, screens } = useAppConfig();
  const { userHavePermission, userIsAdmin, user } = useSession();
  const { handleCopy } = useCopyToClipboard();

  const userCanDeleteProposal =
    userIsAdmin || userHavePermission("DELETE_PROPOSALS");

  const [filters, setFilters] = useState<ProposalsTableFiltersType>({
    ...DEFAULT_FILTERS,
    ...defaultFilters,
  });

  const [selectedProposalToAction, setSelectedProposalToAction] =
    useState<ISelectedProposalToAction>();

  const [columns, setColumns] = useState<
    SolarzTableColumnType<IProposalResponseSchema>[]
  >([]);

  const { data, isFetching, error, refetch } =
    useFetchPostToGetPaginatedProposals({
      payload: {
        ...filters,
        page: (filters.page ?? 1) - 1,
        size: 10, // static
      },
      dependencyArray: [filters.page],
      options: {
        enabled: Number(filters.page) > 0,
      },
    });

  const { mutate: fetchProposalCopy, isLoading: isCopyingProposal } =
    useFetchProposalCopy({
      options: {
        onSuccess: (data) => {
          router.push({
            pathname: `/proposta/${data.customId}`,
            query: {
              pipedriveId: data.pipedriveDealId,
            },
          });
        },
      },
    });

  const { mutate: deleteProjectByCustomId, isLoading: isDeletingProject } =
    useFetchDeleteProjectByCustomId({
      options: {
        onSuccess: () => setSelectedProposalToAction(undefined),
      },
    });

  const {
    data: base64,
    isFetching: isLoadingBase64,
    error: errorBase64,
  } = useFetchGetProposalPdf({
    payload: {
      id: selectedProposalToAction?.proposal?.id ?? 0,
    },
    options: {
      enabled:
        selectedProposalToAction?.action === "DISPLAY_PDF" &&
        Number(selectedProposalToAction?.proposal?.id) > 0,
    },
    dependencyArray: [selectedProposalToAction],
  });

  function onChangeFilters(filters: Partial<ProposalsTableFiltersType>) {
    setFilters((state) => ({ ...state, ...filters }));
  }

  function handleSubmit() {
    Number(filters.page) > 1
      ? setFilters((current) => ({ ...current, page: 1 }))
      : refetch();
  }

  function renderActionColumn(record: ProposalsTableDataType) {
    const currentStep = getProposalEnumEquivalentStepNumber(
      record.lastStepEnum,
      {
        haveAfterSizingStep: record.haveIntermediateStep,
        havePaymentStep: record.havePaymentStep,
      },
    );

    const href = `/proposta/${record.id}?passo=${currentStep}`;

    const isProposalInThrash = record.status === "DELETED";
    const proposalAsFinalized = record.finished;

    const userCanBindToDeal =
      user?.pipedriveIntegration && !record?.pipedriveDealId;

    if (isProposalInThrash)
      return (
        <Menu.MoreDropdown
          items={[
            {
              icon: (
                <MdRestore size={16} style={{ color: "var(--blue-700)" }} />
              ),
              text: "Restaurar proposta",
              color: "var(--blue-700)",
              onClick: () =>
                setSelectedProposalToAction({
                  proposal: record,
                  action: "RESTORE_PROPOSAL",
                }),
              hidden: !record.active,
            },
            {
              icon: (
                <BsFillTrashFill
                  size={16}
                  style={{ color: "var(--red-500)" }}
                />
              ),
              text: "Apagar proposta",
              color: "var(--red-500)",
              onClick: () =>
                setSelectedProposalToAction({
                  proposal: record,
                  action: "TO_DELETE",
                }),
            },
          ]}
          position="bottomLeft"
        />
      );

    return (
      <Menu.MoreDropdown
        items={[
          {
            customComponent: (
              <Link
                href={href}
                style={{
                  padding: "0rem 1rem",
                  display: "flex",
                  height: 34,
                  width: "100%",
                  color: "var(--gold-600)",
                }}
              >
                <Flex justify="start" align="center" wrap="nowrap" gap={8}>
                  <BsArrowUpRightSquare />
                  Editar proposta
                </Flex>
              </Link>
            ),
          },
          {
            icon: <AiOutlineCopy style={{ color: "var(--primary-500)" }} />,
            text: "Copiar proposta",
            onClick: () =>
              fetchProposalCopy({
                id: record.id ?? 0,
              }),
            color: "var(--primary-500)",
            isLoading: isCopyingProposal,
            isHidden: !record.id,
          },
          {
            icon: (
              <AiOutlineCloudDownload
                size={16}
                style={{ color: "var(--blue-700)" }}
              />
            ),
            text: "Download PDF",
            color: "var(--blue-700)",
            customComponent: (
              <Button.ProposalDownloadPdf
                proposalId={record.id}
                type="link"
                size="small"
                style={{
                  padding: "0 16px",
                  margin: 0,
                  width: "100%",
                  color: "var(--blue-700)",
                  height: 34,
                }}
              >
                <Flex align="center" style={{ height: "100%" }} gap={8}>
                  <AiOutlineCloudDownload size={16} />
                  Download PDF
                </Flex>
              </Button.ProposalDownloadPdf>
            ),
            isHidden: !proposalAsFinalized,
          },
          {
            icon: (
              <AiOutlineLink size={16} style={{ color: "var(--purple-500)" }} />
            ),
            text: "Copiar link da proposta",
            color: "var(--purple-500)",
            onClick: () => {
              handleCopy(record.linkProposal ?? "");
            },
            isHidden: !proposalAsFinalized || !record.linkProposal,
          },
          {
            icon: (
              <AiOutlineWhatsApp
                size={16}
                style={{ color: "var(--green-700)" }}
              />
            ),
            text: "Enviar pelo WhatsApp",
            color: "var(--green-700)",
            onClick: () =>
              setSelectedProposalToAction({
                proposal: record,
                action: "SEND_WHATSAPP_MESSAGE",
              }),
            isHidden: !proposalAsFinalized,
          },
          {
            icon: (
              <AiOutlineEye size={16} style={{ color: "var(--blue-500)" }} />
            ),
            text: "Visualizar proposta",
            color: "var(--blue-500)",
            onClick: () =>
              setSelectedProposalToAction({
                action: "DISPLAY_PDF",
                proposal: record,
              }),
            isHidden: !proposalAsFinalized,
          },
          {
            icon: (
              <AiOutlineFundView
                size={16}
                style={{ color: "var(--magenta-500)" }}
              />
            ),
            text: "Visualizar acessos",
            color: "var(--magenta-500)",
            onClick: () =>
              setSelectedProposalToAction({
                action: "VIEW_ACCESS",
                proposal: record,
              }),
            isHidden: !proposalAsFinalized,
          },
          {
            icon: (
              <AiOutlineHistory
                size={16}
                style={{ color: "var(geekBlue-500)" }}
              />
            ),
            text: "Visualizar histórico",
            color: "var(geekBlue-500)",
            onClick: () =>
              setSelectedProposalToAction({
                action: "VIEW_HISTORY",
                proposal: record,
              }),
          },
          {
            icon: (
              <BsGraphUpArrow size={16} style={{ color: "var(--green-500)" }} />
            ),
            text: "Definir como ganha",
            color: "var(--green-500)",
            onClick: () =>
              setSelectedProposalToAction({
                action: "SET_AS_WON",
                proposal: record,
              }),
            isHidden:
              (!user?.pipedriveIntegration && record?.status === "WON") ||
              (user?.pipedriveIntegration &&
                record?.pipedriveDealStatus === "WON"),
          },
          {
            icon: (
              <BsGraphDownArrow
                size={16}
                style={{ color: "var(--primary-500)" }}
              />
            ),
            text: "Definir como perdida",
            color: "var(--primary-500)",
            onClick: () =>
              setSelectedProposalToAction({
                action: "SET_AS_LOST",
                proposal: record,
              }),
            isHidden:
              (!user?.pipedriveIntegration && record?.status === "LOST") ||
              (user?.pipedriveIntegration &&
                record?.pipedriveDealStatus === "LOST"),
          },
          {
            icon: (
              <BsFillTrashFill size={16} style={{ color: "var(--red-500)" }} />
            ),
            text: "Mover para lixeira",
            color: "var(--red-500)",
            onClick: () =>
              setSelectedProposalToAction({
                proposal: record,
                action: "SET_AS_DELETED",
              }),
            isHidden: !userCanDeleteProposal,
          },
          {
            icon: (
              <MdAttachMoney size={16} style={{ color: "var(--green-500)" }} />
            ),
            text: "Associar a um negócio",
            color: "var(--green-500)",
            onClick: () => {
              setSelectedProposalToAction({
                proposal: record,
                action: "BIND_PROPOSAL_TO_DEAL",
              });
            },
            isHidden: !userCanBindToDeal,
          },
        ].filter((item) => !item.isHidden)}
        position="bottomLeft"
      />
    );
  }

  function renderProjectColumn(type: ProjectTypeEnum) {
    switch (type) {
      case "DEFAULT":
        return "Solar";
      case "DIVERSE":
        return "Diversa";
      case "EXPRESS":
        return "Express";
      default:
        return "Desconhecido";
    }
  }

  function renderPipedriveStatusColumn(status: PipedriveDealStatusEnum) {
    const ICON_SIZE = 18;

    switch (status) {
      case "WON":
        return (
          <Tooltip title="Proposta ganha!" placement="left">
            <BsFillPersonCheckFill
              size={ICON_SIZE}
              style={{ color: "var(--green-500)" }}
            />
          </Tooltip>
        );
      case "LOST":
        return (
          <Tooltip title="Proposta perdida">
            <BiUserX size={ICON_SIZE} style={{ color: "var(--red-500)" }} />
          </Tooltip>
        );
      case "OPEN":
        return (
          <Tooltip title="Proposta aberta">
            <FaRegFileAlt
              size={ICON_SIZE}
              color={
                currentTheme === "dark"
                  ? "var(--gray-400)"
                  : "var(--geekBlue-500)"
              }
            />
          </Tooltip>
        );
      case "DELETED":
        return (
          <Tooltip title="Proposta ainda é um rascunho, deve ser finalizada para ser enviada ao cliente">
            <BsPencilSquare
              size={ICON_SIZE}
              style={{ color: "var(--gray-500)" }}
            />
          </Tooltip>
        );
      default:
        return (
          <Tooltip title="Status desconhecido, por favor editar a proposta para atualizarmos o status">
            <AiOutlineQuestionCircle
              size={ICON_SIZE}
              style={{ color: "var(--gray-500)" }}
            />
          </Tooltip>
        );
    }
  }

  function renderStatusColumn(status: ProposalStatusEnum) {
    const ICON_SIZE = 18;

    switch (status) {
      case "WON":
        return (
          <Tooltip title="Proposta ganha!" placement="left">
            <BsFillPersonCheckFill
              size={ICON_SIZE}
              style={{ color: "var(--green-500)" }}
            />
          </Tooltip>
        );
      case "LOST":
        return (
          <Tooltip title="Proposta perdida">
            <BiUserX size={ICON_SIZE} style={{ color: "var(--red-500)" }} />
          </Tooltip>
        );
      case "OPEN":
        return (
          <Tooltip title="Proposta aberta">
            <FaRegFileAlt
              size={ICON_SIZE}
              color={
                currentTheme === "dark"
                  ? "var(--gray-400)"
                  : "var(--geekBlue-500)"
              }
            />
          </Tooltip>
        );
      case "DELETED":
        return (
          <Tooltip title="Proposta ainda é um rascunho, deve ser finalizada para ser enviada ao cliente">
            <BsPencilSquare
              size={ICON_SIZE}
              style={{ color: "var(--gray-500)" }}
            />
          </Tooltip>
        );
      case "REJECTED":
        return (
          <Tooltip title="Esta proposta foi rejeitada. Para mais detalhes sobre os motivos da rejeição, por favor, entre em contato com o responsável pela avaliação.">
            <BsPencilSquare
              size={ICON_SIZE}
              style={{ color: "var(--gray-500)" }}
            />
          </Tooltip>
        );
      default:
        return (
          <Tooltip title="Status desconhecido, por favor editar a proposta para atualizarmos o status">
            <AiOutlineQuestionCircle
              size={ICON_SIZE}
              style={{ color: "var(--gray-500)" }}
            />
          </Tooltip>
        );
    }
  }

  const getClientHref = (
    clientName: string,
    hasDealResponse: boolean,
    record: ProposalsTableDataType,
  ) => {
    const parsedClientName = parseRemoveAccent(clientName.split(" ")[0]);
    if (hasDealResponse && record.person) {
      return `/clientes/pessoa/${record.person.id}`;
    }
    if (hasDealResponse && record.organization) {
      return `/clientes/empresa/${record.organization.id}`;
    }
    if (!hasDealResponse && record.person) {
      return `/clientes?tab=pessoas&buscar=${parsedClientName}`;
    }
    if (!hasDealResponse && record.organization) {
      return `/clientes?tab=empresas&buscar=${parsedClientName}`;
    }
  };

  const getColumns = (hasDealResponse: boolean) => {
    const columnsDefault: SolarzTableColumnType<IProposalResponseSchema>[] = [
      {
        title: "Id",
        dataIndex: "formattedIdentifier",
        align: "center" as const,
        width: 72,
        render: (formattedIdentifier, record) =>
          formattedIdentifier || record?.id,
        filterDropdown: (
          <ColumnFilter
            fields={[
              <Form.Item key="id" htmlFor="id" label="ID da proposta">
                <CustomInput.Number
                  id="id"
                  value={filters.id}
                  onChange={(value) =>
                    onChangeFilters({
                      id: isNaN(Number(value)) ? undefined : Number(value),
                    })
                  }
                  placeholder="Id da proposta..."
                  min={1}
                  precision={0}
                />
              </Form.Item>,
            ]}
            onReset={() => onChangeFilters({ id: undefined })}
            isLoading={isFetching}
            onSubmit={handleSubmit}
            data-cy="column-id"
          />
        ),
        filteredValue: filters.id ? [true] : [],
      },
      {
        title: "Cliente",
        dataIndex: "clientName",
        width: 240,
        render: (clientName, record) => {
          if (clientName) {
            if (record.person && record.person.name) {
              return (
                <Tooltip title={`Pessoa - ${record.person.name}`}>
                  <SolarzAnchor
                    isNextLink
                    aria-label={clientName}
                    href={getClientHref(clientName, hasDealResponse, record)}
                    defaultUnderlined={false}
                    style={{
                      display: "flex",
                      textAlign: "start",
                      justifyContent: "start",
                      alignItems: "center",
                      gap: 4,
                      padding: 0,
                      cursor: "pointer",
                    }}
                  >
                    <People />
                    {record.person.name}
                  </SolarzAnchor>
                </Tooltip>
              );
            }
            if (record.organization && record.organization.name) {
              return (
                <Tooltip title={`Organização - ${record.organization.name}`}>
                  <SolarzAnchor
                    isNextLink
                    aria-label={clientName}
                    href={getClientHref(clientName, hasDealResponse, record)}
                    defaultUnderlined={false}
                    style={{
                      display: "flex",
                      textAlign: "start",
                      justifyContent: "start",
                      alignItems: "center",
                      gap: 4,
                      padding: 0,
                      cursor: "pointer",
                    }}
                  >
                    <Company />
                    {record.organization.name}
                  </SolarzAnchor>
                </Tooltip>
              );
            }
            <SolarzAnchor
              isNextLink
              aria-label={clientName}
              href={
                hasDealResponse
                  ? record.person?.id
                    ? `/clientes/pessoa/${record.person?.id}`
                    : record.organization?.id
                      ? `/clientes/empresa/${record.organization?.id}`
                      : `/clientes?tab=pessoas&buscar=${clientName.split(" ")[0]}`
                  : record.person?.id
                    ? `/clientes?tab=pessoas&buscar=${clientName.split(" ")[0]}`
                    : record.organization?.id
                      ? `/clientes?tab=empresas&buscar=${clientName.split(" ")[0]}`
                      : "#"
              }
              defaultUnderlined={false}
              style={{ display: "flex", textAlign: "start" }}
            >
              {clientName}
            </SolarzAnchor>;
          }

          return <div className="flex justify-center items-center">-</div>;
        },
        filterDropdown: (
          <ColumnFilter
            fields={[
              <Form.Item
                key="clientName"
                htmlFor="clientName"
                label="Nome do cliente"
              >
                <AutoComplete.Person
                  id="clientName"
                  value={filters.clientName}
                  onChange={(value) => onChangeFilters({ clientName: value })}
                  placeholder="Cliente..."
                />
              </Form.Item>,
            ]}
            onReset={() => onChangeFilters({ clientName: undefined })}
            isLoading={isFetching}
            onSubmit={handleSubmit}
            data-cy="column-clients"
          />
        ),
        filteredValue: filters.clientName ? [true] : [],
      },
      {
        title: "Responsável",
        dataIndex: "sellerName",
        width: 240,
        render: (sellerName, record) =>
          sellerName ? (
            <div className="flex justify-start items-center gap-4">
              <Avatar.CustomUser
                avatarUrl={record.sellerAvatarUrl}
                name={sellerName}
                height={32}
                width={32}
              />
              {sellerName}
            </div>
          ) : (
            <div>-</div>
          ),
        hidden: !userIsAdmin && userHavePermission("ACCESS_OWN_PROPOSALS"),
        filterDropdown: (
          <ColumnFilter
            fields={[
              <Form.Item
                key="sellerName"
                htmlFor="sellerName"
                label="Nome do responsável"
              >
                <AutoComplete.Seller
                  id="sellerName"
                  value={filters.sellerName}
                  onChange={(value) => onChangeFilters({ sellerName: value })}
                  placeholder="Responsável..."
                />
              </Form.Item>,
            ]}
            onReset={() => onChangeFilters({ sellerName: undefined })}
            isLoading={isFetching}
            onSubmit={handleSubmit}
            data-cy="column-seller-name"
          />
        ),
        filteredValue: filters.sellerName ? [true] : [],
      },
      {
        title: "Potência do sistema",
        dataIndex: "fullPower",
        align: "center" as const,
        width: 180,
        render: (fullPower) =>
          parsePotency(fullPower) || (
            <div className="flex justify-center items-center">-</div>
          ),
        filterDropdown: (
          <ColumnFilter
            fields={[
              <Form.Item
                key="fullPower"
                htmlFor="fullPower"
                label="Potência"
                colon={false}
              >
                <CustomInput.UnitMeasurement
                  id="fullPower"
                  precision="2"
                  unitMeasurement="KWp"
                  value={filters.fullPower}
                  onChange={(value) => {
                    const parsedValue = parseInputValueTypeToNumber(value);
                    onChangeFilters({ fullPower: parsedValue });
                  }}
                  placeholder="Potência..."
                  style={{ width: "100%" }}
                />
              </Form.Item>,
            ]}
            onReset={() => onChangeFilters({ fullPower: undefined })}
            isLoading={isFetching}
            onSubmit={handleSubmit}
          />
        ),
        filteredValue: filters.fullPower ? [true] : [],
      },
      {
        title: "Preço",
        dataIndex: "valueForCustomer",
        align: "center" as const,
        width: 180,
        render: (valueForCustomer) =>
          parseCurrency(valueForCustomer) || (
            <div className="flex justify-center items-center">-</div>
          ),
        filterDropdown: (
          <ColumnFilter
            fields={[
              <Form.Item
                key="valueForCustomer"
                htmlFor="valueForCustomer"
                label="Preço"
                colon={false}
              >
                <CustomInput.UnitMeasurement
                  id="valueForCustomer"
                  precision="2"
                  unitMeasurement="R$"
                  value={filters.valueForCustomer}
                  onChange={(value) => {
                    const parsedValue = parseInputValueTypeToNumber(value);
                    onChangeFilters({ valueForCustomer: parsedValue });
                  }}
                  placeholder="Preço..."
                  style={{ width: "100%" }}
                />
              </Form.Item>,
            ]}
            onReset={() => onChangeFilters({ valueForCustomer: undefined })}
            isLoading={isFetching}
            onSubmit={handleSubmit}
          />
        ),
        filteredValue: filters.valueForCustomer ? [true] : [],
      },
      {
        title: "Cidade",
        dataIndex: "cityName",
        width: 180,
        render: (cityName) =>
          cityName ?? <div className="flex justify-center items-center">-</div>,
        filterDropdown: (
          <ColumnFilter
            fields={[
              <Form.Item
                key="cityName"
                htmlFor="cityName"
                label="Cidade"
                colon={false}
              >
                <CustomInput.Text
                  id="cityName"
                  value={filters.cityName}
                  onChange={(event) =>
                    onChangeFilters({ cityName: event.target.value })
                  }
                  placeholder="Cidade..."
                />
              </Form.Item>,
            ]}
            onReset={() => onChangeFilters({ cityName: undefined })}
            isLoading={isFetching}
            onSubmit={handleSubmit}
          />
        ),
        filteredValue: filters.cityName ? [true] : [],
      },
      {
        title: "Status",
        dataIndex: user?.pipedriveIntegration
          ? "pipedriveDealStatus"
          : "status",
        key: user?.pipedriveIntegration ? "pipedriveDealStatus" : "status",
        align: "center" as const,
        width: 100,
        render: (_, record) => {
          return (
            <>
              <div className="flex justify-center items-center">
                {user?.pipedriveIntegration
                  ? renderPipedriveStatusColumn(
                      record.pipedriveDealStatus ?? "OPEN",
                    )
                  : renderStatusColumn(record.status ?? "OPEN")}
              </div>
            </>
          );
        },
        filterDropdown: (
          <ColumnFilter
            fields={[
              <Form.Item
                key={
                  user?.pipedriveIntegration ? "pipedriveDealStatus" : "status"
                }
                htmlFor={
                  user?.pipedriveIntegration ? "pipedriveDealStatus" : "status"
                }
                label="Status"
                colon={false}
                data-cy="button-filter-status"
              >
                <Select.ProposalStatus
                  mode="tags"
                  value={
                    user?.pipedriveIntegration
                      ? filters.pipedriveDealStatus
                      : filters.status
                  }
                  onChange={(value) => {
                    const parsedValue = value as (
                      | PipedriveDealStatusEnum
                      | ProposalStatusEnum
                    )[];

                    const pipedriveDealStatusValues = parsedValue.filter((v) =>
                      ["WON", "LOST", "OPEN", "DELETED"].includes(v as string),
                    ) as PipedriveDealStatusEnum[];
                    const proposalStatusValues = parsedValue.filter((v) =>
                      ["WON", "LOST", "OPEN", "DELETED", "REJECTED"].includes(
                        v as string,
                      ),
                    ) as ProposalStatusEnum[];

                    onChangeFilters({
                      pipedriveDealStatus: user?.pipedriveIntegration
                        ? pipedriveDealStatusValues
                        : undefined,
                      status: !user?.pipedriveIntegration
                        ? proposalStatusValues
                        : undefined,
                    });
                  }}
                  data-cy="select-proposal-status"
                />
              </Form.Item>,
            ]}
            onReset={() =>
              onChangeFilters(
                user?.pipedriveIntegration
                  ? { pipedriveDealStatus: undefined }
                  : { status: undefined },
              )
            }
            isLoading={isFetching}
            onSubmit={handleSubmit}
          />
        ),
        filteredValue: user?.pipedriveIntegration
          ? filters.pipedriveDealStatus
            ? [true]
            : []
          : filters.status
            ? [true]
            : [],
      },
      {
        title: "Tipo",
        dataIndex: "projectType",
        align: "center" as const,
        width: 100,
        render: (projectType) => renderProjectColumn(projectType),
        filterDropdown: (
          <ColumnFilter
            fields={[
              <Form.Item
                key="projectType"
                htmlFor="projectType"
                label="Tipo de projeto"
                colon={false}
                data-cy="button-filter-project-type"
              >
                <Select.ProposalTypeFilter
                  mode="tags"
                  value={filters.projectType}
                  hideOptions={["ALL"]}
                  onChange={(value) => {
                    if (Array.isArray(value)) {
                      setFilters((current) => ({
                        ...current,
                        projectType: value,
                      }));
                    } else {
                      setFilters((current) => ({
                        ...current,
                        projectType: value === null ? [] : [value],
                      }));
                    }
                  }}
                  data-cy="select-proposal-type"
                />
              </Form.Item>,
            ]}
            onReset={() => onChangeFilters({ projectType: undefined })}
            isLoading={isFetching}
            onSubmit={handleSubmit}
          />
        ),
        filteredValue: filters.projectType ? [true] : [],
      },
      {
        title: "",
        align: "center" as const,
        hidden: hideActionColumn,
        width: 64,
        render: (_, record) => renderActionColumn(record),
      },
    ];

    if (hasDealResponse) {
      columnsDefault.splice(3, 0, {
        title: "Negócio",
        dataIndex: "proposalDealResponse",
        width: 240,
        render: (proposalDealResponse) => {
          return proposalDealResponse ? (
            <SolarzAnchor
              isNextLink
              aria-label={proposalDealResponse.name}
              href={`/funil/negocio/${proposalDealResponse.dealId}?tab=anotacoes`}
              defaultUnderlined={false}
              style={{ display: "flex", textAlign: "start" }}
            >
              {proposalDealResponse.name}
            </SolarzAnchor>
          ) : (
            <div className="flex justify-start items-center">-</div>
          );
        },
      });
    }

    return columnsDefault;
  };

  useEffect(() => {
    setColumns(
      getColumns(
        data?.content.some((proposal) => !!proposal.proposalDealResponse) ??
          false,
      ),
    );
  }, [isFetching, filters, data]);

  return (
    <>
      <SolarzTable<ProposalsTableDataType>
        data-cy="proposals-table"
        columns={columns}
        rows={data?.content ?? []}
        rowKey="id"
        pagination={{
          currentItemCount: data?.size ?? 0,
          currentPage: filters?.page ?? 0,
          itemLabel: "negócios",
          totalItemCount: data?.totalElements ?? 0,
          onChange: (page) => onChangeFilters({ page }),
          isDisabled: false,
        }}
        scroll={
          screens.mobile
            ? {
                x: undefined,
                y: "calc(100vh - 326px)",
              }
            : {
                x: screens.desktop ? 1200 : 616,
              }
        }
        emptyMessage="Não foi encontrado nenhuma proposta"
        errorMessage={error?.message}
        isLoading={isFetching}
        showHeader={!screens.mobile}
        reloadFn={refetch}
        rowClassName={screens.desktop ? undefined : "cursor-pointer"}
      />

      <Modal.PDFViewer
        isOpen={selectedProposalToAction?.action === "DISPLAY_PDF"}
        onClose={() => setSelectedProposalToAction(undefined)}
        PDFViewerProps={{
          base64pdf: base64?.file,
          initialScale: 0.7,
          isLoading: isLoadingBase64,
          onErrorResultProps: errorBase64?.message
            ? {
                title: "Falha ao carregar PDF",
                subTitle: errorBase64?.message ?? "Error desconhecido",
              }
            : undefined,
        }}
      />

      <Modal.ProposalViews
        isOpen={selectedProposalToAction?.action === "VIEW_ACCESS"}
        onClose={() => setSelectedProposalToAction(undefined)}
        listProps={{
          proposalId: selectedProposalToAction?.proposal?.id,
        }}
      />

      <Modal.ProposalHistory
        isOpen={selectedProposalToAction?.action === "VIEW_HISTORY"}
        onClose={() => setSelectedProposalToAction(undefined)}
        listProps={{
          proposalId: selectedProposalToAction?.proposal?.id,
        }}
      />

      <Modal.SetProposalStatus
        isOpen={selectedProposalToAction?.action === "SET_AS_WON"}
        onClose={() => setSelectedProposalToAction(undefined)}
        onSuccess={() => setSelectedProposalToAction(undefined)}
        customId={selectedProposalToAction?.proposal?.id ?? 0}
        dealId={
          selectedProposalToAction?.proposal.proposalDealResponse?.dealId ?? 0
        }
        action="SET_AS_WON"
      />

      <Modal.SetProposalStatus
        isOpen={selectedProposalToAction?.action === "SET_AS_LOST"}
        onClose={() => setSelectedProposalToAction(undefined)}
        onSuccess={() => setSelectedProposalToAction(undefined)}
        customId={selectedProposalToAction?.proposal?.id ?? 0}
        dealId={
          selectedProposalToAction?.proposal?.proposalDealResponse?.dealId ?? 0
        }
        action="SET_AS_LOST"
      />

      <Modal.SetProposalStatus
        isOpen={selectedProposalToAction?.action === "SET_AS_DELETED"}
        onClose={() => setSelectedProposalToAction(undefined)}
        onSuccess={() => setSelectedProposalToAction(undefined)}
        customId={selectedProposalToAction?.proposal.id ?? 0}
        action="SET_AS_DELETED"
      />

      <Modal.SetProposalStatus
        isOpen={selectedProposalToAction?.action === "RESTORE_PROPOSAL"}
        onClose={() => setSelectedProposalToAction(undefined)}
        onSuccess={() => setSelectedProposalToAction(undefined)}
        customId={selectedProposalToAction?.proposal?.id ?? 0}
        action="SET_AS_OPEN"
      />

      <Modal.SendProposalWhatsAppMessageForm
        isOpen={selectedProposalToAction?.action === "SEND_WHATSAPP_MESSAGE"}
        onClose={() => setSelectedProposalToAction(undefined)}
        formProps={{
          projectId: selectedProposalToAction?.proposal.id ?? 0,
        }}
      />

      {userCanDeleteProposal && (
        <Modal.DefaultRemove
          isLoading={isDeletingProject}
          isOpen={selectedProposalToAction?.action === "TO_DELETE"}
          onClose={() => setSelectedProposalToAction(undefined)}
          onSubmit={() =>
            deleteProjectByCustomId({
              customId: selectedProposalToAction?.proposal.id ?? 0,
            })
          }
          suffix="item proposta"
          itemName={`para o cliente ${selectedProposalToAction?.proposal?.clientName} permanentemente`}
          title="Apagar proposta?"
        />
      )}

      <Modal.UpdateProposalDealForm
        isOpen={selectedProposalToAction?.action === "BIND_PROPOSAL_TO_DEAL"}
        onClose={() => setSelectedProposalToAction(undefined)}
        proposalCustomId={selectedProposalToAction?.proposal?.id}
        onSubmit={() => {
          setSelectedProposalToAction(undefined);
          refetch();
        }}
      />
    </>
  );
}
