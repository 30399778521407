import { useMemo } from "react";

import type {
  ILogEntityTypeSelect,
  LogEntityTypeOptionType,
  LogEntityTypeRecordType,
} from "./interface";

import { SolarzSelect } from "~solarzui/SolarzSelect";
import { logEntityTypeEnumValues } from "~types/enum";
import { translateLogEntityType } from "~utils/translate";

export function LogEntityTypeSelect({
  hideOptions,
  disabledOptions,
  ...props
}: ILogEntityTypeSelect) {
  const OPTIONS = useMemo(() => {
    return logEntityTypeEnumValues.map((value) => ({
      value,
      record: {
        value,
      },
      label: translateLogEntityType(value),
      hidden: hideOptions && hideOptions.includes(value),
      disabled: disabledOptions && disabledOptions.includes(value),
    })) as LogEntityTypeOptionType;
  }, [disabledOptions, hideOptions]);

  return (
    <SolarzSelect<LogEntityTypeRecordType, "value">
      {...props}
      options={OPTIONS}
    />
  );
}
