import { useMemo } from "react";

import type {
  IActivityPeriodsSelect,
  ActivityPeriodsOptionType,
  ActivityPeriodsRecordType,
} from "./interface";

import { SolarzSelect } from "~solarzui/SolarzSelect";
import { activityPeriodsEnum } from "~types/enum";
import { translateActivityPeriods } from "~utils/translate";

export function ActivityPeriodsSelect({
  hideOptions,
  disabledOptions,
  ...props
}: IActivityPeriodsSelect) {
  const OPTIONS = useMemo(() => {
    return activityPeriodsEnum.map((value) => ({
      value,
      record: {
        value,
      },
      label: translateActivityPeriods(value),
      hidden: hideOptions && hideOptions.includes(value),
      disabled: disabledOptions && disabledOptions.includes(value),
    })) as ActivityPeriodsOptionType;
  }, [disabledOptions, hideOptions]);

  return (
    <SolarzSelect<ActivityPeriodsRecordType, "value">
      {...props}
      options={OPTIONS}
    />
  );
}
