import { useEffect, useState } from "react";
import {
  AiOutlineEye,
  AiOutlineFileDone,
  AiOutlineInbox,
} from "react-icons/ai";

import type {
  IDatasheetFormValuesType,
  IDatasheetFormProps,
} from "./interface";

import { Alert } from "~components/Alert";
import { Modal } from "~components/Modal";
import { Delete } from "~estrela-ui/icons";
import {
  useFetchCreateDatasheet,
  useFetchGetDatasheetById,
  useFetchUpdateDatasheet,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzFormItem } from "~solarzui/SolarzFormItem";
import { SolarzInput } from "~solarzui/SolarzInput";
import {
  Button,
  Card,
  Flex,
  Form,
  Spin,
  Tooltip,
  Typography,
  Upload,
} from "antd";
import type { RcFile } from "antd/es/upload";

const LOADING_INDICATOR_KEY = "PROCESSING_DATASHEET";

export function DatasheetForm({
  formInstance,
  initialValues,
  datasheetId,
  onCancel,
  onError,
  onSuccess,
}: IDatasheetFormProps) {
  const { message } = useAppConfig();

  const [form] = Form.useForm<IDatasheetFormValuesType>(formInstance);

  // WATCHERS
  const base64: string | undefined = Form.useWatch("base64", {
    preserve: true,
    form: form,
  });

  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);

  async function processPdfRcFile(base64: RcFile) {
    if (base64.type !== "application/pdf") {
      return message.error(`${base64.name} não é um arquivo do tipo PDF!`);
    }

    const reader = new FileReader();

    reader.readAsDataURL(base64);

    reader.onload = async (e) => {
      try {
        message.open({
          key: LOADING_INDICATOR_KEY,
          type: "loading",
          content: "Processando arquivo...",
          duration: 9999,
        });

        const loadedString = e.target?.result as string;

        if (!loadedString) {
          throw new Error(
            "Client error: falha ao converter arquivo para base64",
          );
        }

        // eslint-disable-next-line
        const [_mimeType, base64] = loadedString.split(";base64,");

        form.setFieldValue("base64", base64);

        message.open({
          key: LOADING_INDICATOR_KEY,
          type: "success",
          content: "Arquivo processado",
          duration: 2,
        });
      } catch (error: any) {
        message.open({
          key: LOADING_INDICATOR_KEY,
          type: "error",
          content: error?.message,
          duration: 2,
        });
        form.setFieldValue("base64", undefined);
      }
    };

    reader.onerror = () => {
      message.error("Client error: falha ao ler arquivo");
      form.setFieldValue("base64", undefined);
    };
  }

  const { mutate: createDatasheet, isLoading: isCreatingDatasheet } =
    useFetchCreateDatasheet({
      options: {
        onSuccess,
        onError,
      },
    });

  const { mutate: updateDatasheet, isLoading: isUpdatingDatasheet } =
    useFetchUpdateDatasheet({
      options: {
        onSuccess,
        onError,
      },
    });

  const {
    data: datasheet,
    isFetching: isLoadingDatasheet,
    error: datasheetError,
    refetch: reloadDatasheet,
  } = useFetchGetDatasheetById({
    payload: {
      id: datasheetId ?? 0,
    },
    dependencyArray: [datasheetId],
    options: {
      enabled: Number(datasheetId) > 0,
      retry: 1,
    },
  });

  useEffect(() => {
    if (datasheet) {
      form.setFieldsValue({ brand: datasheet.brand, name: datasheet.name });
    }
  }, [form, datasheet]);

  function handleSubmit(formValues: IDatasheetFormValuesType) {
    datasheetId
      ? updateDatasheet({ ...formValues, id: datasheetId })
      : createDatasheet({ ...formValues, base64: formValues.base64 ?? "" });
  }

  function handleCancel() {
    form.setFieldsValue({
      ...initialValues,
      ...datasheet,
    });

    if (typeof onCancel === "function") {
      return onCancel();
    }
  }

  const isLoading = isCreatingDatasheet || isUpdatingDatasheet;

  return (
    <>
      <Form
        data-cy="datasheet-form"
        form={form}
        initialValues={initialValues}
        onFinish={handleSubmit}
        preserve
        layout="vertical"
      >
        {datasheetError && (
          <Alert.CardError
            errorMessage={datasheetError?.message}
            title="Falha ao carregar dados iniciais"
            reloadFn={reloadDatasheet}
            style={{ marginBottom: 16 }}
          />
        )}

        <Spin spinning={isLoadingDatasheet}>
          <Flex vertical>
            <SolarzFormItem<IDatasheetFormValuesType>
              name="name"
              label="Nome do Datasheet"
              rules={[
                {
                  required: true,
                  message: "Nome é obrigatório",
                },
              ]}
            >
              <SolarzInput placeholder="Informe o nome..." />
            </SolarzFormItem>

            <SolarzFormItem<IDatasheetFormValuesType>
              name="brand"
              label="Fabricante"
              rules={[
                {
                  required: true,
                  message: "Fabricante é obrigatório",
                },
              ]}
            >
              <SolarzInput placeholder="Informe o fabricante..." />
            </SolarzFormItem>

            <SolarzFormItem<IDatasheetFormValuesType>
              name="base64"
              rules={[
                {
                  validator: () => {
                    const base64 = form.getFieldValue("base64");

                    return base64
                      ? Promise.resolve()
                      : Promise.reject("O arquivo .pdf é obrigatório");
                  },
                },
              ]}
              style={{ marginBottom: 16 }}
            >
              {base64 ? (
                <Card
                  style={{
                    width: "100%",
                    minHeight: "6rem",
                    border: `1px dashed var(--green-500)`,
                  }}
                >
                  <Flex
                    vertical
                    align="center"
                    justify="center"
                    style={{ marginBottom: "1rem" }}
                  >
                    <AiOutlineFileDone
                      size={64}
                      style={{ color: "var(--green-500)" }}
                    />
                    <Typography.Text style={{ color: "var(--green-500)" }}>
                      Arquivo pronto para envio!
                    </Typography.Text>
                  </Flex>
                  <Flex align="center" justify="center" gap={24}>
                    <Tooltip title="Remover documento">
                      <Button
                        type="dashed"
                        size="large"
                        shape="circle"
                        style={{
                          borderColor: "var(--red-500)",
                          color: "var(--red-500)",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          lineHeight: 0,
                        }}
                        onClick={() => form.setFieldValue("base64", undefined)}
                      >
                        <Delete style={{ borderColor: "var(--red-500)" }} />
                      </Button>
                    </Tooltip>

                    <Tooltip title="Visualizar documento">
                      <Button
                        type="dashed"
                        size="large"
                        shape="circle"
                        style={{
                          borderColor: "var(--blue-500)",
                          color: "var(--blue-500)",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          lineHeight: 0,
                        }}
                        onClick={() => setIsPdfModalOpen(true)}
                      >
                        <AiOutlineEye
                          size={22}
                          style={{ borderColor: "var(--blue-500)" }}
                        />
                      </Button>
                    </Tooltip>
                  </Flex>
                </Card>
              ) : (
                <Upload.Dragger
                  beforeUpload={processPdfRcFile}
                  multiple={false}
                  showUploadList={false}
                  style={{
                    padding: "1rem 2rem",
                    marginTop: "0.5rem",
                  }}
                  accept="application/pdf"
                >
                  <Flex vertical align="center" style={{ width: "100%" }}>
                    <AiOutlineInbox
                      size={64}
                      style={{ color: "var(--primary-500)" }}
                    />

                    <Typography.Text style={{ color: "var(--black)" }}>
                      Clique ou arraste o arquivo
                    </Typography.Text>

                    <Typography.Text
                      style={{ color: "var(--gray-500)", marginTop: "0.5rem" }}
                    >
                      Somente um arquivo é aceitado. O arquivo deve ser .pdf
                    </Typography.Text>
                  </Flex>
                </Upload.Dragger>
              )}
            </SolarzFormItem>
          </Flex>
        </Spin>

        <Flex
          style={{ marginTop: 12 }}
          align="center"
          gap={16}
          justify="flex-end"
        >
          <SolarzButton variant="secondary" scale="tiny" onClick={handleCancel}>
            Cancelar
          </SolarzButton>
          <SolarzButton
            scale="tiny"
            htmlType="submit"
            isLoading={isLoading}
            isDisabled={isLoadingDatasheet}
          >
            {datasheetId ? "Atualizar" : "Criar"}
          </SolarzButton>
        </Flex>
      </Form>

      <Modal.PDFViewer
        isOpen={isPdfModalOpen}
        onClose={() => setIsPdfModalOpen(false)}
        PDFViewerProps={{
          base64pdf: base64,
          initialScale: 0.7,
          isLoading: isLoadingDatasheet,
          onErrorResultProps: datasheetError?.message
            ? {
                title: "Falha ao carregar PDF",
                subTitle: datasheetError?.message ?? "Error desconhecido",
              }
            : undefined,
        }}
      />
    </>
  );
}
