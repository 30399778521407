import { useState } from "react";

import type { IToggleDistributorsSwitchProps } from "./interface";
import styles from "./styles";

import { Modal } from "~components/Modal";
import {
  useFetchDeleteIntegrationLinks,
  useFetchToggleDistributors,
} from "~hooks/api";
import { Flex, Switch, Tooltip } from "antd";

export function ToggleDistributorsSwitch({
  distributorId = 0,
  isChecked = false,
  isDisabled = false,
  tooltip = "",
}: IToggleDistributorsSwitchProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { mutate: fetchToggleDistributor, isLoading } =
    useFetchToggleDistributors();

  const { mutate: fetchDeleteIntegrationLinks } =
    useFetchDeleteIntegrationLinks();

  function handleClickOnSwitch() {
    if (isChecked) {
      setIsModalVisible(true);
    } else {
      fetchToggleDistributor({ distributorId });
    }
  }
  const parsedId = distributorId.toString();

  return (
    <>
      <style jsx>{styles}</style>
      <Flex data-cy="toggle-distribuitors-switch" align="center" gap={8}>
        <Tooltip title={tooltip}>
          <Switch
            id={parsedId}
            checked={isChecked}
            loading={isLoading}
            onClick={handleClickOnSwitch}
            checkedChildren="ativo"
            unCheckedChildren="inativo"
            className="toggle-distributors-switch"
            disabled={isDisabled}
          />
        </Tooltip>
      </Flex>
      <Modal.DeleteIntegrationLinks
        distributorId={distributorId}
        fetchToggleDistributor={fetchToggleDistributor}
        fetchDeleteIntegrationLinks={fetchDeleteIntegrationLinks}
        isOpen={isModalVisible}
        onSuccess={() => setIsModalVisible(!isModalVisible)}
        onClose={() => setIsModalVisible(false)}
      />
    </>
  );
}
