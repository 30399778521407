import NextImage from "next/image";

import type {
  IAdminDistributorsTableData,
  IAdminDistributorsTableProps,
  TypeStatus,
} from "./interface";

import { useFetchGetAdminDistributors } from "~hooks/api";
import { SolarzTable } from "~solarzui/SolarzTable";
import { Badge, Tag, Tooltip } from "antd";

export function AdminDistributorsTable(_props: IAdminDistributorsTableProps) {
  const IMAGE_WIDTH = 150;
  const IMAGE_HEIGHT = 60;

  const {
    data: adminDistributors = [],
    isFetching: isLoadingAdminDistributors,
    refetch: reloadAdminDistributors,
    error: errorAdminDistributors,
  } = useFetchGetAdminDistributors({
    dependencyArray: [],
    options: {
      retry: 1,
    },
  });

  const getColorByStatus = (status: TypeStatus) => {
    switch (status) {
      case "SUCCESS":
        return {
          title: "Sucesso",
          color: "green",
        };
      case "ERROR":
        return {
          title: "Erro",
          color: "red",
        };
      case "PROCESSING":
        return {
          title: "Processando",
          color: "blue",
        };
      case "SKIPPED":
        return {
          title: "Passado",
          color: "gray",
        };
      default:
        return {
          title: "Desconhecido",
          color: "gray",
        };
    }
  };

  const getDistributorImage = (data: IAdminDistributorsTableData) => {
    switch (data.name) {
      case "EDELTEC":
        return {
          src: "/images/integradores/logo-edeltec-solar.svg",
          alt: "Logotipo da Edeltec",
          width: IMAGE_WIDTH,
          height: IMAGE_HEIGHT,
        };
      case "FOCO":
        return {
          src: "/images/integradores/logo-foco-energia-solar.svg",
          alt: "Logotipo da Foco Energia",
          width: IMAGE_WIDTH,
          height: IMAGE_HEIGHT,
        };
      case "FORTLEV":
        return {
          src: "/images/integradores/logo-fortlev-solar.svg",
          alt: "Logotipo da FORTLEV",
          width: IMAGE_WIDTH,
          height: IMAGE_HEIGHT,
        };
      case "GT":
        return {
          src: "/images/integradores/logo-gt-solar.svg",
          alt: "Logotipo da GTSolar",
          width: IMAGE_WIDTH,
          height: IMAGE_HEIGHT,
        };
      case "KOMECO":
        return {
          src: "/images/integradores/logo-komeco-solar.svg",
          alt: "Logotipo da Komeco",
          width: IMAGE_WIDTH,
          height: IMAGE_HEIGHT,
        };
      case "SOUENERGY":
        return {
          src: "/images/integradores/logo-sou-energy-solar.svg",
          alt: "Logotipo da SOU ENERGY SOLAR",
          width: IMAGE_WIDTH - 40,
          height: IMAGE_HEIGHT + 20,
        };
      case "WEG":
        return {
          src: "/images/integradores/logo-weg-solar.svg",
          alt: "Logotipo da WEG",
          width: IMAGE_WIDTH - 40,
          height: IMAGE_HEIGHT,
        };
      case "ALDO":
        return {
          src: "/images/integradores/aldo-logo-solar.svg",
          alt: "Logotipo da Aldo",
          width: IMAGE_WIDTH,
          height: IMAGE_HEIGHT + 20,
        };
      default:
        return {
          src: "/images/integradores/unknown-integrator.svg",
          alt: "Integrador desconhecido",
          width: IMAGE_WIDTH,
          height: 80,
        };
    }
  };

  return (
    <SolarzTable<IAdminDistributorsTableData>
      data-cy="admin-distributors-table"
      rowKey="id"
      columns={[
        {
          title: "Distribuidor",
          dataIndex: "name",
          width: IMAGE_WIDTH + 16,
          align: "center",
          render: (_, record) => (
            <Tooltip title={record.name}>
              <NextImage {...getDistributorImage(record)} priority />
            </Tooltip>
          ),
        },
        {
          title: "Descrição",
          dataIndex: "description",
          render: (_, record) => record.description || "-",
        },
        {
          title: "Integradores",
          dataIndex: "companiesQuantity",
          width: 120,
          align: "center" as const,
          render: (_, record) => record.companiesQuantity,
        },
        {
          title: "Status",
          dataIndex: "syncLog",
          width: 180,
          align: "center" as const,
          render: (_, record) => {
            if (!record.syncLog) {
              return <Badge color="gray">-</Badge>;
            }
            if (record.syncLog.status) {
              const { title, color } = getColorByStatus(record.syncLog.status);
              return (
                <Tooltip title={record.syncLog?.message}>
                  <Tag color={color}>{title}</Tag>
                </Tooltip>
              );
            }
          },
        },
      ]}
      rows={adminDistributors}
      scroll={{ x: 720 }}
      isLoading={isLoadingAdminDistributors}
      reloadFn={reloadAdminDistributors}
      errorMessage={errorAdminDistributors?.message}
      emptyMessage="Nenhum integrador encontrado"
    />
  );
}
