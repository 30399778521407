import { useMemo } from "react";

import {
  LogsTableQueryParamKeysEnum,
  type ILogsTableProps,
  type LogsTableFiltersType,
  type LogsTableQueryParamsKeysType,
} from "./interface";
import { LogsTableHeader } from "./LogsTableHeader";

import { useFetchGetPaginatedUsageLogs } from "~hooks/api";
import { useQueryParams, parseFunctions } from "~hooks/useQueryParams";
import { SolarzAnchor } from "~solarzui/SolarzAnchor";
import { SolarzTable } from "~solarzui/SolarzTable";
import type { IUsageLogsDTOSchema } from "~types/schemas";
import {
  translateLogPropertyName,
  translateLogActionType,
  translateLogEntityType,
} from "~utils/translate";
import { Flex } from "antd";
import dayjs from "dayjs";

function getUrlByEntityType(record: IUsageLogsDTOSchema) {
  switch (record.entityType) {
    case "AdditionalCostDescriptor":
      return "/configuracoes-de-propostas/custos-complementares?tab=itens-complementares";
    case "Labor":
      return "/configuracoes-de-propostas/custos-complementares?tab=mao-de-obra";
    case "AlternatingCurrentMaterial":
      return "/configuracoes-de-propostas/custos-complementares?tab=corrente-alternada";
    case "Logistics":
      return "/configuracoes-de-propostas/custos-complementares?tab=logistica";
    case "Project":
      return `/proposta/${record.entityCustomId}`;
    case "StructureMaterialCostRule":
      return "/configuracoes-de-propostas/custos-complementares?tab=calculo-de-estrutura";
    default:
      return "#";
  }
}

function getFormattedValue(value: string) {
  const isPossibleDate = value.length >= 19;

  const parsedValueDayJs = dayjs(isPossibleDate ? value : NaN);

  if (parsedValueDayJs.isValid()) {
    return parsedValueDayJs.format("DD MMM YYYY, HH:mm:ss");
  }

  const translations = {
    true: "Sim",
    false: "Não",
  } as Record<string, string>;

  return translations?.[value] ?? value;
}

export function LogsTable(props: ILogsTableProps) {
  const { getParsedQueryParams, handleSaveInQueryParams } = useQueryParams();

  const QUERY_PARAMS_KEYS: LogsTableQueryParamsKeysType = useMemo(
    () => ({
      USER_NAME:
        props.queryParamKeys?.USER_NAME ||
        LogsTableQueryParamKeysEnum.USER_NAME,
      START_DATE:
        props.queryParamKeys?.START_DATE ||
        LogsTableQueryParamKeysEnum.START_DATE,
      END_DATE:
        props.queryParamKeys?.END_DATE || LogsTableQueryParamKeysEnum.END_DATE,
      CURRENT_PAGE:
        props.queryParamKeys?.CURRENT_PAGE ||
        LogsTableQueryParamKeysEnum.CURRENT_PAGE,
      ACTION:
        props.queryParamKeys?.ACTION || LogsTableQueryParamKeysEnum.ACTION,
      ENTITY:
        props.queryParamKeys?.ENTITY || LogsTableQueryParamKeysEnum.ENTITY,
    }),
    [props.queryParamKeys],
  );

  const filters: LogsTableFiltersType = useMemo(
    () => ({
      currentPage:
        getParsedQueryParams(
          QUERY_PARAMS_KEYS.CURRENT_PAGE,
          parseFunctions.NUMBER,
        ) ?? 1,
      startDate:
        getParsedQueryParams(
          QUERY_PARAMS_KEYS.START_DATE,
          parseFunctions.DAYJS,
        ) ?? dayjs().subtract(1, "week"),
      endDate:
        getParsedQueryParams(
          QUERY_PARAMS_KEYS.END_DATE,
          parseFunctions.DAYJS,
        ) ?? dayjs(),
      userName: getParsedQueryParams(
        QUERY_PARAMS_KEYS.USER_NAME,
        parseFunctions.STRING,
      ),
      entity: getParsedQueryParams(
        QUERY_PARAMS_KEYS.ENTITY,
        parseFunctions.STRING,
      ) as LogsTableFiltersType["entity"] | null,
      action: getParsedQueryParams(
        QUERY_PARAMS_KEYS.ACTION,
        parseFunctions.STRING,
      ) as LogsTableFiltersType["action"] | null,
    }),
    [getParsedQueryParams, QUERY_PARAMS_KEYS],
  );

  const {
    data: usageLogs,
    isFetching: isLoadingUsageLogs,
    error: usageLogsError,
    refetch: reloadUsageLogs,
  } = useFetchGetPaginatedUsageLogs({
    dependencyArray: [filters],
    options: {
      retry: 1,
      enabled: filters.currentPage > 0,
    },
    payload: {
      page: filters.currentPage - 1,
      size: 10, // STATIC,
      startAt: filters.startDate?.format("DD/MM/YYYY"),
      endAt: filters.endDate?.format("DD/MM/YYYY"),
      userName: filters.userName ?? undefined,
      action: filters.action ?? undefined,
      entity: filters.entity ?? undefined,
    },
  });

  return (
    <Flex vertical>
      {props.displayFiltersComponent && (
        <LogsTableHeader queryParamKeys={props.queryParamKeys} />
      )}

      <SolarzTable<IUsageLogsDTOSchema>
        data-cy="logs-table"
        columns={[
          {
            title: "Data",
            dataIndex: "createdAt",
            key: "createdAt",
            width: 288,
            render: (_, record) =>
              dayjs(record.createdAt).format("DD/MM/YYYY hh:mm:ss"),
          },
          {
            title: "USUARIO",
            dataIndex: "userAccountName",
            key: "userAccountName",
            width: 240,
          },
          {
            title: "AÇÃO",
            dataIndex: "actionType",
            key: "actionType",
            width: 342,
            render: (_, record) => {
              const logActionType = translateLogActionType(record.actionType);
              const logEntityType = translateLogEntityType(record.entityType);

              return `${logActionType.toUpperCase()} ${logEntityType.toUpperCase()}`;
            },
          },
          {
            title: "DETALHES",
            key: "description",
            width: 500,
            render: (_, record) => {
              const logActionType = translateLogActionType(record.actionType);
              const logEntityType = translateLogEntityType(record.entityType);

              const customDescription = `${record.userAccountName} “${logActionType.toLowerCase()}” ${logEntityType.toLowerCase()} “${record.targetName.toLowerCase()}”`;

              return customDescription;
            },
          },
          {
            title: "LINK",
            dataIndex: "link",
            key: "link",
            width: 224,
            render: (_, record) => (
              <SolarzAnchor
                isNextLink
                href={getUrlByEntityType(record)}
                style={{ textTransform: "uppercase" }}
                isDisabled={record.actionType === "DELETE"}
              >
                {translateLogEntityType(record.entityType).toUpperCase()}
              </SolarzAnchor>
            ),
          },
        ]}
        rows={usageLogs?.content ?? []}
        isLoading={isLoadingUsageLogs}
        pagination={{
          currentItemCount: usageLogs?.size ?? 0,
          currentPage: filters.currentPage || 1,
          itemLabel: "logs",
          totalItemCount: usageLogs?.totalElements ?? 0,
          onChange: (currentPage) => {
            handleSaveInQueryParams({
              [QUERY_PARAMS_KEYS.CURRENT_PAGE]: currentPage,
            });
          },
        }}
        scroll={{ x: 1200 }}
        emptyMessage="Não foi encontrado nenhum log"
        errorMessage={usageLogsError?.message}
        reloadFn={reloadUsageLogs}
        rowKey="id"
        expandable={{
          expandedRowRender: (record) => {
            return (
              <Flex vertical gap={12} style={{ paddingLeft: "3.25rem" }}>
                {record.propertyDiffs.map((item, index) => {
                  const translatedField =
                    translateLogPropertyName(item.propertyName) ||
                    `'${item.propertyName}'`;

                  const formattedOldValue = getFormattedValue(item.oldValue);

                  const formattedNewValue = getFormattedValue(item.newValue);

                  const description = `O campo '${translatedField}' foi alterado: ${formattedOldValue} -> ${formattedNewValue}`;

                  return <div key={index + 1}>{description}</div>;
                })}
              </Flex>
            );
          },
          rowExpandable: (record) => record.propertyDiffs.length > 0,
        }}
      />
    </Flex>
  );
}
