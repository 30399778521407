import { useState } from "react";
import { BsGearFill, BsPersonFillLock } from "react-icons/bs";
import { CiLogout } from "react-icons/ci";
import { GoMoon } from "react-icons/go";
import { MdOutlineWbSunny } from "react-icons/md";

import type { ILoggedUserDrawerProps } from "./interface";
import {
  LogoutButton,
  StyledAnchor,
  StyledButton,
  StyledLabel,
} from "./styles";

import { Avatar } from "~components/Avatar";
import { ReleaseNoteInfoButton } from "~components/Button/ReleaseNoteInfoButton";
import { Modal } from "~components/Modal";
import { Select } from "~components/Select";
import { ArrowLeft } from "~estrela-ui/icons";
import { useAppConfig } from "~hooks/useAppConfig";
import { useSession } from "~hooks/useSession";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { Divider, Drawer, Flex } from "antd";

export function LoggedUserDrawer({
  open,
  onClose,
  displayBusinessUnit = true,
}: ILoggedUserDrawerProps) {
  const { changeTheme, currentTheme } = useAppConfig();
  const { user, sessionStatus, signOut } = useSession();

  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);

  function handleOpenModal() {
    setIsChangePasswordModalOpen(true);
  }

  function handleCloseModal() {
    setIsChangePasswordModalOpen(false);
  }

  const forceUserToChangePassword =
    !!user?.initialAccess && !user?.pipedriveImportedUser;

  return (
    <>
      <Drawer
        open={open}
        onClose={onClose}
        zIndex={1000}
        closable={false}
        width="100%"
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        <Flex
          style={{ height: 56, width: "100%", padding: "1rem" }}
          align="center"
          justify="space-between"
        >
          <SolarzButton onClick={onClose} icon={<ArrowLeft />} variant="link" />

          <SolarzTypography
            hierarchy="paragraph1"
            variant="title"
            fontWeight="bold"
          >
            Perfil
          </SolarzTypography>

          <ReleaseNoteInfoButton />
        </Flex>

        <Divider style={{ margin: "0 0 1rem 0" }} />

        <Flex gap={16} align="center" style={{ padding: "0 1rem" }}>
          <Avatar.CustomUser
            name={user?.name}
            avatarUrl={user?.avatarUrl}
            userId={user?.id}
            sessionStatus={sessionStatus}
            height={32}
            width={32}
          />
          <Flex vertical gap={4}>
            <SolarzTypography
              variant="title"
              hierarchy="paragraph1"
              fontWeight="bold"
            >
              Logado como
            </SolarzTypography>
            <SolarzTypography variant="default" fontWeight="regular">
              {user?.email}
            </SolarzTypography>
          </Flex>
        </Flex>
        {displayBusinessUnit && (
          <>
            <Divider style={{ margin: "1.5rem 0" }} />

            <Flex vertical gap={6} style={{ margin: "0 1rem" }}>
              <StyledLabel htmlFor="logged-user-business-unit">
                Unidade de negócio:
              </StyledLabel>
              <Select.LoggedUserBusinessUnit
                id="logged-user-business-unit"
                style={{ width: "100%" }}
              />
            </Flex>
          </>
        )}
        <Divider style={{ margin: "1.5rem 0 0 0" }} />

        <Flex vertical>
          <StyledButton type="text" onClick={() => handleOpenModal()}>
            <BsPersonFillLock size={20} />
            <span>Alterar senha</span>
          </StyledButton>
          <StyledAnchor
            href="/configuracoes-de-perfil"
            isNextLink
            onClick={(e) => onClose(e)}
          >
            <BsGearFill size={20} />
            <span>Configurações de perfil</span>
          </StyledAnchor>

          <StyledButton
            onClick={() =>
              changeTheme(currentTheme === "dark" ? "default" : "dark")
            }
            type="text"
            tabIndex={0}
          >
            {currentTheme === "dark" ? (
              <GoMoon size={20} />
            ) : (
              <MdOutlineWbSunny size={20} />
            )}
            <span>
              {currentTheme === "dark"
                ? "Ativar tema claro"
                : "Ativar tema escuro"}
            </span>
          </StyledButton>

          <LogoutButton danger onClick={signOut}>
            <CiLogout size={20} />
            <span>Logout</span>
          </LogoutButton>
        </Flex>
      </Drawer>

      <Modal.ChangePasswordForm
        isOpen={forceUserToChangePassword || isChangePasswordModalOpen}
        onClose={() => handleCloseModal()}
        formProps={{
          onSuccess: () => handleCloseModal(),
        }}
        closable={!forceUserToChangePassword}
      />
    </>
  );
}
