import { useState } from "react";
import { BsTrash3 } from "react-icons/bs";

import type { IPricingFormProps, IPricingFormValues } from "./interface";

import { Modal } from "~components/Modal";
import { Select } from "~components/Select";
import {
  useFetchCreatePricingTypes,
  useFetchDeletePricingTypes,
  useFetchUpdatePricingTypes,
} from "~hooks/api";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzInput } from "~solarzui/SolarzInput";
import {
  IFetchCreatePricingTypesPayloadSchema,
  IFetchUpdatePricingTypesPayloadSchema,
} from "~types/schemas";
import { Flex, Form } from "antd";

export function PricingForm({
  initialValues,
  onCancel = () => undefined,
  onError = () => undefined,
  onSuccess = () => undefined,
  activeTab = undefined,
}: IPricingFormProps) {
  const [form] = Form.useForm<IPricingFormValues>();
  const [pricingToDelete, setPricingToDelete] = useState<
    IPricingFormValues | undefined
  >();

  const { mutate: fetchDeletePricingType, isLoading: isDeletingPricing } =
    useFetchDeletePricingTypes({
      activeTab,
      onSuccess: () => {
        setPricingToDelete(undefined);
        onCancel();
      },
    });

  async function handleDeletePricingTypes(pricingId: number) {
    if (pricingId > 0) {
      fetchDeletePricingType({
        pricingId,
      });
    }
  }

  const { mutate: createPricing, isLoading: isCreatingPricing } =
    useFetchCreatePricingTypes({
      options: {
        onSuccess,
        onError,
      },
    });

  const { mutate: updatePricingTypes, isLoading: isUpdatingPricingTypes } =
    useFetchUpdatePricingTypes({
      options: {
        onSuccess,
        onError,
      },
    });

  function handleSubmit(formValues: IPricingFormValues) {
    if (initialValues?.id) {
      updatePricingTypes({
        pricingId: initialValues.id,
        name: formValues.name,
      } as IFetchUpdatePricingTypesPayloadSchema);
    } else {
      createPricing({
        name: formValues.name,
      } as IFetchCreatePricingTypesPayloadSchema);
    }
  }

  const isLoading = isCreatingPricing || isUpdatingPricingTypes;

  return (
    <>
      <Form
        data-cy="pricing-form"
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        style={{ width: "100%" }}
      >
        <Form.Item
          name="name"
          label="Nome"
          required
          rules={[
            {
              required: true,
              message: "Nome é obrigatório",
            },
          ]}
        >
          <SolarzInput
            placeholder="Ex.: José da Silva"
            defaultValue={initialValues?.name}
          />
        </Form.Item>
        <Form.Item label="Tipo de proposta">
          <Select.ProposalType
            style={{ width: "100%" }}
            hideExpress={true}
            hideAll={true}
            isDisabled={true}
          />
        </Form.Item>

        <Flex gap={12} justify={initialValues ? "space-between" : "end"}>
          {initialValues && (
            <Flex gap={12}>
              <Form.Item style={{ margin: 0 }}>
                <SolarzButton
                  style={{
                    borderColor: "var(--red-400)",
                    color: "var(--red-400)",
                  }}
                  variant="secondary"
                  scale="tiny"
                  icon={<BsTrash3 size={8} color="var(--red-400)" />}
                  onClick={() => setPricingToDelete(initialValues)}
                >
                  Excluir
                </SolarzButton>
              </Form.Item>
            </Flex>
          )}
          <Flex gap={12}>
            <Form.Item style={{ margin: 0 }}>
              <SolarzButton variant="secondary" scale="tiny" onClick={onCancel}>
                Cancelar
              </SolarzButton>
            </Form.Item>
            <Form.Item style={{ margin: 0 }}>
              <SolarzButton
                variant="primary"
                scale="tiny"
                htmlType="submit"
                isLoading={isLoading}
              >
                Salvar
              </SolarzButton>
            </Form.Item>
          </Flex>
        </Flex>
      </Form>
      <Modal.DefaultRemove
        isLoading={isDeletingPricing}
        isOpen={!!pricingToDelete}
        itemName={pricingToDelete?.name}
        onClose={() => setPricingToDelete(undefined)}
        suffix="tipo de precificação"
        onSubmit={() => handleDeletePricingTypes(pricingToDelete?.id || 0)}
        title={`Remover precificação`}
      />
    </>
  );
}
