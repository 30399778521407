import { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";

import { ITransformGroupRuleListProps, ITransformGroupRule } from "./interface";

import { Modal } from "~components/Modal";
import { useAppConfig } from "~hooks/useAppConfig";
import { Button, Empty, Flex, List, Result, Tooltip } from "antd";

export function TransformGroupRuleList({
  rules = [],
  error,
  isLoading,
  deleteModalProps,
  updateModalProps,
  selectedRuleToUpdate,
  selectedRuleToDelete,
  onSelectRuleToUpdate = () => undefined,
  onSelectRuleToDelete = () => undefined,
}: ITransformGroupRuleListProps) {
  const { message } = useAppConfig();

  const [_selectedRuleToUpdate, _setSelectedRuleToUpdate] =
    useState<ITransformGroupRule>();
  const [_selectedRuleToDelete, _setSelectedRuleToDelete] =
    useState<ITransformGroupRule>();

  function handleDeleteSubmit() {
    if (!deleteModalProps?.onSubmit) return;
    if (!_selectedRuleToDelete) {
      message.error("Client error: Faltando a regra selecionada");
      return;
    }
    deleteModalProps.onSubmit({
      rule: _selectedRuleToDelete,
      onSuccess: () => _setSelectedRuleToDelete(undefined),
    });
  }

  function onSelectToUpdate(rule?: ITransformGroupRule) {
    _setSelectedRuleToUpdate(rule);
    onSelectRuleToUpdate(rule);
  }

  function onSelectToDelete(rule?: ITransformGroupRule) {
    _setSelectedRuleToDelete(rule);
    onSelectRuleToDelete(rule);
  }

  useEffect(() => {
    _setSelectedRuleToUpdate(selectedRuleToUpdate);
    _setSelectedRuleToDelete(selectedRuleToDelete);
  }, [selectedRuleToDelete, selectedRuleToUpdate]);

  return (
    <>
      <List
        data-cy="transform-group-rule-list"
        itemLayout="horizontal"
        dataSource={rules}
        renderItem={(rule) => (
          <List.Item key={rule.id}>
            <Flex
              align="center"
              justify="space-between"
              style={{ width: "100%", paddingRight: "0.5rem" }}
            >
              <List.Item.Meta title={rule.title} />
              <Flex align="center" gap={16} style={{ marginRight: "1rem" }}>
                <Tooltip title="Alterar transformador">
                  <Button
                    type="link"
                    size="small"
                    shape="circle"
                    onClick={() => onSelectToUpdate(rule)}
                  >
                    <Flex align="center" gap={4}>
                      <AiOutlineEdit
                        fontSize={20}
                        style={{ color: "var(--gold-500)" }}
                      />
                    </Flex>
                  </Button>
                </Tooltip>

                <Tooltip title="Remover transformador">
                  <Button
                    type="link"
                    size="small"
                    shape="circle"
                    onClick={() => onSelectToDelete(rule)}
                  >
                    <Flex align="center" gap={4}>
                      <AiOutlineDelete
                        fontSize={20}
                        style={{ color: "var(--volcano-500)" }}
                      />
                    </Flex>
                  </Button>
                </Tooltip>
              </Flex>
            </Flex>
          </List.Item>
        )}
        locale={{
          emptyText: error ? (
            <Result
              title="Falha ao carregar regras"
              subTitle={error}
              status="error"
            />
          ) : (
            <Empty description="Nenhuma regra cadastrada ainda!" />
          ),
        }}
        loading={isLoading}
      />

      <Modal.DefaultRemove
        {...deleteModalProps}
        isOpen={!!_selectedRuleToDelete}
        onClose={() => onSelectToDelete(undefined)}
        itemName={_selectedRuleToDelete?.title}
        suffix="regra de grupo"
        title="Remover regra"
        onSubmit={handleDeleteSubmit}
      />

      <Modal.TransformerGroupForm
        {...updateModalProps}
        isOpen={!!_selectedRuleToUpdate}
        onClose={() => onSelectToUpdate(undefined)}
      />
    </>
  );
}
