import { BsChatSquareText } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { IoCallOutline, IoDocumentTextOutline } from "react-icons/io5";
import { LuTimerReset, LuUsers, LuUserCircle2, LuUsers2 } from "react-icons/lu";

import { IViewActivityModalProps } from "./interface";

import { useFetchGetActivityById } from "~hooks/api/";
import { useFetchDeleteActivity } from "~hooks/api/activity/useFetchDeleteActivity";
import { useFetchToggleStatusActivity } from "~hooks/api/activity/useFetchToggleStatusActivity";
import {
  Button,
  Checkbox,
  Divider,
  Flex,
  Modal,
  Popconfirm,
  Popover,
  Typography,
} from "antd";
import dayjs from "dayjs";

export function ViewActivityModal({
  isOpen = false,
  onClose = () => null,
  reload = () => null,
  onEdit,
  title,
  width = 600,
  activityId,
}: IViewActivityModalProps) {
  const { data: activity } = useFetchGetActivityById({
    payload: {
      activityId,
    },
    options: {
      enabled: !!activityId,
    },
    dependencyArray: [activityId],
  });

  const { mutate: deleteAvtivity } = useFetchDeleteActivity({
    options: {
      onSuccess: () => {
        reload && reload();
        onClose();
      },
    },
  });

  const { mutate: updateStatusActivity } = useFetchToggleStatusActivity({
    options: {
      onSuccess() {
        reload && reload();
        onClose();
      },
    },
  });

  const getIconForEventType = (
    eventType?: string,
    type: "ICON" | "NAME" = "ICON",
  ) => {
    switch (eventType) {
      case "TASK":
        if (type === "NAME") return "Tarefa";
        return <LuTimerReset size={18} />;
      case "EMAIL":
        if (type === "NAME") return "E-mail";
        return <HiOutlineMail size={18} />;
      case "CALL":
        if (type === "NAME") return "Chamada";
        return <IoCallOutline size={18} />;
      case "MEETING":
        if (type === "NAME") return "Reunião";
        return <LuUsers size={18} />;
      case "PROPOSAL":
        if (type === "NAME") return "Proposta";
        return <IoDocumentTextOutline size={18} />;
      default:
        return <LuTimerReset size={18} />;
    }
  };

  return (
    <Modal
      data-cy="view-activity-modal"
      width={width}
      title={title}
      footer={false}
      destroyOnClose
      open={isOpen}
      onCancel={onClose}
    >
      <Flex align="start" gap={8}>
        <div style={{ marginTop: "0.2rem" }}>
          <Popover content={getIconForEventType(activity?.eventType, "NAME")}>
            {getIconForEventType(activity?.eventType)}
          </Popover>
        </div>

        <div>
          <Typography.Title level={4} style={{ margin: 0 }}>
            {activity?.name}
          </Typography.Title>
          <p>
            {dayjs(activity?.beginDate).format("dddd, MMMM DD ⋅ HH:mm")}–
            {dayjs(activity?.endDate).format("HH:mm")}
          </p>
        </div>
      </Flex>

      <Flex gap={8} style={{ flexDirection: "column" }}>
        <Flex align="center" gap={8}>
          <LuUserCircle2 size={18} />

          <div>
            {activity?.owner?.name &&
              `${activity?.owner.name} - ` + activity?.owner?.email}
          </div>
        </Flex>

        <Flex gap={8}>
          <LuUsers2 size={18} />
        </Flex>

        {activity?.notes && (
          <Flex align="center" gap={8} style={{ marginTop: "1.5rem" }}>
            <BsChatSquareText size={18} />
            <div>{activity?.notes}</div>
          </Flex>
        )}
      </Flex>

      <Divider />

      <Flex justify="space-between" align="center">
        <Popconfirm
          title="Realmente deseja excluir esta atividade?"
          description="Essa é uma ação que não poderá ser desfeita."
          onConfirm={() => deleteAvtivity({ activityId: Number(activity?.id) })}
          okText="Sim"
          cancelText="Não"
        >
          <Button danger>Excluir</Button>
        </Popconfirm>

        <Flex align="center" gap={16}>
          <Checkbox
            checked={activity?.status == "DONE"}
            onChange={() => {
              updateStatusActivity({
                id: Number(activity?.id),
              });
            }}
          >
            Marcar como concluida
          </Checkbox>

          <Button onClick={() => activity && onEdit && onEdit(activity)}>
            Editar
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
}
