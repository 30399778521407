import { useMemo } from "react";

import type { IFinancialListProps, IFinancialDTOTemporary } from "./interface";

import { Card } from "~components/Card";
import { Result } from "~components/Result";
import { List } from "antd";

const MOCKED_DATA = [
  {
    id: 1,
    name: "EOS",
    description:
      "A Eos é o elo que simplifica sua relação com as principais instituições financeiras do país",
  },
];

export function FinancialList(props: IFinancialListProps) {
  const DATA_SOURCE: IFinancialDTOTemporary[] = useMemo(
    () => MOCKED_DATA ?? [],
    [],
  );

  return (
    <List<IFinancialDTOTemporary>
      {...props}
      data-cy="integration-list"
      itemLayout="horizontal"
      grid={{ gutter: 16 }}
      dataSource={DATA_SOURCE}
      rowKey="name"
      locale={{
        emptyText: <Result.TableErrorOrEmpty />,
      }}
      renderItem={(data) => (
        <List.Item style={{ display: "flex" }}>
          <Card.Financial
            description={data.description}
            financial={data.name}
          />
        </List.Item>
      )}
    />
  );
}
