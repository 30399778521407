import { useCallback, useEffect, useState } from "react";
import { LuCalendarPlus } from "react-icons/lu";

import type {
  DataSourceType,
  ILeadsKanbanProps,
  TaskProp,
  TaskToUpdateType,
  TaskType,
} from "./interface";
import { CalendarIcon, Deal } from "./styles";

import { Avatar } from "~components/Avatar";
import { Modal } from "~components/Modal";
import { useRxStompClient } from "~hooks/useRxStompClient";
import { SolarzKanban } from "~solarzui/SolarzKanban";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { parseCurrency } from "~utils/parse";
import { Badge, Card, Flex, Tooltip } from "antd";

export function LeadsKanban({
  pipelineStages = [],
  pipelineError,
  isLoading,
  className,
  style,
}: ILeadsKanbanProps) {
  const { client, publish, isConnected } = useRxStompClient();

  const [dealInitialDataForActivityForm, setDealInitialDataForActivityForm] =
    useState<TaskType>();

  const [dataSource, setDataSource] = useState<DataSourceType[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dealId, setDealId] = useState<number | undefined>(undefined);

  const handleMoveDeal = useCallback(
    (taskToUpdate: TaskToUpdateType) => {
      publish({
        destination: "/send/deal/move",
        body: JSON.stringify(taskToUpdate),
      });
    },
    [publish],
  );

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDeleteDeal = (task: TaskProp) => {
    setDealId(task.id);
    setIsModalOpen(true);
  };

  useEffect(() => {
    const parsedData: DataSourceType[] = pipelineStages.map((stage) => {
      return {
        id: stage.id,
        record: stage,
        title: stage.name,
        tasks: stage.deals.map((deal) => ({
          id: deal.id,
          record: deal,
          title: deal.name,
          position: deal.displayOrder,
        })),
      };
    });
    setDataSource(parsedData);
  }, [pipelineStages]);

  return (
    <>
      <SolarzKanban
        data-cy="leads-kanban"
        dataSource={dataSource}
        renderHeader={({ column, tasks }) => {
          const totalAmount = tasks.reduce(
            (total, task) => (task.record.value ?? 0) + total,
            0,
          );

          return (
            <Flex vertical gap={8}>
              <Flex align="center" justify="space-between">
                <SolarzTypography
                  fontWeight="medium"
                  hierarchy="paragraph1"
                  variant="title"
                  style={{
                    width: "100%",
                  }}
                  ellipse
                >
                  <Tooltip title={column.title}>{column.title}</Tooltip>
                </SolarzTypography>

                {/* <Tag
                color="green"
                style={{
                  borderWidth: 2,
                  padding: "0 4px",
                  margin: 0,
                }}
              >
                <SolarzTypography
                  hierarchy="tiny"
                  variant="success"
                  fontWeight="medium"
                >
                  {parsePercentage(column.record.probability ?? 0)}
                </SolarzTypography>
              </Tag> */}
              </Flex>

              <Flex align="center" justify="space-between">
                <SolarzTypography
                  fontWeight="regular"
                  variant="subTitle"
                  hierarchy="tiny"
                >
                  {tasks.length} Negócios
                </SolarzTypography>

                <SolarzTypography
                  fontWeight="regular"
                  variant="subTitle"
                  hierarchy="tiny"
                >
                  {parseCurrency(totalAmount)}
                </SolarzTypography>
              </Flex>
            </Flex>
          );
        }}
        renderTask={(task) => {
          const formattedUsername =
            task.record.person?.name || task.record.organization?.name || "-";

          const formattedCurrency = parseCurrency(task.record.value);

          const isProposalLate = false;

          const formattedDaysCount = "0 d";

          return (
            <Deal
              isNextLink
              href={`/funil/negocio/${task.id}`}
              style={{ width: "100%" }}
            >
              <Card
                style={{
                  width: "100%",
                  minHeight: 98,
                  cursor: "pointer",
                  border: isProposalLate
                    ? "1px solid var(--red-500)"
                    : undefined,
                  backgroundColor: isProposalLate ? "var(--red-50)" : undefined,
                  display: "block",
                }}
                title={null}
                size="small"
                hoverable
              >
                <Flex vertical gap={12}>
                  <Flex align="start" justify="space-between" gap={4}>
                    <Flex vertical gap={6}>
                      <SolarzTypography
                        fontWeight="medium"
                        variant="title"
                        hierarchy="paragraph2"
                        style={{ textAlign: "left" }}
                      >
                        {task.title}
                      </SolarzTypography>
                      <SolarzTypography
                        fontWeight="regular"
                        variant="disabled"
                        hierarchy="small"
                        style={{ textAlign: "left" }}
                      >
                        {formattedUsername}
                      </SolarzTypography>
                    </Flex>

                    <CalendarIcon
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setDealInitialDataForActivityForm(task.record);
                      }}
                      className={isProposalLate ? "is-danger" : undefined}
                    >
                      <LuCalendarPlus size={16} />
                    </CalendarIcon>
                  </Flex>

                  <Flex align="center" justify="space-between">
                    <Flex align="center" gap={8}>
                      <Tooltip title={task.record.owner?.name}>
                        <Avatar.CustomUser
                          name={task.record.owner?.name}
                          avatarUrl={task.record.owner?.avatarUrl}
                          userId={task.record.owner?.id}
                          height={24}
                          width={24}
                        />
                      </Tooltip>

                      <SolarzTypography
                        fontWeight="regular"
                        variant="disabled"
                        hierarchy="small"
                      >
                        {formattedCurrency}
                      </SolarzTypography>
                    </Flex>

                    <Badge
                      color="red"
                      count={formattedDaysCount}
                      styles={{
                        indicator: {
                          borderRadius: 4,
                          padding: 4,
                          fontWeight: 600,
                          lineHeight: "12px",
                          fontSize: 10,
                          display: isProposalLate ? undefined : "none",
                        },
                      }}
                    />
                  </Flex>
                </Flex>
              </Card>
            </Deal>
          );
        }}
        emptyMessage=""
        errorMessage={pipelineError?.message}
        isLoading={isLoading}
        disableColumnDragAndDrop
        onChange={({ activeTask, columns, tasks }) => {
          const newDataSource = columns.map((column) => {
            return {
              ...column,
              tasks: tasks.filter((task) => task.columnId === column.id),
            };
          });

          setDataSource(newDataSource);

          if (!activeTask) return;

          const activeTaskColumn = newDataSource.find(
            (col) => col.id === activeTask?.columnId,
          );

          if (!activeTaskColumn) return;

          const position =
            activeTaskColumn.tasks.findIndex(
              (task) => task.id === activeTask.id,
            ) + 1;

          if (activeTask && isConnected) {
            handleMoveDeal({
              dealId: activeTask.id,
              pipelineStageId: activeTask.columnId,
              position: position,
            });
          }
        }}
        onDeleteTask={handleDeleteDeal}
        className={className}
        style={style}
      />

      <Modal.ActivityForm
        isOpen={
          dealInitialDataForActivityForm?.id
            ? dealInitialDataForActivityForm.id > 0
            : false
        }
        onClose={() => {
          setDealInitialDataForActivityForm(undefined);
        }}
        formProps={{
          dealId: dealInitialDataForActivityForm?.id,
          initialValues: {
            addressId:
              dealInitialDataForActivityForm?.person?.address?.id ||
              dealInitialDataForActivityForm?.organization?.address?.id,
            owner: dealInitialDataForActivityForm?.owner?.id,
            organizationId: dealInitialDataForActivityForm?.organization?.id,
            name: dealInitialDataForActivityForm?.name,
            clientId: dealInitialDataForActivityForm?.person?.id,
            dealId: dealInitialDataForActivityForm?.id,
          },
        }}
      />

      <Modal.DeleteDeal
        dealId={dealId ?? 0}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSuccess={() => {
          console.log("Deal deleted");
        }}
      />
    </>
  );
}
