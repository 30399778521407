export * from "./ForbiddenResult/interface";
import { ForbiddenResult } from "./ForbiddenResult";
export * from "./InDevelopmentResult/interface";
import { InDevelopmentResult } from "./InDevelopmentResult";
export * from "./SelectErrorOrEmptyResult/interface";
import { SelectErrorOrEmptyResult } from "./SelectErrorOrEmptyResult";
export * from "./TableErrorOrEmptyResult/interface";
import { TableErrorOrEmptyResult } from "./TableErrorOrEmptyResult";

export const Result = {
  Forbidden: ForbiddenResult,
  InDevelopment: InDevelopmentResult,
  SelectErrorOrEmpty: SelectErrorOrEmptyResult,
  TableErrorOrEmpty: TableErrorOrEmptyResult,
};
