import { useState } from "react";

import type {
  ISolarimetryState,
  ISolarimetryFormValues,
  ISolarimetryFormProps,
} from "./interface";

import { CustomInput } from "~components/CustomInput";
import {
  useFetchGetGlobalIrradiationByCustomId,
  useFetchGetMonthlyRadiationByCustomId,
  useFetchUpdateMonthlyRadiationByCustomId,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { IMonthlySolarRadiationDTOSchema } from "~types/schemas";
import {
  getAverageGlobalIrradiation,
  getAverageMonthlyIrradiation,
  transformDailySolarRadiationToMonthlyRadiation,
  transformGlobalRadiationToMonthlySolarRadiation,
  transformMonthlySolarRadiationToDailyRadiation,
} from "~utils/calculateAverageMonthlyIrradiation";
import {
  Form,
  Row,
  Col,
  Button,
  Spin,
  Alert,
  Divider,
  Radio,
  RadioChangeEvent,
  Flex,
} from "antd";

const MONTHS = [
  { ptBrKey: "Janeiro", key: "jan" },
  { ptBrKey: "Fevereiro", key: "feb" },
  { ptBrKey: "Março", key: "mar" },
  { ptBrKey: "Abril", key: "apr" },
  { ptBrKey: "Maio", key: "may" },
  { ptBrKey: "Junho", key: "jun" },
  { ptBrKey: "Julho", key: "jul" },
  { ptBrKey: "Agosto", key: "aug" },
  { ptBrKey: "Setembro", key: "sep" },
  { ptBrKey: "Outubro", key: "oct" },
  { ptBrKey: "Novembro", key: "nov" },
  { ptBrKey: "Dezembro", key: "dec" },
] as const;

export function SolarimetryForm({
  onSuccess = () => undefined,
  onError = () => undefined,
  onCancel = () => undefined,
  formInstance,
  customId = 0,
  irradiationType,
  setIrradiationType = () => undefined,
}: ISolarimetryFormProps) {
  const { screens } = useAppConfig();

  const [form] = Form.useForm<ISolarimetryFormValues>(formInstance);

  const [solarimetryError, setSolarimetryError] = useState<string>();

  const [canGetGlobalIrradiation, setCanGetGlobalIrradiation] =
    useState<boolean>(false);

  const {
    isFetching: isLoadingGlobalIrradiation,
    refetch: getGlobalIrradiationByCustomId,
  } = useFetchGetGlobalIrradiationByCustomId({
    payload: {
      customId,
    },
    options: {
      onSuccess(data) {
        const parsedData =
          irradiationType === "MONTHLY"
            ? transformGlobalRadiationToMonthlySolarRadiation(data)
            : data;

        const average = getAverageGlobalIrradiation(parsedData);

        const monthlyIrradiation: ISolarimetryState = {
          ...parsedData,
          average,
        };

        form.setFieldsValue(monthlyIrradiation);

        setCanGetGlobalIrradiation(false);

        setSolarimetryError(undefined);
      },
      onError: (error) => {
        setCanGetGlobalIrradiation(false);
        setSolarimetryError(error?.message);
      },
      enabled: canGetGlobalIrradiation,
    },
    dependencyArray: [customId, canGetGlobalIrradiation],
  });

  const {
    isFetching: isLoadingProjectIrradiation,
    refetch: reloadProjectIrradiation,
  } = useFetchGetMonthlyRadiationByCustomId({
    dependencyArray: [customId],
    payload: {
      customId,
    },
    options: {
      enabled: customId > 0,
      onSuccess(data) {
        if (!data?.monthlySolarRadiation) {
          setSolarimetryError(undefined);
          getGlobalIrradiationByCustomId();
          return;
        }
        const parsedData =
          irradiationType === "DAILY" && data.irradiationType === "MONTHLY"
            ? transformMonthlySolarRadiationToDailyRadiation(data)
            : irradiationType === "MONTHLY" && data.irradiationType === "DAILY"
              ? transformDailySolarRadiationToMonthlyRadiation(data)
              : data;

        const average = getAverageMonthlyIrradiation(parsedData);

        const monthlyIrradiation: ISolarimetryState = {
          ...parsedData.monthlySolarRadiation,
          average,
        };

        form.setFieldsValue(monthlyIrradiation);
        setIrradiationType(irradiationType ?? data.irradiationType ?? "DAILY");

        setSolarimetryError(undefined);
      },
      onError(error) {
        setSolarimetryError(error?.message);
      },
    },
  });

  const { mutate, isLoading: isUpdatingMonthlyRadiation } =
    useFetchUpdateMonthlyRadiationByCustomId({
      options: {
        onSuccess: (data) => {
          const average = getAverageMonthlyIrradiation(data ?? {});
          const irradiationType = data?.irradiationType;

          const monthlyIrradiation: ISolarimetryState = {
            ...data?.monthlySolarRadiation,
            irradiationType,
            average,
          };

          return onSuccess(monthlyIrradiation);
        },
        onError: (error) => onError(error.message),
      },
    });

  const handleRadioChange = async (e: RadioChangeEvent) => {
    const newValue = e.target.value;
    setIrradiationType(newValue);
    reloadProjectIrradiation();
  };

  function convertToMonthlySolarRadiation(
    data: ISolarimetryState,
  ): IMonthlySolarRadiationDTOSchema {
    return {
      monthlySolarRadiation: {
        jan: data.jan || 0,
        feb: data.feb || 0,
        mar: data.mar || 0,
        apr: data.apr || 0,
        may: data.may || 0,
        jun: data.jun || 0,
        jul: data.jul || 0,
        aug: data.aug || 0,
        sep: data.sep || 0,
        oct: data.oct || 0,
        nov: data.nov || 0,
        dec: data.dec || 0,
      },
      irradiationType: data.irradiationType || irradiationType || "DAILY",
    };
  }

  const handleSubmit = (fieldsValue: ISolarimetryFormValues) => {
    const convertedData: IMonthlySolarRadiationDTOSchema =
      convertToMonthlySolarRadiation(fieldsValue);

    mutate({
      ...convertedData,
      customId,
    });
  };

  const isLoadingData =
    isLoadingGlobalIrradiation || isLoadingProjectIrradiation;

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      scrollToFirstError
    >
      {solarimetryError && (
        <Alert
          message="Falha ao carregar dados"
          description={solarimetryError}
          type="error"
          showIcon
          style={{ marginBottom: "1rem" }}
          closable
          afterClose={() => setSolarimetryError(undefined)}
        />
      )}

      <Form.Item name="average" initialValue={0} hidden>
        <CustomInput.Number name="average" readOnly disabled />
      </Form.Item>

      <Spin spinning={isLoadingData}>
        <Row gutter={16}>
          {MONTHS.map((month) => (
            <Col key={month.key} span={screens.xs ? 12 : 8}>
              <Form.Item
                name={month.key}
                label={month.ptBrKey}
                initialValue={0}
                rules={[
                  {
                    required: true,
                    message: `Por favor, insira o mês de ${month.ptBrKey}`,
                  },
                ]}
              >
                <CustomInput.UnitMeasurement
                  name={month.key}
                  placeholder={month.ptBrKey}
                  min={0}
                  precision="2"
                  unitMeasurement={
                    irradiationType === "DAILY" ? "Wh/m².dia" : "kWh/m².mes"
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          ))}
        </Row>
      </Spin>

      <Divider />
      <Col>
        <Form.Item name="irradiationType" label={<b>Unidade de irradiação</b>}>
          <Radio.Group
            defaultValue={irradiationType}
            onChange={handleRadioChange}
          >
            <Radio value={"DAILY"}>Por dia (Wh/m².dia)</Radio>
            <Radio value={"MONTHLY"}>Por mês (kWh/m².mes)</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>

      <Flex gap={12} justify="end">
        <Form.Item style={{ margin: 0 }}>
          <Button
            size="small"
            type="link"
            onClick={() => getGlobalIrradiationByCustomId()}
            disabled={isLoadingData}
            data-cy="button-reset-solar-radiation"
          >
            Resetar
          </Button>
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button onClick={onCancel}>Cancelar</Button>
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={isLoadingData}
            loading={isUpdatingMonthlyRadiation}
          >
            Salvar
          </Button>
        </Form.Item>
      </Flex>
    </Form>
  );
}
