import { useMemo } from "react";

import type {
  IOrderStatusSelectProps,
  IOrderStatusSelectOption,
} from "./interface";

import { Select } from "antd";

export function OrderStatusSelect({
  value,
  onChange = () => null,
  defaultValue,
  options = {
    disableConfirmado: false,
    hideConfirmado: false,
    disableEntregue: false,
    hideEntregue: false,
    disableEnviado: false,
    hideEnviado: false,
    disableProdução: false,
    hideProdução: false,
    disableRecebido: false,
    hideRecebido: false,
  },
  ...props
}: IOrderStatusSelectProps) {
  const OPTIONS: IOrderStatusSelectOption[] = useMemo(
    () => [
      {
        label: "Confirmado",
        value: "Confirmado",
        disabled: options.disableConfirmado,
        hide: options.hideConfirmado,
      },
      {
        label: "Entregue",
        value: "Entregue",
        disabled: options.disableEntregue,
        hide: options.hideEntregue,
      },
      {
        label: "Enviado",
        value: "Enviado",
        disabled: options.disableEnviado,
        hide: options.hideEnviado,
      },
      {
        label: "Produção",
        value: "Produção",
        disabled: options.disableProdução,
        hide: options.hideProdução,
      },
      {
        label: "Recebido",
        value: "Recebido",
        disabled: options.disableRecebido,
        hide: options.hideRecebido,
      },
    ],
    [options],
  );

  return (
    <Select
      {...props}
      options={OPTIONS}
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
      placeholder="Selecione..."
    />
  );
}
