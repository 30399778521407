import { LaborFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function LaborFormModal({
  isOpen,
  onClose,
  title = "Mão de obra",
  width = 920,
  formProps = {},
}: LaborFormModalProps) {
  return (
    <Modal
      data-cy="labor-form-modal"
      title={title}
      open={isOpen}
      onCancel={onClose}
      footer={null}
      width={width}
      destroyOnClose
    >
      <Form.Labor onCancel={onClose} {...formProps} />
    </Modal>
  );
}
