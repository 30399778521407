import * as React from "react";

import type { IIconProps } from "../@types/globals";
import { cn } from "../lib/utils";

const Meeting: React.FC<IIconProps> = ({ className, ...props }) => (
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    className={cn("pointer-events-none w-6 h-6 stroke-2", className)}
    {...props}
  >
    <title>Reunião</title>
    <path
      d="M15 9C16.6569 9 18 7.65685 18 6C18 4.34315 16.6569 3 15 3M18 14C19.7542 14.3847 21 15.3589 21 16.5C21 17.5293 19.9863 18.4229 18.5 18.8704M13 6C13 8.20914 11.2091 10 9 10C6.79086 10 5 8.20914 5 6C5 3.79086 6.79086 2 9 2C11.2091 2 13 3.79086 13 6ZM16 17C16 19.2091 12.866 21 9 21C5.13401 21 2 19.2091 2 17C2 14.7909 5.13401 13 9 13C12.866 13 16 14.7909 16 17Z"
      strokeLinecap="round"
    />
  </svg>
);

Meeting.displayName = "Meeting";

export { Meeting };
