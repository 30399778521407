import type { IPersonCustomDataFieldFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function PersonCustomDataFieldFormModal({
  isOpen = false,
  onClose = () => null,
  width = 890,
  formProps = {
    initialValues: undefined,
  },
}: IPersonCustomDataFieldFormModalProps) {
  return (
    <Modal
      data-cy="person-custom-field-form-modal"
      open={isOpen}
      onCancel={onClose}
      title="Campo customizado de pessoa"
      width={width}
      style={{ maxWidth: "90vw" }}
      footer={null}
      destroyOnClose
    >
      <Form.PersonCustomDataField {...formProps} onCancel={onClose} />
    </Modal>
  );
}
