import styled from "@emotion/styled";
import { Select } from "antd";

export const StyledSelect = styled(Select<number>)`
  width: 14.75rem; // 236px
  height: 2rem;
  border-color: var(--gray-300);
  border-radius: 0.5rem;
  color: var(--gray-500);
  font-size: 14px;

  .ant-select-arrow {
    color: var(--primary-500);
  }
`;
