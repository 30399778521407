import type {
  IDiscountKitItemsFormProps,
  IDiscountKitItemsFormValues,
  IDiscountOverCurrencyTypeSelect,
} from "./interface";

import { CustomInput } from "~components/CustomInput";
import { Select } from "~components/Select";
import { Form, Button, Flex } from "antd";
import type { RuleObject } from "antd/lib/form";
import type { StoreValue } from "antd/lib/form/interface";

export function DiscountKitItemsForm({
  onCancel = () => undefined,
  onSubmit = () => null,
  isLoading = false,
  initialValues,
}: IDiscountKitItemsFormProps) {
  const [form] = Form.useForm<IDiscountKitItemsFormValues>();

  const valueType = Form.useWatch("valueType", form);
  const amount = Form.useWatch("amount", form);

  function onChangeValueType(value: IDiscountOverCurrencyTypeSelect) {
    const newFieldValues = {
      valueType: value,
      amount: 0,
    };

    form.setFieldsValue(newFieldValues);
  }

  function amountValidator(_rule: RuleObject, value: StoreValue) {
    const parsedValue = Number(value);

    if (valueType === "Reais" && parsedValue <= 0) {
      return Promise.reject("Valor deve ser maior que zero");
    }

    if (valueType === "Percentual" && (parsedValue <= 0 || parsedValue > 100)) {
      return Promise.reject(
        "Valor deve ser maior que 0% e menor ou igual à 100%",
      );
    }

    return Promise.resolve();
  }

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFinish={onSubmit}
      layout="vertical"
    >
      <Form.Item
        name="valueType"
        label="Tipo do valor"
        rules={[{ required: true }]}
      >
        <Select.CurrencyType value={valueType} onChange={onChangeValueType} />
      </Form.Item>

      {valueType === "Reais" ? (
        <Form.Item
          name="amount"
          label="Valor do Desconto:"
          rules={[{ required: true }, { validator: amountValidator }]}
        >
          <CustomInput.Money min={0} value={valueType} />
        </Form.Item>
      ) : (
        <>
          <Form.Item
            name="amount"
            label="Taxa de desconto:"
            rules={[{ required: true }, { validator: amountValidator }]}
          >
            <CustomInput.UnitMeasurement
              precision="2"
              unitMeasurement="%"
              value={amount}
              min={0}
              max={100}
            />
          </Form.Item>
        </>
      )}

      <Flex align="center" gap={8} justify="end">
        <Button onClick={onCancel}>Cancelar</Button>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Salvar
        </Button>
      </Flex>
    </Form>
  );
}
