import { FC } from "react";

import dynamic from "next/dynamic";

import type { IPDFViewerModalProps } from "./interface";
import styles from "./styles";

import { Loading } from "~components/Loading";
import type { IPDFViewerProps } from "~components/Others/PDFViewer/interface";
import { useAppConfig } from "~hooks/useAppConfig";
import { Modal } from "antd";

const PDFViewer = dynamic<IPDFViewerProps>(
  () =>
    import("~components/Others/PDFViewer").then((module) => module.PDFViewer),
  {
    ssr: false,
    loading: () => <Loading />,
  },
);

export const PDFViewerModal: FC<IPDFViewerModalProps> = ({
  isOpen = false,
  onClose = () => undefined,
  PDFViewerProps,
}) => {
  const { screens } = useAppConfig();

  const pdfWidth = window.innerWidth + 190;

  return (
    <>
      <style jsx>{styles}</style>
      <Modal
        data-cy="pdf-worker-modal"
        className="pdfViewerModal"
        open={isOpen}
        onCancel={onClose}
        closable={false}
        destroyOnClose
        footer={null}
        style={{
          top: 16,
          maxHeight: "100vh",
          width: "720px !important",
        }}
        styles={{
          body: {
            width: "80vw",
            height: "unset !important",
          },
          mask: {
            overflow: "hidden",
            maxHeight: "100vh",
            backdropFilter: "blur(3px)",
          },
        }}
      >
        <PDFViewer
          {...PDFViewerProps}
          contentPdfStyle={{
            background: "unset",
            width: !screens.md ? "90vw" : "80vw",
            maxWidth: "unset",
            height: "unset",
            border: 0,
          }}
          pagePdfStyle={{
            width: pdfWidth,
          }}
          controllerStyle={{
            position: "fixed",
            left: 0,
            top: 0,
          }}
        />
      </Modal>
    </>
  );
};
