import type { IDealStagesPipelineTab } from "./interface";

import { useFetchGetPipelineById } from "~hooks/api";
import { SolarzPipelineTab } from "~solarzui/SolarzPipelineTab";

export function DealStagesPipelineTab({
  pipelineId,
  color,
  onLoad,
  ...props
}: IDealStagesPipelineTab) {
  const {
    data: pipeline,
    isFetching: isLoadingPipeline,
    error: pipelineError,
    refetch: refetchPipeline,
  } = useFetchGetPipelineById({
    payload: {
      pipelineId,
    },
    dependencyArray: [pipelineId],
    options: {
      enabled: pipelineId > 0,
      retry: 1,
      onSuccess: onLoad,
    },
  });

  return (
    <SolarzPipelineTab
      {...props}
      isLoading={isLoadingPipeline}
      emptyMessage="Nenhum estágio encontrado"
      errorMessage={pipelineError?.message}
      refetchFn={() => {
        if (!pipelineId) return;
        refetchPipeline();
      }}
      tabs={pipeline?.stages?.map((stage) => {
        return {
          label: stage.name,
          record: stage,
          color,
        };
      })}
    />
  );
}
