import type { IDiscountKitItemsFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function DiscountKitItemsFormModal({
  isOpen = false,
  onClose = () => null,
  onSubmit = () => null,
  isLoading = false,
  title = "Fornecer desconto no material",
  maxWidth = 512,
  formProps,
}: IDiscountKitItemsFormModalProps) {
  return (
    <Modal
      data-cy="discount-kit-items-form-modal"
      open={isOpen}
      onCancel={onClose}
      title={title}
      width="90vw"
      footer={null}
      style={{ maxWidth }}
      destroyOnClose
    >
      <Form.DiscountKitItems
        isLoading={isLoading}
        onSubmit={onSubmit}
        onCancel={onClose}
        {...formProps}
      />
    </Modal>
  );
}
