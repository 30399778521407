import { BiErrorCircle } from "react-icons/bi";

import {
  ICostAggregatorSelectProps,
  SelectCostAggregatorOptionType,
} from "./interface";

import { useFetchGetAllCostAggregator } from "~hooks/api";
import { Select } from "antd";

export function CostAggregatorSelect({
  onLoad = () => undefined,
  ...props
}: ICostAggregatorSelectProps) {
  const {
    data: costAggregators,
    isFetching: isLoadingCostAggregators,
    error: costAggregatorsError,
  } = useFetchGetAllCostAggregator({
    options: {
      onSuccess: onLoad,
    },
  });

  const dataSource: SelectCostAggregatorOptionType[] = costAggregators
    ? costAggregators.map((cost) => ({
      label: cost.name,
      value: cost.id,
      record: cost,
    }))
    : [];

  return (
    <Select
      {...props}
      loading={isLoadingCostAggregators}
      options={dataSource}
      status={costAggregatorsError ? "error" : ""}
      allowClear
      data-cy="cost-aggregator-select"
      suffixIcon={
        costAggregatorsError ? (
          <BiErrorCircle size={18} color="var(--red-200)" />
        ) : undefined
      }
    />
  );
}
