import { useMemo } from "react";

import { ICompanyDistributorSelectProps } from "./interface";

import { SelectSuffixIcon } from "~components/Others";
import { Result } from "~components/Result";
import { useFetchGetCompanyDistributors } from "~hooks/api";
import { Select } from "antd";

export function CompanyDistributorSelect({
  allowClear = true,
  onChange,
  newOptions,
  ...props
}: ICompanyDistributorSelectProps) {
  const {
    data: companyDistributors = [],
    error,
    isFetching,
    refetch,
  } = useFetchGetCompanyDistributors({
    dependencyArray: [],
    options: {
      retry: 1,
    },
  });

  const OPTIONS = useMemo(() => {
    const allOptions = companyDistributors.map((item) => ({
      label: item.name,
      value: item.id,
      record: item,
      disabled: false,
    }));

    if (newOptions) {
      //deve verificar se o distribuidor já está na lista
      newOptions.forEach((option) => {
        const hasOption = allOptions.some(
          (item) => item.value === option.value,
        );
        if (!hasOption) {
          allOptions.push({
            label: option.label,
            value: option.value,
            record: option.record,
            disabled: option.disabled,
          });
        }
      });
    }

    return allOptions;
  }, [companyDistributors, newOptions]);

  return (
    <Select
      {...props}
      loading={isFetching}
      options={OPTIONS}
      allowClear={allowClear}
      status={error ? "error" : ""}
      suffixIcon={
        <SelectSuffixIcon hasError={!!error} isLoading={isFetching} />
      }
      notFoundContent={
        <Result.SelectErrorOrEmpty
          errorMessage={error?.message}
          emptyMessage="Nenhum distribuidor encontrado"
          loading={isFetching}
          reloadFn={refetch}
        />
      }
      onChange={(value, option) => {
        if (option instanceof Array || !onChange) return;
        onChange(value, option);
      }}
    />
  );
}
