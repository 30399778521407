import { useMemo, useState } from "react";

import type {
  PersonSelectPriorityOptionType,
  PersonSelectRecordType,
  IPersonSelectProps,
} from "./interface";

import { Modal } from "~components/Modal";
import { useFetchGetPaginatedPerson } from "~hooks/api";
import { useSession } from "~hooks/useSession";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzSelect } from "~solarzui/SolarzSelect";
import { parseRemoveAccent } from "~utils/parse";
import { Flex } from "antd";

export function PersonSelect({
  showSearch = true,
  showDropdownRender = false,
  parentTitleModal = "",
  hasOrganization = undefined,
  onCreatePersonSuccess,
  filters,
  ...props
}: IPersonSelectProps) {
  const { user } = useSession();
  const [searchTerm, setSearchTerm] = useState("");
  const [isPersonModalOpen, setIsPersonModalOpen] = useState(false);

  const {
    data: personData,
    isFetching: isLoadingPersonData,
    error: personDataError,
    refetch: refetchPersonData,
  } = useFetchGetPaginatedPerson({
    dependencyArray: [filters?.organizationId],
    payload: {
      page: 0,
      size: 500,
      orgId: filters?.organizationId,
    },
    options: {
      retry: 1,
      enabled: !!user,
    },
  });

  const OPTIONS = useMemo(() => {
    if (!personData?.content) return [];

    if (hasOrganization !== undefined) {
      return (
        (personData?.content
          .filter((person) => {
            if (hasOrganization) {
              return person.organization !== undefined;
            } else {
              return person.organization === undefined;
            }
          })
          .map((person) => ({
            key: person.id,
            value: person.id,
            label: person.name,
            record: person,
            disabled: props.disableOptions?.includes(person.id),
          })) as PersonSelectPriorityOptionType) ?? []
      );
    }

    return (
      (personData?.content.map((person) => ({
        key: person.id,
        value: person.id,
        label: person.name,
        record: person,
        disabled: props.disableOptions?.includes(person.id),
      })) as PersonSelectPriorityOptionType) ?? []
    );
  }, [hasOrganization, personData?.content, props.disableOptions]);

  const filteredOptions =
    showSearch && searchTerm
      ? OPTIONS.filter((option) =>
          parseRemoveAccent(option.record.name)
            .toLowerCase()
            .includes(searchTerm.toLowerCase()),
        )
      : OPTIONS;

  return (
    <>
      <SolarzSelect<PersonSelectRecordType, "id">
        {...props}
        data-cy="person-select"
        options={filteredOptions}
        isLoading={isLoadingPersonData}
        refetchFn={refetchPersonData}
        errorMessage={personDataError?.message}
        emptyMessage="Nenhuma pessoa encontrada"
        searchValue={searchTerm}
        onChangeSearch={(value) => {
          setSearchTerm(value);
        }}
        showSearch={showSearch}
        dropdownRender={
          showDropdownRender
            ? (menu) => {
                return (
                  <Flex vertical>
                    {menu}
                    <Flex vertical style={{ margin: "8px 16px 0 16px" }}>
                      <Flex justify="center">
                        <SolarzButton
                          style={{ margin: "8px 0 4px 0" }}
                          variant="ghost"
                          scale="tiny"
                          onClick={() => setIsPersonModalOpen(true)}
                        >
                          Nova pessoa
                        </SolarzButton>
                      </Flex>
                    </Flex>
                  </Flex>
                );
              }
            : undefined
        }
      />
      <Modal.SelectPersonForm
        parentTitleModal={parentTitleModal}
        isOpen={isPersonModalOpen}
        onClose={() => setIsPersonModalOpen(false)}
        formProps={{
          onSuccess: (organization) => {
            setIsPersonModalOpen(false);

            if (typeof onCreatePersonSuccess === "function") {
              onCreatePersonSuccess(organization);
            }
          },
          initialValues: {
            responsibleSellerId: user?.id || undefined,
            name: searchTerm || undefined,
            organizationId: filters?.organizationId,
          },
          personInTheOrganization: !!filters?.organizationId,
        }}
      />
    </>
  );
}
