import { FC, useEffect, useMemo, useState } from "react";
import {
  AiOutlineEdit,
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineStop,
  AiOutlineCheckCircle,
} from "react-icons/ai";

import type {
  IKitItemsDetailTableProps,
  IKitItemsDetailsTableColumn,
  IKitItemsDetailsTableData,
  IKitItemsDetailsHandleUpdateSubmitParams,
  IGroupedRow,
} from "./interface";

import { Menu } from "~components/Menu";
import { Modal } from "~components/Modal";
import { Tag } from "~components/Tag";
import { useAppConfig } from "~hooks/useAppConfig";
import { parseCurrency } from "~utils/parse";
import { Flex, Table, Tooltip } from "antd";

export const KitItemsDetailsTable: FC<IKitItemsDetailTableProps> = ({
  kitItems = [],
  isLoading = false,
  isLoadingUpdate = false,
  isLoadingDelete = false,
  displayActionColumn = true,
  displayPricesColumn = true,
  displayEnableOrDisableItems = true,
  displayItemInfoIcon = true,
  displayEditOption = true,
  displayRemoveOption = true,
  onSubmitDelete = () => null,
  onSubmitUpdate = () => null,
  onSubmitEnable = () => null,
  selectedKitItemToDelete = undefined,
  selectedKitItemToUpdate = undefined,
  selectedKitItemToEnable = undefined,
  onSelectKitToUpdate = () => null,
  onSelectKitToDelete = () => null,
  onSelectKitToEnable = () => null,
}) => {
  const { message } = useAppConfig();

  const [kitItemToDelete, setKitItemToDelete] = useState<
    IKitItemsDetailsTableData | undefined
  >(selectedKitItemToDelete);

  const [kitItemToUpdate, setKitItemToUpdate] = useState<
    IKitItemsDetailsTableData | undefined
  >(undefined);

  const [kitItemToEnable, setKitItemToEnable] = useState<
    IKitItemsDetailsTableData | undefined
  >(undefined);

  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  // Função para lidar com a expansão de linhas
  const handleExpandRow = (expanded: boolean, record: IGroupedRow) => {
    if (expanded) {
      setExpandedRows((prevExpandedRows) => [...prevExpandedRows, record.key]);
    } else {
      setExpandedRows((prevExpandedRows) =>
        prevExpandedRows.filter((key) => key !== record.key),
      );
    }
  };

  function handleSelectKitItemToDelete(kitItem?: IKitItemsDetailsTableData) {
    setKitItemToDelete(kitItem);
    onSelectKitToDelete(kitItem);
  }

  function handleSelectKitItemToEnable(kitItem: IKitItemsDetailsTableData) {
    setKitItemToEnable(kitItem);
    onSelectKitToEnable(kitItem);
  }

  function handleSelectKitItemToUpdate(kitItem?: IKitItemsDetailsTableData) {
    setKitItemToUpdate(kitItem);
    onSelectKitToUpdate(kitItem);
  }

  function handleHideKitItemOnTemplate(kitItem: IKitItemsDetailsTableData) {
    onSubmitUpdate({ ...kitItem, print: false });
  }

  function handleShowKitItemOnTemplate(kitItem: IKitItemsDetailsTableData) {
    onSubmitUpdate({ ...kitItem, print: true });
  }

  function handleSubmitUpdate(
    kitItem: IKitItemsDetailsHandleUpdateSubmitParams,
  ) {
    if (!kitItemToUpdate) {
      message.error("Client error: faltando o kit selecionado");
      return;
    }

    onSubmitUpdate({
      ...kitItemToUpdate,
      ...kitItem,
      key: kitItemToUpdate.key,
    });
  }

  function handleSubmitDelete() {
    if (!kitItemToDelete) {
      message.error("Client error: faltando o kit selecionado");
      return;
    }

    onSubmitDelete(kitItemToDelete);
  }

  function handleSubmitEnable() {
    if (!kitItemToEnable) {
      message.error("Client error: faltando o kit selecionado");
      return;
    }

    onSubmitEnable(kitItemToEnable);
  }

  const groupedData: IGroupedRow[] = useMemo(() => {
    const result: IGroupedRow[] = [];

    kitItems.forEach((item) => {
      const groupName = item.groupName || "Itens gerais";
      let existingGroup = result.find((group) => group.key === groupName);

      if (!existingGroup) {
        existingGroup = {
          key: groupName,
          group: [],
        };
        result.push(existingGroup);
      }

      existingGroup.group.push(item);
    });

    setExpandedRows(result.map((group) => group.key));

    return result;
  }, [kitItems]);

  const COLUMNS: IKitItemsDetailsTableColumn[] = (
    [
      {
        title: "Item",
        dataIndex: "name",
        render: (value: string, record: IKitItemsDetailsTableData) => {
          const formattedValue = value ?? "-";

          return (
            <Flex wrap="nowrap" gap={4}>
              {formattedValue}
              {displayItemInfoIcon && record.deactivated && (
                <Tooltip title="Este item está desativado">
                  <AiOutlineStop
                    fontSize={16}
                    style={{
                      color: "var(--red-500)",
                    }}
                  />
                </Tooltip>
              )}
              {displayItemInfoIcon && !record.print && (
                <Tooltip title="Este item não será mostrado no template">
                  <AiOutlineEyeInvisible
                    fontSize={16}
                    style={{
                      color: "var(--green-500)",
                    }}
                  />
                </Tooltip>
              )}
              {record.outOfStock && (
                <Tag.UniqueValue
                  title="Sem estoque"
                  color="red"
                  style={{ width: "min-content" }}
                />
              )}
            </Flex>
          );
        },
        width: "18rem",
      },
      {
        title: "Categoria",
        dataIndex: "category",
        width: "10rem",
        render: (value: string) => value ?? "-",
      },
      {
        title: "Quantidade",
        dataIndex: "quantity",
        align: "center" as const,
        width: "6rem",
        render: (value: string) => value ?? "-",
      },
      {
        title: "Valor unitário",
        dataIndex: "unityAmount",
        align: "center" as const,
        width: "6rem",
        render: (value?: number) => (value ? parseCurrency(value) : "-"),
        hidden: !displayPricesColumn,
      },
      {
        title: "Valor total",
        dataIndex: "totalAmount",
        align: "center" as const,
        width: "6rem",
        render: (value?: number) => (value ? parseCurrency(value) : "-"),
        hidden: !displayPricesColumn,
      },
      {
        title: "",
        align: "center" as const,
        width: "4rem",
        render: (_, kitItem) => {
          const hideUpdateOption = !kitItem.manuallyAdded && !displayEditOption;

          const hideDeleteOption =
            !kitItem.manuallyAdded && !displayRemoveOption;

          const hideDisableOption =
            kitItem.manuallyAdded || !displayEnableOrDisableItems;

          return (
            <Menu.MoreDropdown
              position="bottomLeft"
              items={[
                {
                  icon: kitItem.print ? (
                    <AiOutlineEyeInvisible
                      fontSize={16}
                      style={{
                        color: kitItem.deactivated
                          ? "var(--gray-500)"
                          : "var(--blue-500)",
                      }}
                    />
                  ) : (
                    <AiOutlineEye
                      fontSize={16}
                      style={{
                        color: kitItem.deactivated
                          ? "var(--gray-500)"
                          : "var(--blue-500)",
                      }}
                    />
                  ),
                  isDisabled: kitItem.deactivated,
                  text: kitItem.print
                    ? "Não mostrar no template"
                    : "Mostrar no template",
                  color: kitItem.deactivated
                    ? "var(--gray-500)"
                    : "var(--blue-500)",
                  onClick: kitItem.print
                    ? () => handleHideKitItemOnTemplate(kitItem)
                    : () => handleShowKitItemOnTemplate(kitItem),
                },
                {
                  icon: (
                    <AiOutlineEdit
                      fontSize={18}
                      style={{
                        color: kitItem.deactivated
                          ? "var(--gray-500)"
                          : "var(--gold-500)",
                      }}
                    />
                  ),
                  text: "Alterar",
                  color: kitItem.deactivated
                    ? "var(--gray-500)"
                    : "var(--gold-500)",
                  onClick: () => handleSelectKitItemToUpdate(kitItem),
                  isDisabled: kitItem.deactivated,
                  hidden: hideUpdateOption,
                },
                kitItem.deactivated
                  ? {
                      icon: (
                        <AiOutlineCheckCircle
                          fontSize={16}
                          style={{ color: "var(--green-500)" }}
                        />
                      ),
                      text: "Habilitar",
                      color: "var(--green-500)",
                      onClick: () => handleSelectKitItemToEnable(kitItem),
                      hidden: hideDisableOption,
                    }
                  : {
                      icon: (
                        <AiOutlineStop
                          fontSize={16}
                          style={{ color: "var(--volcano-500)" }}
                        />
                      ),
                      text: kitItem.manuallyAdded ? "Remover" : "Desabilitar",
                      color: "var(--volcano-500)",
                      onClick: () => handleSelectKitItemToDelete(kitItem),
                      hidden: kitItem?.manuallyAdded
                        ? hideDeleteOption
                        : hideDisableOption,
                    },
              ]}
            />
          );
        },
        hidden: !displayActionColumn,
      },
    ] as IKitItemsDetailsTableColumn[]
  ).filter((column) => !column.hidden);

  const columns = [
    {
      title: "",
      dataIndex: "key", // Usando "key" para exibir o nome do grupo
      render: (value: string, _record: IGroupedRow) => <strong>{value}</strong>,
    },
  ];

  useEffect(() => {
    setKitItemToUpdate(selectedKitItemToUpdate);
  }, [selectedKitItemToUpdate]);

  useEffect(() => {
    setKitItemToDelete(selectedKitItemToDelete);
  }, [selectedKitItemToDelete]);

  useEffect(() => {
    setKitItemToEnable(selectedKitItemToEnable);
  }, [selectedKitItemToEnable]);

  return (
    <>
      <Table
        data-cy="kit-items-details-table"
        columns={columns}
        dataSource={groupedData}
        expandable={{
          expandedRowRender: (record) => (
            <Table
              dataSource={record.group}
              columns={COLUMNS}
              pagination={false}
              rowClassName={(record) => {
                return `${record.deactivated && "disable"}`;
              }}
              size="small"
              scroll={{
                x: 1020,
              }}
            />
          ),
          rowExpandable: (record) => !!record.group && record.group.length > 0,
          expandedRowKeys: expandedRows,
          onExpand: (expanded, record) => handleExpandRow(expanded, record), // Atualiza a lista de linhas expandidas
        }}
        pagination={false}
        size="small"
        loading={isLoading}
        scroll={{
          x: 1020,
        }}
      />

      {displayActionColumn && (
        <>
          <Modal.KitItemForm
            isOpen={!!kitItemToUpdate}
            onClose={() => setKitItemToUpdate(undefined)}
            formProps={{
              initialValues: kitItemToUpdate,
              onSubmit: handleSubmitUpdate,
              isLoading: isLoadingUpdate,
            }}
          />

          <Modal.DefaultRemove
            isOpen={!!kitItemToDelete}
            onClose={() => setKitItemToDelete(undefined)}
            onSubmit={handleSubmitDelete}
            itemName={kitItemToDelete?.name}
            suffix="kit"
            title={
              kitItemToDelete?.manuallyAdded
                ? "Remover item"
                : "Desabilitar item"
            }
            isLoading={isLoadingDelete}
          />

          <Modal.DefaultAlert
            isOpen={!!kitItemToEnable}
            onClose={() => setKitItemToEnable(undefined)}
            onSubmit={handleSubmitEnable}
            title="Habilitar item"
            subtitle={`Deseja habilitar o item ${kitItemToEnable?.name}`}
            isLoading={isLoadingDelete}
          />
        </>
      )}
    </>
  );
};
