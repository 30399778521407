import { useEffect, useMemo, useState } from "react";

import { IVariablesMentionsProps } from "./interface";

import {
  useFetchGetAllCustomVariables,
  useFetchGetAllVariables,
} from "~hooks/api";
import { createUuid } from "~utils/createUuid";
import { Mentions } from "antd";
import { MentionsOptionProps } from "antd/lib/mentions";

export function VariablesMentions({
  onChange = () => null,
  initialValueField,
  ...props
}: IVariablesMentionsProps) {
  const [value, setValue] = useState(initialValueField ?? "");

  const { data: variables, isFetching: isLoadingVariables } =
    useFetchGetAllVariables({});

  const { data: customVariables, isFetching: isLoadingCustomVariables } =
    useFetchGetAllCustomVariables({});

  const variableOptions = useMemo(() => {
    return [
      ...(variables
        ?.filter((variable) => !!variable.description)
        .map((variable) => ({
          expression: variable.placeholder.replace("$", ""),
          value: variable.description,
          key: createUuid(),
          label: variable.description,
        })) ?? []),
      ...(customVariables
        ?.filter((customVariable) => !!customVariable.description)
        .map((customVariable) => ({
          expression: customVariable.value.replace("$", ""),
          value: customVariable.description,
          label: customVariable.description,
          key: createUuid(),
        })) ?? []),
    ];
  }, [variables, customVariables]);

  const onSelect = (option: MentionsOptionProps) => {
    const search = option.value ?? "";
    const searchValue =
      variableOptions?.find((variable) => variable.value === search)
        ?.expression ?? "";

    setValue((prevState) => prevState.replaceAll(search, searchValue));
  };

  useEffect(() => {
    onChange(value);
  }, [value, onChange]);

  function handleChange(value: string) {
    setValue(value);
  }

  return (
    <Mentions
      data-cy="variables-mentions"
      {...props}
      placeholder="Ex: ${variavel_do_sistema}/${variavel_do_sistema}"
      prefix={"$"}
      options={variableOptions}
      value={value}
      validateSearch={(text) => {
        if (text.length >= 3 && !text.includes("}")) return true;
        return false;
      }}
      split=""
      loading={isLoadingVariables || isLoadingCustomVariables}
      onSelect={onSelect}
      onChange={handleChange}
    />
  );
}
