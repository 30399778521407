import type { IUserGroupFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function UserGroupFormModal({
  isOpen = false,
  onClose = () => null,
  title = "Grupos de usuários",
  maxWidth = 612,
  formProps = {},
}: IUserGroupFormModalProps) {
  return (
    <Modal
      data-cy="user-group-form-modal"
      open={isOpen}
      onCancel={onClose}
      title={title}
      width="90vw"
      footer={null}
      style={{ maxWidth }}
      destroyOnClose
    >
      <Form.UserGroup onCancel={onClose} {...formProps} />
    </Modal>
  );
}
