export * from "./ACMaterialFormModal/interface";
import { ACMaterialFormModal } from "./ACMaterialFormModal";
export * from "./ActivateDistributorKitFilterFormModal/interface";
import { ActivateDistributorKitFilterFormModal } from "./ActivateDistributorKitFilterFormModal";
export * from "./ActivityFormModal/interface";
import { ActivityFormModal } from "./ActivityFormModal";
export * from "./AdditionalCostTaxFormModal/interface";
import { AdditionalCostTaxFormModal } from "./AdditionalCostTaxFormModal";
export * from "./AddPersonToOrganizationFormModal/interface";
import { AddPersonToOrganizationFormModal } from "./AddPersonToOrganizationFormModal";
export * from "./AddUserFormModal/interface";
import { AddUserFormModal } from "./AddUserFormModal";
export * from "./AdminFormModal/interface";
export * from "./AdminCompanyConfigurationFormModal/interface";
import { AdminCompanyConfigurationFormModal } from "./AdminCompanyConfigurationFormModal";
export * from "./AdminCompanyConfigurationFormModal/interface";
export * from "./AdminDatasheetRevisionsTableModal/interface";
import { AdminDatasheetRevisionsTableModal } from "./AdminDatasheetRevisionsTableModal";
export * from "./AdminFormModal/interface";
import { AdminFormModal } from "./AdminFormModal";
export * from "./AdminInverterDetailsModal/interface";
import { AdminInverterDetailsModal } from "./AdminInverterDetailsModal";
export * from "./AdminInverterFormModal/interface";
import { AdminInverterFormModal } from "./AdminInverterFormModal";
export * from "./AdminModuleDetailsModal/interface";
import { AdminModuleDetailsModal } from "./AdminModuleDetailsModal";
export * from "./AdminModuleFormModal/interface";
import { AdminModuleFormModal } from "./AdminModuleFormModal";
export * from "./AldoIntegrationFormModal/interface";
import { AldoIntegrationFormModal } from "./AldoIntegrationFormModal";
export * from "./AneelTableModal/interface";
import { AneelTableModal } from "./AneelTableModal";
export * from "./AssignVariableWithPipedriveFormModal/interface";
import { AssignVariableWithPipedriveFormModal } from "./AssignVariableWithPipedriveFormModal";
export * from "./AuxiliaryVariablesFormModal/interface";
import { AuxiliaryVariablesFormModal } from "./AuxiliaryVariablesFormModal";
export * from "./BaseAddressFormModal/interface";
import { BaseAddressFormModal } from "./BaseAddressFormModal";
export * from "./BusinessUnitFormModal/interface";
import { BusinessUnitFormModal } from "./BusinessUnitFormModal";
export * from "./ChangeDealTotalValueTypeFormModal/interface";
import { ChangeDealTotalValueTypeFormModal } from "./ChangeDealTotalValueTypeFormModal";
export * from "./ChangeIsTemplateModal/interface";
import { ChangeIsTemplateModal } from "./ChangeIsTemplateModal";
export * from "./ChangePasswordFormModal/interface";
import { ChangePasswordFormModal } from "./ChangePasswordFormModal";
export * from "./ChangeProfileImageFormModal/interface";
import { ChangeProfileImageFormModal } from "./ChangeProfileImageFormModal";
export * from "./ChangeUserBusinessUnitFormModal/interface";
import { ChangeUserBusinessUnitFormModal } from "./ChangeUserBusinessUnitFormModal";
export * from "./ChangeUserProfileFormModal/interface";
import { ChangeUserProfileFormModal } from "./ChangeUserProfileFormModal";
export * from "./ComplementaryItemFormModal/interface";
import { ComplementaryItemFormModal } from "./ComplementaryItemFormModal";
export * from "./ComplementaryItemsOfSettingsFormModal/interface";
import { ComplementaryItemsOfSettingsFormModal } from "./ComplementaryItemsOfSettingsFormModal";
export * from "./ComplementaryItemsSpreadsheetUploadFormModal/interface";
import { ComplementaryItemsSpreadsheetUploadFormModal } from "./ComplementaryItemsSpreadsheetUploadFormModal";
export * from "./ConcessionairesProfileFormModal/interface";
import { ConcessionairesProfileFormModal } from "./ConcessionairesProfileFormModal";
export * from "./ConcessionairesProfilesListModal/interface";
import { ConcessionairesProfilesListModal } from "./ConcessionairesProfilesListModal";
export * from "./ConsumerUnitsFormModal/interface";
import { ConsumerUnitsFormModal } from "./ConsumerUnitsFormModal";
export * from "./CopyBusinessUnitModalForm/interface";
import { CopyBusinessUnitModalForm } from "./CopyBusinessUnitModalForm";
export * from "./CostAggregatorFormModal/interface";
import { CostAggregatorFormModalFormModal } from "./CostAggregatorFormModal";
export * from "./UpdateProposalDealFormModal/interface";
export * from "./CustomVariableFormModal/interface";
import { CustomVariableFormModal } from "./CustomVariableFormModal";
export * from "./DatasheetFormModal/interface";
import { DatasheetFormModal } from "./DatasheetFormModal";
export * from "./DatasheetLastRevisionModal/interface";
import { DatasheetLastRevisionModal } from "./DatasheetLastRevisionModal";
export * from "./DealCustomFieldGroupFormModal/interface";
import { DealCustomFieldGroupFormModal } from "./DealCustomFieldGroupFormModal";
export * from "./DealCustomFieldsFormModal/interface";
import { DealCustomFieldsFormModal } from "./DealCustomFieldsFormModal";
export * from "./DealCustomVariableFormModal/interface";
import { DealCustomVariableFormModal } from "./DealCustomVariableFormModal";
export * from "./DealDefaultDataFieldFormModal/interface";
import { DealDefaultFieldFormModal } from "./DealDefaultDataFieldFormModal";
export * from "./DealFormModal/interface";
import { DealFormModal } from "./DealFormModal";
export * from "./DefaultAlertModal/interface";
import { DefaultAlertModal } from "./DefaultAlertModal";
export * from "./DefaultRemoveModal/interface";
import { DefaultRemoveModal } from "./DefaultRemoveModal";
export * from "./DeleteCustomFieldDealModal/interface";
import { DeleteCustomFieldDealModal } from "./DeleteCustomFieldDealModal";
export * from "./DeleteDatasheetModal/interface";
import { DeleteDatasheetModal } from "./DeleteDatasheetModal";
export * from "./DeleteDealCustomFieldGroupFormModal/interface";
import { DeleteDealCustomFieldGroupFormModal } from "./DeleteDealCustomFieldGroupFormModal";
export * from "./DeleteDealModal/interface";
import { DeleteDealModal } from "./DeleteDealModal";
export * from "./DeleteIntegrationApiModal/interface";
import { DeleteIntegrationApiModal } from "./DeleteIntegrationApiModal";
export * from "./DeleteIntegrationLinksModal/interface";
import { DeleteIntegrationLinksModal } from "./DeleteIntegrationLinksModal";
export * from "./DeleteOrganizationModal/interface";
import { DeleteOrganizationModal } from "./DeleteOrganizationModal";
export * from "./DeletePersonModal/interface";
import { DeletePersonModal } from "./DeletePersonModal";
export * from "./DeletePipelineFormModal/interface";
import { DeletePipelineFormModal } from "./DeletePipelineFormModal";
export * from "./DeletePipelineStageFormModal/interface";
import { DeletePipelineStageFormModal } from "./DeletePipelineStageFormModal";
export * from "./DeleteReasonForLossModal/interface";
import { DeleteReasonForLossModal } from "./DeleteReasonForLossModal";
export * from "./DiscountKitItemsFormModal/interface";
import { DiscountKitItemsFormModal } from "./DiscountKitItemsFormModal";
export * from "./DiverseKitsTableModal/interface";
import { DiverseKitsTableModal } from "./DiverseKitsTableModal";
export * from "./EdeltecIntegrationFormModal/interface";
import { EdeltecIntegrationFormModal } from "./EdeltecIntegrationFormModal";
export * from "./KitItemFormModal/interface";
import { EmptyKitFormModal } from "./EmptyKitFormModal";
export * from "./EmptyKitFormModal/interface";
import { GoogleMapSatelliteModal } from "./GoogleMapSatelliteModal";
export * from "./GoogleMapSatelliteModal/interface";
import { GTSolarIntegrationFormModal } from "./GTSolarIntegrationFormModal";
export * from "./ImportUserFromPipedriveFormModal/interface";
import { ImportUserFromPipedriveFormModal } from "./ImportUserFromPipedriveFormModal";
export * from "./ImportUserListModal/interface";
import { ImportUserListModal } from "./ImportUserListModal";
export * from "./GTSolarIntegrationFormModal/interface";
import { KitItemFormModal } from "./KitItemFormModal";
export * from "./KitItemsDetailsTableModal/interface";
import { KitItemMultipleFormModal } from "./KitItemMultipleFormModal";
export * from "./KitItemMultipleFormModal/interface";
import { KitItemsDetailsTableModal } from "./KitItemsDetailsTableModal";
export * from "./KitsTableModal/interface";
import { KitsTableModal } from "./KitsTableModal";
export * from "./KomecoIntegrationFormModal/interface";
import { KomecoIntegrationFormModal } from "./KomecoIntegrationFormModal";
export * from "./LaborFormModal/interface";
import { LaborFormModal } from "./LaborFormModal";
export * from "./LaborStructuresFormModal/interface";
import { LaborStructuresFormModal } from "./LaborStructuresFormModal";
export * from "./LostDealFormModal/interface";
export * from "./LinkUserWithPipedriveModal/interface";
import { LinkUserWithPipedriveModal } from "./LinkUserWithPipedriveModal";
import { LostDealFormModal } from "./LostDealFormModal";
export * from "./ManageUsersModal/interface";
import { ManageUsersModal } from "./ManageUsersModal";
export * from "./MonthFormModal/interface";
import { MonthFormModal } from "./MonthFormModal";
export * from "./MonthlyFormModal/interface";
import { MonthlyFormModal } from "./MonthlyFormModal";
export * from "./OrganizationDefaultDataFieldFormModal/interface";
import { OrganizationDefaultFieldFormModal } from "./OrganizationDefaultDataFieldFormModal";
export * from "./OrganizationFormModal/interface";
import { OrganizationFormModal } from "./OrganizationFormModal";
export * from "./OrganizationOrPersonTabsFormModal/interface";
import { OrganizationOrPersonTabsFormModal } from "./OrganizationOrPersonTabsFormModal";
export * from "./PaymentSimulationFormModal/interface";
import { PaymentSimulationFormModal } from "./PaymentSimulationFormModal";
export * from "./PDFViewerModal/interface";
import { PDFViewerModal } from "./PDFViewerModal";
export * from "./PerformanceProfileFormModal/interface";
import { PerformanceProfileFormModal } from "./PerformanceProfileFormModal";
export * from "./PerformanceRateMonthFormModal/interface";
import { PerformanceRateMonthFormModal } from "./PerformanceRateMonthFormModal";
export * from "./PersonCustomDataFieldFormModal/interface";
import { PersonCustomDataFieldFormModal } from "./PersonCustomDataFieldFormModal";
export * from "./PersonDefaultDataFieldFormModal/interface";
import { PersonDefaultDataFieldFormModal } from "./PersonDefaultDataFieldFormModal";
export * from "./PersonFormModal/interface";
import { PersonFormModal } from "./PersonFormModal";
export * from "./PipedriveUsersListModal/interface";
import { PipedriveUsersListModal } from "./PipedriveUsersListModal";
export * from "./PricingFormModal/interface";
import { PricingFormModal } from "./PricingFormModal";
export * from "./ProductsImportHistoryListModal/interface";
import { ProductsImportHistoryListModal } from "./ProductsImportHistoryListModal";
export * from "./ProductTypeOtherFormModal/interface";
import { ProductTypeOtherFormModal } from "./ProductTypeOtherFormModal";
export * from "./ProfileFormModal/interface";
import { ProfileFormModal } from "./ProfileFormModal";
export * from "./ProposalCustomDataFieldFormModal/interface";
import { ProposalCustomDataFieldFormModal } from "./ProposalCustomDataFieldFormModal";
export * from "./ProposalCustomFieldFormModal/interface";
import { ProposalCustomFieldFormModal } from "./ProposalCustomFieldFormModal";
export * from "./ProposalDataFieldProfileSettingsFormModal/interface";
import { ProposalDataFieldProfileSettingsFormModal } from "./ProposalDataFieldProfileSettingsFormModal";
export * from "./ProposalDefaultDataFieldFormModal/interface";
import { ProposalDefaultDataFieldFormModal } from "./ProposalDefaultDataFieldFormModal";
export * from "./ProposalDefaultDataFieldProfileSettingsFormModal/interface";
import { ProposalDefaultDataFieldProfileSettingsFormModal } from "./ProposalDefaultDataFieldProfileSettingsFormModal";
export * from "./ProposalExpirationRuleFormModal/interface";
import { ProposalExpirationRuleFormModal } from "./ProposalExpirationRuleFormModal";
export * from "./ProposalHistoryModal/interface";
import { ProposalHistoryModal } from "./ProposalHistoryModal";
export * from "./ProposalViewsModal/interface";
import { ProposalViewsModal } from "./ProposalViewsModal";
export * from "./ReasonForLossFormModal/interface";
import { ReasonForLossFormModal } from "./ReasonForLossFormModal";
export * from "./RemovePersonFromOrganizationModal/interface";
import { RemovePersonFromOrganizationModal } from "./RemovePersonFromOrganizationModal";
export * from "./SelectConcessionaireProfileFormModal/interface";
import { SelectConcessionaireProfileFormModal } from "./SelectConcessionaireProfileFormModal";
export * from "./SelectOrganizationFormModal/interface";
import { SelectOrganizationFormModal } from "./SelectOrganizationFormModal";
export * from "./SelectPersonFormModal";
import { SelectPersonFormModal } from "./SelectPersonFormModal";
export * from "./SendProposalWhatsAppMessageFormModal/interface";
import { SendProposalWhatsAppMessageFormModal } from "./SendProposalWhatsAppMessageFormModal";
export * from "./SendTemplateFormModal/interface";
import { SendTemplateFormModal } from "./SendTemplateFormModal";
export * from "./ServicesDealFormModal/interface";
import { ServicesDealFormModal } from "./ServicesDealFormModal";
export * from "./SetProposalStatusModal/interface";
import { SetProposalStatusModal } from "./SetProposalStatusModal";
export * from "./SimultaneityProfilesFormModal/interface";
import { SimultaneityProfileFormModal } from "./SimultaneityProfilesFormModal";
export * from "./SizingKitFormModal/interface";
import { SizingKitFormModal } from "./SizingKitFormModal";
export * from "./SolarimetryFormModal/interface";
import { SolarimetryFormModal } from "./SolarimetryFormModal";
export * from "./TransformerGroupFormModal/interface";
import { TransformerGroupFormModal } from "./TransformerGroupFormModal";
export * from "./TransformerGroupRuleFormModal/interface";
import { TransformerGroupRuleFormModal } from "./TransformerGroupRuleFormModal";
export * from "./UnlinkUserWithPipedriveModal/interface";
import { UnlinkUserWithPipedriveModal } from "./UnlinkUserWithPipedriveModal";
export * from "./UpdatePipedriveTokenApiFormModal/interface";
import { UpdatePipedriveTokenApiFormModal } from "./UpdatePipedriveTokenApiFormModal";
import { UpdateProposalDealFormModal } from "./UpdateProposalDealFormModal";
export * from "./UserFormModal/interface";
import { UserFormModal } from "./UserFormModal";
export * from "./UserGroupFormModal/interface";
import { UserGroupFormModal } from "./UserGroupFormModal";
export * from "./UserSettingsFormModal/interface";
import { UserSettingsFormModal } from "./UserSettingsFormModal";
export * from "./ValidationRuleFormModal/interface";
import { ValidationRuleFormModal } from "./ValidationRuleFormModal";
export * from "./ViewActivityModal/interface";
import { ViewActivityModal } from "./ViewActivityModal";
export * from "./WegIntegrationFormModal/interface";
import { WegIntegrationFormModal } from "./WegIntegrationFormModal";
export * from "./WonDealModal/interface";
import { WonDealModal } from "./WonDealModal";
export * from "./YouTubeVideoModal/interface";
import { YouTubeVideoModal } from "./YouTubeVideoModal";

export const Modal = {
  AdminForm: AdminFormModal,
  AdminInverterDetails: AdminInverterDetailsModal,
  AdminInverterForm: AdminInverterFormModal,
  AdminModuleDetails: AdminModuleDetailsModal,
  AdminModuleForm: AdminModuleFormModal,
  ACMaterialForm: ACMaterialFormModal,
  ActivityForm: ActivityFormModal,
  ActivateDistributorKitFilterForm: ActivateDistributorKitFilterFormModal,
  AdditionalCostTaxForm: AdditionalCostTaxFormModal,
  AddPersonToOrganizationForm: AddPersonToOrganizationFormModal,
  AddUserForm: AddUserFormModal,
  AdminCompanyConfigurationForm: AdminCompanyConfigurationFormModal,
  AdminDatasheetRevisionsTable: AdminDatasheetRevisionsTableModal,
  AldoIntegrationForm: AldoIntegrationFormModal,
  AneelTable: AneelTableModal,
  AssignVariableWithPipedriveForm: AssignVariableWithPipedriveFormModal,
  AuxiliaryVariablesForm: AuxiliaryVariablesFormModal,
  BaseAddressForm: BaseAddressFormModal,
  BusinessUnitForm: BusinessUnitFormModal,
  ChangeIsTemplate: ChangeIsTemplateModal,
  ChangePasswordForm: ChangePasswordFormModal,
  ChangeProfileImageForm: ChangeProfileImageFormModal,
  ChangeUserBusinessUnitForm: ChangeUserBusinessUnitFormModal,
  ChangeUserProfileForm: ChangeUserProfileFormModal,
  ComplementaryItemForm: ComplementaryItemFormModal,
  ComplementaryItemsOfSettingsForm: ComplementaryItemsOfSettingsFormModal,
  ComplementaryItemsSpreadsheetUploadForm:
    ComplementaryItemsSpreadsheetUploadFormModal,
  ConcessionairesProfileForm: ConcessionairesProfileFormModal,
  ConcessionairesProfilesList: ConcessionairesProfilesListModal,
  ConsumerUnitsForm: ConsumerUnitsFormModal,
  CopyBusinessUnitModal: CopyBusinessUnitModalForm,
  CostAggregatorFormModalForm: CostAggregatorFormModalFormModal,
  CustomVariableForm: CustomVariableFormModal,
  ChangeDealTotalValueTypeForm: ChangeDealTotalValueTypeFormModal,
  DefaultAlert: DefaultAlertModal,
  DefaultRemove: DefaultRemoveModal,
  DealCustomFieldsForm: DealCustomFieldsFormModal,
  DeleteCustomFieldDeal: DeleteCustomFieldDealModal,
  DeleteDeal: DeleteDealModal,
  DeleteIntegrationApi: DeleteIntegrationApiModal,
  DeleteIntegrationLinks: DeleteIntegrationLinksModal,
  DeleteOrganization: DeleteOrganizationModal,
  DeletePerson: DeletePersonModal,
  DeletePipelineForm: DeletePipelineFormModal,
  DeletePipelineStageForm: DeletePipelineStageFormModal,
  DeleteReasonForLoss: DeleteReasonForLossModal,
  DatasheetForm: DatasheetFormModal,
  DealCustomFieldGroupForm: DealCustomFieldGroupFormModal,
  DatasheetLastRevision: DatasheetLastRevisionModal,
  DealCustomVariableForm: DealCustomVariableFormModal,
  DeleteDatasheet: DeleteDatasheetModal,
  DeleteDealCustomFieldGroupForm: DeleteDealCustomFieldGroupFormModal,
  DiscountKitItemsForm: DiscountKitItemsFormModal,
  DiverseKitsTable: DiverseKitsTableModal,
  DealDefaultFieldForm: DealDefaultFieldFormModal,
  EdeltecIntegrationForm: EdeltecIntegrationFormModal,
  EmptyKitForm: EmptyKitFormModal,
  GoogleMapSatellite: GoogleMapSatelliteModal,
  GTSolarIntegrationForm: GTSolarIntegrationFormModal,
  ImportUserFromPipedriveForm: ImportUserFromPipedriveFormModal,
  ImportUserList: ImportUserListModal,
  KitItemForm: KitItemFormModal,
  KitItemMultipleForm: KitItemMultipleFormModal,
  KitItemsDetails: KitItemsDetailsTableModal,
  KitsTable: KitsTableModal,
  KomecoIntegrationForm: KomecoIntegrationFormModal,
  LaborForm: LaborFormModal,
  LaborStructuresForm: LaborStructuresFormModal,
  LostDealForm: LostDealFormModal,
  DealForm: DealFormModal,
  LinkUserWithPipedrive: LinkUserWithPipedriveModal,
  ManageUsers: ManageUsersModal,
  UserSettingsForm: UserSettingsFormModal,
  MonthForm: MonthFormModal,
  MonthlyForm: MonthlyFormModal,
  OrganizationForm: OrganizationFormModal,
  OrganizationOrPersonTabsForm: OrganizationOrPersonTabsFormModal,
  OrganizationDefaultFieldForm: OrganizationDefaultFieldFormModal,
  PaymentSimulationForm: PaymentSimulationFormModal,
  PerformanceProfileForm: PerformanceProfileFormModal,
  PerformanceRateMonthForm: PerformanceRateMonthFormModal,
  PersonCustomDataFieldForm: PersonCustomDataFieldFormModal,
  PersonDefaultDataFieldForm: PersonDefaultDataFieldFormModal,
  PersonForm: PersonFormModal,
  PDFViewer: PDFViewerModal,
  PipedriveUsersList: PipedriveUsersListModal,
  PricingForm: PricingFormModal,
  ProductImportHistoryList: ProductsImportHistoryListModal,
  ProductTypeOtherForm: ProductTypeOtherFormModal,
  ProfileForm: ProfileFormModal,
  ProposalCustomDataFieldForm: ProposalCustomDataFieldFormModal,
  ProposalCustomFieldForm: ProposalCustomFieldFormModal,
  ProposalDataFieldProfileSettingsForm:
    ProposalDataFieldProfileSettingsFormModal,
  ProposalDefaultDataFieldForm: ProposalDefaultDataFieldFormModal,
  ProposalDefaultDataFieldProfileSettingsForm:
    ProposalDefaultDataFieldProfileSettingsFormModal,
  ProposalExpirationRuleForm: ProposalExpirationRuleFormModal,
  ProposalHistory: ProposalHistoryModal,
  ProposalViews: ProposalViewsModal,
  ReasonForLossForm: ReasonForLossFormModal,
  RemovePersonFromOrganization: RemovePersonFromOrganizationModal,
  SelectConcessionaireProfileForm: SelectConcessionaireProfileFormModal,
  SelectOrganizationForm: SelectOrganizationFormModal,
  SelectPersonForm: SelectPersonFormModal,
  SendProposalWhatsAppMessageForm: SendProposalWhatsAppMessageFormModal,
  SendTemplateForm: SendTemplateFormModal,
  ServicesDealForm: ServicesDealFormModal,
  SetProposalStatus: SetProposalStatusModal,
  SimultaneityProfileForm: SimultaneityProfileFormModal,
  SizingKitForm: SizingKitFormModal,
  SolarimetryForm: SolarimetryFormModal,
  TransformerGroupForm: TransformerGroupFormModal,
  TransformerRuleForm: TransformerGroupRuleFormModal,
  UnlinkUserWithPipedrive: UnlinkUserWithPipedriveModal,
  UpdatePipedriveTokenApiForm: UpdatePipedriveTokenApiFormModal,
  UserForm: UserFormModal,
  UserGroupForm: UserGroupFormModal,
  ValidationRuleForm: ValidationRuleFormModal,
  ViewActivity: ViewActivityModal,
  WegIntegrationForm: WegIntegrationFormModal,
  WonDeal: WonDealModal,
  YouTubeVideo: YouTubeVideoModal,
  UpdateProposalDealForm: UpdateProposalDealFormModal,
};
