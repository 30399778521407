import type { ISolarzAnchorProps } from "./interface";

import styled from "@emotion/styled";

export const StyledAnchor = styled.button<ISolarzAnchorProps>`
  padding: 0;
  margin: 0;
  border: 0;
  background: none;
  border-radius: 2px;
  color: var(--primary-500);
  cursor: pointer;

  a {
    text-decoration: ${(props) =>
      props.defaultUnderlined ? "underline" : "none"};

    :hover {
      text-decoration: underline;
    }
  }

  &[disabled="disabled"],
  &:disabled {
    cursor: not-allowed !important;
    color: var(--gray-400);

    a {
      color: var(--gray-400);
      text-decoration: none;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  &:not([disabled="disabled"]):focus,
  &:not(:disabled):focus,
  &:not([disabled="disabled"]):focus-within,
  &:not(:disabled):focus-within {
    outline: 2px solid var(--primary-300) !important;
    outline-offset: 0.125rem;
  }
`;
