import { useMemo, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { CgInpicture } from "react-icons/cg";

import type {
  IYouTubeVideoModalProps,
  IYouTubeVideoModalStyleOptions,
  IYouTubeVideoOptions,
} from "./interface";
import { StyledModal } from "./styles";

import { Button, Space } from "antd";

const DEFAULT_STYLE_OPTIONS: IYouTubeVideoModalStyleOptions = {
  inFullModeWidth: "820px",
  inPictureModeWidth: "360px",
  inFullModeHeight: "820px",
  inPictureModeHeight: "360px",
};

const DEFAULT_YOUTUBE_OPTIONS: IYouTubeVideoOptions = {
  youTubeVideoCode: "",
  autoPlay: false,
  mute: false,
  keyboardControls: true,
  controls: true,
  title: "",
};

export function YouTubeVideoModal({
  isOpen = false,
  onClose = () => undefined,
  title,
  youTubeOptions,
  styleOptions,
  initInPictureMode,
}: IYouTubeVideoModalProps) {
  const STYLE_OPTIONS = useMemo(
    () => ({
      ...DEFAULT_STYLE_OPTIONS,
      ...styleOptions,
    }),
    [styleOptions],
  );

  const YOUTUBE_OPTIONS = useMemo(
    () => ({
      ...DEFAULT_YOUTUBE_OPTIONS,
      ...youTubeOptions,
    }),
    [youTubeOptions],
  );

  const YOUTUBE_VIDEO_URL = useMemo(() => {
    const isMute = YOUTUBE_OPTIONS.mute ? "mute=1" : "mute=0";
    const isAutoPlay = YOUTUBE_OPTIONS.autoPlay ? "autoplay=1" : "autoplay=0";
    const isKeyboard = YOUTUBE_OPTIONS.keyboardControls
      ? "disablekb=0"
      : "disablekb=1";
    const isControls = YOUTUBE_OPTIONS.controls ? "controls=1" : "controls=0";

    const youTubeUrl =
      "https://www.youtube.com/embed/" +
      YOUTUBE_OPTIONS.youTubeVideoCode +
      "?" +
      isMute +
      "&" +
      isAutoPlay +
      "&" +
      isKeyboard +
      "&" +
      isControls;

    return youTubeUrl;
  }, [YOUTUBE_OPTIONS]);

  const [isPictureInPictureMode, setIsPictureInPictureMode] =
    useState<boolean>(!!initInPictureMode);

  function handleChangePictureMode() {
    setIsPictureInPictureMode((current) => !current);
  }

  return (
    <StyledModal
      data-cy="youTube-video-modal"
      open={isOpen}
      closable={false}
      onCancel={() => undefined}
      afterClose={() => undefined}
      title={title}
      footer={null}
      style={{ maxWidth: "90vw" }}
      width={
        isPictureInPictureMode
          ? STYLE_OPTIONS.inPictureModeWidth
          : STYLE_OPTIONS.inFullModeWidth
      }
      mask={!isPictureInPictureMode}
      isPictureInPictureMode={isPictureInPictureMode}
      inFullModeHeight={STYLE_OPTIONS.inFullModeHeight}
      inFullModeWidth={STYLE_OPTIONS.inFullModeWidth}
      inPictureModeHeight={STYLE_OPTIONS.inPictureModeHeight}
      inPictureModeWidth={STYLE_OPTIONS.inPictureModeWidth}
      destroyOnClose
    >
      <iframe
        width="100%"
        height="100%"
        src={YOUTUBE_VIDEO_URL}
        title={youTubeOptions?.title}
      />

      <Space
        direction="vertical"
        size={0}
        style={{
          position: "absolute",
          top: isPictureInPictureMode ? "0rem" : "-0.5rem",
          right: isPictureInPictureMode ? "auto" : "-2.5rem",
          left: isPictureInPictureMode ? "-1.75rem" : "auto",
          border: "none",
          padding: "0.125rem",
          backgroundColor: isPictureInPictureMode ? "var(--white)" : "unset",
        }}
      >
        <Button
          size="large"
          type="link"
          onClick={() => {
            onClose();
            setIsPictureInPictureMode(false);
          }}
          style={{
            border: "none",
            padding: "0.125rem",
            height: "auto",
            color: isPictureInPictureMode
              ? "var(--primary-500)"
              : "var(--white)",
          }}
        >
          <AiOutlineClose size={isPictureInPictureMode ? "1.250rem" : "2rem"} />
        </Button>
        <Button
          size="large"
          type="link"
          onClick={() => handleChangePictureMode()}
          style={{
            border: "none",
            padding: "0.125rem",
            height: "auto",
            color: isPictureInPictureMode
              ? "var(--primary-500)"
              : "var(--white)",
          }}
        >
          <CgInpicture size={isPictureInPictureMode ? "1.250rem" : "2rem"} />
        </Button>
      </Space>
    </StyledModal>
  );
}
