import { useMemo } from "react";
import { BiErrorCircle } from "react-icons/bi";

import type {
  ICustomFieldBusinessUnitSelectProps,
  CustomFieldBusinessUnitSelectOptionType,
} from "./interface";

import { Result } from "~components/Result";
import { useFetchGetUserBusinessUnits } from "~hooks/api";
import { useSession } from "~hooks/useSession";
import { Button, Select, Tooltip } from "antd";

export function CustomFieldBusinessUnitSelect({
  placeholder = "Unidade de negócio...",
  onChange = () => undefined,
  value,
  ...props
}: ICustomFieldBusinessUnitSelectProps) {
  const { user } = useSession();

  const {
    data: businessUnits = [],
    isFetching: isLoadingBusinessUnits,
    error: errorBusinessUnits,
    refetch: reloadBusinessUnits,
  } = useFetchGetUserBusinessUnits({
    options: {
      enabled: !!user,
    },
  });

  const options: CustomFieldBusinessUnitSelectOptionType[] = useMemo(() => {
    return businessUnits.map((item) => ({
      label: item.name,
      value: item.id,
      record: item,
    }));
  }, [businessUnits]);

  return (
    <Select
      {...props}
      options={options}
      loading={isLoadingBusinessUnits}
      value={isLoadingBusinessUnits ? "" : value}
      onChange={(value: number, option) => {
        const parsedOption = option instanceof Array ? option[0] : option;

        onChange(value, parsedOption);
      }}
      status={errorBusinessUnits ? "error" : ""}
      notFoundContent={
        <Result.SelectErrorOrEmpty
          errorMessage={errorBusinessUnits?.message}
          emptyMessage="Nenhuma unidade encontrado"
          loading={isLoadingBusinessUnits}
          reloadFn={reloadBusinessUnits}
        />
      }
      suffixIcon={
        errorBusinessUnits ? (
          <Button
            type="link"
            loading={isLoadingBusinessUnits}
            onClick={() => reloadBusinessUnits()}
          >
            <Tooltip title="Recarregar dados" zIndex={10000}>
              <BiErrorCircle size={18} />
            </Tooltip>
          </Button>
        ) : undefined
      }
      placeholder={placeholder}
      style={{ width: "100%", ...props.style }}
    />
  );
}
