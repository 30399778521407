import { CSSProperties } from "react";

import type { LogActionTypeEnum, LogEntityTypeEnum } from "~types/enum";
import type { Dayjs } from "dayjs";

export type LogsTableFiltersType = {
  userName: string | null;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  entity: LogEntityTypeEnum | null;
  action: LogActionTypeEnum | null;
  currentPage: number;
};

export type LogsFormFiltersType = {
  entity: LogsTableFiltersType["entity"];
  action: LogsTableFiltersType["action"];
};

export enum LogsTableQueryParamKeysEnum {
  USER_NAME = "userName",
  START_DATE = "startDate",
  END_DATE = "endDate",
  CURRENT_PAGE = "page",
  ENTITY = "entity",
  ACTION = "action",
}

export type LogsTableQueryParamsKeysType = Record<
  keyof typeof LogsTableQueryParamKeysEnum,
  string
>;

export interface ILogsTableProps {
  defaultFilters?: Partial<LogsTableFiltersType>;
  displayFiltersComponent?: boolean;
  queryParamKeys?: LogsTableQueryParamsKeysType;
}

export interface ILogsTableHeaderProps {
  queryParamKeys?: ILogsTableProps["queryParamKeys"];
  style?: CSSProperties;
}
