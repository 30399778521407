import { PipelineDealSchema } from "./PipelineDealSchema";

import { z } from "zod";

export const PipelineDealListSchema = z.object({
  data: z.array(PipelineDealSchema),
});

export interface IPipelineDealListSchema
  extends z.infer<typeof PipelineDealListSchema> {}
