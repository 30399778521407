import { useMemo } from "react";
import { BiErrorCircle } from "react-icons/bi";

import type { ILaborSuggestedSelectProps } from "./interface";

import { useFetchGetLaborsSuggestedByStructureId } from "~hooks/api";
import { Button, Select, Tooltip } from "antd";

export function LaborSuggestedSelect({
  allowClear = true,
  onChange,
  structureId = 0,
  onLoad = () => undefined,
  initialValue = undefined,
  ...props
}: ILaborSuggestedSelectProps) {
  const {
    data: labors,
    isFetching,
    error,
    refetch,
  } = useFetchGetLaborsSuggestedByStructureId({
    dependencyArray: [structureId],
    payload: {
      structureId: structureId,
    },
    options: {
      enabled: structureId > 0,
      onSuccess(data) {
        if (initialValue) {
          const initialLabor = data.find((item) => item.id === initialValue);

          if (initialLabor) {
            return onLoad(initialLabor.id);
          }
        }

        const defaultLaborFilter = data.find((item) => item.suggested === true);

        if (defaultLaborFilter) {
          return onLoad(defaultLaborFilter.id ?? -1);
        }
      },
    },
  });

  const options = useMemo(() => {
    return labors
      ? labors.map((labor) => ({
          label: labor.name,
          value: labor.id ?? -1,
          record: labor,
        }))
      : [];
  }, [labors]);

  return (
    <Select
      {...props}
      loading={isFetching}
      options={options}
      allowClear={allowClear}
      status={error ? "error" : ""}
      suffixIcon={
        error && (
          <Tooltip title={error.message + " | Clique para recarregar."}>
            <Button type="link" loading={isFetching}>
              <BiErrorCircle size={18} onClick={() => refetch()} />
            </Button>
          </Tooltip>
        )
      }
      onChange={(value, option) => {
        if (option instanceof Array || !onChange) return;
        onChange(value, option);
      }}
    />
  );
}
