import type { IChangeDealTotalValueTypeFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { Modal } from "antd";

export function ChangeDealTotalValueTypeFormModal({
  projectCustomId,
  dealId,
  isOpen = false,
  onClose = () => null,
  title = "Valor do negócio",
  maxWidth = 545,
  formProps,
}: IChangeDealTotalValueTypeFormModalProps) {
  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title={
        <SolarzTypography
          hierarchy="h1"
          fontWeight="semiBold"
          style={{ fontSize: 20, color: "black" }}
        >
          {title}
        </SolarzTypography>
      }
      width="90vw"
      footer={null}
      style={{ maxWidth, padding: 32 }}
      styles={{
        content: {
          padding: "2rem 1.5rem",
        },
      }}
      destroyOnClose
    >
      <Form.ChangeDealTotalValueType
        {...formProps}
        onCancel={onClose}
        dealId={dealId}
        projectCustomId={projectCustomId}
      />
    </Modal>
  );
}
