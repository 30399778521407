import * as React from "react";

import type { IIconProps } from "../@types/globals";
import { cn } from "../lib/utils";

const Pipedrive: React.FC<IIconProps> = ({ className, ...props }) => (
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    className={cn("pointer-events-none w-6 h-6 stroke-[1.5px]", className)}
    {...props}
  >
    <title>Pipedrive</title>
    <defs>
      <path
        d="M4.706,6.073 C4.706,7.873 5.457,9.902 7.278,9.902 C8.639,9.902 10.052,8.462 10.052,6.231 C10.052,4.329 8.753,2.976 7.439,2.976 C6.148,2.976 4.706,3.891 4.706,6.073 Z M7.995,0 C11.074,0 13.086,2.554 13.086,6.239 C13.086,9.937 10.646,12.222 7.492,12.222 C6.012,12.222 5.027,11.44 4.548,11.006 C4.551,11.119 4.553,11.34 4.553,11.566 L4.553,16.5 L1.421,16.5 L1.421,3.396 C1.421,3.126 1.316,3.066 1.084,3.066 L0.04,3.066 L0.04,0.26 L2.998,0.26 C4.356,0.26 4.733,0.981 4.822,1.361 C5.381,0.798 6.548,0 7.995,0 Z"
        id="main-path"
      />
    </defs>
    <g stroke="currentColor" fillRule="evenodd">
      <g transform="translate(5.125000, 3.5)">
        <mask fill="white">
          <use href="#main-path" />
        </mask>
        <use xlinkHref="#main-path" />
      </g>
    </g>
  </svg>
);

Pipedrive.displayName = "Pipedrive";

export { Pipedrive };
