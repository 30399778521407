import { AiOutlineQuestionCircle } from "react-icons/ai";

import type { IDeleteIntegrationLinksFormProps } from "./interface";

import { Col, Form, Row, Tooltip, Checkbox, Flex, Button } from "antd";

export function DeleteIntegrationLinksForm({
  initialValues = {
    isChecked: false,
  },
  onSuccess = () => undefined,
  onCancel = () => undefined,
  distributorId,
  fetchToggleDistributor,
  fetchDeleteIntegrationLinks,
}: IDeleteIntegrationLinksFormProps) {
  const [form] = Form.useForm();

  async function handleSubmitForm(data: { isChecked: boolean }) {
    if (data.isChecked) fetchDeleteIntegrationLinks({ distributorId });
    fetchToggleDistributor({ distributorId });
    return onSuccess();
  }

  return (
    <Form
      data-cy="delete-integration-links-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmitForm}
    >
      <Flex>
        <Col xs={24} xl={24}>
          <Row>
            <Form.Item name="isChecked">
              <Checkbox
                defaultChecked={!!initialValues?.isChecked}
                onChange={(e) =>
                  form.setFieldValue("isChecked", !!e.target.checked)
                }
              >
                <Flex align="center" gap={4} wrap="nowrap">
                  Excluir os vínculos de estruturas dessa integração?
                  <Tooltip title="Ao marcar esse campo você aceita que os vínculos de estruturas ligados a essa integração também sejam removidos.">
                    <AiOutlineQuestionCircle size={16} />
                  </Tooltip>
                </Flex>
              </Checkbox>
            </Form.Item>
          </Row>
        </Col>
      </Flex>
      <Flex gap={12} justify="end">
        <Form.Item style={{ margin: 0 }}>
          <Button onClick={onCancel}>Cancelar</Button>
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button type="primary" htmlType="submit">
            Salvar
          </Button>
        </Form.Item>
      </Flex>
    </Form>
  );
}
