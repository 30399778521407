import type { IProposalViewsModalProps } from "./interface";

import { List } from "~components/List";
import { Modal } from "antd";

export function ProposalViewsModal({
  isOpen = false,
  onClose = () => null,
  title = "Acessos",
  maxWidth = 768,
  listProps = {
    proposalId: 0,
  },
}: IProposalViewsModalProps) {
  return (
    <Modal
      data-cy="proposal-views-modal"
      open={isOpen}
      onCancel={onClose}
      title={title}
      width="90vw"
      footer={null}
      destroyOnClose
      style={{ maxWidth }}
      styles={{
        body: {
          maxHeight: "calc(100vh - 240px)",
          overflowY: "auto",
        },
      }}
    >
      <List.ProposalViews {...listProps} />
    </Modal>
  );
}
