import type {
  IAssignVariableWithPipedriveFormProps,
  AssignVariableWithPipedriveFormValuesType,
} from "./interface";

import { CustomInput } from "~components/CustomInput";
import { Select } from "~components/Select";
import { useFetchUpdateFieldBind } from "~hooks/api";
import { Button, Form, Flex } from "antd";

export function AssignVariableWithPipedriveForm({
  initialValues = {
    pipedriveFieldKey: "", // format => ${variable}
    variableKey: "",
  },
  onCancel = () => undefined,
  onError = () => undefined,
  onSuccess = () => undefined,
}: IAssignVariableWithPipedriveFormProps) {
  const { mutate: assignVariableWithPipedrive, isLoading } =
    useFetchUpdateFieldBind({
      options: {
        onSuccess,
        onError,
      },
    });

  function handleSubmitForm(
    formValues: AssignVariableWithPipedriveFormValuesType,
  ) {
    const formattedValue =
      "${" + formValues.variableKey.replace(/[${}]/g, "") + "}";

    assignVariableWithPipedrive({
      fieldKey: formValues.pipedriveFieldKey,
      bindKey: formattedValue,
    });
  }

  return (
    <Form
      data-cy="assign-variable-with-pipedrive-form"
      onFinish={handleSubmitForm}
      initialValues={initialValues}
      layout="vertical"
      style={{ width: "100%" }}
    >
      <Form.Item
        name="variableKey"
        label="Variável"
        rules={[{ required: true, message: "Por favor insira a variável!" }]}
      >
        <CustomInput.Text disabled />
      </Form.Item>

      {/* <Form.Item name="variableId" hidden>
        <CustomInput.Text disabled hidden />
      </Form.Item> */}

      <Form.Item
        name="pipedriveFieldKey"
        label="Campo do pipedrive"
        tooltip="Deixe vazio para desassociar o campo do Pipedrive"
      >
        <Select.PipedriveField
          data-cy="select-pipedrive-field"
          placeholder="Selecione o campo..."
        />
      </Form.Item>

      <Flex align="center" gap={8} justify="end">
        <Button onClick={onCancel}>Cancelar</Button>
        <Button
          type="primary"
          data-cy="button-save"
          htmlType="submit"
          loading={isLoading}
        >
          Salvar
        </Button>
      </Flex>
    </Form>
  );
}
