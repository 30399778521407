import styled from "@emotion/styled";
import { Flex } from "antd";

export const StyledFlex = styled(Flex)`
  table thead > tr > td {
    background-color: var(--gray-200) !important;
    border-radius: 0 !important;
  }

  table thead > tr > th {
    background-color: var(--gray-200) !important;
    border-radius: 0 !important;
  }

  table thead > tr > td.ant-table-cell {
    width: 0;
  }

  /* DARK THEME */
  html.dark & table thead > tr > td {
    background-color: var(--gray-700) !important;
  }

  html.dark & table thead > tr > th {
    background-color: var(--gray-700) !important;
    color: var(--gray-300) !important;
  }

  html.dark & table td {
    color: var(--gray-400) !important;
  }
`;
