import styled from "@emotion/styled";

export interface IStyledFormItem {
  orientation?: "inline" | "vertical";
}

export const WrapperFormItem = styled.div<IStyledFormItem>`
  .solarz-form-item {
    flex: 1;

    & > div {
      flex-direction: ${(props) =>
        props.orientation === "inline" ? "row" : "column"};
    }

    .ant-form-item-control {
      flex: 0;
    }

    .ant-form-item-label {
      text-align: left;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    & .ant-form-item-label > label {
      width: 100% !important;
    }
  }
`;
