import { IoChevronForwardOutline } from "react-icons/io5";

import { INewPersonModalInSelectBreadcrumbProps } from "./interface";
import { StyledBreadcrumb } from "./styles";

export function NewPersonModalInSelectBreadcrumb({
  parentTitleModal,
}: INewPersonModalInSelectBreadcrumbProps) {
  return (
    <StyledBreadcrumb
      separator={<IoChevronForwardOutline size={12} />}
      items={[
        { title: <span>{parentTitleModal}</span> },
        { title: <span className="active">Nova pessoa</span> },
      ]}
    />
  );
}
