import * as React from "react";

import type { IIconProps } from "../@types/globals";
import { cn } from "../lib/utils";

const Insights: React.FC<IIconProps> = ({ className, ...props }) => (
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    className={cn("pointer-events-none w-6 h-6 stroke-[1.5px]", className)}
    {...props}
  >
    <title>Insights</title>
    <path
      d="M12 2C7.85786 2 4.5 5.35786 4.5 9.5C4.5 11.7206 5.4651 13.7159 6.99876 15.0892C8.11945 16.0927 8.67987 16.5945 8.83082 16.7807C9.31762 17.3815 9.3391 17.4296 9.46192 18.193C9.5 18.4297 9.5 18.7865 9.5 19.5M14.5 19.5H9.5M14.5 19.5C14.5 18.7865 14.5 18.4297 14.5381 18.193C14.6609 17.4296 14.6824 17.3815 15.1692 16.7807C15.2889 16.633 16.3706 15.6561 16.8135 15.2577C16.9146 15.1668 16.9652 15.1213 17.0231 15.0632C17.081 15.0051 17.1116 14.9709 17.1728 14.9023C18.5384 13.3734 19 11.806 19 9.5M14.5 19.5C14.5 20.4346 14.5 20.9019 14.299 21.25C14.1674 21.478 13.978 21.6674 13.75 21.799C13.4019 22 12.9346 22 12 22C11.0654 22 10.5981 22 10.25 21.799C10.022 21.6674 9.83261 21.478 9.70096 21.25C9.5 20.9019 9.5 20.4346 9.5 19.5"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path d="M12 17V15" stroke="currentColor" strokeLinecap="round" />
    <path
      d="M13.7324 14C13.3866 14.5978 12.7403 15 12 15C11.2597 15 10.6134 14.5978 10.2676 14"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      d="M18.9866 2.8225L18.75 2.2224L18.5133 2.8225C18.3887 3.13853 18.1385 3.38869 17.8225 3.51333L17.2224 3.75L17.8225 3.98667C18.1385 4.11131 18.3887 4.36147 18.5133 4.6775L18.75 5.2776L18.9866 4.6775C19.1113 4.36147 19.3614 4.11131 19.6775 3.98667L20.2776 3.75L19.6775 3.51333L19.9526 2.81563L19.6775 3.51333C19.3614 3.38869 19.1113 3.13852 18.9866 2.8225L19.6843 2.54733L18.9866 2.8225Z"
      stroke="currentColor"
    />
    <path
      d="M13.8333 7.11652C13.9077 7.00405 14.004 6.9077 14.1165 6.83333C14.004 6.75897 13.9077 6.66262 13.8333 6.55015C13.759 6.66262 13.6626 6.75897 13.5501 6.83333C13.6626 6.9077 13.759 7.00405 13.8333 7.11652ZM14.4001 6.10361L13.9651 6.27517L14.4001 6.10361Z"
      stroke="currentColor"
    />
  </svg>
);

Insights.displayName = "Insights";

export { Insights };
