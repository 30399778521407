import styled from "@emotion/styled";
import { SolarzButton } from "~solarzui/SolarzButton";

export const StyledSolarzButton = styled(SolarzButton)`
  border-color: var(--red-500);
  color: var(--red-500);

  &:not(:disabled):hover {
    background-color: var(--red-400) !important;
    color: var(--white) !important;
    border-color: var(--red-400) !important;
  }
`;
