import { useMemo, useState } from "react";

import type {
  OrganizationSelectOptionType,
  OrganizationSelectRecordType,
  IOrganizationSelectProps,
} from "./interface";

import { Modal } from "~components/Modal";
import { useFetchGetPaginatedOrganization } from "~hooks/api";
import { useSession } from "~hooks/useSession";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzSelect } from "~solarzui/SolarzSelect";
import { parseRemoveAccent } from "~utils/parse";
import { Flex } from "antd";

export function OrganizationSelect({
  showSearch = true,
  parentTitleModal = "",
  showDropdownRender = false,
  onCreateOrganizationSuccess,
  ...props
}: IOrganizationSelectProps) {
  const { user } = useSession();

  const [searchTerm, setSearchTerm] = useState("");
  const [isOrganizationModalOpen, setIsOrganizationModalOpen] = useState(false);

  const {
    data: organizations,
    isFetching: isLoadingOrganizations,
    error: organizationsError,
    refetch: refetchOrganizations,
  } = useFetchGetPaginatedOrganization({
    dependencyArray: [],
    payload: {
      page: 0,
      size: 500,
    },
  });

  const OPTIONS =
    useMemo(() => {
      return organizations?.content.map((organization) => ({
        key: organization.id,
        value: organization.id,
        label: organization.name,
        record: organization,
      })) as OrganizationSelectOptionType;
    }, [organizations?.content]) ?? [];

  const filteredOptions =
    showSearch && searchTerm
      ? OPTIONS.filter((option) =>
          parseRemoveAccent(option.record.name)
            .toLowerCase()
            .includes(searchTerm.toLowerCase()),
        )
      : OPTIONS;

  return (
    <>
      <SolarzSelect<OrganizationSelectRecordType, "id">
        {...props}
        options={filteredOptions}
        isLoading={isLoadingOrganizations}
        refetchFn={refetchOrganizations}
        errorMessage={organizationsError?.message}
        emptyMessage="Nenhuma organização encontrada"
        searchValue={searchTerm}
        onChangeSearch={(value) => {
          setSearchTerm(value);
        }}
        showSearch={showSearch}
        dropdownRender={
          showDropdownRender
            ? (menu) => {
                return (
                  <Flex vertical>
                    {menu}
                    <Flex vertical style={{ margin: "8px 16px 0 16px" }}>
                      <Flex justify="center">
                        <SolarzButton
                          style={{ margin: "8px 0 4px 0" }}
                          variant="ghost"
                          scale="tiny"
                          onClick={() => setIsOrganizationModalOpen(true)}
                        >
                          Nova organização
                        </SolarzButton>
                      </Flex>
                    </Flex>
                  </Flex>
                );
              }
            : undefined
        }
      />
      <Modal.SelectOrganizationForm
        parentTitleModal={parentTitleModal}
        isOpen={isOrganizationModalOpen}
        onClose={() => setIsOrganizationModalOpen(false)}
        formProps={{
          onSuccess: (organization) => {
            setIsOrganizationModalOpen(false);

            if (typeof onCreateOrganizationSuccess === "function") {
              onCreateOrganizationSuccess(organization);
            }
          },
          initialValues: {
            ownerId: user?.id || undefined,
            name: searchTerm || undefined,
          },
        }}
      />
    </>
  );
}
