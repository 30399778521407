import { ReactElement, useMemo } from "react";
import { AiOutlineSearch } from "react-icons/ai";

import { StyledSpin, StyledFlex } from "./styles";

import { Button, Divider, Space, Tooltip, Flex } from "antd";

type Props = {
  fields?: ReactElement[];
  onSubmit?: () => void;
  onReset?: () => void;
  isLoading?: boolean;
  width?: string | number;
  maxWidth?: string | number;
  maxHeight?: string | number;
  direction?: "column" | "row";
  isDisabled?: boolean;
  searchTooltip?: string;
};

export function ColumnFilter({
  fields = [],
  onSubmit = () => undefined,
  onReset = () => undefined,
  isLoading = false,
  width = "14rem",
  maxWidth = "75vw",
  maxHeight = "50vh",
  direction = "column",
  isDisabled = false,
  searchTooltip = "",
}: Props) {
  const inputs = useMemo(() => fields.map((field) => field), [fields]);

  return (
    <Flex
      vertical
      align="end"
      gap={4}
      style={{
        width,
        maxWidth,
      }}
    >
      <StyledFlex
        vertical={direction === "column"}
        justify={direction === "column" ? "left" : "space-between"}
        style={{ maxHeight }}
        wrap="wrap"
      >
        {inputs}
      </StyledFlex>

      <Divider style={{ padding: 0, margin: 0 }} />

      <Flex
        justify="end"
        wrap="nowrap"
        gap={8}
        style={{
          margin: "0.5rem",
        }}
      >
        <Button onClick={onReset} size="small">
          Limpar
        </Button>
        <Tooltip title={searchTooltip}>
          <Button
            type="primary"
            onClick={onSubmit}
            size="small"
            style={{ width: "auto", lineHeight: 0 }}
            htmlType="submit"
            disabled={isDisabled}
          >
            <Space
              size="small"
              style={{
                width: "100%",
              }}
            >
              {isLoading ? (
                <StyledSpin size="small" />
              ) : (
                <AiOutlineSearch size={16} style={{ lineHeight: 0 }} />
              )}
              Buscar
            </Space>
          </Button>
        </Tooltip>
      </Flex>
    </Flex>
  );
}
