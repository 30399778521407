import { useMemo } from "react";

import type { ICompanyStructureTypeSelectProps } from "./interface";

import { SelectSuffixIcon } from "~components/Others";
import { Result } from "~components/Result";
import { useFetchGetStructureTypes } from "~hooks/api";
import { parseStructureToLabel } from "~utils/parseInformationToStructureLabel";
import { Select } from "antd";

export function CompanyStructureTypeSelect({
  allowClear = true,
  onChange,
  filters,
  ...props
}: ICompanyStructureTypeSelectProps) {
  const {
    data: structureTypes = [],
    error,
    isFetching,
    refetch,
  } = useFetchGetStructureTypes({
    dependencyArray: [],
    options: {
      retry: 1,
    },
  });

  const OPTIONS = useMemo(() => {
    const filteredStructureTypes = filters
      ? structureTypes.filter(
          (structureType) => filters.active === structureType.active,
        )
      : structureTypes;

    return filteredStructureTypes.map((structureType) => ({
      label: parseStructureToLabel(structureType),
      value: structureType.id,
      record: structureType,
    }));
  }, [filters, structureTypes]);

  return (
    <Select
      {...props}
      loading={isFetching}
      options={OPTIONS}
      allowClear={allowClear}
      status={error ? "error" : ""}
      showSearch
      filterOption={(input, option) => {
        const label = option?.label?.toString() ?? "";
        return label?.toLowerCase().includes(input.toLowerCase());
      }}
      optionFilterProp="children"
      suffixIcon={
        <SelectSuffixIcon hasError={!!error} isLoading={isFetching} />
      }
      notFoundContent={
        <Result.SelectErrorOrEmpty
          errorMessage={error?.message}
          emptyMessage="Nenhuma estrutura encontrada"
          loading={isFetching}
          reloadFn={refetch}
        />
      }
      onChange={(value, option) => {
        if (option instanceof Array || !onChange) return;
        onChange(value, option);
      }}
    />
  );
}
