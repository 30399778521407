import type {
  IReasonForLossFormProps,
  IReasonForLossFormValuesType,
} from "./interface";

import { Alert } from "~components/Alert";
import {
  useFetchCreateReasonForLoss,
  useFetchGetReasonForLossById,
  useFetchUpdateReasonForLoss,
} from "~hooks/api";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzFormItem } from "~solarzui/SolarzFormItem";
import { SolarzInput } from "~solarzui/SolarzInput";
import { Flex, Form, Spin } from "antd";

export function ReasonForLossForm({
  formInstance,
  initialValues,
  reasonForLossId = 0,
  onCancel,
  onError,
  onSuccess,
  style,
  className,
}: IReasonForLossFormProps) {
  const [form] = Form.useForm<IReasonForLossFormValuesType>(formInstance);

  const { mutate: createReasonForLoss, isLoading: isCreatingReasonForLoss } =
    useFetchCreateReasonForLoss({
      options: {
        onSuccess,
        onError,
      },
    });

  const { mutate: updateReasonForLoss, isLoading: isUpdatingReasonForLoss } =
    useFetchUpdateReasonForLoss({
      options: {
        onSuccess,
        onError,
      },
    });

  const {
    data: initialDataReasonForLoss,
    isFetching: isLoadingReasonForLoss,
    error: errorReasonForLoss,
    refetch: reloadReasonForLoss,
  } = useFetchGetReasonForLossById({
    payload: {
      id: reasonForLossId ?? 0,
    },
    dependencyArray: [reasonForLossId],
    options: {
      enabled: Number(reasonForLossId) > 0,
      onSuccess: (reasonForLoss) => {
        form.setFieldsValue({
          reason: reasonForLoss.reason,
        });
      },
      retry: 1,
    },
  });

  function handleSubmit(formValues: IReasonForLossFormValuesType) {
    reasonForLossId > 0
      ? updateReasonForLoss({ ...formValues, id: reasonForLossId })
      : createReasonForLoss(formValues);
  }

  function handleCancel() {
    if (typeof onCancel === "function") {
      return onCancel();
    }

    form.setFieldsValue({
      reason: initialDataReasonForLoss?.reason || "",
    });
  }

  const isCreatingOrUpdating =
    isCreatingReasonForLoss || isUpdatingReasonForLoss;

  const submitButtonIsDisabled =
    reasonForLossId > 0 && !initialDataReasonForLoss?.id;

  return (
    <Form
      data-cy="reason-for-loss-form"
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmit}
      preserve
      layout="vertical"
      style={style}
      rootClassName={className}
    >
      {errorReasonForLoss && (
        <Alert.CardError
          errorMessage={errorReasonForLoss?.message}
          title="Falha ao carregar dados iniciais"
          reloadFn={reloadReasonForLoss}
          style={{ marginBottom: 16 }}
        />
      )}

      <Spin spinning={isLoadingReasonForLoss}>
        <SolarzFormItem<IReasonForLossFormValuesType>
          name="reason"
          label="Motivo da perda"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <SolarzInput placeholder="Digite o motivo de perda" />
        </SolarzFormItem>
      </Spin>

      <Flex
        style={{ marginTop: 16 }}
        align="center"
        gap={12}
        justify="flex-end"
      >
        <SolarzButton variant="secondary" scale="tiny" onClick={handleCancel}>
          Cancelar
        </SolarzButton>
        <SolarzButton
          scale="tiny"
          htmlType="submit"
          isDisabled={submitButtonIsDisabled}
          isLoading={isCreatingOrUpdating}
        >
          {reasonForLossId ? "Atualizar" : "Criar"}
        </SolarzButton>
      </Flex>
    </Form>
  );
}
