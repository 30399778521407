import type {
  ISelectConcessionaireProfileForm,
  ISelectConcessionaireProfileFormValues,
} from "./interface";

import { Select } from "~components/Select";
import { Button, Flex, Form } from "antd";

export function SelectConcessionaireProfileForm({
  onSubmit = () => undefined,
  onCancel = () => undefined,
  concessionaireId = 0,
  voltageType = "LOW",
  modalityTaxType = undefined,
}: ISelectConcessionaireProfileForm) {
  const [form] = Form.useForm<ISelectConcessionaireProfileFormValues>();

  function handleSubmit() {
    const formValues: ISelectConcessionaireProfileFormValues =
      form.getFieldsValue(true);

    onSubmit(formValues.concessionaireProfile);
  }

  return (
    <Form onFinish={handleSubmit}>
      <Form.Item
        name="concessionaireProfile"
        required
        rules={[{ required: true }]}
      >
        <Select.ConcessionaireProfilesByVoltage
          placeholder="Selecione..."
          allowClear
          concessionaireId={concessionaireId}
          voltageType={voltageType}
          modalityTaxType={modalityTaxType}
          onChange={(_, { record }) =>
            form.setFieldValue("concessionaireProfile", record)
          }
          data-cy="select-concessionaire-profile"
        />
      </Form.Item>

      <Flex gap={8} align="center" justify="end">
        <Button type="default" onClick={onCancel}>
          Cancelar
        </Button>
        <Button data-cy="button-save" type="primary" htmlType="submit">
          Salvar
        </Button>
      </Flex>
    </Form>
  );
}
