import { useState } from "react";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye } from "react-icons/ai";
import { BsThreeDots } from "react-icons/bs";
import { FaListCheck } from "react-icons/fa6";

import type {
  AdminDatasheetTableFiltersType,
  IAdminDatasheetTableProps,
} from "./interface";

import { ColumnFilter } from "~components/ColumnFilter";
import { Modal } from "~components/Modal";
import { useFetchGetPaginatedDatasheet } from "~hooks/api";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzInputNumber } from "~solarzui/SolarzInputNumber";
import { SolarzTable } from "~solarzui/SolarzTable";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { Dropdown, Flex, Form } from "antd";

const DEFAULT_FILTERS: AdminDatasheetTableFiltersType = {
  page: 1,
  size: 10,
  id: undefined,
};

export function AdminDatasheetTable({
  initialFilters,
}: IAdminDatasheetTableProps) {
  const [filters, setFilters] = useState<AdminDatasheetTableFiltersType>({
    ...DEFAULT_FILTERS,
    ...initialFilters,
  });

  function onChangeFilters(filters: Partial<AdminDatasheetTableFiltersType>) {
    setFilters((currentFilters) => {
      return {
        ...currentFilters,
        ...filters,
      };
    });
  }

  const [datasheetIdToUpdate, setDatasheetIdToUpdate] = useState<number>();
  const [datasheetIdToDelete, setDatasheetIdToDelete] = useState<number>();
  const [datasheetIdToDisplayRevisions, setDatasheetIdToDisplayRevisions] =
    useState<number>();
  const [
    datasheetIdToDisplayLastRevision,
    setDatasheetIdToDisplayLastRevision,
  ] = useState<number>();

  const {
    data: adminsData,
    isFetching: isLoadingAdminDatasheet,
    error: adminsError,
    refetch: reloadAdminDatasheet,
  } = useFetchGetPaginatedDatasheet({
    dependencyArray: [filters.page],
    payload: {
      ...filters,
      page: filters.page - 1,
    },
  });

  function handleFetchTableData() {
    filters.page > 1
      ? setFilters((current) => ({ ...current, pageNumber: 1 }))
      : reloadAdminDatasheet();
  }

  return (
    <>
      <SolarzTable
        data-cy="admins-table"
        isLoading={isLoadingAdminDatasheet}
        columns={[
          {
            title: "Id",
            dataIndex: "id",
            key: "id",
            width: 80,
            align: "center",
            render: (_, record) => (
              <SolarzTypography fontWeight="regular" style={{ fontSize: 14 }}>
                #{record.id}
              </SolarzTypography>
            ),
            filterDropdown: (
              <ColumnFilter
                fields={[
                  <Form.Item key="id" htmlFor="id" label="Id">
                    <SolarzInputNumber
                      id="id"
                      value={filters.id}
                      onChange={(id) => {
                        onChangeFilters({ id: id ?? undefined });
                      }}
                      min={1}
                      placeholder="Ex.: ID..."
                      precision={0}
                    />
                  </Form.Item>,
                ]}
                onReset={() => onChangeFilters({ id: undefined })}
                isLoading={isLoadingAdminDatasheet}
                onSubmit={handleFetchTableData}
              />
            ),
            filteredValue: filters.id ? [true] : [],
          },
          {
            title: "Nome",
            dataIndex: "name",
            key: "name",
            render: (_, record) => (
              <SolarzTypography fontWeight="semiBold" style={{ fontSize: 14 }}>
                {record.name}
              </SolarzTypography>
            ),
          },
          {
            title: "Fabricante",
            dataIndex: "brand",
            key: "brand",
            width: 320,
            render: (_, record) => (
              <SolarzTypography fontWeight="regular" style={{ fontSize: 14 }}>
                {record.brand}
              </SolarzTypography>
            ),
          },
          {
            title: "",
            width: 64,
            align: "center",
            render: (_, record) => (
              <Dropdown
                trigger={["click"]}
                menu={{
                  items: [
                    {
                      key: "last-revision",
                      label: (
                        <SolarzTypography
                          hierarchy="paragraph2"
                          fontWeight="medium"
                          style={{ color: "var(--blue-500)" }}
                        >
                          <Flex align="center" style={{ height: 32 }} gap={12}>
                            <AiOutlineEye size={18} />
                            Ultima revisão
                          </Flex>
                        </SolarzTypography>
                      ),
                      onClick: () => {
                        setDatasheetIdToDisplayLastRevision(record.id);
                      },
                    },
                    {
                      key: "revisions",
                      label: (
                        <SolarzTypography
                          hierarchy="paragraph2"
                          fontWeight="medium"
                          style={{ color: "var(--green-600)" }}
                        >
                          <Flex align="center" style={{ height: 32 }} gap={12}>
                            <FaListCheck size={18} />
                            Lista de revisões
                          </Flex>
                        </SolarzTypography>
                      ),
                      onClick: () => {
                        setDatasheetIdToDisplayRevisions(record.id);
                      },
                    },
                    {
                      key: "update",
                      label: (
                        <SolarzTypography
                          hierarchy="paragraph2"
                          variant="warning"
                          fontWeight="medium"
                        >
                          <Flex align="center" style={{ height: 32 }} gap={12}>
                            <AiOutlineEdit size={18} />
                            Atualizar Datasheet
                          </Flex>
                        </SolarzTypography>
                      ),
                      onClick: () => {
                        setDatasheetIdToUpdate(record.id);
                      },
                    },
                    {
                      key: "delete",
                      label: (
                        <SolarzTypography
                          hierarchy="paragraph2"
                          variant="danger"
                          fontWeight="medium"
                        >
                          <Flex align="center" style={{ height: 32 }} gap={12}>
                            <AiOutlineDelete size={18} />
                            Apagar Datasheet
                          </Flex>
                        </SolarzTypography>
                      ),
                      onClick: () => {
                        setDatasheetIdToDelete(record.id);
                      },
                    },
                  ],
                }}
                arrow
                placement="bottomRight"
              >
                <div>
                  <SolarzButton
                    variant="ghost"
                    scale="large"
                    icon={
                      <BsThreeDots
                        size={20}
                        style={{ color: "var(--primary-500)" }}
                      />
                    }
                    style={{
                      height: 32,
                      width: 32,
                      borderRadius: 9999,
                    }}
                  />
                </div>
              </Dropdown>
            ),
          },
        ]}
        rows={adminsData?.content ?? []}
        pagination={{
          currentItemCount: adminsData?.size ?? 0,
          currentPage: adminsData?.number ? adminsData?.number + 1 : 1,
          itemLabel: "Datasheets",
          totalItemCount: adminsData?.totalElements ?? 0,
          onChange: (page) =>
            onChangeFilters({
              page: page - 1,
            }),
        }}
        rowKey="id"
        scroll={{ x: 688 }}
        emptyMessage="Nenhum Datasheet encontrado"
        errorMessage={adminsError?.message}
        reloadFn={reloadAdminDatasheet}
      />

      <Modal.DatasheetLastRevision
        isOpen={!!datasheetIdToDisplayLastRevision}
        datasheetId={datasheetIdToDisplayLastRevision ?? 0}
        onClose={() => {
          setDatasheetIdToDisplayLastRevision(undefined);
        }}
      />

      <Modal.DatasheetForm
        isOpen={!!datasheetIdToUpdate}
        datasheetId={datasheetIdToUpdate}
        onClose={() => {
          setDatasheetIdToUpdate(undefined);
        }}
        title="Atualizar Datasheet"
        formProps={{
          onSuccess: () => {
            setDatasheetIdToUpdate(undefined);
          },
        }}
      />

      <Modal.DeleteDatasheet
        isOpen={Number(datasheetIdToDelete) > 0}
        datasheetId={datasheetIdToDelete ?? 0}
        onClose={() => {
          setDatasheetIdToDelete(undefined);
        }}
        onSuccess={() => {
          setDatasheetIdToDelete(undefined);
        }}
      />

      <Modal.AdminDatasheetRevisionsTable
        isOpen={Number(datasheetIdToDisplayRevisions) > 0}
        datasheetId={datasheetIdToDisplayRevisions ?? 0}
        onClose={() => {
          setDatasheetIdToDisplayRevisions(undefined);
        }}
      />
    </>
  );
}
