import type { IMonthlyFormProps, IMonthlyFormValues } from "./interface";

import { CustomInput } from "~components/CustomInput";
import { useAppConfig } from "~hooks/useAppConfig";
import { Button, Col, Flex, Form, Row } from "antd";

const MONTHS = [
  { ptBrKey: "Janeiro", key: "january" },
  { ptBrKey: "Fevereiro", key: "february" },
  { ptBrKey: "Março", key: "march" },
  { ptBrKey: "Abril", key: "april" },
  { ptBrKey: "Maio", key: "may" },
  { ptBrKey: "Junho", key: "june" },
  { ptBrKey: "Julho", key: "july" },
  { ptBrKey: "Agosto", key: "august" },
  { ptBrKey: "Setembro", key: "september" },
  { ptBrKey: "Outubro", key: "october" },
  { ptBrKey: "Novembro", key: "november" },
  { ptBrKey: "Dezembro", key: "december" },
] as const;

export function MonthlyForm({
  initialValues,
  onSubmit = () => undefined,
  onCancel = () => undefined,
}: IMonthlyFormProps) {
  const { screens } = useAppConfig();

  function handleSubmit(formData: IMonthlyFormValues) {
    const sumConsumption = MONTHS.reduce((acc, month) => {
      const actualValue: number = formData?.[month.key] ?? 0;

      return acc + actualValue;
    }, 0);

    const averageConsumption = sumConsumption / 12;

    onSubmit({
      ...formData,
      averageConsumption,
    });
  }

  return (
    <Form
      data-cy="monyhly-form"
      onFinish={handleSubmit}
      layout="vertical"
      style={{ width: "100%" }}
      initialValues={initialValues}
    >
      <Row gutter={16}>
        {MONTHS.map((month) => (
          <Col key={month.key} span={screens.xs ? 12 : 8}>
            <Form.Item
              name={month.key}
              label={month.ptBrKey}
              initialValue={0}
              rules={[
                {
                  required: true,
                  message: `Por favor, insira o mês de ${month.ptBrKey}`,
                },
              ]}
            >
              <CustomInput.UnitMeasurement
                name={month.key}
                placeholder={month.ptBrKey}
                min={0}
                precision="2"
                style={{ width: "100%" }}
                unitMeasurement="KWh"
              />
            </Form.Item>
          </Col>
        ))}
      </Row>

      <Flex
        align="center"
        gap={8}
        justify="end"
        style={{ marginTop: "0.5rem" }}
      >
        <Button onClick={onCancel}>Voltar</Button>
        <Button type="primary" htmlType="submit">
          Salvar
        </Button>
      </Flex>
    </Form>
  );
}
