import { useState } from "react";
import { GoAlert } from "react-icons/go";
import { IoReload } from "react-icons/io5";

import { ISolarzPipelineTabProps } from "./interface";
import { Tab, Wrapper } from "./styles";

import { ArrowLeft, ArrowRight } from "~estrela-ui/icons";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { Flex, Skeleton, Spin, Tooltip } from "antd";

export function SolarzPipelineTab<RecordType extends Record<string, any>>({
  tabs = [],
  actualStep,
  onChange,
  scale = "large",
  separator = "arrow",
  defaultStep = 0,
  hideLabel = false,
  hideTooltip = false,
  labelStyle,
  emptyMessage,
  errorMessage,
  isLoading,
  refetchFn,
  withControls,
  ...props
}: ISolarzPipelineTabProps<RecordType>) {
  const [currentStep, setCurrentStep] = useState(defaultStep);

  function handleClickStep(step: number, record: RecordType) {
    setCurrentStep(step);

    if (typeof onChange === "function") {
      onChange(step, record);
    }
  }

  function handleClickPreviousStep() {
    const newStep = currentStep - 1;
    const tab = tabs[newStep - 1];

    setCurrentStep(newStep);

    if (typeof onChange === "function") {
      onChange(step, tab.record);
    }
  }

  function handleClickNextStep() {
    const newStep = currentStep + 1;
    const tab = tabs[newStep - 1];

    setCurrentStep(newStep);

    if (typeof onChange === "function") {
      onChange(step, tab.record);
    }
  }

  if (isLoading || tabs.length === 0) {
    return (
      <Flex vertical style={{ width: "100%" }}>
        <Flex align="start" justify="space-between">
          <Skeleton.Input
            active={isLoading}
            size="small"
            style={{ height: 14 }}
          />
          {isLoading ? (
            <Spin
              spinning
              size="small"
              style={{ marginRight: 10, marginTop: -1 }}
            />
          ) : (
            <Tooltip title={errorMessage || emptyMessage} trigger={["hover"]}>
              <div style={{ marginTop: -10 }}>
                <SolarzButton
                  scale="tiny"
                  variant="ghost"
                  icon={
                    typeof refetchFn === "function" && errorMessage ? (
                      <IoReload />
                    ) : (
                      <GoAlert />
                    )
                  }
                  style={{ height: 32, width: 32 }}
                  onClick={refetchFn}
                />
              </div>
            </Tooltip>
          )}
        </Flex>

        <Wrapper scale={scale} {...props}>
          <Skeleton.Node
            active={isLoading}
            style={{ width: "100%", height: "100%" }}
            rootClassName="solarz-pipeline-tab-skeleton"
          >
            {" "}
          </Skeleton.Node>
        </Wrapper>
      </Flex>
    );
  }

  const step = actualStep || currentStep;

  return (
    <Flex vertical gap={4} style={{ width: "100%" }}>
      {!hideLabel && withControls && (
        <Flex justify="space-around" align="center" style={{ marginTop: 2 }}>
          <SolarzButton
            icon={<ArrowLeft />}
            scale="tiny"
            variant="ghost"
            onClick={handleClickPreviousStep}
            isDisabled={step <= 1}
          />
          <SolarzTypography
            hierarchy="paragraph2"
            fontWeight="regular"
            variant="default"
            style={labelStyle}
          >
            {tabs?.[step <= 0 || step > tabs.length ? 0 : step - 1]?.label ??
              "S/N"}
          </SolarzTypography>
          <SolarzButton
            icon={<ArrowRight />}
            scale="tiny"
            variant="ghost"
            onClick={handleClickNextStep}
            isDisabled={step === tabs.length}
          />
        </Flex>
      )}

      {!hideLabel && !withControls && (
        <SolarzTypography
          hierarchy="paragraph2"
          fontWeight="regular"
          variant="default"
          style={labelStyle}
        >
          {tabs?.[step <= 0 || step > tabs.length ? 0 : step - 1]?.label ??
            "S/N"}
        </SolarzTypography>
      )}

      <Wrapper scale={scale} {...props}>
        {tabs.map((tab, index) => (
          <Tooltip
            key={`tab-${index + 1}`}
            title={tab.disabled || hideTooltip ? null : tab.label}
            trigger={["hover", "focus"]}
          >
            <Tab
              isCompleted={step >= index + 1}
              disabled={!!tab.disabled}
              scale={scale}
              onClick={() => {
                if (tab.disabled) return;
                handleClickStep(index + 1, tab.record);
              }}
              className="hoverable"
              separator={separator}
              color={tab.color ?? "primary"}
              tabIndex={!tab.disabled ? 0 : -1}
              aria-label={tab.label}
              aria-disabled={tab.disabled}
            />
          </Tooltip>
        ))}
      </Wrapper>
    </Flex>
  );
}
