import { useState } from "react";

import type { IDistributorIntegrationButton } from "./interface";

import { Modal } from "~components/Modal";
import { Button } from "antd";

export function DistributorIntegrationButton({
  children,
  type = "primary",
  distributorId,
  activeKitFilter,
  ...props
}: IDistributorIntegrationButton) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        {...props}
        data-cy="distributor-integration-button"
        onClick={openModal}
        type={type}
        htmlType="button"
      >
        {children ?? "Configurar integrador"}
      </Button>

      <Modal.ActivateDistributorKitFilterForm
        distributorId={distributorId}
        activeKitFilter={activeKitFilter}
        isOpen={isModalOpen}
        onClose={closeModal}
      />
    </>
  );
}
