import { IProposalDealCardProps } from "./interface";
import { StyledCard } from "./styles";

import { List } from "~components/List";
import { SolarzAnchor } from "~solarzui/SolarzAnchor";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { Button, Row } from "antd";

export function ProposalDealCard({ style, dealId }: IProposalDealCardProps) {
  return (
    <StyledCard
      style={{
        ...style,
      }}
      title={
        <SolarzTypography
          fontWeight="semiBold"
          hierarchy="paragraph1"
          variant="title"
        >
          Propostas de negócio
        </SolarzTypography>
      }
    >
      <Row style={{ display: "flex", flexDirection: "column" }}>
        <List.ProposalDeal dealId={dealId} />
      </Row>

      <SolarzAnchor
        style={{ width: "100%" }}
        href={`/proposta?negocio=${dealId}`}
        isNextLink
      >
        <Button style={{ width: "100%" }} htmlType="button" type="primary">
          Gerar proposta
        </Button>
      </SolarzAnchor>
    </StyledCard>
  );
}
