import { BiLinkExternal } from "react-icons/bi";

import Link from "next/link";

import {
  IUpdatePipedriveTokenApiFormProps,
  IUpdatePipedriveTokenApiFormValues,
} from "./interface";

import { CustomInput } from "~components/CustomInput";
import {
  useFetchGetPipedriveApiToken,
  useFetchUpdatePipedriveApiToken,
} from "~hooks/api";
import { Button as AntdButton, Flex, Form } from "antd";

const DEFAULT_VALUES: IUpdatePipedriveTokenApiFormValues = {
  apiToken: "",
};

export function UpdatePipedriveTokenApiForm({
  formInstance,
  onCancel,
  onError,
  onSuccess,
  footerJustifyStyle = "flex-end",
}: IUpdatePipedriveTokenApiFormProps) {
  const [form] = Form.useForm<IUpdatePipedriveTokenApiFormValues>(formInstance);

  const { error: initialValuesError } = useFetchGetPipedriveApiToken({
    options: {
      onSuccess: (data) => {
        const apiToken = data.apiToken;
        if (apiToken) {
          form.setFieldValue("apiToken", apiToken);
        }
      },
    },
  });

  const {
    mutate: updatePipedriveApiToken,
    isLoading: isUpdatingPipedriveApiToken,
  } = useFetchUpdatePipedriveApiToken({
    options: {
      onError,
      onSuccess,
    },
  });

  function handleCancel() {
    return onCancel ? onCancel() : form.resetFields();
  }

  function handleSubmit(formData: IUpdatePipedriveTokenApiFormValues) {
    updatePipedriveApiToken(formData);
  }

  return (
    <Form
      form={form}
      initialValues={DEFAULT_VALUES}
      onFinish={handleSubmit}
      layout="vertical"
    >
      <Form.Item<IUpdatePipedriveTokenApiFormValues>
        name="apiToken"
        label="Pipedrive api token"
        tooltip={
          <span>
            Aprenda como{" "}
            <Link
              style={{ display: "flex", alignItems: "center", gap: 4 }}
              href="https://support.pipedrive.com/pt/article/how-can-i-find-my-personal-api-key"
              target="_blank"
              rel="noopener noreferrer"
            >
              conseguir o token <BiLinkExternal />
            </Link>
          </span>
        }
        rules={[
          {
            min: 40,
            message: "Tokens do Pipedrive possuem ao menos 40 caracteres",
          },
        ]}
        extra={
          initialValuesError?.message &&
          `Error ao obter token atual: ${initialValuesError?.message}`
        }
      >
        <CustomInput.Text />
      </Form.Item>

      <Flex align="center" justify={footerJustifyStyle} gap={8}>
        <AntdButton onClick={handleCancel}>Cancelar</AntdButton>
        <AntdButton
          htmlType="submit"
          type="primary"
          loading={isUpdatingPipedriveApiToken}
        >
          Salvar
        </AntdButton>
      </Flex>
    </Form>
  );
}
