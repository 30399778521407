import { api, apiException } from "~services/api";
import {
  FetchPatchPersonDefaultFieldsSettingsPayloadSchema,
  PersonDefaultFieldSettingsDTOSchema,
  type IFetchPatchPersonDefaultFieldsSettingsPayloadSchema,
} from "~types/schemas";

export const fetchPatchPersonDefaultFieldsSettings = async (
  payload: IFetchPatchPersonDefaultFieldsSettingsPayloadSchema,
) => {
  try {
    const { id, ...body } =
      FetchPatchPersonDefaultFieldsSettingsPayloadSchema.parse(payload);

    const response = await api.patch(
      `/config/persons/default-fields/settings/${id}`,
      body,
    );

    const parsedData = PersonDefaultFieldSettingsDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
