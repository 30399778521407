import { AiOutlineMinusCircle, AiOutlinePlus } from "react-icons/ai";

import {
  ISimulationOptionFormValues,
  ISimulationOptionPaymentType,
  ISimulationOptionsType,
  PaymentSimulationOptionsFormProps,
} from "./interface";
import styles from "./styles";

import { CustomInput } from "~components/CustomInput";
import {
  useFetchCreateManyPaymentSimulationOptions,
  useFetchCreatePaymentSimulation,
  useFetchUpdateManyPaymentSimulationOptions,
  useFetchDeletePaymentSimulationOption,
  useFetchGetSimulationOptionById,
} from "~hooks/api";
import { BaseCalculationEnum } from "~types/enum";
import { IUpdateProjectPaymentSimulationOptionFormSchema } from "~types/schemas";
import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";

function getBaseCalculationByPaymentType(
  value?: ISimulationOptionPaymentType,
): BaseCalculationEnum | undefined {
  switch (value) {
    case "FIXED":
      return "VALOR_FIXO";
    case "PERCENTAGE_COMPLEMENTARY_COSTS":
      return "CUSTOS_COMPLEMENTARES";
    case "PERCENTAGE_MATERIAL":
      return "VALOR_DO_MATERIAL";
    case "PERCENTAGE_TOTAL_VALUE":
      return "VALOR_TOTAL";
    default:
      return undefined;
  }
}

function getPaymentTypeByBaseCalculation(value?: BaseCalculationEnum) {
  switch (value) {
    case "CUSTOS_COMPLEMENTARES":
      return "PERCENTAGE_COMPLEMENTARY_COSTS";
    case "VALOR_DO_MATERIAL":
      return "PERCENTAGE_MATERIAL";
    case "VALOR_TOTAL":
      return "PERCENTAGE_TOTAL_VALUE";
    default:
      return "FIXED";
  }
}

function createRequestBodyByOptions(
  options: ISimulationOptionsType[] = [],
  projectPaymentSimulationOptionId = 0,
) {
  return options.map((option) => {
    const baseCalculation = getBaseCalculationByPaymentType(option.paymentType);

    const downPaymentPercentage =
      baseCalculation !== "VALOR_FIXO"
        ? option.downPaymentPercentage
        : undefined;

    const downPaymentFixedValue =
      baseCalculation === "VALOR_FIXO"
        ? option.downPaymentFixedValue
        : undefined;

    const id = option.id ?? 0; // YES IT CAN BE ZERO!

    const data: IUpdateProjectPaymentSimulationOptionFormSchema = {
      id,
      projectPaymentSimulationOptionId,
      name: option.name ?? "",
      installments: option.installments ?? 0,
      monthlyInterestRate: option.monthlyInterestRate ?? 0,
      downPaymentPercentage,
      downPaymentFixedValue,
      baseCalculation,
    };

    return data;
  });
}

export function PaymentSimulationOptionsForm({
  paymentSimulationId,
  onCancel,
  onError,
  onSuccess,
  formInstance,
}: PaymentSimulationOptionsFormProps) {
  const [form] = Form.useForm<ISimulationOptionFormValues>(formInstance);

  const {
    mutate: fetchCreateManyPaymentSimulationOptions,
    isLoading: isCreatingPaymentSimulationOptions,
  } = useFetchCreateManyPaymentSimulationOptions({
    options: {
      onSuccess,
      onError,
    },
    displaySuccessMessage: false,
  });

  const {
    mutate: fetchUpdateManyPaymentSimulationOptions,
    isLoading: isUpdatingPaymentSimulationOptions,
  } = useFetchUpdateManyPaymentSimulationOptions({
    options: {
      onSuccess,
      onError,
    },
  });

  const {
    mutateAsync: fetchCreatePaymentSimulation,
    isLoading: isCreatingPaymentSimulation,
  } = useFetchCreatePaymentSimulation({});

  const { mutate: fetchDeletePaymentSimulationOption } =
    useFetchDeletePaymentSimulationOption({});

  const { isFetching: isLoadingPaymentOptions } =
    useFetchGetSimulationOptionById({
      payload: {
        projectPaymentSimulationGroupId: paymentSimulationId ?? 0,
      },
      options: {
        enabled: Number(paymentSimulationId) > 0,
        onSuccess(data) {
          const parsedPaymentOptions: ISimulationOptionsType[] = data.map(
            (option) => ({
              ...option,
              paymentType: getPaymentTypeByBaseCalculation(
                option.baseCalculation,
              ),
            }),
          );

          form.setFieldValue("options", parsedPaymentOptions);
        },
      },
    });

  async function onFinish(formValues: ISimulationOptionFormValues) {
    if (!paymentSimulationId) {
      const { id = 0 } = await fetchCreatePaymentSimulation({
        name: formValues.name,
      });

      const payload = createRequestBodyByOptions(formValues.options, id);

      fetchCreateManyPaymentSimulationOptions(payload);
    } else {
      const payload = createRequestBodyByOptions(
        formValues.options,
        paymentSimulationId,
      );
      fetchUpdateManyPaymentSimulationOptions(payload);
    }
  }

  function handleCancel() {
    typeof onCancel === "function" ? onCancel() : form.resetFields();
  }

  function handleDeleteOption(fieldIndex: number, callback: () => void) {
    const id = form.getFieldValue(["options", fieldIndex, "id"]);

    if (id) {
      fetchDeletePaymentSimulationOption({
        projectPaymentSimulationOptionId: id,
      });
    }

    callback();
  }

  return (
    <Form
      data-cy="payment-simulation-options-form"
      layout="vertical"
      name="control-ref"
      form={form}
      onFinish={onFinish}
      initialValues={
        {
          name: "",
          options: [
            {
              name: undefined,
              paymentType: "PERCENTAGE_TOTAL_VALUE",
              downPaymentFixedValue: undefined,
              downPaymentPercentage: 0,
              monthlyInterestRate: 0,
              installments: 0,
              id: undefined,
            },
          ],
        } as ISimulationOptionFormValues
      }
    >
      <style jsx>{styles}</style>

      {!paymentSimulationId && (
        <Row gutter={12} justify="center">
          <Col xs={24}>
            <Form.Item
              name="name"
              label="Nome da simulação"
              rules={[{ required: true }]}
            >
              <CustomInput.Text placeholder="Nome da simulação" />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Typography.Title level={5} style={{ fontWeight: "400" }}>
        Opções de pagamento
      </Typography.Title>

      <Spin spinning={isLoadingPaymentOptions}>
        <div
          style={{
            overflowY: "visible",
            overflowX: "hidden",
            marginBottom: "1rem",
          }}
        >
          <Form.List name="options">
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map(({ key, name, ...restField }, index) => {
                    const { options = [] } = form.getFieldsValue();

                    const paymentTypeValue: ISimulationOptionPaymentType =
                      options[name]?.paymentType ?? "PERCENTAGE_TOTAL_VALUE";

                    return (
                      <Row key={key} gutter={11} style={{ textAlign: "left" }}>
                        <Form.Item name={[name, "id"]} hidden>
                          <Input readOnly disabled />
                        </Form.Item>

                        <Col xs={24} md={6}>
                          <Form.Item
                            label="Nome"
                            {...restField}
                            name={[name, "name"]}
                            rules={[
                              {
                                required: true,
                                message: "Por favor, insira o nome",
                              },
                            ]}
                          >
                            <CustomInput.Text placeholder="Ex.: Pagamento 01" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={4}>
                          <Form.Item
                            {...restField}
                            name={[name, "installments"]}
                            label="Número de parcelas"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                            tooltip="Deve ser maior que 0"
                          >
                            <CustomInput.UnitMeasurement
                              precision="0"
                              unitMeasurement="meses"
                              min={0}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} md={4}>
                          <Form.Item
                            {...restField}
                            name={[name, "monthlyInterestRate"]}
                            label="Taxa de juros mensal"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                            tooltip="Não é permitido valor maior que 80% ao mês"
                          >
                            <CustomInput.UnitMeasurement
                              precision="2"
                              unitMeasurement="%"
                              min={0}
                              max={80}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} md={5}>
                          <Form.Item
                            {...restField}
                            name={[name, "paymentType"]}
                            label="Tipo do valor de entrada"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                            tooltip="O valor de entrada pode ser um percentual do valor, ou um valor fixado"
                          >
                            <Select<ISimulationOptionPaymentType>
                              options={[
                                {
                                  label: "Percentual s/ valor total",
                                  value: "PERCENTAGE_TOTAL_VALUE",
                                },
                                {
                                  label: "Percentual s/ custos complementares",
                                  value: "PERCENTAGE_COMPLEMENTARY_COSTS",
                                },
                                {
                                  label: "Percentual s/ o material",
                                  value: "PERCENTAGE_MATERIAL",
                                },
                                {
                                  label: "Valor fixo",
                                  value: "FIXED",
                                },
                              ]}
                              onChange={(paymentType) => {
                                const { options } = form.getFieldsValue();

                                const updatedOptions = options.map(
                                  (option, index) => {
                                    if (index === name) {
                                      return {
                                        ...option,
                                        paymentType,
                                      };
                                    } else {
                                      return option;
                                    }
                                  },
                                );

                                form.setFieldValue("options", updatedOptions);
                              }}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} md={4}>
                          {paymentTypeValue === "FIXED" ? (
                            <Form.Item
                              {...restField}
                              name={[name, "downPaymentFixedValue"]}
                              label="Valor de entrada"
                              required
                            >
                              <CustomInput.Money min={0} />
                            </Form.Item>
                          ) : (
                            <Form.Item
                              {...restField}
                              name={[name, "downPaymentPercentage"]}
                              label="Valor de entrada"
                              required
                            >
                              <CustomInput.UnitMeasurement
                                precision="2"
                                unitMeasurement="%"
                                min={0}
                                max={80}
                              />
                            </Form.Item>
                          )}
                        </Col>

                        <Col
                          xs={24}
                          md={1}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            disabled={fields.length === 1}
                            type="link"
                            onClick={() =>
                              handleDeleteOption(index, () => remove(name))
                            }
                            icon={<AiOutlineMinusCircle size={16} />}
                          />
                        </Col>
                      </Row>
                    );
                  })}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() =>
                        add({
                          name: "",
                          installments: 0,
                          monthlyInterestRate: 0,
                          downPaymentPercentage: 0,
                          paymentType: "PERCENTAGE_TOTAL_VALUE",
                          downPaymentFixedValue: 0,
                        } as ISimulationOptionsType)
                      }
                      block
                      className="addRowButton"
                    >
                      <Flex align="center" justify="center" gap={8}>
                        <AiOutlinePlus size={16} />
                        Novo intervalo
                      </Flex>
                    </Button>
                  </Form.Item>
                </>
              );
            }}
          </Form.List>
        </div>
      </Spin>
      <div
        style={{
          display: "flex",
          paddingTop: "1rem",
          gap: "0.5rem",
          justifyContent: "flex-end",
        }}
      >
        <Button onClick={handleCancel}>Cancelar</Button>
        <Button
          type="primary"
          htmlType="submit"
          loading={
            isCreatingPaymentSimulationOptions ||
            isUpdatingPaymentSimulationOptions ||
            isCreatingPaymentSimulation
          }
        >
          {paymentSimulationId ? "Atualizar" : "Criar"}
        </Button>
      </div>
    </Form>
  );
}
