import styles from "./styles";

import { Typography } from "antd";

interface IPaginationInfo {
  total: number;
  start: number;
  end: number;
  textItemType?: string;
}

export function PaginationInfo({
  total,
  start,
  end,
  textItemType = "itens",
}: IPaginationInfo) {
  const contentText = `${start} - ${end} de ${total} ${textItemType}.`;

  return (
    <>
      <style jsx>{styles}</style>
      <Typography.Text data-cy="pagination-info" className="paginationInfo">
        {contentText}
      </Typography.Text>
    </>
  );
}
