export * from "./ActivityTable/interface";
import { ActivityTable } from "./ActivityTable";
export * from "./AdditionalCostTaxesTable/interface";
import { AdditionalCostTaxesTable } from "./AdditionalCostTaxesTable";
export * from "./AdminDatasheetTable/interface";
export * from "./AdminDatasheetRevisionsTable/interface";
export * from "./AdminsTable/interface";
import { AdminDatasheetRevisionsTable } from "./AdminDatasheetRevisionsTable";
import { AdminDatasheetTable } from "./AdminDatasheetTable";
export * from "./AdminDistributorsTable/interface";
import { AdminDistributorsTable } from "./AdminDistributorsTable";
export * from "./AdminInvertersTable/interface";
import { AdminInvertersTable } from "./AdminInvertersTable";
export * from "./AdminLogsTable/interface";
import { AdminLogsTable } from "./AdminLogsTable";
export * from "./AdminModulesTable/interface";
import { AdminModulesTable } from "./AdminModulesTable";
export * from "./AdminModulesTable/interface";
import { AdminsTable } from "./AdminsTable";
export * from "./AdminsTable/interface";
export * from "./AdminUsersTable/interface";
import { AdminUsersTable } from "./AdminUsersTable";
export * from "./AneelTable/interface";
import { AneelTable } from "./AneelTable";
export * from "./BusinessUnitTable/interface";
import { BusinessUnitTable } from "./BusinessUnitTable";
export * from "./ComplementaryItemsOfSettingsTable/interface";
import { ComplementaryItemsOfSettingsTable } from "./ComplementaryItemsOfSettingsTable";
export * from "./ComplementaryItemsTable/interface";
import { ComplementaryItemsTable } from "./ComplementaryItemsTable";
export * from "./ConcessionairesTable/interface";
import { ConcessionaireTable } from "./ConcessionairesTable";
export * from "./DealCustomDataFieldsTable/interface";
import { DealCustomDataFieldsTable } from "./DealCustomDataFieldsTable";
export * from "./DealDefaultDataFieldsTable/interface";
import { DealDefaultFieldsTable } from "./DealDefaultDataFieldsTable";
export * from "./DiverseKitsTable/interface";
import { DiverseKitsTable } from "./DiverseKitsTable";
export * from "./EosFinancialProposalsTable/interface";
import { EosFinancialProposalsTable } from "./EosFinancialProposalsTable";
export * from "./ReasonForLossTable/interface";
export * from "./KitItemsDetailsTable/interface";
import { KitItemsDetailsTable } from "./KitItemsDetailsTable";
export * from "./KitsTable/interface";
import { KitsTable } from "./KitsTable";
export * from "./LaborTable/interface";
import { LaborTable } from "./LaborTable";
export * from "./LogsTable/interface";
import { LogsTable } from "./LogsTable";
export * from "./OrdersTable/interface";
import { OrdersTable } from "./OrdersTable";
export * from "./OrganizationDefaultFieldsTable/interface";
import { OrganizationDefaultFieldsTable } from "./OrganizationDefaultFieldsTable";
export * from "./OrganizationTable/interface";
import { OrganizationTable } from "./OrganizationTable";
export * from "./PermissionsMultiTable/interface";
import { PermissionsMultiTable } from "./PermissionsMultiTable";
export * from "./PersonCustomDataFieldsTable/interface";
import { PersonCustomDataFieldsTable } from "./PersonCustomDataFieldsTable";
export * from "./PersonDefaultDataFieldsTable/interface";
import { PersonDefaultDataFieldsTable } from "./PersonDefaultDataFieldsTable";
export * from "./PersonsTable/interface";
import { PersonsTable } from "./PersonsTable";
export * from "./PipelineDealsListTable/interface";
import { PipelineDealsListTable } from "./PipelineDealsListTable";
export * from "./PipelineDealsTable/interface";
import { PipelineDealsTable } from "./PipelineDealsTable";
export * from "./ProductsInvertersTable/interface";
import { ProductsInvertersTable } from "./ProductsInvertersTable";
export * from "./ProductsKitsTable/interface";
import { ProductsKitsTable } from "./ProductsKitsTable";
export * from "./ProductsModulesTable/interface";
import { ProductsModulesTable } from "./ProductsModulesTable";
export * from "./ProductsOthersTable/interface";
import { ProductsOthersTable } from "./ProductsOthersTable";
export * from "./ProductsStructuresTable/interface";
import { ProductsStructuresTable } from "./ProductsStructuresTable";
export * from "./ProposalCustomDataFieldsTable/interface";
import { ProposalCustomDataFieldsTable } from "./ProposalCustomDataFieldsTable";
export * from "./ProposalCustomFieldsTable/interface";
import { ProposalCustomFieldsTable } from "./ProposalCustomFieldsTable";
export * from "./ProposalDefaultFieldsTable/interface";
import { ProposalDefaultFieldsTable } from "./ProposalDefaultFieldsTable";
export * from "./ProposalsTable/interface";
import { ProposalsTable } from "./ProposalsTable";
import { ReasonForLossTable } from "./ReasonForLossTable";
export * from "./TransformerGroupRulesTable/interface";
import { TransformerGroupRulesTable } from "./TransformerGroupRulesTable";
export * from "./UsersTable/interface";
import { UsersTable } from "./UsersTable";

export const Table = {
  Activity: ActivityTable,
  AdditionalCostTaxes: AdditionalCostTaxesTable,
  AdminDatasheet: AdminDatasheetTable,
  AdminDatasheetRevisions: AdminDatasheetRevisionsTable,
  AdminDistributors: AdminDistributorsTable,
  AdminInverters: AdminInvertersTable,
  AdminLogs: AdminLogsTable,
  AdminModules: AdminModulesTable,
  AdminUsers: AdminUsersTable,
  Admins: AdminsTable,
  Aneel: AneelTable,
  BusinessUnit: BusinessUnitTable,
  ComplementaryItemsOfSettings: ComplementaryItemsOfSettingsTable,
  ComplementaryItems: ComplementaryItemsTable,
  Concessionaire: ConcessionaireTable,
  DiverseKits: DiverseKitsTable,
  DealCustomDataFields: DealCustomDataFieldsTable,
  DealDefaultFields: DealDefaultFieldsTable,
  EosFinancialProposals: EosFinancialProposalsTable,
  KitItemsDetails: KitItemsDetailsTable,
  Kits: KitsTable,
  Labor: LaborTable,
  Logs: LogsTable,
  Orders: OrdersTable,
  Organization: OrganizationTable,
  OrganizationDefaultFields: OrganizationDefaultFieldsTable,
  PermissionsMulti: PermissionsMultiTable,
  PersonCustomDataFields: PersonCustomDataFieldsTable,
  PersonDefaultDataFields: PersonDefaultDataFieldsTable,
  Persons: PersonsTable,
  PipelineDealsList: PipelineDealsListTable,
  PipelineDeals: PipelineDealsTable,
  ProductsInverters: ProductsInvertersTable,
  ProductsKits: ProductsKitsTable,
  ProductsModules: ProductsModulesTable,
  ProductsOthers: ProductsOthersTable,
  ProductsStructures: ProductsStructuresTable,
  ProposalCustomDataFields: ProposalCustomDataFieldsTable,
  ProposalCustomFields: ProposalCustomFieldsTable,
  ProposalDefaultFields: ProposalDefaultFieldsTable,
  Proposals: ProposalsTable,
  ReasonForLoss: ReasonForLossTable,
  TransformerGroupRules: TransformerGroupRulesTable,
  Users: UsersTable,
};
