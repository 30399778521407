import type { IAssignVariableWithPipedriveFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function AssignVariableWithPipedriveFormModal({
  isOpen = false,
  onClose = () => null,
  title = "Associar variável",
  maxWidth = 768,
  formProps,
}: IAssignVariableWithPipedriveFormModalProps) {
  return (
    <Modal
      data-cy="assign-variable-with-pipedrive-form-modal"
      open={isOpen}
      onCancel={onClose}
      title={title}
      width="90vw"
      footer={null}
      style={{ maxWidth, minHeight: "72vh" }}
      destroyOnClose
    >
      <Form.AssignVariableWithPipedrive {...formProps} onCancel={onClose} />
    </Modal>
  );
}
