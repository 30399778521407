import { AdminBreadcrumb } from "./AdminBreadcrumb";
import { DefaultBreadcrumb } from "./DefaultBreadcrumb";
import { LoggedUserBreadcrumb } from "./LoggedUserBreadcrumb";
import { NewOrganizationModalInSelectBreadcrumb } from "./NewOrganizationModalInSelectBreadcrumb";
import { NewPersonModalInSelectBreadcrumb } from "./NewPersonModalInSelectBreadcrumb";

export const Breadcrumb = {
  Admin: AdminBreadcrumb,
  Default: DefaultBreadcrumb,
  LoggedUser: LoggedUserBreadcrumb,
  NewOrganizationModalInSelect: NewOrganizationModalInSelectBreadcrumb,
  NewPersonModalInSelect: NewPersonModalInSelectBreadcrumb,
};
