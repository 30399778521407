import { useMemo, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";

import {
  IPaymentSimulationDataType,
  IPaymentSimulationsList,
} from "./interface";

import { Menu } from "~components/Menu";
import { Modal } from "~components/Modal";
import { Result } from "~components/Result";
import {
  useFetchDeletePaymentSimulation,
  useFetchGetAllPaymentSimulations,
} from "~hooks/api";
import { createUuid } from "~utils/createUuid";
import { Flex, List } from "antd";

export function PaymentSimulationsList(props: IPaymentSimulationsList) {
  const [simulationToUpdate, setSimulationToUpdate] =
    useState<IPaymentSimulationDataType>();
  const [simulationToDelete, setSimulationToDelete] =
    useState<IPaymentSimulationDataType>();

  const { data, isLoading, error } = useFetchGetAllPaymentSimulations({});

  const {
    mutate: fetchDeleteSimulationGroup,
    isLoading: isDeletePaymentSimulationLoading,
  } = useFetchDeletePaymentSimulation({
    options: {
      onSuccess: () => {
        setSimulationToDelete(undefined);
      },
    },
  });

  const handleDeleteSimulationGroup = async (simulationId?: number) => {
    if (!simulationId) {
      throw new Error("Faltando ID da simulação");
    }

    fetchDeleteSimulationGroup({ simulationId });
  };

  const DATA_SOURCE: IPaymentSimulationDataType[] = useMemo(() => {
    if (!data) return [];

    return data.map((payment) => ({
      key: createUuid(),
      id: payment.id,
      name: payment.name ?? "",
      optionsCount: payment.optionsCount ?? 0,
    }));
  }, [data]);

  return (
    <>
      <List<IPaymentSimulationDataType>
        data-cy="payment-simulations-list"
        {...props}
        itemLayout="horizontal"
        dataSource={DATA_SOURCE}
        loading={isLoading || isDeletePaymentSimulationLoading}
        style={{ width: "100%" }}
        locale={{
          emptyText: (
            <Result.TableErrorOrEmpty
              errorMessage={error?.message}
              emptyMessage="Nenhuma simulação de pagamento encontrada."
            />
          ),
        }}
        rowKey="key"
        renderItem={(item) => (
          <List.Item
            style={{ padding: "20px 0" }}
            data-cy="payment-simulation-item"
          >
            <Flex
              align="center"
              justify="space-between"
              style={{ width: "100%", paddingRight: "0.5rem" }}
            >
              <List.Item.Meta
                title={item.name}
                description={`Total de opções: ${item.optionsCount}`}
              />
              <Flex align="center" gap={16} style={{ marginRight: "1rem" }}>
                <Menu.MoreDropdown
                  position="bottomRight"
                  icon="cog"
                  items={[
                    {
                      icon: (
                        <AiOutlineEdit
                          fontSize={20}
                          style={{ color: "var(--gold-500)" }}
                        />
                      ),
                      text: "Editar",
                      color: "var(--gold-500)",
                      onClick: () => setSimulationToUpdate(item),
                    },
                    {
                      icon: (
                        <AiOutlineDelete
                          fontSize={20}
                          style={{ color: "var(--volcano-500)" }}
                        />
                      ),
                      text: "Remover",
                      color: "var(--volcano-500)",
                      onClick: () => setSimulationToDelete(item),
                    },
                  ]}
                />
              </Flex>
            </Flex>
          </List.Item>
        )}
      />
      <Modal.DefaultRemove
        isOpen={!!simulationToDelete}
        onClose={() => setSimulationToDelete(undefined)}
        itemName={simulationToDelete?.name}
        suffix="simulação de pagamento"
        title="Remover simulação de pagamento"
        onSubmit={() => handleDeleteSimulationGroup(simulationToDelete?.id)}
      />
      <Modal.PaymentSimulationForm
        isOpen={!!simulationToUpdate}
        onClose={() => setSimulationToUpdate(undefined)}
        formProps={{
          initialValues: {
            id: simulationToUpdate?.id,
            name: simulationToUpdate?.name,
            optionsCount: simulationToUpdate?.optionsCount,
          },
        }}
      />
    </>
  );
}
