import { useState } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

import type {
  IOrdersTableColumnType,
  IOrdersTableProps,
  IOrdersTablePagination,
  IOrdersTableData,
  IOrdersTableFilters,
} from "./interface";

import { ColumnFilter } from "~components/ColumnFilter";
import { CustomInput } from "~components/CustomInput";
import { createPaginationTableObject } from "~components/Pagination";
import { Result } from "~components/Result";
import { OrderStatusType, Select } from "~components/Select";
import { UniqueValueTagColorType } from "~components/Tag";
import { UniqueValueTag } from "~components/Tag/UniqueValueTag";
import { parseCurrency, parseInputValueTypeToNumber } from "~utils/parse";
import { Button, Flex, Form, Table } from "antd";

function getTagColorByStatus(
  status: OrderStatusType | undefined,
): UniqueValueTagColorType {
  switch (status) {
    case "Confirmado":
      return "cyan";
    case "Entregue":
      return "green";
    case "Recebido":
      return "default";
    case "Produção":
      return "blue";
    case "Enviado":
      return "geekblue";
    default:
      return "default";
  }
}

function renderStatusColumn(value: OrderStatusType | undefined) {
  if (!value) {
    return <>-</>;
  }

  const tagColor = getTagColorByStatus(value);

  return <UniqueValueTag color={tagColor} title={value} tooltip={value} />;
}

function renderComponentByNFStatus(nfStatus: string | undefined) {
  switch (nfStatus) {
    case "nfSended":
      return (
        <Button type="text" style={{ color: "#1890ff" }}>
          <Flex align="center" gap={4}>
            <AiOutlineMinus />
            abrir
          </Flex>
        </Button>
      );
    case "nfNotSended":
      return (
        <Button type="text" style={{ color: "#1890ff" }}>
          <Flex align="center" gap={4}>
            <AiOutlinePlus />
            anexar
          </Flex>
        </Button>
      );
    default:
      return <>Indisponível</>;
  }
}

export function OrdersTable({
  defaultFilters = {
    client: undefined,
    code: undefined,
    distributors: undefined,
    itemsAmount: undefined,
    price: undefined,
    status: undefined,
  },
  size = "small",
}: IOrdersTableProps) {
  const [filters, setFilters] = useState<IOrdersTableFilters>(defaultFilters);

  const [pagination, setPagination] = useState<IOrdersTablePagination>({
    number: 1,
    totalElements: 0,
    pageSize: 10, // not change!
  });

  function onChangeFilters(filters: Partial<IOrdersTableFilters>) {
    setFilters((state) => ({ ...state, ...filters }));
  }

  const columns: IOrdersTableColumnType[] = [
    {
      title: "Número",
      dataIndex: "code",
      render: (value: number) => (value ? `#${value}` : "-"),
      width: 96,
      filterDropdown: (
        <ColumnFilter
          fields={[
            <Form.Item key="code" htmlFor="code" label="Número" colon={false}>
              <CustomInput.Number
                id="code"
                value={filters.code}
                onChange={(value) => {
                  const parsedValue = parseInputValueTypeToNumber(value);
                  onChangeFilters({ code: parsedValue });
                }}
                placeholder="Número..."
                style={{ width: "100%" }}
              />
            </Form.Item>,
          ]}
          onReset={() => onChangeFilters({ code: undefined })}
          // isLoading={isFetching}
          // onSubmit={handleSubmit}
        />
      ),
      filteredValue: filters.code ? [true] : [],
    },
    {
      title: "Status",
      dataIndex: "status",
      render: renderStatusColumn,
      width: 136,
      align: "center" as const,
      filterDropdown: (
        <ColumnFilter
          fields={[
            <Form.Item
              key="status"
              htmlFor="status"
              label="Número de itens"
              colon={false}
            >
              <Select.OrderStatus
                allowClear
                value={filters.status}
                onChange={(value) => {
                  onChangeFilters({ status: value });
                }}
              />
            </Form.Item>,
          ]}
          onReset={() => onChangeFilters({ status: undefined })}
          // isLoading={isFetching}
          // onSubmit={handleSubmit}
        />
      ),
      filteredValue: filters.status ? [true] : [],
    },
    {
      title: "N. Itens",
      dataIndex: "itemsAmount",
      render: (value: number) => value ?? "-",
      width: 96,
      align: "center" as const,
      filterDropdown: (
        <ColumnFilter
          fields={[
            <Form.Item
              key="itemsAmount"
              htmlFor="itemsAmount"
              label="Número de itens"
              colon={false}
            >
              <CustomInput.Number
                id="itemsAmount"
                value={filters.itemsAmount}
                onChange={(value) => {
                  const parsedValue = parseInputValueTypeToNumber(value);
                  onChangeFilters({ itemsAmount: parsedValue });
                }}
                placeholder="Número de itens..."
                precision={0}
                style={{ width: "100%" }}
              />
            </Form.Item>,
          ]}
          onReset={() => onChangeFilters({ itemsAmount: undefined })}
          // isLoading={isFetching}
          // onSubmit={handleSubmit}
        />
      ),
      filteredValue: filters.itemsAmount ? [true] : [],
    },
    {
      title: "Valor",
      dataIndex: "price",
      render: (value: number) => (value ? parseCurrency(value) : "-"),
      width: 176,
      align: "center" as const,
      filterDropdown: (
        <ColumnFilter
          fields={[
            <Form.Item key="price" htmlFor="price" label="Valor" colon={false}>
              <CustomInput.Money
                id="price"
                value={filters.price}
                onChange={(value) => {
                  const parsedValue = parseInputValueTypeToNumber(value);
                  onChangeFilters({ price: parsedValue });
                }}
                placeholder="Valor..."
                style={{ width: "100%" }}
              />
            </Form.Item>,
          ]}
          onReset={() => onChangeFilters({ price: undefined })}
          // isLoading={isFetching}
          // onSubmit={handleSubmit}
        />
      ),
      filteredValue: filters.price ? [true] : [],
    },
    {
      title: "Cliente",
      dataIndex: "client",
      render: (value: string) => value ?? "-",
      filterDropdown: (
        <ColumnFilter
          fields={[
            <Form.Item
              key="client"
              htmlFor="client"
              label="Cliente"
              colon={false}
            >
              <CustomInput.Text
                id="client"
                value={filters.client}
                onChange={(event) => {
                  const parsedValue = event.target.value;
                  onChangeFilters({ client: parsedValue });
                }}
                placeholder="Cliente..."
              />
            </Form.Item>,
          ]}
          onReset={() => onChangeFilters({ client: undefined })}
          // isLoading={isFetching}
          // onSubmit={handleSubmit}
        />
      ),
      filteredValue: filters.client ? [true] : [],
    },
    {
      title: "NF",
      dataIndex: "nfStatus",
      render: renderComponentByNFStatus,
      width: 128,
    },
    {
      title: "Distribuidores",
      dataIndex: "distributors",
      render: (value: string) => value ?? "-",
      filterDropdown: (
        <ColumnFilter
          fields={[
            <Form.Item
              key="distributors"
              htmlFor="distributors"
              label="Distribuidor"
              colon={false}
            >
              <CustomInput.Text
                id="distributors"
                value={filters.distributors}
                onChange={(event) => {
                  const parsedValue = event.target.value;
                  onChangeFilters({ distributors: parsedValue });
                }}
                placeholder="Distribuidor..."
              />
            </Form.Item>,
          ]}
          onReset={() => onChangeFilters({ distributors: undefined })}
          // isLoading={isFetching}
          // onSubmit={handleSubmit}
        />
      ),
      filteredValue: filters.distributors ? [true] : [],
    },
  ];

  return (
    <Table
      data-cy="orders-table"
      rowKey="code"
      columns={columns}
      dataSource={
        [
          {
            code: 9123,
            status: "Confirmado",
            client: "Lorem Ipsum",
            distributors: "Mundo distribuidora, Estruturas e CIA",
            itemsAmount: 3,
            nfStatus: "confirmed",
            price: 36635,
          },
        ] as IOrdersTableData[]
      }
      size={size}
      scroll={{ x: 720 }}
      locale={{
        emptyText: (
          <Result.TableErrorOrEmpty
            // errorMessage={error?.message}
            emptyMessage="Nenhum pedido encontrada."
          />
        ),
      }}
      style={{ width: "100%" }}
      pagination={createPaginationTableObject({
        number: pagination.number,
        size: pagination.pageSize,
        totalElements:
          pagination.number > 1
            ? pagination.totalElements - pagination.pageSize
            : pagination.totalElements,
        onChange: (page: number) =>
          setPagination((state) => ({ ...state, number: page })),
        disabled: false,
        itemType: "pedidos",
      })}
    />
  );
}
