import { IMonthlyFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function MonthlyFormModal({
  isOpen = false,
  onClose = () => undefined,
  title = "Consumo mês a mês",
  closeOnSubmit = true,
  maxWidth = 680,
  formProps,
}: IMonthlyFormModalProps) {
  return (
    <Modal
      data-cy="monthly-form-modal"
      title={title}
      open={isOpen}
      onCancel={onClose}
      footer={null}
      destroyOnClose
      width="90vw"
      style={{ maxWidth }}
    >
      <Form.Monthly
        {...formProps}
        onCancel={onClose}
        onSubmit={(data) => {
          if (typeof formProps?.onSubmit !== "undefined")
            formProps.onSubmit(data);

          if (closeOnSubmit) onClose();
        }}
      />
    </Modal>
  );
}
