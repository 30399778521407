import { useState } from "react";

import { useRouter } from "next/router";

import {
  type IPipelineDealsListTableProps,
  type PipelineDealsListTableDataType,
  IPipelineDealsListTableFiltersType,
} from "./interface";
import { StyledSolarzAnchor } from "./styles";

import { Avatar } from "~components/Avatar";
import { ColumnFilter } from "~components/ColumnFilter";
import { Tag } from "~components/Tag";
import { Company, People } from "~estrela-ui/icons";
import { useFetchGetPipelineDealsListById } from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { useSession } from "~hooks/useSession";
import { SolarzAnchor } from "~solarzui/SolarzAnchor";
import { SolarzInput } from "~solarzui/SolarzInput";
import { SolarzTable } from "~solarzui/SolarzTable";
import { formatTelephoneWithDdd } from "~utils/format";
import { parseCurrency } from "~utils/parse";
import { Form, Select, Tooltip } from "antd";

const DEFAULT_FILTERS: IPipelineDealsListTableFiltersType = {
  page: 1,
  size: 10,
};

export function PipelineDealsListTable({
  defaultFilters,
  pipelineId,
  responsibleSellerId,
}: IPipelineDealsListTableProps) {
  const router = useRouter();
  const { screens } = useAppConfig();
  const { userHavePermission, userIsAdmin } = useSession();

  const [filters, setFilters] = useState<IPipelineDealsListTableFiltersType>({
    ...DEFAULT_FILTERS,
    ...defaultFilters,
  });

  const {
    data: deals,
    isFetching: isLoadingDeals,
    error: dealsError,
    refetch: reloadDeals,
  } = useFetchGetPipelineDealsListById({
    payload: {
      ...filters,
      page: filters.page - 1,
      pipelineId,
      responsibleSellerId,
    },
    dependencyArray: [pipelineId, responsibleSellerId, filters.page],
    options: {
      retry: 1,
      enabled: filters.page > 0 && !!pipelineId,
    },
  });

  function handleChangeFilter(
    filters: Partial<IPipelineDealsListTableFiltersType>,
  ) {
    setFilters((currentData) => {
      return { ...currentData, ...filters };
    });
  }

  function handleFetchTableData() {
    filters.page > 1
      ? setFilters((current) => ({ ...current, pageNumber: 1 }))
      : reloadDeals();
  }

  return (
    <SolarzTable<PipelineDealsListTableDataType>
      data-cy="pipeline-deals-list-table"
      columns={[
        {
          title: "TÍTULO",
          dataIndex: "name",
          key: "name",
          width: screens.mobile ? "calc(100vh)/3" : 234,
          align: screens.mobile ? "start" : undefined,
          render: (_, record) => {
            return (
              <StyledSolarzAnchor
                isNextLink
                href={`/funil/negocio/${record.id}`}
                style={{ display: "flex", textAlign: "start" }}
              >
                {record.name}
              </StyledSolarzAnchor>
            );
          },
          filterDropdown: (
            <ColumnFilter
              fields={[
                <Form.Item key="dealName" htmlFor="dealName" label="Título">
                  <SolarzInput
                    id="dealName"
                    value={filters.dealName}
                    onChange={(deals) => {
                      const dealName = deals ? String(deals) : undefined;
                      handleChangeFilter({
                        dealName,
                      });
                    }}
                    placeholder="Título..."
                  />
                </Form.Item>,
              ]}
              onReset={() => handleChangeFilter({ dealName: undefined })}
              isLoading={isLoadingDeals}
              onSubmit={handleFetchTableData}
            />
          ),
          filteredValue: filters?.dealName ? [filters.dealName] : undefined,
          onCell: screens.tablet
            ? (record) => {
                return {
                  onClick: () => router.push(`/funil/negocio/${record.id}`),
                };
              }
            : undefined,
        },
        {
          title: "VALOR",
          dataIndex: "value",
          key: "value",
          width: screens.mobile ? "calc(100vh)/3" : 92,
          align: screens.mobile ? "start" : "center",
          render: (_, record) => parseCurrency(record.value),
        },
        {
          title: "Responsável",
          dataIndex: "owner",
          width: 240,
          render: (_, record) => (
            <SolarzAnchor
              isNextLink
              isDisabled={!record.owner?.id}
              href={`/configuracoes-gerais/gerenciamento-de-usuarios?tab=usuarios&id=${record.owner?.id}`}
            >
              {record.owner ? (
                <div className="flex justify-start items-center gap-4">
                  <Avatar.CustomUser
                    avatarUrl={record.owner?.avatarUrl}
                    name={record.owner?.name}
                    height={32}
                    width={32}
                  />
                  {record.owner?.name}
                </div>
              ) : (
                <div>-</div>
              )}
            </SolarzAnchor>
          ),
          hidden: !userIsAdmin && userHavePermission("ACCESS_OWN_PROPOSALS"),
        },
        {
          title: "CLIENTE",
          dataIndex: "person",
          key: "client",
          width: 142,
          render: (_, record) => {
            if (record.person && record.person.name) {
              return (
                <Tooltip title={`Pessoa - ${record.person.name}`}>
                  <StyledSolarzAnchor
                    isNextLink
                    href={`/clientes/pessoa/${record.person.id}`}
                  >
                    <People />
                    {record.person.name}
                  </StyledSolarzAnchor>
                </Tooltip>
              );
            }
            if (record.organization && record.organization.name) {
              return (
                <Tooltip title={`Organização - ${record.organization.name}`}>
                  <StyledSolarzAnchor
                    isNextLink
                    href={`/clientes/empresa/${record.organization.id}`}
                  >
                    <Company />
                    {record.organization.name}
                  </StyledSolarzAnchor>
                </Tooltip>
              );
            }
            return "-";
          },
          hidden: !screens.desktop,
        },
        {
          title: "CONTATO",
          dataIndex: "person",
          key: "clientContact",
          align: "center",
          width: 92,
          render: (_, record) => {
            if (record.person && record.person.phone) {
              return formatTelephoneWithDdd(record.person.phone);
            }
            if (record.organization && record.organization.name) {
              return formatTelephoneWithDdd(record.organization.phone);
            }
            return "-";
          },
          hidden: !screens.desktop,
        },
        {
          title: "STATUS",
          dataIndex: "status",
          key: "status",
          width: screens.mobile ? "calc(100vh)/3" : 92,
          align: screens.mobile ? "start" : "center",
          render: (_, record) => (
            <Tag.UniqueValue
              title={
                record.status === "OPEN"
                  ? "Em andamento"
                  : record.status === "WON"
                    ? "Ganho"
                    : record.status === "LOST"
                      ? "Perdido"
                      : "Estado Desconhecido"
              }
              color={
                record.status === "OPEN"
                  ? "geekblue"
                  : record.status === "WON"
                    ? "green"
                    : record.status === "LOST"
                      ? "red"
                      : "orange"
              }
            />
          ),
          filterDropdown: (
            <ColumnFilter
              fields={[
                <Form.Item key="dealStatus" htmlFor="dealStatus" label="Status">
                  <Select
                    id="dealStatus"
                    options={[
                      { value: "WON", label: "Ganho" },
                      { value: "LOST", label: "Perdido" },
                      { value: "PENDING", label: "Em andamento" },
                    ]}
                    value={filters.dealStatus}
                    onChange={(status) => {
                      handleChangeFilter({
                        dealStatus: status,
                      });
                    }}
                    placeholder="Status..."
                  />
                </Form.Item>,
              ]}
              onReset={() => handleChangeFilter({ dealStatus: undefined })}
              isLoading={isLoadingDeals}
              onSubmit={handleFetchTableData}
            />
          ),
          filteredValue: filters.dealStatus ? [filters.dealStatus] : undefined,
        },
      ]}
      rows={deals?.content ?? []}
      pagination={{
        currentItemCount: deals?.size ?? 0,
        currentPage: filters?.page,
        itemLabel: "negócios",
        totalItemCount: deals?.totalElements ?? 0,
        onChange: (page) => handleChangeFilter({ page }),
        isDisabled: false,
      }}
      scroll={
        screens.mobile
          ? {
              x: "calc(100vw - 10px)",
              y: "calc(100vh - 326px)",
            }
          : {
              x: screens.desktop ? 1200 : 616,
            }
      }
      emptyMessage="Não foi encontrado nenhum negócio"
      rowKey="id"
      errorMessage={dealsError?.message}
      reloadFn={reloadDeals}
      isLoading={isLoadingDeals}
      showHeader={!screens.mobile}
      rowClassName={screens.desktop ? undefined : "cursor-pointer"}
    />
  );
}
