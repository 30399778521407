import { useMemo } from "react";

import type { IFortlevStructureSelectProps } from "./interface";

import { SelectSuffixIcon } from "~components/Others";
import { Result } from "~components/Result";
import { useFetchGetFortlevStructures } from "~hooks/api";
import { Select } from "antd";

export function FortlevStructureSelect({
  allowClear = true,
  onChange,
  ...props
}: IFortlevStructureSelectProps) {
  const {
    data: structures = [],
    error: structuresError,
    isFetching: isLoadingStructures,
    refetch: reloadStructures,
  } = useFetchGetFortlevStructures({
    dependencyArray: [],
    options: {
      retry: 1,
    },
  });

  const OPTIONS = useMemo(() => {
    return structures.map((structure) => ({
      label: structure.description,
      value: structure.id,
      record: structure,
    }));
  }, [structures]);

  return (
    <Select
      {...props}
      loading={isLoadingStructures}
      options={OPTIONS}
      allowClear={allowClear}
      status={structuresError ? "error" : ""}
      suffixIcon={
        <SelectSuffixIcon
          hasError={!!structuresError}
          isLoading={isLoadingStructures}
        />
      }
      notFoundContent={
        <Result.SelectErrorOrEmpty
          errorMessage={structuresError?.message}
          emptyMessage="Nenhuma estrutura da Fortlev foi encontrada"
          loading={isLoadingStructures}
          reloadFn={reloadStructures}
        />
      }
      onChange={(value, option) => {
        if (!onChange) return;
        onChange(value, option);
      }}
    />
  );
}
