import { useState } from "react";

import type { IUserFormProps, IUserFormValues } from "./interface";

import { Button } from "~components/Button";
import { CustomInput } from "~components/CustomInput";
import { Select } from "~components/Select";
import { useFetchCreateUser, useFetchUpdateUser } from "~hooks/api";
import { useSession } from "~hooks/useSession";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzFormItem } from "~solarzui/SolarzFormItem";
import { SolarzInput } from "~solarzui/SolarzInput";
import { SolarzInputNumber } from "~solarzui/SolarzInputNumber";
import { Alert, Form } from "antd";

export function UserForm({
  initialValues,
  onCancel = () => undefined,
  onError = () => undefined,
  onSuccess = () => undefined,
  hideProfileField = false,
}: IUserFormProps) {
  const { userIsAdmin, user } = useSession();
  const [form] = Form.useForm<IUserFormValues>();

  const [needCreateAlert, setCreateAlert] = useState(false);

  const { mutate: createUser, isLoading: isCreatingUser } = useFetchCreateUser({
    options: {
      onSuccess,
      onError: (error) => {
        const needCreateAlert = !!error?.message.includes(
          "Para criar um usuário é necessário configurar a integração com o Pipedrive",
        );

        setCreateAlert(needCreateAlert);
      },
    },
  });

  const { mutate: updateUser, isLoading: isUpdatingUser } = useFetchUpdateUser({
    options: {
      onSuccess,
      onError,
    },
  });

  function handleSubmit(formValues: IUserFormValues) {
    const { userId, ...payload } = formValues;
    userId ? updateUser({ userId, ...payload }) : createUser(payload);
  }

  const canChangeEmail = userIsAdmin && initialValues?.userId !== user?.id;

  const isLoading = isCreatingUser || isUpdatingUser;

  return (
    <>
      <Form
        data-cy="user-form"
        form={form}
        initialValues={initialValues}
        onFinish={handleSubmit}
        layout="vertical"
        style={{ width: "100%" }}
      >
        {needCreateAlert && (
          <Alert
            message={
              <span>
                Necessário configurar a integração com o Pipedrive{" "}
                <Button.UpdatePipedriveTokenApi
                  type="link"
                  size="small"
                  style={{ padding: 0 }}
                  closeOnSuccess
                  modalProps={{
                    formProps: {
                      onSuccess: () => {
                        setCreateAlert(false);
                      },
                    },
                  }}
                >
                  <span style={{ textDecoration: "underline" }}>
                    configure aqui
                  </span>
                </Button.UpdatePipedriveTokenApi>
              </span>
            }
            type="info"
            showIcon
            style={{
              marginTop: 12,
              marginBottom: 12,
            }}
          />
        )}

        <SolarzFormItem name="userId" hidden>
          <SolarzInputNumber placeholder="Ex.: 1" isDisabled />
        </SolarzFormItem>

        <SolarzFormItem
          name="name"
          label="Nome"
          required
          rules={[
            {
              required: true,
              message: "Nome é obrigatório",
            },
          ]}
        >
          <SolarzInput
            placeholder="Ex.: José da Silva"
            style={{ height: 48 }}
          />
        </SolarzFormItem>

        <SolarzFormItem
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: "Email é obrigatório",
            },
          ]}
        >
          <SolarzInput
            isDisabled={!canChangeEmail}
            placeholder="Ex.: exemplo@email.com"
            inputType="email"
            style={{ height: 48 }}
          />
        </SolarzFormItem>

        <SolarzFormItem
          name="phone"
          label="Telefone"
          required={false}
          rules={[
            {
              pattern: /^\(\d{2}\) \d{4,5}-\d{4}$/,
              message: "Formato inválido!",
            },
          ]}
        >
          <CustomInput.TelephoneWithDdd
            placeholder="Ex.: (00) 00000-0000"
            style={{ height: 48 }}
          />
        </SolarzFormItem>

        <SolarzFormItem
          name="profileId"
          label="Perfil"
          required={false}
          hidden={hideProfileField}
        >
          <Select.Profile
            placeholder="Selecionar perfil..."
            disabled={hideProfileField}
            style={{ height: 48 }}
          />
        </SolarzFormItem>

        <div className="flex flex-row align-middle justify-end gap-3">
          <SolarzButton variant="secondary" onClick={onCancel}>
            Cancelar
          </SolarzButton>
          <SolarzButton
            variant="primary"
            htmlType="submit"
            isLoading={isLoading}
          >
            Salvar
          </SolarzButton>
        </div>
      </Form>
    </>
  );
}
