import { useMemo } from "react";

import type { IProposalDownloadPdfButtonProps } from "./interface";

import { useFetchGetProposalPdf, useFetchGetProposalStep } from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { createUuid } from "~utils/createUuid";
import { Button, Tooltip } from "antd";

export function ProposalDownloadPdfButton({
  proposalId,
  ...props
}: IProposalDownloadPdfButtonProps) {
  const { message } = useAppConfig();

  const { data: proposalStepData } = useFetchGetProposalStep({
    payload: {
      customId: proposalId,
    },
    options: {
      enabled: proposalId > 0,
      retry: 1,
    },
  });

  const MESSAGE_KEY = useMemo(() => createUuid(), []);

  const FILENAME = useMemo(
    () =>
      `Proposta ${proposalStepData?.client?.name} ${proposalStepData?.fullPower}kWp ${proposalId}.pdf`,
    [proposalId, proposalStepData],
  );

  function createAnchorElementAndDownloadPdf(base64: string) {
    const a = document.createElement("a");
    a.href = `data:application/pdf;base64,${base64}`;
    a.download = FILENAME;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const { isFetching: isLoadingBase64, refetch: loadPdf } =
    useFetchGetProposalPdf({
      payload: {
        id: proposalId,
      },
      options: {
        enabled: false,
        onSuccess: ({ file }) => {
          message.success("PDF processado, download iniciará automaticamente");
          message.destroy(MESSAGE_KEY);

          if (typeof window !== "undefined") {
            createAnchorElementAndDownloadPdf(file);
          }
        },
        onError: (error) => {
          message.error("Falha ao processar PDF: " + error?.message);
          message.destroy(MESSAGE_KEY);
        },
      },
      dependencyArray: [proposalId, message],
    });

  const TOOLTIP_TITLE = useMemo(() => {
    return proposalId ? undefined : "Está faltando o ID da proposta";
  }, [proposalId]);

  function handleLoadPdf() {
    message.open({
      content: "Processando PDF...",
      type: "loading",
      key: MESSAGE_KEY,
      duration: 0,
    });

    loadPdf();
  }

  return (
    <Tooltip title={TOOLTIP_TITLE}>
      <Button
        {...props}
        disabled={!!TOOLTIP_TITLE || isLoadingBase64}
        onClick={handleLoadPdf}
      />
    </Tooltip>
  );
}
