import * as React from "react";

import type { IIconProps } from "../@types/globals";
import { cn } from "../lib/utils";

const ArrowUp: React.FC<IIconProps> = ({ className, ...props }) => (
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    className={cn("pointer-events-none w-6 h-6 stroke-2", className)}
    {...props}
  >
    <title>Seta para cima</title>
    <path d="M19 15L12 9L5 15" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

ArrowUp.displayName = "ArrowUp";

export { ArrowUp };
