import { useState } from "react";

import {
  ICalculationScaleTypesComplementaryItemsRadioProps,
  IScaleTypeList,
} from "./interface";

import { useFetchGetCalculationScaleTypesComplementaryItems } from "~hooks/api";
import { createUuid } from "~utils/createUuid";
import { Radio, Space, Spin } from "antd";

export function CalculationScaleTypesComplementaryItemsRadio({
  valueType,
  costGroup,
  value,
  onChange = () => null,
}: ICalculationScaleTypesComplementaryItemsRadioProps) {
  const [dataSource, setDataSource] = useState<IScaleTypeList[]>([]);

  const { isFetching: isLoadingScaleType } =
    useFetchGetCalculationScaleTypesComplementaryItems({
      payload: {
        calculationType: valueType,
      },
      dependencyArray: [valueType, costGroup],
      options: {
        enabled: !!valueType,
        onSuccess(data) {
          if (valueType === "PERCENTUAL" && costGroup !== "INSIGHTS") {
            const parsedData = data.filter(
              (item) =>
                !(item.value === "VALOR_TOTAL") &&
                !(item.value === "CUSTOS_COMPLEMENTARES"),
            );
            return setDataSource(parsedData);
          }

          setDataSource(data);
        },
      },
    });

  return (
    <Spin
      data-cy="calculation-scale-type-complementary-items-radio"
      spinning={isLoadingScaleType}
      style={{ width: 800 }}
    >
      <Radio.Group value={value} onChange={(e) => onChange(e.target.value)}>
        <Space direction="vertical">
          {dataSource.map((item) => (
            <Radio value={item.value} key={createUuid()}>
              {item.description}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </Spin>
  );
}
