import {
  BsArrowUpRightSquare,
  BsChevronDoubleDown,
  BsChevronDoubleUp,
} from "react-icons/bs";
import { HiMenuAlt4 } from "react-icons/hi";

import { CheckActivityStatus } from "./CheckActivityStatus";
import { ActivityTableDataType, type IActivityTableProps } from "./interface";

import { ActivityFormValuesType } from "~components/Form";
import { Menu } from "~components/Menu";
import { Modal } from "~components/Modal";
import { useFetchGetPaginatedActivityByPeriod } from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { parseFunctions, useQueryParams } from "~hooks/useQueryParams";
import { useSession } from "~hooks/useSession";
import { SolarzTable } from "~solarzui/SolarzTable";
import { ActivityPriorityEnum } from "~types/enum";
import {
  translateActivityEventTypeEnum,
  translateActivityPriorityEnum,
} from "~utils/translate";
import { Flex, Form } from "antd";
import dayjs from "dayjs";

const ACTIVITY_MODAL_QUERY_KEY = "create-activity";
const ACTIVITY_UPDATE_MODAL_QUERY_KEY = "update-activity";
const CURRENT_PAGE_PARAM_KEY = "page";

export function ActivityTable({
  currentDate = dayjs(),
  filters,
  viewType,
}: IActivityTableProps) {
  const { screens } = useAppConfig();
  const [form] = Form.useForm<ActivityFormValuesType>();
  const { user } = useSession();

  const { getParsedQueryParams, handleSaveInQueryParams } = useQueryParams();

  const getPriorityIcon = (priority: ActivityPriorityEnum) => {
    switch (priority) {
      case "LOW":
        return <BsChevronDoubleDown color="var(--green-400)" />;
      case "HIGH":
        return <BsChevronDoubleUp color="var(--red-400)" />;
      case "MEDIUM":
        return <HiMenuAlt4 color="var(--orange-400)" />;
      default:
        return null;
    }
  };

  const {
    data: activities,
    isFetching: isLoadingActivities,
    error: errorActivities,
    refetch: refetchActivities,
  } = useFetchGetPaginatedActivityByPeriod({
    dependencyArray: [currentDate, filters],
    payload: {
      ...filters,
      begin: currentDate
        .startOf(viewType ?? "day")
        .add(-3, "hours")
        .toISOString(),
      end: currentDate
        .endOf(viewType ?? "day")
        .add(-3, "hours")
        .toISOString(),
      eventType: filters?.eventType,
      status: filters?.statusType,
      state: filters?.stateType,
      priority: filters?.priority,
      ownerId: filters?.ownerId,
      calendarShortcut: filters?.period,
      name: filters?.name,
      page: (filters?.page ?? 1) - 1,
      size: filters?.size ?? 10,
    },
    options: {
      retry: 1,
    },
  });

  const isCreateActivityModalOpen = !!getParsedQueryParams(
    ACTIVITY_MODAL_QUERY_KEY,
    parseFunctions.BOOLEAN,
  );

  const selectedActivityId = getParsedQueryParams(
    ACTIVITY_UPDATE_MODAL_QUERY_KEY,
    parseFunctions.NUMBER,
  );

  const isActivityModalOpen = isCreateActivityModalOpen || !!selectedActivityId;

  function handleSelectActivityToUpdate(activityId: number) {
    handleSaveInQueryParams({ [ACTIVITY_UPDATE_MODAL_QUERY_KEY]: activityId });
  }

  function handleCloseActivityModal() {
    handleSaveInQueryParams({
      [ACTIVITY_MODAL_QUERY_KEY]: "",
      [ACTIVITY_UPDATE_MODAL_QUERY_KEY]: "",
    });
  }

  return (
    <>
      <SolarzTable<ActivityTableDataType>
        data-cy="activity-table"
        columns={[
          {
            title: "CONCLUÍDO",
            key: "status",
            width: 92,
            align: "center",
            render: (_, record) => {
              return <CheckActivityStatus record={record} />;
            },
          },
          {
            title: "TIPO",
            dataIndex: "eventType",
            key: "eventType",
            width: 180,
            render: (_, record) => {
              return record?.eventType
                ? translateActivityEventTypeEnum(record.eventType)
                : "-";
            },
          },
          {
            title: "ATIVIDADE",
            dataIndex: "name",
            key: "name",
            width: screens.desktop ? 288 : 182,
            render: (_, record) => {
              return record.name ? record.name : "-";
            },
          },
          {
            title: "PRIORIDADE",
            dataIndex: "priority",
            key: "priority",
            width: screens.desktop ? 280 : 98,
            render: (_, record) => {
              return (
                <Flex align="center" gap={8} flex={1}>
                  {record?.priority && getPriorityIcon(record.priority)}

                  <span>
                    {record.priority
                      ? translateActivityPriorityEnum(record.priority)
                      : "-"}
                  </span>
                </Flex>
              );
            },
          },
          {
            title: "NOME DO CLIENTE",
            dataIndex: "client",
            key: "clientName",
            width: screens.desktop ? 380 : 200,
            render: (_, record) => {
              return record.client
                ? record.client.name
                : record.organization
                  ? record.organization.name
                  : "-";
            },
          },
          {
            title: "DATA",
            dataIndex: "beginDate",
            key: "date",
            width: 280,
            render: (_, record) => {
              const { beginDate, endDate } = record;
              if (!beginDate || !endDate) return "-";
              const formattedBeginDate = dayjs(beginDate).format("DD/MM/YYYY");
              const formattedEndDate = dayjs(endDate).format("DD/MM/YYYY");
              return `${formattedBeginDate} - ${formattedEndDate}`;
            },
          },
          {
            title: "HORÁRIO",
            dataIndex: "endDate",
            key: "time",
            width: 200,
            render: (_, record) => {
              const { beginDate, endDate } = record;
              if (!beginDate || !endDate) return "-";
              const formattedBeginTime = dayjs(beginDate).format("HH:mm");
              const formattedEndTime = dayjs(endDate).format("HH:mm");
              return `${formattedBeginTime} - ${formattedEndTime}`;
            },
          },
          {
            title: "PROPRIETÁRIO",
            dataIndex: "owner",
            key: "owner",
            width: screens.desktop ? 416 : 200,
            render: (_, record) => {
              return record.owner?.name
                ? record.owner.name
                : record.owner?.email;
            },
          },
          {
            title: "",
            key: "options",
            render: (_, record) => {
              return (
                <div className="w-full flex align-middle justify-center ">
                  <Menu.MoreDropdown
                    items={[
                      {
                        icon: <BsArrowUpRightSquare />,
                        text: "Editar Tarefa",
                        color: "var(--primary-500)",
                        onClick: () => handleSelectActivityToUpdate(record.id),
                        hidden: !record.active,
                      },
                    ]}
                  />
                </div>
              );
            },
          },
        ]}
        rows={activities?.content ?? []}
        pagination={{
          currentItemCount: activities?.size ?? 0,
          currentPage: activities?.number ? activities?.number + 1 : 1,
          itemLabel: "atividades",
          totalItemCount: activities?.totalElements ?? 0,
          onChange: (currentPage) => {
            handleSaveInQueryParams({
              [CURRENT_PAGE_PARAM_KEY]: currentPage,
            });
          },
          isDisabled: false,
        }}
        scroll={
          screens.mobile
            ? {
                x: undefined,
                y: "calc(100vh - 326px)",
              }
            : {
                x: screens.desktop ? 1200 : 616,
              }
        }
        emptyMessage="Não foi encontrado nenhuma atividade"
        rowKey="id"
        errorMessage={errorActivities?.message}
        reloadFn={refetchActivities}
        isLoading={isLoadingActivities}
        showHeader={!screens.mobile}
        rowClassName={screens.desktop ? undefined : "cursor-pointer"}
      />

      <Modal.ActivityForm
        isOpen={isActivityModalOpen}
        onClose={handleCloseActivityModal}
        title="Nova atividade"
        formProps={{
          formInstance: form,
          activityId: selectedActivityId ?? undefined,
          onSuccess: handleCloseActivityModal,
          onDeleteSuccess: handleCloseActivityModal,
          initialValues: {
            owner: user?.id,
          },
        }}
      />
    </>
  );
}
