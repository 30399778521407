import type { ILinkUserWithPipedriveModalProps } from "./interface";

import { useFetchLinkPipedrive } from "~hooks/api";
import { Flex, Modal, Alert } from "antd";

export function LinkUserWithPipedriveModal({
  companyId,
  isOpen = false,
  onClose = () => null,
  title = "Vincular com pipedrive",
  maxWidth = 520,
  onError,
  onSuccess,
}: ILinkUserWithPipedriveModalProps) {
  const { mutate: linkPipedrive, isLoading: isLinkingPipedrive } =
    useFetchLinkPipedrive({
      onSuccess: () => {
        if (typeof onSuccess === "function") {
          onSuccess();
        }

        onClose();
      },
      onError,
    });

  function handleAssignUserWithPipedrive() {
    linkPipedrive({ companyId });
  }

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title={title}
      style={{
        width: "100%",
        maxWidth,
      }}
      okText="Vincular"
      onOk={handleAssignUserWithPipedrive}
      okButtonProps={{
        loading: isLinkingPipedrive,
      }}
    >
      <Flex vertical gap={16}>
        <Alert
          type="info"
          message="Você tem certeza que deseja vincular com o Pipedrive?"
          description="Ao vincular conta com o Pipedrive é necessário configurar os seus vendedores (caso tenham acesso ao Pipedrive)."
          style={{ padding: "8px 16px" }}
        />
      </Flex>
    </Modal>
  );
}
