import { useEffect, useState } from "react";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineSetting,
} from "react-icons/ai";

import type {
  IProposalCustomDataFieldsTableData,
  IProposalCustomDataFieldsTableColumn,
  IProposalCustomDataFieldsTableProps,
} from "./interface";
import { StyledCollapse } from "./styles";

import { DndContext, DragEndEvent } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Menu } from "~components/Menu";
import { Modal } from "~components/Modal";
import { DraggableTableRow } from "~components/Others";
import { Result } from "~components/Result";
import {
  useFetchDeleteCustomField,
  useFetchGetCustomFields,
  useFetchUpdateCustomFieldsOrder,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import type { FieldTypeEnum, ProposalStepEnumType } from "~types/enum";
import { ICustomFieldDTOSchema } from "~types/schemas";
import { Badge, Flex, Table, Typography } from "antd";

function translateCustomFieldType(value: FieldTypeEnum) {
  switch (value) {
    case "BOOLEAN":
      return "Sim/Não";
    case "NUMBER":
      return "Numérico";
    case "TEXT":
      return "Texto";
    case "TEXT_AREA":
      return "Caixa de texto";
    case "SELECT":
      return "Selecionável";
    case "IMAGE":
      return "Imagem";
    case "DATE":
      return "Data";
    default:
      return "Desconhecido";
  }
}

function translateProposalStep(value: ProposalStepEnumType) {
  switch (value) {
    case "DATA":
      return "Dados";
    case "SIZING":
      return "Dimensionamento";
    case "DETAILS":
      return "Detalhes";
    case "AFTER_SIZING":
      return "Pós-Dimensionamento";
    case "PAYMENT":
      return "Pagamento";
    case "PROPOSAL":
      return "Proposta";
    case "FINISH":
      return "Final";
    default:
      return "Desconhecido";
  }
}

function proposalCustomFieldTypeColumnRender(value: FieldTypeEnum) {
  const parsedData = translateCustomFieldType(value);

  return parsedData;
}

function proposalSectionColumnRender(value: ProposalStepEnumType) {
  const ProposalSectionValue = translateProposalStep(value);

  return ProposalSectionValue;
}

export function ProposalCustomDataFieldsTable({
  proposalSection = undefined,
}: IProposalCustomDataFieldsTableProps) {
  const { message } = useAppConfig();

  const [needUpdateOrder, setNeedUpdateOrder] = useState(false);

  const [dataSource, setDataSource] = useState<
    IProposalCustomDataFieldsTableData[]
  >([]);

  const [selectedCustomFieldToUpdate, setSelectedCustomFieldToUpdate] =
    useState<IProposalCustomDataFieldsTableData | undefined>(undefined);

  const [selectedCustomFieldToDelete, setSelectedCustomFieldToDelete] =
    useState<IProposalCustomDataFieldsTableData | undefined>(undefined);

  const [selectedCustomFieldToProfile, setSelectedCustomFieldToProfile] =
    useState<ICustomFieldDTOSchema | undefined>(undefined);

  const columns: IProposalCustomDataFieldsTableColumn = [
    {
      key: "sort",
      width: 42,
      align: "center" as const,
    },
    {
      title: "Nome",
      dataIndex: "label",
      width: "auto",
      render: (_, record) => {
        return (
          <span>
            {record?.label || "-"}
            {record.required && (
              <b style={{ color: "var(--primary-500)" }}>*</b>
            )}
          </span>
        );
      },
    },
    {
      title: "Identificador",
      dataIndex: "identifier",
      width: "auto",
    },
    {
      title: "Tipo do campo",
      dataIndex: "type",
      render: proposalCustomFieldTypeColumnRender,
    },
    {
      title: "Passo da proposta",
      dataIndex: "proposalStep",
      render: proposalSectionColumnRender,
    },
    {
      title: "",
      dataIndex: "action",
      width: 42,
      align: "center" as const,
      render: (_, customField) => (
        <Menu.MoreDropdown
          items={[
            {
              icon: (
                <AiOutlineEdit
                  fontSize={18}
                  style={{ color: "var(--gold-500)" }}
                />
              ),
              text: "Alterar",
              color: "var(--gold-500)",
              onClick: () => setSelectedCustomFieldToUpdate(customField),
            },
            {
              icon: (
                <AiOutlineSetting
                  fontSize={18}
                  style={{ color: "var(--blue-500)" }}
                />
              ),
              text: "Configurar por perfil",
              color: "var(--blue-500)",
              onClick: () => setSelectedCustomFieldToProfile(customField),
            },
            {
              icon: (
                <AiOutlineDelete
                  fontSize={16}
                  style={{ color: "var(--volcano-500)" }}
                />
              ),
              text: "Remover",
              color: "var(--volcano-500)",
              onClick: () => setSelectedCustomFieldToDelete(customField),
            },
          ]}
          position="bottomLeft"
        />
      ),
    },
  ];

  function onDragEnd({ active, over }: DragEndEvent) {
    if (active.id !== over?.id) {
      setDataSource((state) => {
        const activeIndex = state.findIndex((i) => i.key === active.id);
        const overIndex = state.findIndex((i) => i.key === over?.id);
        return arrayMove(state, activeIndex, overIndex);
      });
      setNeedUpdateOrder(true);
    }
  }

  const { isFetching: isLoadingCustomFields, error: customFieldsError } =
    useFetchGetCustomFields({
      options: {
        onSuccess: (data) => {
          const dataSource = data.map((content) => ({
            ...content,
            key: content.id,
          }));

          setDataSource(dataSource);
        },
        queryHash: proposalSection,
      },
      payload: {
        proposalSection,
      },
    });
  const {
    mutate: updateCustomFieldsOrder,
    isLoading: isUpdatingCustomFieldsOrder,
  } = useFetchUpdateCustomFieldsOrder({
    options: {
      onSuccess: () => setNeedUpdateOrder(false),
      onError: () => setNeedUpdateOrder(false),
    },
  });

  const { mutate: deleteCustomField, isLoading: isDeletingCustomField } =
    useFetchDeleteCustomField({
      onSuccess: () => {
        setSelectedCustomFieldToDelete(undefined);
      },
    });

  function handleDeleteCustomField() {
    const customFieldId = selectedCustomFieldToDelete?.id;

    customFieldId
      ? deleteCustomField({ customFieldId: customFieldId })
      : message.error("Client error: faltando o ID do campo customizado!");
  }

  useEffect(() => {
    if (needUpdateOrder) {
      const proposalFieldsOrder = dataSource.map((item, index) => ({
        id: item.id,
        order: index + 1,
      }));

      updateCustomFieldsOrder({
        proposalFieldsOrder,
      });
    }
  }, [needUpdateOrder, updateCustomFieldsOrder, dataSource, proposalSection]);

  return (
    <>
      <StyledCollapse
        size="large"
        style={{ padding: 0, border: 0 }}
        defaultActiveKey={["1"]}
        items={[
          {
            key: "1",
            label: (
              <Flex align="center" gap={6}>
                <Typography.Text
                  style={{
                    margin: 0,
                    fontWeight: "bold",
                  }}
                >
                  Campos personalizados
                </Typography.Text>

                <Flex align="center">
                  <Badge
                    count={dataSource ? dataSource.length : 0}
                    style={{
                      backgroundColor: "#e4e4e5",
                      color: "#21232c",
                      fontWeight: "bold",
                    }}
                  />
                </Flex>
              </Flex>
            ),
            children: (
              <DndContext
                data-cy="proposal-custom-fields-table"
                onDragEnd={onDragEnd}
              >
                <SortableContext
                  items={dataSource.map((item) => item.id)}
                  strategy={verticalListSortingStrategy}
                >
                  <Table
                    components={{
                      body: {
                        row: DraggableTableRow,
                      },
                    }}
                    rowKey="key"
                    columns={columns}
                    dataSource={dataSource}
                    scroll={{ x: 900 }}
                    size="small"
                    pagination={false}
                    loading={
                      isLoadingCustomFields || isUpdatingCustomFieldsOrder
                    }
                    locale={{
                      emptyText: (
                        <Result.TableErrorOrEmpty
                          errorMessage={customFieldsError?.message}
                          emptyMessage="Nenhum campo personalizado encontrado"
                        />
                      ),
                    }}
                    style={{ width: "100%" }}
                  />
                </SortableContext>
              </DndContext>
            ),
          },
        ]}
      />

      <Modal.ProposalCustomDataFieldForm
        isOpen={!!selectedCustomFieldToUpdate}
        onClose={() => setSelectedCustomFieldToUpdate(undefined)}
        formProps={{
          initialValues: selectedCustomFieldToUpdate,
          onSubmitSuccess: () => setSelectedCustomFieldToUpdate(undefined),
        }}
      />
      <Modal.ProposalDataFieldProfileSettingsForm
        isOpen={!!selectedCustomFieldToProfile}
        onClose={() => setSelectedCustomFieldToProfile(undefined)}
        formProps={{
          initialValues: selectedCustomFieldToProfile,
          onSubmitSuccess: () => setSelectedCustomFieldToProfile(undefined),
        }}
      />

      <Modal.DefaultRemove
        title="Remover campo customizado"
        suffix="campo customizado"
        alertMessage={
          Number(selectedCustomFieldToDelete?.businessUnits?.length) > 1
            ? "Esse campo está associado em mais de uma unidade de negócio, excluí-lo também removerá da(s) outra(s) unidade(s). Caso queira remover apenas dessa unidade, edite o campo para não ser mostrado na UN."
            : undefined
        }
        isOpen={!!selectedCustomFieldToDelete}
        itemName={selectedCustomFieldToDelete?.label}
        onClose={() => setSelectedCustomFieldToDelete(undefined)}
        onSubmit={handleDeleteCustomField}
        isLoading={isDeletingCustomField}
        maxWidth={580}
      />
    </>
  );
}
