import { useEffect } from "react";

import {
  ActiveDistributorKitFilterFormValues,
  IActivateDistributorKitFilterProps,
} from "./interface";

import { useFetchPatchActivateDistributorKitFilter } from "~hooks/api";
import { Button, Flex, Form, Radio } from "antd";

export function ActivateDistributorKitFilterForm({
  onSubmitForm = () => null,
  onCancel = () => null,
  onError = () => null,
  distributorId,
  activeKitFilter,
}: IActivateDistributorKitFilterProps) {
  const [form] = Form.useForm<ActiveDistributorKitFilterFormValues>();

  const {
    mutate: fetchPatchActivateDistributorKitFilter,
    isLoading: isUpdateLoading,
  } = useFetchPatchActivateDistributorKitFilter({
    options: {
      onSuccess: () => {
        onSubmitForm();
        onCancel();
      },
      onError,
    },
  });

  const onSubmit = async (formValues: ActiveDistributorKitFilterFormValues) => {
    fetchPatchActivateDistributorKitFilter({
      distributorId: distributorId,
      activeKitFilter: formValues.activeKitFilter,
    });
  };

  useEffect(() => {
    form.resetFields();
  }, [activeKitFilter, form]);

  return (
    <Form
      data-cy="activate-distributor-kit-filter-form"
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{ activeKitFilter: activeKitFilter }}
    >
      <Form.Item
        name="activeKitFilter"
        label="Ativar filtro de kit do distribuidor?"
        rules={[{ required: true }]}
      >
        <Radio.Group defaultValue={activeKitFilter}>
          <Radio.Button value={true}>Sim</Radio.Button>
          <Radio.Button value={false}>Não</Radio.Button>
        </Radio.Group>

        <Flex align="center" gap={8} justify="end">
          <Button onClick={onCancel} disabled={isUpdateLoading}>
            Cancelar
          </Button>
          <Button type="primary" htmlType="submit" loading={isUpdateLoading}>
            Atualizar
          </Button>
        </Flex>
      </Form.Item>
    </Form>
  );
}
