import styled from "@emotion/styled";
import { SolarzAnchor } from "~solarzui/SolarzAnchor";

export const StyledSolarzAnchor = styled(SolarzAnchor)`
  font-size: 14px;
  font-weight: 600;
  height: 32px;
  width: 100%;
  display: flex;

  justify-content: center;
  align-items: center;
  gap: 6px;

  text-decoration: none !important;

  svg {
    height: 20px;
    width: 20px;
  }

  &:hover {
    background-color: var(--gray-50) !important;
  }
`;
