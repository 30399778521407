import { ICardItem } from "./interface";

import { useAppConfig } from "~hooks/useAppConfig";
import { Skeleton, Typography, Flex } from "antd";

export const CardItem = ({ title, value, isLoading = false }: ICardItem) => {
  const { screens } = useAppConfig();

  return (
    <Flex vertical gap={2} style={{ padding: "0.25rem", width: "100%" }}>
      {isLoading ? (
        <Skeleton.Button
          style={{ height: 18, margin: 0, padding: 0 }}
          active
          size="large"
        />
      ) : (
        <Typography.Text
          ellipsis={{
            tooltip: value,
          }}
          style={{
            fontSize: screens.xs ? "0.75rem" : "0.875rem",
            fontWeight: "bold",
            margin: 0,
            lineHeight: 1,
          }}
        >
          {value}
        </Typography.Text>
      )}

      <Flex
        align="center"
        gap={4}
        style={{ fontSize: screens.xs ? "0.6875rem" : "0.75rem" }}
      >
        {title}
      </Flex>
    </Flex>
  );
};
