import { Skeleton } from "antd";

export function CardLoading() {
  return (
    <>
      {Array(3)
        .fill(null)
        .map((_, index) => (
          <Skeleton.Node
            active
            key={index}
            style={{ width: "100%", height: 112 }}
          >
            {" "}
          </Skeleton.Node>
        ))}
    </>
  );
}
