import { useState } from "react";

import type { IPersonAutoCompleteProps } from "./interface";

import { AutoCompleteSuffixIcon } from "~components/Others";
import { Result } from "~components/Result";
import { useFetchGetAutoCompletePerson } from "~hooks/api";
import { AutoComplete } from "antd";

export function PersonAutoComplete({
  allowClear = true,
  onChange,
  onClear,
  ...props
}: IPersonAutoCompleteProps) {
  const [inputValue, setInputValue] = useState<string>("");

  const {
    data: persons = [],
    isFetching,
    error,
    refetch,
  } = useFetchGetAutoCompletePerson({
    dependencyArray: [inputValue],
    payload: {
      term: inputValue,
    },
    options: {
      enabled: inputValue.length >= 3,
    },
  });

  const handleSearch = (value: string) => {
    setInputValue(value);
  };

  return (
    <AutoComplete
      {...props}
      data-cy="person-auto-complete"
      showSearch
      filterOption={false}
      onSearch={handleSearch}
      options={persons.map((person) => ({
        value: person.name,
        title: person.name,
        label: person.name,
        record: person,
        key: person.id,
      }))}
      allowClear={allowClear}
      status={error ? "error" : ""}
      notFoundContent={
        <Result.SelectErrorOrEmpty
          errorMessage={error?.message}
          emptyMessage="Nenhum pessoa encontrada"
          loading={isFetching}
          reloadFn={refetch}
          disableReload={inputValue.length < 3}
        />
      }
      suffixIcon={
        <AutoCompleteSuffixIcon hasError={!!error} isLoading={isFetching} />
      }
      onClear={() => {
        setInputValue("");
        if (typeof onClear === "function") {
          onClear();
        }
      }}
      onChange={(value, option) => {
        if (option instanceof Array || !onChange) return;
        onChange(value, option);
      }}
      style={{ width: "100%" }}
    />
  );
}
