import { useCallback, useEffect, useState } from "react";
import { BsPencil } from "react-icons/bs";

import type {
  IEosFinancingOrganizationProjectDataFormProps,
  EosFinancingOrganizationProjectDataFormValuesType,
} from "./interface";
import { EditButton } from "./styles";

import { AutoComplete } from "~components/AutoComplete";
import { Select } from "~components/Select";
import { useAppConfig } from "~hooks/useAppConfig";
import { useInterval } from "~hooks/useInterval";
import { EOS_FORM_STEP_VALUES_ORGANIZATION_STORAGE_KEY } from "~pages/configuracoes-de-propostas/financiamento/EOS/cadastro/_module/page.config";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzFormItem } from "~solarzui/SolarzFormItem";
import { SolarzInputNumber } from "~solarzui/SolarzInputNumber";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import {
  getLocalStorageData,
  setLocalStorageData,
} from "~utils/localstorageUtilitFunctions";
import { parseCurrency } from "~utils/parse";
import { Col, Divider, Flex, Form, Row, Spin } from "antd";

export function EosFinancingOrganizationProjectDataForm({
  formInstance,
  initialValues,
  setSelectedProposalId,
  onCancel,
  onNextStep,
  proposalDetails,
}: IEosFinancingOrganizationProjectDataFormProps) {
  const { screens } = useAppConfig();
  const [form] =
    Form.useForm<EosFinancingOrganizationProjectDataFormValuesType>(
      formInstance,
    );
  const [isEditingTotal, setIsEditingTotal] = useState(false);

  const [totalValue, setTotalValue] = useState<number>(0);

  const handleTotalValueChange = (value: number | null) => {
    if (value) {
      setTotalValue(value);
    }
  };

  const handleToggleEdit = () => {
    setIsEditingTotal(!isEditingTotal);
  };

  const handleValueChange = useCallback(() => {
    const kitValue = form.getFieldValue("kitFotovoltaico") || 0;
    const laborValue = form.getFieldValue("maoObra") || 0;
    const total = kitValue + laborValue;
    setTotalValue(total);
  }, [form]);

  function handleCancel() {
    if (typeof onCancel === "function") {
      return onCancel();
    }
  }

  function handleSubmit() {
    if (totalValue <= 0) {
      alert("O valor total deve ser maior que zero.");
      return;
    }
    if (typeof onNextStep === "function") {
      const formFields = form.getFieldsValue(true);
      handlePersistsDataInStorage({ ...formFields, valorTotal: totalValue });
      onNextStep();
    }
  }

  const isLoading = false;

  const handlePersistsDataInStorage = useCallback(
    (data: EosFinancingOrganizationProjectDataFormValuesType) => {
      try {
        const objectExisting = getLocalStorageData(
          EOS_FORM_STEP_VALUES_ORGANIZATION_STORAGE_KEY,
        );
        const objectUpdated = {
          ...objectExisting,
          ...data,
        };

        setLocalStorageData(
          EOS_FORM_STEP_VALUES_ORGANIZATION_STORAGE_KEY,
          objectUpdated,
        );
      } catch (error: any) {
        console.warn(error?.message);
      }
    },
    [],
  );

  useInterval(() => {
    const formFields = form.getFieldsValue(true);
    handlePersistsDataInStorage({ ...formFields, valorTotal: totalValue });
  }, 3000);

  useEffect(() => {
    const objectExisting = getLocalStorageData(
      EOS_FORM_STEP_VALUES_ORGANIZATION_STORAGE_KEY,
    );

    setTotalValue(objectExisting?.valorTotal ?? 0);
  }, []);

  useEffect(() => {
    const objectExisting = getLocalStorageData(
      EOS_FORM_STEP_VALUES_ORGANIZATION_STORAGE_KEY,
    );

    if (objectExisting) {
      form.setFieldsValue({
        ...objectExisting,
      });
      handleValueChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, proposalDetails]);

  return (
    <Form
      data-cy="eos-financing-organization-project-data-form"
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmit}
      preserve
      layout="vertical"
    >
      <Flex vertical gap={8} style={{ margin: "38px 0" }}>
        <SolarzTypography
          fontWeight="semiBold"
          hierarchy="title2"
          variant="title"
        >
          Dados do projeto
        </SolarzTypography>
        <SolarzTypography
          fontWeight="regular"
          hierarchy="paragraph2"
          variant="disabled"
        >
          Preencha os dados relacionados ao projeto
        </SolarzTypography>
      </Flex>
      <SolarzFormItem
        label="Proposta base"
        tooltip="Busque propostas  inserindo o nome do projeto, cliente ou potência desejada."
        style={{ marginTop: "24px" }}
      >
        <AutoComplete.EosBaseProposal
          onChange={(_, option) => {
            if (option?.record) {
              setSelectedProposalId(option.record?.id);
            }
          }}
        />
      </SolarzFormItem>
      <Divider />

      <Spin spinning={isLoading}>
        <Row gutter={24}>
          <Col span={24}>
            <SolarzFormItem<EosFinancingOrganizationProjectDataFormValuesType>
              name="tipoProjeto"
              label="Tipo do projeto"
              rules={[{ required: true }]}
            >
              <Select.ProjectTypeEosFinancingPerson
                scale={screens.mobile ? "medium" : "large"}
              />
            </SolarzFormItem>
          </Col>
        </Row>
        <Row gutter={screens.mobile ? 16 : 24}>
          <Col span={screens.mobile ? 24 : 7}>
            <SolarzFormItem<EosFinancingOrganizationProjectDataFormValuesType>
              name="carencia"
              label="Carência"
              rules={[
                { required: true },
                {
                  validator(_, value) {
                    if (value >= 0 && value <= 6) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "O valor da carência deve ser de 0 a 6 meses",
                    );
                  },
                },
              ]}
            >
              <SolarzInputNumber
                inputType="meses"
                scale={screens.mobile ? "medium" : "large"}
                placeholder="Ex.: 3 meses"
                precision={0}
              />
            </SolarzFormItem>
          </Col>
          <Col span={screens.mobile ? 24 : 7}>
            <SolarzFormItem<EosFinancingOrganizationProjectDataFormValuesType>
              name="entrada"
              label="Entrada"
              rules={[{ required: true }]}
            >
              <SolarzInputNumber
                inputType="R$"
                scale={screens.mobile ? "medium" : "large"}
                placeholder="Ex.: 5.000"
              />
            </SolarzFormItem>
          </Col>
          <Col span={screens.mobile ? 24 : 10}>
            <SolarzFormItem<EosFinancingOrganizationProjectDataFormValuesType>
              name="tempoFinanciamento"
              label="Tempo de financiamento"
              rules={[{ required: true }]}
            >
              <SolarzInputNumber
                inputType="meses"
                scale={screens.mobile ? "medium" : "large"}
                placeholder="Ex.: 36 meses"
                precision={0}
              />
            </SolarzFormItem>
          </Col>
        </Row>
        <Row gutter={screens.mobile ? 16 : 24}>
          <Col span={screens.mobile ? 24 : 12}>
            <SolarzFormItem<EosFinancingOrganizationProjectDataFormValuesType>
              name="mediaContaEnergia"
              label="Média conta de energia"
              rules={[{ required: true }]}
            >
              <SolarzInputNumber
                scale={screens.mobile ? "medium" : "large"}
                placeholder="Ex.: 100"
                inputType="R$"
              />
            </SolarzFormItem>
          </Col>
          <Col span={screens.mobile ? 24 : 12}>
            <SolarzFormItem<EosFinancingOrganizationProjectDataFormValuesType>
              name="potenciaInstaladaSugerida"
              label="Potência instalada sugerida"
              rules={[{ required: true }]}
            >
              <SolarzInputNumber
                scale={screens.mobile ? "medium" : "large"}
                placeholder="Ex.: 10"
                inputType="KWh"
              />
            </SolarzFormItem>
          </Col>
        </Row>
        <Row gutter={screens.mobile ? 16 : 24}>
          <Col span={screens.mobile ? 24 : 12}>
            <SolarzFormItem<EosFinancingOrganizationProjectDataFormValuesType>
              name="kitFotovoltaico"
              label="Valor do kit"
              rules={[{ required: true }]}
            >
              <SolarzInputNumber
                scale={screens.mobile ? "medium" : "large"}
                placeholder="Ex.: 100"
                inputType="R$"
                onChange={handleValueChange}
              />
            </SolarzFormItem>
          </Col>
          <Col span={screens.mobile ? 24 : 12}>
            <SolarzFormItem<EosFinancingOrganizationProjectDataFormValuesType>
              name="maoObra"
              label="Mão de obra"
            >
              <SolarzInputNumber
                scale={screens.mobile ? "medium" : "large"}
                placeholder="Ex.: 100"
                inputType="R$"
                onChange={handleValueChange}
              />
            </SolarzFormItem>
          </Col>
        </Row>
        <Row gutter={screens.mobile ? 16 : 24} justify="space-between">
          <Col span={screens.mobile ? 24 : 12}>
            {" "}
            <SolarzTypography
              fontWeight="semiBold"
              hierarchy="title2"
              variant="title"
            >
              Valor total
            </SolarzTypography>
          </Col>
          <Col span={screens.mobile ? 24 : 12}>
            <Flex
              justify={screens.mobile ? "flex-start" : "flex-end"}
              align="center"
              gap={24}
            >
              {isEditingTotal ? (
                <SolarzInputNumber
                  defaultValue={totalValue}
                  value={totalValue}
                  onChange={handleTotalValueChange}
                  scale="tiny"
                  inputType="R$"
                  onBlur={handleToggleEdit}
                />
              ) : (
                <>
                  <SolarzTypography
                    fontWeight="semiBold"
                    style={{ color: "var(--primary-500)" }}
                  >
                    {parseCurrency(totalValue)}
                  </SolarzTypography>
                </>
              )}

              <EditButton
                scale="tiny"
                variant="ghost"
                icon={<BsPencil size={12} />}
                onClick={handleToggleEdit}
              />
            </Flex>
          </Col>
        </Row>
        <Flex
          style={{ marginTop: 12 }}
          align="center"
          gap={16}
          justify="space-between"
        >
          <SolarzButton
            variant="secondary"
            scale="large"
            onClick={handleCancel}
          >
            Cancelar
          </SolarzButton>
          <SolarzButton scale="large" htmlType="submit" isLoading={isLoading}>
            Próximo
          </SolarzButton>
        </Flex>
      </Spin>
    </Form>
  );
}
