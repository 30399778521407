export type GoogleAddress = {
  long_name: string;
  short_name: string;
  types: string[];
};

/*
  sublocality_level_1 = bairro;
  administrative_area_level_2 = cidade;
  administrative_area_level_1 = estado;
  country = pais;
  postal_code = cep;
  street_number = numero do local;
  router = rua;
*/

export type SimpleLocation = {
  placeId?: string;
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  zipCode?: string;
  streetNumber?: number;
  district?: string;
};

const SOLARZ_ADDRESS_TYPES = {
  sublocality_level_1: "district",
  administrative_area_level_2: "city",
  administrative_area_level_1: "state",
  country: "country",
  postal_code: "zipCode",
  street_number: "streetNumber",
  route: "street",
} as Record<string, string>;

export const parseGoogleAddress = (
  googleAddressList: GoogleAddress[],
  defaultAddress: string,
) => {
  const defaultStreet = defaultAddress.split("-")[0].trim();

  const address = googleAddressList.reduce((address, googleAddress) => {
    const addressType =
      SOLARZ_ADDRESS_TYPES?.[googleAddress.types?.[0]] ?? undefined;

    if (!addressType) return address;

    const newAddress = {
      [addressType]: googleAddress.long_name,
    };

    return {
      ...address,
      ...newAddress,
    };
  }, {} as SimpleLocation);

  if (!address?.street) {
    return {
      ...address,
      street: defaultStreet,
    };
  }

  return address;
};
