import { useCallback, useState } from "react";

import { ICopyToClipboardButtonProps } from "./interface";

import { useAppConfig } from "~hooks/useAppConfig";
import { Button } from "antd";

export function CopyToClipboardButton({
  children,
  textToCopy,
  onSuccess,
  onError,
  type = "primary",
  ...props
}: ICopyToClipboardButtonProps) {
  const { message } = useAppConfig();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClick = useCallback(async () => {
    if (typeof navigator === "undefined") return;

    try {
      setIsLoading(true);

      await navigator.clipboard.writeText(
        typeof textToCopy === "function" ? textToCopy() : textToCopy,
      );

      message.success("Mensagem copiada para o clipboard");

      if (typeof onSuccess === "function") {
        onSuccess();
      }
    } catch (e: any) {
      message.error(e?.message ?? "Falha ao copiar");

      if (typeof onError === "function") {
        onError();
      }
    } finally {
      setIsLoading(false);
    }
  }, [textToCopy, message, onError, onSuccess]);

  return (
    <Button
      {...props}
      data-cy="copy-to-clipboard-button"
      type={type}
      loading={isLoading}
      onClick={handleClick}
    >
      {children ?? "Copiar para clipboard"}
    </Button>
  );
}
