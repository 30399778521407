import { PaginationInfo } from "~components/PaginationInfo";
import { Pagination as AntdPagination } from "antd";

interface IPaginationProps {
  number?: number;
  size?: number;
  totalElements?: number;
  onChange?: (page: number, pageSize: number) => void;
  itemType?: string;
  disabled?: boolean;
  displayTotalInformation?: boolean;
}

export function createPaginationTableObject({
  number = 0,
  size = 0,
  totalElements = 0,
  onChange = () => null,
  itemType = "itens",
  disabled = false,
  displayTotalInformation = true,
}: IPaginationProps) {
  return {
    current: number,
    pageSize: size,
    total: totalElements,
    onChange: onChange,
    size: "small" as const,
    showSizeChanger: false,
    showTotal: (total: number, [start, end]: [number, number]) =>
      displayTotalInformation && (
        <PaginationInfo
          total={total}
          start={start}
          end={end}
          textItemType={itemType}
        />
      ),
    disabled: number === 0 || disabled,
  };
}

export function Pagination({
  number = 0,
  size = 0,
  totalElements = 0,
  onChange = () => null,
  itemType = "itens",
  disabled = false,
}: IPaginationProps) {
  const props = createPaginationTableObject({
    number: number,
    size: size,
    totalElements: totalElements,
    onChange: onChange,
    itemType: itemType,
    disabled: disabled,
  });

  return <AntdPagination data-cy="pagination" {...props} />;
}
