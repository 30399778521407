import { BsCheckCircle } from "react-icons/bs";

import NextImage from "next/image";

import type { IBindPipedriveIdToPersonButtonProps } from "./interface";

import {
  useFetchBindsPipedriveIdToClient,
  useFetchGetPersonById,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { SolarzButton } from "~solarzui/SolarzButton";
import { Skeleton, Spin } from "antd";

export function BindPipedriveIdToPersonButton({
  children,
  personId = 0,
  pipedriveId = 0,
  ...props
}: IBindPipedriveIdToPersonButtonProps) {
  const { message } = useAppConfig();

  const { data: personDetail, isLoading: isLoadingPersonDetail } =
    useFetchGetPersonById({
      payload: {
        personId: personId,
      },
      dependencyArray: [personId],
      options: {
        enabled: personId > 0,
        retry: 1,
      },
    });

  const { mutate: handleBindsPipedriveIdToPerson, isLoading } =
    useFetchBindsPipedriveIdToClient({});

  const handleClick = async () => {
    if (personId > 0 && pipedriveId > 0) {
      handleBindsPipedriveIdToPerson({
        personId: personId,
        pipedriveId: pipedriveId,
      });
    } else {
      message.info(
        "Por favor, verifique se o cliente está corretamente selecionado.",
      );
    }
  };
  return (
    <>
      <SolarzButton
        {...props}
        data-cy="bind-pipedrive-id-to-person-button"
        onClick={handleClick}
        variant="secondary"
        isLoading={isLoading || isLoadingPersonDetail}
        htmlType="button"
        isDisabled={
          !(personId > 0 && pipedriveId > 0 && !personDetail?.pipedriveId)
        }
        icon={
          isLoadingPersonDetail ? (
            <Spin size="small" />
          ) : personDetail?.pipedriveId ? (
            <BsCheckCircle size={18} />
          ) : (
            <NextImage
              src="/images/pipedrive-icon-black.svg"
              height={14}
              width={14}
              alt="Integrado com Pipedrive"
            />
          )
        }
      >
        {children ?? isLoadingPersonDetail ? (
          <Skeleton.Input
            active={isLoadingPersonDetail}
            size="small"
            style={{ width: "100%" }}
          />
        ) : personDetail?.pipedriveId ? (
          "Cliente vinculado ao pipedrive"
        ) : (
          "Vincular cliente ao pipedrive"
        )}
      </SolarzButton>
    </>
  );
}
