import { IUserInfoCardProps } from "./interface";
import styles from "./styles";

import { Avatar } from "~components/Avatar";
import { useSession } from "~hooks/useSession";
import { Card, Skeleton, Typography, Flex } from "antd";

export function UserInfoCard({
  displayEmail = true,
  displayRule = true,
}: IUserInfoCardProps) {
  const { user, sessionStatus } = useSession();

  const userNameUppercase = user?.name.toLocaleUpperCase() ?? "?";
  const userEmail = user?.email ?? "-";
  const userRule = user?.profile?.name ?? "-";
  const isLoading = sessionStatus === "loading";

  return (
    <Skeleton
      data-cy="user-info-card"
      title={false}
      paragraph={{
        rows: 3,
        width: 220,
      }}
      loading={isLoading}
      avatar
      active
      style={{ width: "90vw", maxWidth: 320 }}
    >
      <style jsx>{styles}</style>
      <Card.Meta
        className="userInfo"
        avatar={
          <Avatar.CustomUser
            name={user?.name}
            avatarUrl={user?.avatarUrl}
            userId={user?.id}
            sessionStatus={sessionStatus}
          />
        }
        title={userNameUppercase}
        description={
          <Flex vertical>
            {displayEmail && <Typography.Text>{userEmail}</Typography.Text>}
            {displayRule && <Typography.Text>{userRule}</Typography.Text>}
          </Flex>
        }
      />
    </Skeleton>
  );
}
