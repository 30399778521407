import styled from "@emotion/styled";
import { Collapse } from "antd";

export const StyledCollapse = styled(Collapse)`
  &.ant-collapse {
    border-radius: 0;
  }

  & .ant-collapse-content > .ant-collapse-content-box {
    padding: 0 !important;
  }

  & > .ant-collapse-item {
    border-bottom: 0;
  }
`;
