import { useEffect, useMemo } from "react";

import type {
  CompanyUserSelectOptionType,
  CompanyUserSelectRecordType,
  ICompanyUserSelectProps,
} from "./interface";

import { useFetchGetPaginatedUsersByCompany } from "~hooks/api";
import { useSession } from "~hooks/useSession";
import { SolarzSelect } from "~solarzui/SolarzSelect";

export function CompanyUserSelect(props: ICompanyUserSelectProps) {
  const { user } = useSession();

  const {
    data: companyUsers,
    isFetching: isLoadingCompanyUsers,
    error: companyUsersError,
    refetch: refetchCompanyUsers,
  } = useFetchGetPaginatedUsersByCompany({
    dependencyArray: [],
    payload: {
      page: 0,
      size: 100,
    },
  });

  const OPTIONS = useMemo(() => {
    return companyUsers?.content.map((owner) => ({
      key: owner.id,
      value: owner.id,
      label: `${owner.profileName} ${owner?.profileName && "-"} ${owner.email}`,
      record: owner,
      hidden: !!props.hideOptions?.includes(owner.id),
    })) as CompanyUserSelectOptionType;
  }, [companyUsers?.content, props.hideOptions]);

  useEffect(() => {
    if (
      !isLoadingCompanyUsers &&
      props.useDefaultValue &&
      props.form &&
      user?.id
    ) {
      props.form.setFieldsValue({ owner: user.id });
    }
  }, [
    isLoadingCompanyUsers,
    OPTIONS,
    props.form,
    props.useDefaultValue,
    user?.id,
  ]);

  return (
    <SolarzSelect<CompanyUserSelectRecordType, "id">
      {...props}
      options={OPTIONS}
      isLoading={isLoadingCompanyUsers}
      refetchFn={refetchCompanyUsers}
      errorMessage={companyUsersError?.message}
      emptyMessage="Nenhum usuário encontrado"
    />
  );
}
