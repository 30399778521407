import { useState, useRef, useEffect } from "react";

import type { IInfoTagProps } from "./interface";
import styles from "./styles";

import { useOnClickOutside } from "~hooks/useOnClickOutside";
import { Spin, Tag, Tooltip, Typography } from "antd";

export function InfoTag({
  color = "blue",
  fieldName = "",
  fieldValue = "",
  formattedValue = "",
  tooltip,
  size = "small",
  onClickEdit = () => undefined,
  isEditable = false,
  onEditSubmit = () => undefined,
  isSubmittingEdit = false,
  isEditing = false,
  tagStyles = undefined,
}: IInfoTagProps) {
  const [isEditFieldOpen, setIsEditFieldOpen] = useState(false);

  const TypographyRef = useRef<HTMLSpanElement | null>(null);
  useOnClickOutside(TypographyRef, () => setIsEditFieldOpen(false));

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [value, setValue] = useState(fieldValue);

  function handleClickEdit() {
    setIsEditFieldOpen(true);
    onClickEdit();
  }

  useEffect(() => {
    if (!isEditing) {
      setIsEditFieldOpen(false);
    }
  }, [isEditing]);

  useEffect(() => {
    setIsSubmitting(isSubmittingEdit);
  }, [isSubmittingEdit]);

  useEffect(() => {
    if (value) {
      onEditSubmit(value);
      setIsEditFieldOpen(false);
    }
  }, [value]);

  if (fieldName === "Distância") console.log("isSubmitting", isSubmitting);
  return (
    <>
      <style jsx>{styles}</style>
      <Tooltip data-cy="info-tag" title={tooltip} style={{ cursor: "pointer" }}>
        <Tag
          ref={TypographyRef}
          color={color}
          style={{
            padding: size ? "0.25rem 0.5rem" : "0.125rem 0.25rem",
            margin: 0,
            width: "auto",
            fontSize: size === "small" ? 11.25 : 12,
            display: "flex",
            flexWrap: "nowrap",
            userSelect: "none",
            ...tagStyles,
          }}
        >
          <span style={{ marginRight: "0.25rem" }}>{fieldName}:</span>
          {!isEditFieldOpen && !isSubmitting && (
            <strong>{formattedValue || fieldValue}</strong>
          )}
          <Spin
            size="small"
            style={{ fontSize: 0, marginTop: 1 }}
            spinning={isSubmitting}
          />
          {!isSubmitting && (
            <Typography.Text
              editable={
                isEditable && {
                  tooltip: "Editar",
                  text: fieldValue,
                  autoSize: false,
                  onChange: (value) => setValue(value),
                  editing: isEditFieldOpen,
                  onStart: handleClickEdit,
                  onCancel: () => setIsEditFieldOpen(false),
                  enterIcon: isSubmitting ? (
                    <Spin size="small" style={{ fontSize: 0 }} />
                  ) : undefined,
                  icon: isSubmitting ? (
                    <Spin size="small" style={{ fontSize: 0 }} />
                  ) : undefined,
                }
              }
              style={{
                fontSize: size === "small" ? 11.25 : 12,
                marginTop: "0",
              }}
              className="edit"
            />
          )}
        </Tag>
      </Tooltip>
    </>
  );
}
