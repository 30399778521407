import { useEffect } from "react";
import { IoReload } from "react-icons/io5";

import type { IAdminModuleFormData, IAdminModuleFormProps } from "./interface";

import { CustomInput } from "~components/CustomInput";
import { Select } from "~components/Select";
import {
  useFetchCreateAdminModules,
  useFetchGetAdminModulesById,
  useFetchUpdateAdminModules,
} from "~hooks/api";
import {
  Alert,
  Button,
  Col,
  Divider,
  Flex,
  Form,
  Row,
  Spin,
  Tooltip,
} from "antd";

export function AdminModuleForm({
  id = 0,
  formInstance,
  initialValues,
  onCancel,
  onError,
  onSuccess,
}: IAdminModuleFormProps) {
  const [form] = Form.useForm<IAdminModuleFormData>(formInstance);

  const {
    data: adminModule,
    isFetching: isLoadingAdminModule,
    error: adminModuleError,
    refetch: reloadAdminModule,
  } = useFetchGetAdminModulesById({
    payload: {
      id: id,
    },
    options: {
      enabled: id > 0,
      retry: 1,
    },
  });

  const { mutate: updateAdminModules, isLoading: isUpdatingAdminModules } =
    useFetchUpdateAdminModules({
      options: {
        onSuccess,
        onError,
      },
    });

  const { mutate: createAdminModules, isLoading: isCreatingAdminModules } =
    useFetchCreateAdminModules({
      options: {
        onSuccess,
        onError,
      },
    });

  function handleSubmit(formData: IAdminModuleFormData) {
    return id > 0
      ? updateAdminModules({
          ...formData,
          id,
        })
      : createAdminModules(formData);
  }

  function handleCancel() {
    form.setFieldsValue({
      ...initialValues,
      ...adminModule,
    });

    if (typeof onCancel === "function") {
      onCancel();
    }
  }

  useEffect(() => {
    if (adminModule) {
      form.setFieldsValue({
        ...adminModule,
        datasheetId: adminModule?.datasheet?.id ?? undefined,
      });
    }
  }, [form, adminModule]);

  return (
    <Form
      data-cy="admin-inverter-form"
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={initialValues}
    >
      {adminModuleError && (
        <Alert
          type="error"
          message="Falha ao carregar dados iniciais"
          description={adminModuleError.message}
          style={{ marginBottom: "1rem", marginTop: "1rem" }}
          action={
            <Tooltip title="Tentar recarregar dados">
              <Button
                onClick={() => reloadAdminModule()}
                icon={<IoReload strokeWidth={4} size={18} />}
              />
            </Tooltip>
          }
        />
      )}

      <Spin spinning={isLoadingAdminModule}>
        <Row gutter={16}>
          <Col span={24} md={12}>
            <Form.Item<IAdminModuleFormData>
              name="model"
              label="Modelo"
              rules={[{ required: true, message: "Este campo é obrigatório" }]}
            >
              <CustomInput.Text size="large" />
            </Form.Item>
          </Col>

          <Col span={24} md={12}>
            <Form.Item<IAdminModuleFormData>
              name="manufacturer"
              label="Fabricante"
              rules={[{ required: true, message: "Este campo é obrigatório" }]}
            >
              <CustomInput.Text size="large" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24} md={8}>
            <Form.Item<IAdminModuleFormData>
              name="cellType"
              label="Tipo de módulo"
              rules={[{ required: true, message: "Este campo é obrigatório" }]}
            >
              <Select.ModuleCellType size="large" />
            </Form.Item>
          </Col>

          <Col span={24} md={8}>
            <Form.Item<IAdminModuleFormData>
              name="surfaceType"
              label="Tipo de face"
              rules={[{ required: true, message: "Este campo é obrigatório" }]}
            >
              <Select.ModulePhase size="large" />
            </Form.Item>
          </Col>

          <Col span={24} md={8}>
            <Form.Item<IAdminModuleFormData> name="powerWp" label="Potência">
              <CustomInput.UnitMeasurement
                precision="2"
                unitMeasurement="Wp"
                min={0}
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24} md={8}>
            <Form.Item<IAdminModuleFormData> name="height" label="Altura">
              <CustomInput.UnitMeasurement
                precision="2"
                unitMeasurement="mm"
                min={0}
                size="large"
              />
            </Form.Item>
          </Col>

          <Col span={24} md={8}>
            <Form.Item<IAdminModuleFormData> name="width" label="Largura">
              <CustomInput.UnitMeasurement
                precision="2"
                unitMeasurement="mm"
                min={0}
                size="large"
              />
            </Form.Item>
          </Col>

          <Col span={24} md={8}>
            <Form.Item<IAdminModuleFormData> name="depth" label="Profundidade">
              <CustomInput.UnitMeasurement
                precision="2"
                unitMeasurement="mm"
                min={0}
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider style={{ marginTop: 0, marginBottom: "1rem" }} />

        <Row gutter={16}>
          <Col span={24} md={8}>
            <Form.Item<IAdminModuleFormData> name="weight" label="Peso">
              <CustomInput.Number precision={1} min={0} size="large" />
            </Form.Item>
          </Col>

          <Col span={24} md={8}>
            <Form.Item<IAdminModuleFormData> name="vocStc" label="Voc stc">
              <CustomInput.Number precision={1} min={0} size="large" />
            </Form.Item>
          </Col>

          <Col span={24} md={8}>
            <Form.Item<IAdminModuleFormData> name="iscStc" label="Isc stc">
              <CustomInput.Number precision={1} min={0} size="large" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24} md={8}>
            <Form.Item<IAdminModuleFormData> name="vmpStc" label="Vmp stc">
              <CustomInput.Number precision={1} min={0} size="large" />
            </Form.Item>
          </Col>

          <Col span={24} md={8}>
            <Form.Item<IAdminModuleFormData> name="impStc" label="Imp stc">
              <CustomInput.Number precision={1} min={0} size="large" />
            </Form.Item>
          </Col>

          <Col span={24} md={8}>
            <Form.Item<IAdminModuleFormData>
              name="efficiencyStc"
              label="Eficiência"
            >
              <CustomInput.Number precision={1} min={0} size="large" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24} md={8}>
            <Form.Item<IAdminModuleFormData> name="vocNmot" label="Voc nmot">
              <CustomInput.Number precision={1} min={0} size="large" />
            </Form.Item>
          </Col>

          <Col span={24} md={8}>
            <Form.Item<IAdminModuleFormData> name="iscNmot" label="Isc nmot">
              <CustomInput.Number precision={1} min={0} size="large" />
            </Form.Item>
          </Col>

          <Col span={24} md={8}>
            <Form.Item<IAdminModuleFormData> name="vmpNmot" label="Vmp nmot">
              <CustomInput.Number precision={1} min={0} size="large" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24} md={8}>
            <Form.Item<IAdminModuleFormData> name="impNmot" label="Imp nmot">
              <CustomInput.Number precision={2} min={0} size="large" />
            </Form.Item>
          </Col>

          <Col span={24} md={8}>
            <Form.Item<IAdminModuleFormData>
              name="vocCoefficientTemperature"
              label="Variação de tensão VOC"
            >
              <CustomInput.Number precision={2} size="large" />
            </Form.Item>
          </Col>

          <Col span={24} md={8}>
            <Form.Item<IAdminModuleFormData>
              name="iscCoefficientTemperature"
              label="Variação de corrente ISC"
            >
              <CustomInput.Number precision={2} min={0} size="large" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24} md={8}>
            <Form.Item<IAdminModuleFormData>
              name="annualLoss"
              label="Perda anual"
            >
              <CustomInput.Number precision={2} min={0} size="large" />
            </Form.Item>
          </Col>

          <Col span={24} md={8}>
            <Form.Item<IAdminModuleFormData>
              name="productWarranty"
              label="Garantia do produto"
            >
              <CustomInput.Number precision={2} min={0} size="large" />
            </Form.Item>
          </Col>

          <Col span={24} md={8}>
            <Form.Item<IAdminModuleFormData>
              name="performanceWarranty"
              label="Garantia da performance"
            >
              <CustomInput.Number precision={2} min={0} size="large" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item<IAdminModuleFormData> name="datasheetId" label="Datasheet">
          <Select.DatasheetSearch
            initialRecord={adminModule?.datasheet}
            placeholder="Selecione o Datasheet..."
          />
        </Form.Item>
      </Spin>

      <Flex align="center" gap={8} justify="end" style={{ marginTop: 16 }}>
        <Button
          onClick={handleCancel}
          disabled={isUpdatingAdminModules || isCreatingAdminModules}
        >
          Cancelar
        </Button>
        <Button
          loading={isUpdatingAdminModules || isCreatingAdminModules}
          type="primary"
          htmlType="submit"
          disabled={isLoadingAdminModule}
        >
          {id > 0 ? "Atualizar" : "Criar"}
        </Button>
      </Flex>
    </Form>
  );
}
