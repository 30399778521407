import { useEffect } from "react";

import { IServicesDealFormProps, ServicesFormValuesType } from "./interface";

import { Alert } from "~components/Alert";
import { CityAutoComplete } from "~components/AutoComplete/CityAutoComplete";
import { Select } from "~components/Select";
import {
  useFetchGetDealByCustomId,
  useFetchUpdateSolarServiceByDealId,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzFormItem } from "~solarzui/SolarzFormItem";
import { SolarzInputNumber } from "~solarzui/SolarzInputNumber";
import { Form } from "antd";

export function SolarServiceDealForm({
  formInstance,
  initialValues,
  dealId = 0,
  onError,
  onSuccess,
  onCancel,
}: IServicesDealFormProps) {
  const [form] = Form.useForm<ServicesFormValuesType>(formInstance);
  const { screens } = useAppConfig();

  // watchers
  const voltageType = Form.useWatch("voltageType", form);

  const { mutate: updateSolarService, isLoading: isUpdatingSolarService } =
    useFetchUpdateSolarServiceByDealId({
      options: {
        onSuccess,
        onError,
      },
    });

  function handleSubmit(formValues: ServicesFormValuesType) {
    updateSolarService({
      ...formValues,
      id: dealId,
    });
  }

  const {
    data: deal,
    isFetching: isLoadingDeal,
    error: dealError,
    refetch: refetchDeal,
  } = useFetchGetDealByCustomId({
    dependencyArray: [dealId],
    payload: {
      customId: dealId,
    },
    options: {
      enabled: dealId > 0,
    },
  });

  function handleCancel() {
    if (typeof onCancel === "function") {
      return onCancel();
    }

    return deal
      ? form.setFieldsValue({
          ...deal.solarService,
          cityName: deal.solarService?.city?.name,
          cityId: deal.solarService?.city?.id,
        })
      : form.setFieldsValue({
          ...initialValues,
          cityId: undefined,
          cityName: undefined,
          desiredPower: undefined,
          energyRequirement: undefined,
          energyRequirementFp: undefined,
          totalValue: undefined,
          voltageType: undefined,
        });
  }

  useEffect(() => {
    if (deal?.solarService) {
      form.setFieldsValue({
        ...deal,
        voltageType: deal.solarService?.voltageType,
        desiredPower: deal.solarService?.desiredPower,
        energyRequirement: deal.solarService?.energyRequirement,
        energyRequirementFp: deal.solarService?.energyRequirementFp,
        totalValue: deal.solarService?.totalValue,
        cityId: deal.solarService?.city?.id,
        cityName: deal.solarService?.city?.name,
      });
    }
  }, [deal, form]);

  const isDisable = !!dealError || isLoadingDeal;

  const handleVoltageTypeChange = (
    value: "LOW" | "MEDIUM" | ("LOW" | "MEDIUM")[],
  ) => {
    const newValue = Array.isArray(value) ? value[0] : value;
    form.setFieldsValue({
      voltageType: newValue,
    });
  };

  return (
    <Form
      data-cy="solar-service-deal-form"
      form={form}
      layout="vertical"
      initialValues={{
        voltageType: "LOW",
      }}
      onFinish={() => {
        handleSubmit(form.getFieldsValue(true));
      }}
      preserve
    >
      {dealError && (
        <Alert.CardError
          errorMessage={dealError?.message}
          title="Falha ao carregar dados iniciais"
          reloadFn={refetchDeal}
          style={{ marginBottom: 16 }}
        />
      )}

      <div className="w-full flex flex-col">
        <div className="w-full flex flex-row gap-3 align-middle flex-wrap">
          <div
            className={`w-full flex ${screens.mobile ? "flex-col" : "flex-row"} gap-3 align-middle`}
          >
            <SolarzFormItem<ServicesFormValuesType>
              name="voltageType"
              label="Tipo de tensão"
              style={{ width: "100%" }}
            >
              <Select.VoltageType
                style={{ height: 40 }}
                onChange={handleVoltageTypeChange}
              />
            </SolarzFormItem>
            <SolarzFormItem<ServicesFormValuesType>
              name="desiredPower"
              label="Potência Desejada"
              style={{ width: "100%" }}
            >
              <SolarzInputNumber icon="kWp" placeholder="00,00" />
            </SolarzFormItem>
          </div>
          <div
            className={`w-full flex ${screens.mobile ? "flex-col" : "flex-row"} gap-3`}
          >
            <SolarzFormItem<ServicesFormValuesType>
              name="energyRequirement"
              label={
                voltageType === "MEDIUM"
                  ? "Necessidade energética ponta"
                  : "Necessidade energética"
              }
              style={{ width: "100%" }}
            >
              <SolarzInputNumber icon="kWh" placeholder="00,00" />
            </SolarzFormItem>
            {voltageType === "MEDIUM" && (
              <SolarzFormItem<ServicesFormValuesType>
                name="energyRequirementFp"
                label="Necessidade energética fora ponta"
                style={{ width: "100%" }}
              >
                <SolarzInputNumber icon="kWh" placeholder="00,00" />
              </SolarzFormItem>
            )}
          </div>
        </div>
        <div
          className={`w-full flex ${screens.mobile ? "flex-col" : "flex-row"} gap-3 align-middle justify-between`}
        >
          <SolarzFormItem<ServicesFormValuesType>
            name="cityName"
            label="Cidade"
            style={{ width: "100%" }}
          >
            <CityAutoComplete
              onChange={(value, option) => {
                form.setFieldsValue({ cityId: option?.record?.id });
              }}
              style={{
                width: "100%",
                height: 40,
              }}
              value=""
            />
          </SolarzFormItem>

          <SolarzFormItem<ServicesFormValuesType>
            name="totalValue"
            label="Valor total"
            style={{ width: "100%" }}
          >
            <SolarzInputNumber inputType="R$" />
          </SolarzFormItem>
        </div>

        <div className="w-full flex flex-row align-middle justify-end gap-4 mt-4">
          <SolarzButton variant="secondary" onClick={handleCancel}>
            Cancelar
          </SolarzButton>
          <SolarzButton
            variant="primary"
            htmlType="submit"
            isLoading={isUpdatingSolarService}
            isDisabled={isDisable}
          >
            Salvar
          </SolarzButton>
        </div>
      </div>
    </Form>
  );
}
