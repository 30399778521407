import { useMemo } from "react";
import { BiErrorCircle } from "react-icons/bi";

import type { IEquipmentRegionSelectProps } from "./interface";

import { useFetchGetAllRegions } from "~hooks/api";
import { Button, Select, Tooltip } from "antd";

export function EquipmentRegionSelect({
  allowClear = true,
  onChange,
  ...props
}: IEquipmentRegionSelectProps) {
  const {
    data: regions,
    isFetching,
    error,
    refetch,
  } = useFetchGetAllRegions({});

  const options = useMemo(() => {
    return regions
      ? regions.map((region) => ({
          label: region.description ?? "N/A",
          value: region.id,
          record: region,
        }))
      : [];
  }, [regions]);

  return (
    <Select
      {...props}
      data-cy="equipment-region-select"
      loading={isFetching}
      options={options}
      allowClear={allowClear}
      status={error ? "error" : ""}
      suffixIcon={
        error && (
          <Tooltip title={error.message + " | Clique para recarregar."}>
            <Button type="link" loading={isFetching}>
              <BiErrorCircle size={18} onClick={() => refetch()} />
            </Button>
          </Tooltip>
        )
      }
      onChange={(value, option) => {
        if (option instanceof Array || !onChange) return;
        onChange(value, option);
      }}
    />
  );
}
