import { useMemo } from "react";

import {
  IConsumerUnitCompensationSelectProps,
  IConsumerUnitCompensationSelectOption,
} from "./interface";

import { Select } from "antd";

export function ConsumerUnitCompensationSelect({
  value,
  onChange,
  defaultValue,
  disabledValues,
  ...props
}: IConsumerUnitCompensationSelectProps) {
  const OPTIONS: IConsumerUnitCompensationSelectOption[] = useMemo(
    () => [
      {
        label: "Porcentagem",
        value: "RATEIO" as const,
        disabled: disabledValues?.RATEIO,
      },
      {
        label: "Fila de prioridade",
        value: "FILA_PRIORIDADE" as const,
        disabled: disabledValues?.FILA_PRIORIDADE,
      },
    ],
    [disabledValues],
  );

  return (
    <Select
      {...props}
      options={OPTIONS}
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
      data-cy="select-compensation"
    />
  );
}
