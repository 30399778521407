import type { ISizingKitFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function SizingKitFormModal({
  isOpen = false,
  onClose = () => null,
  title = "Gerar kit dimensionado",
  maxWidth = 1280,
  formProps,
}: ISizingKitFormModalProps) {
  return (
    <Modal
      data-cy="sizing-kit-form-modal"
      open={isOpen}
      onCancel={onClose}
      title={title}
      width="90vw"
      footer={null}
      style={{ maxWidth }}
      destroyOnClose
    >
      <Form.SizingKit {...formProps} onCancel={onClose} />
    </Modal>
  );
}
