import { useEffect, useMemo, useState } from "react";

import type {
  DealDefaultFieldsSettingsKeys,
  DealFormMappedFieldSettings,
  DealFormValuesAutoCompleteStatusType,
  DealFormValuesType,
  IDealFormProps,
} from "./interface";

import { Alert } from "~components/Alert";
import { PipelineTab } from "~components/PipelineTab";
import { Select } from "~components/Select";
import {
  useFetchCreateDeal,
  useFetchGetDealByCustomId,
  useFetchGetDealCustomFieldsByPipelineId,
  useFetchUpdateDealByCustomId,
} from "~hooks/api";
import { useFetchGetDealDefaultFieldsSettings } from "~hooks/api/config/useFetchGetDealDefaultFieldsSettings";
import { useAppConfig } from "~hooks/useAppConfig";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzFormItem } from "~solarzui/SolarzFormItem";
import { SolarzInput } from "~solarzui/SolarzInput";
import { SolarzInputNumber } from "~solarzui/SolarzInputNumber";
import { SolarzSelect } from "~solarzui/SolarzSelect";
import { FieldTypeEnum } from "~types/enum";
import {
  Col,
  DatePicker,
  Divider,
  Flex,
  Form,
  FormInstance,
  Input,
  Radio,
  Row,
  Spin,
} from "antd";
import { RuleObject } from "antd/es/form";
import dayjs, { type Dayjs } from "dayjs";

const FIELDS_MAP = {
  BOOLEAN: (
    <Radio.Group
      options={[
        {
          label: "Sim",
          value: true,
        },
        {
          label: "Não",
          value: false,
        },
      ]}
    />
  ),
  NUMBER: (
    <SolarzInputNumber
      placeholder="Digite o valor numérico"
      style={{ width: "100%" }}
      scale="large"
    />
  ),
  TEXT_AREA: (
    <Input.TextArea
      placeholder="Digite o texto..."
      style={{ width: "100%", maxHeight: 120 }}
      maxLength={255}
    />
  ),
  TEXT: (
    <SolarzInput
      placeholder="Digite o texto..."
      scale="large"
      style={{ width: "100%" }}
    />
  ),
  SELECT: (
    <div className="flex flex-col">
      <SolarzSelect mode="tags" placeholder="Digite as opções..." allowClear />
    </div>
  ),
  DATE: (
    <DatePicker
      format="DD/MM/YYYY"
      allowClear
      style={{ height: 42, width: "100%" }}
      placeholder="Selecione a data..."
    />
  ),
} as { [key in FieldTypeEnum]: JSX.Element };

function parseDateStringToDayJs(dateString?: string): Dayjs | undefined {
  if (!dateString) return undefined;

  const [day, month, year] = dateString
    .split("/")
    .map((value) => Number(value)); // expected format DD/MM/YYYY

  if (isNaN(day) || isNaN(month) || isNaN(year)) return undefined;

  const formattedString = `${month}/${day}/${year}`;

  return dayjs(formattedString);
}

function getInputComponentByType(type?: string) {
  return type ? (FIELDS_MAP as Record<string, JSX.Element>)?.[type] : <></>;
}

const DEFAULT_INITIAL_VALUES = {
  eventType: "CALL",
  priority: "MEDIUM",
  value: 0,
} as Partial<DealFormValuesType>;

export function DealForm({
  formInstance,
  onCancel,
  onError,
  onSuccess,
  dealId,
  initialValues,
  disableFieldOrganization,
}: IDealFormProps) {
  const { screens } = useAppConfig();
  const [form] = Form.useForm<DealFormValuesType>(formInstance);

  const formWatcherPipelineId = Form.useWatch("pipelineId", form);
  const formWatcherPipelineStageId = Form.useWatch("pipelineStageId", form);
  const formWatcherOrganizationId = Form.useWatch("organizationId", form);
  const formWatcherPersonId = Form.useWatch("personId", form);

  const [currentStep, setCurrentStep] = useState<number>(1);

  const [autoCompleteStatus, setAutoCompleteStatus] =
    useState<DealFormValuesAutoCompleteStatusType>({
      autoCompletedByOrganization: false,
      autoCompletedByPerson: false,
      selectedOrganizationRecord: undefined,
      selectedPersonRecord: undefined,
    });

  function onChangeAutoCompleteStatus(
    newStatus: Partial<DealFormValuesAutoCompleteStatusType>,
  ) {
    setAutoCompleteStatus((current) => ({
      ...current,
      ...newStatus,
    }));
  }

  const {
    data: fieldsSettings,
    isLoading: isLoadingFieldSettings,
    error: fieldSettingsError,
    refetch: reloadFieldsSettings,
  } = useFetchGetDealDefaultFieldsSettings({});

  const {
    data: dealCustomFields = [],
    isLoading: isLoadingDealCustomFields,
    error: errorDealCustomFields,
    refetch: reloadDealCustomFields,
    isFetched: dealCustomFieldsIsFetched,
  } = useFetchGetDealCustomFieldsByPipelineId({
    payload: {
      id: formWatcherPipelineId,
    },
    dependencyArray: [formWatcherPipelineId],
    options: {
      enabled: formWatcherPipelineId > 0,
    },
  });

  const {
    data: deal,
    isLoading: isLoadingDeal,
    error: dealError,
    refetch: refetchDeal,
  } = useFetchGetDealByCustomId({
    dependencyArray: [dealId],
    options: {
      retry: 1,
      enabled: !!dealId && dealCustomFieldsIsFetched,
    },
    payload: {
      customId: dealId ?? 0,
    },
  });

  const { mutate: createDeal, isLoading: isCreatingDeal } = useFetchCreateDeal({
    options: {
      onError,
      onSuccess,
    },
  });

  const { mutate: updateDeal, isLoading: isUpdatingDeal } =
    useFetchUpdateDealByCustomId({
      options: {
        onError,
        onSuccess,
      },
    });

  function handleSubmit(formValues: DealFormValuesType) {
    const customFieldValues = formValues.customFieldValues?.map(
      (fieldValue) => {
        return {
          dealCustomFieldId: fieldValue.dealCustomFieldId,
          value: fieldValue.value ? fieldValue.value.toString() : "",
        };
      },
    );

    // only send custom fields with values
    const filteredFieldsValues = customFieldValues?.filter(
      (field) => !!field.value,
    );

    return dealId
      ? updateDeal({
          ...formValues,
          customId: dealId,
          customFieldValues: filteredFieldsValues,
        })
      : createDeal({ ...formValues, customFieldValues: filteredFieldsValues });
  }

  function handleCancel() {
    if (typeof onCancel === "function") {
      return onCancel();
    }

    return deal
      ? form.setFieldsValue({
          ...deal,
          ownerId: deal.owner?.id,
          organizationId: deal.organization?.id,
          personId: deal.person?.id,
        })
      : form.setFieldsValue({
          name: undefined,
          organizationId: undefined,
          ownerId: undefined,
          personId: undefined,
          pipelineId: undefined,
          pipelineStageId: undefined,
          value: undefined,
          ...DEFAULT_INITIAL_VALUES,
          ...initialValues,
        });
  }

  const mappedFieldsSettings = useMemo(() => {
    return (
      fieldsSettings?.reduce(
        (accumulator, settings) => {
          const { dealDefaultField, ...rest } = settings;
          return {
            ...accumulator,
            [dealDefaultField?.name || ""]: rest,
          };
        },
        {} as Record<
          DealDefaultFieldsSettingsKeys,
          DealFormMappedFieldSettings
        >,
      ) ??
      ({} as Record<DealDefaultFieldsSettingsKeys, DealFormMappedFieldSettings>)
    );
  }, [fieldsSettings]);

  const requireOrganizationOrPerson = ({
    getFieldValue,
  }: {
    getFieldValue: FormInstance["getFieldValue"];
  }) => ({
    validator(_: RuleObject) {
      const organizationId = getFieldValue("organizationId");
      const personId = getFieldValue("personId");

      if (organizationId || personId) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error(
          'O campo "Organização" ou "Pessoa" tem que estar preenchido.',
        ),
      );
    },
  });

  useEffect(() => {
    if (deal) {
      form.setFieldsValue({
        ...deal,
        ownerId: deal.owner?.id || undefined,
        organizationId:
          deal.person?.organization?.id || deal.organization?.id || undefined,
        personId: deal.person?.id || undefined,
        pipelineId: deal.pipeline?.id || undefined,
        pipelineStageId: deal.pipelineStage?.id || undefined,
        customFieldValues: dealCustomFields.map((field) => {
          const existentDealCustomField = deal.dealCustomFieldValues.find(
            (customField) => customField.dealCustomFieldId === field.id,
          );

          const value =
            existentDealCustomField?.value || field.defaultValue || undefined;

          return {
            dealCustomFieldId: field.id,
            value:
              field.type === "DATE" ? parseDateStringToDayJs(value) : value,
          };
        }),
      });

      const currentStep = deal.pipelineStage?.position;

      return setCurrentStep(currentStep || 1);
    }

    form.setFieldsValue({
      customFieldValues: dealCustomFields.map((field) => {
        const value =
          field.type === "DATE" && field.defaultValue
            ? parseDateStringToDayJs(field.defaultValue)
            : field.defaultValue;

        return {
          dealCustomFieldId: field.id,
          value: value || undefined,
        };
      }),
    });
  }, [deal, dealCustomFields, form]);

  const isSubmittingForm = isCreatingDeal || isUpdatingDeal;

  const isLoadingForm =
    isLoadingDeal || isLoadingFieldSettings || isLoadingDealCustomFields;

  const organizationFieldIsDisabled =
    disableFieldOrganization ||
    (!!formWatcherPersonId && !formWatcherOrganizationId);

  return (
    <Form
      data-cy="deal-form"
      form={form}
      initialValues={{
        ...DEFAULT_INITIAL_VALUES,
        ...initialValues,
      }}
      onFinish={handleSubmit}
      preserve
      layout="vertical"
    >
      {dealError && (
        <Alert.CardError
          errorMessage={dealError?.message}
          title="Falha ao carregar dados iniciais"
          reloadFn={refetchDeal}
          style={{ marginBottom: 16 }}
        />
      )}

      {fieldSettingsError && (
        <Alert.CardError
          errorMessage={fieldSettingsError?.message}
          title="Falha ao carregar configurações dos campos"
          reloadFn={reloadFieldsSettings}
          style={{ marginBottom: 16 }}
        />
      )}

      {errorDealCustomFields && (
        <Alert.CardError
          errorMessage={errorDealCustomFields?.message}
          title="Falha ao carregar campos customizados"
          reloadFn={reloadDealCustomFields}
          style={{ marginBottom: 16 }}
        />
      )}

      <Spin spinning={isLoadingForm}>
        <SolarzFormItem<DealFormValuesType>
          name="organizationId"
          hidden={mappedFieldsSettings["organization"]?.hidden}
          label="Organização"
          style={{ marginBottom: 24 }}
          rules={[
            {
              required: mappedFieldsSettings["organization"]?.required,
              message: "Organização é obrigatória",
            },
            requireOrganizationOrPerson,
          ]}
        >
          <Select.Organization
            scale="large"
            isDisabled={
              !mappedFieldsSettings["organization"]?.editable ||
              organizationFieldIsDisabled
            }
            placeholder="Selecione a organização..."
            onChange={(_, record) => {
              form.setFieldsValue({ personId: undefined });
              form.validateFields(["organizationId"]);
              form.validateFields(["personId"]);

              if (!record || Array.isArray(record)) {
                if (autoCompleteStatus.autoCompletedByOrganization) {
                  form.setFieldsValue({ name: undefined });
                }

                return onChangeAutoCompleteStatus({
                  autoCompletedByOrganization: false,
                  selectedOrganizationRecord: undefined,
                });
              }

              const currentFormFieldName = form.getFieldValue("name");

              if (
                currentFormFieldName &&
                !autoCompleteStatus.autoCompletedByOrganization
              ) {
                return onChangeAutoCompleteStatus({
                  selectedOrganizationRecord: record.record,
                });
              }

              const newFormFieldName = `Negócio ${record.record.name}`;

              form.setFieldsValue({ name: newFormFieldName });

              onChangeAutoCompleteStatus({
                autoCompletedByOrganization: true,
                selectedOrganizationRecord: record.record,
              });
            }}
            parentTitleModal="Novo negócio"
            onCreateOrganizationSuccess={(organization) => {
              form.setFieldsValue({
                organizationId: organization.id,
              });

              form.validateFields(["organizationId"]);
              form.validateFields(["personId"]);

              // auto-complete field of name
              if (
                form.getFieldValue("name") &&
                !autoCompleteStatus.autoCompletedByPerson
              )
                return;

              const newFormFieldName = `Negócio ${organization.name}`;

              form.setFieldsValue({ name: newFormFieldName });

              onChangeAutoCompleteStatus({
                autoCompletedByPerson: true,
                selectedOrganizationRecord: {
                  ...organization,
                  name: organization.name || "",
                  cnpj: organization.cnpj || "",
                  email: organization.email || "",
                  phone: organization.phone || "",
                },
              });
            }}
            showDropdownRender
          />
        </SolarzFormItem>

        <SolarzFormItem<DealFormValuesType>
          name="personId"
          hidden={mappedFieldsSettings["person"]?.hidden}
          label={formWatcherOrganizationId ? "Pessoa na organização" : "Pessoa"}
          style={{ marginBottom: 24 }}
          rules={[
            {
              required: mappedFieldsSettings["person"]?.required,
            },
            requireOrganizationOrPerson,
          ]}
        >
          <Select.Person
            scale="large"
            isDisabled={!mappedFieldsSettings["person"]?.editable}
            placeholder="Selecione a pessoa..."
            filters={{
              organizationId: formWatcherOrganizationId,
            }}
            onChange={(_, record) => {
              form.validateFields(["organizationId"]);
              form.validateFields(["personId"]);

              if (!record || Array.isArray(record)) {
                if (!autoCompleteStatus.autoCompletedByPerson) {
                  return onChangeAutoCompleteStatus({
                    autoCompletedByPerson: false,
                    selectedPersonRecord: undefined,
                  });
                }

                const alreadySelectedOrganizationName =
                  autoCompleteStatus.selectedOrganizationRecord?.name ||
                  undefined;

                form.setFieldsValue({
                  name: alreadySelectedOrganizationName,
                });

                return onChangeAutoCompleteStatus({
                  autoCompletedByPerson: false,
                  selectedPersonRecord: undefined,
                  autoCompletedByOrganization: true,
                });
              }

              const currentFormFieldName = form.getFieldValue("name");

              if (currentFormFieldName) {
                return onChangeAutoCompleteStatus({
                  selectedPersonRecord: record.record,
                });
              }

              const newFormFieldName = `Negócio ${record.record.name}`;

              form.setFieldsValue({ name: newFormFieldName });

              onChangeAutoCompleteStatus({
                autoCompletedByPerson: true,
                selectedPersonRecord: record.record,
              });
            }}
            parentTitleModal="Nova pessoa"
            onCreatePersonSuccess={(person) => {
              form.setFieldsValue({
                personId: person.id,
              });

              form.validateFields(["organizationId"]);
              form.validateFields(["personId"]);

              // auto-complete field of name
              if (
                form.getFieldValue("name") &&
                !autoCompleteStatus.autoCompletedByPerson
              )
                return;

              const newFormFieldName = `Negócio ${person.name}`;

              form.setFieldsValue({ name: newFormFieldName });

              onChangeAutoCompleteStatus({
                autoCompletedByPerson: true,
                selectedPersonRecord: {
                  ...person,
                  name: person.name || "",
                  identifier: person.identifier || "",
                  email: person.email || "",
                  phone: person.phone || "",
                },
              });
            }}
            showDropdownRender
          />
        </SolarzFormItem>

        <SolarzFormItem<DealFormValuesType>
          name="name"
          label="Nome"
          hidden={mappedFieldsSettings["name"]?.hidden}
          rules={[
            {
              required: mappedFieldsSettings["name"]?.required,
              message: "Nome do negócio é obrigatório!",
            },
          ]}
        >
          <SolarzInput
            isDisabled={!mappedFieldsSettings["name"]?.editable}
            scale="large"
            placeholder="Nome do negócio..."
            onChange={() => {
              if (
                !autoCompleteStatus.autoCompletedByOrganization &&
                !autoCompleteStatus.autoCompletedByPerson
              )
                return; // improves performance!

              onChangeAutoCompleteStatus({
                autoCompletedByOrganization: false,
                autoCompletedByPerson: false,
              });
            }}
          />
        </SolarzFormItem>

        <SolarzFormItem<DealFormValuesType>
          name="ownerId"
          hidden={mappedFieldsSettings["responsibleSeller"]?.hidden}
          label="Responsável"
          style={{ marginBottom: 24 }}
          rules={[
            {
              required: mappedFieldsSettings["responsibleSeller"]?.required,
              message: "Responsável",
            },
          ]}
        >
          <Select.User
            scale="large"
            isDisabled={!mappedFieldsSettings["responsibleSeller"]?.editable}
            placeholder="Selecione o responsável..."
          />
        </SolarzFormItem>

        <SolarzFormItem<DealFormValuesType>
          name="pipelineId"
          hidden={mappedFieldsSettings["pipeline"]?.hidden}
          label="Funil"
          style={{ marginBottom: 24 }}
          rules={[
            {
              required: mappedFieldsSettings["pipeline"]?.required,
              message: "Funil é obrigatório",
            },
          ]}
        >
          <Select.Pipeline
            scale="large"
            isDisabled={!mappedFieldsSettings["pipeline"]?.editable}
            placeholder="Selecione o funil..."
            allowClear={false}
            onLoad={(pipelines) => {
              if (!form.getFieldValue("pipelineId") && pipelines.length > 0) {
                form.setFieldsValue({
                  pipelineId: pipelines[0].id,
                });
              }
            }}
          />
        </SolarzFormItem>

        <Row gutter={screens.mobile ? 16 : 24}>
          <Col span={screens.mobile ? 24 : 12}>
            <SolarzFormItem<DealFormValuesType>
              name="pipelineStageId"
              hidden={mappedFieldsSettings["pipelineStage"]?.hidden}
              label="Fase do funil"
              style={{ marginBottom: 24, width: "100%" }}
              rules={[
                {
                  required: mappedFieldsSettings["pipelineStage"]?.required,
                  message: "Funil é obrigatório",
                },
              ]}
            >
              <div style={{ width: "100%" }}>
                <PipelineTab.DealStages
                  pipelineId={formWatcherPipelineId}
                  onChange={(step, record) => {
                    form.setFieldsValue({
                      pipelineStageId: record.id,
                    });

                    setCurrentStep(step);
                  }}
                  onLoad={(pipeline) => {
                    const firstPipelineStage = pipeline.stages[0];

                    const currentFormPipelineStageId =
                      form.getFieldValue("pipelineStageId");

                    if (!currentFormPipelineStageId) {
                      form.setFieldsValue({
                        pipelineStageId: firstPipelineStage.id,
                      });
                      setCurrentStep(1);
                      return;
                    }

                    const pipelineStage = pipeline.stages.find(
                      (stage) => stage.id === currentFormPipelineStageId,
                    );

                    if (!pipelineStage) {
                      form.setFieldsValue({
                        pipelineStageId: firstPipelineStage.id,
                      });
                      setCurrentStep(1);
                      return;
                    }

                    setCurrentStep(pipelineStage.position ?? 1);
                  }}
                  scale="medium"
                  separator="arrow"
                  actualStep={currentStep}
                />
              </div>
            </SolarzFormItem>
          </Col>
          <Col span={screens.mobile ? 24 : 12}>
            <SolarzFormItem<DealFormValuesType>
              name="value"
              hidden={mappedFieldsSettings["dealValue"]?.hidden}
              label="Valor do negócio"
              style={{ marginBottom: 24 }}
              rules={[
                {
                  required: mappedFieldsSettings["dealValue"]?.required,
                  message: "Valor do negócio é obrigatório",
                },
              ]}
            >
              <SolarzInputNumber
                scale="large"
                isDisabled={!mappedFieldsSettings["dealValue"]?.editable}
                placeholder="Ex.: 1000,00"
                inputType="R$"
              />
            </SolarzFormItem>
          </Col>
        </Row>

        {dealCustomFields.length > 0 && (
          <>
            <Divider
              rootClassName={dealCustomFields.length > 0 ? "" : "hidden"}
            >
              <span className="text-sm">Campos customizados</span>
            </Divider>

            {dealCustomFields.map((field, index) => {
              return (
                <div key={field.id}>
                  <SolarzFormItem<DealFormValuesType>
                    name={["customFieldValues", index, "dealCustomFieldId"]}
                    hidden
                  >
                    <SolarzInputNumber readOnly isDisabled />
                  </SolarzFormItem>

                  <SolarzFormItem<DealFormValuesType>
                    name={["customFieldValues", index, "value"]}
                    label={field.label}
                    preserve
                    key={field.id}
                    rules={[
                      {
                        required: !!field.pipelinesRequired.find(
                          (pipeline) =>
                            pipeline.id === formWatcherPipelineStageId,
                        ),
                      },
                    ]}
                  >
                    {field.type === "SELECT" ? (
                      <SolarzSelect
                        options={field.options.map((option) => {
                          return {
                            label: option,
                            value: option,
                            record: {
                              value: option,
                            },
                          };
                        })}
                        placeholder="Selecione..."
                        allowClear
                        emptyMessage="Adicione opções no campo acima para selecionar a opção padrão"
                        scale="large"
                      />
                    ) : (
                      getInputComponentByType(field.type)
                    )}
                  </SolarzFormItem>
                </div>
              );
            })}
          </>
        )}
      </Spin>

      <Flex align="center" gap={16} justify="flex-end">
        <SolarzButton variant="secondary" scale="tiny" onClick={handleCancel}>
          Cancelar
        </SolarzButton>
        <SolarzButton
          scale="tiny"
          htmlType="submit"
          isDisabled={isLoadingForm}
          isLoading={isSubmittingForm}
        >
          Salvar
        </SolarzButton>
      </Flex>
    </Form>
  );
}
