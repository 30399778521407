import type { IAppPagesMenuDrawer } from "./interface";
import styles from "./styles";

import { Button } from "~components/Button";
import { Card } from "~components/Card";
import { Menu } from "~components/Menu";
import { Select } from "~components/Select";
import { useSession } from "~hooks/useSession";
import { Drawer, Typography, Flex } from "antd";

export function AppPagesMenuDrawer({
  isOpen = false,
  onClose = () => undefined,
  menuProps = undefined,
  ...props
}: IAppPagesMenuDrawer) {
  const { user } = useSession();

  return (
    <Drawer
      data-cy="app-pages-menu-drawer"
      title={
        <Flex vertical gap={4} style={{ paddingTop: "32px" }}>
          <Card.UserInfo />

          <Flex
            align="flex-end"
            gap={12}
            style={{ marginBottom: "0.5rem", marginTop: "1rem" }}
          >
            <Flex
              vertical
              style={{
                width: "100%",
              }}
            >
              <Typography.Text style={{ marginLeft: 2 }}>
                Unidade de negócio:
              </Typography.Text>
              <Select.BusinessUnit
                updateCurrentUserBusinessUnitOnSelect
                value={user?.businessUnitId}
              />
            </Flex>
            <Button.ReleaseNoteInfo />
          </Flex>

          <Button.ControlTheme
            size="middle"
            type="link"
            style={{ padding: 0, margin: 0 }}
          />

          <Button.Logout
            size="middle"
            type="link"
            style={{ padding: 0, margin: 0 }}
          />
        </Flex>
      }
      placement="right"
      onClose={onClose}
      open={isOpen}
      styles={{
        body: { padding: 0, ...props.styles?.body },
        header: {
          padding: "1rem",
          ...props.styles?.header,
        },
      }}
      contentWrapperStyle={{
        maxWidth: "90vw",
        width: "320px",
      }}
      className="header"
      {...props}
    >
      <style jsx>{styles}</style>
      <Menu.AppPages mode="inline" {...menuProps} />
    </Drawer>
  );
}
