import * as React from "react";

import type { IIconProps } from "../@types/globals";
import { cn } from "../lib/utils";

const RotateLeft: React.FC<IIconProps> = ({ className, ...props }) => (
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    className={cn("pointer-events-none w-6 h-6 stroke-2", className)}
    {...props}
  >
    <title>Rotacionar para esquerda</title>
    <path
      d="M8.02252 8.71452L8.46447 8.2621C10.4171 6.26318 13.5829 6.26318 15.5355 8.2621C17.4882 10.261 17.4882 13.5019 15.5355 15.5008C13.5829 17.4997 10.4171 17.4997 8.46447 15.5008C7.32895 14.3384 6.85378 12.7559 7.03894 11.242M8.02252 8.71452H10.6742M8.02252 8.71452V6M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

RotateLeft.displayName = "RotateLeft";

export { RotateLeft };
