import { useCallback, useEffect, useMemo, useState } from "react";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineStop,
  AiOutlineCheckCircle,
} from "react-icons/ai";

import type {
  IComplementaryItemsTableColumn,
  IComplementaryItemsTableData,
  IComplementaryItemsTableDataTableProps,
} from "./interface";
import { StyledTable } from "./styles";

import { Menu } from "~components/Menu";
import { Modal } from "~components/Modal";
import { ValueGain } from "~components/Others";
import { Result } from "~components/Result";
import { ComplementaryItemCategoryType } from "~components/Select";
import { Tag } from "~components/Tag";
import { useFetchUpdateLogisticsDistance } from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { checkIfValueIsNumber } from "~utils/checkIfValueIsNumber";
import { parseCurrency, parseKilometers } from "~utils/parse";
import { Skeleton, Spin, Tooltip, Typography } from "antd";

function categoryTag(
  category?: ComplementaryItemCategoryType,
  minimumValueSetted = false,
) {
  if (minimumValueSetted) {
    return (
      <Tag.UniqueValue
        color="gold"
        title="Valor mínimo"
        tooltip="Este valor possui uma base percentual, porém estamos utilizando o valor mínimo possível para ele."
      />
    );
  }

  switch (category) {
    case "Valor fixo":
      return (
        <Tag.UniqueValue
          color="volcano"
          title="Valor fixo"
          tooltip="Este valor é fixo"
        />
      );
    case "Percentual I.C":
      return (
        <Tag.UniqueValue
          color="magenta"
          title="Percentual I.C"
          tooltip="Valor percentual sobre os itens complementares"
        />
      );
    case "Percentual total":
      return (
        <Tag.UniqueValue
          color="blue"
          title="Percentual Total"
          tooltip="Valor percentual sobre o valor total"
        />
      );
    case "Multiplicador":
      return (
        <Tag.UniqueValue
          color="purple"
          title="Multiplicador"
          tooltip="multiplica um parâmetro do sistema por um valor fixo em reais, para mais detalhes acesse em Configuração > Custos complementares"
        />
      );
    case "Material":
      return (
        <Tag.UniqueValue
          color="green"
          title="Percentual material"
          tooltip="Valor em percentual sobre o material"
        />
      );
    case "Personalizado":
      return (
        <Tag.UniqueValue
          color="orange"
          title="Personalizado"
          tooltip="Expressão personalizada, para mais detalhes acesse em Configuração > Custos complementares"
        />
      );
    default:
      return (
        <Tag.UniqueValue
          color="default"
          title="Desconhecido"
          tooltip="Este valor é um tipo desconhecido"
        />
      );
  }
}

function getUnitByCategory(category?: ComplementaryItemCategoryType) {
  switch (category) {
    case "Valor fixo":
      return "R$";
    case "Percentual total":
      return "%";
    case "Percentual I.C":
      return "%";
    case "Material":
      return "%";
    default:
      return "";
  }
}

export function ComplementaryItemsTable({
  complementaryItems = [],
  isLoading = false,
  isLoadingUpdate = false,
  isLoadingDelete = false,
  onSubmitDelete = () => null,
  onSubmitUpdate = () => null,
  onSelectComplementaryItemToDelete = () => null,
  onSelectComplementaryItemToUpdate = () => null,
  selectedComplementaryItemToDelete,
  selectedComplementaryItemToUpdate,
  displayActionColumn = true,
  displayTaxColumns = true,
  displayPriceColumns = true,
  displayEnableOrDisableItems = true,
  proposalId = undefined,
  onUpdateDistance = () => null,
  onSubmitEnable = () => null,
  displayFooter = true,
  displayEditActionOption = true,
  displayRemoveActionOption = true,
  canUpdateDistance = true,
  displayItemsAutomaticallyAdded = true,
}: IComplementaryItemsTableDataTableProps) {
  const { message } = useAppConfig();

  const SOME_ITENS_CAN_IGNORE_DISPLAY_PRICE_COLUMN = useMemo(
    () => !!complementaryItems.find((item) => item.alwaysBeDisplay === true),
    [complementaryItems],
  );

  const DISPLAY_PRICE_COLUMNS = useMemo(() => {
    return SOME_ITENS_CAN_IGNORE_DISPLAY_PRICE_COLUMN || displayPriceColumns;
  }, [SOME_ITENS_CAN_IGNORE_DISPLAY_PRICE_COLUMN, displayPriceColumns]);

  const middlewareToRenderPriceColumn = useCallback(
    (record: IComplementaryItemsTableData, callbackFn: () => any) => {
      if (displayPriceColumns) {
        return callbackFn();
      }

      if (
        SOME_ITENS_CAN_IGNORE_DISPLAY_PRICE_COLUMN &&
        record.alwaysBeDisplay
      ) {
        return callbackFn();
      }

      return (
        <Tooltip title="Você não pode acessar essa informação">
          <div style={{ cursor: "help" }}>
            <Skeleton.Input size="small" />
          </div>
        </Tooltip>
      );
    },
    [SOME_ITENS_CAN_IGNORE_DISPLAY_PRICE_COLUMN, displayPriceColumns],
  );

  const middlewareToRenderFooterRow = useCallback(
    (
      record: IComplementaryItemsTableData,
      callbackFn: () => any,
      callbackFnWhenFalse = () => "",
    ) => {
      if (record.isFooterValue) {
        return callbackFnWhenFalse();
      }

      return callbackFn();
    },
    [],
  );

  const [complementaryItemToDelete, setComplementaryItemToDelete] = useState<
    IComplementaryItemsTableData | undefined
  >(undefined);

  const [complementaryItemToUpdate, setComplementaryItemToUpdate] = useState<
    IComplementaryItemsTableData | undefined
  >(undefined);

  const [isEditingDistance, setIsEditingDistance] = useState(false);

  const {
    mutate: fetchUpdateLogisticsDistance,
    isLoading: isUpdateLogisticsLoading,
  } = useFetchUpdateLogisticsDistance({
    onSuccess: () => {
      setIsEditingDistance(false);
      onUpdateDistance(proposalId ?? 0);
    },
  });

  function handleUpdateDistance(value: string) {
    fetchUpdateLogisticsDistance({
      proposalId: proposalId ?? 0,
      distanceInKm: Number(value),
    });
    setIsEditingDistance(true);
  }

  function handleSelectKitItemToDelete(
    complementaryItem?: IComplementaryItemsTableData,
  ) {
    setComplementaryItemToDelete(complementaryItem);
    onSelectComplementaryItemToDelete(complementaryItem);
  }

  function handleSelectKitItemToUpdate(
    complementaryItem?: IComplementaryItemsTableData,
  ) {
    setComplementaryItemToUpdate(complementaryItem);
    onSelectComplementaryItemToUpdate(complementaryItem);
  }

  function handleSelectKitItemToEnable(
    complementaryItem?: IComplementaryItemsTableData,
  ) {
    if (complementaryItem) {
      onSubmitEnable(complementaryItem);
    }
  }

  function handleSubmitUpdate(complementaryItem: any) {
    if (!complementaryItemToUpdate) {
      message.error("Client error: faltando o kit selecionado");
      return;
    }

    onSubmitUpdate({
      ...complementaryItem,
      key: complementaryItemToUpdate.key,
    });
  }

  function handleSubmitDelete() {
    if (!complementaryItemToDelete) {
      message.error("Client error: faltando o kit selecionado");
      return;
    }

    onSubmitDelete(complementaryItemToDelete);
  }

  const tableFooterRowData: IComplementaryItemsTableData = useMemo(() => {
    const sumValues = complementaryItems.reduce(
      (
        reduce,
        {
          totalAmount = 0,
          discountTotalAmount = 0,
          taxPaid = 0,
          taxPaidAfterDiscount = 0,
          totalValueWithTax = 0,
        },
      ) => {
        return {
          sumTotalAmount: reduce.sumTotalAmount + totalAmount,
          sumDiscountTotalAmount:
            reduce.sumDiscountTotalAmount + discountTotalAmount,
          sumTaxPaid: reduce.sumTaxPaid + taxPaid,
          sumTaxPaidAfterDiscount:
            reduce.sumTaxPaidAfterDiscount + taxPaidAfterDiscount,
          sumTotalValueWithTax: reduce.sumTotalValueWithTax + totalValueWithTax,
        };
      },
      {
        sumTotalAmount: 0,
        sumDiscountTotalAmount: 0,
        sumTaxPaid: 0,
        sumTaxPaidAfterDiscount: 0,
        sumTotalValueWithTax: 0,
      },
    );

    return {
      key: "footer",
      isFooterValue: true,
      totalAmount: sumValues.sumTotalAmount,
      discountTotalAmount: sumValues.sumDiscountTotalAmount,
      taxPaid: sumValues.sumTaxPaid,
      taxPaidAfterDiscount: sumValues.sumTaxPaidAfterDiscount,
      totalValueWithTax: sumValues.sumTotalValueWithTax,
    };
  }, [complementaryItems]);

  const DATA_SOURCE: IComplementaryItemsTableData[] = useMemo(() => {
    const filteredItems = displayItemsAutomaticallyAdded
      ? complementaryItems
      : complementaryItems.filter((item) => item.manuallyAdded);

    if (filteredItems.length === 0) {
      return [];
    }

    return displayFooter
      ? [...filteredItems, tableFooterRowData]
      : filteredItems;
  }, [
    complementaryItems,
    tableFooterRowData,
    displayFooter,
    displayItemsAutomaticallyAdded,
  ]);

  const COLUMNS: IComplementaryItemsTableColumn[] = (
    [
      {
        title: "Item",
        dataIndex: "name",
        render: (value = "", record) =>
          middlewareToRenderFooterRow(record, () => value),
      },
      {
        title: "Operação",
        dataIndex: "category",
        width: "10rem",
        render: (value: ComplementaryItemCategoryType, record) =>
          middlewareToRenderFooterRow(record, () =>
            middlewareToRenderPriceColumn(record, () => {
              return record.isFooterValue
                ? ""
                : categoryTag(value, record.minimumValueSetted);
            }),
          ),
        align: "center" as const,
        hide: !DISPLAY_PRICE_COLUMNS,
      },
      {
        title: "Base de cálculo",
        dataIndex: "calculationBase",
        width: "10rem",
        render: (value: number, record) =>
          middlewareToRenderFooterRow(record, () =>
            middlewareToRenderPriceColumn(record, () => {
              if (!record.calculationBaseAfterDiscount) {
                return "-";
              }
              return (
                <ValueGain
                  actualValue={record.calculationBaseAfterDiscount}
                  oldValue={value}
                  unit="R$"
                />
              );
            }),
          ),
        align: "center" as const,
        hide: !DISPLAY_PRICE_COLUMNS,
      },
      {
        title: "Quantidade",
        dataIndex: "quantity",
        align: "center" as const,
        width: "8rem",
        render: (
          value: string,
          { name, isFooterValue }: IComplementaryItemsTableData,
        ) => {
          if (isFooterValue) {
            return "";
          }

          const isLogistics = canUpdateDistance && name === "Logística";
          const parsedValue = isLogistics
            ? parseKilometers(Number(value))
            : Number(value);

          return (
            <Typography.Paragraph
              style={{
                fontSize: "0.875rem",
                margin: 0,
                fontWeight: "400",
              }}
              editable={
                isLogistics && {
                  tooltip: "Editar",
                  text: value.toString() ?? "",
                  autoSize: false,
                  onChange: handleUpdateDistance,
                  editing: isEditingDistance,
                  onStart: () => setIsEditingDistance(true),
                  enterIcon: isUpdateLogisticsLoading ? (
                    <Spin size="small" style={{ fontSize: 0 }} />
                  ) : undefined,
                  icon: isUpdateLogisticsLoading ? (
                    <Spin size="small" style={{ fontSize: 0 }} />
                  ) : undefined,
                  onCancel: () => setIsEditingDistance(false),
                  // onEnd: () => setIsEditFieldOpen(false),
                }
              }
            >
              {parsedValue}
            </Typography.Paragraph>
          );
        },
      },
      {
        title: "Valor unitário",
        dataIndex: "unityAmount",
        align: "center" as const,
        width: "10rem",
        hide: !DISPLAY_PRICE_COLUMNS,
        render: (value: number | undefined, record) =>
          middlewareToRenderFooterRow(record, () =>
            middlewareToRenderPriceColumn(record, () => {
              const unit = getUnitByCategory(record.category);

              return (
                <ValueGain
                  actualValue={record.discountAmount}
                  oldValue={value}
                  unit={unit}
                />
              );
            }),
          ),
      },
      {
        title: "Valor total",
        dataIndex: "totalAmount",
        align: "center" as const,
        width: "9rem",
        hide: !DISPLAY_PRICE_COLUMNS,
        render: (value: number | undefined, record) => {
          const parsedValue = Number(value);

          if (record.isFooterValue && !isNaN(parsedValue)) {
            return (
              <ValueGain
                actualValue={record.discountTotalAmount}
                oldValue={parsedValue}
                unit="R$"
              />
            );
          }

          return middlewareToRenderPriceColumn(record, () => {
            const parsedValue = Number(value);

            if (isNaN(parsedValue)) return "";

            return (
              <ValueGain
                actualValue={record.discountTotalAmount}
                oldValue={parsedValue}
                unit="R$"
              />
            );
          });
        },
      },
      {
        title: "Imposto pago",
        dataIndex: "taxPaid",
        width: "10rem",
        render: (value: number, { taxPaidAfterDiscount }) => {
          if (!checkIfValueIsNumber(value)) return "-";
          return (
            <ValueGain
              actualValue={taxPaidAfterDiscount}
              oldValue={value}
              unit="R$"
            />
          );
        },
        align: "center" as const,
        hide: !displayTaxColumns,
      },
      {
        title: "Valor com imposto",
        dataIndex: "totalValueWithTax",
        width: "10rem",
        render: (value: number) =>
          checkIfValueIsNumber(value) ? parseCurrency(value) : "-",
        align: "center" as const,
        hide: !displayTaxColumns,
      },
      {
        title: "",
        align: "center" as const,
        width: "4rem",
        render: (_, complementaryItem) => {
          if (complementaryItem.isFooterValue) return;

          const hideUpdateOption =
            !complementaryItem.manuallyAdded && !displayEditActionOption;

          const hideDeleteOption =
            !complementaryItem.manuallyAdded && !displayRemoveActionOption;

          const hideDisableOption =
            complementaryItem.manuallyAdded || !displayEnableOrDisableItems;

          const isActionMenuDisabled = complementaryItem.manuallyAdded
            ? hideUpdateOption && hideDeleteOption
            : hideDisableOption && !displayEditActionOption;

          return (
            <Menu.MoreDropdown
              data-cy="menu-more"
              items={[
                {
                  icon: (
                    <AiOutlineEdit
                      fontSize={18}
                      style={{
                        color: "var(--gold-500)",
                      }}
                    />
                  ),
                  text: "Alterar",
                  color: "var(--gold-500)",
                  onClick: () => handleSelectKitItemToUpdate(complementaryItem),
                  hidden: hideUpdateOption,
                },
                complementaryItem.deactivated
                  ? {
                      icon: (
                        <AiOutlineCheckCircle
                          fontSize={16}
                          style={{ color: "var(--green-500)" }}
                        />
                      ),
                      text: "Habilitar",
                      color: "var(--green-500)",
                      onClick: () =>
                        handleSelectKitItemToEnable(complementaryItem),
                      hidden: hideDisableOption,
                    }
                  : {
                      icon: complementaryItem.manuallyAdded ? (
                        <AiOutlineDelete
                          fontSize={16}
                          style={{ color: "var(--volcano-500)" }}
                        />
                      ) : (
                        <AiOutlineStop
                          fontSize={16}
                          style={{
                            color:
                              complementaryItem.name === "Desconto"
                                ? "var(--gray-500)"
                                : "var(--volcano-500)",
                          }}
                        />
                      ),
                      text: complementaryItem.manuallyAdded
                        ? "Remover"
                        : "Desabilitar",
                      color:
                        complementaryItem.name === "Desconto"
                          ? "var(--gray-500)"
                          : "var(--volcano-500)",
                      isDisabled: complementaryItem.name === "Desconto",
                      tooltip:
                        complementaryItem.name === "Desconto"
                          ? "O item não pode ser removido"
                          : "",
                      onClick: () =>
                        handleSelectKitItemToDelete(complementaryItem),
                      hidden: complementaryItem.manuallyAdded
                        ? hideDeleteOption
                        : hideDisableOption,
                    },
              ]}
              isDisabled={!complementaryItem.editable || isActionMenuDisabled}
              tooltip={
                complementaryItem.editable ? "" : "Este item não é editável"
              }
              position="bottomLeft"
            />
          );
        },
        hide: !displayActionColumn,
      },
    ] as IComplementaryItemsTableColumn[]
  ).filter((column) => !column.hide);

  useEffect(() => {
    setComplementaryItemToDelete(selectedComplementaryItemToDelete);
  }, [selectedComplementaryItemToDelete]);

  useEffect(() => {
    setComplementaryItemToUpdate(selectedComplementaryItemToUpdate);
  }, [selectedComplementaryItemToUpdate]);

  return (
    <>
      <StyledTable
        data-cy="complementary-items-table"
        columns={COLUMNS}
        dataSource={DATA_SOURCE}
        loading={isLoading}
        rowKey={(record) => record.key}
        pagination={false}
        rowClassName={(record) => {
          return `${record.deactivated ? "disabled" : ""}`;
        }}
        size="small"
        scroll={{
          x: 1024,
        }}
        locale={{
          emptyText: (
            <Result.TableErrorOrEmpty emptyMessage="Nenhum item complementar a ser mostrado" />
          ),
        }}
        displayFooter
      />

      {displayActionColumn && (
        <>
          <Modal.DefaultRemove
            isOpen={!!complementaryItemToDelete}
            itemName={complementaryItemToDelete?.name}
            onClose={() => setComplementaryItemToDelete(undefined)}
            suffix="item complementar"
            title="Remover item complementar"
            onSubmit={() => handleSubmitDelete()}
            isLoading={isLoadingDelete}
          />

          <Modal.ComplementaryItemForm
            isOpen={!!complementaryItemToUpdate}
            onClose={() => setComplementaryItemToUpdate(undefined)}
            title="Alterar item complementar"
            formProps={{
              initialValues: {
                applyTax: !complementaryItemToUpdate?.additionalCostTaxId,
                unityAmount:
                  complementaryItemToUpdate?.category === "Material"
                    ? complementaryItemToUpdate?.totalAmount
                    : complementaryItemToUpdate?.unityAmount,
                additionalCostTaxId:
                  complementaryItemToUpdate?.additionalCostTaxId,
                category: complementaryItemToUpdate?.category,
                costAggregatorIds: complementaryItemToUpdate?.costAggregatorIds,
                name: complementaryItemToUpdate?.name,
                notApplyDefaultAdditionalCostTax:
                  Number(
                    complementaryItemToUpdate?.costAggregatorIds?.length,
                  ) <= 0,
                quantity: complementaryItemToUpdate?.quantity,
              },
              onSubmit: (kitItem) => handleSubmitUpdate(kitItem ?? {}),
              isLoading: isLoadingUpdate,
              onlyUpdatePriceAndQuantity:
                !complementaryItemToUpdate?.manuallyAdded,
              // canUpdateExtraFields: complementaryItemToUpdate?.manuallyAdded,
              canUpdateExtraFields: true, // Changed by Edvaldo - Requested by Samuel in 19/07/2024
              isEditForm: true,
            }}
          />
        </>
      )}
    </>
  );
}
