import { useEffect, useMemo, useState } from "react";
import { FaCheck, FaMinus } from "react-icons/fa";

import {
  type IPermissionsMultiTableColumnType,
  type IPermissionsMultiTableProps,
  IPermissionsMultiTableDataSourceType,
} from "./interface";
import { StyledFlex } from "./styles";

import { Result } from "~components/Result";
import { Select } from "~components/Select";
import { useFetchGetAllPermissions } from "~hooks/api";
import type { PermissionsEnum } from "~types/enum";
import { IPermissionValueSchema } from "~types/schemas";
import { createUuid } from "~utils/createUuid";
import { Flex, Skeleton, Table } from "antd";

function getTableDataSourceByPermissionValue(
  permissions: IPermissionValueSchema[],
  defaultSelectedRows: PermissionsEnum[] = [],
): IPermissionsMultiTableDataSourceType[] {
  return permissions.map((permission) => ({
    key: permission.value ?? createUuid(),
    permission: permission.description,
    value: permission.value,
    active: permission.value
      ? defaultSelectedRows.includes(permission.value)
      : false,
  }));
}

export function PermissionsMultiTable({
  size = "small",
  defaultSelectedRows = [],
  selectedRows = [],
  onChangeSelectedRows,
  isEditing,
  isLoading = false,
  preventsClearSelectedRowsOnCancel = false,
  userGroupId,
  onChangeGroupId,
  defaultUserGroupId,
}: IPermissionsMultiTableProps) {
  const [isEditingPermissions, setIsEditingPermissions] =
    useState<boolean>(false);

  useEffect(() => {
    if (typeof isEditing !== "undefined") {
      setIsEditingPermissions(isEditing);
    }
  }, [isEditing]);

  const [currentSelectedRows, setCurrentSelectedRows] =
    useState<PermissionsEnum[]>(defaultSelectedRows);

  useEffect(() => {
    setCurrentSelectedRows(selectedRows);
  }, [selectedRows]);

  const [groupId, setGroupId] = useState<number | undefined>(
    defaultUserGroupId,
  );

  useEffect(() => {
    setGroupId(userGroupId);
  }, [userGroupId]);

  // CLEAR SELECTED ROWS
  useEffect(() => {
    if (!isEditingPermissions && !preventsClearSelectedRowsOnCancel) {
      setCurrentSelectedRows(defaultSelectedRows); // CLEAR ROWS TO INITIAL STATE
      setGroupId(defaultUserGroupId); // CLEAR GROUP ID

      if (typeof onChangeSelectedRows === "function") {
        onChangeSelectedRows(defaultSelectedRows);
      }

      if (typeof onChangeGroupId === "function") {
        onChangeGroupId(defaultUserGroupId);
      }
    }
  }, [
    isEditingPermissions,
    preventsClearSelectedRowsOnCancel,
    defaultSelectedRows,
    onChangeSelectedRows,
    onChangeGroupId,
    defaultUserGroupId,
  ]);

  const {
    data: permissions = [],
    isFetching: isLoadingPermissions,
    error: permissionsError,
    refetch: refetchPermissions,
  } = useFetchGetAllPermissions({
    options: {
      retry: 1,
    },
  });

  const TABLES = useMemo(
    () =>
      permissions.map((permission) => {
        const DATA_SOURCE = getTableDataSourceByPermissionValue(
          permission.permissions,
          defaultSelectedRows,
        ) as IPermissionsMultiTableDataSourceType[];

        const COLUMNS = [
          {
            title: "",
            dataIndex: "active",
            hidden: isEditingPermissions,
            render: (_, record) =>
              record.active ? (
                <FaCheck style={{ color: "var(--green-500)" }} />
              ) : (
                <FaMinus style={{ color: "var(--red-500)" }} />
              ),
          },
          {
            dataIndex: "permission",
            title: () => (
              <Flex justify="space-between" align="center">
                <span>{permission.description}</span>
                <span>
                  {
                    DATA_SOURCE.filter((data) =>
                      data.value
                        ? currentSelectedRows.includes(data.value)
                        : false,
                    ).length
                  }
                  /{DATA_SOURCE.length}
                </span>
              </Flex>
            ),
            render: (_, record) => {
              if (
                record.value === "ACCESS_GROUP_PROPOSALS" &&
                currentSelectedRows.includes(record.value)
              ) {
                return (
                  <Flex vertical>
                    <span>{record.permission}</span>
                    <Select.UserGroup
                      value={groupId}
                      style={{ width: "100%" }}
                      disabled={!isEditingPermissions}
                      onChange={(_, option) => {
                        const updatedGroupId = option.record.id;

                        setGroupId(updatedGroupId);

                        if (typeof onChangeGroupId === "function") {
                          onChangeGroupId(updatedGroupId);
                        }
                      }}
                    />
                  </Flex>
                );
              }

              return record.permission;
            },
          },
        ] as IPermissionsMultiTableColumnType[];

        return {
          SECTION: permission.section,
          DESCRIPTION: permission.description,
          DATA_SOURCE,
          COLUMNS: COLUMNS.filter((column) => !column.hidden),
        };
      }),
    [
      permissions,
      defaultSelectedRows,
      isEditingPermissions,
      currentSelectedRows,
      groupId,
      onChangeGroupId,
    ],
  );

  if (isLoadingPermissions || isLoading) {
    return new Array(4).fill(null).map((_, index) => (
      <div key={index}>
        <Skeleton.Input block active style={{ margin: "1rem 0" }} />
        <Flex vertical gap={12}>
          <Skeleton.Input
            active
            style={{ height: 24, width: "auto", minWidth: 260 }}
          />
          <Skeleton.Input
            active
            style={{ height: 24, width: "auto", minWidth: 260 }}
          />
          <Skeleton.Input
            active
            style={{ height: 24, width: "auto", minWidth: 260 }}
          />
        </Flex>
      </div>
    ));
  }

  return (
    <StyledFlex vertical data-cy="permissions-multi-table">
      {TABLES.map((TABLE) => {
        return (
          <Table
            key={TABLE.SECTION}
            pagination={false}
            loading={isLoadingPermissions}
            style={{ width: "100%" }}
            rowKey="key"
            dataSource={TABLE.DATA_SOURCE}
            columns={TABLE.COLUMNS}
            rowSelection={
              isEditingPermissions
                ? {
                  selectedRowKeys: selectedRows,
                  onSelect(record, selected) {
                    const updatedCurrentSelectedRows =
                      selected && record.value
                        ? [...currentSelectedRows, record.value]
                        : currentSelectedRows.filter(
                          (row) => row !== record.value,
                        );

                    setCurrentSelectedRows(updatedCurrentSelectedRows);

                    if (typeof onChangeSelectedRows === "function") {
                      onChangeSelectedRows(updatedCurrentSelectedRows);
                    }
                  },
                  onChange(selectedRowKeys, _selectedRows, info) {
                    if (info.type !== "all") return;

                    const sectionPermissionKeys = TABLE.DATA_SOURCE.map(
                      (data) => data.value,
                    );

                    const updatedCurrentSelectedRows =
                      selectedRowKeys.length === 0
                        ? currentSelectedRows.filter(
                          (row) => !sectionPermissionKeys.includes(row),
                        )
                        : ([
                          ...new Set([
                            ...currentSelectedRows,
                            ...sectionPermissionKeys.filter(
                              (item) => item !== undefined,
                            ),
                          ]),
                        ] as PermissionsEnum[]);

                    setCurrentSelectedRows(updatedCurrentSelectedRows);

                    if (typeof onChangeSelectedRows === "function") {
                      onChangeSelectedRows(updatedCurrentSelectedRows);
                    }
                  },
                }
                : undefined
            }
            locale={{
              emptyText: (
                <Result.TableErrorOrEmpty
                  errorMessage={permissionsError?.message}
                  emptyMessage="Nenhuma permissão foi encontrada."
                  loading={isLoadingPermissions}
                  reloadFn={() => refetchPermissions()}
                />
              ),
            }}
            size={size}
          />
        );
      })}
    </StyledFlex>
  );
}
