import { BiErrorCircle } from "react-icons/bi";

import type {
  IPipedriveCustomFieldSelectProps,
  PipedriveCustomFieldSelectOptionType,
} from "./interface";

import { useFetchGetPipedriveCustomFields } from "~hooks/api";
import { useSession } from "~hooks/useSession";
import { Button, Select, Spin, Tooltip } from "antd";

export function PipedriveCustomFieldSelect({
  allowClear = true,
  onChange,
  ...props
}: IPipedriveCustomFieldSelectProps) {
  const { token } = useSession();

  const {
    data: customFields,
    isFetching,
    error,
    refetch,
  } = useFetchGetPipedriveCustomFields({
    dependencyArray: [token],
  });

  const options: PipedriveCustomFieldSelectOptionType[] =
    customFields?.map((field) => ({
      label: field.name,
      value: field.key,
      record: field,
    })) ?? [];

  return (
    <Select
      {...props}
      loading={isFetching}
      options={options}
      allowClear={allowClear}
      status={error ? "error" : ""}
      suffixIcon={
        <>
          {error && (
            <Tooltip title={error.message + " | Clique para recarregar."}>
              <Button type="link" loading={isFetching}>
                <BiErrorCircle size={18} onClick={() => refetch()} />
              </Button>
            </Tooltip>
          )}
          {isFetching && <Spin size="small" spinning />}
        </>
      }
      onChange={(value, option) => {
        if (option instanceof Array || !onChange) return;
        onChange(value, option);
      }}
    />
  );
}
