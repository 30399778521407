import { CSSProperties, ReactNode, useEffect, useMemo, useState } from "react";
import { IoAtOutline, IoWarningOutline } from "react-icons/io5";

import Image from "next/image";

import type { ILoggedUserAvatarProps } from "./interface";

import { api } from "~services/api";
import { getAvatarColorByIdAndName } from "~utils/getAvatarColorByIdAndName";
import { getTwoFirstLatterOfFullName } from "~utils/getTwoFirstLatterOfFullName";
import { Avatar, Badge, Skeleton } from "antd";

export function CustomUserAvatar({
  badgeProps,
  name,
  userId,
  avatarUrl,
  sessionStatus = "unauthenticated",
  isLoading = false,
  width = 32,
  height = 32,
  quality = 80,
  style,
  customAvatarColor,
  ...props
}: ILoggedUserAvatarProps) {
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  const domain = api.getUri();

  const avatarName = name ?? "SolarZ User";

  const avatarLetters = getTwoFirstLatterOfFullName(avatarName ?? "")
    .join("")
    .toUpperCase();

  const avatar = useMemo(() => {
    return avatarUrl
      ? avatarUrl.startsWith("public")
        ? `${domain}/${avatarUrl}`
        : avatarUrl
      : undefined;
  }, [avatarUrl, domain]);

  const avatarColor = useMemo(() => {
    return avatarUrl
      ? "var(--background-container)"
      : getAvatarColorByIdAndName(userId ?? 1, name ?? "");
  }, [avatarUrl, name, userId]);

  useEffect(() => {
    if (!avatar) {
      setImageSrc(null);
      return;
    }

    setImageSrc(avatar);
  }, [avatar]);

  if (sessionStatus === "loading" || isLoading) {
    return (
      <Badge {...badgeProps}>
        <Skeleton.Avatar
          active
          shape="circle"
          data-cy="loading-avatar"
          style={{
            height: height,
            width: width,
            ...style,
          }}
        />
      </Badge>
    );
  }

  const badgeIcon = (): ReactNode => {
    if (!badgeProps?.icon || badgeProps?.showBadge) return null;
    const iconStyle: CSSProperties = {
      backgroundColor: "var(--white)",
      padding: 1,
      borderRadius: "100%",
      fontSize: 18,
      color: "var(--primary-400)",
    };
    switch (badgeProps?.icon) {
      case "WARNING":
        return <IoWarningOutline style={iconStyle} />;
      case "MENTION":
        return <IoAtOutline style={iconStyle} />;
      default:
        return null;
    }
  };

  return (
    <Badge count={badgeIcon()} {...badgeProps}>
      {imageSrc ? (
        <Image
          src={imageSrc}
          layout="fixed"
          width={width}
          height={height}
          quality={quality}
          sizes="
              (max-width: 480px) 32px,
              (max-width: 768px) 55px,
              (max-width: 1024px) 64px,
              128px
            "
          alt="SolarZ User"
          style={{
            borderRadius: "50%",
            objectFit: "cover",
            width: width,
            ...style,
          }}
        />
      ) : (
        <Avatar
          data-cy="custom-user-avatar"
          shape="circle"
          style={{
            height: height,
            width: width,
            color: "var(--white)",
            background: customAvatarColor || avatarColor,
            ...style,
          }}
          {...props}
        >
          {avatarLetters}
        </Avatar>
      )}
    </Badge>
  );
}
