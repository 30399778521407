import { z } from "zod";

export const PipelineDealSchema = z.object({
  id: z.number().int().gt(0),
  creator_user_id: z.number().nullish(),
  person_id: z.number().nullish(),
  org_id: z.number().nullish(),
  title: z.string(),
  value: z.number(),
  status: z.string().nullish(),
  expected_close_date: z.string().nullish(),
});

export interface IPipelineDealSchema
  extends z.infer<typeof PipelineDealSchema> {}
