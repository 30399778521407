import { ITableErrorOrEmptyResultProps } from "./interface";

import { Button, Empty, Flex, Result, Spin, Typography } from "antd";

export function TableErrorOrEmptyResult({
  errorMessage,
  emptyMessage,
  errorTitle = "Ops!",
  loading = false,
  reloadFn,
  disableReload = false,
}: ITableErrorOrEmptyResultProps) {
  return (
    <Flex justify="center" align="center" data-cy="in-development-result">
      {errorMessage ? (
        <Result
          title={errorTitle}
          status="error"
          subTitle={
            <Flex vertical>
              <Typography.Text
                type={errorMessage ? "danger" : "secondary"}
                style={{ margin: 0 }}
              >
                {!loading && (errorMessage ?? emptyMessage)}
              </Typography.Text>
              {reloadFn && (
                <Button
                  type="link"
                  size="small"
                  style={{ margin: 0, padding: 0, marginBottom: "0.5rem" }}
                  onClick={reloadFn}
                  disabled={loading || disableReload}
                >
                  {loading ? (
                    <Flex align="center" gap={8}>
                      <Spin size="small" /> carregando...
                    </Flex>
                  ) : (
                    "Carregar novamente?"
                  )}
                </Button>
              )}
            </Flex>
          }
        />
      ) : (
        <Empty description={emptyMessage} />
      )}
    </Flex>
  );
}
