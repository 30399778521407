import type { IPipedrivePipelineSelectProps } from "./interface";

import { useFetchGetPipedrivePipelines } from "~hooks/api";
import { useSession } from "~hooks/useSession";
import { SolarzSelect } from "~solarzui/SolarzSelect";

export function PipedrivePipelineSelect({
  allowClear = true,
  onLoad = () => undefined,
  ...props
}: IPipedrivePipelineSelectProps) {
  const { token, user } = useSession();

  const {
    data: pipelines,
    isFetching: isLoadingPipelines,
    error: pipelinesError,
    refetch: refetchPipelines,
  } = useFetchGetPipedrivePipelines({
    dependencyArray: [token],
    options: {
      onSuccess: onLoad,
      enabled: !!user?.pipedriveIntegration,
    },
  });

  return (
    <SolarzSelect
      {...props}
      isLoading={isLoadingPipelines}
      options={pipelines?.data?.map((item) => {
        return {
          label: item.name,
          record: item,
          value: item.id,
        };
      })}
      allowClear={allowClear}
      errorMessage={pipelinesError?.message}
      emptyMessage="Nenhum Pipeline encontrado"
      refetchFn={refetchPipelines}
    />
  );
}
