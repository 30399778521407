import styled from "@emotion/styled";
import { Card, Collapse } from "antd";

export const StyledCard = styled(Card)`
  &.ant-card {
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    gap: 12px;
    flex: none;
    flex-grow: 0;

    .ant-card-body {
      padding: 0;
    }
  }
`;

export const StyledCollapse = styled(Collapse)`
  border: none;
  width: 100%;
  background: var(--background);

  .ant-collapse-content-box {
    padding: 16px 16px 0 16px !important;
  }
`;
