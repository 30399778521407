import { useState } from "react";

import type { IDealCustomFieldGroupSelectProps } from "./interface";

import { Modal } from "~components/Modal";
import { Add } from "~estrela-ui/icons";
import { useFetchGetDealCustomFieldsGroupList } from "~hooks/api";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzSelect } from "~solarzui/SolarzSelect";
import { Divider, Flex } from "antd";

function createMapFromIdsArray(ids: number[]) {
  return ids.reduce(
    (map, id) => {
      return { ...map, [id]: id };
    },
    {} as Record<string, number>,
  );
}

export function DealCustomFieldGroupSelect({
  disabledOptions = [],
  hiddenOptions = [],
  onCreateSuccess,
  onCreateError,
  canCreateNewCustomFieldGroup = true,
  ...props
}: IDealCustomFieldGroupSelectProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    data: dealGroups = [],
    isFetching: isLoadingDealGroups,
    error: errorDealGroups,
    refetch: reloadDealGroups,
  } = useFetchGetDealCustomFieldsGroupList({
    dependencyArray: [],
    options: {
      retry: 1,
    },
  });

  const disabledOptionsMap = createMapFromIdsArray(disabledOptions);
  const hiddenOptionsMap = createMapFromIdsArray(hiddenOptions);

  function handleOpenModal() {
    setIsModalOpen(true);
  }

  function handleCloseModal() {
    setIsModalOpen(false);
  }

  return (
    <>
      <SolarzSelect
        {...props}
        options={dealGroups.map((dealGroup) => {
          return {
            label: dealGroup.name,
            value: dealGroup.id,
            record: dealGroup,
            disabled: !!disabledOptionsMap?.[dealGroup.id],
            hidden: !!hiddenOptionsMap?.[dealGroup.id],
          };
        })}
        isLoading={isLoadingDealGroups}
        errorMessage={errorDealGroups?.message}
        refetchFn={reloadDealGroups}
        dropdownRender={(menu) => {
          return (
            <Flex vertical>
              {menu}{" "}
              {canCreateNewCustomFieldGroup && (
                <Flex vertical style={{ margin: "8px 16px 0 16px" }}>
                  <Divider style={{ margin: 0 }} />
                  <SolarzButton
                    variant="ghost"
                    style={{ margin: "8px 0 4px 0", textDecoration: "none" }}
                    onClick={handleOpenModal}
                  >
                    <Add />
                    Nova sessão
                  </SolarzButton>
                </Flex>
              )}
            </Flex>
          );
        }}
      />

      <Modal.DealCustomFieldGroupForm
        onClose={handleCloseModal}
        isOpen={isModalOpen}
        title="Criar novo grupo"
        formProps={{
          onSuccess: (group) => {
            handleCloseModal();

            if (onCreateSuccess) {
              onCreateSuccess(group);
            }
          },
          onError: onCreateError,
        }}
      />
    </>
  );
}
