import { useState } from "react";

import type { IGTSolarIntegrationButton } from "./interface";

import { Modal } from "~components/Modal";
import { Button } from "antd";

export function GTSolarIntegrationButton({
  integratorId = 0,
  children,
  ...props
}: IGTSolarIntegrationButton) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button
        {...props}
        data-cy="gt-solar-integration-button"
        onClick={() => setIsModalOpen(true)}
        type="primary"
        htmlType="button"
      >
        {children ?? "Integrar com GKSolar"}
      </Button>

      <Modal.GTSolarIntegrationForm
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        formProps={{
          initialValues: {
            integratorId,
          },
          onSuccess: () => setIsModalOpen(false),
        }}
      />
    </>
  );
}
