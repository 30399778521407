import { useMemo, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { BiSelectMultiple } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";

import type { ISimultaneityProfilesListProps } from "./interface";

import { Menu } from "~components/Menu";
import { Modal } from "~components/Modal";
import { createPaginationTableObject } from "~components/Pagination";
import { Result } from "~components/Result";
import { Tag } from "~components/Tag";
import {
  useFetchDeleteSimultaneityProfile,
  useFetchGetPaginatedSimultaneityProfiles,
  useFetchSetDefaultSimultaneityProfile,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import type { ISimultaneityProfileDTOSchema } from "~types/schemas";
import { Flex, List } from "antd";

export function SimultaneityProfilesList({
  defaultCurrentPage = 1,
  ...props
}: ISimultaneityProfilesListProps) {
  const { screens } = useAppConfig();

  const [
    selectedSimultaneityProfileToUpdate,
    setSelectedSimultaneityProfileToUpdate,
  ] = useState<ISimultaneityProfileDTOSchema>();

  const [simultaneityProfileToDelete, setSimultaneityProfileToDelete] =
    useState<ISimultaneityProfileDTOSchema>();

  const [
    simultaneityProfileToSetAsDefault,
    setSimultaneityProfileToSetAsDefault,
  ] = useState<ISimultaneityProfileDTOSchema>();

  const [currentPage, setCurrentPage] = useState<number>(defaultCurrentPage);

  function onChangePagination(number: number, _totalElements?: number) {
    setCurrentPage(number);
  }

  const {
    data: simultaneityProfiles,
    isFetching,
    error,
  } = useFetchGetPaginatedSimultaneityProfiles({
    payload: {
      page: currentPage - 1,
      size: 5,
    },
    dependencyArray: [currentPage],
    options: {
      enabled: currentPage > 0,
    },
  });

  const { mutate: handleDeleteProfile, isLoading: isDeletingProfile } =
    useFetchDeleteSimultaneityProfile({
      onSuccess: () => {
        setSimultaneityProfileToDelete(undefined);
      },
    });

  const {
    mutate: handleSetDefaultProfile,
    isLoading: isSettingDefaultProfile,
  } = useFetchSetDefaultSimultaneityProfile({
    options: {
      onSuccess: () => {
        setSimultaneityProfileToSetAsDefault(undefined);
      },
    },
  });

  const DATA_SOURCE = useMemo(
    () => simultaneityProfiles?.content ?? [],
    [simultaneityProfiles?.content],
  );

  return (
    <>
      <List
        data-cy="simultaneity-profiles-list"
        {...props}
        itemLayout="horizontal"
        dataSource={DATA_SOURCE}
        loading={isFetching}
        rowKey="id"
        pagination={createPaginationTableObject({
          number: currentPage,
          size: simultaneityProfiles?.size,
          totalElements: simultaneityProfiles?.totalElements,
          onChange: onChangePagination,
          disabled: false,
          itemType: "perfis",
          displayTotalInformation: screens.sm,
        })}
        locale={{
          emptyText: (
            <Result.TableErrorOrEmpty
              emptyMessage="Nenhum perfil de simultaneidade encontrado"
              errorMessage={error?.message}
            />
          ),
        }}
        renderItem={(profile) => (
          <List.Item key={profile.id}>
            <Flex
              align="center"
              justify="space-between"
              style={{ width: "100%", paddingRight: "0.5rem" }}
            >
              <List.Item.Meta
                title={
                  <Flex vertical>
                    <span>{profile.description}</span>{" "}
                    {profile.default && (
                      <Tag.UniqueValue
                        tooltip="Este item é o perfil de simultaneidade padrão"
                        title="Padrão"
                        color="green"
                        style={{ width: "min-content" }}
                      />
                    )}
                  </Flex>
                }
                description={`Valor: ${profile.value}`}
              />
              <List.Item.Meta
                title={profile.description}
                description={`Taxa de simultaneidade: ${profile.value} %`}
              />
              <Menu.MoreDropdown
                items={[
                  {
                    icon: (
                      <FaEdit size={16} style={{ color: "var(--gold-500)" }} />
                    ),
                    text: "Alterar",
                    color: "var(--gold-500)",
                    onClick: () =>
                      setSelectedSimultaneityProfileToUpdate(profile),
                  },

                  {
                    icon: (
                      <AiOutlineDelete
                        size={16}
                        style={{ color: "var(--red-500)" }}
                      />
                    ),
                    text: "Remover",
                    color: "var(--red-500)",
                    onClick: () => setSimultaneityProfileToDelete(profile),
                  },
                  {
                    icon: (
                      <BiSelectMultiple
                        fontSize={16}
                        style={{ color: "var(--blue-500)" }}
                      />
                    ),
                    text: "Definir como padrão",
                    color: "var(--blue-500)",
                    onClick: () =>
                      setSimultaneityProfileToSetAsDefault(profile),
                    isDisabled: !!profile.default,
                    tooltip: profile.default
                      ? "Esta simultaneidade já é o padrão"
                      : "",
                  },
                ]}
                position="bottomLeft"
                icon="cog"
              />
            </Flex>
          </List.Item>
        )}
      />

      <Modal.DefaultRemove
        isOpen={!!simultaneityProfileToDelete}
        itemName={simultaneityProfileToDelete?.description}
        onClose={() => setSimultaneityProfileToDelete(undefined)}
        suffix="perfil de simultaneidade"
        onSubmit={() =>
          handleDeleteProfile({
            simultaneityProfileId: simultaneityProfileToDelete?.id || 0,
          })
        }
        title="Remover perfil de simultaneidade"
        isLoading={isDeletingProfile}
      />

      <Modal.DefaultRemove
        isOpen={!!simultaneityProfileToSetAsDefault}
        itemName={simultaneityProfileToSetAsDefault?.description}
        onClose={() => setSimultaneityProfileToSetAsDefault(undefined)}
        suffix="perfil de simultaneidade"
        onSubmit={() =>
          handleSetDefaultProfile({
            simultaneityProfileId: simultaneityProfileToSetAsDefault?.id || 0,
          })
        }
        title="Definir perfil de simultaneidade como padrão"
        isLoading={isSettingDefaultProfile}
      />

      <Modal.SimultaneityProfileForm
        isOpen={!!selectedSimultaneityProfileToUpdate}
        onClose={() => setSelectedSimultaneityProfileToUpdate(undefined)}
        formProps={{
          initialValues: selectedSimultaneityProfileToUpdate,
          onSuccess: () => setSelectedSimultaneityProfileToUpdate(undefined),
        }}
      />
    </>
  );
}
