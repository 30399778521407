import { useState } from "react";

import type { ICreateProfileButtonProps } from "./interface";

import { Drawer } from "~components/Drawer";
import { Modal } from "~components/Modal";
import { SolarzButton } from "~solarzui/SolarzButton";

export function CreateProfileButton({
  children,
  formProps = {},
  modalProps = {},
  onSuccessOpenDrawer = false,
  ...props
}: ICreateProfileButtonProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [createdProfileId, setCreatedProfileId] = useState<number>();

  return (
    <>
      <SolarzButton
        {...props}
        data-cy="create-profile-button"
        onClick={() => setIsModalOpen(true)}
        variant="primary"
        htmlType="button"
      >
        {children ?? "Criar perfil"}
      </SolarzButton>

      <Modal.ProfileForm
        {...modalProps}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        formProps={{
          ...formProps,
          onSuccess: (profile) => {
            if (typeof formProps.onSuccess === "function") {
              formProps.onSuccess(profile);
            }

            setIsModalOpen(false);

            if (onSuccessOpenDrawer) {
              setCreatedProfileId(profile.id);
            }
          },
        }}
      />

      {onSuccessOpenDrawer && (
        <Drawer.Permission
          profileId={createdProfileId ?? undefined}
          isOpen={!!createdProfileId}
          onClose={() => {
            setCreatedProfileId(undefined);
          }}
        />
      )}
    </>
  );
}
