import { BiErrorCircle } from "react-icons/bi";

import type { IProfileSelectProps } from "./interface";

import { useFetchGetPaginatedProfiles } from "~hooks/api";
import { Button, Select, Tooltip } from "antd";

export function ProfileSelect({
  allowClear = true,
  onChange,
  ...props
}: IProfileSelectProps) {
  const {
    data: profiles,
    isFetching,
    error,
    refetch,
  } = useFetchGetPaginatedProfiles({
    dependencyArray: [],
    payload: {
      page: 0,
      size: 1000,
    },
  });

  const options = profiles
    ? profiles.content?.map((profile) => ({
      label: profile.name,
      value: profile.id,
      record: profile,
    }))
    : [];

  return (
    <Select
      {...props}
      data-cy="profile-select"
      loading={isFetching}
      options={options}
      allowClear={allowClear}
      status={error ? "error" : ""}
      suffixIcon={
        error ? (
          <Tooltip title="Recarregar dados">
            <Button type="link" loading={isFetching}>
              <BiErrorCircle size={18} onClick={() => refetch()} />
            </Button>
          </Tooltip>
        ) : undefined
      }
      onChange={(value, option) => {
        if (option instanceof Array || !onChange) return;
        onChange(value, option);
      }}
      style={{ width: "100%", height: 48 }}
    />
  );
}
