import type { IUpdateUserBusinessUnitSwitchProps } from "./interface";
import styles from "./styles";

import {
  useFetchAssignBusinessUnitToUser,
  useFetchDeleteBusinessUnitFromUser,
} from "~hooks/api";
import { Flex, Switch } from "antd";

export function UpdateUserBusinessUnitSwitch({
  businessUnitId = 0,
  businessUnitName = "",
  userId = 0,
  isChecked = false,
}: IUpdateUserBusinessUnitSwitchProps) {
  const { mutate: assignBusinessUnitToUser, isLoading: isAssociating } =
    useFetchAssignBusinessUnitToUser();

  const { mutate: unassignBusinessUnitToUser, isLoading: isDisassociating } =
    useFetchDeleteBusinessUnitFromUser();

  function handleClickOnSwitch() {
    if (isChecked) {
      unassignBusinessUnitToUser({
        userId,
        businessUnitId,
      });
    } else {
      assignBusinessUnitToUser({
        userId,
        businessUnitId,
      });
    }
  }

  const isLoading = isAssociating || isDisassociating;

  return (
    <Flex data-cy="update-user-business-unit-switch" align="center" gap={8}>
      <style jsx>{styles}</style>
      <Switch
        id={businessUnitId.toString()}
        title={businessUnitName}
        checked={isChecked}
        loading={isLoading}
        onClick={handleClickOnSwitch}
        checkedChildren="ativo"
        unCheckedChildren="inativo"
        className="input"
      />
      <label htmlFor={businessUnitName} className="label">
        {businessUnitName}
      </label>
    </Flex>
  );
}
