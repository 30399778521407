import type { IChangeUserBusinessUnitFormModalProps } from "./interface";

import { Result } from "~components/Result";
import { Switch } from "~components/Switch";
import {
  useFetchGetCompanyUserBusinessUnits,
  useFetchGetUserOutBusinessUnit,
} from "~hooks/api";
import { Flex, Modal, Spin } from "antd";

export function ChangeUserBusinessUnitFormModal({
  isOpen = false,
  onClose = () => null,
  userId = 0,
  title = "Associar unidade de negócio",
  maxWidth = 512,
}: IChangeUserBusinessUnitFormModalProps) {
  const {
    data: businessUnits,
    isFetching: isLoadingBusinessUnits,
    error: businessUnitsError,
  } = useFetchGetCompanyUserBusinessUnits({});

  const {
    data: notAssignedBusinessUnits,
    isFetching: isLoadingNotAssignedBusinessUnits,
    error: notAssignedBusinessUnitsError,
  } = useFetchGetUserOutBusinessUnit({
    payload: {
      userId,
    },
    options: {
      enabled: userId > 0,
    },
  });

  const activeBusinessUnits =
    businessUnits?.filter((businessUnit) => businessUnit.active) ?? [];

  const parsedBusinessUnits = activeBusinessUnits.map((businessUnit) => {
    return {
      ...businessUnit,
      active: !notAssignedBusinessUnits?.find(
        (item) => item.id === businessUnit.id,
      ),
    };
  });

  const errorMessage =
    userId <= 0
      ? "Client error: Faltando o ID do usuário"
      : businessUnitsError?.message || notAssignedBusinessUnitsError?.message;

  const isLoading = isLoadingBusinessUnits || isLoadingNotAssignedBusinessUnits;

  return (
    <Modal
      data-cy="change-user-business-unit-form-modal"
      open={isOpen}
      onCancel={onClose}
      title={title}
      width="90vw"
      footer={null}
      style={{ maxWidth }}
      styles={{
        body: { maxHeight: "calc(75vh)", overflowY: "scroll" },
      }}
      destroyOnClose
    >
      <Spin spinning={isLoading}>
        {errorMessage || parsedBusinessUnits.length === 0 ? (
          <Flex justify="center" align="center">
            <Result.TableErrorOrEmpty
              emptyMessage="Nenhuma unidade de negócio encontrada"
              errorMessage={errorMessage}
            />
          </Flex>
        ) : (
          <Flex vertical gap={8}>
            {parsedBusinessUnits?.map((businessUnit) => (
              <Switch.UpdateUserBusinessUnit
                key={businessUnit.id}
                businessUnitId={businessUnit.id}
                businessUnitName={businessUnit.name}
                isChecked={businessUnit.active}
                userId={userId}
              />
            ))}
          </Flex>
        )}
      </Spin>
    </Modal>
  );
}
