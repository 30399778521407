import type { IProfileFormProps, IProfileFormValues } from "./interface";

import { Select } from "~components/Select";
import { useFetchCreateProfile, useFetchUpdateProfile } from "~hooks/api";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzFormItem } from "~solarzui/SolarzFormItem";
import { SolarzInput } from "~solarzui/SolarzInput";
import { Form } from "antd";

export function ProfileForm({
  initialValues = {
    id: undefined,
    name: "",
    permissions: [],
    userGroupId: undefined,
    description: "",
  },
  onCancel = () => undefined,
  onError = () => undefined,
  onSuccess = () => undefined,
}: IProfileFormProps) {
  const [form] = Form.useForm();

  const { mutate: createProfile, isLoading: isCreatingProfile } =
    useFetchCreateProfile({
      options: {
        onSuccess,
        onError,
      },
    });

  const { mutate: updateProfile, isLoading: isUpdatingProfile } =
    useFetchUpdateProfile({
      options: {
        onSuccess,
        onError,
      },
    });

  function handleSubmitForm(formValues: IProfileFormValues) {
    if (initialValues.id) {
      updateProfile({
        ...formValues,
        profileId: initialValues.id,
      });
    } else {
      createProfile({
        ...formValues,
        permissions: ["CREATE_DEFAULT_PROPOSAL"],
      });
    }
  }

  const isLoading = isCreatingProfile || isUpdatingProfile;

  return (
    <Form
      form={form}
      data-cy="profile-form"
      onFinish={handleSubmitForm}
      initialValues={initialValues}
      layout="vertical"
      style={{ width: "100%" }}
    >
      <SolarzFormItem<IProfileFormValues>
        name="name"
        label="Nome do perfil"
        rules={[{ required: true }]}
      >
        <SolarzInput placeholder="Ex.: Nome do perfil..." scale="large" />
      </SolarzFormItem>

      <SolarzFormItem<IProfileFormValues>
        name="description"
        label="Descrição"
        rules={[{ required: false }]}
      >
        <SolarzInput placeholder="Ex.: Descrição..." scale="large" />
      </SolarzFormItem>

      <SolarzFormItem<IProfileFormValues>
        name="users"
        label="Usuários"
        rules={[{ required: false }]}
      >
        <Select.User
          mode="tags"
          scale="large"
          placeholder="Selecione os usuários"
        />
      </SolarzFormItem>

      <div className="flex flex-row gap-3 align-middle justify-end">
        <SolarzButton variant="secondary" onClick={onCancel}>
          Cancelar
        </SolarzButton>
        <SolarzButton variant="primary" htmlType="submit" isLoading={isLoading}>
          Salvar
        </SolarzButton>
      </div>
    </Form>
  );
}
