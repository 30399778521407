import { useState } from "react";
import { BsDownload } from "react-icons/bs";

import NextImage from "next/image";

import { IDownloadFileProps } from "./interface";

import { useFetchGetNoteFile } from "~hooks/api";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { Spin } from "antd";

const printBytes = (bytes: number) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;
};

export function DownloadFile({
  fileId,
  entityId,
  filename,
  fileType,
  fileSize,
  title,
}: IDownloadFileProps) {
  const ICON_BY_FILE_TYPE: Record<string, string> = {
    "application/pdf": "/images/attachments/pdf.svg",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      "/images/attachments/word.svg",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      "/images/attachments/excel.svg",
    "text/csv": "/images/attachments/csv.svg",
    unknown: "/images/attachments/unknown.svg",
  };

  const getIconByFileType = (fileType: string): string => {
    return ICON_BY_FILE_TYPE[fileType] || ICON_BY_FILE_TYPE["unknown"];
  };
  const [requestDownload, setRequestDownload] = useState(false);

  function createAnchorElementAndDownloadPdf(
    base64: string,
    filename: string,
    fileType: string,
  ) {
    const a = document.createElement("a");
    a.href = `data:${fileType};base64,${base64}`;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const { isFetching: isLoadingImageBase64 } = useFetchGetNoteFile({
    payload: {
      fileId: fileId,
      noteId: entityId,
    },
    dependencyArray: [],
    options: {
      enabled: requestDownload,
      onSuccess: (data) => {
        createAnchorElementAndDownloadPdf(
          data.fileDataInBase64,
          filename,
          fileType,
        );
        setRequestDownload(false);
      },
    },
    customKey: `api/note/${entityId}/file/${fileId}/get`,
  });

  const handleClick = () => {
    setRequestDownload(true);
  };

  return (
    <div className="p-0 box-border max-w-56" key={fileId}>
      <Spin spinning={isLoadingImageBase64 || requestDownload}>
        <div className="flex justify-between items-start rounded-sm border-[1px] border-gray-300 dark:border-gray-700">
          <div className="flex flex-start items-start py-1">
            <NextImage
              src={getIconByFileType(fileType)}
              width={32}
              height={32}
              alt="file-icon"
              style={{ objectFit: "contain", paddingTop: 4 }}
            />
            <div className="ms-1 max-w-44">
              <SolarzTypography
                hierarchy="paragraph2"
                fontWeight="regular"
                variant="title"
              >
                {title || filename}
              </SolarzTypography>
              <SolarzTypography
                hierarchy="paragraph2"
                fontWeight="regular"
                variant="subTitle"
              >
                {printBytes(fileSize)}
              </SolarzTypography>
            </div>
          </div>
          <div className="p-2 cursor-pointer" onClick={handleClick}>
            <BsDownload style={{ fontSize: 20 }} />
          </div>
        </div>
      </Spin>
    </div>
  );
}
