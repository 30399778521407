import { useState } from "react";

import type { ICreateSimulationButtonProps } from "./interface";

import { Modal } from "~components/Modal";
import { Button } from "antd";

export function CreateSimulationButton({
  children,
  modalProps,
  type = "primary",
  ...props
}: ICreateSimulationButtonProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button
        {...props}
        data-cy="create-simulation-button"
        onClick={() => setIsModalOpen(true)}
        htmlType="button"
        type={type}
      >
        {children ?? "Nova simulação"}
      </Button>

      <Modal.PaymentSimulationForm
        {...modalProps}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
}
