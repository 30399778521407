import { useMemo } from "react";

import type { IFortlevModuleSelectProps } from "./interface";

import { SelectSuffixIcon } from "~components/Others";
import { Result } from "~components/Result";
import { useFetchGetAllFortlevEquipmentModules } from "~hooks/api";
import { Select } from "antd";

export function FortlevModuleSelect({
  allowClear = true,
  onChange,
  ...props
}: IFortlevModuleSelectProps) {
  const {
    data: modules = [],
    error: moduleError,
    isFetching: isLoadingModule,
    refetch: reloadModule,
  } = useFetchGetAllFortlevEquipmentModules({
    dependencyArray: [],
    options: {
      retry: 1,
    },
  });

  const OPTIONS = useMemo(() => {
    return modules.map((item) => ({
      label: item.description,
      value: item.id,
      record: item,
    }));
  }, [modules]);

  return (
    <Select
      {...props}
      loading={isLoadingModule}
      options={OPTIONS}
      allowClear={allowClear}
      status={moduleError ? "error" : ""}
      suffixIcon={
        <SelectSuffixIcon
          hasError={!!moduleError}
          isLoading={isLoadingModule}
        />
      }
      notFoundContent={
        <Result.SelectErrorOrEmpty
          errorMessage={moduleError?.message}
          emptyMessage="Nenhum módulo da Fortlev foi encontrado"
          loading={isLoadingModule}
          reloadFn={reloadModule}
        />
      }
      onChange={(value, option) => {
        if (!onChange) return;
        onChange(value, option);
      }}
    />
  );
}
