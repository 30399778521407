import type { IDeleteReasonForLossModalProps } from "./interface";

import { Alert } from "~components/Alert";
import {
  useFetchDeleteReasonForLoss,
  useFetchGetReasonForLossById,
} from "~hooks/api";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { Flex, Modal, Skeleton } from "antd";

export function DeleteReasonForLossModal({
  reasonForLossId = 0,
  isOpen = false,
  onClose = () => null,
  title = "Excluir motivo de perda",
  maxWidth = 520,
  onError,
  onSuccess,
}: IDeleteReasonForLossModalProps) {
  const {
    data: reasonForLoss,
    isFetching: isLoadingReasonForLoss,
    error: errorReasonForLoss,
    refetch: reloadReasonForLoss,
  } = useFetchGetReasonForLossById({
    payload: {
      id: reasonForLossId,
    },
    dependencyArray: [reasonForLossId],
    options: {
      enabled: reasonForLossId > 0,
      retry: 1,
    },
  });

  const { mutate: handleDelete, isLoading: isDeletingReasonForLoss } =
    useFetchDeleteReasonForLoss({
      options: {
        onSuccess,
        onError,
      },
    });

  const deleteButtonIsDisabled = !reasonForLoss?.id;

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title={title}
      width="90vw"
      footer={null}
      style={{ maxWidth }}
      destroyOnClose
    >
      {errorReasonForLoss && (
        <Alert.CardError
          errorMessage={errorReasonForLoss.message}
          reloadFn={reloadReasonForLoss}
          title="Falha ao carregar motivo de perda"
          className="my-8"
        />
      )}

      {(isLoadingReasonForLoss || !!errorReasonForLoss) && (
        <Skeleton.Node
          style={{
            height: 192,
            width: `calc(${maxWidth}px - 48px)`,
          }}
          active={isLoadingReasonForLoss}
        >
          {" "}
        </Skeleton.Node>
      )}

      {!isLoadingReasonForLoss && !errorReasonForLoss && (
        <Flex vertical gap={16} className="my-8">
          <SolarzTypography fontWeight="medium" variant="title">
            Tem certeza de que deseja excluir o motivo de perda{" "}
            {reasonForLoss?.reason ? <b>“{reasonForLoss?.reason}”</b> : null}?
          </SolarzTypography>

          <SolarzTypography fontWeight="medium" variant="title">
            Qualquer negócio perdido em que este motivo foi selecionado manterá
            estes dados.
          </SolarzTypography>

          <SolarzTypography fontWeight="medium" variant="title">
            Motivos de perda excluídos não podem ser selecionados em filtros e
            relatórios.
          </SolarzTypography>
        </Flex>
      )}

      <Flex
        style={{ marginTop: 16 }}
        align="center"
        gap={12}
        justify="flex-end"
      >
        <SolarzButton variant="secondary" scale="tiny" onClick={onClose}>
          Cancelar
        </SolarzButton>
        <SolarzButton
          scale="tiny"
          htmlType="submit"
          isDisabled={deleteButtonIsDisabled}
          isLoading={isDeletingReasonForLoss}
          onClick={() => {
            handleDelete({ id: reasonForLossId });
          }}
          variant="danger"
        >
          Excluir
        </SolarzButton>
      </Flex>
    </Modal>
  );
}
