import type { IKitItemFormProps, IKitItemFormValues } from "./interface";

import { CustomInput } from "~components/CustomInput";
import { Select } from "~components/Select";
import { Button, Flex, Form } from "antd";

export function KitItemForm({
  initialValues = {
    quantity: 1,
    unityAmount: 0,
    category: undefined,
    key: undefined,
    name: undefined,
    groupName: undefined,
  },
  isLoading = false,
  onSubmit = () => undefined,
  onCancel = () => null,
}: IKitItemFormProps) {
  const [form] = Form.useForm<IKitItemFormValues>();

  return (
    <Form
      data-cy="kit-item-form"
      form={form}
      initialValues={initialValues}
      onFinish={onSubmit}
      layout="vertical"
    >
      <Form.Item name="name" label="Nome do item" rules={[{ required: true }]}>
        <CustomInput.Text placeholder="Ex.: Inversor" />
      </Form.Item>

      <Form.Item name="category" label="Categoria" rules={[{ required: true }]}>
        <Select.KitItemCategory placeholder="Ex.: Módulo" />
      </Form.Item>

      <Form.Item
        name="groupName"
        label="Grupo"
        tooltip="Os itens serão agrupados de acordo com esse grupo cadastrado"
      >
        <CustomInput.Text placeholder="Ex.: Padrão" />
      </Form.Item>

      <Flex align="center" justify="space-between" wrap="nowrap" gap={16}>
        <Form.Item
          name="unityAmount"
          label="Valor unitário"
          rules={[{ required: true }]}
          style={{ width: "100%" }}
        >
          <CustomInput.Money min={0} style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          name="quantity"
          label="Quantidade"
          rules={[{ required: true }]}
          style={{ width: "100%" }}
        >
          <CustomInput.Number min={0} precision={0} style={{ width: "100%" }} />
        </Form.Item>
      </Flex>

      <Flex gap={12} justify="end">
        <Form.Item style={{ margin: 0 }}>
          <Button onClick={onCancel}>Cancelar</Button>
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Salvar
          </Button>
        </Form.Item>
      </Flex>
    </Form>
  );
}
