import * as React from "react";

import type { IIconProps } from "../@types/globals";
import { cn } from "../lib/utils";

const Newspaper: React.FC<IIconProps> = ({ className, ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cn("pointer-events-none w-6 h-6 stroke-1", className)}
    {...props}
  >
    <title>Jornal</title>
    <path
      d="M15 2.75C15.4142 2.75 15.75 2.41421 15.75 2C15.75 1.58579 15.4142 1.25 15 1.25V2.75ZM21.75 9C21.75 8.58579 21.4142 8.25 21 8.25C20.5858 8.25 20.25 8.58579 20.25 9H21.75ZM19.8284 20.8284L19.2981 20.2981L19.8284 20.8284ZM8 11.25C7.58579 11.25 7.25 11.5858 7.25 12C7.25 12.4142 7.58579 12.75 8 12.75V11.25ZM16 12.75C16.4142 12.75 16.75 12.4142 16.75 12C16.75 11.5858 16.4142 11.25 16 11.25V12.75ZM8 15.25C7.58579 15.25 7.25 15.5858 7.25 16C7.25 16.4142 7.58579 16.75 8 16.75V15.25ZM13 16.75C13.4142 16.75 13.75 16.4142 13.75 16C13.75 15.5858 13.4142 15.25 13 15.25V16.75ZM11 2.75H15V1.25H11V2.75ZM20.25 9V14H21.75V9H20.25ZM13 21.25H11V22.75H13V21.25ZM3.75 14V10H2.25V14H3.75ZM11 21.25C9.09318 21.25 7.73851 21.2484 6.71085 21.1102C5.70476 20.975 5.12511 20.7213 4.7019 20.2981L3.64124 21.3588C4.38961 22.1071 5.33855 22.4392 6.51098 22.5969C7.66182 22.7516 9.13558 22.75 11 22.75V21.25ZM2.25 14C2.25 15.8644 2.24841 17.3382 2.40313 18.489C2.56076 19.6614 2.89288 20.6104 3.64124 21.3588L4.7019 20.2981C4.27869 19.8749 4.02502 19.2952 3.88976 18.2892C3.75159 17.2615 3.75 15.9068 3.75 14H2.25ZM20.25 14C20.25 15.9068 20.2484 17.2615 20.1102 18.2892C19.975 19.2952 19.7213 19.8749 19.2981 20.2981L20.3588 21.3588C21.1071 20.6104 21.4392 19.6614 21.5969 18.489C21.7516 17.3382 21.75 15.8644 21.75 14H20.25ZM13 22.75C14.8644 22.75 16.3382 22.7516 17.489 22.5969C18.6614 22.4392 19.6104 22.1071 20.3588 21.3588L19.2981 20.2981C18.8749 20.7213 18.2952 20.975 17.2892 21.1102C16.2615 21.2484 14.9068 21.25 13 21.25V22.75ZM11 1.25C9.13558 1.25 7.66182 1.24841 6.51098 1.40313C5.33855 1.56076 4.38961 1.89288 3.64124 2.64124L4.7019 3.7019C5.12511 3.27869 5.70476 3.02502 6.71085 2.88976C7.73851 2.75159 9.09318 2.75 11 2.75V1.25ZM3.75 10C3.75 8.09318 3.75159 6.73851 3.88976 5.71085C4.02502 4.70476 4.27869 4.12511 4.7019 3.7019L3.64124 2.64124C2.89288 3.38961 2.56076 4.33855 2.40313 5.51098C2.24841 6.66182 2.25 8.13558 2.25 10H3.75ZM8 12.75H16V11.25H8V12.75ZM8 16.75H13V15.25H8V16.75Z"
      fill="currentColor"
    />
    <path
      d="M20.3442 3.10577L19.875 1.916L19.4058 3.10577C19.3065 3.35737 19.1074 3.55653 18.8558 3.65576L17.666 4.125L18.8558 4.59424C19.1074 4.69347 19.3065 4.89263 19.4058 5.14423L19.875 6.334L20.3442 5.14423C20.4435 4.89263 20.6426 4.69347 20.8942 4.59424L22.084 4.125L20.8942 3.65576C20.6426 3.55653 20.4435 3.35737 20.3442 3.10577Z"
      stroke="currentColor"
    />
    <path
      d="M16.3446 7.23171L16.2083 6.88622L16.0721 7.23171C15.9956 7.42553 15.8422 7.57895 15.6484 7.65539L15.3029 7.79165L15.6484 7.92791C15.8422 8.00435 15.9956 8.15776 16.0721 8.35158L16.2083 8.69707L16.3446 8.35158C16.421 8.15776 16.5745 8.00435 16.7683 7.92791L17.1138 7.79165L16.7683 7.65539C16.5745 7.57895 16.421 7.42553 16.3446 7.23171Z"
      stroke="currentColor"
    />
  </svg>
);

Newspaper.displayName = "Newspaper";

export { Newspaper };
