import { FC } from "react";

import styles from "./styles";

import { InputNumber } from "antd";
import type { InputNumberProps } from "antd/lib/input-number";

export type Props = Omit<
  InputNumberProps,
  "decimalSeparator" | "precision" | "addonAfter"
> & {
  precision: "0" | "1" | "2";
  unitMeasurement:
    | "R$"
    | "%"
    | "KWh"
    | "KWp"
    | "KW"
    | "Km"
    | "mm"
    | "meses"
    | "m²/m² de módulos"
    | "m²"
    | "% a.m."
    | "R$/kWh"
    | "R$/MWh"
    | "Wh/m².dia"
    | "Wh/m².mes"
    | "kWh/m².mes"
    | "kVa"
    | "Celsius"
    | "Wp"
    | "VA"
    | "V"
    | "m"
    | "metros";
};

// PRECISA ALTERAR AS UNIDADES DE MEDIDA PÓS-REFACT DA GERAÇÃO DA PROPOSTA
function parseSomeValue(unit: string) {
  switch (unit) {
    case "kVa":
      return "kVA";
    case "KWp":
      return "kWp";
    case "KWh":
      return "kWh";
    case "KW":
      return "kW";
    case "Celsius":
      return "°C";
    default:
      return unit;
  }
}

export const InputUnitMeasurement: FC<Props> = ({
  className,
  precision,
  unitMeasurement,
  onFocus = (event) => event.target.select(),
  ...props
}) => {
  return (
    <>
      <style jsx>{styles}</style>
      <InputNumber
        {...props}
        decimalSeparator=","
        precision={Number(precision) ?? 0}
        className={className ?? "input"}
        addonAfter={parseSomeValue(unitMeasurement)}
        onFocus={onFocus}
      />
    </>
  );
};
