import type { IDeleteIntegrationApiModalProps } from "./interface";
import { StyledAlert } from "./styles";

import { useFetchDeletePersonalToken } from "~hooks/api";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { Flex, Modal } from "antd";

export function DeleteIntegrationApiModal({
  isOpen = false,
  onClose = () => null,
  title = "Remover integração",
  maxWidth = 520,
  onError,
  onSuccess,
}: IDeleteIntegrationApiModalProps) {
  const { mutate: deletePersonalToken, isLoading: isDeletingPersonalToken } =
    useFetchDeletePersonalToken({
      options: {
        onSuccess: () => {
          if (typeof onSuccess === "function") {
            onSuccess();
          }

          if (typeof onClose === "function") {
            onClose();
          }
        },
        onError,
      },
    });

  function handleDeleteIntegration() {
    deletePersonalToken();
  }

  const isLoading = isDeletingPersonalToken;

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title={title}
      width="90vw"
      footer={null}
      data-cy="delete-integration-modal"
      style={{ maxWidth }}
      destroyOnClose
    >
      {!isLoading && (
        <Flex vertical style={{ marginTop: "1.5rem" }}>
          <StyledAlert
            type="error"
            description={
              <Flex vertical>
                <SolarzTypography variant="danger" fontWeight="medium">
                  Você tem certeza que deseja remover a integração?
                </SolarzTypography>
              </Flex>
            }
          />
        </Flex>
      )}

      <Flex
        style={{ marginTop: "1.5rem" }}
        align="center"
        gap={16}
        justify="flex-end"
      >
        <SolarzButton variant="secondary" scale="tiny" onClick={onClose}>
          Cancelar
        </SolarzButton>
        <SolarzButton
          scale="tiny"
          htmlType="submit"
          isLoading={isLoading}
          variant="danger"
          onClick={handleDeleteIntegration}
        >
          Apagar
        </SolarzButton>
      </Flex>
    </Modal>
  );
}
