import { useMemo } from "react";

import type {
  ISolarzPercentageRowGraphProps,
  SolarzPercentageRowGraphContentType,
} from "./interface";
import { Wrapper } from "./styles";

import { SolarzTypography } from "~solarzui/SolarzTypography";
import { parsePercentage } from "~utils/parse";
import { Badge, Col, Flex, Row, Tooltip } from "antd";

function getDataPropsFromContent<RecordType extends Record<string, any>>(
  content: SolarzPercentageRowGraphContentType<RecordType>[],
) {
  const sumAbsoluteValues = content.reduce(
    (sum, content) => sum + content.absoluteValue,
    0,
  );

  return content.map(({ absoluteValue, color, label, record }) => {
    const relativeValue = (absoluteValue / sumAbsoluteValues) * 100;

    return {
      label,
      record,
      absoluteValue,
      relativeValue,
      badgeColorProp: color,
    };
  });
}

export function SolarzPercentageRowGraph<
  RecordType extends Record<string, any>,
>({
  content = [],
  summaryItemRender,
  tooltipItemRender,
}: ISolarzPercentageRowGraphProps<RecordType>) {
  const DATA = useMemo(() => getDataPropsFromContent(content), [content]);

  return (
    <Wrapper data-cy="solarz-percentage-row-graph" vertical gap={12}>
      <Row
        style={{ width: "100%" }}
        gutter={[2, 0]}
        className="solarz-graph"
        wrap={false}
      >
        {DATA.map((data, index) => {
          const tooltipTitle =
            typeof tooltipItemRender === "function"
              ? tooltipItemRender(
                  {
                    absoluteValue: data.absoluteValue,
                    color: data.badgeColorProp,
                    label: data.label,
                    record: data.record,
                    relativeValue: data.relativeValue,
                  },
                  index,
                )
              : `${data.label} | ${data.absoluteValue} (${parsePercentage(
                  data.relativeValue,
                )})`;

          return (
            <Col
              key={index}
              flex={`${data.relativeValue}%`}
              style={{ height: 16 }}
            >
              <Tooltip title={tooltipTitle}>
                <Badge
                  color={data.badgeColorProp}
                  styles={{
                    indicator: {
                      height: 16,
                      width: "100%",
                    },
                    root: {
                      width: "100%",
                    },
                  }}
                />
              </Tooltip>
            </Col>
          );
        })}
      </Row>

      <Flex vertical gap={8}>
        {DATA.map((data, index) => {
          if (typeof summaryItemRender === "function") {
            return summaryItemRender(
              {
                absoluteValue: data.absoluteValue,
                color: data.badgeColorProp,
                label: data.label,
                record: data.record,
                relativeValue: data.relativeValue,
              },
              index,
            );
          }

          return (
            <Row key={index}>
              <Col span={8}>
                <Flex gap={6}>
                  <Badge
                    color={data.badgeColorProp}
                    styles={{
                      indicator: {
                        height: 10,
                        width: 10,
                        borderRadius: 2,
                      },
                    }}
                  />
                  <SolarzTypography
                    hierarchy="paragraph2"
                    fontWeight="regular"
                    variant="default"
                  >
                    {data.label}
                  </SolarzTypography>
                </Flex>
              </Col>

              <Col span={8}>
                <SolarzTypography
                  hierarchy="paragraph2"
                  fontWeight="regular"
                  variant="default"
                  style={{
                    textAlign: "center",
                  }}
                >
                  {data.absoluteValue}
                </SolarzTypography>
              </Col>

              <Col span={8}>
                <SolarzTypography
                  hierarchy="paragraph2"
                  fontWeight="regular"
                  variant="default"
                  style={{
                    textAlign: "right",
                  }}
                >
                  {parsePercentage(data.relativeValue, "pt-BR", 0)}
                </SolarzTypography>
              </Col>
            </Row>
          );
        })}
      </Flex>
    </Wrapper>
  );
}
