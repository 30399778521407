import { api, apiException } from "~services/api";
import {
  FetchSendEmailToRecoverPasswordPayloadSchema,
  IFetchSendEmailToRecoverPasswordPayloadSchema,
} from "~types/schemas";

export const fetchSendEmailToRecoverPassword = async (
  payload: IFetchSendEmailToRecoverPasswordPayloadSchema,
) => {
  try {
    const body = FetchSendEmailToRecoverPasswordPayloadSchema.parse(payload);

    await api.post(`/users/forgot-password`, body);
  } catch (error) {
    throw apiException(error);
  }
};
