import { useState } from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";

import { IAdditionalCostMarginTypeFormValues } from "./interface";

import { Modal } from "~components/Modal";
import {
  useFetchCreateAdditionalCostMarginType,
  useFetchUpdateAdditionalCostMarginType,
  useFetchGetCurrentAdditionalCostMarginType,
  useFetchDeleteAdditionalCostMarginType,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { IAdditionalCostMarginTypeDTOSchema } from "~types/schemas";
import { Button, Form, Radio, Space, Tooltip, Flex } from "antd";

export function AdditionalCostMarginTypeForm() {
  const { message } = useAppConfig();
  const [form] = Form.useForm<IAdditionalCostMarginTypeFormValues>();
  const [data, setData] = useState<IAdditionalCostMarginTypeDTOSchema>();
  const [isOpenModalDelete, setIsOpenModalDelete] = useState<boolean>(false);

  const { isLoading: isGetCurrentAdditionalCostMarginType } =
    useFetchGetCurrentAdditionalCostMarginType({
      options: {
        retry: false,
        onSuccess: (data) => {
          form.setFieldsValue({
            afterCalculations: data.afterCalculations,
            includeTaxes: data.includeTaxes,
            insideCalculation: data.insideCalculation,
          });
          setData(data);
        },
        onError: (error) => {
          if (error.code === 404) {
            message.info("Você não possui um tipo de margem de lucro.");
          } else {
            message.error(error.message);
          }

          setData(undefined);
          form.resetFields();
        },
      },
    });

  const {
    mutate: createAdditionalCostMarginType,
    isLoading: isCreatingAdditionalCostMarginType,
  } = useFetchCreateAdditionalCostMarginType({});

  const {
    mutate: updateAdditionalCostMarginType,
    isLoading: isUpdatingAdditionalCostMarginType,
  } = useFetchUpdateAdditionalCostMarginType({});

  const {
    mutate: fetchDeleteAdditionalCostMarginType,
    isLoading: isDeletingAdditionalCostMarginType,
  } = useFetchDeleteAdditionalCostMarginType({
    onSuccess: () => setIsOpenModalDelete(false),
  });

  function handleSubmit(dataForm: IAdditionalCostMarginTypeFormValues) {
    const additionalCostMarginTypeId = data?.id;

    if (additionalCostMarginTypeId) {
      updateAdditionalCostMarginType({
        ...dataForm,
        additionalCostMarginTypeId,
      });
    } else {
      createAdditionalCostMarginType(dataForm);
    }
  }

  function handleDelete() {
    fetchDeleteAdditionalCostMarginType({
      additionalCostMarginTypeId: data?.id ?? 0,
    });
  }

  const isLoading =
    isGetCurrentAdditionalCostMarginType ||
    isCreatingAdditionalCostMarginType ||
    isUpdatingAdditionalCostMarginType ||
    isDeletingAdditionalCostMarginType;

  return (
    <>
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          label=" Calculada após o cálculo de custos?"
          name="afterCalculations"
          rules={[{ required: true }]}
          style={{ width: "100%" }}
        >
          <Radio.Group>
            <Radio value={true}>Sim</Radio>
            <Radio value={false}>Não</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Incluir impostos?"
          name="includeTaxes"
          rules={[{ required: true }]}
          style={{ width: "100%" }}
        >
          <Radio.Group>
            <Radio value={true}>Sim</Radio>
            <Radio value={false}>Não</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Tipo de operação"
          name="insideCalculation"
          rules={[{ required: true }]}
          style={{ width: "100%" }}
        >
          <Radio.Group>
            <Space direction="vertical">
              <Radio value={true}>
                <Space>
                  Percentual por dentro
                  <Tooltip title=" 5% por dentro de R$ 100,00 é o equivalente a (R$ 100 / 0,95) * 0,05 = R$ 5,26.">
                    <AiOutlineQuestionCircle
                      size={18}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    />
                  </Tooltip>
                </Space>
              </Radio>
              <Radio value={false}>
                <Space>
                  Percentual por fora
                  <Tooltip title="5% por fora de R$ 100,00 é o equivalente a R$ 100 * 0,05 = R$ 5,00">
                    <AiOutlineQuestionCircle
                      size={18}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    />
                  </Tooltip>
                </Space>
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Flex gap={12} justify="start">
          <Form.Item style={{ margin: 0 }}>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Salvar
            </Button>
          </Form.Item>
          <Form.Item style={{ margin: 0 }}>
            <Button
              type="default"
              onClick={() => setIsOpenModalDelete(true)}
              disabled={!data?.id}
            >
              Deletar
            </Button>
          </Form.Item>
        </Flex>
      </Form>
      <Modal.DefaultRemove
        isOpen={isOpenModalDelete}
        isLoading={isLoading}
        onClose={() => setIsOpenModalDelete(false)}
        suffix="tipo de margem de lucro"
        title="Remover tipo de margem de lucro"
        onSubmit={handleDelete}
      />
    </>
  );
}
