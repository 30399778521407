import { useMemo } from "react";
import { BiErrorCircle } from "react-icons/bi";

import type {
  ILoggedUserBusinessUnitSelectProps,
  LoggedUserBusinessUnitSelectOptionType,
} from "./interface";
import { StyledSelect } from "./styles";

import { Result } from "~components/Result";
import {
  useFetchGetUserBusinessUnits,
  useFetchUpdateUserBusinessUnit,
} from "~hooks/api";
import { useSession } from "~hooks/useSession";
import { Button, Tooltip } from "antd";

export function LoggedUserBusinessUnitSelect(
  props: ILoggedUserBusinessUnitSelectProps,
) {
  const { user } = useSession();

  const {
    mutate: updateUserBusinessUnit,
    isLoading: isUpdatingUserBusinessUnit,
  } = useFetchUpdateUserBusinessUnit({});

  const {
    data: businessUnits = [],
    isFetching: isLoadingBusinessUnits,
    error: errorBusinessUnits,
    refetch: reloadBusinessUnits,
  } = useFetchGetUserBusinessUnits({
    options: {
      enabled: !!user,
    },
  });

  function handleSelectBusinessUnit(businessUnitId: number) {
    updateUserBusinessUnit({
      businessUnitId,
    });
  }

  const options: LoggedUserBusinessUnitSelectOptionType[] = useMemo(() => {
    return businessUnits.map((item) => ({
      label: item.name,
      value: item.id,
      record: item,
    }));
  }, [businessUnits]);

  const isLoading = isUpdatingUserBusinessUnit || isLoadingBusinessUnits;

  return (
    <StyledSelect
      options={options}
      loading={isLoading}
      value={user?.businessUnitId}
      onChange={handleSelectBusinessUnit}
      status={errorBusinessUnits ? "error" : ""}
      notFoundContent={
        <Result.SelectErrorOrEmpty
          errorMessage={errorBusinessUnits?.message}
          emptyMessage="Nenhuma unidade de negócio encontrada"
          loading={isLoading}
          reloadFn={reloadBusinessUnits}
        />
      }
      suffixIcon={
        errorBusinessUnits ? (
          <Button
            type="link"
            loading={isLoading}
            onClick={() => reloadBusinessUnits()}
          >
            <Tooltip title="Recarregar dados" zIndex={10000}>
              <BiErrorCircle size={18} />
            </Tooltip>
          </Button>
        ) : undefined
      }
      placeholder="Unidade de negócio..."
      {...props}
    />
  );
}
