import type { IChangePasswordFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function ChangePasswordFormModal({
  isOpen = false,
  onClose = () => undefined,
  title = "Alterar senha",
  maxWidth = 512,
  closable = true,
  formProps,
}: IChangePasswordFormModalProps) {
  return (
    <Modal
      data-cy="change-password-form-modal"
      open={isOpen}
      onCancel={onClose}
      title={title}
      width="90vw"
      footer={null}
      style={{ maxWidth }}
      destroyOnClose
      closable={closable}
    >
      <Form.ChangePassword {...formProps} onCancel={onClose} />
    </Modal>
  );
}
