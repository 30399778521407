import { FC, useCallback, useEffect, useRef } from "react";

import { INITIAL_LOCATION, MAP_OPTIONS } from "../configs";
import { useGoogleMaps } from "../hooks/useGoogleMaps";

import { GoogleMap, Marker } from "@react-google-maps/api";

export const Map: FC = () => {
  const { location } = useGoogleMaps();

  const mapRef = useRef<google.maps.Map>();
  const onLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
  }, []);

  useEffect(() => {
    if (!location?.latitude || !location?.longitude) return;

    mapRef.current?.panTo({
      lat: location.latitude,
      lng: location.longitude,
    });
  }, [location]);

  return (
    <GoogleMap
      data-cy="google-map"
      mapContainerStyle={{
        width: "100%",
        height: "100vh",
      }}
      zoom={10}
      center={INITIAL_LOCATION}
      options={MAP_OPTIONS}
      onLoad={onLoad}
    >
      {location?.latitude && location?.longitude && (
        <>
          <Marker
            position={{
              lat: location.latitude,
              lng: location.longitude,
            }}
          />
        </>
      )}
    </GoogleMap>
  );
};
