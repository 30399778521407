import type { ITransformerGroupRuleFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function TransformerGroupRuleFormModal({
  isOpen = false,
  onClose = () => null,
  width = 512,
  formProps = {},
}: ITransformerGroupRuleFormModalProps) {
  return (
    <Modal
      data-cy="transformer-group-rule-form-modal"
      open={isOpen}
      onCancel={onClose}
      title="Regra do transformador"
      width={width}
      footer={null}
      destroyOnClose
    >
      <Form.TransformerGroupRule {...formProps} onCancel={onClose} />
    </Modal>
  );
}
