import { HiArrowNarrowUp, HiArrowNarrowDown } from "react-icons/hi";

import type { IValueGainProps, ValueGainUnitType } from "./interface";

import { parseCurrency, parsePercentage } from "~utils/parse";
import { Flex, Tooltip, Typography } from "antd";

function formatValue(value: number, unit: ValueGainUnitType) {
  switch (unit) {
    case "%":
      return parsePercentage(value);
    case "R$":
      return parseCurrency(value);
    default:
      return value.toFixed(2);
  }
}

function getStatus(actualValue: number, oldValue: number) {
  const difference = actualValue - oldValue;

  // need format to compare, in some cases the value is 0.000000012312
  if (difference.toFixed(2) === "0.00") {
    return actualValue < 0 ? "CRITICAL_EQUAL" : "EQUAL";
  }

  if (difference > 0.01) {
    return "GAIN";
  }

  // LOOSE
  return actualValue > 0 ? "LOOSE" : "CRITICAL";
}

function getColorByStatus(
  status: "GAIN" | "LOOSE" | "CRITICAL" | "EQUAL" | "CRITICAL_EQUAL",
) {
  switch (status) {
    case "GAIN":
      return "#52c41a";
    case "LOOSE":
      return "#fa8c16";
    case "CRITICAL":
      return "#f5222d";
    case "CRITICAL_EQUAL":
      return "#f5222d";
    default:
      return "";
  }
}

function getIconByStatus(
  status: "GAIN" | "LOOSE" | "CRITICAL" | "EQUAL" | "CRITICAL_EQUAL",
  color = "",
) {
  switch (status) {
    case "GAIN":
      return <HiArrowNarrowUp size={16} color={color} />;
    case "LOOSE":
      return <HiArrowNarrowDown size={16} color={color} />;
    case "CRITICAL":
      return <HiArrowNarrowDown size={16} color={color} />;
    default:
      return <></>;
  }
}

export function ValueGain({
  actualValue = 0,
  oldValue = 0,
  unit = "R$",
}: IValueGainProps) {
  const formattedActualValue = formatValue(actualValue, unit);
  const formattedOldValue = formatValue(oldValue, unit);
  const difference = formatValue(actualValue - oldValue, unit);

  const status = getStatus(actualValue, oldValue);

  const currentValueColor = getColorByStatus(status);

  const arrowColor = getColorByStatus(status);
  const IconComponent = getIconByStatus(status, arrowColor);

  return (
    <Tooltip
      data-cy="value-gain"
      title={
        status !== "EQUAL" &&
        status !== "CRITICAL_EQUAL" && (
          <Flex align="center" gap={0} wrap="wrap">
            <span>{formattedOldValue}</span>
            <span style={{ margin: "0 0.25rem" }}>→</span>
            <span>{formattedActualValue}</span>
            <br />
            <span style={{ marginLeft: "0.25rem" }}>({difference})</span>
          </Flex>
        )
      }
      overlayStyle={{ fontSize: 13 }}
    >
      <Typography.Text style={{ padding: 0 }}>
        <Flex align="center" justify="center" gap={4} wrap="nowrap">
          <span style={{ color: currentValueColor }}>
            {formattedActualValue}
          </span>
          {IconComponent}
        </Flex>
      </Typography.Text>
    </Tooltip>
  );
}
