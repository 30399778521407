import { useMemo } from "react";

import { IOutputVoltageSelectProps, IOutputVoltageOption } from "./interface";

import { Select } from "antd";

export function OutputVoltageSelect({
  value,
  onChange,
  defaultValue,
  disabledValues,
  hideValues,
  ...props
}: IOutputVoltageSelectProps) {
  const OPTIONS: IOutputVoltageOption[] = useMemo(
    () =>
      [
        {
          label: "V110" as const,
          value: "V110" as const,
          disabled: disabledValues?.V110,
          isHidden: hideValues?.V110,
        },
        {
          label: "V220" as const,
          value: "V220" as const,
          disabled: disabledValues?.V220,
          isHidden: hideValues?.V220,
        },
        {
          label: "V380" as const,
          value: "V380" as const,
          disabled: disabledValues?.V380,
          isHidden: hideValues?.V380,
        },
        {
          label: "V460" as const,
          value: "V460" as const,
          disabled: disabledValues?.V460,
          isHidden: hideValues?.V460,
        },
        {
          label: "V480" as const,
          value: "V480" as const,
          disabled: disabledValues?.V480,
          isHidden: hideValues?.V480,
        },
        {
          label: "V500" as const,
          value: "V500" as const,
          disabled: disabledValues?.V500,
          isHidden: hideValues?.V500,
        },
        {
          label: "V540" as const,
          value: "V540" as const,
          disabled: disabledValues?.V540,
          isHidden: hideValues?.V540,
        },
        {
          label: "V600" as const,
          value: "V600" as const,
          disabled: disabledValues?.V600,
          isHidden: hideValues?.V600,
        },
        {
          label: "V800" as const,
          value: "V800" as const,
          disabled: disabledValues?.V800,
          isHidden: hideValues?.V800,
        },
      ].filter((option) => !option.isHidden),
    [disabledValues, hideValues],
  );

  return (
    <Select
      {...props}
      options={OPTIONS}
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
}
