import type { IProductsImportHistoryListModalProps } from "./interface";

import { List } from "~components/List";
import { Modal } from "antd";

export function ProductsImportHistoryListModal({
  isOpen = false,
  onClose = () => null,
  title = "Importações",
  maxWidth = 768,
}: IProductsImportHistoryListModalProps) {
  return (
    <Modal
      data-cy="products-import-history-modal"
      open={isOpen}
      onCancel={onClose}
      title={title}
      width="90vw"
      footer={null}
      destroyOnClose
      style={{ maxWidth }}
      styles={{
        body: {
          maxHeight: "calc(100vh - 240px)",
          overflowY: "auto",
        },
      }}
    >
      <List.ProductsImportHistory />
    </Modal>
  );
}
