import type { IAdminFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function AdminFormModal({
  isOpen = false,
  onClose = () => null,
  maxWidth = 560,
  formProps,
}: IAdminFormModalProps) {
  return (
    <Modal
      data-cy="admin-form-modal"
      open={isOpen}
      onCancel={onClose}
      title="Criar administrador"
      width="90vw"
      style={{ maxWidth }}
      footer={null}
      destroyOnClose
    >
      <Form.Admin {...formProps} onCancel={onClose} />
    </Modal>
  );
}
