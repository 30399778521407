import { useEffect, useState } from "react";
import { BiErrorCircle } from "react-icons/bi";
import { FiAlertTriangle } from "react-icons/fi";

import { IInputGlobalIrradiationProps } from "./interface";
import styles from "./styles";

import { ISolarimetryState } from "~components/Form";
import { Modal } from "~components/Modal";
import {
  useFetchGetMonthlyRadiationByCustomId,
  useFetchGetGlobalIrradiationByCustomId,
} from "~hooks/api";
import { IrradiationTypeEnum } from "~types/enum";
import {
  getAverageGlobalIrradiation,
  getAverageMonthlyIrradiation,
  transformGlobalRadiationToMonthlySolarRadiation,
} from "~utils/calculateAverageMonthlyIrradiation";
import { Button, Divider, Form, InputNumber, Flex, Tooltip } from "antd";

export function InputGlobalIrradiation({
  customId = 0,
  defaultIrradiationValue,
  className,
  onFocus = (event) => event.target.select(),
  extra,
  onChange = () => undefined,
  completeWithDefaultValueInFirstLoad = false,
  irradiationType,
  setIrradiationType,
  setTypeOfIrradiationReturnedAfterUpdate,
  solarimetryModalOpen = false,
  onCloseSolarimetryModal,
  ...props
}: IInputGlobalIrradiationProps) {
  const [irradiationLabel, setIrradiationLabel] = useState<IrradiationTypeEnum>(
    irradiationType || "DAILY",
  );

  const {
    isFetching: isLoadingGlobalSolarimetry,
    refetch: reloadGlobalSolarimetry,
  } = useFetchGetGlobalIrradiationByCustomId({
    dependencyArray: [customId],
    payload: {
      customId,
    },
    options: {
      onSuccess(data) {
        const parsedData =
          irradiationType === "MONTHLY"
            ? transformGlobalRadiationToMonthlySolarRadiation(data)
            : data;

        const average = getAverageGlobalIrradiation(parsedData);

        setDefaultSolarimetry({
          ...data,
          average,
        });
      },
      onError(error) {
        setSolarimetryError(error.message);
      },
      enabled: false,
    },
  });

  const { isFetching: isLoadingSolarimetry, refetch: reloadSolarimetry } =
    useFetchGetMonthlyRadiationByCustomId({
      dependencyArray: [customId],
      payload: {
        customId,
      },
      options: {
        enabled: customId > 0,
        onSuccess(data) {
          if (!data?.monthlySolarRadiation) {
            return reloadGlobalSolarimetry();
          }

          const average = getAverageMonthlyIrradiation(data);

          setDefaultSolarimetry({
            ...data,
            average,
          });
          setIrradiationLabel(data.irradiationType ?? "DAILY");
        },
        onError(error) {
          setSolarimetryError(error.message);
        },
      },
    });

  const [defaultSolarimetry, setDefaultSolarimetry] = useState<
    ISolarimetryState | undefined
  >();
  const [solarimetryError, setSolarimetryError] = useState<string | undefined>(
    undefined,
  );
  const [currentSolarimetry, setCurrentSolarimetry] = useState<
    ISolarimetryState | undefined
  >(defaultIrradiationValue);

  const [isSolarimetryModalOpen, setIsSolarimetryModalOpen] =
    useState(solarimetryModalOpen);

  useEffect(() => {
    setIsSolarimetryModalOpen(solarimetryModalOpen);
  }, [solarimetryModalOpen]);

  useEffect(() => {
    onChange(currentSolarimetry);
  }, [onChange, currentSolarimetry]);

  useEffect(() => {
    if (completeWithDefaultValueInFirstLoad && !currentSolarimetry) {
      setCurrentSolarimetry(defaultSolarimetry);
    }
  }, [
    completeWithDefaultValueInFirstLoad,
    currentSolarimetry,
    defaultSolarimetry,
  ]);

  const isLoading = isLoadingGlobalSolarimetry || isLoadingSolarimetry;

  const hasDirectlyInserted =
    defaultSolarimetry?.average !== currentSolarimetry?.average;

  return (
    <>
      <style jsx>{styles}</style>
      <Form.Item
        extra={
          <Flex justify="space-between" align="center">
            <Flex align="center" gap={4}>
              <Button
                type="link"
                size="small"
                onClick={() => setIsSolarimetryModalOpen(true)}
                style={{ margin: 0, padding: 0 }}
                data-cy="button-set-solar-radiation"
                loading={isLoading}
              >
                <Flex justify="center" align="center">
                  Definir solarimetria
                </Flex>
              </Button>
              {hasDirectlyInserted && (
                <>
                  <Divider type="vertical" />
                  <Tooltip title="O valor foi inserido manualmente ignorando o valor mês-a-mês">
                    <Flex align="center" style={{ cursor: "help" }}>
                      <FiAlertTriangle size={14} color="var(--red-500)" />
                      <span
                        style={{
                          color: "var(--red-500)",
                          marginLeft: "0.25rem",
                        }}
                      >
                        Inserido manualmente!
                      </span>
                    </Flex>
                  </Tooltip>
                </>
              )}
            </Flex>

            {extra}
          </Flex>
        }
      >
        <InputNumber
          {...props}
          decimalSeparator=","
          precision={2}
          className={className ?? "input"}
          addonAfter={
            solarimetryError ? (
              <Tooltip title="Clique para recarregar">
                <BiErrorCircle
                  size={18}
                  color="var(--red-200)"
                  onClick={() => reloadSolarimetry()}
                />
              </Tooltip>
            ) : (
              <span style={{ fontSize: "0.875rem" }}>
                {irradiationLabel === "DAILY" ? "Wh/m².dia" : "kWh/m2.mes"}
              </span>
            )
          }
          onChange={(value) => {
            const irradiation = Number(value);
            const parsedValue = isNaN(irradiation) ? undefined : irradiation;
            setCurrentSolarimetry({
              average: parsedValue ?? 0,
              jan: undefined,
              feb: undefined,
              mar: undefined,
              apr: undefined,
              may: undefined,
              jun: undefined,
              jul: undefined,
              aug: undefined,
              sep: undefined,
              oct: undefined,
              nov: undefined,
              dec: undefined,
              irradiationType: undefined,
            });
          }}
          value={currentSolarimetry?.average}
          placeholder={
            solarimetryError
              ? `Falha ao carregar dados: ${solarimetryError}`
              : "Ex.: 5212"
          }
          onFocus={onFocus}
        />
      </Form.Item>

      <Modal.SolarimetryForm
        isOpen={isSolarimetryModalOpen}
        formProps={{
          customId,
          onSuccess: (solarimetry) => {
            setCurrentSolarimetry(solarimetry);
            setIsSolarimetryModalOpen(false);
            setTypeOfIrradiationReturnedAfterUpdate(
              solarimetry.irradiationType,
            );
          },
        }}
        onClose={() => {
          setIsSolarimetryModalOpen(false);

          if (typeof onCloseSolarimetryModal === "function") {
            onCloseSolarimetryModal();
          }
        }}
        irradiationType={irradiationType}
        setIrradiationType={setIrradiationType}
      />
    </>
  );
}
