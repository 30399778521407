import { IIntegrationCardProps } from "./interface";

import { Switch } from "~components/Switch";
import { Button, Card, Typography, Flex } from "antd";

export function IntegrationCard({
  id = 0,
  title = "",
  subtitle = "",
  icon = null,
  canConfigure = false,
  canEnable = false,
  canImport = false,
  enabled = false,
  canSeeImportation = false,
  onConfigure = () => null,
  onImport = () => null,
  onSeeImportation = () => null,
}: IIntegrationCardProps) {
  return (
    <Card
      data-cy="integration-card"
      style={{
        maxWidth: "280px",
        minWidth: "220px",
      }}
    >
      <Flex align="center" justify="center" vertical gap={16}>
        <Typography.Title level={4}>{title}</Typography.Title>
        {icon}

        {canConfigure && (
          <Button onClick={onConfigure} block>
            Configurar
          </Button>
        )}

        {canImport && (
          <Button onClick={onImport} block>
            Importar
          </Button>
        )}

        {canSeeImportation && (
          <Button onClick={onSeeImportation} block>
            Visualizar Importações
          </Button>
        )}

        {canEnable && (
          <Switch.ToggleDistributors distributorId={id} isChecked={enabled} />
        )}

        <Typography.Text style={{ textAlign: "center" }}>
          {subtitle}
        </Typography.Text>
      </Flex>
    </Card>
  );
}
