import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IoIosNotifications } from "react-icons/io";

import { useRouter } from "next/router";

import type {
  INotificationDrawerProps,
  NotificationCenterMenuItem,
} from "./interface";
import { StyledButton, NotificationHeader } from "./styles";

import { Card } from "~components/Card";
import { RadioGroup } from "~components/RadioGroup";
import { ArrowLeft } from "~estrela-ui/icons";
import {
  useFetchGetPaginatedNotifications,
  useFetchSetNotificationRead,
} from "~hooks/api";
import { useSession } from "~hooks/useSession";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { NotificationStatusEnum } from "~types/enum";
import type {
  INotificationDTOSchema,
  IPaginatedNotificationDTOSchema,
} from "~types/schemas";
import { Drawer, Divider, Flex, List, Skeleton } from "antd";

export function NotificationCenterDrawer({
  open,
  onClose,
}: INotificationDrawerProps) {
  const { user } = useSession();
  const router = useRouter();

  const [filterStatus, setFilterStatus] =
    useState<NotificationStatusEnum>("ALL");

  const [notifications, setNotifications] = useState<INotificationDTOSchema[]>(
    [],
  );

  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const loader = useRef(null);
  const drawerContainer = useRef(null);

  const { mutate: setNotificationAsRead } = useFetchSetNotificationRead({});

  const {
    isLoading: isLoadingNotifications,
    isRefetching: isRefethingNotifications,
  } = useFetchGetPaginatedNotifications({
    dependencyArray: [filterStatus, page],
    payload: {
      size: 15,
      page,
      status: filterStatus,
    },
    options: {
      enabled: open && !!user,
      onSuccess: (data) => {
        handleAddNotifications(data);
      },
    },
  });

  const handleAddNotifications = (
    notificationsData: IPaginatedNotificationDTOSchema,
  ) => {
    setNotifications((prevItems) => {
      const newItems = notificationsData.content.filter(
        (item) => !prevItems.some((prevItem) => prevItem.id === item.id),
      );

      return page === 0
        ? [...notificationsData.content]
        : [...prevItems, ...newItems];
    });
    setHasMore(page < notificationsData.totalPages - 1);
  };

  const handlePathToRedirectToMentionedPage = (
    notification: INotificationDTOSchema,
  ) => {
    switch (notification.origin) {
      case "ACTIVITY":
        return "/atividades";
      case "DEAL":
        return `/funil/negocio/${notification?.originId}`;
      case "PERSON":
        return `/clientes/pessoa/${notification?.originId}`;
      case "ORGANIZATION":
        return `/clientes/empresa/${notification?.originId}`;
      default:
        return "";
    }
  };

  const onChangeStatusFilter = (filter: string) => {
    setFilterStatus(filter as NotificationStatusEnum);
  };

  const handleNotificationDrawerChanges = () => {
    setHasMore(false);
    setNotifications([]);
    setPage(0);
    loader.current = null;
    drawerContainer.current = null;
  };

  const onNotificationClick = useCallback(
    (notification: INotificationDTOSchema) => {
      if (notification?.status === "UNREAD") {
        setNotificationAsRead({
          notificationId: notification?.id ?? 0,
        });
      }
      router.push(handlePathToRedirectToMentionedPage(notification));
    },
    [router, setNotificationAsRead],
  );

  const readNotifications: NotificationCenterMenuItem[] = useMemo(
    () =>
      notifications
        .filter((n) => n.status === "READ")
        .map((notification) => ({
          key: notification.id,
          onClick: () => onNotificationClick(notification),
          label: <Card.Notification notification={notification} />,
          style: { padding: 16 },
        })),
    [notifications, onNotificationClick],
  );

  const unReadNotifications: NotificationCenterMenuItem[] = useMemo(
    () =>
      notifications
        .filter((n) => n.status === "UNREAD")
        .map((notification) => ({
          key: notification.id,
          onClick: () => onNotificationClick(notification),
          label: <Card.Notification notification={notification} />,
          style: { padding: 16 },
        })),
    [notifications, onNotificationClick],
  );

  const isLoadingNotificationCenter =
    isLoadingNotifications || isRefethingNotifications;

  useEffect(() => {
    if (
      !open ||
      isLoadingNotificationCenter ||
      !hasMore ||
      !loader.current ||
      !drawerContainer.current
    )
      return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setPage((prevPage) => prevPage + 1);
        }
      },
      {
        threshold: 1,
        root: drawerContainer?.current,
      },
    );

    const loaderCurrent = loader.current;

    if (loaderCurrent) {
      observer.observe(loaderCurrent);
    }

    return () => {
      if (loaderCurrent) {
        observer.unobserve(loaderCurrent);
      }
    };
  }, [hasMore, isLoadingNotificationCenter, open]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      zIndex={1000}
      closable={false}
      width="100%"
      destroyOnClose
      afterOpenChange={(open) => {
        if (!open) handleNotificationDrawerChanges();
      }}
      styles={{
        body: {
          padding: 0,
        },
      }}
    >
      <Flex
        style={{ height: 56, width: "100%", padding: "1rem" }}
        align="center"
        justify="space-between"
      >
        <StyledButton onClick={onClose} icon={<ArrowLeft />} />
        <NotificationHeader>Notificações</NotificationHeader>
        <StyledButton icon={<IoIosNotifications />} />
      </Flex>

      <Divider style={{ margin: "0 0 0.5rem 0" }} />
      <RadioGroup.NotificationCenterType
        onChange={(e) => onChangeStatusFilter(e.target.value)}
        style={{ padding: "0 16px" }}
      />
      <Flex ref={drawerContainer} vertical>
        {isLoadingNotificationCenter ? (
          <List
            split={false}
            header={
              <Skeleton
                avatar={false}
                style={{ padding: "8px 16px" }}
                title={false}
                active
                paragraph={{ rows: 1 }}
              />
            }
            dataSource={[1, 2, 3, 4, 5, 6, 7, 8]}
            renderItem={() => (
              <Skeleton
                avatar
                style={{ padding: 16 }}
                title={false}
                active
                paragraph={{ rows: 3 }}
              />
            )}
          />
        ) : (
          <>
            <List
              split={false}
              header={
                <SolarzTypography
                  hierarchy="paragraph2"
                  fontWeight="semiBold"
                  style={{
                    color: "var(--gray-700)",
                    fontSize: 14,
                    lineHeight: "19.6px",
                    padding: "0 16px",
                  }}
                >
                  Novas
                </SolarzTypography>
              }
              dataSource={unReadNotifications}
              renderItem={(notification) => (
                <List.Item key={notification.key} style={{ padding: 16 }}>
                  {notification.label}
                </List.Item>
              )}
            />

            {readNotifications.length > 0 && (
              <List
                split={false}
                header={
                  <SolarzTypography
                    hierarchy="paragraph2"
                    fontWeight="semiBold"
                    style={{
                      color: "var(--gray-700)",
                      fontSize: 14,
                      lineHeight: "19.6px",
                      padding: "0 16px",
                    }}
                  >
                    Anteriores
                  </SolarzTypography>
                }
                dataSource={readNotifications}
                renderItem={(notification) => (
                  <List.Item key={notification.key} style={{ padding: 16 }}>
                    {notification.label}
                  </List.Item>
                )}
              />
            )}
          </>
        )}
        <div ref={loader} />
      </Flex>
    </Drawer>
  );
}
