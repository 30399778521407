import styled from "@emotion/styled";
import { Tabs } from "antd";

export const StyledTabs = styled(Tabs)`
  // MOBILE
  @media (max-width: 768px) {
    .ant-tabs-nav-list {
      width: 100% !important;
    }

    .ant-tabs-tab {
      width: 100% !important;
      display: flex;
      justify-content: center;
      margin: 0 !important;
    }
  }
`;
