import Link from "next/link";
import { useRouter } from "next/router";

import { House } from "~estrela-ui/icons";
import { parseCapitalized } from "~utils/parse";
import { parseUrlPathToAccentedWord } from "~utils/parseUrlPathToAccentedWord";
import { Breadcrumb } from "antd";

function clearString(text: string) {
  const formattedText = text.replaceAll("/", "").replaceAll("-", " ");
  const capitalizedText = parseCapitalized(formattedText);

  return parseUrlPathToAccentedWord(capitalizedText);
}

export function AdminBreadcrumb() {
  const router = useRouter();

  const routes = router?.asPath
    .split("?")[0]
    .split("/")
    .filter((route) => route && route !== "admin")
    .map((route) => `/${route}`);

  const items = routes.map((route, index) => {
    const urlString = routes.slice(0, index + 1).join("");

    const href = `/admin${urlString}`;

    return {
      title: <Link href={href}>{clearString(route)}</Link>,
    };
  });

  return (
    <Breadcrumb
      data-cy="admin-breadcrumb"
      items={[
        {
          title: (
            <Link href="/admin">
              <House />
              {router?.asPath === "/" ? " Dashboard" : ""}
            </Link>
          ),
        },
        ...items,
      ]}
    />
  );
}
