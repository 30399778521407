import { useEffect, useState } from "react";
import {
  AiOutlineMinusCircle,
  AiOutlinePlus,
  AiOutlineQuestionCircle,
  AiOutlineDown,
  AiOutlineUp,
} from "react-icons/ai";

import { useRouter } from "next/router";

import type {
  IComplementaryItemsOfSettingsFormProps,
  IComplementaryItemCalculationScaleItemFormData,
} from "./interface";

import { CustomInput } from "~components/CustomInput";
import { Mentions } from "~components/Mentions";
import { PercentageInfo } from "~components/PercentageInfo";
import { Radio } from "~components/Radio";
import { Select } from "~components/Select";
import {
  useFetchCreateComplementaryItemsOfSettings,
  useFetchUpdateComplementaryItemsOfSettings,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { useSession } from "~hooks/useSession";
import { IAdditionalCostFormSchema } from "~types/schemas";
import { indentTextAreaCode } from "~utils/indentTextAreaCode";
import {
  Button,
  Col,
  Form,
  Radio as RadioAntd,
  Row,
  Select as SelectAntd,
  Space,
  Tooltip,
  Checkbox,
  Spin,
  Alert,
  Flex,
} from "antd";

const optionsBaseCalculation = {
  BasisProfit: [
    {
      value: "CUSTOS_COMPLEMENTARES",
      label: "Custos Complementares(R$)",
    },
    {
      value: "VALOR_TOTAL",
      label: "Valor Total (R$)",
    },
  ],
  BasisItemKit: [
    {
      value: "VALOR_DO_MATERIAL",
      label: "Custo do material (R$)",
    },
  ],
  All: [
    {
      value: "CUSTOS_COMPLEMENTARES",
      label: "Custos Complementares(R$)",
    },
    {
      value: "VALOR_DO_MATERIAL",
      label: "Custo do material (R$)",
    },
    {
      value: "VALOR_TOTAL",
      label: "Valor Total (R$)",
    },
  ],
};

export function ComplementaryItemsOfSettingsForm({
  initialValues,
  onSuccess = () => undefined,
  onError = () => undefined,
  onCancel = () => undefined,
}: IComplementaryItemsOfSettingsFormProps) {
  const { screens } = useAppConfig();
  const { user } = useSession();

  const [form] = Form.useForm<IAdditionalCostFormSchema>();
  const router = useRouter();

  const [showMoreOptions, setShowMoreOptions] = useState(false);

  const [isLoadingCalculationBasis, setIsLoadingCalculationBasis] =
    useState<boolean>(false);

  const [scaleItem, setScaleItem] = useState<
    IComplementaryItemCalculationScaleItemFormData[] | undefined
  >();

  const isEditForm = !!initialValues?.id;

  const isCheckedPricingRanges =
    initialValues?.valueFlexibilityType === "SCALED" ? true : false;

  const calculationType = Form.useWatch("calculationType", form);

  const baseCalculationType = Form.useWatch("baseCalculationType", form);

  const applyTax = Form.useWatch("notApplyDefaultAdditionalCostTax", form);

  const conditional = Form.useWatch("conditional", form);

  const costGroup = Form.useWatch("costGroup", form);

  const scaleType = Form.useWatch("scaleType", form);

  // const costAggregatorId = Form.useWatch("costAggregatorIds", form);

  const [checkPricingRanges, setCheckPricingRanges] = useState<boolean>(
    isCheckedPricingRanges ?? false,
  );

  const [valueAdditionalCostTaxId, setValueAdditionalCostTaxId] =
    useState<number>();

  function handleChangeTabAuxiliaryVariables(key: string) {
    router.replace(
      {
        query: { ...router.query, tab: key },
      },
      undefined,
      { shallow: true },
    );
  }

  const {
    mutate: createComplementaryItems,
    isLoading: isCreatingComplementaryItems,
  } = useFetchCreateComplementaryItemsOfSettings({
    options: {
      onSuccess: (data) => {
        if (data.isAuxVariable) {
          handleChangeTabAuxiliaryVariables("variaveis-auxiliares");
        }
        onSuccess();
      },
      onError: (error) => {
        onError(error.message);
      },
    },
  });

  const {
    mutate: fetchUpdateComplementaryItems,
    isLoading: isUpdateComplementaryItems,
  } = useFetchUpdateComplementaryItemsOfSettings({
    options: {
      onSuccess: (data) => {
        if (data.isAuxVariable) {
          handleChangeTabAuxiliaryVariables("variaveis-auxiliares");
        }
        onCancel();
      },
    },
  });

  async function handleSubmitForm(data: IAdditionalCostFormSchema) {
    const valueFlexibilityType = checkPricingRanges ? "SCALED" : "FIXED";

    data.executionOrder = data.executionOrder ?? 1;

    const baseCalculationType =
      data?.calculationType === "MULTIPLICADOR"
        ? data.scaleType
        : data.baseCalculationType;

    const payload = {
      ...data,
      valueFlexibilityType,
      baseCalculationType,
    } as const;

    if (initialValues?.id) {
      fetchUpdateComplementaryItems({
        ...payload,
        additionalCostId: initialValues?.id,
      });
    } else {
      createComplementaryItems(payload);
    }
  }

  const isLoading = isCreatingComplementaryItems || isUpdateComplementaryItems;

  const toggleMoreOptions = () => {
    setShowMoreOptions(!showMoreOptions);
  };

  const identifierFieldIsDisabled =
    initialValues?.identifier === "cc_lucro" || initialValues?.fixed;

  useEffect(() => {
    setIsLoadingCalculationBasis(true);

    const timeout = setTimeout(() => {
      setIsLoadingCalculationBasis(false);
    }, 200);
    return () => clearTimeout(timeout);
  }, [costGroup]);

  useEffect(() => {
    if (initialValues?.scale && initialValues.scale.length > 0) {
      setScaleItem(initialValues.scale);
      form.setFieldValue("scale", initialValues.scale);
    } else {
      const scale = [{ id: undefined, key: undefined, value: 0, minValue: 0 }];
      setScaleItem(scale);
      form.setFieldValue("scale", scale);
    }
  }, [checkPricingRanges, form, initialValues?.scale]);

  useEffect(() => {
    if (
      initialValues?.additionalCostTaxId ||
      (initialValues?.costAggregators?.length ?? 0) > 0 ||
      initialValues?.ignoreViewPricePermission ||
      initialValues?.conditional
    ) {
      setShowMoreOptions(true);
    }
  }, [
    initialValues?.additionalCostTaxId,
    initialValues?.conditional,
    initialValues?.costAggregators,
    initialValues?.ignoreViewPricePermission,
  ]);

  return (
    <Form
      data-cy="complementary-items-of-settings"
      form={form}
      layout="vertical"
      onFinish={handleSubmitForm}
      onValuesChange={(e, v) => {
        setScaleItem(v.scale);
        if (v?.scale?.length === 0) {
          setScaleItem(undefined);
          form.setFieldValue("scale", undefined);
        }
      }}
      initialValues={{
        ...initialValues,
        scale: initialValues?.scale ?? [
          { id: undefined, key: undefined, value: 0, minValue: 0 },
        ],
        calculationType: initialValues?.calculationType ?? "PERCENTUAL",
        minValue: initialValues?.minValue ?? 0,
        notApplyDefaultAdditionalCostTax:
          initialValues?.notApplyDefaultAdditionalCostTax ?? true,
        conditional: initialValues?.conditional ?? false,
        costGroup: initialValues?.costGroup ?? "CUSTOS_COMPLEMENTARES",
        costAggregatorIds:
          initialValues?.costAggregators?.map(
            (costAggregator) => costAggregator?.id,
          ) ?? [],
      }}
    >
      {/* HEAD */}
      <Flex align="center" gap={12} justify="space-between">
        {calculationType === "PERCENTUAL" &&
          baseCalculationType !== "VALOR_DO_MATERIAL" &&
          costGroup === "CUSTOS_COMPLEMENTARES" && <PercentageInfo />}
      </Flex>

      {/* FORM CONTENT */}
      <Flex>
        <Col xs={24} xl={24}>
          <Row gutter={5}>
            <Col xs={24} xl={8}>
              <Form.Item label="Nome" name="name" rules={[{ required: true }]}>
                <CustomInput.Text data-cy="name" placeholder="Nome" />
              </Form.Item>
            </Col>
            <Col xs={24} xl={8}>
              <Form.Item
                label="Identificador"
                name="identifier"
                hasFeedback
                rules={[
                  {
                    pattern: new RegExp(/^[A-Za-z_-]*$/),
                    message: "Apenas letras, underlines ou traços",
                  },
                ]}
                tooltip={`Esse campo é um campo único, utilizado para referenciar o custo${
                  identifierFieldIsDisabled
                    ? " (não se pode alterar o identificador dos campos fixados)"
                    : ""
                }.`}
              >
                <CustomInput.Text
                  placeholder="Identificador"
                  disabled={identifierFieldIsDisabled}
                />
              </Form.Item>
            </Col>
            <Col xs={24} xl={8}>
              <Form.Item name="pricingType" label="Precificação">
                <Select.PricingType
                  style={{ width: "300px" }}
                  isDisabled={true}
                  scale="tiny"
                  onLoad={(value) => {
                    if (value) {
                      form.setFieldValue("pricingType", value);
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={5}>
            <Col xs={24} xl={calculationType !== "VALOR_FIXO" ? 8 : 12}>
              <Form.Item
                name="calculationType"
                label="Selecione a operação:"
                rules={[{ required: true }]}
              >
                <SelectAntd
                  placeholder="Selecione uma opção..."
                  options={[
                    { value: "PERCENTUAL", label: "Percentual (%)" },
                    {
                      value: "VALOR_FIXO",
                      label: "Fixo / Real (R$)",
                      disabled:
                        initialValues?.fixed &&
                        initialValues?.identifier === "cc_lucro"
                          ? true
                          : false,
                    },
                    {
                      value: "MULTIPLICADOR",
                      label: "Multiplicador",
                      disabled:
                        initialValues?.fixed &&
                        initialValues?.identifier === "cc_lucro"
                          ? true
                          : false,
                    },
                    {
                      value: "EXPRESSAO_PERSONALIZADA",
                      label: "Expressão personalizada",
                      disabled:
                        initialValues?.fixed &&
                        initialValues?.identifier === "cc_lucro"
                          ? true
                          : false,
                    },
                    // {
                    //   value: "PERCENTUAL_AGREGADOR_CUSTO",
                    //   label: "Agregador de custo",
                    //   disabled:
                    //     initialValues?.fixed &&
                    //     initialValues?.identifier === "cc_lucro"
                    //       ? true
                    //       : false,
                    // },
                  ]}
                  onSelect={(value: any) => {
                    setCheckPricingRanges(false);

                    form.resetFields(["scaleType", "scale"]);

                    if (value === "EXPRESSAO_PERSONALIZADA") {
                      form.resetFields(["customExpression"]);
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} xl={calculationType !== "VALOR_FIXO" ? 8 : 12}>
              <Form.Item
                label="Valor"
                name="value"
                rules={[
                  {
                    required:
                      calculationType === "EXPRESSAO_PERSONALIZADA"
                        ? false
                        : !checkPricingRanges,
                  },
                ]}
              >
                {calculationType === "PERCENTUAL" ||
                calculationType === "PERCENTUAL_AGREGADOR_CUSTO" ? (
                  <CustomInput.UnitMeasurement
                    placeholder="Valor"
                    precision="2"
                    unitMeasurement="%"
                    min={0}
                    disabled={checkPricingRanges}
                    data-cy="value"
                  />
                ) : (
                  <CustomInput.Money
                    placeholder="Valor"
                    unit="R$"
                    style={{ width: "100%" }}
                    disabled={
                      calculationType === "EXPRESSAO_PERSONALIZADA"
                        ? true
                        : checkPricingRanges
                    }
                  />
                )}
              </Form.Item>
            </Col>
            {calculationType !== "VALOR_FIXO" && (
              <Col xs={24} xl={8}>
                <Form.Item
                  label="Valor mínimo"
                  name="minValue"
                  tooltip="Valor mínimo considerado após o cálculo do item complementar no valor da proposta."
                  rules={[
                    {
                      required:
                        calculationType === "EXPRESSAO_PERSONALIZADA"
                          ? false
                          : !checkPricingRanges,
                    },
                  ]}
                >
                  <CustomInput.UnitMeasurement
                    placeholder="Valor"
                    precision="2"
                    data-cy="minimum-value"
                    unitMeasurement={
                      initialValues?.identifier === "cc_lucro" ? "%" : "R$"
                    }
                    min={0}
                    disabled={
                      calculationType === "EXPRESSAO_PERSONALIZADA"
                        ? true
                        : checkPricingRanges
                    }
                  />
                </Form.Item>
              </Col>
            )}
            {calculationType === "PERCENTUAL_AGREGADOR_CUSTO" && (
              <Col xs={24}>
                <Form.Item
                  label="Agregador"
                  name="aggregatorCostId"
                  rules={[
                    {
                      required:
                        calculationType === "PERCENTUAL_AGREGADOR_CUSTO",
                    },
                  ]}
                >
                  <Select.CostAggregator />
                </Form.Item>
              </Col>
            )}
          </Row>

          <Row gutter={15}>
            {/* Add calculation base field to the complementary cost form when the selected operation is PERCENTAGE */}
            {(calculationType === "PERCENTUAL" ||
              calculationType === "PERCENTUAL_AGREGADOR_CUSTO") && (
              <Col xs={24} xl={12}>
                <Form.Item
                  name="baseCalculationType"
                  label="Base de Cálculo"
                  rules={[{ required: true }]}
                >
                  <RadioAntd.Group data-cy="radio-button">
                    <Spin spinning={isLoadingCalculationBasis}>
                      <Space direction="vertical">
                        {costGroup === "KIT" &&
                        !initialValues?.fixed &&
                        !(initialValues?.identifier === "cc_lucro")
                          ? optionsBaseCalculation["BasisItemKit"].map(
                              (item, index) => (
                                <RadioAntd
                                  key={index}
                                  value={item.value}
                                  style={{ display: "flex" }}
                                >
                                  {item.label}
                                </RadioAntd>
                              ),
                            )
                          : initialValues?.fixed &&
                              initialValues?.identifier === "cc_lucro"
                            ? optionsBaseCalculation["BasisProfit"].map(
                                (item, index) => (
                                  <RadioAntd
                                    key={index}
                                    value={item.value}
                                    style={{ display: "flex" }}
                                    data-cy=""
                                  >
                                    {item.label}
                                  </RadioAntd>
                                ),
                              )
                            : optionsBaseCalculation["All"].map(
                                (item, index) => (
                                  <RadioAntd
                                    key={index}
                                    value={item.value}
                                    style={{ display: "flex" }}
                                  >
                                    {item.label}
                                  </RadioAntd>
                                ),
                              )}
                      </Space>
                    </Spin>
                  </RadioAntd.Group>
                </Form.Item>
              </Col>
            )}
            {calculationType === "MULTIPLICADOR" &&
              user?.projectType !== "DIVERSE" && (
                <Col xs={24} xl={12}>
                  <Form.Item
                    name="scaleType"
                    label="Multiplicar"
                    rules={[{ required: true }]}
                  >
                    <Radio.CalculationScaleTypesComplementaryItems
                      valueType={calculationType}
                      costGroup={costGroup}
                    />
                  </Form.Item>
                </Col>
              )}

            <Col
              xs={24}
              xl={12}
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <Form.Item
                label="Grupo do custo"
                rules={[{ required: true }]}
                name="costGroup"
              >
                <RadioAntd.Group
                  onChange={() => {
                    if (initialValues && initialValues?.fixed) {
                      form.resetFields(["scaleType"]);
                    } else {
                      form.resetFields(["baseCalculationType", "scaleType"]);
                    }
                  }}
                >
                  <Space direction="vertical">
                    <RadioAntd
                      value="CUSTOS_COMPLEMENTARES"
                      style={{ display: "flex" }}
                    >
                      Custos Complementares
                    </RadioAntd>
                    {initialValues?.identifier !== "cc_lucro" && (
                      <>
                        <RadioAntd value="KIT" style={{ display: "flex" }}>
                          Kit
                        </RadioAntd>
                        <RadioAntd
                          value="INSIGHTS"
                          style={{
                            display: "flex",
                          }}
                        >
                          <Space>
                            Insights
                            <Tooltip title="Cálculos sobre dados da proposta que não interferem no preço, servem apenas para dar mais informações.">
                              <AiOutlineQuestionCircle
                                size={18}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              />
                            </Tooltip>
                          </Space>
                        </RadioAntd>
                      </>
                    )}
                  </Space>
                </RadioAntd.Group>
              </Form.Item>
            </Col>
          </Row>
          {calculationType === "EXPRESSAO_PERSONALIZADA" && (
            <Row gutter={15}>
              <Col sm={24} md={12}>
                <Form.Item name="executionOrder" label="Ordem de execução">
                  <CustomInput.Number min={0} precision={0} />
                </Form.Item>
              </Col>
              <Col
                sm={24}
                md={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "24px",
                }}
              >
                <Form.Item name="isAuxVariable" valuePropName="checked">
                  <Checkbox
                    onChange={(e) =>
                      form.setFieldValue("isAuxVariable", !!e.target.checked)
                    }
                    defaultChecked={!!initialValues?.isAuxVariable}
                  >
                    É uma variável auxiliar?
                  </Checkbox>
                </Form.Item>
                <Tooltip title="Ao marcar como variável auxiliar não interfere diretamente na precificação, pode ser usado em outros custos">
                  <AiOutlineQuestionCircle
                    size={16}
                    style={{ marginTop: -28 }}
                  />
                </Tooltip>
              </Col>
            </Row>
          )}
          <Row>
            {calculationType === "EXPRESSAO_PERSONALIZADA" && (
              <Col xs={24} xl={24}>
                <Form.Item
                  style={{
                    width: "100%",
                  }}
                  name="customExpression"
                  label={
                    <Flex
                      gap={0}
                      align="center"
                      justify="space-between"
                      style={{ width: "100%" }}
                    >
                      <div>
                        <span>Expressão personalizada</span>

                        <Tooltip title="Trecho de código javascript.">
                          <AiOutlineQuestionCircle
                            size={18}
                            style={{ marginLeft: 4 }}
                          />
                        </Tooltip>
                      </div>
                    </Flex>
                  }
                  help={
                    <Alert
                      style={{ margin: "0.5rem 0" }}
                      message="Para expressões javascript com condicionais o 'return' deve ser informado, e não utilizar loopings."
                    />
                  }
                  extra={
                    <Alert
                      style={{ marginBottom: "0.5rem" }}
                      message='Para a utilização de números decimais utilize "." ao invés de "," (ponto ao invés de virgula)'
                    />
                  }
                >
                  <Mentions.Variables
                    initialValueField={indentTextAreaCode(
                      initialValues?.customExpression ?? "",
                    )}
                    rows={7}
                    onChange={(value) =>
                      form.setFieldValue("customExpression", value)
                    }
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row>
            <Col xs={24} xl={24}>
              <Form.Item>
                <Button
                  type="link"
                  onClick={toggleMoreOptions}
                  style={{ paddingLeft: 0 }}
                  data-cy="more-or-less-options-button"
                >
                  <Flex gap={2} align="center">
                    {showMoreOptions ? "Menos opções" : "Mais opções"}
                    {showMoreOptions ? <AiOutlineUp /> : <AiOutlineDown />}
                  </Flex>
                </Button>
              </Form.Item>
            </Col>
          </Row>
          {showMoreOptions && (
            <>
              <Row>
                <Form.Item name="ignoreViewPricePermission">
                  <Checkbox
                    defaultChecked={!!initialValues?.ignoreViewPricePermission}
                    onChange={(e) =>
                      form.setFieldValue(
                        "ignoreViewPricePermission",
                        !!e.target.checked,
                      )
                    }
                  >
                    <Flex align="center" gap={4} wrap="nowrap">
                      Ignorar regra de ver o item complementar?
                      <Tooltip title="Ao marcar esse campo a regra de visualizar o item complementar será ignorada para esse item específico, comumente utilizado para mostrar a comissão do vendedor em questão. Utilizando essa opção você pode esconder todas as informações dos custos complementares e ainda assim mostrar a comissão.">
                        <AiOutlineQuestionCircle
                          size={16}
                          style={{
                            color: "var(--blue-500)",
                          }}
                        />
                      </Tooltip>
                    </Flex>
                  </Checkbox>
                </Form.Item>
              </Row>
              {costGroup !== "KIT" && (
                <Row gutter={5}>
                  <Col xs={24} xl={6}>
                    <Form.Item
                      label="Aplicar Imposto pós precificado"
                      tooltip="Alíquota aplicada por fora ao final dos cálculos de precificação, útil quando há alíquotas de imposto diferente nos itens complementares ou quando há custos precificado em % sobre o global e custos em % precificados sobre os custos complementares simultaneamente e ainda seja necessário cálculo de imposto"
                      name="notApplyDefaultAdditionalCostTax"
                    >
                      <RadioAntd.Group
                        onChange={(e) => {
                          if (e.target.value === true) {
                            form.resetFields(["additionalCostTaxId"]);
                            setValueAdditionalCostTaxId(undefined);
                          }
                        }}
                      >
                        <Space>
                          <RadioAntd value={false}>Sim</RadioAntd>
                          <RadioAntd value={true}>Não aplicar</RadioAntd>
                        </Space>
                      </RadioAntd.Group>
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={12}>
                    <Form.Item
                      label="Escolher imposto pós precificado"
                      rules={[{ required: !applyTax }]}
                      name="additionalCostTaxId"
                    >
                      <Select.TaxesAfterPricing
                        value={valueAdditionalCostTaxId}
                        onChange={(e) => setValueAdditionalCostTaxId(e)}
                        isEditForm={isEditForm}
                        disabled={applyTax}
                        applyTax={applyTax}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Row>
                <Col xs={24} xl={12}>
                  <Form.Item label="Aplicar" name="conditional">
                    <RadioAntd.Group>
                      <Space>
                        <RadioAntd value={false}>Sempre</RadioAntd>
                        <RadioAntd value={true}>
                          Se condição for verdadeira
                        </RadioAntd>
                      </Space>
                    </RadioAntd.Group>
                  </Form.Item>
                </Col>
                {conditional && (
                  <Col xs={24} xl={24}>
                    <Form.Item
                      name="customConditional"
                      label="Condição"
                      rules={[{ required: true }]}
                      tooltip="Esse custo será adicionado caso a condicional fornecida for satisfeita. Não é possível referenciar outras variáveis customizadas nem itens complementares, apenas os campos do projeto, incluindo informações do kit"
                      help="Use o caractere $ seguido de pelos menos 3 caracteres para buscar uma variável. Ex: $var"
                    >
                      <Mentions.Variables
                        placeholder="Escreva seu código aqui..."
                        rows={6}
                        initialValueField={indentTextAreaCode(
                          initialValues?.customConditional ?? "",
                        )}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
              <Row>
                <Col xs={24} xl={12}>
                  <Form.Item
                    label="Escolher grupo de agregador de custo"
                    name="costAggregatorIds"
                    tooltip={
                      'Agregadores de custo são utilizados para referenciar grupos de custo dentro de expressões personalizadas. Exemplo: se você tiver 5 custos que somados compõe a mão de obra, você pode indicar que eles pertencem ao grupo de custo "mão de obra" e usar o identificador do grupo para referenciar o somatório desse grupo dentro de uma expressão customizada.'
                    }
                  >
                    <Select.CostAggregator
                      mode="multiple"
                      // onLoad={(costs) => {
                      //   const recordExists = costs?.find(
                      //     (costAggregator) =>
                      //       costAggregator.id === costAggregatorId,
                      //   );

                      //   form.setFieldValue(
                      //     "costAggregatorId",
                      //     recordExists?.id,
                      //   );
                      // }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          {calculationType !== "EXPRESSAO_PERSONALIZADA" && (
            <Row>
              <Form.Item name="valueFlexibilityType">
                <Checkbox
                  onChange={(e) => {
                    setCheckPricingRanges(e.target.checked);
                    form.resetFields(["value", "minValue", "scale"]);
                    setScaleItem(
                      initialValues
                        ? initialValues?.scale
                        : [
                            {
                              id: undefined,
                              key: undefined,
                              value: 0,
                              minValue: 0,
                            },
                          ],
                    );
                  }}
                  checked={checkPricingRanges}
                >
                  <Space>
                    Usar faixas de precificação
                    <Tooltip title="Ao marcar esse campo diretamente os campos de Valor e Valor mínimo serão limpos">
                      <AiOutlineQuestionCircle
                        size={18}
                        style={{ display: "flex", alignItems: "center" }}
                      />
                    </Tooltip>
                  </Space>
                </Checkbox>
              </Form.Item>
            </Row>
          )}

          {checkPricingRanges &&
            calculationType !== "MULTIPLICADOR" &&
            user?.projectType !== "DIVERSE" && (
              <Form.Item
                name="scaleType"
                label="Tipo de Escala"
                rules={[{ required: true }]}
              >
                <Radio.CalculationScaleTypesComplementaryItems
                  valueType={calculationType}
                  costGroup={costGroup}
                />
              </Form.Item>
            )}
          {checkPricingRanges &&
            calculationType !== "MULTIPLICADOR" &&
            scaleType === "EXPRESSAO_PERSONALIZADA" && (
              <Form.Item
                name="customExpressionForScale"
                label="Expressão personalizada para escala"
                rules={[{ required: true }]}
              >
                <Mentions.Variables
                  initialValueField={initialValues?.customExpressionForScale}
                  rows={7}
                  onChange={(value) =>
                    form.setFieldValue("customExpressionForScale", value)
                  }
                />
              </Form.Item>
            )}
          {checkPricingRanges && scaleType !== "EXPRESSAO_PERSONALIZADA" && (
            <div
              style={{
                overflowY: "visible",
                overflowX: "hidden",
                maxHeight: screens.xs ? "unset" : "300px",
                marginBottom: "1rem",
              }}
            >
              <Form.List name="scale">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      {fields.map((field, index) => (
                        <Row
                          key={field.key}
                          gutter={11}
                          style={{ textAlign: "left" }}
                        >
                          <Col
                            xs={24}
                            md={11}
                            lg={
                              calculationType === "MULTIPLICADOR" ||
                              calculationType === "PERCENTUAL" ||
                              calculationType === "PERCENTUAL_AGREGADOR_CUSTO"
                                ? 9
                                : 12
                            }
                          >
                            <Form.Item
                              {...field}
                              label="Faixa"
                              name={[field.name, "key"]}
                              tooltip="Inclui o limite inferior e não o superior. Assim, de 0 a 10 se aplica até o 9.9999, o 10 está fora."
                              rules={[
                                {
                                  required: true,
                                  message: "Por favor, insira o valor da faixa",
                                },
                              ]}
                            >
                              <CustomInput.Number
                                prefix={`De ${
                                  scaleItem?.length &&
                                  scaleItem.length > 1 &&
                                  index !== 0
                                    ? scaleItem?.[index - 1]?.key ?? 0 + 1
                                    : 0
                                } até `}
                                min={
                                  scaleItem?.length &&
                                  scaleItem?.length > 1 &&
                                  index !== 0
                                    ? scaleItem[index - 1].key
                                    : 0
                                }
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col
                            xs={24}
                            md={11}
                            lg={
                              calculationType === "MULTIPLICADOR" ||
                              calculationType === "PERCENTUAL" ||
                              calculationType === "PERCENTUAL_AGREGADOR_CUSTO"
                                ? 7
                                : 11
                            }
                          >
                            <Form.Item
                              {...field}
                              name={[field.name, "value"]}
                              label="Valor por faixa"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              {calculationType === "PERCENTUAL" ||
                              calculationType ===
                                "PERCENTUAL_AGREGADOR_CUSTO" ? (
                                <CustomInput.UnitMeasurement
                                  precision="2"
                                  unitMeasurement="%"
                                  min={0}
                                  max={100}
                                />
                              ) : (
                                <CustomInput.Money />
                              )}
                            </Form.Item>
                          </Col>
                          {calculationType !== "VALOR_FIXO" && (
                            <Col xs={24} md={11} lg={7}>
                              <Form.Item
                                {...field}
                                name={[field.name, "minValue"]}
                                label="Valor mínimo"
                                initialValue={0}
                                rules={[
                                  {
                                    required: true,
                                  },
                                ]}
                              >
                                <CustomInput.Money />
                              </Form.Item>
                            </Col>
                          )}

                          <Col
                            xs={24}
                            md={2}
                            lg={1}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              disabled={
                                fields.length === 1 || index < fields.length - 1
                              }
                              type="text"
                              onClick={() => remove(field.name)}
                              icon={<AiOutlineMinusCircle />}
                              style={{ marginTop: "1rem" }}
                            />
                          </Col>
                        </Row>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          disabled={
                            scaleItem &&
                            scaleItem[scaleItem.length - 1]?.key === undefined
                          }
                        >
                          <Flex align="center" justify="center" gap={4}>
                            <AiOutlinePlus />
                            Nova Faixa
                          </Flex>
                        </Button>
                      </Form.Item>
                    </>
                  );
                }}
              </Form.List>
            </div>
          )}
        </Col>
      </Flex>

      <Flex gap={12} justify="end">
        <Form.Item style={{ margin: 0 }}>
          <Button onClick={onCancel}>Cancelar</Button>
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button
            data-cy="save-button"
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            Salvar
          </Button>
        </Form.Item>
      </Flex>
    </Form>
  );
}
