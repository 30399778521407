import { AiOutlineLock, AiOutlineUser } from "react-icons/ai";

import type {
  IGTSolarIntegrationFormProps,
  IGTSolarIntegrationFormValues,
} from "./interface";

import { CustomInput } from "~components/CustomInput";
import { Button, Flex, Form, message } from "antd";

export function GTSolarIntegrationForm({
  initialValues = {
    integratorId: 0,
    password: "",
    user: "",
  },
  onCancel = () => undefined,
}: IGTSolarIntegrationFormProps) {
  function handleSubmitForm(formValues: IGTSolarIntegrationFormValues) {
    message.info(
      "Necessário integrar com a API, dados recebidos: " +
        JSON.stringify(formValues),
    );
  }

  return (
    <Form
      data-cy="gt-solar-integration-form"
      onFinish={handleSubmitForm}
      initialValues={initialValues}
      layout="vertical"
      style={{ width: "100%" }}
    >
      <Form.Item
        name="user"
        rules={[
          {
            required: true,
            message: "Usuário é obrigatório",
          },
        ]}
        initialValue={initialValues?.user}
      >
        <CustomInput.Text prefix={<AiOutlineUser />} placeholder="Usuário" />
      </Form.Item>
      <Form.Item
        name="password"
        required
        rules={[
          {
            required: true,
            message: "Senha é obrigatório",
          },
        ]}
        initialValue={initialValues?.password}
      >
        <CustomInput.Text
          prefix={<AiOutlineLock />}
          type="password"
          placeholder="Senha"
        />
      </Form.Item>

      <Flex align="center" gap={8} justify="end">
        <Button onClick={onCancel}>Cancelar</Button>
        <Button type="primary" htmlType="submit">
          Autenticar
        </Button>
      </Flex>
    </Form>
  );
}
