import { useState } from "react";

import type { IOrganizationAutoCompleteProps } from "./interface";

import { AutoCompleteSuffixIcon } from "~components/Others";
import { Result } from "~components/Result";
import { useFetchGetAutoCompleteOrganization } from "~hooks/api";
import { AutoComplete } from "antd";

export function OrganizationAutoComplete({
  allowClear = true,
  onChange,
  onClear,
  ...props
}: IOrganizationAutoCompleteProps) {
  const [inputValue, setInputValue] = useState<string>("");

  const {
    data: organizations = [],
    isFetching,
    error,
    refetch,
  } = useFetchGetAutoCompleteOrganization({
    dependencyArray: [inputValue],
    payload: {
      query: inputValue,
    },
    options: {
      enabled: inputValue.length >= 3,
    },
  });

  const handleSearch = (value: string) => {
    setInputValue(value);
  };

  return (
    <AutoComplete
      {...props}
      data-cy="organization-auto-complete"
      showSearch
      filterOption={false}
      onSearch={handleSearch}
      options={organizations.map((organization) => ({
        value: organization.name,
        title: organization.name,
        label: organization.name,
        record: organization,
        key: organization.id,
      }))}
      allowClear={allowClear}
      status={error ? "error" : ""}
      notFoundContent={
        <Result.SelectErrorOrEmpty
          errorMessage={error?.message}
          emptyMessage="Nenhuma empresa encontrada"
          loading={isFetching}
          reloadFn={refetch}
          disableReload={inputValue.length < 3}
        />
      }
      suffixIcon={
        <AutoCompleteSuffixIcon hasError={!!error} isLoading={isFetching} />
      }
      onClear={() => {
        setInputValue("");
        if (typeof onClear === "function") {
          onClear();
        }
      }}
      onChange={(value, option) => {
        if (option instanceof Array || !onChange) return;
        onChange(value, option);
      }}
      style={{ width: "100%" }}
    />
  );
}
