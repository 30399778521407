import { useState } from "react";

import type { ILoggedUserDrawerButtonProps } from "./interface";

import { Avatar } from "~components/Avatar";
import { Drawer } from "~components/Drawer";
import { SolarzButton } from "~solarzui/SolarzButton";

export function LoggedUserDrawerButton({
  name,
  avatarUrl,
  sessionStatus,
  displayBusinessUnit = true,
}: ILoggedUserDrawerButtonProps) {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  return (
    <>
      <SolarzButton
        icon={
          <Avatar.CustomUser
            name={name}
            avatarUrl={avatarUrl}
            sessionStatus={sessionStatus}
            width={32}
            height={32}
          />
        }
        onClick={() => setIsDrawerOpen(true)}
        variant="link"
        scale="large"
      />

      <Drawer.LoggedUser
        open={isDrawerOpen}
        displayBusinessUnit={displayBusinessUnit}
        onClose={() => setIsDrawerOpen(false)}
      />
    </>
  );
}
