import { useMemo } from "react";

import {
  FortlevInverterTypeSelectProps,
  IFortlevInverterTypeOption,
} from "./interface";

import { Select } from "antd";

export function FortlevInverterTypeSelect({
  value,
  onChange,
  defaultValue,
  ...props
}: FortlevInverterTypeSelectProps) {
  const OPTIONS: IFortlevInverterTypeOption[] = useMemo(
    () => [
      {
        label: "String" as const,
        value: "STRING" as const,
      },
      {
        label: "Micro" as const,
        value: "MICRO" as const,
      },
      {
        label: "Otimizador" as const,
        value: "OPTIMIZER" as const,
      },
    ],
    [],
  );

  return (
    <Select
      {...props}
      options={OPTIONS}
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
}
