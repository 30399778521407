import { useEffect, useState } from "react";
import { AiOutlineMinusCircle, AiOutlinePlus } from "react-icons/ai";

import type {
  ITransformer,
  ITransformerGroup,
  ITransformerGroupForm,
  ITransformerGroupFormData,
  ITransformerGroupFormProps,
} from "./interface";
import styles from "./styles";

import { CustomInput } from "~components/CustomInput";
import {
  useFetchGetTransformerRulesById,
  useFetchUpdateTransformerRule,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { createUuid } from "~utils/createUuid";
import { Button, Col, Divider, Flex, Form, Popconfirm, Row, Spin } from "antd";

export function TransformerGroupForm({
  initialValues,
  onCancel = () => undefined,
  onError = () => undefined,
  onSuccess = () => undefined,
  bodyMaxHeight = 480,
  canBeEmpty = true,
}: ITransformerGroupFormProps) {
  const [form] = Form.useForm();
  const { message } = useAppConfig();

  const transformerGroupId = initialValues?.id ?? 0;

  const [transformers, setTransformers] = useState<ITransformerGroupFormData[]>(
    [],
  );

  const [initialTransformers, setInitialTransformers] = useState<
    ITransformerGroupFormData[]
  >([]);

  const { isFetching: isLoadingRules } = useFetchGetTransformerRulesById({
    payload: { ruleId: transformerGroupId },
    options: {
      enabled: !!transformerGroupId && transformerGroupId > 0,
      onSuccess(data) {
        const parsedPrices = Object.entries(data?.prices ?? {});

        const transformers: ITransformer[] = parsedPrices.map(
          ([potency, price]) => {
            return {
              price: price,
              potency: Number(potency),
            } as ITransformer;
          },
        );
        const parsedTransformers = transformers.map((transformers) => {
          return {
            key: createUuid(),
            ...transformers,
          };
        });

        setInitialTransformers(parsedTransformers);
      },
    },
  });

  function handleCreateNewFormLine() {
    const newTransform: ITransformerGroupFormData = {
      key: createUuid(),
      name: "",
      potency: 0,
      price: 0,
    };

    setTransformers((state) => [...state, newTransform]);
  }

  function handleDeleteFormLine(key: string) {
    setTransformers((state) => state.filter((item) => item.key !== key));
  }

  function handleResetForm() {
    setTransformers(initialTransformers);
  }

  function onChangeData(key: string, field: string, value: any) {
    setTransformers((state) => {
      const transformer = state.find((transformer) => transformer.key === key);

      if (!transformer) return state;

      const updatedTransform = {
        ...transformer,
        [field]: value,
      };

      return state.map((transformer) =>
        transformer.key === key ? updatedTransform : transformer,
      );
    });
  }

  const {
    mutate: updateTransformerRule,
    isLoading: isUpdatingTransformerRule,
  } = useFetchUpdateTransformerRule({
    options: {
      onSuccess: () => {
        onSuccess();
      },
      onError: (error) => {
        onError(error);
      },
    },
  });

  function handleSubmit(formValues: ITransformerGroupForm) {
    const data: ITransformerGroup = {
      transformers,
      id: initialValues?.id,
      defaultPrice: formValues.defaultPrice,
    };

    const prices = data.transformers.map((item) => item.price);
    const potencies = data.transformers.map((item) => item.potency);
    const ruleId = data.id;
    const defaultPrice = data.defaultPrice ?? 0;

    if (!ruleId) {
      message.error("Client error: Faltando o ID.");
      return;
    }

    const parsedData = prices.reduce(
      (data, price, index) => {
        const key = potencies?.[index];

        const newData = { [key]: price } as { [key: number]: number };

        return { ...data, ...newData };
      },
      {} as { [key: number]: number },
    );

    updateTransformerRule({
      id: ruleId,
      defaultPrice,
      prices: parsedData,
    });
  }

  useEffect(() => {
    setTransformers(initialTransformers);
  }, [initialTransformers, initialValues]);

  useEffect(() => {
    form.setFieldValue("defaultPrice", initialValues?.defaultPrice ?? 0);
  }, [form, initialValues]);

  return (
    <Spin
      data-cy="transformer-group-form"
      spinning={isLoadingRules}
      tip="Carregando..."
      style={{ marginTop: "-2rem" }}
    >
      <style jsx>{styles}</style>
      <Form<ITransformerGroupForm>
        form={form}
        initialValues={{
          transformers: initialTransformers,
        }}
        onFinish={handleSubmit}
        layout="vertical"
      >
        <section
          style={{
            maxHeight: bodyMaxHeight,
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <Form.Item
            name="defaultPrice"
            label="Preço padrão por kVa"
            tooltip="Esse valor será usado quando o transformador não se enquadrar em nenhuma das regras previamente cadastradas."
            rules={[{ required: true }]}
          >
            <CustomInput.Money placeholder="Ex.: 500,00" />
          </Form.Item>
          {transformers.map((transformer) => {
            return (
              <Row
                align="middle"
                wrap={false}
                justify="center"
                gutter={[16, 0]}
                key={transformer.key}
              >
                <Col flex={1}>
                  <Form.Item
                    label="Potência"
                    tooltip="O preço é calculado da faixa de potência imediamente anterior (ou 0 caso não tenha uma menor) até a potência cadastrada nesse formulário. Caso a potência seja maior do que o cadastrado aqui, usaremos a maior potência cadastrada. Ex: Temos cadastradas as potências 20 kVa, com preço de R$ 1000, e 40 kVa, com preço de R$ 2000; se a potência máxima do inversor for 19, vamos usar R$ 1000; se for 30, usaremos R$ 2000, e se for 50 usaremos R$ 2000 também."
                    rules={[{ required: true }]}
                  >
                    <CustomInput.UnitMeasurement
                      precision="2"
                      unitMeasurement="kVa"
                      placeholder="Ex.: 5,00"
                      value={transformer.potency}
                      onChange={(value) => {
                        const parsedValue = Number(value);
                        const trulyValue = isNaN(parsedValue) ? 0 : parsedValue;
                        onChangeData(transformer.key, "potency", trulyValue);
                      }}
                      min={0}
                    />
                  </Form.Item>
                </Col>

                <Col flex={1}>
                  <Form.Item label="Preço" rules={[{ required: true }]}>
                    <CustomInput.Money
                      placeholder="Ex.: 500,00"
                      value={transformer.price}
                      onChange={(value) => {
                        const parsedValue = Number(value);
                        const trulyValue = isNaN(parsedValue) ? 0 : parsedValue;
                        onChangeData(transformer.key, "price", trulyValue);
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col>
                  <Flex justify="center" align="center">
                    <Button
                      type="text"
                      size="small"
                      shape="circle"
                      style={{ lineHeight: 0 }}
                      disabled={!canBeEmpty && transformers.length === 1}
                      onClick={() => handleDeleteFormLine(transformer.key)}
                    >
                      <AiOutlineMinusCircle size={16} />
                    </Button>
                  </Flex>
                </Col>
              </Row>
            );
          })}
        </section>

        <Divider style={{ margin: "0 0 0.5rem 0" }} />

        <Flex vertical gap={0} wrap="nowrap">
          <Button
            block
            type="dashed"
            className="addRowButton"
            onClick={handleCreateNewFormLine}
          >
            <Flex align="center" justify="center" gap={8}>
              <AiOutlinePlus size={16} />
              Novo transformador
            </Flex>
          </Button>
          <Flex align="center">
            Voltar ao
            <Popconfirm
              title="Tem certeza? - As suas modificações serão desfeitas"
              onConfirm={handleResetForm}
              okText="Sim"
              cancelText="não"
            >
              <Button style={{ marginLeft: "-2px" }} type="link" size="small">
                <span style={{ textDecoration: "underline" }}>
                  estado inicial?
                </span>
              </Button>
            </Popconfirm>
          </Flex>
        </Flex>

        <Flex gap={12} justify="end" style={{ marginTop: "1.5rem" }}>
          <Form.Item style={{ margin: 0 }}>
            <Button onClick={onCancel}>Cancelar</Button>
          </Form.Item>
          <Form.Item style={{ margin: 0 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={isUpdatingTransformerRule}
            >
              Salvar
            </Button>
          </Form.Item>
        </Flex>
      </Form>
    </Spin>
  );
}
