import { useMemo, useState } from "react";

import {
  IProductsKitTableProps,
  ProductsKitTableColumnType,
} from "./interface";

import { createPaginationTableObject } from "~components/Pagination";
import { Result } from "~components/Result";
import { useFetchGetPaginatedKits } from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { parseCurrency } from "~utils/parse";
import { Table } from "antd";

export function ProductsKitsTable({ size = "small" }: IProductsKitTableProps) {
  const { screens } = useAppConfig();

  const [currentPage, setCurrentPage] = useState(0);

  const {
    data,
    isLoading: isKitsLoading,
    error: kitsError,
  } = useFetchGetPaginatedKits({
    dependencyArray: [currentPage],
    payload: {
      page: currentPage,
    },
  });

  const DATA_SOURCE = useMemo(() => {
    return data?.content ?? [];
  }, [data?.content]);

  const columns: ProductsKitTableColumnType = [
    {
      title: "Identificador",
      dataIndex: "identifier",
      key: "identifier",
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Distribuidor",
      dataIndex: "distributor",
      key: "distributor",
    },
    {
      title: "Tensão",
      dataIndex: "voltage",
      key: "voltage",
    },
    {
      title: "Potência",
      dataIndex: "powerKwp",
      key: "powerKwp",
      render: (i) => i + "Kwp",
    },
    {
      title: "N. Itens Extra",
      dataIndex: "customPvModulesCount",
      key: "customPvModulesCount",
    },
    {
      title: "Preço",
      dataIndex: "totalPriceCents",
      key: "totalPriceCents",
      render: (item) => parseCurrency(item / 100),
    },
  ];

  return (
    <Table
      data-cy="products-kits-table"
      rowKey="id"
      pagination={createPaginationTableObject({
        onChange: (page) => setCurrentPage(page - 1),
        size: data?.size,
        disabled: false,
        itemType: "kits",
        number: data?.number ? data?.number + 1 : 1,
        totalElements: data?.totalElements,
        displayTotalInformation: screens.sm,
      })}
      loading={isKitsLoading}
      scroll={{ x: 720 }}
      style={{ width: "100%" }}
      columns={columns}
      dataSource={DATA_SOURCE}
      size={size}
      locale={{
        emptyText: (
          <Result.TableErrorOrEmpty
            errorMessage={kitsError?.message}
            emptyMessage="Nenhum kit encontrado."
          />
        ),
      }}
    />
  );
}
