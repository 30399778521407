import { useState } from "react";

import type { IAneelTableModalProps } from "./interface";

import { IAneelTableData, Table } from "~components/Table";
import { useAppConfig } from "~hooks/useAppConfig";
import { Modal as AntdModal } from "antd";

export function AneelTableModal({
  isOpen = false,
  onClose = () => undefined,
  title = "Lista da Aneel",
  width = "90vw",
  tableProps,
  styleTop = 48 /* 3rem */,
  isSelectable = false,
  onHandleClickOk = () => undefined,
}: IAneelTableModalProps) {
  const { message } = useAppConfig();

  const [selectedRow, setSelectedRow] = useState<IAneelTableData | undefined>();

  const okButtonText = isSelectable ? "Selecionar item" : "Ok";

  const okButtonIsDisabled = isSelectable && !selectedRow;

  function handleClickOk() {
    try {
      if (!selectedRow) throw new Error("Necessário selecionar o item");

      onHandleClickOk(selectedRow);
    } catch (error: any) {
      message.error("Client error: ", error?.message);
    }
  }

  return (
    <AntdModal
      data-cy="aneel-table-modal"
      open={isOpen}
      onCancel={onClose}
      onOk={isSelectable ? handleClickOk : undefined}
      title={title}
      okText={okButtonText}
      okButtonProps={{
        disabled: okButtonIsDisabled,
      }}
      width={width}
      destroyOnClose
      styles={{
        body: {
          height: "calc(100vh - 280px)",
          overflowX: "auto",
        },
      }}
      style={{ top: styleTop, maxWidth: "1980px" }}
    >
      <Table.Aneel
        {...tableProps}
        isSelectable={isSelectable}
        onSelect={(row) => setSelectedRow(row)}
      />
    </AntdModal>
  );
}
