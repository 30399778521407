import Link from "next/link";
import { useRouter } from "next/router";

import { StyledBreadcrumb } from "./styles";

import { ArrowRight } from "~estrela-ui/icons";
import { useFetchGetDealByCustomId } from "~hooks/api";
import { useSession } from "~hooks/useSession";
import { Skeleton } from "antd";

function getDealIdFromUrlPath(path: string) {
  try {
    const dealIdString = path.split("/funil/negocio/")[1].split("?")[0];

    const dealId = Number(dealIdString);

    return isNaN(dealId) ? 0 : dealId;
  } catch {
    return 0;
  }
}

// Ex.: expected string /clientes/negocio/1?...
export function DealBreadcrumb() {
  const router = useRouter();
  const { sessionStatus } = useSession();

  const dealId = getDealIdFromUrlPath(router.asPath);

  const {
    data: deal,
    isFetching: isLoadingDeal,
    error: dealError,
  } = useFetchGetDealByCustomId({
    payload: {
      customId: dealId,
    },
    dependencyArray: [dealId, sessionStatus],
    options: {
      enabled: dealId > 0 && sessionStatus === "authenticated",
      retry: 1,
    },
  });

  return (
    <StyledBreadcrumb
      separator={<ArrowRight className="h-3 w-3" />}
      items={[
        // { title: <span>Vendas</span> },
        { title: <Link href="/funil">Funil</Link> },
        { title: <span>Negocio</span> },
        {
          title:
            isLoadingDeal || dealError ? (
              <Skeleton.Node
                active={isLoadingDeal}
                style={{ height: 16, width: 80 }}
              >
                {" "}
              </Skeleton.Node>
            ) : (
              <Link href={`/funil/negocio/${dealId}`}>
                {deal?.name ?? dealId}
              </Link>
            ),
          className: "active",
        },
      ]}
    />
  );
}
