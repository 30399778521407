import { BsBuildingGear, BsThreeDots } from "react-icons/bs";
import { IoNewspaperOutline } from "react-icons/io5";

import type { IOrganizationSummaryCardProps } from "./interface";
import { OrganizationIcon, StyledCard } from "./styles";

import { Alert } from "~components/Alert";
import { Modal } from "~components/Modal";
import { Delete, Company } from "~estrela-ui/icons";
import { useFetchGetOrganizationById } from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { parseFunctions, useQueryParams } from "~hooks/useQueryParams";
import { SolarzAnchor } from "~solarzui/SolarzAnchor";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { Dropdown, Flex, Skeleton } from "antd";

const DELETE_MODAL_QUERY_KEY = "delete-organization";

export function OrganizationSummaryCard({
  organizationId,
  onDeleteSuccess,
  style,
}: IOrganizationSummaryCardProps) {
  const { screens } = useAppConfig();
  const { handleSaveInQueryParams, getParsedQueryParams } = useQueryParams();

  const isDeleteOrganizationModalOpen =
    Number(
      getParsedQueryParams(DELETE_MODAL_QUERY_KEY, parseFunctions.NUMBER),
    ) > 0;

  function handleOpenDeleteOrganizationModal() {
    handleSaveInQueryParams({ [DELETE_MODAL_QUERY_KEY]: organizationId });
  }

  function handleCloseDeleteOrganizationModal() {
    handleSaveInQueryParams({ [DELETE_MODAL_QUERY_KEY]: "" });
  }

  const {
    data: organization,
    isFetching: isLoadingOrganization,
    error: organizationError,
    refetch: reloadOrganization,
  } = useFetchGetOrganizationById({
    dependencyArray: [organizationId],
    payload: {
      organizationId,
    },
    options: {
      enabled: organizationId > 0,
    },
  });

  const isLoading = organizationId === 0 || isLoadingOrganization;

  return (
    <>
      {organizationError && (
        <Alert.CardError
          title="Falha ao carregar dados"
          errorMessage={organizationError?.message}
          reloadFn={reloadOrganization}
          style={{
            marginBottom: 12,
          }}
        />
      )}

      <StyledCard size="small" style={style}>
        <Flex justify="space-between" align="center" style={{ height: "100%" }}>
          {screens.mobile ? (
            <Flex gap={8} flex={1} align="center">
              <OrganizationIcon style={{ marginTop: 2 }}>
                <Company />
              </OrganizationIcon>

              {isLoading || organizationError ? (
                <Skeleton.Node
                  active={isLoadingOrganization}
                  style={{ width: 72, height: 20 }}
                >
                  {" "}
                </Skeleton.Node>
              ) : (
                <SolarzTypography variant="title" hierarchy="paragraph2">
                  {organization?.name}
                </SolarzTypography>
              )}
            </Flex>
          ) : (
            <Flex align="center" gap={16}>
              <BsBuildingGear
                size={24}
                style={{ color: "var(--primary-500)" }}
              />

              {isLoading || organizationError ? (
                <Skeleton.Input
                  active={isLoadingOrganization}
                  style={{ width: 180 }}
                />
              ) : (
                <SolarzTypography
                  hierarchy="paragraph1"
                  variant="title"
                  fontWeight="medium"
                >
                  {organization?.name}
                </SolarzTypography>
              )}
            </Flex>
          )}

          <Flex align="center" gap={8}>
            <SolarzAnchor
              isNextLink
              href={`/proposta?empresa=${organizationId}`}
            >
              <SolarzButton
                scale="large"
                icon={<IoNewspaperOutline size={20} />}
                style={
                  screens.mobile
                    ? {
                        height: 40,
                        width: 40,
                      }
                    : undefined
                }
              >
                {!screens.mobile && "Gerar proposta"}
              </SolarzButton>
            </SolarzAnchor>
            <Dropdown
              trigger={["click"]}
              menu={{
                items: [
                  {
                    key: "delete",
                    label: (
                      <SolarzTypography
                        hierarchy="paragraph2"
                        variant="danger"
                        fontWeight="medium"
                      >
                        <Flex align="center" style={{ height: 32 }} gap={12}>
                          <Delete />
                          Apagar organização
                        </Flex>
                      </SolarzTypography>
                    ),
                    onClick: () => {
                      handleOpenDeleteOrganizationModal();
                    },
                  },
                ],
              }}
              arrow
              placement="bottomRight"
            >
              <div>
                <SolarzButton
                  variant="secondary"
                  scale="large"
                  icon={
                    <BsThreeDots
                      size={20}
                      style={{ color: "var(--primary-500)" }}
                    />
                  }
                  isDisabled={organizationId === 0}
                  style={{
                    height: screens.mobile ? 40 : 48,
                    width: screens.mobile ? 40 : 48,
                  }}
                />
              </div>
            </Dropdown>
          </Flex>
        </Flex>
      </StyledCard>

      <Modal.DeleteOrganization
        organizationId={organizationId}
        isOpen={isDeleteOrganizationModalOpen}
        onClose={handleCloseDeleteOrganizationModal}
        onSuccess={onDeleteSuccess}
      />
    </>
  );
}
