import styled from "@emotion/styled";

export const EmailText = styled("span")`
  & {
    color: var(--gray-400);
  }

  // DARK THEME
  html.dark & {
    color: var(--gray-500);
  }
`;

export const NameText = styled("span")`
  & {
    color: var(--gray-900);
  }

  // DARK THEME
  html.dark & {
    color: var(--gray-200);
  }
`;
