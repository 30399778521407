import { useState } from "react";

import type { IPipelineCheckboxGroupCollapseProps } from "./interface";
import { StyledCollapse } from "./styles";

import { Alert } from "~components/Alert";
import { useFetchGetAllPipelines } from "~hooks/api";
import { Checkbox, Flex, Spin } from "antd";

export function PipelineCheckboxGroupCollapse({
  onChangeSelectedPipelinesIds,
  initialSelectedPipelinesIds = [],
  selectedPipelinesIds: controlledSelectedPipelinesIds,
  isAllChecked: controlledIsAllChecked,
  startExpanded = true,
  style,
}: IPipelineCheckboxGroupCollapseProps) {
  const [selectedPipelinesIds, setSelectedPipelinesIds] = useState<number[]>(
    initialSelectedPipelinesIds,
  );

  const {
    data: pipelines = [],
    isFetching: pipelineIsLoading,
    error: pipelineError,
    refetch: pipelineRefetch,
  } = useFetchGetAllPipelines({
    dependencyArray: [],
    options: {
      retry: 0,
    },
  });

  function handleSelectAll() {
    const allPipelineIds = pipelines.map((pipeline) => pipeline.id);
    setSelectedPipelinesIds(allPipelineIds);

    if (typeof onChangeSelectedPipelinesIds === "function") {
      onChangeSelectedPipelinesIds(allPipelineIds, true);
    }
  }

  function handleRemoveAll() {
    setSelectedPipelinesIds([]);

    if (typeof onChangeSelectedPipelinesIds === "function") {
      onChangeSelectedPipelinesIds([], false);
    }
  }

  const isAllChecked =
    (controlledSelectedPipelinesIds ?? selectedPipelinesIds).length ===
    pipelines.length;

  const isIndeterminate =
    (controlledSelectedPipelinesIds ?? selectedPipelinesIds).length > 0 &&
    (controlledSelectedPipelinesIds ?? selectedPipelinesIds).length <
      pipelines.length;

  return (
    <Flex vertical gap={8}>
      {pipelineError && (
        <Alert.CardError
          errorMessage={pipelineError.message}
          reloadFn={pipelineRefetch}
          title="Falha ao carregar pipelines"
        />
      )}

      <StyledCollapse
        collapsible={pipelineError ? "disabled" : "header"}
        items={[
          {
            key: "CheckboxGroup.Pipeline",
            label: (
              <Flex gap={8}>
                <Checkbox
                  id="all-pipelines-checkbox"
                  onClick={(e) => {
                    e.stopPropagation();
                    isAllChecked ? handleRemoveAll() : handleSelectAll();
                  }}
                  indeterminate={isIndeterminate}
                  checked={isAllChecked}
                  disabled={!!pipelineError || !!pipelineIsLoading}
                />
                <label
                  htmlFor="all-pipelines-checkbox"
                  style={{ fontWeight: 500 }}
                  onClick={(e) => e.stopPropagation()}
                >
                  Todos os funis
                </label>
              </Flex>
            ),
            children: (
              <Checkbox.Group<number>
                options={pipelines.map((pipeline) => {
                  return {
                    label: pipeline.name,
                    value: pipeline.id,
                  };
                })}
                value={controlledSelectedPipelinesIds ?? selectedPipelinesIds}
                onChange={(pipelineIds) => {
                  setSelectedPipelinesIds(pipelineIds);

                  if (typeof onChangeSelectedPipelinesIds === "function") {
                    const isAllChecked =
                      pipelineIds.length === pipelines.length;
                    controlledIsAllChecked = isAllChecked;
                    onChangeSelectedPipelinesIds(pipelineIds, isAllChecked);
                  }
                }}
                disabled={!!pipelineError}
              />
            ),
          },
        ]}
        expandIconPosition="right"
        expandIcon={
          pipelineIsLoading ? () => <Spin size="small" spinning /> : undefined
        }
        defaultActiveKey={startExpanded ? ["CheckboxGroup.Pipeline"] : []}
        style={style}
      />
    </Flex>
  );
}
