import { useCallback, useEffect, useMemo, useState } from "react";

import {
  type IPipelineDealsTableProps,
  PipelineDealsTableDataSourceType,
} from "./interface";
import {
  CalendarIcon,
  Deal,
  PipelineTabControl,
  TableContainer,
  Wrapper,
} from "./styles";

import { Avatar } from "~components/Avatar";
import { TaskToUpdateType } from "~components/Others";
import { ArrowLeft, ArrowRight, CalendarAdd } from "~estrela-ui/icons";
import { useRxStompClient } from "~hooks/useRxStompClient";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzPipelineTab } from "~solarzui/SolarzPipelineTab";
import { SolarzTable } from "~solarzui/SolarzTable";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { parseCurrency, parsePercentage } from "~utils/parse";
import { Badge, Card, Flex, Skeleton, Tag } from "antd";
import dayjs from "dayjs";

export function PipelineDealsTable({
  pipelineId,
  isLoading,
  pipelineStages,
  pipelineError,
  filters,
  ...props
}: IPipelineDealsTableProps) {
  const [dataSource, setDataSource] = useState<
    PipelineDealsTableDataSourceType[]
  >([]);

  const [columnPosition, setColumnPosition] = useState(1);

  const { publish } = useRxStompClient();

  const handleMoveDeal = useCallback(
    (taskToUpdate: TaskToUpdateType) => {
      publish({
        destination: "/send/deal/move",
        body: JSON.stringify(taskToUpdate),
      });
    },
    [publish],
  );

  useEffect(() => {
    const parsedData: PipelineDealsTableDataSourceType[] = pipelineStages.map(
      (stage) => {
        return {
          id: stage.id,
          record: stage,
          title: stage.name,
          tasks: stage.deals.map((deal) => ({
            ...deal,
            record: deal,
          })),
        };
      },
    );

    setDataSource(parsedData);
  }, [pipelineStages]);

  useEffect(() => {
    setColumnPosition(1);
  }, [pipelineId]);

  const { column, deals } = useMemo(() => {
    const column = dataSource.find(
      (data) => data.record.position === columnPosition,
    );

    const deals = column?.tasks ?? [];

    return { column, deals };
  }, [columnPosition, dataSource]);

  const dealsTotalAmount = deals.reduce(
    (total, deal) => (deal.value ?? 0) + total,
    0,
  );

  return (
    <Wrapper>
      <TableContainer>
        <SolarzTable<PipelineDealsTableDataSourceType["tasks"][0]>
          {...props}
          rowKey="id"
          rows={deals}
          columns={[
            {
              key: "id",
              render: (_, record) => {
                const formattedName = record.person?.name || "-";

                const formattedCurrency = parseCurrency(record.value);

                const isProposalLate = false;

                const parsedCreatedAt = dayjs(record.createdAt);

                const formattedDaysCount = parsedCreatedAt.isValid()
                  ? `${dayjs().diff(parsedCreatedAt.startOf("day"), "days")} d`
                  : "0 d";

                return (
                  <Deal
                    isNextLink
                    href={`/funil/negocio/${record.id}`}
                    style={{ width: "100%" }}
                  >
                    <Card
                      style={{
                        width: "100%",
                        minHeight: 98,
                        cursor: "pointer",
                        border: isProposalLate
                          ? "1px solid var(--red-500)"
                          : undefined,
                        backgroundColor: isProposalLate
                          ? "var(--red-50)"
                          : undefined,
                        display: "block",
                      }}
                      title={null}
                      size="small"
                      hoverable
                    >
                      <Flex vertical gap={12}>
                        <Flex align="start" justify="space-between" gap={4}>
                          <Flex vertical gap={6}>
                            <SolarzTypography
                              fontWeight="medium"
                              variant="title"
                              hierarchy="paragraph2"
                            >
                              {record.name}
                            </SolarzTypography>
                            <SolarzTypography
                              fontWeight="regular"
                              variant="disabled"
                              hierarchy="small"
                              style={{ textAlign: "left" }}
                            >
                              {formattedName}
                            </SolarzTypography>
                          </Flex>

                          <CalendarIcon
                            className={isProposalLate ? "is-danger" : undefined}
                          >
                            <CalendarAdd className="h-4 w-4" />
                          </CalendarIcon>
                        </Flex>

                        <Flex align="center" justify="space-between">
                          <Flex align="center" gap={8}>
                            <Avatar.CustomUser
                              name={record.owner?.name}
                              avatarUrl={record.owner?.avatarUrl}
                              userId={record.owner?.id}
                              width={24}
                              height={24}
                            />

                            <SolarzTypography
                              fontWeight="regular"
                              variant="disabled"
                              hierarchy="small"
                            >
                              {formattedCurrency}
                            </SolarzTypography>
                          </Flex>

                          <Badge
                            color="red"
                            count={formattedDaysCount}
                            styles={{
                              indicator: {
                                borderRadius: 4,
                                padding: 4,
                                fontWeight: 600,
                                lineHeight: "12px",
                                fontSize: 10,
                                display: isProposalLate ? undefined : "none",
                              },
                            }}
                          />
                        </Flex>
                      </Flex>
                    </Card>
                  </Deal>
                );
              },
            },
          ]}
          isLoading={isLoading}
          showHeader={false}
          pagination={undefined}
          emptyMessage="Nenhum negócio encontrado"
          errorMessage={pipelineError?.message}
          disableReload={pipelineId === 0}
          draggable
          onDragEnd={(rowChanged, rows) => {
            setDataSource((current) => {
              return current.map((data) => {
                return {
                  ...data,
                  tasks:
                    data.record.position === columnPosition ? rows : data.tasks,
                };
              });
            });

            handleMoveDeal({
              dealId: rowChanged.id,
              pipelineStageId: rowChanged?.pipelineStageId ?? 0,
              position: rows.findIndex((row) => row.id === rowChanged.id) + 1,
            });
          }}
        />
      </TableContainer>

      <PipelineTabControl vertical align="center" gap={4}>
        <Flex vertical gap={4} style={{ width: "100%", padding: "0 16px" }}>
          <Flex align="center" justify="space-between">
            {isLoading ? (
              <Skeleton.Node
                active={isLoading}
                style={{ height: 14, width: 100 }}
              >
                {" "}
              </Skeleton.Node>
            ) : (
              <SolarzTypography
                fontWeight="medium"
                hierarchy="paragraph1"
                variant="title"
                style={{
                  width: "100%",
                }}
                ellipse
              >
                {column?.title}
              </SolarzTypography>
            )}

            {isLoading ? (
              <Skeleton.Node
                active={isLoading}
                style={{ height: 14, width: 32 }}
              >
                {" "}
              </Skeleton.Node>
            ) : (
              <Tag
                color="green"
                style={{
                  borderWidth: 2,
                  padding: "0 4px",
                  margin: 0,
                }}
              >
                <SolarzTypography
                  hierarchy="tiny"
                  variant="success"
                  fontWeight="medium"
                >
                  {parsePercentage(column?.record.probability ?? 0)}
                </SolarzTypography>
              </Tag>
            )}
          </Flex>

          <Flex align="center" justify="space-between">
            {isLoading ? (
              <Skeleton.Node
                active={isLoading}
                style={{ height: 14, width: 64 }}
              >
                {" "}
              </Skeleton.Node>
            ) : (
              <SolarzTypography
                fontWeight="regular"
                variant="subTitle"
                hierarchy="tiny"
              >
                {deals.length} Negócios
              </SolarzTypography>
            )}

            {isLoading ? (
              <Skeleton.Node
                active={isLoading}
                style={{ height: 14, width: 48 }}
              >
                {" "}
              </Skeleton.Node>
            ) : (
              <SolarzTypography
                fontWeight="regular"
                variant="subTitle"
                hierarchy="tiny"
              >
                {parseCurrency(dealsTotalAmount)}
              </SolarzTypography>
            )}
          </Flex>
        </Flex>

        <Flex align="center" gap={4} style={{ width: "100%" }}>
          <SolarzButton
            icon={<ArrowLeft />}
            scale="tiny"
            variant="ghost"
            onClick={() => setColumnPosition((current) => current - 1)}
            isDisabled={columnPosition <= 1}
          />
          <SolarzPipelineTab
            isLoading={isLoading}
            tabs={dataSource.map((column) => {
              return {
                label: column.title,
                record: column,
              };
            })}
            labelStyle={{ display: "none" }}
            style={{ width: "100%" }}
            scale="tiny"
            actualStep={columnPosition}
            defaultStep={1}
            onChange={(step) => setColumnPosition(step)}
          />
          <SolarzButton
            icon={<ArrowRight />}
            scale="tiny"
            variant="ghost"
            onClick={() => setColumnPosition((current) => current + 1)}
            isDisabled={columnPosition >= dataSource.length}
          />
        </Flex>
      </PipelineTabControl>
    </Wrapper>
  );
}
