import type { IReasonForLossFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function ReasonForLossFormModal({
  isOpen = false,
  onClose = () => undefined,
  maxWidth = 512,
  title = "Formulário do motivo de perda",
  formProps,
  reasonForLossId,
}: IReasonForLossFormModalProps) {
  return (
    <Modal
      data-cy="reason-for-loss-form-modal"
      title={title}
      width="90vw"
      style={{
        maxWidth,
      }}
      open={isOpen}
      footer={null}
      onCancel={onClose}
      destroyOnClose
    >
      <Form.ReasonForLoss
        {...formProps}
        onCancel={onClose}
        reasonForLossId={reasonForLossId}
        style={{ marginTop: 8, ...formProps?.style }}
      />
    </Modal>
  );
}
