import { Select } from "~components/Select";
import { Form } from "antd";

export function PipedriveActivitiesForm() {
  const [form] = Form.useForm();
  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="activity"
        label="Selecione a atividade que será marcada no pipedrive após a geração de uma proposta"
      >
        <Select.PipedriveActivities
          placeholder="Selecionar base de cálculo..."
          onLoad={(value) => {
            form.setFieldValue("activity", value);
          }}
        />
      </Form.Item>
    </Form>
  );
}
