import type {
  IChangeUserProfileFormProps,
  IChangeUserProfileFormValues,
} from "./interface";

import { Select } from "~components/Select";
import { useFetchUpdateUserProfile } from "~hooks/api";
import { Button, Form, Flex } from "antd";

export function ChangeUserProfileForm({
  userId = 0,
  initialValues = {
    profileId: undefined,
  },
  onCancel = () => undefined,
  onSubmitFail = () => undefined,
  onSubmitSuccess = () => undefined,
}: IChangeUserProfileFormProps) {
  const { mutate: handleUpdateUserProfile, isLoading } =
    useFetchUpdateUserProfile({
      onSuccess: onSubmitSuccess,
      onError: onSubmitFail,
    });

  function handleSubmitForm(formValues: IChangeUserProfileFormValues) {
    handleUpdateUserProfile({
      profileId: formValues.profileId,
      userId,
    });
  }

  return (
    <Form
      data-cy="change-user-profile-form"
      onFinish={handleSubmitForm}
      initialValues={initialValues}
      layout="vertical"
      style={{ width: "100%" }}
    >
      <Form.Item
        name="profileId"
        label="Selecione o perfil..."
        rules={[{ required: true }]}
      >
        <Select.Profile placeholder="Selecione o perfil..." />
      </Form.Item>

      <Flex align="center" gap={8} justify="end">
        <Button onClick={onCancel}>Cancelar</Button>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Salvar
        </Button>
      </Flex>
    </Form>
  );
}
