import type {
  IOrganizationDefaultFieldFormProps,
  IOrganizationDefaultFieldFormValues,
} from "./interface";

import { CustomInput } from "~components/CustomInput";
import { useFetchPatchOrganizationDefaultFieldSettings } from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import {
  Button,
  Form,
  Flex,
  Divider,
  Col,
  Row,
  Checkbox,
  Typography,
} from "antd";

export function OrganizationDefaultFieldForm({
  initialValues,
  onCancel,
  onSubmitFail = () => undefined,
  onSubmitSuccess = () => undefined,
}: IOrganizationDefaultFieldFormProps) {
  const { screens } = useAppConfig();

  const [form] = Form.useForm();
  const fieldIsConfigurable =
    !initialValues?.organizationDefaultField?.configurable;

  const { mutate: updateDefaultField, isLoading: isUpdatingDefaultField } =
    useFetchPatchOrganizationDefaultFieldSettings({
      options: {
        onSuccess: onSubmitSuccess,
        onError: onSubmitFail,
      },
    });

  function handleSubmitForm(data: IOrganizationDefaultFieldFormValues) {
    if (initialValues?.id) {
      updateDefaultField({ ...data, id: initialValues?.id });
    }
  }

  return (
    <Form
      form={form}
      data-cy="organization-default-field-form"
      onFinish={handleSubmitForm}
      initialValues={{
        ...initialValues,
        label: initialValues?.organizationDefaultField?.label,
      }}
      layout="vertical"
    >
      <Flex
        align="center"
        gap={screens.md ? 8 : 0}
        wrap="nowrap"
        style={{
          display: "block",
        }}
      >
        <Row>
          <Col span={screens.md ? 14 : 24}>
            <Form.Item
              label="Nome do campo"
              name="label"
              required
              style={{ width: "100%" }}
            >
              <CustomInput.Text disabled />
            </Form.Item>
          </Col>

          <Col
            span={screens.md ? 2 : 24}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Divider
              type={screens.md ? "vertical" : "horizontal"}
              style={{ height: "100%" }}
            />
          </Col>

          <Col span={screens.md ? 8 : 24}>
            <Flex vertical gap={8}>
              <Typography.Text>Características do campo</Typography.Text>

              <Form.Item<IOrganizationDefaultFieldFormValues>
                name="required"
                rules={[{ required: true }]}
                style={{ marginBottom: 0, paddingBottom: 0 }}
                valuePropName="checked"
              >
                <Checkbox disabled={fieldIsConfigurable}>Obrigatório</Checkbox>
              </Form.Item>
              <Form.Item<IOrganizationDefaultFieldFormValues>
                name="editable"
                rules={[{ required: true }]}
                style={{ marginBottom: 0, paddingBottom: 0 }}
                valuePropName="checked"
              >
                <Checkbox disabled={fieldIsConfigurable}>Editável</Checkbox>
              </Form.Item>
              <Form.Item<IOrganizationDefaultFieldFormValues>
                name="hidden"
                rules={[{ required: true }]}
                style={{ marginBottom: 0, paddingBottom: 0 }}
                valuePropName="checked"
              >
                <Checkbox disabled={fieldIsConfigurable}>Oculto</Checkbox>
              </Form.Item>
            </Flex>
          </Col>
        </Row>
      </Flex>
      <Flex gap={12} justify="end">
        <Form.Item style={{ margin: 0 }}>
          <Button onClick={onCancel}>Cancelar</Button>
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={isUpdatingDefaultField}
            disabled={fieldIsConfigurable}
          >
            Salvar
          </Button>
        </Form.Item>
      </Flex>
    </Form>
  );
}
