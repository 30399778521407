import { useMemo } from "react";

import type {
  IWegIntegrationFormProps,
  IWegIntegrationFormValues,
} from "./interface";

import { CustomInput } from "~components/CustomInput";
import { Select } from "~components/Select";
import { useFetchUpdateConfigWegDistributorIntegration } from "~hooks/api";
import { Button, Col, Flex, Form, Radio, Row } from "antd";

const DEFAULT_VALUES: IWegIntegrationFormValues = {
  discountRate: 0,
  freightRate: 0,
  autoSizingCAProtector: false,
  autoSizingWiring: true,
  autoSizingConnector: true,
  includeInverterProtection: true,
  includeOutOfStock: false,
};

export function WegIntegrationForm({
  initialValues,
  formInstance,
  onCancel = () => undefined,
  onError = () => undefined,
  onSuccess = () => undefined,
}: IWegIntegrationFormProps) {
  const [form] = Form.useForm<IWegIntegrationFormValues>(formInstance);

  const { mutate: updateConfigWeg, isLoading: isUpdatingConfigWeg } =
    useFetchUpdateConfigWegDistributorIntegration({
      onError,
      onSuccess,
    });

  function handleSubmitForm(formValues: IWegIntegrationFormValues) {
    updateConfigWeg(formValues);
  }

  const INITIAL_VALUES: IWegIntegrationFormValues = useMemo(
    () => ({
      ...DEFAULT_VALUES,
      ...initialValues,
    }),
    [initialValues],
  );

  return (
    <Form
      data-cy="weg-integration-form"
      form={form}
      onFinish={handleSubmitForm}
      initialValues={INITIAL_VALUES}
      layout="vertical"
      style={{ width: "100%" }}
    >
      <Form.Item<IWegIntegrationFormValues> name="freightRate" label="Frete">
        <CustomInput.UnitMeasurement
          unitMeasurement="%"
          precision="2"
          min={0}
          data-cy="input-freight-rate"
        />
      </Form.Item>

      <Form.Item<IWegIntegrationFormValues>
        name="discountRate"
        label="Fator de desconto"
        data-cy="input-discount-rate"
      >
        <CustomInput.UnitMeasurement
          unitMeasurement="%"
          precision="2"
          min={0}
        />
      </Form.Item>

      <Form.Item<IWegIntegrationFormValues>
        label="Região"
        name="defaultRegionId"
      >
        <Select.EquipmentRegion allowClear />
      </Form.Item>

      <Form.Item<IWegIntegrationFormValues>
        name="autoSizingCAProtector"
        label="Dimensionar Protetor de Surto CA"
        tooltip="Se sim, vamos escalar o dimensionamento de corrente alternada da maneira correta."
      >
        <Radio.Group
          options={[
            {
              label: "Sim",
              value: true,
            },
            {
              label: "Não",
              value: false,
            },
          ]}
        />
      </Form.Item>

      <Row gutter={[12, 12]} wrap>
        <Col xs={24} sm={12}>
          <Form.Item<IWegIntegrationFormValues>
            name="autoSizingWiring"
            label="Incluir Cabos no Kit"
          >
            <Radio.Group
              options={[
                {
                  label: "Sim",
                  value: true,
                },
                {
                  label: "Não",
                  value: false,
                },
              ]}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item<IWegIntegrationFormValues>
            name="autoSizingConnector"
            label="Incluir Conectores no Kit"
          >
            <Radio.Group
              options={[
                {
                  label: "Sim",
                  value: true,
                },
                {
                  label: "Não",
                  value: false,
                },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 12]} wrap>
        <Col xs={24} sm={12}>
          <Form.Item<IWegIntegrationFormValues>
            name="includeInverterProtection"
            label="Incluir disjuntores no Kit"
          >
            <Radio.Group
              options={[
                {
                  label: "Sim",
                  value: true,
                },
                {
                  label: "Não",
                  value: false,
                },
              ]}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item<IWegIntegrationFormValues>
            name="includeOutOfStock"
            label="Incluir Fora de estoque na pesquisa?"
          >
            <Radio.Group
              options={[
                {
                  label: "Sim",
                  value: true,
                },
                {
                  label: "Não",
                  value: false,
                },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Flex align="center" gap={8} justify="end">
        <Button onClick={onCancel}>Cancelar</Button>
        <Button
          data-cy="button-save"
          type="primary"
          htmlType="submit"
          loading={isUpdatingConfigWeg}
        >
          Atualizar
        </Button>
      </Flex>
    </Form>
  );
}
