import type { IUpdateHideableFieldSwitchProps } from "./interface";
import styles from "./styles";

import { useFetchUpdateHideableFields } from "~hooks/api";
import { Flex, Switch } from "antd";

export function UpdateHideableFieldSwitch({
  hideableFieldId = 0,
  hideableFieldName = "",
  isChecked = false,
  onError = () => undefined,
  onSuccess = () => undefined,
}: IUpdateHideableFieldSwitchProps) {
  const { mutate: updateHideableField, isLoading: isUpdatingHideableField } =
    useFetchUpdateHideableFields({
      options: {
        onSuccess,
        onError,
      },
    });

  function handleClick() {
    updateHideableField({
      fieldId: hideableFieldId,
    });
  }

  const switchId = hideableFieldId?.toString() || new Date().toJSON(); // new date just to not cause a key duplicate.

  return (
    <Flex align="center" gap={8}>
      <style jsx>{styles}</style>
      <Switch
        data-cy="update-hideable-field-switch"
        id={switchId}
        title={hideableFieldName}
        checked={isChecked}
        loading={isUpdatingHideableField}
        onClick={handleClick}
        checkedChildren="Oculto"
        unCheckedChildren="Visível"
        className="input"
      />
      <label htmlFor={switchId}>{hideableFieldName}</label>
    </Flex>
  );
}
