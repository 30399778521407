import { useMemo } from "react";

import type { IEcoriModuleBrandsSelectProps } from "./interface";

import { SelectSuffixIcon } from "~components/Others";
import { Result } from "~components/Result";
import { useFetchGetAllEcoriEquipmentModules } from "~hooks/api";
import { Select } from "antd";

export function EcoriModuleBrandsSelect({
  allowClear = true,
  onChange,
  ...props
}: IEcoriModuleBrandsSelectProps) {
  const {
    data: moduleBrands = [],
    error: moduleBrandsError,
    isFetching: isLoadingModuleBrands,
    refetch: reloadModuleBrands,
  } = useFetchGetAllEcoriEquipmentModules({
    dependencyArray: [],
    options: {
      retry: 1,
    },
  });

  const OPTIONS = useMemo(() => {
    return moduleBrands.map((product) => ({
      label: product.brand,
      value: product.id,
      record: product,
    }));
  }, [moduleBrands]);

  return (
    <Select
      {...props}
      loading={isLoadingModuleBrands}
      options={OPTIONS}
      allowClear={allowClear}
      status={moduleBrandsError ? "error" : ""}
      suffixIcon={
        <SelectSuffixIcon
          hasError={!!moduleBrandsError}
          isLoading={isLoadingModuleBrands}
        />
      }
      notFoundContent={
        <Result.SelectErrorOrEmpty
          errorMessage={moduleBrandsError?.message}
          emptyMessage="Nenhum módulo da Ecori foi encontrado"
          loading={isLoadingModuleBrands}
          reloadFn={reloadModuleBrands}
        />
      }
      onChange={(value, option) => {
        if (!onChange) return;
        onChange(value, option);
      }}
    />
  );
}
