import styled from "@emotion/styled";
import { SolarzButton } from "~solarzui/SolarzButton";

export const StyledSolarzButton = styled(SolarzButton)`
  &.pipedrive-button {
    color: var(--blue-600) !important;
    border-color: var(--blue-600) !important;

    &:hover {
      color: var(--blue-700) !important;
      border-color: var(--blue-700) !important;
    }
  }
`;
