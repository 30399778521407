import styled from "@emotion/styled";
import { Card } from "antd";

export const StyledCard = styled(Card)`
  &.ant-card {
    width: 100%;
    box-sizing: border-box;
    gap: 12px;
    flex: none;
    order: 2;
    flex-grow: 0;

    .ant-card-body {
      padding: 16px;
    }
  }
`;
