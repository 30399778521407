import * as React from "react";

import type { IIconProps } from "../@types/globals";
import { cn } from "../lib/utils";

const At: React.FC<IIconProps> = ({ className, ...props }) => (
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    stroke="currentColor"
    className={cn("pointer-events-none w-6 h-6", className)}
    {...props}
  >
    <title>Arroba</title>
    <path d="M12 22.9375C12.5178 22.9375 12.9375 22.5178 12.9375 22C12.9375 21.4822 12.5178 21.0625 12 21.0625V22.9375ZM21.3987 15.4234L20.5178 15.1025L20.5178 15.1025L21.3987 15.4234ZM20.5771 16.545L21.2128 17.2341L21.2128 17.2341L20.5771 16.545ZM20.7114 16.4212L20.0757 15.7321L20.0757 15.7321L20.7114 16.4212ZM17.2146 16.8097L16.6946 17.5898L16.6946 17.5898L17.2146 16.8097ZM12 21.0625C6.99492 21.0625 2.9375 17.0051 2.9375 12H1.0625C1.0625 18.0406 5.95938 22.9375 12 22.9375V21.0625ZM2.9375 12C2.9375 6.99492 6.99492 2.9375 12 2.9375V1.0625C5.95938 1.0625 1.0625 5.95938 1.0625 12H2.9375ZM12 2.9375C17.0051 2.9375 21.0625 6.99492 21.0625 12H22.9375C22.9375 5.95938 18.0406 1.0625 12 1.0625V2.9375ZM21.0625 12C21.0625 13.0915 20.87 14.1359 20.5178 15.1025L22.2795 15.7443C22.7055 14.575 22.9375 13.3136 22.9375 12H21.0625ZM21.2128 17.2341L21.347 17.1103L20.0757 15.7321L19.9414 15.856L21.2128 17.2341ZM16.9375 14.5402V12H15.0625V14.5402H16.9375ZM16.6946 17.5898C18.1022 18.5282 19.9694 18.3812 21.2128 17.2341L19.9415 15.856C19.3341 16.4163 18.4222 16.488 17.7346 16.0297L16.6946 17.5898ZM20.5178 15.1025C20.4385 15.32 20.2899 15.5345 20.0757 15.7321L21.347 17.1103C21.7349 16.7524 22.0783 16.2966 22.2795 15.7443L20.5178 15.1025ZM17.7346 16.0297C17.2366 15.6977 16.9375 15.1388 16.9375 14.5402H15.0625C15.0625 15.7657 15.6749 16.91 16.6946 17.5898L17.7346 16.0297ZM15.0625 12C15.0625 13.6914 13.6914 15.0625 12 15.0625V16.9375C14.7269 16.9375 16.9375 14.7269 16.9375 12H15.0625ZM12 15.0625C10.3086 15.0625 8.9375 13.6914 8.9375 12H7.0625C7.0625 14.7269 9.27309 16.9375 12 16.9375V15.0625ZM8.9375 12C8.9375 10.3086 10.3086 8.9375 12 8.9375V7.0625C9.27309 7.0625 7.0625 9.27309 7.0625 12H8.9375ZM12 8.9375C13.6914 8.9375 15.0625 10.3086 15.0625 12H16.9375C16.9375 9.27309 14.7269 7.0625 12 7.0625V8.9375Z" />
  </svg>
);

At.displayName = "At";

export { At };
