import { useCallback, useEffect } from "react";

import type {
  IEosFinancingOrganizationGuarantorDataFormProps,
  EosFinancingOrganizationGuarantorDataFormValuesType,
} from "./interface";

import { GoogleMapsProvider, SelectLocation } from "~components/GoogleMaps";
import { Select } from "~components/Select";
import { useAppConfig } from "~hooks/useAppConfig";
import { useInterval } from "~hooks/useInterval";
import { EOS_FORM_STEP_VALUES_ORGANIZATION_STORAGE_KEY } from "~pages/configuracoes-de-propostas/financiamento/EOS/cadastro/_module/page.config";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzFormItem } from "~solarzui/SolarzFormItem";
import { SolarzInput } from "~solarzui/SolarzInput";
import { SolarzInputNumber } from "~solarzui/SolarzInputNumber";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import {
  getLocalStorageData,
  setLocalStorageData,
} from "~utils/localstorageUtilitFunctions";
import { parseGoogleLocationToAddressEosForm } from "~utils/parseGoogleLocationToAddressForm";
import { Col, DatePicker, Flex, Form, Input, Row } from "antd";
import dayjs from "dayjs";

export function EosFinancingOrganizationGuarantorDataForm({
  formInstance,
  initialValues,
  isSubmitting,
  onSubmit,
  onPrevStep,
}: IEosFinancingOrganizationGuarantorDataFormProps) {
  const { screens } = useAppConfig();
  const [form] =
    Form.useForm<EosFinancingOrganizationGuarantorDataFormValuesType>(
      formInstance,
    );

  const avalistaOutrasRendas = Form.useWatch("avalistaOutrasRendas", form);

  const handlePersistsDataInStorage = useCallback(
    (data: EosFinancingOrganizationGuarantorDataFormValuesType) => {
      try {
        const objectExisting = getLocalStorageData(
          EOS_FORM_STEP_VALUES_ORGANIZATION_STORAGE_KEY,
        );
        const objectUpdated = {
          ...objectExisting,
          ...data,
        };

        setLocalStorageData(
          EOS_FORM_STEP_VALUES_ORGANIZATION_STORAGE_KEY,
          objectUpdated,
        );
      } catch (error: any) {
        console.warn(error?.message);
      }
    },
    [],
  );

  useInterval(() => {
    const formFields = form.getFieldsValue(true);
    handlePersistsDataInStorage({ ...formFields });
  }, 3000);

  function handleCancel() {
    if (typeof onPrevStep === "function") {
      return onPrevStep();
    }
  }

  useEffect(() => {
    const objectExisting = getLocalStorageData(
      EOS_FORM_STEP_VALUES_ORGANIZATION_STORAGE_KEY,
    );
    const dateString = objectExisting.avalistaDataNascimento;

    const guarantorDateBirth =
      dateString && dayjs(dateString).isValid() ? dayjs(dateString) : undefined;

    if (objectExisting) {
      form.setFieldsValue({
        ...objectExisting,
        avalistaDataNascimento: guarantorDateBirth,
        avalistaOutrasRendas: objectExisting.objectExisting ?? 0,
      });
    }
  }, [form]);

  return (
    <Form
      data-cy="eos-financing-guarantor-data-form"
      form={form}
      initialValues={initialValues}
      onFinish={onSubmit}
      preserve
      layout="vertical"
    >
      <SolarzTypography
        fontWeight="semiBold"
        hierarchy="paragraph1"
        variant="title"
        style={{ marginTop: "38px", marginBottom: "12px" }}
      >
        Dados do avalista
      </SolarzTypography>
      <Row gutter={screens.mobile ? 16 : 24}>
        <Col span={screens.mobile ? 24 : 12}>
          <SolarzFormItem<EosFinancingOrganizationGuarantorDataFormValuesType>
            name="avalistaNome"
            label="Nome do avalista"
            rules={[{ required: true }]}
          >
            <SolarzInput
              scale={screens.mobile ? "medium" : "large"}
              placeholder="Ex.: João da Silva"
            />
          </SolarzFormItem>
        </Col>

        <Col span={screens.mobile ? 24 : 12}>
          <SolarzFormItem<EosFinancingOrganizationGuarantorDataFormValuesType>
            name="avalistaCpf"
            label="CPF"
            rules={[{ required: true }]}
          >
            <SolarzInput
              inputType="cpf"
              scale={screens.mobile ? "medium" : "large"}
              placeholder="Ex.: 000.000.000-00"
            />
          </SolarzFormItem>
        </Col>
      </Row>

      <Row gutter={screens.mobile ? 16 : 24}>
        <Col span={screens.mobile ? 24 : 8}>
          <SolarzFormItem<EosFinancingOrganizationGuarantorDataFormValuesType>
            name="avalistaCargoFuncao"
            label="Cargo"
            rules={[{ required: true }]}
          >
            <SolarzInput scale={screens.mobile ? "medium" : "large"} />
          </SolarzFormItem>
        </Col>

        <Col span={screens.mobile ? 24 : 8}>
          <SolarzFormItem<EosFinancingOrganizationGuarantorDataFormValuesType>
            name="avalistaTempoEmpresa"
            label="Tempo de empresa  "
            rules={[{ required: true }]}
          >
            <SolarzInputNumber
              inputType="Anos"
              scale={screens.mobile ? "medium" : "large"}
              precision={0}
              onChange={(value) => {
                const parsedValueString = value?.toString() || null;
                form.setFieldValue("avalistaTempoEmpresa", parsedValueString);
              }}
            />
          </SolarzFormItem>
        </Col>
        <Col span={screens.mobile ? 24 : 8}>
          <SolarzFormItem<EosFinancingOrganizationGuarantorDataFormValuesType>
            name="avalistaNaturezaOcupacao"
            label="Ocupação"
            rules={[{ required: true }]}
          >
            <Select.GuarantorOccupationEosFinancing
              scale={screens.mobile ? "medium" : "large"}
            />
          </SolarzFormItem>
        </Col>
      </Row>

      <Row gutter={screens.mobile ? 16 : 24}>
        <Col span={screens.mobile ? 24 : 8}>
          <SolarzFormItem<EosFinancingOrganizationGuarantorDataFormValuesType>
            name="avalistaTelefone"
            label="Telefone"
            rules={[{ required: true }]}
          >
            <SolarzInput
              scale={screens.mobile ? "medium" : "large"}
              inputType="telephone"
            />
          </SolarzFormItem>
        </Col>

        <Col span={screens.mobile ? 24 : 8}>
          <SolarzFormItem<EosFinancingOrganizationGuarantorDataFormValuesType>
            name="avalistaEmail"
            label="Email"
            rules={[{ required: true }]}
          >
            <SolarzInput
              scale={screens.mobile ? "medium" : "large"}
              placeholder="Ex.: joaodasilva77@mail.com"
            />
          </SolarzFormItem>
        </Col>
        <Col span={screens.mobile ? 24 : 8}>
          <SolarzFormItem<EosFinancingOrganizationGuarantorDataFormValuesType>
            name="avalistaDataNascimento"
            label="Data de nasciemento"
            rules={[{ required: true }]}
          >
            <DatePicker
              style={{ width: "100%", height: 48 }}
              format="ddd, DD MMM. YYYY"
            />
          </SolarzFormItem>
        </Col>
      </Row>
      <Row gutter={screens.mobile ? 16 : 24}>
        <Col span={screens.mobile ? 24 : 8}>
          <SolarzFormItem<EosFinancingOrganizationGuarantorDataFormValuesType>
            name="avalistaRendaMensal"
            label="Renda mensal"
            rules={[{ required: true }]}
          >
            <SolarzInputNumber
              scale={screens.mobile ? "medium" : "large"}
              inputType="R$"
              placeholder="10.000"
            />
          </SolarzFormItem>
        </Col>

        <Col span={screens.mobile ? 24 : 8}>
          <SolarzFormItem<EosFinancingOrganizationGuarantorDataFormValuesType>
            name="avalistaOutrasRendas"
            label="Outras rendas"
            rules={[{ required: true }]}
          >
            <SolarzInputNumber
              scale={screens.mobile ? "medium" : "large"}
              inputType="R$"
              placeholder="1.000"
            />
          </SolarzFormItem>
        </Col>
        <Col span={screens.mobile ? 24 : 8}>
          <SolarzFormItem<EosFinancingOrganizationGuarantorDataFormValuesType>
            name="avalistaPatrimonio"
            label="Patrimônio"
            rules={[{ required: true }]}
          >
            <SolarzInputNumber
              scale={screens.mobile ? "medium" : "large"}
              inputType="R$"
              placeholder="20.000"
            />
          </SolarzFormItem>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <SolarzFormItem<EosFinancingOrganizationGuarantorDataFormValuesType>
            name="avalistaOutrasRendasDescricao"
            label="Descrição de outras rendas"
            rules={[
              { required: !!avalistaOutrasRendas && avalistaOutrasRendas > 0 },
            ]}
          >
            <Input.TextArea
              style={{ minHeight: 90, maxHeight: 320 }}
              placeholder="Descrição..."
            />
          </SolarzFormItem>
        </Col>
      </Row>

      <SolarzTypography
        fontWeight="semiBold"
        hierarchy="paragraph1"
        variant="title"
        style={{ marginBottom: "24px" }}
      >
        Endereço do avalista
      </SolarzTypography>
      <Row gutter={24}>
        <Col span={24}>
          <SolarzFormItem label="Buscar endereço no Google">
            <GoogleMapsProvider
              onChangeLocationCallback={(location) => {
                const parsedValue =
                  parseGoogleLocationToAddressEosForm(location);
                form.setFieldsValue({
                  avalistaBairro: parsedValue.bairro,
                  avalistaCidade: parsedValue.cidade,
                  avalistaCep: parsedValue.cep,
                  avalistaEstado: parsedValue.estado,
                  avalistaNumero: parsedValue.numero,
                  avalistaLogradouro: parsedValue.logradouro,
                  avalistaComplemento: parsedValue.complemento,
                });
              }}
            >
              <SelectLocation
                style={{
                  width: "100%",
                  height: 48,
                }}
                placeholder="Procurar endereço com o Google"
              />
            </GoogleMapsProvider>
          </SolarzFormItem>
        </Col>
      </Row>
      <Row gutter={screens.mobile ? 16 : 24}>
        <Col span={screens.mobile ? 12 : 8}>
          <SolarzFormItem<EosFinancingOrganizationGuarantorDataFormValuesType>
            name="avalistaLogradouro"
            label="Logradouro"
            rules={[{ required: true, message: "Logradouro é obrigatório" }]}
          >
            <SolarzInput
              scale={screens.mobile ? "medium" : "large"}
              placeholder="Ex.: R. Presidente Venceslau"
            />
          </SolarzFormItem>
        </Col>
        <Col span={screens.mobile ? 6 : 8}>
          <SolarzFormItem<EosFinancingOrganizationGuarantorDataFormValuesType>
            name="avalistaBairro"
            label="Bairro"
            rules={[{ required: true, message: "É Obrigatório" }]}
          >
            <SolarzInput
              scale={screens.mobile ? "medium" : "large"}
              placeholder="Ex.: Bairro Senhor divino"
            />
          </SolarzFormItem>
        </Col>

        <Col span={screens.mobile ? 6 : 8}>
          <SolarzFormItem<EosFinancingOrganizationGuarantorDataFormValuesType>
            name="avalistaNumero"
            label="Número"
            rules={[{ required: true, message: "É Obrigatório" }]}
          >
            <SolarzInputNumber
              scale={screens.mobile ? "medium" : "large"}
              placeholder="Ex.: 777"
              precision={0}
              min={0}
              onChange={(value) => {
                const parsedValueString = value?.toString() || null;
                form.setFieldValue("avalistaNumero", parsedValueString);
              }}
            />
          </SolarzFormItem>
        </Col>
      </Row>
      <Row gutter={screens.mobile ? 16 : 24}>
        <Col span={screens.mobile ? 16 : 12}>
          <SolarzFormItem<EosFinancingOrganizationGuarantorDataFormValuesType>
            name="avalistaCidade"
            label="Cidade"
            rules={[{ required: true, message: "Cidade é obrigatório" }]}
          >
            <SolarzInput
              scale={screens.mobile ? "medium" : "large"}
              placeholder="Ex.: Mossoró"
            />
          </SolarzFormItem>
        </Col>
        <Col span={screens.mobile ? 8 : 6}>
          <SolarzFormItem<EosFinancingOrganizationGuarantorDataFormValuesType>
            name="avalistaEstado"
            label="Estado"
            rules={[{ required: true, message: "Estado é obrigatório" }]}
          >
            <Select.BrazilianStateByAcronym
              style={{ height: screens.mobile ? 40 : 48 }}
              placeholder="Ex.: RN"
            />
          </SolarzFormItem>
        </Col>
        <Col span={screens.mobile ? 24 : 6}>
          <SolarzFormItem<EosFinancingOrganizationGuarantorDataFormValuesType>
            name="avalistaCep"
            label="CEP"
            rules={[{ required: true, message: "CEP é obrigatório" }]}
          >
            <SolarzInput
              scale={screens.mobile ? "medium" : "large"}
              placeholder="Ex.: 00000-000"
            />
          </SolarzFormItem>
        </Col>
      </Row>
      <Row gutter={screens.mobile ? 16 : 24}>
        <Col span={24}>
          <SolarzFormItem<EosFinancingOrganizationGuarantorDataFormValuesType>
            name="avalistaComplemento"
            label="Complemento"
            rules={[{ required: true }]}
          >
            <SolarzInput scale={screens.mobile ? "medium" : "large"} />
          </SolarzFormItem>
        </Col>
      </Row>
      <Flex
        style={{ marginTop: 12 }}
        align="center"
        gap={16}
        justify="space-between"
      >
        <SolarzButton variant="secondary" scale="large" onClick={handleCancel}>
          Voltar
        </SolarzButton>
        <SolarzButton scale="large" htmlType="submit" isLoading={isSubmitting}>
          Finalizar
        </SolarzButton>
      </Flex>
    </Form>
  );
}
