import type { ISolarzInputNumberProps } from "./interface";

import styled from "@emotion/styled";
import { InputNumber } from "antd";

function getStylesByScaleProps(scale?: ISolarzInputNumberProps["scale"]) {
  switch (scale) {
    case "large":
      return {
        fontSize: "16px",
        height: "2.875rem",
        lineHeight: "19.2px",
      };
    case "medium":
      return {
        fontSize: "14px",
        height: "2.375rem",
        lineHeight: "17.52px",
      };
    case "tiny":
      return {
        fontSize: "12px",
        height: "1.875rem",
        lineHeight: "15.84px",
      };
    default:
      return {
        fontSize: "14px",
        height: "2.5rem",
      };
  }
}

interface IStyledInputNumber extends ISolarzInputNumberProps {}

export const StyledInputNumber = styled(
  InputNumber<number>,
)<IStyledInputNumber>`
  width: 100%;

  input {
    width: 100%;
    height: ${(props) => getStylesByScaleProps(props.scale).height};
    font-size: ${(props) => getStylesByScaleProps(props.scale).fontSize};
    line-height: ${(props) => getStylesByScaleProps(props.scale).lineHeight};

    padding: 0rem 1rem 0rem 0rem;
  }

  > svg {
    color: var(--gray-400);
    font-size: 1.25rem;
  }

  .ant-dropdown-trigger {
    cursor: pointer;
  }

  .ant-dropdown-open {
    color: var(--primary-500);
  }

  & .ant-input-number-group-addon {
    position: absolute;
    width: min-content;
    height: 100%;
    background: none;
    right: 0px;
    border: none;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    z-index: 1;
    transition: all 0.8s;
    top: 0;
  }

  & .ant-input-number-suffix {
    right: 0rem;
    transition: all 0.3s ease-in-out;
  }

  &:hover .ant-input-number-suffix {
    right: 1rem;
  }

  & .ant-input-number-focused ~ .ant-input-number-suffix {
    right: 1rem;
  }
`;
