import { useState } from "react";
import {
  AiOutlineUsergroupAdd,
  AiOutlineDelete,
  AiOutlineLock,
} from "react-icons/ai";

import { ICompanyUser, ModalManageUsersProps } from "./interface";
import { ModalAddBusinessUnitUsers } from "./ModalAddUser";

import { FooterModal } from "~components/FooterModal";
import {
  useFetchDeleteBusinessUnitFromUser,
  useFetchGetBusinessUnitUsers,
} from "~hooks/api";
import {
  Modal,
  Row,
  Col,
  message,
  Typography,
  Button,
  Popconfirm,
  Tooltip,
  Empty,
  Flex,
} from "antd";

export const ManageUsersModal = ({
  onClose,
  isOpen,
  unit,
}: ModalManageUsersProps) => {
  const [dataSourceCompanyUsers, setDataSourceCompanyUsers] =
    useState<ICompanyUser[]>();

  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handleChangeIsVisible = () => {
    setIsVisible(!isVisible);
  };

  useFetchGetBusinessUnitUsers({
    payload: {
      businessUnitId: unit?.id ?? 0,
    },
    options: {
      enabled: !!unit?.id && unit.id > 0,
      onSuccess: (data) => {
        setDataSourceCompanyUsers(data);
      },
    },
  });

  const { mutate: fetchDeleteBusinessUnitUsers } =
    useFetchDeleteBusinessUnitFromUser({});

  const handleDeleteBusinessUnitUser = async (
    businessUnitId: number,
    userId: number,
  ) => {
    try {
      fetchDeleteBusinessUnitUsers({
        businessUnitId,
        userId,
      });
    } catch (error: any) {
      message.error(error?.message);
    }
  };

  return (
    <>
      <Modal
        data-cy="manage-users-modal"
        open={isOpen}
        onCancel={onClose}
        footer={null}
        title={`Usuários de ${unit?.name}`}
        destroyOnClose
      >
        <Row>
          {dataSourceCompanyUsers && dataSourceCompanyUsers?.length !== 0 ? (
            dataSourceCompanyUsers.map((user) => {
              return (
                <Col
                  xs={24}
                  key={user.id}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Row justify="start" style={{ width: "35vh" }}>
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {user.accountOwner ? (
                        <Tooltip title="Este  é um usuário administrador por isso não pode ser removido.">
                          <Button size="large" type="link" disabled>
                            <AiOutlineLock size={20} />
                          </Button>
                        </Tooltip>
                      ) : (
                        <Popconfirm
                          title="Deseja remover esse usuário?"
                          onConfirm={() => {
                            if (!unit?.id) {
                              return message.error(
                                "Client error: faltando o id da unidade",
                              );
                            }
                            handleDeleteBusinessUnitUser(unit.id, user.id);
                          }}
                        >
                          <Button type="link">
                            <AiOutlineDelete size={20} />
                          </Button>
                        </Popconfirm>
                      )}
                    </Col>
                    <Col
                      xs={20}
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <Typography.Text>{user.email}</Typography.Text>
                    </Col>
                  </Row>
                </Col>
              );
            })
          ) : (
            <Flex justify="center" align="center" style={{ width: "100%" }}>
              <Empty />
            </Flex>
          )}
        </Row>
        <FooterModal spaceStyle={{ justifyContent: "center" }}>
          <Flex gap={16}>
            <Button
              type="primary"
              size="small"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                margin: "10px",
              }}
              onClick={handleChangeIsVisible}
            >
              <AiOutlineUsergroupAdd size={15} />
              Adicionar usuários
            </Button>
          </Flex>
        </FooterModal>
      </Modal>
      <ModalAddBusinessUnitUsers
        open={isVisible}
        onClose={handleChangeIsVisible}
        idUnit={unit?.id}
      />
    </>
  );
};
