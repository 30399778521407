import styled from "@emotion/styled";

export interface ICalendarWrapperProps {
  displayHeader?: boolean;
}

export const CalendarWrapper = styled.div<ICalendarWrapperProps>`
  .fc-timegrid-event-harness {
    border: none;
    padding: 0;
    margin: 0;
    background: none;

    a {
      border: none;
      padding: 0;
      margin: 0;
      background: none;
    }

    .fc-event-selected::before,
    .fc-event:focus::before,
    .fc-event-selected::after,
    .fc-event:focus::after {
      background: none;
    }
  }

  & > div > div > div > div > div > table > tbody > tr:nth-child(1),
  & > div > div > div > div > div > table > tbody > tr:nth-child(2) {
    display: none;
  }

  .fc-timegrid-now-indicator-arrow {
    width: 42px;
    height: 20px;
    border-radius: 4px;
    color: white;
    background-color: var(--red-600);
    padding: 0;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -10px;
    border-radius: 0 20px 20px 0;

    span {
      color: white;
      line-height: 16.4px;
      font-size: 10px;
      font-weight: 600;
    }
  }

  .fc-timegrid-now-indicator-line {
    height: 2px !important;
    background-color: var(--red-600);
  }

  .fc-timegrid-now-indicator-line > .indicator-timer {
    display: none; // hide timer from line of cell
  }

  // DAYS HEADER
  .fc-col-header-cell {
    height: 2rem;
    background-color: var(--gray-100);

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    > div > a {
      text-transform: uppercase;
      color: var(--gray-500);
      font-weight: 600;
      font-size: 12px;
      cursor: text;
    }
  }

  table > thead > tr {
    background-color: var(--gray-100);
  }
`;
