import type { IActivityFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function ActivityFormModal({
  isOpen = false,
  onClose = () => undefined,
  maxWidth = 560,
  title = "Formulário de atividade",
  formProps,
}: IActivityFormModalProps) {
  return (
    <Modal
      data-cy="activity-form-modal"
      title={title}
      width="90vw"
      style={{
        maxWidth,
      }}
      open={isOpen}
      footer={null}
      onCancel={onClose}
      destroyOnClose
    >
      <Form.Activity {...formProps} onCancel={onClose} />
    </Modal>
  );
}
