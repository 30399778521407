import { FiAlertTriangle } from "react-icons/fi";

import { IDefaultAlertModal } from "./interface";

import { Flex, Modal, Typography } from "antd";

export function DefaultAlertModal({
  isOpen = false,
  onClose = () => null,
  onSubmit = () => undefined,
  subtitle = "Tem certeza que deseja executar essa ação?",
  title = "Aviso",
  isLoading = false,
  maxWidth = "420px",
}: IDefaultAlertModal) {
  return (
    <Modal
      data-cy="default-alert-modal"
      open={isOpen}
      onCancel={onClose}
      onOk={onSubmit}
      closable={false}
      confirmLoading={isLoading}
      width={420}
      style={{ maxWidth }}
      destroyOnClose
    >
      <Flex style={{ padding: "1rem" }}>
        <Flex align="start" gap={12} wrap="nowrap">
          <FiAlertTriangle color="var(--primary-400)" size={28} />

          <Flex vertical>
            <Typography.Title level={4} style={{ color: "var(--primary-500)" }}>
              {title}
            </Typography.Title>
            <Typography.Text style={{ color: "var(--ray-600)" }}>
              {subtitle}
            </Typography.Text>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
}
