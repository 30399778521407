import { useQuery } from "react-query";

import type { IUseGetProposalPayments } from "./interface";

import { fetchGetProposalPaymentsAutocomplete } from "~api/project/payment/simulation-item-project/custom-id/{custom-id}/autocomplete/get";

export const useFetchGetProposalPaymentsAutocompleteKey =
  "api/project/payment/simulation-item-project/custom-id/{custom-id}/auto-complete";

export function useFetchGetProposalPaymentsAutocomplete({
  dependencyArray = [],
  options,
  payload = {
    customId: 0,
  },
}: IUseGetProposalPayments) {
  return useQuery(
    [useFetchGetProposalPaymentsAutocompleteKey, dependencyArray],
    async () => await fetchGetProposalPaymentsAutocomplete(payload),
    options,
  );
}
