import { useMemo } from "react";

import type { IUpdateStructureTypeSwitchProps } from "./interface";
import styles from "./styles";

import {
  useFetchDeleteStructureType,
  useFetchUpdateStructureType,
} from "~hooks/api";
import { Flex, Switch } from "antd";

export function UpdateStructureTypeSwitch({
  structureTypeId = 0,
  structureTypeName = "",
  isChecked = false,
  onError = () => undefined,
  onSuccess = () => undefined,
}: IUpdateStructureTypeSwitchProps) {
  const { mutate: activeStructureType, isLoading: isActivatingStructureType } =
    useFetchUpdateStructureType({
      options: {
        onSuccess,
        onError,
      },
    });

  const {
    mutate: deactivateStructureType,
    isLoading: isDeactivatingStructureType,
  } = useFetchDeleteStructureType({
    options: {
      onSuccess,
      onError,
    },
  });

  function handleClick() {
    isChecked
      ? deactivateStructureType({ structureTypeId })
      : activeStructureType({ structureTypeId });
  }

  const switchIsLoading =
    isActivatingStructureType || isDeactivatingStructureType;

  const switchId = useMemo(
    () => structureTypeId.toString() || new Date().toJSON(),
    [structureTypeId],
  ); // new date just to not cause a key duplicate.

  return (
    <Flex align="center" gap={8}>
      <style jsx>{styles}</style>

      <Switch
        data-cy="update-proposal-field-switch"
        id={switchId}
        title={structureTypeName}
        checked={isChecked}
        loading={switchIsLoading}
        onClick={handleClick}
        checkedChildren="Ativo"
        unCheckedChildren="Inativo"
        className="input-update-proposal-field-switch"
      />

      <label htmlFor={switchId}>{structureTypeName}</label>
    </Flex>
  );
}
