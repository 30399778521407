import { useCallback, useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineUser } from "react-icons/ai";

import type {
  DescriptionStateType,
  IPermissionDrawerProps,
  TitleStateType,
} from "./interface";

import { Form, type IPermissionsProfileFormValues } from "~components/Form";
import { List } from "~components/List";
import { Result } from "~components/Result";
import {
  useFetchDeleteProfile,
  useFetchGetProfileById,
  useFetchUpdateProfile,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { IProfileDTOSchema, type IProfileFormSchema } from "~types/schemas";
import {
  Button,
  Col,
  Drawer,
  Flex,
  Row,
  Skeleton,
  Tooltip,
  Typography,
  Form as AntdForm,
} from "antd";

export function PermissionDrawer({
  isOpen,
  onClose = () => undefined,
  width = 960,
  profileId = 0,
}: IPermissionDrawerProps) {
  const { screens, message } = useAppConfig();

  const [form] = AntdForm.useForm<IPermissionsProfileFormValues>();

  const [formInitialData, setFormInitialData] = useState<IProfileDTOSchema>();

  const [userGroupId, setUserGroupId] = useState<number | undefined>();

  const [titleState, setTitleState] = useState<TitleStateType>({
    text: "Administrador da proposta",
    needSubmit: false,
  });

  function onChangeTitleState(data: Partial<TitleStateType>) {
    setTitleState((current) => ({ ...current, ...data }));
  }

  const [descriptionState, setDescriptionState] = useState<TitleStateType>({
    text: "Veja e edite todos os dados das propostas e gerencie configurações das propostas a nível de empresa",
    needSubmit: false,
  });

  function onChangeDescriptionState(data: Partial<DescriptionStateType>) {
    setDescriptionState((current) => ({ ...current, ...data }));
  }

  const [isEditingPermissions, setIsEditingPermissions] = useState(false);
  const [displayCheckButtonToDelete, setDisplayCheckButtonToDelete] =
    useState(false);

  function handleCloseDrawer() {
    setIsEditingPermissions(false);
    setDisplayCheckButtonToDelete(false);
    if (typeof onClose === "function") {
      onClose();
    }
  }

  const {
    data: profile,
    isFetching: isLoadingProfile,
    error: profileError,
    refetch: reloadProfile,
  } = useFetchGetProfileById({
    dependencyArray: [profileId, isOpen],
    payload: {
      profileId,
    },
    options: {
      enabled: Number(profileId) > 0 && isOpen,
      retry: 1,
      onSuccess: (profile) => {
        onChangeTitleState({ text: profile.name });
        onChangeDescriptionState({ text: profile.description });
        setUserGroupId(profile.userGroupId);
      },
    },
  });

  const { mutate: updateProfile, isLoading: isUpdatingProfile } =
    useFetchUpdateProfile({
      options: {
        onSuccess: () => {
          setIsEditingPermissions(false);
        },
      },
    });

  const { mutate: deleteProfile, isLoading: isDeletingProfile } =
    useFetchDeleteProfile({
      onSuccess: handleCloseDrawer,
    });

  const handleUpdateProfile = useCallback(
    (data: Partial<IProfileFormSchema>) => {
      const currentProfileData = profile ?? {};

      const name = data.name || profile?.name || "";
      const permissions = data.permissions || profile?.permissions || [];

      const desiredPermissions = [
        "CREATE_DEFAULT_PROPOSAL",
        "CREATE_DIVERSE_PROPOSAL",
        "CREATE_EXPRESS_PROPOSAL",
      ];

      const hasDesiredPermission = permissions.some((permission) =>
        desiredPermissions.includes(permission),
      );

      if (!hasDesiredPermission) {
        message.info(
          "O perfil precisa ter permissão para criar pelo menos um tipo de proposta.",
        );
        return;
      }

      updateProfile({
        ...currentProfileData,
        ...data,
        profileId,
        name,
        permissions,
        userGroupId: userGroupId,
      });
    },
    [message, profile, profileId, updateProfile, userGroupId],
  );

  function handleSubmitForm() {
    form.submit();
  }

  function handleCancelForm() {
    form.setFieldsValue({
      permissions: formInitialData?.permissions || [],
      userGroupId: formInitialData?.userGroupId,
    });
  }

  // UPDATE TITLE
  useEffect(() => {
    if (titleState.needSubmit) {
      handleUpdateProfile({ name: titleState.text });
      onChangeTitleState({ needSubmit: false });
    }
  }, [titleState, handleUpdateProfile]);

  // UPDATE DESCRIPTION
  useEffect(() => {
    if (descriptionState.needSubmit) {
      handleUpdateProfile({ description: descriptionState.text });
      onChangeDescriptionState({ needSubmit: false });
    }
  }, [descriptionState, handleUpdateProfile]);

  const isLoadingDataProfile =
    isLoadingProfile || isUpdatingProfile || isDeletingProfile;

  return (
    <Drawer
      open={isOpen}
      onClose={handleCloseDrawer}
      title={
        <Flex
          vertical={screens.xs}
          justify="space-between"
          style={{ width: "100%" }}
        >
          <Flex vertical gap={2} style={{ width: "100%" }}>
            {isLoadingDataProfile ? (
              <Skeleton.Input active style={{ height: 28 }} />
            ) : (
              <Typography.Title
                level={4}
                style={{
                  fontWeight: "normal",
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                }}
                editable={{
                  maxLength: 50,
                  autoSize: { maxRows: 1, minRows: 1 },
                  onChange: (value) => {
                    onChangeTitleState({ text: value });
                  },
                  onEnd: () => {
                    onChangeTitleState({ needSubmit: true });
                  },
                  tooltip: profile?.settings.default
                    ? "Não pode alterar o nome desse perfil."
                    : "Editar nome do perfil.",
                  icon: (
                    <Button
                      type="link"
                      style={{
                        padding: 0,
                        lineHeight: 0,
                      }}
                      size="small"
                      disabled={profile?.settings.default}
                    >
                      <AiOutlineEdit size={16} />
                    </Button>
                  ),
                }}
              >
                {profile?.name || "Sem título..."}
              </Typography.Title>
            )}

            {isLoadingDataProfile ? (
              <Skeleton.Input active style={{ height: 18, width: 300 }} />
            ) : (
              <Typography.Text
                type="secondary"
                style={{
                  fontWeight: "normal",
                  marginTop: 0,
                  display: "flex",
                  alignItems: "center",
                }}
                editable={{
                  maxLength: 120,
                  autoSize: { maxRows: 2, minRows: 2 },
                  onChange: (value) => {
                    onChangeDescriptionState({ text: value });
                  },
                  onEnd: () => {
                    onChangeDescriptionState({ needSubmit: true });
                  },
                  tooltip: profile?.settings.default
                    ? "Não pode alterar a descrição desse perfil."
                    : "Editar descrição.",
                  icon: (
                    <Button
                      type="link"
                      style={{
                        padding: 0,
                        lineHeight: 0,
                      }}
                      size="small"
                      disabled={profile?.settings.default}
                    >
                      <AiOutlineEdit size={16} />
                    </Button>
                  ),
                }}
              >
                {profile?.description || "Sem descrição..."}
              </Typography.Text>
            )}
          </Flex>

          {displayCheckButtonToDelete ? (
            <Flex align="start" gap={8}>
              <Button
                onClick={() => {
                  setDisplayCheckButtonToDelete(false);
                }}
              >
                Cancelar
              </Button>

              <Button
                type="primary"
                disabled={!!profileError || !profile?.settings.erasable}
                onClick={() => {
                  deleteProfile({
                    profileId,
                  });
                }}
                loading={isLoadingDataProfile}
                style={{
                  display: "flex",
                  gap: 4,
                  alignItems: "center",
                }}
                color="danger"
                danger
              >
                {!isLoadingDataProfile && (
                  <AiOutlineDelete style={{ fontSize: 18 }} />
                )}
                Tem certeza que deseja apagar?
              </Button>
            </Flex>
          ) : (
            <Tooltip
              title={
                !profile?.settings.erasable &&
                "Essa perfil não pode ser apagado."
              }
            >
              <Button
                type="primary"
                data-cy="button-delete-profile"
                disabled={!!profileError || !profile?.settings.erasable}
                onClick={() => {
                  setDisplayCheckButtonToDelete(true);
                }}
                loading={isLoadingDataProfile}
                style={{
                  display: "flex",
                  gap: 4,
                  alignItems: "center",
                  width: "min-content",
                }}
                color="danger"
                danger
              >
                {!isLoadingDataProfile && (
                  <AiOutlineDelete style={{ fontSize: 18 }} />
                )}
                Apagar perfil
              </Button>
            </Tooltip>
          )}
        </Flex>
      }
      styles={{
        body: {
          padding: 0,
        },
      }}
      width={width}
    >
      <Row style={{ minHeight: "100%", minWidth: 712 }}>
        <Col
          xs={12}
          md={16}
          style={{
            border: "1px solid var(--gray-300)",
          }}
        >
          <Flex
            justify="space-between"
            align="center"
            style={{ padding: "0.44rem" }}
          >
            <Typography.Text
              style={{
                margin: 8,
                fontWeight: "bold",
              }}
            >
              PERMISSÕES
            </Typography.Text>

            {isEditingPermissions ? (
              <Flex gap={8} align="center">
                <Button
                  onClick={() => {
                    setIsEditingPermissions(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  type="primary"
                  onClick={handleSubmitForm}
                  loading={isLoadingDataProfile}
                >
                  Salvar
                </Button>
              </Flex>
            ) : (
              <Tooltip
                title={
                  !profile?.settings.editable &&
                  "Esse perfil não pode ser editado."
                }
              >
                <Button
                  type="primary"
                  data-cy="button-edit-permissions"
                  onClick={() => {
                    setIsEditingPermissions(true);
                  }}
                  disabled={!!profileError || !profile?.settings.editable}
                  loading={isLoadingDataProfile}
                  style={{
                    display: "flex",
                    gap: 4,
                    alignItems: "center",
                  }}
                >
                  {!isLoadingDataProfile && (
                    <AiOutlineEdit style={{ fontSize: 18 }} />
                  )}
                  Editar permissões
                </Button>
              </Tooltip>
            )}
          </Flex>

          {profileError || profileId === 0 ? (
            <Result.TableErrorOrEmpty
              errorMessage={
                profileError &&
                "Falha ao carregar as permissões associadas ao perfil"
              }
              emptyMessage="Impossível carregar, esta faltando o ID do perfil"
              loading={isLoadingDataProfile}
              reloadFn={reloadProfile}
            />
          ) : (
            <Form.PermissionsProfile
              profileId={profileId}
              form={form}
              isEditingPermissions={isEditingPermissions}
              onSuccess={() => {
                setIsEditingPermissions(false);
              }}
              onCancel={handleCancelForm}
              onLoadInitialValues={(data) => {
                setFormInitialData(data);
              }}
            />
          )}
        </Col>
        <Col
          xs={12}
          md={8}
          style={{
            border: "1px solid var(--gray-300)",
          }}
        >
          <Flex vertical style={{ width: "100%" }}>
            <Flex
              align="center"
              justify="space-between"
              style={{
                width: "100%",
                borderBottom: "1px solid var(--gray-300)",
                padding: "0.75rem",
              }}
            >
              <Typography.Text
                style={{
                  margin: 0,
                  fontWeight: "bold",
                }}
              >
                USUÁRIOS
              </Typography.Text>

              <Flex align="center" gap={2}>
                <AiOutlineUser size={18} /> {profile?.users.length}
              </Flex>
            </Flex>
            <Flex vertical style={{ marginLeft: 12 }}>
              <List.ProfileUsers profileId={profileId} />
            </Flex>
          </Flex>
        </Col>
      </Row>
    </Drawer>
  );
}
