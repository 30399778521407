import styled from "@emotion/styled";
import { SolarzAnchor } from "~solarzui/SolarzAnchor";

export const CalendarIcon = styled.button`
  min-width: 20px;
  min-height: 20px;
  border: 1px solid var(--gray-500);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  outline-color: var(--primary-500);
  outline-offset: 4px;
  outline-width: 1px;

  svg {
    color: var(--gray-500);
    height: 16px !important;
    width: 16px !important;
    line-height: 0;
    font-size: 16px;
    stroke-width: 2px;
    transition: all 0.2s ease-in-out;
  }

  &.is-danger {
    border-color: var(--red-500) !important;
    color: var(--red-500) !important;
  }

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    border-color: var(--primary-500);

    svg {
      color: var(--primary-500);
    }
  }
`;

export const Deal = styled(SolarzAnchor)`
  width: 100%;
  min-height: 98px;
  text-decoration: none !important;
`;
