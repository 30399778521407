import { useMemo, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";

import type {
  ICostAggregatorListDataType,
  ICostAggregatorListProps,
} from "./interface";

import { Menu } from "~components/Menu";
import { Modal } from "~components/Modal";
import { createPaginationTableObject } from "~components/Pagination";
import { Result } from "~components/Result";
import {
  useFetchDeleteCostAggregator,
  useFetchGetPaginatedCostAggregator,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { CostAggregatorDTO } from "~types/api/CostAggregatorDTO";
import { Flex, List, message } from "antd";

export function CostAggregatorList(props: ICostAggregatorListProps) {
  const { screens } = useAppConfig();

  const [currentPage, setCurrentPage] = useState(1);

  const [selectedCostAggregatorToUpdate, setSelectedCostAggregatorToUpdate] =
    useState<ICostAggregatorListDataType>();

  const [selectedCostAggregatorToDelete, setSelectedCostAggregatorToDelete] =
    useState<ICostAggregatorListDataType>();

  const { mutate: deleteCostAggregator, isLoading: isDeletingCostAggregator } =
    useFetchDeleteCostAggregator({
      options: {
        onSuccess: () => setSelectedCostAggregatorToDelete(undefined),
      },
    });

  const { data, isFetching, error } = useFetchGetPaginatedCostAggregator({
    payload: {
      page: currentPage - 1,
      size: 5,
    },
    dependencyArray: [currentPage],
    options: {
      enabled: currentPage > 0,
    },
  });

  const dataSource = useMemo(() => {
    return data?.content.map((cost: CostAggregatorDTO) => ({
      id: cost.id,
      name: cost.name,
      identifier: cost.entityIdentifier?.identifier,
    }));
  }, [data]);

  function handleDeleteSubmit() {
    const costAggregatorId = selectedCostAggregatorToDelete?.id;

    if (!costAggregatorId) {
      message.error("Client error: Faltando o ID do custo");
      return;
    }

    deleteCostAggregator({ costAggregatorId: costAggregatorId });
  }

  return (
    <>
      <List
        data-cy="cost-aggregator-list"
        {...props}
        itemLayout="horizontal"
        dataSource={dataSource}
        loading={isFetching}
        pagination={createPaginationTableObject({
          itemType: "grupos",
          number: currentPage,
          onChange: (page, _) => {
            setCurrentPage(page);
          },
          size: data?.size,
          totalElements: data?.totalElements,
          displayTotalInformation: screens.sm,
        })}
        renderItem={(cost) => (
          <List.Item key={cost.id}>
            <Flex
              align="center"
              justify="space-between"
              style={{ width: "100%", paddingRight: "0.5rem" }}
            >
              <List.Item.Meta title={cost.name} />
              <Flex
                align="center"
                gap={16}
                style={{ marginRight: "1rem" }}
                data-cy="button-actions"
              >
                <Menu.MoreDropdown
                  position="bottomRight"
                  icon="cog"
                  items={[
                    {
                      icon: (
                        <AiOutlineEdit
                          fontSize={20}
                          style={{ color: "var(--gold-500)" }}
                        />
                      ),
                      text: "Editar",
                      color: "var(--gold-500)",
                      onClick: () => setSelectedCostAggregatorToUpdate(cost),
                    },
                    {
                      icon: (
                        <AiOutlineDelete
                          fontSize={20}
                          style={{ color: "var(--volcano-500)" }}
                        />
                      ),
                      text: "Remover",
                      color: "var(--volcano-500)",
                      onClick: () => setSelectedCostAggregatorToDelete(cost),
                    },
                  ]}
                />
              </Flex>
            </Flex>
          </List.Item>
        )}
        locale={{
          emptyText: (
            <Result.TableErrorOrEmpty
              emptyMessage="Nenhum grupo cadastrado ainda"
              errorMessage={error?.message}
            />
          ),
        }}
      />

      <Modal.DefaultRemove
        isOpen={!!selectedCostAggregatorToDelete}
        onClose={() => setSelectedCostAggregatorToDelete(undefined)}
        isLoading={isDeletingCostAggregator}
        itemName={selectedCostAggregatorToDelete?.name}
        suffix="agregador de custo"
        title="Agregador de custo"
        onSubmit={handleDeleteSubmit}
      />

      <Modal.CostAggregatorFormModalForm
        formProps={{
          initialValues: {
            id: selectedCostAggregatorToUpdate?.id,
            name: selectedCostAggregatorToUpdate?.name ?? "",
            identifier: selectedCostAggregatorToUpdate?.identifier,
          },
          onSuccess: () => setSelectedCostAggregatorToUpdate(undefined),
        }}
        isOpen={!!selectedCostAggregatorToUpdate}
        onClose={() => setSelectedCostAggregatorToUpdate(undefined)}
      />
    </>
  );
}
