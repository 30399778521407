import type { IAldoIntegrationFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function AldoIntegrationFormModal({
  isOpen = false,
  onClose = () => undefined,
  title = "Aldo integração",
  maxWidth = 480,
  formProps,
}: IAldoIntegrationFormModalProps) {
  return (
    <Modal
      data-cy="aldo-integration-form-modal"
      open={isOpen}
      onCancel={onClose}
      title={title}
      width="90vw"
      footer={null}
      style={{ maxWidth }}
      destroyOnClose
    >
      <Form.AldoIntegration {...formProps} onCancel={onClose} />
    </Modal>
  );
}
