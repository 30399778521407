import type { IUpdateProposalFieldSwitchProps } from "./interface";
import styles from "./styles";

import { useFetchUpdateProposalFields } from "~hooks/api";
import { Flex, Switch, Tooltip } from "antd";

export function UpdateProposalFieldSwitch({
  proposalFieldId = 0,
  proposalFieldName = "",
  isChecked = false,
  isDisabled = false,
  onError = () => undefined,
  onSuccess = () => undefined,
}: IUpdateProposalFieldSwitchProps) {
  const { mutate: updateProposalField, isLoading: isUpdatingProposalField } =
    useFetchUpdateProposalFields({
      onSuccess,
      onError,
    });

  function handleClick() {
    updateProposalField({
      fieldId: proposalFieldId,
    });
  }

  const switchId = proposalFieldId?.toString() || new Date().toJSON(); // new date just to not cause a key duplicate.

  return (
    <Flex align="center" gap={8}>
      <style jsx>{styles}</style>
      <Switch
        data-cy="update-proposal-field-switch"
        id={switchId}
        title={proposalFieldName}
        checked={isChecked}
        disabled={isDisabled}
        loading={isUpdatingProposalField}
        onClick={handleClick}
        checkedChildren="Ativo"
        unCheckedChildren="Inativo"
        className="input"
      />
      <label htmlFor={switchId}>
        <Tooltip title={isDisabled && "Campo não editável"} placement="right">
          {proposalFieldName}
        </Tooltip>
      </label>
    </Flex>
  );
}
