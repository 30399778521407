import { IAuxiliaryVariablesFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function AuxiliaryVariablesFormModal({
  isOpen = false,
  onClose = () => null,
  title = "Variável auxiliar",
  width = "90vw",
  formProps = {},
}: IAuxiliaryVariablesFormModalProps) {
  return (
    <Modal
      data-cy="auxiliary-variables-form-modal"
      open={isOpen}
      onCancel={onClose}
      title={title}
      width={width}
      footer={null}
      destroyOnClose
      style={{ maxWidth: 1000 }}
    >
      <Form.AuxiliaryVariables onCancel={onClose} {...formProps} />
    </Modal>
  );
}
