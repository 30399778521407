export * from "./CustomUserAvatar/interface";
import { CustomUserAvatar } from "./CustomUserAvatar";
export * from "./LoggedUserAvatarBackup/interface";
import { LoggedUserAvatarBackup } from "./LoggedUserAvatarBackup";
export * from "./UserAvatar/interface";
import { UserAvatar } from "./UserAvatar";

export const Avatar = {
  LoggedUserBackup: LoggedUserAvatarBackup,
  User: UserAvatar,
  CustomUser: CustomUserAvatar,
};
