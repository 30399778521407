import { useMemo } from "react";

import { useRouter } from "next/router";

import {
  EosFinancialProposalsTableDataType,
  EosFinancialProposalsTableQueryParamKeysEnum,
  type IEosFinancialProposalsTableProps,
  type EosFinancialProposalsTableFiltersType,
  type EosFinancialProposalsTableQueryParamsKeysType,
} from "./interface";
import { TableWrapper } from "./styles";

import { Tag } from "~components/Tag";
import { useFetchGetEosFinancialProposals } from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { useQueryParams, parseFunctions } from "~hooks/useQueryParams";
import { SolarzAnchor } from "~solarzui/SolarzAnchor";
import { SolarzTable } from "~solarzui/SolarzTable";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { parseCurrency } from "~utils/parse";
import { Flex } from "antd";

export function EosFinancialProposalsTable(
  props: IEosFinancialProposalsTableProps,
) {
  const router = useRouter();
  const { screens } = useAppConfig();
  const { getParsedQueryParams, handleSaveInQueryParams } = useQueryParams();

  const QUERY_PARAMS_KEYS: EosFinancialProposalsTableQueryParamsKeysType =
    useMemo(
      () => ({
        ...EosFinancialProposalsTableQueryParamKeysEnum,
        ...props.queryParamKeys,
      }),
      [props.queryParamKeys],
    );

  const filters: EosFinancialProposalsTableFiltersType = useMemo(
    () => ({
      currentPage:
        getParsedQueryParams(
          QUERY_PARAMS_KEYS.CURRENT_PAGE,
          parseFunctions.NUMBER,
        ) ?? 1,
      name:
        getParsedQueryParams(QUERY_PARAMS_KEYS.QUERY, parseFunctions.STRING) ??
        undefined,
    }),
    [getParsedQueryParams, QUERY_PARAMS_KEYS],
  );

  const {
    data: EosFinancialProposalsData,
    isFetching: isLoadingPersons,
    error: personsError,
    refetch: reloadPersons,
  } = useFetchGetEosFinancialProposals({
    dependencyArray: [filters],
    options: {
      retry: 1,
      enabled: filters.currentPage > 0,
    },
    payload: {
      page: filters.currentPage - 1,
      size: 10, // STATIC,
    },
  });

  return (
    <TableWrapper>
      <Flex vertical>
        <SolarzTable<EosFinancialProposalsTableDataType>
          data-cy="eos-financial-proposals-table"
          columns={[
            {
              title: "PROTOCOLO",
              dataIndex: "protocol",
              key: "protocol",
              width: screens.desktop ? 246 : 246,
              render: (_, { protocol }) => `${protocol}`,
            },
            {
              title: "CLIENTE",
              dataIndex: "clientName",
              key: "clientName",
              render: (_, { clientName }) => clientName,
              width: 346,
              hidden: screens.mobile,
            },
            {
              title: "STATUS",
              dataIndex: "status",
              key: "status",
              render: (_, { status }) => {
                return (
                  <Tag.UniqueValue
                    color="gold"
                    title={status}
                    style={{ borderRadius: "24px" }}
                  />
                );
              },
              hidden: !screens.desktop,
              width: 246,
            },
            {
              title: "DETALHES",
              dataIndex: "gracePeriod",
              key: "gracePeriod",
              render: (_, { gracePeriod, installment }) => {
                return (
                  <Flex align="flex-start" gap={12} vertical>
                    <SolarzTypography
                      fontWeight="regular"
                      variant="disabled"
                      hierarchy="small"
                    >
                      <span style={{ fontWeight: "bold" }}>Carência</span>{" "}
                      {gracePeriod}{" "}
                      {!!gracePeriod && gracePeriod > 1 ? "meses" : "mês"}
                    </SolarzTypography>
                    <SolarzTypography
                      fontWeight="regular"
                      variant="disabled"
                      hierarchy="small"
                    >
                      <span style={{ fontWeight: "bold" }}>Prestação</span>{" "}
                      {parseCurrency(installment) || "-"}
                    </SolarzTypography>
                  </Flex>
                );
              },
              hidden: !screens.desktop,
              width: 246,
            },
            {
              title: "VALORES",
              dataIndex: "totalValue",
              key: "totalValue",
              render: (_, { totalValue, downPayment }) => {
                return (
                  <Flex align="flex-start" gap={12} vertical>
                    {" "}
                    <SolarzTypography
                      fontWeight="regular"
                      variant="disabled"
                      hierarchy="small"
                    >
                      <span style={{ fontWeight: "bold" }}>Total</span>{" "}
                      {parseCurrency(totalValue) || "-"}
                    </SolarzTypography>
                    <SolarzTypography
                      fontWeight="regular"
                      variant="disabled"
                      hierarchy="small"
                    >
                      <span style={{ fontWeight: "bold" }}>Entrada</span>{" "}
                      {parseCurrency(downPayment) || "-"}
                    </SolarzTypography>
                  </Flex>
                );
              },
              hidden: !screens.desktop,
              width: 246,
            },
            {
              title: "GERENCIAR",
              width: 176,
              render: (_, record) => {
                return (
                  <SolarzAnchor
                    isNextLink
                    href={`/configuracoes-de-propostas/financiamento/EOS/${record.protocol}`}
                  >
                    Ver mais
                  </SolarzAnchor>
                );
              },
              onCell: screens.tablet
                ? (record) => {
                    return {
                      onClick: () =>
                        router.push(
                          `/configuracoes-de-propostas/financiamento/EOS/${record.protocol}`,
                        ),
                    };
                  }
                : undefined,
            },
          ]}
          rows={EosFinancialProposalsData?.content ?? []}
          isLoading={isLoadingPersons}
          pagination={{
            currentItemCount: EosFinancialProposalsData?.size ?? 0,
            currentPage: filters.currentPage || 1,
            itemLabel: "financiamentos",
            totalItemCount: EosFinancialProposalsData?.totalElements ?? 0,
            onChange: (currentPage) => {
              handleSaveInQueryParams({
                [QUERY_PARAMS_KEYS.CURRENT_PAGE]: currentPage,
              });
            },
          }}
          scroll={
            screens.mobile
              ? {
                  x: undefined,
                  y: "calc(100vh - 376px)",
                }
              : {
                  x: undefined,
                }
          }
          emptyMessage="Sem financiamentos encontrados"
          errorMessage={personsError?.message}
          reloadFn={reloadPersons}
          rowKey="protocol"
          showHeader={!screens.mobile}
          rowClassName={screens.desktop ? undefined : "cursor-pointer"}
        />
      </Flex>
    </TableWrapper>
  );
}
