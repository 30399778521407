import { useState } from "react";
import { BsDownload } from "react-icons/bs";

import type { IImportUsersListModalButtonProps } from "./interface";

import { Modal } from "~components/Modal";
import { SolarzButton } from "~solarzui/SolarzButton";

export function ImportUsersListModalButton({
  children,
  icon = <BsDownload size={22} />,
  variant = "primary",
  ...props
}: IImportUsersListModalButtonProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <SolarzButton
        {...props}
        variant={variant}
        data-cy="import-users-list-modal-button"
        onClick={() => setIsModalOpen(true)}
      >
        {icon}
        {children ?? "Importar usuários"}
      </SolarzButton>

      <Modal.ImportUserList
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
}
