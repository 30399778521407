import styled from "@emotion/styled";
import { Flex, Spin } from "antd";

export const StyledSpin = styled(Spin)`
  & .ant-spin-dot-item {
    background-color: var(--white) !important;
  }
`;

export const StyledFlex = styled(Flex)`
  width: 100%;
  padding: 0.5rem;
  overflow-y: auto;

  & .ant-form-item {
    width: ${(props) => (props.vertical ? "auto" : "100%")};
    margin: 0;
  }

  & .ant-form-item > .ant-row {
    flex-direction: column;
  }

  & .ant-form-item > .ant-row > .ant-form-item-label {
    text-align: left !important;
    height: 28px;
  }
`;
