import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { BsNodePlusFill } from "react-icons/bs";

import type {
  IKitsTableColumn,
  IKitsTableData,
  IKitsTableFilters,
  IKitsTableProps,
  IKitsTablePagination,
  IKitsTableSort,
  IInverterSearchType,
  IModuleSearchType,
  IKitsTableKitColumnActiveFiltersState,
  ICollapseKeyKitFiltersColumnType,
  IEdeltecFiltersType,
  IInverterType,
  IFortlevFiltersType,
  IAldoFiltersType,
  IEcoriFiltersType,
} from "./interface";
import { StyledCollapse } from "./styles";

import { ColumnFilter } from "~components/ColumnFilter";
import { CustomInput } from "~components/CustomInput";
import { Modal } from "~components/Modal";
import { createPaginationTableObject } from "~components/Pagination";
import { Result } from "~components/Result";
import { Select } from "~components/Select";
import { Tag } from "~components/Tag";
import { ENV } from "~configs/Env";
import { useAppConfig } from "~hooks/useAppConfig";
import { useSession } from "~hooks/useSession";
import type { ModulePhaseEnum, PhaseEnum, VoltageEnum } from "~types/enum";
import { parseCurrency, parseEnergy, parsePotency } from "~utils/parse";
import {
  Button,
  Table,
  Form,
  Tooltip,
  Divider,
  Radio,
  Select as AntdSelect,
  Typography,
  Flex as AntdFlex,
  Flex,
  Checkbox,
} from "antd";
import { CollapseProps } from "antd/lib";

const DEFAULT_FILTERS = {
  fullPower: 1,
  outputVoltage: ["V110", "V220"],
  phase: ["Monofásico", "Trifásico"],
  moduleFaceType: ["MONOFACIAL"],
  inverterSearchType: "BETWEEN",
  moduleSearchType: "BETWEEN",
} as IKitsTableFilters;

const DEFAULT_SORT = {
  by: "pricePerKwp",
  order: "ascend",
} as IKitsTableSort;

const DEFAULT_PAGINATION = {
  number: 1,
  totalElements: 0,
  size: 10,
} as IKitsTablePagination;

export function KitsTable({
  kits = [],
  defaultFilters = {
    fullPower: 1,
    outputVoltage: ["V110", "V220"],
    phase: ["Monofásico", "Trifásico"],
    moduleFaceType: ["MONOFACIAL"],
    inverterSearchType: "BETWEEN",
    moduleSearchType: "BETWEEN",
  },
  defaultPagination = {
    number: 1,
    totalElements: 0,
    size: 10,
  },
  defaultSort = {
    by: "pricePerKwp",
    order: "ascend",
  },
  onSelectKit = () => null,
  disableSelect = false,
  onSubmit = () => undefined,
  isLoading = false,
  size = "small",
  scroll = {
    x: 1280,
    y: undefined,
  },
  tablePaginationPosition = ["bottomRight"],
  displayKitItemsDetailsPrices = true,
  displayKomecoFilters = false,
  displayEdeltecFilters = false,
  displayFortlevFilters = false,
  displayAldoFilters = false,
  displayEcoriFilters = false,
  defaultActiveFilters = {
    default: true,
    komeco: false,
    edeltec: false,
    fortlev: false,
    aldo: false,
    ecori: false,
  },
}: IKitsTableProps) {
  const { userHavePermission, userIsAdmin } = useSession();
  const { screens } = useAppConfig();

  const [activeFilters, setActiveFilters] =
    useState<IKitsTableKitColumnActiveFiltersState>({
      default: defaultActiveFilters?.default ?? true,
      komeco: defaultActiveFilters?.komeco ?? false,
      edeltec: defaultActiveFilters?.edeltec ?? false,
      fortlev: defaultActiveFilters?.fortlev ?? false,
      aldo: defaultActiveFilters?.aldo ?? false,
      ecori: defaultActiveFilters?.ecori ?? false,
    });

  const [filters, setFilters] = useState<IKitsTableFilters>({
    ...DEFAULT_FILTERS,
    ...defaultFilters,
    phase: defaultFilters.phase?.includes("Trifásico")
      ? ["Monofásico", "Trifásico"]
      : ["Monofásico"],
  });

  function onChangeEdeltecFilters(
    updatedFilters: Partial<IEdeltecFiltersType>,
  ) {
    setFilters((current) => ({
      ...current,
      edeltec: {
        ...current.edeltec,
        ...updatedFilters,
      },
    }));
  }

  function onChangeFortlevFilters(
    updatedFilters: Partial<IFortlevFiltersType>,
  ) {
    setFilters((current) => ({
      ...current,
      fortlev: {
        ...current.fortlev,
        ...updatedFilters,
      },
    }));
  }

  function onChangeAldoFilters(updatedFilters: Partial<IAldoFiltersType>) {
    setFilters((current) => ({
      ...current,
      aldo: {
        ...current.aldo,
        ...updatedFilters,
      },
    }));
  }

  function onChangeEcoriFilters(updatedFilters: Partial<IEcoriFiltersType>) {
    setFilters((current) => ({
      ...current,
      ecori: {
        ...current.ecori,
        ...updatedFilters,
      },
    }));
  }

  const [sort, setSort] = useState<IKitsTableSort>({
    ...DEFAULT_SORT,
    ...defaultSort,
  });

  const pagination = useMemo(() => {
    return {
      ...DEFAULT_PAGINATION,
      ...defaultPagination,
    };
  }, [defaultPagination]);

  const [selectedKitDetails, setSelectedKitDetails] = useState<
    IKitsTableData | undefined
  >();

  const [isYouTubeVideoModalOpen, setIsYouTubeVideoModalOpen] = useState(false);

  const DATA_SOURCE: IKitsTableData[] = useMemo(() => kits, [kits]);

  const getActiveFilters = useCallback(() => {
    const {
      komeco: restKomecoFilters,
      fortlev: restFortlevFilters,
      aldo: restAldoFilters,
      ecori: restEcoriFilters,
      edeltec: restEdeltecFilters,
      ...restDefaultFilters
    } = filters;
    const defaultActiveFilters = activeFilters.default
      ? {
          ...restDefaultFilters,
          fullPower: filters?.fullPower ?? defaultFilters?.fullPower,
        }
      : undefined;

    const komecoActiveFilters = activeFilters.komeco
      ? restKomecoFilters
      : undefined;

    const fortlevActiveFilters = activeFilters.fortlev
      ? restFortlevFilters
      : undefined;

    const aldoActiveFilters = activeFilters.aldo ? restAldoFilters : undefined;

    const ecoriActiveFilters = activeFilters.ecori
      ? restEcoriFilters
      : undefined;

    const edeltecActiveFilters = activeFilters.edeltec
      ? restEdeltecFilters
      : undefined;

    const filteredFilters: IKitsTableFilters = {
      ...defaultActiveFilters,
      komeco: komecoActiveFilters,
      fortlev: fortlevActiveFilters,
      aldo: aldoActiveFilters,
      ecori: ecoriActiveFilters,
      edeltec: edeltecActiveFilters,
    };

    return filteredFilters;
  }, [
    activeFilters.default,
    activeFilters.komeco,
    activeFilters.aldo,
    activeFilters.fortlev,
    activeFilters.ecori,
    activeFilters.edeltec,
    defaultFilters,
    filters,
  ]);

  // RENDER COLUMN CELL
  function renderKit(data: IKitsTableData) {
    const formattedPotency = data.fullPower
      ? parsePotency(data.fullPower, 2, true)
      : "-";

    const formattedGeneration = data.generation
      ? parseEnergy(data.generation)
      : "-";

    return (
      <Flex vertical>
        <span>{data.name}</span>
        <Flex align="center" wrap="nowrap">
          <Tag.UniqueValue
            title={formattedPotency}
            tooltip={`Potência: ${formattedPotency}`}
            color="red"
          />
          <Divider orientation="center" type="vertical" />
          <Tag.UniqueValue
            title={data.structure ?? "Sem  estrutura"}
            tooltip={data.structure ?? "Sem  estrutura"}
            color="orange"
          />
          <Divider orientation="center" type="vertical" />
          <Tag.UniqueValue
            title={data.phase}
            tooltip={`Fase: ${data.phase}`}
            color="volcano"
          />
          <Divider orientation="center" type="vertical" />
          <Tag.UniqueValue
            title={formattedGeneration}
            tooltip={`Geração média: ${formattedGeneration}`}
            color="magenta"
          />
        </Flex>
      </Flex>
    );
  }

  function renderSupplierName(data: IKitsTableData) {
    const formattedSupplierName = data.supplierName ?? "-";

    return (
      <span style={{ whiteSpace: "nowrap" }}>{formattedSupplierName}</span>
    );
  }

  function renderPricePerKwp(data: IKitsTableData) {
    return data.pricePerKwp ? parseCurrency(data.pricePerKwp) : "-";
  }

  function renderTotalValue(data: IKitsTableData) {
    return data.totalValue ? parseCurrency(data.totalValue) : "-";
  }

  function renderInversor(data: IKitsTableData) {
    const brand = data.inverterBrand ?? "-";

    const potency = data.inverterPower
      ? parsePotency(data.inverterPower, 2, true)
      : "-";

    const inversorList =
      data.items?.filter(
        (item) => item.category === "INVERTER" || item.category === "Inversor",
      ) ?? [];

    const formattedInversorList = inversorList
      .map(
        (inversor) =>
          `${Number(inversor.quantity) > 1 ? `${inversor.quantity} x` : ""} ${
            inversor.name
          }`,
      )
      .join(", ");

    const formattedInversorListToComponent = inversorList.map((item, index) => {
      const quantity = Number(item.quantity);

      const QuantityElement =
        quantity > 1 ? <span>{`${quantity} x`}</span> : <span></span>;

      const NameElement = <b>{item.name ?? ""}</b>;

      return (
        <Fragment key={index}>
          {index > 0 && <Divider type="vertical" />}
          {QuantityElement} {NameElement}
        </Fragment>
      );
    });

    const tooltipTitle = `${formattedInversorList} | ${potency} | ${brand}`;

    const haveSomeInversorOutOfStock = data.items?.some(
      (item) => item.category === "INVERTER" && item.outOfStock,
    );

    return (
      <Tooltip title={tooltipTitle}>
        <Flex vertical>
          <Typography.Text ellipsis style={{ width: "max-content" }}>
            <span style={{ whiteSpace: "nowrap" }}>
              {formattedInversorListToComponent}
            </span>
            <Divider orientation="center" type="vertical" />
            <span style={{ whiteSpace: "nowrap" }}>{potency}</span>
            <Divider orientation="center" type="vertical" />
            <span style={{ whiteSpace: "nowrap" }}>{brand}</span>
          </Typography.Text>

          {haveSomeInversorOutOfStock && (
            <Tag.UniqueValue
              title="Sem estoque"
              color="red"
              style={{ width: "min-content" }}
            />
          )}
        </Flex>
      </Tooltip>
    );
  }

  function renderModule(data: IKitsTableData) {
    const moduleList =
      data.items?.filter(
        (item) => item.category === "MODULE" || item.category === "Módulo",
      ) ?? [];

    const formattedModuleListToString = moduleList
      .map(
        (item) =>
          `${Number(item.quantity) > 1 ? `${item.quantity} x` : ""} ${
            item.name
          } `,
      )
      .join(", ");

    const formattedModuleListToComponent = moduleList.map((item, index) => {
      const quantity = Number(item.quantity);

      const QuantityElement =
        quantity > 1 ? <span>{`${quantity} x`}</span> : <span></span>;

      const NameElement = <b>{item.name ?? ""}</b>;

      return (
        <Fragment key={index}>
          {index > 0 && <Divider type="vertical" />}
          {QuantityElement} {NameElement}
        </Fragment>
      );
    });

    const tooltipTitle = `${formattedModuleListToString}`;

    const haveSomeModuleOutOfStock = data.items?.some(
      (item) => item.category === "MODULE" && item.outOfStock,
    );

    return (
      <Tooltip title={tooltipTitle}>
        <Flex vertical>
          <Typography.Text ellipsis style={{ width: "max-content" }}>
            <span style={{ whiteSpace: "nowrap" }}>
              {formattedModuleListToComponent}
            </span>
          </Typography.Text>

          {haveSomeModuleOutOfStock && (
            <Tag.UniqueValue
              title="Sem estoque"
              color="red"
              style={{ width: "min-content" }}
            />
          )}
        </Flex>
      </Tooltip>
    );
  }

  // COLUMNS FILTERS
  function renderKitFilter() {
    return (
      <ColumnFilter
        isLoading={isLoading}
        onReset={() =>
          setFilters((current) => ({
            ...current,
            fullPower: defaultFilters.fullPower,
            inverterMinPower: defaultFilters.inverterMinPower,
            inverterMaxPower: defaultFilters.inverterMaxPower,
            inverterPower: defaultFilters.inverterPower,
            inverterSearchType: defaultFilters.inverterSearchType ?? "BETWEEN",
            inverterType: undefined,
            moduleMaxPowerWp: defaultFilters.moduleMaxPowerWp,
            moduleMinPowerWp: defaultFilters.moduleMinPowerWp,
            modulePowerWp: defaultFilters.modulePowerWp,
            moduleSearchType: defaultFilters.moduleSearchType ?? "BETWEEN",
            phase: defaultFilters.phase,
            name: defaultFilters.name,
            ignoreElectricUtilityRules:
              !!defaultFilters.ignoreElectricUtilityRules,
            inverterNames: defaultFilters.inverterNames,
            moduleNames: defaultFilters.moduleNames,
            komeco: defaultFilters.komeco,
            edeltec: defaultFilters.edeltec,
            fortlev: defaultFilters.fortlev,
            aldo: defaultFilters.aldo,
            ecori: defaultFilters.ecori,
            microAndStringInverterMixAllowedInSolarKit:
              defaultFilters.microAndStringInverterMixAllowedInSolarKit,
            differentPhaseInverterMixAllowedInSolarKit:
              defaultFilters.differentPhaseInverterMixAllowedInSolarKit,
          }))
        }
        onSubmit={() =>
          onSubmit({
            filters: getActiveFilters(),
            sort,
            page: pagination.number,
          })
        }
        width="32rem"
        direction="row"
        isDisabled={!filters.fullPower && activeFilters.default}
        searchTooltip={
          !filters.fullPower && activeFilters.default
            ? "A potência do kit é obrigatória!"
            : ""
        }
        fields={
          displayEdeltecFilters ||
          displayKomecoFilters ||
          displayFortlevFilters ||
          displayAldoFilters ||
          displayEcoriFilters
            ? [
                <StyledCollapse
                  key="kit-filters-collapse"
                  accordion
                  style={{ width: "100%" }}
                  size="small"
                  defaultActiveKey={[]}
                  items={
                    [
                      {
                        key: "DEFAULT_FITLERS" as ICollapseKeyKitFiltersColumnType,
                        label: "Filtros gerais",
                        extra: (
                          <AntdFlex
                            onClick={(e) => e.stopPropagation()}
                            gap={4}
                          >
                            <Tooltip title="Se ativo vamos utilizar esse filtro na pesquisa">
                              <label
                                key="defaultFilterActive"
                                htmlFor="defaultFilterActive"
                              >
                                Ativo:{" "}
                                <Checkbox
                                  id="defaultFilterActive"
                                  checked={activeFilters.default}
                                  onChange={() => {
                                    setActiveFilters((current) => ({
                                      ...current,
                                      default: !current.default,
                                    }));
                                  }}
                                />
                              </label>
                            </Tooltip>
                          </AntdFlex>
                        ),
                        children: (
                          <>
                            <Divider
                              style={{
                                margin: "0rem",
                                marginTop: "0.75rem",
                                color: "var(--primary-500)",
                              }}
                              orientation="left"
                              plain
                            >
                              Filtros do kit
                            </Divider>
                            <AntdFlex
                              wrap={screens.xs ? "wrap" : "nowrap"}
                              gap={8}
                            >
                              <Form.Item
                                key="name"
                                htmlFor="name"
                                label="Nome do kit"
                                colon={false}
                                style={{ width: "100%" }}
                              >
                                <CustomInput.Text
                                  id="name"
                                  value={filters.name}
                                  onChange={(event) => {
                                    setFilters((current) => ({
                                      ...current,
                                      name: event.target.value,
                                    }));
                                  }}
                                  placeholder="Nome do kit..."
                                />
                              </Form.Item>
                              <Form.Item
                                key="fullPower"
                                htmlFor="fullPower"
                                label="Potência do kit"
                                tooltip="Potência do kit, valor padrão é o informado nos passos anteriores"
                                colon={false}
                                style={{ width: "100%" }}
                                rules={[{ required: true }]}
                                required
                              >
                                <CustomInput.UnitMeasurement
                                  id="fullPower"
                                  value={filters.fullPower}
                                  precision="2"
                                  unitMeasurement="KWp"
                                  style={{ width: "100%" }}
                                  onChange={(value) => {
                                    const fullPower = value
                                      ? Number(value)
                                      : undefined;

                                    setFilters((current) => ({
                                      ...current,
                                      fullPower,
                                    }));
                                  }}
                                  min={0}
                                  placeholder="Ex.: 100"
                                />
                              </Form.Item>
                            </AntdFlex>
                            <Divider
                              key="divider_inverter"
                              style={{
                                margin: "0rem",
                                marginTop: "0.75rem",
                                color: "var(--primary-500)",
                              }}
                              orientation="left"
                              plain
                            >
                              Filtros dos inversores
                            </Divider>
                            <Form.Item
                              key="inverterNames"
                              htmlFor="inverterNames"
                              label="Nome contenha:"
                              colon={false}
                              style={{ width: "100%" }}
                              initialValue={defaultFilters.inverterNames}
                            >
                              <AntdSelect
                                mode="tags"
                                onChange={(value) =>
                                  setFilters((current) => ({
                                    ...current,
                                    inverterNames: value,
                                  }))
                                }
                                allowClear={false}
                                defaultValue={defaultFilters.inverterNames}
                                value={filters.inverterNames}
                              />
                            </Form.Item>
                            <Form.Item
                              key="inverterType"
                              htmlFor="inverterType"
                              label="Filtrar inversores por"
                              colon={false}
                              style={{ width: "100%" }}
                              initialValue={defaultFilters.inverterType}
                            >
                              <AntdSelect<IInverterType>
                                options={[
                                  { label: "String", value: "STRING" },
                                  { label: "Micro", value: "MICRO" },
                                  {
                                    label: "Otimizador",
                                    value: "OPTIMIZER",
                                  },
                                ]}
                                onChange={(value) =>
                                  setFilters((current) => ({
                                    ...current,
                                    inverterType: value,
                                  }))
                                }
                                allowClear={true}
                                value={filters.inverterType}
                              />
                            </Form.Item>
                            <Form.Item
                              key="inverterSearchType"
                              htmlFor="inverterSearchType"
                              label="Filtrar potência por"
                              colon={false}
                              style={{ width: "100%" }}
                              initialValue={
                                defaultFilters.inverterSearchType ?? "BETWEEN"
                              }
                            >
                              <AntdSelect<IInverterSearchType>
                                options={[
                                  { label: "Por faixa", value: "BETWEEN" },
                                  { label: "Valor exato", value: "EXACTLY" },
                                ]}
                                onChange={(value) =>
                                  setFilters((current) => ({
                                    ...current,
                                    inverterSearchType: value,
                                  }))
                                }
                                allowClear={false}
                                defaultValue="BETWEEN"
                                value={filters.inverterSearchType}
                              />
                            </Form.Item>
                            <Form.Item
                              key="inverterPower"
                              htmlFor="inverterPower"
                              label="Potência dos inversores"
                              colon={false}
                              style={{ width: "100%" }}
                              hidden={filters.inverterSearchType !== "EXACTLY"}
                            >
                              <CustomInput.UnitMeasurement
                                id="inverterPower"
                                value={filters.inverterPower}
                                precision="2"
                                unitMeasurement="KWp"
                                onChange={(value) => {
                                  const parsedValue = Number(value);
                                  setFilters((current) => ({
                                    ...current,
                                    inverterPower: isNaN(parsedValue)
                                      ? 0
                                      : parsedValue,
                                  }));
                                }}
                                placeholder="Ex.: 25"
                              />
                            </Form.Item>
                            <AntdFlex
                              wrap={screens.xs ? "wrap" : "nowrap"}
                              gap={8}
                            >
                              <Form.Item
                                key="inverterMinPower"
                                htmlFor="inverterMinPower"
                                label="Potência mínima dos inversores"
                                tooltip="Potência mínima dos inversores"
                                colon={false}
                                style={{ width: screens.xs ? "100%" : "50%" }}
                                hidden={
                                  filters.inverterSearchType !== "BETWEEN"
                                }
                              >
                                <CustomInput.UnitMeasurement
                                  id="inverterMinPower"
                                  value={filters.inverterMinPower}
                                  precision="2"
                                  unitMeasurement="KWp"
                                  onChange={(value) => {
                                    const parsedValue = Number(value);
                                    setFilters((current) => ({
                                      ...current,
                                      inverterMinPower: isNaN(parsedValue)
                                        ? 0
                                        : parsedValue,
                                    }));
                                  }}
                                  min={0}
                                  max={filters.inverterMaxPower}
                                  placeholder="Ex.: 25"
                                />
                              </Form.Item>
                              <Form.Item
                                key="inverterMaxPower"
                                htmlFor="inverterMaxPower"
                                label="Potência máxima dos inversores"
                                tooltip="Potência máxima dos inversores"
                                colon={false}
                                style={{ width: screens.xs ? "100%" : "50%" }}
                                hidden={
                                  filters.inverterSearchType !== "BETWEEN"
                                }
                              >
                                <CustomInput.UnitMeasurement
                                  id="inverterMaxPower"
                                  value={filters.inverterMaxPower}
                                  precision="2"
                                  unitMeasurement="KWp"
                                  onChange={(value) => {
                                    const parsedValue = Number(value);
                                    setFilters((current) => ({
                                      ...current,
                                      inverterMaxPower: isNaN(parsedValue)
                                        ? 0
                                        : parsedValue,
                                    }));
                                  }}
                                  min={filters.inverterMinPower}
                                  placeholder="Ex.: 50"
                                />
                              </Form.Item>
                            </AntdFlex>
                            <AntdFlex
                              wrap={screens.xs ? "wrap" : "nowrap"}
                              gap={8}
                            >
                              <Form.Item
                                key="outputVoltage"
                                htmlFor="outputVoltage"
                                label="Saída"
                                colon={false}
                                style={{ width: "100%" }}
                              >
                                <Select.OutputVoltage
                                  id="outputVoltage"
                                  value={filters.outputVoltage}
                                  defaultValue={defaultFilters.outputVoltage}
                                  mode="multiple"
                                  onChange={(outputVoltage) => {
                                    const parsedOutputVoltage: VoltageEnum[] =
                                      Array.isArray(outputVoltage)
                                        ? outputVoltage
                                        : [outputVoltage];

                                    setFilters((current) => ({
                                      ...current,
                                      outputVoltage: parsedOutputVoltage,
                                    }));
                                  }}
                                  hideValues={{
                                    V460: true,
                                    V480: true,
                                    V500: true,
                                    V540: true,
                                    V600: true,
                                  }}
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                              <Form.Item
                                key="phase"
                                htmlFor="phase"
                                label="Fase"
                                colon={false}
                                style={{ width: "100%" }}
                                data-cy="select-phase"
                              >
                                <Select.Phase
                                  id="phase"
                                  value={filters.phase}
                                  defaultValue={defaultFilters.phase}
                                  onChange={(phase) => {
                                    const parsedPhase: PhaseEnum[] =
                                      Array.isArray(phase) ? phase : [phase];

                                    setFilters((current) => ({
                                      ...current,
                                      phase: parsedPhase,
                                    }));
                                  }}
                                  mode="multiple"
                                  allowClear
                                  style={{ width: "100%" }}
                                  hideBifasico
                                  hideTrifasico={
                                    !defaultFilters.phase?.includes("Trifásico")
                                  }
                                />
                              </Form.Item>
                            </AntdFlex>
                            <AntdFlex wrap={"wrap"} gap={8}>
                              <Form.Item
                                key="microAndStringInverterMixAllowedInSolarKit"
                                htmlFor="microAndStringInverterMixAllowedInSolarKit"
                                label="Permitir micro e string num mesmo kit?"
                                colon={false}
                                initialValue={
                                  defaultFilters.microAndStringInverterMixAllowedInSolarKit
                                }
                                style={{
                                  width: "100%",
                                }}
                              >
                                <Radio.Group
                                  options={[
                                    {
                                      label: "Sim",
                                      value: true,
                                    },
                                    {
                                      label: "Não",
                                      value: false,
                                    },
                                  ]}
                                  value={
                                    filters.microAndStringInverterMixAllowedInSolarKit
                                  }
                                  defaultValue={
                                    defaultFilters.microAndStringInverterMixAllowedInSolarKit
                                  }
                                  onChange={(event) => {
                                    const microAndStringInverterMixAllowedInSolarKit =
                                      event.target.value;

                                    setFilters((current) => ({
                                      ...current,
                                      microAndStringInverterMixAllowedInSolarKit,
                                    }));
                                  }}
                                />
                              </Form.Item>
                              <Form.Item
                                key="differentPhaseInverterMixAllowedInSolarKit"
                                htmlFor="differentPhaseInverterMixAllowedInSolarKit"
                                label="Permitir inversores com tensão de saída diferentes no mesmo kit?"
                                colon={false}
                                initialValue={
                                  defaultFilters.differentPhaseInverterMixAllowedInSolarKit
                                }
                                style={{
                                  width: "100%",
                                }}
                              >
                                <Radio.Group
                                  options={[
                                    {
                                      label: "Sim",
                                      value: true,
                                    },
                                    {
                                      label: "Não",
                                      value: false,
                                    },
                                  ]}
                                  value={
                                    filters.differentPhaseInverterMixAllowedInSolarKit
                                  }
                                  defaultValue={
                                    defaultFilters.differentPhaseInverterMixAllowedInSolarKit
                                  }
                                  onChange={(event) => {
                                    const differentPhaseInverterMixAllowedInSolarKit =
                                      event.target.value;

                                    setFilters((current) => ({
                                      ...current,
                                      differentPhaseInverterMixAllowedInSolarKit,
                                    }));
                                  }}
                                />
                              </Form.Item>
                            </AntdFlex>
                            <Divider
                              key="divider_module"
                              style={{
                                margin: "0rem",
                                marginTop: "0.75rem",
                                color: "var(--primary-500)",
                              }}
                              orientation="left"
                              plain
                            >
                              Filtros dos módulos
                            </Divider>
                            <Form.Item
                              key="moduleNames"
                              htmlFor="moduleNames"
                              label="Nome contenha:"
                              colon={false}
                              style={{ width: "100%" }}
                              initialValue={defaultFilters.moduleNames}
                            >
                              <AntdSelect
                                mode="tags"
                                onChange={(value) =>
                                  setFilters((current) => ({
                                    ...current,
                                    moduleNames: value,
                                  }))
                                }
                                allowClear={false}
                                defaultValue={defaultFilters.moduleNames}
                                value={filters.moduleNames}
                              />
                            </Form.Item>
                            <Form.Item
                              key="moduleFaceType"
                              htmlFor="moduleFaceType"
                              label="Fase do módulo"
                              colon={false}
                              style={{ width: "100%" }}
                            >
                              <Select.ModulePhase
                                id="moduleFaceType"
                                value={filters.moduleFaceType}
                                defaultValue={defaultFilters.moduleFaceType}
                                mode="multiple"
                                onChange={(phase) => {
                                  const parsedModuleFaceType: ModulePhaseEnum[] =
                                    Array.isArray(phase) ? phase : [phase];

                                  setFilters((current) => ({
                                    ...current,
                                    moduleFaceType: parsedModuleFaceType,
                                  }));
                                }}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                            <Form.Item
                              key="moduleSearchType"
                              htmlFor="moduleSearchType"
                              label="Filtrar potência por"
                              colon={false}
                              style={{ width: "100%" }}
                              initialValue={
                                defaultFilters.moduleSearchType ?? "BETWEEN"
                              }
                            >
                              <AntdSelect<IModuleSearchType>
                                options={[
                                  { label: "Por faixa", value: "BETWEEN" },
                                  { label: "Valor exato", value: "EXACTLY" },
                                ]}
                                onChange={(value) =>
                                  setFilters((current) => ({
                                    ...current,
                                    moduleSearchType: value,
                                  }))
                                }
                                allowClear={false}
                                defaultValue="BETWEEN"
                                value={filters.moduleSearchType}
                              />
                            </Form.Item>
                            <Form.Item
                              key="modulePowerWp"
                              htmlFor="modulePowerWp"
                              label="Potência dos módulos"
                              colon={false}
                              style={{ width: "100%" }}
                              hidden={filters.moduleSearchType !== "EXACTLY"}
                            >
                              <CustomInput.UnitMeasurement
                                id="modulePowerWp"
                                value={filters.modulePowerWp}
                                precision="2"
                                unitMeasurement="Wp"
                                onChange={(value) => {
                                  const parsedValue = Number(value);
                                  setFilters((current) => ({
                                    ...current,
                                    modulePowerWp: isNaN(parsedValue)
                                      ? 0
                                      : parsedValue,
                                  }));
                                }}
                                placeholder="Ex.: 25"
                              />
                            </Form.Item>
                            <AntdFlex
                              wrap={screens.xs ? "wrap" : "nowrap"}
                              gap={8}
                            >
                              <Form.Item
                                key="moduleMinPowerWp"
                                htmlFor="moduleMinPowerWp"
                                label="Potência mínima dos módulos"
                                tooltip="Potência mínima dos módulos"
                                colon={false}
                                style={{ width: "100%" }}
                                hidden={filters.moduleSearchType !== "BETWEEN"}
                              >
                                <CustomInput.UnitMeasurement
                                  id="moduleMinPowerWp"
                                  value={filters.moduleMinPowerWp}
                                  precision="2"
                                  unitMeasurement="Wp"
                                  onChange={(value) => {
                                    const parsedValue = Number(value);
                                    setFilters((current) => ({
                                      ...current,
                                      moduleMinPowerWp: isNaN(parsedValue)
                                        ? 0
                                        : parsedValue,
                                    }));
                                  }}
                                  min={0}
                                  max={filters.moduleMaxPowerWp}
                                  placeholder="Ex.: 25"
                                />
                              </Form.Item>
                              <Form.Item
                                key="moduleMaxPowerWp"
                                htmlFor="moduleMaxPowerWp"
                                label="Potência máxima dos módulos"
                                tooltip="Potência máxima dos módulos"
                                colon={false}
                                style={{ width: "100%" }}
                                hidden={filters.moduleSearchType !== "BETWEEN"}
                              >
                                <CustomInput.UnitMeasurement
                                  id="moduleMaxPowerWp"
                                  value={filters.moduleMaxPowerWp}
                                  precision="2"
                                  unitMeasurement="Wp"
                                  onChange={(value) => {
                                    const parsedValue = Number(value);
                                    setFilters((current) => ({
                                      ...current,
                                      moduleMaxPowerWp: isNaN(parsedValue)
                                        ? 0
                                        : parsedValue,
                                    }));
                                  }}
                                  min={filters.moduleMinPowerWp}
                                  placeholder="Ex.: 50"
                                />
                              </Form.Item>
                            </AntdFlex>
                            <Divider
                              key="divider_others"
                              style={{
                                margin: "0rem",
                                marginTop: "0.75rem",
                                color: "var(--primary-500)",
                              }}
                              orientation="left"
                              plain
                            >
                              Outros filtros
                            </Divider>
                            <Form.Item
                              key="ignoreElectricUtilityRules"
                              htmlFor="ignoreElectricUtilityRules"
                              label="Ignorar regras da concessionária?"
                              colon={false}
                              initialValue={
                                !!defaultFilters.ignoreElectricUtilityRules
                              }
                              style={{
                                width: screens.xs ? "100%" : "49%",
                              }}
                            >
                              <Radio.Group
                                options={[
                                  {
                                    label: "Sim",
                                    value: true,
                                  },
                                  {
                                    label: "Não",
                                    value: false,
                                  },
                                ]}
                                value={!!filters.ignoreElectricUtilityRules}
                                defaultValue={
                                  !!defaultFilters.ignoreElectricUtilityRules
                                }
                                onChange={(event) => {
                                  const ignoreElectricUtilityRules =
                                    !!event.target.value;

                                  setFilters((current) => ({
                                    ...current,
                                    ignoreElectricUtilityRules,
                                  }));
                                }}
                              />
                            </Form.Item>
                            {/* <Form.Item
                        key="regionId"
                        htmlFor="regionId"
                        label="Região"
                        colon={false}
                        initialValue={defaultFilters.regionId}
                        style={{ width: screens.xs ? "100%" : "49%" }}
                        hidden={hideRegionFilterInput}
                        required={!hideRegionFilterInput}
                      >
                        <Select.EquipmentRegion
                          allowClear
                          value={filters.regionId}
                          onChange={(regionId) =>
                            setFilters((current) => ({ ...current, regionId }))
                          }
                        />
                      </Form.Item> */}
                          </>
                        ),
                      },
                      {
                        key: "KOMECO_FILTERS" as ICollapseKeyKitFiltersColumnType,
                        label: "Filtros da Komeco",
                        extra: (
                          <AntdFlex
                            onClick={(e) => e.stopPropagation()}
                            gap={4}
                          >
                            <Tooltip title="Se ativo vamos utilizar esse filtro na pesquisa">
                              <label
                                key="komecoFilterActive"
                                htmlFor="komecoFilterActive"
                              >
                                Ativo:{" "}
                                <Checkbox
                                  id="komecoFilterActive"
                                  checked={activeFilters.komeco}
                                  onChange={() => {
                                    setActiveFilters((current) => ({
                                      ...current,
                                      komeco: !current.komeco,
                                    }));
                                  }}
                                />
                              </label>
                            </Tooltip>
                          </AntdFlex>
                        ),
                        children: (
                          <AntdFlex vertical>
                            <AntdFlex
                              wrap={screens.xs ? "wrap" : "nowrap"}
                              gap={8}
                            >
                              <Form.Item
                                key="komecoKitPower"
                                htmlFor="komecoKitPower"
                                label="Potência do kit"
                                colon={false}
                                style={{ width: "100%" }}
                                rules={[{ required: true }]}
                              >
                                <CustomInput.UnitMeasurement
                                  id="komecoKitPower"
                                  value={filters.komeco?.komecoKitPower}
                                  precision="2"
                                  unitMeasurement="KWp"
                                  onChange={(value) => {
                                    const komecoKitPower = value
                                      ? Number(value)
                                      : undefined;
                                    setFilters((current) => ({
                                      ...current,
                                      komeco: {
                                        ...current.komeco,
                                        komecoKitPower,
                                      },
                                    }));
                                  }}
                                  min={0}
                                  placeholder="Ex.: 100"
                                />
                              </Form.Item>
                              <Form.Item
                                key="komecoModulePower"
                                htmlFor="komecoModulePower"
                                label="Potência do Módulo"
                                colon={false}
                                style={{ width: "100%" }}
                                rules={[{ required: true }]}
                              >
                                <Select.KomecoEquipmentModule
                                  id="komecoModulePower"
                                  value={filters.komeco?.komecoModulePower}
                                  onChange={(value) => {
                                    setFilters((current) => ({
                                      ...current,
                                      komeco: {
                                        ...current.komeco,
                                        komecoModulePower: value,
                                      },
                                    }));
                                  }}
                                  placeholder="Selecione o módulo..."
                                />
                              </Form.Item>
                            </AntdFlex>
                            <Form.Item
                              key="komecoStructureType"
                              htmlFor="komecoStructureType"
                              label="Filtrar potência por"
                              colon={false}
                              style={{ width: "100%" }}
                              initialValue={
                                defaultFilters?.komeco?.komecoStructureType ??
                                "VALUE1"
                              }
                            >
                              <Select.KomecoEquipmentStructureType
                                onChange={(value) => {
                                  if (Array.isArray(value)) return;

                                  setFilters((current) => ({
                                    ...current,
                                    komeco: {
                                      ...current.komeco,
                                      komecoStructureType: value,
                                    },
                                  }));
                                }}
                                value={filters?.komeco?.komecoStructureType}
                              />
                            </Form.Item>
                            <Divider
                              style={{
                                margin: "0rem",
                                marginTop: "0.75rem",
                                color: "var(--primary-500)",
                              }}
                              orientation="left"
                              plain
                            >
                              inversores
                            </Divider>
                            <Form.Item
                              key="komecoInverterPowerOne"
                              htmlFor="komecoInverterPowerOne"
                              label="Potência do inversor 1"
                              colon={false}
                              style={{ width: "100%" }}
                              rules={[{ required: true }]}
                            >
                              <CustomInput.UnitMeasurement
                                id="komecoInverterPowerOne"
                                value={filters?.komeco?.komecoInverterPowerOne}
                                precision="2"
                                unitMeasurement="KWp"
                                onChange={(value) => {
                                  const komecoInverterPowerOne = value
                                    ? Number(value)
                                    : undefined;

                                  setFilters((current) => ({
                                    ...current,
                                    komeco: {
                                      ...current.komeco,
                                      komecoInverterPowerOne,
                                    },
                                  }));
                                }}
                                min={0}
                                placeholder="Ex.: 100"
                              />
                            </Form.Item>
                            <Form.Item
                              key="komecoInverterPowerTwo"
                              htmlFor="komecoInverterPowerTwo"
                              label="Potência do inversor 2"
                              colon={false}
                              style={{ width: "100%" }}
                              rules={[{ required: true }]}
                            >
                              <CustomInput.UnitMeasurement
                                id="komecoInverterPowerTwo"
                                value={filters?.komeco?.komecoInverterPowerTwo}
                                precision="2"
                                unitMeasurement="KWp"
                                onChange={(value) => {
                                  const komecoInverterPowerTwo = value
                                    ? Number(value)
                                    : undefined;

                                  setFilters((current) => ({
                                    ...current,
                                    komeco: {
                                      ...current.komeco,
                                      komecoInverterPowerTwo,
                                    },
                                  }));
                                }}
                                min={0}
                                placeholder="Ex.: 100"
                              />
                            </Form.Item>
                            <Form.Item
                              key="komecoInverterPowerThree"
                              htmlFor="komecoInverterPowerThree"
                              label="Potência do inversor 3"
                              colon={false}
                              style={{ width: "100%" }}
                              rules={[{ required: true }]}
                            >
                              <CustomInput.UnitMeasurement
                                id="komecoInverterPowerThree"
                                value={
                                  filters?.komeco?.komecoInverterPowerThree
                                }
                                precision="2"
                                unitMeasurement="KWp"
                                onChange={(value) => {
                                  const komecoInverterPowerThree = value
                                    ? Number(value)
                                    : undefined;

                                  setFilters((current) => ({
                                    ...current,
                                    komeco: {
                                      ...current.komeco,
                                      komecoInverterPowerThree,
                                    },
                                  }));
                                }}
                                min={0}
                                placeholder="Ex.: 100"
                              />
                            </Form.Item>
                          </AntdFlex>
                        ),
                        isHidden: !displayKomecoFilters,
                      },
                      {
                        key: "EDELTEC_FILTERS" as ICollapseKeyKitFiltersColumnType,
                        label: "Filtros da Edeltec",
                        extra: (
                          <AntdFlex
                            onClick={(e) => e.stopPropagation()}
                            gap={4}
                          >
                            <Tooltip title="Se ativo vamos utilizar esse filtro na pesquisa">
                              <label
                                key="edeltecFilterActive"
                                htmlFor="edeltecFilterActive"
                              >
                                Ativo:{" "}
                                <Checkbox
                                  id="edeltecFilterActive"
                                  checked={activeFilters.edeltec}
                                  onChange={() => {
                                    setActiveFilters((current) => ({
                                      ...current,
                                      edeltec: !current.edeltec,
                                    }));
                                  }}
                                />
                              </label>
                            </Tooltip>
                          </AntdFlex>
                        ),
                        children: (
                          <AntdFlex vertical>
                            <Form.Item
                              key="edeltecKitPower"
                              htmlFor="edeltecKitPower"
                              label="Potência do kit"
                              colon={false}
                              style={{ width: "100%" }}
                              rules={[{ required: true }]}
                            >
                              <CustomInput.UnitMeasurement
                                id="edeltecKitPower"
                                value={filters.edeltec?.edeltecKitPower}
                                precision="2"
                                unitMeasurement="KWp"
                                onChange={(value) => {
                                  const edeltecKitPower = value
                                    ? Number(value)
                                    : undefined;

                                  onChangeEdeltecFilters({
                                    edeltecKitPower,
                                  });
                                }}
                                min={0}
                                placeholder="Ex.: 100"
                              />
                            </Form.Item>
                            {/* <Form.Item
                        key="edeltecProductType"
                        htmlFor="edeltecProductType"
                        label="Tipos de gerador"
                        colon={false}
                        style={{ width: "100%" }}
                        rules={[{ required: false }]}
                      >
                        <Select.EdeltecProductTypes
                          id="edeltecProductType"
                          placeholder="Selecione Tipos de Gerador..."
                          mode="tags"
                          value={filters.edeltec?.edeltecProductType}
                          onChange={(value) => {
                            onChangeEdeltecFilters({
                              edeltecProductType:
                                value instanceof Array ? value : undefined,
                            });
                          }}
                        />
                      </Form.Item> */}
                            <Form.Item
                              key="edeltecInverterBrand"
                              htmlFor="edeltecInverterBrand"
                              label="Marcas de Inversor"
                              colon={false}
                              style={{ width: "100%" }}
                              rules={[{ required: false }]}
                            >
                              <Select.EdeltecInverterBrands
                                id="edeltecInverterBrand"
                                placeholder="Selecione Inversores..."
                                mode="tags"
                                value={filters.edeltec?.edeltecInverterBrand}
                                onChange={(value) => {
                                  onChangeEdeltecFilters({
                                    edeltecInverterBrand:
                                      value instanceof Array
                                        ? value
                                        : undefined,
                                  });
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              key="edeltecModuleBrand"
                              htmlFor="edeltecModuleBrand"
                              label="Marcas de Módulo"
                              colon={false}
                              style={{ width: "100%" }}
                              rules={[{ required: false }]}
                            >
                              <Select.EdeltecModuleBrands
                                id="edeltecModuleBrand"
                                placeholder="Selecione Módulos..."
                                mode="tags"
                                value={filters.edeltec?.edeltecModuleBrand}
                                onChange={(value) => {
                                  onChangeEdeltecFilters({
                                    edeltecModuleBrand:
                                      value instanceof Array
                                        ? value
                                        : undefined,
                                  });
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              key="edeltecStructure"
                              htmlFor="edeltecStructure"
                              label="Estruturas"
                              colon={false}
                              style={{ width: "100%" }}
                              rules={[{ required: false }]}
                            >
                              <Select.EdeltecStructure
                                id="edeltecStructure"
                                placeholder="Selecione Estruturas..."
                                mode="tags"
                                value={filters.edeltec?.edeltecStructure}
                                onChange={(value) => {
                                  onChangeEdeltecFilters({
                                    edeltecStructure:
                                      value instanceof Array
                                        ? value
                                        : undefined,
                                  });
                                }}
                              />
                            </Form.Item>
                          </AntdFlex>
                        ),
                        isHidden: !displayEdeltecFilters,
                      },
                      {
                        key: "FORTLEV_FILTERS" as ICollapseKeyKitFiltersColumnType,
                        label: "Filtros da Fortlev",
                        extra: (
                          <AntdFlex
                            onClick={(e) => e.stopPropagation()}
                            gap={4}
                          >
                            <Tooltip title="Se ativo vamos utilizar esse filtro na pesquisa">
                              <label
                                key="fortlevFilterActive"
                                htmlFor="fortlevFilterActive"
                              >
                                Ativo:{" "}
                                <Checkbox
                                  id="fortlevFilterActive"
                                  checked={activeFilters.fortlev}
                                  onChange={() => {
                                    setActiveFilters((current) => ({
                                      ...current,
                                      fortlev: !current.fortlev,
                                    }));
                                  }}
                                />
                              </label>
                            </Tooltip>
                          </AntdFlex>
                        ),
                        children: (
                          <AntdFlex vertical>
                            <Form.Item
                              key="fortlevTargetPower"
                              htmlFor="fortlevTargetPower"
                              label="Potência do kit"
                              colon={false}
                              style={{ width: "100%" }}
                              rules={[{ required: true }]}
                            >
                              <CustomInput.UnitMeasurement
                                id="fortlevTargetPower"
                                value={filters.fortlev?.fortlevTargetPower}
                                precision="2"
                                unitMeasurement="KWp"
                                onChange={(value) => {
                                  const fortlevTargetPower = value
                                    ? Number(value)
                                    : undefined;

                                  onChangeFortlevFilters({
                                    fortlevTargetPower,
                                  });
                                }}
                                min={0}
                                placeholder="Ex.: 100"
                              />
                            </Form.Item>
                            <Form.Item
                              key="fortlevPhase "
                              htmlFor="fortlevPhase "
                              label="Fase"
                              colon={false}
                              style={{ width: "100%" }}
                              rules={[{ required: false }]}
                            >
                              <Select.Phase
                                id="fortlevPhase "
                                placeholder="Selecione a fase..."
                                value={filters.fortlev?.fortlevPhase}
                                onChange={(value) => {
                                  onChangeFortlevFilters({
                                    fortlevPhase:
                                      value instanceof Array
                                        ? undefined
                                        : value,
                                  });
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              key="fortlevVoltage"
                              htmlFor="fortlevVoltage"
                              label="Saída"
                              colon={false}
                              style={{ width: "100%" }}
                              rules={[{ required: false }]}
                            >
                              <Select.OutputVoltage
                                id="fortlevVoltage"
                                placeholder="Selecione saída..."
                                value={filters.fortlev?.fortlevVoltage}
                                onChange={(value) => {
                                  onChangeFortlevFilters({
                                    fortlevVoltage:
                                      value instanceof Array
                                        ? undefined
                                        : (value as "V220" | "V380"),
                                  });
                                }}
                                hideValues={{
                                  V110: true,
                                  V460: true,
                                  V480: true,
                                  V500: true,
                                  V540: true,
                                  V600: true,
                                  V800: true,
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              key="fortlevModule"
                              htmlFor="fortlevModule"
                              label="Módulo"
                              colon={false}
                              style={{ width: "100%" }}
                              rules={[{ required: false }]}
                            >
                              <Select.FortlevModule
                                id="fortlevModule"
                                placeholder="Selecione o módulo..."
                                value={filters.fortlev?.fortlevModuleId}
                                onChange={(value) => {
                                  onChangeFortlevFilters({
                                    fortlevModuleId:
                                      value instanceof Array
                                        ? undefined
                                        : value,
                                  });
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              key="fortlevStructure"
                              htmlFor="fortlevStructure"
                              label="Estrutura"
                              colon={false}
                              style={{ width: "100%" }}
                              rules={[{ required: false }]}
                            >
                              <Select.FortlevStructure
                                id="fortlevStructure"
                                placeholder="Selecione a estrutura..."
                                value={filters.fortlev?.fortlevStructureId}
                                onChange={(value) => {
                                  onChangeFortlevFilters({
                                    fortlevStructureId:
                                      value instanceof Array
                                        ? undefined
                                        : value,
                                  });
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              key="fortlevInverterType"
                              htmlFor="fortlevInverterType"
                              label="Tipo de inversor"
                              colon={false}
                              style={{ width: "100%" }}
                              rules={[{ required: false }]}
                            >
                              <Select.FortlevInverterType
                                id="fortlevInverterType"
                                allowClear
                                placeholder="Selecione um tipo de inversor..."
                                value={filters.fortlev?.fortlevInverterType}
                                onChange={(value) => {
                                  onChangeFortlevFilters({
                                    fortlevInverterType: value ?? undefined,
                                  });
                                }}
                              />
                            </Form.Item>
                          </AntdFlex>
                        ),
                        isHidden: !displayFortlevFilters,
                      },
                      {
                        key: "ALDO_FILTERS" as ICollapseKeyKitFiltersColumnType,
                        label: "Filtros da Aldo",
                        extra: (
                          <AntdFlex
                            onClick={(e) => e.stopPropagation()}
                            gap={4}
                          >
                            <Tooltip title="Se ativo vamos utilizar esse filtro na pesquisa">
                              <label
                                key="aldoFilterActive"
                                htmlFor="aldoFilterActive"
                              >
                                Ativo:{" "}
                                <Checkbox
                                  id="aldoFilterActive"
                                  checked={activeFilters.aldo}
                                  onChange={() => {
                                    setActiveFilters((current) => ({
                                      ...current,
                                      aldo: !current.aldo,
                                    }));
                                  }}
                                />
                              </label>
                            </Tooltip>
                          </AntdFlex>
                        ),
                        children: (
                          <AntdFlex vertical>
                            <Form.Item
                              key="aldoPhase"
                              htmlFor="aldoPhase "
                              label="Fase"
                              colon={false}
                              style={{ width: "100%" }}
                              rules={[{ required: false }]}
                            >
                              <Select.Phase
                                id="phase"
                                value={filters.aldo?.aldoPhase}
                                defaultValue={defaultFilters.aldo?.aldoPhase}
                                onChange={(phase) => {
                                  const aldoPhase: PhaseEnum[] = Array.isArray(
                                    phase,
                                  )
                                    ? phase
                                    : [phase];

                                  onChangeAldoFilters({
                                    aldoPhase,
                                  });
                                }}
                                mode="multiple"
                                allowClear
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                            <Form.Item
                              key="aldoKitPower"
                              htmlFor="aldoKitPower"
                              label="Potência do kit"
                              colon={false}
                              style={{ width: "100%" }}
                              rules={[{ required: true }]}
                            >
                              <CustomInput.UnitMeasurement
                                id="aldoKitPower"
                                value={filters.aldo?.aldoPowerKwp}
                                precision="2"
                                unitMeasurement="KWp"
                                onChange={(value) => {
                                  const aldoPowerKwp = value
                                    ? Number(value)
                                    : undefined;

                                  onChangeAldoFilters({
                                    aldoPowerKwp,
                                  });
                                }}
                                min={0}
                                placeholder="Ex.: 100"
                              />
                            </Form.Item>
                            <Form.Item
                              key="aldoCode"
                              htmlFor="aldoCode "
                              label="Código do produto"
                              colon={false}
                              style={{ width: "100%" }}
                              rules={[{ required: false }]}
                            >
                              <CustomInput.Text
                                id="aldoCode"
                                value={filters.aldo?.aldoCode}
                                defaultValue={defaultFilters.aldo?.aldoCode}
                                placeholder="Código do produto..."
                                onChange={(event) => {
                                  const aldoCode: string | undefined = event
                                    ? event.target.value
                                    : undefined;

                                  onChangeAldoFilters({
                                    aldoCode,
                                  });
                                }}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                            <Form.Item
                              key="aldoVoltage"
                              htmlFor="aldoVoltage"
                              label="Saída"
                              colon={false}
                              style={{ width: "100%" }}
                            >
                              <Select.OutputVoltage
                                id="aldoVoltage"
                                value={filters.aldo?.aldoVoltage}
                                defaultValue={defaultFilters.aldo?.aldoVoltage}
                                mode="multiple"
                                onChange={(outputVoltage) => {
                                  const parsedOutputVoltage: VoltageEnum[] =
                                    Array.isArray(outputVoltage)
                                      ? outputVoltage
                                      : [outputVoltage];

                                  onChangeAldoFilters({
                                    aldoVoltage: parsedOutputVoltage,
                                  });
                                }}
                                hideValues={{
                                  V460: true,
                                  V480: true,
                                  V500: true,
                                  V540: true,
                                  V600: true,
                                }}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                            <Form.Item
                              key="aldoStructure"
                              htmlFor="aldoStructure"
                              label="Estrutura"
                              colon={false}
                              style={{ width: "100%" }}
                              rules={[{ required: false }]}
                            >
                              <Select.AldoStructure
                                id="aldoStructure"
                                placeholder="Selecione a estrutura..."
                                value={
                                  filters.aldo?.aldoStructureId || undefined
                                }
                                onChange={(value) => {
                                  onChangeAldoFilters({
                                    aldoStructureId:
                                      value instanceof Array
                                        ? undefined
                                        : value,
                                  });
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              key="aldoModule"
                              htmlFor="aldoModule"
                              label="Módulo"
                              colon={false}
                              style={{ width: "100%" }}
                              rules={[{ required: false }]}
                            >
                              <Select.AldoModuleBrands
                                id="aldoModule"
                                placeholder="Selecione o módulo..."
                                value={filters.aldo?.aldoModuleId}
                                mode="tags"
                                onChange={(value) => {
                                  onChangeAldoFilters({
                                    aldoModuleId:
                                      value !== undefined
                                        ? Array.isArray(value)
                                          ? value
                                          : [value]
                                        : undefined,
                                  });
                                }}
                              />
                            </Form.Item>

                            <Form.Item
                              key="aldoInverterBrand"
                              htmlFor="aldoInverterBrand"
                              label="Inversor"
                              colon={false}
                              style={{ width: "100%" }}
                              rules={[{ required: false }]}
                            >
                              <Select.AldoInverterBrands
                                id="aldoInverterBrand"
                                placeholder="Selecione Inversores..."
                                mode="tags"
                                value={filters.aldo?.aldoInvertersId}
                                onChange={(value) => {
                                  onChangeAldoFilters({
                                    aldoInvertersId:
                                      value instanceof Array
                                        ? value
                                        : undefined,
                                  });
                                }}
                              />
                            </Form.Item>
                          </AntdFlex>
                        ),
                        isHidden: !displayAldoFilters,
                      },
                      {
                        key: "ECORI_FILTERS" as ICollapseKeyKitFiltersColumnType,
                        label: "Filtros da Ecori",
                        extra: (
                          <AntdFlex
                            onClick={(e) => e.stopPropagation()}
                            gap={4}
                          >
                            <Tooltip title="Se ativo vamos utilizar esse filtro na pesquisa">
                              <label
                                key="ecoriFilterActive"
                                htmlFor="ecoriFilterActive"
                              >
                                Ativo:{" "}
                                <Checkbox
                                  id="ecoriFilterActive"
                                  checked={activeFilters.ecori}
                                  onChange={() => {
                                    setActiveFilters((current) => ({
                                      ...current,
                                      ecori: !current.ecori,
                                    }));
                                  }}
                                />
                              </label>
                            </Tooltip>
                          </AntdFlex>
                        ),
                        children: (
                          <AntdFlex vertical>
                            <Form.Item
                              key="ecoriPhase"
                              htmlFor="ecoriPhase "
                              label="Fase"
                              colon={false}
                              style={{ width: "100%" }}
                              rules={[{ required: false }]}
                            >
                              <Select.Phase
                                id="phase"
                                value={filters.ecori?.ecoriPhase}
                                defaultValue={defaultFilters.ecori?.ecoriPhase}
                                onChange={(phase) => {
                                  const ecoriPhase: PhaseEnum[] = Array.isArray(
                                    phase,
                                  )
                                    ? phase
                                    : [phase];

                                  onChangeEcoriFilters({
                                    ecoriPhase,
                                  });
                                }}
                                mode="multiple"
                                allowClear
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                            <Form.Item
                              key="ecoriKitPower"
                              htmlFor="ecoriKitPower"
                              label="Potência do kit"
                              colon={false}
                              style={{ width: "100%" }}
                              rules={[{ required: true }]}
                            >
                              <CustomInput.UnitMeasurement
                                id="ecoriKitPower"
                                value={filters.ecori?.ecoriPowerKwp}
                                precision="2"
                                unitMeasurement="KWp"
                                onChange={(value) => {
                                  const ecoriPowerKwp = value
                                    ? Number(value)
                                    : undefined;

                                  onChangeEcoriFilters({
                                    ecoriPowerKwp,
                                  });
                                }}
                                min={0}
                                placeholder="Ex.: 100"
                              />
                            </Form.Item>
                            <Form.Item
                              key="ecoriVoltage"
                              htmlFor="ecoriVoltage"
                              label="Saída"
                              colon={false}
                              style={{ width: "100%" }}
                            >
                              <Select.OutputVoltage
                                id="ecoriVoltage"
                                value={filters.ecori?.ecoriVoltage}
                                defaultValue={
                                  defaultFilters.ecori?.ecoriVoltage
                                }
                                mode="multiple"
                                onChange={(outputVoltage) => {
                                  const parsedOutputVoltage: VoltageEnum[] =
                                    Array.isArray(outputVoltage)
                                      ? outputVoltage
                                      : [outputVoltage];

                                  onChangeEcoriFilters({
                                    ecoriVoltage: parsedOutputVoltage,
                                  });
                                }}
                                hideValues={{
                                  V460: true,
                                  V480: true,
                                  V500: true,
                                  V540: true,
                                  V600: true,
                                }}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                            <Form.Item
                              key="ecoriStructure"
                              htmlFor="ecoriStructure"
                              label="Estrutura"
                              colon={false}
                              style={{ width: "100%" }}
                              rules={[{ required: false }]}
                            >
                              <Select.EcoriStructure
                                id="ecoriStructure"
                                placeholder="Selecione a estrutura..."
                                value={filters.ecori?.ecoriStructureId}
                                onChange={(value) => {
                                  onChangeEcoriFilters({
                                    ecoriStructureId:
                                      value instanceof Array
                                        ? undefined
                                        : value,
                                  });
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              key="ecoriModule"
                              htmlFor="ecoriModule"
                              label="Módulo"
                              colon={false}
                              style={{ width: "100%" }}
                              rules={[{ required: false }]}
                            >
                              <Select.EcoriModuleBrands
                                id="ecoriModule"
                                placeholder="Selecione o módulo..."
                                value={filters.ecori?.ecoriModuleId}
                                mode="tags"
                                onChange={(value) => {
                                  onChangeEcoriFilters({
                                    ecoriModuleId:
                                      value !== undefined
                                        ? Array.isArray(value)
                                          ? value
                                          : [value]
                                        : undefined,
                                  });
                                }}
                              />
                            </Form.Item>

                            <Form.Item
                              key="ecoriInverterBrand"
                              htmlFor="ecoriInverterBrand"
                              label="Inversor"
                              colon={false}
                              style={{ width: "100%" }}
                              rules={[{ required: false }]}
                            >
                              <Select.EcoriInverterBrands
                                id="ecoriInverterBrand"
                                placeholder="Selecione Inversores..."
                                mode="tags"
                                value={filters.ecori?.ecoriInvertersId}
                                onChange={(value) => {
                                  onChangeEcoriFilters({
                                    ecoriInvertersId:
                                      value instanceof Array
                                        ? value
                                        : undefined,
                                  });
                                }}
                              />
                            </Form.Item>
                          </AntdFlex>
                        ),
                        isHidden: !displayEcoriFilters,
                      },
                    ].filter((item) => !item.isHidden) as CollapseProps["items"]
                  }
                />,
              ]
            : [
                <AntdFlex key="ant" vertical>
                  <Divider
                    style={{
                      margin: "0rem",
                      marginTop: "0.75rem",
                      color: "var(--primary-500)",
                    }}
                    orientation="left"
                    plain
                  >
                    Filtros do kit
                  </Divider>
                  <AntdFlex wrap={screens.xs ? "wrap" : "nowrap"} gap={8}>
                    <Form.Item
                      key="name"
                      htmlFor="name"
                      label="Nome do kit"
                      colon={false}
                      style={{ width: "100%" }}
                    >
                      <CustomInput.Text
                        id="name"
                        value={filters.name}
                        onChange={(event) => {
                          setFilters((current) => ({
                            ...current,
                            name: event.target.value,
                          }));
                        }}
                        placeholder="Nome do kit..."
                      />
                    </Form.Item>
                    <Form.Item
                      key="fullPower"
                      htmlFor="fullPower"
                      label="Potência do kit"
                      tooltip="Potência do kit, valor padrão é o informado nos passos anteriores"
                      colon={false}
                      style={{ width: "100%" }}
                      rules={[{ required: true }]}
                      required
                    >
                      <CustomInput.UnitMeasurement
                        id="fullPower"
                        value={filters.fullPower}
                        precision="2"
                        unitMeasurement="KWp"
                        onChange={(value) => {
                          const fullPower = value ? Number(value) : undefined;

                          setFilters((current) => ({
                            ...current,
                            fullPower,
                          }));
                        }}
                        min={0}
                        placeholder="Ex.: 100"
                      />
                    </Form.Item>
                  </AntdFlex>
                  <Divider
                    key="divider_inverter"
                    style={{
                      margin: "0rem",
                      marginTop: "0.75rem",
                      color: "var(--primary-500)",
                    }}
                    orientation="left"
                    plain
                  >
                    Filtros dos inversores
                  </Divider>
                  <Form.Item
                    key="inverterNames"
                    htmlFor="inverterNames"
                    label="Nome contenha:"
                    colon={false}
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                    initialValue={defaultFilters.inverterNames}
                  >
                    <AntdSelect
                      mode="tags"
                      onChange={(value) =>
                        setFilters((current) => ({
                          ...current,
                          inverterNames: value,
                        }))
                      }
                      allowClear={false}
                      defaultValue={defaultFilters.inverterNames}
                      value={filters.inverterNames}
                    />
                  </Form.Item>
                  <Form.Item
                    key="inverterType"
                    htmlFor="inverterType"
                    label="Filtrar inversores por"
                    colon={false}
                    style={{ width: "100%" }}
                    initialValue={defaultFilters.inverterType}
                  >
                    <AntdSelect<IInverterType>
                      options={[
                        { label: "String", value: "STRING" },
                        { label: "Micro", value: "MICRO" },
                        {
                          label: "Otimizador",
                          value: "OPTIMIZER",
                        },
                      ]}
                      onChange={(value) =>
                        setFilters((current) => ({
                          ...current,
                          inverterType: value,
                        }))
                      }
                      allowClear={true}
                      value={filters.inverterType}
                    />
                  </Form.Item>
                  <Form.Item
                    key="inverterSearchType"
                    htmlFor="inverterSearchType"
                    label="Filtrar potência por"
                    colon={false}
                    style={{ width: "100%" }}
                    initialValue={
                      defaultFilters.inverterSearchType ?? "BETWEEN"
                    }
                  >
                    <AntdSelect<IInverterSearchType>
                      options={[
                        { label: "Por faixa", value: "BETWEEN" },
                        { label: "Valor exato", value: "EXACTLY" },
                      ]}
                      onChange={(value) =>
                        setFilters((current) => ({
                          ...current,
                          inverterSearchType: value,
                        }))
                      }
                      allowClear={false}
                      defaultValue="BETWEEN"
                      value={filters.inverterSearchType}
                    />
                  </Form.Item>
                  <Form.Item
                    key="inverterPower"
                    htmlFor="inverterPower"
                    label="Potência dos inversores"
                    colon={false}
                    style={{ width: "100%" }}
                    hidden={filters.inverterSearchType !== "EXACTLY"}
                  >
                    <CustomInput.UnitMeasurement
                      id="inverterPower"
                      value={filters.inverterPower}
                      precision="2"
                      unitMeasurement="KWp"
                      onChange={(value) => {
                        const parsedValue = Number(value);
                        setFilters((current) => ({
                          ...current,
                          inverterPower: isNaN(parsedValue) ? 0 : parsedValue,
                        }));
                      }}
                      placeholder="Ex.: 25"
                    />
                  </Form.Item>
                  <AntdFlex wrap={screens.xs ? "wrap" : "nowrap"} gap={8}>
                    <Form.Item
                      key="inverterMinPower"
                      htmlFor="inverterMinPower"
                      label="Potência mínima dos inversores"
                      tooltip="Potência mínima dos inversores"
                      colon={false}
                      style={{ width: "100%" }}
                      hidden={filters.inverterSearchType !== "BETWEEN"}
                    >
                      <CustomInput.UnitMeasurement
                        id="inverterMinPower"
                        value={filters.inverterMinPower}
                        precision="2"
                        unitMeasurement="KWp"
                        onChange={(value) => {
                          const parsedValue = Number(value);
                          setFilters((current) => ({
                            ...current,
                            inverterMinPower: isNaN(parsedValue)
                              ? 0
                              : parsedValue,
                          }));
                        }}
                        min={0}
                        max={filters.inverterMaxPower}
                        placeholder="Ex.: 25"
                      />
                    </Form.Item>
                    <Form.Item
                      key="inverterMaxPower"
                      htmlFor="inverterMaxPower"
                      label="Potência máxima dos inversores"
                      tooltip="Potência máxima dos inversores"
                      colon={false}
                      style={{ width: "100%" }}
                      hidden={filters.inverterSearchType !== "BETWEEN"}
                    >
                      <CustomInput.UnitMeasurement
                        id="inverterMaxPower"
                        value={filters.inverterMaxPower}
                        precision="2"
                        unitMeasurement="KWp"
                        onChange={(value) => {
                          const parsedValue = Number(value);
                          setFilters((current) => ({
                            ...current,
                            inverterMaxPower: isNaN(parsedValue)
                              ? 0
                              : parsedValue,
                          }));
                        }}
                        min={filters.inverterMinPower}
                        placeholder="Ex.: 50"
                      />
                    </Form.Item>
                  </AntdFlex>
                  <AntdFlex wrap={screens.xs ? "wrap" : "nowrap"} gap={8}>
                    <Form.Item
                      key="outputVoltage"
                      htmlFor="outputVoltage"
                      label="Saída"
                      colon={false}
                      style={{ width: "100%" }}
                    >
                      <Select.OutputVoltage
                        id="outputVoltage"
                        value={filters.outputVoltage}
                        defaultValue={defaultFilters.outputVoltage}
                        mode="multiple"
                        onChange={(outputVoltage) => {
                          const parsedOutputVoltage: VoltageEnum[] =
                            Array.isArray(outputVoltage)
                              ? outputVoltage
                              : [outputVoltage];

                          setFilters((current) => ({
                            ...current,
                            outputVoltage: parsedOutputVoltage,
                          }));
                        }}
                        hideValues={{
                          V460: true,
                          V480: true,
                          V500: true,
                          V540: true,
                          V600: true,
                        }}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                    <Form.Item
                      key="phase"
                      htmlFor="phase"
                      label="Fase"
                      colon={false}
                      style={{ width: "100%" }}
                      data-cy="select-phase"
                    >
                      <Select.Phase
                        id="phase"
                        value={filters.phase}
                        defaultValue={defaultFilters.phase}
                        onChange={(phase) => {
                          const parsedPhase: PhaseEnum[] = Array.isArray(phase)
                            ? phase
                            : [phase];

                          setFilters((current) => ({
                            ...current,
                            phase: parsedPhase,
                          }));
                        }}
                        mode="multiple"
                        allowClear
                        style={{ width: "100%" }}
                        hideBifasico
                        hideTrifasico={
                          !defaultFilters.phase?.includes("Trifásico")
                        }
                      />
                    </Form.Item>
                  </AntdFlex>

                  <AntdFlex wrap={screens.xs ? "wrap" : "nowrap"} gap={8}>
                    <Form.Item
                      key="differentPhaseInverterMixAllowedInSolarKit"
                      htmlFor="differentPhaseInverterMixAllowedInSolarKit"
                      label="Permitir inversores com tensão de saída diferentes no mesmo kit?"
                      colon={false}
                      initialValue={
                        defaultFilters.differentPhaseInverterMixAllowedInSolarKit
                      }
                      style={{ width: "100%" }}
                    >
                      <Radio.Group
                        options={[
                          {
                            label: "Sim",
                            value: true,
                          },
                          {
                            label: "Não",
                            value: false,
                          },
                        ]}
                        value={
                          filters.differentPhaseInverterMixAllowedInSolarKit
                        }
                        defaultValue={
                          defaultFilters.differentPhaseInverterMixAllowedInSolarKit
                        }
                        onChange={(event) => {
                          const differentPhaseInverterMixAllowedInSolarKit =
                            event.target.value;

                          setFilters((current) => ({
                            ...current,
                            differentPhaseInverterMixAllowedInSolarKit,
                          }));
                        }}
                      />
                    </Form.Item>
                  </AntdFlex>

                  <AntdFlex wrap={screens.xs ? "wrap" : "nowrap"} gap={8}>
                    <Form.Item
                      key="microAndStringInverterMixAllowedInSolarKit"
                      htmlFor="microAndStringInverterMixAllowedInSolarKit"
                      label="Permitir micro e string num mesmo kit?"
                      colon={false}
                      initialValue={
                        defaultFilters.microAndStringInverterMixAllowedInSolarKit
                      }
                      style={{ width: "100%" }}
                    >
                      <Radio.Group
                        options={[
                          {
                            label: "Sim",
                            value: true,
                          },
                          {
                            label: "Não",
                            value: false,
                          },
                        ]}
                        value={
                          filters.microAndStringInverterMixAllowedInSolarKit
                        }
                        defaultValue={
                          defaultFilters.microAndStringInverterMixAllowedInSolarKit
                        }
                        onChange={(event) => {
                          const microAndStringInverterMixAllowedInSolarKit =
                            event.target.value;

                          setFilters((current) => ({
                            ...current,
                            microAndStringInverterMixAllowedInSolarKit,
                          }));
                        }}
                      />
                    </Form.Item>
                  </AntdFlex>
                  <Divider
                    key="divider_module"
                    style={{
                      margin: "0rem",
                      marginTop: "0.75rem",
                      color: "var(--primary-500)",
                    }}
                    orientation="left"
                    plain
                  >
                    Filtros dos módulos
                  </Divider>
                  <Form.Item
                    key="moduleNames"
                    htmlFor="moduleNames"
                    label="Nome contenha:"
                    colon={false}
                    style={{ width: "100%" }}
                    initialValue={defaultFilters.moduleNames}
                  >
                    <AntdSelect
                      mode="tags"
                      onChange={(value) =>
                        setFilters((current) => ({
                          ...current,
                          moduleNames: value,
                        }))
                      }
                      allowClear={false}
                      defaultValue={defaultFilters.moduleNames}
                      value={filters.moduleNames}
                    />
                  </Form.Item>
                  <Form.Item
                    key="moduleFaceType"
                    htmlFor="moduleFaceType"
                    label="Fase do módulo"
                    colon={false}
                    style={{ width: "100%" }}
                  >
                    <Select.ModulePhase
                      id="moduleFaceType"
                      value={filters.moduleFaceType}
                      defaultValue={defaultFilters.moduleFaceType}
                      mode="multiple"
                      onChange={(phase) => {
                        const parsedModuleFaceType: ModulePhaseEnum[] =
                          Array.isArray(phase) ? phase : [phase];

                        setFilters((current) => ({
                          ...current,
                          moduleFaceType: parsedModuleFaceType,
                        }));
                      }}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                  <Form.Item
                    key="moduleSearchType"
                    htmlFor="moduleSearchType"
                    label="Filtrar potência por"
                    colon={false}
                    style={{ width: "100%" }}
                    initialValue={defaultFilters.moduleSearchType ?? "BETWEEN"}
                  >
                    <AntdSelect<IModuleSearchType>
                      options={[
                        { label: "Por faixa", value: "BETWEEN" },
                        { label: "Valor exato", value: "EXACTLY" },
                      ]}
                      onChange={(value) =>
                        setFilters((current) => ({
                          ...current,
                          moduleSearchType: value,
                        }))
                      }
                      allowClear={false}
                      defaultValue="BETWEEN"
                      value={filters.moduleSearchType}
                    />
                  </Form.Item>
                  <Form.Item
                    key="modulePowerWp"
                    htmlFor="modulePowerWp"
                    label="Potência dos módulos"
                    colon={false}
                    style={{ width: "100%" }}
                    hidden={filters.moduleSearchType !== "EXACTLY"}
                  >
                    <CustomInput.UnitMeasurement
                      id="modulePowerWp"
                      value={filters.modulePowerWp}
                      precision="2"
                      unitMeasurement="Wp"
                      onChange={(value) => {
                        const parsedValue = Number(value);
                        setFilters((current) => ({
                          ...current,
                          modulePowerWp: isNaN(parsedValue) ? 0 : parsedValue,
                        }));
                      }}
                      placeholder="Ex.: 25"
                    />
                  </Form.Item>
                  <AntdFlex wrap={screens.xs ? "wrap" : "nowrap"} gap={8}>
                    <Form.Item
                      key="moduleMinPowerWp"
                      htmlFor="moduleMinPowerWp"
                      label="Potência mínima dos módulos"
                      tooltip="Potência mínima dos módulos"
                      colon={false}
                      style={{ width: "100%" }}
                      hidden={filters.moduleSearchType !== "BETWEEN"}
                    >
                      <CustomInput.UnitMeasurement
                        id="moduleMinPowerWp"
                        value={filters.moduleMinPowerWp}
                        precision="2"
                        unitMeasurement="Wp"
                        onChange={(value) => {
                          const parsedValue = Number(value);
                          setFilters((current) => ({
                            ...current,
                            moduleMinPowerWp: isNaN(parsedValue)
                              ? 0
                              : parsedValue,
                          }));
                        }}
                        min={0}
                        max={filters.moduleMaxPowerWp}
                        placeholder="Ex.: 25"
                      />
                    </Form.Item>
                    <Form.Item
                      key="moduleMaxPowerWp"
                      htmlFor="moduleMaxPowerWp"
                      label="Potência máxima dos módulos"
                      tooltip="Potência máxima dos módulos"
                      colon={false}
                      style={{ width: "100%" }}
                      hidden={filters.moduleSearchType !== "BETWEEN"}
                    >
                      <CustomInput.UnitMeasurement
                        id="moduleMaxPowerWp"
                        value={filters.moduleMaxPowerWp}
                        precision="2"
                        unitMeasurement="Wp"
                        onChange={(value) => {
                          const parsedValue = Number(value);
                          setFilters((current) => ({
                            ...current,
                            moduleMaxPowerWp: isNaN(parsedValue)
                              ? 0
                              : parsedValue,
                          }));
                        }}
                        min={filters.moduleMinPowerWp}
                        placeholder="Ex.: 50"
                      />
                    </Form.Item>
                  </AntdFlex>
                  <Divider
                    key="divider_others"
                    style={{
                      margin: "0rem",
                      marginTop: "0.75rem",
                      color: "var(--primary-500)",
                    }}
                    orientation="left"
                    plain
                  >
                    Outros filtros
                  </Divider>
                  <Form.Item
                    key="ignoreElectricUtilityRules"
                    htmlFor="ignoreElectricUtilityRules"
                    label="Ignorar regras da concessionária?"
                    colon={false}
                    initialValue={!!defaultFilters.ignoreElectricUtilityRules}
                    style={{
                      width: screens.xs ? "100%" : "49%",
                    }}
                  >
                    <Radio.Group
                      options={[
                        {
                          label: "Sim",
                          value: true,
                        },
                        {
                          label: "Não",
                          value: false,
                        },
                      ]}
                      value={!!filters.ignoreElectricUtilityRules}
                      defaultValue={!!defaultFilters.ignoreElectricUtilityRules}
                      onChange={(event) => {
                        const ignoreElectricUtilityRules = !!event.target.value;

                        setFilters((current) => ({
                          ...current,
                          ignoreElectricUtilityRules,
                        }));
                      }}
                    />
                  </Form.Item>
                </AntdFlex>,
              ]
        }
      />
    );
  }

  function renderSupplierNameFilter() {
    return (
      <ColumnFilter
        fields={[
          <Form.Item
            key="supplierName"
            htmlFor="supplierName"
            label="Fornecedor"
            tooltip="Filtragem por fornecedor só é aplicada aos kits manuais"
            colon={false}
          >
            <CustomInput.Text
              id="supplierName"
              value={filters.supplierName}
              onChange={(event) => {
                setFilters((current) => ({
                  ...current,
                  supplierName: event.target.value,
                }));
              }}
              placeholder="Fornecedor..."
            />
          </Form.Item>,
        ]}
        isLoading={isLoading}
        onReset={() =>
          setFilters((current) => ({ ...current, supplierName: "" }))
        }
        onSubmit={() =>
          onSubmit({
            filters: getActiveFilters(),
            sort,
            page: pagination.number,
          })
        }
        isDisabled={!filters.fullPower}
        searchTooltip={
          !filters.fullPower ? "A potência do kit é obrigatória!" : ""
        }
      />
    );
  }

  const COLUMNS = (
    [
      {
        title: (
          <Flex align="center" gap={4}>
            Kit{" "}
            <Tooltip title="Geração média: É a geração esperada em 30 dias com o fator de geração e performance definidos">
              <AiOutlineQuestionCircle
                color={"var(--primary-500)"}
                style={{ cursor: "help" }}
              />
            </Tooltip>
          </Flex>
        ),
        dataIndex: "name",
        render: (_, record) => renderKit(record),
        filterDropdown: renderKitFilter,
        filteredValue: [true],
        width: "30rem",
      },
      {
        title: "Fornecedor",
        dataIndex: "supplierName",
        width: "8rem",
        render: (_, record) => renderSupplierName(record),
        filterDropdown: renderSupplierNameFilter,
        filteredValue: filters.supplierName ? [filters.supplierName] : null,
      },
      {
        title: "Inversor",
        render: (_, record) => renderInversor(record),
        width: "16rem",
      },
      {
        title: "Módulo",
        render: (_, record) => renderModule(record),
        width: "12rem",
      },
      {
        title: "Preço por kWp",
        dataIndex: "pricePerKwp",
        align: "center" as const,
        width: "10rem",
        render: (_, record) => renderPricePerKwp(record),
        sorter: true,
        sortOrder: sort.by === "pricePerKwp" ? sort.order : undefined,
        onHeaderCell: () => ({
          onClick: () => {
            setSort((current) => {
              const sortBy = "pricePerKwp";

              const sortOrder =
                current.by === "pricePerKwp"
                  ? current.order === "ascend"
                    ? "descend"
                    : "ascend"
                  : "ascend";

              return {
                ...current,
                by: sortBy,
                order: sortOrder,
              };
            });
            onSubmit({
              filters: getActiveFilters(),
              sort,
              page: pagination.number,
            });
          },
        }),
        isHidden: !userHavePermission("VIEW_PRICE_DETAILS_KIT"),
      },
      {
        title: "Valor total",
        dataIndex: "totalValue",
        align: "center" as const,
        width: "10rem",
        render: (_, record) => renderTotalValue(record),
        sortOrder: sort.by === "totalValue" ? sort.order : undefined,
        sorter: true,
        onHeaderCell: () => ({
          onClick: () => {
            setSort((current) => {
              const sortBy = "totalValue";

              const sortOrder =
                sort.by === "totalValue"
                  ? sort.order === "ascend"
                    ? "descend"
                    : "ascend"
                  : "ascend";

              return {
                ...current,
                by: sortBy,
                order: sortOrder,
              };
            });
            onSubmit({
              filters: getActiveFilters(),
              sort,
              page: pagination.number,
            });
          },
        }),
        isHidden: !userHavePermission("VIEW_PRICE_DETAILS_KIT") && !userIsAdmin,
      },
      {
        title: "",
        dataIndex: "action",
        align: "center" as const,
        width: "4rem",
        render: (_, kit) => (
          <Button type="link" onClick={() => setSelectedKitDetails(kit)}>
            <BsNodePlusFill size={20} />
          </Button>
        ),
      },
    ] as IKitsTableColumn[]
  ).filter((column) => !column.isHidden);

  function onChangePagination(page: number) {
    onSubmit({
      filters: getActiveFilters(),
      sort,
      page,
    });
  }

  useEffect(() => {
    if (!displayKomecoFilters) {
      setActiveFilters((current) => ({ ...current, komeco: false }));
    }
  }, [displayKomecoFilters]);

  return (
    <>
      <Table
        data-cy="kits-table"
        columns={COLUMNS}
        dataSource={DATA_SOURCE}
        pagination={{
          ...createPaginationTableObject({
            number: pagination.number,
            size: pagination.size,
            totalElements: pagination.totalElements,
            onChange: onChangePagination,
          }),
          position: tablePaginationPosition,
        }}
        locale={{
          emptyText: (
            <Result.TableErrorOrEmpty
              errorMessage=""
              emptyMessage={
                <AntdFlex vertical>
                  <span>Nenhum kit encontrado</span>

                  {ENV.AMBIENT === "DEVELOPMENT" && (
                    <span>
                      Assista{" "}
                      <Button
                        type="link"
                        size="small"
                        style={{ padding: 0 }}
                        onClick={() => setIsYouTubeVideoModalOpen(true)}
                      >
                        <span style={{ textDecoration: "underline" }}>
                          nosso vídeo
                        </span>
                      </Button>{" "}
                      para entender melhor.
                    </span>
                  )}
                </AntdFlex>
              }
            />
          ),
        }}
        rowSelection={
          disableSelect
            ? undefined
            : {
                onSelect: (record) => {
                  onSelectKit(record);
                },
                type: "radio",
              }
        }
        loading={isLoading}
        size={size}
        className="table"
        scroll={scroll}
      />

      <Modal.KitItemsDetails
        isOpen={!!selectedKitDetails?.items}
        itemKits={selectedKitDetails?.items}
        title={selectedKitDetails?.name}
        onClose={() => {
          setSelectedKitDetails(undefined);
        }}
        tableProps={{
          displayActionColumn: false,
          displayItemInfoIcon: false,
          displayPricesColumn: displayKitItemsDetailsPrices,
        }}
      />

      {ENV.AMBIENT === "DEVELOPMENT" && (
        <Modal.YouTubeVideo
          isOpen={isYouTubeVideoModalOpen}
          onClose={() => setIsYouTubeVideoModalOpen(false)}
          youTubeOptions={{
            youTubeVideoCode: "a_C64mcr5sc",
          }}
        />
      )}
    </>
  );
}
