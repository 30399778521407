import { BiCog } from "react-icons/bi";

import NextImage from "next/image";

import type { IDistributorIntegrationCardProps } from "./interface";

import { Button } from "~components/Button";
import { Switch } from "~components/Switch";
import { Card, Tooltip, Typography, Flex } from "antd";
import dayjs from "dayjs";

function getPropsByDistributor({
  distributor,
  distributorId,
  description,
  isActive,
  activeKitFilter,
  version,
  lastUpdate,
}: IDistributorIntegrationCardProps) {
  const IMAGE_HEIGHT = 80;

  switch (distributor) {
    case "EDELTEC":
      return {
        title: "EDELTEC",
        subtitle: description,
        version,
        src: "/images/integradores/logo-edeltec-solar.svg",
        alt: "Logotipo da Edeltec",
        width: 164,
        height: IMAGE_HEIGHT,
        content: (
          <Flex align="center" gap={12}>
            <Switch.EdeltecIntegration
              isChecked={isActive}
              distributorId={distributorId}
            />
          </Flex>
        ),
      };
    case "FOCO":
      return {
        title: "FOCO ENERGIA",
        subtitle: description,
        version,
        src: "/images/integradores/logo-foco-energia-solar.svg",
        alt: "Logotipo da Foco Energia",
        width: 142,
        height: IMAGE_HEIGHT,
        content: (
          <Flex align="center" gap={12}>
            <Switch.ToggleDistributors
              distributorId={distributorId}
              isChecked={isActive}
            />
          </Flex>
        ),
      };
    case "FORTLEV":
      return {
        title: "FORTLEV",
        subtitle: description,
        version,
        src: "/images/integradores/logo-fortlev-solar.svg",
        alt: "Logotipo da FORTLEV",
        width: 220,
        height: IMAGE_HEIGHT,
        content: (
          <Flex align="center" gap={12}>
            <Switch.FortlevIntegration
              isChecked={isActive}
              distributorId={distributorId}
            />
            {isActive && (
              <Tooltip title="Configurar integração com a FORTLEV">
                <Button.DistributorIntegration
                  size="small"
                  style={{ padding: 0, margin: 0, lineHeight: "0" }}
                  type="link"
                  distributorId={distributorId ?? 0}
                  activeKitFilter={!!activeKitFilter}
                >
                  <BiCog size={16} />
                </Button.DistributorIntegration>
              </Tooltip>
            )}
          </Flex>
        ),
      };
    case "GT":
      return {
        title: "GTSolar",
        subtitle: description,
        version,
        src: "/images/integradores/logo-gt-solar.svg",
        alt: "Logotipo da GTSolar",
        width: 220,
        height: IMAGE_HEIGHT,
        content: (
          <Button.GTSolarIntegration integratorId={distributorId} block>
            {isActive ? "Já integrado" : "Integrar"}
          </Button.GTSolarIntegration>
        ),
      };
    case "KOMECO":
      return {
        title: "KOMECO",
        subtitle: description,
        version,
        src: "/images/integradores/logo-komeco-solar.svg",
        alt: "Logotipo da Komeco",
        width: 142,
        height: IMAGE_HEIGHT,
        content: (
          <Flex align="center" gap={12}>
            <Switch.ToggleDistributors
              distributorId={distributorId}
              isChecked={isActive}
            />
            {isActive && (
              <Tooltip title="Configurar integração com a KOMECO">
                <Button.DistributorIntegration
                  size="small"
                  style={{ padding: 0, margin: 0, lineHeight: "0" }}
                  type="link"
                  distributorId={distributorId ?? 0}
                  activeKitFilter={!!activeKitFilter}
                >
                  <BiCog size={16} />
                </Button.DistributorIntegration>
              </Tooltip>
            )}
          </Flex>
        ),
      };
    case "SOUENERGY":
      return {
        title: "SOU ENERGY",
        subtitle: description,
        version,
        src: "/images/integradores/logo-sou-energy-solar.svg",
        alt: "Logotipo da SOU ENERGY SOLAR",
        width: 114,
        height: IMAGE_HEIGHT,
        content: (
          <Switch.ToggleDistributors
            distributorId={distributorId}
            isChecked={isActive}
          />
        ),
      };
    case "WEG":
      return {
        title: "WEG",
        subtitle: description,
        version,
        src: "/images/integradores/logo-weg-solar.svg",
        alt: "Logotipo da WEG",
        width: 142,
        height: IMAGE_HEIGHT,
        content: (
          <Flex align="center" gap={12}>
            <Switch.ToggleDistributors
              distributorId={distributorId}
              isChecked={isActive}
            />
            {isActive && (
              <Tooltip title="Configurar integração com a WEG">
                <Button.WegIntegration
                  size="small"
                  style={{ padding: 0, margin: 0, lineHeight: "0" }}
                  type="link"
                >
                  <BiCog size={16} />
                </Button.WegIntegration>
              </Tooltip>
            )}
          </Flex>
        ),
      };
    case "ALDO":
      return {
        title: "ALDO",
        subtitle: description,
        version,
        lastUpdate,
        src: "/images/integradores/aldo-logo-solar.svg",
        alt: "Logotipo da Aldo",
        width: 142,
        height: IMAGE_HEIGHT,
        content: (
          <Flex align="center" gap={12}>
            <Switch.AldoIntegration
              isChecked={isActive}
              distributorId={distributorId}
            />
            {isActive && (
              <Tooltip title="Configurar integração com a ALDO">
                <Button.DistributorIntegration
                  size="small"
                  style={{ padding: 0, margin: 0, lineHeight: "0" }}
                  type="link"
                  distributorId={distributorId ?? 0}
                  activeKitFilter={!!activeKitFilter}
                >
                  <BiCog size={16} />
                </Button.DistributorIntegration>
              </Tooltip>
            )}
          </Flex>
        ),
      };
    case "ECORI":
      return {
        title: "ECORI",
        subtitle: description,
        version,
        src: "/images/integradores/ecori-logo-solar.svg",
        alt: "Logotipo da Aldo",
        width: 220,
        height: IMAGE_HEIGHT,
        content: (
          <Flex align="center" gap={12}>
            <Button.DistributorIntegration
              size="small"
              style={{ padding: 0, margin: 0, lineHeight: "0" }}
              type="link"
              distributorId={distributorId ?? 0}
              activeKitFilter={!!activeKitFilter}
            >
              <BiCog size={16} />
            </Button.DistributorIntegration>
          </Flex>
        ),
      };
    default:
      return {
        title: "Desconhecido",
        subtitle: "O sistema não reconhece esse integrador",
        version,
        src: "/images/integradores/unknown-integrator.svg",
        alt: "Integrador desconhecido",
        width: 100,
        height: 80,
        content: <></>,
      };
  }
}

export function DistributorIntegrationCard(
  props: IDistributorIntegrationCardProps,
) {
  const { content, title, subtitle, version, lastUpdate, ...logoProps } =
    getPropsByDistributor(props);

  return (
    <>
      <Card
        data-cy="distributor-integration-card"
        style={{
          maxWidth: 280,
          minWidth: 220,
          minHeight: 380,
        }}
      >
        <Flex align="center" justify="center" vertical gap={16}>
          <Typography.Title style={{ margin: 0 }} level={4}>
            {title}
          </Typography.Title>

          <NextImage {...logoProps} priority />

          {content}
          {(title === "WEG" || title === "FOCO ENERGIA") && (
            <Typography.Text style={{ textAlign: "center" }}>
              Versão atual da planilha: <b>{version || "Indisponível"}</b>
            </Typography.Text>
          )}
          {title === "ALDO" && (
            <Typography.Text style={{ textAlign: "center" }}>
              Última importação:{" "}
              <b>
                {lastUpdate
                  ? dayjs(lastUpdate).format("DD/MM/YYYY [às] HH:mm")
                  : "Indisponível"}
              </b>
            </Typography.Text>
          )}
          <Typography.Text style={{ textAlign: "center" }}>
            {subtitle}
          </Typography.Text>
        </Flex>
      </Card>
    </>
  );
}
