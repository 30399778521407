import { useMemo, useState } from "react";

import type { IConcessionairesProfilesListModalProps } from "./interface";

import { IConcessionairesProfile, List } from "~components/List";
import { useFetchGetPaginatedConcessionaireProfilesById } from "~hooks/api";
import { mapperElectricUtilityProfileListItemDtoToProfile } from "~mappers";
import { Modal } from "antd";

export function ConcessionairesProfilesListModal({
  isOpen = false,
  onClose = () => null,
  width = "80vw",
  concessionaire,
}: IConcessionairesProfilesListModalProps) {
  const [currentPage, setCurrentPage] = useState(0);

  const idSelectedConcessionaire = concessionaire?.id ?? 0;

  const {
    isFetching: isLoadingConcessionaireProfiles,
    data: ConcessionaireProfilesList,
    error: ConcessionaireProfilesError,
  } = useFetchGetPaginatedConcessionaireProfilesById({
    dependencyArray: [currentPage, idSelectedConcessionaire],
    payload: {
      concessionaireId: idSelectedConcessionaire,
      page: currentPage,
      size: 10,
    },
    options: {
      enabled: idSelectedConcessionaire > 0,
    },
  });

  const PROFILES_LIST: IConcessionairesProfile[] = useMemo(() => {
    if (!ConcessionaireProfilesList) return [];

    return ConcessionaireProfilesList.content.map((profile: any) =>
      mapperElectricUtilityProfileListItemDtoToProfile(profile),
    );
  }, [ConcessionaireProfilesList]);

  const modalTitle = `Perfis da concessionária ${concessionaire?.name}`;

  return (
    <Modal
      data-cy="concessionaires-profiles-list-modal"
      open={isOpen}
      onCancel={onClose}
      title={modalTitle}
      width={width}
      footer={null}
      destroyOnClose
    >
      <List.ConcessionaireProfiles
        isLoading={isLoadingConcessionaireProfiles}
        profiles={PROFILES_LIST}
        error={ConcessionaireProfilesError?.message}
        page={ConcessionaireProfilesList}
        setCurrentPage={setCurrentPage}
      />
    </Modal>
  );
}
