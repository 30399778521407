import type {
  IProductTypeOtherFormProps,
  IProductTypeOtherFormValues,
} from "./interface";

import { CustomInput } from "~components/CustomInput";
import {
  useFetchCreateProductTypeOther,
  useFetchUpdateProductTypeOther,
} from "~hooks/api";
import { Button, Flex, Form } from "antd";

export function ProductTypeOtherForm({
  initialValues,
  onCancel = () => undefined,
  onError = () => undefined,
  onSuccess = () => undefined,
}: IProductTypeOtherFormProps) {
  const [form] = Form.useForm<IProductTypeOtherFormValues>();

  const { mutate: createProduct, isLoading: isCreatingProduct } =
    useFetchCreateProductTypeOther({
      options: {
        onSuccess,
        onError,
      },
    });

  const { mutate: updateProduct, isLoading: isUpdatingProduct } =
    useFetchUpdateProductTypeOther({
      options: {
        onSuccess,
        onError,
      },
    });

  function handleSubmit(formValues: IProductTypeOtherFormValues) {
    const formattedPriceBaseCents = formValues.priceBaseCents * 100;

    const payload = {
      ...formValues,
      priceBaseCents: formattedPriceBaseCents,
    };

    payload.id
      ? updateProduct({ ...payload, productId: payload.id })
      : createProduct(payload);
  }

  const isLoading = isCreatingProduct || isUpdatingProduct;

  return (
    <Form
      data-cy="product-type-other-form"
      form={form}
      initialValues={{
        ...initialValues,
        priceBaseCents: (initialValues?.priceBaseCents ?? 0) / 100,
      }}
      onFinish={handleSubmit}
      layout="vertical"
      style={{ width: "100%" }}
    >
      <Form.Item name="id" hidden>
        <CustomInput.Number disabled hidden />
      </Form.Item>

      <Form.Item
        name="name"
        label="Nome"
        required
        rules={[
          {
            required: true,
            message: "Nome é obrigatório",
          },
        ]}
      >
        <CustomInput.Text placeholder="Ex.: SIW 500" />
      </Form.Item>

      <Form.Item
        name="priceBaseCents"
        label="Valor unitário"
        required
        rules={[
          {
            required: true,
            message: "Valor unitário é obrigatório",
          },
        ]}
      >
        <CustomInput.Money placeholder="Ex.: 1000,00" min={0} />
      </Form.Item>

      <Flex gap={12} justify="end">
        <Form.Item style={{ margin: 0 }}>
          <Button onClick={onCancel}>Cancelar</Button>
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Salvar
          </Button>
        </Form.Item>
      </Flex>
    </Form>
  );
}
