import { useState } from "react";
import { AiOutlineEdit, AiOutlineSetting } from "react-icons/ai";

import type {
  IAdminModulesTableData,
  IAdminModulesTableFilters,
  IAdminModulesTableProps,
} from "./interface";

import { ColumnFilter } from "~components/ColumnFilter";
import { CustomInput } from "~components/CustomInput";
import { Menu } from "~components/Menu";
import { Modal } from "~components/Modal";
import { useFetchGetPaginatedAdminModules } from "~hooks/api";
import { SolarzAnchor } from "~solarzui/SolarzAnchor";
import { SolarzTable } from "~solarzui/SolarzTable";
import { parsePotency } from "~utils/parse";
import { Form, Select } from "antd";

const DEFAULT_FILTERS: IAdminModulesTableFilters = {
  page: 1,
  size: 10,
};

export function AdminModulesTable({ defaultFilters }: IAdminModulesTableProps) {
  const [filters, setFilters] = useState<IAdminModulesTableFilters>({
    ...DEFAULT_FILTERS,
    ...defaultFilters,
  });

  const [inverterIdToUpdate, setModuleIdToUpdate] = useState<number>();

  const [moduleIdToDisplayDetails, setModuleIdToDisplayDetails] =
    useState<number>();

  const {
    data: paginatedAdminModules,
    isFetching: isLoadingPaginatedAdminModules,
    refetch: reloadPaginatedAdminModules,
    error: errorPaginatedAdminModules,
  } = useFetchGetPaginatedAdminModules({
    payload: filters,
    dependencyArray: [filters.page],
    options: {
      retry: 1,
    },
  });

  function handleChangeFilter(filters: Partial<IAdminModulesTableFilters>) {
    setFilters((currentData) => {
      return { ...currentData, ...filters };
    });
  }

  function handleFetchTableData() {
    filters.page > 1
      ? setFilters((current) => ({ ...current, pageNumber: 1 }))
      : reloadPaginatedAdminModules();
  }

  return (
    <>
      <SolarzTable<IAdminModulesTableData>
        data-cy="admin-inverters-table"
        rowKey="id"
        columns={[
          {
            title: "Modelo",
            dataIndex: "model",
            render: (_, record) => record.model || "-",
            filterDropdown: (
              <ColumnFilter
                fields={[
                  <Form.Item key="models" htmlFor="models" label="Modelo">
                    <Select
                      mode="tags"
                      id="models"
                      value={filters.models}
                      onChange={(models) => {
                        handleChangeFilter({
                          models: models,
                        });
                      }}
                      placeholder="Modelos..."
                    />
                  </Form.Item>,
                ]}
                onReset={() => handleChangeFilter({ models: undefined })}
                isLoading={isLoadingPaginatedAdminModules}
                onSubmit={handleFetchTableData}
              />
            ),
            filteredValue: filters.models,
          },
          {
            title: "Fabricante",
            dataIndex: "manufacturer",
            width: 320,
            render: (_, record) => record.manufacturer || "-",
            filterDropdown: (
              <ColumnFilter
                fields={[
                  <Form.Item key="brands" htmlFor="brands" label="Fabricante">
                    <Select
                      mode="tags"
                      id="brands"
                      value={filters.manufacturers}
                      onChange={(brands) => {
                        handleChangeFilter({
                          manufacturers: brands,
                        });
                      }}
                      placeholder="Fabricantes..."
                    />
                  </Form.Item>,
                ]}
                onReset={() => handleChangeFilter({ manufacturers: undefined })}
                isLoading={isLoadingPaginatedAdminModules}
                onSubmit={handleFetchTableData}
              />
            ),
            filteredValue: filters.manufacturers,
          },
          {
            title: "Potência pico",
            dataIndex: "powerWp",
            width: 180,
            align: "center" as const,
            render: (_, record) =>
              record.powerWp ? parsePotency(record.powerWp, 2, true) : "-",
            filterDropdown: (
              <ColumnFilter
                fields={[
                  <Form.Item
                    key="powerWp"
                    htmlFor="powerWp"
                    label="Potência pico"
                  >
                    <CustomInput.Number
                      id="powerWp"
                      value={filters.powerWp}
                      onChange={(value) => {
                        const parsedValue = Number(value);

                        handleChangeFilter({
                          powerWp: isNaN(parsedValue) ? undefined : parsedValue,
                        });
                      }}
                      min={0}
                      placeholder="Potência..."
                    />
                  </Form.Item>,
                ]}
                onReset={() => handleChangeFilter({ powerWp: undefined })}
                isLoading={isLoadingPaginatedAdminModules}
                onSubmit={handleFetchTableData}
              />
            ),
            filteredValue: filters.powerWp ? [true] : [],
          },
          {
            title: "Tipo",
            dataIndex: "surfaceType",
            width: 140,
            align: "center" as const,
            render: (_, record) => record.surfaceType || "-",
          },
          {
            title: "Link",
            dataIndex: "datasheetId",
            width: 80,
            align: "center" as const,
            render: (_, record) => {
              const isValidId = record.datasheetId > 0;

              return (
                <SolarzAnchor
                  isNextLink
                  href={`/admin/datasheets?id=${record.datasheetId}`}
                  isDisabled={!isValidId}
                >
                  {isValidId ? "LINK" : "-"}
                </SolarzAnchor>
              );
            },
          },
          {
            key: "action",
            width: 64,
            render: (_, record) => {
              return (
                <Menu.MoreDropdown
                  items={[
                    {
                      icon: (
                        <AiOutlineEdit
                          fontSize={18}
                          style={{ color: "var(--gold-500)" }}
                        />
                      ),
                      text: "Alterar",
                      color: "var(--gold-500)",
                      onClick: () => setModuleIdToUpdate(record.id),
                    },
                    {
                      icon: (
                        <AiOutlineSetting
                          fontSize={18}
                          style={{ color: "var(--blue-500)" }}
                        />
                      ),
                      text: "Detalhes",
                      color: "var(--blue-500)",
                      onClick: () => setModuleIdToDisplayDetails(record.id),
                    },
                  ]}
                  position="bottomLeft"
                />
              );
            },
          },
        ]}
        rows={paginatedAdminModules?.content ?? []}
        pagination={{
          currentItemCount: filters.size,
          currentPage: filters.page,
          itemLabel: "módulos",
          onChange: (page) => handleChangeFilter({ page }),
          totalItemCount: paginatedAdminModules?.totalElements ?? 0,
          isDisabled: false,
        }}
        scroll={{ x: 1360 }}
        isLoading={isLoadingPaginatedAdminModules}
        reloadFn={reloadPaginatedAdminModules}
        errorMessage={errorPaginatedAdminModules?.message}
        emptyMessage="Nenhum módulo encontrado"
      />

      <Modal.AdminModuleForm
        isOpen={!!inverterIdToUpdate}
        onClose={() => setModuleIdToUpdate(undefined)}
        id={inverterIdToUpdate ?? 0}
        formProps={{
          onSuccess: () => {
            setModuleIdToUpdate(undefined);
          },
        }}
      />

      <Modal.AdminModuleDetails
        isOpen={!!moduleIdToDisplayDetails}
        onClose={() => setModuleIdToDisplayDetails(undefined)}
        moduleId={moduleIdToDisplayDetails ?? 0}
      />
    </>
  );
}
