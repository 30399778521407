import * as React from "react";

import type { IIconProps } from "../@types/globals";
import { cn } from "../lib/utils";

const Bell: React.FC<IIconProps> = ({ className, ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cn("pointer-events-none w-6 h-6 stroke-2", className)}
    {...props}
  >
    <title>Sino</title>
    <path
      d="M12.0186 2.91003C8.70862 2.91003 6.01862 5.60003 6.01862 8.91003V11.8C6.01862 12.41 5.75862 13.34 5.44862 13.86L4.29862 15.77C3.58862 16.95 4.07862 18.26 5.37862 18.7C9.68862 20.14 14.3386 20.14 18.6486 18.7C19.8586 18.3 20.3886 16.87 19.7286 15.77L18.5786 13.86C18.2786 13.34 18.0186 12.41 18.0186 11.8V8.91003C18.0186 5.61003 15.3186 2.91003 12.0186 2.91003Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M13.8689 3.2C12.6597 2.85559 11.3782 2.85559 10.1689 3.2C10.4589 2.46 11.1789 1.94 12.0189 1.94C12.8589 1.94 13.5789 2.46 13.8689 3.2Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0205 19.06C15.0205 20.71 13.6705 22.06 12.0205 22.06C11.2005 22.06 10.4405 21.72 9.90051 21.18C9.33866 20.6173 9.0223 19.8552 9.02051 19.06"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

Bell.displayName = "Bell";

export { Bell };
