import styled from "@emotion/styled";
import { Button, Typography } from "antd";

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 36px;
  height: 36px;
  padding: 0;
  background: transparent;
  border: none;
  box-shadow: none;

  svg {
    color: var(--gray-500);
  }
`;

export const NotificationHeader = styled(Typography.Text)`
  font-size: 18px;
  font-weight: bold;
  color: var(--gray-900);

  // DARK THEME
  html.dark & {
    color: var(--gray-100) !important;
  }
`;

export const StyledLabel = styled(Typography.Text)`
  font-size: 14px;
  font-weight: 500;
  line-height: 15.84px;
  color: var(--gray-600);

  // DARK THEME
  html.dark & {
    color: var(--gray-400) !important;
  }
`;

export const StyledTitle = styled(Typography.Title)`
  color: var(--gray-600);
  margin: 0;

  // DARK THEME
  html.dark & {
    color: var(--gray-400) !important;
  }
`;
