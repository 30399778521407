import type { IOrganizationCardProps } from "./interface";
import { EditButton } from "./styles";

import { Alert } from "~components/Alert";
import { Modal } from "~components/Modal";
import { Edit } from "~estrela-ui/icons";
import { useFetchGetOrganizationById } from "~hooks/api";
import { useQueryParams, parseFunctions } from "~hooks/useQueryParams";
import { SolarzAnchor } from "~solarzui/SolarzAnchor";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { formatCnpj, formatTelephoneWithDdd } from "~utils/format";
import { Card, Col, Flex, Row, Skeleton } from "antd";

const ORGANIZATION_MODAL_QUERY = "organization-update";

export function OrganizationCard({
  organizationId,
  style,
}: IOrganizationCardProps) {
  const { getParsedQueryParams, handleSaveInQueryParams } = useQueryParams();

  const {
    data: organizationData,
    isFetching: isLoadingOrganization,
    error: organizationError,
    refetch: reloadOrganizationData,
  } = useFetchGetOrganizationById({
    payload: {
      organizationId,
    },
    dependencyArray: [organizationId],
    options: {
      enabled: organizationId > 0,
      retry: 1,
    },
  });

  const organizationIdToUpdate = getParsedQueryParams(
    ORGANIZATION_MODAL_QUERY,
    parseFunctions.NUMBER,
  );

  function handleOpenOrganizationModal() {
    handleSaveInQueryParams({ [ORGANIZATION_MODAL_QUERY]: organizationId });
  }

  function handleCloseOrganizationModal() {
    handleSaveInQueryParams({ [ORGANIZATION_MODAL_QUERY]: "" });
  }

  const isLoading = isLoadingOrganization || organizationId === 0;

  return (
    <>
      <Card
        style={{
          width: "100%",
          maxWidth: "400px",
          ...style,
        }}
        styles={{
          body: {
            padding: 16,
          },
        }}
        title={
          <Flex justify="space-between" align="center">
            <SolarzTypography
              fontWeight="semiBold"
              hierarchy="paragraph1"
              variant="title"
            >
              Dados da Organização
            </SolarzTypography>

            <EditButton
              scale="tiny"
              variant="ghost"
              icon={<Edit />}
              isDisabled={organizationId === 0}
              onClick={handleOpenOrganizationModal}
            />
          </Flex>
        }
      >
        <Flex vertical gap={16}>
          {organizationError && (
            <Alert.CardError
              title="Falha ao carregar dados"
              errorMessage={organizationError?.message}
              reloadFn={reloadOrganizationData}
            />
          )}

          <Row align="top" gutter={24} wrap={false}>
            <Col span={10}>
              <SolarzTypography
                fontWeight="medium"
                variant="title"
                hierarchy="paragraph2"
                style={{ textAlign: "start" }}
              >
                CNPJ
              </SolarzTypography>
            </Col>
            <Col span={14}>
              {isLoading || organizationError ? (
                <Skeleton.Input active={isLoadingOrganization} size="small" />
              ) : (
                <SolarzTypography
                  fontWeight="regular"
                  variant="disabled"
                  hierarchy="small"
                  style={{ textAlign: "start" }}
                >
                  {formatCnpj(organizationData?.cnpj ?? "")}
                </SolarzTypography>
              )}
            </Col>
          </Row>

          <Row align="top" gutter={24} wrap={false}>
            <Col span={10}>
              <SolarzTypography
                fontWeight="medium"
                variant="title"
                hierarchy="paragraph2"
                style={{ textAlign: "start" }}
              >
                Endereço
              </SolarzTypography>
            </Col>
            <Col span={14}>
              {isLoading || organizationError ? (
                <Skeleton.Input active={isLoadingOrganization} size="small" />
              ) : (
                <SolarzTypography
                  fontWeight="regular"
                  variant="disabled"
                  hierarchy="small"
                  style={{ textAlign: "start" }}
                >
                  {organizationData?.address?.formattedAddress}
                </SolarzTypography>
              )}
            </Col>
          </Row>

          <Row align="top" gutter={24} wrap={false}>
            <Col span={10}>
              <SolarzTypography
                fontWeight="medium"
                variant="title"
                hierarchy="paragraph2"
                style={{ textAlign: "start" }}
              >
                Telefone
              </SolarzTypography>
            </Col>
            <Col span={14}>
              {isLoading || organizationError ? (
                <Skeleton.Input active={isLoadingOrganization} size="small" />
              ) : (
                <SolarzTypography
                  fontWeight="regular"
                  variant="disabled"
                  hierarchy="small"
                  style={{ textAlign: "start" }}
                >
                  {formatTelephoneWithDdd(organizationData?.phone ?? "")}
                </SolarzTypography>
              )}
            </Col>
          </Row>

          <Row align="top" gutter={24} wrap={false}>
            <Col span={10}>
              <SolarzTypography
                fontWeight="medium"
                variant="title"
                hierarchy="paragraph2"
                style={{ textAlign: "start" }}
              >
                Responsável
              </SolarzTypography>
            </Col>
            <Col span={14}>
              {isLoading || organizationError ? (
                <Skeleton.Input active={isLoadingOrganization} size="small" />
              ) : (
                <SolarzTypography
                  fontWeight="regular"
                  variant="disabled"
                  hierarchy="small"
                >
                  <SolarzAnchor
                    isNextLink
                    aria-label={
                      organizationData?.owner?.name ||
                      organizationData?.owner?.email ||
                      "Sem nome/email"
                    }
                    href={`/configuracoes-gerais/gerenciamento-de-usuarios?tab=usuarios&id=${organizationData?.owner?.id}`}
                    defaultUnderlined={false}
                    style={{ textAlign: "start" }}
                  >
                    {organizationData?.owner?.name ||
                      organizationData?.owner?.email ||
                      "Sem nome/email"}
                  </SolarzAnchor>
                </SolarzTypography>
              )}
            </Col>
          </Row>
        </Flex>
      </Card>

      <Modal.OrganizationForm
        isOpen={!!organizationIdToUpdate}
        onClose={handleCloseOrganizationModal}
        title="Atualizar organização"
        formProps={{
          onSuccess: handleCloseOrganizationModal,
          organizationId: organizationIdToUpdate ?? undefined,
        }}
      />
    </>
  );
}
