import { useState } from "react";

import type { IWegIntegrationButton } from "./interface";

import { Modal } from "~components/Modal";
import { useFetchGetConfigWegDistributorIntegration } from "~hooks/api";
import { Button } from "antd";

export function WegIntegrationButton({
  children,
  type = "primary",
  ...props
}: IWegIntegrationButton) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: configWegInitialValues } =
    useFetchGetConfigWegDistributorIntegration({});

  return (
    <>
      <Button
        {...props}
        data-cy="weg-integration-button"
        onClick={() => setIsModalOpen(true)}
        type={type}
        htmlType="button"
      >
        {children ?? "Configurar WEG"}
      </Button>

      <Modal.WegIntegrationForm
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        formProps={{
          onSuccess: () => setIsModalOpen(false),
          initialValues: configWegInitialValues,
        }}
      />
    </>
  );
}
