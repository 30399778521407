import { BsFillMoonFill, BsFillSunFill } from "react-icons/bs";

import type { IControlThemeButtonProps } from "./interface";
import styles from "./styles";

import { useAppConfig } from "~hooks/useAppConfig";
import { Button, Flex } from "antd";

export function ControlThemeButton({
  size = "small",
  type = "link",
  className,
  ...props
}: IControlThemeButtonProps) {
  const { changeTheme, currentTheme } = useAppConfig();

  function handleChangeTheme() {
    const theme = currentTheme === "default" ? "dark" : "default";
    changeTheme(theme);
  }

  return (
    <Button
      data-cy="control-theme-button"
      type={type}
      onClick={handleChangeTheme}
      className={`menuItem themeItem ${className}`}
      size={size}
      {...props}
    >
      <style jsx>{styles}</style>
      <Flex align="center" gap={8} wrap="nowrap">
        {currentTheme === "default" ? (
          <>
            <BsFillMoonFill />
            Ativar tema escuro
          </>
        ) : (
          <>
            <BsFillSunFill />
            Ativar tema claro
          </>
        )}
      </Flex>
    </Button>
  );
}
