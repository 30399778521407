import * as React from "react";

import type { IIconProps } from "../@types/globals";
import { cn } from "../lib/utils";

const Export: React.FC<IIconProps> = ({ className, ...props }) => (
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    stroke="currentColor"
    className={cn("pointer-events-none w-6 h-6", className)}
    {...props}
  >
    <title>Exportar</title>
    <path d="M11.25 16C11.25 16.4142 11.5858 16.75 12 16.75C12.4142 16.75 12.75 16.4142 12.75 16H11.25ZM12 3L12.5535 2.49392C12.4114 2.33852 12.2106 2.25 12 2.25C11.7894 2.25 11.5886 2.33852 11.4465 2.49392L12 3ZM15.4465 7.88108C15.726 8.18678 16.2004 8.20802 16.5061 7.92852C16.8118 7.64902 16.833 7.17462 16.5535 6.86892L15.4465 7.88108ZM7.44648 6.86892C7.16698 7.17462 7.18822 7.64902 7.49392 7.92852C7.79962 8.20802 8.27402 8.18678 8.55352 7.88108L7.44648 6.86892ZM15 20.25H9V21.75H15V20.25ZM9 20.25C7.56458 20.25 6.56347 20.2484 5.80812 20.1469C5.07434 20.0482 4.68577 19.8678 4.40901 19.591L3.34835 20.6517C3.95027 21.2536 4.70814 21.5125 5.60825 21.6335C6.48678 21.7516 7.60699 21.75 9 21.75V20.25ZM2.25 15C2.25 16.393 2.24841 17.5132 2.36652 18.3918C2.48754 19.2919 2.74643 20.0497 3.34835 20.6517L4.40901 19.591C4.13225 19.3142 3.9518 18.9257 3.85315 18.1919C3.75159 17.4365 3.75 16.4354 3.75 15H2.25ZM20.25 15C20.25 16.4354 20.2484 17.4365 20.1469 18.1919C20.0482 18.9257 19.8678 19.3142 19.591 19.591L20.6517 20.6517C21.2536 20.0497 21.5125 19.2919 21.6335 18.3918C21.7516 17.5132 21.75 16.393 21.75 15H20.25ZM15 21.75C16.393 21.75 17.5132 21.7516 18.3918 21.6335C19.2919 21.5125 20.0497 21.2536 20.6517 20.6517L19.591 19.591C19.3142 19.8678 18.9257 20.0482 18.1919 20.1469C17.4365 20.2484 16.4354 20.25 15 20.25V21.75ZM12.75 16V3H11.25V16H12.75ZM11.4465 3.50608L15.4465 7.88108L16.5535 6.86892L12.5535 2.49392L11.4465 3.50608ZM11.4465 2.49392L7.44648 6.86892L8.55352 7.88108L12.5535 3.50608L11.4465 2.49392Z" />
  </svg>
);

Export.displayName = "Export";

export { Export };
