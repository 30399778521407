import type {
  IDealCustomFieldsListProps,
  IDealCustomFieldsListValueType,
} from "./interface";

import { useFetchGetDealCustomFieldValueById } from "~hooks/api";
import { useSession } from "~hooks/useSession";
import { SolarzAnchor } from "~solarzui/SolarzAnchor";
import { SolarzList } from "~solarzui/SolarzList";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { Col, Flex, List, Row, Skeleton } from "antd";

function booleanToText(value: string | undefined): string {
  if (value === "true") return "Sim";
  if (value === "false") return "Não";
  return "-";
}

export function DealCustomFieldsList({
  dealId = 0,
}: IDealCustomFieldsListProps) {
  const { user, userHavePermission, userIsAdmin } = useSession();

  const {
    data: dealCustomFieldGroups = [],
    isFetching: isLoadingDealCustomFieldGroups,
    error: errorDealCustomFieldGroups,
    refetch: reloadDealCustomFieldGroups,
  } = useFetchGetDealCustomFieldValueById({
    payload: {
      id: dealId,
    },
    dependencyArray: [dealId],
    options: {
      enabled: dealId > 0 && !!user,
    },
  });

  const canAccessAdministrativeArea =
    userIsAdmin || userHavePermission("ACCESS_ADMINISTRATIVE_AREA");

  const countDealCustomFields = dealCustomFieldGroups.reduce(
    (count, group) => count + (group.dealCustomFields.length ?? 0),
    0,
  );

  return (
    <SolarzList<IDealCustomFieldsListValueType>
      data-cy="deal-custom-fields-list"
      items={countDealCustomFields > 0 ? dealCustomFieldGroups : []}
      isLoading={isLoadingDealCustomFieldGroups}
      listItemKey="id"
      emptyMessage={
        <div className="flex flex-col">
          <SolarzTypography hierarchy="paragraph2">
            Nenhum campo personalizado do negócio encontrado{" "}
            {!canAccessAdministrativeArea ? (
              <SolarzAnchor
                isNextLink
                href="/configuracoes-gerais/campos-de-dados?tab=negocio"
                style={{ marginTop: 8, fontSize: 14 }}
              >
                acessar página de cadastro?
              </SolarzAnchor>
            ) : (
              ""
            )}
          </SolarzTypography>
        </div>
      }
      reloadFn={reloadDealCustomFieldGroups}
      errorMessage={errorDealCustomFieldGroups?.message}
      renderItem={(group) => {
        if (group.dealCustomFields.length === 0) return <></>; // its not correctly waiting new endpoint to fix it and DealCustomFieldValueGroupDTOSchema

        return (
          <List.Item
            style={{
              width: "100%",
              height: "100%",
              borderBottom: 0,
            }}
          >
            <Flex vertical gap={8} className="w-full">
              <SolarzTypography
                variant="title"
                fontWeight="bold"
                hierarchy="paragraph2"
              >
                {group.name}
              </SolarzTypography>

              {group.dealCustomFields.map((customField) => {
                return (
                  <Row
                    key={customField.id}
                    align="top"
                    gutter={16}
                    wrap={false}
                    style={{ width: "100%" }}
                  >
                    <Col span={15}>
                      <SolarzTypography
                        fontWeight="medium"
                        variant="title"
                        hierarchy="small"
                        style={{ textAlign: "left" }}
                      >
                        {customField.label}
                      </SolarzTypography>
                    </Col>
                    <Col span={9}>
                      {isLoadingDealCustomFieldGroups ||
                      errorDealCustomFieldGroups ? (
                        <Skeleton.Input
                          active={isLoadingDealCustomFieldGroups}
                          size="small"
                        />
                      ) : (
                        <SolarzTypography
                          fontWeight="regular"
                          variant="disabled"
                          hierarchy="small"
                        >
                          {customField.type === "BOOLEAN"
                            ? booleanToText(customField.value)
                            : customField.value ?? "-"}
                        </SolarzTypography>
                      )}
                    </Col>
                  </Row>
                );
              })}
            </Flex>
          </List.Item>
        );
      }}
    />
  );
}
