import { POSSIBLE_PROPOSAL_STEP, ProposalStepEnumType } from "~types/enum";

export function checkIfIsAValidProposalStep(value?: string) {
  return value
    ? !!POSSIBLE_PROPOSAL_STEP.find((option) => option === value)
    : false;
}

export function getProposalEnumEquivalentStepNumber(
  value: ProposalStepEnumType | undefined,
  options: {
    haveAfterSizingStep?: boolean;
    havePaymentStep?: boolean;
  },
) {
  const extraStepsCount = [
    options.haveAfterSizingStep,
    options.havePaymentStep,
  ].filter((option) => option === false).length;

  switch (value) {
    case "DATA":
      return 1;
    case "SIZING":
      return 2;
    case "DETAILS":
      return 3;
    case "AFTER_SIZING":
      return 4;
    case "PAYMENT":
      return options.haveAfterSizingStep ? 5 : 4;
    case "PROPOSAL":
      return 6 - extraStepsCount;
    case "FINISH":
      return 7 - extraStepsCount;
    default:
      return 1;
  }
}
