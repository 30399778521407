import type {
  ITransformerGroupRuleForm,
  ITransformerGroupRuleFormProps,
} from "./interface";

import { Select } from "~components/Select";
import { useFetchCreateTransformerRules } from "~hooks/api";
import { VoltageEnum } from "~types/enum";
import { Button, Flex, Form } from "antd";

export function TransformerGroupRuleForm({
  initialValues,
  onSuccess = () => undefined,
  onError = () => undefined,
  onCancel = () => undefined,
}: ITransformerGroupRuleFormProps) {
  const [form] = Form.useForm<ITransformerGroupRuleForm>();

  const inputVoltage = Form.useWatch("inputVoltage", form);
  const outputVoltage = Form.useWatch("outputVoltage", form);

  const {
    mutate: createTransformerGroupRule,
    isLoading: isCreatingTransformerGroupRule,
  } = useFetchCreateTransformerRules({
    options: {
      onSuccess: () => {
        onSuccess();
      },
      onError: (error) => {
        onError(error.message);
      },
    },
  });

  function handleSubmit(formValues: ITransformerGroupRuleForm) {
    const { inputVoltage, outputVoltage } = formValues;

    createTransformerGroupRule({
      inputVoltage,
      outputVoltage,
    });
  }

  return (
    <Form
      data-cy="transformer-group-rule-form"
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmit}
      layout="vertical"
    >
      <Form.Item
        label="Saída do inversor"
        name="inputVoltage"
        rules={[{ required: true }]}
        tooltip="Nota: Os valores não podem ser iguais."
      >
        <Select.OutputVoltage
          disabledValues={
            {
              [outputVoltage]: true,
            } as Record<VoltageEnum, boolean>
          }
        />
      </Form.Item>

      <Form.Item
        label="Entrada da rede"
        name="outputVoltage"
        rules={[{ required: true }]}
        tooltip="Nota: Os valores não podem ser iguais."
      >
        <Select.OutputVoltage
          disabledValues={
            {
              [inputVoltage]: true,
            } as Record<VoltageEnum, boolean>
          }
        />
      </Form.Item>

      <Flex gap={12} justify="end">
        <Form.Item style={{ margin: 0 }}>
          <Button onClick={onCancel}>Cancelar</Button>
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={isCreatingTransformerGroupRule}
          >
            Salvar
          </Button>
        </Form.Item>
      </Flex>
    </Form>
  );
}
