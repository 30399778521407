import { FC } from "react";

import { formatTelephoneWithDdd } from "~utils/format";
import { Input } from "antd";
import type { InputProps } from "antd/lib/input";

export const InputTelephoneWithDdd: FC<InputProps> = ({
  onChange,
  onFocus = (event) => event.target.select(),
  ...rest
}) => {
  return (
    <Input
      {...rest}
      onFocus={onFocus}
      onChange={(event) => {
        event.target.value = formatTelephoneWithDdd(event.target?.value ?? "");

        if (!onChange) return;
        onChange(event);
      }}
    />
  );
};
