import { useMutation } from "react-query";

import { useFetchGetProjectRevisionKey } from "../useFetchGetProjectRevisionById";
import type { IUseFetchPatchDealTotalValueType } from "./interface";

import { fetchPatchDealTotalValueType } from "~api/projects/step_five/custom-id/{customId}/deal/update-total-value/patch";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const UseFetchPatchDealTotalValueTypeKey =
  "api/projects/step_five/custom-id/{customId}/deal/update-total-value/patch";

export function useFetchPatchDealTotalValueType({
  options,
}: IUseFetchPatchDealTotalValueType) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchPatchDealTotalValueType(payload),
    {
      ...options,
      mutationKey: UseFetchPatchDealTotalValueTypeKey,
      onSuccess: (data, variables, context) => {
        message.info("Configuração do total do negócio foi atualizada");
        queryClient.invalidateQueries(useFetchGetProjectRevisionKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
