import NextImage from "next/image";
import NextLink from "next/link";

import { IInDevelopmentResultProps } from "./interface";

import { useAppConfig } from "~hooks/useAppConfig";
import { Button, Flex, Typography } from "antd";

export function InDevelopmentResult({
  minHeight = "100vh",
  maxWidth = "90vw",
}: IInDevelopmentResultProps) {
  const { screens } = useAppConfig();

  return (
    <Flex
      data-cy="in-development-result"
      justify="center"
      align="center"
      wrap="nowrap"
      gap={screens.lg ? 72 : 32}
      style={{
        minHeight,
        width: "90vw",
        maxWidth,
        margin: "0 auto",
        flexDirection: screens.lg ? "row" : "column",
      }}
    >
      <NextImage
        src="/images/in-development.svg"
        width={570}
        height={450}
        alt="O conteúdo esta em desenvolvimento"
      />

      <Flex vertical>
        <Typography.Title
          style={{ fontSize: screens.lg ? "4.5rem" : "2rem", margin: 0 }}
        >
          Ops!
        </Typography.Title>
        <Typography.Text
          style={{ fontSize: screens.lg ? "2rem" : "1.125rem" }}
          strong
        >
          Estamos desenvolvendo esse conteúdo
        </Typography.Text>
        <Typography.Text style={{ color: "var(--gray-500)", margin: 0 }}>
          Nossos desenvolvedores estão trabalhando nesse conteúdo neste momento
        </Typography.Text>
      </Flex>

      <NextLink passHref href="/">
        <Button type="primary" size="large" style={{ marginTop: "1rem" }}>
          Voltar ao início
        </Button>
      </NextLink>
    </Flex>
  );
}
