import type { IWonDealModalProps } from "./interface";

import { List } from "~components/List";
import { useFetchUpdateDealStatusByCustomId } from "~hooks/api";
import { SolarzButton } from "~solarzui/SolarzButton";
import { Flex, Modal, Row } from "antd";

export function WonDealModal({
  isOpen = false,
  onClose = () => undefined,
  maxWidth = 560,
  dealId,
  title = "Propostas do negócio",
}: IWonDealModalProps) {
  const { mutate: updateDealStatus, isLoading: isUpdatingDealStatus } =
    useFetchUpdateDealStatusByCustomId({});

  const handleWinDeal = () => {
    updateDealStatus(
      { id: dealId, status: "WON" },
      {
        onSuccess: onClose,
      },
    );
  };

  const handleReset = () => {
    onClose();
  };

  const isLoading = isUpdatingDealStatus;
  return (
    <Modal
      data-cy="won-deal-modal"
      title={title}
      width="90vw"
      style={{
        maxWidth,
      }}
      open={isOpen}
      footer={null}
      onCancel={onClose}
      destroyOnClose
    >
      <Row style={{ display: "flex", flexDirection: "column" }}>
        <List.ProposalDeal dealId={dealId} />
      </Row>
      <Flex gap={16}>
        <SolarzButton variant="secondary" scale="tiny" onClick={handleReset}>
          Cancelar
        </SolarzButton>
        <SolarzButton
          scale="tiny"
          onClick={handleWinDeal}
          isLoading={isLoading}
        >
          Ganhar Negócio
        </SolarzButton>
      </Flex>
    </Modal>
  );
}
