import {
  ISendProposalWhatsAppMessageFormProps,
  ISendProposalWhatsAppMessageFormValues,
} from "./interface";

import { Button } from "~components/Button";
import { CustomInput } from "~components/CustomInput";
import {
  useFetchGetProposalStep,
  useFetchGetProposalWhatsAppMessage,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { formatTelephoneWithDdd } from "~utils/format";
import { Flex, Form, Spin, Button as AntdButton, Alert } from "antd";

export function SendProposalWhatsAppMessageForm({
  projectId,
  formInstance,
  onCancel,
}: ISendProposalWhatsAppMessageFormProps) {
  const { message } = useAppConfig();
  const [form] =
    Form.useForm<ISendProposalWhatsAppMessageFormValues>(formInstance);

  const { isFetching: isLoadingWhatsAppMessage, error: whatsAppMessageError } =
    useFetchGetProposalWhatsAppMessage({
      payload: {
        customId: projectId,
      },
      dependencyArray: [projectId],
      options: {
        enabled: projectId > 0,
        onSuccess: (whatsAppMessage) => {
          form.setFieldValue("message", whatsAppMessage);
        },
        onError: (error) => {
          message.error(error.message);
        },
      },
    });

  const { isLoading: isLoadingPhoneNumber, error: phoneNumberError } =
    useFetchGetProposalStep({
      dependencyArray: [projectId],
      payload: {
        customId: projectId,
      },
      options: {
        enabled: projectId > 0,
        onSuccess: (data) => {
          form.setFieldValue(
            "clientPhoneNumber",
            data.client?.phone
              ? formatTelephoneWithDdd(data.client?.phone)
              : undefined,
          );
        },
      },
    });

  function handleCancel() {
    onCancel ? onCancel() : form.resetFields();
  }

  function handleSubmit({
    clientPhoneNumber,
    message,
  }: ISendProposalWhatsAppMessageFormValues) {
    const formattedPhoneNumber = clientPhoneNumber?.replaceAll(/[()-\s]/g, "");
    const formattedWhatsAppMessage = message?.replaceAll("\n", "%0A");

    const whatsAppUrl = `https://api.whatsapp.com/send?phone=55${formattedPhoneNumber}&text=${formattedWhatsAppMessage}`;

    window.open(whatsAppUrl, "_blank");
  }

  return (
    <Form form={form} onFinish={handleSubmit}>
      {whatsAppMessageError && (
        <Alert
          message={`Falha ao carregar mensagem do whatsApp: ${whatsAppMessageError.message}`}
          type="error"
        />
      )}

      {phoneNumberError && (
        <Alert
          message={`Falha ao carregar número do cliente: ${phoneNumberError.message}`}
          type="error"
        />
      )}

      <Flex
        align="start"
        justify="space-between"
        style={{ marginBottom: "1rem" }}
      >
        <Spin spinning={isLoadingPhoneNumber}>
          <Form.Item<ISendProposalWhatsAppMessageFormValues>
            name="clientPhoneNumber"
            label="Telefone do cliente"
            style={{ margin: 0 }}
            rules={[
              { required: true, message: "Número é obrigatório" },
              {
                min: 14,
                max: 15,
                message: "Número inválido",
              },
            ]}
          >
            <CustomInput.TelephoneWithDdd
              style={{ width: "100%" }}
              placeholder="(99) 99999-9999"
            />
          </Form.Item>
        </Spin>

        <Button.CopyToClipboard
          textToCopy={() => form.getFieldValue("message")}
        />
      </Flex>
      <Spin size="small" spinning={isLoadingWhatsAppMessage}>
        <Form.Item<ISendProposalWhatsAppMessageFormValues> name="message">
          <CustomInput.TextArea
            placeholder="Customize aqui sua mensagem para esta proposta"
            rows={15}
            style={{ maxHeight: "50vh" }}
          />
        </Form.Item>
      </Spin>

      <Flex align="center" justify="end" gap={8}>
        <AntdButton onClick={handleCancel}>Cancelar</AntdButton>
        <AntdButton htmlType="submit" type="primary">
          Enviar
        </AntdButton>
      </Flex>
    </Form>
  );
}
