import { useMemo, useState } from "react";

import { ISellerAutoCompleteProps } from "./interface";

import { AutoCompleteSuffixIcon } from "~components/Others";
import { Result } from "~components/Result";
import { useFetchGetSellersAutoComplete } from "~hooks/api";
import { createUuid } from "~utils/createUuid";
import { AutoComplete } from "antd";

export function SellerAutoComplete({
  allowClear = true,
  onChange,
  onClear,
  ...props
}: ISellerAutoCompleteProps) {
  const [inputValue, setInputValue] = useState<string>("");

  const CAN_LOAD_DATA = useMemo(() => inputValue.length >= 3, [inputValue]);

  const {
    data: sellers,
    isFetching,
    error,
    refetch,
  } = useFetchGetSellersAutoComplete({
    dependencyArray: [inputValue],
    payload: {
      term: inputValue,
    },
    options: {
      enabled: CAN_LOAD_DATA,
      retry: 1,
    },
  });

  const OPTIONS = useMemo(() => {
    return sellers
      ? sellers.map((seller) => ({
          label: seller,
          value: seller,
          record: seller,
          key: createUuid(),
        }))
      : [];
  }, [sellers]);

  const handleSearch = (value: string) => {
    setInputValue(value);
  };

  return (
    <AutoComplete
      {...props}
      data-cy="seller-auto-complete"
      showSearch
      filterOption={false}
      onSearch={handleSearch}
      options={OPTIONS}
      allowClear={allowClear}
      status={error ? "error" : ""}
      notFoundContent={
        <Result.SelectErrorOrEmpty
          errorMessage={error?.message}
          emptyMessage="Nenhum responsável encontrado"
          loading={isFetching}
          reloadFn={refetch}
          disableReload={!CAN_LOAD_DATA}
        />
      }
      suffixIcon={
        <AutoCompleteSuffixIcon hasError={!!error} isLoading={isFetching} />
      }
      onClear={() => {
        setInputValue("");
        if (typeof onClear === "function") {
          onClear();
        }
      }}
      onChange={(value, option) => {
        if (option instanceof Array || !onChange) return;
        onChange(value, option);
      }}
      style={{ width: "100%" }}
    />
  );
}
