import { api, apiException } from "~services/api";
import type { ClientForm } from "~types/api/ClientForm";
import type { Project } from "~types/api/Project";

export type FetchCreateProject = {
  body?: ClientForm;
};

export const fetchCreateProject = async ({ body }: FetchCreateProject) => {
  try {
    return await api.post<Project>(`/projects`, body);
  } catch (error) {
    throw apiException(error);
  }
};
