import {
  IConcessionaireSelectProps,
  SelectConcessionaireOptionType,
} from "./interface";

import { useFetchGetPaginatedConcessionaires } from "~hooks/api";
import { Select } from "antd";

export function ConcessionaireSelect(props: IConcessionaireSelectProps) {
  const { data: concessionaires } = useFetchGetPaginatedConcessionaires({
    payload: {
      page: 0,
      size: 200,
    },
  });

  const dataSource: SelectConcessionaireOptionType[] = concessionaires
    ? concessionaires.content.map((profile) => ({
        label: profile.name,
        value: profile.id,
      }))
    : [];
  return (
    <Select
      {...props}
      showSearch
      filterOption={(input, option) => {
        const label = option?.label?.toString() ?? "";
        return label?.toLowerCase().includes(input.toLowerCase());
      }}
      optionFilterProp="children"
      placeholder="Selecione a concessionaria..."
      options={dataSource}
      onFocus={(event: any) => {
        event.target.autocomplete = "do-not-autofill";
      }}
      style={{ ...props.style }}
    />
  );
}
