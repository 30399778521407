import { useMemo, useState } from "react";
import { BsFillGearFill, BsThreeDotsVertical } from "react-icons/bs";

import type {
  IMoreDropdownMenuItem,
  IMoreDropdownMenuProps,
} from "./interface";
import { StyledButton } from "./styles";

import { createUuid } from "~utils/createUuid";
import { Button, Dropdown, Flex, Tooltip, Typography } from "antd";

export function MoreDropdownMenu({
  items,
  isDisabled,
  position = "bottom",
  type = "link",
  tooltip = "",
  shape = "circle",
  icon = "dots",
}: IMoreDropdownMenuProps) {
  const MENU_KEY = useMemo(() => createUuid(), []);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const MENU_ITEMS = useMemo(() => {
    const parsedMenu = items.map((item, index) => {
      const key = `${MENU_KEY}_${index}`;

      return {
        key,
        label: item.customComponent ?? (
          <Tooltip title={item.tooltip} placement="left">
            <Button
              type="link"
              size="small"
              onClick={item.onClick}
              disabled={item.isDisabled || item.isLoading}
              style={{
                padding: "0rem 1rem",
                height: "2.125rem",
                width: "100%",
              }}
              block
              loading={item.isLoading}
            >
              {item.isLoading ? (
                item.text
              ) : (
                <Flex justify="start" align="center" wrap="nowrap" gap={8}>
                  {item.icon}
                  <Typography.Text
                    style={{ color: item.color ?? "#000", fontWeight: "500" }}
                  >
                    {item.text}
                  </Typography.Text>
                </Flex>
              )}
            </Button>
          </Tooltip>
        ),
        style: {
          padding: 0,
        },
        hidden: item.hidden,
        type: item.type,
      };
    });

    const filteredMenu: IMoreDropdownMenuItem[] = parsedMenu.filter(
      (item) => !item.hidden,
    );

    return filteredMenu;
  }, [MENU_KEY, items]);

  const handleVisibleChange = (visible: boolean) => {
    if (!isDisabled) {
      setDropdownVisible(visible);
    }
  };

  const Icon = useMemo(() => {
    switch (icon) {
      case "dots":
        return (
          <BsThreeDotsVertical
            size={14}
            strokeWidth={0.5}
            style={{ margin: "0 auto" }}
          />
        );
      case "cog":
        return <BsFillGearFill size={14} style={{ margin: "0 auto" }} />;
      default:
        return <BsThreeDotsVertical size={14} style={{ margin: "0 auto" }} />;
    }
  }, [icon]);

  const menuIsDisabled = isDisabled || items.length === 0;

  const anyItemLoading = items.some((item) => item.isLoading);

  return (
    <Dropdown
      data-cy="more-dropdown-menu"
      menu={{ items: MENU_ITEMS }}
      placement={position}
      arrow
      disabled={menuIsDisabled}
      trigger={["click"]}
      open={dropdownVisible || anyItemLoading}
      onOpenChange={handleVisibleChange}
    >
      <Tooltip title={tooltip}>
        <div>
          <StyledButton
            type={type}
            size="small"
            className="button"
            disabled={menuIsDisabled}
            shape={shape}
          >
            {Icon}
          </StyledButton>
        </div>
      </Tooltip>
    </Dropdown>
  );
}
