import { useCallback, useMemo } from "react";

import { useSearchParams } from "next/navigation";
import { useRouter } from "next/router";

import dayjs, { Dayjs, isDayjs } from "dayjs";
import { z } from "zod";

type ValueType = string | number | Dayjs | null | undefined;

type QueryParamAcceptableValue = string | number | null | undefined;

function cleanObject<T = any>(
  object: Record<string, ValueType>,
): Record<string, T> {
  return Object.entries(object).reduce(
    (cleanedObject, [key, value]: [string, any]) => {
      if (value !== null && value !== undefined) {
        cleanedObject[key] = value;
      }
      return cleanedObject;
    },
    {} as Record<string, T>,
  );
}

function parseValueToQueryParamAcceptableValue(
  value: ValueType | undefined,
): QueryParamAcceptableValue {
  if (!value) {
    return null;
  }

  if (isDayjs(value)) {
    return value.format("DD-MM-YYYY");
  }

  const valueAsNumber = Number(value);

  if (!isNaN(valueAsNumber)) {
    return valueAsNumber;
  }

  if (typeof value === "string") {
    return value;
  }

  return null;
}

export const parseFunctions = {
  BOOLEAN: (value: string) => {
    if (value === "true") {
      return true;
    }

    if (value === "false") {
      return false;
    }

    return null;
  },
  STRING: (value: string) => {
    try {
      return z
        .string()
        .nullish()
        .transform((val) => val ?? null)
        .parse(value);
    } catch (e: any) {
      return null;
    }
  },
  NUMBER: (value: string) => {
    const parsedValue = Number(value);
    return !isNaN(parsedValue) ? parsedValue : null;
  },
  DAYJS: (value: string /* EXPECTED FORMAT DD-MM-YYYY */) => {
    try {
      const [day, month, year] = value.split("-");

      const date = dayjs(`${year}-${month}-${day}`);

      return date.isValid() ? date : null;
    } catch (e: any) {
      return null;
    }
  },
} as const;

export function useQueryParams() {
  const router = useRouter();
  const searchParams = useSearchParams();

  const currentQueryParams = useMemo(() => {
    const queryParamsString = searchParams?.toString(); // Ex.: date=28-02-2024&username=Edvaldo

    if (!queryParamsString) {
      return {};
    }

    const queryParamsKeyValueString = queryParamsString.split("&"); // Output: [date=28-02-2024, username=Edvaldo]

    const queryParams = queryParamsKeyValueString.reduce(
      (queryParamsObject, keyValueString: string) => {
        const [key, value] = keyValueString.split("="); // Entry = date=28-02-2024 | Output: { date: "28-02-2024" }

        return {
          ...queryParamsObject,
          [key]: value,
        };
      },
      {} as Record<string, string>,
    );

    return queryParams;
  }, [searchParams]);

  const handleSaveInQueryParams = useCallback(
    <T extends Record<string, ValueType>>(
      newQueryObject: Partial<T> = {},
      options?: {
        preventsHistoryUpdate: boolean;
      },
    ) => {
      const formattedNewQueryObject = Object.entries(newQueryObject).reduce(
        (queryObject, [key, value]) => {
          if (Array.isArray(value)) {
            const formattedArrayValue = value
              .map((val) => parseValueToQueryParamAcceptableValue(val))
              .join(",");
            queryObject[key] = formattedArrayValue;
          } else {
            const parsedValue = parseValueToQueryParamAcceptableValue(value);
            queryObject[key] = parsedValue ?? null;
          }
          return queryObject;
        },
        {} as Record<string, QueryParamAcceptableValue>,
      );

      const updatedQueryParams = cleanObject<QueryParamAcceptableValue>({
        ...currentQueryParams,
        ...formattedNewQueryObject,
      });

      const basePathName = router.asPath.split("?")[0];

      options?.preventsHistoryUpdate
        ? router.replace(
            {
              pathname: basePathName,
              query: updatedQueryParams,
            },
            undefined,
            {
              shallow: true,
            },
          )
        : router.push(
            {
              pathname: basePathName,
              query: updatedQueryParams,
            },
            undefined,
            {
              shallow: true,
            },
          );
    },
    [currentQueryParams, router],
  );

  const getParsedQueryParams = useCallback(
    <T>(key: string, parseFn: (value: string) => T) => {
      const value = currentQueryParams[key];

      if (!value) return null;

      return parseFn(value);
    },
    [currentQueryParams],
  );

  const memoizedValues = useMemo(
    () => ({
      currentQueryParams,
      handleSaveInQueryParams,
      getParsedQueryParams,
      isRouterReady: router.isReady,
    }),
    [
      currentQueryParams,
      getParsedQueryParams,
      handleSaveInQueryParams,
      router.isReady,
    ],
  );

  return memoizedValues;
}
