import type { IOrganizationDealsResumeInfoCardProps } from "./interface";
import { CreateButton } from "./styles";

import { Alert } from "~components/Alert";
import { List } from "~components/List";
import { Modal } from "~components/Modal";
import { Add } from "~estrela-ui/icons";
import { useFetchGetOrganizationDealsCountByOrganizationId } from "~hooks/api";
import { parseFunctions, useQueryParams } from "~hooks/useQueryParams";
import { useSession } from "~hooks/useSession";
import { SolarzPercentageRowGraph } from "~solarzui/SolarzPercentageRowGraph";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { parseCurrency, parsePercentage } from "~utils/parse";
import { Badge, Card, Col, Flex, Row, Skeleton } from "antd";

const CREATE_DEAL_MODAL_PARAM_KEY = "create-deal";

export function OrganizationDealsResumeInfoCard({
  organizationId,
  organizationName = "",
  style,
}: IOrganizationDealsResumeInfoCardProps) {
  const { user } = useSession();
  const { handleSaveInQueryParams, getParsedQueryParams } = useQueryParams();

  const isCreateDealModalOpen = !!getParsedQueryParams(
    CREATE_DEAL_MODAL_PARAM_KEY,
    parseFunctions.BOOLEAN,
  );

  function handleOpenCreateDealModal() {
    handleSaveInQueryParams({ [CREATE_DEAL_MODAL_PARAM_KEY]: "true" });
  }

  function handleCloseCreateDealModal() {
    handleSaveInQueryParams({ [CREATE_DEAL_MODAL_PARAM_KEY]: "" });
  }

  const {
    data: organizationDealsCount,
    isFetching: isLoadingOrganizationDealsCount,
    error: organizationDealsCountError,
    refetch: reloadOrganizationDealsCount,
  } = useFetchGetOrganizationDealsCountByOrganizationId({
    payload: {
      organizationId,
    },
    dependencyArray: [organizationId],
    options: {
      enabled: organizationId > 0,
    },
  });

  const isLoading = organizationId === 0 || isLoadingOrganizationDealsCount;

  return (
    <>
      <Card
        style={{
          width: "100%",
          maxWidth: "400px",
          ...style,
        }}
        styles={{
          body: {
            padding: 16,
          },
        }}
        title={
          <Flex justify="space-between" align="center">
            <SolarzTypography
              fontWeight="semiBold"
              hierarchy="paragraph1"
              variant="title"
            >
              Negócios
            </SolarzTypography>

            <CreateButton
              icon={<Add className="h-4 w-4" />}
              scale="tiny"
              variant="ghost"
              onClick={handleOpenCreateDealModal}
              isDisabled={organizationId === 0}
            />
          </Flex>
        }
      >
        <Flex vertical gap={16}>
          {organizationDealsCountError && (
            <Alert.CardError
              title="Falha ao carregar dados"
              errorMessage={organizationDealsCountError?.message}
              reloadFn={reloadOrganizationDealsCount}
            />
          )}

          <SolarzTypography
            hierarchy="paragraph2"
            fontWeight="semiBold"
            variant="title"
            style={{ display: "flex", gap: 8, alignItems: "center" }}
          >
            Em aberto{" "}
            {isLoading || organizationDealsCountError ? (
              <Skeleton.Node
                style={{ height: 16, width: 22 }}
                active={isLoadingOrganizationDealsCount}
              >
                {" "}
              </Skeleton.Node>
            ) : (
              <SolarzTypography hierarchy="small" fontWeight="regular">
                ({organizationDealsCount?.openDealsCount})
              </SolarzTypography>
            )}
          </SolarzTypography>

          <List.OrganizationDeals organizationId={organizationId} />

          <SolarzTypography
            hierarchy="paragraph2"
            fontWeight="semiBold"
            variant="title"
            style={{ display: "flex", gap: 8, alignItems: "center" }}
          >
            Fechados{" "}
            {isLoading || organizationDealsCountError ? (
              <Skeleton.Node
                style={{ height: 16, width: 22 }}
                active={isLoadingOrganizationDealsCount}
              >
                {" "}
              </Skeleton.Node>
            ) : (
              <SolarzTypography hierarchy="small" fontWeight="regular">
                ({organizationDealsCount?.closedDealsCount})
              </SolarzTypography>
            )}
          </SolarzTypography>

          {isLoading || organizationDealsCountError ? (
            <Flex vertical gap={16}>
              <Skeleton.Node
                style={{ height: 32, width: "100%", marginBottom: 12 }}
                active={isLoadingOrganizationDealsCount}
              >
                {" "}
              </Skeleton.Node>
              <Skeleton.Node
                style={{ height: 24, width: "100%" }}
                active={isLoadingOrganizationDealsCount}
              >
                {" "}
              </Skeleton.Node>
              <Skeleton.Node
                style={{ height: 24, width: "100%" }}
                active={isLoadingOrganizationDealsCount}
              >
                {" "}
              </Skeleton.Node>
            </Flex>
          ) : (
            <SolarzPercentageRowGraph
              content={[
                {
                  label: "Ganho",
                  absoluteValue: organizationDealsCount?.wonDealsCount ?? 0,
                  color: "green",
                  record: {
                    totalAbsoluteValue:
                      organizationDealsCount?.totalValueWon ?? 0,
                  },
                },
                {
                  label: "Perda",
                  absoluteValue: organizationDealsCount?.lostDealsCount ?? 0,
                  color: "red",
                  record: {
                    totalAbsoluteValue:
                      organizationDealsCount?.totalValueLost ?? 0,
                  },
                },
                {
                  label: "Aberto",
                  absoluteValue: organizationDealsCount?.openDealsCount ?? 0,
                  color: "gold",
                  record: {
                    totalAbsoluteValue:
                      organizationDealsCount?.totalValueOpen ?? 0,
                  },
                },
              ]}
              summaryItemRender={(item, index) => {
                return (
                  <Row key={index}>
                    <Col span={6}>
                      <Flex gap={6}>
                        <Badge
                          color={item.color}
                          styles={{
                            indicator: {
                              height: 10,
                              width: 10,
                              borderRadius: 2,
                            },
                          }}
                        />
                        <SolarzTypography
                          hierarchy="paragraph2"
                          fontWeight="regular"
                          variant="default"
                        >
                          {item.label}
                        </SolarzTypography>
                      </Flex>
                    </Col>

                    <Col span={6}>
                      <SolarzTypography
                        hierarchy="paragraph2"
                        fontWeight="regular"
                        variant="default"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {item.absoluteValue}
                      </SolarzTypography>
                    </Col>

                    <Col span={6}>
                      <SolarzTypography
                        hierarchy="paragraph2"
                        fontWeight="regular"
                        variant="default"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {parsePercentage(item.relativeValue, "pt-BR", 0)}
                      </SolarzTypography>
                    </Col>

                    <Col span={6}>
                      <SolarzTypography
                        hierarchy="paragraph2"
                        fontWeight="regular"
                        variant="default"
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {parseCurrency(item.record.totalAbsoluteValue)}
                      </SolarzTypography>
                    </Col>
                  </Row>
                );
              }}
              tooltipItemRender={(item) => {
                return `Total ${parseCurrency(item.record.totalAbsoluteValue)}`;
              }}
            />
          )}
        </Flex>
      </Card>

      <Modal.DealForm
        isOpen={isCreateDealModalOpen}
        onClose={handleCloseCreateDealModal}
        formProps={{
          onSuccess: handleCloseCreateDealModal,
          disableFieldOrganization: Number(organizationId) > 0,
          initialValues: {
            organizationId,
            ownerId: user?.id,
            name: organizationName ? `Negócio ${organizationName}` : undefined,
          },
        }}
      />
    </>
  );
}
