import { useState } from "react";
import { AiOutlineUser } from "react-icons/ai";

import { IProfilesPermissionsListProps } from "./interface";
import { StyledList } from "./styles";

import { Drawer } from "~components/Drawer";
import { createPaginationTableObject } from "~components/Pagination";
import { Result } from "~components/Result";
import { useFetchGetPaginatedProfiles } from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import type { IUserManagementDTOSchema } from "~types/schemas";
import { Flex, List, Tag, Tooltip } from "antd";

function getTooltipTitle(users: IUserManagementDTOSchema[], LIMIT = 5) {
  if (users.length === 0) {
    return "Nenhum usuário associado";
  }

  if (users.length < LIMIT) {
    return users
      .slice(0, users.length)
      .map((user) => user.name)
      .join("\n");
  }

  const difference = users.length - LIMIT;

  return (
    users
      .slice(0, LIMIT)
      .map((user) => user.name)
      .join("\n") + `\n... e outros ${difference} usuários`
  );
}

export function ProfilesPermissionsList(props: IProfilesPermissionsListProps) {
  const { screens } = useAppConfig();

  const [currentPage, setCurrentPage] = useState(0);

  const [selectedProfileId, setSelectedProfileId] = useState<number | null>(
    null,
  );

  function handleSelectProfileId(id: number) {
    setSelectedProfileId(id);
  }

  function handleUnselectProfileId() {
    setSelectedProfileId(null);
  }

  const {
    data: profiles,
    isFetching: isLoadingProfiles,
    error: profilesError,
    refetch: reloadProfiles,
  } = useFetchGetPaginatedProfiles({
    dependencyArray: [currentPage],
    payload: {
      page: currentPage,
      size: 5,
    },
    options: {
      retry: 1,
    },
  });

  function onPageChange(page: number) {
    setCurrentPage(page);
  }

  return (
    <>
      <StyledList
        {...props}
        data-cy="profiles-permissions-list"
        rowKey="id"
        dataSource={profiles?.content}
        loading={isLoadingProfiles}
        locale={{
          emptyText: (
            <Result.TableErrorOrEmpty
              emptyMessage="Nenhum perfil de permissão encontrado"
              errorMessage={profilesError?.message}
              loading={isLoadingProfiles}
              reloadFn={reloadProfiles}
            />
          ),
        }}
        pagination={createPaginationTableObject({
          size: profiles?.size,
          number: profiles?.number ? profiles?.number + 1 : 1,
          itemType: "Perfis",
          totalElements: profiles?.totalElements,
          onChange: (page) => onPageChange(page - 1),
          displayTotalInformation: screens.sm,
        })}
        renderItem={(profile) => {
          const usersCount = profile.users.length;

          const tooltipTitle = getTooltipTitle(profile.users ?? []);

          return (
            <List.Item
              key={profile.id}
              style={{ width: "100%" }}
              onClick={() => handleSelectProfileId(profile.id)}
              data-cy="list-item"
              className={
                selectedProfileId === profile.id ? "active" : undefined
              }
            >
              <List.Item.Meta
                title={
                  <Flex align="center" gap={4}>
                    {profile.name}
                    {profile.settings.default && (
                      <Tag
                        color="volcano"
                        bordered={false}
                        style={{
                          padding: 8,
                          lineHeight: 0,
                          borderRadius: 9999,
                        }}
                      >
                        Gerado pelo sistema
                      </Tag>
                    )}
                  </Flex>
                }
                description={profile.description || "Sem descrição..."}
              />
              <Tooltip
                title={tooltipTitle}
                placement="left"
                overlayStyle={{ whiteSpace: "pre-line" }}
              >
                <Flex align="center" gap={2}>
                  <AiOutlineUser data-cy="button-profile" size={18} />{" "}
                  {usersCount}
                </Flex>
              </Tooltip>
            </List.Item>
          );
        }}
      />

      <Drawer.Permission
        isOpen={!!selectedProfileId}
        onClose={handleUnselectProfileId}
        profileId={selectedProfileId ?? 0}
      />
    </>
  );
}
