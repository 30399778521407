import type { IPersonDefaultDataFieldFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function PersonDefaultDataFieldFormModal({
  isOpen = false,
  onClose = () => null,
  width = "90vw",
  formProps = {
    initialValues: undefined,
  },
}: IPersonDefaultDataFieldFormModalProps) {
  return (
    <Modal
      data-cy="person-default-field-form-modal"
      open={isOpen}
      onCancel={onClose}
      title="Editar campo"
      width={width}
      style={{ maxWidth: 890 }}
      footer={null}
      destroyOnClose
    >
      <Form.PersonDefaultDataField {...formProps} onCancel={onClose} />
    </Modal>
  );
}
