import { useState } from "react";

import type {
  PipedriveDealSearchSelectRecordType,
  IPipedriveDealSearchSelectProps,
} from "./interface";

import { Company } from "~estrela-ui/icons";
import { useFetchGetSearchPipedriveDeal } from "~hooks/api";
import { SolarzSelect } from "~solarzui/SolarzSelect";
import { Flex } from "antd";

export function PipedriveDealSearchSelect(
  props: IPipedriveDealSearchSelectProps,
) {
  const [searchValue, setSearchValue] = useState("");

  const canMakeRequest = searchValue.length >= 3;

  const {
    data = [],
    isFetching,
    error,
    refetch,
  } = useFetchGetSearchPipedriveDeal({
    dependencyArray: [searchValue],
    payload: {
      term: searchValue,
    },
    options: {
      enabled: canMakeRequest,
      retry: false,
    },
  });

  return (
    <SolarzSelect<PipedriveDealSearchSelectRecordType, "pipedriveId">
      {...props}
      searchValue={props.searchValue ?? searchValue}
      onChangeSearch={(value) => {
        setSearchValue(value);

        if (typeof props.onChangeSearch === "function") {
          props.onChangeSearch(value);
        }
      }}
      options={data.map((option) => {
        return {
          label: (
            <Flex align="center" gap={8}>
              <Company />
              <span>
                {option.pipedriveId} - {option.title}
              </span>
            </Flex>
          ),
          record: option,
          value: option.pipedriveId,
        };
      })}
      isLoading={isFetching}
      refetchFn={canMakeRequest ? refetch : undefined}
      errorMessage={error?.message}
      emptyMessage={
        canMakeRequest
          ? "Nenhum negócio encontrado"
          : "Digite ao menos 3 letras para buscar"
      }
      showSearch
    />
  );
}
