import NextImage from "next/image";

import type { IForbiddenResult } from "./interface";

import { Result } from "antd";

export function ForbiddenResult({
  message = "Você não possui permissão para acessar esse conteúdo",
}: IForbiddenResult) {
  return (
    <Result
      data-cy="forbidden-result"
      title="Sem permissão"
      icon={
        <NextImage
          src="/images/forbidden.svg"
          width={386}
          height={306}
          alt="403 - Proibido"
          style={{ margin: "0 auto" }}
        />
      }
      status="error"
      subTitle={message}
    />
  );
}
