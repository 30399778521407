import { useEffect, useMemo } from "react";

import type {
  IDealCustomFieldsFormProps,
  IDealCustomFieldsFormValues,
} from "./interface";

import { Alert } from "~components/Alert";
import { Result } from "~components/Result";
import {
  useFetchGetDealByCustomId,
  useFetchGetDealCustomFieldsByPipelineStageId,
  useFetchUpdateDealCustomFieldValue,
} from "~hooks/api";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzFormItem } from "~solarzui/SolarzFormItem";
import { SolarzInput } from "~solarzui/SolarzInput";
import { SolarzInputNumber } from "~solarzui/SolarzInputNumber";
import { SolarzSelect } from "~solarzui/SolarzSelect";
import { FieldTypeEnum } from "~types/enum";
import { IDealCustomFieldDTOSchema } from "~types/schemas";
import { DatePicker, Flex, Form, Input, Radio, Skeleton } from "antd";
import dayjs, { Dayjs } from "dayjs";

function parseDateStringToDayJs(dateString?: string): Dayjs | undefined {
  if (!dateString) return undefined;

  const [day, month, year] = dateString
    .split("/")
    .map((value) => Number(value)); // expected format DD/MM/YYYY

  if (isNaN(day) || isNaN(month) || isNaN(year)) return undefined;

  const formattedString = `${month}/${day}/${year}`;

  return dayjs(formattedString);
}

const FIELDS_MAP = {
  BOOLEAN: (
    <Radio.Group
      options={[
        {
          label: "Sim",
          value: true,
        },
        {
          label: "Não",
          value: false,
        },
      ]}
    />
  ),
  NUMBER: (
    <SolarzInputNumber
      placeholder="Digite o valor numérico"
      style={{ width: "100%" }}
      scale="large"
    />
  ),
  TEXT_AREA: (
    <Input.TextArea
      placeholder="Digite o texto..."
      style={{ width: "100%", maxHeight: 120 }}
      maxLength={255}
    />
  ),
  TEXT: (
    <SolarzInput
      placeholder="Digite o texto..."
      scale="large"
      style={{ width: "100%" }}
    />
  ),
  SELECT: (
    <SolarzSelect mode="tags" placeholder="Digite as opções..." allowClear />
  ),
  DATE: (
    <DatePicker
      format="DD/MM/YYYY"
      allowClear
      style={{ height: 42, width: "100%" }}
      placeholder="Selecione a data..."
    />
  ),
} as { [key in FieldTypeEnum]: JSX.Element };

function getInputComponentByType(type?: string) {
  return type ? (FIELDS_MAP as Record<string, JSX.Element>)?.[type] : <></>;
}

export function DealCustomFieldsForm({
  dealId,
  onCancel,
  onError,
  onSuccess,
  formInstance,
}: IDealCustomFieldsFormProps) {
  const [form] = Form.useForm(formInstance);

  const {
    data: deal,
    isFetching: isLoadingDeal,
    error: errorDeal,
    refetch: reloadDeal,
  } = useFetchGetDealByCustomId({
    payload: {
      customId: dealId,
    },
    dependencyArray: [dealId],
    options: {
      enabled: dealId > 0,
    },
  });

  const {
    data: dealCustomFieldGroups = [],
    isFetching: isLoadingDealCustomFieldGroups,
    error: errorDealCustomFieldGroups,
    refetch: reloadDealCustomFieldGroups,
  } = useFetchGetDealCustomFieldsByPipelineStageId({
    payload: {
      pipelineStageId: deal?.pipelineStage?.id ?? 0,
    },
    dependencyArray: [deal?.pipelineStage?.id],
    options: {
      enabled: !!deal?.pipelineStage?.id && deal.pipelineStage.id > 0,
    },
  });

  const {
    mutate: updateCustomFieldsValue,
    isLoading: isUpdatingCustomFieldsValue,
  } = useFetchUpdateDealCustomFieldValue({
    options: {
      onError,
      onSuccess,
    },
  });

  function handleCancel() {
    if (typeof onCancel === "function") {
      return onCancel();
    }
  }

  function handleSubmit(formValues: IDealCustomFieldsFormValues) {
    const customFieldValues = formValues.customFieldsDealCustomFields.map(
      (field) => {
        let value: string | boolean | number | Dayjs = field.value ?? "";

        if (typeof value === "string") {
          const parsedValueDayJs = dayjs(
            value,
            ["YYYY-MM-DD", "MM/DD/YYYY", "DD/MM/YYYY"],
            true,
          );
          value = parsedValueDayJs.isValid()
            ? parsedValueDayJs.format("DD/MM/YYYY")
            : value;
        } else if (dayjs.isDayjs(value)) {
          value = value.format("DD/MM/YYYY");
        } else if (typeof value === "boolean") {
          value = value ? "true" : "false";
        } else if (typeof value === "number") {
          value = String(value);
        } else {
          value = "";
        }

        return {
          dealCustomFieldId: field.dealCustomFieldId ?? 0,
          value,
        };
      },
    );

    updateCustomFieldsValue({ id: dealId, customFieldValues });
  }

  const customFields = useMemo(() => {
    const allCustomFields = dealCustomFieldGroups.reduce(
      (dealCustomFields, group) => {
        return [...dealCustomFields, ...group.dealCustomFields];
      },
      [] as IDealCustomFieldDTOSchema[],
    );

    return allCustomFields;
  }, [dealCustomFieldGroups]);

  // SET INITIAL VALUES
  useEffect(() => {
    if (deal && customFields.length > 0) {
      const customFieldsDealCustomFields = customFields.map((customField) => {
        const dealCustomField = deal.dealCustomFieldValues.find(
          (dealCustomField) =>
            dealCustomField.dealCustomFieldId === customField.id,
        );

        let value: string | boolean | Dayjs | undefined =
          dealCustomField?.value || customField.defaultValue || undefined;

        if (customField.type === "DATE") {
          value = parseDateStringToDayJs(value as string);
        } else if (customField.type === "BOOLEAN") {
          if (value !== "true" && value !== "false") {
            value = undefined;
          } else {
            value = value === "true";
          }
        }

        return {
          dealCustomFieldId:
            dealCustomField?.dealCustomFieldId || customField.id,
          value,
        };
      });

      form.setFieldsValue({ customFieldsDealCustomFields });
    }
  }, [deal, customFields, form]);

  const loadingInputElementsArray = new Array(5).fill(null);

  const isLoading = isLoadingDeal || isLoadingDealCustomFieldGroups;
  const error = errorDeal || errorDealCustomFieldGroups;
  const reloadFn =
    isLoadingDeal || errorDeal ? reloadDeal : reloadDealCustomFieldGroups;

  return (
    <Form
      data-cy="deal-custom-fields-form"
      form={form}
      onFinish={handleSubmit}
      preserve
      layout="vertical"
    >
      {error && (
        <Alert.CardError
          errorMessage={error.message}
          reloadFn={reloadFn}
          title="Falha ao carregar campos"
        />
      )}

      {isLoading &&
        loadingInputElementsArray.map((_, index) => {
          return (
            <div key={`skeleton_loading_${index}`} className="flex flex-col">
              <Skeleton.Input
                key={index}
                active
                rootClassName="mb-4"
                style={{ width: "100%" }}
                size="large"
              />
            </div>
          );
        })}

      {customFields.length === 0 && !isLoading && (
        <Result.TableErrorOrEmpty
          reloadFn={reloadFn}
          disableReload={dealId <= 0}
          emptyMessage="Nenhuma variável customizada de negócio foi encontrada"
          loading={isLoading}
        />
      )}

      {!isLoading &&
        customFields.map((field, index) => {
          return (
            <div key={`custom-field-${index}`}>
              <SolarzFormItem<IDealCustomFieldsFormValues>
                name={[
                  "customFieldsDealCustomFields",
                  index,
                  "dealCustomFieldId",
                ]}
                preserve
                hidden
              >
                <SolarzInputNumber readOnly />
              </SolarzFormItem>
              <SolarzFormItem<IDealCustomFieldsFormValues>
                name={["customFieldsDealCustomFields", index, "value"]}
                label={field.label}
                preserve
                key={field.id}
                rules={[
                  {
                    required: !!field.required,
                  },
                ]}
              >
                {field.type === "SELECT" ? (
                  <SolarzSelect
                    options={field.options.map((option) => {
                      return {
                        label: option,
                        value: option,
                        record: {
                          value: option,
                        },
                      };
                    })}
                    placeholder="Selecione..."
                    allowClear
                    emptyMessage="Nenhuma opção cadastrada"
                    scale="large"
                  />
                ) : (
                  getInputComponentByType(field.type)
                )}
              </SolarzFormItem>
            </div>
          );
        })}

      <Flex align="center" gap={16} justify="flex-end">
        <SolarzButton variant="secondary" scale="tiny" onClick={handleCancel}>
          Cancelar
        </SolarzButton>
        <SolarzButton
          scale="tiny"
          htmlType="submit"
          isLoading={isUpdatingCustomFieldsValue}
          isDisabled={isLoading || !!error}
        >
          Salvar
        </SolarzButton>
      </Flex>
    </Form>
  );
}
