import type { IAdminDatasheetRevisionsTableModalProps } from "./interface";

import { Table } from "~components/Table";
import { Modal } from "antd";

export function AdminDatasheetRevisionsTableModal({
  datasheetId,
  isOpen = false,
  onClose = () => undefined,
  title = "Revisões do Datasheet",
  maxWidth = 768,
  tableProps,
}: IAdminDatasheetRevisionsTableModalProps) {
  return (
    <Modal
      data-cy="admin-datasheet-revisions-table-modal"
      width="90vw"
      title={title}
      footer={null}
      destroyOnClose
      open={isOpen}
      onCancel={onClose}
      style={{ maxWidth }}
    >
      <Table.AdminDatasheetRevisions
        {...tableProps}
        datasheetId={datasheetId}
      />
    </Modal>
  );
}
