const WORDS = {
  Configuracoes: "Configurações",
  Endereco: "Endereço",
  Gerenciamento: "Gerenciamento",
  Regras: "Regras",
  Simulacao: "Simulação",
  Unidades: "Unidades",
  Usuarios: "Usuários",
  Integracao: "Integração",
  Precificacoes: "Precificações",
  "Status das integracoes": "Status das integrações",
} as Record<string, string>;

export function parseUrlPathToAccentedWord(value = "") {
  return WORDS[value] ?? value;
}
