import type { ISolarzTypographyProps } from "./interface";
import { StyledTypography } from "./styles";

export function SolarzTypography({
  children,
  fontWeight = "regular",
  hierarchy = "paragraph1",
  style,
  variant = "default",
  ellipse = false,
}: ISolarzTypographyProps) {
  return (
    <StyledTypography
      hierarchy={hierarchy}
      variant={variant}
      fontWeight={fontWeight}
      style={style}
      className={ellipse ? "ellipse" : undefined}
    >
      {children}
    </StyledTypography>
  );
}
