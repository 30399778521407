import { useState } from "react";

import { PaymentSimulationEditableTypographyProps } from "./interface";

import { useFetchUpdatePaymentSimulation } from "~hooks/api";
import { Spin, Typography } from "antd";

export function PaymentSimulationEditableTypography({
  initialValue,
  simulationId,
  ...props
}: PaymentSimulationEditableTypographyProps) {
  const [nameSimulationGroup, setNameSimulationGroup] = useState(initialValue);

  const { mutate: fetchUpdateSimulationGroup, isLoading: isLoadingNameGroup } =
    useFetchUpdatePaymentSimulation({
      options: {
        onSuccess: (data) => {
          setNameSimulationGroup(data.name);
        },
      },
    });

  const handleEditNameSimulationGroup = async (e: string) => {
    fetchUpdateSimulationGroup({ name: e, id: simulationId });
  };

  return (
    <Spin
      data-cy="payment-simulation-editable-typography"
      spinning={isLoadingNameGroup}
      size="small"
    >
      <Typography.Title
        level={4}
        editable={{
          tooltip: "Editar nome da simulação",
          onChange: (e) => handleEditNameSimulationGroup(e),
        }}
        {...props}
      >
        {nameSimulationGroup}
      </Typography.Title>
    </Spin>
  );
}
