import { useState } from "react";
import { BsPencil, BsTrash3 } from "react-icons/bs";

import { useRouter } from "next/router";

import { IPricingListProps } from "./interface";
import { StyledList } from "./styles";

import { Modal } from "~components/Modal";
import { Result } from "~components/Result";
import {
  useFetchDeletePricingTypes,
  useFetchGetAllPricingTypes,
  useFetchUpdatePricingTypeInUse,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { SolarzButton } from "~solarzui/SolarzButton";
import { IPricingTypeDTOSchema } from "~types/schemas";
import { Flex, List, Tooltip } from "antd";

export function PricingList(props: IPricingListProps) {
  const router = useRouter();
  const { message } = useAppConfig();

  const [pricingToDelete, setPricingToDelete] =
    useState<IPricingTypeDTOSchema>();

  const [pricingToUpdate, setPricingToUpdate] =
    useState<IPricingTypeDTOSchema>();

  const {
    data: pricing,
    isFetching: isLoadingPricing,
    error: pricingError,
    refetch: reloadPricing,
  } = useFetchGetAllPricingTypes({});

  const { mutate: fetchDeletePricingType, isLoading: isDeletingPricing } =
    useFetchDeletePricingTypes({
      onSuccess: () => {
        setPricingToDelete(undefined);
      },
    });

  async function handleDeletePricingTypes(pricingId: number) {
    if (pricingId > 0) {
      fetchDeletePricingType({
        pricingId,
      });
    }
  }

  const { mutate: updatePricingType } = useFetchUpdatePricingTypeInUse({
    onSuccess() {
      router.push(
        "/configuracoes-de-propostas/custos-complementares?tab=itens-complementares",
      );
    },
  });

  function updatePricingAndRedirectToCost(pricingTypeId: number) {
    try {
      updatePricingType({ pricingTypeId });
    } catch (error: any) {
      message.error("Erro ao visualizar precificação:", error?.message);
    }
  }

  return (
    <>
      <StyledList
        {...props}
        data-cy="pricing-list"
        rowKey="id"
        dataSource={pricing}
        loading={isLoadingPricing}
        locale={{
          emptyText: (
            <Result.TableErrorOrEmpty
              emptyMessage="Nenhuma precificações encontrado"
              errorMessage={pricingError?.message}
              loading={isLoadingPricing}
              reloadFn={reloadPricing}
            />
          ),
        }}
        renderItem={(item, index) => {
          const isFirstItem = index === 0;
          return (
            <List.Item
              key={item.id}
              style={{
                width: "100%",
                borderTop: isFirstItem ? "1px  solid #0505050f" : "none",
              }}
              data-cy="list-item"
            >
              <List.Item.Meta title={item.name} />
              <Tooltip
                placement="left"
                overlayStyle={{ whiteSpace: "pre-line" }}
              >
                <Flex align="center" gap={12}>
                  <SolarzButton
                    variant="secondary"
                    onClick={() => updatePricingAndRedirectToCost(item.id)}
                  >
                    Visualizar
                  </SolarzButton>
                  <SolarzButton
                    variant="secondary"
                    icon={<BsPencil size={10} />}
                    onClick={() => setPricingToUpdate(item)}
                  />
                  <SolarzButton
                    style={{ borderColor: "var(--red-500)" }}
                    variant="secondary"
                    icon={<BsTrash3 size={10} color={"var(--red-500)"} />}
                    onClick={() => setPricingToDelete(item)}
                  />
                </Flex>
              </Tooltip>
            </List.Item>
          );
        }}
      />
      <Modal.PricingForm
        isOpen={!!pricingToUpdate}
        onClose={() => setPricingToUpdate(undefined)}
        formProps={{
          initialValues: pricingToUpdate,
          onSuccess: () => setPricingToUpdate(undefined),
        }}
      />
      <Modal.DefaultRemove
        isLoading={isDeletingPricing}
        isOpen={!!pricingToDelete}
        itemName={pricingToDelete?.name}
        onClose={() => setPricingToDelete(undefined)}
        suffix="tipo de precificação"
        onSubmit={() => handleDeletePricingTypes(pricingToDelete?.id || 0)}
        title={`Remover precificação`}
      />
    </>
  );
}
