import { ValidationRuleFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function ValidationRuleFormModal({
  isOpen = false,
  onClose = () => undefined,
  maxWidth = 768,
  formProps,
}: ValidationRuleFormModalProps) {
  return (
    <Modal
      data-cy="validation-rule-form-modal"
      open={isOpen}
      onCancel={onClose}
      title="Regra de validação"
      width="90vw"
      footer={null}
      style={{ maxWidth }}
      destroyOnClose
    >
      <Form.ValidationRule {...formProps} onCancel={onClose} />
    </Modal>
  );
}
