import { useMemo } from "react";

import type {
  IPricingTypeOfProposalSelectProps,
  PricingOfProposalValueType,
} from "./interface";

import { useFetchGetAllPricingTypes } from "~hooks/api";
import { SolarzSelect } from "~solarzui/SolarzSelect";

export function PricingTypeOfProposalSelect({
  projectType,
  onLoad,
  ...props
}: IPricingTypeOfProposalSelectProps) {
  const {
    data: pricing = [],
    isFetching: isLoadingPricing,
    error: errorPricing,
    refetch: refetchPricing,
  } = useFetchGetAllPricingTypes({
    payload: { projectType },
    dependencyArray: [projectType],
    options: {
      onSuccess: onLoad,
      enabled: !!projectType,
    },
  });

  const options = useMemo(() => {
    return pricing.map((item) => ({
      label: item.name,
      value: item.id,
      record: item,
    }));
  }, [pricing]);

  return (
    <SolarzSelect<PricingOfProposalValueType, "id">
      {...props}
      options={options}
      allowClear={false}
      isLoading={isLoadingPricing}
      errorMessage={errorPricing?.message}
      emptyMessage="Nenhum dado encontrado"
      refetchFn={refetchPricing}
    />
  );
}
