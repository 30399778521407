import { CSSProperties } from "react";

import type { LogActionTypeEnum, LogEntityTypeEnum } from "~types/enum";
import type { Dayjs } from "dayjs";

export type AdminLogsTableFiltersType = {
  userName: string | null;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  entity: LogEntityTypeEnum | null;
  action: LogActionTypeEnum | null;
  currentPage: number;
};

export type AdminLogsFormFiltersType = {
  entity: AdminLogsTableFiltersType["entity"];
  action: AdminLogsTableFiltersType["action"];
};

export enum AdminLogsTableQueryParamKeysEnum {
  USER_NAME = "userName",
  START_DATE = "startDate",
  END_DATE = "endDate",
  CURRENT_PAGE = "page",
  ENTITY = "entity",
  ACTION = "action",
}

export type AdminLogsTableQueryParamsKeysType = Record<
  keyof typeof AdminLogsTableQueryParamKeysEnum,
  string
>;

export interface IAdminLogsTableProps {
  defaultFilters?: Partial<AdminLogsTableFiltersType>;
  displayFiltersComponent?: boolean;
  queryParamKeys?: AdminLogsTableQueryParamsKeysType;
}

export interface IAdminLogsTableHeaderProps {
  queryParamKeys?: IAdminLogsTableProps["queryParamKeys"];
  style?: CSSProperties;
}
