import { useState } from "react";

import { useInterval } from "~hooks/useInterval";
import dayjs, { Dayjs } from "dayjs";

export function TimeIndicator() {
  const [currentTime, setCurrentTime] = useState<Dayjs>(dayjs());

  useInterval(() => {
    if (dayjs().format("HH:mm") !== currentTime.format("HH:mm")) {
      setCurrentTime(dayjs());
    }
  }, 500);

  return <span className="indicator-timer">{currentTime.format("HH:mm")}</span>;
}
