import { BsThreeDots } from "react-icons/bs";
import { IoNewspaperOutline } from "react-icons/io5";
import { SlUser } from "react-icons/sl";

import type { IPersonSummaryCardProps } from "./interface";
import { PersonIcon, StyledCard } from "./styles";

import { Alert } from "~components/Alert";
import { Modal } from "~components/Modal";
import { Delete, People } from "~estrela-ui/icons";
import { useFetchGetPersonById } from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { parseFunctions, useQueryParams } from "~hooks/useQueryParams";
import { SolarzAnchor } from "~solarzui/SolarzAnchor";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { Dropdown, Flex, Skeleton } from "antd";

const DELETE_MODAL_QUERY_KEY = "delete-person";

export function PersonSummaryCard({
  personId,
  onDeleteSuccess,
  style,
}: IPersonSummaryCardProps) {
  const { screens } = useAppConfig();
  const { handleSaveInQueryParams, getParsedQueryParams } = useQueryParams();

  const isDeletePersonModalOpen =
    Number(
      getParsedQueryParams(DELETE_MODAL_QUERY_KEY, parseFunctions.NUMBER),
    ) > 0;

  function handleOpenDeletePersonModal() {
    handleSaveInQueryParams({ [DELETE_MODAL_QUERY_KEY]: personId });
  }

  function handleCloseDeletePersonModal() {
    handleSaveInQueryParams({ [DELETE_MODAL_QUERY_KEY]: "" });
  }

  const {
    data: person,
    isFetching: isLoadingPerson,
    error: personError,
    refetch: reloadPerson,
  } = useFetchGetPersonById({
    dependencyArray: [personId],
    payload: {
      personId,
    },
    options: {
      enabled: personId > 0,
    },
  });

  const isLoading = personId === 0 || isLoadingPerson;

  return (
    <>
      {personError && (
        <Alert.CardError
          title="Falha ao carregar dados"
          errorMessage={personError?.message}
          reloadFn={reloadPerson}
          style={{
            marginBottom: 12,
          }}
        />
      )}

      <StyledCard size="small" style={style}>
        <Flex justify="space-between" align="center" style={{ height: "100%" }}>
          {screens.mobile ? (
            <Flex gap={8} flex={1}>
              <PersonIcon style={{ marginTop: 2 }}>
                <People />
              </PersonIcon>

              {isLoading || personError ? (
                <Flex vertical gap={4}>
                  <Skeleton.Node
                    active={isLoadingPerson}
                    style={{ width: 72, height: 20 }}
                  >
                    {" "}
                  </Skeleton.Node>
                  <Skeleton.Node
                    active={isLoadingPerson}
                    style={{ width: 100, height: 20 }}
                  >
                    {" "}
                  </Skeleton.Node>
                </Flex>
              ) : (
                <Flex vertical>
                  <SolarzTypography variant="title" hierarchy="paragraph2">
                    {person?.name}
                  </SolarzTypography>

                  <SolarzTypography variant="disabled" hierarchy="small">
                    {person?.organization?.name ?? "-"}
                  </SolarzTypography>
                </Flex>
              )}
            </Flex>
          ) : (
            <Flex align="center" gap={16}>
              <SlUser size={24} style={{ color: "var(--primary-500)" }} />

              {isLoading || personError ? (
                <Skeleton.Input
                  active={isLoadingPerson}
                  style={{ width: 180 }}
                />
              ) : (
                <SolarzTypography
                  hierarchy="paragraph1"
                  variant="title"
                  fontWeight="medium"
                >
                  {person?.name}
                </SolarzTypography>
              )}
            </Flex>
          )}

          <Flex align="center" gap={8}>
            <SolarzAnchor isNextLink href={`/proposta?pessoa=${personId}`}>
              <SolarzButton
                scale="large"
                icon={<IoNewspaperOutline size={20} />}
                style={
                  screens.mobile
                    ? {
                        height: 40,
                        width: 40,
                      }
                    : undefined
                }
              >
                {!screens.mobile && "Gerar proposta"}
              </SolarzButton>
            </SolarzAnchor>
            <Dropdown
              trigger={["click"]}
              menu={{
                items: [
                  {
                    key: "delete",
                    label: (
                      <SolarzTypography
                        hierarchy="paragraph2"
                        variant="danger"
                        fontWeight="medium"
                      >
                        <Flex align="center" style={{ height: 32 }} gap={12}>
                          <Delete />
                          Apagar pessoa
                        </Flex>
                      </SolarzTypography>
                    ),
                    onClick: () => {
                      handleOpenDeletePersonModal();
                    },
                  },
                ],
              }}
              arrow
              placement="bottomRight"
            >
              <div>
                <SolarzButton
                  variant="secondary"
                  scale="large"
                  icon={
                    <BsThreeDots
                      size={20}
                      style={{ color: "var(--primary-500)" }}
                    />
                  }
                  isDisabled={personId === 0}
                  style={{
                    height: screens.mobile ? 40 : 48,
                    width: screens.mobile ? 40 : 48,
                  }}
                />
              </div>
            </Dropdown>
          </Flex>
        </Flex>
      </StyledCard>

      <Modal.DeletePerson
        personId={personId}
        isOpen={isDeletePersonModalOpen}
        onClose={handleCloseDeletePersonModal}
        onSuccess={onDeleteSuccess}
      />
    </>
  );
}
