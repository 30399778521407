import { useCallback, useEffect, useRef } from "react";
import { FaCamera } from "react-icons/fa";

import { INITIAL_LOCATION } from "../configs";
import { useGoogleMaps } from "../hooks/useGoogleMaps";
import { SelectLocation } from "./SelectLocation";

import { GoogleMap, Marker } from "@react-google-maps/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { dataURItoBlob } from "~utils/parseDataUriToBlob";
import { Button, Flex } from "antd";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";

interface IMapSatelliteProps {
  canScreenshot?: boolean;
}

export function MapSatellite({ canScreenshot = true }: IMapSatelliteProps) {
  const { location } = useGoogleMaps();
  const { message } = useAppConfig();

  const mapRef = useRef<google.maps.Map>();

  const onLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
  }, []);

  async function handleScreenshot() {
    try {
      if (!mapRef?.current) throw new Error("Missing map ref");

      const canvas = await html2canvas(mapRef.current.getDiv(), {
        useCORS: true,
        allowTaint: true,
      });

      const dataURL = canvas.toDataURL();

      const blob = dataURItoBlob(dataURL);

      saveAs(blob, `${new Date().getTime()}.png`);
    } catch (error: any) {
      message.error(`Falha ao tirar print: ${error}`);
    }
  }

  useEffect(() => {
    if (!location?.latitude || !location?.longitude) return;

    mapRef.current?.panTo({
      lat: location.latitude,
      lng: location.longitude,
    });
  }, [location]);

  return (
    <GoogleMap
      data-cy="google-map"
      mapContainerStyle={{
        width: "100%",
        height: "100%",
      }}
      zoom={10}
      center={INITIAL_LOCATION}
      options={{
        mapTypeControl: false,
        streetViewControl: false,
        mapTypeId: "hybrid",
      }}
      onLoad={onLoad}
    >
      <>
        <Flex
          style={{
            position: "relative",
            top: 4,
            left: 4,
            width: "100%",
          }}
          gap={8}
        >
          <SelectLocation
            style={{ height: 42, maxWidth: "320px" }}
            placeholder="Buscar endereço..."
          />
          {canScreenshot && (
            <Button
              style={{ height: 42, width: 42, lineHeight: 1, padding: 0 }}
              type="primary"
              icon={<FaCamera fontSize={22} />}
              title="Tirar print"
              onClick={handleScreenshot}
              size="small"
            />
          )}
        </Flex>
        {location?.latitude && location?.longitude && (
          <Marker
            position={{
              lat: location.latitude,
              lng: location.longitude,
            }}
          />
        )}
      </>
    </GoogleMap>
  );
}
