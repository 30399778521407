import { useState } from "react";
import { useMutation } from "react-query";

import type {
  ISendRecoverPasswordFormProps,
  ISendRecoverPasswordFormValues,
} from "./interface";

import { fetchSendEmailToRecoverPassword } from "~api/users/forgot-password/post";
import { CustomInput } from "~components/CustomInput";
import { useAppConfig } from "~hooks/useAppConfig";
import { Button, Flex, Form, Result, Typography } from "antd";

export function SendRecoverPasswordForm({
  initialValues = {
    email: "",
  },
  onSubmitFail = () => undefined,
  onSubmitSuccess = () => undefined,
  extra = <></>,
}: ISendRecoverPasswordFormProps) {
  const { message } = useAppConfig();

  const [emailWasSended, setEmailWasSended] = useState(false);

  const fetchMutation = useMutation(
    async (data: ISendRecoverPasswordFormValues) => {
      return await fetchSendEmailToRecoverPassword(data);
    },
    {
      onSuccess: () => {
        message.success("Vamos lhe enviar o email de recuperação");
        setEmailWasSended(true);
        onSubmitSuccess();
      },
      onError: (error: any) => {
        message.error(error?.message);
        onSubmitFail();
      },
    },
  );

  if (emailWasSended) {
    return (
      <Result
        status="success"
        title="Aguarde o email de recuperação"
        subTitle="O email pode demorar alguns minutos para chegar, lembre-se também de checar a caixa de span."
        extra={[
          <Typography.Text key="try_again_key">
            Não recebi o email,{" "}
            <Button
              onClick={() => setEmailWasSended(false)}
              type="link"
              size="small"
              style={{ padding: 0 }}
            >
              tentar novamente
            </Button>
          </Typography.Text>,
        ]}
      />
    );
  }

  return (
    <Form
      data-cy="send-recover-password-form"
      onFinish={fetchMutation.mutate}
      initialValues={initialValues}
      layout="vertical"
      style={{ width: "100%" }}
    >
      <Form.Item
        name="email"
        label="Informe o e-mail cadastrado"
        rules={[
          {
            required: true,
            message: "Email é obrigatório",
          },
          {
            pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            message: "Formato de email é inválido!",
          },
        ]}
        style={{ marginBottom: "1rem" }}
      >
        <CustomInput.Text placeholder="Exemplo@mail.com.br" type="email" />
      </Form.Item>

      <Form.Item>
        <Flex align="center" justify="end" gap={4}>
          {extra}

          <Button
            type="primary"
            htmlType="submit"
            loading={fetchMutation.isLoading}
          >
            Enviar e-mail
          </Button>
        </Flex>
      </Form.Item>
    </Form>
  );
}
