import { ICustomEosProposalResponseSchema } from "~types/schemas";

export type EosFinancialProposalsTableDataType =
  ICustomEosProposalResponseSchema;

export type EosFinancialProposalsTableFiltersType = {
  currentPage: number;
  query?: string;
};

export enum EosFinancialProposalsTableQueryParamKeysEnum {
  CURRENT_PAGE = "page",
  QUERY = "query",
}

export type EosFinancialProposalsTableQueryParamsKeysType = Record<
  keyof typeof EosFinancialProposalsTableQueryParamKeysEnum,
  string
>;

export interface IEosFinancialProposalsTableProps {
  queryParamKeys?: Partial<EosFinancialProposalsTableQueryParamsKeysType>;
}
