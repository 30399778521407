import { useMemo } from "react";

import type {
  ActivityPriorityOptionType,
  ActivityPriorityRecordType,
  IActivityPrioritySelectProps,
} from "./interface";

import { SolarzSelect } from "~solarzui/SolarzSelect";
import { activityPriorityEnumValues } from "~types/enum";
import { translateActivityPriorityEnum } from "~utils/translate";

export function ActivityPrioritySelect({
  disabledOptions,
  hideOptions,
  ...props
}: IActivityPrioritySelectProps) {
  const OPTIONS = useMemo(() => {
    return activityPriorityEnumValues.map((value) => ({
      value,
      record: {
        value,
      },
      label: translateActivityPriorityEnum(value),
      hidden: hideOptions && hideOptions.includes(value),
      disabled: disabledOptions && disabledOptions.includes(value),
    })) as ActivityPriorityOptionType;
  }, [disabledOptions, hideOptions]);

  return (
    <SolarzSelect<ActivityPriorityRecordType, "value">
      {...props}
      options={OPTIONS}
    />
  );
}
