import styled from "@emotion/styled";
import { SolarzButton } from "~solarzui/SolarzButton";
import { Radio } from "antd";

export const RadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    display: flex;
    align-items: center;
    padding: 0.5rem;

    span,
    svg {
      line-height: 0;
      color: var(--gray-500);
    }
  }

  .ant-radio-button-wrapper-checked {
    background-color: var(--primary-50);

    span,
    svg {
      color: var(--primary-500) !important;
    }

    // DARK THEME
    html.dark & {
      .ant-radio-button {
        background-color: var(--red-900) !important;

        span,
        svg {
          color: var(--red-50) !important;
        }
      }
    }
  }

  .is-hidden {
    display: none;
    opacity: 0;
  }
`;

export const DeleteButton = styled(SolarzButton)`
  color: var(--red-500);

  &:not(:disabled):hover {
    color: var(--red-400) !important;
  }
`;
