import type { IDeleteIntegrationApiButtonProps } from "./interface";
import { StyledSolarzButton } from "./styles";

import { Modal } from "~components/Modal";
import { Delete } from "~estrela-ui/icons";
import { useAppConfig } from "~hooks/useAppConfig";
import { parseFunctions, useQueryParams } from "~hooks/useQueryParams";

const MODAL_QUERY_PARAM_KEY = "remover-integracao";

export function DeleteIntegrationApiButton({
  isDisabled,
  isLoading,
}: IDeleteIntegrationApiButtonProps) {
  const { handleSaveInQueryParams, getParsedQueryParams } = useQueryParams();
  const { screens } = useAppConfig();

  const isModalOpen = !!getParsedQueryParams(
    MODAL_QUERY_PARAM_KEY,
    parseFunctions.BOOLEAN,
  );

  function handleOpenModal() {
    handleSaveInQueryParams({ [MODAL_QUERY_PARAM_KEY]: "true" });
  }

  function handleCloseModal() {
    handleSaveInQueryParams({ [MODAL_QUERY_PARAM_KEY]: "" });
  }

  return (
    <>
      <StyledSolarzButton
        scale={screens.mobile ? "tiny" : "medium"}
        variant="secondary"
        isDisabled={isDisabled}
        isLoading={isLoading}
        style={{
          width: screens.mobile ? 32 : 48,
          padding: screens.mobile ? 0 : undefined,
        }}
        icon={<Delete />}
        onClick={handleOpenModal}
      />
      <Modal.DeleteIntegrationApi
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      />
    </>
  );
}
