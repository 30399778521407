import { useState } from "react";

import type {
  IPerformanceRateMonthState,
  IPerformanceRateMonthFormValues,
  IPerformanceRateMonthFormProps,
} from "./interface";

import { CustomInput } from "~components/CustomInput";
import {
  useFetchGetMonthlyPerformanceRateByCustomId,
  useFetchUpdateMonthlyPerformanceRateByCustomId,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { getAverageMonthlyPerformanceRate } from "~utils/calculateAverageMonthlyPerformance";
import { Form, Row, Col, Button, Spin, Alert, Flex } from "antd";

const MONTHS = [
  { ptBrKey: "Janeiro", key: "jan" },
  { ptBrKey: "Fevereiro", key: "feb" },
  { ptBrKey: "Março", key: "mar" },
  { ptBrKey: "Abril", key: "apr" },
  { ptBrKey: "Maio", key: "may" },
  { ptBrKey: "Junho", key: "jun" },
  { ptBrKey: "Julho", key: "jul" },
  { ptBrKey: "Agosto", key: "aug" },
  { ptBrKey: "Setembro", key: "sep" },
  { ptBrKey: "Outubro", key: "oct" },
  { ptBrKey: "Novembro", key: "nov" },
  { ptBrKey: "Dezembro", key: "dec" },
] as const;

export function PerformanceRateMonthForm({
  onSuccess = () => undefined,
  onError = () => undefined,
  onCancel = () => undefined,
  formInstance,
  customId = 0,
}: IPerformanceRateMonthFormProps) {
  const { screens, message } = useAppConfig();

  const [form] = Form.useForm<IPerformanceRateMonthFormValues>(formInstance);

  const [PerformanceRateError, setPerformanceRateError] = useState<string>();
  const { isFetching: isLoadingProjectPerformanceRate } =
    useFetchGetMonthlyPerformanceRateByCustomId({
      payload: {
        customId,
      },
      options: {
        onSuccess(data) {
          if (!data) {
            setPerformanceRateError(undefined);
            return;
          }
          const average = getAverageMonthlyPerformanceRate(data);

          const monthlyPerformanceRate: IPerformanceRateMonthState = {
            ...data,
            average,
          };

          form.setFieldsValue(monthlyPerformanceRate);

          setPerformanceRateError(undefined);
        },
        onError(error) {
          setPerformanceRateError(error?.message);
        },
      },
      dependencyArray: [customId],
    });

  const {
    mutate: updateMonthlyPerformanceRate,
    isLoading: isUpdatingMonthlyPerformanceRate,
  } = useFetchUpdateMonthlyPerformanceRateByCustomId({
    options: {
      onSuccess: (data) => {
        const average = getAverageMonthlyPerformanceRate(data);

        const monthlyPerformanceRate: IPerformanceRateMonthState = {
          ...data,
          average,
        };
        message.success("Performance foi definida!");
        return onSuccess(monthlyPerformanceRate);
      },
      onError: (error) => onError(error.message),
    },
  });

  const handleSubmit = (fieldsValue: IPerformanceRateMonthFormValues) => {
    updateMonthlyPerformanceRate({ ...fieldsValue, customId });
  };

  const handleReset = () => {
    const initialValues: IPerformanceRateMonthFormValues = {
      average: 0,
      jan: 0,
      feb: 0,
      mar: 0,
      apr: 0,
      may: 0,
      jun: 0,
      jul: 0,
      aug: 0,
      sep: 0,
      oct: 0,
      nov: 0,
      dec: 0,
    };

    form.setFieldsValue(initialValues);
  };

  const isLoadingData =
    isLoadingProjectPerformanceRate || isUpdatingMonthlyPerformanceRate;

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      scrollToFirstError
    >
      {PerformanceRateError && (
        <Alert
          message="Falha ao carregar dados"
          description={PerformanceRateError}
          type="error"
          showIcon
          style={{ marginBottom: "1rem" }}
          closable
          afterClose={() => setPerformanceRateError(undefined)}
        />
      )}

      <Form.Item name="average" initialValue={0} hidden>
        <CustomInput.Number name="average" readOnly disabled />
      </Form.Item>

      <Spin spinning={isLoadingData}>
        <Row gutter={16}>
          {MONTHS.map((month) => (
            <Col key={month.key} span={screens.xs ? 12 : 8}>
              <Form.Item
                name={month.key}
                label={month.ptBrKey}
                initialValue={0}
                rules={[
                  {
                    required: true,
                    message: `Por favor, insira o mês de ${month.ptBrKey}`,
                  },
                ]}
              >
                <CustomInput.UnitMeasurement
                  name={month.key}
                  placeholder={month.ptBrKey}
                  min={0}
                  precision="2"
                  unitMeasurement="%"
                  max={100}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          ))}
        </Row>
      </Spin>

      <Flex gap={12} justify="end">
        <Form.Item style={{ margin: 0 }}>
          <Button
            size="small"
            type="link"
            onClick={handleReset}
            disabled={isLoadingData}
          >
            Resetar
          </Button>
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button onClick={onCancel}>Cancelar</Button>
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button type="primary" htmlType="submit" disabled={isLoadingData}>
            Salvar
          </Button>
        </Form.Item>
      </Flex>
    </Form>
  );
}
