import { IRemovePersonFromOrganizationModalProps } from "./interface";
import { LoadingSkeleton, StyledAlert } from "./styles";

import { Alert } from "~components/Alert";
import {
  useFetchGetPersonById,
  useFetchRemovePersonFromOrganization,
} from "~hooks/api";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { Flex, Modal } from "antd";

export function RemovePersonFromOrganizationModal({
  organizationId,
  personId,
  isOpen = false,
  onClose = () => null,
  title = "Remover pessoa da organização",
  maxWidth = 520,
}: IRemovePersonFromOrganizationModalProps) {
  const {
    mutate: removePersonFromOrganization,
    isLoading: isRemovingPersonFromOrganization,
  } = useFetchRemovePersonFromOrganization({
    options: {
      onSuccess: onClose,
    },
  });

  const {
    data: person,
    isFetching: isLoadingPerson,
    error: personError,
    refetch: refetchPerson,
  } = useFetchGetPersonById({
    dependencyArray: [personId],
    options: {
      retry: 1,
      enabled: personId > 0,
    },
    payload: {
      personId,
    },
  });

  function handleRemovePersonFromOrganization() {
    removePersonFromOrganization({ organizationId, peopleId: [personId] });
  }

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title={title}
      style={{
        width: "100%",
        maxWidth,
      }}
      okText="Apagar"
      onOk={handleRemovePersonFromOrganization}
      okButtonProps={{
        loading: isRemovingPersonFromOrganization,
      }}
    >
      {personError?.message && (
        <Alert.CardError
          errorMessage={personError?.message}
          reloadFn={refetchPerson}
          title="Falha ao carregar dados da pessoa"
          style={{ marginBottom: 16 }}
        />
      )}

      {isLoadingPerson && (
        <LoadingSkeleton
          active
          style={{
            height: 160,
            marginBottom: 12,
            width: maxWidth - 60,
          }}
        >
          {" "}
        </LoadingSkeleton>
      )}

      {!isLoadingPerson && (
        <StyledAlert
          type="error"
          description={
            <Flex vertical>
              <SolarzTypography variant="danger" fontWeight="medium">
                Você tem certeza que deseja remover a pessoa{" "}
                <b>{person?.name}</b> da organização?
              </SolarzTypography>

              <ul>
                <li>
                  <SolarzTypography variant="danger" hierarchy="paragraph2">
                    Todas as <b>notas, arquivos</b> e <b>documentos</b>{" "}
                    associados a essa pessoa irão ficar sem responsável
                  </SolarzTypography>
                </li>
              </ul>
            </Flex>
          }
        />
      )}
    </Modal>
  );
}
