import { useMemo } from "react";

import { IModulePhaseSelectProps, IModulePhaseSelectOption } from "./interface";

import { Select } from "antd";

export function ModulePhaseSelect({
  value,
  onChange,
  defaultValue,
  disabledValues = [],
  ...props
}: IModulePhaseSelectProps) {
  const OPTIONS: IModulePhaseSelectOption[] = useMemo(
    () => [
      {
        label: "Monofacial" as const,
        value: "MONOFACIAL" as const,
        disabled: disabledValues.includes("MONOFACIAL"),
      },
      {
        label: "Bifacial" as const,
        value: "BIFACIAL" as const,
        disabled: disabledValues.includes("BIFACIAL"),
      },
    ],
    [disabledValues],
  );

  return (
    <Select
      {...props}
      options={OPTIONS}
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
}
