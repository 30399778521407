import { useQuery } from "react-query";

import { IUseFetchGetProductQueue } from "./interface";

import { fetchGetDownloadProductQueue } from "~api/product-queue/download/{id}/get";

export const UseFetchGetProductQueueKey = "api/product-queue/download/{id}/get";

export function useFetchGetDownloadProductQueue({
  dependencyArray = [],
  options,
  payload = {
    id: 0,
  },
}: IUseFetchGetProductQueue) {
  return useQuery(
    [UseFetchGetProductQueueKey, dependencyArray],
    async () => await fetchGetDownloadProductQueue(payload),
    options,
  );
}
