import { useCallback, useEffect, useMemo, useState } from "react";
import { AiOutlineMinusCircle, AiOutlinePlus } from "react-icons/ai";

import type {
  ISizingKitFormCollapseKey,
  ISizingKitFormInverter,
  ISizingKitFormModule,
  ISizingKitFormOnFinishFailed,
  ISizingKitFormOtherItems,
  ISizingKitFormProps,
  ISizingKitFormValues,
} from "./interface";
import styles from "./styles";

import { AutoComplete } from "~components/AutoComplete";
import { CustomInput } from "~components/CustomInput";
import { Select } from "~components/Select";
import { Tag } from "~components/Tag";
import { PROPOSAL_SIZING_KIT_DATA } from "~configs/ProposalConfig";
import { useAppConfig } from "~hooks/useAppConfig";
import { useInterval } from "~hooks/useInterval";
import { parseCurrency, parsePotency, parseSquareMeters } from "~utils/parse";
import {
  Form,
  Row,
  Col,
  Button,
  Divider,
  Collapse,
  Button as AntdButton,
  Flex,
} from "antd";

function MinusButtonToRemoveLine({
  disabled = false,
  onClick = () => undefined,
}: {
  onClick?: () => void;
  disabled?: boolean;
}) {
  return (
    <Flex justify="center" align="center" style={{ height: "100%" }}>
      <Button
        type="text"
        size="small"
        shape="circle"
        style={{
          lineHeight: 0,
        }}
        onClick={onClick}
        disabled={disabled}
      >
        <AiOutlineMinusCircle size={16} />
      </Button>
    </Flex>
  );
}

function calculateModuleArea(widthInMm: number, lengthInMm: number) {
  const lengthInMeters = lengthInMm / 1000;

  const widthInMeters = widthInMm / 1000;

  const area = lengthInMeters * widthInMeters;

  return area;
}

export function SizingKitForm({
  // onSuccess = () => undefined,
  // onError = () => undefined,
  onSubmit = () => undefined,
  onCancel = () => undefined,
  isLoading = false,
  formInstance,
  dimensionedPowerInKwp,
  proposalIdToPersistsDataInStorage,
}: ISizingKitFormProps) {
  const { screens } = useAppConfig();

  const [form] = Form.useForm<ISizingKitFormValues>(formInstance);

  // INVERTERS WATCHER
  const inverters = Form.useWatch("inverters", form);

  // MODULE WATCHERS
  const moduleBrand = Form.useWatch("moduleBrand", form);
  const modulePotency = Form.useWatch("modulePotency", form) ?? 0;
  const moduleModel = Form.useWatch("moduleModel", form);
  const moduleQuantity = Form.useWatch("moduleQuantity", form) ?? 0;
  const moduleUnityAmount = Form.useWatch("moduleUnityAmount", form);
  const moduleAreaM2 =
    Form.useWatch("moduleAreaM2", { form, preserve: true }) ?? 0; // hidden
  const moduleTotalPrice =
    Form.useWatch("moduleTotalPrice", { form, preserve: true }) ?? 0; // hidden

  // PREVENTS FORM CHANGE WHEN LOAD MODEL FROM MODULE OR INVERSOR WHEN DATA IS LOADED FROM STORAGE
  const [preventsFormChange, setPreventsFormChange] = useState(
    !!proposalIdToPersistsDataInStorage,
  );

  // OTHERS WATCHERS
  const others = Form.useWatch("others", form);

  // COLLAPSE CONTROL
  const [collapseActiveKey, setCollapseActiveKey] =
    useState<ISizingKitFormCollapseKey>("MODULES");

  function calculateModuleQuantityByDimensionedPowerInKwp(
    dimensionedPotencyInKwp = 0,
    modulePotency = 0,
  ) {
    const dimensionedPotencyInWp = dimensionedPotencyInKwp * 1000;
    const quantity = dimensionedPotencyInWp / modulePotency;
    return Math.ceil(quantity);
  }

  function cleanModuleFields() {
    const currentFormData = form.getFieldsValue(true) as ISizingKitFormValues;

    form.setFieldsValue({
      ...currentFormData,
      moduleAreaM2: undefined,
      moduleBrand: undefined,
      moduleId: undefined,
      moduleLenghtMm: undefined,
      modulePotency: undefined,
      moduleModel: undefined,
      moduleQuantity: 1,
      moduleTotalPrice: 0,
      moduleUnityAmount: undefined,
      moduleWidthMm: undefined,
      moduleType: undefined,
      moduleRecord: undefined,
    });
  }

  function cleanInverterFields(position: number) {
    const currentFormData = form.getFieldsValue(true) as ISizingKitFormValues;

    const updatedInvertersList = currentFormData.inverters?.map(
      (inverter, index) => {
        if (index === position) {
          return {
            ...inverter,
            inverterBrand: undefined,
            inverterId: undefined,
            inverterModel: undefined,
            inverterQuantity: 1,
            inverterTotalPrice: 0,
            inverterUnityAmount: undefined,
            inverterMaximumPotency: undefined,
            inverterNominalPotency: undefined,
            inverterOutputVoltage: undefined,
            inverterPhase: undefined,
            inverterType: undefined,
            inverterRecord: undefined,
          };
        } else {
          return inverter;
        }
      },
    );

    form.setFieldValue("inverter", updatedInvertersList);
  }

  function onChangeModulePotency(power?: number) {
    form.setFieldsValue({
      moduleModel: undefined,
      modulePotency: power,
      moduleQuantity: dimensionedPowerInKwp
        ? calculateModuleQuantityByDimensionedPowerInKwp(
            dimensionedPowerInKwp,
            power,
          )
        : form.getFieldValue("moduleQuantity"),
    });
  }

  function onChangeModuleBrand(brand?: string) {
    form.setFieldsValue({
      moduleModel: undefined,
      modulePotency: undefined,
      moduleBrand: brand,
    });
  }

  function onChangeInverterPotency(
    power: number | undefined,
    position: number,
  ) {
    const { inverters = [] } = form.getFieldsValue();

    const updatedInverters: ISizingKitFormInverter[] = inverters.map(
      (item, index) => {
        if (index === position) {
          return {
            ...item,
            inverterNominalPotency: power,
          } as ISizingKitFormInverter;
        } else {
          return item;
        }
      },
    );

    form.setFieldValue("inverters", updatedInverters);
  }

  function onChangeInverterBrand(brand: string | undefined, position: number) {
    const { inverters = [] } = form.getFieldsValue();

    const updatedInverters: ISizingKitFormInverter[] = inverters.map(
      (item, index) => {
        if (index === position) {
          return {
            ...item,
            inverterBrand: brand,
            inverterModel: undefined,
            inverterNominalPotency: undefined,
          } as ISizingKitFormInverter;
        } else {
          return item;
        }
      },
    );

    form.setFieldValue("inverters", updatedInverters);
  }

  function calculateAndSetModuleAreaFieldValue() {
    const { moduleLenghtMm = 0, moduleWidthMm = 0 } = form.getFieldsValue(true);

    const area = calculateModuleArea(moduleWidthMm, moduleLenghtMm);

    form.setFieldValue("moduleAreaM2", area);
  }

  function calculateAndSetModuleTotalValue() {
    const { moduleUnityAmount = 0, moduleQuantity = 0 } = form.getFieldsValue();

    const moduleTotalPrice = moduleUnityAmount * moduleQuantity;

    form.setFieldValue("moduleTotalPrice", moduleTotalPrice);
  }

  function calculateAndSetInverterTotalValue(index: number) {
    const { inverters = [] } = form.getFieldsValue();

    const updatedInverters = inverters.map((inverter, inverterIndex) => {
      if (inverterIndex === index) {
        const quantity = inverter.inverterQuantity ?? 0;
        const unityAmount = inverter.inverterUnityAmount ?? 0;
        const inverterTotalPrice = quantity * unityAmount;

        return {
          ...inverter,
          inverterTotalPrice,
        };
      } else {
        return inverter;
      }
    });

    form.setFieldValue("inverters", updatedInverters);
  }

  function calculateAndSetOtherTotalValue(index: number) {
    const { others = [] } = form.getFieldsValue();

    const updatedOthers = others.map((other, otherIndex) => {
      if (otherIndex === index) {
        const quantity = other.otherQuantity ?? 0;
        const unityAmount = other.otherUnityAmount ?? 0;
        const otherTotalPrice = quantity * unityAmount;

        return {
          ...other,
          otherTotalPrice,
        };
      } else {
        return other;
      }
    });

    form.setFieldValue("others", updatedOthers);
  }

  const { invertersTotalPrice, invertersTotalQuantity } = useMemo(() => {
    const invertersList = inverters ?? [];

    return invertersList.reduce(
      (info, inverter) => {
        const totalPrice =
          info.invertersTotalPrice + (inverter.inverterTotalPrice ?? 0);
        const totalQuantity =
          info.invertersTotalQuantity + (inverter.inverterQuantity ?? 0);

        return {
          invertersTotalPrice: totalPrice,
          invertersTotalQuantity: totalQuantity,
        };
      },
      {
        invertersTotalPrice: 0,
        invertersTotalQuantity: 0,
      },
    );
  }, [inverters]);

  const { othersTotalPrice, othersTotalQuantity } = useMemo(() => {
    const othersList = others ?? [];

    return othersList.reduce(
      (info, other) => {
        const totalPrice = info.othersTotalPrice + (other.otherTotalPrice ?? 0);
        const totalQuantity =
          info.othersTotalQuantity + (other.otherQuantity ?? 0);

        return {
          othersTotalPrice: totalPrice,
          othersTotalQuantity: totalQuantity,
        };
      },
      {
        othersTotalPrice: 0,
        othersTotalQuantity: 0,
      },
    );
  }, [others]);

  // SOME CONST
  const AllItensTotalPrice =
    moduleTotalPrice + invertersTotalPrice + othersTotalPrice;

  const modulesTotalPotency = (modulePotency * moduleQuantity) / 1000;

  function handleSubmit(formData: ISizingKitFormValues) {
    onSubmit({
      ...formData,
      price: formData.price ?? AllItensTotalPrice,
      hasPriceSetManually: formData.price
        ? formData.price !== AllItensTotalPrice
        : false,
    });
  }

  function onSubmitFailed(errorInfo: ISizingKitFormOnFinishFailed) {
    const firstErrorStatus = errorInfo.errorFields?.[0].name[0].toString();

    if (firstErrorStatus.includes("module"))
      return setCollapseActiveKey("MODULES");

    if (firstErrorStatus.includes("inverter"))
      return setCollapseActiveKey("INVERTERS");

    if (firstErrorStatus.includes("other"))
      return setCollapseActiveKey("OTHERS");
  }

  // START LOAD & PERSISTS DATA IN STORAGE
  const persistsFormDataInStorage = useCallback(() => {
    const formData = form.getFieldsValue(true) as ISizingKitFormValues;
    const parsedFormData = JSON.stringify(formData);
    localStorage.setItem(PROPOSAL_SIZING_KIT_DATA, parsedFormData);
  }, [form]);

  const loadPersistedFormDataFromStorage = useCallback(() => {
    const storageData = localStorage.getItem(PROPOSAL_SIZING_KIT_DATA);

    if (!storageData) return;

    const parsedData = JSON.parse(storageData) as ISizingKitFormValues;

    if (
      parsedData.proposalIdToPersistsDataInStorage !==
      proposalIdToPersistsDataInStorage
    ) {
      return localStorage.removeItem(PROPOSAL_SIZING_KIT_DATA);
    }

    form.setFieldsValue(parsedData);
  }, [form, proposalIdToPersistsDataInStorage]);

  useEffect(() => {
    if (proposalIdToPersistsDataInStorage) {
      loadPersistedFormDataFromStorage();
    }
  }, [loadPersistedFormDataFromStorage, proposalIdToPersistsDataInStorage]); // not set dependencies!

  useInterval(() => {
    if (proposalIdToPersistsDataInStorage) {
      setPreventsFormChange(false);
      persistsFormDataInStorage();
    }
  }, 1000);
  // END LOAD & PERSISTS DATA IN STORAGE

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={() => handleSubmit(form.getFieldsValue(true))}
      onFinishFailed={onSubmitFailed}
      scrollToFirstError
      preserve
    >
      <style jsx>{styles}</style>

      <Form.Item
        name="proposalIdToPersistsDataInStorage"
        hidden
        initialValue={proposalIdToPersistsDataInStorage}
      >
        <CustomInput.Number disabled readOnly />
      </Form.Item>

      <Form.Item
        label="Preço"
        name="price"
        tooltip="Você pode usar o preço total dos itens informado abaixo, ou informar qualquer outro valor. Campo NÃO é obrigatório"
        required={false}
        extra={
          <Flex align="center" gap={4}>
            O valor total de todos itens é de:{" "}
            {parseCurrency(AllItensTotalPrice)}.{" "}
            <AntdButton
              type="text"
              size="small"
              style={{
                padding: 0,
                border: 0,
                margin: 0,
                color: "var(--primary-500)",
              }}
              onClick={() => form.setFieldValue("price", AllItensTotalPrice)}
            >
              Preencher com este valor?
            </AntdButton>
          </Flex>
        }
      >
        <CustomInput.Money placeholder="Ex.: 1999,99" min={0} />
      </Form.Item>

      <Collapse
        activeKey={collapseActiveKey}
        onChange={(key) =>
          setCollapseActiveKey(key as ISizingKitFormCollapseKey)
        }
        items={[
          {
            key: "MODULES",
            label: (
              <Flex align="center" justify="space-between" gap="4px">
                Módulo
                <Flex gap={8} align="center">
                  <Tag.Info
                    fieldName="Preço total do módulo"
                    fieldValue={parseCurrency(moduleTotalPrice)}
                    tagStyles={screens.xs ? { width: "100%" } : undefined}
                  />
                  <Tag.Info
                    fieldName="Quantidade"
                    fieldValue={moduleQuantity.toString()}
                    tagStyles={screens.xs ? { width: "100%" } : undefined}
                  />
                  <Tag.Info
                    fieldName="Área total"
                    fieldValue={
                      moduleAreaM2 && moduleQuantity
                        ? parseSquareMeters(moduleAreaM2 * moduleQuantity)
                        : "-"
                    }
                    tagStyles={screens.xs ? { width: "100%" } : undefined}
                  />
                  <Tag.Info
                    fieldName="Área por módulo"
                    fieldValue={
                      moduleAreaM2 ? parseSquareMeters(moduleAreaM2) : "-"
                    }
                    tagStyles={screens.xs ? { width: "100%" } : undefined}
                  />
                  <Tag.Info
                    fieldName="Potência do kit"
                    fieldValue={
                      modulesTotalPotency
                        ? parsePotency(modulesTotalPotency)
                        : "-"
                    }
                    tagStyles={screens.xs ? { width: "100%" } : undefined}
                  />
                </Flex>
              </Flex>
            ),
            children: (
              <Flex style={{ width: "100%" }} vertical>
                {/* START HIDDEN INPUTS */}
                <Form.Item name="moduleId" hidden>
                  <CustomInput.Number disabled readOnly />
                </Form.Item>

                <Form.Item name="moduleAreaM2" hidden>
                  <CustomInput.UnitMeasurement
                    precision="2"
                    unitMeasurement="m²"
                    readOnly
                    disabled
                  />
                </Form.Item>

                <Form.Item name="moduleTotalPrice" hidden>
                  <CustomInput.Money readOnly disabled />
                </Form.Item>

                <Form.Item name="moduleType" hidden>
                  <CustomInput.Text readOnly disabled />
                </Form.Item>
                {/* END HIDDEN INPUTS */}
                <Row
                  wrap={true}
                  justify="center"
                  gutter={[16, 0]}
                  style={{
                    width: "100%",
                  }}
                >
                  <Col xs={24} md={6}>
                    <Form.Item
                      name="moduleBrand"
                      label="Marca do módulo"
                      rules={[{ required: true, message: "Obrigatório!" }]}
                    >
                      <AutoComplete.ModuleManufacturer
                        allowClear
                        onChange={onChangeModuleBrand}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={6}>
                    <Form.Item
                      name="modulePotency"
                      label="Potência do módulo"
                      tooltip="Unidade medida: Watt-pico (Wp)"
                      rules={[{ required: true }]}
                    >
                      <AutoComplete.ModulePower
                        allowClear
                        disabled={!moduleBrand}
                        filters={{ manufacturer: moduleBrand }}
                        onChange={onChangeModulePotency}
                        value={modulePotency}
                        autoCompleteInSingleOption
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="moduleModel"
                      label="Modelo do módulo"
                      rules={[{ required: true, message: "Obrigatório!" }]}
                    >
                      <AutoComplete.ProductModule
                        placeholder="Digite ao menos 3 letras para auto-completar"
                        allowClear
                        value={moduleModel}
                        onChange={(value, option) => {
                          if (!option?.record || preventsFormChange) return;

                          const currentFormData = form.getFieldsValue(
                            true,
                          ) as ISizingKitFormValues;

                          if (
                            !currentFormData.moduleBrand ||
                            !currentFormData.modulePotency
                          ) {
                            return cleanModuleFields();
                          }

                          const moduleUnityAmount =
                            (option.record.precoBaseCents ?? 0) / 100;

                          const moduleTotalPrice =
                            moduleUnityAmount * currentFormData.moduleQuantity;

                          form.setFieldsValue({
                            ...currentFormData,
                            moduleUnityAmount,
                            moduleTotalPrice,
                            moduleId: option.record.id,
                            moduleModel: option.record.model,
                            moduleType: option.record.type,
                            modulePowerWp: option.record.powerWp,
                            moduleAreaM2: calculateModuleArea(
                              option.record.widthMm ?? 0,
                              option.record.lengthMm ?? 0,
                            ),
                            moduleLenghtMm: option.record.lengthMm,
                            moduleWidthMm: option.record.widthMm,
                            modulePotency: option.record.powerWp,
                            moduleQuantity: currentFormData.moduleQuantity,
                            moduleBrand: currentFormData.moduleBrand,
                            moduleRecord: option.record,
                          } as ISizingKitFormModule);
                        }}
                        filters={{
                          manufacturer: moduleBrand,
                          power: modulePotency,
                        }}
                        disabled={!modulePotency || !moduleBrand}
                        autoCompleteInSingleOption
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  wrap={true}
                  justify="center"
                  gutter={[16, 0]}
                  style={{
                    width: "100%",
                  }}
                >
                  <Col xs={24} md={6}>
                    <Form.Item
                      name="moduleQuantity"
                      label="Quantidade"
                      extra={
                        modulesTotalPotency &&
                        `Potência do kit ${parsePotency(modulesTotalPotency)}`
                      }
                      initialValue={1}
                    >
                      <CustomInput.Number
                        placeholder="Ex.: 4"
                        precision={0}
                        min={0}
                        disabled={!moduleModel}
                        onChange={calculateAndSetModuleTotalValue}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={6}>
                    <Form.Item
                      name="moduleUnityAmount"
                      label="Valor unitário"
                      extra={
                        moduleUnityAmount &&
                        moduleQuantity &&
                        `Total dos módulos: ${parseCurrency(moduleTotalPrice)}`
                      }
                    >
                      <CustomInput.Money
                        placeholder="Ex.: 199,00"
                        disabled={!moduleModel}
                        onChange={calculateAndSetModuleTotalValue}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={6}>
                    <Form.Item name="moduleWidthMm" label="Largura">
                      <CustomInput.UnitMeasurement
                        precision="2"
                        unitMeasurement="mm"
                        placeholder="Ex.: 250"
                        onChange={calculateAndSetModuleAreaFieldValue}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={6}>
                    <Form.Item name="moduleLenghtMm" label="Comprimento">
                      <CustomInput.UnitMeasurement
                        precision="2"
                        unitMeasurement="mm"
                        placeholder="Ex.: 300"
                        onChange={calculateAndSetModuleAreaFieldValue}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Flex>
            ),
          },
          {
            key: "INVERTERS",
            forceRender: true, // COLLAPSE BY DEFAULT NOT RENDER COMPONENT BEFORE COLLAPSE
            label: (
              <Flex align="center" justify="space-between" gap="4px">
                <div
                  style={{
                    textAlign: "left",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    minWidth: "126px",
                  }}
                >
                  Lista de inversores
                </div>
                <Flex gap={8} align="center">
                  <Tag.Info
                    fieldName="Preço total dos inversores"
                    fieldValue={parseCurrency(invertersTotalPrice)}
                    tagStyles={screens.xs ? { width: "100%" } : undefined}
                  />
                  <Tag.Info
                    fieldName="Quantidade"
                    fieldValue={invertersTotalQuantity?.toString()}
                    tagStyles={screens.xs ? { width: "100%" } : undefined}
                  />
                </Flex>
              </Flex>
            ),
            children: (
              <Form.List
                name="inverters"
                initialValue={[
                  {
                    category: "INVERTER",
                    quantity: 1,
                    unityAmount: 0,
                  },
                ]}
              >
                {(fields, { add, remove }) => (
                  <>
                    <Flex
                      vertical
                      wrap="nowrap"
                      style={{
                        width: "100%",
                        maxHeight: 360,
                        overflowY: "auto",
                      }}
                    >
                      {fields.map((field, index) => {
                        const inverterBrand = inverters?.[index].inverterBrand;

                        const inverterPotency =
                          inverters?.[index].inverterNominalPotency;

                        const inverterModel = inverters?.[index].inverterModel;

                        const inverterTotalPrice =
                          inverters?.[index].inverterTotalPrice ?? 0;

                        const secondRowFieldsAreDisabled =
                          !inverterBrand || !inverterPotency || !inverterModel;

                        return (
                          <div key={field.key} style={{ width: "100%" }}>
                            <Divider
                              orientation="left"
                              style={{
                                fontSize: 12,
                                color: "var(--primary-500)",
                              }}
                            >
                              Inversor {index + 1}
                            </Divider>

                            {/* START HIDDEN FIELDS */}
                            <Form.Item name={[field.name, "inverterId"]} hidden>
                              <CustomInput.Number readOnly disabled />
                            </Form.Item>

                            <Form.Item
                              name={[field.name, "inverterTotalPrice"]}
                              hidden
                            >
                              <CustomInput.Money readOnly disabled />
                            </Form.Item>

                            <Form.Item
                              name={[field.name, "inverterType"]}
                              hidden
                            >
                              <CustomInput.Text readOnly disabled />
                            </Form.Item>
                            {/* END HIDDEN FIELDS */}

                            <Row
                              wrap={true}
                              gutter={[16, 0]}
                              style={{
                                width: "100%",
                              }}
                            >
                              <Col xs={22} md={6}>
                                <Form.Item
                                  name={[field.name, "inverterBrand"]}
                                  label="Marca do inversor"
                                  rules={[
                                    { required: true, message: "Obrigatório!" },
                                  ]}
                                >
                                  <AutoComplete.InverterManufacturer
                                    placeholder="Marca do inversor"
                                    onChange={(value) =>
                                      onChangeInverterBrand(value, index)
                                    }
                                    allowClear
                                  />
                                </Form.Item>
                              </Col>

                              {screens.xs && (
                                <Col xs={1} md={1}>
                                  <MinusButtonToRemoveLine
                                    disabled={fields.length <= 1}
                                    onClick={() => remove(field.name)}
                                  />
                                </Col>
                              )}

                              <Col xs={24} md={6}>
                                <Form.Item
                                  name={[field.name, "inverterNominalPotency"]}
                                  label="Potência do inversor"
                                  rules={[{ required: true }]}
                                >
                                  <AutoComplete.InverterPower
                                    placeholder="Ex.: 180"
                                    disabled={!inverterBrand}
                                    value={inverterPotency}
                                    onChange={(value) =>
                                      onChangeInverterPotency(value, index)
                                    }
                                    filters={{
                                      manufacturer: inverterBrand,
                                    }}
                                    allowClear
                                    autoCompleteInSingleOption
                                  />
                                </Form.Item>
                              </Col>

                              <Col xs={24} md={11}>
                                <Form.Item
                                  name={[field.name, "inverterModel"]}
                                  label="Modelo do inversor"
                                  rules={[{ required: true, whitespace: true }]}
                                >
                                  <AutoComplete.ProductInverter
                                    value={
                                      inverters?.[field.name].inverterModel
                                    }
                                    placeholder="Digite ao menos 3 letras para auto-completar"
                                    disabled={
                                      !inverterBrand || !inverterPotency
                                    }
                                    filters={{
                                      manufacturer: inverterBrand,
                                      power: inverterPotency,
                                    }}
                                    allowClear
                                    onChange={(value, option) => {
                                      if (!option || preventsFormChange) return;

                                      const fields = form.getFieldsValue();
                                      const { inverters = [] } = fields;

                                      const currentInverterData =
                                        inverters[field.name];

                                      if (
                                        !currentInverterData.inverterBrand ||
                                        !currentInverterData.inverterNominalPotency
                                      ) {
                                        return cleanInverterFields(field.name);
                                      }

                                      const inverterQuantity =
                                        inverters?.[field.name]
                                          .inverterQuantity ?? 0;

                                      const inverterUnityAmount =
                                        (option.record.precoBaseCents ?? 0) /
                                        100;

                                      const inverterTotalPrice =
                                        inverterUnityAmount * inverterQuantity;

                                      if (
                                        inverters?.[field.name] &&
                                        option.record
                                      ) {
                                        Object.assign(inverters[field.name], {
                                          inverterQuantity,
                                          inverterTotalPrice,
                                          inverterUnityAmount,
                                          inverterCategory: "INVERTER",
                                          inverterBrand: option.record.brand,
                                          inverterOutputVoltage:
                                            option.record.outputVoltage,
                                          inverterMaximumPotency:
                                            option.record.maxPower,
                                          inverterNominalPotency:
                                            option.record.nominalPower,
                                          inverterId: option.record?.id,
                                          inverterModel: option.record.model,
                                          inverterPhase: option.record.phase,
                                          inverterType: option.record.type,
                                          inverterRecord: option.record,
                                        } as ISizingKitFormInverter);

                                        form.setFieldsValue({ inverters });
                                      }
                                    }}
                                    autoCompleteInSingleOption
                                  />
                                </Form.Item>
                              </Col>

                              {!screens.xs && (
                                <Col md={1}>
                                  <MinusButtonToRemoveLine
                                    disabled={fields.length <= 1}
                                    onClick={() => remove(field.name)}
                                  />
                                </Col>
                              )}
                            </Row>
                            <Row
                              wrap={true}
                              gutter={[16, 0]}
                              style={{
                                width: "100%",
                              }}
                            >
                              <Col xs={24} md={4}>
                                <Form.Item
                                  name={[field.name, "inverterOutputVoltage"]}
                                  label="Tensão de saída"
                                  rules={[
                                    { required: true, message: "Obrigatório!" },
                                  ]}
                                >
                                  <Select.OutputVoltage
                                    placeholder="Ex.: 220V"
                                    disabled={secondRowFieldsAreDisabled}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={4}>
                                <Form.Item
                                  name={[field.name, "inverterPhase"]}
                                  label="Fase do inversor"
                                >
                                  <Select.Phase
                                    placeholder="Selecione a Fase..."
                                    disabled={secondRowFieldsAreDisabled}
                                    allowClear
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={4}>
                                <Form.Item
                                  name={[field.name, "inverterMaximumPotency"]}
                                  label="Potência máxima"
                                >
                                  <CustomInput.UnitMeasurement
                                    precision="2"
                                    unitMeasurement="VA"
                                    placeholder="Ex.: 200"
                                    disabled={secondRowFieldsAreDisabled}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={6}>
                                <Form.Item
                                  name={[field.name, "inverterQuantity"]}
                                  label="Quantidade"
                                  rules={[
                                    { required: true, message: "Obrigatório" },
                                  ]}
                                  extra={
                                    inverterTotalPrice &&
                                    `Total do inversor: ${parseCurrency(
                                      inverterTotalPrice,
                                    )}`
                                  }
                                  initialValue={1}
                                >
                                  <CustomInput.Number
                                    min={0}
                                    precision={0}
                                    style={{ width: "100%" }}
                                    placeholder="Ex.: 3"
                                    disabled={secondRowFieldsAreDisabled}
                                    onChange={() =>
                                      calculateAndSetInverterTotalValue(index)
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={6}>
                                <Form.Item
                                  name={[field.name, "inverterUnityAmount"]}
                                  label="Valor unitário"
                                  rules={[
                                    { required: true, message: "Obrigatório" },
                                  ]}
                                >
                                  <CustomInput.Money
                                    min={0}
                                    style={{ width: "100%" }}
                                    placeholder="Ex.: 199,99"
                                    disabled={secondRowFieldsAreDisabled}
                                    onChange={() =>
                                      calculateAndSetInverterTotalValue(index)
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                    </Flex>

                    <Divider style={{ margin: "0 0 0.5rem 0" }} />

                    <Button
                      block
                      type="dashed"
                      className="addInverterButton"
                      onClick={() =>
                        add({
                          category: undefined,
                          name: undefined,
                          quantity: 1,
                          unityAmount: 0,
                        })
                      }
                    >
                      <Flex align="center" justify="center" gap={8}>
                        <AiOutlinePlus size={16} />
                        Novo Inversor
                      </Flex>
                    </Button>
                  </>
                )}
              </Form.List>
            ),
          },
          {
            key: "OTHERS",
            forceRender: true, // COLLAPSE BY DEFAULT NOT RENDER COMPONENT BEFORE COLLAPSE
            label: (
              <Flex align="center" justify="space-between" gap="4px">
                <div
                  style={{
                    textAlign: "left",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    minWidth: "100px",
                  }}
                >
                  Lista de outros
                </div>
                <Flex gap={8} align="center">
                  <Tag.Info
                    fieldName="Preço total"
                    fieldValue={parseCurrency(othersTotalPrice)}
                    tagStyles={screens.xs ? { width: "100%" } : undefined}
                  />
                  <Tag.Info
                    fieldName="Quantidade"
                    fieldValue={othersTotalQuantity?.toString()}
                    tagStyles={screens.xs ? { width: "100%" } : undefined}
                  />
                </Flex>
              </Flex>
            ),
            children: (
              <Form.List name="others">
                {(fields, { add, remove }) => (
                  <>
                    <Flex
                      vertical
                      wrap="nowrap"
                      style={{
                        width: "100%",
                        maxHeight: 360,
                        overflowY: "auto",
                      }}
                    >
                      {fields.map((field, index) => {
                        const otherTotalPrice = others?.[index].otherTotalPrice;

                        return (
                          <div key={field.key} style={{ width: "100%" }}>
                            <Divider
                              orientation="left"
                              style={{
                                fontSize: 12,
                                color: "var(--primary-500)",
                              }}
                            >
                              Outro item {index + 1}
                            </Divider>

                            {/* START HIDDEN FIELDS */}
                            <Form.Item name={[field.name, "otherId"]} hidden>
                              <CustomInput.Number readOnly disabled />
                            </Form.Item>

                            <Form.Item
                              name={[field.name, "otherTotalPrice"]}
                              hidden
                            >
                              <CustomInput.Money readOnly disabled />
                            </Form.Item>
                            {/* END HIDDEN FIELDS */}

                            <Row
                              wrap={true}
                              gutter={[16, 0]}
                              style={{
                                width: "100%",
                              }}
                            >
                              <Col xs={22} md={8}>
                                <Form.Item
                                  name={[field.name, "otherName"]}
                                  label="Nome"
                                  rules={[
                                    { required: true, message: "Obrigatório!" },
                                  ]}
                                >
                                  <AutoComplete.Product
                                    placeholder="Digite ao menos 3 letras para auto-completar"
                                    onChange={(value, option) => {
                                      if (!option || preventsFormChange) return;

                                      const fields = form.getFieldsValue();
                                      const { others } = fields;

                                      if (
                                        others?.[field.name] &&
                                        option.record
                                      ) {
                                        Object.assign(others[field.name], {
                                          otherId: option.record.id,
                                          otherName: option.record.name,
                                          otherUnityAmount:
                                            (option.record.priceBaseCents ??
                                              0) / 100,
                                          otherQuantity:
                                            others?.[field.name].otherQuantity,
                                          otherTotalPrice:
                                            others?.[field.name]
                                              .otherTotalPrice,
                                        } as ISizingKitFormOtherItems);

                                        form.setFieldsValue({ inverters });
                                      }
                                    }}
                                  />
                                </Form.Item>
                              </Col>

                              {screens.xs && (
                                <Col xs={2} md={2}>
                                  <MinusButtonToRemoveLine
                                    onClick={() => remove(field.name)}
                                  />
                                </Col>
                              )}

                              <Col xs={24} md={8}>
                                <Form.Item
                                  name={[field.name, "otherQuantity"]}
                                  label="Quantidade"
                                  rules={[
                                    { required: true, message: "Obrigatório" },
                                  ]}
                                  extra={
                                    otherTotalPrice &&
                                    `Total do item: ${parseCurrency(
                                      otherTotalPrice,
                                    )}`
                                  }
                                  initialValue={1}
                                >
                                  <CustomInput.Number
                                    min={0}
                                    precision={0}
                                    style={{ width: "100%" }}
                                    placeholder="Ex.: 3"
                                    onChange={() =>
                                      calculateAndSetOtherTotalValue(index)
                                    }
                                  />
                                </Form.Item>
                              </Col>

                              <Col xs={24} md={7}>
                                <Form.Item
                                  name={[field.name, "otherUnityAmount"]}
                                  label="Valor unitário"
                                  rules={[
                                    { required: true, message: "Obrigatório" },
                                  ]}
                                >
                                  <CustomInput.Money
                                    min={0}
                                    style={{ width: "100%" }}
                                    placeholder="Ex.: 199,99"
                                    onChange={() =>
                                      calculateAndSetOtherTotalValue(index)
                                    }
                                  />
                                </Form.Item>
                              </Col>

                              {!screens.xs && (
                                <Col xs={1} md={1}>
                                  <MinusButtonToRemoveLine
                                    onClick={() => remove(field.name)}
                                  />
                                </Col>
                              )}
                            </Row>
                          </div>
                        );
                      })}
                    </Flex>

                    <Divider style={{ margin: "0 0 0.5rem 0" }} />

                    <Button
                      block
                      type="dashed"
                      className="addInverterButton"
                      onClick={() =>
                        add({
                          category: undefined,
                          name: undefined,
                          quantity: 1,
                          unityAmount: 0,
                        })
                      }
                    >
                      <Flex align="center" justify="center" gap={8}>
                        <AiOutlinePlus size={16} />
                        Novo Item
                      </Flex>
                    </Button>
                  </>
                )}
              </Form.List>
            ),
          },
        ]}
        accordion
        size="small"
        style={{
          marginBottom: "1rem",
        }}
      />

      <Flex gap={12} justify="end">
        <Form.Item style={{ margin: 0 }}>
          <Button onClick={onCancel}>Cancelar</Button>
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Dimensionar
          </Button>
        </Form.Item>
      </Flex>
    </Form>
  );
}
