import { useEffect } from "react";

import type {
  IStructureMaterialCostRuleCardProps,
  IStructureMaterialCostRuleForm,
  StructureMaterialCostType,
} from "./interface";
import { StyledCard } from "./styles";

import { InputMoney } from "~components/CustomInput/Money";
import { Select } from "~components/Select";
import {
  useFetchCreateStructureCalculation,
  useFetchUpdateStructureCalculation,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import type { IStructuresMaterialCostRuleFormSchema } from "~types/schemas";
import { parseInformationToLabel } from "~utils/parseInformationToStructureLabel";
import { Button, Col, Form, Radio, Space, Select as AntdSelect } from "antd";

const DEFAULT_INITIAL_VALUES = {
  costType: "COST_PER_MODULE",
  additionalCostTaxId: undefined,
  notApplyDefaultAdditionalCostTax: true,
  structureType: undefined,
  valuePerModule: 0,
  valuePerWp: 0,
} as IStructureMaterialCostRuleForm;

export function StructureMaterialCostRuleCard({
  data,
  isLoading,
}: IStructureMaterialCostRuleCardProps) {
  const { screens } = useAppConfig();

  const [form] = Form.useForm<IStructureMaterialCostRuleForm>();
  const costType = Form.useWatch("costType", form);
  const applyTax = Form.useWatch("notApplyDefaultAdditionalCostTax", form);

  const parsedDataLabel =
    data?.structureType?.type &&
    data?.structureType?.type.charAt(0).toLocaleUpperCase() +
      data.structureType.type.slice(1).toLocaleLowerCase() +
      " " +
      (data?.structureType?.subCategory ? data.structureType.subCategory : "") +
      " " +
      parseInformationToLabel(data.structureType);

  const { mutate: createStructureCalculation } =
    useFetchCreateStructureCalculation({});

  const { mutate: updateStructureCalculation } =
    useFetchUpdateStructureCalculation({});

  function onFinish(formData: IStructureMaterialCostRuleForm) {
    if (!data?.structureType?.id) {
      throw new Error("Faltando ID da estrutura!");
    }

    const parsedData = {
      structureType: {
        id: data?.structureType?.id,
      },
      valuePerModule:
        formData.costType === "COST_PER_MODULE"
          ? formData.valuePerModule
          : undefined,
      valuePerWp:
        formData.costType === "COST_PER_WP" ? formData.valuePerWp : undefined,
      additionalCostTaxId: formData.additionalCostTaxId,
      notApplyDefaultAdditionalCostTax:
        formData.notApplyDefaultAdditionalCostTax ?? true,
    } as IStructuresMaterialCostRuleFormSchema;

    if (data.id) {
      updateStructureCalculation({
        ...parsedData,
        structuresMaterialCostRuleId: data.id,
      });
    } else {
      createStructureCalculation(parsedData);
    }
  }

  useEffect(() => {
    form.setFieldsValue({
      notApplyDefaultAdditionalCostTax:
        data?.notApplyDefaultAdditionalCostTax ?? true,
      valuePerModule: data?.valuePerModule,
      valuePerWp: data?.valuePerWp,
      additionalCostTaxId: data?.additionalCostTaxId,
      costType: data?.costType,
      structureType: {
        id: data?.structureType?.id,
      },
    });
  }, [data, form]);

  return (
    <Form
      layout="vertical"
      style={{
        margin: 0,
        padding: 0,
        width: screens.xl ? "33%" : screens.md ? "40%" : "100%",
      }}
      form={form}
      onFinish={onFinish}
      scrollToFirstError
      initialValues={DEFAULT_INITIAL_VALUES}
    >
      <Col sm={24}>
        <StyledCard
          title={parsedDataLabel}
          className="card-structure"
          headStyle={{
            fontSize: "14px",
            padding: "1rem",
            minHeight: "70px",
            whiteSpace: "unset",
          }}
          bordered={true}
          loading={isLoading}
          actions={[
            <Button type="link" key="save" onClick={form.submit}>
              Salvar
            </Button>,
          ]}
        >
          <Form.Item<IStructureMaterialCostRuleForm>
            label="Tipo de cálculo"
            name="costType"
            rules={[{ required: true }]}
          >
            <AntdSelect<StructureMaterialCostType>
              options={[
                {
                  label: "Custo por módulo",
                  value: "COST_PER_MODULE",
                },
                {
                  label: "Custo por Wp",
                  value: "COST_PER_WP",
                },
              ]}
              placeholder="Selecione o tipo..."
            />
          </Form.Item>

          <Form.Item<IStructureMaterialCostRuleForm>
            label="Custo por módulo"
            name="valuePerModule"
            rules={[{ required: costType === "COST_PER_MODULE" }]}
            hidden={costType !== "COST_PER_MODULE"}
            required={costType === "COST_PER_MODULE"}
          >
            <InputMoney
              min={0}
              readOnly={costType !== "COST_PER_MODULE"}
              disabled={costType !== "COST_PER_MODULE"}
            />
          </Form.Item>

          <Form.Item<IStructureMaterialCostRuleForm>
            label="Custo por Wp"
            name="valuePerWp"
            rules={[{ required: costType === "COST_PER_WP" }]}
            hidden={costType !== "COST_PER_WP"}
            required={costType === "COST_PER_WP"}
          >
            <InputMoney
              min={0}
              readOnly={costType !== "COST_PER_WP"}
              disabled={costType !== "COST_PER_WP"}
            />
          </Form.Item>

          <Form.Item<IStructureMaterialCostRuleForm>
            label="Aplicar Imposto pós precificado"
            tooltip="Alíquota aplicada por fora ao final dos cálculos de precificação, útil quando há alíquotas de imposto diferente nos itens complementares ou quando há custos precificado em % sobre o global e custos em % precificados sobre os custos complementares simultaneamente e ainda seja necessário cálculo de imposto"
            name="notApplyDefaultAdditionalCostTax"
          >
            <Radio.Group
              onChange={(event) => {
                if (event.target.value === false) {
                  form.resetFields(["additionalCostTaxId"]);
                }
              }}
            >
              <Space>
                <Radio value={true}>Sim</Radio>
                <Radio value={false}>Não aplicar</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>

          <Form.Item<IStructureMaterialCostRuleForm>
            label="Escolher imposto pós precificado"
            rules={[{ required: applyTax }]}
            name="additionalCostTaxId"
          >
            <Select.TaxesAfterPricing
              onChange={(value) => {
                form.setFieldValue("additionalCostTaxId", value);
              }}
              isEditForm={!!data?.id}
              disabled={!applyTax}
              applyTax={!applyTax}
            />
          </Form.Item>
        </StyledCard>
      </Col>
    </Form>
  );
}
