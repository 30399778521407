import { useMemo, useState } from "react";
import { BiCog, BiLinkExternal } from "react-icons/bi";
import { BsPersonFillLock } from "react-icons/bs";

import NextLink from "next/link";

import type { IUserActionDropdownMenuProps } from "./interface";
import styles from "./styles";

import { Avatar } from "~components/Avatar";
import { Button } from "~components/Button";
import { Card } from "~components/Card";
import { Modal } from "~components/Modal";
import { useSession } from "~hooks/useSession";
import { Button as AntdButton, Dropdown, Flex } from "antd";
import type { ItemType } from "antd/lib/menu/hooks/useItems";

export function UserActionDropdownMenu({
  hideOptions = {
    configs: false,
    changePassword: false,
    changeProfileImage: false,
  },
}: IUserActionDropdownMenuProps) {
  const { user, sessionStatus } = useSession();

  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [isChangeProfileImageModalOpen, setIsChangeProfileImageModalOpen] =
    useState(false);

  const MENU_OPTIONS = useMemo(() => {
    const options = [
      {
        key: "userInfo",
        type: "group",
        label: <Card.UserInfo displayRule />,
        style: {
          marginBottom: 8,
        },
      },
      {
        key: "configs",
        label: (
          <NextLink
            href="/configuracoes-gerais"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AntdButton
              type="link"
              className="menuItem configItem"
              size="small"
            >
              <Flex align="center" justify="space-between">
                <Flex align="center" gap={8}>
                  <BiCog size={16} />
                  Configurações
                </Flex>

                <BiLinkExternal size={16} />
              </Flex>
            </AntdButton>
          </NextLink>
        ),
        hidden: hideOptions.configs,
      },
      {
        key: "change-password",
        label: (
          <AntdButton
            type="link"
            className="menuItem changePasswordItem"
            size="small"
            onClick={() => setIsChangePasswordModalOpen(true)}
          >
            <Flex align="center" justify="space-between">
              <Flex align="center" gap={8}>
                <BsPersonFillLock size={16} />
                Alterar minha senha
              </Flex>
            </Flex>
          </AntdButton>
        ),
        hidden: hideOptions.changePassword,
      },
      {
        key: "change-profile-image",
        label: (
          <AntdButton
            type="link"
            className="menuItem changeProfileImageItem"
            size="small"
            onClick={() => setIsChangeProfileImageModalOpen(true)}
          >
            <Flex align="center" justify="space-between">
              <Flex align="center" gap={8}>
                <BsPersonFillLock size={16} />
                Alterar minha imagem de perfil
              </Flex>
            </Flex>
          </AntdButton>
        ),
        hidden: hideOptions.changeProfileImage,
      },
      {
        key: "divider",
        type: "divider",
        hidden: hideOptions.configs && hideOptions.changePassword,
      },
      {
        key: "theme",
        label: <Button.ControlTheme />,
      },
      {
        key: "logout",
        label: <Button.Logout />,
      },
    ];

    const filteredOptions: ItemType[] = options.filter((item) => !item.hidden);

    return filteredOptions;
  }, [
    hideOptions.changePassword,
    hideOptions.changeProfileImage,
    hideOptions.configs,
  ]);

  const forceUserToChangePassword =
    !!user?.initialAccess && !user?.pipedriveImportedUser;

  return (
    <>
      <style jsx>{styles}</style>
      <Dropdown
        data-cy="user-action-dropdown-menu"
        menu={{
          items: MENU_OPTIONS,
        }}
        trigger={["hover"]}
        className="dropdown"
        overlayStyle={{
          width: 320,
        }}
        rootClassName="rootDropdown"
      >
        <Avatar.CustomUser
          name={user?.name}
          avatarUrl={user?.avatarUrl}
          userId={user?.id}
          sessionStatus={sessionStatus}
          height={32}
          width={32}
        />
      </Dropdown>

      <Modal.ChangePasswordForm
        isOpen={forceUserToChangePassword || isChangePasswordModalOpen}
        onClose={() => setIsChangePasswordModalOpen(false)}
        formProps={{
          onSuccess: () => setIsChangePasswordModalOpen(false),
        }}
        closable={!forceUserToChangePassword}
      />
      <Modal.ChangeProfileImageForm
        isOpen={isChangeProfileImageModalOpen}
        onClose={() => setIsChangeProfileImageModalOpen(false)}
        formProps={{
          onSuccess: () => setIsChangeProfileImageModalOpen(false),
        }}
      />
    </>
  );
}
