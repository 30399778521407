import { ISolarzFormItemProps } from "./interface";
import { WrapperFormItem } from "./styles";

import { Form } from "antd";

const { Item: FormItem } = Form;

export function SolarzFormItem<FormItem extends Record<string, any>>({
  orientation = "vertical",
  help,
  label,
  style,
  required,
  rules,
  tooltip,
  children,
  name,
  colon = false,
  hidden = false,
  valuePropName,
  preserve,
}: ISolarzFormItemProps<FormItem>) {
  return (
    <WrapperFormItem
      orientation={orientation}
      style={{
        marginBottom: "0.75rem",
        ...style,
      }}
    >
      <FormItem<FormItem>
        help={help}
        label={label}
        required={required}
        rules={rules}
        tooltip={tooltip}
        name={name}
        colon={colon}
        valuePropName={valuePropName}
        hidden={hidden}
        preserve={preserve}
        className="solarz-form-item"
        style={{ margin: 0 }}
      >
        {children}
      </FormItem>
    </WrapperFormItem>
  );
}
