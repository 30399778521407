import { useMemo } from "react";

import type {
  IVoltageTypeSelectProps,
  IVoltageTypeSelectOption,
} from "./interface";

import { Select } from "antd";

export function VoltageTypeSelect({
  value,
  onChange,
  defaultValue,
  disabledValues,
  ...props
}: IVoltageTypeSelectProps) {
  const OPTIONS: IVoltageTypeSelectOption[] = useMemo(
    () => [
      {
        label: "Baixa tensão",
        value: "LOW" as const,
        disabled: disabledValues?.LOW,
      },
      {
        label: "Média tensão",
        value: "MEDIUM" as const,
        disabled: disabledValues?.MEDIUM,
      },
    ],
    [disabledValues],
  );

  return (
    <Select
      {...props}
      options={OPTIONS}
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
      data-cy="select-tension-type"
    />
  );
}
