import { useEffect, useMemo, useState } from "react";
import { AiOutlineMinusCircle, AiOutlinePlus } from "react-icons/ai";

import {
  IPeriods,
  IPeriodsGroupFormData,
  IProposalExpirationRuleFormProps,
  IPeriodsGroupForm,
} from "./interface";

import { CustomInput } from "~components/CustomInput";
import {
  useFetchCreateProposalExpirationRule,
  useFetchUpdateProposalExpirationRule,
} from "~hooks/api";
import { createUuid } from "~utils/createUuid";
import { Button, Col, Divider, Flex, Form, Popconfirm, Row, Spin } from "antd";

export function ProposalExpirationRuleForm({
  initialValues,
  onSubmitFail = () => undefined,
  onSubmitSuccess = () => undefined,
  onCancel = () => undefined,
}: IProposalExpirationRuleFormProps) {
  const [form] = Form.useForm();

  const INITIAL_PERIODS = useMemo(() => {
    const parsedPeriods = initialValues?.periods
      ? Object.entries(initialValues.periods).map(([key, value]) => ({
          key: createUuid(),
          potency: Number(key),
          days: value,
        }))
      : [];

    return parsedPeriods;
  }, [initialValues]);

  const [periods, setPeriods] = useState<IPeriodsGroupFormData[]>([]);

  function handleCreateNewFormLine() {
    const newPeriod: IPeriodsGroupFormData = {
      key: createUuid(),
      potency: 0,
      days: 0,
    };

    setPeriods((state) => [...state, { ...newPeriod }]);
  }

  function handleDeleteFormLine(key: string) {
    setPeriods((state) => state.filter((item) => item.key !== key));
  }

  function handleResetForm() {
    setPeriods(INITIAL_PERIODS);
  }

  function onChangeData(key: string, field: keyof IPeriods, value: any) {
    setPeriods((state) => {
      const period = state.find((p) => p.key === key);

      if (!period) return state;

      const updatedPeriod = {
        ...period,
        [field]: value,
      };

      return state.map((p) => (p.key === key ? updatedPeriod : p));
    });
  }

  const {
    mutate: createProposalExpirationRule,
    isLoading: isCreatingProposalExpirationRule,
  } = useFetchCreateProposalExpirationRule({
    options: {
      onSuccess: onSubmitSuccess,
      onError: onSubmitFail,
    },
  });

  const {
    mutate: updateProposalExpirationRule,
    isLoading: isUpdatingProposalExpirationRule,
  } = useFetchUpdateProposalExpirationRule({
    options: {
      onSuccess: onSubmitSuccess,
      onError: onSubmitFail,
    },
  });

  function handleSubmitForm(value: IPeriodsGroupForm) {
    const convertedPeriods: Record<string, number> = periods.reduce(
      (acc, period) => {
        acc[String(period.potency)] = period.days;
        return acc;
      },
      {} as Record<string, number>,
    );

    if (initialValues?.id) {
      updateProposalExpirationRule({
        expirationRuleId: initialValues.id,
        periods: convertedPeriods,
        defaultPeriod: value.defaultPeriod,
      });
    } else {
      createProposalExpirationRule({
        periods: convertedPeriods,
        defaultPeriod: value.defaultPeriod,
      });
    }
  }

  useEffect(() => {
    const parsedPeriods = initialValues?.periods
      ? Object.entries(initialValues.periods).map(([key, value]) => ({
          key: createUuid(),
          potency: Number(key),
          days: value,
        }))
      : [];

    setPeriods(parsedPeriods ?? []);
  }, [initialValues]);

  const isLoading =
    isCreatingProposalExpirationRule || isUpdatingProposalExpirationRule;

  return (
    <Spin
      data-cy="period-group-form"
      spinning={isLoading}
      tip="Loading..."
      style={{ marginTop: "-2rem" }}
    >
      <Form<IPeriodsGroupForm>
        form={form}
        initialValues={{
          periods: INITIAL_PERIODS,
          defaultPeriod: initialValues?.defaultPeriod,
        }}
        onFinish={handleSubmitForm}
        layout="vertical"
      >
        <section
          style={{
            maxHeight: 480,
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <Form.Item
            label="Valor padrão de validade da proposta em dias"
            name="defaultPeriod"
            tooltip="Se nenhum valor padrão for definido, será automaticamente atribuído um valor padrão de 30 dias."
          >
            <CustomInput.Number precision={0} placeholder="30" min={0} />
          </Form.Item>
          {periods.map((period) => (
            <Row
              align="middle"
              wrap={false}
              justify="center"
              gutter={[16, 0]}
              key={period.key}
            >
              <Col flex={1}>
                <Form.Item
                  label="Potência"
                  tooltip="A data de validade da proposta em dias é aplicada com base na faixa de potência imediatamente anterior (ou 0 caso não haja uma menor) até a potência cadastrada neste formulário."
                  rules={[{ required: true }]}
                >
                  <CustomInput.UnitMeasurement
                    precision="2"
                    unitMeasurement="kVa"
                    data-cy="input-power"
                    placeholder="Ex.: 5,00"
                    value={period.potency}
                    onChange={(value) => {
                      const parsedValue = Number(value);
                      const trulyValue = isNaN(parsedValue) ? 0 : parsedValue;
                      onChangeData(period.key, "potency", trulyValue);
                    }}
                    min={0}
                  />
                </Form.Item>
              </Col>

              <Col flex={1}>
                <Form.Item label="Dias" rules={[{ required: true }]}>
                  <CustomInput.Number
                    placeholder="Ex.: 30"
                    precision={0}
                    data-cy="input-days"
                    value={period.days}
                    onChange={(value) => {
                      const parsedValue = Number(value);
                      const trulyValue = isNaN(parsedValue) ? 0 : parsedValue;
                      onChangeData(period.key, "days", trulyValue);
                    }}
                    min={0}
                  />
                </Form.Item>
              </Col>

              <Col>
                <Flex justify="center" align="center">
                  <Button
                    type="text"
                    size="small"
                    shape="circle"
                    style={{ lineHeight: 0 }}
                    disabled={periods.length === 1}
                    onClick={() => handleDeleteFormLine(period.key)}
                  >
                    <AiOutlineMinusCircle size={16} />
                  </Button>
                </Flex>
              </Col>
            </Row>
          ))}
        </section>

        <Divider style={{ margin: "0 0 0.5rem 0" }} />

        <Flex vertical gap={0} wrap="nowrap">
          <Button
            block
            type="dashed"
            className="addRowButton"
            onClick={handleCreateNewFormLine}
          >
            <Flex align="center" justify="center" gap={8}>
              <AiOutlinePlus size={16} />
              Novo período
            </Flex>
          </Button>
          <Flex align="center">
            Voltar ao
            <Popconfirm
              title="Tem certeza? - As suas modificações serão desfeitas"
              onConfirm={handleResetForm}
              okText="Sim"
              cancelText="Não"
            >
              <Button style={{ marginLeft: "-2px" }} type="link" size="small">
                <span style={{ textDecoration: "underline" }}>
                  estado inicial?
                </span>
              </Button>
            </Popconfirm>
          </Flex>
        </Flex>

        <Flex gap={12} justify="end" style={{ marginTop: "1.5rem" }}>
          <Form.Item style={{ margin: 0 }}>
            <Button onClick={onCancel}>Cancelar</Button>
          </Form.Item>
          <Form.Item style={{ margin: 0 }}>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Salvar
            </Button>
          </Form.Item>
        </Flex>
      </Form>
    </Spin>
  );
}
