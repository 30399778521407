import type {
  IResetPasswordFormProps,
  IResetPasswordFormValues,
} from "./interface";

import { CustomInput } from "~components/CustomInput";
import { useFetchSendNewPassword } from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { checkIfIsValidUuid } from "~utils/checkIfIsValidUuid";
import { checkIfPasswordIsValid } from "~utils/checkIfPasswordIsValid";
import { Button, Form, Flex } from "antd";

export function ResetPasswordForm({
  token = "",
  initialValues = {
    password: "",
  },
  onSubmitFail = () => undefined,
  onSubmitSuccess = () => undefined,
  extra = <></>,
}: IResetPasswordFormProps) {
  const { message } = useAppConfig();

  const [form] = Form.useForm<IResetPasswordFormValues>();

  const { mutate: fetchSendNewPassword, isLoading: isChangingPassword } =
    useFetchSendNewPassword({
      options: {
        onSuccess: onSubmitSuccess,
        onError: onSubmitFail,
      },
    });

  function validatePasswordField() {
    const password = form.getFieldValue("password") as string;
    const confirmPassword = form.getFieldValue("confirmPassword") as string;

    if (password !== confirmPassword) {
      return form.setFields([
        {
          name: "password",
          errors: ["Senha e a confirmação devem ser iguais"],
        },
        {
          name: "confirmPassword",
          errors: ["Senha e a confirmação devem ser iguais"],
        },
      ]);
    }

    if (!password || !confirmPassword) {
      return form.setFields([
        {
          name: "password",
          errors: [!password ? "Senha é obrigatório" : ""],
        },
        {
          name: "confirmPassword",
          errors: [
            !confirmPassword ? "Confirmação da senha é obrigatória" : "",
          ],
        },
      ]);
    }

    const passwordIsValid = checkIfPasswordIsValid(password);
    const confirmPasswordIsValid = checkIfPasswordIsValid(confirmPassword);

    if (!passwordIsValid || !confirmPasswordIsValid) {
      return form.setFields([
        {
          name: "password",
          errors: [
            !passwordIsValid ? "A senha não segue a regra estabelecida" : "",
          ],
        },
        {
          name: "confirmPassword",
          errors: [
            !confirmPasswordIsValid
              ? "A senha não segue a regra estabelecida"
              : "",
          ],
        },
      ]);
    }

    return true;
  }

  function handleSubmit(data: IResetPasswordFormValues) {
    const isFormValid = !!validatePasswordField();

    const tokenIsValid = checkIfIsValidUuid(token);

    if (!tokenIsValid) {
      message.error("Client error: Faltando o token de alteração");
      return;
    }

    if (isFormValid) {
      fetchSendNewPassword({
        password: data.password,
        token,
      });
    }
  }

  return (
    <Form
      data-cy="reset-password-form"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
      layout="vertical"
      style={{ width: "100%" }}
    >
      <Form.Item
        name="password"
        label="Nova senha"
        rules={[{ required: true }]}
        tooltip="Regra: Senha deve ter ao menos seis caracteres, uma letra maiúscula e um caractere especial"
      >
        <CustomInput.Text type="text" />
      </Form.Item>

      <Form.Item
        name="confirmPassword"
        label="Repetir nova senha"
        rules={[{ required: true }]}
        style={{ marginBottom: "1rem" }}
        tooltip="Regra: Senha deve ter ao menos seis caracteres, uma letra maiúscula e um caractere especial"
      >
        <CustomInput.Text type="text" />
      </Form.Item>

      <Form.Item>
        <Flex align="center" justify="end" gap={4}>
          {extra}

          <Button type="primary" htmlType="submit" loading={isChangingPassword}>
            Enviar e-mail
          </Button>
        </Flex>
      </Form.Item>
    </Form>
  );
}
