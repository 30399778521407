import type { IUniqueValueTag } from "./interface";

import { Tag, Tooltip } from "antd";

export function UniqueValueTag({
  color = "blue",
  title = "",
  tooltip,
  style,
}: IUniqueValueTag) {
  return (
    <Tooltip data-cy="unique-value-tag" title={tooltip}>
      <Tag
        color={color}
        style={{
          padding: "0 0.5rem",
          margin: 0,
          width: "auto",
          fontSize: "0.75rem",
          cursor: "pointer",
          ...style,
        }}
      >
        {title}
      </Tag>
    </Tooltip>
  );
}
