import { useMemo, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { FaPen } from "react-icons/fa";

import { IUserGroupListProps } from "./interface";

import { Menu } from "~components/Menu";
import { Modal } from "~components/Modal";
import { createPaginationTableObject } from "~components/Pagination";
import { Result } from "~components/Result";
import {
  useFetchDeleteUserGroup,
  useFetchGetPaginatedUserGroup,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { IUserGroupDTOSchema } from "~types/schemas";
import { Flex, List } from "antd";

export function UserGroupList(props: IUserGroupListProps) {
  const { screens } = useAppConfig();

  const [currentPage, setCurrentPage] = useState(1);

  const [userGroupToDelete, setUserGroupToDelete] =
    useState<IUserGroupDTOSchema>();

  const [userGroupToUpdate, setUserGroupToUpdate] =
    useState<IUserGroupDTOSchema>();

  const { data, isFetching, error } = useFetchGetPaginatedUserGroup({
    payload: {
      page: currentPage - 1,
      size: 5,
    },
    dependencyArray: [currentPage],
    options: {
      enabled: currentPage > 0,
    },
  });

  const { mutate: handleDeleteUserGroup, isLoading: isDeletingUserGroup } =
    useFetchDeleteUserGroup({
      onSuccess: () => setUserGroupToDelete(undefined),
    });

  const DATA_SOURCE: IUserGroupDTOSchema[] = useMemo(
    () => data?.content ?? [],
    [data?.content],
  );

  return (
    <>
      <List
        data-cy="user-group-list"
        {...props}
        itemLayout="horizontal"
        dataSource={DATA_SOURCE}
        loading={isFetching}
        rowKey="id"
        pagination={createPaginationTableObject({
          itemType: "grupos",
          number: currentPage,
          onChange: (page, _) => {
            setCurrentPage(page);
          },
          size: data?.size,
          totalElements: data?.totalElements,
          displayTotalInformation: screens.sm,
        })}
        locale={{
          emptyText: (
            <Result.TableErrorOrEmpty
              emptyMessage="Nenhum grupo encontrado"
              errorMessage={error?.message}
            />
          ),
        }}
        renderItem={(group) => (
          <List.Item style={{ width: "100%" }}>
            <List.Item.Meta
              title={<Flex gap={4}>{group.name}</Flex>}
              description={`Total de usuários: ${group.users.length || 0}`}
            />

            <Menu.MoreDropdown
              items={[
                {
                  icon: <FaPen size={16} color={"var(--gold-500)"} />,
                  text: "Alterar grupo",
                  color: "var(--gold-500)",
                  onClick: () => setUserGroupToUpdate(group),
                },
                {
                  icon: <AiOutlineDelete size={16} color={"var(--red-500)"} />,
                  text: "Remover grupo",
                  color: "var(--red-500)",
                  onClick: () => setUserGroupToDelete(group),
                },
              ]}
              position="bottomLeft"
            />
          </List.Item>
        )}
      />

      <Modal.DefaultRemove
        isLoading={isDeletingUserGroup}
        isOpen={!!userGroupToDelete}
        itemName={userGroupToDelete?.name}
        onClose={() => setUserGroupToDelete(undefined)}
        suffix="grupo de usuários"
        onSubmit={() =>
          handleDeleteUserGroup({ userGroupId: userGroupToDelete?.id || 0 })
        }
        title="Remover grupo de usuários"
      />

      <Modal.UserGroupForm
        isOpen={!!userGroupToUpdate}
        formProps={{
          initialValues: {
            id: userGroupToUpdate?.id,
            name: userGroupToUpdate?.name,
            users: userGroupToUpdate?.users,
          },
          onSuccess: () => setUserGroupToUpdate(undefined),
        }}
        onClose={() => setUserGroupToUpdate(undefined)}
      />
    </>
  );
}
