import { IAddUserFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function AddUserFormModal({
  isOpen = false,
  onClose = () => null,
  title = "Novo Usuário",
  width = 1000,
  formProps,
}: IAddUserFormModalProps) {
  return (
    <Modal
      data-cy="add-user-form-modal"
      width={width}
      title={title}
      footer={false}
      destroyOnClose
      open={isOpen}
      onCancel={onClose}
    >
      <Form.AddUser {...formProps} onCancel={onClose} />
    </Modal>
  );
}
