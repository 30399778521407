import { useState } from "react";

import type { IEdeltecIntegrationSwitchProps } from "./interface";
import styles from "./styles";

import { Modal } from "~components/Modal";
import { useFetchToggleDistributors } from "~hooks/api";
import { Flex, Switch } from "antd";

export function EdeltecIntegrationSwitch({
  isChecked = false,
  distributorId = 0,
}: IEdeltecIntegrationSwitchProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  function handleOpenModal() {
    setIsModalOpen(true);
  }

  function handleCloseModal() {
    setIsModalOpen(false);
  }

  const { mutate: fetchToggleDistributor, isLoading } =
    useFetchToggleDistributors();

  function deactivateIntegration() {
    fetchToggleDistributor({
      distributorId,
    });
  }

  return (
    <>
      <style jsx>{styles}</style>
      <Flex data-cy="edeltec-integration-switch" align="center" gap={8}>
        <Switch
          checked={isChecked}
          checkedChildren="ativo"
          unCheckedChildren="inativo"
          className="edeltec-integration-switch"
          onClick={isChecked ? deactivateIntegration : handleOpenModal}
          loading={isLoading}
        />
      </Flex>

      <Modal.EdeltecIntegrationForm
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        formProps={{
          onSuccess: handleCloseModal,
        }}
      />
    </>
  );
}
