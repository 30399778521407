import { IBrazilianStateSelectProps } from "./interface";

import { Select } from "antd";

const BRAZILIAN_STATES = [
  {
    value: "Acre",
    label: "AC - Acre",
  },
  {
    value: "Alagoas",
    label: "AL - Alagoas",
  },
  {
    value: "Amapá",
    label: "AP - Amapá",
  },
  {
    value: "Amazonas",
    label: "AM - Amazonas",
  },
  {
    value: "Bahia",
    label: "BA - Bahia",
  },
  {
    value: "Ceará",
    label: "CE - Ceará",
  },
  {
    value: "Distrito Federal",
    label: "DF - Distrito Federal",
  },
  {
    value: "Espírito Santo",
    label: "ES - Espírito Santo",
  },
  {
    value: "Goiás",
    label: "GO - Goiás",
  },
  {
    value: "Maranhão",
    label: "MA - Maranhão",
  },
  {
    value: "Mato Grosso",
    label: "MT - Mato Grosso",
  },
  {
    value: "Mato Grosso do Sul",
    label: "MS - Mato Grosso do Sul",
  },
  {
    value: "Minas Gerais",
    label: "MG - Minas Gerais",
  },
  {
    value: "Pará",
    label: "PA - Pará",
  },
  {
    value: "Paraíba",
    label: "PB - Paraíba",
  },
  {
    value: "Paraná",
    label: "PR - Paraná",
  },
  {
    value: "Pernambuco",
    label: "PE - Pernambuco",
  },
  {
    value: "Piauí",
    label: "PI - Piauí",
  },
  {
    value: "Rio de Janeiro",
    label: "RJ - Rio de Janeiro",
  },
  {
    value: "Rio Grande do Norte",
    label: "RN - Rio Grande do Norte",
  },
  {
    value: "Rio Grande do Sul",
    label: "RS - Rio Grande do Sul",
  },
  {
    value: "Rondônia",
    label: "RO - Rondônia",
  },
  {
    value: "Roraima",
    label: "RR - Roraima",
  },
  {
    value: "Santa Catarina",
    label: "SC - Santa Catarina",
  },
  {
    value: "São Paulo",
    label: "SP - São Paulo",
  },
  {
    value: "Sergipe",
    label: "SE - Sergipe",
  },
  {
    value: "Tocantins",
    label: "TO - Tocantins",
  },
];

export function BrazilianStateSelect(props: IBrazilianStateSelectProps) {
  return (
    <Select
      {...props}
      showSearch
      filterOption={(input, option) => {
        const label = option?.label?.toString() ?? "";
        return label?.toLowerCase().includes(input.toLowerCase());
      }}
      optionFilterProp="children"
      placeholder="Selecione o estado..."
      options={BRAZILIAN_STATES}
      onFocus={(event: any) => {
        event.target.autocomplete = "do-not-autofill";
      }}
      style={{ width: "100%", ...props.style }}
    />
  );
}
