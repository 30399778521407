import { translateStructureType } from "./translate";

import type { IStructureTypeDTOSchema } from "~types/schemas";

export function parseFixationToLabel(fixation: string) {
  switch (fixation) {
    case "METALICA":
      return "Estrutura metalica";
    case "MADEIRA":
      return "Estrutura em madeira";
    case "PERFIL_CONTINUO":
      return "Perfil contínuo";
    case "MINI_TRILHO":
      return "Mini trilho";
    case "CONCRETO":
      return "Estrutura em concreto";
  }
}

export function parseOrientationToLabel(orientation: string) {
  switch (orientation) {
    case "ORIENTACAO_PAISAGEM":
      return "Orientação paisagem";
    case "ORIENTACAO_RETRATO":
      return "Orientação retrato";
  }
}

export function parseComplementToLabel(complement: string) {
  switch (complement) {
    case "ESPESSURA_4_MM":
      return "Espessura (4mm)";
    case "CALHETAO_ALTO":
      return "Calhetão alto";
  }
}

export function parseUnitSizeToLabel(unitSize?: string) {
  switch (unitSize) {
    case "MM":
      return "mm";
    case "M":
      return "m";
  }
}

export function parseInformationToLabel(structure: IStructureTypeDTOSchema) {
  if (structure.details?.orientation)
    return "- " + parseOrientationToLabel(structure.details.orientation);
  if (structure.details?.complement)
    return "- " + parseComplementToLabel(structure.details.complement);

  if (structure.fixation && structure.size && structure.unitSize) {
    return (
      "- " +
      parseFixationToLabel(structure.fixation) +
      " (" +
      structure.size +
      parseUnitSizeToLabel(structure.unitSize) +
      ")"
    );
  } else if (structure.fixation) {
    return "- " + parseFixationToLabel(structure.fixation);
  }

  return "";
}

function capitalizeWord(text?: string) {
  if (!text) {
    return "";
  }
  return text.charAt(0).toLocaleUpperCase() + text.slice(1).toLocaleLowerCase();
}

export function parseStructureToLabel(structure: IStructureTypeDTOSchema) {
  const translatedStructureType =
    translateStructureType(structure.type) || "Desconhecido";

  if (structure.type === "SEM_ESTRUTURA") return "Sem Estrutura";

  return (
    capitalizeWord(translatedStructureType) +
    " " +
    capitalizeWord(structure.subCategory) +
    " " +
    parseInformationToLabel(structure)
  );
}
