import type { ILoggedUserAvatar } from "./interface";
import { LoadingAvatar, StyledAvatar } from "./styles";

import { useSession } from "~hooks/useSession";
import { getAvatarColorByIdAndName } from "~utils/getAvatarColorByIdAndName";
import { getTwoFirstLatterOfFullName } from "~utils/getTwoFirstLatterOfFullName";

export function LoggedUserAvatarBackup({
  variant = "medium",
}: ILoggedUserAvatar) {
  const { user, sessionStatus } = useSession();

  const avatarLetters = getTwoFirstLatterOfFullName(user?.name ?? "")
    .join("")
    .toUpperCase();

  const avatarColor = getAvatarColorByIdAndName(
    user?.id ?? 0,
    user?.name ?? "",
  );

  if (sessionStatus === "loading") {
    return <LoadingAvatar variant={variant} />;
  }

  return (
    <StyledAvatar
      data-cy="user-avatar"
      variant={variant}
      style={{ background: avatarColor }}
    >
      {avatarLetters}
    </StyledAvatar>
  );
}
