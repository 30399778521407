import type { IPersonCardProps } from "./interface";
import { EditButton } from "./styles";

import { Alert } from "~components/Alert";
import { Modal } from "~components/Modal";
import { Edit } from "~estrela-ui/icons";
import { useFetchGetPersonById } from "~hooks/api";
import { useQueryParams, parseFunctions } from "~hooks/useQueryParams";
import { SolarzAnchor } from "~solarzui/SolarzAnchor";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { formatTelephoneWithDdd } from "~utils/format";
import { Card, Col, Flex, Row, Skeleton } from "antd";

const ORGANIZATION_MODAL_QUERY = "person-update";

export function PersonCard({ personId, style }: IPersonCardProps) {
  const { getParsedQueryParams, handleSaveInQueryParams } = useQueryParams();

  const {
    data: personData,
    isFetching: isLoadingPerson,
    error: personError,
    refetch: reloadPersonData,
  } = useFetchGetPersonById({
    payload: {
      personId,
    },
    dependencyArray: [personId],
    options: {
      enabled: personId > 0,
      retry: 1,
    },
  });

  const personIdToUpdate = getParsedQueryParams(
    ORGANIZATION_MODAL_QUERY,
    parseFunctions.NUMBER,
  );

  function handleOpenPersonModal() {
    handleSaveInQueryParams({ [ORGANIZATION_MODAL_QUERY]: personId });
  }

  function handleClosePersonModal() {
    handleSaveInQueryParams({ [ORGANIZATION_MODAL_QUERY]: "" });
  }

  const isLoading = personId === 0 || isLoadingPerson;

  return (
    <>
      <Card
        style={{
          width: "100%",
          maxWidth: "400px",
          ...style,
        }}
        styles={{
          body: {
            padding: 16,
          },
        }}
        title={
          <Flex justify="space-between" align="center">
            <SolarzTypography
              fontWeight="semiBold"
              hierarchy="paragraph1"
              variant="title"
            >
              Detalhes
            </SolarzTypography>

            <EditButton
              scale="tiny"
              variant="ghost"
              icon={<Edit style={{ color: "var(--primary-500)" }} />}
              onClick={handleOpenPersonModal}
              isDisabled={personId === 0}
            />
          </Flex>
        }
      >
        <Flex vertical gap={16}>
          {personError && (
            <Alert.CardError
              title="Falha ao carregar dados"
              errorMessage={personError?.message}
              reloadFn={reloadPersonData}
            />
          )}

          <Row align="top" gutter={24} wrap={false}>
            <Col span={10}>
              <SolarzTypography
                fontWeight="medium"
                variant="title"
                hierarchy="paragraph2"
                style={{ textAlign: "start" }}
              >
                Nome
              </SolarzTypography>
            </Col>
            <Col span={14}>
              {isLoading || personError ? (
                <Skeleton.Input active={isLoadingPerson} size="small" />
              ) : (
                <SolarzTypography
                  fontWeight="regular"
                  variant="disabled"
                  hierarchy="small"
                >
                  {personData?.name}
                </SolarzTypography>
              )}
            </Col>
          </Row>

          <Row align="top" gutter={24} wrap={false}>
            <Col span={10}>
              <SolarzTypography
                fontWeight="medium"
                variant="title"
                hierarchy="paragraph2"
                style={{ textAlign: "start" }}
              >
                Organização
              </SolarzTypography>
            </Col>
            <Col span={14}>
              {isLoading || personError ? (
                <Skeleton.Input active={isLoadingPerson} size="small" />
              ) : (
                <SolarzTypography
                  fontWeight="regular"
                  variant="disabled"
                  hierarchy="small"
                >
                  <SolarzAnchor
                    isNextLink
                    aria-label="Samuel Melo"
                    href={`/clientes/empresa/${personData?.organization?.id}`}
                    defaultUnderlined={false}
                    isDisabled={!personData?.organization?.id}
                    style={{ textAlign: "start" }}
                  >
                    {personData?.organization?.name}
                  </SolarzAnchor>
                </SolarzTypography>
              )}
            </Col>
          </Row>

          <Row align="top" gutter={24} wrap={false}>
            <Col span={10}>
              <SolarzTypography
                fontWeight="medium"
                variant="title"
                hierarchy="paragraph2"
                style={{ textAlign: "start" }}
              >
                Email
              </SolarzTypography>
            </Col>
            <Col span={14}>
              {isLoading || personError ? (
                <Skeleton.Input active={isLoadingPerson} size="small" />
              ) : (
                <SolarzTypography
                  fontWeight="regular"
                  variant="disabled"
                  hierarchy="small"
                  style={{ textAlign: "start" }}
                >
                  {personData?.email}
                </SolarzTypography>
              )}
            </Col>
          </Row>

          <Row align="top" gutter={24} wrap={false}>
            <Col span={10}>
              <SolarzTypography
                fontWeight="medium"
                variant="title"
                hierarchy="paragraph2"
                style={{ textAlign: "start" }}
              >
                Telefone
              </SolarzTypography>
            </Col>
            <Col span={14}>
              {isLoading || personError ? (
                <Skeleton.Input active={isLoadingPerson} size="small" />
              ) : (
                <SolarzTypography
                  fontWeight="regular"
                  variant="disabled"
                  hierarchy="small"
                  style={{ textAlign: "start" }}
                >
                  {formatTelephoneWithDdd(personData?.phone ?? "")}
                </SolarzTypography>
              )}
            </Col>
          </Row>
        </Flex>
      </Card>

      <Modal.PersonForm
        isOpen={!!personIdToUpdate}
        onClose={handleClosePersonModal}
        title="Atualizar pessoa"
        formProps={{
          onSuccess: handleClosePersonModal,
          personId: personIdToUpdate ?? undefined,
        }}
      />
    </>
  );
}
