export * from "./AldoIntegrationSwitch/interface";
import { AldoIntegrationSwitch } from "./AldoIntegrationSwitch";
export * from "./EcoriIntegrationSwitch/interface";
import { EcoriIntegrationSwitch } from "./EcoriIntegrationSwitch";
export * from "./EdeltecIntegrationSwitch/interface";
import { EdeltecIntegrationSwitch } from "./EdeltecIntegrationSwitch";
export * from "./FortlevIntegrationSwitch/interface";
import { FortlevIntegrationSwitch } from "./FortlevIntegrationSwitch";
export * from "./ToggleDistributorsSwitch/interface";
import { ToggleDistributorsSwitch } from "./ToggleDistributorsSwitch";
export * from "./UpdateDealPipedriveSwitch/interface";
import { UpdateDealPipedriveSwitch } from "./UpdateDealPipedriveSwitch";
export * from "./UpdateHideableFieldSwitch/interface";
import { UpdateHideableFieldSwitch } from "./UpdateHideableFieldSwitch";
export * from "./UpdateProposalFieldSwitch/interface";
import { UpdateProposalFieldSwitch } from "./UpdateProposalFieldSwitch";
export * from "./UpdateStructureTypeSwitch/interface";
import { UpdateStructureTypeSwitch } from "./UpdateStructureTypeSwitch";
export * from "./UpdateUserBusinessUnitSwitch/interface";
import { UpdateUserBusinessUnitSwitch } from "./UpdateUserBusinessUnitSwitch";

export const Switch = {
  AldoIntegration: AldoIntegrationSwitch,
  EcoriIntegration: EcoriIntegrationSwitch,
  EdeltecIntegration: EdeltecIntegrationSwitch,
  FortlevIntegration: FortlevIntegrationSwitch,
  ToggleDistributors: ToggleDistributorsSwitch,
  UpdateDealPipedriveSwitch: UpdateDealPipedriveSwitch,
  UpdateHideableField: UpdateHideableFieldSwitch,
  UpdateProposalField: UpdateProposalFieldSwitch,
  UpdateStructureType: UpdateStructureTypeSwitch,
  UpdateUserBusinessUnit: UpdateUserBusinessUnitSwitch,
};
