import { useMemo } from "react";

import type { ISolarzTabsProps } from "./interface";
import { StyledTabs } from "./styles";

export function SolarzTabs<KeyType extends string>({
  destroyInactiveTabPane = true,
  tabs,
  ...props
}: ISolarzTabsProps<KeyType>) {
  const filteredTabs = useMemo(() => {
    return tabs.filter((tab) => !tab.isHidden);
  }, [tabs]);

  return (
    <StyledTabs
      {...props}
      items={filteredTabs}
      destroyInactiveTabPane={destroyInactiveTabPane}
    />
  );
}
