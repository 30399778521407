import { IPaymentSimulationFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Typography } from "~components/Typography";
import { Modal } from "antd";

export function PaymentSimulationFormModal({
  isOpen,
  onClose,
  maxWidth = 1280,
  title = "Detalhes da simulação",
  formProps,
}: IPaymentSimulationFormModalProps) {
  return (
    <Modal
      data-cy="payment-simulation-form-modal"
      title={title}
      open={isOpen}
      onCancel={onClose}
      footer={null}
      destroyOnClose
      width="90vw"
      style={{ maxWidth }}
    >
      {formProps?.initialValues.id && (
        <Typography.PaymentSimulationEditable
          initialValue={formProps?.initialValues.name}
          simulationId={formProps?.initialValues.id}
          style={{ margin: "1rem 0 1.2rem 0" }}
        />
      )}

      <Form.PaymentSimulationOptions
        onSuccess={onClose}
        onCancel={onClose}
        paymentSimulationId={formProps?.initialValues.id}
      />
    </Modal>
  );
}
