import type { IUserSettingsFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function UserSettingsFormModal({
  isOpen = false,
  onClose = () => null,
  title = "Configurações do usuário",
  maxWidth = 568,
  formProps = {},
}: IUserSettingsFormModalProps) {
  return (
    <Modal
      data-cy="users-settings-form-modal"
      open={isOpen}
      onCancel={onClose}
      title={title}
      width="50vw"
      footer={null}
      destroyOnClose
      style={{ maxWidth }}
      styles={{
        body: {
          maxHeight: "calc(100vh - 240px)",
        },
      }}
    >
      <Form.UserSettings onCancel={onClose} {...formProps} />
    </Modal>
  );
}
