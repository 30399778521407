import { GoPerson } from "react-icons/go";

import { IDeletePersonCardProps } from "./interface";
import { StyledCard } from "./style";

import { Alert } from "~components/Alert";
import { useFetchGetPersonById } from "~hooks/api";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { formatTelephoneWithDdd } from "~utils/format";
import { Skeleton } from "antd";

export function DeletePersonCard({ personId, style }: IDeletePersonCardProps) {
  const {
    data: personData,
    isFetching: isLoadingPerson,
    error: personError,
    refetch: reloadPersonData,
  } = useFetchGetPersonById({
    payload: {
      personId,
    },
    dependencyArray: [personId],
    options: {
      enabled: personId > 0,
      retry: 1,
    },
  });

  return (
    <StyledCard
      style={{ ...style }}
      title={
        <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
          <GoPerson size={24} color="var(--primary-500)" />
          <SolarzTypography
            fontWeight="semiBold"
            hierarchy="paragraph1"
            variant="title"
          >
            {personData?.name}
          </SolarzTypography>
        </div>
      }
    >
      <div>
        {personError && (
          <Alert.CardError
            title="Falha ao carregar dados"
            errorMessage={personError?.message}
            reloadFn={reloadPersonData}
            style={{ marginBottom: 12 }}
          />
        )}
        <div className="flex justify-normal gap-6">
          <div>
            <div className="w-28 ">
              <SolarzTypography
                fontWeight="semiBold"
                variant="title"
                hierarchy="paragraph2"
              >
                CPF
              </SolarzTypography>
            </div>
          </div>
          {isLoadingPerson || personError ? (
            <Skeleton.Input active size="small" />
          ) : (
            <SolarzTypography
              fontWeight="regular"
              variant="disabled"
              hierarchy="small"
              style={{ width: "100%" }}
            >
              {personData?.identifier}
            </SolarzTypography>
          )}
        </div>
        <div className="flex justify-normal gap-6">
          <div className=" align-middle">
            <div className="w-28">
              <SolarzTypography
                fontWeight="semiBold"
                variant="title"
                hierarchy="paragraph2"
              >
                Endereço
              </SolarzTypography>
            </div>
          </div>
          {isLoadingPerson || personError ? (
            <Skeleton.Input active size="small" />
          ) : (
            <SolarzTypography
              fontWeight="regular"
              variant="disabled"
              hierarchy="small"
            >
              {personData?.address?.formattedAddress}
            </SolarzTypography>
          )}
        </div>
        <div className="flex justify-normal gap-6">
          <div className="w-28">
            <SolarzTypography
              fontWeight="semiBold"
              variant="title"
              hierarchy="paragraph2"
            >
              Telefone
            </SolarzTypography>
          </div>
          {isLoadingPerson || personError ? (
            <Skeleton.Input active size="small" />
          ) : (
            <SolarzTypography
              fontWeight="regular"
              variant="disabled"
              hierarchy="small"
            >
              {formatTelephoneWithDdd(personData?.phone ?? "")}
            </SolarzTypography>
          )}
        </div>
        <div className="flex justify-start gap-6">
          <div className="w-28 ">
            <SolarzTypography
              fontWeight="semiBold"
              variant="title"
              hierarchy="paragraph2"
            >
              Responsável
            </SolarzTypography>
          </div>
          <SolarzTypography
            fontWeight="regular"
            variant="disabled"
            hierarchy="small"
            style={{ color: "var(--primary-500)" }}
          >
            {personData?.owner?.name}
          </SolarzTypography>
        </div>
      </div>
    </StyledCard>
  );
}
