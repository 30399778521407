import { createContext, FC, ReactNode, useState } from "react";

import { GOOGLE_MAPS_LIBRARIES, GOOGLE_MAPS_API_KEY } from "../configs";

import { useLoadScript } from "@react-google-maps/api";
import { Loading } from "~components/Loading";

export type Location = {
  latitude?: number;
  longitude?: number;
  placeId?: string;
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  zipCode?: string;
  streetNumber?: number;
  district?: string;
};

export type GoogleMapsContextType = {
  location?: Location;
  onChangeLocation: (location: Location) => void;
};

export type GoogleMapsProviderProps = {
  children?: ReactNode;
  onChangeLocationCallback?: (location: Location) => void;
};

export const GoogleMapsContext = createContext({} as GoogleMapsContextType);

export const GoogleMapsProvider: FC<GoogleMapsProviderProps> = ({
  children,
  onChangeLocationCallback = (_location: Location) => null,
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: GOOGLE_MAPS_LIBRARIES,
    region: "BR",
    language: "pt-br",
    preventGoogleFontsLoading: true,
  });

  const [location, setLocation] = useState<Location>();

  const onChangeLocation = (location: Location) => {
    setLocation(location);
    onChangeLocationCallback(location);
  };

  return (
    <GoogleMapsContext.Provider
      value={{
        location,
        onChangeLocation,
      }}
    >
      {isLoaded ? (
        children
      ) : (
        <Loading
          style={{ height: "auto", gap: 12, flexDirection: "row" }}
          size="default"
          tip="Carregando Google api..."
        >
          <></>
          {/* Spin tip prop must be used when Spin have a children. */}
        </Loading>
      )}
    </GoogleMapsContext.Provider>
  );
};

export const GoogleMapsConsumer = GoogleMapsContext.Consumer;
