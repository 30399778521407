import { useState, useMemo, useEffect } from "react";

import { IModuleManufacturerAutoCompleteProps } from "./interface";

import { AutoCompleteSuffixIcon } from "~components/Others";
import { Result } from "~components/Result";
import { useFetchGetPaginatedProductModuleManufacturers } from "~hooks/api";
import { createUuid } from "~utils/createUuid";
import { AutoComplete } from "antd";

export function ModuleManufacturerAutoComplete({
  allowClear = true,
  onChange,
  autoCompleteInSingleOption = false,
  onClear,
  ...props
}: IModuleManufacturerAutoCompleteProps) {
  const [inputValue, setInputValue] = useState("");

  const CAN_LOAD_DATA = useMemo(() => inputValue.length >= 3, [inputValue]);

  const { data, isFetching, error, refetch } =
    useFetchGetPaginatedProductModuleManufacturers({
      payload: {
        page: 0,
        size: 100,
      },
    });

  const OPTIONS = useMemo(() => {
    return (
      data?.map((manufacturer) => ({
        label: manufacturer,
        value: manufacturer,
        record: manufacturer,
        key: createUuid(),
      })) ?? []
    );
  }, [data]);

  function onSearch(value: string) {
    setInputValue(value);
  }

  const filteredOptions = OPTIONS.filter((opt) =>
    opt.value.toLowerCase().includes(inputValue.toLowerCase()),
  );

  useEffect(() => {
    if (autoCompleteInSingleOption && OPTIONS.length === 1) {
      setInputValue(OPTIONS[0]?.label);

      if (onChange) {
        onChange(OPTIONS[0].label, OPTIONS[0]);
      }
    }
  }, [OPTIONS, autoCompleteInSingleOption, onChange]);

  return (
    <AutoComplete
      {...props}
      data-cy="manufacturer-auto-complete"
      showSearch
      filterOption={false}
      onSearch={onSearch}
      options={filteredOptions}
      allowClear={allowClear}
      status={error ? "error" : ""}
      notFoundContent={
        <Result.SelectErrorOrEmpty
          errorMessage={error?.message}
          emptyMessage="Nenhum fabricante encontrado"
          loading={isFetching}
          reloadFn={refetch}
          disableReload={!CAN_LOAD_DATA}
        />
      }
      suffixIcon={
        <AutoCompleteSuffixIcon hasError={!!error} isLoading={isFetching} />
      }
      onClear={() => {
        setInputValue("");
        if (typeof onClear === "function") {
          onClear();
        }
      }}
      onChange={(value, option) => {
        if (option instanceof Array || !onChange) return;
        onChange(value, option);
      }}
      style={{ width: "100%" }}
    />
  );
}
