import { ISelectErrorOrEmptyResultProps } from "./interface";

import { Empty, Flex, Button, Typography, Spin } from "antd";

export function SelectErrorOrEmptyResult({
  errorMessage,
  emptyMessage = "Nenhum dado encontrado",
  loading = false,
  reloadFn,
  disableReload = false,
}: ISelectErrorOrEmptyResultProps) {
  return (
    <Flex data-cy="select-error-result" justify="center" align="center">
      <Empty
        description={
          <Flex vertical>
            <Typography.Text
              type={errorMessage ? "danger" : "secondary"}
              style={{ margin: 0 }}
            >
              {!loading && (errorMessage ?? emptyMessage)}
            </Typography.Text>
            {reloadFn && (
              <Button
                type="link"
                size="small"
                style={{ margin: 0, padding: 0, marginBottom: "0.5rem" }}
                onClick={reloadFn}
                disabled={loading || disableReload}
              >
                {loading ? (
                  <Flex align="center" gap={8}>
                    <Spin size="small" /> carregando...
                  </Flex>
                ) : (
                  "Carregar novamente?"
                )}
              </Button>
            )}
          </Flex>
        }
        imageStyle={{
          width: 98,
          margin: "0 auto",
        }}
      />
    </Flex>
  );
}
