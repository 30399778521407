import { useState } from "react";
import { BsStickies, BsQuestionCircle } from "react-icons/bs";

import {
  CopyBusinessUnitFormType,
  ICopyBusinessUnitFormProps,
} from "./interface";

import {
  useFetchCopyBusinessUnit,
  useFetchGetAllBusinessUnit,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Tooltip,
  Typography,
  message,
} from "antd";

const gridStyle: React.CSSProperties = {
  width: "25%",
  textAlign: "center",
};

const smallGridStyle: React.CSSProperties = {
  width: "50%",
  textAlign: "center",
};

export function CopyBusinessUnitForm({
  onSuccess = () => null,
}: ICopyBusinessUnitFormProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [formVisible, setFormVisible] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<CopyBusinessUnitFormType[]>();

  const { screens } = useAppConfig();

  const [form] = Form.useForm();

  const { mutate: copyBusinessUnit } = useFetchCopyBusinessUnit({});

  const handleUnitCopy = (item: CopyBusinessUnitFormType) => {
    form.setFieldsValue({
      id: item.id,
      name: item.name,
    });

    setFormVisible(true);
  };

  const onFinish = async (values: CopyBusinessUnitFormType) => {
    try {
      setIsLoading(true);

      copyBusinessUnit({
        businessUnitId: values.id,
        name: form.getFieldValue("name"),
      });

      setFormVisible(false);

      onSuccess();
    } catch (error: any) {
      message.error(error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  useFetchGetAllBusinessUnit({
    options: {
      onSuccess: (data) => {
        const parsedData = data.filter((item: any) => item.active === true);
        setDataSource(
          parsedData.map((item) => ({
            id: item.id ?? 0,
            name: item.name,
          })),
        );
      },
    },
  });

  return (
    <>
      <Form
        layout="inline"
        form={form}
        onFinish={onFinish}
        style={{ display: `${formVisible ? "block" : "none"}` }}
      >
        <Typography.Text
          type="secondary"
          style={{
            marginLeft: "1rem",
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          Unidade de negócio modelo
          <Tooltip title="Todas as configurações dessa unidade de negócio serão replicadas em uma nova">
            <BsQuestionCircle />
          </Tooltip>
        </Typography.Text>
        <Row>
          <Col>
            <Form.Item name="id">
              <Input disabled hidden />
            </Form.Item>
          </Col>
          <Col sm={10}>
            <Form.Item name="name">
              <Input />
            </Form.Item>
          </Col>
          <Col sm={8}>
            <Form.Item label="">
              <Button
                type="dashed"
                htmlType="submit"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
                loading={isLoading}
                data-cy="create-copy-button"
              >
                <BsStickies />
                Criar copia
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Card title="Escolha a unidade" style={{ marginTop: "50px" }}>
        {dataSource &&
          dataSource.map((item) => {
            return (
              <Card.Grid
                key={item.id}
                style={screens.xs ? smallGridStyle : gridStyle}
                onClick={() => handleUnitCopy(item)}
                data-cy="business-unit-card"
              >
                {item.name}
              </Card.Grid>
            );
          })}
      </Card>
    </>
  );
}
