import { api, apiException } from "~services/api";
import { IFetchPatchDealTotalValueTypePayloadSchema } from "~types/schemas";

export const fetchPatchDealTotalValueType = async (
  payload: IFetchPatchDealTotalValueTypePayloadSchema,
) => {
  try {
    const { customId, ...body } = payload;
    await api.patch(
      `/projects/step_five/custom-id/${customId}/deal/update-total-value`,
      body,
    );
  } catch (error) {
    throw apiException(error);
  }
};
