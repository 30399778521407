import { useMemo, useState } from "react";
import { AiOutlineCopy, AiOutlineMore } from "react-icons/ai";
import { BiUserCheck, BiUserX } from "react-icons/bi";
import { FaTrash, FaUserSecret, FaEdit } from "react-icons/fa";
import { MdLinkOff, MdOutlineAddLink } from "react-icons/md";

import type {
  IAdminUsersTableColumType,
  IAdminUsersTableDataType,
  IAdminUsersTableFiltersType,
  IAdminUsersTableProps,
} from "./interface";

import { ColumnFilter } from "~components/ColumnFilter";
import { CustomInput } from "~components/CustomInput";
import { Modal } from "~components/Modal";
import { Tag } from "~components/Tag";
import {
  useFetchBlockUser,
  useFetchDeleteAdminUser,
  useFetchGetAdminUsers,
  useFetchUnblockUser,
} from "~hooks/api";
import { useSession } from "~hooks/useSession";
import { SolarzTable } from "~solarzui/SolarzTable";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { parseInputValueTypeToNumber } from "~utils/parse";
import { Button, Dropdown, Flex, Form, Select } from "antd";

export function AdminUsersTable({
  initialFilters = {
    company: undefined,
    email: undefined,
    id: undefined,
    isPipedriveIntegration: null,
  },
}: IAdminUsersTableProps) {
  const { impersonateUser, userIsSysAdmin, userIsSuperSysAdmin } = useSession();

  const [filters, setFilters] =
    useState<IAdminUsersTableFiltersType>(initialFilters);

  const [currentPage, setCurrentPage] = useState<number>(1);

  const [userToDelete, setUserToDelete] = useState<IAdminUsersTableDataType>();

  const [userToToggleActivation, setUserToToggleActivation] =
    useState<IAdminUsersTableDataType>();

  const [companyIdToUpdateConfigurations, setCompanyIdToUpdateConfigurations] =
    useState<number>();

  const [companyIdToUnlinkPipedrive, setCompanyIdToUnlinkPipedrive] =
    useState<number>();

  const [companyIdToLinkPipedrive, setCompanyIdToLinkPipedrive] =
    useState<number>();

  const [companyToChangeIsTemplate, setCompanyToChangeIsTemplate] =
    useState<IAdminUsersTableDataType>();

  const {
    data: usersData,
    isFetching: isUsersLoading,
    error,
    refetch,
  } = useFetchGetAdminUsers({
    payload: {
      ...filters,
      isPipedriveIntegration: filters.isPipedriveIntegration ?? undefined,
      page: (currentPage || 1) - 1,
      size: 10, //STATIC
    },
    dependencyArray: [currentPage, userIsSuperSysAdmin, userIsSysAdmin],
    options: {
      enabled: currentPage > 0 && (userIsSuperSysAdmin || userIsSysAdmin),
    },
  });

  const { mutate: fetchDeleteUser, isLoading: isDeletingUser } =
    useFetchDeleteAdminUser({
      onSuccess: () => setUserToDelete(undefined),
    });

  const { mutate: blockUser, isLoading: isBlockingUser } = useFetchBlockUser({
    onSuccess: () => {
      setUserToToggleActivation(undefined);
    },
  });

  const { mutate: unblockUser, isLoading: isUnblockingUser } =
    useFetchUnblockUser({
      onSuccess: () => {
        setUserToToggleActivation(undefined);
      },
    });

  function onPageChange(number: number) {
    setCurrentPage(number);
  }

  function onChangeFilters(filters: Partial<IAdminUsersTableFiltersType>) {
    setFilters((state) => ({ ...state, ...filters }));
  }

  function handleSubmit() {
    currentPage > 1 ? setCurrentPage(1) : refetch();
  }

  function handleDeleteUser() {
    fetchDeleteUser({
      id: userToDelete?.id ?? 0,
    });
  }

  function handleToggleUserActivation() {
    userToToggleActivation?.blocked
      ? unblockUser({ id: userToToggleActivation?.id ?? 0 })
      : blockUser({ id: userToToggleActivation?.id ?? 0 });
  }

  function formatDateTime(date: string) {
    const targetDate = new Date(date);
    const formattedDate = targetDate.toLocaleDateString("pt-br");
    const formattedTime = targetDate.toLocaleTimeString("pt-br", {
      timeStyle: "short",
    });

    return `${formattedDate} às ${formattedTime}`;
  }

  const columns: IAdminUsersTableColumType[] = [
    {
      title: "ID",
      dataIndex: "id",
      align: "center" as const,
      render: (id) => (
        <SolarzTypography
          fontWeight="regular"
          style={{ fontSize: 14, width: 40 }}
        >
          #{id}
        </SolarzTypography>
      ),
      filterDropdown: (
        <ColumnFilter
          fields={[
            <Form.Item
              key="id"
              htmlFor="id"
              label="Id do usuário"
              colon={false}
            >
              <CustomInput.Number
                precision={0}
                id="id"
                value={filters.id}
                onChange={(value) => {
                  const parsedValue = parseInputValueTypeToNumber(value);
                  onChangeFilters({ id: parsedValue });
                }}
                placeholder="Id do usuário"
                min={1}
              />
            </Form.Item>,
          ]}
          isLoading={isUsersLoading}
          onReset={() => onChangeFilters({ id: undefined })}
          onSubmit={handleSubmit}
        />
      ),
      filteredValue: filters.id ? [true] : [],
    },
    {
      title: "Company",
      dataIndex: "company",
      width: 500,
      render: (_, record) => (
        <SolarzTypography fontWeight="semiBold" style={{ fontSize: 14 }}>
          {record.company}
        </SolarzTypography>
      ),
      filterDropdown: (
        <ColumnFilter
          fields={[
            <Form.Item
              key="company"
              htmlFor="company"
              label="Empresa do usuário"
              colon={false}
            >
              <CustomInput.Text
                id="company"
                value={filters.company}
                onChange={(event) => {
                  onChangeFilters({ company: event.target.value });
                }}
                placeholder="Empresa do usuário"
              />
            </Form.Item>,
          ]}
          isLoading={isUsersLoading}
          onReset={() => onChangeFilters({ company: undefined })}
          onSubmit={handleSubmit}
        />
      ),
      filteredValue: filters.company ? [true] : [],
    },
    {
      title: "Integrado ao Pipedrive",
      dataIndex: "pipedriveIntegration",
      width: 160,
      align: "center",
      render: (_, record) => (
        <Tag.UniqueValue
          tooltip={
            record.pipedriveIntegration
              ? `Conta possui integração com o Pipedrive`
              : "NÃO tem integração com Pipedrive"
          }
          title={record.pipedriveIntegration ? "Integrado" : "NÃO integrado"}
          color={record.pipedriveIntegration ? "geekblue" : "red"}
        />
      ),
      filterDropdown: (
        <ColumnFilter
          fields={[
            <Form.Item
              key="isPipedriveIntegration"
              htmlFor="isPipedriveIntegration"
              label="Integração com Pipedrive"
              colon={false}
            >
              <Select
                value={filters.isPipedriveIntegration}
                onChange={(value) => {
                  onChangeFilters({
                    isPipedriveIntegration: value,
                  });
                }}
                options={[
                  { label: "Todos", value: null },
                  { label: "Com integração", value: true },
                  { label: "Sem integração", value: false },
                ]}
              />
            </Form.Item>,
          ]}
          isLoading={isUsersLoading}
          onReset={() => onChangeFilters({ isPipedriveIntegration: null })}
          onSubmit={handleSubmit}
        />
      ),
      filteredValue: filters.isPipedriveIntegration ? [true] : [],
    },
    {
      title: "E-mail",
      dataIndex: "email",
      render: (email) => (
        <SolarzTypography
          fontWeight="regular"
          style={{ fontSize: 14, width: 240 }}
        >
          {email}
        </SolarzTypography>
      ),
      filterDropdown: (
        <ColumnFilter
          fields={[
            <Form.Item
              key="email"
              htmlFor="email"
              label="Email do usuário"
              colon={false}
            >
              <CustomInput.Text
                id="email"
                value={filters.email}
                onChange={(event) => {
                  onChangeFilters({ email: event.target.value });
                }}
                placeholder="Email do usuário"
              />
            </Form.Item>,
          ]}
          isLoading={isUsersLoading}
          onReset={() => onChangeFilters({ email: undefined })}
          onSubmit={handleSubmit}
        />
      ),
      filteredValue: filters.email ? [true] : [],
    },
    {
      title: "CNPJ",
      dataIndex: "identifier",
      width: 350,
      align: "center",
      render: (_, { identifier }) => (
        <SolarzTypography fontWeight="regular" style={{ fontSize: 14 }}>
          {identifier ?? "-"}
        </SolarzTypography>
      ),
    },
    {
      title: "Licenças contratadas",
      dataIndex: "maxUsersLicensed",
      width: 120,
      align: "center",
      render: (_, { maxUsersLicensed }) => (
        <SolarzTypography fontWeight="regular" style={{ fontSize: 14 }}>
          {maxUsersLicensed ?? "-"}
        </SolarzTypography>
      ),
    },
    {
      title: "Licenças em uso",
      dataIndex: "countUsers",
      width: 120,
      align: "center",
      render: (_, { countUsers }) => (
        <SolarzTypography fontWeight="regular" style={{ fontSize: 14 }}>
          {countUsers ?? "-"}
        </SolarzTypography>
      ),
    },
    {
      title: "Uso de dados(S3)",
      dataIndex: "dataUsage",
      width: 220,
      align: "center",
      render: (_, { dataUsage }) => (
        <Tag.UniqueValue
          tooltip={
            dataUsage
              ? `O usuário utilizou ${dataUsage} megabytes de armazenamento.`
              : "-"
          }
          title={dataUsage ? `${dataUsage}MB` : "-"}
          color={"orange"}
        />
      ),
    },
    {
      title: "Último acesso",
      dataIndex: "lastActivity",
      width: 250,
      align: "center",
      render: (_, { lastActivity }) => (
        <SolarzTypography fontWeight="regular" style={{ fontSize: 14 }}>
          {lastActivity ? formatDateTime(lastActivity) : "-"}
        </SolarzTypography>
      ),
    },
    {
      title: "Status",
      dataIndex: "blocked",
      align: "center",
      render: (_, { blocked }) => (
        <Tag.UniqueValue
          tooltip={`O usuário está ${blocked ? "Bloqueado" : "Ativo"}`}
          title={blocked ? "Bloqueado" : "Ativo"}
          color={blocked ? "volcano" : "green"}
        />
      ),
    },
    {
      title: "",
      width: 32,
      align: "center" as const,
      render(_, record) {
        return (
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <Flex
                      align="center"
                      gap={8}
                      style={{ color: "var(--blue-500)" }}
                    >
                      <FaUserSecret />
                      Representar
                    </Flex>
                  ),
                  onClick: () => impersonateUser(record.id),
                },
                {
                  key: "2",
                  label: (
                    <Flex
                      align="center"
                      gap={8}
                      style={{ color: "var(--gold-500)" }}
                    >
                      <FaEdit /> Configurações
                    </Flex>
                  ),
                  onClick: () =>
                    setCompanyIdToUpdateConfigurations(record.companyId),
                },
                {
                  key: "3",
                  label: (
                    <Flex
                      align="center"
                      gap={8}
                      style={{
                        color: record.blocked
                          ? "var(--geekBlue-500)"
                          : "var(--volcano-500)",
                      }}
                      data-cy="block-or-unblock-user"
                    >
                      {record.blocked ? (
                        <BiUserCheck size={16} />
                      ) : (
                        <BiUserX size={16} />
                      )}
                      {record.blocked ? "Desbloquear" : "Bloquear"}
                    </Flex>
                  ),
                  onClick: () => setUserToToggleActivation(record),
                },
                {
                  key: "4",
                  label: (
                    <Flex
                      align="center"
                      gap={8}
                      style={{ color: "var(--red-500)" }}
                    >
                      <FaTrash /> Apagar usuário
                    </Flex>
                  ),
                  onClick: () => setUserToDelete(record),
                },
                {
                  key: "divider_01",
                  type: "divider",
                },
                {
                  key: "5",
                  label: (
                    <Flex
                      align="center"
                      gap={8}
                      style={{
                        color: record.templateCompany
                          ? "var(--red-500)"
                          : "var(--geekBlue-500)",
                      }}
                    >
                      {record.templateCompany ? (
                        <>
                          <AiOutlineCopy size={20} /> Desfazer Template
                        </>
                      ) : (
                        <>
                          <AiOutlineCopy size={20} /> Transformar em Template
                        </>
                      )}
                    </Flex>
                  ),
                  onClick: () => setCompanyToChangeIsTemplate(record),
                },
                {
                  key: "6",
                  label: (
                    <Flex
                      align="center"
                      gap={8}
                      style={{ color: "var(--green-500)" }}
                    >
                      <MdOutlineAddLink size={20} /> Vincular com Pipedrive
                    </Flex>
                  ),
                  onClick: () => setCompanyIdToLinkPipedrive(record.companyId),
                  hide: record.pipedriveIntegration,
                },
                {
                  key: "7",
                  label: (
                    <Flex
                      align="center"
                      gap={8}
                      style={{ color: "var(--red-500)" }}
                    >
                      <MdLinkOff size={20} /> Desvincular do Pipedrive
                    </Flex>
                  ),
                  onClick: () =>
                    setCompanyIdToUnlinkPipedrive(record.companyId),
                  hide: !record.pipedriveIntegration,
                },
              ].filter((item) => !item.hide),
            }}
            trigger={["click"]}
          >
            <Button shape="circle" data-cy="actions" type="text">
              <AiOutlineMore />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const DATA_SOURCE = useMemo(
    () => usersData?.content ?? [],
    [usersData?.content],
  );

  return (
    <>
      <SolarzTable
        data-cy="admin-users-table"
        isLoading={isUsersLoading}
        rowKey="id"
        columns={columns}
        rows={DATA_SOURCE}
        pagination={{
          currentItemCount: usersData?.size ?? 0,
          currentPage: usersData?.number ? usersData?.number + 1 : 1,
          itemLabel: "usuários",
          totalItemCount: usersData?.totalElements ?? 0,
          onChange: onPageChange,
        }}
        scroll={{
          scrollToFirstRowOnChange: true,
          x: 1600,
        }}
        emptyMessage="Nenhum usuário encontrado"
        errorMessage={error?.message}
        reloadFn={refetch}
      />

      <Modal.AdminCompanyConfigurationForm
        companyId={companyIdToUpdateConfigurations ?? 0}
        isOpen={!!companyIdToUpdateConfigurations}
        onClose={() => setCompanyIdToUpdateConfigurations(undefined)}
        formProps={{
          onSuccess: () => setCompanyIdToUpdateConfigurations(undefined),
        }}
      />

      <Modal.DefaultRemove
        isLoading={isDeletingUser}
        isOpen={!!userToDelete}
        itemName={userToDelete?.email}
        onClose={() => setUserToDelete(undefined)}
        onSubmit={handleDeleteUser}
        suffix="Usuário"
        title="Remover usuário"
      />

      <Modal.DefaultAlert
        isOpen={!!userToToggleActivation}
        onClose={() => setUserToToggleActivation(undefined)}
        isLoading={isBlockingUser || isUnblockingUser}
        subtitle={`Deseja desabilitar o usuário ${userToToggleActivation?.email}?`}
        title={
          userToToggleActivation?.blocked
            ? "Desbloquear usuário"
            : "Bloquear usuário"
        }
        onSubmit={handleToggleUserActivation}
      />

      <Modal.UnlinkUserWithPipedrive
        companyId={companyIdToUnlinkPipedrive ?? 0}
        isOpen={!!companyIdToUnlinkPipedrive}
        onClose={() => setCompanyIdToUnlinkPipedrive(undefined)}
      />

      <Modal.LinkUserWithPipedrive
        companyId={companyIdToLinkPipedrive ?? 0}
        isOpen={!!companyIdToLinkPipedrive}
        onClose={() => setCompanyIdToLinkPipedrive(undefined)}
      />

      <Modal.ChangeIsTemplate
        companyId={companyToChangeIsTemplate?.companyId ?? 0}
        isTemplate={companyToChangeIsTemplate?.templateCompany}
        isOpen={!!companyToChangeIsTemplate}
        onClose={() => setCompanyToChangeIsTemplate(undefined)}
      />
    </>
  );
}
