import { ISimultaneityProfilesFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";
export function SimultaneityProfileFormModal({
  isOpen = false,
  onClose = () => null,
  width = 512,
  formProps = {},
}: ISimultaneityProfilesFormModalProps) {
  return (
    <Modal
      data-cy="simultaneity-profiles-form-modal"
      open={isOpen}
      onCancel={onClose}
      title="Perfil de simultaneidade"
      width={width}
      footer={null}
      destroyOnClose
    >
      <Form.SimultaneityProfile {...formProps} onCancel={onClose} />
    </Modal>
  );
}
