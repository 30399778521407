import type { CSSProperties } from "react";

import type { IUserManagementDTOSchema } from "~types/schemas";

export type UsersTableDataType = IUserManagementDTOSchema;

export type UsersTableFiltersType = {
  id: number | null;
  currentPage: number;
};

export enum UsersTableQueryParamKeysEnum {
  ID = "id",
  CURRENT_PAGE = "page",
}

export type UsersTableQueryParamsKeysType = Record<
  keyof typeof UsersTableQueryParamKeysEnum,
  string
>;

export interface IUsersTableProps {
  queryParamKeys?: Partial<UsersTableQueryParamsKeysType>;
}
export interface IUsersTableHeaderProps {
  queryParamKeys?: IUsersTableProps["queryParamKeys"];
  style?: CSSProperties;
}
