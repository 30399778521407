import { useMemo, useState } from "react";
import { AiOutlineDelete, AiFillCrown } from "react-icons/ai";
import { BsBuilding } from "react-icons/bs";
import { FaPen, FaUserEdit } from "react-icons/fa";

import {
  UsersTableDataType,
  UsersTableQueryParamKeysEnum,
  type IUsersTableProps,
  type UsersTableFiltersType,
  type UsersTableQueryParamsKeysType,
} from "./interface";
import { EmailText, NameText } from "./styles";

import { Avatar } from "~components/Avatar";
import { Menu } from "~components/Menu";
import { Modal } from "~components/Modal";
import {
  useFetchGetPaginatedUsersByCompany,
  useFetchRemoveUserFromCompanyByUserId,
} from "~hooks/api";
import { parseFunctions, useQueryParams } from "~hooks/useQueryParams";
import { useSession } from "~hooks/useSession";
import { SolarzTable } from "~solarzui/SolarzTable";
import { Flex, Tag, Tooltip } from "antd";

export function UsersTable(props: IUsersTableProps) {
  const { user } = useSession();
  const { getParsedQueryParams, handleSaveInQueryParams } = useQueryParams();

  const QUERY_PARAMS_KEYS: UsersTableQueryParamsKeysType = useMemo(
    () => ({
      ID: props.queryParamKeys?.ID || UsersTableQueryParamKeysEnum.ID,
      CURRENT_PAGE:
        props.queryParamKeys?.CURRENT_PAGE ||
        UsersTableQueryParamKeysEnum.CURRENT_PAGE,
    }),
    [props.queryParamKeys],
  );

  const filters: UsersTableFiltersType = useMemo(
    () => ({
      currentPage:
        getParsedQueryParams(
          QUERY_PARAMS_KEYS.CURRENT_PAGE,
          parseFunctions.NUMBER,
        ) ?? 1,
      id: getParsedQueryParams(QUERY_PARAMS_KEYS.ID, parseFunctions.NUMBER),
    }),
    [getParsedQueryParams, QUERY_PARAMS_KEYS],
  );

  const [userToDelete, setUserToDelete] = useState<UsersTableDataType>();

  const [userToChangeProfile, setUserToChangeProfile] =
    useState<UsersTableDataType>();

  const [userToChangeBusinessUnit, setUserToChangeBusinessUnit] =
    useState<UsersTableDataType>();

  const [userToUpdate, setUserToUpdate] = useState<UsersTableDataType>();

  const { mutate: handleDeleteUser, isLoading: isDeletingUser } =
    useFetchRemoveUserFromCompanyByUserId({
      onSuccess: () => {
        setUserToDelete(undefined);
      },
    });

  const {
    data: users,
    isFetching: isLoadingUsers,
    error: usersError,
    refetch: reloadUsers,
  } = useFetchGetPaginatedUsersByCompany({
    payload: {
      page: filters.currentPage - 1,
      size: 5, // STATIC
      userId: filters.id ?? undefined,
    },
    dependencyArray: [filters],
    options: {
      retry: 1,
      enabled: filters.currentPage > 0,
    },
  });

  return (
    <>
      <SolarzTable
        data-cy="users-table"
        {...props}
        rows={users?.content ?? []}
        columns={[
          {
            title: "Usuários",
            dataIndex: "name",
            width: "50%",
            render(_, record) {
              return (
                <Flex gap={8} align="center">
                  <Avatar.CustomUser
                    userId={record.id}
                    name={record.name}
                    avatarUrl={record.avatarUrl}
                    width={32}
                    height={32}
                  />

                  <Flex vertical>
                    <Flex gap={4} align="center">
                      <NameText>{record.name}</NameText>

                      {record.accountOwner && (
                        <Tooltip title="Este usuário é o dono da conta">
                          <AiFillCrown
                            size={18}
                            color="var(--gold-500)"
                            style={{ cursor: "help" }}
                          />
                        </Tooltip>
                      )}
                    </Flex>

                    <EmailText>{record.email}</EmailText>
                  </Flex>
                </Flex>
              );
            },
          },
          {
            title: "Perfil de permissão",
            width: "50%",
            render(_, record) {
              return (
                <Tag bordered={false} color="blue">
                  {record.profileName}
                </Tag>
              );
            },
          },
          {
            title: "",
            width: 64,
            render(_, record) {
              return (
                <Menu.MoreDropdown
                  items={[
                    {
                      icon: <FaPen size={16} color="var(--gold-500)" />,
                      text: "Alterar usuário",
                      color: "var(--gold-500)",
                      onClick: () => setUserToUpdate(record),
                    },
                    {
                      icon: (
                        <AiOutlineDelete size={16} color="var(--red-500)" />
                      ),
                      text: "Remover usuário",
                      color: "var(--red-500)",
                      onClick: () => setUserToDelete(record),
                      isDisabled: record.accountOwner,
                      tooltip: record.accountOwner
                        ? "Este usuário é um administrador, por tanto não pode ser removido."
                        : "",
                    },
                    {
                      icon: <FaUserEdit size={16} color="var(--cyan-500)" />,
                      text: "Alterar perfil de usuário",
                      color: "var(--cyan-500)",
                      onClick: () => setUserToChangeProfile(record),
                      isDisabled: record.id === user?.id || !user?.accountOwner,
                      tooltip:
                        record.id === user?.id
                          ? "Você não pode alterar o seu próprio perfil"
                          : user?.accountOwner
                            ? ""
                            : "Você precisa ser o dono para gerenciar os perfis",
                    },
                    {
                      icon: <BsBuilding size={16} color="var(--blue-500)" />,
                      text: "Alterar unidades de negócio",
                      color: "var(--blue-500)",
                      onClick: () => setUserToChangeBusinessUnit(record),
                    },
                  ]}
                  position="bottomLeft"
                />
              );
            },
          },
        ]}
        isLoading={isLoadingUsers}
        pagination={{
          currentItemCount: users?.size ?? 0,
          currentPage: filters.currentPage || 1,
          itemLabel: "usuários",
          totalItemCount: users?.totalElements ?? 0,
          onChange: (currentPage) => {
            handleSaveInQueryParams({
              [QUERY_PARAMS_KEYS.CURRENT_PAGE]: currentPage,
            });
          },
        }}
        emptyMessage="Sem usuários encontradas"
        errorMessage={usersError?.message}
        reloadFn={reloadUsers}
        rowKey="id"
      />

      <Modal.DefaultRemove
        isLoading={isDeletingUser}
        isOpen={!!userToDelete}
        itemName={userToDelete?.email}
        onClose={() => setUserToDelete(undefined)}
        suffix="usuário"
        onSubmit={() => handleDeleteUser({ userId: userToDelete?.id || 0 })}
        title="Remover usuário"
      />

      <Modal.ChangeUserProfileForm
        isOpen={!!userToChangeProfile}
        onClose={() => setUserToChangeProfile(undefined)}
        formProps={{
          userId: userToChangeProfile?.id,
          initialValues: {
            profileId: userToChangeProfile?.profileId,
          },
          onSubmitSuccess: () => setUserToChangeProfile(undefined),
        }}
      />

      <Modal.ChangeUserBusinessUnitForm
        isOpen={!!userToChangeBusinessUnit}
        onClose={() => setUserToChangeBusinessUnit(undefined)}
        userId={userToChangeBusinessUnit?.id}
      />

      <Modal.UserForm
        isOpen={!!userToUpdate}
        formProps={{
          initialValues: {
            ...userToUpdate,
            userId: userToUpdate?.id,
          },
          onSuccess: () => setUserToUpdate(undefined),
          hideProfileField: true,
        }}
        onClose={() => setUserToUpdate(undefined)}
      />
    </>
  );
}
