import { useMemo, useState } from "react";

import { ICityAutoCompleteProps } from "./interface";

import { AutoCompleteSuffixIcon } from "~components/Others";
import { Result } from "~components/Result";
import { useFetchGetCity } from "~hooks/api";
import { AutoComplete } from "antd";

export function CityAutoComplete({
  allowClear = true,
  onChange,
  onClear,
  ...props
}: ICityAutoCompleteProps) {
  const [inputValue, setInputValue] = useState<string>("");

  const CAN_LOAD_DATA = useMemo(() => inputValue.length >= 3, [inputValue]);

  const {
    data: cities,
    isFetching,
    error,
    refetch,
  } = useFetchGetCity({
    dependencyArray: [inputValue],
    payload: {
      term: inputValue ?? "",
    },
    options: {
      enabled: CAN_LOAD_DATA,
      retry: 1,
    },
  });

  const OPTIONS = useMemo(() => {
    return cities
      ? cities.map((city) => ({
          label: city.name,
          value: city.name,
          record: city,
          key: city.id,
        }))
      : [];
  }, [cities]);

  const handleSearch = (value: string) => {
    setInputValue(value);
  };

  return (
    <AutoComplete
      {...props}
      data-cy="city-auto-complete"
      showSearch
      filterOption={false}
      onSearch={handleSearch}
      options={OPTIONS}
      allowClear={allowClear}
      status={error ? "error" : ""}
      notFoundContent={
        <Result.SelectErrorOrEmpty
          errorMessage={error?.message}
          emptyMessage="Nenhuma cidade encontrada"
          loading={isFetching}
          reloadFn={refetch}
          disableReload={!CAN_LOAD_DATA}
        />
      }
      suffixIcon={
        <AutoCompleteSuffixIcon hasError={!!error} isLoading={isFetching} />
      }
      onClear={() => {
        setInputValue("");
        if (typeof onClear === "function") {
          onClear();
        }
      }}
      onChange={(value, option) => {
        if (option instanceof Array || !onChange) return;
        onChange(value, option);
      }}
      style={{ width: "100%", ...props.style }}
    />
  );
}
