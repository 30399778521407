import { useEffect } from "react";

import type {
  IGeneralInformationFormInitialValues,
  IGeneralInformationFormProps,
  IGeneralInformationFormValues,
} from "./interface";

import { useFetchUpdateUser } from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { useSession } from "~hooks/useSession";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzFormItem } from "~solarzui/SolarzFormItem";
import { SolarzInput } from "~solarzui/SolarzInput";
import { SolarzSelect } from "~solarzui/SolarzSelect";
import { Flex, Form } from "antd";

export function GeneralInformationForm({
  onSuccess,
  onError,
}: IGeneralInformationFormProps) {
  const { user, sessionStatus } = useSession();
  const { screens } = useAppConfig();
  const [form] = Form.useForm<IGeneralInformationFormInitialValues>();

  const { mutate: updateUser, isLoading: isUpdatingUser } = useFetchUpdateUser({
    options: {
      onSuccess,
      onError,
    },
  });

  useEffect(() => {
    form.setFieldsValue({
      name: user?.name,
      email: user?.email,
      lastName: user?.lastName,
      profile: user?.profile?.name,
    });
  }, [form, user]);

  function handleCancel() {
    form.setFieldsValue({
      name: user?.name,
      email: user?.email,
      lastName: user?.lastName,
      profile: user?.profile?.name,
    });
  }

  function handleSubmit(formValues: IGeneralInformationFormValues) {
    updateUser({ userId: user?.id || 0, ...formValues });
  }

  return (
    <Form
      data-cy="general-information-form"
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
    >
      {!screens.mobile ? (
        <Flex align="center" justify="space-between" wrap="nowrap" gap={16}>
          <SolarzFormItem name="name" label="Nome" rules={[{ required: true }]}>
            <SolarzInput inputType="default" />
          </SolarzFormItem>

          <SolarzFormItem
            name="lastName"
            label="Sobrenome"
            rules={[{ required: false }]}
          >
            <SolarzInput inputType="default" />
          </SolarzFormItem>
        </Flex>
      ) : (
        <>
          <SolarzFormItem name="name" label="Nome" rules={[{ required: true }]}>
            <SolarzInput inputType="default" />
          </SolarzFormItem>

          <SolarzFormItem
            name="lastName"
            label="Sobrenome"
            rules={[{ required: false }]}
          >
            <SolarzInput inputType="default" />
          </SolarzFormItem>
        </>
      )}

      <SolarzFormItem name="email" label="E-mail" rules={[{ required: true }]}>
        <SolarzInput readOnly isDisabled />
      </SolarzFormItem>

      <SolarzFormItem
        name="profile"
        label="Perfil de usuário"
        rules={[{ required: true }]}
      >
        <SolarzSelect isDisabled />
      </SolarzFormItem>

      <Flex gap={8} justify="end" className="mt-4">
        <SolarzFormItem style={{ margin: 0 }}>
          <SolarzButton
            htmlType="button"
            variant="ghost"
            isDisabled={sessionStatus === "loading"}
            onClick={handleCancel}
          >
            Cancelar
          </SolarzButton>
        </SolarzFormItem>
        <SolarzFormItem style={{ margin: 0 }}>
          <SolarzButton
            htmlType="submit"
            isLoading={isUpdatingUser}
            variant="primary"
            isDisabled={sessionStatus === "loading" || !user?.id}
          >
            Salvar
          </SolarzButton>
        </SolarzFormItem>
      </Flex>
    </Form>
  );
}
