import { useMemo, useState } from "react";
// import { CiUser } from "react-icons/ci";
import { FaRegQuestionCircle } from "react-icons/fa";

import type {
  IClientSearchSelectProps,
  ClientSearchSelectRecordType,
} from "./interface";

import { Modal } from "~components/Modal";
import { People, Company } from "~estrela-ui/icons";
import { useFetchGetSearchClient } from "~hooks/api";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzSelect } from "~solarzui/SolarzSelect";
import { Col, Divider, Flex, Row } from "antd";

function getOptionPropsByClientType(record: ClientSearchSelectRecordType) {
  if (record.clientType === "PERSON") {
    return {
      label: record.name,
      icon: <People />,
    };
  }

  if (record.clientType === "ORGANIZATION") {
    return {
      label: record.name,
      icon: <Company />,
    };
  }

  return {
    label: "???",
    icon: <FaRegQuestionCircle size={18} strokeWidth={0.75} />,
  };
}

export function ClientSearchSelect({
  searchValue: _searchValue,
  onCreatePersonSuccess,
  onCreatePersonError,
  onCreateOrganizationError,
  onCreateOrganizationSuccess,
  createPersonInitialValues,
  createOrganizationInitialValues,
  initialRecord,
  ...props
}: IClientSearchSelectProps) {
  const [searchValue, setSearchValue] = useState("");

  const [isPersonModalOpen, setIsPersonModalOpen] = useState(false);
  const [isOrganizationModalOpen, setIsOrganizationModalOpen] = useState(false);

  const canMakeRequest = searchValue.length >= 3;

  const { data, isFetching, error, refetch } = useFetchGetSearchClient({
    dependencyArray: [searchValue],
    payload: {
      term: searchValue,
    },
    options: {
      enabled: canMakeRequest,
    },
  });

  const defaultOption = useMemo(() => {
    if (!initialRecord) return undefined;

    const { icon, label } = getOptionPropsByClientType(initialRecord);

    return [
      {
        label: (
          <Flex align="center" gap={8}>
            {icon}
            <span>
              {initialRecord.id} - {label}
            </span>
          </Flex>
        ),
        record: initialRecord,
        value: initialRecord.id,
      },
    ];
  }, [initialRecord]);

  return (
    <>
      <SolarzSelect<ClientSearchSelectRecordType, "id">
        {...props}
        searchValue={_searchValue ?? searchValue}
        onChangeSearch={(value) => {
          setSearchValue(value);

          if (typeof props.onChangeSearch === "function") {
            props.onChangeSearch(value);
          }
        }}
        options={
          data?.map((option) => {
            const { icon, label } = getOptionPropsByClientType(option);

            return {
              label: (
                <Flex align="center" gap={8}>
                  {icon}
                  <span>
                    {option.id} - {label}
                  </span>
                </Flex>
              ),
              record: option,
              value: option.id,
            };
          }) || defaultOption
        }
        isLoading={isFetching}
        refetchFn={canMakeRequest ? refetch : undefined}
        errorMessage={error?.message}
        emptyMessage={
          canMakeRequest
            ? "Nenhum cliente foi encontrado"
            : "Digite ao menos 3 letras para buscar"
        }
        dropdownRender={(menu) => {
          return (
            <Flex vertical>
              {menu}
              <Flex vertical style={{ margin: "8px 16px 0 16px" }}>
                <Divider style={{ margin: 0 }} />

                <Row gutter={12}>
                  <Col span={10}>
                    <Flex justify="end">
                      <SolarzButton
                        style={{ margin: "8px 0 4px 0" }}
                        variant="ghost"
                        scale="tiny"
                        onClick={() => setIsPersonModalOpen(true)}
                      >
                        Nova pessoa
                      </SolarzButton>
                    </Flex>
                  </Col>
                  <Col span={4}>
                    <Flex justify="center">
                      <Divider
                        type="vertical"
                        style={{ marginTop: 8, height: 32 }}
                      />
                    </Flex>
                  </Col>
                  <Col span={10}>
                    <Flex justify="start">
                      <SolarzButton
                        style={{ margin: "8px 0 4px 0" }}
                        variant="ghost"
                        scale="tiny"
                        onClick={() => setIsOrganizationModalOpen(true)}
                      >
                        Nova organização
                      </SolarzButton>
                    </Flex>
                  </Col>
                </Row>
              </Flex>
            </Flex>
          );
        }}
        showSearch
      />

      <Modal.PersonForm
        title="Cadastrar pessoa"
        formProps={{
          onSuccess: (person) => {
            setIsPersonModalOpen(false);

            if (typeof onCreatePersonSuccess === "function") {
              onCreatePersonSuccess(person);
            }
          },
          onError: onCreatePersonError,
          initialValues: createPersonInitialValues,
        }}
        isOpen={isPersonModalOpen}
        onClose={() => setIsPersonModalOpen(false)}
      />

      <Modal.OrganizationForm
        title="Cadastrar organização"
        formProps={{
          onSuccess: (organization) => {
            setIsOrganizationModalOpen(false);

            if (typeof onCreateOrganizationSuccess === "function") {
              onCreateOrganizationSuccess(organization);
            }
          },
          onError: onCreateOrganizationError,
          initialValues: createOrganizationInitialValues,
        }}
        isOpen={isOrganizationModalOpen}
        onClose={() => setIsOrganizationModalOpen(false)}
      />
    </>
  );
}
