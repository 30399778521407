import styled from "@emotion/styled";
import { SolarzAnchor } from "~solarzui/SolarzAnchor";
import { Button } from "antd";

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;

  padding: 0 0.75rem;
  line-height: 18px;
  height: 44px;
  font-size: 14px;

  span {
    color: var(--gray-400);
  }

  svg {
    color: var(--gray-500);
  }
`;

export const StyledAnchor = styled(SolarzAnchor)`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;

  padding: 0 0.75rem;
  line-height: 18px;
  height: 44px;
  font-size: 14px;
  text-decoration: none !important;

  span {
    color: var(--gray-400);
  }

  svg {
    color: var(--gray-500);
  }
`;

export const LogoutButton = styled(StyledButton)`
  span {
    color: var(--red-500);
  }

  svg {
    color: var(--red-500);
  }

  border: 1px solid var(--gray-100);
  border-left: 0;
  border-right: 0;
  border-radius: 0;

  &:hover {
    background-color: var(--red-50) !important;
  }

  // DARK THEME
  html.dark & {
    border-color: var(--gray-700) !important;

    &:hover {
      background-color: #2c1618 !important;
      border-color: var(--red-700) !important;
    }

    svg {
      color: var(--red-400);
      filter: brightness(1);
    }
  }
`;

export const StyledLabel = styled.label`
  font-size: 14px;
  font-weight: 500;
  line-height: 15.84px;
  color: var(--gray-600);

  // DARK THEME
  html.dark & {
    color: var(--gray-400) !important;
  }
`;
