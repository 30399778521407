import { useEffect, useRef } from "react";
import { BsSliders } from "react-icons/bs";

import type { ISolarzInputNumberProps } from "./interface";
import { StyledInputNumber } from "./styles";

import { Dropdown } from "antd";

const beforeDecimalFormat = new Intl.NumberFormat("pt-br")
  .format(1111)
  .replace(/1/g, "");

const afterDecimalFormat = new Intl.NumberFormat("pt-br")
  .format(1.1)
  .replace(/1/g, "");

const currencyFormatter = (value: number | undefined, precision: number) => {
  if (!value) return "";

  const slice = precision === 0 ? 2 : precision + 1;

  const formattedValue = new Intl.NumberFormat("pt-br", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: precision,
  })
    .format(value)
    .slice(slice);

  return formattedValue;
};

const currencyParser = (value: string | undefined, precision: number) => {
  if (!value) return 0;

  const baseValueString =
    typeof value === "string" && !value.length ? "0.0" : value;

  const valueBeforeDecimal = baseValueString.replace(
    new RegExp(`\\${beforeDecimalFormat}`, "g"),
    "",
  );

  const valueAfterDecimal = valueBeforeDecimal.replace(
    new RegExp(`\\${afterDecimalFormat}`, "g"),
    ".",
  );

  const baseValueNumericOnlyString = valueAfterDecimal.replace(/[^0-9.]/g, "");

  const digitsAfterDecimalCount = (
    baseValueNumericOnlyString.split(".")[1] || []
  ).length;

  const parsedValue =
    Number(baseValueNumericOnlyString) *
    Math.pow(10, digitsAfterDecimalCount - precision);

  return Number.isNaN(parsedValue) ? 0 : parsedValue;
};

export function SolarzInputNumber({
  defaultValue,
  id,
  name,
  onChange,
  onPressEnter,
  onBlur,
  placeholder,
  style,
  value,
  filterRender,
  icon,
  scale = "medium",
  readOnly = false,
  inputType = "default",
  isDisabled = false,
  precision = 2,
  min,
  max,
}: ISolarzInputNumberProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef?.current && defaultValue) {
      inputRef.current.value = defaultValue?.toString() || "";
    }
  }, [inputRef, defaultValue]);

  return (
    <StyledInputNumber
      ref={inputRef}
      defaultValue={defaultValue ?? undefined}
      value={value}
      onChange={onChange}
      decimalSeparator=","
      suffix={
        filterRender ? (
          <Dropdown
            trigger={["click"]}
            placement="bottomRight"
            dropdownRender={() => filterRender ?? <div></div>}
            overlayStyle={{ top: 162 }}
          >
            {icon ?? <BsSliders />}
          </Dropdown>
        ) : (
          icon ?? undefined
        )
      }
      onFocus={(event) => event.target.select()}
      onBlur={onBlur}
      onPressEnter={(_event) => {
        if (typeof onPressEnter !== "function") return;

        if (value !== undefined) {
          return onPressEnter(value);
        }

        const parsedCurrentValue = Number(inputRef.current?.value ?? undefined);

        return isNaN(parsedCurrentValue) ? null : parsedCurrentValue;
      }}
      id={id}
      name={name}
      placeholder={placeholder}
      style={style}
      scale={scale}
      readOnly={readOnly}
      disabled={isDisabled}
      precision={precision}
      formatter={
        inputType === "R$" || inputType === "R$/MWh" || inputType === "R$/kWh"
          ? (value) => currencyFormatter(value, precision)
          : undefined
      }
      parser={
        inputType === "R$" || inputType === "R$/MWh" || inputType === "R$/kWh"
          ? (value) => currencyParser(value, precision)
          : undefined
      }
      addonAfter={inputType === "default" ? undefined : inputType}
      min={min}
      max={max}
    />
  );
}
