import type { ILostDealFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function LostDealFormModal({
  dealId,
  isOpen = false,
  onClose = () => undefined,
  maxWidth = 560,
  title = "Negócio perdido",
  formProps,
}: ILostDealFormModalProps) {
  return (
    <Modal
      data-cy="lost-deal-form-modal"
      title={title}
      width="100%"
      style={{
        maxWidth,
      }}
      open={isOpen}
      footer={null}
      onCancel={onClose}
      destroyOnClose
    >
      <Form.LostDeal {...formProps} dealId={dealId} onCancel={onClose} />
    </Modal>
  );
}
