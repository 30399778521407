import type { ILaborStructuresFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function LaborStructuresFormModal({
  isOpen = false,
  onClose = () => null,
  title = "Gerenciar Estruturas",
  width = 512,
  formProps,
  laborId,
}: ILaborStructuresFormModalProps) {
  return (
    <Modal
      data-cy="labor-structures-form-modal"
      open={isOpen}
      onCancel={onClose}
      title={title}
      width={width}
      footer={null}
      destroyOnClose
    >
      <Form.LaborStructures
        {...formProps}
        onCancel={onClose}
        laborId={laborId}
      />
    </Modal>
  );
}
