import { useMemo, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";

import {
  IValidationRulesListDataType,
  IValidationRulesList,
} from "./interface";

import { Menu } from "~components/Menu";
import { Modal } from "~components/Modal";
import { createPaginationTableObject } from "~components/Pagination";
import { Result } from "~components/Result";
import {
  useFetchDeleteValidationRule,
  useFetchGetPaginatedValidationRules,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { Flex, List } from "antd";

export function ValidationRulesList({ ...props }: IValidationRulesList) {
  const { screens } = useAppConfig();

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedValidationRuleToUpdate, setSelectedValidationRuleToUpdate] =
    useState<IValidationRulesListDataType>();
  const [selectedValidationRuleToDelete, setSelectedValidationRuleToDelete] =
    useState<IValidationRulesListDataType>();

  const {
    data,
    isLoading: isValidationRulesLoading,
    error: ValidationRulesError,
  } = useFetchGetPaginatedValidationRules({
    dependencyArray: [currentPage],
    payload: {
      page: currentPage - 1,
      size: 5,
    },
  });

  const { mutate: fetchDeleteValidationRule } = useFetchDeleteValidationRule({
    onSettled: () => {
      setSelectedValidationRuleToDelete(undefined);
    },
  });

  async function deleteValidationRule() {
    fetchDeleteValidationRule({
      validationRulId: selectedValidationRuleToDelete?.id || 0,
    });
  }
  const DATA_SOURCE: IValidationRulesListDataType[] = useMemo(
    () => data?.content ?? [],
    [data?.content],
  );

  return (
    <>
      <List
        data-cy="validation-rules-list"
        {...props}
        itemLayout="horizontal"
        dataSource={DATA_SOURCE}
        style={{ width: "100%" }}
        pagination={createPaginationTableObject({
          itemType: "regras",
          number: currentPage,
          onChange: (page, _) => {
            setCurrentPage(page);
          },
          size: data?.size,
          totalElements: data?.totalElements,
          displayTotalInformation: screens.sm,
        })}
        renderItem={(rule) => (
          <List.Item key={rule.id}>
            <Flex
              align="center"
              justify="space-between"
              style={{ width: "100%", paddingRight: "0.5rem" }}
            >
              <List.Item.Meta title={rule.name} />
              <Flex align="center" gap={16} style={{ marginRight: "1rem" }}>
                <Menu.MoreDropdown
                  position="bottomRight"
                  icon="cog"
                  items={[
                    {
                      icon: (
                        <AiOutlineEdit
                          fontSize={20}
                          style={{ color: "var(--gold-500)" }}
                        />
                      ),
                      text: "Editar",
                      color: "var(--gold-500)",
                      onClick: () => setSelectedValidationRuleToUpdate(rule),
                    },
                    {
                      icon: (
                        <AiOutlineDelete
                          fontSize={20}
                          style={{ color: "var(--volcano-500)" }}
                        />
                      ),
                      text: "Remover",
                      color: "var(--volcano-500)",
                      onClick: () => setSelectedValidationRuleToDelete(rule),
                    },
                  ]}
                />
              </Flex>
            </Flex>
          </List.Item>
        )}
        locale={{
          emptyText: (
            <Result.TableErrorOrEmpty
              errorMessage={ValidationRulesError?.message}
              emptyMessage="Nenhuma regra de validação encontrada."
            />
          ),
        }}
        loading={isValidationRulesLoading}
      />

      <Modal.DefaultRemove
        isOpen={!!selectedValidationRuleToDelete}
        onClose={() => setSelectedValidationRuleToDelete(undefined)}
        itemName={selectedValidationRuleToDelete?.name}
        suffix="regra de validação"
        title="Remover regra"
        onSubmit={deleteValidationRule}
      />

      <Modal.ValidationRuleForm
        formProps={{
          initialValues: {
            id: selectedValidationRuleToUpdate?.id,
            name: selectedValidationRuleToUpdate?.name,
            expression: selectedValidationRuleToUpdate?.expression,
          },
          onSuccess: () => setSelectedValidationRuleToUpdate(undefined),
        }}
        isOpen={!!selectedValidationRuleToUpdate}
        onClose={() => setSelectedValidationRuleToUpdate(undefined)}
      />
    </>
  );
}
