import { useMemo, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { BiSelectMultiple } from "react-icons/bi";

import type { IPerformanceProfilesListProps } from "./interface";

import { Menu } from "~components/Menu";
import { Modal } from "~components/Modal";
import { createPaginationTableObject } from "~components/Pagination";
import { Result } from "~components/Result";
import { Tag } from "~components/Tag";
import {
  useFetchDeletePerformanceProfile,
  useFetchGetPaginatedPerformanceProfile,
  useFetchSetOrRemovePerformanceProfileDefault,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import type { IPerformanceProfileDTOSchema } from "~types/schemas";
import { List, Flex } from "antd";

export function PerformanceProfilesList(props: IPerformanceProfilesListProps) {
  const { screens, message } = useAppConfig();

  const [currentPage, setCurrentPage] = useState(1);

  const [
    selectedPerformanceProfileToUpdate,
    setSelectedPerformanceProfilesToUpdate,
  ] = useState<IPerformanceProfileDTOSchema>();

  const [
    selectedPerformanceProfileToDelete,
    setSelectedPerformanceProfilesToDelete,
  ] = useState<IPerformanceProfileDTOSchema>();

  const [
    selectedPerformanceProfileToSetAsDefault,
    setSelectedPerformanceProfileToSetAsDefault,
  ] = useState<IPerformanceProfileDTOSchema>();

  const { data, isFetching, error } = useFetchGetPaginatedPerformanceProfile({
    payload: {
      page: currentPage - 1,
      size: 5,
    },
    dependencyArray: [currentPage],
    options: {
      enabled: currentPage > 0,
    },
  });

  const {
    mutate: fetchDeletePerformanceProfile,
    isLoading: isDeletingPerformanceProfile,
  } = useFetchDeletePerformanceProfile({
    onSuccess: () => {
      setSelectedPerformanceProfilesToDelete(undefined);
    },
  });

  const {
    mutate: setPerformanceProfileAsDefault,
    isLoading: isSettingPerformanceProfileAsDefault,
  } = useFetchSetOrRemovePerformanceProfileDefault({
    options: {
      onSuccess: () => setSelectedPerformanceProfileToSetAsDefault(undefined),
    },
  });

  function handleDeleteSubmit() {
    const PerformanceProfilesId = selectedPerformanceProfileToDelete?.id;

    if (!PerformanceProfilesId) {
      message.error("Client error: Faltando o ID do custo");
      return;
    }
    fetchDeletePerformanceProfile({ id: PerformanceProfilesId });
  }

  function handleSetPerformanceProfileAsDefault() {
    const performanceProfileId = selectedPerformanceProfileToSetAsDefault?.id;

    if (!performanceProfileId) {
      message.error("Client error: Faltando o ID do perfil de performance");
      return;
    }

    setPerformanceProfileAsDefault({ id: performanceProfileId });
  }

  function renderDefaultItemTag(isDefault: boolean) {
    return (
      <Flex align="center" gap={4}>
        {isDefault && (
          <Tag.UniqueValue
            tooltip="Este item é o perfil padrão"
            title="Padrão"
            color="green"
          />
        )}
      </Flex>
    );
  }

  const DATA_SOURCE = useMemo(() => {
    return data?.content?.map(
      (profile) =>
        ({
          id: profile.id,
          description: profile.description,
          value: profile.value,
          defaultProfile: profile.defaultProfile,
        }) ?? [],
    );
  }, [data]);

  return (
    <>
      <List
        {...props}
        data-cy="performance-profiles-list"
        itemLayout="horizontal"
        dataSource={DATA_SOURCE}
        loading={isFetching}
        pagination={createPaginationTableObject({
          itemType: "perfis",
          number: currentPage,
          onChange: (page, _) => {
            setCurrentPage(page);
          },
          size: data?.size,
          totalElements: data?.totalElements,
          displayTotalInformation: screens.sm,
        })}
        renderItem={(profile) => (
          <List.Item
            key={profile.id}
            actions={[
              <Flex
                align="center"
                gap={16}
                style={{ marginRight: "1rem" }}
                key={profile.id}
              >
                <Menu.MoreDropdown
                  position="bottomRight"
                  icon="cog"
                  items={[
                    {
                      icon: (
                        <AiOutlineEdit
                          fontSize={20}
                          style={{ color: "var(--gold-500)" }}
                        />
                      ),
                      text: "Editar",
                      color: "var(--gold-500)",
                      onClick: () =>
                        setSelectedPerformanceProfilesToUpdate(profile),
                    },
                    {
                      icon: (
                        <AiOutlineDelete
                          fontSize={20}
                          style={{ color: "var(--volcano-500)" }}
                        />
                      ),
                      text: "Remover",
                      color: "var(--volcano-500)",
                      onClick: () =>
                        setSelectedPerformanceProfilesToDelete(profile),
                    },
                    {
                      icon: (
                        <BiSelectMultiple
                          fontSize={16}
                          style={{
                            color: profile.defaultProfile
                              ? "var(--red-500)"
                              : "var(--blue-500)",
                          }}
                        />
                      ),
                      text: profile.defaultProfile
                        ? "Remover perfil padrão"
                        : "Definir como padrão",
                      color: profile.defaultProfile
                        ? "var(--red-500)"
                        : "var(--blue-500)",
                      onClick: () =>
                        setSelectedPerformanceProfileToSetAsDefault(profile),
                    },
                  ]}
                />
              </Flex>,
            ]}
          >
            <List.Item.Meta
              title={
                <Flex vertical>
                  <span>{profile.description}</span>{" "}
                  {profile.defaultProfile &&
                    renderDefaultItemTag(profile.defaultProfile)}
                </Flex>
              }
              description={`Valor: ${profile.value}`}
            />
          </List.Item>
        )}
        locale={{
          emptyText: (
            <Result.TableErrorOrEmpty
              emptyMessage="Nenhum perfil cadastrado ainda!"
              errorMessage={error?.message}
            />
          ),
        }}
      />

      <Modal.DefaultRemove
        isOpen={!!selectedPerformanceProfileToDelete}
        onClose={() => setSelectedPerformanceProfilesToDelete(undefined)}
        isLoading={isDeletingPerformanceProfile}
        itemName={selectedPerformanceProfileToDelete?.description}
        suffix="perfil de performance"
        title="perfil de performance"
        onSubmit={handleDeleteSubmit}
      />

      <Modal.PerformanceProfileForm
        formProps={{
          initialValues: {
            id: selectedPerformanceProfileToUpdate?.id,
            description: selectedPerformanceProfileToUpdate?.description ?? "",
            value: selectedPerformanceProfileToUpdate?.value,
          },
          onSuccess: () => setSelectedPerformanceProfilesToUpdate(undefined),
        }}
        isOpen={!!selectedPerformanceProfileToUpdate}
        onClose={() => setSelectedPerformanceProfilesToUpdate(undefined)}
      />

      <Modal.DefaultAlert
        isOpen={!!selectedPerformanceProfileToSetAsDefault}
        isLoading={isSettingPerformanceProfileAsDefault}
        onClose={() => setSelectedPerformanceProfileToSetAsDefault(undefined)}
        onSubmit={handleSetPerformanceProfileAsDefault}
        title="Aviso"
        subtitle={
          selectedPerformanceProfileToSetAsDefault?.defaultProfile
            ? "Tem certeza de que deseja remover este perfil do padrão?"
            : "Você esta certo que deseja setar este perfil como perfil padrão?"
        }
      />
    </>
  );
}
