import { useQuery } from "react-query";

import type { IUseFetchGetPersonDefaultFieldsSettings } from "./interface";

import { fetchGetPersonDefaultFieldsSettings } from "~api/config/persons/default-fields/settings/get";

export const useFetchGetPersonDefaultFieldsSettingsKey =
  "api/config/persons/default-fields/settings/get";

export function useFetchGetPersonDefaultFieldsSettings({
  dependencyArray = [],
  options,
}: IUseFetchGetPersonDefaultFieldsSettings) {
  return useQuery(
    [useFetchGetPersonDefaultFieldsSettingsKey, dependencyArray],
    async () => await fetchGetPersonDefaultFieldsSettings(),
    options,
  );
}
