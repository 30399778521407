export * from "./BusinessTimeline/interface";
import { BusinessTimeline } from "./BusinessTimeline";
export * from "./DealTimeline/interface";
import { DealTimeline } from "./DealTimeline";
export * from "./OrganizationTimeline/interface";
import { OrganizationTimeline } from "./OrganizationTimeline";
export * from "./PersonTimeline/interface";
import { PersonTimeline } from "./PersonTimeline";

export const Timeline = {
  Business: BusinessTimeline,
  Deal: DealTimeline,
  Person: PersonTimeline,
  Organization: OrganizationTimeline,
};
