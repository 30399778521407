import type { IProposalDefaultDataFieldFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function ProposalDefaultDataFieldFormModal({
  isOpen = false,
  onClose = () => null,
  width = 890,
  formProps = {
    initialValues: undefined,
  },
}: IProposalDefaultDataFieldFormModalProps) {
  return (
    <Modal
      data-cy="proposal-default-data-field-form-modal"
      open={isOpen}
      onCancel={onClose}
      title="Campo padrão da proposta"
      width={width}
      style={{ maxWidth: "90vw" }}
      footer={null}
      destroyOnClose
    >
      <Form.ProposalDefaultDataField {...formProps} onCancel={onClose} />
    </Modal>
  );
}
