import { MaterialFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export const ACMaterialFormModal = ({
  item,
  isOpen,
  onClose = () => null,
}: MaterialFormModalProps) => {
  return (
    <Modal
      data-cy="ac-material-form-modal"
      title="Material de corrente alternada"
      open={isOpen}
      onCancel={onClose}
      destroyOnClose
      footer={null}
    >
      <Form.ACMaterial
        initialValues={item}
        onSubmit={onClose}
        onCancel={onClose}
      />
    </Modal>
  );
};
