import { useMemo } from "react";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";

import { CardItem } from "./CardItem";
import { IProposalCardProps } from "./interface";

import { useAppConfig } from "~hooks/useAppConfig";
import { SolarzButton } from "~solarzui/SolarzButton";
import { parseCurrency, parsePotency, parseEnergy } from "~utils/parse";
import { Card, Col, Row, Tooltip, Flex } from "antd";

export const ProposalCard = ({
  simplified = false,
  maxWidth = "280px",
  proposalStepData,
  isProposalStepLoading,
  proposalStatus,
  displaySolarInfo = {
    generation: true,
    power: true,
    powerPrice: true,
  },
  openChangeDealValueTypeModal = () => {},
  showChangeDealValueTypeButton,
}: IProposalCardProps) => {
  const { screens } = useAppConfig();

  const value = useMemo(() => {
    return (
      proposalStepData?.totalValueWithDiscount || proposalStepData?.value || 0
    );
  }, [proposalStepData?.totalValueWithDiscount, proposalStepData?.value]);

  return (
    <Card
      title={
        !screens.xs && (
          <Flex justify="space-between">
            Dados do cliente
            {proposalStatus === "WON" && (
              <Tooltip title="Proposta ganha!" trigger={["hover"]}>
                <AiFillCheckCircle
                  size={20}
                  style={{ color: "var(--green-500)" }}
                />
              </Tooltip>
            )}
            {proposalStatus === "LOST" && (
              <Tooltip title="Proposta perdida!">
                <AiFillCloseCircle
                  size={20}
                  style={{ color: "var(--red-500)" }}
                />
              </Tooltip>
            )}
          </Flex>
        )
      }
      size="small"
      style={{
        width: "100%",
        maxWidth: maxWidth,
        overflow: "hidden",
      }}
      actions={
        showChangeDealValueTypeButton
          ? [
              <SolarzButton
                key={"1"}
                variant="link"
                style={{
                  color: "var(--primary-500)",
                  fontSize: 14,
                  fontWeight: "bolder",
                  marginLeft: "1rem",
                }}
                onClick={() => openChangeDealValueTypeModal()}
              >
                Ajustar valor do negócio
              </SolarzButton>,
            ]
          : []
      }
    >
      <Row
        gutter={4}
        wrap={simplified ? false : true}
        style={{ overflow: "hidden" }}
      >
        <Col span={simplified ? 12 : 24}>
          <CardItem
            title="Valor"
            value={parseCurrency(value) ?? ""}
            isLoading={isProposalStepLoading}
          />
        </Col>
        {displaySolarInfo.power && (
          <Col span={simplified ? 12 : 24}>
            <CardItem
              title="Potência"
              value={parsePotency(proposalStepData?.fullPower) ?? ""}
              isLoading={isProposalStepLoading}
            />
          </Col>
        )}
      </Row>
      {displaySolarInfo.powerPrice ||
        (displaySolarInfo.generation && (
          <Row
            gutter={4}
            wrap={simplified ? false : true}
            style={{ overflow: "hidden" }}
          >
            {displaySolarInfo.powerPrice && (
              <Col span={simplified ? 12 : 24}>
                <CardItem
                  title="Preço por kWp"
                  value={
                    parseCurrency(value / (proposalStepData?.fullPower || 0)) ??
                    ""
                  }
                  isLoading={isProposalStepLoading}
                />
              </Col>
            )}

            {displaySolarInfo.generation && (
              <Col span={simplified ? 12 : 24}>
                <CardItem
                  title="Geração em 30 dias"
                  value={
                    proposalStepData?.generationInKwh
                      ? parseEnergy(proposalStepData?.generationInKwh)
                      : "-"
                  }
                  isLoading={isProposalStepLoading}
                />
              </Col>
            )}
          </Row>
        ))}
    </Card>
  );
};
