import styled from "@emotion/styled";
import { Alert, Skeleton } from "antd";

export const LoadingSkeleton = styled(Skeleton.Node)`
  width: 100% !important;
`;

export const StyledAlert = styled(Alert)`
  border-color: var(--red-500);
  padding: 8px 16px;

  ul {
    margin-top: 12px;
  }

  ul li::marker {
    color: var(--red-600);
  }
`;
