import { AiOutlineFileDone, AiOutlineInbox } from "react-icons/ai";

import type {
  ISendTemplateFormProps,
  ISendTemplateFormValues,
} from "./interface";

import { CustomInput } from "~components/CustomInput";
import { ENV } from "~configs/Env";
import { Delete } from "~estrela-ui/icons";
import {
  useFetchUpdateReportTemplateById,
  useFetchUploadFile,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import {
  Button,
  Card,
  Flex,
  Form,
  Select,
  Tooltip,
  Typography,
  Upload,
} from "antd";
import { RcFile } from "antd/lib/upload";

const DOCX_TYPE =
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

const HTML_TYPE = "text/html";

const LOADING_INDICATOR_KEY = "PROCESSING_TEMPLATE";

const DEFAULT_INITIAL_VALUES: ISendTemplateFormValues = {
  fileType: "DOCX",
  id: undefined,
  templateName: "",
  file: "",
};

export function SendTemplateForm({
  formInstance,
  initialValues,
  onError,
  onSuccess,
  onCancel,
}: ISendTemplateFormProps) {
  const { message } = useAppConfig();
  const [form] = Form.useForm(formInstance);

  // WATCHERS
  const fileType: ISendTemplateFormValues["fileType"] = Form.useWatch(
    "fileType",
    form,
  );

  const templateName: string | undefined = Form.useWatch("templateName", form);

  const file: string | undefined = Form.useWatch("file", {
    preserve: true,
    form: form,
  });

  function processDocxRcFile(file: RcFile) {
    if (DOCX_TYPE !== file.type) {
      return message.error(`${file.name} não é um arquivo do tipo Docx!`);
    }

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = async (e) => {
      try {
        message.open({
          key: LOADING_INDICATOR_KEY,
          type: "loading",
          content: "Processando arquivo...",
          duration: 9999,
        });

        const loadedString = e.target?.result as string;

        if (!loadedString) {
          throw new Error(
            "Client error: falha ao converter arquivo para base64",
          );
        }

        // eslint-disable-next-line
        const [_mimeType, base64] = loadedString.split(";base64,");

        form.setFieldValue("file", base64);

        message.open({
          key: LOADING_INDICATOR_KEY,
          type: "success",
          content: "Arquivo processado",
          duration: 2,
        });
      } catch (error: any) {
        message.open({
          key: LOADING_INDICATOR_KEY,
          type: "error",
          content: error?.message,
          duration: 2,
        });
        form.setFieldValue("file", undefined);
      }
    };

    reader.onerror = () => {
      message.error("Client error: falha ao ler arquivo");
      form.setFieldValue("file", undefined);
    };
  }

  function processHtmlRcFile(file: RcFile) {
    if (HTML_TYPE !== file.type) {
      return message.error(`${file.name} não é um arquivo do tipo HTML!`);
    }

    const reader = new FileReader();

    reader.readAsText(file);

    reader.onload = async (e) => {
      try {
        message.open({
          key: LOADING_INDICATOR_KEY,
          type: "loading",
          content: "Processando arquivo...",
          duration: 9999,
        });

        const loadedHtmlString = e.target?.result as string;

        if (!loadedHtmlString) {
          throw new Error(
            "Client error: falha ao converter arquivo para .html",
          );
        }

        // SUBSTITUTE PLACEHOLDERS
        const fulledHtmlString = loadedHtmlString.replace(
          "[Proposta.Potência do sistema]",
          "90",
        );

        form.setFieldValue("file", fulledHtmlString);

        message.open({
          key: LOADING_INDICATOR_KEY,
          type: "success",
          content: "Arquivo processado",
          duration: 2,
        });
      } catch (error: any) {
        message.open({
          key: LOADING_INDICATOR_KEY,
          type: "error",
          content: error?.message,
          duration: 2,
        });

        form.setFieldValue("file", undefined);
      }
    };

    reader.onerror = () => {
      message.error("Client error: falha ao ler arquivo");
      form.setFieldValue("file", undefined);
    };
  }

  async function beforeUpload(file: RcFile) {
    switch (fileType) {
      case "DOCX": {
        return processDocxRcFile(file);
      }
      case "HTML": {
        return processHtmlRcFile(file);
      }
      default: {
        message.error("Invalid file type!");
        return;
      }
    }
  }

  const { mutate: updateReportTemplate, isLoading: isUpdatingReportTemplate } =
    useFetchUpdateReportTemplateById({
      options: {
        onSuccess,
        onError,
      },
    });

  const { mutate: uploadFile, isLoading: isUploadingFile } = useFetchUploadFile(
    {
      options: {
        onSuccess,
        onError,
      },
    },
  );

  async function handleSubmit() {
    const id = initialValues?.id;

    const getMimeType = () => {
      switch (fileType) {
        case "DOCX":
          return DOCX_TYPE;
        case "HTML":
          return HTML_TYPE;
        default:
          return "";
      }
    };

    const requestData = {
      fileDataInBase64: file,
      mimeType: getMimeType(),
      name: templateName,
    };

    if (id) {
      updateReportTemplate({
        reportTemplateId: id,
        ...requestData,
      });
    } else {
      uploadFile(requestData);
    }
  }

  function handleCancel() {
    if (typeof onCancel === "function") {
      onCancel();
    } else {
      form.resetFields();
    }
  }

  // CONSTANTS
  const isEditingTemplate = !!initialValues?.id;

  const isLoadingSendingTemplate = isUpdatingReportTemplate || isUploadingFile;

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      style={{ width: "100%", marginTop: "2rem" }}
      initialValues={{ ...DEFAULT_INITIAL_VALUES, ...initialValues }}
    >
      <Form.Item
        name="fileType"
        label="Tipo do arquivo"
        rules={[
          {
            required: true,
            message: "Tipo do arquivo é obrigatório!",
          },
        ]}
      >
        <Select
          placeholder="Selecione o tipo de arquivo..."
          options={[
            {
              label: "DOCX",
              value: "DOCX",
            },
            {
              label:
                ENV.AMBIENT !== "DEVELOPMENT" ? "HTML (Em breve...)" : "HTML",
              value: "HTML",
              disabled: ENV.AMBIENT !== "DEVELOPMENT",
            },
          ]}
          onChange={() => form.setFieldValue("file", undefined)}
        />
      </Form.Item>

      <Form.Item
        name="templateName"
        label="Nome do template"
        rules={[
          {
            required: true,
            message: "Nome do template é obrigatório",
          },
        ]}
        tooltip={
          isEditingTemplate &&
          "Não se pode alterar o nome do template, apenas enviar um novo arquivo"
        }
      >
        <CustomInput.Text
          type="text"
          placeholder="Ex.: Template padrão"
          disabled={isEditingTemplate}
        />
      </Form.Item>

      {file ? (
        <Card
          style={{
            width: "100%",
            minHeight: "6rem",
            border: `1px dashed var(--green-500)`,
            marginBottom: "1rem",
          }}
        >
          <Flex
            vertical
            align="center"
            justify="center"
            style={{ marginBottom: "1rem" }}
          >
            <AiOutlineFileDone
              size={64}
              style={{ color: "var(--green-500)" }}
            />
            <Typography.Text style={{ color: "var(--green-500)" }}>
              Arquivo pronto para envio!
            </Typography.Text>
          </Flex>
          <Flex align="center" justify="center" gap={24}>
            <Tooltip title="Remover documento">
              <Button
                type="dashed"
                size="large"
                shape="circle"
                style={{
                  borderColor: "var(--red-400)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => form.setFieldValue("file", undefined)}
              >
                <Delete className="text-red-500" />
              </Button>
            </Tooltip>
          </Flex>
        </Card>
      ) : (
        <Upload.Dragger
          beforeUpload={beforeUpload}
          multiple={false}
          showUploadList={false}
          style={{ padding: "1rem 2rem", marginBottom: "1rem" }}
          accept={fileType && `.${fileType?.toLocaleLowerCase()}`}
        >
          <Flex vertical align="center" style={{ width: "100%" }}>
            <AiOutlineInbox size={64} style={{ color: "var(--primary-500)" }} />

            <Typography.Text style={{ color: "var(--black)" }}>
              Clique ou arraste o arquivo
            </Typography.Text>

            <Typography.Text
              style={{ color: "var(--gray-500)", marginTop: "0.5rem" }}
            >
              Somente um arquivo é aceitado. O arquivo deve ser .
              {fileType?.toLowerCase()}
            </Typography.Text>
          </Flex>
        </Upload.Dragger>
      )}

      <Flex align="center" gap={8}>
        <Button disabled={isLoadingSendingTemplate} onClick={handleCancel}>
          Cancelar
        </Button>
        <Tooltip title={file ? "" : "Necessário enviar o arquivo"}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!file}
            loading={isLoadingSendingTemplate}
          >
            Enviar template
          </Button>
        </Tooltip>
      </Flex>
    </Form>
  );
}
