import { useEffect, useState } from "react";

import type {
  IProposalCustomDataFieldFormValues,
  IProposalCustomDataFieldFormProps,
} from "./interface";
import styles from "./styles";

import { CustomInput } from "~components/CustomInput";
import { Select } from "~components/Select";
import {
  useFetchCreateCustomField,
  useFetchUpdateCustomField,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import {
  Button,
  Form,
  Input as AntdInput,
  Radio,
  Select as AntdSelect,
  Flex,
  DatePicker,
  Divider,
  Col,
  Row,
  Checkbox,
  Typography,
} from "antd";
import dayjs from "dayjs";

const DEFAULT_VALUES = {
  id: undefined,
  proposalSection: undefined,
  proposalStep: undefined,
  type: "TEXT",
  required: false,
  editable: true,
  hidden: false,
  defaultValue: undefined,
  identifier: undefined,
  label: "",
  options: [],
  fieldSettings: [],
} as IProposalCustomDataFieldFormValues;

export function ProposalCustomDataFieldForm({
  initialValues,
  onCancel,
  onSubmitFail = () => undefined,
  onSubmitSuccess = () => undefined,
}: IProposalCustomDataFieldFormProps) {
  const { screens } = useAppConfig();

  const [form] = Form.useForm();

  const proposalStep = Form.useWatch("proposalStep", form);

  const { mutate: createCustomField, isLoading: isCreatingCustomField } =
    useFetchCreateCustomField({
      options: {
        onSuccess: onSubmitSuccess,
        onError: onSubmitFail,
      },
    });

  const { mutate: updateCustomField, isLoading: isUpdatingCustomField } =
    useFetchUpdateCustomField({
      options: {
        onSuccess: onSubmitSuccess,
        onError: onSubmitFail,
      },
    });

  function handleSubmitForm(data: IProposalCustomDataFieldFormValues) {
    const parsedDefaultValue =
      data.type === "DATE" && data.defaultValue
        ? dayjs(data?.defaultValue.toString()).format("DD/MM/YYYY")
        : data.defaultValue;

    const customFieldIdToUpdate = initialValues?.id;

    if (customFieldIdToUpdate) {
      updateCustomField({
        ...data,
        defaultValue: parsedDefaultValue,
        id: customFieldIdToUpdate,
      });
    } else {
      createCustomField({
        ...data,
        defaultValue: parsedDefaultValue,
      });
    }
  }

  function parseDefaultValue(
    initialValues: IProposalCustomDataFieldFormValues,
  ) {
    if (initialValues.type === "DATE" && initialValues.defaultValue) {
      const parsedDate = dayjs(
        initialValues.defaultValue as string,
        "DD/MM/YYYY",
      );

      return parsedDate;
    }

    if (initialValues.type !== "BOOLEAN") return initialValues.defaultValue;

    if (!initialValues.defaultValue) return "";

    return initialValues.defaultValue === "true";
  }

  const type = Form.useWatch("type", form);
  const options = Form.useWatch("options", form);
  const hidden = Form.useWatch("hidden", form);

  const [isRequired, setIsRequired] = useState(false);

  useEffect(() => {
    setIsRequired(hidden);
  }, [hidden]);

  return (
    <Form
      form={form}
      data-cy="proposal-custom-field-form"
      onFinish={handleSubmitForm}
      initialValues={{
        ...DEFAULT_VALUES,
        ...initialValues,
        defaultValue: parseDefaultValue({
          ...DEFAULT_VALUES,
          ...initialValues,
        }),
      }}
      onValuesChange={(changedValues) => {
        if ("proposalStep" in changedValues) {
          form.setFieldsValue({
            proposalSection: undefined,
          });
        }
      }}
      layout="vertical"
    >
      <style jsx>{styles}</style>

      <Flex
        align="center"
        gap={screens.md ? 8 : 0}
        wrap="nowrap"
        style={{
          display: "block",
        }}
      >
        <Row>
          <Col span={screens.md ? 14 : 24}>
            <Form.Item
              label="Nome do campo"
              name="label"
              rules={[
                {
                  required: true,
                  message: "Por favor, insira um nome do campo.",
                },
              ]}
              style={{ width: "100%" }}
            >
              <CustomInput.Text placeholder="Ex.: Revisão" maxLength={255} />
            </Form.Item>
            <Form.Item
              label="Passo da proposta"
              name="proposalStep"
              rules={[
                {
                  required: true,
                  message: "Por favor, selecione um passo de proposta.",
                },
              ]}
              tooltip="Ao selecionar uma seção da proposta, o campo de 'Seção da Página' será redefinido, contendo apenas as opções de seções específicas dessa página na proposta."
              style={{ width: "100%" }}
            >
              <Select.ProposalStep
                options={{
                  hideKits: true,
                  hideProposal: true,
                }}
              />
            </Form.Item>
            <Form.Item
              label="Seção da página"
              name="proposalSection"
              rules={[
                {
                  required: true,
                  message: "Por favor, selecione uma seção da página.",
                },
              ]}
              style={{ width: "100%" }}
            >
              <Select.ProposalSections
                proposalStep={proposalStep}
                disabled={proposalStep ? false : true}
              />
            </Form.Item>
            <Form.Item
              label="Tipo de campo"
              name="type"
              style={{ width: "100%" }}
            >
              <Select.ProposalCustomFieldType
                onChange={() => {
                  form.setFieldValue("defaultValue", "");
                }}
              />
            </Form.Item>
            <Form.Item
              label="Opções:"
              name="options"
              hidden={type !== "SELECT"}
            >
              <AntdSelect
                mode="tags"
                placeholder="Digite as opções"
                disabled={type !== "SELECT"}
              />
            </Form.Item>

            {type === "BOOLEAN" && (
              <Form.Item
                label="Valor padrão:"
                name="defaultValue"
                rules={[
                  {
                    required: isRequired,
                    message: "Por favor, insira um valor padrão.",
                  },
                ]}
              >
                <Radio.Group
                  options={[
                    {
                      label: "Sim",
                      value: true,
                    },
                    {
                      label: "Não",
                      value: false,
                    },
                    {
                      label: "Vazio",
                      value: "",
                    },
                  ]}
                />
              </Form.Item>
            )}

            {type === "NUMBER" && (
              <Form.Item
                label="Valor padrão:"
                name="defaultValue"
                rules={[
                  {
                    required: isRequired,
                    message: "Por favor, insira um valor padrão.",
                  },
                ]}
              >
                <CustomInput.Number
                  type="number"
                  placeholder="Ex.: 32"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            )}

            {type === "TEXT" && (
              <Form.Item
                label="Valor padrão:"
                name="defaultValue"
                rules={[
                  {
                    required: isRequired,
                    message: "Por favor, insira um valor padrão.",
                  },
                ]}
              >
                <AntdInput.TextArea
                  placeholder="Ex.: Revisão de proposta número 1"
                  style={{ width: "100%", maxHeight: 120 }}
                  maxLength={255}
                />
              </Form.Item>
            )}

            {type === "SELECT" && (
              <Form.Item
                label="Valor padrão:"
                name="defaultValue"
                rules={[
                  {
                    required: isRequired,
                    message: "Por favor, insira um valor padrão.",
                  },
                ]}
              >
                <AntdSelect
                  placeholder="Selecione..."
                  disabled={type !== "SELECT"}
                  options={(options ?? []).map((option: string) => ({
                    label: option,
                    value: option,
                  }))}
                  allowClear
                />
              </Form.Item>
            )}

            {type === "DATE" && (
              <Form.Item
                label="Valor padrão:"
                name="defaultValue"
                rules={[
                  {
                    required: isRequired,
                    message: "Por favor, insira um valor padrão.",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  format="DD/MM/YYYY"
                  allowClear={false}
                />
              </Form.Item>
            )}
            {type === "IMAGE" && (
              <>
                <Form.Item
                  label="Altura da imagem"
                  name="height"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, insira a altura da imagem!",
                    },
                    {
                      validator: (_rule, value) => {
                        return value > 0
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error("Valor tem que ser maior que zero!"),
                            );
                      },
                    },
                  ]}
                >
                  <CustomInput.Number
                    type="number"
                    placeholder="Ex.: 32"
                    style={{ width: "100%" }}
                    precision={0}
                  />
                </Form.Item>
                <Form.Item
                  label="Largura da imagem"
                  name="width"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, insira a largura da imagem!",
                    },
                    {
                      validator: (_rule, value) => {
                        return value > 0
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error("Valor tem que ser maior que zero!"),
                            );
                      },
                    },
                  ]}
                >
                  <CustomInput.Number
                    type="number"
                    placeholder="Ex.: 32"
                    style={{ width: "100%" }}
                    precision={0}
                  />
                </Form.Item>
              </>
            )}
            <Form.Item
              label="Identificador"
              name="identifier"
              rules={[
                {
                  pattern: new RegExp(/^[a-zA-Z0-9_]*$/),
                  message: "Apenas letras, números ou underlines",
                },
              ]}
              tooltip="Não pode conter espaços e/ou caracteres especiais"
            >
              <CustomInput.Text
                placeholder="Ex.: kit_solar"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col
            span={screens.md ? 2 : 24}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Divider
              type={screens.md ? "vertical" : "horizontal"}
              style={{ height: "100%" }}
            />
          </Col>

          <Col span={screens.md ? 8 : 24}>
            <Flex vertical gap={8}>
              <Typography.Text>Características do campo</Typography.Text>

              <Form.Item<IProposalCustomDataFieldFormValues>
                name="required"
                style={{ marginBottom: 0, paddingBottom: 0 }}
                valuePropName="checked"
              >
                <Checkbox>Obrigatório</Checkbox>
              </Form.Item>
              <Form.Item<IProposalCustomDataFieldFormValues>
                name="editable"
                style={{ marginBottom: 0, paddingBottom: 0 }}
                valuePropName="checked"
              >
                <Checkbox>Editável</Checkbox>
              </Form.Item>
              <Form.Item<IProposalCustomDataFieldFormValues>
                name="hidden"
                style={{ marginBottom: 0, paddingBottom: 0 }}
                valuePropName="checked"
              >
                <Checkbox>Oculto</Checkbox>
              </Form.Item>
            </Flex>
          </Col>
        </Row>
      </Flex>
      <Flex gap={12} justify="end">
        <Form.Item style={{ margin: 0 }}>
          <Button onClick={onCancel}>Cancelar</Button>
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={isCreatingCustomField || isUpdatingCustomField}
          >
            Salvar
          </Button>
        </Form.Item>
      </Flex>
    </Form>
  );
}
