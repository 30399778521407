import { useState } from "react";

import { PDFViewerModal } from "../PDFViewerModal";
import type { IDatasheetLastRevisionModalProps } from "./interface";

import { Alert } from "~components/Alert";
import { useFetchGetDatasheetLastRevision } from "~hooks/api";
import { useFetchGetPdfBase64FromDatasheetRevisionById } from "~hooks/api/admin/datasheets/useFetchGetPdfBase64FromDatasheetRevisionById";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { Col, Flex, Modal as AntdModal, Row, Skeleton } from "antd";
import dayjs from "dayjs";

export function DatasheetLastRevisionModal({
  isOpen = false,
  onClose = () => null,
  title = "Ultima revisão do Datasheet",
  maxWidth = 512,
  datasheetId,
}: IDatasheetLastRevisionModalProps) {
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);

  const {
    data: datasheetData,
    isFetching: isLoadingDatasheet,
    error: datasheetError,
    refetch: reloadDatasheetData,
  } = useFetchGetDatasheetLastRevision({
    payload: {
      id: datasheetId,
    },
    dependencyArray: [datasheetId],
    options: {
      enabled: datasheetId > 0,
      retry: 1,
    },
  });

  const {
    data: revisionData,
    isFetching: isLoadingRevisionData,
    error: revisionDataError,
  } = useFetchGetPdfBase64FromDatasheetRevisionById({
    payload: {
      id: datasheetData?.id ?? 0,
      revision: datasheetData?.revision ?? 0,
    },
    dependencyArray: [
      datasheetData?.id,
      datasheetData?.revision,
      isPdfModalOpen,
    ],
    options: {
      enabled:
        Number(datasheetData?.id) > 0 &&
        Number(datasheetData?.revision) > 0 &&
        isPdfModalOpen,
      retry: 1,
    },
  });

  return (
    <>
      <AntdModal
        data-cy="datasheet-form-modal"
        open={isOpen}
        onCancel={onClose}
        title={title}
        width="90vw"
        style={{
          maxWidth,
        }}
        footer={null}
      >
        <Flex vertical gap={16}>
          {datasheetError && (
            <Alert.CardError
              title="Falha ao carregar dados"
              errorMessage={datasheetError?.message}
              reloadFn={reloadDatasheetData}
              style={{ marginBottom: 12 }}
            />
          )}

          <Row align="top" gutter={24} wrap={false}>
            <Col span={10}>
              <SolarzTypography
                fontWeight="medium"
                variant="title"
                hierarchy="paragraph2"
                style={{ textAlign: "right" }}
              >
                Revisão:
              </SolarzTypography>
            </Col>
            <Col span={14}>
              {isLoadingDatasheet || datasheetError ? (
                <Skeleton.Input active={isLoadingDatasheet} size="small" />
              ) : (
                <SolarzTypography
                  fontWeight="regular"
                  variant="disabled"
                  hierarchy="small"
                >
                  {datasheetData?.revision ?? "-"}
                </SolarzTypography>
              )}
            </Col>
          </Row>

          <Row align="top" gutter={24} wrap={false}>
            <Col span={10}>
              <SolarzTypography
                fontWeight="medium"
                variant="title"
                hierarchy="paragraph2"
                style={{ textAlign: "right" }}
              >
                Criado em:
              </SolarzTypography>
            </Col>
            <Col span={14}>
              {isLoadingDatasheet || datasheetError ? (
                <Skeleton.Input active={isLoadingDatasheet} size="small" />
              ) : (
                <SolarzTypography
                  fontWeight="regular"
                  variant="disabled"
                  hierarchy="small"
                >
                  {datasheetData?.createdAt
                    ? dayjs(datasheetData?.createdAt)
                        .add(-3, "hours")
                        .format("DD/MM/YYYY hh:mm")
                    : "-"}
                </SolarzTypography>
              )}
            </Col>
          </Row>

          <Row align="top" gutter={24} wrap={false}>
            <Col span={10}>
              <SolarzTypography
                fontWeight="medium"
                variant="title"
                hierarchy="paragraph2"
                style={{ textAlign: "right" }}
              >
                Hash:
              </SolarzTypography>
            </Col>
            <Col span={14}>
              {isLoadingDatasheet || datasheetError ? (
                <Skeleton.Input active={isLoadingDatasheet} size="small" />
              ) : (
                <SolarzTypography
                  fontWeight="regular"
                  variant="disabled"
                  hierarchy="small"
                >
                  {datasheetData?.hash ?? "-"}
                </SolarzTypography>
              )}
            </Col>
          </Row>

          <Row align="top" gutter={24} wrap={false}>
            <Col span={10}>
              <SolarzTypography
                fontWeight="medium"
                variant="title"
                hierarchy="paragraph2"
                style={{ textAlign: "right" }}
              >
                Arquivo:
              </SolarzTypography>
            </Col>
            <Col span={14}>
              {isLoadingDatasheet || datasheetError ? (
                <Skeleton.Input active={isLoadingDatasheet} size="small" />
              ) : (
                <SolarzTypography
                  fontWeight="regular"
                  variant="disabled"
                  hierarchy="small"
                >
                  <SolarzButton
                    variant="link"
                    onClick={() => {
                      setIsPdfModalOpen(true);
                    }}
                    style={{ fontWeight: 400, textDecoration: "underline" }}
                  >
                    Arquivo PDF
                  </SolarzButton>
                </SolarzTypography>
              )}
            </Col>
          </Row>
        </Flex>
      </AntdModal>

      <PDFViewerModal
        isOpen={isPdfModalOpen}
        onClose={() => setIsPdfModalOpen(false)}
        PDFViewerProps={{
          base64pdf: revisionData?.base64 ?? "",
          initialScale: 0.7,
          isLoading: isLoadingRevisionData,
          onErrorResultProps: revisionDataError?.message
            ? {
                title: "Falha ao carregar PDF",
                subTitle: revisionDataError?.message ?? "Error desconhecido",
              }
            : undefined,
        }}
      />
    </>
  );
}
