import { useMemo, useState } from "react";
import { FaRegQuestionCircle } from "react-icons/fa";

import type {
  IDealClientSearchSelectProps,
  DealClientSearchSelectRecordType,
} from "./interface";

import { Company } from "~estrela-ui/icons";
import { useFetchSearchDeal } from "~hooks/api";
import { SolarzSelect } from "~solarzui/SolarzSelect";
import { Flex } from "antd";

function getOptionPropsByDeal(record: DealClientSearchSelectRecordType) {
  if (record.name) {
    return {
      label: record.name,
      icon: <Company />,
    };
  }
  return {
    label: "???",
    icon: <FaRegQuestionCircle size={18} strokeWidth={0.75} />,
  };
}

export function DealClientSearchSelect({
  initialRecord,
  ...props
}: IDealClientSearchSelectProps) {
  const [searchValue, setSearchValue] = useState("");

  const canMakeRequest = searchValue.length >= 3;

  const { data, isFetching, error, refetch } = useFetchSearchDeal({
    dependencyArray: [searchValue],
    payload: {
      term: searchValue,
    },
    options: {
      enabled: canMakeRequest,
    },
  });

  const defaultOption = useMemo(() => {
    if (!initialRecord) return undefined;

    const { icon, label } = getOptionPropsByDeal(initialRecord);

    return [
      {
        label: (
          <Flex align="center" gap={8}>
            {icon}
            <span>
              {initialRecord.id} - {label}
            </span>
          </Flex>
        ),
        record: initialRecord,
        value: initialRecord.id,
      },
    ];
  }, [initialRecord]);

  return (
    <SolarzSelect<DealClientSearchSelectRecordType, "id">
      {...props}
      searchValue={props.searchValue ?? searchValue}
      onChangeSearch={(value) => {
        setSearchValue(value);

        if (typeof props.onChangeSearch === "function") {
          props.onChangeSearch(value);
        }
      }}
      options={
        data?.map((option) => {
          const { icon, label } = getOptionPropsByDeal(option);

          return {
            label: (
              <Flex align="center" gap={8}>
                {icon}
                <span>
                  {option.id} - {label}
                </span>
              </Flex>
            ),
            record: option,
            value: option.id,
          };
        }) || defaultOption
      }
      isLoading={isFetching}
      refetchFn={canMakeRequest ? refetch : undefined}
      errorMessage={error?.message}
      emptyMessage={
        canMakeRequest
          ? "Nenhum cliente do negócio foi encontrado"
          : "Digite ao menos 3 letras para buscar"
      }
      showSearch
    />
  );
}
