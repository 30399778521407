import type { IOrganizationDefaultFieldFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function OrganizationDefaultFieldFormModal({
  isOpen = false,
  onClose = () => null,
  width = "90vw",
  formProps = {
    initialValues: undefined,
  },
}: IOrganizationDefaultFieldFormModalProps) {
  return (
    <Modal
      data-cy="organization-default-field-form-modal"
      open={isOpen}
      onCancel={onClose}
      title="Campo padrão de organização"
      width={width}
      style={{ maxWidth: 890 }}
      footer={null}
      destroyOnClose
    >
      <Form.OrganizationDefaultField {...formProps} onCancel={onClose} />
    </Modal>
  );
}
