import { AiOutlineMinusCircle, AiOutlinePlus } from "react-icons/ai";

import type {
  IOptionSelectDistributor,
  IStructureDistributorType,
  IStructureDistributorsFormProps,
  IStructureDistributorsFormValues,
} from "./interface";
import { StyledButton } from "./styles";

import { CustomInput } from "~components/CustomInput";
import { Select } from "~components/Select";
import {
  useFetchGetCompanyDistributors,
  useFetchGetPaginatedDistributors,
  useFetchGetStructureDistributors,
  useFetchUpdateStructureDistributors,
} from "~hooks/api";
import {
  Alert,
  Button,
  Col,
  Divider,
  Empty,
  Flex,
  Form,
  Row,
  Spin,
  Select as AntdSelect,
} from "antd";

const DEFAULT_VALUES: IStructureDistributorsFormValues = {
  distributorStructures: [],
};

function MinusButtonToRemoveLine({
  disabled = false,
  onClick = () => undefined,
}: {
  onClick?: () => void;
  disabled?: boolean;
}) {
  return (
    <Flex justify="center" align="center" style={{ height: "100%" }}>
      <Button
        type="text"
        size="small"
        shape="circle"
        style={{
          lineHeight: 0,
        }}
        onClick={onClick}
        disabled={disabled}
      >
        <AiOutlineMinusCircle size={16} />
      </Button>
    </Flex>
  );
}

export function StructureDistributorsForm({
  formInstance,
  onCancel,
  onError,
  onSuccess,
  footerJustify = "flex-end",
  maxContentHeight,
}: IStructureDistributorsFormProps) {
  const [form] = Form.useForm<IStructureDistributorsFormValues>(formInstance);

  Form.useWatch(["distributorStructures"], form);

  const {
    data: initialData = [],
    isFetching: isLoadingInitialData,
    error: initialDataError,
    refetch: reloadInitialData,
  } = useFetchGetStructureDistributors({
    options: {
      onSuccess: (data) => {
        form.setFieldValue("distributorStructures", data);
      },
    },
  });

  const {
    mutate: updateStructureDistributors,
    isLoading: isUpdatingStructureDistributors,
  } = useFetchUpdateStructureDistributors({
    options: {
      onSuccess,
      onError,
    },
  });

  const { data: companyDistributors = [] } = useFetchGetCompanyDistributors({
    dependencyArray: [],
    options: {
      retry: 1,
    },
  });

  const { data: allDistributorsPaginated } = useFetchGetPaginatedDistributors({
    dependencyArray: [],
    options: {
      retry: 1,
    },
    payload: {
      page: 0,
      size: 100,
    },
  });
  const allDistributors = allDistributorsPaginated?.content ?? [];

  function handleCancel() {
    typeof onCancel === "function"
      ? onCancel()
      : form.setFieldValue("distributorStructures", initialData);
  }

  function handleSubmit(formData: IStructureDistributorsFormValues) {
    updateStructureDistributors(formData.distributorStructures);
  }

  const isLoading = isLoadingInitialData || isUpdatingStructureDistributors;

  const OPTIONS = companyDistributors.map((item) => ({
    label: item.name,
    value: item.id,
    record: item,
    disabled: false,
  }));

  return (
    <Form
      form={form}
      initialValues={DEFAULT_VALUES}
      layout="vertical"
      onFinish={handleSubmit}
    >
      {initialDataError && (
        <Alert
          type="error"
          message={
            <Flex align="center" gap={4}>
              Não foi possível obter as estruturas atuais, deseja
              <Button
                size="small"
                type="link"
                style={{ padding: 0 }}
                onClick={() => reloadInitialData()}
              >
                <span style={{ textDecoration: "underline" }}>
                  Carregar novamente?
                </span>
              </Button>
            </Flex>
          }
          showIcon
          description={initialDataError?.message}
        />
      )}

      <Spin spinning={isLoadingInitialData}>
        <Form.List name="distributorStructures">
          {(fields, { add, remove }) => {
            return (
              <Flex vertical>
                <Flex
                  vertical
                  style={{ maxHeight: maxContentHeight, overflowY: "auto" }}
                >
                  {initialData.length === 0 && fields.length === 0 && (
                    <Empty
                      key={0}
                      description="Nenhuma estrutura vinculada"
                      style={{ marginBottom: 12 }}
                    />
                  )}
                  {fields.map((field, index) => {
                    const distributorId = form.getFieldValue([
                      "distributorStructures",
                      field.name,
                      "distributorId",
                    ]);

                    const newOptions: IOptionSelectDistributor[] = [];

                    const komeco = allDistributors.find(
                      (distributor) => distributor.name === "KOMECO",
                    );
                    const isKomecoActive = distributorId === komeco?.id;
                    const hasKomecoInOptions = OPTIONS.some(
                      (option) => option.value === komeco?.id,
                    );
                    if (isKomecoActive && !hasKomecoInOptions && komeco) {
                      newOptions.push({
                        label: komeco?.name,
                        value: komeco?.id,
                        record: komeco,
                        disabled: true,
                      });
                    }

                    const edeltec = allDistributors.find(
                      (distributor) => distributor.name === "EDELTEC",
                    );
                    const isEdeltecActive = distributorId === edeltec?.id;
                    const hasEdeltecInOptions = OPTIONS.some(
                      (option) => option.value === edeltec?.id,
                    );
                    if (isEdeltecActive && !hasEdeltecInOptions && edeltec) {
                      newOptions.push({
                        label: edeltec?.name,
                        value: edeltec?.id,
                        record: edeltec,
                        disabled: true,
                      });
                    }

                    const fortlev = allDistributors.find(
                      (distributor) => distributor.name === "FORTLEV",
                    );
                    const isFortlevActive = distributorId === fortlev?.id;
                    const hasFortlevInOptions = OPTIONS.some(
                      (option) => option.value === fortlev?.id,
                    );
                    if (isFortlevActive && !hasFortlevInOptions && fortlev) {
                      newOptions.push({
                        label: fortlev?.name,
                        value: fortlev?.id,
                        record: fortlev,
                        disabled: true,
                      });
                    }
                    const aldo = allDistributors.find(
                      (distributor) => distributor.name === "ALDO",
                    );

                    const isAldoActive = distributorId === aldo?.id;

                    const hasAldoInOptions = OPTIONS.some(
                      (option) => option.value === aldo?.id,
                    );

                    if (isAldoActive && !hasAldoInOptions && aldo) {
                      newOptions.push({
                        label: aldo?.name,
                        value: aldo?.id,
                        record: aldo,
                        disabled: true,
                      });
                    }

                    const ecori = allDistributors.find(
                      (distributor) => distributor.name === "ECORI",
                    );
                    const isEcoriActive = distributorId === ecori?.id;
                    const hasEcoriInOptions = OPTIONS.some(
                      (option) => option.value === ecori?.id,
                    );
                    if (isEcoriActive && !hasEcoriInOptions && ecori) {
                      newOptions.push({
                        label: ecori?.name,
                        value: ecori?.id,
                        record: ecori,
                        disabled: true,
                      });
                    }

                    return (
                      <Row
                        key={field.key}
                        style={{ width: "100%" }}
                        gutter={16}
                      >
                        <Form.Item<IStructureDistributorType["id"]>
                          name="id"
                          hidden
                        >
                          <CustomInput.Text readOnly disabled />
                        </Form.Item>

                        <Col style={{ width: "100%", maxWidth: "320px" }}>
                          <Form.Item<IStructureDistributorType["distributorId"]>
                            name={[field.name, "distributorId"]}
                            label="Distribuidor"
                            style={{ width: "100%" }}
                            rules={[
                              { required: true, message: "Campo obrigatório!" },
                            ]}
                          >
                            <Select.CompanyDistributor
                              newOptions={newOptions}
                            />
                          </Form.Item>
                        </Col>

                        <Col style={{ width: "100%", maxWidth: "320px" }}>
                          <Form.Item<
                            IStructureDistributorType["structureTypeId"]
                          >
                            name={[field.name, "structureTypeId"]}
                            label="Estrutura"
                            style={{ width: "100%" }}
                            rules={[
                              { required: true, message: "Campo obrigatório!" },
                            ]}
                          >
                            <Select.CompanyStructureType />
                          </Form.Item>
                        </Col>

                        <Col flex={3}>
                          <Form.Item<
                            IStructureDistributorType["structureDistributorIdentifiers"]
                          >
                            name={[
                              field.name,
                              "structureDistributorIdentifiers",
                            ]}
                            label={
                              isKomecoActive ||
                              isEdeltecActive ||
                              isAldoActive ||
                              isEcoriActive
                                ? "Estrutura do distribuidor"
                                : "Código da estrutura do distribuidor"
                            }
                            style={{ width: "100%" }}
                            rules={[
                              { required: true, message: "Campo obrigatório!" },
                            ]}
                            dependencies={[form]}
                            tooltip={
                              isFortlevActive &&
                              "A integração com a Fortlev só permite associar uma única estrutura"
                            }
                          >
                            {isEdeltecActive && (
                              <Select.EdeltecStructure mode="tags" />
                            )}
                            {isKomecoActive && (
                              <Select.KomecoEquipmentStructureType mode="tags" />
                            )}
                            {isFortlevActive && (
                              <Select.FortlevStructure
                                mode="tags"
                                maxCount={1}
                              />
                            )}
                            {isAldoActive && (
                              <Select.AldoStructure mode="tags" />
                            )}
                            {isEcoriActive && (
                              <Select.EcoriStructure mode="tags" />
                            )}
                            {!isEdeltecActive &&
                              !isKomecoActive &&
                              !isFortlevActive &&
                              !isAldoActive &&
                              !isEcoriActive && (
                                <AntdSelect
                                  mode="tags"
                                  tokenSeparators={[","]}
                                  style={{ width: "100%" }}
                                  allowClear
                                />
                              )}
                          </Form.Item>
                        </Col>

                        <Col>
                          <MinusButtonToRemoveLine
                            onClick={() => remove(field.name)}
                          />
                        </Col>

                        {index !== fields.length - 1 && (
                          <Divider
                            style={{
                              marginTop: 0,
                              marginBottom: 12,
                              padding: 0,
                            }}
                          />
                        )}
                      </Row>
                    );
                  })}
                </Flex>

                <Divider style={{ margin: "0 0 0.5rem 0" }} />

                <StyledButton
                  block
                  type="dashed"
                  className="addInverterButton"
                  onClick={() =>
                    add({
                      category: undefined,
                      name: undefined,
                      quantity: 1,
                      unityAmount: 0,
                    })
                  }
                >
                  <Flex align="center" justify="center" gap={8}>
                    <AiOutlinePlus size={16} />
                    Nova estrutura
                  </Flex>
                </StyledButton>
              </Flex>
            );
          }}
        </Form.List>
      </Spin>

      <Flex
        justify={footerJustify}
        align="center"
        gap={12}
        style={{
          marginTop: 16,
        }}
      >
        <Button onClick={handleCancel}>Cancelar</Button>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Salvar
        </Button>
      </Flex>
    </Form>
  );
}
