import { useEffect } from "react";
import { IoReload } from "react-icons/io5";

import type {
  IAdminCompanyConfigurationFormData,
  IAdminCompanyConfigurationFormProps,
} from "./interface";

import { CustomInput } from "~components/CustomInput";
import {
  useFetchGetUserCompanyConfiguration,
  useFetchUpdateUserCompanyConfiguration,
} from "~hooks/api";
import { locationServiceEnumValues } from "~types/enum";
import { translateLocationServiceEnum } from "~utils/translate";
import { Alert, Button, Flex, Form, Select, Spin, Tooltip } from "antd";

const DEFAULT_INITIAL_VALUES: IAdminCompanyConfigurationFormData = {
  accountOwnerEmail: undefined,
  locationService: undefined,
  maxUsersLicensed: undefined,
};

export function AdminCompanyConfigurationForm({
  companyId,
  formInstance,
  initialValues,
  onCancel,
  onError,
  onSuccess,
}: IAdminCompanyConfigurationFormProps) {
  const [form] = Form.useForm<IAdminCompanyConfigurationFormData>(formInstance);

  const {
    data: adminCompanyConfigurationData,
    isFetching: isLoadingAdminCompanyConfigurationData,
    error: adminCompanyConfigurationError,
    refetch: reloadAdminCompanyConfiguration,
  } = useFetchGetUserCompanyConfiguration({
    payload: {
      companyId,
    },
    options: {
      enabled: companyId > 0,
      retry: 1,
    },
  });

  const {
    mutate: updateAdminCompanyConfiguration,
    isLoading: isUpdatingAdminCompanyConfiguration,
  } = useFetchUpdateUserCompanyConfiguration({
    options: {
      onSuccess,
      onError,
    },
  });

  function handleSubmit(formData: IAdminCompanyConfigurationFormData) {
    updateAdminCompanyConfiguration({ ...formData, companyId });
  }

  function handleCancel() {
    form.setFieldsValue({
      ...DEFAULT_INITIAL_VALUES,
      ...initialValues,
    });

    if (typeof onCancel === "function") {
      onCancel();
    }
  }

  useEffect(() => {
    if (adminCompanyConfigurationData) {
      form.setFieldsValue(adminCompanyConfigurationData);
    }
  }, [form, adminCompanyConfigurationData]);

  return (
    <Form
      data-cy="admin-company-configuration-form"
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        ...DEFAULT_INITIAL_VALUES,
        ...initialValues,
      }}
    >
      {adminCompanyConfigurationError && (
        <Alert
          type="error"
          message="Falha ao carregar dados iniciais"
          description={adminCompanyConfigurationError.message}
          style={{ marginBottom: "1rem", marginTop: "1rem" }}
          action={
            <Tooltip title="Tentar recarregar dados">
              <Button
                onClick={() => reloadAdminCompanyConfiguration()}
                icon={<IoReload strokeWidth={4} size={18} />}
              />
            </Tooltip>
          }
        />
      )}

      <Spin spinning={isLoadingAdminCompanyConfigurationData}>
        <Form.Item<IAdminCompanyConfigurationFormData>
          name="maxUsersLicensed"
          label="Máximo de usuários licenciados"
          rules={[{ required: true, message: "Este campo é obrigatório" }]}
        >
          <CustomInput.Number min={0} precision={0} size="large" />
        </Form.Item>

        <Form.Item<IAdminCompanyConfigurationFormData>
          name="locationService"
          label="Serviço de localização"
          rules={[
            { required: true, message: "Serviço de localização é obrigatório" },
          ]}
        >
          <Select
            options={locationServiceEnumValues.map((option) => {
              return {
                key: option,
                value: option,
                label: translateLocationServiceEnum(option),
              };
            })}
            size="large"
          />
        </Form.Item>

        <Form.Item<IAdminCompanyConfigurationFormData>
          name="accountOwnerEmail"
          label="Email"
          rules={[
            { required: true, message: "Campo é obrigatório" },
            { type: "email", message: "Email é inválido" },
          ]}
        >
          <CustomInput.Text type="email" size="large" />
        </Form.Item>
      </Spin>

      <Flex align="center" gap={8} justify="end">
        <Button
          onClick={handleCancel}
          disabled={isUpdatingAdminCompanyConfiguration}
        >
          Cancelar
        </Button>
        <Button
          loading={isUpdatingAdminCompanyConfiguration}
          type="primary"
          htmlType="submit"
          disabled={isLoadingAdminCompanyConfigurationData}
        >
          Atualizar dados
        </Button>
      </Flex>
    </Form>
  );
}
