import type { ISendTemplateFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Flex, Modal, Typography } from "antd";

export function SendTemplateFormModal({
  isOpen = false,
  onClose = () => null,
  title = "Enviar template",
  maxWidth = 720,
  formProps,
}: ISendTemplateFormModalProps) {
  return (
    <Modal
      data-cy="send-template-form-modal"
      open={isOpen}
      onCancel={onClose}
      title={title}
      width="90vw"
      footer={null}
      style={{ maxWidth }}
      destroyOnClose
    >
      <Flex vertical align="center">
        <Typography.Title level={4}>
          Enviar o template de proposta gerada
        </Typography.Title>

        <Typography.Text>
          Faça o envio do template da proposta com placeholders dinâmicos
        </Typography.Text>

        <Form.SendTemplate {...formProps} onCancel={onClose} />
      </Flex>
    </Modal>
  );
}
