import { useState } from "react";
import { IoMdAdd } from "react-icons/io";

import type { ICreateSysAdminUserButtonProps } from "./interface";

import { Modal } from "~components/Modal";
import { useAppConfig } from "~hooks/useAppConfig";
import { SolarzButton } from "~solarzui/SolarzButton";

export function CreateSysAdminUserButton({
  children,
  icon = <IoMdAdd size={22} />,
  ...props
}: ICreateSysAdminUserButtonProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { screens } = useAppConfig();

  return (
    <>
      <SolarzButton
        {...props}
        onClick={() => setIsModalOpen(true)}
        variant="primary"
        htmlType="button"
        scale={screens.mobile ? "tiny" : "medium"}
      >
        {icon}
        {children ?? "Criar novo admin"}
      </SolarzButton>
      <Modal.AdminForm
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        formProps={{
          onSuccess: () => setIsModalOpen(false),
        }}
      />
    </>
  );
}
