import { useState } from "react";

import type { ICreateSizingKitButtonProps } from "./interface";

import { Modal } from "~components/Modal";
import { Button } from "antd";

export function CreateSizingKitButton({
  children,
  modalProps,
  type = "primary",
  ...props
}: ICreateSizingKitButtonProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button
        {...props}
        data-cy="create-sizing-kit-button"
        onClick={() => setIsModalOpen(true)}
        htmlType="button"
        type={type}
      >
        {children ?? "Kit dimensionado"}
      </Button>

      <Modal.SizingKitForm
        {...modalProps}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        formProps={{
          ...modalProps?.formProps,
          // onSuccess: () => {
          //   if (modalProps?.formProps?.onSuccess) {
          //     modalProps?.formProps?.onSuccess();
          //   }

          //   setIsModalOpen(false);
          // },
          onSubmit: (data) => {
            if (modalProps?.formProps?.onSubmit) {
              modalProps?.formProps?.onSubmit(data);
            }

            setIsModalOpen(false);
          },
        }}
      />
    </>
  );
}
