import { useMemo, useState } from "react";
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineEdit,
  AiOutlineTeam,
} from "react-icons/ai";

import {
  BusinessUnitTableColumnType,
  BusinessUnitTableDataType,
  IBusinessUnitTableProps,
} from "./interface";

import { ColumnFilter } from "~components/ColumnFilter";
import { Menu } from "~components/Menu";
import { Modal } from "~components/Modal";
import { createPaginationTableObject } from "~components/Pagination";
import { Result } from "~components/Result";
import { Tag } from "~components/Tag";
import {
  useFetchActiveBusinessUnit,
  useFetchDeactivateBusinessUnit,
  useFetchGetPaginatedBusinessUnits,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { Form, Select, Table } from "antd";

export function BusinessUnitTable({ size = "small" }: IBusinessUnitTableProps) {
  const { message } = useAppConfig();

  const { screens } = useAppConfig();

  const [selectedUnitToManageUsers, setSelectedUnitToManageUsers] =
    useState<BusinessUnitTableDataType>();

  const [selectedBusinessUnitToUpdate, setSelectedBusinessUnitToUpdate] =
    useState<BusinessUnitTableDataType>();

  const [
    selectedBusinessUnitToActivateOrDeactivate,
    setSelectedBusinessUnitToActivateOrDeactivate,
  ] = useState<BusinessUnitTableDataType>();

  const [page, setPage] = useState(0);
  const [state, setState] = useState("ALL");
  const [filterStatus, setFilterStatus] = useState<boolean | undefined>(
    undefined,
  );

  const columns: BusinessUnitTableColumnType = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      render: (value: string) => value,
    },
    {
      title: "Endereço",
      dataIndex: "address",
      key: "address",
      render: (address: BusinessUnitTableDataType["address"]) =>
        address && address != null
          ? address?.city?.name + ", " + address?.province?.name
          : "Atribua um endereço a está unidade",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      filterDropdown: (
        <ColumnFilter
          fields={[
            <Form.Item
              key="unitStatus"
              htmlFor="unitStatus"
              label="Status"
              tooltip="Filtragem por estado da unidade"
              colon={false}
            >
              <Select
                id="supplierName"
                options={[
                  { value: "ALL", label: "Todos" },
                  { value: "ACTIVE", label: "Ativos" },
                  { value: "INACTIVE", label: "Inativos" },
                ]}
                value={state}
                onChange={(event) => {
                  setState(event);
                }}
                placeholder="Fornecedor..."
              />
            </Form.Item>,
          ]}
          onReset={() => setState("ALL")}
          onSubmit={() => handleFilterStatus()}
        />
      ),
      filteredValue: filterStatus ? [filterStatus] : null,
      render: (_, { active }) => (
        <Tag.UniqueValue
          tooltip={`A unidade está ${active ? "Ativada" : "Desativada"}`}
          title={active ? "Ativo" : "Inativo"}
          color={active ? "green" : "volcano"}
        />
      ),
    },
    {
      title: "",
      align: "center" as const,
      width: "4rem",
      render: (_, item) => (
        <Menu.MoreDropdown
          position="bottomRight"
          items={[
            {
              icon: (
                <AiOutlineTeam
                  fontSize={18}
                  style={{ color: "var(--blue-500)" }}
                />
              ),
              text: "Gerenciar usuários",
              color: "var(--blue-500)",
              onClick: () => setSelectedUnitToManageUsers(item),
            },
            {
              icon: (
                <AiOutlineEdit
                  fontSize={16}
                  style={{ color: "var(--gold-500)" }}
                />
              ),
              text: "Editar unidade de negócio",
              color: "var(--gold-500)",
              onClick: () => setSelectedBusinessUnitToUpdate(item),
            },
            {
              icon: item.active ? (
                <AiOutlineClose
                  fontSize={16}
                  style={{ color: "var(--volcano-500)" }}
                />
              ) : (
                <AiOutlineCheck
                  fontSize={16}
                  style={{ color: "var(--green-500)" }}
                />
              ),
              text: item.active
                ? "Desativar unidade de negócio"
                : "Ativar unidade de negócio",
              color: item.active ? "var(--volcano-500)" : "var(--green-500)",
              onClick: () =>
                setSelectedBusinessUnitToActivateOrDeactivate(item),
            },
          ]}
        />
      ),
    },
  ];

  function onPageChange(page: number) {
    setPage(page);
  }

  function handleFilterStatus() {
    switch (state) {
      case "ALL":
        setFilterStatus(undefined);
        break;
      case "ACTIVE":
        setFilterStatus(true);
        break;
      case "INACTIVE":
        setFilterStatus(false);
        break;
    }
  }

  const {
    data: businessUnits,
    isFetching: isLoadingBusinessUnits,
    error: businessUnitError,
  } = useFetchGetPaginatedBusinessUnits({
    dependencyArray: [page, filterStatus],
    payload: { page, isActive: filterStatus },
    options: {
      onError: (error) => {
        message.error(error.message);
      },
    },
  });

  const {
    mutate: deactivateBusinessUnit,
    isLoading: isLoadingDeactivateBusinessUnits,
  } = useFetchDeactivateBusinessUnit({
    onSuccess: () => setSelectedBusinessUnitToActivateOrDeactivate(undefined),
  });

  const {
    mutate: activateBusinessUnit,
    isLoading: isLoadingActiveBusinessUnits,
  } = useFetchActiveBusinessUnit({
    onSuccess: () => setSelectedBusinessUnitToActivateOrDeactivate(undefined),
  });

  const dataSource: BusinessUnitTableDataType[] = useMemo(() => {
    return (
      businessUnits?.businessUnits.map((item) => ({
        id: item.id,
        name: item.name,
        active: item.active,
        company: item.company,
        address: item.address,
      })) ?? []
    );
  }, [businessUnits]);

  function handleActivateOrDeactivateBusinessUnit() {
    const businessUnitId = selectedBusinessUnitToActivateOrDeactivate?.id ?? 0;
    const businessUnitIsActive =
      !!selectedBusinessUnitToActivateOrDeactivate?.active;

    businessUnitIsActive
      ? deactivateBusinessUnit({ businessUnitId })
      : activateBusinessUnit({ businessUnitId });
  }

  return (
    <>
      <Table
        data-cy="business-unit-table"
        loading={isLoadingBusinessUnits}
        columns={columns}
        dataSource={dataSource}
        rowKey="id"
        pagination={createPaginationTableObject({
          size: businessUnits?.size,
          number: businessUnits?.number ? businessUnits?.number + 1 : 1,
          itemType: "unidades",
          totalElements: businessUnits?.totalElements,
          onChange: (page) => onPageChange(page - 1),
          displayTotalInformation: screens.sm,
        })}
        scroll={{ x: 660 }}
        size={size}
        locale={{
          emptyText: (
            <Result.TableErrorOrEmpty
              errorMessage={businessUnitError?.message}
              emptyMessage="Nenhuma unidade de negócio encontrada."
            />
          ),
        }}
      />

      <Modal.DefaultAlert
        isOpen={!!selectedBusinessUnitToActivateOrDeactivate}
        onClose={() => setSelectedBusinessUnitToActivateOrDeactivate(undefined)}
        isLoading={
          isLoadingDeactivateBusinessUnits || isLoadingActiveBusinessUnits
        }
        subtitle={selectedBusinessUnitToActivateOrDeactivate?.name}
        title={
          selectedBusinessUnitToActivateOrDeactivate?.active
            ? "Desativar unidade de negócio"
            : "Ativar unidade de negócio"
        }
        onSubmit={handleActivateOrDeactivateBusinessUnit}
      />

      <Modal.ManageUsers
        onClose={() => setSelectedUnitToManageUsers(undefined)}
        isOpen={!!selectedUnitToManageUsers}
        unit={selectedUnitToManageUsers}
        idUnit={selectedUnitToManageUsers?.id}
      />

      <Modal.BusinessUnitForm
        isOpen={!!selectedBusinessUnitToUpdate}
        onClose={() => setSelectedBusinessUnitToUpdate(undefined)}
        formProps={{
          initialValues: {
            addressBaseId: selectedBusinessUnitToUpdate?.address?.id,
            id: selectedBusinessUnitToUpdate?.id,
            name: selectedBusinessUnitToUpdate?.name,
          },
          onSuccess: () => setSelectedBusinessUnitToUpdate(undefined),
        }}
      />
    </>
  );
}
