import { useMemo } from "react";

import type {
  ILogActionTypeSelect,
  LogActionTypeOptionType,
  LogActionTypeRecordType,
} from "./interface";

import { SolarzSelect } from "~solarzui/SolarzSelect";
import { logActionTypeEnumValues } from "~types/enum";
import { translateLogActionType } from "~utils/translate";

export function LogActionTypeSelect({
  hideOptions,
  disabledOptions,
  ...props
}: ILogActionTypeSelect) {
  const OPTIONS = useMemo(() => {
    return logActionTypeEnumValues.map((value) => ({
      value,
      record: {
        value,
      },
      label: translateLogActionType(value),
      hidden: hideOptions && hideOptions.includes(value),
      disabled: disabledOptions && disabledOptions.includes(value),
    })) as LogActionTypeOptionType;
  }, [disabledOptions, hideOptions]);

  return (
    <SolarzSelect<LogActionTypeRecordType, "value">
      {...props}
      options={OPTIONS}
    />
  );
}
