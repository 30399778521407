import { useState } from "react";

import type { ISetProposalStatusButtonProps } from "./interface";

import { Modal } from "~components/Modal";
import { Button } from "antd";

export function SetProposalStatusButton({
  children,
  action,
  pipedriveDealId = 0,
  dealId = 0,
  customId = 0,
  ...props
}: ISetProposalStatusButtonProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button
        {...props}
        data-cy="set-proposal-status-button"
        onClick={() => setIsModalOpen(true)}
        htmlType="button"
      >
        {children ?? "Definir proposta"}
      </Button>

      <Modal.SetProposalStatus
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSuccess={() => setIsModalOpen(false)}
        customId={customId}
        pipedriveDealId={pipedriveDealId}
        dealId={dealId}
        action={action}
      />
    </>
  );
}
