import { useCallback, useEffect, useMemo, useState } from "react";

import type {
  IAneelTableColumn,
  IAneelTableData,
  IAneelTableFilters,
  IAneelTableProps,
} from "./interface";

import { ColumnFilter } from "~components/ColumnFilter";
import { CustomInput } from "~components/CustomInput";
import { createPaginationTableObject } from "~components/Pagination";
import { Result } from "~components/Result";
import { Tag } from "~components/Tag";
import {
  useFetchGetElectricUtilityProfileAccessBy,
  useFetchGetElectricUtilityProfileConsumerClass,
  useFetchGetElectricUtilityProfileConsumerSubclass,
  useFetchGetElectricUtilityProfileDetail,
  useFetchGetElectricUtilityProfileModalities,
  useFetchGetElectricUtilityProfileSubgroups,
  useFetchPostToGetPaginatedElectricUtilityProfiles,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { createUuid } from "~utils/createUuid";
import { Button, Form, Table, Select as AntdSelect, Flex } from "antd";

const DEFAULT_FILTERS: IAneelTableFilters = {
  subgroup: [],
  modality: [],
  detail: [],
  accessBy: [],
  consumerClass: [],
  consumerSubclass: [],
  icms: undefined,
  pisCofins: undefined,
};

export function AneelTable({
  size = "small",
  defaultFilters,
  onSelect = () => undefined,
  isSelectable = false,
  displayExtraFilters = false,
  voltageType = "LOW",
  electricUtilityName,
}: IAneelTableProps) {
  const { screens } = useAppConfig();

  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState<IAneelTableFilters>({
    ...DEFAULT_FILTERS,
    ...defaultFilters,
  });

  const {
    data: aneelDataList,
    isFetching: isLoadingAneelList,
    error: aneelDataListError,
    refetch: refetchAneelList,
  } = useFetchPostToGetPaginatedElectricUtilityProfiles({
    dependencyArray: [electricUtilityName, currentPage],
    payload: {
      ...filters,
      page: currentPage,
      size: 10,
      electricUtilityName,
    },
    options: {
      enabled: !!electricUtilityName,
    },
  });

  // INITIAL DATA FROM API
  const { data: accessBy, isLoading: isLoadingFilterAccessBy } =
    useFetchGetElectricUtilityProfileAccessBy({
      options: { retry: 1, enabled: false },
    });

  const { data: consumerSubclass, isLoading: isLoadingConsumerSubclass } =
    useFetchGetElectricUtilityProfileConsumerSubclass({
      options: { retry: 1 },
    });

  const { data: consumerClass, isLoading: isLoadingConsumerClass } =
    useFetchGetElectricUtilityProfileConsumerClass({
      options: { retry: 1 },
    });

  const { data: detail, isLoading: isLoadingFilterDetail } =
    useFetchGetElectricUtilityProfileDetail({
      options: { retry: 1 },
    });

  const { data: subgroup, isLoading: isLoadingSubgroup } =
    useFetchGetElectricUtilityProfileModalities({
      options: { retry: 1 },
    });

  const { data: modality, isLoading: isLoadingModality } =
    useFetchGetElectricUtilityProfileSubgroups({
      options: { retry: 1 },
    });
  // END INITIAL DATA FROM API

  const INITIAL_FILTERS = useMemo(() => {
    return {
      ...DEFAULT_FILTERS,
      ...defaultFilters,
      accessBy,
      consumerClass,
      consumerSubclass,
      subgroup,
      modality,
      detail,
    };
  }, [
    accessBy,
    consumerClass,
    consumerSubclass,
    defaultFilters,
    detail,
    subgroup,
    modality,
  ]);

  const FILTERS_ARE_LOADED = useMemo(() => {
    return (
      !isLoadingFilterAccessBy &&
      !isLoadingConsumerSubclass &&
      !isLoadingConsumerClass &&
      !isLoadingFilterDetail &&
      !isLoadingSubgroup &&
      !isLoadingModality
    );
  }, [
    isLoadingConsumerClass,
    isLoadingConsumerSubclass,
    isLoadingFilterAccessBy,
    isLoadingFilterDetail,
    isLoadingSubgroup,
    isLoadingModality,
  ]);

  function onPageChange(page: number) {
    setCurrentPage(page);
  }

  const handleSubmit = useCallback(() => {
    currentPage > 1 ? setCurrentPage(1) : refetchAneelList();
  }, [currentPage, refetchAneelList]);

  // START COLUMN RENDERS
  function renderModalityColumn(value: IAneelTableData["modality"]) {
    switch (value) {
      case "GREEN":
        return (
          <Tag.UniqueValue
            color="green"
            title="Tarifa verde"
            tooltip="Tarifa verde"
          />
        );
      case "BLUE":
        return (
          <Tag.UniqueValue
            color="blue"
            title="Tarifa azul"
            tooltip="Tarifa azul"
          />
        );
      default:
        return (
          <Tag.UniqueValue
            color="default"
            title="Tarifa desconhecida"
            tooltip="Tarifa desconhecida"
          />
        );
    }
  }
  // END COLUMN RENDER

  // START FILTER RENDERS
  const renderSubgroupFilterDropdown = useCallback(() => {
    return (
      <ColumnFilter
        fields={[
          <Form.Item htmlFor="subgroup" label="Subgrupo" key="subgroup">
            <AntdSelect<string[]>
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Subgrupos"
              value={filters?.subgroup ?? []}
              onChange={(value, _options) => {
                setFilters((current) => ({
                  ...current,
                  subgroup: value,
                }));
              }}
            />
          </Form.Item>,
        ]}
        onReset={() =>
          setFilters((current) => ({
            ...current,
            subgroup: [],
          }))
        }
        isLoading={isLoadingAneelList}
        onSubmit={handleSubmit}
      />
    );
  }, [filters?.subgroup, handleSubmit, isLoadingAneelList]);

  const renderModalityFilterDropdown = useCallback(() => {
    return (
      <ColumnFilter
        fields={[
          <Form.Item htmlFor="modality" label="Modalidade" key="modality">
            <AntdSelect<string[]>
              mode="tags"
              id="modality"
              value={filters?.modality}
              onChange={(modalities) => {
                setFilters((current) => ({
                  ...current,
                  modality: modalities,
                }));
              }}
              placeholder="Modalidade..."
            />
          </Form.Item>,
        ]}
        onReset={() =>
          setFilters((current) => ({
            ...current,
            modality: [],
          }))
        }
        isLoading={isLoadingAneelList}
        onSubmit={handleSubmit}
      />
    );
  }, [filters?.modality, handleSubmit, isLoadingAneelList]);

  const renderClassFilterDropdown = useCallback(() => {
    return (
      <ColumnFilter
        fields={[
          <Form.Item htmlFor="consumerClass" label="Classe" key="consumerClass">
            <AntdSelect<string[]>
              mode="tags"
              id="consumerClass"
              value={filters?.consumerClass}
              onChange={(consumerClass) => {
                setFilters((current) => ({
                  ...current,
                  consumerClass,
                }));
              }}
              placeholder="Classe..."
            />
          </Form.Item>,
        ]}
        onReset={() =>
          setFilters((current) => ({
            ...current,
            consumerClass: [],
          }))
        }
        isLoading={isLoadingAneelList}
        onSubmit={handleSubmit}
      />
    );
  }, [filters?.consumerClass, handleSubmit, isLoadingAneelList]);

  const renderSubclassFilterDropdown = useCallback(() => {
    return (
      <ColumnFilter
        fields={[
          <Form.Item
            htmlFor="consumerSubclass"
            label="Subclasse"
            key="consumerSubclass"
          >
            <AntdSelect<string[]>
              mode="tags"
              id="consumerSubclass"
              value={filters?.consumerSubclass}
              onChange={(consumerSubclass) => {
                setFilters((current) => ({
                  ...current,
                  consumerSubclass,
                }));
              }}
              placeholder="Subclasse..."
            />
          </Form.Item>,
        ]}
        onReset={() =>
          setFilters((current) => ({
            ...current,
            consumerSubclass: [],
          }))
        }
        isLoading={isLoadingAneelList}
        onSubmit={handleSubmit}
      />
    );
  }, [filters?.consumerSubclass, handleSubmit, isLoadingAneelList]);

  const renderDetailFilterDropdown = useCallback(() => {
    return (
      <ColumnFilter
        fields={[
          <Form.Item htmlFor="detail" label="Detalhe" key="detail">
            <AntdSelect<string[]>
              mode="tags"
              id="detail"
              value={filters?.detail}
              onChange={(detail) => {
                setFilters((current) => ({
                  ...current,
                  detail,
                }));
              }}
              placeholder="Detalhe..."
            />
          </Form.Item>,
        ]}
        onReset={() =>
          setFilters((current) => ({
            ...current,
            detail: [],
          }))
        }
        isLoading={isLoadingAneelList}
        onSubmit={handleSubmit}
      />
    );
  }, [filters?.detail, handleSubmit, isLoadingAneelList]);

  const renderAccessByFilterDropdown = useCallback(() => {
    return (
      <ColumnFilter
        fields={[
          <Form.Item htmlFor="accessBy" label="Acessante" key="accessBy">
            <AntdSelect<string[]>
              mode="tags"
              id="accessBy"
              value={filters?.accessBy}
              onChange={(accessBy) => {
                setFilters((current) => ({
                  ...current,
                  accessBy,
                }));
              }}
              placeholder="Acessante..."
            />
          </Form.Item>,
        ]}
        onReset={() =>
          setFilters((current) => ({
            ...current,
            accessBy: [],
          }))
        }
        isLoading={isLoadingAneelList}
        onSubmit={handleSubmit}
      />
    );
  }, [filters?.accessBy, handleSubmit, isLoadingAneelList]);

  // const renderTusdFilterDropdown = useCallback(() => {
  //   return (
  //     <ColumnFilter
  //       fields={[
  //         <Form.Item htmlFor="tusd" label="TUSD" key="tusd">
  //           <CustomInput.Number
  //             id="tusd"
  //             value={filters?.tusd}
  //             onChange={(value) => {
  //               const parsedValue = Number(value);

  //               setFilters((current) => ({
  //                 ...current,
  //                 tusd: parsedValue || undefined,
  //               }));
  //             }}
  //             placeholder="TUSD..."
  //           />
  //         </Form.Item>,
  //       ]}
  //       onReset={() =>
  //         setFilters((current) => ({
  //           ...current,
  //           tusd: undefined,
  //         }))
  //       }
  //       isLoading={isLoadingAneelList}
  //       onSubmit={handleSubmit}
  //     />
  //   );
  // }, [filters?.tusd, handleSubmit, isLoadingAneelList]);

  // const renderTeFilterDropdown = useCallback(() => {
  //   return (
  //     <ColumnFilter
  //       fields={[
  //         <Form.Item htmlFor="te" label="TE" key="te">
  //           <CustomInput.Number
  //             id="te"
  //             value={filters?.te}
  //             onChange={(value) => {
  //               const parsedValue = Number(value);

  //               setFilters((current) => ({
  //                 ...current,
  //                 te: parsedValue || undefined,
  //               }));
  //             }}
  //             placeholder="TE..."
  //           />
  //         </Form.Item>,
  //       ]}
  //       onReset={() =>
  //         setFilters((current) => ({
  //           ...current,
  //           te: undefined,
  //         }))
  //       }
  //       isLoading={isLoadingAneelList}
  //       onSubmit={handleSubmit}
  //     />
  //   );
  // }, [filters?.te, handleSubmit, isLoadingAneelList]);
  // END FILTER RENDERS

  const DATA_SOURCE = useMemo(
    () =>
      aneelDataList?.content.map(
        (aneelData) =>
          ({
            ...aneelData,
            key: createUuid(),
          }) ?? [],
      ),
    [aneelDataList],
  );

  const COLUMNS: IAneelTableColumn[] = useMemo(
    () =>
      (
        [
          {
            title: "Concessionária",
            dataIndex: "concessionaire",
            key: "concessionaire",
            render: (value: string) => value,
          },
          {
            title: "Resolução ANEEL",
            dataIndex: "ANEELResolution",
            key: "ANEELResolution",
            render: (value: string) => value,
          },
          {
            title: "Início vigência",
            dataIndex: "startTerm",
            key: "startTerm",
            render: (value: string) => value,
          },
          {
            title: "Fim vigência",
            dataIndex: "endTerm",
            key: "endTerm",
            render: (value: string) => value,
          },
          {
            title: "Base tarifárica",
            dataIndex: "tariffBases",
            key: "tariffBases",
            render: (value: string) => value,
          },
          {
            title: "Subgrupo",
            dataIndex: "subgroup",
            key: "subgroup",
            render: (value: string) => value,
            filterDropdown: renderSubgroupFilterDropdown,
            filteredValue: filters?.subgroup,
          },
          {
            title: "Modalidade",
            dataIndex: "modality",
            key: "modality",
            render: renderModalityColumn,
            filterDropdown: renderModalityFilterDropdown,
            filteredValue: filters?.modality,
          },
          {
            title: "Classe",
            dataIndex: "class",
            key: "class",
            render: (value: string) => value,
            filterDropdown: renderClassFilterDropdown,
            filteredValue: filters?.consumerClass,
          },
          {
            title: "Subclasse",
            dataIndex: "subclass",
            key: "subclass",
            render: (value: string) => value,
            filterDropdown: renderSubclassFilterDropdown,
            filteredValue: filters?.consumerSubclass,
          },
          {
            title: "Detalhe",
            dataIndex: "detail",
            key: "detail",
            render: (value: string) => value,
            filterDropdown: renderDetailFilterDropdown,
            filteredValue: filters?.detail,
          },
          {
            title: "Acessante",
            dataIndex: "accessBy",
            key: "accessBy",
            render: (value: string) => value,
            filterDropdown: renderAccessByFilterDropdown,
            filteredValue: filters?.accessBy,
          },
          {
            title: "Unidade",
            dataIndex: "unit",
            key: "unit",
            render: (value: string) => value,
          },
          {
            title: "TUSD",
            dataIndex: "tusd",
            key: "tusd",
            render: (_, record) =>
              record.lowVoltageTaxes?.tusd?.toFixed(2).padStart(2, "0"),
            isHidden: voltageType !== "LOW",
            // filterDropdown: renderTusdFilterDropdown,
            // filteredValue: filters?.tusd ? [true] : [],
          },
          {
            title: "TE",
            dataIndex: "te",
            key: "te",
            render: (_, record) =>
              record.lowVoltageTaxes?.te?.toFixed(2).padStart(2, "0"),
            isHidden: voltageType !== "LOW",
            // filterDropdown: renderTeFilterDropdown,
            // filteredValue: filters?.te ? [true] : [],
          },
          {
            title: "Fio B",
            dataIndex: "te",
            key: "te",
            render: (_, record) =>
              record.lowVoltageTaxes?.wireB?.toFixed(2).padStart(2, "0"),
            isHidden: voltageType !== "LOW",
            // filterDropdown: renderTeFilterDropdown,
            // filteredValue: filters?.te ? [true] : [],
          },
          {
            title: "TUSD (P)",
            dataIndex: "tusd",
            key: "tusd",
            render: (_, record) =>
              record.mediumVoltageTaxes?.tusdP?.toFixed(2).padStart(2, "0"),
            isHidden: voltageType !== "MEDIUM",
          },
          {
            title: "TE (P)",
            dataIndex: "te",
            key: "te",
            render: (_, record) =>
              record.mediumVoltageTaxes?.teP?.toFixed(2).padStart(2, "0"),
            isHidden: voltageType !== "MEDIUM",
          },
          {
            title: "Fio B (P)",
            dataIndex: "te",
            key: "te",
            render: (_, record) =>
              record.mediumVoltageTaxes?.wireBP?.toFixed(2).padStart(2, "0"),
            isHidden: voltageType !== "MEDIUM",
          },
          {
            title: "TUSD (FP)",
            dataIndex: "tusd",
            key: "tusd",
            render: (_, record) =>
              record.mediumVoltageTaxes?.tusdFP?.toFixed(2).padStart(2, "0"),
            isHidden: voltageType !== "MEDIUM",
          },
          {
            title: "TE (FP)",
            dataIndex: "te",
            key: "te",
            render: (_, record) =>
              record.mediumVoltageTaxes?.teFP?.toFixed(2).padStart(2, "0"),
            isHidden: voltageType !== "MEDIUM",
          },
          {
            title: "Fio B (FP)",
            dataIndex: "te",
            key: "te",
            render: (_, record) =>
              record.mediumVoltageTaxes?.wireBFP?.toFixed(2).padStart(2, "0"),
            isHidden: voltageType !== "MEDIUM",
          },
        ] as IAneelTableColumn[]
      ).filter((column) => !column.isHidden),
    [
      filters?.accessBy,
      filters?.consumerClass,
      filters?.detail,
      filters?.modality,
      filters?.consumerSubclass,
      filters?.subgroup,
      // filters?.te,
      // filters?.tusd,
      renderAccessByFilterDropdown,
      renderClassFilterDropdown,
      renderDetailFilterDropdown,
      renderModalityFilterDropdown,
      renderSubclassFilterDropdown,
      renderSubgroupFilterDropdown,
      // renderTeFilterDropdown,
      // renderTusdFilterDropdown,
      voltageType,
    ],
  );

  const EXTRA_FIELDS_COMP = useMemo(() => {
    return (
      <Flex align="end" wrap="wrap" gap={16} style={{ marginBottom: "0.5rem" }}>
        <label
          htmlFor="icms"
          style={{ display: "flex", flexGrow: 1, flexDirection: "column" }}
        >
          ICMS
          <CustomInput.UnitMeasurement
            id="icms"
            precision="2"
            unitMeasurement="%"
            value={filters?.icms}
            onChange={(value) => {
              const parsedValue = Number(value);

              setFilters((current) => ({
                ...current,
                icms: parsedValue || undefined,
              }));
            }}
            min={0}
            placeholder="ICMS..."
          />
        </label>
        <label
          htmlFor="icms"
          style={{ display: "flex", flexGrow: 1, flexDirection: "column" }}
        >
          PIS/COFINS
          <CustomInput.UnitMeasurement
            id="pisCofins"
            precision="2"
            unitMeasurement="%"
            value={filters?.pisCofins}
            onChange={(value) => {
              const parsedValue = Number(value);

              setFilters((current) => ({
                ...current,
                pisCofins: parsedValue || undefined,
              }));
            }}
            placeholder="PIS/COFINS..."
            min={0}
          />
        </label>
        <Button
          type="primary"
          title="Pesquisar"
          onClick={handleSubmit}
          loading={isLoadingAneelList || !FILTERS_ARE_LOADED}
        >
          {!FILTERS_ARE_LOADED ? "Carregando filtros" : "Pesquisar"}
        </Button>
      </Flex>
    );
  }, [
    filters?.icms,
    filters?.pisCofins,
    handleSubmit,
    FILTERS_ARE_LOADED,
    isLoadingAneelList,
  ]);

  useEffect(() => {
    if (FILTERS_ARE_LOADED) {
      setFilters((current) => ({ ...current, ...INITIAL_FILTERS }));
    }
  }, [FILTERS_ARE_LOADED, INITIAL_FILTERS]);

  return (
    <article data-cy="aneel-table">
      {displayExtraFilters && EXTRA_FIELDS_COMP}
      <Table
        loading={isLoadingAneelList}
        columns={COLUMNS}
        dataSource={DATA_SOURCE}
        rowKey="key"
        pagination={createPaginationTableObject({
          size: aneelDataList?.size,
          number: aneelDataList?.number ? aneelDataList?.number + 1 : 1,
          itemType: "Itens",
          totalElements: aneelDataList?.totalElements,
          onChange: (page) => onPageChange(page - 1),
          displayTotalInformation: screens.sm,
        })}
        scroll={{ x: 720 }}
        size={size}
        locale={{
          emptyText: (
            <Result.TableErrorOrEmpty
              errorMessage={aneelDataListError?.message}
              emptyMessage="Nenhum dado encontrado"
            />
          ),
        }}
        rowSelection={
          isSelectable
            ? {
                type: "radio",
                onSelect,
              }
            : undefined
        }
      />
    </article>
  );
}
