import { useMemo, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";

import { MaterialListProps } from "./interface";

import { Modal } from "~components/Modal";
import { createPaginationTableObject } from "~components/Pagination";
import { Result } from "~components/Result";
import {
  useFetchDeleteAlternatingCurrentMaterial,
  useFetchGetPaginatedAlternatingCurrentMaterials,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { AlternatingCurrentMaterial } from "~types/api/AlternatingCurrentMaterial";
import { createUuid } from "~utils/createUuid";
import { parseCurrency, parsePotency } from "~utils/parse";
import { List, Row, Col, Tooltip, Button, Popconfirm } from "antd";

export const ACMaterialList = (props: MaterialListProps) => {
  const { screens, message } = useAppConfig();

  const [currentPage, setCurrentPage] = useState(1);

  const [selectedItemToDelete, setSelectedItemToDelete] =
    useState<AlternatingCurrentMaterial>();

  const { mutate: fetchDeleteAlternatingCurrentMaterial } =
    useFetchDeleteAlternatingCurrentMaterial();

  async function handleDeleteCorrenteAlternada(acMaterialId: number) {
    fetchDeleteAlternatingCurrentMaterial({
      acMaterialId,
    });
  }

  const {
    data: materialList,
    isFetching: isAlternatingCurrentMaterialLoading,
    error: alternatingCurrentMaterialError,
  } = useFetchGetPaginatedAlternatingCurrentMaterials({
    dependencyArray: [currentPage],
    payload: {
      page: currentPage - 1,
      size: 5,
    },
    options: {
      onError: (error) => {
        message.error(error.message);
      },
    },
  });

  const dataSource = useMemo(() => {
    return (
      materialList?.content.map((material) => ({
        id: material.id,
        power: material.power,
        value: material.value,
        active: material.active,
        additionalCostTaxId: material.additionalCostTaxId,
        notApplyDefaultAdditionalCostTax:
          material.notApplyDefaultAdditionalCostTax,
        costAggregators: material.costAggregators,
        key: createUuid(),
      })) ?? []
    );
  }, [materialList]);

  return (
    <>
      <List
        {...props}
        data-cy="ac-material-list"
        className="demo-loadmore-list"
        itemLayout="horizontal"
        dataSource={dataSource}
        loading={isAlternatingCurrentMaterialLoading}
        style={{ width: "100%" }}
        rowKey="key"
        renderItem={(item: (typeof dataSource)[0]) => (
          <List.Item>
            <Row justify="space-between" style={{ width: "100%" }}>
              <Col xs={8} lg={10}>
                <List.Item.Meta
                  title="Potência (kWp)"
                  description={
                    item?.power ? parsePotency(item.power, 2, true) : "-"
                  }
                />
              </Col>
              <Col xs={6} lg={10}>
                <List.Item.Meta
                  title="Valor (R$)"
                  description={item?.value ? parseCurrency(item.value) : "-"}
                />
              </Col>

              <Col
                xs={6}
                lg={4}
                style={{ display: "flex", justifyContent: "end" }}
              >
                <Tooltip title="Editar" placement="bottom">
                  <Button
                    size={screens.lg ? "large" : "small"}
                    onClick={() => setSelectedItemToDelete(item)}
                    type="link"
                  >
                    <AiOutlineEdit style={{ fontSize: "1.25rem" }} />
                  </Button>
                </Tooltip>

                <Popconfirm
                  title="Deseja remover o item?"
                  onConfirm={() =>
                    handleDeleteCorrenteAlternada(item.id as number)
                  }
                >
                  <Tooltip title="Deletar" placement="bottom">
                    <Button type="link">
                      <AiOutlineDelete style={{ fontSize: "1.25rem" }} />
                    </Button>
                  </Tooltip>
                </Popconfirm>
              </Col>
            </Row>
          </List.Item>
        )}
        pagination={createPaginationTableObject({
          itemType: "materiais",
          number: currentPage,
          onChange: (page) => setCurrentPage(page),
          size: materialList?.size,
          totalElements: materialList?.totalElements,
          displayTotalInformation: screens.sm,
        })}
        locale={{
          emptyText: (
            <Result.TableErrorOrEmpty
              errorMessage={alternatingCurrentMaterialError?.message}
              emptyMessage="Nenhum material encontrado."
            />
          ),
        }}
      />
      <Modal.ACMaterialForm
        isOpen={!!selectedItemToDelete}
        item={selectedItemToDelete}
        onClose={() => setSelectedItemToDelete(undefined)}
      />
    </>
  );
};
