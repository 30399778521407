import { useMemo } from "react";

import type { IEdeltecProductTypesSelectProps } from "./interface";

import { SelectSuffixIcon } from "~components/Others";
import { Result } from "~components/Result";
import { useFetchGetEdeltecProductTypes } from "~hooks/api";
import { Select } from "antd";

export function EdeltecProductTypesSelect({
  allowClear = true,
  onChange,
  ...props
}: IEdeltecProductTypesSelectProps) {
  const {
    data: productTypes = [],
    error: productTypesError,
    isFetching: isLoadingProductsTypes,
    refetch: reloadProductsTypes,
  } = useFetchGetEdeltecProductTypes({
    dependencyArray: [],
    options: {
      retry: 1,
    },
  });

  const OPTIONS = useMemo(() => {
    return productTypes.map((product) => ({
      label: product.description,
      value: product.productType,
      record: product,
    }));
  }, [productTypes]);

  return (
    <Select
      {...props}
      loading={isLoadingProductsTypes}
      options={OPTIONS}
      allowClear={allowClear}
      status={productTypesError ? "error" : ""}
      suffixIcon={
        <SelectSuffixIcon
          hasError={!!productTypesError}
          isLoading={isLoadingProductsTypes}
        />
      }
      notFoundContent={
        <Result.SelectErrorOrEmpty
          errorMessage={productTypesError?.message}
          emptyMessage="Nenhum produto da Edeltec foi encontrado"
          loading={isLoadingProductsTypes}
          reloadFn={reloadProductsTypes}
        />
      }
      onChange={(value, option) => {
        if (!onChange) return;
        onChange(value, option);
      }}
    />
  );
}
