import { useMemo } from "react";
import { BiErrorCircle } from "react-icons/bi";

import type { IConcessionaireProfilesByVoltageSelectProps } from "./interface";

import { useFetchGetConcessionaireProfilesByVoltage } from "~hooks/api";
import { Select } from "antd";

export function ConcessionaireProfilesByVoltageSelect({
  concessionaireId = 0,
  voltageType = "LOW",
  modalityTaxType = undefined,
  onChange = () => undefined,
  onLoad = () => undefined,
  ...props
}: IConcessionaireProfilesByVoltageSelectProps) {
  const {
    data: profiles,
    isFetching: isLoadingProfiles,
    error: profilesError,
  } = useFetchGetConcessionaireProfilesByVoltage({
    payload: {
      electricUtilityId: concessionaireId,
      voltageType,
      modalidade: modalityTaxType,
    },
    options: {
      enabled: concessionaireId > 0 && !!voltageType,
      onSuccess: onLoad,
    },
    dependencyArray: [concessionaireId, voltageType, modalityTaxType],
  });

  const DATA_SOURCE = useMemo(() => {
    if (!profiles) return [];

    return profiles.map((profile) => ({
      label: profile.name,
      value: profile.name, // NOT USE ID!!! have some data that no have any id
      record: profile,
    }));
  }, [profiles]);

  return (
    <Select
      {...props}
      loading={isLoadingProfiles}
      options={DATA_SOURCE}
      status={profilesError ? "error" : ""}
      suffixIcon={
        profilesError ? (
          <BiErrorCircle size={18} color="var(--red-200)" />
        ) : undefined
      }
      onChange={(value, option) => {
        if (option instanceof Array || !onChange) return;
        onChange(value, option);
      }}
    />
  );
}
