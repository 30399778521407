import { ENV } from "~configs/Env";

type Libraries = Array<
  | "core"
  | "maps"
  | "places"
  | "geocoding"
  | "routes"
  | "marker"
  | "geometry"
  | "elevation"
  | "streetView"
  | "journeySharing"
  | "drawing"
  | "visualization"
>;

export const GOOGLE_MAPS_LIBRARIES: Libraries = ["places"];

export const GOOGLE_MAPS_API_KEY: string = ENV.GOOGLE_MAPS_API_KEY ?? "";

export const MAP_OPTIONS = {
  disableDefaultUI: true,
  clickableIcons: false,
};

export const INITIAL_LOCATION = {
  lat: -5.1889358,
  lng: -37.2972422,
};
