import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";

import { CardItem } from "./CardItem";
import { IClientDataCardProps } from "./interface";

import { useAppConfig } from "~hooks/useAppConfig";
import { Card, Col, Row, Tooltip, Flex } from "antd";

export const ClientDataCard = ({
  simplified = false,
  maxWidth = "280px",
  isProposalStepLoading,
  proposalStepData,
  proposalStatus,
}: IClientDataCardProps) => {
  const { screens } = useAppConfig();

  return (
    <Card
      title={
        !screens.xs && (
          <Flex justify="space-between">
            Dados do cliente
            {proposalStatus === "WON" && (
              <Tooltip title="Proposta ganha!">
                <AiFillCheckCircle
                  size={20}
                  style={{ color: "var(--green-500)" }}
                />
              </Tooltip>
            )}
            {proposalStatus === "LOST" && (
              <Tooltip title="Proposta perdida!">
                <AiFillCloseCircle
                  size={20}
                  style={{ color: "var(--red-500)" }}
                />
              </Tooltip>
            )}
          </Flex>
        )
      }
      size="small"
      style={{
        width: "100%",
        maxWidth: maxWidth,
        overflow: "hidden",
      }}
    >
      <Row
        gutter={4}
        wrap={simplified ? false : true}
        style={{ overflow: "hidden" }}
      >
        <Col span={simplified ? 12 : 24}>
          <CardItem
            title="Nome"
            value={proposalStepData?.client?.name ?? "-"}
            isLoading={isProposalStepLoading}
          />
        </Col>
        <Col span={simplified ? 12 : 24}>
          <CardItem
            title="Email"
            value={proposalStepData?.client?.email ?? "-"}
            isLoading={isProposalStepLoading}
          />
        </Col>
      </Row>
      <Row
        gutter={4}
        wrap={simplified ? false : true}
        style={{ overflow: "hidden" }}
      >
        <Col span={simplified ? 12 : 24}>
          <CardItem
            title="Documento"
            value={proposalStepData?.client?.identifier ?? "-"}
            isLoading={isProposalStepLoading}
          />
        </Col>
        <Col span={simplified ? 12 : 24}>
          <CardItem
            title="Endereço"
            value={[
              proposalStepData?.client?.address?.street,
              proposalStepData?.client?.address?.city?.name,
              proposalStepData?.client?.address?.province?.name,
            ]
              .filter((value) => !!value)
              .join(", ")}
            isLoading={isProposalStepLoading}
          />
        </Col>
      </Row>
    </Card>
  );
};
