import { useMemo } from "react";
import { FaChevronRight } from "react-icons/fa";

import type { DraggableRowType, ISolarzTableProps } from "./interface";
import { StyledTable, TableExpandButton } from "./styles";

import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Result } from "~components/Result";
import { useAppConfig } from "~hooks/useAppConfig";
import { SolarzAnchor } from "~solarzui/SolarzAnchor";

export function SolarzTable<RecordType = any>({
  columns,
  rows,
  className,
  disableReload,
  emptyMessage,
  errorMessage,
  expandable,
  isLoading,
  pagination,
  reloadFn,
  rootClassName,
  rowClassName,
  rowKey,
  scroll,
  showHeader,
  draggable = false,
  onDragEnd,
  onDragStart,
  touchSensorDelayTime = 150,
}: ISolarzTableProps<RecordType>) {
  const { screens } = useAppConfig();

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: touchSensorDelayTime,
        tolerance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragEnd({ active, over }: DragEndEvent) {
    if (typeof onDragEnd !== "function" || active.id === over?.id) return;

    const rowChanged = rows.find((row) => row[rowKey] === active.id);

    if (!rowChanged) return;

    const activeIndex = rows.findIndex((row) => row[rowKey] === active.id);
    const overIndex = rows.findIndex((row) => row[rowKey] === over?.id);
    const updatedRows = arrayMove(rows, activeIndex, overIndex);

    onDragEnd(rowChanged, updatedRows);
  }

  function handleDragStart(_event: DragStartEvent) {
    if (typeof onDragStart !== "function") return;
    onDragStart();
  }

  function DraggableRow(props: DraggableRowType) {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props["data-row-key"],
    });

    const style: React.CSSProperties = {
      ...props.style,
      transform: CSS.Translate.toString(transform),
      transition,
      cursor: "move",
      ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
    };

    return (
      <tr
        {...props}
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
      />
    );
  }

  const CURRENT_PAGE = useMemo(
    () => pagination?.currentPage ?? 0,
    [pagination?.currentPage],
  );

  const HAVE_NEXT_PAGE = useMemo(() => {
    const totalItemCount = pagination?.totalItemCount ?? 0;
    const currentPage = pagination?.currentPage ?? 0;
    const currentItemCount = pagination?.currentItemCount ?? 0;

    return currentItemCount * currentPage < totalItemCount;
  }, [
    pagination?.totalItemCount,
    pagination?.currentPage,
    pagination?.currentItemCount,
  ]);

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <SortableContext
        items={rows.map((row) => row[rowKey] as UniqueIdentifier)}
        strategy={verticalListSortingStrategy}
      >
        <StyledTable
          components={
            draggable && rows.length > 0
              ? {
                  body: {
                    row: DraggableRow,
                  },
                }
              : undefined
          }
          dataSource={rows}
          columns={columns.filter((column) => !column.hidden)}
          rowKey={rowKey}
          pagination={
            pagination
              ? {
                  showSizeChanger: false,
                  simple: true,
                  showTotal: screens.mobile
                    ? undefined
                    : (total, [start, end]) => {
                        return (
                          <span>{`${start}-${end} de ${total} ${pagination?.itemLabel}.`}</span>
                        );
                      },
                  itemRender(_page, type, originalElement) {
                    if (type === "prev") {
                      return (
                        <SolarzAnchor
                          isDisabled={CURRENT_PAGE <= 1}
                          aria-label="Ir para página anterior"
                        >
                          Anterior
                        </SolarzAnchor>
                      );
                    }

                    if (type === "next") {
                      return (
                        <SolarzAnchor
                          isDisabled={!HAVE_NEXT_PAGE}
                          aria-label="Ir para a próxima página"
                        >
                          Próximo
                        </SolarzAnchor>
                      );
                    }

                    return originalElement;
                  },
                  pageSize: pagination.currentItemCount,
                  current: CURRENT_PAGE,
                  total: pagination.totalItemCount,
                  onChange: pagination.onChange,
                }
              : false
          }
          scroll={{
            x: scroll?.x,
            y: scroll?.y,
          }}
          locale={{
            emptyText: (
              <Result.TableErrorOrEmpty
                disableReload={disableReload}
                errorMessage={errorMessage}
                emptyMessage={emptyMessage}
                loading={isLoading}
                reloadFn={reloadFn}
                errorTitle={undefined}
              />
            ),
          }}
          expandable={
            expandable
              ? {
                  ...expandable,
                  expandIcon: (expand) =>
                    expand.expandable ? (
                      <TableExpandButton
                        onClick={(event) =>
                          expand.onExpand(expand.record, event)
                        }
                        className={expand.expanded ? "expanded" : undefined}
                        title={
                          expand.expanded ? "Esconder linha" : "Expandir linha"
                        }
                      >
                        <FaChevronRight />
                      </TableExpandButton>
                    ) : null,
                }
              : undefined
          }
          loading={isLoading}
          showHeader={showHeader}
          rowClassName={rowClassName}
          rootClassName={rootClassName}
          className={className}
        />
      </SortableContext>
    </DndContext>
  );
}
