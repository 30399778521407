import { useMemo } from "react";

import {
  IInverterTypeSelectOption,
  InverterTypeSelectSelectProps,
} from "./interface";

import { Select } from "antd";

export function InverterTypeSelect({
  value,
  onChange,
  defaultValue,
  ...props
}: InverterTypeSelectSelectProps) {
  const OPTIONS: IInverterTypeSelectOption[] = useMemo(
    () => [
      {
        label: "String",
        value: "STRING",
      },
      {
        label: "Micro",
        value: "MICRO",
      },
      {
        label: "Otimizador",
        value: "OPTIMIZER",
      },
    ],
    [],
  );

  return (
    <Select
      {...props}
      options={OPTIONS}
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
}
