import { Children, CSSProperties, ReactElement, cloneElement } from "react";
import { AiOutlineMenu } from "react-icons/ai";

import { IDraggableTableRowProps } from "./interface";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export function DraggableTableRow({
  children,
  ...props
}: IDraggableTableRowProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });

  const style: CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 999 } : {}),
  };

  return (
    <tr
      data-cy="draggable-table-row"
      {...props}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      {Children.map(children, (child) => {
        if ((child as ReactElement).key === "sort") {
          return cloneElement(child as ReactElement, {
            children: (
              <div ref={setActivatorNodeRef}>
                <AiOutlineMenu
                  style={{ touchAction: "none", cursor: "move" }}
                  {...listeners}
                />
              </div>
            ),
          });
        }
        return child;
      })}
    </tr>
  );
}
