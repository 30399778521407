import { useEffect, useMemo, useState } from "react";

import type {
  OrganizationFormValuesType,
  IOrganizationFormProps,
  OrganizationDefaultFieldsSettingsKeys,
  MappedFieldSettings,
} from "./interface";

import { Alert } from "~components/Alert";
import { Button } from "~components/Button";
import { GoogleMapsProvider, SelectLocation } from "~components/GoogleMaps";
import { Select } from "~components/Select";
import {
  useFetchCreateOrganization,
  useFetchGetOrganizationById,
  useFetchGetOrganizationDefaultFieldSettings,
  useFetchUpdateOrganization,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { useSession } from "~hooks/useSession";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzFormItem } from "~solarzui/SolarzFormItem";
import { SolarzInput } from "~solarzui/SolarzInput";
import { SolarzInputNumber } from "~solarzui/SolarzInputNumber";
import { IOrganizationDTOSchema } from "~types/schemas";
import {
  formatTelephoneWithDdd,
  formatAddressDTO,
  formatCnpj,
  formatStringToBeNumeric,
} from "~utils/format";
import { parseGoogleLocationToAddressForm } from "~utils/parseGoogleLocationToAddressForm";
import { Col, Flex, Form, Row, Spin } from "antd";

function parseOrganizationDTOToOrganizationFormValues(
  organization: IOrganizationDTOSchema,
): Partial<OrganizationFormValuesType> {
  return {
    address: {
      ...organization.address,
      city: {
        name: organization.address?.city?.name,
      },
      province: {
        name: organization.address?.province?.name,
        uf: organization.address?.province?.uf,
      },
      formattedAddress: organization.address
        ? formatAddressDTO(organization.address)
        : undefined,
    },
    cnpj: formatCnpj(organization.cnpj),
    email: organization.email,
    name: organization.name,
    ownerId: organization.owner?.id ?? undefined,
    phone: organization.phone
      ? formatTelephoneWithDdd(organization.phone)
      : undefined,
  };
}

export function OrganizationForm({
  formInstance,
  initialValues,
  organizationId = 0,
  displayPipedriveIntegration = false,
  onCancel,
  onError,
  onSuccess,
}: IOrganizationFormProps) {
  const { screens } = useAppConfig();
  const { user } = useSession();
  const [form] = Form.useForm<OrganizationFormValuesType>(formInstance);
  const [selectedClientOrganizationId, setSelectedClientOrganizationId] =
    useState<number | undefined>();

  const {
    data: fieldsSettings,
    isFetching: isLoadingFieldSettings,
    error: errorFieldSettings,
    refetch: reloadFieldSettings,
  } = useFetchGetOrganizationDefaultFieldSettings({});

  const mappedFieldsSettings = useMemo(() => {
    return (
      fieldsSettings?.reduce(
        (accumulator, settings) => {
          const { organizationDefaultField, ...rest } = settings;
          return {
            ...accumulator,
            [organizationDefaultField?.name || ""]: {
              ...rest,
            },
          };
        },
        {} as Record<
          OrganizationDefaultFieldsSettingsKeys,
          MappedFieldSettings
        >,
      ) ??
      ({} as Record<OrganizationDefaultFieldsSettingsKeys, MappedFieldSettings>)
    );
  }, [fieldsSettings]);

  const { mutate: createOrganization, isLoading: isCreatingOrganization } =
    useFetchCreateOrganization({
      options: {
        onSuccess,
        onError,
      },
    });

  const { mutate: updateOrganization, isLoading: isUpdatingOrganization } =
    useFetchUpdateOrganization({
      options: {
        onSuccess,
        onError,
      },
    });

  const {
    data: initialOrganizationData,
    isFetching: isLoadingInitialOrganizationData,
    error: errorInitialOrganizationData,
    refetch: reloadInitialOrganizationData,
  } = useFetchGetOrganizationById({
    payload: {
      organizationId: organizationId,
    },
    dependencyArray: [organizationId],
    options: {
      enabled: organizationId > 0,
      retry: 1,
    },
  });

  function handleSubmit(formValues: OrganizationFormValuesType) {
    const parsedPhone = formValues.phone
      ? formatStringToBeNumeric(formValues.phone)
      : undefined;

    const parsedCnpj =
      formValues.cnpj?.trim() === "" ? undefined : formValues.cnpj;

    const parsedAddress =
      formValues.address?.city?.name ||
      formValues.address?.province?.name ||
      formValues.address?.street ||
      formValues.address?.zipCode ||
      formValues.address?.number
        ? formValues.address
        : undefined;

    organizationId
      ? updateOrganization({
          ...formValues,
          organizationId,
          phone: parsedPhone,
          cnpj: parsedCnpj,
          email: formValues.email || undefined,
          address: parsedAddress,
        })
      : createOrganization({
          ...formValues,
          phone: parsedPhone,
          pipedriveId: initialValues?.pipedriveId,
          cnpj: parsedCnpj,
          email: formValues.email || undefined,
          address: parsedAddress,
        });
  }

  function handleCancel() {
    const formInitialValues = initialOrganizationData
      ? parseOrganizationDTOToOrganizationFormValues(initialOrganizationData)
      : initialValues ?? {};

    form.setFieldsValue(formInitialValues);

    if (typeof onCancel === "function") {
      return onCancel();
    }
  }

  useEffect(() => {
    if (initialOrganizationData) {
      const initialOrganizationFormData =
        parseOrganizationDTOToOrganizationFormValues(initialOrganizationData);

      form.setFieldsValue(initialOrganizationFormData);
    }
  }, [form, initialOrganizationData]);

  const isLoadingFormData =
    isLoadingInitialOrganizationData || isLoadingFieldSettings;

  const isSubmittingForm = isCreatingOrganization || isUpdatingOrganization;

  const isDisabledForm = !!errorInitialOrganizationData || !!errorFieldSettings;

  return (
    <Form
      data-cy="organization-form"
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmit}
      preserve
      layout="vertical"
    >
      {errorInitialOrganizationData &&
        errorInitialOrganizationData.code !== 404 && (
          <Alert.CardError
            errorMessage={errorInitialOrganizationData?.message}
            title="Falha ao carregar dados iniciais"
            reloadFn={reloadInitialOrganizationData}
            style={{ marginBottom: 16 }}
          />
        )}

      {errorFieldSettings && (
        <Alert.CardError
          errorMessage={errorFieldSettings?.message}
          title="Falha ao carregar campos iniciais"
          reloadFn={reloadFieldSettings}
          style={{ marginBottom: 16 }}
        />
      )}

      {errorFieldSettings && (
        <Alert.CardError
          errorMessage={errorFieldSettings?.message}
          title="Falha ao carregar configurações dos campos"
          reloadFn={reloadFieldSettings}
          style={{ marginBottom: 16 }}
        />
      )}

      <Spin spinning={isLoadingFormData}>
        {user?.pipedriveIntegration && displayPipedriveIntegration && (
          <div
            className={`flex gap-1 -mx-${screens.mobile ? "2" : "3"} gap-${screens.mobile ? "4" : "6"}`}
          >
            <div className={screens.mobile ? "w-full" : "w-7/12"}>
              <Form.Item
                label="Buscar organização"
                extra="Digite ao menos 3 caracteres para pesquisar"
                tooltip="Busque uma organização existente para vinculá-la a organização do Pipedrive."
              >
                <Select.OrganizationToLinkPipedrive
                  placeholder="Digite ao menos 3 caracteres para pesquisar..."
                  onChange={(_, option) => {
                    if (!option) {
                      setSelectedClientOrganizationId(undefined);
                    }

                    if (Array.isArray(option)) return;

                    setSelectedClientOrganizationId(option?.record.id);
                  }}
                />
              </Form.Item>
            </div>
            <div className={screens.mobile ? "w-full" : "w-5/12"}>
              <Form.Item label=" ">
                <Button.BindPipedriveIdToOrganization
                  clientId={selectedClientOrganizationId}
                  pipedriveId={initialValues?.pipedriveId}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </div>
          </div>
        )}
        <Row gutter={screens.mobile ? 16 : 24}>
          <Col span={screens.mobile ? 24 : 12}>
            <SolarzFormItem<OrganizationFormValuesType>
              name="name"
              label="Nome"
              hidden={mappedFieldsSettings["name"]?.hidden}
              rules={[
                {
                  required: mappedFieldsSettings["name"]?.required,
                  message: "Nome é obrigatório",
                },
              ]}
            >
              <SolarzInput
                isDisabled={!mappedFieldsSettings["name"]?.editable}
                scale={screens.mobile ? "medium" : "large"}
                placeholder="Ex.: João da Silva"
              />
            </SolarzFormItem>
          </Col>

          <Col span={screens.mobile ? 24 : 12}>
            <SolarzFormItem<OrganizationFormValuesType>
              name="cnpj"
              label="CNPJ"
              hidden={mappedFieldsSettings["identifier"]?.hidden}
              rules={[
                {
                  required: mappedFieldsSettings["identifier"]?.required,
                  message: "CNPJ é obrigatório",
                },
                {
                  min: 18,
                  message: "CNPJ precisa conter pelo menos 14 caracteres",
                },
              ]}
            >
              <SolarzInput
                inputType="cnpj"
                isDisabled={!mappedFieldsSettings["identifier"]?.editable}
                scale={screens.mobile ? "medium" : "large"}
                placeholder="Ex.: 000.000.000-00"
              />
            </SolarzFormItem>
          </Col>
        </Row>

        <SolarzFormItem<OrganizationFormValuesType>
          name="ownerId"
          label="Responsável"
          hidden={mappedFieldsSettings["responsibleSeller"]?.hidden}
          rules={[
            {
              required: mappedFieldsSettings["responsibleSeller"]?.required,
              message: "Responsável é obrigatório",
            },
          ]}
        >
          <Select.User
            isDisabled={!mappedFieldsSettings["responsibleSeller"]?.editable}
            scale={screens.mobile ? "medium" : "large"}
            placeholder="Selecione..."
          />
        </SolarzFormItem>

        <SolarzFormItem label="Buscar endereço">
          <GoogleMapsProvider
            onChangeLocationCallback={(location) => {
              const parsedValue = parseGoogleLocationToAddressForm(location);
              form.setFieldValue("address", parsedValue);
            }}
          >
            <SelectLocation
              style={{
                width: "100%",
                height: 48,
              }}
              placeholder="Procurar endereço com o Google"
            />
          </GoogleMapsProvider>
        </SolarzFormItem>

        <Row gutter={screens.mobile ? 16 : 24}>
          <Col span={screens.mobile ? 16 : 18}>
            <SolarzFormItem<OrganizationFormValuesType>
              name={["address", "street"]}
              label="Rua"
              hidden={mappedFieldsSettings["street"]?.hidden}
              rules={[
                {
                  required: mappedFieldsSettings["street"]?.required,
                  message: "Rua é obrigatório",
                },
                {
                  max: 255,
                  message: "Rua não pode ter mais de 255 caracteres",
                },
              ]}
            >
              <SolarzInput
                isDisabled={!mappedFieldsSettings["street"]?.editable}
                scale={screens.mobile ? "medium" : "large"}
                placeholder="Ex.: Rua José da Silva"
              />
            </SolarzFormItem>
          </Col>

          <Col span={screens.mobile ? 8 : 6}>
            <SolarzFormItem<OrganizationFormValuesType>
              name={["address", "number"]}
              label="Número"
              hidden={mappedFieldsSettings["number"]?.hidden}
              rules={[
                {
                  required: mappedFieldsSettings["number"]?.required,
                  message: "Número é obrigatório",
                },
              ]}
            >
              <SolarzInputNumber
                isDisabled={!mappedFieldsSettings["number"]?.editable}
                scale={screens.mobile ? "medium" : "large"}
                placeholder="Ex.: 777"
                precision={0}
                min={0}
                onChange={(value) => {
                  const parsedValue = value?.toString() || "";
                  form.setFieldValue(["address", "number"], parsedValue);
                }}
              />
            </SolarzFormItem>
          </Col>
        </Row>

        <Row gutter={screens.mobile ? 16 : 24}>
          <Col span={screens.mobile ? 16 : 12}>
            <SolarzFormItem<OrganizationFormValuesType>
              name={["address", "city", "name"]}
              label="Cidade"
              hidden={mappedFieldsSettings["city"]?.hidden}
              rules={[
                {
                  required: mappedFieldsSettings["city"]?.required,
                  message: "Cidade é obrigatório",
                },
              ]}
            >
              <SolarzInput
                isDisabled={!mappedFieldsSettings["city"]?.editable}
                scale={screens.mobile ? "medium" : "large"}
                placeholder="Ex.: Mossoró"
              />
            </SolarzFormItem>
          </Col>
          <Col span={screens.mobile ? 8 : 6}>
            <SolarzFormItem<OrganizationFormValuesType>
              name={["address", "province", "name"]}
              label="Estado"
              hidden={mappedFieldsSettings["province"]?.hidden}
              rules={[
                {
                  required: mappedFieldsSettings["province"]?.required,
                  message: "Estado é obrigatório",
                },
              ]}
            >
              <Select.BrazilianState
                disabled={!mappedFieldsSettings["province"]?.editable}
                style={{ height: screens.mobile ? 40 : 48 }}
                placeholder="Ex.: RN"
                onChange={(value) => {
                  form.setFieldValue(["address", "province", "uf"], value);
                }}
              />
            </SolarzFormItem>
          </Col>
          <Col span={screens.mobile ? 24 : 6}>
            <SolarzFormItem<OrganizationFormValuesType>
              name={["address", "zipCode"]}
              label="CEP"
              hidden={mappedFieldsSettings["zipCode"]?.hidden}
              rules={[
                {
                  required: mappedFieldsSettings["zipCode"]?.required,
                  message: "CEP é obrigatório",
                },
              ]}
            >
              <SolarzInput
                inputType="cep"
                isDisabled={!mappedFieldsSettings["zipCode"]?.editable}
                scale={screens.mobile ? "medium" : "large"}
                placeholder="Ex.: 00000-000"
              />
            </SolarzFormItem>
          </Col>
        </Row>

        <Row gutter={screens.mobile ? 16 : 24}>
          <Col span={screens.mobile ? 24 : 12}>
            <SolarzFormItem<OrganizationFormValuesType>
              name="phone"
              label="Telefone"
              hidden={mappedFieldsSettings["phone"]?.hidden}
              rules={[
                {
                  required: mappedFieldsSettings["phone"]?.required,
                  message: "Telefone é obrigatório",
                },
              ]}
              style={{ width: "100%" }}
            >
              <SolarzInput
                isDisabled={!mappedFieldsSettings["phone"]?.editable}
                inputType="telephone"
                scale={screens.mobile ? "medium" : "large"}
                placeholder="Ex.: (00) 00000-0000"
              />
            </SolarzFormItem>
          </Col>
          <Col span={screens.mobile ? 24 : 12}>
            <SolarzFormItem<OrganizationFormValuesType>
              name="email"
              label="Email"
              hidden={mappedFieldsSettings["email"]?.hidden}
              rules={[
                {
                  required: mappedFieldsSettings["email"]?.required,
                  message: "Email é obrigatório",
                },
              ]}
              style={{ width: "100%" }}
            >
              <SolarzInput
                isDisabled={!mappedFieldsSettings["email"]?.editable}
                scale={screens.mobile ? "medium" : "large"}
                placeholder="Ex.: joaodasilva77@mail.com"
              />
            </SolarzFormItem>
          </Col>
        </Row>
      </Spin>

      <Flex
        style={{ marginTop: 12 }}
        align="center"
        gap={16}
        justify="flex-end"
      >
        <SolarzButton variant="secondary" scale="tiny" onClick={handleCancel}>
          Cancelar
        </SolarzButton>
        <SolarzButton
          scale="tiny"
          htmlType="submit"
          isDisabled={isDisabledForm}
          isLoading={isSubmittingForm}
        >
          {organizationId ? "Atualizar organização" : "Criar organização"}
        </SolarzButton>
      </Flex>
    </Form>
  );
}
