import { useMemo } from "react";

import type {
  GuarantorOccupationEosFinancingRecordType,
  IGuarantorOccupationEosFinancingSelectProps,
  GuarantorOccupationEosFinancingOptionType,
} from "./interface";

import { SolarzSelect } from "~solarzui/SolarzSelect";
import { guarantorNatureOccupationEnum } from "~types/enum";
import { translateOccupationEosFinancingEnum } from "~utils/translate";

export function GuarantorOccupationEosFinancingSelect({
  ...props
}: IGuarantorOccupationEosFinancingSelectProps) {
  const OPTIONS = useMemo(() => {
    return guarantorNatureOccupationEnum.map((value) => ({
      value,
      record: {
        value,
      },
      label: translateOccupationEosFinancingEnum(value),
    })) as GuarantorOccupationEosFinancingOptionType;
  }, []);

  return (
    <SolarzSelect<GuarantorOccupationEosFinancingRecordType, "value">
      {...props}
      options={OPTIONS}
    />
  );
}
