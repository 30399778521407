import { PipedriveUsersListModalProps } from "./interface";

import { List } from "~components/List";
import { Modal } from "antd";

export function PipedriveUsersListModal({
  isOpen,
  onClose,
  title = "Importar usuário do Pipedrive",
  width = 512,
}: PipedriveUsersListModalProps) {
  return (
    <Modal
      data-cy="pipedrive-users-list-modal"
      title={title}
      open={isOpen}
      onCancel={onClose}
      footer={null}
      width={width}
      destroyOnClose
      styles={{
        body: {
          maxHeight: "75vh",
          overflowY: "auto",
        },
      }}
    >
      <List.PipedriveUsers style={{ paddingRight: "0.5rem" }} />
    </Modal>
  );
}
