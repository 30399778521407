import { AiOutlineMinusCircle, AiOutlinePlus } from "react-icons/ai";

import {
  IKitItemMultipleData,
  IKitItemMultipleFormProps,
  IKitItemMultipleFormValues,
} from "./interface";
import styles from "./styles";

import { AutoComplete } from "~components/AutoComplete";
import { CustomInput } from "~components/CustomInput";
import { Select } from "~components/Select";
import { Button, Col, Divider, Flex, Form, Row } from "antd";

export function KitItemMultipleForm({
  initialValues = {
    kitItems: [
      {
        category: undefined,
        name: undefined,
        quantity: 1,
        unityAmount: 0,
        key: undefined,
        otherParams: undefined,
      },
    ],
  },
  isLoading = false,
  onCancel = () => undefined,
  onSubmit = () => undefined,
  maxHeight = 512,
}: IKitItemMultipleFormProps) {
  const [form] = Form.useForm<IKitItemMultipleFormValues>();

  function handleSubmitForm(formValues: IKitItemMultipleFormValues) {
    const kitItems = formValues.kitItems ?? [];

    const parsedKitItems = kitItems.map((item) => {
      return {
        category: item.category,
        name: item.name,
        quantity: item.quantity,
        unityAmount: item.unityAmount,
        key: item.key,
        otherParams: item.otherParams,
      } as IKitItemMultipleData;
    });

    onSubmit(parsedKitItems);
  }

  return (
    <Form
      data-cy="kit-item-multiple-form"
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmitForm}
      layout="vertical"
      autoComplete="off"
      name="kitItemMultipleForm"
    >
      <style jsx>{styles}</style>
      <Form.List name="kitItems">
        {(fields, { add, remove }) => (
          <>
            <Flex
              vertical
              wrap="nowrap"
              style={{
                overflowX: "hidden",
                maxHeight: maxHeight,
                overflowY: "auto",
              }}
            >
              {fields.map(({ key, name, ...restField }) => (
                <Row
                  key={key}
                  align="middle"
                  wrap={true}
                  justify="center"
                  gutter={[16, 0]}
                  style={{
                    width: "100%",
                  }}
                >
                  <Col xs={24} md={8}>
                    <Form.Item
                      {...restField}
                      name={[name, "name"]}
                      label="Nome do item"
                      rules={[{ required: true, whitespace: true }]}
                    >
                      <AutoComplete.Product
                        placeholder="Digite ao menos 3 letras para auto-completar"
                        onChange={(_value, option) => {
                          if (!option) return;

                          const fields = form.getFieldsValue();
                          const { kitItems } = fields;

                          form.setFieldsValue({
                            kitItems: {
                              ...kitItems,
                              [key]: {
                                ...kitItems[key],
                                unityAmount:
                                  (option.record?.priceBaseCents ?? 0) / 100,
                                category: option.record?.category || undefined,
                                otherParams: option.record?.otherParams,
                              },
                            },
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={7}>
                    <Form.Item
                      {...restField}
                      name={[name, "category"]}
                      label="Categoria"
                      rules={[{ required: true }]}
                    >
                      <Select.KitItemCategory
                        placeholder="Ex.: Módulo"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={12} md={4}>
                    <Form.Item
                      {...restField}
                      name={[name, "unityAmount"]}
                      label="Valor unitário"
                      rules={[{ required: true, message: "Obrigatório" }]}
                    >
                      <CustomInput.Money min={0} style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>

                  <Col xs={12} md={4}>
                    <Form.Item
                      {...restField}
                      name={[name, "quantity"]}
                      label="Quantidade"
                      rules={[{ required: true, message: "Obrigatório" }]}
                    >
                      <CustomInput.Number
                        min={0}
                        precision={0}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>

                  <Col md={1}>
                    <Flex justify="center" align="center">
                      <Button
                        type="text"
                        size="small"
                        shape="circle"
                        style={{ lineHeight: 0 }}
                        onClick={() => remove(name)}
                        disabled={fields.length <= 1}
                      >
                        <AiOutlineMinusCircle size={16} />
                      </Button>
                    </Flex>
                  </Col>
                </Row>
              ))}
            </Flex>

            <Divider style={{ margin: "0 0 0.5rem 0" }} />

            <Button
              block
              type="dashed"
              className="addRowButton"
              onClick={() =>
                add({
                  category: undefined,
                  name: undefined,
                  quantity: 1,
                  unityAmount: 0,
                })
              }
            >
              <Flex align="center" justify="center" gap={8}>
                <AiOutlinePlus size={16} />
                Novo item
              </Flex>
            </Button>
          </>
        )}
      </Form.List>

      <Flex gap={12} justify="end" style={{ marginTop: "1rem" }}>
        <Form.Item style={{ margin: 0 }}>
          <Button onClick={onCancel}>Cancelar</Button>
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            data-cy="save-button"
          >
            Salvar
          </Button>
        </Form.Item>
      </Flex>
    </Form>
  );
}
