import { useCallback, useEffect, useState } from "react";
import {
  AiOutlineArrowRight,
  AiOutlineCheck,
  AiOutlineEdit,
  AiOutlineGoogle,
  AiOutlinePlus,
} from "react-icons/ai";

import type {
  CheckFieldConfigResult,
  IProposalDataStepFormInitialValues,
  IProposalDataStepFormProps,
  IProposalDataStepFormValues,
  ISettingsField,
  PersonOrOrganizationData,
  PersonOrOrganizationFieldSettings,
  ProposalDataStepCustomField,
  SelectedClientStateType,
} from "./interface";

import { CustomInput } from "~components/CustomInput";
import { SelectLocationInputModal } from "~components/GoogleMaps";
import { Modal } from "~components/Modal";
import { ProposalCustomFieldsForm } from "~components/Others";
import { Select } from "~components/Select";
import { PROPOSAL_DATA_HAS_CHANGED } from "~configs/ProposalConfig";
import { Company, People } from "~estrela-ui/icons";
import {
  useFetchCreateStepOne,
  useFetchGetAllProposalDefaultFieldSettings,
  useFetchGetProposalExpirationRules,
  useFetchUpdateStepOne,
  useFetchGetOrganizationDefaultFieldSettings,
  useFetchGetPersonInformationByPipedriveId,
  useFetchGetOrganizationInformationByPipedriveId,
  useFetchGetPersonById,
  useFetchGetOrganizationById,
} from "~hooks/api";
import { useFetchGetPersonDefaultFieldsSettings } from "~hooks/api/config/useFetchGetPersonDefaultFieldsSettings";
import { useAppConfig } from "~hooks/useAppConfig";
import { useProposal } from "~hooks/useProposal";
import { useSession } from "~hooks/useSession";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzFormItem } from "~solarzui/SolarzFormItem";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import type { ClientTypeEnum, ProjectTypeEnum } from "~types/enum";
import type {
  IOrganizationDefaultFieldSettingsDTOSchema,
  IPersonDefaultFieldSettingsDTOSchema,
  IStepOneFormSchema,
} from "~types/schemas";
import { formatCpfCnpj, formatTelephoneWithDdd } from "~utils/format";
import { getBase64 } from "~utils/getBase64";
import { getFieldReadonlyStyle } from "~utils/getFieldReadonlyStyle";
import { parseWithoutEspecialCharacters } from "~utils/parse";
import {
  Form,
  Layout,
  Row,
  Col,
  Typography,
  Divider,
  Button,
  notification,
  Space,
  Flex,
  DatePicker,
  Alert,
  Button as AntdButton,
  Checkbox,
  Tooltip,
  Spin,
  Card,
} from "antd";
import type { RcFile } from "antd/lib/upload/interface";
import dayjs, { Dayjs } from "dayjs";

function getIconBySelectedClientType(clientTypeWatcher?: ClientTypeEnum) {
  switch (clientTypeWatcher) {
    case "ORGANIZATION":
      return <Company />;
    case "PERSON":
      return <People />;
    default:
      return undefined;
  }
}

const getAddress = (initialValues?: IProposalDataStepFormInitialValues) => {
  if (!initialValues) {
    return undefined;
  }
  const client =
    initialValues?.clientType === "PERSON"
      ? initialValues?.client
      : initialValues?.organization;
  return {
    city: client?.address?.city
      ? {
          name: client?.address?.city.name,
        }
      : undefined,
    district: client?.address?.district,
    zipCode: client?.address?.zipCode,
    street: client?.address?.street,
    number: client?.address?.number,
    province: client?.address?.province
      ? {
          name: client?.address?.province.name,
          uf: client?.address?.province.uf,
        }
      : undefined,
  };
};

function adjustPipedrivePhoneString(phoneString?: string) {
  if (!phoneString) return "";

  const phoneStringNumericOnly = phoneString.replace(/\D/g, "") || "";

  const phoneStringNumericOnlyLength = phoneStringNumericOnly.length;

  return phoneStringNumericOnlyLength > 11
    ? phoneStringNumericOnly.slice(phoneStringNumericOnlyLength - 11)
    : phoneStringNumericOnly;
}

export function ProposalDataStepForm({
  onSuccess = () => undefined,
  onCancel = () => null,
  pipedriveId,
  proposalId,
  initialValues,
  canBeExpress,
  customFieldsInitialValues,
  hideCancelButton = false,
  lastStep,
  onHandleStepChange = () => null,
}: IProposalDataStepFormProps) {
  const { screens, currentTheme } = useAppConfig();
  const { user, userHavePermission, userIsAdmin } = useSession();
  const { steps } = useProposal();

  const [isGoogleSearchOpen, setIsGoogleSearchOpen] = useState(false);

  const shouldRenderPipedrivePipeline =
    user?.pipedriveIntegration && pipedriveId === 0;

  const [api, contextHolder] = notification.useNotification();

  const [form] = Form.useForm<IProposalDataStepFormValues>();

  const [settingsFields, setSettingsFields] = useState<ISettingsField[]>([]);

  const [emailSettings, setEmailSettings] = useState<CheckFieldConfigResult>();

  const [phoneSettings, setPhoneSettings] = useState<CheckFieldConfigResult>();

  const [selectedClient, setSelectedClient] = useState<
    SelectedClientStateType | undefined
  >();

  const [hasLoadedInitialSelectedClient, setHasLoadedInitialSelectedClient] =
    useState(false);

  // load initial data of selected client state
  useEffect(() => {
    if (!hasLoadedInitialSelectedClient && !selectedClient) {
      setSelectedClient({
        address: getAddress(initialValues),
        dealCustomId: initialValues?.deal?.customId,
        personId:
          initialValues?.clientType === "PERSON"
            ? initialValues.clientId ?? undefined
            : undefined,
        organizationId:
          initialValues?.clientType === "ORGANIZATION"
            ? initialValues.clientId ?? undefined
            : undefined,
        organizationCustomId: initialValues?.deal?.organization?.customId,
        personCustomId: initialValues?.deal?.person?.customId,
        pipedrivePersonId: initialValues?.client?.pipedriveId,
        pipedriveOrganizationId: initialValues?.organization?.pipedriveId,
        owner: initialValues?.client?.owner?.id,
      });
      setHasLoadedInitialSelectedClient(true);
    }
  }, [initialValues, selectedClient, hasLoadedInitialSelectedClient]);

  const [useClientAddress, setUseClientAddress] = useState(false);

  const [cpfCnpjSettings, setCpfCnpjSettings] =
    useState<CheckFieldConfigResult>();

  const [emailResponsibleSettings, setEmailResponsibleSettings] =
    useState<CheckFieldConfigResult>();

  const [cepSettings, setCepSettings] = useState<CheckFieldConfigResult>();

  const [streetSettings, setStreetSettings] =
    useState<CheckFieldConfigResult>();

  const [phoneResponsibleSettings, setPhoneResponsibleSettings] =
    useState<CheckFieldConfigResult>();

  const [projectTitleSettings, setProjectTitleSettings] =
    useState<CheckFieldConfigResult>();

  const [
    isOrganizationOrPersonTabsFormModalOpen,
    setIsOrganizationOrPersonTabsFormModalOpen,
  ] = useState(false);

  const [forceToCreateCustomer, setForceToCreateCustomer] = useState(false);

  function handleOpenOrganizationOrPersonTabsFormModal() {
    setIsOrganizationOrPersonTabsFormModalOpen(true);
  }

  function handleCloseOrganizationOrPersonTabsFormModal() {
    setIsOrganizationOrPersonTabsFormModalOpen(false);
  }

  // WATCHERS
  const projectTypeWatcher = Form.useWatch("projectType", form);
  const pricingTypeIdWatcher = Form.useWatch("pricingTypeId", form);
  const clientTypeWatcher = Form.useWatch("clientType", form);
  const potency = Form.useWatch("potency", form);

  const cannotEditProposalExpiration =
    !userIsAdmin && userHavePermission("PROPOSAL_EXPIRATION_READONLY");

  useFetchGetAllProposalDefaultFieldSettings({
    payload: {
      pricingTypeId: pricingTypeIdWatcher,
    },
    options: {
      onSuccess: (data) => {
        const fields = data ?? [];

        const settingsFields: ISettingsField[] = fields.map((item) => ({
          fieldName: item.formField?.nameField ?? "",
          displayName: item?.formField?.displayName ?? "",
          isRequired: !!item.required,
          isEditable: !!item.editable,
          isHidden: !!item.hidden,
        }));

        setSettingsFields(settingsFields);
      },
      enabled: !!pricingTypeIdWatcher,
    },
    dependencyArray: [pricingTypeIdWatcher],
  });

  const expirationDate = form.getFieldValue("expirationDate") as
    | Dayjs
    | undefined;

  const [needGetNewExpirationDate, setNeedGetNewExpirationDate] =
    useState(false);

  const { refetch: reloadExpirationDate, isFetching: isLoadingExpirationDate } =
    useFetchGetProposalExpirationRules({
      options: {
        onSuccess(data) {
          if (expirationDate) {
            form.setFieldValue(
              "expirationDate",
              dayjs(data.proposalExpirationDate),
            );
          }

          setNeedGetNewExpirationDate(false);
        },
        enabled: !initialValues?.id,
      },
      payload:
        potency && projectTypeWatcher === "EXPRESS" ? { potency } : undefined,
      dependencyArray: [potency, projectTypeWatcher],
    });

  const { mutate: fetchCreateStepOne, isLoading: isLoadingCreateStepOne } =
    useFetchCreateStepOne({
      options: {
        onSuccess,
      },
    });

  const { mutate: fetchUpdateStepOne, isLoading: isLoadingUpdateStepOne } =
    useFetchUpdateStepOne({
      options: {
        onSuccess,
      },
    });

  const { data: defaultFieldsSettingsPerson } =
    useFetchGetPersonDefaultFieldsSettings({
      dependencyArray: [selectedClient],
      options: {
        enabled: !!user,
      },
    });

  const { data: defaultFieldsSettingsOrganization } =
    useFetchGetOrganizationDefaultFieldSettings({
      dependencyArray: [selectedClient],
      options: {
        enabled: !!user,
      },
    });

  const requiredFieldsPerson =
    defaultFieldsSettingsPerson?.filter((field) => field.required) || [];

  const requiredFieldsOrganization =
    defaultFieldsSettingsOrganization?.filter((field) => field.required) || [];

  const checkRequiredFieldsPersonOrganization = (
    mappedData: PersonOrOrganizationData,
    requiredFields: PersonOrOrganizationFieldSettings[],
    isPerson: boolean,
  ): boolean => {
    return requiredFields.some((field) => {
      const fieldName = isPerson
        ? (field as IPersonDefaultFieldSettingsDTOSchema).personDefaultField
            ?.name
        : (field as IOrganizationDefaultFieldSettingsDTOSchema)
            .organizationDefaultField?.name;

      if (fieldName === "responsibleSeller") {
        return false;
      }

      return (
        fieldName && !mappedData[fieldName as keyof PersonOrOrganizationData]
      );
    });
  };

  // CHECK IF SEARCH USER IS CUSTOMER REGISTERED IN SOLARZ DATABASE;
  const { isFetching: isLoadingPersonInformationByPipedriveId } =
    useFetchGetPersonInformationByPipedriveId({
      dependencyArray: [selectedClient?.pipedrivePersonId],
      payload: { pipedriveId: selectedClient?.pipedrivePersonId ?? 0 },
      options: {
        enabled: Number(selectedClient?.pipedrivePersonId) > 0,
        onSuccess: (data) => {
          const formattedPhone =
            formatTelephoneWithDdd(adjustPipedrivePhoneString(data?.phone)) ||
            undefined;

          const formattedIdentifier = data?.identifier
            ? formatCpfCnpj(data?.identifier)
            : undefined;

          const updatedClient = {
            ...data,
            identifier: formattedIdentifier,
            phone: formattedPhone,
            pipedrivePersonId: data?.pipedriveId,
            address: {
              city: {
                name: data?.address?.city?.name,
              },
              country: {
                name: data?.address?.country?.name ?? "",
              },
              district: data?.address?.district,
              number: data?.address?.number,
              street: data?.address?.street,
              zipCode: data?.address?.zipCode,
              province: {
                name: data?.address?.province?.name,
              },
            },
            owner: data.owner?.id ?? user?.id,
          };
          const setFormValues = () => {
            form.setFieldsValue({
              name: data?.name,
              cpfCnpj: formattedIdentifier,
              email: data?.email,
              phone: formattedPhone,
              clientId: data?.pipedriveId,
              clientType: "PERSON",
            });
          };

          const handleMissingFields = () => {
            const mappedData = {
              name: data.name,
              identifier: data.identifier,
              email: data.email,
              phone: data?.phone
                ? formatTelephoneWithDdd(data?.phone)
                : undefined,
              city: data?.address?.city?.name,
              street: data?.address?.street,
              province: data?.address?.province?.name,
              zipCode: data?.address?.zipCode,
              number: data?.address?.number,
            };

            const missingFieldsPerson = checkRequiredFieldsPersonOrganization(
              mappedData,
              requiredFieldsPerson,
              true,
            );

            if (!data.id && missingFieldsPerson) {
              setIsOrganizationOrPersonTabsFormModalOpen(true);
              setForceToCreateCustomer(true);
            } else {
              setForceToCreateCustomer(false);
            }
          };

          if (data.id) {
            setSelectedClient((current) => ({
              ...current,
              personId: data.id ?? undefined,
              owner: data.owner?.id,
            }));
          } else {
            setFormValues();
            setSelectedClient(updatedClient);
          }

          handleMissingFields();
        },
      },
    });

  const { isFetching: isLoadingOrganizationInformationByPipedriveId } =
    useFetchGetOrganizationInformationByPipedriveId({
      dependencyArray: [selectedClient?.pipedriveOrganizationId],
      payload: { pipedriveId: selectedClient?.pipedriveOrganizationId ?? 0 },
      options: {
        enabled: Number(selectedClient?.pipedriveOrganizationId) > 0,
        onSuccess: (data) => {
          const formattedPhone =
            formatTelephoneWithDdd(adjustPipedrivePhoneString(data?.phone)) ||
            undefined;

          const formattedIdentifier = data?.cnpj
            ? formatCpfCnpj(data.cnpj)
            : undefined;

          const updatedClient = {
            ...data,
            identifier: formattedIdentifier,
            phone: formattedPhone,
            pipedriveOrganizationId: data?.pipedriveId,
            address: {
              city: { name: data?.address?.city?.name },
              country: { name: data?.address?.country?.name ?? "" },
              district: data?.address?.district,
              number: data?.address?.number,
              street: data?.address?.street,
              zipCode: data?.address?.zipCode,
              province: { name: data?.address?.province?.name },
            },
            owner: data.owner?.id ?? user?.id,
          };

          const setDataInFormValues = () => {
            form.setFieldsValue({
              name: data?.name,
              cpfCnpj: formattedIdentifier,
              email: data?.email,
              phone: formattedPhone,
              clientId: data?.pipedriveId,
              clientType: "ORGANIZATION",
            });
          };

          const handleMissingFields = () => {
            const mappedData = {
              name: data.name,
              identifier: data.cnpj,
              email: data.email,
              phone: formattedPhone,
              city: data?.address?.city?.name,
              street: data?.address?.street,
              province: data?.address?.province?.name,
              zipCode: data?.address?.zipCode,
              number: data?.address?.number,
            };

            const missingFieldsOrganization =
              checkRequiredFieldsPersonOrganization(
                mappedData,
                requiredFieldsOrganization,
                false,
              );

            if (!data.id && missingFieldsOrganization) {
              setIsOrganizationOrPersonTabsFormModalOpen(true);
              setForceToCreateCustomer(true);
            } else {
              setForceToCreateCustomer(false);
            }
          };

          if (data.id) {
            setSelectedClient((current) => ({
              ...current,
              organizationId: data.id ?? undefined,
            }));
          } else {
            setDataInFormValues();
            setSelectedClient(updatedClient);
          }

          handleMissingFields();
        },
      },
    });
  // END CHECK IF SEARCH CUSTOMER IS ALREADY REGISTERED IN SOLARZ DATABASE;

  useFetchGetPersonById({
    payload: {
      personId: selectedClient?.personId ?? 0,
    },
    dependencyArray: [selectedClient?.personId],
    options: {
      enabled: Number(selectedClient?.personId) > 0,
      onSuccess: (data) => {
        const formattedPhone = data?.phone
          ? formatTelephoneWithDdd(data?.phone)
          : undefined;

        const formattedIdentifier = data?.identifier
          ? formatCpfCnpj(data?.identifier)
          : undefined;

        form.setFieldsValue({
          name: data?.name,
          cpfCnpj: formattedIdentifier,
          email: data?.email,
          phone: formattedPhone,
          clientId: data?.pipedriveId,
          clientType: "PERSON",
        });

        setSelectedClient((currentData) => {
          return {
            ...data,
            dealCustomId: currentData?.dealCustomId,
            pipedriveDealId: currentData?.pipedriveDealId,
            identifier: formattedIdentifier,
            personId: data?.id,
            personCustomId: data?.customId,
            pipedrivePersonId: data?.pipedriveId,
            address: {
              city: {
                name: data?.address?.city?.name,
              },
              country: {
                name: data?.address?.country?.name ?? "",
              },
              district: data?.address?.district,
              number: data?.address?.number,
              street: data?.address?.street,
              zipCode: data?.address?.zipCode,
              province: {
                name: data?.address?.province?.name,
              },
            },
            owner: data.owner?.id ?? user?.id,
          };
        });
      },
      retry: 1,
    },
  });

  useFetchGetOrganizationById({
    payload: {
      organizationId: selectedClient?.organizationId ?? 0,
    },
    dependencyArray: [selectedClient?.organizationId],
    options: {
      enabled: Number(selectedClient?.organizationId) > 0,
      onSuccess: (data) => {
        const formattedPhone = data?.phone
          ? formatTelephoneWithDdd(data?.phone)
          : undefined;

        const formattedIdentifier = data?.cnpj
          ? formatCpfCnpj(data?.cnpj)
          : undefined;

        form.setFieldsValue({
          name: data?.name,
          cpfCnpj: formattedIdentifier,
          email: data?.email,
          phone: formattedPhone,
          clientId: data?.pipedriveId,
          clientType: "ORGANIZATION",
        });

        setSelectedClient(() => {
          return {
            ...data,
            identifier: formattedIdentifier,
            organizationId: data?.id,
            organizationCustomId: data?.customId,
            pipedriveOrganizationId: data?.pipedriveId,
            address: {
              city: {
                name: data?.address?.city?.name,
              },
              country: {
                name: data?.address?.country?.name ?? "",
              },
              district: data?.address?.district,
              number: data?.address?.number,
              street: data?.address?.street,
              zipCode: data?.address?.zipCode,
              province: {
                name: data?.address?.province?.name,
              },
            },
            owner: data.owner?.id ?? user?.id,
          };
        });
      },
      retry: 1,
    },
  });

  const checkIfFieldIsRequired = useCallback(
    (field: string, displayName?: string): CheckFieldConfigResult => {
      const currentField = settingsFields.find(
        (item) =>
          (!displayName || displayName === item.displayName) &&
          item.fieldName === field,
      );

      return {
        isRequired: currentField?.isRequired ?? false,
        isHidden: currentField?.isHidden ?? false,
        isEditable: currentField?.isEditable ?? true,
      };
    },
    [settingsFields],
  );

  const openNotification = (callback: { (): Promise<void>; (): void }) => {
    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <Button type="link" size="small" onClick={() => api.destroy()}>
          Cancelar
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={() => {
            onHandleStepChange(4);
            callback();
          }}
        >
          Confirmar
        </Button>
      </Space>
    );
    api.open({
      message: "Atenção",
      description:
        'Ao alterar a proposta o PDF gerado anteriormente será perdido. Para gerar outro,deve-se finalizar a proposta no passo "Proposta"',
      btn,
      key,
      duration: 0,
      onClose: () => api.destroy(),
    });
  };

  function getTooltipText(type: ProjectTypeEnum | undefined) {
    switch (type) {
      case "DEFAULT":
        return "Essa é uma proposta solar, não se pode selecionar outra opção";
      case "EXPRESS":
        return "Essa é uma proposta expressa, não se pode selecionar outra opção";
      case "DIVERSE":
        return "Essa é uma proposta diversa, não se pode selecionar outra opção";
      default:
        return "";
    }
  }

  const processCustomFields = async (
    customFields?: ProposalDataStepCustomField[] | undefined,
    section?: string,
  ): Promise<ProposalDataStepCustomField[]> => {
    if (!customFields) {
      return [];
    }

    return Promise.all(
      customFields.map(async (item) => {
        let parsedValue = item.value;

        if (item.type === "IMAGE" && item.file === null) {
          parsedValue = null;
        } else if (item.type === "IMAGE" && item.file) {
          parsedValue = (
            (await getBase64(item.file as RcFile)) as string
          ).replace(/^data:image\/\w+;base64,/, "");
        } else if (
          item.type === "DATE" &&
          item.value !== null &&
          item.value !== undefined
        ) {
          parsedValue = dayjs(item.value.toString()).format("DD/MM/YYYY");
        }

        return {
          ...item,
          value: parsedValue,
          section: section,
        };
      }),
    );
  };

  const onFinish = useCallback(
    async (formData: IProposalDataStepFormValues, isUpdate = false) => {
      const processedProjectDataCustomFields = await processCustomFields(
        formData?.customFieldsProjectData,
        "PROJECT_DATA",
      );

      const processedClientDataCustomFields = await processCustomFields(
        formData?.customFieldsClientData,
        "CLIENT_DATA",
      );

      const processedAddressDataCustomFields = await processCustomFields(
        formData?.customFieldsAddressData,
        "ADDRESS_DATA",
      );

      const processedInitialDataCustomFields = await processCustomFields(
        formData?.customFieldsInitialData,
        "INITIAL_DATA",
      );

      const allProcessedCustomFields = [
        ...processedProjectDataCustomFields,
        ...processedClientDataCustomFields,
        ...processedAddressDataCustomFields,
        ...processedInitialDataCustomFields,
      ];

      localStorage.removeItem(PROPOSAL_DATA_HAS_CHANGED);
      dispatchEvent(new Event("storage"));

      // address: {
      //   street: formData.street,
      //   number: formData.number,
      //   district: formData.district,
      //   zipCode: formData.zipCode,
      //   city: {
      //     name: formData.city,
      //   },
      //   province: {
      //     name: formData.state,
      //     uf: "",
      //   },
      // },

      const payload: IStepOneFormSchema = {
        title: formData.projectTitle || undefined,
        responsible: formData.owner || undefined,
        emailResponsible: formData.emailResponsible || undefined,
        phoneResponsible:
          parseWithoutEspecialCharacters(formData?.phoneResponsible) ||
          undefined,
        pipedriveDealId: pipedriveId ?? undefined,
        client: {
          name: formData.name,
          organizationCustomId: selectedClient?.organizationCustomId,
          personCustomId: selectedClient?.personCustomId,
          email: formData.email || undefined,
          identifier: formData.cpfCnpj || undefined,
          phone: parseWithoutEspecialCharacters(formData?.phone) || undefined,
        },
        value:
          projectTypeWatcher === "EXPRESS" || projectTypeWatcher === "DIVERSE"
            ? formData.price
            : undefined,
        fullPower:
          projectTypeWatcher === "EXPRESS" ? formData.potency : undefined,
        idBaseAddress: formData?.idBaseAddress ?? 0,
        projectType: projectTypeWatcher,
        installationAddress: {
          street: formData.street,
          number: formData.number,
          district: formData.district,
          zipCode: formData.zipCode,
          city:
            formData.city || formData.state
              ? {
                  name: formData.city,
                }
              : undefined,
          province: {
            name: formData.state,
            uf: "",
          },
          note: formData.note,
        },
        expirationDate:
          projectTypeWatcher === "DEFAULT"
            ? undefined
            : dayjs(formData.expirationDate).toISOString(),
        initialDataCustomFieldValues: allProcessedCustomFields.map((item) => ({
          customFieldId: item.customFieldId ?? item?.customFieldId,
          value: item.value?.toString(),
        })),
        pipedrivePipelineId: formData?.pipedrivePipelineId,
        pricingTypeId: formData?.pricingTypeId,
        dealCustomId:
          selectedClient?.dealCustomId ?? initialValues?.deal?.customId,
        person:
          formData.clientType === "PERSON"
            ? {
                name: formData.name || selectedClient?.name || undefined,
                email: formData.email || selectedClient?.email || undefined,
                identifier: formData.cpfCnpj || undefined,
                phone:
                  parseWithoutEspecialCharacters(formData?.phone) ||
                  selectedClient?.phone ||
                  undefined,
                pipedriveId: selectedClient?.pipedrivePersonId || undefined,
                address:
                  formData.street ||
                  formData.number ||
                  formData.district ||
                  formData.zipCode ||
                  formData.city
                    ? {
                        street: formData.street || undefined,
                        number: formData.number || undefined,
                        district: formData.district || undefined,
                        zipCode: formData.zipCode || undefined,
                        city: formData.city
                          ? {
                              name: formData.city,
                            }
                          : undefined,
                        province: formData.state
                          ? {
                              name: formData.state,
                            }
                          : undefined,
                      }
                    : undefined,
                owner: selectedClient?.owner
                  ? { id: selectedClient.owner }
                  : undefined,
                active: selectedClient?.active || undefined,
                id: formData.clientId || selectedClient?.personId || undefined,
                customId:
                  formData.personCustomId ||
                  selectedClient?.personCustomId ||
                  undefined,
              }
            : undefined,
        organization:
          formData.clientType === "ORGANIZATION"
            ? {
                name: formData.name || selectedClient?.name || undefined,
                email: formData.email || selectedClient?.email || undefined,
                cnpj:
                  formData.cpfCnpj || selectedClient?.identifier || undefined,
                phone:
                  parseWithoutEspecialCharacters(formData?.phone) ||
                  selectedClient?.phone ||
                  undefined,
                pipedriveId:
                  selectedClient?.pipedriveOrganizationId || undefined,
                address:
                  selectedClient?.address ||
                  formData.street ||
                  formData.number ||
                  formData.district ||
                  formData.zipCode ||
                  formData.city
                    ? {
                        ...selectedClient?.address,
                        street:
                          formData.street ||
                          selectedClient?.address?.street ||
                          undefined,
                        number:
                          formData.number ||
                          selectedClient?.address?.number ||
                          undefined,
                        district:
                          formData.district ||
                          selectedClient?.address?.district ||
                          undefined,
                        zipCode:
                          formData.zipCode ||
                          selectedClient?.address?.zipCode ||
                          undefined,
                        city:
                          formData.city || selectedClient?.address?.city?.name
                            ? {
                                name:
                                  formData.city ||
                                  selectedClient?.address?.city?.name ||
                                  undefined,
                              }
                            : undefined,
                        province:
                          formData.state ||
                          selectedClient?.address?.province?.name
                            ? {
                                name:
                                  formData.state ||
                                  selectedClient?.address?.province?.name ||
                                  undefined,
                              }
                            : undefined,
                        country: selectedClient?.address?.country?.name
                          ? {
                              name:
                                selectedClient?.address?.country?.name ??
                                (undefined || undefined),
                            }
                          : undefined,
                        identifier: formData.cpfCnpj || undefined,
                      }
                    : undefined,
                owner: selectedClient?.owner
                  ? { id: selectedClient.owner }
                  : undefined,
                customId: selectedClient?.organizationCustomId || undefined,
                id: selectedClient?.organizationId || undefined,
              }
            : undefined,
      };

      initialValues && initialValues.id
        ? fetchUpdateStepOne({
            ...payload,
            step: isUpdate ? 4 : payload.step,
            customId: initialValues.id,
          })
        : fetchCreateStepOne(payload);
    },
    [
      fetchCreateStepOne,
      fetchUpdateStepOne,
      initialValues,
      pipedriveId,
      projectTypeWatcher,
      selectedClient,
    ],
  );

  useEffect(() => {
    if (form.getFieldValue("owner")) return;
    form.setFieldValue("owner", user?.name);
    form.setFieldValue("email", user?.email);
  }, [user?.name, user?.email, form]);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({
        ...initialValues,
        expirationDate: dayjs(initialValues.expirationDate),
      });
    }
  }, [form, initialValues]);

  useEffect(() => {
    form.setFieldValue("unity", user?.businessUnitId);
  }, [user?.businessUnitId, form]);

  useEffect(() => {
    if (initialValues?.deal?.name) {
      form.setFieldValue("projectTitle", initialValues?.deal?.name);
    }
  }, [form, initialValues?.deal?.name]);

  useEffect(() => {
    const emailSettings = checkIfFieldIsRequired("email", "Email do cliente");
    setEmailSettings(emailSettings);

    const phoneSettings = checkIfFieldIsRequired(
      "phone",
      "Telefone do cliente",
    );
    setPhoneSettings(phoneSettings);

    const cpfCnpjSettings = checkIfFieldIsRequired("identifier", "CPF/CNPJ");
    setCpfCnpjSettings(cpfCnpjSettings);

    const emailResponsibleSettings = checkIfFieldIsRequired(
      "emailResponsible",
      "Email do consultor",
    );
    setEmailResponsibleSettings(emailResponsibleSettings);

    const cepSettings = checkIfFieldIsRequired("zipCode", "Cep");
    setCepSettings(cepSettings);

    const phoneResponsibleSettings = checkIfFieldIsRequired(
      "phoneResponsible",
      "Telefone do consultor",
    );
    setPhoneResponsibleSettings(phoneResponsibleSettings);

    const projectTitleSettings = checkIfFieldIsRequired(
      "title",
      "Nome do projeto",
    );
    setProjectTitleSettings(projectTitleSettings);

    const streetSettings = checkIfFieldIsRequired("street", "Rua");

    setStreetSettings(streetSettings);
  }, [checkIfFieldIsRequired, settingsFields]);

  const isNextStepButtonLoading =
    isLoadingCreateStepOne || isLoadingUpdateStepOne;

  const canUserAutoCompleteFieldsOfAddress =
    !forceToCreateCustomer &&
    (!!selectedClient?.personId ||
      !!selectedClient?.organizationId ||
      !!selectedClient?.pipedrivePersonId ||
      !!selectedClient?.pipedriveOrganizationId);

  return (
    <>
      <Form
        data-cy="proposal-data-step-form"
        form={form}
        layout="vertical"
        onFinish={(formData) =>
          lastStep === steps.length
            ? openNotification(() => onFinish(formData, true))
            : onFinish(formData)
        }
        onChange={() => {
          localStorage.setItem(PROPOSAL_DATA_HAS_CHANGED, "true");
          dispatchEvent(new Event("storage"));
        }}
        style={{ height: "100%" }}
      >
        <Layout
          style={{
            height: "100%",
            background: "transparent",
            padding: screens.xl ? "0rem" : "1rem 0rem",
            width: "100%",
            overflowY: "hidden",
          }}
        >
          <Layout.Content
            style={{
              width: "100%",
              height: "100%",
              overflowY: "auto",
              overflowX: "hidden",
              padding: screens.xl ? "2rem" : "0 1rem 1rem 1rem",
            }}
          >
            {contextHolder}

            <Row>
              <Col span={screens.md ? 11 : 24}>
                <Flex
                  style={{
                    width: "100%",
                    flexDirection: screens.lg ? "row" : "column",
                  }}
                  justify="space-between"
                  wrap="nowrap"
                >
                  <Typography.Title
                    level={4}
                    style={{ marginBottom: "1rem", width: "100%" }}
                  >
                    Parâmetros do projeto
                  </Typography.Title>
                </Flex>

                <Row gutter={16}>
                  <Col xs={24} sm={24} lg={12}>
                    <Form.Item
                      name="projectType"
                      label="Tipo de proposta"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      tooltip={
                        !!proposalId && !canBeExpress
                          ? getTooltipText(projectTypeWatcher)
                          : ""
                      }
                    >
                      <Select.ProjectType
                        disabled={!!proposalId && !canBeExpress}
                        // value={projectType}
                        // onChange={(value) => setProjectType(value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} lg={12}>
                    <Form.Item
                      name="pricingTypeId"
                      label="Precificação"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select.PricingTypeOfProposal
                        scale="tiny"
                        projectType={projectTypeWatcher}
                        onChange={(value) => {
                          const pricing = Number(value);
                          const parsedValue = isNaN(pricing)
                            ? undefined
                            : pricing;
                          form.setFieldValue("pricingTypeId", parsedValue);
                        }}
                        onLoad={(value) => {
                          if (!value) return;

                          // WHEN EXISTS ONLY A UNIQUE OPTION...
                          if (value.length === 1) {
                            form.setFieldValue("pricingTypeId", value[0]?.id);
                            return;
                          }

                          const pricingExists = value.find(
                            (pricing) =>
                              pricing.id === initialValues?.pricingTypeId,
                          );

                          if (pricingExists) {
                            form.setFieldValue(
                              "pricingTypeId",
                              pricingExists?.id,
                            );
                            return;
                          }

                          const pricingDefault = value.find(
                            (pricing) => pricing.default === true,
                          );

                          if (!pricingDefault?.id) return;

                          form.setFieldValue(
                            "pricingTypeId",
                            pricingDefault?.id,
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  name="unity"
                  label="Unidade de negócio"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, insira a unidade",
                    },
                  ]}
                >
                  <Select.BusinessUnit
                    style={{ width: "100%" }}
                    onChange={(value) => form.setFieldValue("unity", value)}
                    updateCurrentUserBusinessUnitOnSelect
                    placeholder="Selecionar unidade..."
                  />
                </Form.Item>
                <Divider style={{ marginTop: "2.5rem" }} />
                <Flex
                  style={{
                    width: "100%",
                    flexDirection: screens.lg ? "row" : "column",
                  }}
                  justify="space-between"
                  wrap="nowrap"
                >
                  <Typography.Title
                    level={4}
                    style={{ marginBottom: "1rem", width: "100%" }}
                  >
                    Dados do projeto
                  </Typography.Title>
                </Flex>
                {/* Project Form */}
                <Form.Item
                  label="Nome do Projeto"
                  name="projectTitle"
                  rules={[{ required: projectTitleSettings?.isRequired }]}
                  hidden={projectTitleSettings?.isHidden}
                >
                  <CustomInput.Text
                    data-cy="input-project-name"
                    placeholder="Projeto econômico"
                    readOnly={!projectTitleSettings?.isEditable}
                    style={getFieldReadonlyStyle(
                      !!projectTitleSettings?.isEditable,
                      !!(currentTheme === "dark"),
                    )}
                  />
                </Form.Item>
                <Form.Item
                  label="Nome do consultor"
                  name="owner"
                  tooltip="Nome do vendedor ou colaborador responsável por esse projeto."
                >
                  <CustomInput.Text
                    data-cy="input-consultant-name"
                    placeholder="Gerente João da Silva"
                  />
                </Form.Item>
                <Form.Item
                  label="Email do consultor"
                  name="emailResponsible"
                  rules={[{ required: emailResponsibleSettings?.isRequired }]}
                  hidden={emailResponsibleSettings?.isHidden}
                >
                  <CustomInput.Text
                    placeholder="Ex.: seuemail@mail.com"
                    type="email"
                    data-cy="input-consultant-email"
                    readOnly={!emailResponsibleSettings?.isEditable}
                    style={getFieldReadonlyStyle(
                      !!emailResponsibleSettings?.isEditable,
                      !!(currentTheme === "dark"),
                    )}
                  />
                </Form.Item>
                <Form.Item
                  label="Telefone do consultor"
                  name="phoneResponsible"
                  rules={[
                    {
                      min: 14,
                      max: 15,
                      message: "Numero tem que ter entre 10 e 14 caracteres",
                    },
                    {
                      required: phoneResponsibleSettings?.isRequired,
                    },
                  ]}
                  hidden={phoneResponsibleSettings?.isHidden}
                >
                  <CustomInput.TelephoneWithDdd
                    placeholder="Ex.: (00) 00000-0000"
                    type="tel"
                    data-cy="input-consultant-phone"
                    readOnly={!phoneResponsibleSettings?.isEditable}
                    style={getFieldReadonlyStyle(
                      !!phoneResponsibleSettings?.isEditable,
                      !!(currentTheme === "dark"),
                    )}
                  />
                </Form.Item>
                <Form.Item
                  label="Endereço base"
                  name="idBaseAddress"
                  rules={[{ required: true }]}
                >
                  <Select.BaseAddress
                    data-cy="select-base-address"
                    placeholder="Selecionar endereço..."
                  />
                </Form.Item>

                {(projectTypeWatcher === "DIVERSE" ||
                  projectTypeWatcher === "EXPRESS") && (
                  <Form.Item
                    label={<span>Validade da proposta </span>}
                    name="expirationDate"
                    extra={
                      <Flex vertical>
                        {expirationDate?.isBefore(Date.now(), "day") && (
                          <Alert
                            message="A data atual é uma data passada"
                            type="info"
                            showIcon
                            style={{
                              height: 32,
                              marginTop: 4,
                            }}
                          />
                        )}
                        {needGetNewExpirationDate === true && (
                          <Alert
                            message="A potência mudou, deseja revalidar a data de expiração?"
                            type="info"
                            showIcon
                            style={{
                              height: 32,
                              marginTop: 4,
                            }}
                            action={
                              <AntdButton
                                type="link"
                                onClick={() => reloadExpirationDate()}
                                loading={isLoadingExpirationDate}
                                size="small"
                              >
                                Revalidar
                              </AntdButton>
                            }
                          />
                        )}
                      </Flex>
                    }
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      format="DD/MM/YYYY"
                      allowClear={false}
                      disabled={cannotEditProposalExpiration}
                      disabledDate={(currentDate) =>
                        currentDate.isBefore(Date.now(), "day")
                      }
                    />
                  </Form.Item>
                )}

                {shouldRenderPipedrivePipeline && (
                  <Form.Item
                    name="pipedrivePipelineId"
                    label="Funil do pipedrive"
                    tooltip="Selecione o funil do pipedrive onde essa proposta irá ficar inicialmente."
                    rules={[{ required: true }]}
                  >
                    <Select.PipedrivePipeline />
                  </Form.Item>
                )}
                <ProposalCustomFieldsForm
                  initialValues={customFieldsInitialValues}
                  proposalSection="PROJECT_DATA"
                  pricingTypeId={pricingTypeIdWatcher}
                  form={form}
                  instanceKey="ProjectData"
                />

                <Divider style={{ marginTop: "2.5rem" }} />
                <Flex
                  align="center"
                  justify="space-between"
                  style={{ width: "100%", marginBottom: "1rem" }}
                >
                  <Typography.Title level={4} style={{ marginBottom: 0 }}>
                    Dados do cliente
                  </Typography.Title>
                </Flex>

                {/* organization and persons */}
                <Divider orientation="left" style={{ margin: "0 0 12px 0" }} />

                {user?.pipedriveIntegration ? (
                  <>
                    <Form.Item
                      label="Buscar cliente"
                      extra="Digite ao menos 3 caracteres para pesquisar"
                      tooltip="Este campo é usado somente para lhe ajudar a completar os dados do cliente"
                    >
                      <Select.PipedriveClientSearch
                        placeholder="Digite ao menos 3 caracteres para pesquisar..."
                        onChange={(_, option) => {
                          if (!option) {
                            form.setFieldsValue({
                              name: undefined,
                              cpfCnpj: undefined,
                              email: undefined,
                              phone: undefined,
                              clientId: undefined,
                              clientType: undefined,
                            });

                            setSelectedClient(undefined);
                            setUseClientAddress(false);
                            setForceToCreateCustomer(false);
                            return;
                          }

                          if (Array.isArray(option)) return;

                          const formattedPhone = option?.record?.phone
                            ? formatTelephoneWithDdd(
                                adjustPipedrivePhoneString(
                                  option?.record?.phone,
                                ),
                              )
                            : undefined;

                          const formattedIdentifier = option?.record?.identifier
                            ? formatCpfCnpj(option.record.identifier)
                            : undefined;

                          form.setFieldsValue({
                            name: option?.record?.name,
                            cpfCnpj: formattedIdentifier,
                            email: option?.record?.email,
                            phone: formattedPhone,
                            clientId: option?.record?.pipedriveId,
                            clientType: option?.record.clientType,
                          });

                          const personData = {
                            pipedrivePersonId:
                              option?.record?.pipedriveId ?? undefined,
                          };

                          const organizationData = {
                            pipedriveOrganizationId:
                              option?.record?.pipedriveId ?? undefined,
                          };

                          const clientData = {
                            PERSON: personData,
                            ORGANIZATION: organizationData,
                          };

                          setSelectedClient({
                            ...clientData[option?.record.clientType],
                            pipedriveStringAddress: option?.record.address,
                            email: option?.record.email,
                            name: option?.record.name,
                            identifier: formattedIdentifier,
                            phone: formattedPhone,
                          });
                        }}
                        onCreatePersonSuccess={(person) => {
                          const formattedPhone = person.phone
                            ? formatTelephoneWithDdd(person.phone)
                            : undefined;

                          const formattedIdentifier = person.identifier
                            ? formatCpfCnpj(person.identifier)
                            : undefined;

                          form.setFieldsValue({
                            name: person.name,
                            cpfCnpj: formattedIdentifier,
                            email: person.email,
                            phone: formattedPhone,
                            clientId: person.id,
                            clientType: "PERSON",
                          });

                          setSelectedClient({
                            pipedrivePersonId: person.pipedriveId,
                            address: person.address,
                          });
                        }}
                        onCreateOrganizationSuccess={(organization) => {
                          const formattedPhone = organization.phone
                            ? formatTelephoneWithDdd(organization.phone)
                            : undefined;

                          const formattedIdentifier = organization.cnpj
                            ? formatCpfCnpj(organization.cnpj)
                            : undefined;

                          form.setFieldsValue({
                            name: organization.name,
                            cpfCnpj: formattedIdentifier,
                            email: organization.email,
                            phone: formattedPhone,
                            clientId: organization.id,
                            clientType: "ORGANIZATION",
                          });

                          setSelectedClient({
                            pipedrivePersonId: organization.pipedriveId,
                            address: organization.address,
                          });
                        }}
                        createOrganizationInitialValue={
                          initialValues?.clientType === "ORGANIZATION"
                            ? {
                                name: initialValues.organization?.name,
                                pipedriveId:
                                  initialValues.organization?.pipedriveId,
                                email: initialValues?.organization?.email,
                                address: {
                                  city: {
                                    name: initialValues?.organization?.address
                                      ?.city?.name,
                                  },
                                  country: {
                                    name:
                                      initialValues?.organization?.address
                                        ?.country?.name ?? "",
                                  },
                                  district:
                                    initialValues?.organization?.address
                                      ?.district,
                                  number:
                                    initialValues?.organization?.address
                                      ?.number,
                                  street:
                                    initialValues?.organization?.address
                                      ?.street,
                                  zipCode:
                                    initialValues?.organization?.address
                                      ?.zipCode,
                                  province: {
                                    name: initialValues?.organization?.address
                                      ?.province?.name,
                                  },
                                },
                                cnpj: initialValues?.organization?.cnpj,
                                ownerId: user?.id,
                                phone: initialValues?.organization?.phone,
                              }
                            : { ownerId: user?.id }
                        }
                        createPersonInitialValue={
                          initialValues?.clientType === "PERSON"
                            ? {
                                name: initialValues.client?.name,
                                pipedriveId: initialValues.client?.pipedriveId,
                                email: initialValues?.client?.email,
                                address: {
                                  city: {
                                    name: initialValues?.client?.address?.city
                                      ?.name,
                                  },
                                  country: {
                                    name:
                                      initialValues?.client?.address?.country
                                        ?.name ?? "",
                                  },
                                  district:
                                    initialValues?.client?.address?.district,
                                  number:
                                    initialValues?.client?.address?.number,
                                  street:
                                    initialValues?.client?.address?.street,
                                  zipCode:
                                    initialValues?.client?.address?.zipCode,
                                  province: {
                                    name: initialValues?.client?.address
                                      ?.province?.name,
                                  },
                                },
                                responsibleSellerId: user?.id,
                                phone: initialValues?.client?.phone,
                                identifier: initialValues?.client?.identifier,
                              }
                            : { responsibleSellerId: user?.id }
                        }
                        isDisabled={!!selectedClient?.pipedriveDealId}
                      />
                    </Form.Item>
                  </>
                ) : (
                  <>
                    <Form.Item
                      label="Buscar negócio"
                      extra="Digite ao menos 3 caracteres para pesquisar"
                      tooltip="Este campo é usado somente para lhe ajudar a completar os dados do cliente"
                    >
                      <Select.DealClientSearch
                        initialRecord={initialValues?.deal}
                        placeholder="Digite ao menos 3 caracteres para pesquisar..."
                        isDisabled={
                          !!initialValues?.deal?.id ||
                          (!selectedClient?.dealCustomId &&
                            (!!selectedClient?.personId ||
                              !!selectedClient?.organizationId))
                        }
                        defaultValue={initialValues?.deal?.id}
                        onChange={(_, option) => {
                          if (!option) {
                            form.setFieldsValue({
                              name: undefined,
                              cpfCnpj: undefined,
                              email: undefined,
                              phone: undefined,
                              clientId: undefined,
                              clientType: undefined,
                            });

                            setSelectedClient(undefined);
                            return setUseClientAddress(false);
                          }

                          if (Array.isArray(option)) return;

                          const person = option?.record.person;

                          if (person) {
                            form.setFieldsValue({
                              name: person.name,
                              cpfCnpj: formatCpfCnpj(person.identifier),
                              email: person.email,
                              phone: formatTelephoneWithDdd(person.phone),
                              clientType: "PERSON",
                              clientId: person.id,
                            });

                            return setSelectedClient({
                              address: person.address,
                              personId: person.id,
                              personCustomId: person.customId,
                              dealCustomId: option?.record.customId,
                            });
                          }

                          const organization = option?.record?.organization;

                          if (!organization) return;

                          form.setFieldsValue({
                            name: organization?.name,
                            cpfCnpj: organization?.identifier
                              ? formatCpfCnpj(organization.identifier)
                              : undefined,
                            email: organization?.email,
                            phone: organization?.phone
                              ? formatTelephoneWithDdd(organization.phone)
                              : undefined,
                            clientType: "ORGANIZATION",
                            clientId: organization.id,
                          });

                          setSelectedClient({
                            organizationId: organization.id,
                            organizationCustomId: organization.customId,
                            dealCustomId: option.record.customId,
                            address: organization.address,
                          });
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Buscar cliente"
                      extra="Digite ao menos 3 caracteres para pesquisar"
                      tooltip="Este campo é usado somente para lhe ajudar a completar os dados do cliente"
                    >
                      <Select.ClientSearch
                        defaultValue={
                          initialValues?.clientId ||
                          initialValues?.client?.id ||
                          initialValues?.organization?.id ||
                          undefined
                        }
                        initialRecord={
                          initialValues?.clientId
                            ? {
                                ...initialValues.client,
                                id: initialValues.clientId,
                                customId: initialValues.client?.customId || 0,
                                email: initialValues.email || "",
                                identifier: initialValues.cpfCnpj || "",
                                name: initialValues.name || "",
                                phone: initialValues.phone || "",
                                clientType: "PERSON",
                              }
                            : initialValues?.organization?.id
                              ? {
                                  ...initialValues.organization,
                                  id: initialValues.organization.id,
                                  customId:
                                    initialValues.organization.customId || 0,
                                  email: initialValues.organization.email,
                                  identifier: initialValues.organization.cnpj,
                                  name: initialValues.organization.name,
                                  phone: initialValues.organization.phone,
                                  address: initialValues.organization.address,
                                  clientType: "ORGANIZATION",
                                }
                              : undefined
                        }
                        placeholder="Digite ao menos 3 caracteres para pesquisar..."
                        onChange={(_, option) => {
                          if (!option) {
                            form.setFieldsValue({
                              name: undefined,
                              cpfCnpj: undefined,
                              email: undefined,
                              phone: undefined,
                              clientId: undefined,
                              clientType: undefined,
                            });

                            setSelectedClient(undefined);
                            return setUseClientAddress(false);
                          }

                          if (Array.isArray(option)) return;

                          const formattedPhone = option?.record?.phone
                            ? formatTelephoneWithDdd(option.record.phone)
                            : undefined;

                          const formattedIdentifier = option?.record?.identifier
                            ? formatCpfCnpj(option.record.identifier)
                            : undefined;

                          form.setFieldsValue({
                            name: option?.record?.name,
                            cpfCnpj: formattedIdentifier,
                            email: option?.record?.email,
                            phone: formattedPhone,
                            clientId: option?.record?.id,
                            clientType: option?.record.clientType,
                          });

                          setSelectedClient({
                            personCustomId:
                              option?.record.clientType === "PERSON"
                                ? option?.record?.customId
                                : undefined,
                            organizationCustomId:
                              option?.record.clientType === "ORGANIZATION"
                                ? option?.record?.customId
                                : undefined,
                            personId:
                              option?.record.clientType === "PERSON"
                                ? option?.record?.id
                                : undefined,
                            organizationId:
                              option?.record.clientType === "ORGANIZATION"
                                ? option?.record?.id
                                : undefined,
                            address: option.record.address,
                          });
                        }}
                        onCreatePersonSuccess={(person) => {
                          const formattedPhone = person.phone
                            ? formatTelephoneWithDdd(person.phone)
                            : undefined;

                          const formattedIdentifier = person.identifier
                            ? formatCpfCnpj(person.identifier)
                            : undefined;

                          form.setFieldsValue({
                            name: person.name,
                            cpfCnpj: formattedIdentifier,
                            email: person.email,
                            phone: formattedPhone,
                            clientId: person.id,
                            clientType: "PERSON",
                          });

                          setSelectedClient({
                            personId: person.id,
                            address: person.address,
                          });
                        }}
                        onCreateOrganizationSuccess={(organization) => {
                          const formattedPhone = organization.phone
                            ? formatTelephoneWithDdd(organization.phone)
                            : undefined;

                          const formattedIdentifier = organization.cnpj
                            ? formatCpfCnpj(organization.cnpj)
                            : undefined;

                          form.setFieldsValue({
                            name: organization.name,
                            cpfCnpj: formattedIdentifier,
                            email: organization.email,
                            phone: formattedPhone,
                            clientId: organization.id,
                            clientType: "ORGANIZATION",
                          });

                          setSelectedClient({
                            organizationId: organization.id,
                            address: organization.address,
                          });
                        }}
                        createPersonInitialValues={{
                          responsibleSellerId: user?.id,
                        }}
                        createOrganizationInitialValues={{
                          ownerId: user?.id,
                        }}
                        isDisabled={
                          !!initialValues?.deal?.id ||
                          !!selectedClient?.dealCustomId
                        }
                      />
                    </Form.Item>
                  </>
                )}

                <Divider style={{ margin: "0 0 12px 0" }} />

                {projectTypeWatcher === "EXPRESS" && (
                  <Row gutter={16}>
                    <Col md={24} lg={12}>
                      <Form.Item
                        label="Potência"
                        name="potency"
                        rules={[{ required: true }]}
                      >
                        <CustomInput.UnitMeasurement
                          precision="2"
                          unitMeasurement="KWp"
                          placeholder="52"
                          min={0}
                          style={{ width: "100%" }}
                          onChange={() => {
                            if (
                              potency &&
                              projectTypeWatcher === "EXPRESS" &&
                              initialValues?.id
                            ) {
                              setNeedGetNewExpirationDate(true);
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={24} lg={12}>
                      <Form.Item
                        label="Preço"
                        name="price"
                        rules={[{ required: true }]}
                      >
                        <CustomInput.Money min={0} />
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                <Card
                  title="Dados do cliente"
                  extra={
                    <Flex>
                      {!forceToCreateCustomer && (
                        <Tooltip
                          title={
                            !selectedClient?.personId &&
                            !selectedClient?.organizationId
                              ? undefined
                              : "Atualizar dados"
                          }
                        >
                          <div>
                            <SolarzButton
                              icon={<AiOutlineEdit />}
                              variant="link"
                              isDisabled={
                                !selectedClient?.personId &&
                                !selectedClient?.organizationId &&
                                !selectedClient?.pipedriveOrganizationId &&
                                !selectedClient?.pipedrivePersonId
                              }
                              onClick={
                                handleOpenOrganizationOrPersonTabsFormModal
                              }
                            />
                          </div>
                        </Tooltip>
                      )}

                      {forceToCreateCustomer && (
                        <Tooltip title="O cliente não possui todos os dados obrigatórios, para prosseguir é necessário cadastra-lo">
                          <div>
                            <SolarzButton
                              icon={<AiOutlinePlus />}
                              variant="link"
                              onClick={
                                handleOpenOrganizationOrPersonTabsFormModal
                              }
                            />
                          </div>
                        </Tooltip>
                      )}
                    </Flex>
                  }
                >
                  <Spin
                    spinning={
                      isLoadingOrganizationInformationByPipedriveId ||
                      isLoadingPersonInformationByPipedriveId
                    }
                  >
                    <Flex gap={12} vertical>
                      {/* Start Hidden Inputs */}
                      <Form.Item name="clientId" preserve hidden>
                        <CustomInput.Number
                          readOnly
                          style={{ cursor: "not-allowed" }}
                        />
                      </Form.Item>
                      <Form.Item
                        name="pipedriveId"
                        preserve
                        hidden
                        initialValue={initialValues?.pipedriveId}
                      >
                        <CustomInput.Number
                          readOnly
                          style={{ cursor: "not-allowed" }}
                        />
                      </Form.Item>
                      {/* End Hidden Inputs */}
                      <Row gutter={12}>
                        <Col span={screens.desktop ? 12 : 24}>
                          <Flex vertical>
                            <SolarzFormItem
                              label={
                                <SolarzTypography
                                  fontWeight="bold"
                                  variant="title"
                                  hierarchy="paragraph2"
                                >
                                  Nome
                                </SolarzTypography>
                              }
                              style={{
                                padding: 0,
                              }}
                              name="name"
                              rules={[
                                {
                                  required: true,
                                  message: "Nome do cliente é obrigatório",
                                },
                              ]}
                            >
                              <CustomInput.Text
                                placeholder="-"
                                prefix={getIconBySelectedClientType(
                                  clientTypeWatcher,
                                )}
                                readOnly
                                variant="borderless"
                                style={{
                                  padding: 0,
                                }}
                              />
                            </SolarzFormItem>
                          </Flex>
                        </Col>
                        <Col span={screens.desktop ? 12 : 24}>
                          <Flex vertical>
                            <SolarzFormItem
                              label={
                                <SolarzTypography
                                  fontWeight="bold"
                                  variant="title"
                                  hierarchy="paragraph2"
                                >
                                  CPF/CNPJ
                                </SolarzTypography>
                              }
                              style={{
                                padding: 0,
                              }}
                              name="cpfCnpj"
                              rules={[
                                {
                                  min: 14,
                                  max: 18,
                                  message:
                                    "CPF/CNPJ tem que ter entre 14 e 18 caracteres",
                                },
                                {
                                  required: cpfCnpjSettings?.isRequired,
                                  message: "Campo CPF/CNPJ é obrigatório",
                                },
                              ]}
                            >
                              <CustomInput.CpfCnpj
                                placeholder="-"
                                readOnly
                                variant="borderless"
                                style={
                                  selectedClient
                                    ? { padding: 0 }
                                    : {
                                        ...getFieldReadonlyStyle(
                                          !!cpfCnpjSettings?.isEditable,
                                          !!(currentTheme === "dark"),
                                        ),
                                        padding: 0,
                                      }
                                }
                              />
                            </SolarzFormItem>
                          </Flex>
                        </Col>
                      </Row>
                      <Row gutter={12}>
                        <Col span={screens.desktop ? 12 : 24}>
                          <Flex vertical>
                            <SolarzFormItem
                              label={
                                <SolarzTypography
                                  fontWeight="bold"
                                  variant="title"
                                  hierarchy="paragraph2"
                                >
                                  Email de contato
                                </SolarzTypography>
                              }
                              style={{
                                padding: 0,
                              }}
                              name="email"
                              rules={[
                                {
                                  required: emailSettings?.isRequired,
                                  message: "Email do cliente é obrigatório",
                                },
                              ]}
                            >
                              <CustomInput.Text
                                placeholder="-"
                                type="email"
                                variant="borderless"
                                style={
                                  selectedClient
                                    ? { padding: 0 }
                                    : {
                                        ...getFieldReadonlyStyle(
                                          !!emailSettings?.isEditable,
                                          !!(currentTheme === "dark"),
                                        ),
                                        padding: 0,
                                      }
                                }
                                readOnly
                              />
                            </SolarzFormItem>
                          </Flex>
                        </Col>
                        <Col span={screens.desktop ? 12 : 24}>
                          <Flex vertical>
                            <SolarzFormItem
                              label={
                                <SolarzTypography
                                  fontWeight="bold"
                                  variant="title"
                                  hierarchy="paragraph2"
                                >
                                  Número de contato
                                </SolarzTypography>
                              }
                              style={{
                                padding: 0,
                              }}
                              name="phone"
                              rules={[
                                {
                                  min: 14,
                                  max: 15,
                                  message:
                                    "Numero tem que ter entre 10 e 14 caracteres",
                                },
                                {
                                  required: phoneSettings?.isRequired,
                                },
                              ]}
                            >
                              <CustomInput.TelephoneWithDdd
                                placeholder="-"
                                type="tel"
                                variant="borderless"
                                style={
                                  selectedClient
                                    ? { padding: 0 }
                                    : {
                                        ...getFieldReadonlyStyle(
                                          !!emailSettings?.isEditable,
                                          !!(currentTheme === "dark"),
                                        ),
                                        padding: 0,
                                      }
                                }
                                readOnly
                              />
                            </SolarzFormItem>
                          </Flex>
                        </Col>
                      </Row>
                    </Flex>
                  </Spin>
                </Card>

                {/* Start client hidden fields */}
                <Form.Item
                  label="Nome"
                  name="name"
                  rules={[{ required: true }]}
                  preserve
                  hidden
                >
                  <CustomInput.Text
                    placeholder="Ex.: José da Silva"
                    value={initialValues?.name}
                    prefix={getIconBySelectedClientType(clientTypeWatcher)}
                    disabled
                    readOnly
                    style={{ cursor: "not-allowed" }}
                  />
                </Form.Item>
                <Form.Item name="clientType" preserve hidden>
                  <CustomInput.Text
                    disabled
                    readOnly
                    style={{ cursor: "not-allowed" }}
                  />
                </Form.Item>
                <Form.Item name="clientId" preserve hidden>
                  <CustomInput.Number
                    disabled
                    readOnly
                    style={{ cursor: "not-allowed" }}
                  />
                </Form.Item>
                <Form.Item
                  name="pipedriveId"
                  preserve
                  hidden
                  initialValue={initialValues?.pipedriveId}
                >
                  <CustomInput.Number disabled readOnly />
                </Form.Item>
                <Form.Item
                  label="E-mail"
                  name="email"
                  data-cy="input-client-email"
                  preserve
                  hidden
                >
                  <CustomInput.Text type="email" disabled readOnly />
                </Form.Item>
                <Form.Item name="cpfCnpj" preserve hidden>
                  <CustomInput.CpfCnpj
                    placeholder="000.000.000-00 ou 000.000.000/0000-00"
                    readOnly
                    disabled
                  />
                </Form.Item>
                <Form.Item name="phone" hidden preserve>
                  <CustomInput.TelephoneWithDdd
                    placeholder="Ex.: (00) 00000-0000"
                    type="tel"
                    data-cy="input-client-phone"
                    readOnly
                    disabled
                  />
                </Form.Item>
                {/* End client hidden fields */}

                <ProposalCustomFieldsForm
                  initialValues={customFieldsInitialValues}
                  proposalSection="CLIENT_DATA"
                  pricingTypeId={pricingTypeIdWatcher}
                  form={form}
                  instanceKey="ClientData"
                />
              </Col>
              <Col
                span={screens.md ? 2 : 24}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Divider
                  type={screens.md ? "vertical" : "horizontal"}
                  style={{ height: "100%" }}
                />
              </Col>
              <Col span={screens.md ? 11 : 24}>
                <Flex
                  style={{
                    width: "100%",
                    flexDirection: screens.lg ? "row" : "column",
                  }}
                  justify="space-between"
                  wrap="nowrap"
                >
                  <Typography.Title level={4} style={{ marginBottom: "1rem" }}>
                    Endereço de instalação da usina
                  </Typography.Title>

                  <Button
                    type="primary"
                    onClick={() => setIsGoogleSearchOpen(true)}
                    style={{
                      fontSize: screens.xs ? "12px" : "14px",
                    }}
                    data-cy="button-search-address-with-google"
                    disabled={useClientAddress}
                  >
                    <Flex
                      align="center"
                      gap={screens.xs ? 4 : 8}
                      style={{ flexFlow: "row" }}
                    >
                      <AiOutlineGoogle size={20} />
                      Buscar endereço com Google
                    </Flex>
                  </Button>
                </Flex>

                {canUserAutoCompleteFieldsOfAddress && (
                  <Tooltip title="Ao marcar, os campos do endereço do clientes serão sobrescritos">
                    <Checkbox
                      disabled={
                        isLoadingOrganizationInformationByPipedriveId ||
                        isLoadingPersonInformationByPipedriveId
                      }
                      value={useClientAddress}
                      onChange={(e) => {
                        const isChecked = e.target.checked;

                        if (!isChecked) {
                          return setUseClientAddress(false);
                        }

                        form.setFieldsValue({
                          street: selectedClient.address?.street,
                          city: selectedClient.address?.city?.name,
                          state: selectedClient.address?.province?.name,
                          number: selectedClient.address?.number,
                          district: selectedClient.address?.district,
                          zipCode: selectedClient.address?.zipCode,
                        });

                        setUseClientAddress(true);
                      }}
                      style={
                        screens.desktop
                          ? {
                              marginBottom: "1rem",
                            }
                          : {
                              margin: "1rem 0",
                            }
                      }
                    >
                      Usar endereço do cliente
                      <Spin
                        spinning={
                          isLoadingOrganizationInformationByPipedriveId ||
                          isLoadingPersonInformationByPipedriveId
                        }
                      />
                    </Checkbox>
                  </Tooltip>
                )}

                <Divider style={{ margin: "0 0 1rem 0" }} />

                <SelectLocationInputModal
                  isOpen={isGoogleSearchOpen}
                  onClose={() => setIsGoogleSearchOpen(false)}
                  onSubmit={(location) => {
                    form.setFieldValue("street", location?.street);
                    form.setFieldValue("number", location?.streetNumber);
                    form.setFieldValue("city", location?.city);
                    form.setFieldValue("state", location?.state);
                    form.setFieldValue("district", location?.district);
                    form.setFieldValue("zipCode", location?.zipCode);

                    setIsGoogleSearchOpen(false);
                  }}
                />

                <Row gutter={16}>
                  <Col xs={24} lg={14} xxl={16}>
                    <Form.Item
                      label="Rua"
                      name="street"
                      rules={[{ required: streetSettings?.isRequired }]}
                      hidden={streetSettings?.isHidden}
                    >
                      <CustomInput.Text
                        data-cy="input-street"
                        placeholder="Av. exemplo"
                        readOnly={!streetSettings?.isEditable}
                        style={
                          useClientAddress
                            ? undefined
                            : getFieldReadonlyStyle(
                                !!streetSettings?.isEditable,
                                !!(currentTheme === "dark"),
                              )
                        }
                        disabled={useClientAddress}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={10} xxl={8}>
                    <Form.Item label="Número do endereço" name="number">
                      <CustomInput.Text
                        data-cy="input-number"
                        placeholder="80"
                        disabled={useClientAddress}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} xl={24}>
                    <Form.Item label="Bairro" name="district">
                      <CustomInput.Text
                        data-cy="input-district"
                        placeholder="Bairro"
                        disabled={useClientAddress}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} xl={24}>
                    <Form.Item
                      label="Cidade"
                      name="city"
                      rules={[{ required: true }]}
                    >
                      <CustomInput.Text
                        data-cy="input-city"
                        placeholder="Cidade"
                        disabled={useClientAddress}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} xl={24}>
                    <Form.Item
                      label="Estado"
                      name="state"
                      rules={[{ required: true /* requiredFields?.state */ }]}
                    >
                      <Select.BrazilianState
                        data-cy="select-state"
                        disabled={useClientAddress}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} xl={24}>
                    <Form.Item
                      label="Cep"
                      name="zipCode"
                      rules={[{ required: cepSettings?.isRequired }]}
                      hidden={cepSettings?.isHidden}
                    >
                      <CustomInput.Cep
                        data-cy="input-zip-code"
                        placeholder="00000-000"
                        readOnly={!cepSettings?.isEditable}
                        style={
                          useClientAddress
                            ? undefined
                            : getFieldReadonlyStyle(
                                !!streetSettings?.isEditable,
                                !!(currentTheme === "dark"),
                              )
                        }
                        disabled={useClientAddress}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Observação"
                      name="note"
                      rules={[{ required: false }]}
                      hidden={false}
                    >
                      <CustomInput.TextArea
                        placeholder="Deixe alguma observação aqui..."
                        style={{ minHeight: 120, maxHeight: 320 }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={24}>
                    <ProposalCustomFieldsForm
                      initialValues={customFieldsInitialValues}
                      proposalSection="ADDRESS_DATA"
                      pricingTypeId={pricingTypeIdWatcher}
                      form={form}
                      instanceKey="AddressData"
                    />
                  </Col>
                </Row>
                <ProposalCustomFieldsForm
                  initialValues={customFieldsInitialValues}
                  proposalSection="INITIAL_DATA"
                  pricingTypeId={pricingTypeIdWatcher}
                  form={form}
                  instanceKey="InitialData"
                />
              </Col>
            </Row>
          </Layout.Content>

          <Layout.Footer
            style={{
              background: "var(--background-color)",
              padding: screens.desktop ? "0.5rem 2rem" : "0.5rem 1.5rem",
              width: "100%",
              height: screens.desktop ? "2.5rem" : "1.5rem",
              marginBottom: "0rem",
              boxShadow: !screens.desktop
                ? currentTheme === "default"
                  ? "0px -2px 4px 0px rgba(0,0,0,0.12)"
                  : "0px -2px 4px 0px rgba(255,255,255,0.12)"
                : undefined,
              zIndex: 1000,
            }}
          >
            <Flex justify="end" gap={16}>
              <Flex gap={12} justify="end">
                {!hideCancelButton && (
                  <Form.Item style={{ margin: 0 }}>
                    <Button onClick={onCancel}>Cancelar</Button>
                  </Form.Item>
                )}
                <Form.Item style={{ margin: 0 }}>
                  <Tooltip
                    title={
                      forceToCreateCustomer &&
                      "O cliente não possui todos os campos obrigatórios, por favor, cadastre o cliente para prosseguir"
                    }
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      data-cy="button-start-proposal"
                      loading={isNextStepButtonLoading}
                      disabled={forceToCreateCustomer}
                    >
                      {proposalId ? (
                        <Flex
                          align="center"
                          gap={8}
                          style={{ flexFlow: "row" }}
                        >
                          {!isNextStepButtonLoading && (
                            <AiOutlineCheck size={18} />
                          )}
                          {canBeExpress &&
                            projectTypeWatcher === "EXPRESS" &&
                            "Atualizar express"}
                          {canBeExpress &&
                            !(projectTypeWatcher === "EXPRESS") &&
                            "Iniciar proposta completa"}
                          {!canBeExpress &&
                            !(projectTypeWatcher === "EXPRESS") &&
                            "Atualizar proposta"}
                        </Flex>
                      ) : (
                        <Flex
                          align="center"
                          gap={8}
                          style={{ flexFlow: "row" }}
                        >
                          {!isNextStepButtonLoading && (
                            <AiOutlineArrowRight size={18} />
                          )}
                          Iniciar{" "}
                          {projectTypeWatcher === "EXPRESS"
                            ? "express"
                            : "proposta"}
                        </Flex>
                      )}
                    </Button>
                  </Tooltip>
                </Form.Item>
              </Flex>
            </Flex>
          </Layout.Footer>
        </Layout>
      </Form>

      <Modal.OrganizationOrPersonTabsForm
        isOpen={isOrganizationOrPersonTabsFormModalOpen}
        onClose={handleCloseOrganizationOrPersonTabsFormModal}
        title="Alterar dados"
        disableOptions={{
          organization:
            !!selectedClient?.personId || !!selectedClient?.pipedrivePersonId,
          person:
            !!selectedClient?.organizationId ||
            !!selectedClient?.pipedriveOrganizationId,
        }}
        hideOptions={{
          organization:
            !!selectedClient?.personId || !!selectedClient?.pipedrivePersonId,
          person:
            !!selectedClient?.organizationId ||
            !!selectedClient?.pipedriveOrganizationId,
        }}
        alertMessage={
          forceToCreateCustomer
            ? "O cliente não possui todos os dados obrigatórios, por favor finalize o cadastro dele."
            : undefined
        }
        organizationFormProps={{
          onSuccess: (organization) => {
            form.setFieldsValue({
              name: organization?.name,
              cpfCnpj: organization?.cnpj
                ? formatCpfCnpj(organization.cnpj)
                : undefined,
              email: organization?.email,
              phone: organization?.phone
                ? formatTelephoneWithDdd(organization.phone)
                : undefined,
              clientId: organization.id,
              organizationCustomId: organization.customId,
            });

            setSelectedClient({
              address: organization.address,
              organizationId: organization.id,
              organizationCustomId: organization.customId,
            });

            setUseClientAddress(false);

            setForceToCreateCustomer(false);
          },
          organizationId: selectedClient?.organizationId ?? undefined,
          displayPipedriveIntegration: forceToCreateCustomer,
          initialValues: {
            name: selectedClient?.name,
            address: selectedClient?.address,
            email: selectedClient?.email,
            phone: selectedClient?.phone,
            cnpj: selectedClient?.identifier,
            pipedriveId: selectedClient?.pipedriveOrganizationId,
            ownerId: user?.id,
          },
        }}
        personFormProps={{
          onSuccess: (person) => {
            form.setFieldsValue({
              name: person?.name,
              cpfCnpj: person?.identifier
                ? formatCpfCnpj(person.identifier)
                : undefined,
              email: person?.email,
              phone: person?.phone
                ? formatTelephoneWithDdd(person.phone)
                : undefined,
              clientId: person.id,
            });

            setSelectedClient({
              address: person.address,
              personId: person.id,
              personCustomId: person.customId,
            });

            setUseClientAddress(false);

            setForceToCreateCustomer(false);
          },
          personId: selectedClient?.personId ?? undefined,
          displayPipedriveIntegration: forceToCreateCustomer,
          initialValues: {
            name: selectedClient?.name,
            address: selectedClient?.address,
            email: selectedClient?.email,
            phone: selectedClient?.phone,
            identifier: selectedClient?.identifier,
            pipedriveId: selectedClient?.pipedrivePersonId,
            responsibleSellerId: user?.id,
          },
        }}
      />
    </>
  );
}
