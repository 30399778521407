import { FC } from "react";

import { InputNumber as AntdInputNumber } from "antd";
import type { InputNumberProps } from "antd/lib/input-number";

export type Props = Omit<InputNumberProps, "precision"> & {
  precision?: number;
};

export const InputNumber: FC<Props> = ({
  onFocus = (event) => event.target.select(),
  precision = 2,
  ...rest
}) => {
  return (
    <AntdInputNumber
      {...rest}
      style={{ width: "100%", ...rest.style }}
      onFocus={onFocus}
      precision={precision}
    />
  );
};
