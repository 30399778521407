import { useEffect, useState } from "react";
import {
  AiOutlineCheckCircle,
  AiOutlineDelete,
  AiOutlineEdit,
} from "react-icons/ai";

import type {
  IProposalCustomFieldsTableData,
  IProposalCustomFieldsTableColumn,
  IProposalCustomFieldsTableProps,
} from "./interface";

import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Menu } from "~components/Menu";
import { Modal } from "~components/Modal";
import { DraggableTableRow } from "~components/Others";
import { Result } from "~components/Result";
import { Tag, UniqueValueTagColorType } from "~components/Tag";
import {
  useFetchDeleteCustomField,
  useFetchGetCustomFields,
  useFetchUpdateCustomFieldsOrder,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import type { FieldTypeEnum, ProjectTypeEnum } from "~types/enum";
import { ICustomFieldDTOSchema } from "~types/schemas";
import { Table, Tooltip } from "antd";

function getTagColorByType(value: FieldTypeEnum): UniqueValueTagColorType {
  switch (value) {
    case "BOOLEAN":
      return "geekblue";
    case "NUMBER":
      return "cyan";
    case "TEXT":
      return "volcano";
    case "SELECT":
      return "magenta";
    case "IMAGE":
      return "gold";
    default:
      return "processing";
  }
}

function getTagColorByProjectType(
  value: ProjectTypeEnum,
): UniqueValueTagColorType {
  switch (value) {
    case "ALL":
      return "geekblue";
    case "DEFAULT":
      return "cyan";
    case "DIVERSE":
      return "volcano";
    case "EXPRESS":
      return "magenta";
    default:
      return "processing";
  }
}

function translateCustomFieldType(value: FieldTypeEnum) {
  switch (value) {
    case "BOOLEAN":
      return "Sim/Não";
    case "NUMBER":
      return "Numérico";
    case "TEXT":
      return "Texto";
    case "TEXT_AREA":
      return "Caixa de texto";
    case "SELECT":
      return "Selecionável";
    case "IMAGE":
      return "Imagem";
    case "DATE":
      return "Data";
    default:
      return "Desconhecido";
  }
}

function translateCustomFieldProjectType(value: ProjectTypeEnum) {
  switch (value) {
    case "ALL":
      return "Todos";
    case "DEFAULT":
      return "Padrão";
    case "DIVERSE":
      return "Diversa";
    case "EXPRESS":
      return "Express";
    default:
      return "Desconhecido";
  }
}

function proposalCustomFieldTypeColumnRender(value: FieldTypeEnum) {
  const tagColor = getTagColorByType(value);
  const tagValue = translateCustomFieldType(value);

  return (
    <Tag.UniqueValue
      color={tagColor}
      title={tagValue}
      tooltip={`Campo do tipo ${tagValue}`}
    />
  );
}

function proposalCustomFieldProjectTypeColumnRender(value: ProjectTypeEnum) {
  const tagColor = getTagColorByProjectType(value);
  const tagValue = translateCustomFieldProjectType(value);

  return (
    <Tag.UniqueValue
      color={tagColor}
      title={tagValue}
      tooltip={`Campo para propostas do tipo ${tagValue}`}
    />
  );
}

function proposalDefaultValueColumnRender(
  value: string | number,
  record: Omit<ICustomFieldDTOSchema, "position">,
) {
  if (!value) return undefined;

  if (record.type !== "BOOLEAN") return value;

  const parsedValue = value === "true" ? "Sim" : "Não";

  return parsedValue;
}

export function ProposalCustomFieldsTable({
  proposalSection = undefined,
}: IProposalCustomFieldsTableProps) {
  const { message } = useAppConfig();

  const [dataSource, setDataSource] = useState<
    IProposalCustomFieldsTableData[]
  >([]);

  const [needUpdateOrder, setNeedUpdateOrder] = useState(false);

  const [selectedCustomFieldToUpdate, setSelectedCustomFieldToUpdate] =
    useState<IProposalCustomFieldsTableData | undefined>(undefined);

  const [selectedCustomFieldToDelete, setSelectedCustomFieldToDelete] =
    useState<IProposalCustomFieldsTableData | undefined>(undefined);

  const columns: IProposalCustomFieldsTableColumn = [
    {
      key: "sort",
      width: 42,
      align: "center" as const,
    },
    {
      title: "Identificador",
      dataIndex: "identifier",
      width: 224,
    },
    {
      title: "Nome",
      dataIndex: "label",
      width: "auto",
    },
    {
      title: "Tipo",
      dataIndex: "type",
      render: proposalCustomFieldTypeColumnRender,
      width: 128,
    },
    {
      title: "Valor padrão",
      dataIndex: "defaultValue",
      width: 320,
      render: proposalDefaultValueColumnRender,
    },
    {
      title: "Obrigatório",
      dataIndex: "required",
      width: 96,
      align: "center" as const,
      render: (value: boolean) => (
        <Tooltip title={value ? "Obrigatório" : "NÃO obrigatório"}>
          <AiOutlineCheckCircle
            size={20}
            color={value ? "var(--green-500)" : "var(--gray-500)"}
          />
        </Tooltip>
      ),
    },
    {
      title: "Habilitado",
      dataIndex: "enabled",
      width: 96,
      align: "center" as const,
      render: (value: boolean) => (
        <Tooltip title={value ? "Habilitado" : "NÃO habilitado"}>
          <AiOutlineCheckCircle
            size={20}
            color={value ? "var(--green-500)" : "var(--gray-500)"}
          />
        </Tooltip>
      ),
    },
    {
      title: "Tipo de proposta",
      dataIndex: "projectType",
      render: proposalCustomFieldProjectTypeColumnRender,
      width: 128,
    },
    {
      title: "",
      dataIndex: "action",
      width: 42,
      align: "center" as const,
      render: (_, customField) => (
        <Menu.MoreDropdown
          items={[
            {
              icon: (
                <AiOutlineEdit
                  fontSize={18}
                  style={{ color: "var(--gold-500)" }}
                />
              ),
              text: "Alterar",
              color: "var(--gold-500)",
              onClick: () => setSelectedCustomFieldToUpdate(customField),
            },
            {
              icon: (
                <AiOutlineDelete
                  fontSize={16}
                  style={{ color: "var(--volcano-500)" }}
                />
              ),
              text: "Remover",
              color: "var(--volcano-500)",
              onClick: () => setSelectedCustomFieldToDelete(customField),
            },
          ]}
          position="bottomLeft"
        />
      ),
    },
  ];

  function onDragEnd({ active, over }: DragEndEvent) {
    if (active.id !== over?.id) {
      setDataSource((state) => {
        const activeIndex = state.findIndex((i) => i.key === active.id);
        const overIndex = state.findIndex((i) => i.key === over?.id);
        return arrayMove(state, activeIndex, overIndex);
      });
      setNeedUpdateOrder(true);
    }
  }

  const { isFetching: isLoadingCustomFields, error: customFieldsError } =
    useFetchGetCustomFields({
      options: {
        onSuccess: (data) => {
          const dataSource = data.map((content) => ({
            ...content,
            key: content.id,
          }));

          setDataSource(dataSource);
        },
        queryHash: proposalSection,
      },
      payload: {
        proposalSection,
      },
    });

  const { mutate: deleteCustomField, isLoading: isDeletingCustomField } =
    useFetchDeleteCustomField({
      onSuccess: () => {
        setSelectedCustomFieldToDelete(undefined);
      },
    });

  const {
    mutate: updateCustomFieldsOrder,
    isLoading: isUpdatingCustomFieldsOrder,
  } = useFetchUpdateCustomFieldsOrder({
    options: {
      onSuccess: () => setNeedUpdateOrder(false),
      onError: () => setNeedUpdateOrder(false),
    },
  });

  function handleDeleteCustomField() {
    const customFieldId = selectedCustomFieldToDelete?.id;

    customFieldId
      ? deleteCustomField({ customFieldId: customFieldId })
      : message.error("Client error: faltando o ID do campo customizado!");
  }

  useEffect(() => {
    if (needUpdateOrder) {
      const proposalFieldsOrder = dataSource.map((item, index) => ({
        id: item.id,
        order: index + 1,
      }));

      updateCustomFieldsOrder({
        proposalFieldsOrder,
      });
    }
  }, [needUpdateOrder, updateCustomFieldsOrder, dataSource, proposalSection]);

  return (
    <>
      <DndContext data-cy="proposal-custom-fields-table" onDragEnd={onDragEnd}>
        <SortableContext
          items={dataSource.map((item) => item.id)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            components={{
              body: {
                row: DraggableTableRow,
              },
            }}
            rowKey="key"
            columns={columns}
            dataSource={dataSource}
            scroll={{ x: 900 }}
            size="small"
            pagination={false}
            loading={isLoadingCustomFields || isUpdatingCustomFieldsOrder}
            locale={{
              emptyText: (
                <Result.TableErrorOrEmpty
                  errorMessage={customFieldsError?.message}
                  emptyMessage="Nenhum campo personalizado encontrado"
                />
              ),
            }}
            style={{ width: "100%" }}
          />
        </SortableContext>
      </DndContext>

      <Modal.ProposalCustomFieldForm
        isOpen={!!selectedCustomFieldToUpdate}
        onClose={() => setSelectedCustomFieldToUpdate(undefined)}
        formProps={{
          initialValues: selectedCustomFieldToUpdate,
          onSubmitSuccess: () => setSelectedCustomFieldToUpdate(undefined),
        }}
      />

      <Modal.DefaultRemove
        title="Remover campo customizado"
        suffix="campo customizado"
        alertMessage={
          Number(selectedCustomFieldToDelete?.businessUnits?.length) > 1
            ? "Esse campo está associado em mais de uma unidade de negócio, excluí-lo também removerá da(s) outra(s) unidade(s). Caso queira remover apenas dessa unidade, edite o campo para não ser mostrado na UN."
            : undefined
        }
        isOpen={!!selectedCustomFieldToDelete}
        itemName={selectedCustomFieldToDelete?.label}
        onClose={() => setSelectedCustomFieldToDelete(undefined)}
        onSubmit={handleDeleteCustomField}
        isLoading={isDeletingCustomField}
        maxWidth={580}
      />
    </>
  );
}
