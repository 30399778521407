import type { IOrganizationDTOSchema } from "~types/schemas";

export type OrganizationTableDataType = IOrganizationDTOSchema;

export type OrganizationTableFiltersType = {
  currentPage: number;
  query?: string;
  userId?: number;
};

export enum OrganizationTableQueryParamKeysEnum {
  CURRENT_PAGE = "page",
  QUERY = "query",
  USER_ID = "user-id",
}

export type OrganizationTableQueryParamsKeysType = Record<
  keyof typeof OrganizationTableQueryParamKeysEnum,
  string
>;

export interface IOrganizationTableProps {
  defaultFilters?: Partial<OrganizationTableFiltersType>;
  displayFiltersComponent?: boolean;
  queryParamKeys?: Partial<OrganizationTableQueryParamsKeysType>;
}
