import { BiErrorCircle } from "react-icons/bi";

import { IUserGroupSelectProps, SelectUserGroupOptionType } from "./interface";

import { useFetchGetAllUsersGroups } from "~hooks/api";
import { Select } from "antd";

export function UserGroupSelect({
  onLoad = () => undefined,
  onChange,
  ...props
}: IUserGroupSelectProps) {
  const {
    data: groups,
    isFetching: isLoadingGroups,
    error: groupsError,
  } = useFetchGetAllUsersGroups({
    options: {
      onSuccess: onLoad,
    },
  });

  const dataSource: SelectUserGroupOptionType[] = groups
    ? groups.map((group) => ({
      label: group.name,
      value: group.id,
      record: group,
    }))
    : [];

  return (
    <Select
      {...props}
      loading={isLoadingGroups}
      options={dataSource}
      status={groupsError ? "error" : ""}
      allowClear
      data-cy="user-group-select"
      suffixIcon={
        groupsError ? (
          <BiErrorCircle size={18} color="var(--red-200)" />
        ) : undefined
      }
      onChange={(value, option) => {
        if (option instanceof Array || !onChange) return;
        onChange(value, option);
      }}
    />
  );
}
