import styled from "@emotion/styled";
import { SolarzButton } from "~solarzui/SolarzButton";
import { Card } from "antd";

export const StyledCard = styled(Card)`
  width: 100%;

  &.ant-card {
    border: 0;

    .ant-card-body {
      background-color: var(--gray-50);
    }

    // DARK THEME
    html.dark & {
      .ant-card-body {
        background-color: var(--gray-999);
      }
    }
  }
`;

export const DealNextStepButton = styled(SolarzButton)`
  font-weight: 400;
  font-size: 12px;
`;
