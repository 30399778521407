export function checkIfValueIsNumber(value: any) {
  if (
    value === null ||
    value === false ||
    typeof value === "object" ||
    typeof value === "function"
  ) {
    return false;
  }

  return !isNaN(Number(value));
}
