import styled from "@emotion/styled";
import { Alert } from "antd";

export const StyledAlert = styled(Alert)`
  border-color: var(--red-500);

  ul {
    margin-top: 12px;
  }

  ul li::marker {
    color: var(--red-600);
  }
`;
