import { useState } from "react";

import type { ICreateDatasheetModalButtonProps } from "./interface";

import { Modal } from "~components/Modal";
import { Add } from "~estrela-ui/icons";
import { SolarzButton } from "~solarzui/SolarzButton";

export function CreateDatasheetModalButton({
  onSuccess,
}: ICreateDatasheetModalButtonProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <SolarzButton
        icon={<Add />}
        onClick={() => {
          setIsModalOpen(true);
        }}
        variant="ghost"
        scale="tiny"
      >
        Datasheet
      </SolarzButton>

      <Modal.DatasheetForm
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        formProps={{
          onSuccess: (datasheet) => {
            setIsModalOpen(false);

            if (typeof onSuccess === "function") {
              onSuccess(datasheet);
            }
          },
        }}
      />
    </>
  );
}
