import { useMemo, useState } from "react";
import { BsPencil, BsTrash3 } from "react-icons/bs";

import {
  TransformerGroupRulesTableQueryParamKeysEnum,
  type ITransformerGroupRulesTableProps,
  type TransformerGroupRulesTableFiltersType,
  type TransformerGroupRulesTableQueryParamsKeysType,
  ITransformerGroupRulesTableColumType,
} from "./interface";

import { Modal } from "~components/Modal";
import {
  useFetchDeleteTransformerRule,
  useFetchGetPaginatedTransformerRules,
} from "~hooks/api";
import { useQueryParams, parseFunctions } from "~hooks/useQueryParams";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzTable } from "~solarzui/SolarzTable";
import type { ITransformerAdditionalCostListItemDTOSchema } from "~types/schemas";
import { Flex } from "antd";

export function TransformerGroupRulesTable({
  ...props
}: ITransformerGroupRulesTableProps) {
  const { getParsedQueryParams, handleSaveInQueryParams } = useQueryParams();

  const [
    selectedTransformerGroupRulesToUpdate,
    setSelectedTransformerGroupRulesToUpdate,
  ] = useState<ITransformerAdditionalCostListItemDTOSchema>();

  const [
    selectedTransformerGroupRulesToDelete,
    setSelectedTransformerGroupRulesToDelete,
  ] = useState<ITransformerAdditionalCostListItemDTOSchema>();

  const QUERY_PARAMS_KEYS: TransformerGroupRulesTableQueryParamsKeysType =
    useMemo(
      () => ({
        CURRENT_PAGE:
          props.queryParamKeys?.CURRENT_PAGE ||
          TransformerGroupRulesTableQueryParamKeysEnum.CURRENT_PAGE,
      }),
      [props.queryParamKeys],
    );

  const filters: TransformerGroupRulesTableFiltersType = useMemo(
    () => ({
      currentPage:
        getParsedQueryParams(
          QUERY_PARAMS_KEYS.CURRENT_PAGE,
          parseFunctions.NUMBER,
        ) ?? 1,
    }),
    [getParsedQueryParams, QUERY_PARAMS_KEYS],
  );

  const {
    mutate: deleteTransformerGroupRules,
    isLoading: isDeletingTransformerGroupRules,
  } = useFetchDeleteTransformerRule({
    onSuccess: () => setSelectedTransformerGroupRulesToDelete(undefined),
  });

  function handleDeleteTransformerGroupRules() {
    deleteTransformerGroupRules({
      transformerRuleId: selectedTransformerGroupRulesToDelete?.id ?? 0,
    });
  }

  const {
    data: transformerGroupRules,
    isFetching: isLoadingTransformerGroupRules,
    error: transformerGroupRulesError,
    refetch: reloadTransformerGroupRules,
  } = useFetchGetPaginatedTransformerRules({
    dependencyArray: [filters],
    options: {
      retry: 1,
      enabled: filters.currentPage > 0,
    },
    payload: {
      page: filters.currentPage - 1,
      pageSize: 10, // STATIC,
    },
  });

  const columns: ITransformerGroupRulesTableColumType[] = [
    {
      title: "Saída do inversor",
      render: (_, record) => record.inputVoltage,
    },
    {
      title: "Entrada da rede",
      render: (_, record) => record.outputVoltage,
    },
    {
      title: "",
      align: "center",
      render: (_, record) => (
        <Flex gap={12} justify="end">
          <SolarzButton
            variant="ghost"
            style={{ border: "none" }}
            icon={<BsPencil size={10} color="var(--gold-500)" />}
            onClick={() => setSelectedTransformerGroupRulesToUpdate(record)}
          />
          <SolarzButton
            style={{ border: "none" }}
            variant="ghost"
            icon={<BsTrash3 size={10} color="var(--red-400)" />}
            onClick={() => setSelectedTransformerGroupRulesToDelete(record)}
          />
        </Flex>
      ),
    },
  ];

  return (
    <Flex vertical>
      <SolarzTable<ITransformerAdditionalCostListItemDTOSchema>
        data-cy="complementary-items-table"
        columns={columns}
        rows={transformerGroupRules?.content ?? []}
        isLoading={isLoadingTransformerGroupRules}
        pagination={{
          currentItemCount: transformerGroupRules?.size ?? 0,
          currentPage: filters.currentPage || 1,
          itemLabel: "Regras de transformadores",
          totalItemCount: transformerGroupRules?.totalElements ?? 0,
          onChange: (currentPage) => {
            handleSaveInQueryParams({
              [QUERY_PARAMS_KEYS.CURRENT_PAGE]: currentPage,
            });
          },
        }}
        scroll={{ x: 1200 }}
        emptyMessage="Não foi encontrado nenhum item"
        errorMessage={transformerGroupRulesError?.message}
        reloadFn={reloadTransformerGroupRules}
        rowKey="id"
      />
      <Modal.DefaultRemove
        isLoading={isDeletingTransformerGroupRules}
        isOpen={!!selectedTransformerGroupRulesToDelete}
        // itemName={selectedTransformerGroupRulesToDelete?.}
        onClose={() => setSelectedTransformerGroupRulesToDelete(undefined)}
        suffix="Regras do grupo de transformadores"
        title="Remover Regras do grupo de transformadores"
        onSubmit={handleDeleteTransformerGroupRules}
      />
      <Modal.TransformerGroupForm
        isOpen={!!selectedTransformerGroupRulesToUpdate}
        onClose={() => setSelectedTransformerGroupRulesToUpdate(undefined)}
        formProps={{
          initialValues: selectedTransformerGroupRulesToUpdate,
          onSuccess: () => setSelectedTransformerGroupRulesToUpdate(undefined),
        }}
      />
    </Flex>
  );
}
