import NextLink from "next/link";

import { ISolarzAnchorProps } from "./interface";
import { StyledAnchor } from "./styles";

export function SolarzAnchor({
  isNextLink,
  children,
  isDisabled,
  defaultUnderlined = true,
  ...props
}: ISolarzAnchorProps) {
  if (isNextLink) {
    return (
      <StyledAnchor
        disabled={isDisabled}
        tabIndex={-1}
        defaultUnderlined={defaultUnderlined}
        style={props.style}
      >
        <NextLink
          {...props}
          href={props.href ?? ""}
          tabIndex={isDisabled ? -1 : 0}
        >
          {children}
        </NextLink>
      </StyledAnchor>
    );
  }

  return (
    <StyledAnchor disabled={isDisabled} tabIndex={-1}>
      <a {...props} tabIndex={isDisabled ? -1 : 0}>
        {children}
      </a>
    </StyledAnchor>
  );
}
