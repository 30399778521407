import type { ISolarzDateRangePicker } from "./interface";

import styled from "@emotion/styled";
import { DatePicker } from "antd";

function getStylesByScaleProps(scale?: ISolarzDateRangePicker["scale"]) {
  switch (scale) {
    case "large":
      return {
        height: "3rem",
      };
    case "medium":
      return {
        height: "2.5rem",
      };
    case "tiny":
      return {
        height: "2rem",
      };
    default:
      return {
        height: "2.5rem",
      };
  }
}

interface IStyledDateRangePicker extends ISolarzDateRangePicker {
  scale?: ISolarzDateRangePicker["scale"];
}

export const StyledDateRangePicker = styled(
  DatePicker.RangePicker,
)<IStyledDateRangePicker>`
  height: ${(props) => getStylesByScaleProps(props.scale).height};

  padding: 0.875rem 0rem;

  input {
    text-align: center;
    color: var(--gray-500) !important;
    cursor: pointer;
  }
`;
