import { useMemo, useState } from "react";

import type {
  CitySelectOptionType,
  CitySelectRecordType,
  ICitySelectProps,
} from "./interface";

import { useFetchGetCity } from "~hooks/api";
import { useSession } from "~hooks/useSession";
import { SolarzSelect } from "~solarzui/SolarzSelect";

export function CitySelect({ showSearch = true, ...props }: ICitySelectProps) {
  const { user } = useSession();
  const [searchTerm, setSearchTerm] = useState("");

  const {
    data: cities,
    isFetching: isLoadingCities,
    error: errorCities,
    refetch: reloadCities,
  } = useFetchGetCity({
    dependencyArray: [searchTerm],
    payload: {
      term: searchTerm,
    },
    options: {
      enabled: !!user && searchTerm.length >= 3,
      retry: 1,
    },
  });

  const OPTIONS: CitySelectOptionType = useMemo(() => {
    if (!cities || cities.length === 0) return [];

    const parsedOptions: CitySelectOptionType = cities.map((city) => {
      return {
        label: city.name,
        record: city,
        value: city.id || 0,
      };
    });

    return parsedOptions;
  }, [cities]);

  return (
    <SolarzSelect<CitySelectRecordType, "id">
      {...props}
      data-cy="admin-user-select"
      options={OPTIONS}
      isLoading={isLoadingCities}
      refetchFn={reloadCities}
      errorMessage={errorCities?.message}
      emptyMessage={
        searchTerm.length > 3
          ? "Nenhuma cidade encontrada"
          : "Digite ao menos 3 letras para buscar"
      }
      searchValue={searchTerm}
      onChangeSearch={(value) => {
        setSearchTerm(value);
      }}
      showSearch={showSearch}
    />
  );
}
