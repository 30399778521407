import { useMemo } from "react";

import type { IKomecoEquipmentStructureTypeSelectProps } from "./interface";

import { AutoCompleteSuffixIcon } from "~components/Others";
import { Result } from "~components/Result";
import { useFetchGetAllKomecoEquipmentStructures } from "~hooks/api";
import { Select } from "antd";

export function KomecoEquipmentStructureTypeSelect({
  allowClear = true,
  onChange,
  ...props
}: IKomecoEquipmentStructureTypeSelectProps) {
  const {
    data: structureTypes = [],
    isFetching,
    error,
    refetch,
  } = useFetchGetAllKomecoEquipmentStructures({
    options: {
      retry: 1,
    },
  });

  const OPTIONS = useMemo(
    () =>
      structureTypes.map((structureType) => ({
        label: structureType.description ?? "Desconhecida",
        value: structureType.structureType,
        record: structureType,
      })),
    [structureTypes],
  );

  return (
    <Select
      {...props}
      data-cy="komeco-equipment-structure-type-select"
      options={OPTIONS}
      allowClear={allowClear}
      status={error ? "error" : ""}
      loading={isFetching}
      notFoundContent={
        <Result.SelectErrorOrEmpty
          errorMessage={error?.message}
          emptyMessage="Nenhuma estrutura encontrada"
          loading={isFetching}
          reloadFn={refetch}
        />
      }
      onChange={(value, option) => {
        if (typeof onChange === "undefined") return;
        onChange(value, option);
      }}
      suffixIcon={
        <AutoCompleteSuffixIcon hasError={!!error} isLoading={isFetching} />
      }
      style={{ width: "100%" }}
    />
  );
}
