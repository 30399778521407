import { FC } from "react";

import { Input } from "antd";
import type { InputProps } from "antd/lib/input";

export const InputText: FC<InputProps> = ({
  onFocus = (event) => event.target.select(),
  ...rest
}) => {
  return <Input {...rest} onFocus={onFocus} />;
};
