import { v4 as uuidv4 } from "uuid";

export function createUuid() {
  return uuidv4();
}

/*
  Por causa de retro-compatibilidade essa função foi
  substituída, hoje 06/04/2023 ainda possuímos clientes
  que não tem o browser atualizado para gerar uuid nativamente.

  Todavia, acredito que dentro de 6 meses, poderemos alterar
  para usar nativamente o uuid, e então desinstalar o pacote
  do uuid do node_modules.
*/

// import crypto from "crypto";

// export function createUuid() {
//   return typeof window !== "undefined"
//     ? window.crypto.randomUUID()
//     : crypto.randomUUID();
// }
