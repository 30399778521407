import { IDeleteOrganizationCardProps } from "./interface";
import { StyledCard } from "./style";

import { Alert } from "~components/Alert";
import { Company } from "~estrela-ui/icons";
import { useFetchGetOrganizationById } from "~hooks/api";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { formatTelephoneWithDdd } from "~utils/format";
import { Skeleton } from "antd";

export function DeleteOrganizationCard({
  organizationId,
  style,
}: IDeleteOrganizationCardProps) {
  const {
    data: organizationData,
    isFetching: isLoadingOrganization,
    error: organizationError,
    refetch: reloadOrganizationData,
  } = useFetchGetOrganizationById({
    payload: {
      organizationId,
    },
    dependencyArray: [organizationId],
    options: {
      enabled: organizationId > 0,
      retry: 1,
    },
  });

  return (
    <StyledCard
      style={{ ...style }}
      title={
        <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
          <Company color="var(--primary-500)" />
          <SolarzTypography
            fontWeight="semiBold"
            hierarchy="paragraph1"
            variant="title"
          >
            {organizationData?.name}
          </SolarzTypography>
        </div>
      }
    >
      <div>
        {organizationError && (
          <Alert.CardError
            title="Falha ao carregar dados"
            errorMessage={organizationError?.message}
            reloadFn={reloadOrganizationData}
            style={{ marginBottom: 12 }}
          />
        )}
        <div className="flex justify-normal gap-6">
          <div>
            <div className="w-28 ">
              <SolarzTypography
                fontWeight="semiBold"
                variant="title"
                hierarchy="paragraph2"
              >
                CNPJ
              </SolarzTypography>
            </div>
          </div>
          {isLoadingOrganization || organizationError ? (
            <Skeleton.Input active size="small" />
          ) : (
            <SolarzTypography
              fontWeight="regular"
              variant="disabled"
              hierarchy="small"
              style={{ width: "100%" }}
            >
              {organizationData?.cnpj}
            </SolarzTypography>
          )}
        </div>
        <div className="flex justify-normal gap-6">
          <div className=" align-middle">
            <div className="w-28">
              <SolarzTypography
                fontWeight="semiBold"
                variant="title"
                hierarchy="paragraph2"
              >
                Endereço
              </SolarzTypography>
            </div>
          </div>
          {isLoadingOrganization || organizationError ? (
            <Skeleton.Input active size="small" />
          ) : (
            <SolarzTypography
              fontWeight="regular"
              variant="disabled"
              hierarchy="small"
            >
              {organizationData?.address?.formattedAddress}
            </SolarzTypography>
          )}
        </div>
        <div className="flex justify-normal gap-6">
          <div className="w-28">
            <SolarzTypography
              fontWeight="semiBold"
              variant="title"
              hierarchy="paragraph2"
            >
              Telefone
            </SolarzTypography>
          </div>
          {isLoadingOrganization || organizationError ? (
            <Skeleton.Input active size="small" />
          ) : (
            <SolarzTypography
              fontWeight="regular"
              variant="disabled"
              hierarchy="small"
            >
              {formatTelephoneWithDdd(organizationData?.phone ?? "")}
            </SolarzTypography>
          )}
        </div>
        <div className="flex justify-start gap-6">
          <div className="w-28 ">
            <SolarzTypography
              fontWeight="semiBold"
              variant="title"
              hierarchy="paragraph2"
            >
              Responsável
            </SolarzTypography>
          </div>
          <SolarzTypography
            fontWeight="regular"
            variant="disabled"
            hierarchy="small"
            style={{
              color: "var(--primary-500)",
            }}
          >
            {organizationData?.owner?.name}
          </SolarzTypography>
        </div>
      </div>
    </StyledCard>
  );
}
