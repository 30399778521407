import type { IPerformanceRateMonthFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function PerformanceRateMonthFormModal({
  isOpen = false,
  onClose = () => null,
  title = "Definir performance mês a mês",
  maxWidth = 612,
  formProps,
}: IPerformanceRateMonthFormModalProps) {
  return (
    <Modal
      data-cy="performance-rate-month-form-modal"
      open={isOpen}
      onCancel={onClose}
      title={title}
      width="90vw"
      footer={null}
      style={{ maxWidth }}
      destroyOnClose
    >
      <Form.PerformanceRateMonth {...formProps} onCancel={onClose} />
    </Modal>
  );
}
