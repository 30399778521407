import { useMemo } from "react";
import { AiOutlineUser, AiOutlineCalendar } from "react-icons/ai";
import { BiCog, BiWrench } from "react-icons/bi";
import {
  BsBasket3,
  BsBox,
  BsBuilding,
  BsCashCoin,
  BsChatSquareText,
  BsCoin,
  BsColumnsGap,
  BsFileEarmarkFont,
  BsFillPeopleFill,
  BsGeoAlt,
  BsHddStack,
  BsPercent,
  BsPlus,
  BsToggles2,
} from "react-icons/bs";
import { GoLog } from "react-icons/go";
import { GrValidate } from "react-icons/gr";
import { LuGitPullRequestClosed } from "react-icons/lu";
import { MdOutlineCalculate } from "react-icons/md";
import { TbBuildingStore } from "react-icons/tb";

import Link from "next/link";
import { useRouter } from "next/router";

import type { IAppPagesMenuProps, MenuItemType } from "./interface";
import styles from "./styles";

import { ENV } from "~configs/Env";
import { useSession } from "~hooks/useSession";
import { Menu } from "antd";

function getActiveKeysByNextRouterPath(path: string) {
  if (!path || path === "/") {
    return ["/"];
  }

  const formattedStringPaths = path
    .split("/")
    .filter((path) => path)
    .map((path) => "/" + path);

  const pathParsedToKey = formattedStringPaths.reduce((keys, path) => {
    if (keys.length === 0) {
      return [path];
    }

    const newKey = keys.concat() + path;

    return [...keys, newKey];
  }, [] as string[]);

  return pathParsedToKey;
}

export function AppPagesMenu({
  inlineIndent = 12,
  mode = "inline",
  ...props
}: IAppPagesMenuProps) {
  const router = useRouter();
  const { userHavePermission, userIsAdmin } = useSession();

  const canAccessAdministrativeArea =
    userIsAdmin || userHavePermission("ACCESS_ADMINISTRATIVE_AREA");

  const MENU_ITEMS = useMemo(
    () =>
      [
        {
          key: "/",
          icon: <BsBox />,
          label: <Link href="/">Dashboard</Link>,
        },
        {
          key: "/clientes",
          icon: <AiOutlineUser />,
          label: <Link href="/clientes">Clientes</Link>,
          hidden: !canAccessAdministrativeArea,
        },
        ...(ENV.AMBIENT === "DEVELOPMENT"
          ? [
              {
                key: "/atividades",
                icon: <AiOutlineCalendar />,
                label: <Link href="/atividades">Atividades</Link>,
                hidden: !canAccessAdministrativeArea,
              },
            ]
          : []),
        {
          key: "/configuracoes-de-propostas",
          icon: <BiWrench size={16} />,
          label: "Configurações de propostas",
          children: [
            ...(ENV.AMBIENT === "DEVELOPMENT"
              ? [
                  {
                    key: "/configuracoes-de-propostas/campos-da-proposta",
                    icon: <BsToggles2 />,
                    label: (
                      <Link href="/configuracoes-de-propostas/campos-da-proposta">
                        Campos da proposta
                      </Link>
                    ),
                    hidden: !canAccessAdministrativeArea,
                  },
                ]
              : []),
            {
              key: "/configuracoes-de-propostas/customizar-documento",
              icon: <BsFileEarmarkFont />,
              label: (
                <Link href="/configuracoes-de-propostas/customizar-documento">
                  Customizar documento
                </Link>
              ),
              hidden: !canAccessAdministrativeArea,
            },
            ...(ENV.AMBIENT === "DEVELOPMENT"
              ? [
                  {
                    key: "/configuracoes-de-propostas/custos-complementares",
                    icon: <BsPlus />,
                    label: (
                      <Link href="/configuracoes-de-propostas/custos-complementares">
                        Custos complementares
                      </Link>
                    ),
                    hidden: !canAccessAdministrativeArea,
                  },
                ]
              : []),
            {
              key: "/configuracoes-de-propostas/integracao",
              icon: <TbBuildingStore />,
              label: (
                <Link href="/configuracoes-de-propostas/integracao">
                  Integração
                </Link>
              ),
              hidden: !canAccessAdministrativeArea,
            },
            {
              key: "/configuracoes-de-propostas/mensagem-compartilhada",
              icon: <BsChatSquareText />,
              label: (
                <Link href="/configuracoes-de-propostas/mensagem-compartilhada">
                  Mensagem compartilhada
                </Link>
              ),
            },
            {
              key: "/configuracoes-de-propostas/meus-produtos",
              icon: <BsBasket3 />,
              label: (
                <Link href="/configuracoes-de-propostas/meus-produtos">
                  Meus produtos
                </Link>
              ),
              hidden: !canAccessAdministrativeArea,
            },
            {
              key: "/configuracoes-de-propostas/precificacoes",
              icon: <BsCashCoin />,
              label: (
                <Link href="/configuracoes-de-propostas/precificacoes">
                  Precificações
                </Link>
              ),
              hidden: !canAccessAdministrativeArea,
            },
            {
              key: "/configuracoes-de-propostas/predefinicoes",
              icon: <BsPercent />,
              label: (
                <Link href="/configuracoes-de-propostas/predefinicoes">
                  Predefinições
                </Link>
              ),
              hidden: !canAccessAdministrativeArea,
            },
            {
              key: "/configuracoes-de-propostas/regras-de-expiracao",
              icon: <LuGitPullRequestClosed />,
              label: (
                <Link href="/configuracoes-de-propostas/regras-de-expiracao">
                  Regras de expiração
                </Link>
              ),
              hidden: !canAccessAdministrativeArea,
            },
            {
              key: "/configuracoes-de-propostas/regras-de-validacao",
              icon: <GrValidate />,
              label: (
                <Link href="/configuracoes-de-propostas/regras-de-validacao">
                  Regras de validação
                </Link>
              ),
              hidden: !canAccessAdministrativeArea,
            },
            {
              key: "/configuracoes-de-propostas/simulacao-de-pagamentos",
              icon: <MdOutlineCalculate />,
              label: (
                <Link href="/configuracoes-de-propostas/simulacao-de-pagamentos">
                  Simulação de pagamentos
                </Link>
              ),
              hidden: !canAccessAdministrativeArea,
            },
            {
              key: "/configuracoes-de-propostas/financiamento",
              icon: <BsCoin />,
              label: (
                <Link href="/configuracoes-de-propostas/financiamento">
                  Financiamento
                </Link>
              ),
              hidden: true,
            },
          ].filter((item) => !item.hidden),
        },
        {
          key: "/configuracoes-gerais",
          icon: <BiCog size={16} />,
          label: "Configurações gerais",
          children: [
            {
              key: "/configuracoes-gerais/campos-de-dados",
              icon: <BsHddStack />,
              label: (
                <Link href="/configuracoes-gerais/campos-de-dados">
                  Campos de dados
                </Link>
              ),
              hidden: !canAccessAdministrativeArea,
            },
            {
              key: "/configuracoes-gerais/empresa",
              icon: <BsBuilding />,
              label: <Link href="/configuracoes-gerais/empresa">Empresa</Link>,
              hidden: !canAccessAdministrativeArea,
            },
            {
              key: "/configuracoes-gerais/endereco-base",
              icon: <BsGeoAlt />,
              label: (
                <Link href="/configuracoes-gerais/endereco-base">
                  Endereço base
                </Link>
              ),
              hidden: !canAccessAdministrativeArea,
            },
            {
              key: "/configuracoes-gerais/gerenciamento-de-usuarios",
              icon: <BsFillPeopleFill />,
              label: (
                <Link href="/configuracoes-gerais/gerenciamento-de-usuarios">
                  Gerenciamento de usuarios
                </Link>
              ),
              hidden: !canAccessAdministrativeArea,
            },
            {
              key: "/configuracoes-gerais/logs",
              icon: <GoLog />,
              label: <Link href="/configuracoes-gerais/logs">Logs</Link>,
              hidden: !canAccessAdministrativeArea,
            },
            {
              key: "/configuracoes-gerais/unidades-de-negocio",
              icon: <BsColumnsGap />,
              label: (
                <Link href="/configuracoes-gerais/unidades-de-negocio">
                  Unidades de negócio
                </Link>
              ),
              hidden: !canAccessAdministrativeArea,
            },
          ].filter((item) => !item.hidden),
        },
      ] as MenuItemType,
    [canAccessAdministrativeArea],
  );

  const activeKeys = getActiveKeysByNextRouterPath(router.asPath).map(
    (route) => route.split("?")[0],
  );

  return (
    <>
      <style jsx>{styles}</style>
      <Menu
        data-cy="app-pages-menu"
        selectedKeys={activeKeys}
        defaultOpenKeys={activeKeys}
        items={MENU_ITEMS}
        style={{ height: "100%" }}
        inlineIndent={inlineIndent}
        mode={mode}
        className="menu"
        {...props}
      />
    </>
  );
}
