import * as React from "react";

import type { IIconProps } from "../@types/globals";
import { cn } from "../lib/utils";

const Funnel: React.FC<IIconProps> = ({ className, ...props }) => (
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    stroke="currentColor"
    className={cn("pointer-events-none w-6 h-6", className)}
    {...props}
  >
    <title>Funil</title>
    <path d="M22 2.75C22.4142 2.75 22.75 2.41421 22.75 2C22.75 1.58579 22.4142 1.25 22 1.25V2.75ZM2 2.75H22V1.25H2V2.75ZM7.5 10.25H4.5V11.75H7.5V10.25ZM3.75 9.5V2H2.25V9.5H3.75ZM8.25 2V9.5H9.75V2H8.25ZM4.5 10.25C4.08579 10.25 3.75 9.91421 3.75 9.5H2.25C2.25 10.7426 3.25736 11.75 4.5 11.75V10.25ZM7.5 11.75C8.74264 11.75 9.75 10.7426 9.75 9.5H8.25C8.25 9.91421 7.91421 10.25 7.5 10.25V11.75ZM9.75 19V2H8.25V19H9.75ZM14.25 2V19H15.75V2H14.25ZM12 21.25C11.2717 21.25 10.8009 21.2484 10.454 21.2018C10.1287 21.158 10.0268 21.0874 9.96967 21.0303L8.90901 22.091C9.29126 22.4732 9.76252 22.6223 10.2542 22.6884C10.7242 22.7516 11.3141 22.75 12 22.75V21.25ZM8.25 19C8.25 19.6859 8.24841 20.2758 8.31161 20.7458C8.3777 21.2375 8.52676 21.7087 8.90901 22.091L9.96967 21.0303C9.91258 20.9732 9.84197 20.8713 9.79823 20.546C9.75159 20.1991 9.75 19.7283 9.75 19H8.25ZM12 22.75C12.6859 22.75 13.2758 22.7516 13.7458 22.6884C14.2375 22.6223 14.7087 22.4732 15.091 22.091L14.0303 21.0303C13.9732 21.0874 13.8713 21.158 13.546 21.2018C13.1991 21.2484 12.7283 21.25 12 21.25V22.75ZM14.25 19C14.25 19.7283 14.2484 20.1991 14.2018 20.546C14.158 20.8713 14.0874 20.9732 14.0303 21.0303L15.091 22.091C15.4732 21.7087 15.6223 21.2375 15.6884 20.7458C15.7516 20.2758 15.75 19.6859 15.75 19H14.25ZM19.5 15.25H16.5V16.75H19.5V15.25ZM15.75 14.5V2H14.25V14.5H15.75ZM20.25 2V14.5H21.75V2H20.25ZM16.5 15.25C16.0858 15.25 15.75 14.9142 15.75 14.5H14.25C14.25 15.7426 15.2574 16.75 16.5 16.75V15.25ZM19.5 16.75C20.7426 16.75 21.75 15.7426 21.75 14.5H20.25C20.25 14.9142 19.9142 15.25 19.5 15.25V16.75Z" />
  </svg>
);

Funnel.displayName = "Funnel";

export { Funnel };
