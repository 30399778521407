export * from "./ActivityPeriodsSelect/interface";
import { ActivityPeriodsSelect } from "./ActivityPeriodsSelect";
export * from "./ActivityPrioritySelect/interface";
import { ActivityPrioritySelect } from "./ActivityPrioritySelect";
export * from "./AdminUserSelect/interface";
import { AdminUserSelect } from "./AdminUserSelect";
export * from "./AldoInverterBrandsSelect/interface";
import { AldoInverterBrandsSelect } from "./AldoInverterBrandsSelect";
export * from "./AldoModuleBrandsSelect/interface";
import { AldoModuleBrandsSelect } from "./AldoModuleBrandsSelect";
export * from "./AldoStructureSelect/interface";
import { AldoStructureSelect } from "./AldoStructureSelect";
export * from "./BaseAddressSelect";
import { BaseAddressSelect } from "./BaseAddressSelect";
export * from "./BrazilianStateByAcronymSelect";
import { BrazilianStateByAcronymSelect } from "./BrazilianStateByAcronymSelect";
export * from "./BrazilianStateSelect/interface";
import { BrazilianStateSelect } from "./BrazilianStateSelect";
export * from "./BusinessUnitSelect/interface";
import { BusinessUnitSelect } from "./BusinessUnitSelect";
export * from "./CitySelect/interface";
import { CitySelect } from "./CitySelect";
export * from "./ClientSearchSelect/interface";
import { ClientSearchSelect } from "./ClientSearchSelect";
export * from "./CompanyDistributorSelect/interface";
import { CompanyDistributorSelect } from "./CompanyDistributorSelect";
export * from "./CompanyStructureTypeSelect/interface";
import { CompanyStructureTypeSelect } from "./CompanyStructureTypeSelect";
export * from "./CompanyUserSelect/interface";
import { CompanyUserSelect } from "./CompanyUserSelect";
export * from "./ComplementaryItemCategorySelect/interface";
import { ComplementaryItemCategorySelect } from "./ComplementaryItemCategorySelect";
export * from "./ConcessionaireProfilesByVoltageSelect/interface";
import { ConcessionaireProfilesByVoltageSelect } from "./ConcessionaireProfilesByVoltageSelect";
export * from "./ConcessionaireSelect/interface";
import { ConcessionaireSelect } from "./ConcessionaireSelect";
export * from "./ConcessionairesWithAssociatedProfileByProvinceSelect/interface";
import { ConcessionairesWithAssociatedProfileByProvinceSelect } from "./ConcessionairesWithAssociatedProfileByProvinceSelect";
export * from "./ConcessionairesWithAssociatedProfileSelect/interface";
import { ConcessionairesWithAssociatedProfileSelect } from "./ConcessionairesWithAssociatedProfileSelect";
export * from "./ConsumerUnitCompensationSelect/interface";
import { ConsumerUnitCompensationSelect } from "./ConsumerUnitCompensationSelect";
export * from "./CostAggregatorSelect/interface";
import { CostAggregatorSelect } from "./CostAggregatorSelect";
export * from "./CurrencyTypeSelect/interface";
import { CurrencyTypeSelect } from "./CurrencyTypeSelect";
export * from "./CustomFieldBusinessUnitSelect";
import { CustomFieldBusinessUnitSelect } from "./CustomFieldBusinessUnitSelect";
export * from "./DealAutoCompleteSelect";
export * from "./DealClientSearchSelect/interface";
export * from "./DealCustomFieldGroupSelect/interface";
export * from "./DealCustomFieldTypeSelect/interface";
export * from "./ProposalTypeSelect";
export * from "./ReasonForLossSelect";
export * from "./EcoriInverterBrandsSelect";
export * from "./EcoriModuleBrandsSelect";
export * from "./EcoriStructureSelect";
export * from "./DatasheetSearchSelect";
import { DatasheetSearchSelect } from "./DatasheetSearchSelect";
import { DealAutoCompleteSelect } from "./DealAutoCompleteSelect";
import { DealClientSearchSelect } from "./DealClientSearchSelect";
import { DealCustomFieldGroupSelect } from "./DealCustomFieldGroupSelect";
import { DealCustomFieldTypeSelect } from "./DealCustomFieldTypeSelect";
import { EcoriInverterBrandsSelect } from "./EcoriInverterBrandsSelect";
import { EcoriModuleBrandsSelect } from "./EcoriModuleBrandsSelect";
import { EcoriStructureSelect } from "./EcoriStructureSelect";
export * from "./EdeltecInverterBrandsSelect";
import { EdeltecInverterBrandsSelect } from "./EdeltecInverterBrandsSelect";
export * from "./EdeltecModuleBrandsSelect";
import { EdeltecModuleBrandsSelect } from "./EdeltecModuleBrandsSelect";
export * from "./EdeltecProductTypesSelect";
import { EdeltecProductTypesSelect } from "./EdeltecProductTypesSelect";
export * from "./EdeltecStructureSelect";
import { EdeltecStructureSelect } from "./EdeltecStructureSelect";
export * from "./EquipmentRegionSelect/interface";
import { EquipmentRegionSelect } from "./EquipmentRegionSelect";
export * from "./FortlevInverterTypeSelectProps/interface";
import { FortlevInverterTypeSelect } from "./FortlevInverterTypeSelectProps";
export * from "./FortlevModuleSelect/interface";
import { FortlevModuleSelect } from "./FortlevModuleSelect";
export * from "./FortlevStructureSelect/interface";
import { FortlevStructureSelect } from "./FortlevStructureSelect";
export * from "./GuarantorOccupationEosFinancingSelect/interface";
export * from "./GridTypeSelect/interface";
import { GridTypeSelect } from "./GridTypeSelect";
import { GuarantorOccupationEosFinancingSelect } from "./GuarantorOccupationEosFinancingSelect";
export * from "./InverterTypeSelect/interface";
import { InverterTypeSelect } from "./InverterTypeSelect";
export * from "./IrradiationSourceSelect/interface";
import { IrradiationSourceSelect } from "./IrradiationSourceSelect";
export * from "./KitItemCategorySelect/interface";
import { KitItemCategorySelect } from "./KitItemCategorySelect";
export * from "./KomecoEquipmentModuleSelect/interface";
import { KomecoEquipmentModuleSelect } from "./KomecoEquipmentModuleSelect";
export * from "./KomecoEquipmentStructureTypeSelect/interface";
import { KomecoEquipmentStructureTypeSelect } from "./KomecoEquipmentStructureTypeSelect";
export * from "./LaborSuggestedSelect/interface";
import { LaborSuggestedSelect } from "./LaborSuggestedSelect";
export * from "./LogActionTypeSelect/interface";
import { LogActionTypeSelect } from "./LogActionTypeSelect";
export * from "./LogEntityTypeSelect/interface";
import { LogEntityTypeSelect } from "./LogEntityTypeSelect";
export * from "./LoggedUserBusinessUnitSelect/interface";
import { LoggedUserBusinessUnitSelect } from "./LoggedUserBusinessUnitSelect";
export * from "./ModuleCellTypeSelect/interface";
import { ModuleCellTypeSelect } from "./ModuleCellTypeSelect";
export * from "./ModulePhaseSelect/interface";
import { ModulePhaseSelect } from "./ModulePhaseSelect";
export * from "./OrderStatusSelect/interface";
import { OrderStatusSelect } from "./OrderStatusSelect";
export * from "./OrganizationSelect/interface";
import { OrganizationSelect } from "./OrganizationSelect";
export * from "./OrganizationToLinkPipedriveSelect";
import { OrganizationToLinkPipedriveSelect } from "./OrganizationToLinkPipedriveSelect";
export * from "./OutputVoltageSelect/interface";
import { OutputVoltageSelect } from "./OutputVoltageSelect";
export * from "./PerformanceProfilesSelect/interface";
import { PerformanceProfilesSelect } from "./PerformanceProfilesSelect";
export * from "./PersonSearchSelect/interface";
import { PersonSearchSelect } from "./PersonSearchSelect";
export * from "./PersonSelect/interface";
import { PersonSelect } from "./PersonSelect";
export * from "./PersonToLinkPipedriveSelect";
import { PersonToLinkPipedriveSelect } from "./PersonToLinkPipedriveSelect";
export * from "./PhaseSelect/interface";
import { PhaseSelect } from "./PhaseSelect";
export * from "./PipedriveActivitiesSelect/interface";
import { SelectActivities } from "./PipedriveActivitiesSelect";
export * from "./PipedriveClientSearchSelect/interface";
import { PipedriveClientSearchSelect } from "./PipedriveClientSearchSelect";
export * from "./PipedriveCustomFieldSelect/interface";
import { PipedriveCustomFieldSelect } from "./PipedriveCustomFieldSelect";
export * from "./PipedriveDealSearchSelect/interface";
import { PipedriveDealSearchSelect } from "./PipedriveDealSearchSelect";
export * from "./PipedriveLeadTypeSelect/interface";
import { PipedriveLeadTypeSelect } from "./PipedriveLeadTypeSelect";
export * from "./PipedrivePipelineDealsSelect/interface";
import { PipedrivePipelineDealsSelect } from "./PipedrivePipelineDealsSelect";
export * from "./PipedrivePipelineSelect/interface";
import { PipedrivePipelineSelect } from "./PipedrivePipelineSelect";
export * from "./PipelineDetailedSelect/interface";
import { PipelineDetailedSelect } from "./PipelineDetailedSelect";
export * from "./PipelineSelect/interface";
import { PipelineSelect } from "./PipelineSelect";
export * from "./PricingTypeOfProposalSelect/interface";
import { PricingTypeOfProposalSelect } from "./PricingTypeOfProposalSelect";
export * from "./PricingTypeSelect/interface";
import { PricingTypeSelect } from "./PricingTypeSelect";
export * from "./ProfileSelect/interface";
import { ProfileSelect } from "./ProfileSelect";
export * from "./ProjectTypeEosFinancingPersonSelect/interface";
import { ProjectTypeEosFinancingPersonSelect } from "./ProjectTypeEosFinancingPersonSelect";
export * from "./ProjectTypeSelect/interface";
import { ProjectTypeSelect } from "./ProjectTypeSelect";
export * from "./PropertySituationEosFinancingPersonSelect/interface";
import { PropertySituationEosFinancingPersonSelect } from "./PropertySituationEosFinancingPersonSelect";
export * from "./ProposalCustomFieldTypeSelect/interface";
import { ProposalCustomFieldTypeSelect } from "./ProposalCustomFieldTypeSelect";
export * from "./ProposalSectionsSelect/interface";
import { ProposalSectionsSelect } from "./ProposalSectionsSelect";
export * from "./ProposalStatusSelect/interface";
import { ProposalStatusSelect } from "./ProposalStatusSelect";
export * from "./ProposalStepSelect/interface";
import { ProposalStepSelect } from "./ProposalStepSelect";
export * from "./ProposalTypeFilterSelect/interface";
import { ProposalTypeFilterSelect } from "./ProposalTypeFilterSelect";
import { ProposalTypeSelect } from "./ProposalTypeSelect";
import { ReasonForLossSelect } from "./ReasonForLossSelect";
export * from "./SimultaneityProfilesSelect/interface";
import { SimultaneityProfilesSelect } from "./SimultaneityProfilesSelect";
export * from "./StructureGroupSelect/interface";
import { StructureGroupSelect } from "./StructureGroupSelect";
export * from "./TaxesAfterPricingSelect/interface";
import { TaxesAfterPricingSelect } from "./TaxesAfterPricingSelect";
export * from "./TaxTypeSelect/interface";
import { TaxTypeSelect } from "./TaxTypeSelect";
export * from "./UserGroupSelect/interface";
import { UserGroupSelect } from "./UserGroupSelect";
export * from "./UserSelect/interface";
import { UserSelect } from "./UserSelect";
export * from "./VoltageTypeSelect/interface";
import { VoltageTypeSelect } from "./VoltageTypeSelect";

export const Select = {
  ActivityPeriods: ActivityPeriodsSelect,
  ActivityPriority: ActivityPrioritySelect,
  AdminUser: AdminUserSelect,
  AldoInverterBrands: AldoInverterBrandsSelect,
  AldoModuleBrands: AldoModuleBrandsSelect,
  AldoStructure: AldoStructureSelect,
  BaseAddress: BaseAddressSelect,
  BrazilianStateByAcronym: BrazilianStateByAcronymSelect,
  BrazilianState: BrazilianStateSelect,
  BusinessUnit: BusinessUnitSelect,
  City: CitySelect,
  ClientSearch: ClientSearchSelect,
  CompanyDistributor: CompanyDistributorSelect,
  CompanyStructureType: CompanyStructureTypeSelect,
  CompanyUser: CompanyUserSelect,
  ComplementaryItemCategory: ComplementaryItemCategorySelect,
  ConcessionaireProfilesByVoltage: ConcessionaireProfilesByVoltageSelect,
  Concessionaire: ConcessionaireSelect,
  ConcessionairesWithAssociatedProfileByProvince:
    ConcessionairesWithAssociatedProfileByProvinceSelect,
  ConcessionairesWithAssociatedProfile:
    ConcessionairesWithAssociatedProfileSelect,
  ConsumerUnitCompensation: ConsumerUnitCompensationSelect,
  CostAggregator: CostAggregatorSelect,
  CurrencyType: CurrencyTypeSelect,
  CustomFieldBusinessUnit: CustomFieldBusinessUnitSelect,
  DealAutoComplete: DealAutoCompleteSelect,
  DealClientSearch: DealClientSearchSelect,
  DealCustomFieldGroup: DealCustomFieldGroupSelect,
  DealCustomFieldType: DealCustomFieldTypeSelect,
  EcoriInverterBrands: EcoriInverterBrandsSelect,
  EcoriModuleBrands: EcoriModuleBrandsSelect,
  EcoriStructure: EcoriStructureSelect,
  ProposalType: ProposalTypeSelect,
  ReasonForLoss: ReasonForLossSelect,
  DatasheetSearch: DatasheetSearchSelect,
  EdeltecInverterBrands: EdeltecInverterBrandsSelect,
  EdeltecModuleBrands: EdeltecModuleBrandsSelect,
  EdeltecProductTypes: EdeltecProductTypesSelect,
  EdeltecStructure: EdeltecStructureSelect,
  EquipmentRegion: EquipmentRegionSelect,
  FortlevModule: FortlevModuleSelect,
  FortlevStructure: FortlevStructureSelect,
  GridType: GridTypeSelect,
  InverterTypeSelect: InverterTypeSelect,
  FortlevInverterType: FortlevInverterTypeSelect,
  GuarantorOccupationEosFinancing: GuarantorOccupationEosFinancingSelect,
  IrradiationSource: IrradiationSourceSelect,
  KitItemCategory: KitItemCategorySelect,
  KomecoEquipmentModule: KomecoEquipmentModuleSelect,
  KomecoEquipmentStructureType: KomecoEquipmentStructureTypeSelect,
  LaborSuggested: LaborSuggestedSelect,
  LogActionType: LogActionTypeSelect,
  LogEntityType: LogEntityTypeSelect,
  LoggedUserBusinessUnit: LoggedUserBusinessUnitSelect,
  ModuleCellType: ModuleCellTypeSelect,
  ModulePhase: ModulePhaseSelect,
  OrderStatus: OrderStatusSelect,
  Organization: OrganizationSelect,
  OutputVoltage: OutputVoltageSelect,
  PerformanceProfiles: PerformanceProfilesSelect,
  PersonSearch: PersonSearchSelect,
  Person: PersonSelect,
  PersonToLinkPipedrive: PersonToLinkPipedriveSelect,
  Phase: PhaseSelect,
  PipedriveActivities: SelectActivities,
  PipedriveClientSearch: PipedriveClientSearchSelect,
  PipedriveField: PipedriveCustomFieldSelect,
  PipedriveDealSearch: PipedriveDealSearchSelect,
  PipedriveLeadType: PipedriveLeadTypeSelect,
  PipedrivePipeline: PipedrivePipelineSelect,
  PipelineDetailed: PipelineDetailedSelect,
  Pipeline: PipelineSelect,
  PipedrivePipelineDeals: PipedrivePipelineDealsSelect,
  PricingTypeOfProposal: PricingTypeOfProposalSelect,
  PricingType: PricingTypeSelect,
  Profile: ProfileSelect,
  PropertySituationEosFinancingPerson:
    PropertySituationEosFinancingPersonSelect,
  ProposalCustomFieldType: ProposalCustomFieldTypeSelect,
  ProposalSections: ProposalSectionsSelect,
  ProposalStatus: ProposalStatusSelect,
  ProposalStep: ProposalStepSelect,
  ProposalTypeFilter: ProposalTypeFilterSelect,
  SimultaneityProfiles: SimultaneityProfilesSelect,
  StructureGroup: StructureGroupSelect,
  TaxesAfterPricing: TaxesAfterPricingSelect,
  TaxType: TaxTypeSelect,
  UserGroup: UserGroupSelect,
  ProjectTypeEosFinancingPerson: ProjectTypeEosFinancingPersonSelect,
  ProjectType: ProjectTypeSelect,
  OrganizationToLinkPipedrive: OrganizationToLinkPipedriveSelect,
  VoltageType: VoltageTypeSelect,
  User: UserSelect,
};
