export * from "./CityAutoComplete/interface";
import { CityAutoComplete } from "./CityAutoComplete";
export * from "./EosBaseProposalAutoComplete/interface";
export * from "./ClientAutoComplete/interface";
import { ClientAutoComplete } from "./ClientAutoComplete";
export * from "./DealAutoComplete/interface";
import { DealAutoComplete } from "./DealAutoComplete";
import { EosBaseProposalAutoComplete } from "./EosBaseProposalAutoComplete";
export * from "./InverterManufacturerAutoComplete/interface";
import { InverterManufacturerAutoComplete } from "./InverterManufacturerAutoComplete";
export * from "./InverterPowerAutoComplete/interface";
import { InverterPowerAutoComplete } from "./InverterPowerAutoComplete";
export * from "./ModuleManufacturerAutoComplete/interface";
import { ModuleManufacturerAutoComplete } from "./ModuleManufacturerAutoComplete";
export * from "./ModulePowerAutoComplete/interface";
import { ModulePowerAutoComplete } from "./ModulePowerAutoComplete";
export * from "./OrganizationAutoComplete/interface";
import { OrganizationAutoComplete } from "./OrganizationAutoComplete";
export * from "./PersonAutoComplete/interface";
import { PersonAutoComplete } from "./PersonAutoComplete";
export * from "./PipedriveClientAutoComplete/interface";
import { PipedriveClientAutoComplete } from "./PipedriveClientAutoComplete";
export * from "./PipedriveDealAutoComplete/interface";
import { PipedriveDealAutoComplete } from "./PipedriveDealAutoComplete";
export * from "./ProductInverterAutoComplete/interface";
import { ProductAutoComplete } from "./ProductAutoComplete";
export * from "./ProductInverterAutoComplete/interface";
import { ProductInverterAutoComplete } from "./ProductInverterAutoComplete";
export * from "./ProductModuleAutoComplete/interface";
import { ProductModuleAutoComplete } from "./ProductModuleAutoComplete";
export * from "./SellerAutoComplete/interface";
import { SellerAutoComplete } from "./SellerAutoComplete";

export const AutoComplete = {
  City: CityAutoComplete,
  EosBaseProposal: EosBaseProposalAutoComplete,
  Client: ClientAutoComplete,
  Deal: DealAutoComplete,
  Person: PersonAutoComplete,
  PipedriveClient: PipedriveClientAutoComplete,
  PipedriveDeal: PipedriveDealAutoComplete,
  InverterManufacturer: InverterManufacturerAutoComplete,
  InverterPower: InverterPowerAutoComplete,
  ModuleManufacturer: ModuleManufacturerAutoComplete,
  ModulePower: ModulePowerAutoComplete,
  Organization: OrganizationAutoComplete,
  Product: ProductAutoComplete,
  ProductInverter: ProductInverterAutoComplete,
  ProductModule: ProductModuleAutoComplete,
  Seller: SellerAutoComplete,
};
