import styled from "@emotion/styled";
import { Card } from "antd";

export const StyledCard = styled(Card)`
  &.ant-card {
    background-color: var(--gray-50);
    height: 80px;

    .ant-card-body {
      padding: 16px;
      height: 100%;
    }
  }

  // DARK THEME
  html.dark & {
    background-color: #141414;
  }
`;

export const PersonIcon = styled.div`
  height: 28px;
  width: 28px;
  min-width: 28px;
  border-radius: 9999px;
  background-color: var(--gray-200);
  color: var(--gray-500);
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
