import { ICopyBusinnessUnitModalFormProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function CopyBusinessUnitModalForm({
  isOpen = false,
  onClose = () => null,
  title = "Nova unidade baseada em uma existente",
  maxWidth = 800,
}: ICopyBusinnessUnitModalFormProps) {
  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title={title}
      footer={null}
      destroyOnClose
      width="90vw"
      style={{
        maxWidth,
      }}
    >
      <Form.CopyBusinessUnit onSuccess={onClose} />
    </Modal>
  );
}
