import styled from "@emotion/styled";
import { SolarzButton } from "~solarzui/SolarzButton";

export const TableWrapper = styled.div`
  .cursor-pointer {
    cursor: pointer;
  }
`;

export const EditButton = styled(SolarzButton)`
  color: var(--gold-500);
  outline-color: var(--gold-300) !important;

  &:not(:disabled):hover {
    color: var(--gold-600) !important;
  }
`;

export const DeleteButton = styled(SolarzButton)`
  color: var(--red-500);
  outline-color: var(--red-300) !important;

  &:not(:disabled):hover {
    color: var(--red-600) !important;
  }
`;

export const OrganizationIcon = styled.div`
  height: 28px;
  width: 28px;
  min-width: 28px;
  border-radius: 9999px;
  background-color: var(--gray-200);
  color: var(--gray-500);
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  // DARK THEME
  html.dark & {
    color: var(--gray-200);
    background-color: var(--gray-800);
  }
`;
