import { useState } from "react";
import {
  AiOutlineMinusCircle,
  AiOutlinePlus,
  AiOutlineQuestionCircle,
} from "react-icons/ai";
import { FiAlertTriangle } from "react-icons/fi";

import type {
  IConsumerUnitsFormProps,
  IConsumerUnitsFormValues,
  IConsumerUnitsMonthModalState,
  IConcessionaireProfileModalState,
  IConsumerUnitsType,
} from "./interface";
import styles from "./styles";

import { CustomInput } from "~components/CustomInput";
import { Modal } from "~components/Modal";
import { Select } from "~components/Select";
import { useFetchUpdateConsumptionUnit } from "~hooks/api";
import { IConsumptionUnitFormSchema } from "~types/schemas";
import {
  Form,
  Button,
  Divider,
  Row,
  Col,
  Tooltip,
  Button as AntdButton,
  Typography,
  Radio,
  Flex,
} from "antd";

// CONSTS
const INITIAL_CONSUMER_UNITS: IConsumerUnitsType = {
  code: "",
  extra: "",
  name: "",
  voltageType: "LOW",
  allocationPercentage: 0,
  mediumVoltage: undefined,
  lowVoltage: undefined,
  taxType: "GREEN",
  powerPhase: "Monofásico",
};

const INITIAL_VALUES: IConsumerUnitsFormValues = {
  compensationType: "FILA_PRIORIDADE",
  consumerUnits: [INITIAL_CONSUMER_UNITS],
  allocationPercentage: undefined,
  considerConsumptionUnits: true,
};

function validateIdentifier(value = "") {
  try {
    // replace all characters with spaces and/or especial characters except underline
    const parsedValue = value.replace(/[^a-zA-Z0-9_]/g, "");

    return value === parsedValue
      ? Promise.resolve()
      : Promise.reject("Formato é inválido!");
  } catch (error: any) {
    return Promise.reject(`Error ao validar: ${error?.message}`);
  }
}

export function ConsumerUnitsForm({
  customId = 0,
  concessionaireId = 0,
  initialValues = INITIAL_VALUES,
  onSuccess = () => undefined,
  onError = () => undefined,
  onCancel = () => undefined,
  consumerListMaxHeight = 512,
}: IConsumerUnitsFormProps) {
  const [form] = Form.useForm<IConsumerUnitsFormValues>();

  // STATES
  const [monthlyModalState, setMonthlyModalState] =
    useState<IConsumerUnitsMonthModalState>();

  const [concessionaireProfileModalState, setConcessionaireProfileModalState] =
    useState<IConcessionaireProfileModalState>();

  // FIELDS
  const compensationType = Form.useWatch("compensationType", form);
  const consumerUnits = Form.useWatch("consumerUnits", form);

  const {
    mutate: updateConsumptionUnit,
    isLoading: isUpdatingConsumptionUnit,
  } = useFetchUpdateConsumptionUnit({
    onSuccess: (consumerUnits) => {
      onSuccess({
        consumerUnits,
        compensationType,
        considerConsumptionUnits: form.getFieldValue(
          "considerConsumptionUnits",
        ),
        allocationPercentage: form.getFieldValue("allocationPercentage"),
      });
    },
    onError,
  });

  function handleSubmit(formData: IConsumerUnitsFormValues) {
    updateConsumptionUnit({
      customId,
      compensationType: formData.compensationType,
      allocationPercentage: formData.allocationPercentage,
      consumerUnits: formData.consumerUnits.map((unit) => {
        return {
          allocationPercentage: unit.allocationPercentage,
          code: unit.code,
          extra: unit.extra,
          mediumVoltage:
            unit.voltageType === "MEDIUM" ? unit.mediumVoltage : undefined,
          lowVoltage: unit.voltageType === "LOW" ? unit.lowVoltage : undefined,
          name: unit.name,
          powerPhase: unit.powerPhase,
        } as IConsumptionUnitFormSchema;
      }),
    });
  }

  function handleResetForm() {
    form.setFieldsValue({ ...INITIAL_VALUES, consumerUnits: [] });
  }

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={() => handleSubmit(form.getFieldsValue(true))}
        initialValues={{
          ...INITIAL_VALUES,
          ...initialValues,
        }}
        scrollToFirstError
        preserve
      >
        <style jsx>{styles}</style>

        <Form.Item
          name="compensationType"
          label="Rateio"
          rules={[{ required: true }]}
          initialValue={initialValues.compensationType}
        >
          <Select.ConsumerUnitCompensation allowClear={false} />
        </Form.Item>

        {compensationType === "RATEIO" && (
          <Form.Item
            name="allocationPercentage"
            label="Percentual da usina"
            rules={[{ required: true }]}
            initialValue={INITIAL_VALUES.allocationPercentage}
          >
            <CustomInput.UnitMeasurement
              precision="2"
              unitMeasurement="%"
              placeholder="Ex.: 77%"
              min={0}
              max={100}
              data-cy="input-allocation-percentage"
            />
          </Form.Item>
        )}

        <Form.Item
          label="Considerar consumo das unidades no dimensionamento da usina"
          name="considerConsumptionUnits"
          initialValue={!!initialValues.considerConsumptionUnits}
        >
          <Radio.Group
            defaultValue={true}
            options={[
              {
                label: "Sim",
                value: true,
              },
              {
                label: "Não",
                value: false,
              },
            ]}
          />
        </Form.Item>

        <Form.List name="consumerUnits">
          {(fields, { add, remove }) => (
            <>
              <Flex
                vertical
                wrap="nowrap"
                style={{
                  width: "100%",
                  maxHeight: consumerListMaxHeight,
                  overflowY: "auto",
                }}
              >
                {fields.map(({ key, name, ...restField }, index) => {
                  const voltageType =
                    consumerUnits?.[name]?.voltageType ??
                    INITIAL_CONSUMER_UNITS.voltageType;

                  const taxType =
                    consumerUnits?.[name]?.taxType ??
                    INITIAL_CONSUMER_UNITS.taxType;

                  const isMonthToMonthActive = !!form.getFieldValue([
                    "consumerUnits",
                    name,
                    "lowVoltage",
                    "monthlyValues",
                  ]);

                  const isMonthToMonthPActive = !!form.getFieldValue([
                    "consumerUnits",
                    name,
                    "mediumVoltage",
                    "monthlyValuesP",
                  ]);

                  const isMonthToMonthFPActive = !!form.getFieldValue([
                    "consumerUnits",
                    name,
                    "mediumVoltage",
                    "monthlyValuesFP",
                  ]);

                  return (
                    <div key={key} style={{ width: "100%" }}>
                      <Divider
                        orientation="left"
                        style={{ fontSize: 12, color: "var(--primary-500)" }}
                      >
                        Unidade consumidora {index + 1}
                      </Divider>

                      {/* HIDDEN FIELDS */}
                      <Form.Item name="id" hidden>
                        <CustomInput.Number disabled readOnly />
                      </Form.Item>

                      {/* nome, código, % rateio, baixa/média */}
                      <Row
                        align="middle"
                        wrap
                        gutter={[16, 0]}
                        style={{
                          width: "100%",
                        }}
                      >
                        <Col xs={12} sm={12} md={6}>
                          <Form.Item
                            {...restField}
                            name={[name, "name"]}
                            label="Nome da unidade"
                            rules={[{ required: true }]}
                          >
                            <CustomInput.Text
                              data-cy="input-unit-name"
                              placeholder="Ex.: Unidade consumidora SolarZ"
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={12} sm={12} md={6}>
                          <Form.Item
                            {...restField}
                            name={[name, "code"]}
                            label="Código"
                            rules={[
                              {
                                validator: (_, value?: string | null) =>
                                  validateIdentifier(value ?? ""),
                              },
                              {
                                required: false,
                              },
                            ]}
                            initialValue=""
                          >
                            <CustomInput.Text
                              data-cy="input-code"
                              placeholder="Ex.: A1B2C345"
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={12} sm={12} md={6}>
                          <Form.Item
                            {...restField}
                            name={[name, "powerPhase"]}
                            label="Fase da rede do cliente"
                            rules={[{ required: true }]}
                            initialValue={INITIAL_CONSUMER_UNITS.powerPhase}
                          >
                            <Select.Phase />
                          </Form.Item>
                        </Col>

                        <Col xs={10} sm={11} md={5}>
                          <Form.Item
                            {...restField}
                            name={[name, "voltageType"]}
                            label="Tipo de tensão"
                            rules={[{ required: true }]}
                            initialValue={
                              INITIAL_VALUES.consumerUnits[0].voltageType
                            }
                          >
                            <Select.VoltageType />
                          </Form.Item>
                        </Col>

                        <Col xs={1}>
                          <Flex justify="center" align="center">
                            <Button
                              type="text"
                              size="small"
                              shape="circle"
                              style={{ lineHeight: 0 }}
                              onClick={() => remove(name)}
                              disabled={fields.length <= 1}
                            >
                              <AiOutlineMinusCircle size={16} />
                            </Button>
                          </Flex>
                        </Col>
                      </Row>

                      {voltageType === "LOW" && (
                        <Row
                          align="top"
                          wrap={true}
                          gutter={[16, 0]}
                          style={{
                            width: "100%",
                          }}
                        >
                          {compensationType === "RATEIO" && (
                            <Col xs={24} sm={24} md={4}>
                              <Form.Item
                                {...restField}
                                name={[name, "allocationPercentage"]}
                                label="% Rateio"
                                rules={[{ required: true }]}
                              >
                                <CustomInput.UnitMeasurement
                                  precision="2"
                                  unitMeasurement="%"
                                  placeholder="Ex.: 4,32%"
                                  min={0}
                                  data-cy="input-percentual-rateio"
                                />
                              </Form.Item>
                            </Col>
                          )}

                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              {...restField}
                              name={[name, "lowVoltage", "averageConsumption"]}
                              label={
                                <Flex
                                  align="center"
                                  style={{ width: "100%" }}
                                  gap={12}
                                >
                                  <Flex gap={0} align="center">
                                    <span style={{ marginRight: "0.2rem" }}>
                                      Consumo médio mensal
                                    </span>
                                    <Tooltip
                                      title="Ao alterar esse campo diretamente os valores
                                  mês-a-mês serão limpos"
                                    >
                                      <AiOutlineQuestionCircle
                                        size={16}
                                        color="var(--gray-500)"
                                      />
                                    </Tooltip>
                                  </Flex>

                                  <AntdButton
                                    type="link"
                                    style={{
                                      padding: 0,
                                      height: "min-content",
                                    }}
                                    onClick={() =>
                                      setMonthlyModalState({
                                        name,
                                        subFieldName: "lowVoltage",
                                        monthlyFormKey: "monthlyValues",
                                        averageFormKey: "averageConsumption",
                                      })
                                    }
                                  >
                                    Mês a mês
                                  </AntdButton>
                                </Flex>
                              }
                              rules={[
                                {
                                  required: true,
                                  message: "Por favor, insira Consumo médio",
                                },
                              ]}
                              extra={
                                isMonthToMonthActive ? (
                                  <Flex align="center">
                                    <Tooltip title="Os valores foram inseridos mês-a-mês. ATENÇÃO: ao alterar esse campo os valores mês-a-mês serão perdidos.">
                                      <Flex
                                        align="center"
                                        style={{
                                          cursor: "help",
                                        }}
                                      >
                                        <FiAlertTriangle
                                          size={14}
                                          style={{ color: "var(--red-500)" }}
                                        />
                                        <span
                                          style={{
                                            color: "var(--red-500)",
                                            marginLeft: "0.25rem",
                                          }}
                                        >
                                          Inserido mês-a-mês
                                        </span>
                                      </Flex>
                                    </Tooltip>
                                  </Flex>
                                ) : undefined
                              }
                            >
                              <CustomInput.UnitMeasurement
                                min={0}
                                precision="2"
                                unitMeasurement="KWh"
                                placeholder="Ex.: 52,00"
                                style={{ width: "100%" }}
                                data-cy="input-average-consumption"
                                onChange={() => {
                                  form.setFieldValue(
                                    [
                                      "consumerUnits",
                                      name,
                                      "lowVoltage",
                                      "monthlyValues",
                                    ],
                                    undefined,
                                  );
                                }}
                              />
                            </Form.Item>
                          </Col>

                          <Col
                            xs={8}
                            sm={8}
                            md={compensationType === "RATEIO" ? 4 : 6}
                          >
                            <Form.Item
                              {...restField}
                              name={[name, "lowVoltage", "te"]}
                              label="Tarifa TE"
                              rules={[{ required: true }]}
                              extra={
                                concessionaireId && (
                                  <AntdButton
                                    type="link"
                                    size="small"
                                    style={{ margin: 0, padding: 0 }}
                                    onClick={() =>
                                      setConcessionaireProfileModalState({
                                        name,
                                        taxType: undefined,
                                        voltageType,
                                      })
                                    }
                                    data-cy="button-search-tariff"
                                  >
                                    Buscar Tarifas?
                                  </AntdButton>
                                )
                              }
                            >
                              <CustomInput.Money
                                unit="R$/kWh"
                                min={0}
                                style={{ width: "100%" }}
                                precision={6}
                                placeholder="Ex.: 1,000000"
                              />
                            </Form.Item>
                          </Col>

                          <Col
                            xs={8}
                            sm={8}
                            md={compensationType === "RATEIO" ? 4 : 6}
                          >
                            <Form.Item
                              {...restField}
                              name={[name, "lowVoltage", "tusd"]}
                              label="Tarifa TUSD"
                              rules={[{ required: true }]}
                            >
                              <CustomInput.Money
                                unit="R$/kWh"
                                min={0}
                                style={{ width: "100%" }}
                                precision={6}
                                placeholder="Ex.: 1,000000"
                              />
                            </Form.Item>
                          </Col>

                          <Col
                            xs={8}
                            sm={8}
                            md={compensationType === "RATEIO" ? 4 : 6}
                          >
                            <Form.Item
                              {...restField}
                              name={[name, "lowVoltage", "wireBTax"]}
                              label="Tarifa de fio B"
                              rules={[{ required: true }]}
                            >
                              <CustomInput.Money
                                unit="R$/kWh"
                                min={0}
                                style={{ width: "100%" }}
                                precision={6}
                                placeholder="Ex.: 1,000000"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      )}

                      {voltageType === "MEDIUM" && (
                        <>
                          <Row
                            align="top"
                            wrap={true}
                            gutter={[16, 0]}
                            style={{
                              width: "100%",
                            }}
                          >
                            {compensationType === "RATEIO" && (
                              <Col xs={12}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "allocationPercentage"]}
                                  label="% Rateio"
                                  rules={[{ required: true }]}
                                >
                                  <CustomInput.UnitMeasurement
                                    precision="2"
                                    unitMeasurement="%"
                                    placeholder="Ex.: 4,32%"
                                    min={0}
                                  />
                                </Form.Item>
                              </Col>
                            )}

                            <Col xs={compensationType === "RATEIO" ? 12 : 24}>
                              <Form.Item
                                label="Tipo de tarifa"
                                name={[name, "taxType"]}
                                style={{ width: "100%" }}
                                initialValue={INITIAL_CONSUMER_UNITS.taxType}
                              >
                                <Select.TaxType />
                              </Form.Item>
                            </Col>
                          </Row>

                          {/* PONTA */}
                          <Row
                            align="top"
                            wrap={true}
                            gutter={[16, 0]}
                            style={{
                              width: "100%",
                            }}
                          >
                            <Divider>
                              <Typography.Text
                                style={{
                                  color: "var(--primary-500)",
                                  fontWeight: 700,
                                }}
                              >
                                Ponta
                              </Typography.Text>
                            </Divider>

                            <Col xs={12} md={9}>
                              <Form.Item
                                {...restField}
                                name={[
                                  name,
                                  "mediumVoltage",
                                  "averageConsumptionP",
                                ]}
                                label={
                                  <Flex
                                    align="center"
                                    style={{ width: "100%" }}
                                    gap={12}
                                  >
                                    <Flex gap={0} align="center">
                                      <span style={{ marginRight: "0.2rem" }}>
                                        Consumo médio mensal
                                      </span>
                                      <Tooltip
                                        title="Ao alterar esse campo diretamente os valores
                                    mês-a-mês serão limpos"
                                      >
                                        <AiOutlineQuestionCircle
                                          size={16}
                                          color="var(--gray-500)"
                                        />
                                      </Tooltip>
                                    </Flex>

                                    <AntdButton
                                      type="link"
                                      style={{
                                        padding: 0,
                                        height: "min-content",
                                      }}
                                      onClick={() =>
                                        setMonthlyModalState({
                                          name: name,
                                          subFieldName: "mediumVoltage",
                                          monthlyFormKey: "monthlyValuesP",
                                          averageFormKey: "averageConsumptionP",
                                        })
                                      }
                                    >
                                      Mês a mês
                                    </AntdButton>
                                  </Flex>
                                }
                                rules={[
                                  {
                                    required: true,
                                    message: "Por favor, insira Consumo médio",
                                  },
                                ]}
                                extra={
                                  isMonthToMonthPActive ? (
                                    <Flex align="center">
                                      <Tooltip title="Os valores foram inseridos mês-a-mês. ATENÇÃO: ao alterar esse campo os valores mês-a-mês serão perdidos.">
                                        <Flex
                                          align="center"
                                          style={{
                                            cursor: "help",
                                          }}
                                        >
                                          <FiAlertTriangle
                                            size={14}
                                            style={{ color: "var(--red-500)" }}
                                          />
                                          <span
                                            style={{
                                              color: "var(--red-500)",
                                              marginLeft: "0.25rem",
                                            }}
                                          >
                                            Inserido mês-a-mês
                                          </span>
                                        </Flex>
                                      </Tooltip>
                                    </Flex>
                                  ) : undefined
                                }
                              >
                                <CustomInput.UnitMeasurement
                                  min={0}
                                  precision="2"
                                  unitMeasurement="KWh"
                                  placeholder="Ex.: 52,00"
                                  style={{ width: "100%" }}
                                  data-cy="input-average-consumption-p"
                                  onChange={() => {
                                    form.setFieldValue(
                                      [
                                        "consumerUnits",
                                        name,
                                        "mediumVoltage",
                                        "monthlyValuesP",
                                      ],
                                      undefined,
                                    );
                                  }}
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={12} md={5}>
                              <Form.Item
                                {...restField}
                                name={[name, "mediumVoltage", "teP"]}
                                label="Tarifa TE"
                                rules={[{ required: true }]}
                                extra={
                                  concessionaireId && (
                                    <AntdButton
                                      type="link"
                                      size="small"
                                      style={{ margin: 0, padding: 0 }}
                                      onClick={() =>
                                        setConcessionaireProfileModalState({
                                          name,
                                          voltageType,
                                          taxType,
                                        })
                                      }
                                      data-cy="button-search-tariff"
                                    >
                                      Buscar Tarifas?
                                    </AntdButton>
                                  )
                                }
                              >
                                <CustomInput.Money
                                  unit="R$/kWh"
                                  min={0}
                                  style={{ width: "100%" }}
                                  precision={6}
                                  placeholder="Ex.: 1,000000"
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={12} md={5}>
                              <Form.Item
                                {...restField}
                                name={[name, "mediumVoltage", "tusdP"]}
                                label="Tarifa TUSD"
                                rules={[{ required: true }]}
                              >
                                <CustomInput.Money
                                  unit="R$/kWh"
                                  min={0}
                                  style={{ width: "100%" }}
                                  precision={6}
                                  placeholder="Ex.: 1,000000"
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={12} md={5}>
                              <Form.Item
                                {...restField}
                                name={[name, "mediumVoltage", "wireBTaxP"]}
                                label="Tarifa de fio B"
                                rules={[{ required: true }]}
                              >
                                <CustomInput.Money
                                  unit="R$/kWh"
                                  min={0}
                                  style={{ width: "100%" }}
                                  precision={6}
                                  placeholder="Ex.: 1,000000"
                                />
                              </Form.Item>
                            </Col>

                            {taxType === "BLUE" && (
                              <>
                                <Col xs={12}>
                                  <Form.Item
                                    label="Demanda contratada"
                                    style={{ width: "100%" }}
                                    name={[
                                      name,
                                      "mediumVoltage",
                                      "contractedDemandP",
                                    ]}
                                    rules={[{ required: true }]}
                                  >
                                    <CustomInput.UnitMeasurement
                                      precision="2"
                                      unitMeasurement="KW"
                                      min={0}
                                      style={{ width: "100%" }}
                                      placeholder="1,33"
                                      data-cy="contracted-demand-p"
                                    />
                                  </Form.Item>
                                </Col>

                                <Col xs={12}>
                                  <Form.Item
                                    label="Tarifa de Demanda"
                                    style={{ width: "100%" }}
                                    name={[
                                      name,
                                      "mediumVoltage",
                                      "contractedDemandTaxP",
                                    ]}
                                    rules={[{ required: true }]}
                                  >
                                    <CustomInput.Money
                                      precision={6}
                                      placeholder="Ex.: 123,000000"
                                      min={0}
                                      unit="R$/kW"
                                    />
                                  </Form.Item>
                                </Col>
                              </>
                            )}
                          </Row>

                          {/* FORA PONTA */}
                          <Row
                            align="top"
                            wrap={true}
                            gutter={[16, 0]}
                            style={{
                              width: "100%",
                            }}
                          >
                            <Divider>
                              <Typography.Text
                                style={{
                                  color: "var(--primary-500)",
                                  fontWeight: 700,
                                }}
                              >
                                Fora ponta
                              </Typography.Text>
                            </Divider>

                            <Col xs={12} md={9}>
                              <Form.Item
                                {...restField}
                                name={[
                                  name,
                                  "mediumVoltage",
                                  "averageConsumptionFP",
                                ]}
                                label={
                                  <Flex
                                    align="center"
                                    style={{ width: "100%" }}
                                    gap={12}
                                  >
                                    <Flex gap={0} align="center">
                                      <span style={{ marginRight: "0.2rem" }}>
                                        Consumo médio mensal
                                      </span>
                                      <Tooltip
                                        title="Ao alterar esse campo diretamente os valores
                                    mês-a-mês serão limpos"
                                      >
                                        <AiOutlineQuestionCircle
                                          size={16}
                                          color="var(--gray-500)"
                                        />
                                      </Tooltip>
                                    </Flex>

                                    <AntdButton
                                      type="link"
                                      style={{
                                        padding: 0,
                                        height: "min-content",
                                      }}
                                      onClick={() =>
                                        setMonthlyModalState({
                                          name: name,
                                          subFieldName: "mediumVoltage",
                                          monthlyFormKey: "monthlyValuesFP",
                                          averageFormKey:
                                            "averageConsumptionFP",
                                        })
                                      }
                                    >
                                      Mês a mês
                                    </AntdButton>
                                  </Flex>
                                }
                                rules={[
                                  {
                                    required: true,
                                    message: "Por favor, insira Consumo médio",
                                  },
                                ]}
                                extra={
                                  isMonthToMonthFPActive ? (
                                    <Flex align="center">
                                      <Tooltip title="Os valores foram inseridos mês-a-mês. ATENÇÃO: ao alterar esse campo os valores mês-a-mês serão perdidos.">
                                        <Flex
                                          align="center"
                                          style={{
                                            cursor: "help",
                                          }}
                                        >
                                          <FiAlertTriangle
                                            size={14}
                                            style={{ color: "var(--red-500)" }}
                                          />
                                          <span
                                            style={{
                                              color: "var(--red-500)",
                                              marginLeft: "0.25rem",
                                            }}
                                          >
                                            Inserido mês-a-mês
                                          </span>
                                        </Flex>
                                      </Tooltip>
                                    </Flex>
                                  ) : undefined
                                }
                              >
                                <CustomInput.UnitMeasurement
                                  min={0}
                                  precision="2"
                                  unitMeasurement="KWh"
                                  placeholder="Ex.: 52,00"
                                  style={{ width: "100%" }}
                                  data-cy="input-average-consumption-fp"
                                  onChange={() => {
                                    form.setFieldValue(
                                      [
                                        "consumerUnits",
                                        name,
                                        "mediumVoltage",
                                        "monthlyValuesFP",
                                      ],
                                      undefined,
                                    );
                                  }}
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={12} md={5}>
                              <Form.Item
                                {...restField}
                                name={[name, "mediumVoltage", "teFP"]}
                                label="Tarifa TE"
                                rules={[{ required: true }]}
                              >
                                <CustomInput.Money
                                  unit="R$/kWh"
                                  min={0}
                                  style={{ width: "100%" }}
                                  precision={6}
                                  placeholder="Ex.: 1,000000"
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={12} md={5}>
                              <Form.Item
                                {...restField}
                                name={[name, "mediumVoltage", "tusdFP"]}
                                label="Tarifa TUSD"
                                rules={[{ required: true }]}
                              >
                                <CustomInput.Money
                                  unit="R$/kWh"
                                  min={0}
                                  style={{ width: "100%" }}
                                  precision={6}
                                  placeholder="Ex.: 1,000000"
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={12} md={5}>
                              <Form.Item
                                {...restField}
                                name={[name, "mediumVoltage", "wireBTaxFP"]}
                                label="Tarifa de fio B"
                                rules={[{ required: true }]}
                              >
                                <CustomInput.Money
                                  unit="R$/kWh"
                                  min={0}
                                  style={{ width: "100%" }}
                                  precision={6}
                                  placeholder="Ex.: 1,000000"
                                />
                              </Form.Item>
                            </Col>

                            {taxType === "BLUE" && (
                              <>
                                <Col xs={12}>
                                  <Form.Item
                                    label="Demanda contratada"
                                    style={{ width: "100%" }}
                                    name={[
                                      name,
                                      "mediumVoltage",
                                      "contractedDemandFP",
                                    ]}
                                    rules={[{ required: true }]}
                                  >
                                    <CustomInput.UnitMeasurement
                                      precision="2"
                                      unitMeasurement="KW"
                                      min={0}
                                      style={{ width: "100%" }}
                                      placeholder="1,33"
                                      data-cy="input-contracted-demand-fp"
                                    />
                                  </Form.Item>
                                </Col>

                                <Col xs={12}>
                                  <Form.Item
                                    label="Tarifa de Demanda"
                                    style={{ width: "100%" }}
                                    name={[
                                      name,
                                      "mediumVoltage",
                                      "contractedDemandTaxFP",
                                    ]}
                                    rules={[{ required: true }]}
                                  >
                                    <CustomInput.Money
                                      precision={6}
                                      placeholder="Ex.: 123,000000"
                                      min={0}
                                      unit="R$/kW"
                                    />
                                  </Form.Item>
                                </Col>
                              </>
                            )}
                          </Row>

                          {/* OUTRAS INFORMAÇÕES */}
                          <Row
                            align="top"
                            wrap={true}
                            gutter={[16, 0]}
                            style={{
                              width: "100%",
                            }}
                          >
                            <Divider>
                              <Typography.Text
                                style={{
                                  color: "var(--primary-500)",
                                  fontWeight: 700,
                                }}
                              >
                                Outras informações
                              </Typography.Text>
                            </Divider>

                            {taxType === "GREEN" && (
                              <>
                                <Col xs={7} md={8}>
                                  <Form.Item
                                    label="Demanda contratada"
                                    style={{ width: "100%" }}
                                    name={[
                                      name,
                                      "mediumVoltage",
                                      "contractedDemand",
                                    ]}
                                    rules={[{ required: true }]}
                                  >
                                    <CustomInput.UnitMeasurement
                                      precision="2"
                                      unitMeasurement="KW"
                                      min={0}
                                      style={{ width: "100%" }}
                                      placeholder="1,33"
                                    />
                                  </Form.Item>
                                </Col>

                                <Col xs={10} md={8}>
                                  <Form.Item
                                    label="Tarifa de demanda contratada"
                                    style={{ width: "100%" }}
                                    name={[
                                      name,
                                      "mediumVoltage",
                                      "contractedDemandTax",
                                    ]}
                                    rules={[{ required: true }]}
                                  >
                                    <CustomInput.Money
                                      precision={6}
                                      placeholder="Ex.: 123,000000"
                                      min={0}
                                      unit="R$/kW"
                                    />
                                  </Form.Item>
                                </Col>
                              </>
                            )}

                            {/* {taxType === "BLUE" && (
                              <Col xs={12}>
                                <Form.Item
                                  label="Tusd G"
                                  style={{ width: "100%" }}
                                  name={[
                                    name,
                                    "mediumVoltage",
                                    "contractedDemandTaxTusdG",
                                  ]}
                                  rules={[{ required: true }]}
                                >
                                  <CustomInput.Money
                                    precision={6}
                                    placeholder="Ex.: 123,000000"
                                    min={0}
                                    unit="R$/kW"
                                  />
                                </Form.Item>
                              </Col>
                            )} */}

                            <Col xs={7} md={taxType === "BLUE" ? 24 : 8}>
                              <Form.Item
                                label="Média energia reativa"
                                name={[
                                  name,
                                  "mediumVoltage",
                                  "reactivePowerAverage",
                                ]}
                                rules={[{ required: false }]}
                                style={{ width: "100%" }}
                              >
                                <CustomInput.Money
                                  precision={6}
                                  placeholder="Ex.: 1,000000"
                                  min={0}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </>
                      )}

                      <Row
                        align="top"
                        wrap={false}
                        justify="center"
                        gutter={[16, 0]}
                        style={{
                          width: "100%",
                        }}
                      >
                        <Col xs={24}>
                          <Form.Item
                            {...restField}
                            name={[name, "extra"]}
                            label="Extra"
                            tooltip="Adicione qualquer informação extra, comumente utilizado para adicionar o endereço."
                          >
                            <CustomInput.TextArea
                              placeholder="Adicionar qualquer informação extra, como endereço por exemplo."
                              style={{ maxHeight: 160 }}
                              data-cy="textarea-extra-field"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  );
                })}
              </Flex>

              <Button
                block
                type="dashed"
                className="addRowButton"
                onClick={() =>
                  add({
                    ...INITIAL_CONSUMER_UNITS,
                  } as IConsumerUnitsType)
                }
                data-cy="button-add-consumer-unit"
              >
                <Flex align="center" justify="center" gap={8}>
                  <AiOutlinePlus size={16} />
                  Adicionar unidade consumidora
                </Flex>
              </Button>
            </>
          )}
        </Form.List>

        <Flex gap={12} justify="end" style={{ marginTop: "1.5rem" }}>
          <Form.Item style={{ margin: 0, padding: 0 }}>
            <Button type="link" size="small" onClick={handleResetForm}>
              Limpar
            </Button>
          </Form.Item>
          <Form.Item style={{ margin: 0 }}>
            <Button onClick={onCancel}>Cancelar</Button>
          </Form.Item>
          <Form.Item style={{ margin: 0 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={isUpdatingConsumptionUnit}
              data-cy="button-save"
            >
              Salvar
            </Button>
          </Form.Item>
        </Flex>
      </Form>

      <Modal.MonthlyForm
        isOpen={!!monthlyModalState}
        onClose={() => setMonthlyModalState(undefined)}
        formProps={{
          initialValues: form.getFieldValue(
            monthlyModalState
              ? [
                "consumerUnits",
                monthlyModalState.name,
                monthlyModalState.subFieldName,
                monthlyModalState.monthlyFormKey,
              ]
              : [],
          ),
          onSubmit: ({ averageConsumption, ...monthlyValues }) => {
            const monthlyField = monthlyModalState
              ? [
                "consumerUnits",
                monthlyModalState.name,
                monthlyModalState.subFieldName,
                monthlyModalState.monthlyFormKey,
              ]
              : [];

            const averageField = monthlyModalState
              ? [
                "consumerUnits",
                monthlyModalState.name,
                monthlyModalState.subFieldName,
                monthlyModalState.averageFormKey,
              ]
              : [];

            form.setFieldValue(monthlyField, monthlyValues);
            form.setFieldValue(averageField, averageConsumption);
          },
        }}
      />

      <Modal.SelectConcessionaireProfileForm
        isOpen={!!concessionaireProfileModalState}
        onClose={() => setConcessionaireProfileModalState(undefined)}
        formProps={{
          concessionaireId,
          voltageType: concessionaireProfileModalState?.voltageType,
          modalityTaxType: concessionaireProfileModalState?.taxType,
          onSubmit: (data) => {
            const formFieldPath = concessionaireProfileModalState
              ? ["consumerUnits", concessionaireProfileModalState.name]
              : [];

            const currentState: IConsumerUnitsType =
              form.getFieldValue(formFieldPath);

            form.setFieldValue(formFieldPath, {
              ...currentState,
              lowVoltage: {
                ...currentState.lowVoltage,
                ...data.lowVoltage,
                averageConsumption: currentState.lowVoltage?.averageConsumption,
              },
              mediumVoltage: {
                ...currentState.mediumVoltage,
                ...data.mediumVoltage,
                averageConsumptionFP:
                  currentState.mediumVoltage?.averageConsumptionFP,
                averageConsumptionP:
                  currentState.mediumVoltage?.averageConsumptionP,
              },
              voltageType: data.voltageType ?? currentState.voltageType,
            } as IConsumerUnitsType);

            setConcessionaireProfileModalState(undefined);
          },
        }}
      />
    </>
  );
}
