import { useState } from "react";
import { AiOutlineEdit, AiOutlineSetting } from "react-icons/ai";

import type {
  IProposalDefaultFieldsTableData,
  ProposalDefaultFieldsTableColumn,
} from "./interface";
import { StyledCollapse } from "./styles";

import { Menu } from "~components/Menu";
import { Modal } from "~components/Modal";
import { Result } from "~components/Result";
import { useFetchGetProposalFields } from "~hooks/api";
import { ProposalStepEnumType } from "~types/enum";
import { IFormFieldDTOSchema } from "~types/schemas";
import { Badge, Flex, Table, Typography } from "antd";

function translateProposalStep(value: ProposalStepEnumType) {
  switch (value) {
    case "DATA":
      return "Dados";
    case "SIZING":
      return "Dimensionamento";
    case "DETAILS":
      return "Detalhes";
    case "AFTER_SIZING":
      return "Pós-Dimensionamento";
    case "PAYMENT":
      return "Pagamento";
    case "PROPOSAL":
      return "Proposta";
    case "FINISH":
      return "Final";
    default:
      return "Desconhecido";
  }
}

function proposalSectionColumnRender(value: ProposalStepEnumType) {
  const ProposalSectionValue = translateProposalStep(value);

  return ProposalSectionValue;
}

export function ProposalDefaultFieldsTable() {
  const [dataSource, setDataSource] = useState<
    IProposalDefaultFieldsTableData[]
  >([]);

  const [selectedDefaultFieldToProfile, setSelectedDefaultFieldToProfile] =
    useState<IFormFieldDTOSchema | undefined>(undefined);

  const [
    selectedDefaultDataFieldToUpdate,
    setSelectedDefaultDataFieldToUpdate,
  ] = useState<IProposalDefaultFieldsTableData | undefined>(undefined);

  const columns: ProposalDefaultFieldsTableColumn = [
    {
      key: "sort",
      width: 42,
      align: "center" as const,
    },
    {
      title: "Nome do campo",
      dataIndex: "displayName",
      width: "auto",
      render: (_, record) => {
        return (
          <span>
            {record?.displayName || "-"}
            {record.required && (
              <b style={{ color: "var(--primary-500)" }}>*</b>
            )}
          </span>
        );
      },
    },
    {
      title: "Passo da proposta",
      dataIndex: "proposalStep",
      render: proposalSectionColumnRender,
    },
    {
      title: "Categoria",
      dataIndex: "fieldCategory",
      width: "auto",
    },

    {
      title: "",
      dataIndex: "action",
      width: 42,
      align: "center" as const,
      render: (_, defaultField) =>
        defaultField.configurable ? (
          <Menu.MoreDropdown
            items={[
              {
                icon: (
                  <AiOutlineEdit
                    fontSize={18}
                    style={{ color: "var(--gold-500)" }}
                  />
                ),
                text: "Alterar",
                color: "var(--gold-500)",
                onClick: () =>
                  setSelectedDefaultDataFieldToUpdate(defaultField),
              },
              {
                icon: (
                  <AiOutlineSetting
                    fontSize={18}
                    style={{ color: "var(--blue-500)" }}
                  />
                ),
                text: "Configurar por perfil",
                color: "var(--blue-500)",
                onClick: () => setSelectedDefaultFieldToProfile(defaultField),
              },
            ]}
            position="bottomLeft"
          />
        ) : null,
    },
  ];

  const { isFetching: IsDefaultFieldsLoading, error: defaultFieldsError } =
    useFetchGetProposalFields({
      options: {
        onSuccess: (data) => {
          const dataSource = data.map((content) => ({
            ...content,
            key: content.id,
          }));

          setDataSource(dataSource);
        },
      },
    });

  return (
    <>
      <StyledCollapse
        size="large"
        style={{ padding: 0, border: 0 }}
        defaultActiveKey={["1"]}
        items={[
          {
            key: "1",
            label: (
              <Flex align="center" gap={6}>
                <Typography.Text
                  style={{
                    margin: 0,
                    fontWeight: "bold",
                  }}
                >
                  Campos padrão
                </Typography.Text>

                <Flex align="center">
                  <Badge
                    count={dataSource ? dataSource.length : 0}
                    style={{
                      backgroundColor: "#e4e4e5",
                      color: "#21232c",
                      fontWeight: "bold",
                    }}
                  />
                </Flex>
              </Flex>
            ),
            children: (
              <Table
                rowKey="key"
                columns={columns}
                dataSource={dataSource}
                scroll={{ x: 900 }}
                size="small"
                pagination={false}
                loading={IsDefaultFieldsLoading}
                locale={{
                  emptyText: (
                    <Result.TableErrorOrEmpty
                      errorMessage={defaultFieldsError?.message}
                      emptyMessage="Nenhum campo padão encontrado"
                    />
                  ),
                }}
                style={{ width: "100%" }}
              />
            ),
          },
        ]}
      />
      <Modal.ProposalDefaultDataFieldForm
        isOpen={!!selectedDefaultDataFieldToUpdate}
        onClose={() => setSelectedDefaultDataFieldToUpdate(undefined)}
        formProps={{
          initialValues: selectedDefaultDataFieldToUpdate,
          onSubmitSuccess: () => setSelectedDefaultDataFieldToUpdate(undefined),
        }}
      />
      <Modal.ProposalDefaultDataFieldProfileSettingsForm
        isOpen={!!selectedDefaultFieldToProfile}
        onClose={() => setSelectedDefaultFieldToProfile(undefined)}
        formProps={{
          initialValues: selectedDefaultFieldToProfile,
          onSubmitSuccess: () => setSelectedDefaultFieldToProfile(undefined),
        }}
      />
    </>
  );
}
