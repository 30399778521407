import type { IUnlinkUserWithPipedriveModalProps } from "./interface";

import { useFetchUnlinkPipedrive } from "~hooks/api";
import { Flex, Modal, Alert } from "antd";

export function UnlinkUserWithPipedriveModal({
  companyId,
  isOpen = false,
  onClose = () => null,
  title = "Desvincular do pipedrive",
  maxWidth = 520,
  onError,
  onSuccess,
}: IUnlinkUserWithPipedriveModalProps) {
  const { mutate: unlinkPipedrive, isLoading: isUnlinkingPipedrive } =
    useFetchUnlinkPipedrive({
      onSuccess: () => {
        if (typeof onSuccess === "function") {
          onSuccess();
        }

        onClose();
      },
      onError,
    });

  function handleAssignUserWithPipedrive() {
    unlinkPipedrive({ companyId });
  }

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title={title}
      style={{
        width: "100%",
        maxWidth,
      }}
      okText="Desvincular"
      onOk={handleAssignUserWithPipedrive}
      okButtonProps={{
        loading: isUnlinkingPipedrive,
      }}
    >
      <Flex vertical gap={16}>
        <Alert
          type="error"
          message="Você tem certeza que deseje desvincular do pipedrive?"
          description="Ao desvincular
          todos os vendedores perderão seu vinculo com o pipedrive, mesmo que
          você vincule novamente sua conta ao pipedrive, você terá de vincular
          novamente."
          style={{ padding: "8px 16px" }}
        />
      </Flex>
    </Modal>
  );
}
