import styled from "@emotion/styled";
import { SolarzButton } from "~solarzui/SolarzButton";
import { Card } from "antd";

export const CreateButton = styled(SolarzButton)`
  width: 28px;
  height: 28px;
  padding: 0;

  border: 1px solid var(--gray-300);

  svg {
    color: var(--primary-500);
  }

  html.dark & {
    border: 1px solid var(--gray-600) !important;
  }
`;

export const StyledCard = styled(Card)`
  &.ant-card {
    border: 0;

    .ant-card-body {
      background-color: var(--gray-50);
    }

    // DARK THEME
    html.dark & {
      .ant-card-body {
        background-color: var(--gray-999);
      }
    }
  }
`;
