/*
  The password must be have:

  - Least six characters;
  - One capital letter;
  - One special character;

  Acceptable example: MyPassword!123
  Wrong examples: MyPassword | My!Pa | MyPassword123
*/
export function checkIfPasswordIsValid(password: string) {
  const passwordRegex =
    /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+,\-./:;<=>?@[\\\]^_`{|}~])(?=.{6,})/;

  return passwordRegex.test(password);
}
