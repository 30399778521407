import type {
  ICustomVariableFormProps,
  CustomVariableFormValuesType,
} from "./interface";

import { CustomInput } from "~components/CustomInput";
import { Mentions } from "~components/Mentions";
import {
  useFetchCreateCustomVariable,
  useFetchUpdateCustomVariable,
} from "~hooks/api";
import { indentTextAreaCode } from "~utils/indentTextAreaCode";
import { Button, Form, Flex } from "antd";

export function CustomVariableForm({
  initialValues = {
    name: "",
    value: "",
    description: "",
  },
  onCancel = () => undefined,
  onError = () => undefined,
  onSuccess = () => undefined,
}: ICustomVariableFormProps) {
  const isToUpdateVar = !!initialValues?.name;

  const [form] = Form.useForm();

  const { mutate: updateCustomVariable, isLoading: isUpdatingCustomVariable } =
    useFetchUpdateCustomVariable({
      options: {
        onSuccess,
        onError,
      },
    });

  const { mutate: createCustomVariable, isLoading: isCreatingCustomVariable } =
    useFetchCreateCustomVariable({
      options: {
        onSuccess,
        onError,
      },
    });

  function handleSubmit(formValues: CustomVariableFormValuesType) {
    isToUpdateVar
      ? updateCustomVariable({
          ...formValues,
          value: formValues.value,
        })
      : createCustomVariable({
          ...formValues,
          value: formValues.value,
        });
  }

  const isLoading = isUpdatingCustomVariable || isCreatingCustomVariable;

  return (
    <Form
      data-cy="custom-variable-form"
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      style={{ width: "100%" }}
      initialValues={initialValues}
    >
      <Form.Item
        name="name"
        label="Identificador"
        tooltip={isToUpdateVar && "Não se pode alterar o nome da variável"}
      >
        <CustomInput.Text
          placeholder="Ex: nome_da_empresa"
          disabled={isToUpdateVar}
          data-cy="input-identifier"
        />
      </Form.Item>

      <Form.Item
        name="description"
        rules={[{ required: true }]}
        label="Descrição da variável"
      >
        <CustomInput.Text
          data-cy="input-description"
          placeholder="Descrição da variável..."
        />
      </Form.Item>

      <Form.Item
        name="value"
        label="Código da variável"
        rules={[{ required: true }]}
        help="Use o caractere $ seguido de pelos menos 3 caracteres para buscar uma variável. Ex: $var"
      >
        <Mentions.Variables
          placeholder="Escreva seu código aqui..."
          rows={6}
          initialValueField={indentTextAreaCode(initialValues?.value ?? "")}
          data-cy="input-expression"
          style={{ minHeight: 280 }}
        />
      </Form.Item>

      <Flex align="center" gap={8} justify="end">
        <Button onClick={onCancel}>Cancelar</Button>
        <Button
          data-cy="button-save"
          type="primary"
          htmlType="submit"
          loading={isLoading}
        >
          Salvar
        </Button>
      </Flex>
    </Form>
  );
}
