import { useEffect, useState } from "react";

import {
  ACMaterialFormProps,
  IAlternatingCurrentMaterialFormValues,
} from "./interface";

import { CustomInput } from "~components/CustomInput";
import { Select } from "~components/Select";
import {
  useFetchCreateAlternatingCurrentMaterial,
  useFetchUpdateAlternatingCurrentMaterial,
} from "~hooks/api";
import { Button, Col, Form, Radio, Row, Space } from "antd";

export function ACMaterialForm({
  initialValues,
  onSubmit = () => null,
  onCancel = () => null,
}: ACMaterialFormProps) {
  const [valueAdditionalCostTaxId, setValueAdditionalCostTaxId] =
    useState<number>();

  const [form] = Form.useForm<IAlternatingCurrentMaterialFormValues>();

  const applyTax = Form.useWatch("notApplyDefaultAdditionalCostTax", form);

  const { mutate: fetchSaveAlternatingCurrentMaterial } =
    useFetchCreateAlternatingCurrentMaterial({
      options: {
        onSuccess: () => {
          form.resetFields();
          onSubmit();
        },
      },
    });

  const { mutate: fetchUpdateAlternatingCurrentMaterial } =
    useFetchUpdateAlternatingCurrentMaterial({
      options: {
        onSuccess: () => {
          onSubmit();
        },
      },
    });

  const onFinish = async (formData: IAlternatingCurrentMaterialFormValues) => {
    if (initialValues?.id === undefined) {
      fetchSaveAlternatingCurrentMaterial(formData);
    } else {
      if (initialValues.id) {
        fetchUpdateAlternatingCurrentMaterial({
          acMaterialId: initialValues.id,
          ...formData,
        });
      }
    }
  };

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({
        power: initialValues.power,
        value: initialValues.value,
        notApplyDefaultAdditionalCostTax:
          initialValues.notApplyDefaultAdditionalCostTax,
        additionalCostTaxId: initialValues.notApplyDefaultAdditionalCostTax
          ? undefined
          : initialValues.additionalCostTaxId,
        costAggregatorIds:
          initialValues?.costAggregators?.map(
            (costAggregator) => costAggregator?.id,
          ) ?? [],
      });
    }
  }, [form, initialValues]);

  return (
    <Form
      data-cy="form-acmaterial-form"
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      onFinish={onFinish}
      autoComplete="off"
      layout="vertical"
    >
      <Form.Item<IAlternatingCurrentMaterialFormValues>
        label="Potência"
        name="power"
        rules={[{ required: true, message: "Por favor insira a potência!" }]}
        tooltip="O preço é calculado da faixa de potência imediamente anterior (ou 0 caso não tenha uma menor) até a potência cadastrada nesse formulário. Caso a potência seja maior do que o cadastrado aqui, usaremos a maior potência cadastrada."
      >
        <CustomInput.UnitMeasurement
          min={0}
          precision="2"
          unitMeasurement="KW"
          style={{ width: "100%" }}
        />
      </Form.Item>

      <Form.Item<IAlternatingCurrentMaterialFormValues>
        label="Valor"
        name="value"
        rules={[{ required: true, message: "Por favor insira o valor!" }]}
      >
        <CustomInput.Money
          style={{
            width: "100%",
          }}
        />
      </Form.Item>

      <Form.Item<IAlternatingCurrentMaterialFormValues>
        label="Aplicar Imposto pós precificado"
        tooltip="Alíquota aplicada por fora ao final dos cálculos de precificação, útil quando há alíquotas de imposto diferente nos itens complementares ou quando há custos precificado em % sobre o global e custos em % precificados sobre os custos complementares simultaneamente e ainda seja necessário cálculo de imposto"
        name="notApplyDefaultAdditionalCostTax"
      >
        <Radio.Group
          onChange={(e) => {
            if (e.target.value === true) {
              form.resetFields(["additionalCostTaxId"]);
              setValueAdditionalCostTaxId(undefined);
            }
          }}
        >
          <Space>
            <Radio value={false}>Sim</Radio>
            <Radio value={true}>Não aplicar</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      <Form.Item<IAlternatingCurrentMaterialFormValues>
        label="Escolher imposto pós precificado"
        rules={[{ required: !applyTax }]}
        name="additionalCostTaxId"
      >
        <Select.TaxesAfterPricing
          value={valueAdditionalCostTaxId}
          onChange={(e) => setValueAdditionalCostTaxId(e)}
          isEditForm
          disabled={applyTax}
          applyTax={applyTax}
        />
      </Form.Item>

      <Form.Item<IAlternatingCurrentMaterialFormValues>
        label="Escolher grupo de agregador de custo"
        name="costAggregatorIds"
        tooltip={
          'Agregadores de custo são utilizados para referenciar grupos de custo dentro de expressões personalizadas. Exemplo: se você tiver 5 custos que somados compõe a mão de obra, você pode indicar que eles pertencem ao grupo de custo "mão de obra" e usar o identificador do grupo para referenciar o somatório desse grupo dentro de uma expressão customizada.'
        }
      >
        <Select.CostAggregator mode="multiple" />
      </Form.Item>

      <Row justify="end" gutter={20}>
        <Col>
          <Button type="default" size="middle" onClick={onCancel}>
            Fechar
          </Button>
        </Col>
        <Col>
          <Button type="primary" size="middle" htmlType="submit">
            Salvar
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
