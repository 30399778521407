import type {
  ISolarzPipelineTabProps,
  SolarzPipelineTabType,
} from "./interface";

import styled from "@emotion/styled";

function getStylesByScaleProps(scale: ISolarzPipelineTabProps<any>["scale"]) {
  switch (scale) {
    case "large":
      return {
        height: "2rem",
        contentFontSize: "44px",
        contentAfterLeft: "-4px",
        contentBeforeLeft: "-8px",
      };
    case "medium":
      return {
        height: "1rem",
        contentFontSize: "24px",
        contentAfterLeft: "-3px",
        contentBeforeLeft: "-5px",
      };
    case "tiny":
      return {
        height: "0.5rem",
        fontSize: "24px",
        contentFontSize: "24px",
        contentAfterLeft: "-3px",
        contentBeforeLeft: "-5px",
      };
    default:
      return {
        height: "0.5rem",
        contentFontSize: "24px",
        contentAfterLeft: "-3px",
        contentBeforeLeft: "-5px",
      };
  }
}

export interface IWrapperProps {
  scale: ISolarzPipelineTabProps<any>["scale"];
}

export interface ITabProps {
  isCompleted: boolean;
  disabled: boolean;
  scale: ISolarzPipelineTabProps<any>["scale"];
  separator: ISolarzPipelineTabProps<any>["separator"];
  color?: SolarzPipelineTabType<any>["color"];
  onClick?: () => void;
}

export const Wrapper = styled.div<IWrapperProps>`
  width: 100%;
  height: ${({ scale }) => `${getStylesByScaleProps(scale).height}`};
  background-color: var(--gray-200);
  overflow: hidden;
  border-radius: 9999px;

  display: flex;
  position: relative;

  .solarz-pipeline-tab-skeleton {
    width: 100%;
    height: 100%;
  }

  // DARK-THEME
  html.dark & {
    background-color: var(--gray-700);
  }
`;

export const Tab = styled.div<ITabProps>`
  background-color: ${({ isCompleted, color }) =>
    isCompleted ? `var(--${color}-500)` : "transparent"};

  flex: 1;
  position: relative;
  transition: all 0.2s;
  outline: 0;

  :first-of-type::after,
  :first-of-type::before {
    content: none; // disable arrow in first element
  }

  :after {
    content: ${({ separator }) => (separator === "pipe" ? "'|'" : "'❭'")};
    position: absolute;
    width: 0;
    height: 0;
    color: white;
    font-size: ${({ scale }) =>
      `${getStylesByScaleProps(scale).contentFontSize}`};
    top: 50%;
    line-height: 0px;
    left: ${({ scale }) => `${getStylesByScaleProps(scale).contentAfterLeft}`};
    font-weight: bold;
  }

  :before {
    content: ${({ separator }) => (separator === "pipe" ? "'|'" : "'❭'")};
    position: absolute;
    width: 0;
    height: 0;
    color: white;
    font-size: ${({ scale }) =>
      `${getStylesByScaleProps(scale).contentFontSize}`};
    top: 50%;
    line-height: 0px;
    left: ${({ scale }) => `${getStylesByScaleProps(scale).contentBeforeLeft}`};
    font-weight: bold;
  }

  &.hoverable {
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  }

  &.hoverable:hover {
    background-color: ${({ isCompleted, color }) =>
      isCompleted ? `var(--${color}-600)` : `var(--${color}-200)`};
  }

  &.hoverable[aria-disabled="false"]:hover {
    background-color: ${({ isCompleted, color }) =>
      isCompleted ? `var(--${color}-600)` : "var(--gray-300)"};
  }

  &.hoverable[aria-disabled="false"]:focus-within,
  &.hoverable[aria-disabled="false"]:focus {
    background-color: ${({ isCompleted, color }) =>
      isCompleted ? `var(--${color}-600)` : "var(--gray-300)"};
  }
`;
