import { useMemo } from "react";

import { IGridTypeOption, IGridTypeSelectProps } from "./interface";

import { Select } from "antd";

export function GridTypeSelect({
  value,
  onChange,
  defaultValue,
  ...props
}: IGridTypeSelectProps) {
  const OPTIONS: IGridTypeOption[] = useMemo(
    () => [
      {
        label: "On grid",
        value: "ON_GRID",
      },
      {
        label: "Off grid",
        value: "OFF_GRID",
      },
      {
        label: "Híbrido",
        value: "HYBRID",
      },
    ],
    [],
  );

  return (
    <Select
      {...props}
      options={OPTIONS}
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
}
