import { useState } from "react";

import {
  ISetProposalStatusModalProps,
  ProposalStatusModalActionEnum,
} from "./interface";

import {
  useFetchGetGetProposalByCustomId,
  useFetchUpdateProposalStatus,
  useFetchUpdateProposalStatusByCustomId,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { useSession } from "~hooks/useSession";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { parseCurrency } from "~utils/parse";
import { Alert, Button, Checkbox, Flex, Modal, Result, Skeleton } from "antd";

function getModalTitle(action: ISetProposalStatusModalProps["action"]) {
  switch (action) {
    case "SET_AS_DELETED":
      return "Apagar proposta";
    case "SET_AS_LOST":
      return "Definir proposta como perdida";
    case "SET_AS_WON":
      return "Definir proposta como ganha";
    case "SET_AS_OPEN":
      return "Restaurar proposta";
    default:
      return "Definir status da proposta";
  }
}

function getLabelPipedriveDealProposalText(
  action: ISetProposalStatusModalProps["action"],
) {
  switch (action) {
    case "SET_AS_DELETED":
      return "Apagar proposta";
    case "SET_AS_OPEN":
      return "Restaurar proposta";
    case "SET_AS_WON":
      return "Definir proposta como ganha";
    case "SET_AS_LOST":
      return "Definir proposta como perdida";
    default:
      return "Definir proposta";
  }
}

function getLabelCrmDealProposalText(
  action: ISetProposalStatusModalProps["action"],
) {
  switch (action) {
    case "SET_AS_WON":
      return "Definir proposta e negócio como ganhos";
    case "SET_AS_LOST":
      return "Definir proposta e negócio como perdidos";
    default:
      return "Definir proposta e negócio";
  }
}

export function SetProposalStatusModal({
  isOpen = false,
  onClose = () => undefined,
  maxWidth = 512,
  dealId = 0,
  customId = 0,
  onError = () => undefined,
  onSuccess = () => undefined,
  action = "SET_AS_WON",
}: ISetProposalStatusModalProps) {
  const { message } = useAppConfig();
  const { user } = useSession();
  const modalTitle = getModalTitle(action);
  const pipedriveDealLabelText = getLabelPipedriveDealProposalText(action);
  const crmDealLabelText = getLabelCrmDealProposalText(action);

  const [isProposalWon, setIsProposalWon] = useState<boolean>(false);
  const [isProposalAndDealWon, setIsProposalAndDealWon] =
    useState<boolean>(false);

  const {
    data: proposal,
    isFetching: isLoadingProposal,
    error: proposalError,
  } = useFetchGetGetProposalByCustomId({
    dependencyArray: [customId],
    payload: {
      customId,
    },
    options: {
      enabled: isOpen,
    },
  });

  const {
    mutate: updateProposalStatusWithPipedrive,
    isLoading: isUpdatingProposalStatusWithPipedrive,
  } = useFetchUpdateProposalStatus({
    options: {
      onSuccess,
      onError,
    },
  });

  const {
    mutate: updateProposalStateByCustomId,
    isLoading: isLoadingProposalStateByCustomId,
  } = useFetchUpdateProposalStatusByCustomId({
    options: {
      onSuccess,
      onError,
    },
  });

  function handleUpdateProposalStatusAndDealStatus() {
    if (!dealId) {
      message.error("Erro: A proposta não possui um dealId associado.");
      return;
    }

    updateProposalStateByCustomId({
      customId,
      changeDealStatus: true,
      status: ProposalStatusModalActionEnum[action],
    });
  }

  function handleUpdateProposalStatus() {
    if (isProposalWon) {
      if (!user?.pipedriveIntegration) {
        updateProposalStateByCustomId({
          customId,
          changeDealStatus: false,
          status: ProposalStatusModalActionEnum[action],
        });
      } else {
        updateProposalStatusWithPipedrive({
          customId,
          status: ProposalStatusModalActionEnum[action],
        });
      }
    } else if (isProposalAndDealWon) {
      handleUpdateProposalStatusAndDealStatus();
    }
  }

  const isDefineButtonDisabled = !isProposalWon && !isProposalAndDealWon;
  const isLoadingStatusProposalOrDeal =
    isUpdatingProposalStatusWithPipedrive || isLoadingProposalStateByCustomId;

  function handleCancel() {
    setIsProposalWon(false);
    setIsProposalAndDealWon(false);

    if (typeof onClose === "function") {
      onClose();
    }
  }
  return (
    <>
      <Modal
        data-cy="set-proposal-status-modal"
        open={isOpen}
        onCancel={handleCancel}
        title={modalTitle}
        width="90vw"
        footer={null}
        style={{ maxWidth }}
        destroyOnClose
      >
        <Skeleton active loading={isLoadingProposal}>
          {proposalError && (
            <Result
              status="error"
              title="Ops!"
              subTitle={proposalError.message}
            />
          )}
          {action === "SET_AS_DELETED" && (
            <Alert
              style={{ width: "100%", marginBottom: "1rem" }}
              message="A proposta será apagada automaticamente em 30 dias"
              type="info"
              showIcon
            />
          )}
          {!proposalError && (
            <div className="flex flex-col gap-3 py-3 my-4 border border-gray-300 rounded-lg">
              <SolarzTypography
                fontWeight="semiBold"
                hierarchy="paragraph1"
                variant="title"
                style={{ padding: "0 1rem" }}
              >
                Detalhes da proposta
              </SolarzTypography>
              <div className="w-full border-t border-gray-200 my-2"></div>

              <div className="grid grid-cols-10 gap-3 px-3">
                <div className="col-span-4 flex justify-start">
                  <SolarzTypography
                    fontWeight="medium"
                    variant="title"
                    hierarchy="paragraph2"
                  >
                    Título
                  </SolarzTypography>
                </div>
                <div className="col-span-6">
                  <SolarzTypography
                    fontWeight="regular"
                    variant="disabled"
                    hierarchy="small"
                  >
                    {proposal?.title}
                  </SolarzTypography>
                </div>
              </div>

              <div className="grid grid-cols-10 gap-3 px-3">
                <div className="col-span-4 flex justify-start">
                  <SolarzTypography
                    fontWeight="medium"
                    variant="title"
                    hierarchy="paragraph2"
                  >
                    Cliente
                  </SolarzTypography>
                </div>
                <div className="col-span-6">
                  <SolarzTypography
                    fontWeight="regular"
                    variant="disabled"
                    hierarchy="small"
                  >
                    {proposal?.client?.name ?? "-"}
                  </SolarzTypography>
                </div>
              </div>

              <div className="grid grid-cols-10 gap-3 px-3">
                <div className="col-span-4 flex justify-start">
                  <SolarzTypography
                    fontWeight="medium"
                    variant="title"
                    hierarchy="paragraph2"
                  >
                    Valor
                  </SolarzTypography>
                </div>
                <div className="col-span-6">
                  <SolarzTypography
                    fontWeight="regular"
                    variant="disabled"
                    hierarchy="small"
                  >
                    {parseCurrency(proposal?.value)}
                  </SolarzTypography>
                </div>
              </div>
            </div>
          )}
        </Skeleton>

        <div className="flex flex-col gap-3 mb-6">
          <Checkbox
            checked={isProposalWon}
            onChange={() => {
              setIsProposalWon(!isProposalWon);
              if (isProposalAndDealWon) {
                setIsProposalAndDealWon(false);
              }
            }}
          >
            {pipedriveDealLabelText}
          </Checkbox>
          {(action === "SET_AS_WON" || action === "SET_AS_LOST") &&
            dealId > 0 &&
            !user?.pipedriveIntegration && (
              <div className="flex flex-col">
                <Checkbox
                  checked={isProposalAndDealWon}
                  onChange={() => {
                    setIsProposalAndDealWon(!isProposalAndDealWon);
                    if (isProposalWon) {
                      setIsProposalWon(false);
                    }
                  }}
                >
                  {crmDealLabelText}
                </Checkbox>
                <SolarzTypography
                  fontWeight="regular"
                  variant="disabled"
                  hierarchy="small"
                  style={{ marginLeft: "1.5rem" }}
                >
                  {action === "SET_AS_WON"
                    ? "Ao definir proposta e negócio como ganho, as outras propostas vinculadas ao negócio serão rejeitadas."
                    : action === "SET_AS_LOST"
                      ? "Ao definir proposta e negócio como perdidos, as outras propostas vinculadas ao negócio serão perdidas também."
                      : ""}
                </SolarzTypography>
              </div>
            )}
        </div>

        <Flex justify="end" gap={8}>
          <Button onClick={handleCancel}>Cancelar</Button>
          <Button
            type="primary"
            disabled={isDefineButtonDisabled}
            loading={isLoadingStatusProposalOrDeal}
            onClick={() => handleUpdateProposalStatus()}
          >
            Salvar
          </Button>
        </Flex>
      </Modal>
    </>
  );
}
