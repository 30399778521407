import { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";

import { type DealCustomDataFieldsTableDataType } from "./interface";
import { StyledCollapse } from "./styles";

import { DndContext, DragEndEvent } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Alert } from "~components/Alert";
import { Menu } from "~components/Menu";
import { Modal } from "~components/Modal";
import { DraggableTableRow } from "~components/Others";
import { Result } from "~components/Result";
import { Delete, Edit } from "~estrela-ui/icons";
import {
  useFetchGetDealCustomFields,
  useFetchGetDealCustomFieldsGroupList,
  useFetchUpdateDealCustomFieldsOrder,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import type { FieldTypeEnum } from "~types/enum";
import { Badge, Flex, Spin, Table, Tooltip, Typography } from "antd";

function translateCustomFieldType(value: FieldTypeEnum) {
  switch (value) {
    case "BOOLEAN":
      return "Sim/Não";
    case "NUMBER":
      return "Numérico";
    case "TEXT":
      return "Texto";
    case "TEXT_AREA":
      return "Caixa de texto";
    case "SELECT":
      return "Selecionável";
    case "IMAGE":
      return "Imagem";
    case "DATE":
      return "Data";
    default:
      return "Desconhecido";
  }
}

function dealCustomFieldTypeColumnRender(value: FieldTypeEnum) {
  const parsedData = translateCustomFieldType(value);

  return parsedData;
}

export function DealCustomDataFieldsTable() {
  const { screens } = useAppConfig();

  const [isCreateSessionModalOpen, setIsCreateSessionModalOpen] =
    useState(false);

  const [needUpdateOrder, setNeedUpdateOrder] = useState(false);

  const [dataSource, setDataSource] = useState<
    DealCustomDataFieldsTableDataType[]
  >([]);

  const [selectedCustomFieldIdToUpdate, setSelectedCustomFieldIdToUpdate] =
    useState<number>();

  const [selectedCustomFieldIdToDelete, setSelectedCustomFieldIdToDelete] =
    useState<number>();

  const [
    selectedDealCustomFieldGroupIdToUpdate,
    setSelectedDealCustomFieldGroupIdToUpdate,
  ] = useState<number>();

  const [
    selectedDealCustomFieldGroupIdToDelete,
    setSelectedDealCustomFieldGroupIdToDelete,
  ] = useState<number>();

  function onDragEnd({ active, over }: DragEndEvent) {
    if (active.id !== over?.id) {
      setDataSource((state) => {
        const activeIndex = state.findIndex((i) => i.key === active.id);
        const overIndex = state.findIndex((i) => i.key === over?.id);
        return arrayMove(state, activeIndex, overIndex);
      });
      setNeedUpdateOrder(true);
    }
  }

  const {
    data: dealCustomFieldGroupList = [],
    isLoading: isLoadingDealCustomFieldGroupList,
    error: dealCustomFieldGroupListError,
    refetch: dealCustomFieldGroupListReload,
  } = useFetchGetDealCustomFieldsGroupList({});

  const { isFetching: isLoadingCustomFields, error: customFieldsError } =
    useFetchGetDealCustomFields({
      options: {
        onSuccess: (dealCustomFields) => {
          const dataSource = dealCustomFields.map((content) => ({
            ...content,
            key: content.id,
          }));
          setDataSource(dataSource);
        },
      },
    });

  const {
    mutate: updateCustomFieldsOrder,
    isLoading: isUpdatingCustomFieldsOrder,
  } = useFetchUpdateDealCustomFieldsOrder({
    options: {
      onSuccess: () => setNeedUpdateOrder(false),
      onError: () => setNeedUpdateOrder(false),
    },
  });

  const handleOpenDeleteModal = (customFieldId: number) => {
    setSelectedCustomFieldIdToDelete(customFieldId);
  };

  useEffect(() => {
    if (needUpdateOrder) {
      const dealFieldsOrder = dataSource.map((item, index) => ({
        id: item.id,
        order: index + 1,
      }));

      updateCustomFieldsOrder({
        body: dealFieldsOrder,
      });
    }
  }, [needUpdateOrder, updateCustomFieldsOrder, dataSource]);

  const dataSourceWithoutGroup = dataSource.filter((data) => !data.group);

  return (
    <>
      <StyledCollapse
        size="large"
        style={{ padding: 0, border: 0 }}
        defaultActiveKey={["1"]}
        items={[
          {
            key: "1",
            label: (
              <Flex align="center" justify="space-between">
                <Flex align="center" gap={6}>
                  <Typography.Text
                    style={{
                      margin: 0,
                      fontWeight: "bold",
                    }}
                  >
                    {screens.mobile
                      ? "Personalizados"
                      : "Campos personalizados"}
                  </Typography.Text>

                  <Flex align="center">
                    <Badge
                      count={dataSource ? dataSource.length : 0}
                      style={{
                        backgroundColor: "#e4e4e5",
                        color: "#21232c",
                        fontWeight: "bold",
                      }}
                    />
                  </Flex>
                </Flex>

                <SolarzButton
                  variant="link"
                  scale="tiny"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsCreateSessionModalOpen(true);
                  }}
                >
                  Criar nova sessão
                </SolarzButton>
              </Flex>
            ),
            children: (
              <Spin spinning={isLoadingDealCustomFieldGroupList}>
                {dealCustomFieldGroupListError && (
                  <Alert.CardError
                    title="Falha ao carregar sessões"
                    reloadFn={dealCustomFieldGroupListReload}
                    errorMessage={dealCustomFieldGroupListError.message}
                  />
                )}

                {dataSourceWithoutGroup.length > 0 && (
                  <DndContext
                    data-cy="deal-custom-fields-table"
                    onDragEnd={onDragEnd}
                  >
                    <SortableContext
                      items={dataSourceWithoutGroup.map((item) => item.id)}
                      strategy={verticalListSortingStrategy}
                    >
                      <Table
                        components={{
                          body: {
                            row: DraggableTableRow,
                          },
                        }}
                        rowKey="key"
                        columns={[
                          {
                            key: "sort",
                            width: 42,
                            align: "center" as const,
                          },
                          {
                            title: "Nome",
                            dataIndex: "label",
                            width: "auto",
                          },
                          {
                            title: "Identificador",
                            dataIndex: "identifier",
                            width: 360,
                          },
                          {
                            title: "Tipo do campo",
                            dataIndex: "type",
                            render: dealCustomFieldTypeColumnRender,
                            width: 220,
                          },
                          {
                            title: "",
                            dataIndex: "action",
                            width: 42,
                            align: "center" as const,
                            render: (_, customField) => (
                              <Menu.MoreDropdown
                                items={[
                                  {
                                    icon: (
                                      <AiOutlineEdit
                                        fontSize={18}
                                        style={{
                                          color: "var(--gold-500)",
                                        }}
                                      />
                                    ),
                                    text: "Alterar",
                                    color: "var(--gold-500)",
                                    onClick: () =>
                                      setSelectedCustomFieldIdToUpdate(
                                        customField.id,
                                      ),
                                  },
                                  {
                                    icon: (
                                      <AiOutlineDelete
                                        fontSize={16}
                                        style={{
                                          color: "var(--volcano-500)",
                                        }}
                                      />
                                    ),
                                    text: "Remover",
                                    color: "var(--volcano-500)",
                                    onClick: () =>
                                      handleOpenDeleteModal(customField.id),
                                  },
                                ]}
                                position="bottomLeft"
                              />
                            ),
                          },
                        ]}
                        dataSource={dataSourceWithoutGroup}
                        scroll={{ x: 900 }}
                        size="small"
                        pagination={false}
                        locale={{
                          emptyText: (
                            <Result.TableErrorOrEmpty
                              errorMessage={customFieldsError?.message}
                              emptyMessage="Nenhum campo personalizado encontrado"
                            />
                          ),
                        }}
                        style={{ width: "100%" }}
                        loading={
                          isLoadingCustomFields || isUpdatingCustomFieldsOrder
                        }
                      />
                    </SortableContext>
                  </DndContext>
                )}

                {dealCustomFieldGroupList.map((group) => {
                  const filteredDataSource =
                    dataSource
                      .filter((data) => data.group?.id === group.id)
                      .map((data) => ({
                        ...data,
                        identifier: data.entityIdentifier?.identifier,
                      })) ?? [];
                  return (
                    <Flex vertical key={group.id}>
                      <section className="h-12 bg-neutral-100 dark:bg-neutral-900 px-4 py-3">
                        <Flex align="center" justify="space-between">
                          <Flex align="center" gap={8}>
                            <SolarzTypography
                              variant="title"
                              hierarchy="paragraph2"
                              fontWeight="semiBold"
                            >
                              {group.name}
                            </SolarzTypography>
                            <Tooltip title="Editar sessão">
                              <div>
                                <SolarzButton
                                  scale="tiny"
                                  variant="link"
                                  icon={<Edit />}
                                  onClick={() => {
                                    setSelectedDealCustomFieldGroupIdToUpdate(
                                      group.id,
                                    );
                                  }}
                                />
                              </div>
                            </Tooltip>
                          </Flex>
                          <Tooltip title="Apagar sessão">
                            <div>
                              <SolarzButton
                                scale="tiny"
                                variant="link"
                                icon={<Delete className="text-red-500" />}
                                onClick={() => {
                                  setSelectedDealCustomFieldGroupIdToDelete(
                                    group.id,
                                  );
                                }}
                              />
                            </div>
                          </Tooltip>
                        </Flex>
                      </section>
                      <DndContext
                        data-cy="deal-custom-fields-table"
                        onDragEnd={onDragEnd}
                      >
                        <SortableContext
                          items={filteredDataSource.map((item) => item.id)}
                          strategy={verticalListSortingStrategy}
                        >
                          <Table
                            components={{
                              body: {
                                row: DraggableTableRow,
                              },
                            }}
                            rowKey="key"
                            columns={[
                              {
                                key: "sort",
                                width: 42,
                                align: "center" as const,
                              },
                              {
                                title: "Nome",
                                dataIndex: "label",
                                width: "auto",
                              },
                              {
                                title: "Identificador",
                                dataIndex: "identifier",
                                width: 360,
                              },
                              {
                                title: "Tipo do campo",
                                dataIndex: "type",
                                render: dealCustomFieldTypeColumnRender,
                                width: 220,
                              },
                              {
                                title: "",
                                dataIndex: "action",
                                width: 42,
                                align: "center" as const,
                                render: (_, customField) => (
                                  <Menu.MoreDropdown
                                    items={[
                                      {
                                        icon: (
                                          <AiOutlineEdit
                                            fontSize={18}
                                            style={{
                                              color: "var(--gold-500)",
                                            }}
                                          />
                                        ),
                                        text: "Alterar",
                                        color: "var(--gold-500)",
                                        onClick: () =>
                                          setSelectedCustomFieldIdToUpdate(
                                            customField.id,
                                          ),
                                      },
                                      {
                                        icon: (
                                          <AiOutlineDelete
                                            fontSize={16}
                                            style={{
                                              color: "var(--volcano-500)",
                                            }}
                                          />
                                        ),
                                        text: "Remover",
                                        color: "var(--volcano-500)",
                                        onClick: () =>
                                          handleOpenDeleteModal(customField.id),
                                      },
                                    ]}
                                    position="bottomLeft"
                                  />
                                ),
                              },
                            ]}
                            dataSource={filteredDataSource}
                            scroll={{ x: 900 }}
                            size="small"
                            pagination={false}
                            locale={{
                              emptyText: (
                                <Result.TableErrorOrEmpty
                                  errorMessage={customFieldsError?.message}
                                  emptyMessage="Nenhum campo personalizado encontrado"
                                />
                              ),
                            }}
                            style={{ width: "100%" }}
                            loading={
                              isLoadingCustomFields ||
                              isUpdatingCustomFieldsOrder
                            }
                          />
                        </SortableContext>
                      </DndContext>
                    </Flex>
                  );
                })}
              </Spin>
            ),
          },
        ]}
      />

      <Modal.DealCustomVariableForm
        isOpen={!!selectedCustomFieldIdToUpdate}
        onClose={() => setSelectedCustomFieldIdToUpdate(undefined)}
        formProps={{
          onSuccess: () => setSelectedCustomFieldIdToUpdate(undefined),
        }}
        customVariableId={selectedCustomFieldIdToUpdate ?? 0}
        title="Atualizar campo personalizado de negócio"
      />

      <Modal.DeleteCustomFieldDeal
        customFieldId={selectedCustomFieldIdToDelete ?? 0}
        isOpen={!!selectedCustomFieldIdToDelete}
        onClose={() => setSelectedCustomFieldIdToDelete(undefined)}
      />

      <Modal.DealCustomFieldGroupForm
        title="Criar nova sessão"
        onClose={() => {
          setIsCreateSessionModalOpen(false);
        }}
        isOpen={isCreateSessionModalOpen}
        formProps={{
          onSuccess: () => {
            setIsCreateSessionModalOpen(false);
          },
        }}
      />

      <Modal.DealCustomFieldGroupForm
        title="Atualizar sessão"
        onClose={() => {
          setSelectedDealCustomFieldGroupIdToUpdate(undefined);
        }}
        isOpen={!!selectedDealCustomFieldGroupIdToUpdate}
        formProps={{
          onSuccess: () => {
            setSelectedDealCustomFieldGroupIdToUpdate(undefined);
          },
          dealCustomFieldGroupId: selectedDealCustomFieldGroupIdToUpdate,
        }}
      />

      <Modal.DeleteDealCustomFieldGroupForm
        onClose={() => {
          setSelectedDealCustomFieldGroupIdToDelete(undefined);
        }}
        isOpen={!!selectedDealCustomFieldGroupIdToDelete}
        formProps={{
          onSuccess: () => {
            setSelectedDealCustomFieldGroupIdToDelete(undefined);
          },
        }}
        dealCustomFieldGroupId={selectedDealCustomFieldGroupIdToDelete ?? 0}
      />
    </>
  );
}
