import { useMemo, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";

import {
  IProposalHistoryListDataType,
  IProposalHistoryListProps,
} from "./interface";

import { Modal } from "~components/Modal";
import { createPaginationTableObject } from "~components/Pagination";
import { Result } from "~components/Result";
import {
  useFetchGetPaginatedProposalHistory,
  useFetchGetProposalPDFFile,
} from "~hooks/api";
import { Button, List } from "antd";
import dayjs from "dayjs";

export function ProposalHistoryList({
  proposalId,
  ...props
}: IProposalHistoryListProps) {
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedProposalIdToDisplayPdf, setSelectedProposalIdToDisplayPdf] =
    useState<number>(0);

  const {
    data: base64,
    isLoading: isLoadingBase64,
    error: errorBase64,
  } = useFetchGetProposalPDFFile({
    payload: {
      id: selectedProposalIdToDisplayPdf,
    },
    options: {
      enabled: selectedProposalIdToDisplayPdf > 0,
    },
    dependencyArray: [selectedProposalIdToDisplayPdf],
  });

  const { data, isLoading, error } = useFetchGetPaginatedProposalHistory({
    dependencyArray: [proposalId, currentPage],
    payload: {
      customId: proposalId ?? 0,
      size: 5,
      page: currentPage - 1,
    },
    options: {
      enabled: Number(proposalId) > 0,
    },
  });

  const dataSource: IProposalHistoryListDataType[] = useMemo(() => {
    return (
      data?.content.map((item) => ({
        id: item.id,
        fileName: item.fileName,
        creationDate: item.creationDate,
      })) ?? []
    );
  }, [data?.content]);

  return (
    <>
      <List
        data-cy="proposal-history-list"
        {...props}
        itemLayout="horizontal"
        dataSource={dataSource}
        loading={isLoading}
        rowKey="id"
        locale={{
          emptyText: (
            <Result.TableErrorOrEmpty
              errorMessage={error?.message}
              emptyMessage="O histórico esta vazio"
            />
          ),
        }}
        pagination={createPaginationTableObject({
          number: currentPage,
          size: data?.size,
          totalElements: data?.totalElements,
          onChange: (page) => setCurrentPage(page),
          itemType: "registros",
        })}
        renderItem={(history) => (
          <List.Item style={{ width: "100%" }}>
            <List.Item.Meta
              title={history.fileName}
              description={dayjs(history.creationDate).format(
                "DD/MM/YYYY HH:mm:ss",
              )}
            />

            <Button
              onClick={() => setSelectedProposalIdToDisplayPdf(history.id ?? 0)}
            >
              <AiOutlineEye size={18} color="var(--primary-500)" />
            </Button>
          </List.Item>
        )}
      />

      <Modal.PDFViewer
        isOpen={!!selectedProposalIdToDisplayPdf}
        onClose={() => setSelectedProposalIdToDisplayPdf(0)}
        PDFViewerProps={{
          base64pdf: base64?.filePdfInBase64,
          isLoading: isLoadingBase64,
          onErrorResultProps: errorBase64?.message
            ? {
              title: "Falha ao carregar PDF",
              subTitle: errorBase64?.message ?? "Error desconhecido",
            }
            : undefined,
        }}
      />
    </>
  );
}
