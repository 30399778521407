import { useEffect } from "react";

import type {
  IDealCustomVariableFormProps,
  IDealCustomVariableFormValuesType,
} from "./interface";

import { Alert } from "~components/Alert";
import { Collapse } from "~components/Collapse";
import { Select } from "~components/Select";
import { Info } from "~estrela-ui/icons";
import {
  useFetchCreateDealCustomField,
  useFetchGetDealCustomFieldsById,
  useFetchUpdateDealCustomFields,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzFormItem } from "~solarzui/SolarzFormItem";
import { SolarzInput } from "~solarzui/SolarzInput";
import { SolarzInputNumber } from "~solarzui/SolarzInputNumber";
import { SolarzSelect } from "~solarzui/SolarzSelect";
import { FieldTypeEnum } from "~types/enum";
import {
  Form,
  Flex,
  Radio,
  Input,
  DatePicker,
  Row,
  Col,
  Divider,
  Spin,
  Switch,
  Tooltip,
} from "antd";
import dayjs from "dayjs";

const FIELDS_MAP = {
  BOOLEAN: (
    <Radio.Group
      options={[
        {
          label: "Sim",
          value: true,
        },
        {
          label: "Não",
          value: false,
        },
      ]}
    />
  ),
  NUMBER: (
    <SolarzInputNumber
      placeholder="Digite o valor numérico"
      style={{ width: "100%" }}
      onChange={(value) => {
        return value ? value.toString() : undefined;
      }}
    />
  ),
  TEXT_AREA: (
    <Input.TextArea
      placeholder="Digite o texto..."
      style={{ width: "100%", maxHeight: 120 }}
      maxLength={255}
    />
  ),
  TEXT: (
    <SolarzInput placeholder="Digite o texto..." style={{ width: "100%" }} />
  ),
  SELECT: (
    <div className="flex flex-col">
      <SolarzSelect mode="tags" placeholder="Digite as opções..." allowClear />
    </div>
  ),
  DATE: (
    <DatePicker
      format="DD/MM/YYYY"
      allowClear
      style={{ height: 42, width: "100%" }}
      placeholder="Selecione a data..."
    />
  ),
} as { [key in FieldTypeEnum]: JSX.Element };

function getInputComponentByType(type?: string) {
  return type ? (FIELDS_MAP as Record<string, JSX.Element>)?.[type] : <></>;
}

const DEFAULT_INITIAL_VALUES: Partial<IDealCustomVariableFormValuesType> = {
  type: "TEXT",
};

export function DealCustomVariableForm({
  customVariableId = 0,
  form: formInstance,
  initialValues,
  onCancel = () => undefined,
  onError = () => undefined,
  onSuccess = () => undefined,
  displayFooterAsFullWidth = false,
  maxHeight = 360,
}: IDealCustomVariableFormProps) {
  const hasUpdateForm = customVariableId > 0;

  const { screens } = useAppConfig();
  const [form] = Form.useForm<IDealCustomVariableFormValuesType>(formInstance);

  // WATCHERS
  const type = Form.useWatch("type", form) ?? "TEXT";
  const options = Form.useWatch("options", form) ?? [];
  const pipelinesId = Form.useWatch("pipelinesId", form) ?? [];
  const requiredInPipelinesStagesId = Form.useWatch(
    "requiredInPipelinesStagesId",
    form,
  );
  const pipelineStagesAreRequired = Form.useWatch("pipelineStagesAreRequired", {
    form,
    preserve: true,
  });
  const isAllPipelinesChecked = Form.useWatch("isAllPipelinesChecked", {
    form,
    preserve: true,
  });
  const futurePipelinesIncluded = Form.useWatch("futurePipelinesIncluded", {
    form,
    preserve: true,
  });

  // FORCE SOME STATES
  if (pipelinesId.length === 0) {
    form.setFieldsValue({ pipelineStagesAreRequired: false });
  }

  if (!isAllPipelinesChecked) {
    form.setFieldsValue({ futurePipelinesIncluded: false });
  }

  const {
    data: initialData,
    isFetching: isLoadingInitialData,
    error: errorInitialData,
    refetch: reloadInitialData,
  } = useFetchGetDealCustomFieldsById({
    dependencyArray: [customVariableId],
    options: {
      enabled: hasUpdateForm,
      retry: 1,
    },
    payload: {
      id: customVariableId,
    },
    customKey: `api/config/deals/custom-fields/${customVariableId}/get`,
  });

  const {
    mutate: updateDealCustomVariable,
    isLoading: isUpdatingDealCustomVariable,
  } = useFetchUpdateDealCustomFields({
    options: {
      onSuccess: onSuccess,
      onError: onError,
    },
  });

  const {
    mutate: createDealCustomVariable,
    isLoading: isCreatingDealCustomVariable,
  } = useFetchCreateDealCustomField({
    options: {
      onSuccess: onSuccess,
      onError: onError,
    },
  });

  function handleSubmit(formValues: IDealCustomVariableFormValuesType) {
    // pipelinesId when is a empty array the backend consider all current pipelines and future created pipelines too.
    const pipelinesId = futurePipelinesIncluded ? [] : formValues.pipelinesId;

    const type = formValues.type as FieldTypeEnum;
    if (type === "DATE" && formValues.bruteDefaultValue) {
      formValues.bruteDefaultValue = dayjs(
        formValues.bruteDefaultValue.toString(),
      ).format("DD/MM/YYYY");
    }

    customVariableId > 0
      ? updateDealCustomVariable({
          ...formValues,
          id: customVariableId,
          pipelinesId,
          editable: true,
          hidden: false,
          options: formValues.options ?? undefined,
          defaultValue: formValues.bruteDefaultValue?.toString(),
        })
      : createDealCustomVariable({
          ...formValues,
          pipelinesId,
          editable: true,
          hidden: false,
          options: formValues.options ?? undefined,
          defaultValue: formValues.bruteDefaultValue?.toString(),
        });
  }

  // set initial data on update form
  useEffect(() => {
    if (initialData) {
      const pipelinesId =
        initialData.pipelines.length > 0
          ? initialData.pipelines.map((pipeline) => pipeline.id)
          : undefined;

      const requiredInPipelinesStagesId =
        initialData.pipelinesRequired.length > 0
          ? initialData.pipelinesRequired.map((stage) => stage.id)
          : undefined;

      const pipelineStagesAreRequired =
        (requiredInPipelinesStagesId ?? []).length > 0;

      if (initialData.futurePipelinesIncluded) {
        form.setFieldsValue({ isAllPipelinesChecked: true });
      }
      const bruteDefaultValue = dayjs(
        initialData.defaultValue,
        "DD/MM/YYYY",
      ).isValid()
        ? dayjs(initialData.defaultValue, "DD/MM/YYYY")
        : initialData.defaultValue;

      form.setFieldsValue({
        bruteDefaultValue: bruteDefaultValue || undefined,
        groupId: initialData.group?.id || undefined,
        identifier: initialData.entityIdentifier?.identifier || undefined,
        label: initialData.label || undefined,
        pipelinesId,
        requiredInPipelinesStagesId,
        type: initialData.type || undefined,
        pipelineStagesAreRequired: pipelineStagesAreRequired,
        options: initialData.options,
        futurePipelinesIncluded: initialData.futurePipelinesIncluded,
      });
    }
  }, [form, initialData]);

  // ADD A LOADING STATE
  const isSubmittingForm =
    isCreatingDealCustomVariable || isUpdatingDealCustomVariable;

  return (
    <Form
      data-cy="deal-custom-variable-form"
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      style={{ width: "100%" }}
      initialValues={{
        ...DEFAULT_INITIAL_VALUES,
        ...initialValues,
      }}
    >
      {errorInitialData && (
        <Alert.CardError
          errorMessage={errorInitialData.message}
          reloadFn={reloadInitialData}
          title="Falha ao carregar dados iniciais"
        />
      )}

      <Spin spinning={isLoadingInitialData}>
        <Row>
          <Col span={screens.desktop ? 11 : 24}>
            <SolarzFormItem<IDealCustomVariableFormValuesType>
              name="label"
              label="Nome do campo"
              rules={[{ required: true }]}
            >
              <SolarzInput placeholder="Nome do campo..." />
            </SolarzFormItem>

            <SolarzFormItem<IDealCustomVariableFormValuesType>
              name="groupId"
              label="Sessão do campo"
            >
              <Select.DealCustomFieldGroup
                placeholder="Selecione o tipo do campo..."
                onCreateSuccess={(group) => {
                  form.setFieldsValue({ groupId: group.id });
                }}
              />
            </SolarzFormItem>

            <SolarzFormItem<IDealCustomVariableFormValuesType>
              name="type"
              label="Tipo do campo"
              rules={[{ required: true }]}
            >
              <Select.DealCustomFieldType
                placeholder="Selecione o tipo do campo..."
                allowClear={false}
                hiddenOptions={{
                  IMAGE: true,
                }}
                onChange={() => {
                  form.setFieldsValue({ bruteDefaultValue: undefined });
                }}
                isDisabled={!!customVariableId}
              />
            </SolarzFormItem>

            {type === "SELECT" && (
              <SolarzFormItem<IDealCustomVariableFormValuesType>
                name="options"
                label="Opções"
                rules={[{ required: true }]}
              >
                <SolarzSelect
                  mode="tags"
                  placeholder="Digite as opções..."
                  allowClear
                  emptyMessage="Digite alguma opção e tecle enter"
                />
              </SolarzFormItem>
            )}

            <SolarzFormItem<IDealCustomVariableFormValuesType>
              name="bruteDefaultValue"
              label="Valor padrão"
              preserve
            >
              {type === "SELECT" ? (
                <SolarzSelect
                  options={options.map((option) => {
                    return {
                      label: option,
                      value: option,
                      record: {
                        value: option,
                      },
                    };
                  })}
                  placeholder="Digite as opções..."
                  allowClear
                  emptyMessage="Adicione opções no campo acima para selecionar a opção padrão"
                />
              ) : (
                getInputComponentByType(type)
              )}
            </SolarzFormItem>

            <SolarzFormItem<IDealCustomVariableFormValuesType>
              name="identifier"
              label="Identificador"
            >
              <SolarzInput placeholder="Identificador único do campo..." />
            </SolarzFormItem>
          </Col>
          <Col span={screens.desktop ? 2 : 24}>
            <Flex justify="center" style={{ width: "100%", height: "100%" }}>
              <Divider
                type={screens.desktop ? "vertical" : "horizontal"}
                style={
                  screens.desktop
                    ? { height: "100%" }
                    : { margin: "0.5rem 0 1rem 0" }
                }
              />
            </Flex>
          </Col>
          <Col
            span={screens.desktop ? 11 : 24}
            style={{
              maxHeight: screens.desktop ? maxHeight : "unset",
              overflowY: "auto",
            }}
          >
            <div className="bg-neutral-100 border border-neutral-200 dark:bg-neutral-800 dark:border-neutral-800 rounded-sm py-3 px-4">
              <div className="flex gap-2 items-center justify-between">
                <Flex gap={8}>
                  <Tooltip
                    title={
                      !isAllPipelinesChecked &&
                      "Para ativar é necessário que todos os funis estejam selecionados"
                    }
                  >
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Switch
                        id="apply-in-future-pipelines"
                        checked={!!futurePipelinesIncluded}
                        disabled={!isAllPipelinesChecked}
                        onChange={(checked) => {
                          form.setFieldsValue({
                            futurePipelinesIncluded: checked,
                          });
                        }}
                      />
                    </div>
                  </Tooltip>
                  <label
                    htmlFor="apply-in-future-pipelines"
                    style={{ fontWeight: 500 }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    Aplicar nos funis futuros
                  </label>
                </Flex>

                <Tooltip title="Quando ativado, esse campo personalizado será aplicado para os funis que forem criados futuramente. OBS: Para funis futuros esse campo NÃO será obrigatório, caso queira deixar este campo obrigatório em algum funil é necessário configurar a obrigatoriedade para o funil especifico.">
                  <Info className="h-4 w-4 cursor-help" />
                </Tooltip>
              </div>
            </div>
            <SolarzFormItem<IDealCustomVariableFormValuesType>
              name="pipelinesId"
              rules={[
                {
                  required: true,
                  message: "É necessário selecionar ao menos um funil",
                },
              ]}
            >
              <Collapse.PipelineCheckboxGroup
                selectedPipelinesIds={pipelinesId}
                isAllChecked={isAllPipelinesChecked}
                onChangeSelectedPipelinesIds={(
                  pipelineIds,
                  isAllPipelinesChecked,
                ) => {
                  form.setFieldsValue({
                    pipelinesId: pipelineIds,
                    isAllPipelinesChecked,
                  });
                }}
              />
            </SolarzFormItem>

            <Divider style={{ margin: "1rem 0" }} />

            <SolarzFormItem<IDealCustomVariableFormValuesType>
              name="requiredInPipelinesStagesId"
              rules={[
                {
                  required: pipelineStagesAreRequired,
                  message:
                    "Campo esta marcado como obrigatório, por favor selecione ao menos um valor",
                },
              ]}
            >
              <Collapse.PipelineStagesCheckboxGroup
                displayOnly={pipelinesId}
                selectedStagesIds={requiredInPipelinesStagesId}
                onChangeSelectedStagesIds={(stagesIds) => {
                  form.setFieldsValue({
                    requiredInPipelinesStagesId: stagesIds,
                  });
                }}
                canSelectItems={pipelineStagesAreRequired}
                canSelectItemsIsDisabled={pipelinesId.length === 0}
                onChangeCanSelectItems={(isRequired) => {
                  form.setFieldsValue({
                    pipelineStagesAreRequired: isRequired,
                  });
                }}
              />
            </SolarzFormItem>
          </Col>
        </Row>
      </Spin>

      <Flex
        align="center"
        gap={displayFooterAsFullWidth ? 16 : 8}
        justify={displayFooterAsFullWidth ? "center" : "end"}
        style={{ marginTop: "0.75rem" }}
      >
        <SolarzButton
          variant="secondary"
          onClick={onCancel}
          scale="medium"
          style={{ width: displayFooterAsFullWidth ? "100%" : "unset" }}
        >
          Cancelar
        </SolarzButton>
        <SolarzButton
          htmlType="submit"
          isLoading={isSubmittingForm}
          scale="medium"
          style={{ width: displayFooterAsFullWidth ? "100%" : "unset" }}
        >
          {hasUpdateForm ? "Atualizar" : "Cadastrar"}
        </SolarzButton>
      </Flex>
    </Form>
  );
}
