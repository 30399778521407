import styled from "@emotion/styled";
import { Card } from "antd";

export const StyledCard = styled(Card)`
  background-color: var(--gray-50);

  html.dark & {
    background-color: rgb(20, 20, 20);
  }
`;
