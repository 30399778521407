import { useState } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { BiSelectMultiple } from "react-icons/bi";

import type {
  IAdditionalCostTaxesTableProps,
  IAdditionalCostTaxesTableColumn,
  IAdditionalCostTaxesTableData,
  IAdditionalCostTax,
} from "./interface";

import { Menu } from "~components/Menu";
import { Modal } from "~components/Modal";
import { Result } from "~components/Result";
import { Tag } from "~components/Tag";
import {
  useFetchDeleteAdditionalCostTax,
  useFetchGetAdditionalCostTaxes,
  useFetchSetDefaultAdditionalCostTax,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { parsePercentage } from "~utils/parse";
import { Flex, Table } from "antd";

export function AdditionalCostTaxesTable({
  displayActionColumn = true,
}: IAdditionalCostTaxesTableProps) {
  const { message } = useAppConfig();

  const [
    selectedAdditionalCostTaxToUpdate,
    setSelectedAdditionalCostTaxToUpdate,
  ] = useState<IAdditionalCostTax>();

  const [
    selectedAdditionalCostTaxToDelete,
    setSelectedAdditionalCostTaxToDelete,
  ] = useState<IAdditionalCostTax>();

  const [
    selectedAdditionalCostTaxToSetAsDefault,
    setSelectedAdditionalCostTaxToSetAsDefault,
  ] = useState<IAdditionalCostTax>();

  const {
    data: taxes,
    isFetching: isLoadingTaxes,
    error: taxesError,
  } = useFetchGetAdditionalCostTaxes({});

  const {
    mutate: deleteAdditionalCostTax,
    isLoading: isDeletingAdditionalCostTax,
  } = useFetchDeleteAdditionalCostTax({
    options: {
      onSuccess: () => setSelectedAdditionalCostTaxToDelete(undefined),
    },
  });

  const {
    mutate: setAdditionalCostTaxAsDefault,
    isLoading: isSettingAdditionalCostTaxAsDefault,
  } = useFetchSetDefaultAdditionalCostTax({
    options: {
      onSuccess: () => setSelectedAdditionalCostTaxToSetAsDefault(undefined),
    },
  });

  function handleDeleteAdditionalCostTax() {
    const taxId = selectedAdditionalCostTaxToDelete?.id;

    if (!taxId) {
      message.error("Client error: Faltando o ID da alíquota de imposto");
      return;
    }

    deleteAdditionalCostTax({ additionalCostTaxId: taxId });
  }

  function handleSetAdditionalCostTaxAsDefault() {
    const taxId = selectedAdditionalCostTaxToSetAsDefault?.id;

    if (!taxId) {
      message.error("Client error: Faltando o ID da alíquota de imposto");
      return;
    }

    setAdditionalCostTaxAsDefault({ idAdditionalCostTax: taxId });
  }

  // RENDERS
  function renderNameColumn(value: string, isDefault: boolean) {
    return (
      <Flex align="center" gap={4}>
        {value}
        {isDefault && (
          <Tag.UniqueValue
            tooltip="Este item é o imposto padrão"
            title="Padrão"
            color="green"
          />
        )}
      </Flex>
    );
  }

  function renderActiveColumn(value: boolean) {
    const status = value ? "Ativado" : "Desativado";

    return (
      <Tag.UniqueValue
        tooltip={status}
        title={status}
        color={status === "Ativado" ? "green" : "volcano"}
      />
    );
  }

  function renderActionColumn(costTax: IAdditionalCostTaxesTableData) {
    return (
      <Menu.MoreDropdown
        items={[
          {
            icon: (
              <AiOutlineEdit
                fontSize={18}
                style={{ color: "var(--gold-500)" }}
              />
            ),
            text: "Alterar",
            color: "var(--gold-500)",
            onClick: () => setSelectedAdditionalCostTaxToUpdate(costTax),
          },
          {
            icon: (
              <AiOutlineDelete
                fontSize={16}
                style={{ color: "var(--volcano-500)" }}
              />
            ),
            text: "Remover",
            color: "var(--volcano-500)",
            onClick: () => setSelectedAdditionalCostTaxToDelete(costTax),
          },
          {
            icon: (
              <BiSelectMultiple
                fontSize={16}
                style={{ color: "var(--blue-500)" }}
              />
            ),
            text: "Definir como padrão",
            color: "var(--blue-500)",
            onClick: () => setSelectedAdditionalCostTaxToSetAsDefault(costTax),
            isDisabled: costTax.defaultTax,
            tooltip: costTax.defaultTax ? "Este imposto já é o padrão" : "",
          },
        ]}
        position="bottomLeft"
      />
    );
  }

  const columns = (
    [
      {
        title: "Nome",
        dataIndex: "name",
        width: 224,
        render: (name: string, { defaultTax }) =>
          renderNameColumn(name, defaultTax),
      },
      {
        title: "Identificador",
        dataIndex: "identifier",
        width: 224,
      },
      {
        title: "Taxa",
        dataIndex: "rate",
        render: (value: number) => parsePercentage(value),
        align: "center" as const,
        width: 72,
      },
      {
        title: "Ativado",
        dataIndex: "active",
        width: 64,
        align: "center" as const,
        render: (value: boolean) => renderActiveColumn(value),
      },
      {
        title: "",
        dataIndex: "action",
        width: 32,
        align: "center" as const,
        render: (_, costTax) => renderActionColumn(costTax),
        hide: !displayActionColumn,
      },
    ] as IAdditionalCostTaxesTableColumn[]
  ).filter((column) => !column.hide);

  const dataSource: IAdditionalCostTaxesTableData[] = taxes
    ? taxes.map((tax) => ({ ...tax, key: tax.id }))
    : [];

  return (
    <>
      <Table
        data-cy="additional-cost-taxes-table"
        rowKey="key"
        columns={columns}
        dataSource={dataSource}
        size="small"
        pagination={false}
        loading={isLoadingTaxes}
        locale={{
          emptyText: (
            <Result.TableErrorOrEmpty
              errorMessage={taxesError?.message}
              emptyMessage="Nenhuma alíquota de imposto encontrada"
            />
          ),
        }}
        style={{ width: "100%" }}
        scroll={{
          x: 767,
        }}
      />

      <Modal.AdditionalCostTaxForm
        isOpen={!!selectedAdditionalCostTaxToUpdate}
        onClose={() => setSelectedAdditionalCostTaxToUpdate(undefined)}
        formProps={{
          initialValues: selectedAdditionalCostTaxToUpdate,
          onSuccess: () => setSelectedAdditionalCostTaxToUpdate(undefined),
        }}
      />

      <Modal.DefaultRemove
        isOpen={!!selectedAdditionalCostTaxToDelete}
        isLoading={isDeletingAdditionalCostTax}
        itemName={selectedAdditionalCostTaxToDelete?.name}
        onClose={() => setSelectedAdditionalCostTaxToDelete(undefined)}
        suffix="imposto"
        title="Remover alíquota de imposto"
        onSubmit={handleDeleteAdditionalCostTax}
        alertMessage="Se o imposto estiver sendo usado em outros custos ele não será mais considerado"
      />

      <Modal.DefaultAlert
        isOpen={!!selectedAdditionalCostTaxToSetAsDefault}
        isLoading={isSettingAdditionalCostTaxAsDefault}
        onClose={() => setSelectedAdditionalCostTaxToSetAsDefault(undefined)}
        onSubmit={handleSetAdditionalCostTaxAsDefault}
        title="Aviso"
        subtitle="Você esta certo que deseja setar este imposto como imposto padrão?"
      />
    </>
  );
}
