import type { IKanbanHeaderProps } from "./interface";

import { SolarzTypography } from "~solarzui/SolarzTypography";
import { Badge, Flex } from "antd";

export function KanbanHeader<
  ColumnRecordType extends Record<string, any>,
  TaskRecordType extends Record<string, any>,
>({
  column,
  tasks,
  renderHeader,
}: IKanbanHeaderProps<ColumnRecordType, TaskRecordType>) {
  if (typeof renderHeader === "function") {
    return renderHeader({ column, tasks });
  }

  return (
    <Flex align="center" gap={8}>
      <SolarzTypography
        fontWeight="medium"
        hierarchy="paragraph1"
        variant="title"
        style={{
          width: "100%",
        }}
        ellipse
      >
        {column.title}
      </SolarzTypography>
      <Badge color="blue" count={tasks.length} showZero />
    </Flex>
  );
}
