import { useMemo, useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { BiUserCheck, BiUserX } from "react-icons/bi";

import type { AdminsTableDataType } from "./interface";

import { Menu } from "~components/Menu";
import { Modal } from "~components/Modal";
import { Tag } from "~components/Tag";
import {
  useFetchPatchSysAdminUser,
  useFetchGetPaginatedSysAdminUser,
} from "~hooks/api";
import { SolarzTable } from "~solarzui/SolarzTable";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { createUuid } from "~utils/createUuid";

export function AdminsTable() {
  const [selectedAdminToDeactivate, setSelectedAdminToDeactivate] =
    useState<AdminsTableDataType>();

  const [currentPage, setCurrentPage] = useState(0);

  const [selectedAdminToUpdate, setSelectedAdminToUpdate] =
    useState<AdminsTableDataType>();

  const {
    data: adminsData,
    isFetching: isLoadingAdmins,
    error: adminsError,
    refetch: reloadAdmins,
  } = useFetchGetPaginatedSysAdminUser({
    dependencyArray: [currentPage],
    payload: {
      page: currentPage,
      size: 10,
    },
  });

  const { mutate: deactivateAdmin, isLoading: isLoadingDeactivation } =
    useFetchPatchSysAdminUser({});

  function onPageChange(page: number) {
    setCurrentPage(page);
  }

  const DATA_SOURCE = useMemo(
    () =>
      adminsData?.content.map((admin) => ({
        ...admin,
        key: createUuid(),
      })) ?? [],
    [adminsData?.content],
  );

  return (
    <>
      <SolarzTable
        data-cy="admins-table"
        isLoading={isLoadingAdmins}
        columns={[
          {
            title: "ID",
            dataIndex: "id",
            key: "id",
            render: (_, record) => (
              <SolarzTypography fontWeight="regular" style={{ fontSize: 14 }}>
                #{record.id}
              </SolarzTypography>
            ),
          },
          {
            title: "Nome",
            dataIndex: "name",
            key: "name",
            render: (_, record) => (
              <SolarzTypography fontWeight="semiBold" style={{ fontSize: 14 }}>
                {record.name}
              </SolarzTypography>
            ),
          },
          {
            title: "E-mail",
            dataIndex: "email",
            key: "email",
            render: (_, record) => (
              <SolarzTypography fontWeight="regular" style={{ fontSize: 14 }}>
                {record.email}
              </SolarzTypography>
            ),
          },
          {
            title: "Status",
            key: "status",
            align: "center",
            render: (_, { active }) => (
              <Tag.UniqueValue
                tooltip={`O administrador está ${active ? "ativo" : "inativo"}`}
                title={active ? "Ativo" : "Inativo"}
                color={active ? "green" : "volcano"}
              />
            ),
          },
          {
            title: "",
            width: 32,
            align: "center",
            render: (_, record) => (
              <Menu.MoreDropdown
                items={[
                  {
                    icon: <AiOutlineEdit size={18} color="var(--gold-500)" />,
                    text: "Alterar",
                    color: "var(--gold-500)",
                    onClick: () => setSelectedAdminToUpdate(record),
                  },
                  {
                    hidden: !record.active,
                    icon: record.active ? (
                      <BiUserX size={18} color="var(--red-500)" />
                    ) : (
                      <BiUserCheck size={18} color="var(--green-500)" />
                    ),
                    text: record.active ? "Desativar" : "Ativar",
                    color: record.active
                      ? "var(--red-500)"
                      : "var(--green-500)",
                    onClick: () => setSelectedAdminToDeactivate(record),
                  },
                ]}
                position="bottomLeft"
              />
            ),
          },
        ]}
        rows={DATA_SOURCE}
        pagination={{
          currentItemCount: adminsData?.size ?? 0,
          currentPage: adminsData?.number ? adminsData?.number + 1 : 1,
          itemLabel: "administradores",
          totalItemCount: adminsData?.totalElements ?? 0,
          onChange: (page) => onPageChange(page - 1),
        }}
        rowKey="id"
        scroll={{ x: 660 }}
        emptyMessage="Nenhum administrador encontrado"
        errorMessage={adminsError?.message}
        reloadFn={reloadAdmins}
      />

      <Modal.AdminForm
        isOpen={!!selectedAdminToUpdate}
        onClose={() => setSelectedAdminToUpdate(undefined)}
        formProps={{
          initialValues: selectedAdminToUpdate,
          onSuccess: () => setSelectedAdminToUpdate(undefined),
        }}
      />

      <Modal.DefaultRemove
        isOpen={!!selectedAdminToDeactivate}
        onClose={() => setSelectedAdminToDeactivate(undefined)}
        isLoading={isLoadingDeactivation}
        onSubmit={() =>
          deactivateAdmin({ id: selectedAdminToDeactivate?.id ?? 0 })
        }
        itemName={selectedAdminToDeactivate?.name}
        suffix="administrador"
        title="Desativar administrador"
        alertMessage=""
      />
    </>
  );
}
