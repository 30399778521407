import { BiErrorCircle } from "react-icons/bi";

import { ISelectSuffixIconProps } from "./interface";

import { Spin } from "antd";

export function SelectSuffixIcon({
  hasError,
  isLoading,
}: ISelectSuffixIconProps) {
  if (isLoading) {
    return <Spin size="small" spinning />;
  }

  if (hasError) {
    return <BiErrorCircle size={18} />;
  }

  return <></>;
}
