import { useMemo, useState } from "react";

import type { ICreateProposalExpirationRuleButtonProps } from "./interface";

import { Modal } from "~components/Modal";
import { useFetchGetProposalExpirationRule } from "~hooks/api";
import { Button, Tooltip } from "antd";

export function CreateProposalExpirationRuleButton({
  children,
  checkIfAlreadyExistsRule = false,
  ...props
}: ICreateProposalExpirationRuleButtonProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    data: proposalExpirationRule = [],
    isFetching: isLoadingProposalExpirationRule,
    error: proposalExpirationRuleError,
  } = useFetchGetProposalExpirationRule({
    dependencyArray: [checkIfAlreadyExistsRule],
    options: {
      enabled: checkIfAlreadyExistsRule,
    },
  });

  const tooltipTile = useMemo(() => {
    if (!checkIfAlreadyExistsRule) return undefined;

    if (proposalExpirationRuleError) {
      return "Obtivemos um error ao solicitar se existe uma regra ativa, por favor, recarregue a página.";
    }

    return proposalExpirationRule.length > 0
      ? "Já existe uma regra cadastrada. Você pode adicionar mais períodos para diferentes potências à regra existente. Se desejar, você pode excluir a regra existente e cadastrar uma nova."
      : "Cadastre uma regra de expiração da proposta";
  }, [
    proposalExpirationRule.length,
    proposalExpirationRuleError,
    checkIfAlreadyExistsRule,
  ]);

  return (
    <>
      <Tooltip title={tooltipTile}>
        <Button
          {...props}
          data-cy="create-proposal-expiration-rule-button"
          onClick={() => setIsModalOpen(true)}
          type="primary"
          htmlType="button"
          loading={isLoadingProposalExpirationRule}
        >
          {children ?? "Criar regra de expiração"}
        </Button>
      </Tooltip>

      <Modal.ProposalExpirationRuleForm
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        formProps={{
          onSubmitSuccess: () => setIsModalOpen(false),
        }}
      />
    </>
  );
}
