import { useEffect } from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";

import type { IAuxiliaryVariablesFormProps } from "./interface";

import { CustomInput } from "~components/CustomInput";
import { Mentions } from "~components/Mentions";
import { Select } from "~components/Select";
import {
  useFetchCreateComplementaryItemsOfSettings,
  useFetchUpdateComplementaryItemsOfSettings,
} from "~hooks/api";
import { useSession } from "~hooks/useSession";
import { IAdditionalCostFormSchema } from "~types/schemas";
import { indentTextAreaCode } from "~utils/indentTextAreaCode";
import {
  Button,
  Col,
  Form,
  Row,
  Select as AntdSelect,
  Tooltip,
  Alert,
  Flex,
} from "antd";

export function AuxiliaryVariablesForm({
  initialValues,
  onSuccess = () => undefined,
  onError = () => undefined,
  onCancel = () => undefined,
}: IAuxiliaryVariablesFormProps) {
  const { user } = useSession();

  const [form] = Form.useForm<IAdditionalCostFormSchema>();

  const {
    mutate: createComplementaryItems,
    isLoading: isCreatingComplementaryItems,
  } = useFetchCreateComplementaryItemsOfSettings({
    options: {
      onSuccess: () => {
        onSuccess();
      },
      onError: (error) => {
        onError(error.message);
      },
    },
  });

  const {
    mutate: fetchUpdateComplementaryItems,
    isLoading: isUpdateComplementaryItems,
  } = useFetchUpdateComplementaryItemsOfSettings({
    options: {
      onSuccess: () => {
        onCancel();
      },
    },
  });

  async function handleSubmitForm(data: IAdditionalCostFormSchema) {
    const payload = {
      ...data,
      valueFlexibilityType: "FIXED",
      costGroup: "CUSTOS_COMPLEMENTARES",
      isAuxVariable: true,
      calculationType: "EXPRESSAO_PERSONALIZADA",
      executionOrder: data.executionOrder ?? 1,
    } as const;

    if (initialValues?.id) {
      fetchUpdateComplementaryItems({
        ...payload,
        additionalCostId: initialValues?.id,
      });
    } else {
      createComplementaryItems(payload);
    }
  }

  const isLoading = isCreatingComplementaryItems || isUpdateComplementaryItems;

  const identifierFieldIsDisabled =
    initialValues?.identifier === "cc_lucro" || initialValues?.fixed;

  useEffect(() => {
    if (user?.pricingTypeId) {
      form.setFieldValue("pricingType", user?.pricingTypeId);
    }
  }, [user?.pricingTypeId, form]);

  return (
    <Form
      data-cy="complementary-items-of-settings"
      form={form}
      layout="vertical"
      onFinish={handleSubmitForm}
      initialValues={{
        ...initialValues,
        calculationType:
          initialValues?.calculationType ?? "EXPRESSAO_PERSONALIZADA",
      }}
    >
      {/* FORM CONTENT */}
      <Flex>
        <Col xs={24} xl={24}>
          <Row gutter={16}>
            <Col xs={24} xl={12}>
              <Form.Item label="Nome" name="name" rules={[{ required: true }]}>
                <CustomInput.Text data-cy="name" placeholder="Nome" />
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item
                label="Identificador"
                name="identifier"
                hasFeedback
                rules={[
                  {
                    pattern: new RegExp(/^[A-Za-z_-]*$/),
                    message: "Apenas letras, underlines ou traços",
                  },
                ]}
                tooltip={`Esse campo é um campo único, utilizado para referenciar o custo${
                  identifierFieldIsDisabled
                    ? " (não se pode alterar o identificador dos campos fixados)"
                    : ""
                }.`}
              >
                <CustomInput.Text
                  placeholder="Identificador"
                  disabled={identifierFieldIsDisabled}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={10}>
              <Form.Item
                name="calculationType"
                label="Selecione a operação:"
                rules={[{ required: true }]}
              >
                <AntdSelect
                  placeholder="Selecione uma opção..."
                  options={[
                    {
                      value: "EXPRESSAO_PERSONALIZADA",
                      label: "Expressão personalizada",
                    },
                  ]}
                  value={"EXPRESSAO_PERSONALIZADA"}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="pricingType"
                label="Precificação"
                rules={[{ required: true }]}
              >
                <Select.PricingType isDisabled={true} scale="tiny" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="executionOrder" label="Ordem de execução">
                <CustomInput.Number min={0} precision={0} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24} xl={24}>
              <Form.Item
                style={{
                  width: "100%",
                }}
                name="customExpression"
                label={
                  <Flex
                    gap={0}
                    align="center"
                    justify="space-between"
                    style={{ width: "100%" }}
                  >
                    <div>
                      <span>Expressão personalizada</span>

                      <Tooltip title="Trecho de código javascript.">
                        <AiOutlineQuestionCircle
                          size={18}
                          style={{ marginLeft: 4 }}
                        />
                      </Tooltip>
                    </div>
                  </Flex>
                }
                help={
                  <Alert
                    style={{ margin: "0.5rem 0" }}
                    message="Para expressões javascript com condicionais o 'return' deve ser informado, e não utilizar loopings."
                  />
                }
                extra={
                  <Alert
                    style={{ marginBottom: "0.5rem" }}
                    message='Para a utilização de números decimais utilize "." ao invés de "," (ponto ao invés de virgula)'
                  />
                }
              >
                <Mentions.Variables
                  initialValueField={indentTextAreaCode(
                    initialValues?.customExpression ?? "",
                  )}
                  rows={7}
                  onChange={(value) =>
                    form.setFieldValue("customExpression", value)
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Flex>

      <Flex gap={12} justify="end">
        <Form.Item style={{ margin: 0 }}>
          <Button onClick={onCancel}>Cancelar</Button>
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button
            data-cy="save-button"
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            Salvar
          </Button>
        </Form.Item>
      </Flex>
    </Form>
  );
}
