import { api, apiException } from "~services/api";
import {
  FetchGetDownloadProductQueuePayloadSchema,
  IFetchGetDownloadProductQueuePayloadSchema,
  ImportProductFileResponseDTOSchema,
} from "~types/schemas";

export const fetchGetDownloadProductQueue = async (
  payload: IFetchGetDownloadProductQueuePayloadSchema,
) => {
  try {
    const { id } = FetchGetDownloadProductQueuePayloadSchema.parse(payload);

    const response = await api.get(`product-queue/download/${id}`);

    const parsedData = ImportProductFileResponseDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
