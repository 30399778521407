import type { ISolarzSelectProps } from "./interface";

import styled from "@emotion/styled";
import { Select, SelectProps } from "antd";

export interface IStyledSelectProps extends SelectProps {
  scale?: ISolarzSelectProps<any, any>["scale"];
}

function getStylesByScaleProps(scale: ISolarzSelectProps<any, any>["scale"]) {
  switch (scale) {
    case "large":
      return {
        padding: "0 1rem",
        minHeight: "3rem",
      };
    case "medium":
      return {
        padding: "0 1rem",
        minHeight: "2.5rem",
      };
    case "tiny":
      return {
        padding: "0 1rem",
        minHeight: "2rem",
      };
    default:
      return {
        padding: "0 1rem",
        minHeight: "2.5rem",
      };
  }
}

export const StyledSelect = styled(Select<any>)<IStyledSelectProps>`
  min-height: ${(props) => getStylesByScaleProps(props.scale).minHeight};

  .ant-select {
    padding: 0 1rem !important;
  }

  .ant-select-arrow {
    padding-right: 0.5rem;

    svg {
      width: 14px;
      color: var(--primary-500);
    }
  }

  .ant-select-clear {
    width: 14px;

    top: calc(50% - 1.5px);
    right: 19px;

    svg {
      width: 14px;
      height: 14px;
      color: var(--primary-500);
    }
  }

  .ant-select-selection-item {
    padding-left: 0.5rem !important;
  }

  .solarz-select-tag {
    background-color: var(--primary-50);
    border: none;
    color: var(--primary-500);
    font-weight: 500;

    svg {
      margin-left: 0.25rem;
      color: var(--primary-500);
      height: 10px;
      width: 10px;

      :hover {
        color: var(--primary-600);
      }
    }
  }
`;
