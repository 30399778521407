import { IoReload } from "react-icons/io5";

import type { ICardErrorAlertProps } from "./interface";

import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { Alert, Flex, Tooltip } from "antd";

export function CardErrorAlert({
  title = "Ops!",
  errorMessage,
  reloadFn,
  style,
  className,
}: ICardErrorAlertProps) {
  return (
    <Alert
      description={
        <Flex vertical>
          <Flex justify="space-between">
            <SolarzTypography
              variant="danger"
              hierarchy="paragraph2"
              fontWeight="medium"
            >
              {title}
            </SolarzTypography>

            {typeof reloadFn === "function" && (
              <Tooltip title="Recarregar dados">
                <div>
                  <SolarzButton
                    onClick={reloadFn}
                    scale="tiny"
                    variant="ghost"
                    style={{
                      width: 24,
                      height: 24,
                      padding: 2,
                    }}
                    icon={<IoReload />}
                  />
                </div>
              </Tooltip>
            )}
          </Flex>

          <SolarzTypography hierarchy="small" variant="danger">
            {errorMessage}
          </SolarzTypography>
        </Flex>
      }
      style={{
        padding: "8px 16px",
        ...style,
      }}
      className={className}
    />
  );
}
