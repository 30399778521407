import { useEffect } from "react";
import { BsQuestionCircle } from "react-icons/bs";
import { FaCheck, FaMinus } from "react-icons/fa";

import {
  IPermissionsProfileFormProps,
  IPermissionsProfileFormValues,
} from "./interface";
import { StyledPanel } from "./styles";

import { Alert } from "~components/Alert";
import { Select } from "~components/Select";
import {
  useFetchGetAllPermissions,
  useFetchGetProfileById,
  useFetchUpdateProfile,
} from "~hooks/api";
import { useSession } from "~hooks/useSession";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { PermissionsEnum } from "~types/enum";
import { Button, Checkbox, Collapse, Divider, Form, Tooltip } from "antd";

export function PermissionsProfileForm({
  initialValues,
  onSuccess,
  onError,
  onCancel,
  profileId,
  hiddenButtons = true,
  form: formInstance,
  isEditingPermissions,
  onLoadInitialValues,
}: IPermissionsProfileFormProps) {
  const { user } = useSession();
  const [form] = Form.useForm<IPermissionsProfileFormValues>(formInstance);

  //watchers
  const permissionsWatcher = (Form.useWatch("permissions", form) ??
    []) as PermissionsEnum[];

  const {
    data: permissions = [],
    isFetching: isLoadingPermissions,
    error: permissionsError,
    refetch: refetchPermissions,
  } = useFetchGetAllPermissions({
    options: {
      retry: 1,
    },
  });

  const {
    data: profile,
    isFetching: isLoadingProfile,
    error: profileError,
    refetch: reloadProfile,
  } = useFetchGetProfileById({
    dependencyArray: [profileId],
    payload: {
      profileId,
    },
    options: {
      enabled: profileId > 0,
      onSuccess: (data) => {
        if (typeof onLoadInitialValues === "function") {
          onLoadInitialValues(data);
        }
      },
    },
  });

  const { mutate: updateProfile, isLoading: isUpdatingProfile } =
    useFetchUpdateProfile({
      options: {
        onSuccess,
        onError,
      },
    });

  function handleSubmitForm(formValues: IPermissionsProfileFormValues) {
    updateProfile({
      ...formValues,
      profileId: profileId,
      name: profile?.name || "",
      userGroupId: formValues.userGroupId,
    });
  }

  function getAllSectionPermissions(section: string) {
    const selectedSection = permissions.find(
      (permission) => permission.section === section,
    );
    if (!selectedSection) {
      return [];
    }
    return selectedSection.permissions.map((permission) => permission.value);
  }

  function getSectionSelectedPermissions(section: string) {
    const allSectionPermissions = getAllSectionPermissions(section);
    const currentPermissions = (form.getFieldValue("permissions") ??
      []) as PermissionsEnum[];
    return currentPermissions.filter((permission) => {
      return allSectionPermissions.includes(permission);
    });
  }

  function handleAddPermission(permission: PermissionsEnum) {
    const currentPermissions = (form.getFieldValue("permissions") ??
      []) as NonNullable<IPermissionsProfileFormValues["permissions"]>;

    form.setFieldsValue({
      permissions: [...currentPermissions, permission],
    });
  }

  function handleRemovePermission(permission: PermissionsEnum) {
    const currentPermissions = (form.getFieldValue("permissions") ??
      []) as NonNullable<IPermissionsProfileFormValues["permissions"]>;

    form.setFieldsValue({
      permissions: currentPermissions.filter(
        (currentPermission) => currentPermission !== permission,
      ),
    });
  }

  function handleSelectAllFromSection(section: string) {
    const selectedSection = permissions.find(
      (permission) => permission.section === section,
    );
    if (!selectedSection) {
      return;
    }
    const sectionPermissionsValues = selectedSection.permissions.map(
      (permission) => permission.value,
    );

    const currentPermissions = (form.getFieldValue("permissions") ??
      []) as PermissionsEnum[];

    const newStatePermissions = [
      ...new Set([...currentPermissions, ...sectionPermissionsValues]),
    ] as PermissionsEnum[];

    form.setFieldsValue({ permissions: newStatePermissions });
  }

  function handleUnselectAllFromSection(section: string) {
    const selectedSection = permissions.find(
      (permission) => permission.section === section,
    );
    if (!selectedSection) {
      return;
    }
    const sectionPermissionsValues = selectedSection.permissions.map(
      (permission) => permission.value,
    );

    const currentPermissions = (form.getFieldValue("permissions") ??
      []) as PermissionsEnum[];

    const filteredCurrentPermissions = currentPermissions.filter(
      (permission) => {
        return !sectionPermissionsValues.includes(permission);
      },
    );

    form.setFieldsValue({ permissions: filteredCurrentPermissions });
  }

  function handleCheck(isChecked: boolean, section: string) {
    return isChecked
      ? handleSelectAllFromSection(section)
      : handleUnselectAllFromSection(section);
  }

  function handleCancel() {
    if (typeof onCancel === "function") {
      return onCancel();
    }

    return form.setFieldsValue({
      permissions: profile?.permissions || [],
      userGroupId: profile?.userGroupId,
    });
  }

  useEffect(() => {
    if (profile?.permissions && Number(profile?.permissions?.length) > 0) {
      form.setFieldsValue({
        permissions: profile.permissions,
        userGroupId: profile.userGroupId,
      });
    }
  }, [profile, form]);

  return (
    <Form
      data-cy="permissions-profile-form"
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmitForm}
      layout="vertical"
    >
      {profileError && (
        <Alert.CardError
          errorMessage={profileError?.message}
          title="Falha ao carregar dados iniciais"
          reloadFn={reloadProfile}
          style={{ marginBottom: 16 }}
        />
      )}

      {permissionsError && (
        <Alert.CardError
          errorMessage={permissionsError?.message}
          title="Falha ao carregar permissões"
          reloadFn={refetchPermissions}
          style={{ marginBottom: 16 }}
        />
      )}

      <Form.Item name="permissions" hidden />

      <Collapse expandIconPosition="right" style={{ width: "100%" }}>
        {permissions.map((section) => {
          const sectionSelectedPermissions = getSectionSelectedPermissions(
            section.section,
          );
          return (
            <StyledPanel
              header={
                <div className="w-full flex flex-row gap-3">
                  {isEditingPermissions && (
                    <Checkbox
                      style={{ paddingLeft: 16 }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onChange={(e) => {
                        handleCheck(e.target.checked, section.section);
                      }}
                      id={section.section}
                      indeterminate={
                        sectionSelectedPermissions.length > 0 &&
                        sectionSelectedPermissions.length <
                          section.permissions.length
                      }
                      checked={
                        sectionSelectedPermissions.length ===
                        section.permissions.length
                      }
                      disabled={
                        isLoadingPermissions ||
                        isUpdatingProfile ||
                        isLoadingProfile
                      }
                    />
                  )}
                  <label
                    onClick={(e) => {
                      if (isEditingPermissions) {
                        e.stopPropagation();
                      }
                    }}
                    htmlFor={section.section}
                    className="cursor-pointer"
                  >
                    <SolarzTypography fontWeight="semiBold" hierarchy="small">
                      {section.description}
                    </SolarzTypography>
                  </label>
                </div>
              }
              key={section.section}
              className="[&_.ant-collapse-content-box]:p-0"
            >
              {section.section === "USER_GROUP" && isEditingPermissions && (
                <>
                  <Form.Item<IPermissionsProfileFormValues>
                    label="Grupos"
                    style={{
                      width: "100%",
                      padding: "12px 16px 16px 32px",
                      marginBottom: 0,
                    }}
                    name="userGroupId"
                  >
                    <Select.UserGroup
                      onChange={(value) => {
                        form.setFieldsValue({ userGroupId: value });
                      }}
                    />
                    <SolarzTypography fontWeight="medium" hierarchy="small">
                      O grupo selecionado será o mesmo para todas as permissões
                      de grupos
                    </SolarzTypography>
                  </Form.Item>
                  <Divider style={{ margin: 0, padding: 0 }} />
                </>
              )}
              <div className="w-full flex flex-col">
                {section.permissions.map((permission) => {
                  if (!permission.value) return;

                  if (
                    user?.pipedriveIntegration &&
                    (permission.value === "ACCESS_OWN_DEALS" ||
                      permission.value === "ACCESS_GROUP_DEALS")
                  ) {
                    return null;
                  }

                  return (
                    <>
                      <div
                        key={permission.value}
                        className="w-full flex items-center px-4 py-3"
                      >
                        {isEditingPermissions ? (
                          <Checkbox
                            onChange={(e) => {
                              return e.target.checked
                                ? handleAddPermission(e.target.value)
                                : handleRemovePermission(e.target.value);
                            }}
                            key={permission.value}
                            value={permission.value}
                            checked={
                              !!permissionsWatcher.includes(
                                permission.value as PermissionsEnum,
                              )
                            }
                            id={permission.value}
                            disabled={
                              isLoadingPermissions ||
                              isUpdatingProfile ||
                              isLoadingProfile
                            }
                            className="pl-4 mr-2 h-4"
                          />
                        ) : (
                          <div className="mr-2">
                            {permissionsWatcher.includes(permission.value) ? (
                              <FaCheck
                                id="check"
                                style={{ color: "var(--green-500)" }}
                              />
                            ) : (
                              <FaMinus
                                id="no-check"
                                style={{ color: "var(--red-500)" }}
                              />
                            )}
                          </div>
                        )}
                        <div className="w-full flex items-center justify-between">
                          <label
                            htmlFor={permission.value}
                            style={{
                              cursor: "pointer",
                              userSelect: "none",
                            }}
                          >
                            <SolarzTypography
                              fontWeight="regular"
                              hierarchy="small"
                            >
                              {permission.description}
                            </SolarzTypography>
                          </label>
                          <Tooltip title={permission.tooltipMessage}>
                            <BsQuestionCircle
                              strokeWidth={0.5}
                              className="cursor-help stroke-blue-300"
                            />
                          </Tooltip>
                        </div>
                      </div>
                      <Divider style={{ margin: 0, padding: 0 }} />
                    </>
                  );
                })}
              </div>
            </StyledPanel>
          );
        })}
      </Collapse>
      {!hiddenButtons && (
        <div className="w-full flex flex-row justify-end">
          <Button
            htmlType="button"
            onClick={handleCancel}
            style={{ marginRight: 16 }}
          >
            Cancelar
          </Button>
          <Button htmlType="submit" type="primary">
            Salvar
          </Button>
        </div>
      )}
    </Form>
  );
}
