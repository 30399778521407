import { IOrganizationDealListSkeletonLoadingProps } from "./interface";

import { Flex, Skeleton } from "antd";

export function OrganizationDealListSkeletonLoading({
  count = 5,
  isLoading = false,
  children,
  ...props
}: IOrganizationDealListSkeletonLoadingProps) {
  return (
    <Flex {...props} vertical gap={12}>
      {new Array(count).fill(null).map((_, index) => {
        return (
          <Skeleton.Node
            key={index}
            style={{ height: 120, width: "100%" }}
            active={isLoading}
          >
            {" "}
          </Skeleton.Node>
        );
      })}

      {children}
    </Flex>
  );
}
