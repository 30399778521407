import { useMemo } from "react";

import type { IProjectTypeSelectProps, IProjectTypeOption } from "./interface";

import { useSession } from "~hooks/useSession";
import { Select } from "antd";

export function ProjectTypeSelect({
  value,
  onChange,
  defaultValue,
  ...props
}: IProjectTypeSelectProps) {
  const { userHavePermission, userIsAdmin } = useSession();

  const canCreateDefaultProposal =
    userIsAdmin || userHavePermission("CREATE_DEFAULT_PROPOSAL");

  const canCreateDiverseProposal =
    userIsAdmin || userHavePermission("CREATE_DIVERSE_PROPOSAL");

  const canCreateExpressProposal =
    userIsAdmin || userHavePermission("CREATE_EXPRESS_PROPOSAL");

  const OPTIONS: IProjectTypeOption[] = useMemo(
    () => [
      {
        label: "Solar" as const,
        value: "DEFAULT" as const,
        disabled: !canCreateDefaultProposal,
      },
      {
        label: "Express" as const,
        value: "EXPRESS" as const,
        disabled: !canCreateExpressProposal,
      },
      {
        label: "Diversa" as const,
        value: "DIVERSE" as const,
        disabled: !canCreateDiverseProposal,
      },
    ],
    [
      canCreateDefaultProposal,
      canCreateDiverseProposal,
      canCreateExpressProposal,
    ],
  );

  return (
    <Select
      {...props}
      options={OPTIONS}
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
}
