import { useMemo } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { BiErrorCircle } from "react-icons/bi";

import type { IPerformanceProfilesSelectProps } from "./interface";

import { useFetchGetAllPerformanceProfiles } from "~hooks/api";
import { Button, Select, Tooltip } from "antd";

export function PerformanceProfilesSelect({
  allowClear = true,
  onChange,
  onLoad = () => undefined,
  ...props
}: IPerformanceProfilesSelectProps) {
  const {
    data: performanceProfiles,
    isFetching,
    error,
    refetch,
  } = useFetchGetAllPerformanceProfiles({
    options: {
      onSuccess() {
        onLoad();
      },
    },
  });

  const options = useMemo(() => {
    return performanceProfiles
      ? performanceProfiles.map((profile) => ({
          label: profile.description,
          value: profile.id,
          record: profile,
        }))
      : [];
  }, [performanceProfiles]);

  return (
    <Select
      {...props}
      loading={isFetching}
      options={options}
      allowClear={allowClear}
      showSearch
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      popupMatchSelectWidth
      dropdownStyle={{ maxHeight: 300 }}
      status={error ? "error" : ""}
      suffixIcon={
        error ? (
          <Tooltip title={error.message + " | Clique para recarregar."}>
            <Button type="link" loading={isFetching}>
              <BiErrorCircle size={18} onClick={() => refetch()} />
            </Button>
          </Tooltip>
        ) : (
          <AiOutlineSearch />
        )
      }
      placeholder="Procurar perfil de performance"
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option?.label?.toString() ?? "").includes(input)
      }
      filterSort={(optionA, optionB) =>
        (optionA?.label?.toString() ?? "")
          .toLowerCase()
          .localeCompare((optionB?.label?.toString() ?? "").toLowerCase())
      }
      onChange={(value, option) => {
        if (option instanceof Array || !onChange) return;
        onChange(value, option);
      }}
    />
  );
}
