import { useMemo } from "react";

import {
  IModuleCellTypeSelectProps,
  IModuleCellTypeSelectOption,
} from "./interface";

import { Select } from "antd";

export function ModuleCellTypeSelect({
  value,
  onChange,
  defaultValue,
  disabledValues = [],
  ...props
}: IModuleCellTypeSelectProps) {
  const OPTIONS: IModuleCellTypeSelectOption[] = useMemo(
    () => [
      {
        label: "Monocristalino" as const,
        value: "MONOCRISTALINO" as const,
        disabled: disabledValues.includes("MONOCRISTALINO"),
      },
      {
        label: "Policristalino" as const,
        value: "POLICRISTALINO" as const,
        disabled: disabledValues.includes("POLICRISTALINO"),
      },
      {
        label: "Perc" as const,
        value: "MONO_PERC" as const,
        disabled: disabledValues.includes("MONO_PERC"),
      },
    ],
    [disabledValues],
  );

  return (
    <Select
      {...props}
      options={OPTIONS}
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
}
