import type {
  IDeleteDealCustomFieldGroupFormProps,
  IDeleteDealCustomFieldGroupFormValuesType,
} from "./interface";
import { LoadingSkeleton, StyledAlert } from "./styles";

import { Alert } from "~components/Alert";
import { Select } from "~components/Select";
import {
  useFetchDeleteDealCustomFieldGroup,
  useFetchGetDealCustomFieldGroupById,
} from "~hooks/api";
import { useSession } from "~hooks/useSession";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzFormItem } from "~solarzui/SolarzFormItem";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { Flex, Form, Radio } from "antd";

export function DeleteDealCustomFieldGroupForm({
  formInstance,
  initialValues,
  dealCustomFieldGroupId,
  onCancel,
  onError,
  onSuccess,
}: IDeleteDealCustomFieldGroupFormProps) {
  const [form] =
    Form.useForm<IDeleteDealCustomFieldGroupFormValuesType>(formInstance);

  const needDestinationWatcher = Form.useWatch("needDestination", form);

  const { userHavePermission } = useSession();

  const {
    mutate: deleteDealCustomFieldGroup,
    isLoading: isDeletingDealCustomFieldGroup,
  } = useFetchDeleteDealCustomFieldGroup({
    options: {
      onSuccess,
      onError,
    },
  });

  const {
    data: dealCustomFieldGroup,
    isFetching: isLoadingDealCustomFieldGroup,
    error: errorDealCustomFieldGroup,
    refetch: refetchDealCustomFieldGroup,
  } = useFetchGetDealCustomFieldGroupById({
    dependencyArray: [dealCustomFieldGroupId],
    options: {
      retry: 1,
      enabled: dealCustomFieldGroupId > 0,
    },
    payload: {
      id: dealCustomFieldGroupId,
    },
  });

  function handleSubmit(formValues: IDeleteDealCustomFieldGroupFormValuesType) {
    deleteDealCustomFieldGroup({
      id: dealCustomFieldGroupId,
      destinationGroupId: formValues.needDestination
        ? formValues.destinationGroupId
        : undefined,
    });
  }

  function handleCancel() {
    typeof onCancel === "function" ? onCancel() : form.resetFields();
  }

  const disableSubmitButton =
    isLoadingDealCustomFieldGroup ||
    !!errorDealCustomFieldGroup ||
    !dealCustomFieldGroupId;

  const userCanRemoverDeal = userHavePermission("REMOVE_DEAL");

  const countAllFieldsInGroup =
    dealCustomFieldGroup?.dealCustomFields.length ?? 0;

  return (
    <Form
      data-cy="delete-deal-custom-field-group"
      form={form}
      initialValues={{
        needDestination: false,
        ...initialValues,
      }}
      onFinish={handleSubmit}
      preserve
      layout="vertical"
    >
      {errorDealCustomFieldGroup?.message && (
        <Alert.CardError
          errorMessage={errorDealCustomFieldGroup?.message}
          reloadFn={refetchDealCustomFieldGroup}
          title="Falha ao carregar dados da sessão"
          style={{ marginBottom: 16 }}
        />
      )}

      {isLoadingDealCustomFieldGroup && (
        <LoadingSkeleton
          active
          style={{
            height: 160,
            marginBottom: 12,
            width: "calc(100% - 60px)",
          }}
        >
          {" "}
        </LoadingSkeleton>
      )}

      {!isLoadingDealCustomFieldGroup && (
        <StyledAlert
          type="error"
          description={
            <Flex vertical>
              <SolarzTypography variant="danger" fontWeight="medium">
                Você tem certeza que deseja apagar a sessão{" "}
                {dealCustomFieldGroup?.name}?
              </SolarzTypography>

              <ul>
                <li>
                  <SolarzTypography variant="danger" hierarchy="paragraph2">
                    Todos os negócios {countAllFieldsInGroup} campos e suas
                    respectivas informações serão{" "}
                    {needDestinationWatcher ? "movidos" : "apagados"}
                  </SolarzTypography>
                </li>
              </ul>
            </Flex>
          }
        />
      )}

      {countAllFieldsInGroup > 0 && !isLoadingDealCustomFieldGroup && (
        <SolarzFormItem<IDeleteDealCustomFieldGroupFormValuesType>
          name="needDestination"
          rules={[{ required: true }]}
          style={{ margin: "1rem 0" }}
        >
          <Radio.Group
            rootClassName="flex flex-col"
            options={[
              {
                label: (
                  <SolarzTypography
                    style={{
                      pointerEvents: userCanRemoverDeal ? "auto" : "none",
                      opacity: userCanRemoverDeal ? 1 : 0.5,
                    }}
                  >
                    Apagar permanentemente o grupo de variáveis
                  </SolarzTypography>
                ),
                value: false,
                style: {
                  marginBottom: "0.5rem",
                },
                disabled: !userCanRemoverDeal,
              },
              {
                label: (
                  <SolarzTypography>
                    Apagar grupo de variáveis e mover as variáveis para outro
                    grupo
                  </SolarzTypography>
                ),
                value: true,
              },
            ]}
          />
        </SolarzFormItem>
      )}

      {needDestinationWatcher && (
        <SolarzFormItem<IDeleteDealCustomFieldGroupFormValuesType>
          name="destinationGroupId"
          rules={[{ required: true, message: "Campo é obrigatório" }]}
          preserve={false}
        >
          <Select.DealCustomFieldGroup
            placeholder="Selecione a sessão..."
            allowClear={false}
            hiddenOptions={[dealCustomFieldGroupId]}
            canCreateNewCustomFieldGroup={false}
          />
        </SolarzFormItem>
      )}

      <Flex
        style={{ marginTop: 12 }}
        align="center"
        gap={16}
        justify="flex-end"
      >
        <SolarzButton variant="secondary" scale="tiny" onClick={handleCancel}>
          Cancelar
        </SolarzButton>
        <SolarzButton
          scale="tiny"
          htmlType="submit"
          isDisabled={disableSubmitButton}
          isLoading={isDeletingDealCustomFieldGroup}
          variant="danger"
        >
          {needDestinationWatcher ? "Mover e apagar" : "Apenas apagar"}
        </SolarzButton>
      </Flex>
    </Form>
  );
}
