import type { IDeleteDealModalProps } from "./interface";
import { LoadingSkeleton, StyledAlert } from "./styles";

import { Alert } from "~components/Alert";
import {
  useFetchDeleteDealByCustomId,
  useFetchGetDealByCustomId,
} from "~hooks/api";
import { SolarzButton } from "~solarzui/SolarzButton";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { parseCurrency } from "~utils/parse";
import { Avatar, Card, Flex, Modal } from "antd";

export function DeleteDealModal({
  dealId,
  isOpen = false,
  onClose = () => null,
  title = "Apagar negócio",
  maxWidth = 520,
  onError,
  onSuccess,
}: IDeleteDealModalProps) {
  const {
    data: deal,
    isLoading: isLoadingDeal,
    error: errorDeal,
    refetch: reloadDeal,
  } = useFetchGetDealByCustomId({
    dependencyArray: [dealId, isOpen],
    payload: {
      customId: dealId,
    },
    options: {
      enabled: isOpen && dealId > 0,
    },
  });

  const { mutate: deleteDeal, isLoading: isDeletingDeal } =
    useFetchDeleteDealByCustomId({
      options: {
        onSuccess: () => {
          if (typeof onSuccess === "function") {
            onSuccess();
          }

          if (typeof onClose === "function") {
            onClose();
          }
        },
        onError,
      },
    });

  function handleDeleteDeal() {
    deleteDeal({ customId: dealId });
  }

  const disableDeleteButton = isLoadingDeal || !!errorDeal || !dealId;

  const isLoading = isDeletingDeal || isLoadingDeal;

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title={title}
      width="90vw"
      footer={null}
      data-cy="delete-deal-modal"
      style={{ maxWidth }}
      destroyOnClose
    >
      {errorDeal?.message && (
        <Alert.CardError
          errorMessage={errorDeal?.message}
          reloadFn={reloadDeal}
          title="Falha ao carregar dados do negócio"
          style={{ marginBottom: 16 }}
        />
      )}

      {isLoadingDeal && (
        <LoadingSkeleton
          active
          style={{
            height: 160,
            marginBottom: 12,
            width: "calc(100% - 60px)",
          }}
        >
          {" "}
        </LoadingSkeleton>
      )}

      {!isLoadingDeal && (
        <Flex vertical style={{ marginTop: "1.5rem" }}>
          <StyledAlert
            type="error"
            description={
              <Flex vertical>
                <SolarzTypography variant="danger" fontWeight="medium">
                  Você tem certeza que deseja apagar o negócio?
                </SolarzTypography>

                <ul style={{ margin: 0, marginTop: 8 }}>
                  <li>
                    <SolarzTypography variant="danger" hierarchy="paragraph2">
                      Todas as notas, arquivos e documentos associados a esse
                      negócio também serão removidos.
                    </SolarzTypography>
                  </li>
                </ul>
              </Flex>
            }
          />

          <Card
            style={{
              width: "100%",
              minHeight: 98,
              display: "block",
              marginTop: "1.5rem",
            }}
            title={null}
            size="small"
          >
            <Flex vertical gap={12}>
              <Flex align="start" justify="space-between" gap={4}>
                <Flex vertical gap={6}>
                  <SolarzTypography
                    fontWeight="medium"
                    variant="title"
                    hierarchy="paragraph2"
                  >
                    {deal?.name}
                  </SolarzTypography>
                  <SolarzTypography
                    fontWeight="regular"
                    variant="disabled"
                    hierarchy="small"
                  >
                    {deal?.owner?.name}
                  </SolarzTypography>
                </Flex>
              </Flex>

              <Flex align="center" justify="space-between">
                <Flex align="center" gap={8}>
                  <Avatar
                    size="small"
                    shape="circle"
                    style={{ border: "1px solid var(--gray-500)" }}
                  />

                  <SolarzTypography
                    fontWeight="regular"
                    variant="disabled"
                    hierarchy="small"
                  >
                    {parseCurrency(deal?.value ?? 0)}
                  </SolarzTypography>
                </Flex>
              </Flex>
            </Flex>
          </Card>
        </Flex>
      )}

      <Flex
        style={{ marginTop: "1.5rem" }}
        align="center"
        gap={16}
        justify="flex-end"
      >
        <SolarzButton variant="secondary" scale="tiny" onClick={onClose}>
          Cancelar
        </SolarzButton>
        <SolarzButton
          scale="tiny"
          htmlType="submit"
          isDisabled={disableDeleteButton}
          isLoading={isLoading}
          variant="danger"
          onClick={handleDeleteDeal}
        >
          Apagar
        </SolarzButton>
      </Flex>
    </Modal>
  );
}
