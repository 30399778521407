import { Input } from "antd";
import type { InputProps } from "antd/lib/input";

export function InputPassword({
  onFocus = (event) => event.target.select(),
  ...props
}: InputProps) {
  return (
    <Input.Password
      {...props}
      style={{ width: "100%", ...props.style }}
      onFocus={onFocus}
    />
  );
}
