import styled from "@emotion/styled";
import { Breadcrumb } from "antd";

export const StyledBreadcrumb = styled(Breadcrumb)`
  font-size: 16px;

  a {
    color: var(--gray-500);
  }

  .ant-breadcrumb-link.active a {
    color: var(--primary-500);
  }

  .ant-breadcrumb-link.active a:hover {
    background: unset;
    text-decoration: underline;
  }

  svg {
    line-height: 0px;
    margin-top: 4px;
  }

  svg:last-child {
    color: var(--primary-500) !important;
  }

  html.dark & {
    a {
      color: var(--gray-300);
    }

    .ant-breadcrumb-link span {
      color: var(--gray-500);
    }
  }
`;
