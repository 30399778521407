import { useState } from "react";

import { CreateDatasheetModalButton } from "./CreateDatasheetModalButton";
import type {
  DatasheetSearchSelectRecordType,
  IDatasheetSearchSelectProps,
} from "./interface";

import { useFetchGetSearchPaginatedSearchDatasheet } from "~hooks/api";
import { SolarzSelect } from "~solarzui/SolarzSelect";
import { Divider, Flex } from "antd";

export function DatasheetSearchSelect({
  initialRecord,
  searchValue: _searchValue,
  onChangeSearch,
  ...props
}: IDatasheetSearchSelectProps) {
  const [searchValue, setSearchValue] = useState("");

  const canMakeRequest = searchValue.length >= 3;

  const { data, isFetching, error, refetch } =
    useFetchGetSearchPaginatedSearchDatasheet({
      dependencyArray: [searchValue],
      payload: {
        search: searchValue,
        page: 0,
        size: 100,
      },
      options: {
        enabled: canMakeRequest,
        retry: false,
      },
    });

  const defaultOption = initialRecord
    ? [
        {
          label: `(valor inicial) ${initialRecord.name} - ${initialRecord.brand}`,
          record: initialRecord,
          value: initialRecord.id,
        },
      ]
    : undefined;

  return (
    <SolarzSelect<DatasheetSearchSelectRecordType, "id">
      {...props}
      searchValue={searchValue ?? searchValue}
      onChangeSearch={(value) => {
        setSearchValue(value);

        if (typeof onChangeSearch === "function") {
          onChangeSearch(value);
        }
      }}
      options={
        data?.content.map((option) => {
          const label = `${option.name} - ${option.brand}`;

          return {
            label: label,
            record: option,
            value: option.id,
          };
        }) || defaultOption
      }
      isLoading={isFetching}
      refetchFn={canMakeRequest ? refetch : undefined}
      errorMessage={error?.message}
      emptyMessage={
        canMakeRequest
          ? "Nenhum Datasheet encontrado"
          : "Digite ao menos 3 letras para buscar"
      }
      showSearch
      dropdownRender={(menu) => {
        return (
          <Flex vertical>
            {menu}
            <Flex vertical style={{ margin: "8px 16px 0 16px" }}>
              <Divider style={{ margin: 0 }} />

              <CreateDatasheetModalButton />
            </Flex>
          </Flex>
        );
      }}
    />
  );
}
