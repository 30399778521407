import type { IBaseAddressFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function BaseAddressFormModal({
  isOpen = false,
  onClose = () => undefined,
  width = 900,
  title = "Cadastrar endereço base",
  formProps,
}: IBaseAddressFormModalProps) {
  return (
    <Modal
      data-cy="base-address-form-modal"
      title={title}
      width={width}
      open={isOpen}
      footer={null}
      onCancel={onClose}
      destroyOnClose
    >
      <Form.BaseAddress {...formProps} onCancel={onClose} />
    </Modal>
  );
}
