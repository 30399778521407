import { useMemo, useState } from "react";
import { AiOutlineCopy, AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { BsQuestionCircle } from "react-icons/bs";

import {
  ComplementaryItemsOfSettingsTableQueryParamKeysEnum,
  type IComplementaryItemsOfSettingsTableProps,
  type ComplementaryItemsOfSettingsTableFiltersType,
  type ComplementaryItemsOfSettingsTableQueryParamsKeysType,
  IComplementaryItemsOfSettingsTableColumType,
} from "./interface";

import { Menu } from "~components/Menu";
import { Modal } from "~components/Modal";
import {
  useFetchCreateCopyComplementaryItems,
  useFetchDeleteComplementaryItemsOfSettings,
  useFetchGetPaginatedAdditionalCostsAuxVariable,
  useFetchGetPaginatedComplementaryItemsOfSettings,
} from "~hooks/api";
import { useQueryParams, parseFunctions } from "~hooks/useQueryParams";
import { SolarzTable } from "~solarzui/SolarzTable";
import { CalculationTypeEnum, CostGroupEnum, ScaleEnum } from "~types/enum";
import type { IAdditionalCostDTOSchema } from "~types/schemas";
import { parseCurrency, parsePercentage } from "~utils/parse";
import { Flex, Popover, Typography } from "antd";

const tooltipDescriptions = [
  {
    name: "percentualDeLucro",
    content: (
      <div style={{ width: "500px" }}>
        <Typography.Text>
          O lucro é a diferença entre a receita total e o custo total da
          empresa. Este indicador representa aquilo que sobrou após todos os
          descontos obrigatórios, custos fixos e custos variáveis de produção.
          Ele poderá ser utilizado para ser distribuído aos sócios, compor o
          capital de giro ou reinvestido. É possível alterar a base de cálculo
          entre o valor total do projeto incluindo o kit, ou apenas considerando
          os itens complementares com exceção do kit.
          <br />
          <span style={{ color: "red" }}>
            Observação 1: Ele poderá ser negativo caso a receita não seja
            suficiente para pagar todos as despesas.
          </span>
        </Typography.Text>
      </div>
    ),
  },
  {
    name: "margemDeContribuição",
    content: (
      <div style={{ width: "500px" }}>
        <Typography.Text>
          O Índice de Margem de Contribuição é o percentual resultante entre os
          custos fixos não envolvido diretamente neste projeto dividido pela
          receita bruta. IMC = Projeção Custos Fixos/Projeção Receita Bruta IMC
          = Índice de Margem de Contribuição Projeção Custos Fixos = Somatório
          de todas as despesas que não estão diretamente relacionadas ao projeto
          atual, como por exemplo: Pró-Labore, Mão de Obra Administrativa,
          Aluguel, Energia, Internet, Contador, Advogado, IPTU, Juros de
          Empréstimo, Amortização de Investimentos, Entre Outros. Projeção
          Receita Bruta = Somatório de toda a receita a ser obtida pela empresa.
          É possível alterar a base de cálculo entre o valor total do projeto
          incluindo o kit, ou apenas considerando os itens complementares com
          exceção do kit.
          <br />
          <span style={{ color: "red" }}>
            Observação 1: O Índice de margem de contribuição não considera o
            lucro, ele reflete apenas o necessário para cobrir as despesas
            fixas.
          </span>
        </Typography.Text>
      </div>
    ),
  },
  {
    name: "impostos",
    content: (
      <div style={{ width: "500px" }}>
        <Typography.Text>
          Este campo representa o custo com impostos, por padrão poderá ser
          utilizado um único percentual para representar todos os impostos, ou
          poderá ser detalhados incluindo mais itens. É possível alterar a base
          de cálculo entre o valor total do projeto incluindo o kit, ou apenas
          considerando os itens complementares com exceção do kit.
        </Typography.Text>
      </div>
    ),
  },
];

function translateCalculationType(value: CalculationTypeEnum) {
  switch (value) {
    case "EXPRESSAO_PERSONALIZADA":
      return "Expressão personalizada";
    case "MULTIPLICADOR":
      return "Multiplicador";
    case "PERCENTUAL":
      return "Percentual";
    case "VALOR_FIXO":
      return "Valor fixo";
    case "PERCENTUAL_AGREGADOR_CUSTO":
      return "Perentual de agregador de custo";
    default:
      return "-";
  }
}

function translateBaseCalculationType(value: ScaleEnum | undefined) {
  switch (value) {
    case "VALOR_TOTAL":
      return "Valor total";
    case "CUSTOS_COMPLEMENTARES":
      return "Custos complementares";
    case "VALOR_DO_MATERIAL":
      return "Valor do material";
    default:
      return "-";
  }
}

function translateCostGroupType(value: CostGroupEnum | undefined) {
  switch (value) {
    case "INSIGHTS":
      return "Insights";
    case "CUSTOS_COMPLEMENTARES":
      return "Custos complementares";
    case "KIT":
      return "Kit";
    default:
      return "-";
  }
}

function formatValue(
  value: number | undefined | null,
  type?: CalculationTypeEnum,
) {
  const parsedValue = Number(value);
  if (isNaN(parsedValue)) return "-";

  switch (type) {
    case "VALOR_FIXO":
      return parseCurrency(parsedValue);
    case "PERCENTUAL":
      return parsePercentage(parsedValue);
    default:
      return parsedValue.toFixed(2);
  }
}

export function ComplementaryItemsOfSettingsTable({
  isAuxVariable = false,
  ...props
}: IComplementaryItemsOfSettingsTableProps) {
  const { getParsedQueryParams, handleSaveInQueryParams } = useQueryParams();

  const [
    selectedComplementaryItemToUpdate,
    setSelectedComplementaryItemToUpdate,
  ] = useState<IAdditionalCostDTOSchema>();

  const [
    selectedAuxiliaryVariablesToUpdate,
    setSelectedAuxiliaryVariablesToUpdate,
  ] = useState<IAdditionalCostDTOSchema>();

  const [selectedComplementaryItemToCopy, setSelectedComplementaryItemToCopy] =
    useState<IAdditionalCostDTOSchema>();

  const [
    selectedComplementaryItemToDelete,
    setSelectedComplementaryItemToDelete,
  ] = useState<IAdditionalCostDTOSchema>();

  const QUERY_PARAMS_KEYS: ComplementaryItemsOfSettingsTableQueryParamsKeysType =
    useMemo(
      () => ({
        CURRENT_PAGE:
          props.queryParamKeys?.CURRENT_PAGE ||
          ComplementaryItemsOfSettingsTableQueryParamKeysEnum.CURRENT_PAGE,
      }),
      [props.queryParamKeys],
    );

  const filters: ComplementaryItemsOfSettingsTableFiltersType = useMemo(
    () => ({
      currentPage:
        getParsedQueryParams(
          QUERY_PARAMS_KEYS.CURRENT_PAGE,
          parseFunctions.NUMBER,
        ) ?? 1,
    }),
    [getParsedQueryParams, QUERY_PARAMS_KEYS],
  );

  const {
    mutate: deleteComplementaryItem,
    isLoading: isDeletingComplementaryItem,
  } = useFetchDeleteComplementaryItemsOfSettings({
    onSuccess: () => setSelectedComplementaryItemToDelete(undefined),
  });

  function handleDeleteComplementaryItem() {
    deleteComplementaryItem({
      additionalCostsId: selectedComplementaryItemToDelete?.id ?? 0,
    });
  }

  const {
    mutate: copyComplementaryItem,
    isLoading: isCopingComplementaryItem,
  } = useFetchCreateCopyComplementaryItems({
    options: {
      onSuccess: () => setSelectedComplementaryItemToCopy(undefined),
    },
  });

  function handleCopyComplementaryItem() {
    copyComplementaryItem({
      complementaryItemId: selectedComplementaryItemToCopy?.id ?? 0,
      name: selectedComplementaryItemToCopy?.name
        ? `${selectedComplementaryItemToCopy?.name} (Cópia)`
        : "",
    });
  }

  const useFetchData = isAuxVariable
    ? useFetchGetPaginatedAdditionalCostsAuxVariable
    : useFetchGetPaginatedComplementaryItemsOfSettings;

  const {
    data: complementaryItems,
    isFetching: isLoadingComplementaryItems,
    error: complementaryItemsError,
    refetch: reloadComplementaryItems,
  } = useFetchData({
    dependencyArray: [filters],
    options: {
      retry: 1,
      enabled: filters.currentPage > 0,
    },
    payload: {
      page: filters.currentPage - 1,
      size: 10, // STATIC,
    },
  });

  const renderOperationType = (record: IAdditionalCostDTOSchema) => {
    const { calculationType, isAuxVariable, valueFlexibilityType, value } =
      record;

    const operationType = translateCalculationType(calculationType);

    if (isAuxVariable)
      return <span style={{ fontWeight: 500 }}>{operationType}</span>;

    let additionalInfo: string | undefined = "";
    if (valueFlexibilityType === "SCALED") {
      additionalInfo = "Faixa de precificação";
    } else if (calculationType !== "EXPRESSAO_PERSONALIZADA") {
      additionalInfo = formatValue(value, calculationType);
    }

    return (
      <Flex style={{ flexDirection: "column" }}>
        <span style={{ fontWeight: 500 }}>{operationType}</span>
        {additionalInfo && <span>{additionalInfo}</span>}
      </Flex>
    );
  };

  const columns: IComplementaryItemsOfSettingsTableColumType[] = [
    {
      title: "Nome",
      render: (_, record) => (
        <>
          {record.fixed ? (
            <>
              <b>{record.name}</b>
              <Popover
                content={
                  record.identifier === "cc_lucro"
                    ? tooltipDescriptions[0].content
                    : record.identifier === "cc_margem_contribuicao"
                      ? tooltipDescriptions[1].content
                      : record.identifier === "cc_impostos"
                        ? tooltipDescriptions[2].content
                        : ""
                }
                trigger="hover"
              >
                <span style={{ marginLeft: "10px" }}>
                  <BsQuestionCircle />
                </span>
              </Popover>
            </>
          ) : (
            record.name
          )}
        </>
      ),
    },
    {
      title: "Identificador",
      render: (_, record) => (record.identifier ? record.identifier : "-"),
    },
    {
      title: "Tipo de operação",
      render: (_, record) => renderOperationType(record),
    },
    {
      title: "Base de Cálculo",
      render: (_, record) =>
        record.calculationType === "PERCENTUAL"
          ? translateBaseCalculationType(record.baseCalculationType)
          : "-",
      hidden: isAuxVariable,
    },
    {
      title: "Grupo de Custo",
      dataIndex: "costGroup",
      render: (_, record) => translateCostGroupType(record.costGroup),
    },
    {
      title: "Ações",
      align: "center",
      render: (_, record) => (
        <Menu.MoreDropdown
          items={[
            {
              text: "Alterar",
              icon: <AiOutlineEdit />,
              color: "var(--primary-500)",
              onClick: () =>
                isAuxVariable
                  ? setSelectedAuxiliaryVariablesToUpdate(record)
                  : setSelectedComplementaryItemToUpdate(record),
            },
            {
              text: "Criar cópia",
              icon: <AiOutlineCopy />,
              color: "var(--primary-500)",
              onClick: () => setSelectedComplementaryItemToCopy(record),
            },
            {
              text: "Remover",
              icon: <AiOutlineDelete />,
              color: !record.fixed ? "var(--primary-500)" : "var(--gray-300)",
              onClick: () => setSelectedComplementaryItemToDelete(record),
              isDisabled: record.fixed,
              tooltip: record.fixed
                ? "Este item é fixo, não pode ser removido."
                : undefined,
            },
          ]}
          position="bottomRight"
        />
      ),
    },
  ];

  return (
    <Flex vertical>
      <SolarzTable<IAdditionalCostDTOSchema>
        data-cy="complementary-items-table"
        columns={columns}
        rows={complementaryItems?.content ?? []}
        isLoading={isLoadingComplementaryItems}
        pagination={{
          currentItemCount: complementaryItems?.size ?? 0,
          currentPage: filters.currentPage || 1,
          itemLabel: isAuxVariable
            ? "variáveis auxiliares"
            : "itens complementares",
          totalItemCount: complementaryItems?.totalElements ?? 0,
          onChange: (currentPage) => {
            handleSaveInQueryParams({
              [QUERY_PARAMS_KEYS.CURRENT_PAGE]: currentPage,
            });
          },
        }}
        scroll={{ x: 1200 }}
        emptyMessage="Não foi encontrado nenhum item"
        errorMessage={complementaryItemsError?.message}
        reloadFn={reloadComplementaryItems}
        rowKey="id"
      />
      <Modal.DefaultRemove
        isLoading={isDeletingComplementaryItem}
        isOpen={!!selectedComplementaryItemToDelete}
        itemName={selectedComplementaryItemToDelete?.name}
        onClose={() => setSelectedComplementaryItemToDelete(undefined)}
        suffix="Item complementar"
        title="Remover Item complementar"
        onSubmit={handleDeleteComplementaryItem}
      />
      <Modal.DefaultAlert
        isOpen={!!selectedComplementaryItemToCopy}
        isLoading={isCopingComplementaryItem}
        onClose={() => setSelectedComplementaryItemToCopy(undefined)}
        subtitle="Realmente deseja criar um cópia deste item complementar?"
        title="Copiar item complementar"
        onSubmit={handleCopyComplementaryItem}
      />
      <Modal.ComplementaryItemsOfSettingsForm
        isOpen={!!selectedComplementaryItemToUpdate}
        onClose={() => setSelectedComplementaryItemToUpdate(undefined)}
        formProps={{
          initialValues: selectedComplementaryItemToUpdate,
          onSuccess: () => setSelectedComplementaryItemToUpdate(undefined),
        }}
      />
      <Modal.AuxiliaryVariablesForm
        isOpen={!!selectedAuxiliaryVariablesToUpdate}
        onClose={() => setSelectedAuxiliaryVariablesToUpdate(undefined)}
        formProps={{
          initialValues: selectedAuxiliaryVariablesToUpdate,
          onSuccess: () => setSelectedAuxiliaryVariablesToUpdate(undefined),
        }}
      />
    </Flex>
  );
}
