import { useMemo } from "react";

import type { IIntegrationListProps } from "./interface";

import { Card } from "~components/Card";
import { Result } from "~components/Result";
import { useFetchGetPaginatedDistributors } from "~hooks/api";
import type { IDistributorDTOSchema } from "~types/schemas";
import { List } from "antd";

export function IntegrationList(props: IIntegrationListProps) {
  const { data, isFetching } = useFetchGetPaginatedDistributors({
    payload: {
      page: 0,
      size: 100,
    },
  });

  const DATA_SOURCE: IDistributorDTOSchema[] = useMemo(
    () => data?.content ?? [],
    [data?.content],
  );

  return (
    <List<IDistributorDTOSchema>
      {...props}
      data-cy="integration-list"
      itemLayout="horizontal"
      grid={{ gutter: 16 }}
      dataSource={DATA_SOURCE}
      loading={isFetching}
      rowKey="name"
      locale={{
        emptyText: <Result.TableErrorOrEmpty />,
      }}
      renderItem={(data) => (
        <List.Item style={{ display: "flex" }}>
          <Card.DistributorIntegration
            description={data.description}
            distributor={data.name}
            distributorId={data.id}
            isActive={data.enabled}
            activeKitFilter={data.activeKitFilter}
            version={data.version}
            lastUpdate={data.lastUpdate}
          />
        </List.Item>
      )}
    />
  );
}
