import { formatGoogleLocationAddress } from "./format";

import type { Location } from "~components/GoogleMaps/contexts/GoogleMapsContext";
import type { IAddressFormSchema, IEosAddressDTOSchema } from "~types/schemas";

export function parseGoogleLocationToAddressForm(
  location: Location,
): IAddressFormSchema {
  return {
    street: location.street,
    city: {
      name: location?.city,
    },
    zipCode: location?.zipCode,
    number: location?.streetNumber?.toString(),
    district: location?.district,
    province: location?.state
      ? {
          name: location?.state,
          uf: location?.state,
        }
      : undefined,
    latitude: location?.latitude,
    longitude: location?.longitude,
    formattedAddress: formatGoogleLocationAddress(location),
  };
}

export function parseGoogleLocationToAddressEosForm(
  location: Location,
): IEosAddressDTOSchema {
  return {
    logradouro: location.street,
    cidade: location?.city,
    cep: location?.zipCode,
    numero: location?.streetNumber?.toString(),
    bairro: location?.district,
    estado: location?.state,
  };
}
