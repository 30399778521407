import { InputCep } from "./Cep";
import { InputCnpj } from "./Cnpj";
import { InputCode } from "./Code";
import { InputCpf } from "./Cpf";
import { InputCpfCnpj } from "./CpfCnpj";
import { InputGlobalIrradiation } from "./GlobalIrradiation";
import { InputMoney } from "./Money";
import { InputNumber } from "./Number";
import { InputPassword } from "./Password";
import { InputPerformanceRate } from "./PerformanceRate";
import { InputTelephone } from "./Telephone";
import { InputTelephoneWithDdd } from "./TelephoneWithDdd";
import { InputText } from "./Text";
import { InputTextArea } from "./TextArea";
import { InputUnitMeasurement } from "./UnitMeasurement";
import { InputUUID } from "./UUID";

export const CustomInput = {
  Cep: InputCep,
  Cnpj: InputCnpj,
  Cpf: InputCpf,
  CpfCnpj: InputCpfCnpj,
  Code: InputCode,
  Number: InputNumber,
  Password: InputPassword,
  Money: InputMoney,
  PerformanceRate: InputPerformanceRate,
  Telephone: InputTelephone,
  TelephoneWithDdd: InputTelephoneWithDdd,
  Text: InputText,
  TextArea: InputTextArea,
  UnitMeasurement: InputUnitMeasurement,
  GlobalIrradiation: InputGlobalIrradiation,
  UUID: InputUUID,
};
