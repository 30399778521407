import type { IDatasheetFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function DatasheetFormModal({
  isOpen = false,
  onClose = () => null,
  title = "Formulário do Datasheet",
  maxWidth = 512,
  formProps,
  datasheetId,
}: IDatasheetFormModalProps) {
  return (
    <Modal
      data-cy="datasheet-form-modal"
      open={isOpen}
      onCancel={onClose}
      title={title}
      width="90vw"
      footer={null}
      style={{ maxWidth }}
      destroyOnClose
      styles={{
        body: {
          marginTop: 12,
        },
      }}
    >
      <Form.Datasheet
        {...formProps}
        datasheetId={datasheetId}
        onCancel={onClose}
      />
    </Modal>
  );
}
