import { useState } from "react";

import type { IConsumerUnitsButtonProps } from "./interface";

import { Modal } from "~components/Modal";
import { Button } from "antd";

export function ConsumerUnitsButton({
  children,
  modalProps,
  closeAfterSubmit = false,
  ...props
}: IConsumerUnitsButtonProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button
        {...props}
        data-cy="consumer-units-button"
        onClick={() => setIsModalOpen(true)}
        type="primary"
        htmlType="button"
      >
        {children ?? "Unidades consumidoras"}
      </Button>

      <Modal.ConsumerUnitsForm
        {...modalProps}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        formProps={{
          ...modalProps.formProps,
          onSuccess(consumptionUnits) {
            if (modalProps.formProps?.onSuccess) {
              modalProps.formProps?.onSuccess(consumptionUnits);
            }

            if (closeAfterSubmit) {
              setIsModalOpen(false);
            }
          },
        }}
      />
    </>
  );
}
