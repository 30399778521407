import { useEffect, useRef, useState } from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";

import type { IInfoCardProps } from "./interface";
import styles from "./styles";

import { useOnClickOutside } from "~hooks/useOnClickOutside";
import { Skeleton, Spin, Tag, Tooltip, Typography, Flex } from "antd";

export function InfoTagCard({
  title = "",
  fieldValue = "",
  subtitle = "",
  tooltip = "",
  color = "blue",
  isLoading = false,
  style,
  onClickEdit = () => undefined,
  isEditable = false,
  onEditSubmit = () => undefined,
  isSubmittingEdit = false,
  isEditing = false,
}: IInfoCardProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditFieldOpen, setIsEditFieldOpen] = useState(false);

  const TypographyRef = useRef<HTMLSpanElement | null>(null);
  useOnClickOutside(TypographyRef, () => setIsEditFieldOpen(false));

  function handleSubmit(value: string) {
    onEditSubmit(value);
  }

  function handleClickEdit() {
    setIsEditFieldOpen(true);
    onClickEdit();
  }

  useEffect(() => {
    if (!isEditing) {
      setIsEditFieldOpen(false);
    }
  }, [isEditing]);

  useEffect(() => {
    setIsSubmitting(isSubmittingEdit);
  }, [isSubmittingEdit]);

  if (isLoading) {
    return (
      <Flex style={{ width: "100%", height: "100%" }}>
        <Skeleton.Button active block style={{ height: 66 }} />
      </Flex>
    );
  }

  return (
    <Tag
      data-cy="info-tag-card"
      ref={TypographyRef}
      color={color}
      style={{
        minWidth: "10rem",
        padding: "0.5rem 1rem",
        minHeight: 68,
        width: "100%",
        margin: 0,
        ...style,
      }}
    >
      <style jsx>{styles}</style>
      <Flex style={{ width: "100%", height: "100%" }}>
        <Flex vertical style={{ padding: "0.25rem" }}>
          <Flex
            align="center"
            style={{ fontWeight: "bold", fontSize: "0.8125rem" }}
          >
            {!isEditFieldOpen && title}
            <Typography.Text
              editable={
                isEditable && {
                  tooltip: "Editar",
                  text: fieldValue?.toString() ?? "",
                  autoSize: false,
                  onChange: handleSubmit,
                  editing: isEditFieldOpen,
                  onStart: handleClickEdit,
                  enterIcon: isSubmitting ? (
                    <Spin size="small" style={{ fontSize: 0 }} />
                  ) : undefined,
                  icon: isSubmitting ? (
                    <Spin size="small" style={{ fontSize: 0 }} />
                  ) : undefined,
                  onCancel: () => setIsEditFieldOpen(false),
                  // onEnd: () => setIsEditFieldOpen(false),
                }
              }
              className="edit"
            />
          </Flex>

          <Flex
            align="center"
            gap={4}
            wrap="nowrap"
            style={{ fontSize: "0.75rem" }}
          >
            {subtitle}{" "}
            <Tooltip title={tooltip}>
              <AiOutlineQuestionCircle fontSize={12} color="var(--blue-500)" />
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>
    </Tag>
  );
}
