import styled from "@emotion/styled";
import { Radio } from "antd";

export const RadioGroup = styled(Radio.Group)`
  gap: 0.5rem;
  display: flex;
  align-items: center;

  .ant-radio-button-wrapper {
    border-radius: 24px;
  }

  .ant-radio-button-wrapper-checked {
    background-color: var(--primary-50);
  }
`;

export const RadioButton = styled(Radio.Button)`
  border: 1px solid var(--gray-200);
  color: var(--gray-400);
  font-weight: 500;
  font-size: 12px;

  transition: all 0.2s;

  &:hover {
    border-color: var(--primary-500);
  }

  &::before {
    display: none !important;
  }

  &::after {
    display: none !important;
  }
`;
