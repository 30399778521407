import Link from "next/link";
import { useRouter } from "next/router";

import { House } from "~estrela-ui/icons";
import { parseCapitalized } from "~utils/parse";
import { parseUrlPathToAccentedWord } from "~utils/parseUrlPathToAccentedWord";
import { Breadcrumb } from "antd";

function clearString(text: string) {
  const formattedText = text.replaceAll("/", "").replaceAll("-", " ");
  const capitalizedText = parseCapitalized(formattedText);

  return parseUrlPathToAccentedWord(capitalizedText);
}

export function DefaultBreadcrumb() {
  const router = useRouter();

  const routes = router?.asPath
    .split("?")[0]
    .split("/")
    .filter((route) => {
      return route;
    })
    .map((route) => `/${route}`);

  const items = routes.map((route, index) => {
    const urlString = routes.slice(0, index + 1).join("");

    const href = `${urlString}`;

    return {
      title: <Link href={href}>{clearString(route)}</Link>,
    };
  });

  return (
    <Breadcrumb
      data-cy="default-breadcrumb"
      items={[
        {
          title: (
            <Link href="/">
              <House />
              {router?.asPath === "/" ? " Dashboard" : ""}
            </Link>
          ),
        },
        ...items,
      ]}
    />
  );
}
