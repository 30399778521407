import type { IGoogleMapSatelliteModalProps } from "./interface";

import { GoogleMapsProvider } from "~components/GoogleMaps";
import { MapSatellite } from "~components/GoogleMaps/components/MapSatellite";
import { Modal } from "antd";

export function GoogleMapSatelliteModal({
  isOpen = false,
  onClose = () => null,
  width = 1280,
}: IGoogleMapSatelliteModalProps) {
  return (
    <Modal
      data-cy="empty-kit-form-modal"
      open={isOpen}
      onCancel={onClose}
      title="Buscar endereço com o Google"
      width={width}
      footer={null}
      destroyOnClose
      style={{
        maxWidth: "90vw",
        top: 24,
      }}
      styles={{
        body: {
          height: "82vh",
        },
      }}
    >
      <GoogleMapsProvider>
        <MapSatellite />
      </GoogleMapsProvider>
    </Modal>
  );
}
