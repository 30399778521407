import type { IAdminModuleDetailsModalProps } from "./interface";

import { Alert } from "~components/Alert";
import { useFetchGetAdminModulesById } from "~hooks/api";
import { SolarzAnchor } from "~solarzui/SolarzAnchor";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { parsePotency } from "~utils/parse";
import { Col, Flex, Modal, Row, Skeleton } from "antd";

export function AdminModuleDetailsModal({
  moduleId = 0,
  isOpen = false,
  onClose = () => undefined,
  title = "Detalhes do módulo",
  maxWidth = 768,
}: IAdminModuleDetailsModalProps) {
  const canMakeRequest = moduleId > 0;

  const {
    data: inverterData,
    isFetching: isLoadingDatasheet,
    error: inverterError,
    refetch: reloadDatasheetData,
  } = useFetchGetAdminModulesById({
    payload: {
      id: moduleId,
    },
    dependencyArray: [moduleId],
    options: {
      enabled: canMakeRequest,
      retry: 1,
    },
  });

  return (
    <Modal
      data-cy="admin-module-details-modal"
      width="90vw"
      title={title}
      footer={null}
      destroyOnClose
      open={isOpen}
      onCancel={onClose}
      style={{ maxWidth }}
    >
      <Flex vertical gap={16}>
        {inverterError && (
          <Alert.CardError
            title="Falha ao carregar dados"
            errorMessage={inverterError?.message}
            reloadFn={reloadDatasheetData}
            style={{ marginBottom: 12 }}
          />
        )}

        {!canMakeRequest && (
          <Alert.CardError
            title="faltando o ID do módulo"
            errorMessage="Esta faltando o ID do módulo"
            style={{ marginBottom: 12 }}
          />
        )}

        <Row align="top" gutter={24} wrap={false} style={{ marginTop: "1rem" }}>
          <Col span={10}>
            <SolarzTypography
              fontWeight="medium"
              variant="title"
              hierarchy="paragraph2"
              style={{ textAlign: "right" }}
            >
              Modelo:
            </SolarzTypography>
          </Col>
          <Col span={14}>
            {isLoadingDatasheet || inverterError ? (
              <Skeleton.Input active={isLoadingDatasheet} size="small" />
            ) : (
              <SolarzTypography
                fontWeight="regular"
                variant="disabled"
                hierarchy="small"
              >
                {inverterData?.model}
              </SolarzTypography>
            )}
          </Col>
        </Row>

        <Row align="top" gutter={24} wrap={false}>
          <Col span={10}>
            <SolarzTypography
              fontWeight="medium"
              variant="title"
              hierarchy="paragraph2"
              style={{ textAlign: "right" }}
            >
              Fabricante:
            </SolarzTypography>
          </Col>
          <Col span={14}>
            {isLoadingDatasheet || inverterError ? (
              <Skeleton.Input active={isLoadingDatasheet} size="small" />
            ) : (
              <SolarzTypography
                fontWeight="regular"
                variant="disabled"
                hierarchy="small"
              >
                {inverterData?.manufacturer}
              </SolarzTypography>
            )}
          </Col>
        </Row>

        <Row align="top" gutter={24} wrap={false}>
          <Col span={10}>
            <SolarzTypography
              fontWeight="medium"
              variant="title"
              hierarchy="paragraph2"
              style={{ textAlign: "right" }}
            >
              Tipo de superfície:
            </SolarzTypography>
          </Col>
          <Col span={14}>
            {isLoadingDatasheet || inverterError ? (
              <Skeleton.Input active={isLoadingDatasheet} size="small" />
            ) : (
              <SolarzTypography
                fontWeight="regular"
                variant="disabled"
                hierarchy="small"
              >
                {inverterData?.surfaceType}
              </SolarzTypography>
            )}
          </Col>
        </Row>

        <Row align="top" gutter={24} wrap={false}>
          <Col span={10}>
            <SolarzTypography
              fontWeight="medium"
              variant="title"
              hierarchy="paragraph2"
              style={{ textAlign: "right" }}
            >
              Potência:
            </SolarzTypography>
          </Col>
          <Col span={14}>
            {isLoadingDatasheet || inverterError ? (
              <Skeleton.Input active={isLoadingDatasheet} size="small" />
            ) : (
              <SolarzTypography
                fontWeight="regular"
                variant="disabled"
                hierarchy="small"
              >
                {parsePotency(inverterData?.powerWp)}
              </SolarzTypography>
            )}
          </Col>
        </Row>

        <Row align="top" gutter={24} wrap={false}>
          <Col span={10}>
            <SolarzTypography
              fontWeight="medium"
              variant="title"
              hierarchy="paragraph2"
              style={{ textAlign: "right" }}
            >
              Datasheet:
            </SolarzTypography>
          </Col>
          <Col span={14}>
            {isLoadingDatasheet || inverterError ? (
              <Skeleton.Input active={isLoadingDatasheet} size="small" />
            ) : (
              <SolarzAnchor
                isNextLink
                href={`/admin/datasheets?id=${inverterData?.datasheet?.id}`}
                isDisabled={
                  !inverterData || Number(inverterData.datasheet?.id) <= 0
                }
              >
                {inverterData?.datasheet?.id ? "Link" : "-"}
              </SolarzAnchor>
            )}
          </Col>
        </Row>
      </Flex>
    </Modal>
  );
}
