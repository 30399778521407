import type { IUpdatePipedriveTokenApiFormModalProps } from "./interface";

import { Form } from "~components/Form";
import { Modal } from "antd";

export function UpdatePipedriveTokenApiFormModal({
  isOpen = false,
  onClose = () => null,
  title = "Definir Pipedrive API token",
  maxWidth = 1280,
  formProps,
}: IUpdatePipedriveTokenApiFormModalProps) {
  return (
    <Modal
      data-cy="update-pipedrive-token-api-form-modal"
      open={isOpen}
      onCancel={onClose}
      title={title}
      width="512px"
      footer={null}
      style={{ maxWidth }}
      destroyOnClose
    >
      <Form.UpdatePipedriveTokenApi {...formProps} onCancel={onClose} />
    </Modal>
  );
}
